var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { OGDialog } from '@opengear/modals';
import { useUpdatePortSmartgroup } from '@/api/ports/smartgroups';
import { DeleteButton, FormActions, SubmitButton, CancelButton, FormContainer } from '@/components/layout/forms';
import { PortFilterNameField, PortFilterQueryFieldset } from './port-filter-modal-form-controls';
import { isPortQueryValid } from '@/containers/filters/advanced-filters/port-filter';
export var PORT_FILTER_EDIT_TITLE_ID = 'port-filter-edit-title';
/**
 * PortFilterEditModalContent
 * Separate content logic to help clear the form state when unmounted
 */
export function PortFilterEditModalContent(_a) {
    var _this = this;
    var onClose = _a.onClose, onDelete = _a.onDelete, onSubmit = _a.onSubmit, selectedFilter = _a.selectedFilter, translations = _a.translations;
    var updatePortSmartgroup = useUpdatePortSmartgroup();
    // Default values are pre set with a valid filter
    var methods = useForm({ mode: 'onTouched', defaultValues: selectedFilter });
    var handleSubmit = useCallback(function (editFilter) { return __awaiter(_this, void 0, void 0, function () {
        var updatedNodeFilter;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isPortQueryValid(editFilter)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePortSmartgroup({
                            id: editFilter.id,
                            name: editFilter.name,
                            queryObject: editFilter.queryObject,
                        })];
                case 1:
                    updatedNodeFilter = _a.sent();
                    if (updatedNodeFilter)
                        onSubmit(updatedNodeFilter);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [onSubmit, updatePortSmartgroup]);
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: PORT_FILTER_EDIT_TITLE_ID, mode: "update", methods: methods, onSubmit: handleSubmit }, { children: [_jsx(DialogTitle, __assign({ id: PORT_FILTER_EDIT_TITLE_ID }, { children: translations.dialogTitle })), _jsxs(DialogContent, { children: [_jsx(PortFilterNameField, {}), _jsx(PortFilterQueryFieldset, {})] }), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { paddingTop: 0 } }, { children: _jsxs(FormActions, { children: [_jsx(DeleteButton, { onClick: onDelete }), _jsx(Box, { flexGrow: 1 }), _jsx(CancelButton, { onCancel: onClose }), _jsx(SubmitButton, { children: translations.saveBtn })] }) }))] })));
}
export function PortFilterEditModal(_a) {
    var onClose = _a.onClose, onDelete = _a.onDelete, onSubmit = _a.onSubmit, open = _a.open, selectedFilter = _a.selectedFilter, translations = _a.translations;
    return (_jsx(OGDialog, __assign({ "aria-labelledby": PORT_FILTER_EDIT_TITLE_ID, open: open, onClose: onClose }, { children: selectedFilter ? (_jsx(PortFilterEditModalContent, { selectedFilter: selectedFilter, onClose: onClose, onDelete: onDelete, onSubmit: onSubmit, translations: translations })) : null })));
}
