var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNodeSmartgroups } from '@/api/nodes/smartgroups';
import { QUERY_PARAM_NODE_FILTER_KEY } from '@/constants/query-params-keys';
import { applyPrefixToQueryObject, formatNodeFilterSearchParam, parseNodeFilterSearchParam, verifyNodeFilter } from './node-filter-utils';
/**
 * Hook to manage the applied node filter state
 * - Validates filter from smartgroups.
 */
export function useNodeFilter() {
    var data = useNodeSmartgroups().data;
    var _a = __read(useState(null), 2), _nodeFilter = _a[0], setNodeFilter = _a[1];
    var nodeFilter = useMemo(function () { return verifyNodeFilter(_nodeFilter, data === null || data === void 0 ? void 0 : data.smartgroups); }, [data === null || data === void 0 ? void 0 : data.smartgroups, _nodeFilter]);
    return {
        nodeFilter: nodeFilter,
        setNodeFilter: setNodeFilter,
    };
}
/**
 * Hook to manage the applied node filter state
 * - Initializes filter from search params.
 * - Validates filter from smartgroups.
 */
export function useNodeFilterSearchParams(prefix) {
    var data = useNodeSmartgroups().data;
    // On load, initialize the filter from the search params
    var _a = __read(useSearchParams(), 2), searchParams = _a[0], setSearchParams = _a[1];
    var nodeFilterSearchParam = searchParams.get(QUERY_PARAM_NODE_FILTER_KEY);
    var nodeFilter = useMemo(function () {
        var parsedNodeFilter = parseNodeFilterSearchParam(nodeFilterSearchParam);
        return verifyNodeFilter(parsedNodeFilter, data === null || data === void 0 ? void 0 : data.smartgroups);
    }, [data === null || data === void 0 ? void 0 : data.smartgroups, nodeFilterSearchParam]);
    var nodeQueryObject = useMemo(function () { return applyPrefixToQueryObject(prefix, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject); }, [prefix, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject]);
    var setNodeFilter = useCallback(function (filter) {
        setSearchParams(function (prevSearchParams) {
            if (!filter) {
                prevSearchParams.delete(QUERY_PARAM_NODE_FILTER_KEY);
            }
            else {
                prevSearchParams.set(QUERY_PARAM_NODE_FILTER_KEY, formatNodeFilterSearchParam(filter));
            }
            return prevSearchParams;
        });
    }, [setSearchParams]);
    return {
        nodeFilter: nodeFilter,
        nodeQueryObject: nodeQueryObject,
        setNodeFilter: setNodeFilter,
    };
}
