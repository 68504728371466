var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import { FileField } from './update-subscription-form-controls';
import { FormActions, FormContainer, SubmitButton, CancelButton } from '@/components/layout/forms';
export function UpdateSubscriptionForm(_a) {
    var onCancel = _a.onCancel, onSubmit = _a.onSubmit, permissions = _a.permissions, ariaLabelledBy = _a.ariaLabelledBy;
    var t = useTranslation().t;
    var defaultValues = {
        file: null,
    };
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: ariaLabelledBy, methods: methods, onSubmit: onSubmit, mode: "create", permissions: permissions }, { children: [_jsx(DialogContent, __assign({ sx: { paddingTop: 0 } }, { children: _jsx(FileField, {}) })), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { paddingTop: 0 } }, { children: _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: onCancel }), _jsx(SubmitButton, { children: t('global.buttons.apply') })] }) }))] })));
}
