var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { WarningBanner } from '@/components/warning-banner';
import { useSystemSmf, useUpdateSystemSmf } from '@/api/system/smf';
import { SmfForm } from '@/components/settings/services/smf';
import { PAGE_TITLE_ID } from '@/constants/ids';
export var SMF_FORM_LOADER_ID = 'smf-form-loading';
export var Smf = withAuthenticationRequired(function () {
    var t = useTranslation().t;
    useDocTitle(t('pages.smf.title'));
    // Check if user has edit permissions based on ui permissions and dependant mode
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.services.smf.edit');
    // Get lighthouse SMF data
    var _a = useSystemSmf(), data = _a.data, isLoading = _a.isLoading;
    // SMF PUT handler
    var updateSmf = useUpdateSystemSmf();
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": SMF_FORM_LOADER_ID });
    }
    if (data) {
        innerNode = _jsx(SmfForm, { data: data, permissions: { hasEditPermission: hasEditPermission }, submitHandler: updateSmf });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.smf.title') })), _jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '50px' }, color: "warning" }), warningMessage: _jsx(Typography, { children: t('pages.smf.warning_message_main') }), warningColor: "warning", bannerCode: "smf-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }), innerNode] })));
}, { rights: ['settings.services.smf.view'] });
