var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { SelectFirmwareTable } from './select-firmware-table';
import { FileManagerUploader } from '../../file-manager/file-manager-uploader';
import { AvailableSpace } from '../../file-manager/available-space';
export function SelectFirmwareModalContent(_a) {
    var onClose = _a.onClose, onFormChange = _a.onFormChange;
    var t = useTranslation().t;
    var _b = __read(useState(), 2), localSelectedFirmware = _b[0], setLocalSelectedFirmware = _b[1];
    var handleFirmwareUpload = function (firmware) {
        setLocalSelectedFirmware(firmware);
    };
    var handleSubmit = useCallback(function () {
        if (localSelectedFirmware) {
            onFormChange(localSelectedFirmware);
        }
        onClose();
    }, [onClose, localSelectedFirmware, onFormChange]);
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, { children: t('pages.firmware_upgrade_new.select_firmware_modal.title') }), _jsxs(DialogContent, { children: [_jsx(Box, __assign({ marginTop: 1 }, { children: _jsx(FileManagerUploader, { onSuccess: handleFirmwareUpload, autoFocus: true }) })), _jsx(SelectFirmwareTable, { selectedFirmware: localSelectedFirmware, setSelectedFirmware: setLocalSelectedFirmware }), _jsx(AvailableSpace, {})] }), _jsxs(DialogActions, __assign({ sx: { paddingTop: 1 } }, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: onClose }, { children: t('global.buttons.close') })), _jsx(OGButton, __assign({ disabled: !localSelectedFirmware, onClick: handleSubmit }, { children: t('pages.firmware_upgrade_new.select_firmware_modal.buttons.select_firmware') }))] }))] }));
}
