var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef } from 'react';
import { OGButton } from '@opengear/buttons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { IQueryDataType, IQueryType } from '../../../../types/query/types';
import { QueryItem } from './query-item';
import { useKeyCache } from '@/utils/keys';
var nestedToggleSX = {
    backgroundColor: 'background.section',
    paddingX: 2,
    paddingY: 1,
};
var toggleSX = {
    paddingY: 1,
};
export function FilterQuery(_a) {
    var query = _a.query, queryFields = _a.queryFields, onChange = _a.onChange, tags = _a.tags, tagValues = _a.tagValues, setError = _a.setError;
    var t = useTranslation().t;
    var _b = __read(useKeyCache(), 2), getKey = _b[0], removeKey = _b[1];
    var numberOfQueryItems = useMemo(function () { return query.items[0].items.reduce(function (total, _a) {
        var items = _a.items;
        return total + items.length;
    }, 0); }, [query]);
    var hasAddedCriteria = useRef(false);
    var lastQueryFieldInputEl = useCallback(function (queryFieldInputEl) {
        if (queryFieldInputEl && hasAddedCriteria.current) {
            queryFieldInputEl.focus();
        }
    }, []);
    var createAddHandler = useCallback(function (index) {
        // if index is supplied append to that OR query else the last OR query
        var orIndex = index !== null && index !== void 0 ? index : query.items[0].items.length - 1;
        return function () {
            onChange({
                items: [
                    {
                        items: query.items[0].items.map(function (queryItems, _orIndex) {
                            if (_orIndex === orIndex) {
                                return {
                                    items: queryItems.items.concat({
                                        type: IQueryType.basic,
                                        datatype: IQueryDataType.string,
                                    }),
                                    type: IQueryType.and,
                                };
                            }
                            return queryItems;
                        }),
                        type: IQueryType.or,
                    },
                ],
                type: IQueryType.and,
            });
            hasAddedCriteria.current = true;
        };
    }, [onChange, query]);
    var createClearHandler = useCallback(function (orIndex, andIndex, keyIndex) {
        // if the indexes point to an OR query with a single and condition, remove the OR query
        if (andIndex === 0 && get(query, "items.0.items.".concat(orIndex, ".items"), []).length === 1) {
            return function () {
                removeKey(keyIndex);
                onChange({
                    items: [
                        {
                            items: query.items[0].items.filter(function (_, _orIndex) { return _orIndex !== orIndex; }),
                            type: IQueryType.or,
                        },
                    ],
                    type: IQueryType.and,
                });
            };
        }
        return function () {
            removeKey(keyIndex);
            onChange({
                items: [
                    {
                        items: query.items[0].items.map(function (queryItems, _orIndex) {
                            if (_orIndex === orIndex) {
                                return {
                                    items: queryItems.items.filter(function (_, _andIndex) { return andIndex !== _andIndex; }),
                                    type: IQueryType.and,
                                };
                            }
                            return queryItems;
                        }),
                        type: IQueryType.or,
                    },
                ],
                type: IQueryType.and,
            });
        };
    }, [onChange, query, removeKey]);
    var createAndClickHandler = useCallback(function (orIndex) {
        // convert an OR toggle button to an AND toggle button by concatenating the adjoining AND queries into one
        return function () {
            onChange({
                items: [
                    {
                        items: query.items[0].items.reduce(function (current, queryItems, _orIndex) {
                            if (orIndex === _orIndex + 1) {
                                return current;
                            }
                            if (orIndex === _orIndex) {
                                var lastQueryItems = query.items[0].items[_orIndex - 1];
                                return current.concat({
                                    items: __spreadArray(__spreadArray([], __read(lastQueryItems.items), false), __read(queryItems.items), false),
                                    type: IQueryType.and,
                                });
                            }
                            return current.concat(queryItems);
                        }, []),
                        type: IQueryType.or,
                    },
                ],
                type: IQueryType.and,
            });
        };
    }, [onChange, query]);
    var createOrClickHandler = useCallback(function (orIndex, andIndex) {
        // convert an AND toggle button to an OR toggle button by splitting the AND query
        return function () {
            onChange({
                items: [
                    {
                        items: query.items[0].items.reduce(function (current, queryItems, _orIndex) {
                            if (orIndex === _orIndex) {
                                return current.concat({
                                    items: queryItems.items.slice(0, andIndex),
                                    type: IQueryType.and,
                                }, {
                                    items: queryItems.items.slice(andIndex),
                                    type: IQueryType.and,
                                });
                            }
                            return current.concat(queryItems);
                        }, []),
                        type: IQueryType.or,
                    },
                ],
                type: IQueryType.and,
            });
        };
    }, [onChange, query]);
    var createUpdateHandler = useCallback(function (orIndex, andIndex) {
        return function (partialQueryItem) {
            onChange({
                items: [
                    {
                        items: query.items[0].items.map(function (queryItems, _orIndex) {
                            if (_orIndex === orIndex) {
                                return {
                                    items: queryItems.items.map(function (queryItem, _andIndex) {
                                        if (_andIndex === andIndex) {
                                            return __assign(__assign({}, queryItem), partialQueryItem);
                                        }
                                        return queryItem;
                                    }),
                                    type: IQueryType.and,
                                };
                            }
                            return queryItems;
                        }),
                        type: IQueryType.or,
                    },
                ],
                type: IQueryType.and,
            });
        };
    }, [query, onChange]);
    var queryItemIndex = 0;
    var typeToggleIndex = 0;
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "column", marginBottom: 2 }, { children: [query.items[0].items.reduce(function (nodes, _a, orIndex) {
                var items = _a.items;
                var nested = items.length > 1 && items.length < numberOfQueryItems;
                if (orIndex > 0) {
                    nodes.push(
                    // eslint-disable-next-line no-plusplus
                    _jsxs(ToggleButtonGroup, __assign({ value: IQueryType.or, sx: toggleSX }, { children: [_jsx(ToggleButton, __assign({ value: IQueryType.and, onClick: createAndClickHandler(orIndex) }, { children: t('components.query.type.and') })), _jsx(ToggleButton, __assign({ value: IQueryType.or }, { children: t('components.query.type.or') }))] }), "type-toggle-".concat(typeToggleIndex++)));
                }
                items.forEach(function (queryItem, andIndex) {
                    if (andIndex > 0) {
                        nodes.push(
                        // eslint-disable-next-line no-plusplus
                        _jsxs(ToggleButtonGroup, __assign({ value: IQueryType.and, sx: nested ? nestedToggleSX : toggleSX }, { children: [_jsx(ToggleButton, __assign({ value: IQueryType.and }, { children: t('components.query.type.and') })), _jsx(ToggleButton, __assign({ value: IQueryType.or, onClick: createOrClickHandler(orIndex, andIndex) }, { children: t('components.query.type.or') }))] }), "type-toggle-".concat(typeToggleIndex++)));
                    }
                    var isLastItem = queryItemIndex + 1 === numberOfQueryItems;
                    nodes.push(_jsx(QueryItem, { query: queryItem, queryFields: queryFields, queryFieldRef: isLastItem ? lastQueryFieldInputEl : undefined, sx: nested
                            ? {
                                backgroundColor: 'background.section',
                                paddingBottom: andIndex + 1 === items.length ? 2 : undefined,
                                paddingTop: andIndex === 0 ? 2 : undefined,
                                paddingX: 2,
                            }
                            : undefined, tags: tags, tagValues: tagValues, onChange: createUpdateHandler(orIndex, andIndex), onClear: numberOfQueryItems !== 1 ? createClearHandler(orIndex, andIndex, queryItemIndex) : undefined, setError: setError }, getKey(queryItemIndex)));
                    queryItemIndex += 1;
                });
                return nodes;
            }, []), _jsx(OGButton, __assign({ startIcon: _jsx(AddCircleOutlineIcon, {}), variant: "secondary", sx: { width: 'fit-content', marginTop: 1 }, onClick: createAddHandler() }, { children: t('components.query.add_criteria.label') }))] })));
}
