var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useId, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import { useLocation } from 'react-router-dom';
import { OG_BUTTON_PRIMARY, OG_BLACK, OG_WHITE, OG_GREY_VVVL } from '@opengear/theme/colors';
import { OGNavMenuItem } from './nav-menu-item';
export function OGNavMainMenuSection(_a) {
    var children = _a.children, icon = _a.icon, id = _a.id, prefixes = _a.prefixes, sx = _a.sx, _b = _a.tabIndex, tabIndex = _b === void 0 ? 0 : _b, text = _a.text, collapseProps = _a.collapseProps, props = __rest(_a, ["children", "icon", "id", "prefixes", "sx", "tabIndex", "text", "collapseProps"]);
    var uuid = useId();
    var theme = useTheme();
    var componentId = id !== null && id !== void 0 ? id : uuid;
    var menuToggleId = "".concat(componentId, "-menu-toggle");
    var menuId = "".concat(componentId, "-menu");
    var pathname = useLocation().pathname;
    var selected = useMemo(function () { return prefixes.some(function (prefix) { return pathname.startsWith(prefix); }); }, [pathname, prefixes]);
    var _c = __read(useState(selected), 2), open = _c[0], setOpen = _c[1];
    var handleClick = useCallback(function () {
        setOpen(function (state) { return !state; });
    }, [setOpen]);
    var ariaLabel = useMemo(function () { return "".concat(text, ", ").concat(open ? 'expanded' : 'collapsed'); }, [text, open]);
    // If all link items are falsy, then don't show entire menu section
    if (!children || (Array.isArray(children) && children.every(function (node) { return !node; })))
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs(OGNavMenuItem, __assign({ "aria-controls": menuId, "aria-haspopup": "true", "aria-expanded": open ? 'true' : 'false', "aria-label": ariaLabel, selected: selected, sx: __assign({ 'minWidth': 'unset', 'paddingLeft': icon ? 1 : 2, 'paddingY': 0.75, 'marginY': theme.spacing(0.25), 'backgroundColor': theme.palette.mode === 'light' ? OG_WHITE : OG_BLACK, 'textWrap': 'wrap', '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus-visible': {
                        backgroundColor: OG_BUTTON_PRIMARY,
                        color: theme.palette.mode === 'light' ? OG_GREY_VVVL : 'inherit',
                    } }, sx), tabIndex: tabIndex }, props, { id: menuToggleId, onClick: handleClick }, { children: [icon && _jsx(Box, __assign({ sx: { marginRight: 1 } }, { children: icon })), _jsx(Typography, __assign({ color: "inherit", component: "span", fontWeight: "inherit", lineHeight: "inherit" }, { children: text })), _jsx(Box, { flex: 1 }), open ? _jsx(ExpandLessIcon, { fontSize: "small" }) : _jsx(ExpandMoreIcon, { fontSize: "small" })] })), _jsx(Collapse, __assign({ id: menuId, in: open, timeout: "auto", unmountOnExit: true }, collapseProps, { children: _jsx(List, __assign({ role: "menu", disablePadding: true }, { children: children })) }))] }));
}
