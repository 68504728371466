import { screen } from '@testing-library/react';
import { t } from 'i18next';
var testUtils = {
    activeLabel: {
        default: new RegExp(t('components.file_uploader.labels.active')),
        get: function (label) {
            return screen.getByText(label ? new RegExp(label) : this.default);
        },
        query: function (label) {
            return screen.queryByText(label ? new RegExp(label) : this.default);
        },
    },
    linkLabel: {
        default: new RegExp(t('components.file_uploader.labels.link')),
        get: function (label) {
            return screen.getByText(label ? new RegExp(label) : this.default);
        },
        query: function (label) {
            return screen.queryByText(label ? new RegExp(label) : this.default);
        },
    },
    mainLabel: {
        default: new RegExp(t('components.file_uploader.labels.main')),
        get: function (label) {
            return screen.getByText(label ? new RegExp(label) : this.default);
        },
        query: function (label) {
            return screen.queryByText(label ? new RegExp(label) : this.default);
        },
    },
    supportedFilesLabel: {
        default: new RegExp(t('components.file_uploader.labels.supported_files')),
        get: function (label) {
            return screen.getByText(label ? new RegExp(label) : this.default);
        },
    },
    acceptText: {
        get: function (accept) {
            return screen.getByText(new RegExp(accept));
        },
    },
    dropArea: {
        get: function (label) {
            return screen.getByLabelText(label ? new RegExp(label) : testUtils.mainLabel.default, { selector: 'label' });
        },
        query: function (label) {
            return screen.queryByLabelText(label ? new RegExp(label) : testUtils.mainLabel.default, { selector: 'label' });
        },
    },
    hiddenInput: {
        get: function (label) {
            return screen.getByLabelText(label ? new RegExp(label) : testUtils.mainLabel.default, { selector: 'input' });
        },
    },
    selectFileButton: {
        get: function () {
            return screen.getByRole('button', { name: t('components.file_uploader.labels.link') });
        },
    },
};
export default testUtils;
