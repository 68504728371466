var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLoginRestrictions, useUpdateLoginRestrictions } from '@/api/users/login-restrictions';
import { UpdateForm } from '@/components/layout/update-form';
import { useFormSync } from '@/hooks/use-form-sync';
import { requiredFieldError, minValueFieldError, maxValueFieldError } from '@/utils/field-validation-errors';
export function LoginRestrictionsForm(_a) {
    var _this = this;
    var data = _a.data, editable = _a.editable;
    var MaxLoginMinValue = 1;
    var MaxLoginMaxValue = 2147483647;
    var LockoutPeriodMinValue = 1;
    var LockoutPeriodMaxValue = 35791394;
    var t = useTranslation().t;
    var isFetching = useLoginRestrictions().isFetching;
    var sourceValues = useMemo(function () { return ({
        enabled: data.login_restrictions.enabled,
        login_lockout_period: data.login_restrictions.login_lockout_period.toString(),
        max_login_attempts: data.login_restrictions.max_login_attempts.toString(),
    }); }, [data]);
    var _b = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _b.control, handleSubmit = _b.handleSubmit, _c = _b.formState, defaultValues = _c.defaultValues, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting, isValid = _c.isValid, reset = _b.reset;
    var updateLoginRestrictions = useUpdateLoginRestrictions();
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var loginLockoutPeriod, maxLoginAttempts, updatedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loginLockoutPeriod = formData.login_lockout_period.toString();
                    maxLoginAttempts = formData.max_login_attempts.toString();
                    return [4 /*yield*/, updateLoginRestrictions({
                            enabled: formData.enabled,
                            login_lockout_period: parseInt(loginLockoutPeriod, 10),
                            max_login_attempts: parseInt(maxLoginAttempts, 10),
                        })];
                case 1:
                    updatedData = _a.sent();
                    if (updatedData) {
                        reset({
                            enabled: updatedData.login_restrictions.enabled,
                            login_lockout_period: updatedData.login_restrictions.login_lockout_period.toString(),
                            max_login_attempts: updatedData.login_restrictions.max_login_attempts.toString(),
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [updateLoginRestrictions, reset]);
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    // responsible for alert the user of data changes
    useFormSync(defaultValues, sourceValues, reset, t('pages.local_auth_policy.form.reset_warning.login.header'), isFetching);
    return (_jsxs(UpdateForm, __assign({ "aria-label": t('pages.local_auth_policy.tabs.login_restrictions.form'), dirty: isDirty, submitting: isSubmitting, valid: isValid, onReset: handleReset, onSubmit: handleSubmit(onSubmit), editable: !!editable }, { children: [_jsxs(Box, __assign({ sx: {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 2,
                    marginBottom: 2,
                } }, { children: [_jsx(ReportProblemIcon, { "aria-hidden": "false", "aria-label": t('global.icon.warning.aria_label'), color: "warning" }), _jsx(Typography, { children: t('pages.local_auth_policy.tabs.login_restrictions.warning') })] })), _jsxs(FormGroup, { children: [_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
                            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                            return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !editable, value: value, onChange: function (event, val) {
                                    if (val !== null) {
                                        onChange(val);
                                    }
                                } }, field, { children: [_jsx(ToggleButton, __assign({ value: false, className: value === false ? 'Mui-selected' : '' }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true, className: value === true ? 'Mui-selected' : '' }, { children: t('global.buttons.enabled.enabled') }))] })));
                        } }), _jsxs(Stack, __assign({ direction: "column", gap: 1, marginTop: 1, marginBottom: 0.5, role: "group", "aria-label": t('pages.local_auth_policy.tabs.login_restrictions.form') }, { children: [_jsx(Controller, { control: control, name: "max_login_attempts", rules: {
                                    required: requiredFieldError(t('pages.local_auth_policy.form.fields.max_login_attempts.label')),
                                    min: {
                                        value: MaxLoginMinValue,
                                        message: minValueFieldError(t('pages.local_auth_policy.form.fields.max_login_attempts.label'), MaxLoginMinValue.toString()),
                                    },
                                    max: {
                                        value: MaxLoginMaxValue,
                                        message: maxValueFieldError(t('pages.local_auth_policy.form.fields.max_login_attempts.label'), MaxLoginMaxValue.toString()),
                                    },
                                }, render: function (_a) {
                                    var field = _a.field, error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ disabled: !editable, required: true, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.local_auth_policy.form.fields.max_login_attempts.label'), type: "number", sx: {
                                            maxWidth: 300,
                                        }, inputProps: {
                                            min: MaxLoginMinValue,
                                            max: MaxLoginMaxValue,
                                        } }, field)));
                                } }), _jsx(Controller, { control: control, name: "login_lockout_period", rules: {
                                    required: requiredFieldError(t('pages.local_auth_policy.form.fields.login_lockout_period.label')),
                                    min: {
                                        value: LockoutPeriodMinValue,
                                        message: minValueFieldError(t('pages.local_auth_policy.form.fields.login_lockout_period.label'), LockoutPeriodMinValue.toString()),
                                    },
                                    max: {
                                        value: LockoutPeriodMaxValue,
                                        message: maxValueFieldError(t('pages.local_auth_policy.form.fields.login_lockout_period.label'), LockoutPeriodMaxValue.toString()),
                                    },
                                }, render: function (_a) {
                                    var field = _a.field, error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ disabled: !editable, required: true, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.local_auth_policy.form.fields.login_lockout_period.label'), type: "number", sx: {
                                            maxWidth: 300,
                                        }, inputProps: {
                                            min: LockoutPeriodMinValue,
                                            max: LockoutPeriodMaxValue,
                                        } }, field)));
                                } })] }))] })] })));
}
