var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { OGButton } from '@opengear/buttons';
import { useTheme } from '@mui/material/styles';
import { useTemplatePushExecContext } from '@/api/template-push/template-push';
import { useDocTitle } from '@/hooks/doc-title';
import { CONFIG_TEMPLATES_ROUTE } from '@/constants/routes';
import { TemplatePreviewSection, TemplatePushStepper } from '@/containers/config/templates/push/template-push-components';
import { useNodes } from '@/api/nodes';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { nodeIdsToQuery, useJson } from '@/utils/query';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import TemplatePushTable from '@/containers/config/templates/push/template-push-table';
export var TEMPLATE_PUSH_LOADER_TEST_ID = 'preflight-loading';
function PushStatusPageContent(_a) {
    var contextId = _a.contextId;
    var t = useTranslation().t;
    var navigate = useNavigate();
    // Refetch context every second until the push is complete
    var _b = __read(useState(1000), 2), refetchInterval = _b[0], setRefetchInterval = _b[1];
    var contextData = useTemplatePushExecContext(contextId, { refetchInterval: refetchInterval }).data;
    var theme = useTheme();
    var nodeIds = (contextData === null || contextData === void 0 ? void 0 : contextData.node_contexts.map(function (node_ctx) { return node_ctx.node_id; })) || [];
    var nodeJson = useJson(nodeIdsToQuery(nodeIds));
    var nodeData = useNodes({ json: nodeJson }, { enabled: !!nodeIds }).data;
    useEffect(function () {
        if (contextData && contextData.status === 'execute_complete') {
            setRefetchInterval(false);
        }
    }, [contextData]);
    if (!contextData || !nodeData)
        return _jsx(LoadingBackdrop, { "data-testid": TEMPLATE_PUSH_LOADER_TEST_ID });
    return (_jsxs(_Fragment, { children: [_jsx(Paper, { children: _jsx(TemplatePushStepper, { step: contextData.status === 'execute_complete' ? 3 : 2 }) }), _jsx(Paper, __assign({ sx: { marginTop: 2 } }, { children: _jsxs(Stack, __assign({ display: "flex", direction: "row", flexBasis: 0 }, { children: [_jsx(Box, __assign({ flexGrow: 1 }, { children: _jsx(TemplatePreviewSection, { templateId: contextData.templates[0] }) })), _jsxs(Box, __assign({ flexGrow: 2, borderLeft: "1px solid ".concat(theme.palette.divider), minWidth: 0 }, { children: [_jsx(Box, __assign({ padding: 2, minWidth: 0 }, { children: _jsx(TemplatePushTable, { nodeContexts: contextData.node_contexts, nodeData: nodeData.nodes }) })), _jsx(Stack, __assign({ spacing: 1, padding: 2, borderTop: "1px solid ".concat(theme.palette.divider), direction: "row", display: "flex", justifyContent: "flex-end" }, { children: _jsx(OGButton, __assign({ variant: "secondary", onClick: function () { return navigate(CONFIG_TEMPLATES_ROUTE); } }, { children: t('pages.config_templates.push.exit') })) }))] }))] })) }))] }));
}
function TemplatePushStatusPage() {
    var t = useTranslation().t;
    useDocTitle(t('pages.config_templates.push.title'));
    var locationState = useLocation().state;
    var navigate = useNavigate();
    useEffect(function () {
        if (locationState === null || !locationState.contextId) {
            navigate(CONFIG_TEMPLATES_ROUTE);
        }
    }, [locationState, navigate]);
    var innerNode = _jsx(LoadingBackdrop, { "data-testid": TEMPLATE_PUSH_LOADER_TEST_ID });
    if (locationState === null || locationState === void 0 ? void 0 : locationState.contextId) {
        innerNode = _jsx(PushStatusPageContent, { contextId: locationState.contextId });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.config_templates.push.title') })), innerNode] })));
}
export default withAuthenticationRequired(TemplatePushStatusPage, { rights: ['configure.configuration_templating.apply_templates.push'] });
