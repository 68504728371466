import { cidrToSubnetMask, subnetMaskToCIDR } from '@/utils/calculations';
/**
 * Transforms a lipy LHVPN response object into a LighthouseVPNFormState object
 */
export var transformResponseToFormState = function (data) { return ({
    address: data.lhvpn.address,
    cidr: subnetMaskToCIDR(data.lhvpn.mask),
    mtu: data.lhvpn.mtu.toString(),
    smf_address: data.lhvpn.smf_address,
    smf_cidr: data.lhvpn.smf_mask,
    smf_mtu: data.lhvpn.mtu.toString(),
}); };
/**
 * Transforms a LighthouseVPNFormState object into a lipy LHVPN request object
 */
export var transformFormStateToRequest = function (formData) {
    var mask = cidrToSubnetMask(formData.cidr);
    var mtu = parseInt(formData.mtu, 10);
    return {
        lhvpn: {
            address: formData.address,
            mask: mask,
            mtu: mtu,
            smf_address: formData.smf_address,
            smf_mask: formData.smf_cidr,
        },
    };
};
