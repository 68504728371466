var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGLogIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 18 18' }, props), { children: [_jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M0.5 4.31812C0.5 3.76583 0.947715 3.31812 1.5 3.31812H14.5303C15.0826 3.31812 15.5303 3.76583 15.5303 4.31812V15C15.5303 15.5522 15.0826 16 14.5303 16H1.5C0.947715 16 0.5 15.5522 0.5 15V4.31812Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M8.95454 11.5848C8.95454 12.363 8.32367 12.9939 7.54545 12.9939C6.76723 12.9939 6.13635 12.363 6.13635 11.5848V8.76664C6.13635 7.98842 6.76723 7.35754 7.54545 7.35754C8.32367 7.35754 8.95454 7.98842 8.95454 8.76664V11.5848Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M13.6516 9.23633V8.76664C13.6516 7.98842 13.0207 7.35754 12.2425 7.35754C11.4642 7.35754 10.8334 7.98842 10.8334 8.76664V11.5848C10.8334 12.363 11.4642 12.9939 12.2425 12.9939C13.0207 12.9939 13.6516 12.363 13.6516 11.5848V11.1151H12.7122", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M2.37878 7.35754V12.0545C2.37878 12.5733 2.79937 12.9939 3.31818 12.9939H4.72727", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.6295 1.8236L17.2766 1.8236L17.2766 4.54785", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
