export var transformResponseToFormState = function (responseData) { return ({
    authStyle: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.authStyle) || 'auth_down_local',
    ldapAuthenticationServers: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapAuthenticationServers.length) ? responseData.auth.ldapAuthenticationServers : [{ hostname: '', port: null }],
    ldapBaseDN: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapBaseDN) || '',
    ldapBindDN: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapBindDN) || '',
    ldapGroupMembershipAttribute: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapGroupMembershipAttribute) || '',
    ldapIgnoreReferrals: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapIgnoreReferrals) || false,
    ldapSslCaCert: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapSslCaCert) || '',
    ldapSslIgnoreCertErrors: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapSslIgnoreCertErrors) || false,
    ldapSslMode: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapSslMode) || 'ldaps_preferred',
    ldapUsernameAttribute: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.ldapUsernameAttribute) || '',
    radiusAuthenticationServers: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.radiusAuthenticationServers.length)
        ? responseData.auth.radiusAuthenticationServers
        : [{ hostname: '', port: 1812 }],
    radiusAccountingServers: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.radiusAccountingServers) || [],
    tacacsService: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.tacacsService) || '',
    tacacsAuthenticationServers: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.tacacsAuthenticationServers.length)
        ? responseData.auth.tacacsAuthenticationServers
        : [{ hostname: '', port: 49 }],
    tacacsMethod: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.tacacsMethod) || 'pap',
    mode: (responseData === null || responseData === void 0 ? void 0 : responseData.auth.mode) || 'local',
    radiusPassword: '',
    tacacsPassword: '',
    ldapBindPassword: '',
    confirmLdapBindPassword: '',
    confirmRadiusPassword: '',
    confirmTacacsPassword: '',
}); };
export var transformToRequestObject = function (formData) {
    var _a, _b, _c;
    return ({
        auth: {
            authStyle: (formData === null || formData === void 0 ? void 0 : formData.authStyle) || 'auth_down_local',
            ldapAuthenticationServers: ((_a = formData === null || formData === void 0 ? void 0 : formData.ldapAuthenticationServers[0]) === null || _a === void 0 ? void 0 : _a.hostname) !== '' ? formData.ldapAuthenticationServers : [],
            ldapBaseDN: (formData === null || formData === void 0 ? void 0 : formData.ldapBaseDN) || null,
            ldapBindDN: (formData === null || formData === void 0 ? void 0 : formData.ldapBindDN) || null,
            ldapGroupMembershipAttribute: (formData === null || formData === void 0 ? void 0 : formData.ldapGroupMembershipAttribute) || null,
            ldapIgnoreReferrals: (formData === null || formData === void 0 ? void 0 : formData.ldapIgnoreReferrals) || false,
            ldapSslCaCert: (formData === null || formData === void 0 ? void 0 : formData.ldapSslCaCert) || null,
            ldapSslIgnoreCertErrors: (formData === null || formData === void 0 ? void 0 : formData.ldapSslIgnoreCertErrors) || false,
            ldapSslMode: (formData === null || formData === void 0 ? void 0 : formData.ldapSslMode) || null,
            ldapUsernameAttribute: (formData === null || formData === void 0 ? void 0 : formData.ldapUsernameAttribute) || null,
            radiusAuthenticationServers: ((_b = formData === null || formData === void 0 ? void 0 : formData.radiusAuthenticationServers[0]) === null || _b === void 0 ? void 0 : _b.hostname) !== '' ? formData.radiusAuthenticationServers : [],
            radiusAccountingServers: (formData === null || formData === void 0 ? void 0 : formData.radiusAccountingServers) || [],
            tacacsService: (formData === null || formData === void 0 ? void 0 : formData.tacacsService) || null,
            tacacsAuthenticationServers: ((_c = formData === null || formData === void 0 ? void 0 : formData.tacacsAuthenticationServers[0]) === null || _c === void 0 ? void 0 : _c.hostname) !== '' ? formData.tacacsAuthenticationServers : [],
            tacacsMethod: (formData === null || formData === void 0 ? void 0 : formData.tacacsMethod) || null,
            mode: (formData === null || formData === void 0 ? void 0 : formData.mode) || 'local',
            radiusPassword: (formData === null || formData === void 0 ? void 0 : formData.radiusPassword) || null,
            tacacsPassword: (formData === null || formData === void 0 ? void 0 : formData.tacacsPassword) || null,
            ldapBindPassword: (formData === null || formData === void 0 ? void 0 : formData.ldapBindPassword) || null,
        },
    });
};
