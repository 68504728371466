var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { OGLink } from '@opengear/links';
import { UNICODE_CHARS } from '@/constants/unicode';
export var QUERY_PARAM_GROUPNAME_KEY = 'groupname';
export function GroupLinks(_a) {
    var groups = _a.groups;
    return groups.length > 0 ? (_jsx(Box, __assign({ sx: { width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' } }, { children: groups.map(function (group, index) {
            var _a;
            return (_jsxs(Box, __assign({ component: "span", sx: { display: 'flex', justifyContent: 'space-between' } }, { children: [_jsx(OGLink, __assign({ variant: "standard", to: "?".concat(new URLSearchParams((_a = {}, _a[QUERY_PARAM_GROUPNAME_KEY] = group, _a))) }, { children: group })), index < groups.length - 1 && ",".concat(UNICODE_CHARS.noBreakSpace)] }), group));
        }) }))) : null;
}
