var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useRef } from 'react';
import { v4 } from 'uuid';
/**
 * The purpose of this hook is to supply a way of mapping integer indexes to unique keys for rendering React components
 * in an array. It uses the React 'useRef' API internally because we do not want to trigger a rerender when its cache
 * updates. See './keys.test.ts' for usage.
 */
export function useKeyCache() {
    var keyCacheRef = useRef([]);
    var getKey = useCallback(function (index) {
        if (!Number.isInteger(index)) {
            return undefined;
        }
        if (!keyCacheRef.current[index]) {
            keyCacheRef.current[index] = v4();
        }
        return keyCacheRef.current[index];
    }, [keyCacheRef]);
    var removeKey = useCallback(function (index) {
        if (Number.isInteger(index) && keyCacheRef.current[index]) {
            keyCacheRef.current = __spreadArray(__spreadArray([], __read(keyCacheRef.current.slice(0, index)), false), __read(keyCacheRef.current.slice(index + 1)), false);
        }
    }, [keyCacheRef]);
    return [getKey, removeKey];
}
