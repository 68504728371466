var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { OGButton } from '@opengear/buttons';
import RemoveIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useLocalStorageState from 'use-local-storage-state';
function useHandleDismissBanner(bannerCode, dismissPeriod) {
    var isBannerDismissed = false;
    var _a = __read(useLocalStorageState("dismissDate_".concat(bannerCode)), 2), localStorageDismissDate = _a[0], setLocalStorageDismissDate = _a[1];
    var _b = __read(useLocalStorageState("dismissPeriod_".concat(bannerCode)), 2), localStorageDismissPeriod = _b[0], setLocalStorageDismissPeriod = _b[1];
    var onDismissCallback = useCallback(function () {
        setLocalStorageDismissDate(Date.now());
        setLocalStorageDismissPeriod(dismissPeriod);
    }, [setLocalStorageDismissDate, setLocalStorageDismissPeriod, dismissPeriod]);
    if (localStorageDismissPeriod !== undefined && localStorageDismissDate !== undefined) {
        if (localStorageDismissPeriod === -1 || Date.now() - localStorageDismissDate <= localStorageDismissPeriod) {
            // Hide banner
            isBannerDismissed = true;
        }
    }
    return { isBannerDismissed: isBannerDismissed, onDismissCallback: onDismissCallback };
}
export function WarningBanner(_a) {
    var warningIcon = _a.warningIcon, warningHeader = _a.warningHeader, warningMessage = _a.warningMessage, warningColor = _a.warningColor, dismissable = _a.dismissable, bannerCode = _a.bannerCode, _b = _a.dismissPeriod, dismissPeriod = _b === void 0 ? -1 : _b, sxProps = _a.sxProps;
    var t = useTranslation().t;
    var handleDismiss = useHandleDismissBanner(bannerCode, dismissPeriod);
    var _c = __read(useState('alert'), 2), role = _c[0], setRole = _c[1];
    useEffect(function () {
        setRole('status');
    }, []);
    return handleDismiss.isBannerDismissed ? null : (_jsx(Paper, __assign({ role: role, sx: __assign({ margin: 2, padding: 2, borderLeftWidth: 2, borderLeftColor: warningColor ? "".concat(warningColor, ".main") : 'transparent', borderLeftStyle: 'solid' }, sxProps) }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 1 }, { children: [warningIcon, _jsxs(Stack, __assign({ justifyContent: "center" }, { children: [warningHeader && (_jsx(Typography, __assign({ component: "h3", variant: "h3", sx: {
                                        textTransform: 'none',
                                        marginTop: 0.5,
                                        marginBottom: 0.5,
                                    } }, { children: warningHeader }))), warningMessage] }))] })), dismissable && (_jsx(Box, { children: _jsx(OGButton, { "aria-label": t('global.buttons.close'), onClick: handleDismiss.onDismissCallback, startIcon: _jsx(RemoveIcon, {}), variant: "borderless" }) }))] })) })));
}
