var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { OGDonut } from '@opengear/donut';
import { OGPanel } from '@opengear/panel';
import { OGCellularIcon } from '@opengear/icons/connections/cellular';
import { useStatsNodesCellHealthSummary } from '@/api/stats/nodes/cell-health-summary';
import { useDeviceView } from '@/hooks/use-device-view';
import { withAuthorizationRequired } from '@/hocs/authorization-required';
import { useBuildCellHealthDonutData, cellHealthListIcons } from './cell-health-panel-utils';
import { DonutLabel, DonutList } from '../panel-components';
var CELL_HEALTH_PANEL_TITLE_ID = 'cell-health-panel-title';
export var CellHealthPanel = withAuthorizationRequired(function () {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useStatsNodesCellHealthSummary(), data = _a.data, isLoading = _a.isLoading;
    var _b = useBuildCellHealthDonutData(data === null || data === void 0 ? void 0 : data.cellularHealthSummary), seriesData = _b.seriesData, seriesTotal = _b.seriesTotal, summaryTotal = _b.summaryTotal;
    var panelInfo = null;
    if (isLoading) {
        panelInfo = _jsx(CircularProgress, { "aria-labelledby": CELL_HEALTH_PANEL_TITLE_ID, color: "inherit" });
    }
    else if (!seriesTotal) {
        panelInfo = _jsx(Typography, __assign({ fontWeight: 400 }, { children: t('pages.dashboard.nodes.cellular_health.no_cell_health') }));
    }
    else {
        panelInfo = _jsx(DonutList, { items: seriesData.map(function (seriesItem) { return (__assign(__assign({}, seriesItem), { icon: cellHealthListIcons[seriesItem.id] })); }) });
    }
    return (_jsxs(OGPanel, { children: [_jsx(Typography, __assign({ id: CELL_HEALTH_PANEL_TITLE_ID, variant: "h3", marginBottom: 2 }, { children: t('pages.dashboard.nodes.cellular_health.title') })), _jsxs(Box, __assign({ display: "flex", flexDirection: isMobileView ? 'column' : 'row', alignItems: isMobileView ? 'start' : 'center', gap: isMobileView ? 2 : 4, height: "100%", paddingY: 2 }, { children: [_jsx(OGDonut, __assign({ data: seriesData, isEmpty: !seriesTotal, size: 140 }, { children: _jsx(DonutLabel, { IconComponent: OGCellularIcon, textProps: { 'aria-label': t('pages.dashboard.nodes.cellular_health.total_count_label'), 'children': summaryTotal } }) })), _jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [_jsx(Divider, { flexItem: true, orientation: "vertical", sx: { marginRight: 4, minHeight: isMobileView ? 'auto' : 120 } }), panelInfo] }))] }))] }));
}, { rights: ['configure.node_enrollment.enrolled_nodes.view_cell_health'] });
