var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import RolesTable from '@/components/accounts/roles/roles-table';
import GroupsTable from '@/components/accounts/groups/groups-table';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { GROUPS_ROLES_ROUTE, GROUPS_ROUTE, ROLES_ROUTE } from '@/constants/routes';
var TabValue;
(function (TabValue) {
    TabValue["GROUPS"] = "GROUPS";
    TabValue["ROLES"] = "ROLES";
})(TabValue || (TabValue = {}));
function GroupsRoles() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useDocTitle(t('pages.groups_roles.title'));
    var hasViewAccess = hasPermission('settings.user_management.permissions.view');
    var tabValue = useMemo(function () { return (pathname.endsWith(ROLES_ROUTE) ? TabValue.ROLES : TabValue.GROUPS); }, [pathname]);
    useEffect(function () {
        if (pathname.endsWith(GROUPS_ROLES_ROUTE)) {
            navigate(GROUPS_ROUTE.slice(1), { replace: true });
        }
    }, [pathname, navigate]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.groups_roles.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: GROUPS_ROUTE.slice(1), label: t('pages.groups_roles.tabs.groups.title'), value: TabValue.GROUPS }), _jsx(Tab, { component: RouterLink, to: ROLES_ROUTE.slice(1), label: t('pages.groups_roles.tabs.roles.title'), value: TabValue.ROLES, disabled: !hasViewAccess })] })), _jsx(TabPanel, __assign({ value: TabValue.GROUPS }, { children: _jsx(GroupsTable, {}) })), _jsx(TabPanel, __assign({ value: TabValue.ROLES }, { children: _jsx(RolesTable, {}) }))] })) })] })));
}
export default withAuthenticationRequired(GroupsRoles);
