var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@mui/material/DialogContentText';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
import { useDeleteNodeTag } from '@/api/tags/node-tags';
export function DeleteNodeTagModal(_a) {
    var nodeTag = _a.nodeTag, onSuccess = _a.onSuccess;
    var t = useTranslation().t;
    var deleteNodeTag = useDeleteNodeTag();
    var onDelete = useCallback(function () { return deleteNodeTag(nodeTag.id); }, [deleteNodeTag, nodeTag.id]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('components.tags.node_tags.modal.title.delete'), deleteButtonLabel: t('global.buttons.delete'), onDelete: onDelete, onSuccess: onSuccess }, { children: _jsx(DialogContentText, { children: format(t('components.tags.node_tags.modal.message.delete'), nodeTag.id) }) })));
}
