var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import uniq from 'lodash/uniq';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { CONSOLE_GATEWAY_FORM_LOADER_ID, ConsoleGatewayForm } from '@/components/settings/services/console-gateway/console-gateway-form';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useConsoleGateway } from '@/api/services/console-gateway';
import { useInterfaces } from '@/api/interfaces';
import { useExternalEndpoints } from '@/api/system/external-endpoints';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
function ConsoleGateway() {
    var t = useTranslation().t;
    useDocTitle(t('pages.console_gateway.title'));
    // Check if user has edit permissions based on ui permissions and dependant mode
    var hasPermission = useUIPermissions().hasPermission;
    // Get console gateway data
    var _a = useConsoleGateway(), consoleGateway = _a.data, isLoadingConsoleGateway = _a.isLoading, isFetchingConsoleGateway = _a.isFetching;
    // Get interfaces data
    var _b = useInterfaces(), lhInterfaces = _b.data, isLoadingInterfaces = _b.isLoading, isFetchingInterfaces = _b.isFetching;
    // Get external_endpoints data
    var _c = useExternalEndpoints(), systemExternalEndpoints = _c.data, isLoadingExternalEndpoints = _c.isLoading, isFetchingExternalEndpoints = _c.isFetching;
    var isFetchingData = isFetchingConsoleGateway || isFetchingInterfaces || isFetchingExternalEndpoints;
    var formData = useMemo(function () {
        if (!consoleGateway || !systemExternalEndpoints || !lhInterfaces) {
            return undefined;
        }
        // Get all IP addresses from Interfaces
        var interfaceAddresses = [consoleGateway.console_gateway.ssh_url_address];
        lhInterfaces.interfaces.forEach(function (item) {
            var _a, _b;
            if (item.enabled) {
                if (item.mode === 'static' && ((_a = item.ipv4_static_settings) === null || _a === void 0 ? void 0 : _a.address)) {
                    interfaceAddresses.push(item.ipv4_static_settings.address);
                }
                else if (item.mode === 'ipv6_static' && ((_b = item.ipv6_static_settings) === null || _b === void 0 ? void 0 : _b.address)) {
                    interfaceAddresses.push(item.ipv6_static_settings.address);
                }
                else {
                    item.runtime_status.addresses.forEach(function (addr) {
                        if (addr.address) {
                            interfaceAddresses.push(addr.address);
                        }
                    });
                }
            }
        });
        // Concatenate IP addresses from external endpoints
        systemExternalEndpoints.system_external_endpoints.forEach(function (item) { return interfaceAddresses.push(item.address); });
        // return form data props
        return {
            ssh_url_delimiter: consoleGateway.console_gateway.ssh_url_delimiter,
            ssh_url_address: consoleGateway.console_gateway.ssh_url_address,
            ssh_addresses: uniq(interfaceAddresses),
        };
    }, [consoleGateway, lhInterfaces, systemExternalEndpoints]);
    var innerNode = null;
    if (isLoadingConsoleGateway || isLoadingInterfaces || isLoadingExternalEndpoints) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": CONSOLE_GATEWAY_FORM_LOADER_ID });
    }
    if (formData) {
        innerNode = _jsx(ConsoleGatewayForm, { editable: hasPermission('settings.services.console_gateway.edit'), data: formData, isFetchingData: isFetchingData });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.console_gateway.title') })), innerNode] })));
}
export default withAuthenticationRequired(ConsoleGateway, { rights: ['settings.services.console_gateway.view'] });
