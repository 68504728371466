var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useId } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import { OGNavPopupMenuHeader, OGNavPopupMenuLink } from '@opengear/navigation';
export var DIVIDER_TEST_ID = 'popup-menu-divider';
/**
 * PopupMenuSection is a pure helper component that helps to render menu links correctly
 * mainly around the complexities of user permissions
 * @param [divider] Optional divider at bottom of menu
 * @param [header] Menu header title
 * @param items Array of data to construct menu links
 */
export function PopupMenuSection(_a) {
    var divider = _a.divider, header = _a.header, items = _a.items;
    var id = useId();
    // If all link items are hidden, then don't show entire menu section
    if (items.every(function (item) { return !item.visible; }))
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: { paddingX: 0.5, paddingY: 1 } }, { children: [header ? _jsx(OGNavPopupMenuHeader, __assign({ id: id }, { children: header })) : null, _jsx(MenuList, __assign({ "aria-labelledby": id, sx: { padding: 0 } }, { children: items.map(function (_a) {
                            var onClose = _a.onClose, title = _a.title, to = _a.to, visible = _a.visible;
                            return visible && (_jsx(OGNavPopupMenuLink, __assign({ to: to, onClose: onClose }, { children: title }), title));
                        }) }))] })), divider && _jsx(Divider, { sx: { marginX: 0.5 }, "data-testid": DIVIDER_TEST_ID })] }));
}
