var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogContentText from '@mui/material/DialogContentText';
import { useBulkDeleteSmfResources } from '@/api/smf-resources/smf-resources';
import { DeleteConfirmationModal } from '../confirmation-modal';
export function SmfResourceDeleteModal(_a) {
    var smfResourceIdsToAction = _a.smfResourceIdsToAction, onClose = _a.onClose, disabled = _a.disabled, deleteButtonLabel = _a.deleteButtonLabel;
    var t = useTranslation().t;
    var deleteSmfResources = useBulkDeleteSmfResources();
    var onDelete = useCallback(function () { return deleteSmfResources(smfResourceIdsToAction); }, [deleteSmfResources, smfResourceIdsToAction]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.smf_resources.table.confirm_modal.title.delete'), deleteButtonLabel: deleteButtonLabel, onDelete: onDelete, disabled: disabled, onSuccess: onClose }, { children: _jsxs(Box, __assign({ sx: { marginBottom: 1, display: 'flex', flexDirection: 'row', gap: 1 } }, { children: [_jsx(ErrorOutlineIcon, { color: "warning" }), _jsx(DialogContentText, __assign({ sx: { marginBottom: 1 } }, { children: t('pages.smf_resources.table.confirm_modal.body.delete') }))] })) })));
}
