var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useState } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
var StyledIconContainer = styled('span')({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'max-content',
    cursor: 'pointer',
    outline: 'none',
});
var IconTooltip = forwardRef(function (_a, ref) {
    var iconComponent = _a.iconComponent, _b = _a.iconColor, iconColor = _b === void 0 ? 'inherit' : _b, iconSize = _a.iconSize, iconSX = _a.iconSX, _c = _a.placement, placement = _c === void 0 ? 'top' : _c, title = _a.title, otherProps = __rest(_a, ["iconComponent", "iconColor", "iconSize", "iconSX", "placement", "title"]);
    var tooltipProps = __assign(__assign({ enterDelay: 500, placement: placement, title: title }, otherProps), { arrow: true, ref: ref });
    var Icon = iconComponent || MuiHelpOutlineIcon;
    var fontSize = iconSize || 'small';
    var sxColor = iconColor === 'inherit' ? 'inherit' : undefined;
    var _d = __read(useState("tooltip-".concat(Math.random().toString(36).substring(2, 11))), 1), tooltipId = _d[0];
    return (_jsx(MuiTooltip, __assign({}, tooltipProps, { id: tooltipId, sx: { marginLeft: 0.5 } }, { children: _jsx(StyledIconContainer, __assign({ tabIndex: 0, role: "tooltip", "aria-describedby": tooltipId, ref: ref }, { children: _jsx(Icon, { color: iconColor, sx: __assign({ color: sxColor, fontSize: fontSize }, iconSX) }) })) })));
});
export default IconTooltip;
