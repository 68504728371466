var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { OGButton } from '@opengear/buttons';
import { OGSubscriptionIcon } from '@opengear/icons/action/subscription-assignment';
export function LicenseUpdateButton(_a) {
    var subscription = _a.subscription, onClickHandler = _a.onClickHandler, hasUpdateLicenseAccess = _a.hasUpdateLicenseAccess;
    var _b = subscription.details, bucket = _b.bucket, tier = _b.tier;
    var t = useTranslation().t;
    var buttonText = tier === 'legacy'
        ? t('pages.subscriptions.tabs.subscriptions.subscription_item.buttons.add_subscription')
        : t('pages.subscriptions.tabs.subscriptions.subscription_item.buttons.update_subscription');
    var icon = bucket === 'eval' || tier === 'legacy' ? _jsx(AddCircleOutline, {}) : _jsx(OGSubscriptionIcon, {});
    return bucket !== 'eval' ? (_jsx(Box, __assign({ width: "100%", display: "flex", justifyContent: "center", marginBottom: 1 }, { children: _jsx(OGButton, __assign({ variant: "secondary", onClick: onClickHandler, startIcon: icon, disabled: !hasUpdateLicenseAccess }, { children: buttonText })) }))) : null;
}
