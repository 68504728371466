import { jsx as _jsx } from "react/jsx-runtime";
import { OGThemeProvider } from '@opengear/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { RestoreStatus } from '@/components/settings/system/system-config/config-restore/config-restore-form-controls';
import { useConfigRestoreStatus } from '@/api/system/config/system-config';
import { useBeforeUnload } from '@/hooks/before-unload';
import { DASHBOARD_ROUTE, NOT_FOUND_ROUTE } from '@/constants/routes';
function RestoreStatusPage() {
    var locationState = useLocation().state;
    var navigate = useNavigate();
    // Don't allow users to access this page if they enter an invalid restore ID
    useEffect(function () {
        if (locationState === null || !locationState.configRestoreResponseStatus) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [locationState, navigate]);
    var restoreStatus = useConfigRestoreStatus();
    // Redirect to login page if restore status is undefined
    useEffect(function () {
        if (restoreStatus.status === undefined) {
            navigate(DASHBOARD_ROUTE, { replace: true });
        }
    }, [restoreStatus.status, navigate]);
    // Set beforeunload handler to warn users
    // if they try to refresh or navigate away from this page while restore is in progress
    useBeforeUnload(restoreStatus.status !== undefined);
    return (_jsx(OGThemeProvider, { children: _jsx(RestoreStatus, { status: restoreStatus.status }) }));
}
export default RestoreStatusPage;
