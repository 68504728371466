export function createRootElement(id) {
    var element = document.getElementById(id);
    if (!element) {
        element = document.createElement('div');
        element.setAttribute('id', id);
        document.body.appendChild(element);
    }
    return element;
}
/**
 * Helper function to get cookie by key
 */
export function getCookie(key, defaultValue) {
    var _a, _b;
    return ((_b = (_a = document.cookie
        .split('; ')
        .find(function (item) { return item.startsWith("".concat(key, "=")); })) === null || _a === void 0 ? void 0 : _a.split('=')[1]) !== null && _b !== void 0 ? _b : defaultValue);
}
/**
 * Helper function to delete cookie by key
 */
export function deleteCookie(key) {
    document.cookie = "".concat(key, "=; Expires=").concat(new Date().toUTCString());
}
/**
 * Helper function to set cookie by key
 */
export function setCookie(key, value) {
    if (process.env.NODE_ENV === 'test') {
        document.cookie = "".concat(key, "=").concat(value, "; Domain=").concat(window.location.hostname, "; Path=/; SameSite=Strict");
    }
    else {
        /* istanbul ignore next */
        document.cookie = "".concat(key, "=").concat(value, "; Domain=").concat(window.location.hostname, "; Path=/; SameSite=Strict; Secure");
    }
}
