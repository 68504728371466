var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { IPV4_PATTERN, IPV6_PATTERN } from '@/constants/regex';
import { invalidFieldError, maxLengthFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
export function StatusToggleButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
            return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasEditPermission, value: value, onChange: function (event, val) {
                    if (val !== null) {
                        onChange(val);
                    }
                } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ autoFocus: true, value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
        } }));
}
export function DescriptionField() {
    var DescriptionFieldMaxLength = 128;
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('pages.network_interfaces.modal.form.fields.description'), DescriptionFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.description') }, field)));
        } }));
}
export function ModeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "mode", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsxs(ToggleButtonGroup, __assign({ "aria-label": "Connection type", disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                    if (!['static', 'dhcp', 'ipv6_static', 'ipv6_automatic'].includes(v))
                        return;
                    onChange(v);
                } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: "static" }, { children: t('pages.network_interfaces.modal.form.mode.static') })), _jsx(ToggleButton, __assign({ value: "dhcp" }, { children: t('pages.network_interfaces.modal.form.mode.dhcp') })), _jsx(ToggleButton, __assign({ value: "ipv6_static" }, { children: t('pages.network_interfaces.modal.form.mode.ipv6_static') })), _jsx(ToggleButton, __assign({ value: "ipv6_automatic" }, { children: t('pages.network_interfaces.modal.form.mode.ipv6_automatic') }))] })));
        } }));
}
export function IPv4AddressField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv4_static_settings.address", rules: {
            required: requiredFieldError(t('pages.network_interfaces.modal.form.fields.ipv4_address.label')),
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.ipv4_address.label')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, label: t('pages.network_interfaces.modal.form.fields.ipv4_address.label') }, field)));
        } }));
}
export function IPv4NetmaskField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv4_static_settings.netmask", rules: {
            required: requiredFieldError(t('pages.network_interfaces.modal.form.fields.ipv4_netmask')),
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.ipv4_netmask')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, label: t('pages.network_interfaces.modal.form.fields.ipv4_netmask') }, field)));
        } }));
}
export function IPv4GatewayField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv4_static_settings.gateway", rules: {
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.gateway')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.gateway') }, field)));
        } }));
}
export function IPv4PrimaryDNSField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv4_static_settings.dns1", rules: {
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.primary_dns')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.primary_dns') }, field)));
        } }));
}
export function IPv4SecondaryDNSField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv4_static_settings.dns2", rules: {
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.secondary_dns')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.secondary_dns') }, field)));
        } }));
}
export function IPv6AddressField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv6_static_settings.address", rules: {
            required: requiredFieldError(t('pages.network_interfaces.modal.form.fields.ipv6_address.label')),
            pattern: {
                value: IPV6_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.ipv6_address.label')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, label: t('pages.network_interfaces.modal.form.fields.ipv6_address.label') }, field)));
        } }));
}
export function IPv6PrefixLengthField() {
    var IPv6PrefixMinVal = 1;
    var IPv6PrefixMaxVal = 128;
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv6_static_settings.prefix_length", rules: {
            required: requiredFieldError(t('pages.network_interfaces.modal.form.fields.prefix.title')),
            min: {
                value: IPv6PrefixMinVal,
                message: minValueFieldError(t('pages.network_interfaces.modal.form.fields.prefix.title'), IPv6PrefixMinVal.toString()),
            },
            max: {
                value: IPv6PrefixMaxVal,
                message: maxValueFieldError(t('pages.network_interfaces.modal.form.fields.prefix.title'), IPv6PrefixMaxVal.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, type: "number", label: t('pages.network_interfaces.modal.form.fields.prefix.title') }, field, { onChange: function (event) {
                    field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                } })));
        } }));
}
export function IPv6GatewayField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv6_static_settings.gateway", rules: {
            pattern: {
                value: IPV6_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.gateway')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.gateway') }, field)));
        } }));
}
export function IPv6PrimaryDNSField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv6_static_settings.dns1", rules: {
            pattern: {
                value: IPV6_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.primary_dns')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.primary_dns') }, field)));
        } }));
}
export function IPv6SecondaryDNSField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ipv6_static_settings.dns2", rules: {
            pattern: {
                value: IPV6_PATTERN,
                message: invalidFieldError(t('pages.network_interfaces.modal.form.fields.secondary_dns')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.network_interfaces.modal.form.fields.secondary_dns') }, field)));
        } }));
}
