var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
/**
 * A hook to manage the pagination model for a table and persist the page size in local storage
 * @example const [paginationModel, handlePaginationModelChange, pageSizeOptions] = usePaginationModel('pending-nodes')
 * @param tableId
 * @param pageSizeOptions
 * @param defaultIndex
 * @param defaultPage
 */
export function usePaginationModel(tableId, pageSizeOptions, defaultIndex, defaultPage) {
    if (pageSizeOptions === void 0) { pageSizeOptions = [10, 20, 50, 100]; }
    if (defaultIndex === void 0) { defaultIndex = 0; }
    if (defaultPage === void 0) { defaultPage = 0; }
    var _a = __read(useState(defaultPage), 2), page = _a[0], setPage = _a[1];
    var _b = __read(useLocalStorageState("".concat(tableId, "_table.page_size"), {
        defaultValue: pageSizeOptions[defaultIndex],
    }), 2), pageSize = _b[0], setPageSize = _b[1];
    var handlePaginationModelChange = useCallback(function (_a) {
        var newPage = _a.page, newPageSize = _a.pageSize;
        setPage(newPage);
        setPageSize(newPageSize);
    }, [setPage, setPageSize]);
    var paginationModal = useMemo(function () { return ({
        page: page,
        // Return default page size if the stored page size in local storage is not one of the page size options
        pageSize: !pageSizeOptions.includes(pageSize) ? pageSizeOptions[defaultIndex] : pageSize,
    }); }, [defaultIndex, page, pageSize, pageSizeOptions]);
    return [paginationModal, handlePaginationModelChange, pageSizeOptions];
}
