var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
export function RemoveCell(_a) {
    var index = _a.index, currentCount = _a.currentCount, tabIndex = _a.tabIndex, onRemove = _a.onRemove, disabled = _a.disabled, translation = _a.translation, _b = _a.icon, icon = _b === void 0 ? _jsx(ClearIcon, {}) : _b, _c = _a.sx, sx = _c === void 0 ? {} : _c;
    var handleRemove = useCallback(function () {
        if (disabled)
            return;
        var focusIndex = index === 0 ? 1 : index - 1;
        // Call the onRemove function with next focusIndex
        onRemove(currentCount > 1 ? focusIndex : undefined);
    }, [onRemove, index, currentCount, disabled]);
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: translation.tooltip }, { children: _jsx("span", { children: _jsx(OGButton, { "aria-label": translation.label, tabIndex: tabIndex, variant: "borderless", onClick: handleRemove, disabled: disabled, size: "small", startIcon: icon, sx: sx, propagateSpacebar: false }) }) })));
}
