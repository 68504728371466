var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { OGDialog } from '@opengear/modals';
import { OGButton } from '@opengear/buttons';
import { useUpdateResourceSmartgroup } from '@/api/smf-resources/smartgroups';
import { DeleteButton, FormActions, CancelButton, FormContainer } from '@/components/layout/forms';
import { ResourceFilterNameField, ResourceFilterQueryFieldset } from './resource-filter-modal-form-controls';
import { isResourceQueryValid } from '@/containers/filters/advanced-filters/resource-filter';
export var RESOURCE_FILTER_EDIT_TITLE_ID = 'resource-filter-edit-title';
/**
 * ResourceFilterEditModalContent
 * Separate content logic to help clear the form state when unmounted
 */
export function ResourceFilterEditModalContent(_a) {
    var onClose = _a.onClose, onDelete = _a.onDelete, onSubmit = _a.onSubmit, selectedFilter = _a.selectedFilter, translations = _a.translations, error = _a.error, setError = _a.setError;
    var mutate = useUpdateResourceSmartgroup().mutate;
    // Default values are pre set with a valid filter
    var methods = useForm({ mode: 'onTouched', defaultValues: selectedFilter });
    var _b = methods.formState, isValid = _b.isValid, isDirty = _b.isDirty, isSubmitting = _b.isSubmitting;
    var handleSubmit = useCallback(function (editFilter) {
        if (isResourceQueryValid(editFilter)) {
            var request = {
                id: editFilter.id,
                name: editFilter.name,
                queryObject: editFilter.queryObject,
            };
            mutate(request, {
                onSuccess: function (updatedSmartgroup) {
                    if (updatedSmartgroup)
                        onSubmit(updatedSmartgroup);
                },
            });
        }
    }, [mutate, onSubmit]);
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: RESOURCE_FILTER_EDIT_TITLE_ID, mode: "update", methods: methods, onSubmit: handleSubmit }, { children: [_jsx(DialogTitle, __assign({ id: RESOURCE_FILTER_EDIT_TITLE_ID }, { children: translations.dialogTitle })), _jsxs(DialogContent, { children: [_jsx(ResourceFilterNameField, {}), _jsx(ResourceFilterQueryFieldset, { setError: setError })] }), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { paddingTop: 0 } }, { children: _jsxs(FormActions, { children: [_jsx(DeleteButton, { onClick: onDelete }), _jsx(Box, { flexGrow: 1 }), _jsx(CancelButton, { onCancel: onClose }), _jsx(OGButton, __assign({ type: "submit", disabled: !isValid || !isDirty || error, loading: isSubmitting }, { children: translations.saveBtn }))] }) }))] })));
}
export function ResourceFilterEditModal(_a) {
    var onClose = _a.onClose, onDelete = _a.onDelete, onSubmit = _a.onSubmit, open = _a.open, selectedFilter = _a.selectedFilter, translations = _a.translations, error = _a.error, setError = _a.setError;
    return (_jsx(OGDialog, __assign({ "aria-labelledby": RESOURCE_FILTER_EDIT_TITLE_ID, open: open, onClose: onClose }, { children: selectedFilter ? (_jsx(ResourceFilterEditModalContent, { selectedFilter: selectedFilter, onClose: onClose, onDelete: onDelete, onSubmit: onSubmit, translations: translations, error: error, setError: setError })) : null })));
}
