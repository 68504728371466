/**
 * Determines if a node is 'connected' based on its runtime status and product type.
 * @param node
 * @returns boolean
 */
export var isNodeConnected = function (node) {
    var connectionStatus = node.runtime_status.connection_status;
    // Opengear devices must be in a connected state to be considered connected
    if (node.product === 'opengear') {
        return connectionStatus === 'connected';
    }
    // In all other cases we assume the node is connected so long as it is not disconnected
    // TODO: IM-16673 improve this logic when we have more information about third-party devices + digi-passports
    return connectionStatus !== 'disconnected';
};
