var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { OGTable } from '@opengear/table';
import { useNodeUpgrades } from '@/api/system/node-upgrade';
import { formatDateString } from '@/utils/datetime';
import { FirmwareFilesDeleteCell, FirmwareFilesDeleteDialog } from './firmware-files-table-components';
// to sort as a date not a string
export var stringDateComparator = function (v1, v2) { return new Date(v1).getTime() - new Date(v2).getTime(); };
export var FirmwareFilesTable = memo(function (_a) {
    var firmwareData = _a.firmwareData, isFirmwareLoading = _a.isFirmwareLoading, hasEditAccess = _a.hasEditAccess;
    var t = useTranslation().t;
    var _b = useNodeUpgrades(), upgradeData = _b.data, upgradeDataLoading = _b.isLoading;
    // Array of firmware ids that are used in any current or future tasks
    // logic is the same as is used in lipy/lipy/views/system_node_firmware.py jobs_that_need_firmware
    var usedFirmwares = useMemo(function () {
        var usedFiles = [];
        if (upgradeData && firmwareData) {
            firmwareData.forEach(function (firmware) {
                var isUsed = upgradeData.node_upgrades.some(function (upgrade) {
                    return (upgrade.status === 'SCHEDULED' || upgrade.status === 'IN_PROGRESS' || upgrade.status === 'CANCELLING') &&
                        upgrade.firmware_id &&
                        upgrade.firmware_id.toString() === firmware.id.split('-')[2];
                });
                if (isUsed) {
                    usedFiles.push(firmware.id);
                }
            });
        }
        return usedFiles;
    }, [firmwareData, upgradeData]);
    // Firmware delete dialog
    var _c = __read(useState(), 2), firmwareIdToDelete = _c[0], setFirmwareIdToDelete = _c[1];
    var handleCloseDeleteDialog = useCallback(function () {
        setFirmwareIdToDelete(undefined);
    }, []);
    // Derived table values
    var _d = useMemo(function () { return ({
        loading: isFirmwareLoading,
        rowCount: firmwareData ? firmwareData.length : 0,
        rows: firmwareData || [],
    }); }, [isFirmwareLoading, firmwareData]), loading = _d.loading, rowCount = _d.rowCount, rows = _d.rows;
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'filename',
            headerName: t('pages.firmware_upgrade.tabs.file_manager.table.headers.filename'),
            flex: 1,
            minWidth: 300,
        },
        {
            field: 'version',
            headerName: t('pages.firmware_upgrade.tabs.file_manager.table.headers.version'),
            minWidth: 150,
        },
        {
            field: 'family',
            headerName: t('pages.firmware_upgrade.tabs.file_manager.table.headers.family'),
            minWidth: 150,
        },
        {
            field: 'size',
            headerName: t('pages.firmware_upgrade.tabs.file_manager.table.headers.size'),
            minWidth: 150,
        },
        {
            field: 'upload_date',
            headerName: t('pages.firmware_upgrade.tabs.file_manager.table.headers.upload_date'),
            minWidth: 200,
            valueGetter: function (_a) {
                var value = _a.value;
                return formatDateString(value);
            },
            sortComparator: stringDateComparator,
        },
        {
            field: 'delete',
            headerName: '',
            align: 'center',
            headerClassName: 'FocusDisabled',
            width: 60,
            sortable: false,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(FirmwareFilesDeleteCell, { disabled: upgradeDataLoading || usedFirmwares.includes(row.id), firmwareId: row.id, onClick: function () { return setFirmwareIdToDelete(row.id); }, tabIndex: tabIndex }));
            },
        },
    ]; }, [t, upgradeDataLoading, usedFirmwares]);
    return (_jsxs(_Fragment, { children: [firmwareIdToDelete && _jsx(FirmwareFilesDeleteDialog, { firmwareId: firmwareIdToDelete, onClose: handleCloseDeleteDialog, open: true }), _jsx(Box, __assign({ marginBottom: 1 }, { children: _jsx(OGTable, { id: "upgrade-tasks-table", "aria-label": t('pages.firmware_upgrade.tabs.file_manager.label'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.firmware_upgrade.tabs.file_manager.table.no_row_message'), rowCount: rowCount, columnVisibilityModel: {
                        delete: hasEditAccess,
                    } }) }))] }));
});
