var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 as uuidV4 } from 'uuid';
import { ConfigurationMethod } from './types';
import { isSmfTemplate } from '@/api/template/guards';
var transformFormInterface = function (interfaces, networks) {
    return interfaces.map(function (_a) {
        var id = _a.id, cost = _a.cost, area = _a.area, md5Keys = _a.md5_keys, cleartext = _a.cleartext, interfaceData = __rest(_a, ["id", "cost", "area", "md5_keys", "cleartext"]);
        var authKeys = [];
        if (interfaceData.auth_method === 'md5') {
            authKeys.push.apply(authKeys, __spreadArray([], __read(md5Keys), false));
        }
        else if (interfaceData.auth_method === 'cleartext') {
            authKeys.push({ id: '1', key: cleartext });
        }
        var modifiedInterfaceData = __assign(__assign({}, interfaceData), { auth_keys: authKeys });
        if (networks.length === 0 && area !== '') {
            modifiedInterfaceData.area = area;
        }
        if (cost !== '') {
            modifiedInterfaceData.cost = parseInt(cost, 10);
        }
        return modifiedInterfaceData;
    });
};
var transformFormNetworks = function (networks, configurationMethod) {
    if (configurationMethod === ConfigurationMethod.interface)
        return [];
    return networks.map(function (network) { return (__assign(__assign({}, network), { area: String(network.area) })); });
};
export var transformFormStateToCreateRequest = function (formState) {
    var name = formState.name, description = formState.description, allZoneIpMasquerade = formState.allZoneIpMasquerade, redistributeConnected = formState.redistributeConnected, redistributeKernel = formState.redistributeKernel, networks = formState.networks, interfaces = formState.interfaces, configurationMethod = formState.configurationMethod;
    return {
        template: {
            name: name,
            description: description,
            type: 'smf',
            smf_data: {
                all_zone_ip_masquerade: allZoneIpMasquerade,
                ospf_redistribute_connected: redistributeConnected,
                ospf_redistribute_kernel: redistributeKernel,
                ospf_networks: transformFormNetworks(networks, configurationMethod),
                ospf_interfaces: transformFormInterface(interfaces, networks),
            },
        },
    };
};
export var transformFormStateToUpdateRequest = function (templateId, formState) {
    var name = formState.name, description = formState.description, allZoneIpMasquerade = formState.allZoneIpMasquerade, redistributeConnected = formState.redistributeConnected, redistributeKernel = formState.redistributeKernel, networks = formState.networks, interfaces = formState.interfaces, configurationMethod = formState.configurationMethod;
    return {
        id: templateId,
        template: {
            name: name,
            description: description,
            type: 'smf',
            smf_data: {
                all_zone_ip_masquerade: allZoneIpMasquerade,
                ospf_redistribute_connected: redistributeConnected,
                ospf_redistribute_kernel: redistributeKernel,
                ospf_networks: transformFormNetworks(networks, configurationMethod),
                ospf_interfaces: transformFormInterface(interfaces, networks),
            },
        },
    };
};
export var transformResponseToFormState = function (response) {
    if (!response || !isSmfTemplate(response))
        return null;
    var name = response.name, _a = response.description, description = _a === void 0 ? '' : _a, smfData = response.smf_data;
    var allZoneIpMasquerade = smfData.all_zone_ip_masquerade, routerId = smfData.ospf_router_id, redistributeConnected = smfData.ospf_redistribute_connected, redistributeKernel = smfData.ospf_redistribute_kernel, _b = smfData.ospf_interfaces, interfaces = _b === void 0 ? [] : _b, _c = smfData.ospf_networks, networks = _c === void 0 ? [] : _c;
    var transformedInterfaces = interfaces.length > 0
        ? interfaces.map(function (_a) {
            var _b;
            var authKeys = _a.auth_keys, ospfInterface = __rest(_a, ["auth_keys"]);
            return (__assign(__assign({}, ospfInterface), { id: uuidV4(), area: (_b = ospfInterface.area) !== null && _b !== void 0 ? _b : '', cost: !ospfInterface.cost ? '' : ospfInterface.cost.toString(), cleartext: ospfInterface.auth_method === 'cleartext' ? authKeys[0].key : '', md5_keys: ospfInterface.auth_method === 'md5' ? authKeys : [] }));
        })
        : [];
    return {
        name: name,
        description: description,
        allZoneIpMasquerade: allZoneIpMasquerade,
        routerId: routerId,
        redistributeConnected: redistributeConnected,
        redistributeKernel: redistributeKernel,
        configurationMethod: networks.length ? ConfigurationMethod.network : ConfigurationMethod.interface,
        networks: networks,
        interfaces: transformedInterfaces,
    };
};
export var transformUpdateInterface = function (formData) {
    var authMethod = formData.auth_method;
    var md5Keys = authMethod === 'md5' ? formData.md5_keys : [];
    var cleartext = authMethod === 'cleartext' ? formData.cleartext : '';
    return __assign(__assign({}, formData), { cleartext: cleartext, md5_keys: md5Keys });
};
