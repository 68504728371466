var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { NameField } from './firmware-name-filter-components';
export function FirmwareNameFilter(_a) {
    var _b;
    var filterOptions = _a.filterOptions, setFilterOptions = _a.setFilterOptions;
    var _c = __read(useState((_b = filterOptions.name_contains) !== null && _b !== void 0 ? _b : ''), 2), inputValue = _c[0], setInputValue = _c[1];
    var handleInputChange = useCallback(function (event) {
        if (event.target.value.trim() === '') {
            setFilterOptions(function (prevState) { return (__assign(__assign({}, prevState), { name_contains: '' })); });
        }
        setInputValue(event.target.value);
    }, [setFilterOptions]);
    var handleBlur = useCallback(function () {
        setFilterOptions(function (prevState) { return (__assign(__assign({}, prevState), { name_contains: inputValue })); });
    }, [inputValue, setFilterOptions]);
    var handleKeyDown = useCallback(function (event) {
        if (event.key === 'Enter') {
            setFilterOptions(function (prevState) { return (__assign(__assign({}, prevState), { name_contains: inputValue })); });
        }
    }, [inputValue, setFilterOptions]);
    return (_jsx(Box, __assign({ display: "flex", alignItems: "center", gap: 1 }, { children: _jsx(NameField, { inputValue: inputValue, handleInputChange: handleInputChange, handleBlur: handleBlur, handleKeyDown: handleKeyDown }) })));
}
