var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { AuthTemplateForm } from '@/containers/config/templates/auth-template';
import { useAlertNotification } from '@/hooks/alert-notification';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useTemplate } from '@/api/template/templates';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useDocTitle } from '@/hooks/doc-title';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { transformToResponseObject } from '@/containers/config/templates/auth-template/transformers';
import { isAuthTemplate } from '@/api/template/guards';
import { PAGE_TITLE_ID } from '@/constants/ids';
var AUTH_TEMPLATE_LOADER_ID = 'node-vpn-form-loading';
function EditAuthTemplate() {
    var t = useTranslation().t;
    var _a = useUIPermissions(), permissions = _a.permissions, hasPermission = _a.hasPermission;
    var templateId = useParams().templateId;
    var alertNotification = useAlertNotification().alertNotification;
    var navigate = useNavigate();
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404) {
            alertNotification(format(t('api.template.get.not_found.message'), templateId !== null && templateId !== void 0 ? templateId : ''), { persistOnUnmount: true });
        }
        navigate(NOT_FOUND_ROUTE);
    }, [alertNotification, navigate, t, templateId]);
    var _b = useTemplate(templateId || '', { notFoundCallback: notFoundCallback }), data = _b.data, isLoading = _b.isLoading;
    var hasAuthEditAccess = hasPermission('configure.configuration_templating.authentication_templates.edit');
    useLayoutEffect(function () {
        if (!permissions || permissions.is_secondary || permissions.is_lic_expired) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate, permissions]);
    useDocTitle(t('pages.config_templates.edit.form.auth.title'));
    var templateData = data;
    var isAuthTemplateData = isAuthTemplate(templateData);
    var sourceValues = transformToResponseObject(templateData);
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": AUTH_TEMPLATE_LOADER_ID });
    }
    if (!isLoading && isAuthTemplateData) {
        innerNode = _jsx(AuthTemplateForm, { data: sourceValues, permissions: { hasAuthEditAccess: hasAuthEditAccess } });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.config_templates.edit.form.auth.title') })), innerNode] })));
}
export default withAuthenticationRequired(EditAuthTemplate);
