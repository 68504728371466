var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { OGButton } from '@opengear/buttons';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useDocTitle } from '@/hooks/doc-title';
import { CONFIG_TEMPLATES_ROUTE, PUSH_TEMPLATE_PREFLIGHT_ROUTE } from '@/constants/routes';
import TemplateNodeSelectTable from '@/containers/config/templates/push/template-node-select-table';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { TemplatePreviewSection, TemplatePushStepper } from '@/containers/config/templates/push/template-push-components';
import { useAdvanceTemplatePush, useCreateTemplatePush } from '@/api/template-push/template-push';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var SELECT_NODE_LOADER_ID = 'select-nodes-loading';
function TemplateNodeSelectPage() {
    var _this = this;
    var t = useTranslation().t;
    useDocTitle(t('pages.config_templates.push.title'));
    var _a = __read(useState([]), 2), selectedNodes = _a[0], setSelectedNodes = _a[1];
    var _b = __read(useState(false), 2), buttonBusy = _b[0], setButtonBusy = _b[1];
    var locationState = useLocation().state;
    var navigate = useNavigate();
    var theme = useTheme();
    var createTemplatePush = useCreateTemplatePush();
    var advanceTemplate = useAdvanceTemplatePush();
    useEffect(function () {
        if (locationState === null || !locationState.templateId) {
            navigate(CONFIG_TEMPLATES_ROUTE);
        }
    }, [locationState, navigate]);
    var _c = useUIPermissions(), hasPermission = _c.hasPermission, hasSomePermission = _c.hasSomePermission;
    var editableNodeFilters = useMemo(function () { return hasPermission('configure.edit_smart_groups.edit'); }, [hasPermission]);
    var readableNodeTags = useMemo(function () { return hasSomePermission(['configure.edit_tags.view', 'configure.node_enrollment.enrollment_bundles.edit']); }, [hasSomePermission]);
    var onPreflight = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var newTemplatePush, advanceResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(locationState === null || locationState === void 0 ? void 0 : locationState.templateId)) return [3 /*break*/, 4];
                    setButtonBusy(true);
                    return [4 /*yield*/, createTemplatePush({
                            template_push: {
                                template_ids: [locationState.templateId],
                                node_ids: selectedNodes,
                            },
                        })];
                case 1:
                    newTemplatePush = _a.sent();
                    if (!newTemplatePush) return [3 /*break*/, 3];
                    return [4 /*yield*/, advanceTemplate(newTemplatePush.id, {
                            template_push: {
                                stage: 'preflight',
                                node_ids: selectedNodes,
                                template_ids: [locationState.templateId],
                            },
                        })];
                case 2:
                    advanceResult = _a.sent();
                    if (advanceResult) {
                        navigate(PUSH_TEMPLATE_PREFLIGHT_ROUTE, { state: { contextId: newTemplatePush.id } });
                    }
                    _a.label = 3;
                case 3:
                    setButtonBusy(false);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [advanceTemplate, createTemplatePush, locationState, navigate, selectedNodes]);
    var innerNode = _jsx(LoadingBackdrop, { "data-testid": SELECT_NODE_LOADER_ID });
    if (locationState === null || locationState === void 0 ? void 0 : locationState.templateId) {
        innerNode = (_jsxs(_Fragment, { children: [_jsx(Paper, { children: _jsx(TemplatePushStepper, { step: 0 }) }), _jsx(Paper, __assign({ sx: { marginTop: 2 } }, { children: _jsxs(Stack, __assign({ display: "flex", direction: "row", flexBasis: 0 }, { children: [_jsx(Box, __assign({ flexGrow: 1 }, { children: _jsx(TemplatePreviewSection, { templateId: locationState.templateId }) })), _jsxs(Box, __assign({ flexGrow: 2, borderLeft: "1px solid ".concat(theme.palette.divider), minWidth: 0 }, { children: [_jsx(Box, __assign({ padding: 2, minWidth: 0 }, { children: _jsx(TemplateNodeSelectTable, { editableNodeFilters: editableNodeFilters, readableNodeTags: readableNodeTags, selectedNodes: selectedNodes, onSelectChange: setSelectedNodes }) })), _jsxs(Stack, __assign({ spacing: 1, padding: 2, borderTop: "1px solid ".concat(theme.palette.divider), direction: "row", display: "flex", justifyContent: "flex-end" }, { children: [_jsx(OGButton, __assign({ variant: "secondary", onClick: function () { return navigate(CONFIG_TEMPLATES_ROUTE); } }, { children: t('pages.config_templates.push.exit') })), _jsx(OGButton, __assign({ disabled: selectedNodes.length === 0, variant: "primary", onClick: onPreflight, loading: buttonBusy }, { children: t('pages.config_templates.push.run_preflight') }))] }))] }))] })) }))] }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.config_templates.push.title') })), innerNode] })));
}
export default withAuthenticationRequired(TemplateNodeSelectPage, { rights: ['configure.configuration_templating.apply_templates.push'] });
