var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import { useFormContainerContext } from '@/components/layout/forms';
export function UpgradeInFailoverActionButton(_a) {
    var action = _a.action, onClick = _a.onClick;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), setValue = _b.setValue, watch = _b.watch;
    var label = t('pages.firmware_upgrade_new.steps.schedule.upgrade_all_in_failover.options')[action];
    var selectedNodes = watch('nodes');
    var handleClick = useCallback(function () {
        var updatedSelectedNodes = selectedNodes.map(function (selectedNode) {
            // eslint-disable-next-line no-param-reassign
            selectedNode.allowInFailover = action === 'allow';
            return selectedNode;
        });
        setValue('nodes', updatedSelectedNodes);
        onClick === null || onClick === void 0 ? void 0 : onClick(updatedSelectedNodes);
    }, [action, onClick, selectedNodes, setValue]);
    return (_jsx(OGButton, __assign({ type: "button", onClick: handleClick, variant: "secondary" }, { children: label })));
}
