var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { NodeStatusPanel } from '@/components/dashboard/node-status-panel';
import { CellHealthPanel } from '@/components/dashboard/cell-health-panel';
import { NodeStatusTablePanel } from '@/containers/nodes/node-status-table-panel';
import { MultiInstancePanel } from '@/components/multi-instance-panel';
import { useDeviceView } from '@/hooks/use-device-view';
function Dashboard() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    useDocTitle(t('pages.dashboard.title'));
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.dashboard.title') })), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 2 }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: isMobileView ? 'column' : 'row', gap: 2, flexWrap: "wrap" }, { children: [_jsx(NodeStatusPanel, {}), _jsx(CellHealthPanel, {})] })), _jsx(NodeStatusTablePanel, {}), _jsx(MultiInstancePanel, {})] }))] })));
}
export default withAuthenticationRequired(Dashboard);
