/* eslint-disable */
/** autogenerate file, do not edit, regenerate with `npx ts-node cmd/openapi2typescript schemas/cdm-lighthouse-spec.yaml types/lighthouse` */
'use strict'
module.exports = validate10
module.exports.default = validate10
var schema11 = {
  type: 'object',
  required: ['error'],
  properties: { error: { type: 'array', items: { $ref: '#/definitions/error' } } },
  title: 'IErrorResponse',
}
var schema12 = {
  required: ['text'],
  type: 'object',
  properties: {
    args: { type: 'object' },
    code: { type: 'integer' },
    field_path: { type: 'array', items: { type: 'string' }, description: '' },
    level: { type: 'integer' },
    text: { type: 'string' },
    type: { type: 'integer' },
  },
  title: 'IError',
  $id: '#/definitions/error',
}
function validate10(data, valCxt) {
  'use strict'
  if (valCxt) {
    var instancePath = valCxt.instancePath
    var parentData = valCxt.parentData
    var parentDataProperty = valCxt.parentDataProperty
    var rootData = valCxt.rootData
  } else {
    var instancePath = ''
    var parentData = undefined
    var parentDataProperty = undefined
    var rootData = data
  }
  var vErrors = null
  var errors = 0
  if (errors === 0) {
    if (data && typeof data == 'object' && !Array.isArray(data)) {
      var missing0
      if (data.error === undefined && (missing0 = 'error')) {
        validate10.errors = [
          {
            instancePath: instancePath,
            schemaPath: '#/required',
            keyword: 'required',
            params: { missingProperty: missing0 },
            message: "must have required property '" + missing0 + "'",
          },
        ]
        return false
      } else {
        if (data.error !== undefined) {
          var data0 = data.error
          var _errs1 = errors
          if (errors === _errs1) {
            if (Array.isArray(data0)) {
              var valid1 = true
              var len0 = data0.length
              for (var i0 = 0; i0 < len0; i0++) {
                var data1 = data0[i0]
                var _errs3 = errors
                var _errs4 = errors
                if (errors === _errs4) {
                  if (data1 && typeof data1 == 'object' && !Array.isArray(data1)) {
                    var missing1
                    if (data1.text === undefined && (missing1 = 'text')) {
                      validate10.errors = [
                        {
                          instancePath: instancePath + '/error/' + i0,
                          schemaPath: '#/definitions/error/required',
                          keyword: 'required',
                          params: { missingProperty: missing1 },
                          message: "must have required property '" + missing1 + "'",
                        },
                      ]
                      return false
                    } else {
                      if (data1.args !== undefined) {
                        var data2 = data1.args
                        var _errs6 = errors
                        if (!(data2 && typeof data2 == 'object' && !Array.isArray(data2))) {
                          validate10.errors = [
                            {
                              instancePath: instancePath + '/error/' + i0 + '/args',
                              schemaPath: '#/definitions/error/properties/args/type',
                              keyword: 'type',
                              params: { type: 'object' },
                              message: 'must be object',
                            },
                          ]
                          return false
                        }
                        var valid3 = _errs6 === errors
                      } else {
                        var valid3 = true
                      }
                      if (valid3) {
                        if (data1.code !== undefined) {
                          var data3 = data1.code
                          var _errs8 = errors
                          if (!(typeof data3 == 'number' && !(data3 % 1) && !isNaN(data3) && isFinite(data3))) {
                            validate10.errors = [
                              {
                                instancePath: instancePath + '/error/' + i0 + '/code',
                                schemaPath: '#/definitions/error/properties/code/type',
                                keyword: 'type',
                                params: { type: 'integer' },
                                message: 'must be integer',
                              },
                            ]
                            return false
                          }
                          var valid3 = _errs8 === errors
                        } else {
                          var valid3 = true
                        }
                        if (valid3) {
                          if (data1.field_path !== undefined) {
                            var data4 = data1.field_path
                            var _errs10 = errors
                            if (errors === _errs10) {
                              if (Array.isArray(data4)) {
                                var valid4 = true
                                var len1 = data4.length
                                for (var i1 = 0; i1 < len1; i1++) {
                                  var _errs12 = errors
                                  if (typeof data4[i1] !== 'string') {
                                    validate10.errors = [
                                      {
                                        instancePath: instancePath + '/error/' + i0 + '/field_path/' + i1,
                                        schemaPath: '#/definitions/error/properties/field_path/items/type',
                                        keyword: 'type',
                                        params: { type: 'string' },
                                        message: 'must be string',
                                      },
                                    ]
                                    return false
                                  }
                                  var valid4 = _errs12 === errors
                                  if (!valid4) {
                                    break
                                  }
                                }
                              } else {
                                validate10.errors = [
                                  {
                                    instancePath: instancePath + '/error/' + i0 + '/field_path',
                                    schemaPath: '#/definitions/error/properties/field_path/type',
                                    keyword: 'type',
                                    params: { type: 'array' },
                                    message: 'must be array',
                                  },
                                ]
                                return false
                              }
                            }
                            var valid3 = _errs10 === errors
                          } else {
                            var valid3 = true
                          }
                          if (valid3) {
                            if (data1.level !== undefined) {
                              var data6 = data1.level
                              var _errs14 = errors
                              if (!(typeof data6 == 'number' && !(data6 % 1) && !isNaN(data6) && isFinite(data6))) {
                                validate10.errors = [
                                  {
                                    instancePath: instancePath + '/error/' + i0 + '/level',
                                    schemaPath: '#/definitions/error/properties/level/type',
                                    keyword: 'type',
                                    params: { type: 'integer' },
                                    message: 'must be integer',
                                  },
                                ]
                                return false
                              }
                              var valid3 = _errs14 === errors
                            } else {
                              var valid3 = true
                            }
                            if (valid3) {
                              if (data1.text !== undefined) {
                                var _errs16 = errors
                                if (typeof data1.text !== 'string') {
                                  validate10.errors = [
                                    {
                                      instancePath: instancePath + '/error/' + i0 + '/text',
                                      schemaPath: '#/definitions/error/properties/text/type',
                                      keyword: 'type',
                                      params: { type: 'string' },
                                      message: 'must be string',
                                    },
                                  ]
                                  return false
                                }
                                var valid3 = _errs16 === errors
                              } else {
                                var valid3 = true
                              }
                              if (valid3) {
                                if (data1.type !== undefined) {
                                  var data8 = data1.type
                                  var _errs18 = errors
                                  if (!(typeof data8 == 'number' && !(data8 % 1) && !isNaN(data8) && isFinite(data8))) {
                                    validate10.errors = [
                                      {
                                        instancePath: instancePath + '/error/' + i0 + '/type',
                                        schemaPath: '#/definitions/error/properties/type/type',
                                        keyword: 'type',
                                        params: { type: 'integer' },
                                        message: 'must be integer',
                                      },
                                    ]
                                    return false
                                  }
                                  var valid3 = _errs18 === errors
                                } else {
                                  var valid3 = true
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  } else {
                    validate10.errors = [
                      {
                        instancePath: instancePath + '/error/' + i0,
                        schemaPath: '#/definitions/error/type',
                        keyword: 'type',
                        params: { type: 'object' },
                        message: 'must be object',
                      },
                    ]
                    return false
                  }
                }
                var valid1 = _errs3 === errors
                if (!valid1) {
                  break
                }
              }
            } else {
              validate10.errors = [
                {
                  instancePath: instancePath + '/error',
                  schemaPath: '#/properties/error/type',
                  keyword: 'type',
                  params: { type: 'array' },
                  message: 'must be array',
                },
              ]
              return false
            }
          }
        }
      }
    } else {
      validate10.errors = [{ instancePath: instancePath, schemaPath: '#/type', keyword: 'type', params: { type: 'object' }, message: 'must be object' }]
      return false
    }
  }
  validate10.errors = vErrors
  return errors === 0
}
