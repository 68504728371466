import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import { commonThemeOptions, OPACITY } from './common';
import { OG_BACKGROUND_LIGHT, OG_GREY_M, OG_GREY_L, OG_GREY_VL, OG_GREY_15, OG_OFF_WHITE, OG_RED, OG_WHITE, OG_DANGER_LIGHT, OG_WARNING_LIGHT, OG_SUCCESS_LIGHT, OG_BUTTON_PRIMARY, OG_BUTTON_PRIMARY_ACTIVE, OG_GREY_VVVL, OG_GREY_H, OG_BLACK, OG_LIGHT_LABEL, OG_GREY_ML, OG_PINNED_COLUMNS_SHADOW_LIGHT, OG_MENU_BG_LIGHT, OG_SELECTBAR_LIGHT, } from './colors';
var lightThemeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: OG_BACKGROUND_LIGHT,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 2px rgba(140, 140, 140, 0.25)',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    ' .MuiDrawer-paper': {
                        boxShadow: '2px 0px 4px rgba(140, 140, 140, 0.25)',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ':hover': {
                        boxShadow: '0px 4px 8px rgba(96, 101, 113, 0.2)',
                    },
                },
                outlined: {
                    'borderColor': OG_GREY_M,
                    ':hover': {
                        backgroundColor: OG_WHITE,
                        borderColor: OG_BUTTON_PRIMARY,
                        color: OG_BUTTON_PRIMARY,
                    },
                    ':active': {
                        backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                        borderColor: OG_BUTTON_PRIMARY_ACTIVE,
                        color: OG_WHITE,
                    },
                    '&.Mui-disabled': {
                        borderColor: OG_GREY_M,
                        color: OG_GREY_M,
                    },
                },
                text: {
                    'color': OG_GREY_M,
                    ':hover': {
                        backgroundColor: OG_WHITE,
                        color: OG_BUTTON_PRIMARY,
                    },
                    ':active': {
                        backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                        color: OG_WHITE,
                    },
                    '&.Mui-disabled': {
                        color: OG_GREY_M,
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: OG_WHITE,
                    color: OG_GREY_M,
                },
                cell: {
                    borderBottomColor: OG_GREY_VL,
                },
                columnHeaders: {
                    backgroundColor: OG_OFF_WHITE,
                    borderBottomColor: OG_GREY_VL,
                    color: OG_GREY_L,
                },
                pinnedColumns: {
                    backgroundColor: OG_WHITE,
                    boxShadow: OG_PINNED_COLUMNS_SHADOW_LIGHT,
                    row: {
                        '&.Mui-hovered': {
                            backgroundColor: OG_GREY_15,
                        },
                    },
                },
                pinnedColumnHeaders: {
                    backgroundColor: OG_OFF_WHITE,
                    boxShadow: OG_PINNED_COLUMNS_SHADOW_LIGHT,
                },
                footerContainer: {
                    borderTopColor: OG_GREY_VL,
                },
                row: {
                    '&.Mui-hovered, &.Mui-selected, &.Mui-selected.Mui-hovered, &.Mui-selected:hover': {
                        backgroundColor: OG_GREY_15,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: OG_GREY_VL,
                },
                head: {
                    backgroundColor: OG_OFF_WHITE,
                    color: OG_GREY_L,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover:hover, &.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: OG_GREY_15,
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    'color': OG_BUTTON_PRIMARY,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    },
                    '&.Mui-selected': {
                        'color': "rgba(81, 90, 97, ".concat(OPACITY, ")"),
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        },
                        '&:focus-visible': {
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        },
                    },
                    '&.Mui-disabled': {
                        color: OG_GREY_M,
                    },
                    '& > .MuiTouchRipple-root': {
                        color: OG_GREY_M,
                    },
                },
            },
        },
    },
    palette: {
        mode: 'light',
        background: {
            default: OG_OFF_WHITE,
            paper: OG_WHITE,
            // custom backgrounds
            active: OG_GREY_VL,
            chip: OG_GREY_VL,
            disabled: OG_GREY_VVVL,
            hover: OG_GREY_15,
            input: OG_WHITE,
            box: OG_OFF_WHITE,
            menu: OG_MENU_BG_LIGHT,
            popup: OG_WHITE,
            section: OG_OFF_WHITE,
            selectbar: OG_SELECTBAR_LIGHT,
            selected: OG_GREY_H,
        },
        divider: OG_GREY_VL,
        dividerLight: OG_OFF_WHITE,
        slider: {
            default: OG_GREY_M,
            hover: OG_GREY_ML,
            active: OG_GREY_VL,
        },
        secondary: {
            main: OG_BUTTON_PRIMARY,
        },
        stepper: {
            active: OG_RED,
            icon: OG_GREY_M,
            indicator: OG_GREY_VVVL,
            outline: OG_GREY_VL,
        },
        table: {
            divider: OG_GREY_VL,
        },
        text: {
            primary: OG_GREY_M,
            secondary: OG_GREY_L,
            disabled: OG_GREY_ML,
            // custom texts
            contrast: OG_GREY_VVVL,
            highlight: OG_BLACK,
            label: OG_LIGHT_LABEL,
        },
        error: {
            main: OG_DANGER_LIGHT,
        },
        warning: {
            main: OG_WARNING_LIGHT,
        },
        success: {
            main: OG_SUCCESS_LIGHT,
        },
        info: {
            main: OG_GREY_L,
        },
    },
};
export var lightTheme = createTheme(merge({}, commonThemeOptions, lightThemeOptions));
