var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGWebTerminalIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 18 18' }, props), { children: [_jsx("path", { fill: "none", d: "M4.28589 6.71423L8.08713 8.99995L4.28589 11.2857", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M9.35419 11.2858H14.0846", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M0.5 3C0.5 2.44772 0.947715 2 1.5 2H16.5C17.0523 2 17.5 2.44772 17.5 3V15C17.5 15.5523 17.0523 16 16.5 16H1.5C0.947715 16 0.5 15.5523 0.5 15V3Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
