var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { OGLink } from '@opengear/links';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import { NETOPS_TEMPLATES_ROUTE } from '@/constants/routes';
import { useAnchorOpen } from '@/hooks/open';
import { useNetopsModuleNamesMap } from '@/api/netops/modules.constants';
export function ViewNetopsModules(_a) {
    var row = _a.row, tabIndex = _a.tabIndex, hasValidPermissions = _a.hasValidPermissions;
    var t = useTranslation().t;
    var _b = useAnchorOpen(), anchorEl = _b.anchorEl, open = _b.open, handleClose = _b.handleClose, handleToggle = _b.handleToggle;
    var nomModules = row.nom_modules;
    var nomModulesFormatMap = useNetopsModuleNamesMap();
    if (!nomModules || nomModules.length === 0) {
        if (hasValidPermissions) {
            return (_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: NETOPS_TEMPLATES_ROUTE }, { children: t('pages.subscriptions.tabs.subscription_assignment.table.netops.add_modules') })));
        }
        return _jsx(Typography, { children: t('pages.subscriptions.tabs.subscription_assignment.table.netops.add_modules') });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Popover, __assign({ open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, onClose: handleClose }, { children: _jsx(MuiList, __assign({ sx: { padding: 1.2 }, id: "".concat(row.id, "-modules-list"), "aria-label": t('pages.subscriptions.tabs.subscription_assignment.table.netops.label') }, { children: nomModules.map(function (module) { return (_jsx(MuiListItem, __assign({ sx: { padding: 0 } }, { children: module.name && nomModulesFormatMap.get(module.name)
                            ? nomModulesFormatMap.get(module.name)
                            : t('pages.subscriptions.tabs.subscription_assignment.table.unknown') }), "".concat(row.id, "-").concat(module.name))); }) })) })), _jsx(ToggleButton, __assign({ sx: { height: 20, width: 'max-content', marginLeft: 1, paddingX: 0.5, paddingY: 'auto', textTransform: 'initial', lineHeight: 1 }, "aria-expanded": open, "aria-haspopup": true, tabIndex: tabIndex, "aria-label": t('pages.subscriptions.tabs.subscription_assignment.table.netops.view_modules'), value: "checked", onClick: handleToggle }, { children: _jsx(Typography, __assign({ noWrap: true }, { children: format(nomModules.length === 1
                        ? t('pages.subscriptions.tabs.subscription_assignment.table.netops.module')
                        : t('pages.subscriptions.tabs.subscription_assignment.table.netops.modules'), nomModules.length.toString()) })) }))] }));
}
