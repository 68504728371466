var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useRef } from 'react';
var defaultFocus = { cell: null, columnHeader: null, columnHeaderFilter: null, columnGroupHeader: null };
var defaultFocusRowIndex = undefined;
var defaultTabIndex = { cell: null, columnHeader: null, columnHeaderFilter: null, columnGroupHeader: null };
var defaultTabRowIndex = undefined;
/**
 * This hook is necessary to restore the cell focus state when a column header is blurred.
 * Seems like a strange MUI behaviour, but could be intentional.
 * Without this, navigating from header column to cell, causes the state.focus.cell to be null instead of the focused cell.
 */
export function useFixColumnHeaderBlur(apiRef) {
    useEffect(function () {
        function handleColumnHeaderBlur() {
            // Assumes if tabIndex.cell is set, then the relatedTarget focus is on a cell or within a cell
            if (apiRef.current.state.tabIndex.cell) {
                apiRef.current.setState(function (prevState) { return (__assign(__assign({}, prevState), { focus: prevState.tabIndex })); });
            }
        }
        return apiRef.current.subscribeEvent('columnHeaderBlur', handleColumnHeaderBlur);
    }, [apiRef]);
}
/**
 * Hook to restore previous focus when a column header with class 'FocusDisabled' is in focus.
 */
export function useFixDisabledHeaderFocus(apiRef) {
    var previousFocusStateRef = useRef({ focus: defaultFocus, tabIndex: defaultTabIndex });
    useEffect(function () {
        function handleStateChange(state) {
            // DataGrid wants to focus on a header with class FocusDisabled, which isn't allowed
            if (state.tabIndex.columnHeader && state.columns.lookup[state.tabIndex.columnHeader.field].headerClassName === 'FocusDisabled') {
                // Restore focus and tabIndex to previous values
                apiRef.current.setState(function (prevState) { return (__assign(__assign({}, prevState), { focus: previousFocusStateRef.current.focus, tabIndex: previousFocusStateRef.current.tabIndex })); });
                return; // Exit without saving the current invalid state as previous
            }
            // Reset previous focus state
            previousFocusStateRef.current.focus = state.focus;
            previousFocusStateRef.current.tabIndex = state.tabIndex;
        }
        return apiRef.current.subscribeEvent('stateChange', handleStateChange);
    }, [apiRef]);
}
/**
 * Hook to only restore tabIndex to next element when a row is removed.
 * Next element could be:
 * - The same cell in the row that replaced the removed row
 * - The last cell in the row
 * - Or clear the tabIndex to reset (null)
 */
export function useFixTabIndexOnRemovedRow(apiRef) {
    var previousFocusStateRef = useRef({ tabIndex: defaultTabIndex, tabRowIndex: defaultTabRowIndex });
    useEffect(function () {
        function handleStateChange(state) {
            var _a;
            if (state.tabIndex.cell && !state.rows.dataRowIds.includes(state.tabIndex.cell.id)) {
                var newTabIndex_1 = { cell: null, columnHeader: null, columnHeaderFilter: null, columnGroupHeader: null };
                if (previousFocusStateRef.current.tabRowIndex !== undefined) {
                    var nextCellRowIndex = previousFocusStateRef.current.tabRowIndex > state.rows.dataRowIds.length - 1
                        ? previousFocusStateRef.current.tabRowIndex - 1
                        : previousFocusStateRef.current.tabRowIndex;
                    var nextCellRowId = state.rows.dataRowIds[nextCellRowIndex];
                    if (nextCellRowId !== undefined) {
                        newTabIndex_1.cell = {
                            id: nextCellRowId,
                            field: state.tabIndex.cell.field,
                        };
                    }
                }
                apiRef.current.setState(function (prevState) { return (__assign(__assign({}, prevState), { tabIndex: newTabIndex_1 })); });
            }
            // Reset previous focus state
            previousFocusStateRef.current.tabIndex = state.tabIndex;
            previousFocusStateRef.current.tabRowIndex =
                ((_a = state.tabIndex.cell) === null || _a === void 0 ? void 0 : _a.id) !== undefined ? apiRef.current.getRowIndexRelativeToVisibleRows(state.tabIndex.cell.id) : undefined;
        }
        return apiRef.current.subscribeEvent('stateChange', handleStateChange);
    }, [apiRef]);
}
/**
 * Hook to restore both tabIndex and focus to next element when a row is removed since the focus was on that row.
 * Next element could be:
 * - The same cell in the row that replaced the removed row
 * - The last cell in the row
 * - Or clear the tabIndex to reset (null)
 */
export function useFixFocusOnRemovedRow(apiRef) {
    var previousFocusStateRef = useRef({ focus: defaultFocus, focusRowIndex: defaultFocusRowIndex });
    useEffect(function () {
        function handleStateChange(state) {
            var _a, _b;
            /**
             * In order to determine if the focus needs restoring, the following conditions must be met:
             * - The cell focus was lost
             *   Calculated by the current focus being null and the previous focus being truthy
             * - The new focus is not on a header
             * - The previous focus, before it was lost, was on a row in the table and not on an element outside the table
             *   Calculated with the apiRef, and document.activeElement (which have stale values from the previous render)
             * If these conditions are met, force the user's focus, otherwise leave the user’s focus alone
             */
            if (state.focus.cell === null &&
                previousFocusStateRef.current.focus.cell &&
                state.focus.columnHeader === null &&
                ((_a = apiRef.current.getRowElement(previousFocusStateRef.current.focus.cell.id)) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement))) {
                if (previousFocusStateRef.current.focusRowIndex !== undefined) {
                    var nextCellRowIndex = previousFocusStateRef.current.focusRowIndex > state.rows.dataRowIds.length - 1
                        ? previousFocusStateRef.current.focusRowIndex - 1
                        : previousFocusStateRef.current.focusRowIndex;
                    var nextCellRowId = state.rows.dataRowIds[nextCellRowIndex];
                    if (nextCellRowId !== undefined) {
                        var newFocus_1 = {
                            cell: { id: nextCellRowId, field: previousFocusStateRef.current.focus.cell.field },
                            columnGroupHeader: null,
                            columnHeader: null,
                            columnHeaderFilter: null,
                        };
                        apiRef.current.setState(function (prevState) { return (__assign(__assign({}, prevState), { focus: newFocus_1, tabIndex: newFocus_1 })); });
                    }
                }
            }
            // Reset previous focus state
            previousFocusStateRef.current.focus = state.focus;
            previousFocusStateRef.current.focusRowIndex =
                ((_b = state.focus.cell) === null || _b === void 0 ? void 0 : _b.id) !== undefined ? apiRef.current.getRowIndexRelativeToVisibleRows(state.focus.cell.id) : undefined;
        }
        return apiRef.current.subscribeEvent('stateChange', handleStateChange);
    }, [apiRef]);
}
/**
 * Hook to immediately set focus to first visible column header
 */
export function useAutoFocus(apiRef, autoFocus) {
    var hasMounted = useRef(false);
    useEffect(function () {
        var _a;
        if (!hasMounted.current && autoFocus) {
            var autoFocusField = (_a = apiRef.current.getVisibleColumns()[0]) === null || _a === void 0 ? void 0 : _a.field;
            if (autoFocusField) {
                apiRef.current.setColumnHeaderFocus(autoFocusField);
            }
            hasMounted.current = true;
        }
    }, [apiRef, autoFocus]);
}
