var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { OGButton } from '@opengear/buttons';
import { OGPanel } from '@opengear/panel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useDeviceView } from '@/hooks/use-device-view';
import { useUpdateAlternateApiSettings } from '@/api/system/alternate-api';
import { useUpdateCliSessionTimeout } from '@/api/system/cli-session-timeout';
import { useUpdateWebuiSessionTimeout } from '@/api/system/webui-session-timeout';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { useFormSync } from '@/hooks/use-form-sync';
import { displayAPIRequestsResults } from '@/utils/form-submission-result';
import { invalidFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { NUM_ONLY_PATTERN } from '@/constants/regex';
export var MinWebUiTimeout = 1;
export var MinCliTimeout = 0;
export var MaxWebUiTimeout = 1440;
export var MaxCliTimeout = 1440;
export function SessionSettingsForm(_a) {
    var _this = this;
    var editable = _a.editable, altApiEnabled = _a.altApiEnabled, cliTimeout = _a.cliTimeout, webUiTimeout = _a.webUiTimeout, isFetchingData = _a.isFetchingData;
    var _b = useAlertNotification(), alertNotification = _b.alertNotification, closeRef = _b.closeRef;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    // Update hooks
    var updateAlternateApiSettings = useUpdateAlternateApiSettings();
    var updateCliSessionSettings = useUpdateCliSessionTimeout();
    var updateWebUISessionSettings = useUpdateWebuiSessionTimeout();
    var sourceValues = useMemo(function () { return ({
        apiEnabled: altApiEnabled,
        cliTimeout: cliTimeout.toString(),
        webUiTimeout: webUiTimeout.toString(),
    }); }, [altApiEnabled, cliTimeout, webUiTimeout]);
    var _c = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _c.control, handleSubmit = _c.handleSubmit, _d = _c.formState, defaultValues = _d.defaultValues, isDirty = _d.isDirty, isSubmitting = _d.isSubmitting, isValid = _d.isValid, dirtyFields = _d.dirtyFields, reset = _c.reset, resetField = _c.resetField;
    // responsible for alert the user of data changes
    useFormSync(defaultValues, sourceValues, reset, t('pages.session_settings.form.reset_warning.header'), isFetchingData);
    // Resets form back to current values from api or defaults
    var resetHandler = useCallback(function () {
        reset();
    }, [reset]);
    // Submit handler to update session settings
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var promises, updateResults, apiRequestResult;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    closeRef.current();
                    promises = [];
                    if (dirtyFields.webUiTimeout) {
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedData;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, updateWebUISessionSettings({
                                            timeout: parseInt(formData.webUiTimeout, 10),
                                        })];
                                    case 1:
                                        updatedData = _b.sent();
                                        if (updatedData) {
                                            // reset related fields for success case
                                            resetField('webUiTimeout', { defaultValue: (_a = updatedData.system_webui_session_timeout.timeout.toString()) !== null && _a !== void 0 ? _a : '' });
                                        }
                                        return [2 /*return*/, updatedData ? t('pages.session_settings.form.fields.system_webui_session_timeout.label') : undefined];
                                }
                            });
                        }); })());
                    }
                    if (dirtyFields.cliTimeout) {
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedData;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, updateCliSessionSettings({
                                            timeout: parseInt(formData.cliTimeout, 10),
                                        })];
                                    case 1:
                                        updatedData = _b.sent();
                                        if (updatedData) {
                                            // reset related fields for success case
                                            resetField('cliTimeout', { defaultValue: (_a = updatedData.system_cli_session_timeout.timeout.toString()) !== null && _a !== void 0 ? _a : '' });
                                        }
                                        return [2 /*return*/, updatedData ? t('pages.session_settings.form.fields.system_cli_session_timeout.label') : undefined];
                                }
                            });
                        }); })());
                    }
                    if (dirtyFields.apiEnabled) {
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, updateAlternateApiSettings({
                                            enabled: formData.apiEnabled,
                                        })];
                                    case 1:
                                        updatedData = _a.sent();
                                        if (updatedData) {
                                            // reset related fields for success case
                                            resetField('apiEnabled', { defaultValue: updatedData.system_alternate_api.enabled });
                                        }
                                        return [2 /*return*/, updatedData ? t('pages.session_settings.form.fields.system_alternate_api.label') : undefined];
                                }
                            });
                        }); })());
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 1:
                    updateResults = _a.sent();
                    apiRequestResult = displayAPIRequestsResults(updateResults);
                    if (apiRequestResult)
                        alertNotification(apiRequestResult.message
                            ? t('api.errors.partial_failure.message').replace('%n', apiRequestResult.message)
                            : t('pages.session_settings.form.submit.ok.message'), {
                            header: apiRequestResult.priority === OGToastPriorities.Warning ? t('api.errors.partial_failure.header') : undefined,
                            priority: apiRequestResult.priority,
                        });
                    return [2 /*return*/];
            }
        });
    }); }, [
        closeRef,
        dirtyFields.webUiTimeout,
        dirtyFields.cliTimeout,
        dirtyFields.apiEnabled,
        alertNotification,
        t,
        updateWebUISessionSettings,
        resetField,
        updateCliSessionSettings,
        updateAlternateApiSettings,
    ]);
    return (_jsx(OGPanel, { children: _jsxs(Stack, __assign({ "aria-label": t('pages.session_settings.form.label'), component: "form", onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Controller, { control: control, name: "webUiTimeout", rules: {
                        required: requiredFieldError(t('pages.session_settings.form.fields.system_webui_session_timeout.label')),
                        max: {
                            value: MaxWebUiTimeout,
                            message: maxValueFieldError(t('pages.session_settings.form.fields.system_webui_session_timeout.label'), MaxWebUiTimeout.toString()),
                        },
                        min: {
                            value: MinWebUiTimeout,
                            message: minValueFieldError(t('pages.session_settings.form.fields.system_webui_session_timeout.label'), MinWebUiTimeout.toString()),
                        },
                        // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
                        pattern: {
                            value: NUM_ONLY_PATTERN,
                            message: invalidFieldError(t('pages.session_settings.form.fields.system_webui_session_timeout.label')),
                        },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "webui_session_settings_timeout", disabled: !editable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.session_settings.form.fields.system_webui_session_timeout.label'), _jsx(OGIconTooltip, { title: t('pages.session_settings.form.fields.system_webui_session_timeout.description') })] }), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true, type: "number", inputProps: { min: 1, max: 1440 } }, field)));
                    } }), _jsx(Controller, { control: control, name: "cliTimeout", rules: {
                        required: requiredFieldError(t('pages.session_settings.form.fields.system_cli_session_timeout.label')),
                        max: {
                            value: MaxCliTimeout,
                            message: maxValueFieldError(t('pages.session_settings.form.fields.system_cli_session_timeout.label'), MaxCliTimeout.toString()),
                        },
                        min: {
                            value: MinCliTimeout,
                            message: minValueFieldError(t('pages.session_settings.form.fields.system_cli_session_timeout.label'), MinCliTimeout.toString()),
                        },
                        // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
                        pattern: {
                            value: NUM_ONLY_PATTERN,
                            message: invalidFieldError(t('pages.session_settings.form.fields.system_webui_session_timeout.label')),
                        },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "cli_session_settings_timeout", disabled: !editable, error: !!fieldState.error, helperText: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? fieldState.error.message : t('pages.session_settings.form.fields.system_cli_session_timeout.note'), label: _jsxs(_Fragment, { children: [t('pages.session_settings.form.fields.system_cli_session_timeout.label'), _jsx(OGIconTooltip, { title: t('pages.session_settings.form.fields.system_cli_session_timeout.description') })] }), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true, type: "number", inputProps: { min: 0, max: 1440 } }, field)));
                    } }), _jsxs(FormControl, __assign({ margin: "none" }, { children: [_jsxs(FormLabel, { children: [t('pages.session_settings.form.fields.system_alternate_api.label'), _jsx(OGIconTooltip, { title: t('pages.session_settings.form.fields.system_alternate_api.description') })] }), _jsx(Controller, { control: control, name: "apiEnabled", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                                return (_jsxs(ToggleButtonGroup, __assign({ disabled: !editable, exclusive: true, value: value.toString(), onChange: function (event, v) {
                                        if (!v)
                                            return;
                                        var parsedValue = JSON.parse(v);
                                        onChange(parsedValue);
                                    } }, field, { children: [_jsx(ToggleButton, __assign({ value: "false" }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: "true" }, { children: t('global.buttons.enabled.enabled') }))] })));
                            } })] })), _jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end", marginTop: 2 }, { children: [_jsx(OGButton, __assign({ id: "session-settings-reset", disabled: !isDirty || isSubmitting || !editable, variant: "borderless", onClick: resetHandler }, { children: t('global.buttons.reset') })), _jsx(OGButton, __assign({ id: "session-settings-submit", type: "submit", disabled: !isValid || !isDirty || !editable, loading: isSubmitting }, { children: t('global.buttons.apply') }))] }))] })) }));
}
