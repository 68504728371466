var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useHostname } from '@/api/system/hostname';
import { useHttpsPort } from '@/api/system/https-port';
import { useSshPort } from '@/api/system/ssh-port';
import { useExternalEndpoints } from '@/api/system/external-endpoints';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NetworkSettingsForm, NETWORK_SETTINGS_FORM_LOADER_ID, transformResponseToFormState } from '@/components/settings/system/network-settings';
import { PAGE_TITLE_ID } from '@/constants/ids';
function EditNetworkSettings() {
    var t = useTranslation().t;
    useDocTitle(t('pages.network_settings.title'));
    var _a = useHostname(), hostname = _a.data, isLoadingHostname = _a.isLoading, isFetchingHostname = _a.isFetching;
    var _b = useHttpsPort(), httpsPort = _b.data, isLoadingHttpsPort = _b.isLoading, isFetchingHttpsPort = _b.isFetching;
    var _c = useSshPort(), sshPort = _c.data, isLoadingSshPort = _c.isLoading, isFetchingSshPort = _c.isFetching;
    var _d = useExternalEndpoints(), externalEndpoints = _d.data, isLoadingExternalEndpoints = _d.isLoading, isFetchingExternalEndpoints = _d.isFetching;
    var _e = useUIPermissions(), hasPermission = _e.hasPermission, isLoadingPermissions = _e.isLoadingPermissions;
    var hasEditPermission = hasPermission('settings.system.administration.edit');
    var innerNode = null;
    if (isLoadingPermissions || isLoadingHostname || isLoadingHttpsPort || isLoadingSshPort || isLoadingExternalEndpoints) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": NETWORK_SETTINGS_FORM_LOADER_ID });
    }
    if (hostname && httpsPort && sshPort && externalEndpoints) {
        var data = transformResponseToFormState(__assign(__assign(__assign(__assign({}, hostname), httpsPort), sshPort), externalEndpoints));
        innerNode = (_jsx(NetworkSettingsForm, { data: data, isFetching: isFetchingHostname || isFetchingHttpsPort || isFetchingSshPort || isFetchingExternalEndpoints, permissions: { hasEditPermission: hasEditPermission } }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.network_settings.title') })), innerNode] })));
}
export default withAuthenticationRequired(EditNetworkSettings, { rights: ['settings.system.administration.view'] });
