import dayjs from 'dayjs';
export var ENTITLEMENTS_PATH = '/system/entitlements';
export var DEFAULT_MOCK_ENTITLEMENTS = [
    {
        id: 'OGLH',
        contact: { name: 'Customer Name', email: 'customeremail@orgname.com.au', phone: '12345678' },
        features: {
            nodes: 1000000,
            available_nodes: 999999,
            consumed_nodes: 1,
            maintenance: dayjs().add(3, 'years').unix(),
            additional: { thirdpartynodes: true, multipleinstance: true },
        },
        details: { bucket: 'max', code: 'ENTLH-MAX', description: 'LH - Max', duration: 'three_years', tier: 'enterprise' },
    },
    {
        id: 'OGLH-PMAX',
        contact: { name: 'Customer Name', email: 'customeremail@orgname.com.au', phone: '12345678' },
        features: {
            nodes: 1000000,
            available_nodes: 1000000,
            consumed_nodes: 0,
            maintenance: dayjs().add(3, 'years').unix(),
            additional: { thirdpartynodes: true, multipleinstance: true },
        },
        details: { bucket: 'max', code: 'AE-ENTLH-MAX', description: 'LH AE - Max', duration: 'three_years', tier: 'premium' },
    },
    {
        id: 'nom-prov',
        contact: { name: 'Customer Name', email: 'customeremail@orgname.com.au', phone: '12345678' },
        features: { nodes: 1000000, maintenance: 1761523200 },
    },
    {
        id: 'nom-ag',
        contact: { name: 'Customer Name', email: 'customeremail@orgname.com.au', phone: '12345678' },
        features: { nodes: 1000000, maintenance: 1761523200 },
    },
    {
        id: 'nom-sdi',
        contact: { name: 'Customer Name', email: 'customeremail@orgname.com.au', phone: '12345678' },
        features: { nodes: 2000000, maintenance: 1761523200 },
    },
];
