import { useTheme } from '@mui/material/styles';
/**
 * Returns a media query string for the given breakpoint based on the current MUI theme
 * @param breakpoint
 * @param inclusive if true (default) the media query will be inclusive (min-width), if false the media query will be exclusive (max-width)
 */
export var useThemeMediaQuery = function (breakpoint, inclusive) {
    if (inclusive === void 0) { inclusive = true; }
    var theme = useTheme();
    return inclusive ? theme.breakpoints.up(breakpoint) : theme.breakpoints.down(breakpoint);
};
