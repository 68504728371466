var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { OGLicenseWarning } from '@opengear/icons/status';
import format from 'string-format';
import { OGLink } from '@opengear/links';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { WarningBanner } from '@/components/warning-banner';
import { useEntitlementsData } from '@/api/system/entitlements';
import { DAY_IN_MS } from '@/constants/units';
import { OG_CONTACT_US_LINK } from '@/constants/links';
export function LicenseExpiryBanners() {
    var t = useTranslation().t;
    var _a = useEntitlementsData(), lighthouseSubscriptions = _a.lighthouseSubscriptions, isLoading = _a.isLoading;
    if (isLoading) {
        return null;
    }
    var licenseCode = '';
    var warningHeader = '';
    var warningMessage = null;
    var unusedSubscriptionBanners = [];
    var inuseSubscriptionBanners = [];
    var inuseSubscriptionCount = 0;
    lighthouseSubscriptions.forEach(function (entitlement) {
        if (!entitlement.expiration || !entitlement.details.code) {
            return;
        }
        if (entitlement.features.consumed_nodes) {
            inuseSubscriptionCount += 1;
        }
        var banners = entitlement.features.consumed_nodes ? inuseSubscriptionBanners : unusedSubscriptionBanners;
        if (entitlement.expiration.isExpired) {
            licenseCode = entitlement.details.code;
            warningHeader = format(t('components.license_expiry_warnings.expired_title'), entitlement.details.edition);
            warningMessage = (_jsxs(_Fragment, { children: [_jsx(Typography, { children: t('components.license_expiry_warnings.expired_message') }), _jsx(OGLink, __assign({ sx: { maxWidth: 'max-content' }, variant: "standard", external: true, endIcon: _jsx(OpenInNewIcon, { fontSize: "medium" }), to: OG_CONTACT_US_LINK }, { children: t('components.license_expiry_warnings.expiring_message') }))] }));
            banners.push(_jsx(WarningBanner, { warningIcon: _jsx(OGLicenseWarning, { style: { fontSize: '40px' } }), warningHeader: warningHeader, warningMessage: warningMessage, warningColor: "error", bannerCode: licenseCode, dismissPeriod: -1 }, licenseCode));
        }
        else if (entitlement.expiration.daysRemaining <= 90) {
            if (entitlement.expiration.daysRemaining <= 30) {
                licenseCode = "".concat(entitlement.details.code, "_30");
            }
            else if (entitlement.expiration.daysRemaining <= 60) {
                licenseCode = "".concat(entitlement.details.code, "_60");
            }
            else {
                licenseCode = "".concat(entitlement.details.code, "_90");
            }
            warningHeader = format(entitlement.expiration.daysRemaining > 1
                ? format(t('components.license_expiry_warnings.expiring_title_multiple_days'), entitlement.details.edition, entitlement.expiration.daysRemaining.toString())
                : format(t('components.license_expiry_warnings.expiring_title_one_day'), entitlement.details.edition));
            warningMessage = (_jsx(OGLink, __assign({ sx: { maxWidth: 'max-content' }, variant: "standard", external: true, endIcon: _jsx(OpenInNewIcon, { fontSize: "medium" }), to: OG_CONTACT_US_LINK }, { children: t('components.license_expiry_warnings.expiring_message') })));
            banners.push(_jsx(WarningBanner, { warningIcon: _jsx(OGLicenseWarning, { style: { fontSize: '40px' } }), warningHeader: warningHeader, warningMessage: warningMessage, warningColor: "warning", dismissable: true, bannerCode: licenseCode, dismissPeriod: entitlement.expiration.daysRemaining <= 30 ? DAY_IN_MS : -1 }, licenseCode));
        }
    });
    if (inuseSubscriptionBanners.length > 0) {
        return _jsx(Box, { children: inuseSubscriptionBanners });
    }
    if (inuseSubscriptionCount === 0 && unusedSubscriptionBanners.length > 0) {
        return _jsx(Box, { children: unusedSubscriptionBanners });
    }
    return null;
}
