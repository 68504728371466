var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { screen, within } from '@testing-library/react';
import { t } from 'i18next';
/**
 * Returns a set of utility functions for interacting with a table component in tests.
 * @experimental  This function is experimental and may change or be removed in future versions.
 * @param translations - The translations object containing the table's labels and messages.
 * @returns An object containing utility functions for interacting with the table.
 */
var getRowTestUtils = function (row) { return ({
    get: function () { return row; },
    getAllCells: function () { return within(row).getAllByRole('cell'); },
    getCell: function (columnLabel) {
        var cell = within(row)
            .getAllByRole('cell')
            .find(function (_cell) { return _cell.getAttribute('data-field') === columnLabel; });
        if (!cell) {
            throw new Error('Cell not found');
        }
        return cell;
    },
}); };
export var getTableTestUtils = function (_translations) {
    var translations = __assign(__assign({}, _translations), { columns: __assign({ __check__: { label: t('global.table.select_all_rows') } }, _translations.columns) });
    var getTableElement = function () { return screen.getByRole('grid', { name: translations.label }); };
    var getPaginationElement = function () { return screen.getByLabelText('pagination navigation', { selector: 'nav' }); };
    return {
        table: {
            get: getTableElement,
        },
        headerColumn: {
            get: function (columnHeader) { return within(getTableElement()).getByRole('columnheader', { name: translations.columns[columnHeader].label }); },
        },
        rows: {
            get: function () {
                return within(getTableElement())
                    .getAllByRole('row')
                    .filter(function (row) { return !Array.from(row.classList).some(function (className) { return className.includes('columnHeaderRow') || className.includes('footerContainer'); }); })
                    .map(function (row) { return getRowTestUtils(row); });
            },
        },
        noRowsMessage: {
            get: function () { return within(getTableElement()).getByText(translations.noRowsMessage); },
        },
        pagination: {
            get: function () { return getPaginationElement; },
            previousPageButton: {
                get: function () { return within(getPaginationElement()).getByRole('button', { name: 'Go to previous page' }); },
            },
            nextPageButton: {
                get: function () { return within(getPaginationElement()).getByRole('button', { name: 'Go to next page' }); },
            },
            pageButton: {
                get: function (page) { return within(getPaginationElement()).getByRole('button', { name: new RegExp(".*page ".concat(page, "$")) }); },
            },
            pageSizeSelect: {
                get: function () { return within(getTableElement()).getByLabelText(t('global.table.items_per_page')); },
            },
        },
    };
};
