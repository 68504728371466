var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useId, useMemo, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { DataGridPro, GridCell as MuiGridCell, GridOverlay, gridPaginationModelSelector, useGridApiContext, useGridApiRef, useGridSelector, GridFooterContainer, gridRowCountSelector, gridTabIndexStateSelector, gridVisibleColumnFieldsSelector, GridColumnHeaderTitle, } from '@mui/x-data-grid-pro';
import MuiExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MuiPagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import i18n from '@/i18n/config';
import { getCookie } from '@/utils/browser';
import { useAutoFocus, useFixColumnHeaderBlur, useFixDisabledHeaderFocus, useFixFocusOnRemovedRow, useFixTabIndexOnRemovedRow } from './table.hooks';
/*
  Initial loading state show progress spinner, otherwise return an empty div
  and rely on the animated cell content to show loading state.
*/
function LoadingOverlay(_a) {
    var ariaLabelPrefix = _a.ariaLabelPrefix, rowCount = _a.rowCount;
    if (rowCount > 0)
        return _jsx("div", {});
    return (_jsx(GridOverlay, __assign({ role: "row" }, { children: _jsx(Box, __assign({ sx: { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'top', paddingTop: '15px' }, role: "cell" }, { children: _jsx(CircularProgress, { "aria-label": ariaLabelPrefix && "".concat(ariaLabelPrefix, " loader"), color: "inherit" }) })) })));
}
function NoRowsOverlay(_a) {
    var _b = _a.message, message = _b === void 0 ? i18n.t('global.table.no_result') : _b;
    return (_jsx(Box, __assign({ sx: { width: '100%', height: '72px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ component: "span" }, { children: message })) })));
}
var GridCell = styled(MuiGridCell)({
    overflowWrap: 'anywhere',
});
function Cell(_a) {
    var children = _a.children, loading = _a.loading, otherCellProps = __rest(_a, ["children", "loading"]);
    return (_jsx(GridCell, __assign({}, otherCellProps, { children: loading ? (
        // return a placeholder div that has a standard max height
        _jsx(Skeleton, __assign({ animation: "wave", variant: "rectangular", width: "100%" }, { children: _jsx(Box, { height: "1.75rem" }) }))) : (children) })));
}
function TablePageSelection(_a) {
    var page = _a.page, pageCount = _a.pageCount, setPage = _a.setPage;
    return (_jsx(Box, __assign({ role: "cell" }, { children: _jsx(MuiPagination, { count: pageCount, page: page + 1, onChange: function (_, value) {
                setPage(value - 1);
            } }) })));
}
function TablePageInfo(_a) {
    var page = _a.page, pageSize = _a.pageSize, rowCount = _a.rowCount;
    return (_jsx(Box, __assign({ role: "cell" }, { children: _jsx(Typography, __assign({ role: "status" }, { children: "".concat(pageSize * page + (!rowCount ? 0 : 1), "-").concat(Math.min(pageSize * (page + 1), rowCount), " of ").concat(rowCount) })) })));
}
function TablePageSizeSelection(_a) {
    var pageSizeOptions = _a.pageSizeOptions, pageSize = _a.pageSize, setPageSize = _a.setPageSize;
    var t = useTranslation().t;
    var labelId = "".concat(useId(), "-page-size-label");
    var handleSelection = useCallback(function (event) {
        setPageSize(parseInt(event.target.value, 10));
    }, [setPageSize]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ id: labelId, sx: { lineHeight: 1.1, marginRight: 1.5, whiteSpace: 'pre-line' } }, { children: t('global.table.items_per_page') })), _jsx(Select, __assign({ labelId: labelId, sx: { minWidth: 56 }, value: "".concat(pageSize), onChange: handleSelection }, { children: pageSizeOptions.map(function (size) { return (_jsx(MenuItem, __assign({ value: size }, { children: size }), size)); }) }))] }));
}
function TableRowCount(_a) {
    var selectedRowCount = _a.selectedRowCount, entityLabel = _a.entityLabel;
    var t = useTranslation().t;
    if (selectedRowCount === undefined || entityLabel === undefined) {
        return null;
    }
    return (_jsxs(Typography, __assign({ sx: { lineHeight: 1.1, marginRight: 1.5, whiteSpace: 'pre-line' } }, { children: [selectedRowCount, ' ', selectedRowCount === 1 ? format(t('global.table.items_selected'), entityLabel) : format(t('global.table.items_selected_plural'), entityLabel)] })));
}
function TableFooter(_a) {
    var pagination = _a.pagination, pageSizeOptions = _a.pageSizeOptions, selectedRowCount = _a.selectedRowCount, entityLabel = _a.entityLabel;
    var apiRef = useGridApiContext();
    var _b = useGridSelector(apiRef, gridPaginationModelSelector), page = _b.page, pageSize = _b.pageSize;
    var rowCount = useGridSelector(apiRef, gridRowCountSelector);
    // useGridSelector(apiRef, gridPageCountSelector) is not currently working when paginationMode is 'server'
    var pageCount = useMemo(function () {
        if (rowCount === 0) {
            return 1;
        }
        return Math.ceil(rowCount / pageSize);
    }, [pageSize, rowCount]);
    if (!pagination) {
        return _jsx(Box, { borderTop: 1, borderColor: "table.divider" });
    }
    return (_jsxs(GridFooterContainer, __assign({ role: "row" }, { children: [_jsx(TablePageSelection, { page: page, pageCount: pageCount, setPage: apiRef.current.setPage }), _jsx(TablePageInfo, { page: page, rowCount: rowCount, pageSize: pageSize }), _jsxs(Box, __assign({ role: "cell", sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(TableRowCount, { selectedRowCount: selectedRowCount, entityLabel: entityLabel }), _jsx(TablePageSizeSelection, { pageSizeOptions: pageSizeOptions, pageSize: pageSize, setPageSize: apiRef.current.setPageSize })] }))] })));
}
export function OGTable(_a) {
    var autoFocus = _a.autoFocus, ariaLabel = _a["aria-label"], ariaDescribedby = _a["aria-describedby"], propColumns = _a["columns"], disableVirtualization = _a.disableVirtualization, loading = _a.loading, noRowsMessage = _a.noRowsMessage, onPaginationModelChange = _a.onPaginationModelChange, _b = _a.pageSizeOptions, pageSizeOptions = _b === void 0 ? [10, 20, 50, 100] : _b, selectedRowCount = _a.selectedRowCount, translations = _a.translations, _c = _a.pagination, pagination = _c === void 0 ? false : _c, _d = _a.paginationMode, paginationMode = _d === void 0 ? 'client' : _d, rows = _a.rows, propRowCount = _a["rowCount"], setCellFocus = _a.setCellFocus, otherDataGridProps = __rest(_a, ["autoFocus", 'aria-label', 'aria-describedby', 'columns', "disableVirtualization", "loading", "noRowsMessage", "onPaginationModelChange", "pageSizeOptions", "selectedRowCount", "translations", "pagination", "paginationMode", "rows", 'rowCount', "setCellFocus"]);
    var rowCountRef = useRef(propRowCount || 0);
    var rowCount = useMemo(function () {
        if (propRowCount !== undefined) {
            rowCountRef.current = propRowCount;
        }
        return rowCountRef.current;
    }, [propRowCount]);
    var apiRef = useGridApiRef();
    var t = useTranslation().t;
    var resetTabIndex = useCallback(function () {
        var _a;
        var currentTabIndexState = gridTabIndexStateSelector(apiRef.current.state);
        var _b = __read(gridVisibleColumnFieldsSelector(apiRef), 1), firstVisibleColumnField = _b[0];
        var columnHeader = (_a = currentTabIndexState.columnHeader) !== null && _a !== void 0 ? _a : { field: firstVisibleColumnField };
        apiRef.current.setState(function (prevState) { return (__assign(__assign({}, prevState), { tabIndex: { cell: null, columnHeader: columnHeader, columnHeaderFilter: null, columnGroupHeader: null } })); });
    }, [apiRef]);
    // TODO: IM-17109 - Remove useEffect and setCellFocus prop. Read ticket for more details.
    useEffect(function () {
        if (rows.length > 0 && !loading && setCellFocus) {
            var cellId = setCellFocus.id, field = setCellFocus.field, _a = setCellFocus.focus, focus_1 = _a === void 0 ? true : _a;
            var cellParams_1 = { id: cellId, field: field };
            apiRef.current.setState(function (prevState) { return (__assign(__assign({}, prevState), { tabIndex: { cell: cellParams_1, columnHeader: null, columnHeaderFilter: null, columnGroupHeader: null }, focus: { cell: focus_1 ? cellParams_1 : null, columnHeader: null, columnHeaderFilter: null, columnGroupHeader: null } })); });
        }
    }, [rows.length, loading, apiRef, setCellFocus]);
    useAutoFocus(apiRef, autoFocus);
    useFixColumnHeaderBlur(apiRef);
    useFixDisabledHeaderFocus(apiRef);
    useFixTabIndexOnRemovedRow(apiRef);
    useFixFocusOnRemovedRow(apiRef);
    var defaultRenderHeader = useCallback(function (_a) {
        var _b = _a.colDef, field = _b.field, headerName = _b.headerName, _c = _b.width, width = _c === void 0 ? 100 : _c;
        var label = headerName !== null && headerName !== void 0 ? headerName : field;
        // NOTE: Passing down the label as the description prop helps screen reader to identify this column as sortable
        // The description prop add an aria-label to the rendered element.
        return _jsx(GridColumnHeaderTitle, { description: label, label: label, columnWidth: width });
    }, []);
    var columns = useMemo(function () {
        return propColumns.map(function (columnDef) { return (__assign({ renderHeader: defaultRenderHeader }, columnDef)); });
    }, [propColumns, defaultRenderHeader]);
    var handlePaginationModelChange = useCallback(function (model, details) {
        // Uncontrolled pagination has no issue with tracking focus
        if (!onPaginationModelChange)
            return;
        onPaginationModelChange(model, details);
        // Always reset tabIndex on page changes
        resetTabIndex();
    }, [onPaginationModelChange, resetTabIndex]);
    return (_jsx(DataGridPro, __assign({ "aria-busy": loading, "aria-label": ariaLabel, "aria-describedby": ariaDescribedby, localeText: {
            checkboxSelectionSelectAllRows: t('global.table.select_all_rows'),
        }, columns: columns, onPaginationModelChange: handlePaginationModelChange, pagination: pagination, paginationMode: paginationMode, pageSizeOptions: pageSizeOptions, rowCount: rowCount, rows: rows, loading: loading, nonce: getCookie('nonce'), slots: {
            cell: Cell,
            columnSortedAscendingIcon: MuiExpandLessIcon,
            columnSortedDescendingIcon: MuiExpandMoreIcon,
            footer: TableFooter,
            loadingOverlay: LoadingOverlay,
            noRowsOverlay: NoRowsOverlay,
        }, slotProps: {
            cell: { loading: loading },
            footer: { pagination: pagination, pageSizeOptions: pageSizeOptions, selectedRowCount: selectedRowCount, entityLabel: translations === null || translations === void 0 ? void 0 : translations.entityLabel },
            loadingOverlay: { ariaLabelPrefix: ariaLabel, rowCount: rows.length },
            noRowsOverlay: { message: noRowsMessage },
        }, disableVirtualization: disableVirtualization !== undefined ? disableVirtualization : process.env.NODE_ENV === 'test' }, otherDataGridProps, { apiRef: apiRef })));
}
