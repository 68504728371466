var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import merge from 'lodash/merge';
import { useQuery } from '@tanstack/react-query';
import { decodeReadUiPermissionsResponse } from '../../../../../types/lighthouse/decoders/sessions/self/ui';
import { useDeleteSession } from '@/api/sessions';
import { DEPENDENT_PERMISSION_OVERRIDES, EXPIRED_LICENSE_PERMISSION_OVERRIDES, PERMISSIONS_PATH } from './ui.constants';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
function usePermissions(queryOptions) {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var signOut = useDeleteSession();
    var fetchPermissions = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(PERMISSIONS_PATH, __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadUiPermissionsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.permissions.get'));
                    if (!(status === 404)) return [3 /*break*/, 4];
                    return [4 /*yield*/, signOut(status)];
                case 3:
                    _b.sent();
                    return [2 /*return*/, undefined];
                case 4: return [2 /*return*/, status === 200 ? data.ui : null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, signOut, t]);
    return useQuery(__assign({ queryKey: [PERMISSIONS_PATH], queryFn: fetchPermissions }, queryOptions));
}
/**
 * Hook to fetch and migrate ui permissions
 */
export function useUIPermissions(queryOptions) {
    var _a, _b;
    var _c = usePermissions(queryOptions), permissions = _c.data, isLoadingPermissions = _c.isLoading;
    /**
     * Is a primary lighthouse instance, returns false if permissions are undefined
     */
    var isPrimary = useMemo(function () {
        if (!permissions)
            return false;
        return !permissions.is_secondary;
    }, [permissions]);
    /**
     * Permissions with expired licenses taken into effect
     */
    var effectivePrimaryPermissions = useMemo(function () {
        if (!permissions) {
            return undefined;
        }
        if (permissions.is_lic_expired) {
            return merge({}, permissions, EXPIRED_LICENSE_PERMISSION_OVERRIDES);
        }
        return permissions;
    }, [permissions]);
    /**
     * Permissions with expired licenses and secondary lighthouse taken into effect
     */
    var effectivePermissions = useMemo(function () {
        if (!effectivePrimaryPermissions) {
            return undefined;
        }
        if (effectivePrimaryPermissions.is_secondary) {
            return merge({}, effectivePrimaryPermissions, DEPENDENT_PERMISSION_OVERRIDES);
        }
        return merge({}, effectivePrimaryPermissions);
    }, [effectivePrimaryPermissions]);
    /**
     * @example
     *  hasPermission('configure.node_enrollment.enrolled_nodes.enroll')
     *  hasPermission('configure.node_enrollment.enrolled_nodes.enroll', { forcePrimary: true })
     *  hasPermission('configure.node_enrollment.enrolled_nodes.enroll', { ignoreExpiry: true })
     */
    var hasPermission = useCallback(function (right, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.forcePrimary, forcePrimary = _c === void 0 ? false : _c, _d = _b.ignoreExpiry, ignoreExpiry = _d === void 0 ? false : _d;
        var tmpPermissions = permissions;
        if ((permissions === null || permissions === void 0 ? void 0 : permissions.is_lic_expired) && !ignoreExpiry) {
            tmpPermissions = merge({}, tmpPermissions, EXPIRED_LICENSE_PERMISSION_OVERRIDES);
        }
        if ((permissions === null || permissions === void 0 ? void 0 : permissions.is_secondary) && !forcePrimary) {
            tmpPermissions = merge({}, tmpPermissions, DEPENDENT_PERMISSION_OVERRIDES);
        }
        if (tmpPermissions === undefined)
            return false;
        return get(tmpPermissions, right, false);
    }, [permissions]);
    /**
     * @example
     *  hasEveryPermission([
     *    'configure.node_enrollment.enrolled_nodes.enroll',
     *    'configure.node_enrollment.enrolled_nodes.view',
     *  ])
     *  hasEveryPermission([
     *    'configure.node_enrollment.enrolled_nodes.enroll',
     *    'configure.node_enrollment.enrolled_nodes.view',
     *  ], { forcePrimary: true })
     */
    var hasEveryPermission = useCallback(function (rights, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.forcePrimary, forcePrimary = _c === void 0 ? false : _c, _d = _b.ignoreExpiry, ignoreExpiry = _d === void 0 ? false : _d;
        if (permissions === undefined || rights.length === 0)
            return false;
        return rights.every(function (right) { return hasPermission(right, { forcePrimary: forcePrimary, ignoreExpiry: ignoreExpiry }); });
    }, [hasPermission, permissions]);
    var hasSomePermission = useCallback(function (rights, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.forcePrimary, forcePrimary = _c === void 0 ? false : _c, _d = _b.ignoreExpiry, ignoreExpiry = _d === void 0 ? false : _d;
        if (permissions === undefined || rights.length === 0)
            return false;
        return rights.some(function (right) { return hasPermission(right, { forcePrimary: forcePrimary, ignoreExpiry: ignoreExpiry }); });
    }, [hasPermission, permissions]);
    var username = (_a = permissions === null || permissions === void 0 ? void 0 : permissions.username) !== null && _a !== void 0 ? _a : null;
    var timeout = (_b = permissions === null || permissions === void 0 ? void 0 : permissions.session_timeout) !== null && _b !== void 0 ? _b : null;
    return {
        permissions: effectivePermissions,
        hasPermission: hasPermission,
        hasEveryPermission: hasEveryPermission,
        hasSomePermission: hasSomePermission,
        isLoadingPermissions: isLoadingPermissions,
        isPrimary: isPrimary,
        username: username,
        timeout: timeout,
    };
}
