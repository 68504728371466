export var transformToRequestObject = function (formData) {
    var id = formData.id, authMode = formData.authMode, name = formData.name, description = formData.description;
    switch (authMode) {
        case 'local':
            return {
                id: id,
                template: {
                    type: 'auth',
                    authMode: 'local',
                    name: name,
                    description: description,
                },
            };
        case 'radius':
            return {
                id: id,
                template: {
                    type: 'auth',
                    authMode: 'radius',
                    name: name,
                    description: description,
                    radiusAuthenticationServers: formData.radiusAuthenticationServers || [],
                    radiusAccountingServers: formData.radiusAccountingServers || [],
                    radiusPassword: formData.radiusPassword || '',
                },
            };
        case 'tacacs':
            return {
                id: id,
                template: {
                    type: 'auth',
                    authMode: 'tacacs',
                    name: name,
                    description: description,
                    tacacsService: formData.tacacsService,
                    tacacsAuthenticationServers: formData.tacacsAuthenticationServers || [],
                    tacacsMethod: formData.tacacsMethod || 'pap',
                    tacacsPassword: formData.tacacsPassword,
                },
            };
        case 'ldap':
            return {
                id: id,
                template: {
                    type: 'auth',
                    authMode: 'ldap',
                    name: formData.name || '',
                    description: formData.description || '',
                    ldapAuthenticationServers: formData.ldapAuthenticationServers || [],
                    ldapBaseDN: formData.ldapBaseDN || '',
                    ldapBindDN: formData.ldapBindDN || '',
                    ldapGroupMembershipAttribute: formData.ldapGroupMembershipAttribute || '',
                    ldapIgnoreReferrals: formData.ldapIgnoreReferrals || false,
                    ldapSslCaCert: formData.ldapSslCaCert || '',
                    ldapSslIgnoreCertErrors: formData.ldapSslIgnoreCertErrors || false,
                    ldapSslMode: formData.ldapSslMode || 'ldaps_preferred',
                    ldapUsernameAttribute: formData.ldapUsernameAttribute || '',
                    ldapBindPassword: formData.ldapBindPassword || '',
                },
            };
        default:
            return null;
    }
};
export var transformToResponseObject = function (data) { return ({
    id: (data === null || data === void 0 ? void 0 : data.id) || '',
    name: (data === null || data === void 0 ? void 0 : data.name) || '',
    description: (data === null || data === void 0 ? void 0 : data.description) || '',
    ldapAuthenticationServers: (data === null || data === void 0 ? void 0 : data.ldapAuthenticationServers) && data.ldapAuthenticationServers.length > 0 ? data.ldapAuthenticationServers : [{ hostname: '', port: null }],
    ldapBaseDN: (data === null || data === void 0 ? void 0 : data.ldapBaseDN) || '',
    ldapBindDN: (data === null || data === void 0 ? void 0 : data.ldapBindDN) || '',
    ldapGroupMembershipAttribute: (data === null || data === void 0 ? void 0 : data.ldapGroupMembershipAttribute) || '',
    ldapIgnoreReferrals: (data === null || data === void 0 ? void 0 : data.ldapIgnoreReferrals) || false,
    ldapSslCaCert: (data === null || data === void 0 ? void 0 : data.ldapSslCaCert) || '',
    ldapSslIgnoreCertErrors: (data === null || data === void 0 ? void 0 : data.ldapSslIgnoreCertErrors) || false,
    ldapSslMode: (data === null || data === void 0 ? void 0 : data.ldapSslMode) || 'ldaps_preferred',
    ldapUsernameAttribute: (data === null || data === void 0 ? void 0 : data.ldapUsernameAttribute) || '',
    radiusAuthenticationServers: (data === null || data === void 0 ? void 0 : data.radiusAuthenticationServers) && data.radiusAuthenticationServers.length > 0 ? data.radiusAuthenticationServers : [{ hostname: '', port: 1812 }],
    radiusAccountingServers: (data === null || data === void 0 ? void 0 : data.radiusAccountingServers) || [],
    tacacsService: (data === null || data === void 0 ? void 0 : data.tacacsService) || '',
    tacacsAuthenticationServers: (data === null || data === void 0 ? void 0 : data.tacacsAuthenticationServers) && data.tacacsAuthenticationServers.length > 0 ? data.tacacsAuthenticationServers : [{ hostname: '', port: 49 }],
    tacacsMethod: (data === null || data === void 0 ? void 0 : data.tacacsMethod) || 'pap',
    type: (data === null || data === void 0 ? void 0 : data.type) || 'auth',
    authMode: (data === null || data === void 0 ? void 0 : data.authMode) || 'local',
    radiusPassword: '',
    confirmRadiusPassword: '',
    tacacsPassword: '',
    confirmTacacsPassword: '',
    ldapBindPassword: '',
    confirmLdapBindPassword: '',
}); };
