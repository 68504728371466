var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useReducer } from 'react';
// Context
var RoleFormWarningsContext = createContext(undefined);
// Consumers
export var useRolePermissionWarnings = function () {
    var roleFormWarningsContext = useContext(RoleFormWarningsContext);
    if (!roleFormWarningsContext) {
        throw Error('useRoleFormWarnings must be wrapped in RoleFormWarningsContext');
    }
    return roleFormWarningsContext;
};
export function useRolePermissionWarningByKey(key) {
    var _a = __read(useRolePermissionWarnings(), 1), roleFormWarningsState = _a[0];
    return roleFormWarningsState.get(key);
}
export function useRolePermissionWarningsCount() {
    var _a = __read(useRolePermissionWarnings(), 1), roleFormWarningsState = _a[0];
    return roleFormWarningsState.size;
}
// Reducer
var rolePermissionWarningsReducer = function (state, action) {
    switch (action.type) {
        case 'set':
            state.set(action.payload.key, action.payload.message);
            return new Map(__spreadArray([], __read(state.entries()), false));
        case 'clear':
            state.delete(action.payload.key);
            return new Map(__spreadArray([], __read(state.entries()), false));
        default:
            /* istanbul ignore next */
            return state;
    }
};
// Provider
export function RolePermissionWarningsProvider(_a) {
    var children = _a.children;
    var _b = __read(useReducer(rolePermissionWarningsReducer, new Map()), 2), warnings = _b[0], dispatch = _b[1];
    var value = useMemo(function () { return [warnings, dispatch]; }, [warnings]);
    return _jsx(RoleFormWarningsContext.Provider, __assign({ value: value }, { children: children }));
}
