var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { requiredFieldError } from '@/utils/field-validation-errors';
export function FactoryResetWarningText() {
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ id: "reset-warning-header", component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 700, fontSize: 16 } }, { children: t('pages.backup_restore_reset.tabs.config_reset.warning.header') })), _jsx(Typography, __assign({ sx: { whiteSpace: 'pre-line' }, id: "reset-warning-msg", gutterBottom: true }, { children: t('pages.backup_restore_reset.tabs.config_reset.warning.message') }))] }));
}
export function FactoryResetConfirmationField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var confirmationText = /^yes$/i;
    var _a = useFormContainerContext(), control = _a.control, formState = _a.formState, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirmation", rules: {
            required: requiredFieldError(t('pages.backup_restore_reset.tabs.config_reset.confirmation.label')),
            pattern: {
                value: confirmationText,
                message: t('pages.backup_restore_reset.tabs.config_reset.confirmation.errors.invalid'),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "confirmation", label: t('pages.backup_restore_reset.tabs.config_reset.confirmation.label'), required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? undefined : 200 } }, field, { disabled: !permissions.hasEditPermission || formState.isSubmitted })));
        } }));
}
