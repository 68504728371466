var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGFileTracker, OGFileUploader } from '@opengear/file-uploader';
import { isUrlValid } from '@/utils/calculations';
import { useFormContainerContext } from '@/components/layout/forms';
export function MethodField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var options = ['url', 'file'];
    return (_jsx(Controller, { control: control, name: "method", rules: { required: true }, render: function (_a) {
            var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "method", inputRef: ref, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('components.system_upgrade.form.fields.method.label'), select: true, sx: { width: 300, maxWidth: '100%' } }, field, { children: options.map(function (option) { return (_jsx(MenuItem, __assign({ value: option }, { children: t("components.system_upgrade.form.fields.method.options.".concat(option)) }), option)); }) })));
        } }));
}
export function ImageUrlField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "imageUrl", rules: {
            required: t('components.system_upgrade.form.fields.image_url.errors.required'),
            validate: function (url) { return isUrlValid(url) || t('components.system_upgrade.form.fields.image_url.errors.invalid_pattern'); },
        }, render: function (_a) {
            var _b;
            var _c = _a.field, ref = _c.ref, field = __rest(_c, ["ref"]), fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "image_url", inputRef: ref, disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, fullWidth: true, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('components.system_upgrade.form.fields.image_url.label') }, field)));
        } }));
}
export function ImageFileField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, watch = _a.watch;
    var imageFileWatch = watch('imageFile');
    return (_jsx(Controller, { control: control, name: "imageFile", rules: {
            required: t('components.system_upgrade.form.fields.image_file.errors.required'),
        }, render: function (_a) {
            var field = _a.field;
            return (_jsxs(Box, __assign({ marginBottom: 1.5 }, { children: [_jsx(InputLabel, __assign({ sx: { marginBottom: 0.75 } }, { children: t('components.system_upgrade.form.fields.image_file.label') })), _jsx(Box, { children: _jsx(OGFileUploader, __assign({ id: "imageFile", accept: ".lh_upg", ariaLabel: t('components.system_upgrade.form.fields.image_file.label'), onChange: function (files) {
                                var _a;
                                field.onChange((_a = files[0]) !== null && _a !== void 0 ? _a : null);
                            }, disabled: !permissions.hasEditPermission || imageFileWatch !== null, required: true }, { children: imageFileWatch && (_jsx(OGFileTracker, { id: imageFileWatch.name, name: imageFileWatch.name, onCancel: function () { return field.onChange(null); } }, imageFileWatch.name)) })) })] })));
        } }));
}
export function OptionsField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "options", render: function (_a) {
            var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "options", inputRef: ref, disabled: !permissions.hasEditPermission, error: !!fieldState.error, fullWidth: true, label: _jsxs(_Fragment, { children: [t('components.system_upgrade.form.fields.options.label'), _jsx(OGIconTooltip, { title: t('components.system_upgrade.form.fields.options.tooltip') })] }) }, field)));
        } }));
}
