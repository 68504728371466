var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { ContactSalesLink } from './contact-sales-link';
export function LicenseItemTitle(_a) {
    var licenseTier = _a.licenseTier, subscription = _a.subscription;
    var t = useTranslation().t;
    var title = '';
    var expireMessage = '';
    var titleIcon = null;
    if ((subscription === null || subscription === void 0 ? void 0 : subscription.expiration) && (subscription === null || subscription === void 0 ? void 0 : subscription.expiration.daysRemaining) <= 45) {
        titleIcon = _jsx(WarningIcon, { "aria-hidden": "false", color: "warning", "aria-labelledby": "expire-message", fontSize: "medium" });
        expireMessage = t('pages.subscriptions.tabs.subscriptions.subscription_item.expire_soon_message');
    }
    if ((subscription === null || subscription === void 0 ? void 0 : subscription.expiration) && (subscription === null || subscription === void 0 ? void 0 : subscription.expiration.isExpired) && (subscription === null || subscription === void 0 ? void 0 : subscription.expiration.daysRemaining) === 0) {
        titleIcon = _jsx(ErrorIcon, { "aria-hidden": "false", color: "error", "aria-labelledby": "expire-message", fontSize: "medium" });
        expireMessage = t('pages.subscriptions.tabs.subscriptions.subscription_item.expired_message');
    }
    if (subscription) {
        switch (true) {
            case (subscription === null || subscription === void 0 ? void 0 : subscription.details.bucket) === 'eval':
                title = t('pages.subscriptions.tabs.subscriptions.subscription_item.evaluation.evaluation_title');
                break;
            case !(subscription === null || subscription === void 0 ? void 0 : subscription.details.description):
                title = t('pages.subscriptions.tabs.subscriptions.subscription_item.lighthouse_enterprise');
                break;
            default:
                title = subscription === null || subscription === void 0 ? void 0 : subscription.details.edition;
        }
    }
    else {
        title = t('pages.subscriptions.tabs.subscriptions.subscription_item')[licenseTier].title;
    }
    return (_jsxs(Box, __assign({ marginBottom: 2 }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [titleIcon && _jsx("span", __assign({ style: { marginRight: '8px' } }, { children: titleIcon })), _jsx(Typography, __assign({ component: "h3", variant: "h3" }, { children: title }))] })), expireMessage && (subscription === null || subscription === void 0 ? void 0 : subscription.details.bucket) !== 'eval' && (_jsxs(Box, __assign({ marginTop: 2, id: "expire-message" }, { children: [expireMessage, _jsx(ContactSalesLink, {})] })))] })));
}
