var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { OGButton } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { OGNotFoundIcon } from '@opengear/icons/navigation';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDeviceView } from '@/hooks/use-device-view';
import { DASHBOARD_ROUTE } from '@/constants/routes';
function NotFound() {
    var t = useTranslation().t;
    useDocTitle(t('pages.not_found.title'));
    var isMobileView = useDeviceView().isMobileView;
    var navigate = useNavigate();
    var handleNavigateToDashboard = useCallback(function () {
        navigate(DASHBOARD_ROUTE);
    }, [navigate]);
    return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column-reverse' : 'row', justifyContent: "center", gap: isMobileView ? 0 : 5, marginTop: isMobileView ? 5 : 20 }, { children: [_jsxs(Stack, __assign({ direction: "column", alignItems: "center", justifyContent: "center", sx: { paddingLeft: isMobileView ? undefined : 2 } }, { children: [_jsx(Typography, __assign({ component: "h1", variant: "h1", sx: { fontSize: isMobileView ? 150 : '10vw' } }, { children: t('pages.not_found.content.title') })), _jsx(Typography, __assign({ paragraph: true, variant: "h2", paddingTop: 1, textTransform: "none", noWrap: true }, { children: t('pages.not_found.content.header') })), _jsx(Typography, __assign({ paragraph: true, variant: "h3", paddingBottom: 1, textTransform: "none", noWrap: true }, { children: t('pages.not_found.content.message') })), _jsx(OGButton, __assign({ id: "home-button", onClick: handleNavigateToDashboard }, { children: t('pages.not_found.buttons.back_to_dashboard') }))] })), _jsx(Stack, __assign({ alignSelf: "center" }, { children: _jsx(OGNotFoundIcon, { sx: { maxWidth: '100%', maxHeight: '100%', fontSize: 500 } }) }))] })));
}
// clear route history so a user will not ever be redirected to the notfound page on sign in
export default withAuthenticationRequired(NotFound, { clearRouteHistory: true });
