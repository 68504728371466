export function transformSmfApiDataToForm(smfSettings) {
    return {
        enabled: smfSettings.enabled,
        ospf_internal_area: smfSettings.ospf_internal_area,
    };
}
export function transformSmfFormDataToApi(formData) {
    return {
        enabled: formData.enabled,
        ospf_internal_area: formData.ospf_internal_area,
    };
}
