var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import format from 'string-format';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import Stack from '@mui/material/Stack';
import { useNodes } from '@/api/nodes';
import { useBulkApproveNodes } from '@/api/nodes/bulk/approve';
import { useBulkUnenrollNodes } from '@/api/nodes/bulk/unenroll';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { PendingStateCell } from '@/components/nodes/pending-state-cell';
import { useEntitlementsData } from '@/api/system/entitlements';
import { subscriptionTierMap } from '@/utils/license';
import { JobModal } from '@/components/jobs/job-modal';
import { NodeFilter, useNodeFilter } from '@/containers/filters/advanced-filters/node-filter';
import { connectionStatusToQuery, NodeConnectionStatus, useJson } from '@/utils/query';
import { QUERY_PARAM_JOB_ID_KEY } from '@/components/jobs/jobs-table';
import { PendingStatusCell } from '@/components/nodes/pending-status-cell';
import { IQueryNodeField } from '../../../types/query/types';
import { useFreeTextSearch, FreeTextSearch } from '@/components/free-text-search';
import { PendingDeleteDialog } from '@/components/nodes/pending-delete-dialog';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { BulkErrorMessage } from '@/components/nodes/bulk-error-message';
import { BulkOperations } from '@/components/nodes/bulk-operations';
import { formatMessageWithList, processBulkResult } from '@/utils/calculations';
import { formatLastOnline, getLastOnlineValue } from '@/containers/nodes/node-table-components';
import { renderAddress } from './node-status-connected-table';
import { isNodeConnected } from './node-utils';
import { RemoveCell } from '@/components/remove-cell';
function renderBundle(_a) {
    var value = _a.value;
    return _jsx("span", __assign({ style: { overflow: 'hidden', textOverflow: 'ellipsis' } }, { children: value }));
}
var statusQuery = connectionStatusToQuery(NodeConnectionStatus.Pending);
var statusFieldNames = [IQueryNodeField.name, IQueryNodeField.id, IQueryNodeField.status, IQueryNodeField.model, IQueryNodeField.serialNumber];
export var NodesPendingTable = memo(function (_a) {
    var permissions = _a.permissions;
    var canDeleteNodes = permissions.canDeleteNodes, canApproveNodes = permissions.canApproveNodes, editableNodeFilters = permissions.editableNodeFilters, isPrimary = permissions.isPrimary;
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var failureAlertNotification = useAlertNotification().alertNotification;
    // Remove row focus management
    var _b = __read(useState(undefined), 2), cellFocus = _b[0], setCellFocus = _b[1];
    var _c = __read(useSearchParams(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var modalJobId = useMemo(function () { return (searchParams.get(QUERY_PARAM_JOB_ID_KEY) ? searchParams.get(QUERY_PARAM_JOB_ID_KEY) : undefined); }, [searchParams]);
    var _d = __read(useState(new Set([])), 2), submittingNodeIds = _d[0], setSubmittingNodeIds = _d[1];
    var _e = __read(useState(), 2), nodeIdsToDelete = _e[0], setNodeIdsToDelete = _e[1];
    var _f = __read(useState([]), 2), rowSelectionModel = _f[0], setRowSelectionModel = _f[1];
    // Table defaults
    var defaultOrderBy = useMemo(function () { return (isPrimary ? 'last_updated' : 'name'); }, [isPrimary]);
    // Table controls - Server side available query params
    var _g = __read(usePaginationModel('nodes_pending'), 3), paginationModel = _g[0], handlePaginationModelChange = _g[1], pageSizeOptions = _g[2];
    var _h = __read(useSortModel(defaultOrderBy), 3), defaultSortModel = _h[0], sortQueryParams = _h[1], handleSortModelChange = _h[2];
    // Node filter
    var _j = useNodeFilter(), nodeFilter = _j.nodeFilter, setNodeFilter = _j.setNodeFilter;
    // Free text search
    var _k = useFreeTextSearch(statusFieldNames), textQueryObject = _k.textQueryObject, dispatchTextQuery = _k.dispatchTextQuery;
    // TODO - CDM-183 - extend logic
    // Aggregate all query items into one json query string
    var json = useJson(statusQuery, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject, textQueryObject);
    // Query options
    var queryParams = useMemo(function () { return (__assign(__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize, json: json }, sortQueryParams), { get_last_job: true })); }, [paginationModel, json, sortQueryParams]);
    // Get Nodes Fetch
    var _l = useNodes(queryParams), data = _l.data, isLoading = _l.isLoading;
    // Derived table values
    var _m = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.nodes) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.nodes, isLoading]), loading = _m.loading, rowCount = _m.rowCount, rows = _m.rows;
    // Get entitlements
    var _o = useEntitlementsData(), availableNodes = _o.availableNodes, isLoadingEntitlements = _o.isLoading;
    var bulkApproveNodes = useBulkApproveNodes();
    var bulkUnenrollNodes = useBulkUnenrollNodes();
    var refocusRef = useRef(null);
    var handleJobModalClose = useCallback(function () {
        setSearchParams(function (prevSearchParams) {
            prevSearchParams.delete(QUERY_PARAM_JOB_ID_KEY);
            return prevSearchParams;
        });
    }, [setSearchParams]);
    var handleDeleteDialogClose = useCallback(function () {
        setNodeIdsToDelete(undefined);
    }, []);
    // bulk approve nodes handler
    var handleBulkApprove = useCallback(function (nodeIds) { return __awaiter(void 0, void 0, void 0, function () {
        var nodeIdsToApprove, approveResult, _a, successIds, errorDetails;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    nodeIdsToApprove = nodeIds.filter(function (id) { return !submittingNodeIds.has(id); });
                    setSubmittingNodeIds(function (ids) {
                        nodeIdsToApprove.forEach(function (id) {
                            ids.add(id);
                        });
                        return new Set(__spreadArray([], __read(ids), false));
                    });
                    return [4 /*yield*/, bulkApproveNodes(nodeIdsToApprove)];
                case 1:
                    approveResult = _b.sent();
                    if (!approveResult)
                        return [2 /*return*/];
                    _a = processBulkResult(approveResult), successIds = _a.successIds, errorDetails = _a.errorDetails;
                    // Show success notification to user
                    if (successIds.length) {
                        setRowSelectionModel(function (ids) { return ids.filter(function (id) { return !successIds.includes(id); }); });
                        alertNotification(format(t('api.nodes_bulk_approve.post.ok.message'), formatMessageWithList(successIds)), {
                            header: t('api.nodes_bulk_approve.post.ok.header'),
                            priority: OGToastPriorities.Success,
                        });
                    }
                    // Show notification to user that approve request for some nodes was failed
                    if (errorDetails.length) {
                        failureAlertNotification(_jsx(BulkErrorMessage, { message: format(t('api.nodes_bulk_approve.post.failed.message'), formatMessageWithList(errorDetails.map(function (errorItem) { return errorItem.id; }))), errorDetails: errorDetails }), {
                            header: t('api.nodes_bulk_approve.post.error.message'),
                            persist: true,
                        });
                    }
                    setSubmittingNodeIds(function (ids) {
                        nodeIdsToApprove.forEach(function (id) {
                            ids.delete(id);
                        });
                        return new Set(__spreadArray([], __read(ids), false));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, bulkApproveNodes, failureAlertNotification, submittingNodeIds, t]);
    // bulk unenroll nodes handler
    var handleBulkUnenroll = useCallback(function (nodeIds) { return __awaiter(void 0, void 0, void 0, function () {
        var nodeIdsToUnenroll, unenrollResult, _a, successIds, errorDetails;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    nodeIdsToUnenroll = nodeIds.filter(function (id) { return !submittingNodeIds.has(id); });
                    setSubmittingNodeIds(function (ids) {
                        nodeIdsToUnenroll.forEach(function (nodeId) {
                            ids.add(nodeId);
                        });
                        return new Set(__spreadArray([], __read(ids), false));
                    });
                    return [4 /*yield*/, bulkUnenrollNodes(nodeIdsToUnenroll)];
                case 1:
                    unenrollResult = _b.sent();
                    handleDeleteDialogClose();
                    // Set focus to next row
                    setCellFocus(cellFocus ? __assign(__assign({}, cellFocus), { focus: true }) : undefined);
                    if (!unenrollResult)
                        return [2 /*return*/];
                    _a = processBulkResult(unenrollResult), successIds = _a.successIds, errorDetails = _a.errorDetails;
                    // Show success notification to user
                    if (successIds.length) {
                        setRowSelectionModel(function (ids) { return ids.filter(function (id) { return !successIds.includes(id); }); });
                        alertNotification(format(t('api.nodes_bulk_unenroll.post.ok.message'), formatMessageWithList(successIds)), {
                            header: t('api.nodes_bulk_unenroll.post.ok.header'),
                            priority: OGToastPriorities.Success,
                        });
                    }
                    // Show notification to user that unenroll request for some nodes was failed
                    if (errorDetails.length) {
                        failureAlertNotification(_jsx(BulkErrorMessage, { message: format(t('api.nodes_bulk_unenroll.post.failed.message'), formatMessageWithList(errorDetails.map(function (errorItem) { return errorItem.id; }))), errorDetails: errorDetails }), {
                            header: t('api.nodes_bulk_unenroll.post.failed.header'),
                            persist: true,
                        });
                    }
                    setSubmittingNodeIds(function (ids) {
                        nodeIdsToUnenroll.forEach(function (nodeId) {
                            ids.delete(nodeId);
                        });
                        return new Set(__spreadArray([], __read(ids), false));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, bulkUnenrollNodes, handleDeleteDialogClose, failureAlertNotification, submittingNodeIds, t, cellFocus]);
    // true is single node can be approved
    var canPendingApprove = useCallback(function (node) {
        var _a, _b;
        if (node === undefined)
            return false;
        // Calculate all false cases
        var nodeRegistered = node.status === 'Registered';
        var nodeUnenrolling = node.runtime_status.action_type === 'unenrollment';
        var subscriptionExpired = (_a = node.subscription) === null || _a === void 0 ? void 0 : _a.expired;
        var inProgress = node.runtime_status.action_status === 'in progress';
        var subscriptionLimitReached = ((_b = node.subscription) === null || _b === void 0 ? void 0 : _b.tier) ? (availableNodes.get(node.subscription.tier) || 0) < 1 : false;
        return (isNodeConnected(node),
            nodeRegistered &&
                !submittingNodeIds.has(node.id) &&
                !isLoadingEntitlements &&
                !node.approved &&
                !nodeUnenrolling &&
                !subscriptionExpired &&
                !inProgress &&
                !subscriptionLimitReached);
    }, [availableNodes, isLoadingEntitlements, submittingNodeIds]);
    // true is single node can be unenrolled
    var canPendingUnenroll = useCallback(function (node) {
        if (node === undefined)
            return false;
        return node.runtime_status.action_type !== 'unenrollment' && !submittingNodeIds.has(node.id);
    }, [submittingNodeIds]);
    // Approve button
    var renderPendingApproveCell = useCallback(function (_a) {
        var node = _a.row, tabIndex = _a.tabIndex;
        var disabled = !canApproveNodes || !canPendingApprove(node);
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: disabled ? t('pages.nodes.table.buttons.approve_tooltip_disabled') : t('pages.nodes.table.buttons.approve_tooltip') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": format(t('pages.nodes.table.buttons.approve_label'), node.id), disabled: disabled, size: "small", startIcon: _jsx(DoneIcon, {}), variant: "borderless", tabIndex: tabIndex, onClick: function () { return handleBulkApprove([node.id]); }, propagateSpacebar: false }) }) })));
    }, [canApproveNodes, canPendingApprove, handleBulkApprove, t]);
    // Unenroll button
    var renderPendingUnenrollCell = useCallback(function (_a) {
        var node = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        var disabled = !canDeleteNodes || !canPendingUnenroll(node);
        return (_jsx(RemoveCell, { index: api.getRowIndexRelativeToVisibleRows(node.id), currentCount: rows.length, tabIndex: tabIndex, onRemove: function (nextFocusIndex) {
                // Set focus to next row
                if (typeof nextFocusIndex !== 'undefined') {
                    var nextFocusRowId = rows[nextFocusIndex] ? rows[nextFocusIndex].id : undefined;
                    setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'unenroll', focus: false } : undefined);
                }
                setNodeIdsToDelete([node.id]);
            }, disabled: disabled, translation: {
                label: format(t('pages.nodes.table.buttons.unenroll_label'), node.id),
                tooltip: disabled ? t('pages.nodes.table.buttons.unenroll_tooltip_disabled') : t('pages.nodes.table.buttons.unenroll_tooltip'),
            } }));
    }, [canPendingUnenroll, canDeleteNodes, t, rows]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'state',
            align: 'center',
            headerName: t('pages.nodes.table.headers.state'),
            sortable: false,
            maxWidth: 1,
            minWidth: 55,
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(PendingStateCell, { row: row });
            },
        },
        { field: 'id', headerName: t('pages.nodes.table.headers.id'), flex: 1, minWidth: 110 },
        { field: 'name', headerName: t('pages.nodes.table.headers.name'), flex: 1, minWidth: 120 },
        {
            field: 'runtime_status',
            headerName: t('pages.nodes.table.headers.status'),
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return _jsx(PendingStatusCell, { row: row, isLink: true, tabIndex: tabIndex });
            },
        },
        {
            field: 'enrollment_bundle',
            headerName: t('pages.nodes.table.headers.bundle'),
            flex: 1,
            minWidth: 80,
            sortable: false,
            renderCell: renderBundle,
        },
        {
            field: 'subscription_type',
            headerName: t('pages.nodes.table.headers.subscription_type'),
            sortable: false,
            valueGetter: function (_a) {
                var _b;
                var row = _a.row;
                return (((_b = row.subscription) === null || _b === void 0 ? void 0 : _b.tier) ? subscriptionTierMap.get(row.subscription.tier) : '');
            },
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'model',
            headerName: t('pages.nodes.table.headers.model'),
            sortable: false,
            valueFormatter: function (_a) {
                var value = _a.value;
                return value !== null && value !== void 0 ? value : 'N/A';
            },
            flex: 1,
            minWidth: 120,
        },
        { field: 'lhvpn_address', headerName: t('pages.dashboard.nodes.table.headers.lhvpn_address'), sortable: false, minWidth: 120, flex: 1 },
        {
            field: 'address',
            headerName: t('pages.dashboard.nodes.table.headers.address'),
            sortable: false,
            minWidth: 200,
            resizable: true,
            renderCell: renderAddress,
        },
        {
            field: 'serial_number',
            headerName: t('pages.nodes.table.headers.serial'),
            sortable: false,
            valueFormatter: function (_a) {
                var value = _a.value;
                return value !== null && value !== void 0 ? value : 'N/A';
            },
            flex: 1,
            minWidth: 135,
        },
        {
            field: 'last_updated',
            headerName: t('pages.nodes.table.headers.last_updated'),
            sortable: isPrimary,
            valueGetter: getLastOnlineValue,
            valueFormatter: formatLastOnline,
            flex: 1,
            minWidth: 210,
        },
        {
            field: 'unenroll',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderPendingUnenrollCell,
        },
        {
            field: 'approve',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderPendingApproveCell,
        },
    ]; }, [isPrimary, renderPendingApproveCell, renderPendingUnenrollCell, t]);
    return (_jsxs(_Fragment, { children: [modalJobId && _jsx(JobModal, { open: !!modalJobId, handleClose: handleJobModalClose, jobId: modalJobId }), nodeIdsToDelete && (_jsx(PendingDeleteDialog, { open: !!nodeIdsToDelete, handleClose: handleDeleteDialogClose, handleUnenroll: handleBulkUnenroll, nodeIds: nodeIdsToDelete })), _jsxs(Stack, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 1, marginBottom: 1, justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(NodeFilter, { filter: nodeFilter, setFilter: setNodeFilter, permissions: { hasSmartgroupsEditAccess: editableNodeFilters } }), _jsx(FreeTextSearch, { ref: refocusRef, onSearch: dispatchTextQuery, useDebounce: false, translations: { tooltip: t('components.nodes.text_search.tooltip') } })] })), (canApproveNodes || canDeleteNodes) && (_jsx(BulkOperations, { nodeIds: rowSelectionModel, totalNodes: rows.length, setRowSelectionModel: setRowSelectionModel, onClickApprove: canApproveNodes ? handleBulkApprove : undefined, onClickUnenroll: canDeleteNodes ? setNodeIdsToDelete : undefined, refocusRef: refocusRef })), _jsx(Box, { children: _jsx(OGTable, { id: "pending-nodes-table", "aria-label": t('pages.nodes.table.label.pending'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.nodes.table.no_row_message.pending'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, checkboxSelection: canApproveNodes || canDeleteNodes, onRowSelectionModelChange: setRowSelectionModel, rowSelectionModel: rowSelectionModel, initialState: {
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                            }, setCellFocus: cellFocus }) })] })] }));
});
