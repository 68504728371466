var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OGPanel } from '@opengear/panel';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { SystemUpgradeForm, transformSystemUpgradeForm, SystemUpgradeInfo, SystemUpgradeMessage, SystemSecondaryUpgradeWarning, } from '@/components/settings/system/system-upgrade';
import { useFirmwareUpgrade } from '@/api/system/firmware-upgrade';
import { useFirmwareUpgradeStatusActivePolling } from '@/api/system/firmware-upgrade-status';
export var SYSTEM_UPGRADE_LOADER_ID = 'system-upgrade-loading';
function SystemUpgradeContent(_a) {
    var _this = this;
    var errorMessage = _a.errorMessage, isRefetching = _a.isRefetching, state = _a.state;
    var isInActiveState = state === 'finished' || state === 'pending' || state === 'running';
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.system.upgrade.edit');
    var hasSecondaryViewPermission = hasPermission('configure.multiple_instance.dependent_lighthouse.view');
    var firmwareUpgrade = useFirmwareUpgrade();
    var handleSubmit = useCallback(function (systemUpgradeFormData) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firmwareUpgrade(transformSystemUpgradeForm(systemUpgradeFormData))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [firmwareUpgrade]);
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 2 }, { children: [_jsx(SystemUpgradeMessage, { errorMessage: errorMessage, isRefetching: isRefetching, state: state }), hasSecondaryViewPermission && _jsx(SystemSecondaryUpgradeWarning, {}), _jsx(OGPanel, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 3 }, { children: [_jsx(SystemUpgradeInfo, { state: state }), isInActiveState ? null : _jsx(SystemUpgradeForm, { onSubmit: handleSubmit, permissions: { hasEditPermission: hasEditPermission } })] })) })] })));
}
function SystemUpgrade() {
    var t = useTranslation().t;
    useDocTitle(t('pages.system_upgrade.title'));
    var _a = useFirmwareUpgradeStatusActivePolling(5000), data = _a.data, isLoading = _a.isLoading, isRefetching = _a.isRefetching;
    var innerNode = null;
    if (isLoading) {
        innerNode = (_jsx(Stack, __assign({ height: 100, alignItems: "center", justifyContent: "center" }, { children: _jsx(CircularProgress, { "aria-labelledby": PAGE_TITLE_ID, "data-testid": SYSTEM_UPGRADE_LOADER_ID, "aria-busy": true, color: "inherit" }) })));
    }
    if (data) {
        innerNode = (_jsx(SystemUpgradeContent, { errorMessage: data.system_firmware_upgrade_status.error_message, isRefetching: isRefetching, state: data.system_firmware_upgrade_status.state }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.system_upgrade.title') })), innerNode] })));
}
export default withAuthenticationRequired(SystemUpgrade, { rights: ['settings.system.upgrade.view'] });
