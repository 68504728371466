var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { OGButton } from '@opengear/buttons';
import { OGNavPopupMenuHeader, OGNavPopupMenuLink } from '@opengear/navigation';
import { useMemo } from 'react';
import { useAnchorOpen } from '@/hooks/open';
import { formatSearchParams } from '@/api/utils';
import { QUERY_PARAM_CREATE_FILTER_MODAL } from '@/constants/query-params-keys';
import { NODE_FILTERS_ROUTE_KEY, PORT_FILTERS_ROUTE_KEY, RESOURCE_FILTERS_ROUTE_KEY } from '@/constants/routes';
var NEW_FILTER_MENU_ID = 'new-filter-menu';
var NEW_FILTER_MENU_HEADING = 'new-filter-menu-heading';
export var OPEN_MODAL_NODE_QUERY_PARAM = { modal: QUERY_PARAM_CREATE_FILTER_MODAL.nodeFilter };
export var OPEN_MODAL_PORT_QUERY_PARAM = { modal: QUERY_PARAM_CREATE_FILTER_MODAL.portFilter };
export var OPEN_MODAL_RESOURCE_QUERY_PARAM = { modal: QUERY_PARAM_CREATE_FILTER_MODAL.resourceFilter };
export function NewFilterMenu(_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var _b = useAnchorOpen(), anchorEl = _b.anchorEl, open = _b.open, handleOpen = _b.handleOpen, handleClose = _b.handleClose;
    var _c = useLocation(), search = _c.search, pathname = _c.pathname;
    var disabled = !permissions[NODE_FILTERS_ROUTE_KEY].hasFilterEditAccess &&
        !permissions[PORT_FILTERS_ROUTE_KEY].hasFilterEditAccess &&
        !permissions[RESOURCE_FILTERS_ROUTE_KEY].hasFilterEditAccess;
    var _d = useMemo(function () {
        var currentQueryParams = Object.fromEntries(new URLSearchParams(search));
        var newQueryParamsNode = __assign(__assign({}, currentQueryParams), OPEN_MODAL_NODE_QUERY_PARAM);
        var newNodeLink = "".concat(pathname).concat(formatSearchParams(newQueryParamsNode));
        var newQueryParamsPort = __assign(__assign({}, currentQueryParams), OPEN_MODAL_PORT_QUERY_PARAM);
        var newPortLink = "".concat(pathname).concat(formatSearchParams(newQueryParamsPort));
        var newQueryParamsResource = __assign(__assign({}, currentQueryParams), OPEN_MODAL_RESOURCE_QUERY_PARAM);
        var newResourceLink = "".concat(pathname).concat(formatSearchParams(newQueryParamsResource));
        return {
            nodeLink: newNodeLink,
            portLink: newPortLink,
            resourceLink: newResourceLink,
        };
    }, [search, pathname]), nodeLink = _d.nodeLink, portLink = _d.portLink, resourceLink = _d.resourceLink;
    return (_jsxs(_Fragment, { children: [_jsx(OGButton, __assign({ "aria-controls": NEW_FILTER_MENU_ID, "aria-expanded": open, "aria-haspopup": true, disabled: disabled, onClick: handleOpen, startIcon: _jsx(AddCircleOutline, {}) }, { children: t('pages.filters.menu.toggle') })), _jsx(Popover, __assign({ id: NEW_FILTER_MENU_ID, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'right' } }, { children: _jsxs(Box, __assign({ sx: { paddingX: 0.5, paddingY: 1 } }, { children: [_jsx(OGNavPopupMenuHeader, __assign({ id: NEW_FILTER_MENU_HEADING }, { children: t('pages.filters.menu.heading') })), _jsxs(MenuList, __assign({ "aria-labelledby": NEW_FILTER_MENU_HEADING, sx: { padding: 0 } }, { children: [permissions[NODE_FILTERS_ROUTE_KEY].hasFilterEditAccess && (_jsx(OGNavPopupMenuLink, __assign({ to: nodeLink, onClose: handleClose, onClick: handleClose }, { children: t('pages.filters.menu.items.node_filter') }))), permissions[PORT_FILTERS_ROUTE_KEY].hasFilterEditAccess && (_jsx(OGNavPopupMenuLink, __assign({ to: portLink, onClose: handleClose, onClick: handleClose }, { children: t('pages.filters.menu.items.port_filter') }))), permissions[RESOURCE_FILTERS_ROUTE_KEY].hasFilterEditAccess && (_jsx(OGNavPopupMenuLink, __assign({ to: resourceLink, onClose: handleClose, onClick: handleClose }, { children: t('pages.filters.menu.items.resource_filter') })))] }))] })) }))] }));
}
