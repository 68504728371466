var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OGButton } from '@opengear/buttons';
import { OGFileExeIcon } from '@opengear/icons/action/file-exe';
export function OGFileTracker(_a) {
    var id = _a.id, message = _a.message, name = _a.name, onCancel = _a.onCancel, progress = _a.progress;
    var t = useTranslation().t;
    var tooltipLabel = progress === undefined || progress === 100
        ? t('components.file_uploader.tracker.clear_label')
        : format(t('components.file_uploader.tracker.cancel_label'), name);
    var handleClick = useCallback(function (event) {
        // Prevents parent label container from opening
        event.preventDefault();
        event.stopPropagation();
        onCancel === null || onCancel === void 0 ? void 0 : onCancel(id);
    }, [id, onCancel]);
    var announceProgress = progress !== undefined ? Math.floor(progress / 10) * 10 : 0;
    return (_jsxs(Box, __assign({ display: "flex", gap: 1, alignItems: "center" }, { children: [_jsx(OGFileExeIcon, {}), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", justifyContent: "center", flexGrow: "1", gap: 0.75 }, { children: [_jsx(Typography, __assign({ sx: { wordBreak: 'break-all', whiteSpace: 'balance' }, component: "span" }, { children: name })), progress !== undefined ? (_jsx(Box, __assign({ "aria-label": format(t('components.file_uploader.tracker.progress_label'), name) }, { children: _jsx(LinearProgress, { "aria-label": "".concat(announceProgress, "% progress"), color: announceProgress >= 100 ? 'success' : 'warning', value: progress, "aria-valuenow": progress, "aria-valuemin": 0, "aria-valuemax": 100, role: "progressbar", "aria-live": "polite" }) }))) : null, message] })), onCancel ? (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: tooltipLabel }, { children: _jsx("div", { children: _jsx(OGButton, __assign({ "aria-label": tooltipLabel, onClick: handleClick, sx: { minHeight: 'auto', minWidth: 'auto', paddingY: 0.5, paddingX: 0.8 }, variant: "borderless" }, { children: _jsx(ClearIcon, { fontSize: "medium" }) })) }) }))) : null] })));
}
