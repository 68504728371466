var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { RoleForm } from '@/components/accounts/roles/role-form';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useCreateRole, useRole } from '@/api/roles';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { useEntities } from '@/api/entities';
export var COPY_ROLE_LOADING_TEST_ID = 'copy-role-loading';
function CopyRole() {
    var t = useTranslation().t;
    useDocTitle(t('pages.role_new.title'));
    var createRole = useCreateRole();
    var navigate = useNavigate();
    // Only fetch once if query param exists
    var roleId = useParams().roleId;
    var alertMessages = {
        forbidden: {
            message: format(t('api.role.get.forbidden.message'), roleId !== null && roleId !== void 0 ? roleId : ''),
            options: { persistOnUnmount: true },
        },
        not_found: {
            message: format(t('api.role.get.not_found.message'), roleId !== null && roleId !== void 0 ? roleId : ''),
            options: { persistOnUnmount: true },
        },
    };
    var statusCallback = useCallback(function (responseStatus) {
        if (responseStatus === 403 || responseStatus === 404) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate]);
    var _a = useEntities(), entitiesData = _a.data, isLoadingEntities = _a.isLoading;
    var _b = useRole(roleId !== null && roleId !== void 0 ? roleId : '', {
        alertMessages: alertMessages,
        statusCallback: statusCallback,
        refreshEnabled: false,
    }), role = _b.data, isLoadingRole = _b.isLoading;
    var innerNode = null;
    if (isLoadingEntities || isLoadingRole) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": COPY_ROLE_LOADING_TEST_ID });
    }
    if (entitiesData && role) {
        // Clear the rolename
        if (role)
            role.rolename = '';
        innerNode = _jsx(RoleForm, { entities: entitiesData.entities, role: role, submitHandler: createRole });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 1.5 }, { children: t('pages.role_new.title') })), innerNode] })));
}
// settings.user_management.permission.edit covers roles and roles
export default withAuthenticationRequired(CopyRole, { rights: ['settings.user_management.permissions.edit'] });
