var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGNetopsIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-3 -5 24 24' }, props), { children: _jsx("svg", __assign({ fill: "none" }, { children: _jsx("path", { d: "M11.25 0.84375C11.4975 0.84375 11.7 1.03359 11.7 1.26562V3.79688C11.7 4.02891 11.4975 4.21875 11.25 4.21875H9.45H8.55H6.75C6.5025 4.21875 6.3 4.02891 6.3 3.79688V1.26562C6.3 1.03359 6.5025 0.84375 6.75 0.84375H11.25ZM9.45 5.0625H11.25C11.9953 5.0625 12.6 4.49561 12.6 3.79688V1.26562C12.6 0.566895 11.9953 0 11.25 0H6.75C6.00469 0 5.4 0.566895 5.4 1.26562V3.79688C5.4 4.49561 6.00469 5.0625 6.75 5.0625H8.55V6.32812H0.45C0.2025 6.32812 0 6.51797 0 6.75C0 6.98203 0.2025 7.17188 0.45 7.17188H4.05V8.4375H2.25C1.50469 8.4375 0.9 9.00439 0.9 9.70312V12.2344C0.9 12.9331 1.50469 13.5 2.25 13.5H6.75C7.49531 13.5 8.1 12.9331 8.1 12.2344V9.70312C8.1 9.00439 7.49531 8.4375 6.75 8.4375H4.95V7.17188H13.05V8.4375H11.25C10.5047 8.4375 9.9 9.00439 9.9 9.70312V12.2344C9.9 12.9331 10.5047 13.5 11.25 13.5H15.75C16.4953 13.5 17.1 12.9331 17.1 12.2344V9.70312C17.1 9.00439 16.4953 8.4375 15.75 8.4375H13.95V7.17188H17.55C17.7975 7.17188 18 6.98203 18 6.75C18 6.51797 17.7975 6.32812 17.55 6.32812H9.45V5.0625ZM13.95 9.28125H15.75C15.9975 9.28125 16.2 9.47109 16.2 9.70312V12.2344C16.2 12.4664 15.9975 12.6562 15.75 12.6562H11.25C11.0025 12.6562 10.8 12.4664 10.8 12.2344V9.70312C10.8 9.47109 11.0025 9.28125 11.25 9.28125H13.05H13.95ZM4.95 9.28125H6.75C6.9975 9.28125 7.2 9.47109 7.2 9.70312V12.2344C7.2 12.4664 6.9975 12.6562 6.75 12.6562H2.25C2.0025 12.6562 1.8 12.4664 1.8 12.2344V9.70312C1.8 9.47109 2.0025 9.28125 2.25 9.28125H4.05H4.95Z", fill: "currentColor" }) })) })));
}
