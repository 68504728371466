var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useNodeBackup } from '@/api/services/node-backup';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NodeBackupForm, NODE_BACKUP_FORM_LOADER_ID } from '@/components/settings/services/node-backup';
import { PAGE_TITLE_ID } from '@/constants/ids';
export var NodeBackup = withAuthenticationRequired(function () {
    var t = useTranslation().t;
    var pageTitle = t('pages.node_backup.title');
    useDocTitle(pageTitle);
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.services.node_backup.edit');
    var _a = useNodeBackup(), data = _a.data, isLoading = _a.isLoading;
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": NODE_BACKUP_FORM_LOADER_ID });
    }
    if (data) {
        innerNode = _jsx(NodeBackupForm, { data: data.node_backup, permissions: { hasEditPermission: hasEditPermission } });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: pageTitle })), innerNode] })));
}, { rights: ['settings.services.node_backup.view'] });
