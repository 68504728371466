var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import format from 'string-format';
import { decodeAddDependentLighthouseResponse, decodeReadDependentLighthousesResponse, } from '../../../types/lighthouse/decoders/multiple-instance/dependent-lighthouses';
import { decodeDeleteDependentLighthouseResponse, decodeGetDependentLighthouseByIdResponse, decodeUpdateDependentLighthouseResponse, } from '../../../types/lighthouse/decoders/multiple-instance/dependent-lighthouses/lighthouse-id';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var DEPENDENT_LIGHTHOUSES_PATH = '/multiple_instance/dependent_lighthouses';
export function formatDependentLighthousePath(lighthouse_id) {
    if (lighthouse_id === void 0) { lighthouse_id = ':lighthouse_id'; }
    return "".concat(DEPENDENT_LIGHTHOUSES_PATH, "/").concat(lighthouse_id);
}
/**
 * Hook to fetch list of dependent Lighthouses
 */
export function useDependentLighthouses() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    var fetchDependentLighthouses = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(DEPENDENT_LIGHTHOUSES_PATH, __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadDependentLighthousesResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.secondary_lighthouses.get'));
                    if (status === 200) {
                        data.dependent_lighthouses.forEach(function (lighthouse) {
                            queryClient.setQueryData([DEPENDENT_LIGHTHOUSES_PATH, lighthouse.id], lighthouse);
                        });
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
    return useQuery({
        queryKey: [DEPENDENT_LIGHTHOUSES_PATH],
        queryFn: fetchDependentLighthouses,
    });
}
/**
 * Hook to fetch Lighthouse by ID
 */
export function useDependentLighthouseById(lighthouseId, _a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a, notFoundCallback = _b.notFoundCallback;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchDependentLighthouseById = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatDependentLighthousePath(lighthouseId), __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeGetDependentLighthouseByIdResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = __assign(__assign({}, t('api.secondary_lighthouses.get')), { not_found: { message: format(t('api.secondary_lighthouses.get.not_found.message'), lighthouseId) } });
                    handleCommonStatusCodes(data, status, translations);
                    if ((status === 403 || status === 404) && notFoundCallback) {
                        notFoundCallback(status);
                    }
                    return [2 /*return*/, status === 200 ? data.dependent_lighthouse : null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, lighthouseId, notFoundCallback, t]);
    return useQuery({
        queryKey: [DEPENDENT_LIGHTHOUSES_PATH, lighthouseId],
        queryFn: fetchDependentLighthouseById,
    });
}
/**
 * Hook to enrol new secondary lighthouse
 */
export function useAddDependentLighthouse() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    return useCallback(function (dependent_lighthouse) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(DEPENDENT_LIGHTHOUSES_PATH, { method: 'POST', body: JSON.stringify({ dependent_lighthouse: dependent_lighthouse }) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeAddDependentLighthouseResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.secondary_lighthouses.post'));
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [DEPENDENT_LIGHTHOUSES_PATH],
                        })
                            .catch(console.error);
                        queryClient.setQueryData([DEPENDENT_LIGHTHOUSES_PATH, data.dependent_lighthouse.id], data.dependent_lighthouse);
                        return [2 /*return*/, data.dependent_lighthouse];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to update a secondary lighthouse
 */
export function useUpdateDependentLighthouse() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var response, _b, data, status, translations;
        var lighthouseId = _a.id, dependent_lighthouse = __rest(_a, ["id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, executeFetch(formatDependentLighthousePath(lighthouseId), { method: 'PUT', body: JSON.stringify({ dependent_lighthouse: dependent_lighthouse }) })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateDependentLighthouseResponse, response)];
                case 2:
                    _b = _c.sent(), data = _b.data, status = _b.status;
                    translations = __assign(__assign({}, t('api.secondary_lighthouses.put')), { forbidden: { message: format(t('api.secondary_lighthouses.put.forbidden.message'), lighthouseId) }, error: { message: format(t('api.secondary_lighthouses.put.error.message'), lighthouseId) } });
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [DEPENDENT_LIGHTHOUSES_PATH],
                        })
                            .catch(console.error);
                        queryClient.setQueryData([DEPENDENT_LIGHTHOUSES_PATH, data.dependent_lighthouse.id], data.dependent_lighthouse);
                        return [2 /*return*/, data.dependent_lighthouse];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to unenroll a secondary lighthouseusequery for bulk read
 */
export function useDeleteDependentLighthouse() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (lighthouseId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatDependentLighthousePath(lighthouseId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteDependentLighthouseResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = __assign(__assign({}, t('api.secondary_lighthouses.delete')), { forbidden: { message: format(t('api.secondary_lighthouses.delete.forbidden.message'), lighthouseId) }, error: { message: format(t('api.secondary_lighthouses.delete.error.message'), lighthouseId) }, not_found: { message: format(t('api.secondary_lighthouses.delete.not_found.message'), lighthouseId) } });
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [DEPENDENT_LIGHTHOUSES_PATH],
                            refetchType: 'none',
                        })
                            .catch(console.error);
                        queryClient.removeQueries({
                            queryKey: [DEPENDENT_LIGHTHOUSES_PATH, lighthouseId],
                        });
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
