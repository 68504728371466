var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { OGButton } from '@opengear/buttons';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';
import { OG_BLACK_80, OG_GREY_VVVL } from '@opengear/theme/colors';
import { OGSubscriptionIcon } from '@opengear/icons/action/subscription-assignment';
export var testId = 'bulk-operation-section';
export function BulkOperations(_a) {
    var nodeIds = _a.nodeIds, totalNodes = _a.totalNodes, setRowSelectionModel = _a.setRowSelectionModel, onClickApprove = _a.onClickApprove, onClickUnenroll = _a.onClickUnenroll, onClickUpdateSubscriptions = _a.onClickUpdateSubscriptions, refocusRef = _a.refocusRef;
    var theme = useTheme();
    var t = useTranslation().t;
    var unenrollRef = useRef(null);
    var approveRef = useRef(null);
    var updateSubscriptionsRef = useRef(null);
    useEffect(function () {
        var _a;
        var currentFocus = document.activeElement;
        if (nodeIds.length === 0 &&
            (currentFocus === unenrollRef.current || currentFocus === approveRef.current || currentFocus === updateSubscriptionsRef.current)) {
            (_a = refocusRef === null || refocusRef === void 0 ? void 0 : refocusRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [refocusRef, nodeIds.length]);
    return (_jsx(Collapse, __assign({ in: !!nodeIds.length, unmountOnExit: true, timeout: 200, "data-testid": testId, sx: { backgroundColor: theme.palette.mode === 'dark' ? OG_BLACK_80 : OG_GREY_VVVL } }, { children: _jsxs(Stack, __assign({ padding: 1, paddingLeft: 2, direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Stack, __assign({ gap: 2, direction: "row", justifyContent: "flex-start", flexWrap: "wrap" }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: 400, display: 'flex', alignItems: 'center' } }, { children: format(t('components.bulk_operations.number_of_selected'), nodeIds.length.toString(), totalNodes.toString()) })), onClickApprove && (_jsx(OGButton, __assign({ ref: approveRef, startIcon: _jsx(DoneIcon, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return onClickApprove(__spreadArray([], __read(nodeIds), false)); } }, { children: t('components.bulk_operations.bulk_approve_label') }))), onClickUnenroll && (_jsx(OGButton, __assign({ ref: unenrollRef, startIcon: _jsx(ClearIcon, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return onClickUnenroll(__spreadArray([], __read(nodeIds), false)); } }, { children: t('components.bulk_operations.bulk_unenroll_label') }))), onClickUpdateSubscriptions && (_jsx(OGButton, __assign({ ref: unenrollRef, startIcon: _jsx(OGSubscriptionIcon, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return onClickUpdateSubscriptions(__spreadArray([], __read(nodeIds), false)); } }, { children: t('components.bulk_operations.bulk_update_subscriptions') })))] })), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('global.buttons.close') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('global.buttons.close'), disabled: !nodeIds.length, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () {
                                var _a;
                                setRowSelectionModel([]);
                                (_a = refocusRef === null || refocusRef === void 0 ? void 0 : refocusRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                            } }) }) }))] })) })));
}
