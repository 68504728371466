var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useCallback, useEffect, useRef } from 'react';
import format from 'string-format';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { OGDialog } from '@opengear/modals';
import { OGAutocomplete } from '@opengear/inputs/autocomplete';
import { useAlertNotification } from '@/hooks/alert-notification';
import { useNodeTags } from '@/api/tags/node-tags';
import { useUpdateNodeTags } from '@/api/nodes/node-tags';
import { useNode } from '@/api/nodes';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var NODE_TAGS_MODAL_LOADING_ID = 'node-tags-modal-loading';
export function NodeTagsForm(_a) {
    var _this = this;
    var nodeTagData = _a.tags, node = _a.node, onClose = _a.onClose;
    var t = useTranslation().t;
    var id = 'node-tags-form';
    var updateNodeTags = useUpdateNodeTags();
    var nodeTags = (node === null || node === void 0 ? void 0 : node.tag_list.tags) || [];
    var nodeId = (node === null || node === void 0 ? void 0 : node.id) || '';
    var addTagButton = useRef(null);
    var tagList = (nodeTagData === null || nodeTagData === void 0 ? void 0 : nodeTagData.nodeTags.map(function (tag) { return tag.name; })) || [];
    var _b = useForm({
        defaultValues: {
            tags: nodeTags,
        },
        mode: 'onBlur',
    }), control = _b.control, createSubmitHandler = _b.handleSubmit, watch = _b.watch, _c = _b.formState, isValid = _c.isValid, isSubmitting = _c.isSubmitting, isDirty = _c.isDirty, reset = _b.reset, setValue = _b.setValue, setFocus = _b.setFocus;
    var _d = useFieldArray({
        name: 'tags',
        control: control,
    }), fields = _d.fields, append = _d.append, remove = _d.remove;
    var handleCancel = useCallback(function () {
        reset();
        onClose();
    }, [onClose, reset]);
    var handleSubmit = useMemo(function () {
        return createSubmitHandler(function (formData) { return __awaiter(_this, void 0, void 0, function () {
            var updatedData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateNodeTags(nodeId, formData.tags)];
                    case 1:
                        updatedData = _a.sent();
                        if (updatedData) {
                            onClose();
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    }, [createSubmitHandler, nodeId, onClose, updateNodeTags]);
    // "Placeholder" for select value field
    var renderSelectValue = useCallback(function (value) {
        if (typeof value !== 'string' || value === '') {
            return _jsx("em", { children: t('pages.nodes.tags_update_modal.form.fields.tag_value.placeholder') });
        }
        return value;
    }, [t]);
    var addTagHandler = useCallback(function () { return append({ name: '', value: '' }); }, [append]);
    var tagFields = watch('tags'); // used to get propper options for value filed and disable tag names that are already used to prevent doubles
    var removeTagHandler = useCallback(function (index) {
        if (fields.length > 1) {
            var nextTag = fields.length - 1 > index ? index + 1 : index - 1;
            setFocus("tags.".concat(nextTag, ".name"));
        }
        else if (addTagButton.current) {
            addTagButton.current.focus();
        }
        remove(index);
    }, [remove, fields, setFocus, addTagButton]);
    var innerContent = (_jsxs(Stack, __assign({ "aria-label": t('pages.nodes.tags_update_modal.form.label'), component: "form", id: id, onSubmit: handleSubmit }, { children: [fields.length > 0 ? (fields.map(function (field, index) {
                var _a;
                var tagName = tagFields[index].name;
                // get values that are propper for selected tag name
                var valuesList = ((_a = nodeTagData === null || nodeTagData === void 0 ? void 0 : nodeTagData.nodeTags.find(function (tag) { return tag.name === tagName; })) === null || _a === void 0 ? void 0 : _a.values.map(function (value) { return value.value; })) || [];
                return (_jsxs(Stack, __assign({ direction: "row", spacing: 2, role: "group", paddingTop: 1 }, { children: [_jsx(Controller, { control: control, name: "tags.".concat(index, ".name"), rules: { required: t('pages.nodes.tags_update_modal.form.fields.tag_name.errors.required') }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, fieldAttributes = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error;
                                return (_jsx(OGAutocomplete, __assign({ ariaLabel: format(t('pages.nodes.tags_update_modal.form.fields.tag_name.label'), (index + 1).toString()), error: !!error, options: tagList, placeholder: t('pages.nodes.tags_update_modal.form.fields.tag_name.placeholder'), disableClearable: true, helperText: error === null || error === void 0 ? void 0 : error.message, getOptionDisabled: function (option) { return tagFields.map(function (item) { return item.name; }).includes(option); }, label: t('pages.nodes.tags_update_modal.form.fields.tag_name.visual_label'), required: true, onChange: function (_, data) {
                                        onChange(data);
                                        setValue("tags.".concat(index, ".value"), ''); // clear the corresponding value when the tag changes
                                    }, defaultValue: value || undefined, sx: { flexGrow: 1, width: 'auto' } }, fieldAttributes)));
                            } }), _jsx(Controller, { control: control, name: "tags.".concat(index, ".value"), rules: { required: t('pages.nodes.tags_update_modal.form.fields.tag_value.errors.required') }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, fieldValue = _b.value, fieldAttributes = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ error: !!error, select: true, placeholder: t('pages.nodes.tags_update_modal.form.fields.tag_value.placeholder'), SelectProps: { displayEmpty: true, renderValue: renderSelectValue }, onChange: onChange, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, label: t('pages.nodes.tags_update_modal.form.fields.tag_value.visual_label'), disabled: !tagName, sx: { width: '180px', margin: 0, flexFlow: 1 }, inputProps: { 'aria-label': format(t('pages.nodes.tags_update_modal.form.fields.tag_value.label'), tagName) }, value: (valuesList === null || valuesList === void 0 ? void 0 : valuesList.includes(fieldValue)) ? fieldValue : '' }, fieldAttributes, { children: valuesList.map(function (value) { return (_jsx(MenuItem, __assign({ value: value }, { children: value }), value)); }) })));
                            } }), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.nodes.tags_update_modal.buttons.remove_tag_tooltip') }, { children: _jsx(OGButton, { "aria-label": format(t('pages.nodes.tags_update_modal.buttons.remove_tag'), tagName), size: "small", sx: { '&.MuiButton-root': { height: '31px', marginLeft: '8px', marginTop: '22px' } }, startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return removeTagHandler(index); } }) }))] }), field.id));
            })) : (_jsx(Typography, __assign({ sx: { marginY: 1 } }, { children: t('pages.nodes.tags_update_modal.no_tags_message') }))), _jsx(OGButton
            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            , __assign({ 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, ref: addTagButton, id: "add-tag-button", startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: addTagHandler }, { children: t('pages.nodes.tags_update_modal.buttons.add_tag') }))] })));
    return (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: innerContent }), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 1 } }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "row", gap: 1 }, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: handleCancel }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ form: id, disabled: !isValid || !isDirty, loading: isSubmitting, type: "submit" }, { children: t('global.buttons.apply') }))] })) }))] }));
}
export function NodeTagsModal(_a) {
    var open = _a.open, onClose = _a.onClose, nodeId = _a.nodeId;
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var _b = useNodeTags(), nodeTagData = _b.data, isLoadingTags = _b.isLoading;
    var _c = useNode(nodeId), nodeData = _c.data, isLoadingNode = _c.isLoading;
    var node = nodeData === null || nodeData === void 0 ? void 0 : nodeData.node;
    useEffect(function () {
        if (!isLoadingNode && !node) {
            alertNotification(t('pages.nodes.tags_update_modal.error_message.message'), {
                persistOnUnmount: true,
            });
        }
    }, [alertNotification, node, isLoadingNode, t]);
    if (isLoadingTags || isLoadingNode) {
        return _jsx(LoadingBackdrop, { "data-testid": NODE_TAGS_MODAL_LOADING_ID });
    }
    return (_jsxs(OGDialog, __assign({ "aria-labelledby": "tags-update-title", open: open, onClose: onClose }, { children: [_jsx(DialogTitle, __assign({ id: "tags-update-title" }, { children: t('pages.nodes.tags_update_modal.title') })), _jsx(NodeTagsForm, { tags: nodeTagData, node: node, onClose: onClose })] })));
}
