var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { AuthServerFields, LoginMethodButtons, PasswordField, ConfirmPasswordField, ServiceField } from './tacacs-form-controls';
export function TacacsSettings() {
    return (_jsxs(Stack, __assign({ direction: "column", gap: 1, "aria-label": "Tacacs section" }, { children: [_jsx(Divider, {}), _jsx(AuthServerFields, {}), _jsx(Divider, {}), _jsx(LoginMethodButtons, {}), _jsx(PasswordField, {}), _jsx(ConfirmPasswordField, {}), _jsx(ServiceField, {})] })));
}
