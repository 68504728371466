var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGTagIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '0 0 12 12' }, props), { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.8746 7.35531C11.9743 7.42512 12.0207 7.54912 11.9913 7.66722C11.9619 7.78531 11.8628 7.87308 11.742 7.88799L8.78876 8.7893L7.88693 11.742C7.87256 11.8631 7.78476 11.9627 7.66638 11.9922C7.54801 12.0217 7.42374 11.9749 7.35424 11.8746L0.380337 4.90123C0.154942 4.6498 0.0219219 4.32909 0.00319611 3.99194L0 0.532685C0 0.238491 0.238491 0 0.532685 0H3.99034C4.32749 0.0182827 4.64829 0.15115 4.89963 0.376608L11.8746 7.35531ZM1.09751 2.66342C1.09751 1.79889 1.79835 1.09805 2.66288 1.09805C3.52741 1.09805 4.22825 1.79889 4.22825 2.66342C4.22825 3.52795 3.52741 4.22879 2.66288 4.22879C1.79835 4.22879 1.09751 3.52795 1.09751 2.66342ZM2.66288 2.09805C2.35063 2.09805 2.09751 2.35118 2.09751 2.66342C2.09751 2.97567 2.35063 3.22879 2.66288 3.22879C2.97512 3.22879 3.22825 2.97567 3.22825 2.66342C3.22825 2.35118 2.97512 2.09805 2.66288 2.09805Z", fill: "currentColor" }) })));
}
