export var USER_GROUP_NAME_PATTERN = /^[a-zA-Z0-9_]+[a-zA-Z0-9._-]*$/;
export var USER_NAME_PATTERN = /^[a-z_][a-z0-9._-]{0,31}$/;
export var USER_DESCRIPTION_PATTERN = /^[^:]*$/;
export var ROLENAME_PATTERN = /^[a-zA-Z0-9_]+[ a-zA-Z0-9._-]*$/;
export var CONTINUOUS_ALPHA_NUM_PATTERN = /^[0-9a-zA-Z._-]+$/;
export var NUM_ONLY_PATTERN = /^[0-9]+$/;
export var EMAIL_ADDRESS_PATTERN = /^(?=[A-Za-z0-9][A-Za-z0-9@._%+-]{5,253}$)[A-Za-z0-9._%+-]{1,64}@(?:(?=[A-Za-z0-9-]{1,63}\.)[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*\.){1,8}[A-Za-z]{2,63}$/;
export var DNS_PATTERN = /^(?!-)(?!.*-\.)[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*\.[A-Za-z]{2,}$/;
export var IPV4_PATTERN = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;
export var IPV6_PATTERN = 
// eslint-disable-next-line max-len
/^((([0-9A-Fa-f]{1,4}:){7,7}[0-9A-Fa-f]{1,4}|([0-9A-Fa-f]{1,4}:){1,7}:|([0-9A-Fa-f]{1,4}:){1,6}:[0-9A-Fa-f]{1,4}|([0-9A-Fa-f]{1,4}:){1,5}(:[0-9A-Fa-f]{1,4}){1,2}|([0-9A-Fa-f]{1,4}:){1,4}(:[0-9A-Fa-f]{1,4}){1,3}|([0-9A-Fa-f]{1,4}:){1,3}(:[0-9A-Fa-f]{1,4}){1,4}|([0-9A-Fa-f]{1,4}:){1,2}(:[0-9A-Fa-f]{1,4}){1,5}|[0-9A-Fa-f]{1,4}:((:[0-9A-Fa-f]{1,4}){1,6})|:((:[0-9A-Fa-f]{1,4}){1,7}|:)|fe80:(:[0-9A-Fa-f]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9A-Fa-f]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])))$/;
export var HOSTNAME_PATTERN = /^(?![-.])(?!(\d+)$)[a-zA-Z0-9-]{1,63}(?<![-.])$|^(?![-.])(?!(\d+)(\.\d+)*$)([a-zA-Z0-9-]{1,63}\.){1,126}[a-zA-Z]{2,63}(?<![-.])$/;
export var ADDRESS_PATTERN = new RegExp("^(".concat(IPV4_PATTERN.source, ")$|^(").concat(IPV6_PATTERN.source, ")$|^(").concat(DNS_PATTERN.source, ")$"));
export var HOSTNAME_OR_ADDRESS_PATTERN = new RegExp("^(".concat(HOSTNAME_PATTERN.source, "|").concat(ADDRESS_PATTERN.source, ")$"));
export var IPV4_OR_NUM_ONLY_PATTERN = new RegExp("(".concat(IPV4_PATTERN.source, ")|(").concat(NUM_ONLY_PATTERN.source, ")"));
export var PORT_RANGE_PATTERN = /^(\d+-\d+|\d+)(,(\d+-\d+|\d+))*$/;
export var NETWORK_RANGE_PATTERN = new RegExp("^".concat(IPV4_PATTERN.source.replace(/\^|\$/g, ''), "\\s?-\\s?").concat(IPV4_PATTERN.source.replace(/\^|\$/g, ''), "$"));
export var SUBNET_MASK_PATTERN = 
// eslint-disable-next-line max-len
/^(((255\.){3}(255|254|252|248|240|224|192|128|0))|((255\.){2}(255|254|252|248|240|224|192|128|0)\.0)|((255\.)(255|254|252|248|240|224|192|128|0)(\.0){2})|((255|254|252|248|240|224|192|128|0)(\.0){3}))$/;
