var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
import { OGLink } from '@opengear/links';
import { SECONDARY_LIGHTHOUSES_ROUTE } from '@/constants/routes';
import { WarningBanner } from '@/components/warning-banner';
import { useDependentLighthouses } from '@/api/multiple-instance/dependent-lighthouses';
export function SystemSecondaryUpgradeWarning() {
    var t = useTranslation().t;
    var _a = useDependentLighthouses(), data = _a.data, isLoading = _a.isLoading;
    var updatingInProccess = useMemo(function () {
        if (data) {
            return data === null || data === void 0 ? void 0 : data.dependent_lighthouses.reduce(function (isUpdating, currentLh) { return isUpdating || currentLh.status === 'Upgrading'; }, false);
        }
        return false;
    }, [data]);
    if (isLoading || !updatingInProccess) {
        return null;
    }
    return (_jsx(WarningBanner, { warningIcon: _jsx(OGAutoRenewIcon, { sx: { fontSize: 24 } }), warningHeader: t('pages.system_upgrade.secondary_upgrade_warning.header'), warningMessage: _jsx(Box, { children: _jsx(OGLink, __assign({ variant: "standard", to: SECONDARY_LIGHTHOUSES_ROUTE }, { children: t('global.buttons.view_details') })) }), bannerCode: "system-secondary-upgrading", dismissPeriod: -1, sxProps: { margin: 0, padding: 2 } }));
}
