var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OGPanel } from '@opengear/panel';
import { OGButton } from '@opengear/buttons';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useNavigate } from 'react-router-dom';
import { CancelButton, FormContainer, FormActions, SubmitButton } from '@/components/layout/forms';
import { transformToRequestObject } from './transformers';
import { LdapSettings } from './ldap';
import { RadiusSettings } from './radius';
import { TacacsSettings } from './tacacs';
import { useCreateTemplate, useUpdateTemplate } from '@/api/template/templates';
import { useAuthConfig } from '@/api/auth/auth-config';
import { AUTH_TEMPLATES_ROUTE } from '@/constants/routes';
import { useFormSync } from '@/hooks/use-form-sync';
import { DeleteTemplateModal } from '@/components/node-tools/config-templates/delete-template-modal';
import { NameField, DescriptionField, AuthModeButtons } from './auth-template-form-controls';
export function AuthTemplateForm(_a) {
    var _this = this;
    var data = _a.data, permissions = _a.permissions;
    var createTemplate = useCreateTemplate();
    var updateTemplate = useUpdateTemplate();
    var authConfigRefetch = useAuthConfig({ enabled: false }).refetch;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var methods = useForm({ mode: 'onTouched', defaultValues: data });
    var formMode = data.id ? 'update' : 'create';
    var selectedAuthMode = methods.watch('authMode');
    var handleCancel = useCallback(function () {
        methods.reset();
        navigate(AUTH_TEMPLATES_ROUTE);
    }, [methods, navigate]);
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var requestObject, updatedData, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestObject = transformToRequestObject(formData);
                    if (!requestObject)
                        return [2 /*return*/];
                    if (!formData.id) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateTemplate(requestObject)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, createTemplate(requestObject)];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    updatedData = _a;
                    if (updatedData && updatedData.type === 'auth') {
                        methods.reset(updatedData);
                        navigate(AUTH_TEMPLATES_ROUTE);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [updateTemplate, createTemplate, methods, navigate]);
    var prePopulateHandler = function () { return __awaiter(_this, void 0, void 0, function () {
        var authConfigData, prePopulateData, updatedLdapAuthenticationServers, updatedRadiusAuthenticationServers, updatedRadiusAccountingServers, updatedTacacsAuthenticationServers;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authConfigRefetch()];
                case 1:
                    authConfigData = (_a.sent()).data;
                    prePopulateData = authConfigData === null || authConfigData === void 0 ? void 0 : authConfigData.auth;
                    if (prePopulateData) {
                        methods.setValue('authMode', prePopulateData.mode, { shouldDirty: true });
                        switch (prePopulateData.mode) {
                            case 'ldap': {
                                updatedLdapAuthenticationServers = prePopulateData.ldapAuthenticationServers.map(function (server) { return ({
                                    hostname: server.hostname,
                                    port: server.port,
                                }); });
                                methods.setValue('ldapAuthenticationServers', updatedLdapAuthenticationServers, { shouldDirty: true });
                                methods.setValue('ldapBaseDN', prePopulateData.ldapBaseDN, { shouldDirty: true });
                                methods.setValue('ldapBindDN', prePopulateData.ldapBindDN, { shouldDirty: true });
                                methods.setValue('ldapGroupMembershipAttribute', prePopulateData.ldapGroupMembershipAttribute, { shouldDirty: true });
                                methods.setValue('ldapIgnoreReferrals', prePopulateData.ldapIgnoreReferrals, { shouldDirty: true });
                                methods.setValue('ldapSslCaCert', prePopulateData.ldapSslCaCert, { shouldDirty: true });
                                methods.setValue('ldapSslIgnoreCertErrors', prePopulateData.ldapSslIgnoreCertErrors, { shouldDirty: true });
                                methods.setValue('ldapSslMode', prePopulateData.ldapSslMode || 'ldap_only', { shouldDirty: true });
                                methods.setValue('ldapUsernameAttribute', prePopulateData.ldapUsernameAttribute, { shouldDirty: true });
                                break;
                            }
                            case 'radius': {
                                updatedRadiusAuthenticationServers = prePopulateData.radiusAuthenticationServers.map(function (server) { return ({
                                    hostname: server.hostname,
                                    port: server.port,
                                }); });
                                updatedRadiusAccountingServers = prePopulateData.radiusAccountingServers.map(function (server) { return ({
                                    hostname: server.hostname,
                                    port: server.port,
                                }); });
                                methods.setValue('radiusAuthenticationServers', updatedRadiusAuthenticationServers, { shouldDirty: true });
                                methods.setValue('radiusAccountingServers', updatedRadiusAccountingServers, { shouldDirty: true });
                                break;
                            }
                            case 'tacacs': {
                                updatedTacacsAuthenticationServers = prePopulateData.tacacsAuthenticationServers.map(function (server) { return ({
                                    hostname: server.hostname,
                                    port: server.port,
                                }); });
                                methods.setValue('tacacsAuthenticationServers', updatedTacacsAuthenticationServers, { shouldDirty: true });
                                methods.setValue('tacacsMethod', prePopulateData.tacacsMethod || 'pap', { shouldDirty: true });
                                methods.setValue('tacacsService', prePopulateData.tacacsService, { shouldDirty: true });
                                break;
                            }
                            default:
                                break;
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useFormSync(methods.formState.defaultValues, data, methods.reset, t('pages.config_templates.edit.form.auth.auth_setting.update.header'));
    return (_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ mode: formMode, permissions: permissions, methods: methods, onSubmit: onSubmit }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", marginBottom: 2 }, { children: t('pages.config_templates.edit.form.auth.template_details.header') })), _jsx(Box, { flexGrow: 1 }), data.id && _jsx(DeleteTemplateModal, { editable: permissions.hasAuthEditAccess, templateId: data.id, redirectUrl: AUTH_TEMPLATES_ROUTE })] })), _jsx(NameField, {}), _jsx(DescriptionField, {}), _jsxs(Stack, __assign({ direction: "column", gap: 1 }, { children: [_jsx(Typography, __assign({ id: "template-title", component: "h3", variant: "h3", marginBottom: 2 }, { children: t('pages.config_templates.edit.form.auth.auth_setting.header') })), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", flexWrap: "wrap", gap: 1, marginBottom: 2 }, { children: [_jsxs(Typography, __assign({ component: "span", marginBottom: 1 }, { children: [t('pages.config_templates.edit.form.auth.auth_setting.pre_populate.title'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.pre_populate.pre_populate_tooltip') })] })), _jsx(OGButton, __assign({ variant: "secondary", onClick: prePopulateHandler, disabled: !permissions.hasAuthEditAccess, sx: { width: '200px' } }, { children: t('pages.config_templates.edit.form.auth.auth_setting.pre_populate.button') }))] }))] })), _jsx(Typography, __assign({ component: "label" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.label') })), _jsx(Stack, __assign({ direction: "column", gap: 1, marginTop: 1, marginBottom: 1 }, { children: _jsx(AuthModeButtons, {}) })), selectedAuthMode === 'radius' && (
                // Render form elements specific to 'radius' auth mode
                _jsx(RadiusSettings, {})), selectedAuthMode === 'tacacs' && (
                // Render form elements specific to 'tacacs' auth mode
                _jsx(TacacsSettings, {})), selectedAuthMode === 'ldap' && (
                // Render form elements specific to 'ldap' auth mode
                _jsx(LdapSettings, {})), _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: handleCancel }), _jsxs(SubmitButton, { children: [" ", t('pages.config_templates.edit.form.auth.button.submit'), " "] })] })] })) }));
}
