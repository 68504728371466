var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useCallback, useMemo, useRef } from 'react';
import { FormContainer } from '@/components/layout/forms';
import { ResourceNameField, ResourceNetworkAddressField, ResourceServiceFieldset } from './smf-resource-modal-form-controls';
import { useCreateSmfResource, useUpdateSmfResource } from '@/api/smf-resources/smf-resources';
import { transformFormStateToRequest, transformRequestToFormState } from './transformers';
import { useDeviceView } from '@/hooks/use-device-view';
import { AssignedTagsInput, RemoveTagButton, AssignExistingTagsInput, CreateNewTagInput, AssignTagFormExistingButton, AssignTagFormCreateButton, } from '../assign-tag-modal/assign-tag-form-controls';
export var SMF_RESOURCE_MODAL_TITLE_ID = 'smf-resource-modal-title-id';
export var defaultValues = {
    name: '',
    address: '',
    http_enabled: false,
    http_port: 80,
    https_enabled: false,
    https_port: 443,
    ssh_enabled: false,
    ssh_port: 22,
    assignedTags: [],
    unassignedTags: [],
    newSelectedOrCreatedTagsFields: [],
};
export function SmfResourceModalForm(_a) {
    var _this = this;
    var children = _a.children, onClose = _a.onClose, smfResource = _a.smfResource, permissions = _a.permissions, _b = _a.resourceTagNames, resourceTagNames = _b === void 0 ? [] : _b;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var createSmfResource = useCreateSmfResource();
    var updateSmfResource = useUpdateSmfResource();
    var addExistingTagButton = useRef(null);
    var mode = !smfResource ? 'create' : 'update';
    var sourceValues = useMemo(function () {
        var defaultFormState = smfResource ? transformRequestToFormState(smfResource, resourceTagNames) : defaultValues;
        if (!smfResource) {
            defaultFormState.unassignedTags = resourceTagNames.map(function (tag) { return ({
                id: tag.id,
                resource_tag_name: tag.resource_tag_name,
            }); });
        }
        return defaultFormState;
    }, [smfResource, resourceTagNames]);
    var SmfResourceId = useMemo(function () { return (smfResource ? smfResource.id : ''); }, [smfResource]);
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    var _c = __read(methods.watch(['http_enabled', 'https_enabled', 'ssh_enabled']), 3), httpEnabled = _c[0], httpsEnabled = _c[1], sshEnabled = _c[2];
    var onSubmitCreateOrUpdateSmfResource = useCallback(function (submittedFormData) { return __awaiter(_this, void 0, void 0, function () {
        var request, updatedData, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = transformFormStateToRequest(smfResource, submittedFormData);
                    if (!(mode === 'create')) return [3 /*break*/, 2];
                    return [4 /*yield*/, createSmfResource(request)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, updateSmfResource(SmfResourceId, request)];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    updatedData = _a;
                    if (updatedData) {
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [updateSmfResource, onClose, mode, createSmfResource, SmfResourceId, smfResource]);
    var _d = useFieldArray({
        control: methods.control,
        name: 'newSelectedOrCreatedTagsFields',
    }), newSelectedOrCreatedTagsFields = _d.fields, appendNewSelectedOrCreatedTags = _d.append, removeNewSelectedOrCreatedTags = _d.remove;
    var _e = useFieldArray({
        control: methods.control,
        name: 'assignedTags',
    }), assignedTagFields = _e.fields, removeAssignedTag = _e.remove;
    var appendUnassignedTags = useFieldArray({
        control: methods.control,
        name: 'unassignedTags',
    }).append;
    // add row to create new tag
    var handleNewCreateTagClick = useCallback(function () {
        appendNewSelectedOrCreatedTags({ resourceTagId: '', newResourceTagName: '', type: 'create' });
    }, [appendNewSelectedOrCreatedTags]);
    // add row to exiting tag
    var handleExistingTagClick = useCallback(function () {
        appendNewSelectedOrCreatedTags({ resourceTagId: '', newResourceTagName: '', type: 'existing' });
    }, [appendNewSelectedOrCreatedTags]);
    // Remove assigned resource tags, append newly removed tag back into assigned tag list
    var handleAssignedTagRemove = useCallback(function (index, tag) {
        if (index === assignedTagFields.length - 1 && newSelectedOrCreatedTagsFields.length > 0) {
            methods.setFocus("newSelectedOrCreatedTagsFields.0.newResourceTagName");
        }
        else if (assignedTagFields.length > 1) {
            var nextTag = assignedTagFields.length - 1 > index ? index + 1 : index - 1;
            methods.setFocus("assignedTags.".concat(nextTag, ".resource_tag_name"));
        }
        else if (addExistingTagButton.current) {
            addExistingTagButton.current.focus();
        }
        var foundTag = resourceTagNames.find(function (resourceTag) { return resourceTag.resource_tag_name === tag.resource_tag_name; });
        if (foundTag) {
            var unassignedTag = { id: foundTag.id, resource_tag_name: foundTag.resource_tag_name };
            appendUnassignedTags([__assign({}, unassignedTag)]);
        }
        removeAssignedTag(index);
    }, [removeAssignedTag, resourceTagNames, appendUnassignedTags, assignedTagFields, methods, newSelectedOrCreatedTagsFields]);
    // handle removal of newly added rows
    var handleNewRowRemove = useCallback(function (index) {
        if (newSelectedOrCreatedTagsFields.length > 1) {
            var nextTag = newSelectedOrCreatedTagsFields.length - 1 > index ? index + 1 : index - 1;
            methods.setFocus("newSelectedOrCreatedTagsFields.".concat(nextTag, ".newResourceTagName"));
        }
        else if (assignedTagFields.length > 0) {
            methods.setFocus("assignedTags.".concat(assignedTagFields.length - 1, ".resource_tag_name"));
        }
        else if (addExistingTagButton.current) {
            addExistingTagButton.current.focus();
        }
        removeNewSelectedOrCreatedTags(index);
    }, [removeNewSelectedOrCreatedTags, newSelectedOrCreatedTagsFields, methods, assignedTagFields]);
    // No tags selected message
    var showNoTagsSelected = useMemo(function () { return !newSelectedOrCreatedTagsFields.length && !assignedTagFields.length; }, [newSelectedOrCreatedTagsFields.length, assignedTagFields.length]);
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: SMF_RESOURCE_MODAL_TITLE_ID, mode: mode, methods: methods, onSubmit: onSubmitCreateOrUpdateSmfResource, permissions: permissions }, { children: [_jsx(ResourceNameField, {}), _jsx(ResourceNetworkAddressField, {}), _jsxs(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: [t('pages.smf_resources.modal.form.fields.services.title'), _jsx(OGIconTooltip, { title: t('pages.smf_resources.modal.form.fields.services.help') })] })), _jsxs(Stack, __assign({ spacing: 1, direction: isMobileView ? 'column' : 'row' }, { children: [ResourceServiceFieldset('http', t('pages.smf_resources.modal.form.fields.services.labels.http_port'), httpEnabled), ResourceServiceFieldset('https', t('pages.smf_resources.modal.form.fields.services.labels.https_port'), httpsEnabled), ResourceServiceFieldset('ssh', t('pages.smf_resources.modal.form.fields.services.labels.ssh_port'), sshEnabled)] })), _jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.smf_resources.modal.form.fields.tags.title') })), showNoTagsSelected && (_jsx(Box, { children: _jsx(Typography, { children: t('components.assign_tag_modal.no_tags_selected') }) })), assignedTagFields.map(function (tag, index) { return (_jsx(Box, __assign({ py: 0.5 }, { children: _jsx(AssignedTagsInput, __assign({ index: index }, { children: _jsx(RemoveTagButton, { disabled: !permissions.hasTagAssignAccess, onClick: function () { return handleAssignedTagRemove(index, tag); } }) })) }), tag.id)); }), newSelectedOrCreatedTagsFields.map(function (input, index) { return (_jsx(Box, __assign({ py: 0.5 }, { children: input && input.type === 'existing' ? (
                // existing tags input
                _jsx(AssignExistingTagsInput, __assign({ index: index }, { children: _jsx(RemoveTagButton, { onClick: function () { return handleNewRowRemove(index); } }) }))) : (
                // new tags input
                _jsx(CreateNewTagInput, __assign({ index: index }, { children: _jsx(RemoveTagButton, { onClick: function () { return handleNewRowRemove(index); } }) }))) }), input.id)); }), _jsxs(Stack, __assign({ direction: "row", spacing: 2, p: 2, pl: 0 }, { children: [_jsx(AssignTagFormExistingButton, { ref: addExistingTagButton, onClick: handleExistingTagClick, disabled: !permissions.hasTagAssignAccess }), _jsx(AssignTagFormCreateButton, { onClick: handleNewCreateTagClick, disabled: !permissions.hasTagCreateAccess })] })), _jsx(Divider, { sx: { marginX: -2 } }), children] })));
}
