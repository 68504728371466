var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OGTable } from '@opengear/table';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import { EnrolledStateCell } from '@/components/nodes/enrolled-state-cell';
import { usePaginationModel } from '@/hooks/pagination-model';
import { TemplatePopover } from '@/components/nodes/node/template-status-popover';
export var PreflightStatus;
(function (PreflightStatus) {
    PreflightStatus[PreflightStatus["Error"] = 0] = "Error";
    PreflightStatus[PreflightStatus["Warning"] = 1] = "Warning";
    PreflightStatus[PreflightStatus["Success"] = 2] = "Success";
    PreflightStatus[PreflightStatus["Pending"] = 3] = "Pending";
})(PreflightStatus || (PreflightStatus = {}));
function calculateStatus(ctx) {
    if (ctx.status !== 'preflight_complete') {
        return PreflightStatus.Pending;
    }
    if (ctx.preflight_errors.length > 0) {
        return PreflightStatus.Error;
    }
    if (ctx.preflight_warnings.length > 0) {
        return PreflightStatus.Warning;
    }
    return PreflightStatus.Success;
}
function calculateMessage(ctx, t) {
    var messages = [];
    if (ctx.preflight_errors.length > 0) {
        messages = ctx.preflight_errors;
    }
    else if (ctx.preflight_warnings.length > 0) {
        messages = ctx.preflight_warnings;
    }
    else if (ctx.preflight_messages.length > 0) {
        messages = ctx.preflight_messages;
    }
    else if (ctx.preflight_changes.length > 0) {
        messages = ctx.preflight_changes;
    }
    var formattedMessages = messages.map(function (m) {
        return t("api.error_codes.".concat(m.code.toString()), __assign(__assign({}, m.args), { defaultValue: t('api.error_codes.code_not_found') }));
    });
    // There aren't any templates that return more than one message of the same level - but it is in theory possible
    // So we need to handle it, but in general this will only be a single message
    return formattedMessages.join(', ');
}
function generateTableData(nodeContexts, nodeData, t) {
    return nodeContexts.flatMap(function (context) {
        var node = nodeData.find(function (nodeInfo) { return nodeInfo.id === context.node_id; });
        if (node) {
            return [
                {
                    id: node.id,
                    runtime_status: node.runtime_status,
                    name: node.name,
                    preflight_status: calculateStatus(context),
                    message: calculateMessage(context, t),
                    config_push_status: node.config_push_status,
                },
            ];
        }
        return [];
    });
}
export function PreflightStatusCell(_a) {
    var preflight_status = _a.preflight_status, message = _a.message;
    var t = useTranslation().t;
    var icon = _jsx(SuccessIcon, { "aria-hidden": "false", fontSize: "medium", color: "success", titleAccess: t('pages.config_templates.push.status_labels.success') });
    if (preflight_status === PreflightStatus.Pending) {
        return _jsx(Typography, { children: t('pages.config_templates.push.preflight_pending') });
    }
    if (preflight_status === PreflightStatus.Error) {
        icon = _jsx(ErrorIcon, { "aria-hidden": "false", fontSize: "medium", color: "error", titleAccess: t('pages.config_templates.push.status_labels.error') });
    }
    else if (preflight_status === PreflightStatus.Warning) {
        icon = _jsx(WarningIcon, { "aria-hidden": "false", fontSize: "medium", color: "warning", titleAccess: t('pages.config_templates.push.status_labels.warning') });
    }
    return (_jsxs(_Fragment, { children: [icon, _jsx(Typography, __assign({ sx: { marginLeft: 1 } }, { children: message }))] }));
}
function TemplatePreflightTable(_a) {
    var nodeContexts = _a.nodeContexts, nodeData = _a.nodeData, status = _a.status, onSelectionChange = _a.onSelectionChange;
    var t = useTranslation().t;
    var rows = useMemo(function () { return generateTableData(nodeContexts, nodeData, t); }, [nodeContexts, nodeData, t]);
    var defaultSortModel = [{ field: 'preflight_status', sort: 'asc' }];
    var _b = __read(usePaginationModel('template_preflight'), 3), paginationModel = _b[0], handlePaginationModelChange = _b[1], pageSizeOptions = _b[2];
    var preflightFinishedFlag = useRef(false);
    var _c = __read(useState([]), 2), selectedRows = _c[0], setSelectedRows = _c[1];
    useEffect(function () {
        if (status === 'preflight_complete' && preflightFinishedFlag.current === false) {
            var rowsToSelect = rows.filter(function (row) { return row.preflight_status !== PreflightStatus.Error; }).map(function (row) { return row.id; });
            setSelectedRows(rowsToSelect);
            if (onSelectionChange)
                onSelectionChange(rowsToSelect);
            preflightFinishedFlag.current = true;
        }
    }, [onSelectionChange, rows, status]);
    var columns = useMemo(function () { return [
        {
            field: 'connection_status',
            headerName: t('pages.nodes.table.headers.state'),
            maxWidth: 1,
            minWidth: 85,
            valueGetter: function (_a) {
                var row = _a.row;
                return row.runtime_status.connection_status;
            },
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(EnrolledStateCell, { runtime_status: row.runtime_status });
            },
        },
        { field: 'id', headerName: t('pages.nodes.table.headers.id'), minWidth: 110 },
        { field: 'name', headerName: t('pages.nodes.table.headers.name'), minWidth: 160 },
        {
            field: 'config_push_status',
            headerName: t('pages.nodes.table.headers.template'),
            sortable: false,
            renderCell: TemplatePopover,
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'preflight_status',
            headerName: t('pages.config_templates.push.preflight_status'),
            flex: 1,
            minWidth: 240,
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(PreflightStatusCell, { preflight_status: row.preflight_status, message: row.message });
            },
        },
    ]; }, [t]);
    return (_jsx(OGTable, { id: "template-preflight-table", "aria-label": t('pages.config_templates.push.preflight_label'), columns: columns, rows: rows, pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "client", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, initialState: {
            sorting: {
                sortModel: defaultSortModel,
            },
        }, checkboxSelection: true, selectedRowCount: selectedRows.length, translations: { entityLabel: t('global.table.node') }, rowSelectionModel: selectedRows, onRowSelectionModelChange: function (newRowSelectedModel) {
            setSelectedRows(newRowSelectedModel);
            if (onSelectionChange)
                onSelectionChange(newRowSelectedModel); // id in PreflightTableRow is a string
        }, isRowSelectable: function () { return status === 'preflight_complete'; } }));
}
// Reduce re-renders
export default memo(TemplatePreflightTable);
