var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { OGPseudoTable, OGPseudoTableRow, OGPseudoTableCell, OGPseudoTableBody } from '@opengear/table/pseudo-table';
import Divider from '@mui/material/Divider';
import { OG_FOCUS_BOX_INNER_SHADOW } from '@opengear/theme/colors';
import { useNodeCellHealthDetails } from '@/api/nodes/cellhealth-runtime-details';
import { useCellhealthSettings } from '@/api/services/cellhealth';
import { LoadingOverlay } from '../layout/loading-overlay';
import { LoadingErrorOverlay } from '../layout/loading-error-overlay';
export var CELLULAR_DETAILS_MODAL_TEST_ID = 'cellular-details-modal-loader';
var CONTENTHEIGHT = 330;
export function CellularDetailsModal(_a) {
    var _b = _a.nodeDetails, nodeId = _b.nodeId, nodeName = _b.nodeName, open = _a.open, onClose = _a.onClose;
    var t = useTranslation().t;
    var _c = useNodeCellHealthDetails(nodeId), nodeCellData = _c.data, isNodeCellDataLoading = _c.isLoading;
    var _d = useCellhealthSettings(), cellHealthSettings = _d.data, isHealthDataLoading = _d.isLoading;
    var innerNode = null;
    var cellData = nodeCellData === null || nodeCellData === void 0 ? void 0 : nodeCellData.node_cellhealth_runtime_details;
    var failoverValue = useMemo(function () {
        if (!cellData)
            return undefined;
        // failover_status Active means configured (ready to fail over)
        if (cellData.failover_enabled && cellData.failover_status === 'Active') {
            return t('components.cellular_details_modal.values.failover_configured');
        }
        // failover_status Failover means active (failed over)
        if (cellData.failover_enabled && cellData.failover_status === 'Failover') {
            return t('components.cellular_details_modal.values.failover_active');
        }
        if (cellData.failover_enabled) {
            return t('components.cellular_details_modal.values.failover_failed');
        }
        return t('components.cellular_details_modal.values.disabled');
    }, [cellData, t]);
    var connectivityTestValue = useMemo(function () {
        if (!(cellHealthSettings === null || cellHealthSettings === void 0 ? void 0 : cellHealthSettings.connectivity_test_enabled))
            return t('components.cellular_details_modal.values.connectivity_disabled');
        if ((cellData === null || cellData === void 0 ? void 0 : cellData.connectivity_test) === -1)
            return t('components.cellular_details_modal.values.connectivity_pending');
        if (cellData === null || cellData === void 0 ? void 0 : cellData.connectivity_test)
            return t('components.cellular_details_modal.values.connectivity_pass');
        return t('components.cellular_details_modal.values.connectivity_fail');
    }, [cellHealthSettings, cellData, t]);
    if (isNodeCellDataLoading || isHealthDataLoading) {
        innerNode = _jsx(LoadingOverlay, { "data-testid": CELLULAR_DETAILS_MODAL_TEST_ID });
    }
    if ((!isNodeCellDataLoading && cellData === undefined) || (!isHealthDataLoading && cellHealthSettings === undefined)) {
        innerNode = _jsx(LoadingErrorOverlay, {});
    }
    if (cellData && cellHealthSettings) {
        innerNode = (_jsx(OGPseudoTable, __assign({ "aria-label": t('components.cellular_details_modal.table_label'), 
            // The table does not have interactive elements, as a result, we struggle
            // to scroll the content with arrow keys. The issue appears on 400% zoom.
            // To fix that tabIndex is added and styled below to be consistent in styling
            tabIndex: 0, sx: {
                'width': '100%',
                '&:focus-within': { outline: 'none' },
                '&:focus-visible': { boxShadow: OG_FOCUS_BOX_INNER_SHADOW },
                'padding': '3px',
                '& .MuiTableCell-head': { width: '170px' },
                '& .MuiTableCell-body': { flex: 1, lineHeight: 1.4 },
            } }, { children: _jsxs(OGPseudoTableBody, { children: [_jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.status') })), _jsx(OGPseudoTableCell, { children: cellData.connection_status
                                    ? t('components.cellular_details_modal.values.connection_up')
                                    : t('components.cellular_details_modal.values.connection_down') })] }), cellData.connection_status && (cellData.ipv4_address || cellData.ipv6_address) && (_jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.cell_ip_address') })), _jsx(OGPseudoTableCell, { children: _jsxs(Stack, { children: [cellData.ipv4_address && _jsx("span", { children: cellData.ipv4_address }), cellData.ipv6_address && _jsx("span", { children: cellData.ipv6_address })] }) })] })), _jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.conditions') })), _jsx(OGPseudoTableCell, { children: _jsxs(Stack, { children: [_jsx("span", { children: "".concat(cellData.is_ngcs
                                                ? t('components.cellular_details_modal.sub_headers.failover')
                                                : t('components.cellular_details_modal.sub_headers.dormant_failover'), ": \n                  ").concat(failoverValue) }), _jsx("span", { children: "".concat(t('components.cellular_details_modal.sub_headers.ar_controlled'), ": ").concat(cellData.ar_controlled ? t('components.cellular_details_modal.values.enabled') : t('components.cellular_details_modal.values.disabled')) }), _jsx("span", { children: "".concat(t('components.cellular_details_modal.sub_headers.ip_passthrough'), ": ").concat(cellData.passthrough_enabled
                                                ? t('components.cellular_details_modal.values.enabled')
                                                : t('components.cellular_details_modal.values.disabled')) })] }) })] }), _jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.signal_quality') })), _jsx(OGPseudoTableCell, { children: cellData.signal_strength })] }), _jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.rssi') })), _jsx(OGPseudoTableCell, { children: cellData.rssi })] }), _jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.connection_type') })), _jsx(OGPseudoTableCell, { children: cellData.connection_type })] }), !cellData.connection_status && (_jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.sim_status') })), _jsx(OGPseudoTableCell, { children: cellData.sim_issues === 'None'
                                    ? t('components.cellular_details_modal.values.sim_card_present')
                                    : format(t('components.cellular_details_modal.values.sim_card'), cellData.sim_issues) })] })), _jsxs(OGPseudoTableRow, { children: [_jsx(OGPseudoTableCell, __assign({ variant: "head" }, { children: t('components.cellular_details_modal.headers.connectivity_test') })), _jsx(OGPseudoTableCell, { children: connectivityTestValue })] })] }) })));
    }
    return (_jsxs(OGDialog, __assign({ open: open, onClose: onClose, fullWidth: true, sx: {
            '& .MuiDialog-paper': {
                minWidth: 'unset',
                maxWidth: '450px',
            },
        } }, { children: [_jsx(DialogTitle, { children: format(t('components.cellular_details_modal.title'), nodeName !== null && nodeName !== void 0 ? nodeName : nodeId) }), _jsx(Divider, { sx: { marginBottom: 2 } }), _jsx(DialogContent, __assign({ sx: { paddingY: 0, height: CONTENTHEIGHT } }, { children: innerNode })), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: onClose, autoFocus: true }, { children: t('global.buttons.close') })) })] })));
}
