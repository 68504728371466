var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { OGButton } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
import { useFormContainerContext } from './container-context';
export function DeleteButton(_a) {
    var disabled = _a.disabled, otherProps = __rest(_a, ["disabled"]);
    var t = useTranslation().t;
    var isSubmitting = useFormContainerContext().formState.isSubmitting;
    return (_jsx(OGButton, __assign({ disabled: isSubmitting || disabled, startIcon: _jsx(DeleteIcon, { fontSize: "small" }), variant: "secondary" }, otherProps, { children: t('global.buttons.delete') })));
}
export function CancelButton(_a) {
    var onCancel = _a.onCancel, otherProps = __rest(_a, ["onCancel"]);
    var t = useTranslation().t;
    var isSubmitting = useFormContainerContext().formState.isSubmitting;
    return (_jsx(OGButton, __assign({ disabled: isSubmitting, variant: "borderless", onClick: onCancel }, otherProps, { children: t('global.buttons.cancel') })));
}
export function ResetButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), _b = _a.formState, isDirty = _b.isDirty, isSubmitting = _b.isSubmitting, reset = _a.reset;
    var resetDisabled = !isDirty || isSubmitting;
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    return (_jsx(OGButton, __assign({ disabled: resetDisabled, variant: "borderless", onClick: handleReset }, { children: t('global.buttons.reset') })));
}
export function SubmitButton(_a) {
    var children = _a.children, buttonProps = __rest(_a, ["children"]);
    var t = useTranslation().t;
    var _b = useFormContainerContext().formState, isValid = _b.isValid, isDirty = _b.isDirty, isSubmitting = _b.isSubmitting;
    var submitDisabled = !isValid || !isDirty || isSubmitting;
    return (_jsx(OGButton, __assign({ type: "submit" }, buttonProps, { disabled: submitDisabled, loading: isSubmitting }, { children: children || t('global.buttons.apply') })));
}
export function FormActions(_a) {
    var children = _a.children;
    return (_jsx(Box, __assign({ display: "flex", gap: 1, justifyContent: "end", marginTop: 2, width: "100%" }, { children: children })));
}
