var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import format from 'string-format';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import { PortField, ProtocolButtons, ServerAddressField } from './syslog-form-controls';
import { CancelButton, FormContainer, FormActions, SubmitButton } from '@/components/layout/forms';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
import { useDeleteSyslogServer } from '@/api/services/syslog';
var defaultValues = {
    address: '',
    protocol: 'UDP',
    port: 514,
};
export function SyslogForm(_a) {
    var ariaLabelledBy = _a.ariaLabelledBy, onClose = _a.onClose, data = _a.data, onSubmit = _a.onSubmit, permissions = _a.permissions;
    var t = useTranslation().t;
    var mode = data ? 'update' : 'create';
    var sourceValues = useMemo(function () { return data || defaultValues; }, [data]);
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    var deleteSyslog = useDeleteSyslogServer();
    var onDelete = useCallback(function () { return deleteSyslog(data === null || data === void 0 ? void 0 : data.id); }, [data, deleteSyslog]);
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: ariaLabelledBy, mode: mode, methods: methods, onSubmit: onSubmit, permissions: permissions }, { children: [_jsx(ServerAddressField, {}), _jsx(ProtocolButtons, {}), _jsx(PortField, {}), _jsx(Divider, {}), _jsxs(FormActions, { children: [mode === 'update' && !!(data === null || data === void 0 ? void 0 : data.id) && (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.alerts_and_monitoring.tabs.syslog.modal.delete.title'), deleteButtonLabel: t('global.buttons.delete'), onDelete: onDelete, onSuccess: onClose, disabled: !permissions.hasEditPermission }, { children: _jsx(DialogContentText, { children: format(t('pages.alerts_and_monitoring.tabs.syslog.modal.delete.content'), data.id) }) }))), _jsx(Box, { flexGrow: 1 }), _jsx(CancelButton, { onCancel: onClose }), _jsx(SubmitButton, { children: mode === 'update'
                            ? t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.buttons.update')
                            : t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.buttons.create') })] })] })));
}
