var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Constants
export var NameFieldMaxLength = 64;
export var DescriptionFieldMaxLength = 128;
export function NameField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, mode = _a.mode;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.users_groups.sections.template_details.fields.name.label')),
            maxLength: {
                value: NameFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.template_details.fields.name.label'), NameFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "name", required: true, disabled: !permissions.hasEditPermission || mode === 'update', error: !!fieldState.error, label: t('pages.config_templates.tabs.users_groups.sections.template_details.fields.name.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.template_details.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "description", disabled: !permissions.hasEditPermission, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.config_templates.tabs.users_groups.sections.template_details.fields.description.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
        } }));
}
