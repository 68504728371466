var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
export function StatusField(_a) {
    var _b;
    var formData = _a.formData, handleSelectChange = _a.handleSelectChange, nodeUpgradeStatusOptions = _a.nodeUpgradeStatusOptions;
    var t = useTranslation().t;
    return (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(Select
        // eslint-disable-next-line jsx-a11y/no-autofocus
        , __assign({ 
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus: true, id: "status", name: "status", sx: { minWidth: '200px' }, label: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.label'), placeholder: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.label'), value: (_b = formData.status) !== null && _b !== void 0 ? _b : '', margin: "none", inputProps: { 'aria-label': t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.label') }, displayEmpty: true, renderValue: function (value) {
                return !value ? _jsx("em", { children: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.placeholder') }) : nodeUpgradeStatusOptions[value];
            }, onChange: handleSelectChange }, { children: Object.entries(nodeUpgradeStatusOptions).map(function (_a) {
                var _b = __read(_a, 2), value = _b[0], label = _b[1];
                return (_jsx(MenuItem, __assign({ value: value }, { children: label }), value));
            }) })) })));
}
export function PeriodField(_a) {
    var _b;
    var formData = _a.formData, handleSelectChange = _a.handleSelectChange, nodeUpgradePeriodOptions = _a.nodeUpgradePeriodOptions;
    var t = useTranslation().t;
    return (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(Select
        // eslint-disable-next-line jsx-a11y/no-autofocus
        , __assign({ 
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus: true, id: "last_x_days", name: "last_x_days", sx: { minWidth: '200px' }, label: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.label'), placeholder: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.label'), value: (_b = formData.last_x_days) !== null && _b !== void 0 ? _b : '', margin: "none", inputProps: { 'aria-label': t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.label') }, displayEmpty: true, renderValue: function (value) {
                return !value ? _jsx("em", { children: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.placeholder') }) : nodeUpgradePeriodOptions[value];
            }, onChange: handleSelectChange }, { children: Object.entries(nodeUpgradePeriodOptions).map(function (_a) {
                var _b = __read(_a, 2), value = _b[0], label = _b[1];
                return (_jsx(MenuItem, __assign({ value: value }, { children: label }), value));
            }) })) })));
}
