var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { OGPanel } from '@opengear/panel';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { WarningBanner } from '@/components/warning-banner';
import { NetworkInterfacesTable } from '@/components/settings/system/network-interfaces/network-interfaces-table';
export var NetworkInterfaces = withAuthenticationRequired(function () {
    var t = useTranslation().t;
    useDocTitle(t('pages.network_interfaces.title'));
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.network_interfaces.title') })), _jsx(Box, __assign({ marginTop: 2 }, { children: _jsx(WarningBanner, { warningIcon: _jsx(ReportProblem, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.network_interfaces.warning.header'), warningMessage: _jsx(Typography, { children: t('pages.network_interfaces.warning.message') }), warningColor: "warning", bannerCode: "network-interfaces-warning", sxProps: { margin: 0, marginBottom: 2, padding: 1 } }) })), _jsx(Box, __assign({ marginTop: 2 }, { children: _jsx(OGPanel, { children: _jsx(NetworkInterfacesTable, {}) }) }))] })));
}, 
// Verifies user permissions and network interfaces check
{
    rights: ['settings.network_connections.network_interfaces.view'],
});
