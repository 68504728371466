var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { OGButton } from '@opengear/buttons';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { OG_OFF_WHITE, OG_BLACK_90 } from '@opengear/theme/colors';
import debounce from 'lodash/debounce';
import { PasswordPolicyMessages } from '@/components/password-policy-message';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { useUpdatePasswordValidation } from '@/api/users/password-validation';
import { useUpdatePasswordReset } from '@/api/users/password-reset';
import { requiredFieldError } from '@/utils/field-validation-errors';
export function ResetPasswordForm(_a) {
    var _this = this;
    var userName = _a.userName, signOut = _a.signOut, passwordPolicy = _a.passwordPolicy;
    var t = useTranslation().t;
    var updatePasswordValidation = useUpdatePasswordValidation();
    var alertNotification = useAlertNotification().alertNotification;
    var updatePasswordReset = useUpdatePasswordReset();
    var _b = __read(useState(undefined), 2), passwordValidationData = _b[0], setPasswordValidationData = _b[1];
    var theme = useTheme();
    var darkMode = theme.palette.mode === 'dark';
    var _c = useForm({
        defaultValues: { current_password: '', new_password: '', confirm_new_password: '' },
        mode: 'onTouched',
    }), control = _c.control, handleSubmit = _c.handleSubmit, _d = _c.formState, isSubmitting = _d.isSubmitting, isDirty = _d.isDirty, isValid = _d.isValid, trigger = _c.trigger, getValues = _c.getValues;
    var passwordPolicyList = passwordPolicy === null || passwordPolicy === void 0 ? void 0 : passwordPolicy.password_policy;
    var isPasswordPolicyEnabled = useMemo(function () {
        return (passwordPolicyList === null || passwordPolicyList === void 0 ? void 0 : passwordPolicyList.enabled) &&
            (passwordPolicyList.min_password_length > 0 ||
                passwordPolicyList.no_username ||
                passwordPolicyList.must_contain_capital_letter ||
                passwordPolicyList.must_contain_number ||
                passwordPolicyList.must_contain_special_character);
    }, [passwordPolicyList]);
    var passwordValidationFunction = useCallback(function (newPassword) { return __awaiter(_this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!userName) return [3 /*break*/, 2];
                    return [4 /*yield*/, updatePasswordValidation({
                            password_validation: {
                                username: userName,
                                password: newPassword,
                            },
                        })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
                case 2: return [2 /*return*/, null];
            }
        });
    }); }, [updatePasswordValidation, userName]);
    var validatePassword = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, newPassword, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!isPasswordPolicyEnabled) {
                        setPasswordValidationData(undefined);
                        return [2 /*return*/, undefined];
                    }
                    _a = __read(getValues(['new_password']), 1), newPassword = _a[0];
                    return [4 /*yield*/, passwordValidationFunction(newPassword)];
                case 1:
                    data = _b.sent();
                    if (data) {
                        setPasswordValidationData(data);
                        return [2 /*return*/, data.result];
                    }
                    return [2 /*return*/, undefined];
            }
        });
    }); }, [getValues, isPasswordPolicyEnabled, passwordValidationFunction]);
    var debounceValidatePassword = useMemo(function () { return debounce(validatePassword, 500); }, [validatePassword]);
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var updatedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updatePasswordReset({
                        current_password: formData.current_password,
                        new_password: formData.new_password,
                    })];
                case 1:
                    updatedData = _a.sent();
                    if (!updatedData) return [3 /*break*/, 3];
                    return [4 /*yield*/, signOut()];
                case 2:
                    _a.sent();
                    alertNotification(t('api.password_reset.put.ok.message'), { priority: OGToastPriorities.Success });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [alertNotification, signOut, t, updatePasswordReset]);
    var handleCancel = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signOut()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [signOut]);
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, { children: t('pages.password_reset.form.fields.title') }), _jsx(DialogContent, { children: _jsxs(Stack, __assign({ component: "form", "aria-label": "Create new password", onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Controller, { control: control, name: "current_password", rules: { required: requiredFieldError(t('pages.password_reset.form.fields.current_password.label')) }, render: function (_a) {
                                var _b;
                                var field = _a.field, fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ fullWidth: true, id: "current-password", autoComplete: "current-password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.password_reset.form.fields.current_password.label'), type: "password" }, field, { required: true })));
                            } }), _jsx(Controller, { control: control, name: "new_password", rules: {
                                required: requiredFieldError(t('pages.password_reset.form.fields.new_password.label')),
                                validate: debounceValidatePassword,
                            }, render: function (_a) {
                                var _b;
                                var _c = _a.field, onChange = _c.onChange, field = __rest(_c, ["onChange"]), fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ fullWidth: true, id: "new-password", autoComplete: "new-password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.password_reset.form.fields.new_password.label'), type: "password" }, field, { required: true, onChange: function (event) {
                                        onChange(event);
                                        trigger('new_password');
                                    } })));
                            } }), isPasswordPolicyEnabled && passwordPolicyList && (_jsx(Typography, __assign({ variant: "body2", color: "textSecondary", tabIndex: 0, sx: { backgroundColor: darkMode ? OG_BLACK_90 : OG_OFF_WHITE, marginBottom: 2, padding: 1 } }, { children: _jsx(PasswordPolicyMessages, { passwordPolicyList: passwordPolicyList, passwordValidationData: passwordValidationData }) }))), _jsx(Controller, { control: control, name: "confirm_new_password", rules: {
                                validate: function (value, formValues) { return value === formValues.new_password || t('global.form.password_not_matched'); },
                            }, render: function (_a) {
                                var _b;
                                var _c = _a.field, onChange = _c.onChange, field = __rest(_c, ["onChange"]), fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ fullWidth: true, id: "confirm-new-password", sx: { marginBottom: 1 }, label: t('pages.password_reset.form.fields.confirm_new_password.label'), margin: "normal", type: "password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field, { onChange: function (event) {
                                        onChange(event);
                                        trigger('confirm_new_password');
                                    } })));
                            } }), _jsxs(Box, __assign({ display: "flex", gap: 1, marginTop: 1, justifyContent: "end" }, { children: [_jsx(OGButton, __assign({ disabled: isSubmitting, variant: "borderless", onClick: handleCancel }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ type: "submit", disabled: !userName || (isPasswordPolicyEnabled && !(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.result)) || !isDirty || !isValid, loading: isSubmitting }, { children: t('global.buttons.apply') }))] }))] })) })] }));
}
