var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
export function SystemUpgradeInfo(_a) {
    var state = _a.state;
    var t = useTranslation().t;
    var info = state === 'pending' || state === 'running' || state === 'finished'
        ? t('components.system_upgrade_status.info.active')
        : t('components.system_upgrade_status.info.idle');
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }, { children: [_jsx(InfoIcon, { role: "none" }), _jsx(Typography, { children: info })] })));
}
