var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { useCallback, useEffect, useMemo } from 'react';
import TabList from '@mui/lab/TabList';
import { LIGHTHOUSE_API_VERSION } from '@/api/utils';
import NetopsIframe from '@/containers/nom/netops-iframe';
import { useNetopsModules } from '@/api/netops';
import { useNetopsModuleRoutes } from '@/api/netops/routes';
import { NOT_FOUND_ROUTE, formatNetopsUIRoute } from '@/constants/routes';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var NOM_UI_LOADING_ID = 'nom-ui-loading';
function NetopsModulePage() {
    var params = useParams();
    var _a = useNetopsModules(), moduleData = _a.data, isLoadingModuleData = _a.isLoading;
    var navigate = useNavigate();
    var routeNotFound = useCallback(function () {
        navigate(NOT_FOUND_ROUTE);
    }, [navigate]);
    var _b = useNetopsModuleRoutes(params.moduleId || '', { notFoundCallback: routeNotFound }), routeData = _b.data, isLoadingRouteData = _b.isLoading;
    var currentModule = moduleData === null || moduleData === void 0 ? void 0 : moduleData['netops-modules'].find(function (module) { return module.id === params.moduleId; });
    var routeList = useMemo(function () { return (routeData && currentModule ? routeData[currentModule.name] : []); }, [currentModule, routeData]);
    var knownRoute = routeList.length > 0 && routeList.find(function (route) { return route.route === params.routeName; });
    // Redirect queries for the base/unknown route to the first tab
    useEffect(function () {
        if (routeList.length > 0 && !knownRoute) {
            navigate(formatNetopsUIRoute(params.moduleId, routeList[0].route), { replace: true });
        }
    }, [knownRoute, navigate, params.moduleId, routeList]);
    if (isLoadingModuleData || isLoadingRouteData || !params.moduleId || !params.routeName || !currentModule) {
        return _jsx(LoadingBackdrop, { "data-testid": NOM_UI_LOADING_ID });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: currentModule.name })), _jsx(Paper, { children: knownRoute && (_jsxs(TabContext, __assign({ value: params.routeName }, { children: [_jsx(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: routeList.map(function (route) { return (_jsx(Tab, { component: RouterLink, to: formatNetopsUIRoute(params.moduleId, route.route), label: route.name, value: route.route, id: route.name }, route.name)); }) })), _jsx(TabPanel, __assign({ value: params.routeName, sx: { padding: 0 } }, { children: _jsx(NetopsIframe, { src: "/api/".concat(LIGHTHOUSE_API_VERSION).concat(formatNetopsUIRoute(params.moduleId, params.routeName)) }) }))] }))) })] })));
}
export default withAuthenticationRequired(NetopsModulePage, { rights: ['configure.netops.modules.view'] });
