var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { requiredFieldError } from '@/utils/field-validation-errors';
export function EncryptBackupToggleButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, setValue = _a.setValue;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ component: "label" }, { children: [t('pages.backup_restore_reset.tabs.config_backup.form.encrypt.label'), _jsx(OGIconTooltip, { title: t('pages.backup_restore_reset.tabs.config_backup.form.encrypt.tooltip') })] })), _jsx(Controller, { control: control, name: "encrypt", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasEditPermission, value: value, onChange: function (event, val) {
                            if (val !== null) {
                                onChange(val);
                                setValue('password', '');
                                setValue('confirm', '', { shouldValidate: true });
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
                } })] }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch, permissions = _a.permissions;
    var isEncrypted = watch('encrypt');
    return (_jsx(Controller, { control: control, name: "password", rules: {
            required: isEncrypted ? requiredFieldError(t('pages.backup_restore_reset.tabs.config_backup.form.password.label')) : false,
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", label: t('pages.backup_restore_reset.tabs.config_backup.form.password.label'), error: fieldState.invalid, helperText: fieldState.error ? fieldState.error.message : null, required: !!isEncrypted }, field)));
        } }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirm", rules: {
            validate: {
                passwordsMatch: function (value, formValues) {
                    return formValues.password === formValues.confirm || t('global.form.password_not_matched');
                },
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", label: t('pages.backup_restore_reset.tabs.config_backup.form.password_confirm.label'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function UserFiles() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "userFiles", render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ id: field.name, "aria-label": t('pages.backup_restore_reset.tabs.config_backup.form.user_files.label'), multiline: true, label: _jsxs(_Fragment, { children: [t('pages.backup_restore_reset.tabs.config_backup.form.user_files.label'), _jsx(OGIconTooltip, { title: t('pages.backup_restore_reset.tabs.config_backup.form.user_files.tooltip') })] }), sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission }, field)));
        } }));
}
