var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import { FormActions, FormContainer } from '@/components/layout/forms';
import { useUploadNetops } from '@/api/services/netops/netops-installer';
import { NetopsOfflineFileManager, NetopsUploadButton } from './netops-settings-components';
import { useBeforeUnload } from '@/hooks/before-unload';
import { OFFLINE_TAB_ID } from '@/pages/settings/services/netops/netops-settings';
export function NetopsOfflineForm(_a) {
    var _this = this;
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var methods = useForm({ mode: 'onTouched', defaultValues: { file: null } });
    var _b = useUploadNetops(), data = _b.data, isLoading = _b.isLoading, upload = _b.upload, reset = _b.reset;
    useBeforeUnload(isLoading);
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!formData.file) return [3 /*break*/, 2];
                    return [4 /*yield*/, upload(formData.file)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [upload]);
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: OFFLINE_TAB_ID, methods: methods, onSubmit: onSubmit, permissions: permissions, mode: "create" }, { children: [_jsx(NetopsOfflineFileManager, { data: data, cancelUpload: reset }), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, alignItems: 'center', marginBottom: 2 } }, { children: [_jsx(InfoIcon, { fontSize: "medium" }), _jsx(Typography, { children: t('pages.netops_settings.tabs.offline.uploader.help_text') })] })), _jsx(FormActions, { children: _jsx(NetopsUploadButton, {}) })] })));
}
