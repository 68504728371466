var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { JobsTable } from '@/components/jobs/jobs-table';
import { JOBS_ROUTE, JOBS_ROUTE_CURRENT, JOBS_ROUTE_ENDED } from '@/constants/routes';
function Jobs() {
    var t = useTranslation().t;
    useDocTitle(t('pages.jobs.title'));
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var tabValue = useMemo(function () { return (pathname.endsWith(JOBS_ROUTE_ENDED) ? 'finished' : 'current'); }, [pathname]);
    useEffect(function () {
        if (pathname.endsWith(JOBS_ROUTE)) {
            navigate("".concat(JOBS_ROUTE).concat(JOBS_ROUTE_CURRENT), { replace: true });
        }
    }, [pathname, navigate]);
    var jobStateMap = useMemo(function () {
        return new Map([
            ['current', t('pages.jobs.tabs.current.title')],
            ['finished', t('pages.jobs.tabs.finished.title')],
        ]);
    }, [t]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.jobs.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsx(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "standard" }, { children: Array.from(jobStateMap.entries()).map(function (jobStateItem) {
                                var _a = __read(jobStateItem, 2), key = _a[0], jobStateTitle = _a[1];
                                return (_jsx(Tab, { component: RouterLink, to: key === 'current' ? JOBS_ROUTE_CURRENT.slice(1) : JOBS_ROUTE_ENDED.slice(1), label: jobStateTitle, value: key }, key));
                            }) })), Array.from(jobStateMap.keys()).map(function (key) { return (_jsx(TabPanel, __assign({ value: key }, { children: _jsx(JobsTable, { jobState: key }) }), key)); })] })) })] })));
}
export default withAuthenticationRequired(Jobs, { rights: ['monitor.jobs.view'] });
