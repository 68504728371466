var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { OGLink } from '@opengear/links';
import { OGTable } from '@opengear/table';
import PauseIcon from '@mui/icons-material/Pause';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import format from 'string-format';
import { useInterfaces } from '@/api/interfaces';
import { InterfaceModal } from './network-interface-edit-modal';
import { InterfacesDetailsCell } from './network-interfaces-table-components';
export var QUERY_PARAM_INTERFACE_ID_KEY = 'interface_id';
export function NetworkInterfacesTable() {
    // Get language
    var t = useTranslation().t;
    // Modal controls
    var _a = __read(useSearchParams(), 2), searchParams = _a[0], setSearchParams = _a[1];
    var modalInterfaceId = useMemo(function () { var _a; return (_a = searchParams.get(QUERY_PARAM_INTERFACE_ID_KEY)) !== null && _a !== void 0 ? _a : undefined; }, [searchParams]);
    var handleModalClose = useCallback(function () {
        searchParams.delete(QUERY_PARAM_INTERFACE_ID_KEY);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    var connectionTypeFormatMap = useMemo(function () {
        return new Map([
            ['dhcp', t('pages.network_interfaces.table.mode.dhcp')],
            ['ipv6_automatic', t('pages.network_interfaces.table.mode.ipv6_automatic')],
            ['ipv6_static', t('pages.network_interfaces.table.mode.ipv6_static')],
            ['static', t('pages.network_interfaces.table.mode.static')],
        ]);
    }, [t]);
    // Get Network Interfaces Fetch
    var _b = useInterfaces(), data = _b.data, isLoading = _b.isLoading;
    // Derived table values
    var _c = {
        loading: isLoading,
        rows: (data === null || data === void 0 ? void 0 : data.interfaces) || [],
    }, loading = _c.loading, rows = _c.rows;
    var renderStatusCell = useCallback(function (params) {
        var ifaceStatus = params.value ? t('pages.network_interfaces.table.status.enabled') : t('pages.network_interfaces.table.status.disabled');
        var ifaceRtStatus = params.row.runtime_status.status ? params.row.runtime_status.status : t('pages.network_interfaces.table.status.unknown');
        if (params.value && ifaceRtStatus === 'running') {
            return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: format(ifaceStatus, ifaceRtStatus) }, { children: _jsx(SuccessIcon, { fontSize: "medium", color: "success", sx: { height: 21 }, titleAccess: format(ifaceStatus, ifaceRtStatus) }) })));
        }
        if (params.value && ifaceRtStatus !== 'running') {
            return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: format(ifaceStatus, ifaceRtStatus) }, { children: _jsx(WarningIcon, { fontSize: "medium", color: "warning", sx: { height: 21 }, titleAccess: format(ifaceStatus, ifaceRtStatus) }) })));
        }
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: format(ifaceStatus, ifaceRtStatus) }, { children: _jsx(PauseIcon, { fontSize: "medium", sx: { height: 21 }, titleAccess: format(ifaceStatus, ifaceRtStatus) }) })));
    }, [t]);
    var renderNameCell = useCallback(function (params) {
        var _a;
        var value = params.value ? params.value : t('global.table.unknown_cell_value');
        return (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, target: value, to: "?".concat(new URLSearchParams((_a = {}, _a[QUERY_PARAM_INTERFACE_ID_KEY] = params.id.toString(), _a))), variant: "standard" }, { children: value })));
    }, [t]);
    var columns = useMemo(function () { return [
        {
            field: 'enabled',
            headerName: t('pages.network_interfaces.table.headers.status'),
            renderCell: renderStatusCell,
            maxWidth: 10,
            align: 'center',
            sortable: false,
            minWidth: 70,
        },
        {
            field: 'name',
            headerName: t('pages.network_interfaces.table.headers.name'),
            valueGetter: function (_a) {
                var _b;
                var row = _a.row;
                return (_b = row.name) !== null && _b !== void 0 ? _b : t('global.table.unknown_cell_value');
            },
            renderCell: renderNameCell,
            flex: 0.2,
            minWidth: 100,
        },
        {
            field: 'role',
            headerName: t('pages.network_interfaces.table.headers.type'),
            valueGetter: function (_a) {
                var row = _a.row;
                return row.role === 'wlan' ? t('pages.network_interfaces.table.type.wlan') : t('pages.network_interfaces.table.type.ethernet');
            },
            flex: 0.2,
            minWidth: 100,
        },
        {
            field: 'description',
            headerName: t('pages.network_interfaces.table.headers.description'),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'mode',
            headerName: t('pages.network_interfaces.table.headers.connection_type'),
            valueGetter: function (_a) {
                var row = _a.row;
                return connectionTypeFormatMap.get(row.mode);
            },
            flex: 0.2,
            minWidth: 160,
        },
        {
            field: 'details',
            headerName: t('pages.network_interfaces.table.headers.details'),
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return _jsx(InterfacesDetailsCell, { row: row, tabIndex: tabIndex });
            },
            flex: 0.4,
            minWidth: 150,
            sortable: false,
        },
    ]; }, [connectionTypeFormatMap, renderNameCell, renderStatusCell, t]);
    return (_jsxs(Box, { children: [modalInterfaceId && _jsx(InterfaceModal, { open: !!modalInterfaceId, handleClose: handleModalClose, interfaceId: modalInterfaceId }), _jsx(OGTable, { id: "network-interfaces-table", "aria-label": t('pages.network_interfaces.table.label'), columns: columns, rows: rows, autoHeight: true, loading: loading, noRowsMessage: t('pages.network_interfaces.table.no_rows_message'), pagination: false, hideFooter: true })] }));
}
