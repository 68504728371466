var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { OGDialog } from '@opengear/modals';
import format from 'string-format';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import { AssignTagModalForm, ASSIGN_TAG_MODAL_TITLE_ID } from './assign-tag-modal-form';
export function AssignTagModal(_a) {
    var selectedRows = _a.selectedRows, resourceTagNames = _a.resourceTagNames, uncheckColumnCheckboxes = _a.uncheckColumnCheckboxes, open = _a.open, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var _b = __read(useState(false), 2), confirm = _b[0], setConfirm = _b[1];
    // modal title has a dynamic ending
    var titleValue = function (rows) {
        if (rows && rows.length === 1)
            return rows[0].label.toUpperCase();
        // format function takes string only
        return format(t('components.assign_tag_modal.ports'), String(rows.length));
    };
    var onClose = useCallback(function () {
        setConfirm(false);
        handleClose();
    }, [handleClose]);
    return (_jsxs(OGDialog, __assign({ open: open, onClose: onClose }, { children: [_jsx(DialogTitle, __assign({ id: ASSIGN_TAG_MODAL_TITLE_ID }, { children: confirm
                    ? format(t('components.assign_tag_modal.confirm_modal.title'), titleValue(selectedRows))
                    : format(t('components.assign_tag_modal.title'), titleValue(selectedRows)) })), _jsx(Box, __assign({ px: 2, pb: 2 }, { children: _jsx(AssignTagModalForm, { onClose: handleClose, selectedRows: selectedRows, resourceTagNames: resourceTagNames, confirm: confirm, setConfirm: setConfirm, uncheckColumnCheckboxes: uncheckColumnCheckboxes }) }))] })));
}
