var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGJobsIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-6 -3.5 24 24' }, props), { children: _jsx("svg", __assign({ fill: "none" }, { children: _jsx("path", { d: "M9.79556 15.9375H2.40355C1.89246 15.9375 1.47954 15.4627 1.47954 14.875V2.125C1.47954 1.5373 1.89246 1.0625 2.40355 1.0625H6.09955V4.78125C6.09955 5.66113 6.72037 6.375 7.48556 6.375H10.7196V14.875C10.7196 15.4627 10.3066 15.9375 9.79556 15.9375ZM7.48556 5.3125C7.23146 5.3125 7.02356 5.07344 7.02356 4.78125V1.0791C7.10441 1.10234 7.17948 1.14883 7.23723 1.21855L10.5838 5.0668C10.6445 5.13652 10.6849 5.21953 10.7051 5.3125H7.48556ZM2.40355 0C1.38426 0 0.555542 0.95293 0.555542 2.125V14.875C0.555542 16.0471 1.38426 17 2.40355 17H9.79556C10.8148 17 11.6436 16.0471 11.6436 14.875V5.44199C11.6436 5.02031 11.4963 4.61523 11.2364 4.31641L7.89269 0.468164C7.63282 0.169336 7.28054 0 6.91383 0H2.40355ZM8.73585 9.76172C8.91487 9.55586 8.91487 9.21719 8.73585 9.01133C8.55682 8.80547 8.2623 8.80547 8.08327 9.01133L5.32859 12.1789L4.11584 10.7844C3.93681 10.5785 3.64229 10.5785 3.46326 10.7844C3.28424 10.9902 3.28424 11.3289 3.46326 11.5348L5.0023 13.3012C5.18133 13.507 5.47585 13.507 5.65488 13.3012L8.73585 9.76172Z", fill: "currentColor" }) })) })));
}
