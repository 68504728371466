import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useSNMPManager } from '@/api/services/snmp-manager';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useDocTitle } from '@/hooks/doc-title';
import { SNMPManagerForm } from './snmp-manager-form';
import { transformToResponseObject } from './transformers';
import { useCellhealthSettings } from '@/api/services/cellhealth';
export var SNMP_MANAGER_LOADER_ID = 'snmp-manager-form-loading';
export default function SNMPManagerTab() {
    var t = useTranslation().t;
    useDocTitle(t('pages.alerts_and_monitoring.tabs.snmp_manager.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var _a = useSNMPManager(), data = _a.data, isLoading = _a.isLoading;
    var _b = useCellhealthSettings(), cellHealthSettings = _b.data, cellHealthSettingsIsLoading = _b.isLoading;
    var hasEditPermission = hasPermission('settings.services.snmp_manager.edit');
    if (isLoading || cellHealthSettingsIsLoading) {
        return _jsx(LoadingBackdrop, { "data-testid": SNMP_MANAGER_LOADER_ID });
    }
    if (!data || !cellHealthSettings) {
        return null;
    }
    var sourceValue = transformToResponseObject(data.snmp_manager, cellHealthSettings.cell_health_enabled);
    return _jsx(SNMPManagerForm, { data: sourceValue, permissions: { hasEditPermission: hasEditPermission } });
}
