var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
export function UserGroupModal(_a) {
    var useOpen = _a.useOpen, children = _a.children, title = _a.title, titleId = _a.titleId;
    var open = useOpen.open, handleClose = useOpen.handleClose;
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ id: titleId }, { children: title })), children] })));
}
