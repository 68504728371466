import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { usePasswordPolicy } from '@/api/users/password-policy';
import { PasswordPolicyForm } from './password-policy-form';
export function PasswordPolicyTab(props) {
    var editable = props.editable;
    var data = usePasswordPolicy().data;
    if (!data) {
        return null;
    }
    return _jsx(PasswordPolicyForm, { data: data, editable: editable });
}
