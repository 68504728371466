var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGTable } from '@opengear/table';
import { OGButton } from '@opengear/buttons';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { useFormContainerContext } from '@/components/layout/forms';
export function ScriptTable(_a) {
    var rows = _a.rows;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), setValue = _b.setValue, permissions = _b.permissions;
    var onDeleteCallback = useCallback(function () {
        setValue('scriptChecksum', '');
        setValue('scriptSize', 0);
    }, [setValue]);
    var renderDeleteActionCell = useCallback(function (_a) {
        var tabIndex = _a.tabIndex;
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.config_templates.tabs.script.table.actions.delete') }, { children: _jsx(OGButton, { tabIndex: tabIndex, startIcon: _jsx(DeleteIcon, {}), variant: "borderless", onClick: onDeleteCallback, "aria-label": t('pages.config_templates.tabs.script.table.actions.delete'), propagateSpacebar: false }) })));
    }, [onDeleteCallback, t]);
    var columns = [
        {
            field: 'checksum',
            headerName: t('pages.config_templates.tabs.script.table.headers.checksum'),
            flex: 3,
            minWidth: 170,
            sortable: false,
        },
        {
            field: 'size',
            headerName: t('pages.config_templates.tabs.script.table.headers.size'),
            flex: 2,
            minWidth: 140,
            sortable: false,
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderDeleteActionCell,
        },
    ];
    return (_jsx(OGTable, { id: "config-templates-scripts-table", "aria-label": t('pages.config_templates.tabs.script.table.label'), autoHeight: true, columns: columns, rows: rows, columnVisibilityModel: {
            delete: permissions.hasEditPermission,
        } }));
}
