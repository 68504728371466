var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGLinkButton } from '@opengear/links';
import format from 'string-format';
import Tooltip from '@mui/material/Tooltip';
import { OGLogIcon } from '@opengear/icons/action/log';
import { NODE_LOGS_ROUTE } from '@/constants/routes';
import { QUERY_PARAM_LOGS_PORT_ID_KEY } from '@/constants/query-params-keys';
export function LogCell(_a) {
    var _b;
    var port = _a.port, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    if (port.mode !== 'consoleServer') {
        return null;
    }
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('global.ports.log_tooltip') }, { children: _jsx(OGLinkButton, { "aria-label": format(t('global.ports.log'), port.port_csid), disabled: port.runtime_status.connection_status !== 'connected', startIcon: _jsx(OGLogIcon, {}), variant: "borderless", sx: { paddingX: 0.5, paddingY: 0.5 }, tabIndex: tabIndex, to: "".concat(NODE_LOGS_ROUTE.slice(1), "?").concat(new URLSearchParams((_b = {}, _b[QUERY_PARAM_LOGS_PORT_ID_KEY] = port.id, _b))) }) })));
}
