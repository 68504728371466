var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import format from 'string-format';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useDocTitle } from '@/hooks/doc-title';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { EnrollmentBundleForm } from '@/components/config/enrollment-bundles/bundle-form';
import { useBundleById } from '@/api/bundles/bundles';
import { useEntitlementsData } from '@/api/system/entitlements';
import { useNodeTags } from '@/api/tags/node-tags';
import { useTemplates } from '@/api/template/templates';
import { useAlertNotification } from '@/hooks/alert-notification';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { useDefaultSubscription } from '@/api/system/default-subscription';
import { useUIPermissions } from '@/api/sessions/self/ui';
export var ADD_BUNDLE_LOADING_TEST_ID = 'add-bundle-loading';
function EditEnrollmentBundle() {
    var t = useTranslation().t;
    useDocTitle(t('pages.enrollment_bundles.edit.title'));
    var lighthouseSubscriptions = useEntitlementsData().lighthouseSubscriptions;
    var _a = useDefaultSubscription(), defaultSubscriptionData = _a.data, isLoadingDefaultSubscription = _a.isLoading;
    var _b = useTemplates(), templatesData = _b.data, isLoadingTemplates = _b.isLoading;
    var _c = useNodeTags(), nodeTagsData = _c.data, isLoadingNodeTags = _c.isLoading;
    var navigate = useNavigate();
    var alertNotification = useAlertNotification().alertNotification;
    var bundleId = useParams().bundleId;
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404) {
            alertNotification(format(t('api.bundles.get.not_found.message'), bundleId !== null && bundleId !== void 0 ? bundleId : ''), { persistOnUnmount: true });
        }
        if (responseStatus === 403) {
            alertNotification(format(t('api.bundles.get.forbidden.message'), bundleId !== null && bundleId !== void 0 ? bundleId : ''), { persistOnUnmount: true });
        }
        navigate(NOT_FOUND_ROUTE);
    }, [alertNotification, navigate, t, bundleId]);
    var _d = useBundleById(bundleId, { notFoundCallback: notFoundCallback }), bundle = _d.data, isLoadingBundle = _d.isLoading;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('configure.node_enrollment.enrollment_bundles.edit');
    var innerNode = null;
    if (isLoadingBundle || isLoadingNodeTags || isLoadingTemplates || isLoadingDefaultSubscription) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": ADD_BUNDLE_LOADING_TEST_ID });
    }
    if (bundle && nodeTagsData && templatesData && defaultSubscriptionData) {
        innerNode = (_jsx(EnrollmentBundleForm, { permissions: { hasEditPermission: hasEditPermission }, lighthouseSubscriptions: lighthouseSubscriptions, templates: templatesData, bundle: bundle, nodeTags: nodeTagsData.nodeTags, defaultSubscriptionData: defaultSubscriptionData }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 1.5 }, { children: format(t('pages.enrollment_bundles.edit.page_title'), bundleId !== null && bundleId !== void 0 ? bundleId : '') })), innerNode] })));
}
export default withAuthenticationRequired(EditEnrollmentBundle, { rights: ['configure.node_enrollment.enrollment_bundles.view'] });
