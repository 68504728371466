var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import format from 'string-format';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { OGSubscriptionIcon } from '@opengear/icons/action/subscription-assignment';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { FormActions, FormContainer, CancelButton, SubmitButton } from '@/components/layout/forms';
import { useBulkUpdateNode } from '@/api/nodes';
import { LoadingOverlay } from '@/components/layout/loading-overlay';
import { useEntitlementsData } from '@/api/system/entitlements';
import { SubscriptionsField, SelectedNodeStack } from './subscriptions-assignment-form-controls';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { formatMessageWithList, processBulkResult } from '@/utils/calculations';
import { BulkErrorMessage } from '@/components/nodes/bulk-error-message';
export var SUBSCRIPTION_ASSIGNMENT_MODAL_TITLE_ID = 'subscription_assignment_modal';
export var SUBSCRIPTIONS_ASSIGNMENT_LOADER_ID = 'subscriptions-assignment-loading';
function SubscriptionAssignmentFormContent(_a) {
    var _this = this;
    var onClose = _a.onClose, nodes = _a.nodes, nodeIds = _a.nodeIds, subscriptions = _a.subscriptions;
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var failureAlertNotification = useAlertNotification().alertNotification;
    var bulkUpdate = useBulkUpdateNode();
    var nodeSelection = useMemo(function () { return nodes.filter(function (node) { return nodeIds.includes(node.id); }); }, [nodeIds, nodes]);
    var subscriptionSelection = useMemo(function () { return __spreadArray([], __read(new Set(nodeSelection.map(function (node) { var _a; return (_a = node.subscription) === null || _a === void 0 ? void 0 : _a.tier; }))), false); }, [nodeSelection]);
    var sourceValues = {
        subscription: subscriptionSelection.length > 1 ? undefined : subscriptionSelection[0],
    };
    // Form state
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    var handleFormSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var subscriptionsSelection, assignmentResults, _a, successIds, errorDetails;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    subscriptionsSelection = nodeSelection.map(function (node) { return (__assign(__assign({}, node), { subscription: { tier: formData.subscription } })); });
                    return [4 /*yield*/, bulkUpdate(subscriptionsSelection)];
                case 1:
                    assignmentResults = _b.sent();
                    _a = processBulkResult(assignmentResults), successIds = _a.successIds, errorDetails = _a.errorDetails;
                    // Show success notification to user
                    if (successIds.length) {
                        alertNotification(format(t('api.nodes_bulk_subscription.put.success.message'), formatMessageWithList(successIds)), {
                            header: t('api.nodes_bulk_subscription.put.success.header'),
                            priority: OGToastPriorities.Success,
                            persistOnUnmount: true,
                        });
                    }
                    // Show notification to user that approve request for some nodes was failed
                    if (errorDetails.length) {
                        failureAlertNotification(_jsx(BulkErrorMessage, { message: format(t('api.nodes_bulk_subscription.put.failed.message'), formatMessageWithList(errorDetails.map(function (errorItem) { return errorItem.id; }))), errorDetails: errorDetails }), {
                            header: t('api.nodes_bulk_subscription.put.failed.header'),
                            persistOnUnmount: true,
                        });
                    }
                    // Close modal
                    onClose({ clearSelection: true });
                    return [2 /*return*/];
            }
        });
    }); }, [nodeSelection, bulkUpdate, alertNotification, failureAlertNotification, t, onClose]);
    var column = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '340px',
        overflowY: 'auto',
    };
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: SUBSCRIPTION_ASSIGNMENT_MODAL_TITLE_ID, mode: "update", methods: methods, onSubmit: handleFormSubmit }, { children: [_jsxs(DialogContent, __assign({ sx: { py: 2, display: 'flex', flexDirection: 'row', minHeight: '240px', gap: 1 } }, { children: [_jsx(Box, __assign({ sx: column, alignItems: "center", justifyContent: "center" }, { children: _jsx(SelectedNodeStack, { selectedNodes: nodeSelection }) })), _jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' }, alignItems: "center", justifyContent: "center" }, { children: _jsx(OGSubscriptionIcon, {}) })), _jsx(Box, __assign({ sx: column, alignItems: "center", justifyContent: "center" }, { children: _jsx(SubscriptionsField, { nodeIds: nodeIds, subscriptions: subscriptions }) }))] })), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { pt: 0, pb: 2 } }, { children: _jsxs(FormActions, { children: [_jsx(CancelButton, { autoFocus: subscriptions.length < 2, onCancel: function () { return onClose({ clearSelection: false }); } }), _jsx(SubmitButton, { children: t('pages.subscriptions.tabs.subscription_assignment.modal.actions.confirm') })] }) }))] })));
}
export function SubscriptionAssignmentForm(_a) {
    var nodes = _a.nodes, nodeIds = _a.nodeIds, onClose = _a.onClose;
    var t = useTranslation().t;
    var _b = useEntitlementsData(), isEntitlementsLoading = _b.isLoading, subscriptions = _b.lighthouseSubscriptions;
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, __assign({ id: SUBSCRIPTION_ASSIGNMENT_MODAL_TITLE_ID }, { children: format(t('pages.subscriptions.tabs.subscription_assignment.modal.title'), String(nodeIds.length)) })), isEntitlementsLoading ? (_jsx(LoadingOverlay, { "data-testid": SUBSCRIPTIONS_ASSIGNMENT_LOADER_ID })) : (_jsx(SubscriptionAssignmentFormContent, { onClose: onClose, nodes: nodes, subscriptions: subscriptions, nodeIds: nodeIds }))] }));
}
