import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { FilterReadonlyQuery } from '../../filter-query';
import { InvalidQueryContent } from '../filter-components';
import { ResourceFilterQuery } from './resource-filter-query';
import { useResourceFilterContext } from './resource-filter-context';
import { isResourceFilterNew, isResourceQueryValid } from './resource-filter-utils';
export function ResourceFilterQueryContent() {
    var _a = useResourceFilterContext(), selectedFilter = _a.selectedFilter, dispatchSelectedFilter = _a.dispatchSelectedFilter, setError = _a.setError;
    var handleNewQueryChange = useCallback(function (query) {
        dispatchSelectedFilter({ type: 'updateQuery', payload: query });
    }, [dispatchSelectedFilter]);
    if (!selectedFilter)
        return null;
    if (isResourceFilterNew(selectedFilter)) {
        return _jsx(ResourceFilterQuery, { query: selectedFilter.queryObject, onChange: handleNewQueryChange, setError: setError });
    }
    if (isResourceQueryValid(selectedFilter)) {
        return _jsx(FilterReadonlyQuery, { query: selectedFilter.queryObject });
    }
    return _jsx(InvalidQueryContent, { query: selectedFilter.query });
}
