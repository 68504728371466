var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGProfileIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 24 24' }, props), { children: [_jsx("path", { fill: "none", d: "M7.80005 10.2C7.80005 11.3139 8.24255 12.3822 9.0302 13.1698C9.81785 13.9575 10.8861 14.4 12 14.4C13.114 14.4 14.1822 13.9575 14.9699 13.1698C15.7575 12.3822 16.2 11.3139 16.2 10.2C16.2 9.08609 15.7575 8.0178 14.9699 7.23015C14.1822 6.4425 13.114 6 12 6C10.8861 6 9.81785 6.4425 9.0302 7.23015C8.24255 8.0178 7.80005 9.08609 7.80005 10.2V10.2Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M17.5305 19.1008C16.8059 18.3717 15.9442 17.793 14.9951 17.3981C14.046 17.0032 13.0281 16.7999 12.0001 16.7999C10.9721 16.7999 9.95428 17.0032 9.00515 17.3981C8.05602 17.793 7.19435 18.3717 6.46973 19.1008", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12V12Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
