var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGFileTracker, OGFileUploader } from '@opengear/file-uploader';
import { useFormContainerContext } from '@/components/layout/forms';
export function FileField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "file", rules: {
            required: t('pages.subscriptions.tabs.subscriptions.form.fields.file.errors.required'),
        }, render: function (_a) {
            var field = _a.field;
            return (_jsxs(Box, __assign({ marginBottom: 1.5 }, { children: [_jsx(InputLabel, { children: t('pages.subscriptions.tabs.subscriptions.form.fields.file.label') }), _jsx(OGIconTooltip, { title: t('pages.subscriptions.tabs.subscriptions.form.fields.file.tooltip') }), _jsx(Box, __assign({ sx: { marginTop: 1 } }, { children: _jsx(OGFileUploader
                        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        , __assign({ 
                            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, id: "subscription-file", accept: ".zip", ariaLabel: t('pages.subscriptions.tabs.subscriptions.form.fields.file.label'), onChange: function (files) {
                                var _a;
                                field.onChange((_a = files[0]) !== null && _a !== void 0 ? _a : null);
                            }, disabled: !permissions.hasUpdateLicenseAccess, required: true }, { children: field.value && _jsx(OGFileTracker, { id: field.value.name, name: field.value.name, onCancel: function () { return field.onChange(null); } }, field.value.name) })) }))] })));
        } }));
}
