var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { OGButton } from '@opengear/buttons';
import Stack from '@mui/material/Stack';
import { SessionStatus } from '@/api/utils';
import { useUpdateSession } from '@/api/sessions';
export function ChallengeForm(_a) {
    var _this = this;
    var challenge = _a.challenge, onCancel = _a.onCancel, alertCloseRef = _a.alertCloseRef;
    var signIn = useUpdateSession(alertCloseRef);
    var t = useTranslation().t;
    var _b = useForm({ defaultValues: { response: '' }, mode: 'onTouched' }), control = _b.control, createSubmitHandler = _b.handleSubmit, _c = _b.formState, isValid = _c.isValid, isSubmitting = _c.isSubmitting;
    var challengeLabel = challenge.challenge, token = challenge.token;
    var handleSubmit = useMemo(function () {
        return createSubmitHandler(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var stat;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, signIn({ request: data, challengeToken: token })];
                    case 1:
                        stat = _a.sent();
                        if (stat !== SessionStatus.Valid) {
                            onCancel();
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    }, [createSubmitHandler, onCancel, token, signIn]);
    return (_jsxs(Stack, __assign({ component: "form", "aria-label": t('pages.login.challenge.label'), onSubmit: handleSubmit }, { children: [_jsx(Controller, { control: control, name: "response", rules: { required: true }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return _jsx(TextField, __assign({ fullWidth: true, id: "response", error: !!error, label: challengeLabel, type: "text" }, field));
                } }), _jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end" }, { children: [_jsx(OGButton, __assign({ type: "button", onClick: onCancel }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ type: "submit", disabled: !isValid, loading: isSubmitting }, { children: t('pages.login.buttons.sign_in') }))] }))] })));
}
