export function transformSystemUpgradeForm(formState) {
    var request = {};
    if (formState.method === 'url') {
        request.firmware_url = formState.imageUrl;
    }
    else if (formState.imageFile) {
        request.file = formState.imageFile;
    }
    if (formState.options)
        request.firmware_options = formState.options;
    return request;
}
