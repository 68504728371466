import i18n from 'i18next';
import format from 'string-format';
export function requiredFieldError(label) {
    return format(i18n.t('global.form.field.validation_errors.required'), label);
}
export function minLengthFieldError(label, minLength) {
    return format(i18n.t('global.form.field.validation_errors.min_length'), label, minLength);
}
export function maxLengthFieldError(label, maxLength) {
    return format(i18n.t('global.form.field.validation_errors.max_length'), label, maxLength);
}
export function minValueFieldError(label, minValue) {
    return format(i18n.t('global.form.field.validation_errors.min_value'), label, minValue);
}
export function maxValueFieldError(label, maxValue) {
    return format(i18n.t('global.form.field.validation_errors.max_value'), label, maxValue);
}
export function invalidFieldError(label) {
    return format(i18n.t('global.form.field.validation_errors.invalid'), label);
}
