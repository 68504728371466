var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGPortsIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-4 -6 24 24' }, props), { children: _jsx("svg", __assign({ fill: "none", transform: "rotate(180)" }, { children: _jsx("path", { d: "M4.3492 1C4.3492 0.446875 4.74997 0 5.24603 0H10.627C11.123 0 11.5238 0.446875 11.5238 1V2H12.4206C12.9167 2 13.3175 2.44688 13.3175 3V4H14.2143C14.7103 4 15.1111 4.44688 15.1111 5V11C15.1111 11.5531 14.7103 12 14.2143 12H11.9722H9.28174H6.59127H3.90079H1.65873C1.16267 12 0.761902 11.5531 0.761902 11V5C0.761902 4.44688 1.16267 4 1.65873 4H2.55555V3C2.55555 2.44688 2.95632 2 3.45238 2H4.3492V1ZM10.627 1H5.24603V2.5C5.24603 2.775 5.04424 3 4.79762 3H3.45238V4.5C3.45238 4.775 3.25059 5 3.00397 5H1.65873V11H3.45238V8.5C3.45238 8.225 3.65416 8 3.90079 8C4.14742 8 4.3492 8.225 4.3492 8.5V11H6.14285V8.5C6.14285 8.225 6.34464 8 6.59127 8C6.83789 8 7.03968 8.225 7.03968 8.5V11H8.83333V8.5C8.83333 8.225 9.03512 8 9.28174 8C9.52837 8 9.73016 8.225 9.73016 8.5V11H11.5238V8.5C11.5238 8.225 11.7256 8 11.9722 8C12.2188 8 12.4206 8.225 12.4206 8.5V11H14.2143V5H12.869C12.6224 5 12.4206 4.775 12.4206 4.5V3H11.0754C10.8288 3 10.627 2.775 10.627 2.5V1Z", fill: "currentColor" }) })) })));
}
