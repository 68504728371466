var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { RadiusSettings, LdapSettings, TacacsSettings } from '@/containers/config/templates/auth-template';
var scrollableStackStyles = {
    overflow: 'auto',
    maxWidth: '100%',
};
export function RadiusModeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(Stack, __assign({ direction: "column", gap: 1, marginTop: 1, marginBottom: 1 }, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.remote_auth.form.mode') })), _jsx(Stack, __assign({ direction: "column", gap: 1, style: scrollableStackStyles }, { children: _jsx(Controller, { control: control, name: "authStyle", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ "aria-label": "Radius auth mode", disabled: !permissions.hasAuthEditAccess, exclusive: true, orientation: isMobileView ? 'vertical' : 'horizontal', onChange: function (event, v) {
                                if (!['auth_down_local', 'auth', 'local_auth', 'auth_local'].includes(v))
                                    return;
                                onChange(v);
                            } }, field, { sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "auth_down_local" }, { children: t('pages.remote_auth.form.scheme.radius.auth_style.auth_down_local') })), _jsx(ToggleButton, __assign({ value: "auth" }, { children: t('pages.remote_auth.form.scheme.radius.auth_style.auth') })), _jsx(ToggleButton, __assign({ value: "local_auth" }, { children: t('pages.remote_auth.form.scheme.radius.auth_style.local_auth') })), _jsx(ToggleButton, __assign({ value: "auth_local" }, { children: t('pages.remote_auth.form.scheme.radius.auth_style.auth_local') }))] })));
                    } }) }))] })));
}
export function LdapModeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(Stack, __assign({ direction: "column", gap: 1, marginTop: 1, marginBottom: 1 }, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.remote_auth.form.mode') })), _jsx(Stack, __assign({ direction: "column", gap: 1, style: scrollableStackStyles }, { children: _jsx(Controller, { control: control, name: "authStyle", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ "aria-label": "LDAP auth mode", disabled: !permissions.hasAuthEditAccess, exclusive: true, onChange: function (event, v) {
                                if (!['auth_down_local', 'auth', 'local_auth', 'auth_local'].includes(v))
                                    return;
                                onChange(v);
                            } }, field, { sx: { marginBottom: 1 }, orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: "auth_down_local" }, { children: t('pages.remote_auth.form.scheme.ldap.auth_style.auth_down_local') })), _jsx(ToggleButton, __assign({ value: "auth" }, { children: t('pages.remote_auth.form.scheme.ldap.auth_style.auth') })), _jsx(ToggleButton, __assign({ value: "local_auth" }, { children: t('pages.remote_auth.form.scheme.ldap.auth_style.local_auth') })), _jsx(ToggleButton, __assign({ value: "auth_local" }, { children: t('pages.remote_auth.form.scheme.ldap.auth_style.auth_local') }))] })));
                    } }) }))] })));
}
export function TacacsModeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(Stack, __assign({ direction: "column", gap: 1, marginTop: 1, marginBottom: 1 }, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.remote_auth.form.mode') })), _jsx(Stack, __assign({ direction: "column", gap: 1, style: scrollableStackStyles }, { children: _jsx(Controller, { control: control, name: "authStyle", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ "aria-label": "TACACS auth mode", disabled: !permissions.hasAuthEditAccess, exclusive: true, onChange: function (event, v) {
                                if (!['auth_down_local', 'auth', 'local_auth', 'auth_local'].includes(v))
                                    return;
                                onChange(v);
                            } }, field, { sx: { marginBottom: 1 }, orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: "auth_down_local" }, { children: t('pages.remote_auth.form.scheme.tacacs.auth_style.auth_down_local') })), _jsx(ToggleButton, __assign({ value: "auth" }, { children: t('pages.remote_auth.form.scheme.tacacs.auth_style.auth') })), _jsx(ToggleButton, __assign({ value: "local_auth" }, { children: t('pages.remote_auth.form.scheme.tacacs.auth_style.local_auth') })), _jsx(ToggleButton, __assign({ value: "auth_local" }, { children: t('pages.remote_auth.form.scheme.tacacs.auth_style.auth_local') }))] })));
                    } }) }))] })));
}
export function AuthModeTabs() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch, permissions = _a.permissions, formState = _a.formState, resetField = _a.resetField;
    var selectedAuthMode = watch('mode');
    var isMobileView = useDeviceView().isMobileView;
    // Reset hidden fields so they can't cause validation errors or cause the form to be dirty unexpectedly
    var resetHidden = useCallback(function () {
        if (formState.defaultValues) {
            Object.keys(formState.defaultValues).forEach(function (key) {
                if (key !== 'mode') {
                    resetField(key);
                }
            });
        }
    }, [formState.defaultValues, resetField]);
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, __assign({ direction: "column", gap: 1 }, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.label') })), _jsx(Stack, __assign({ direction: "column", gap: 1 }, { children: _jsx(Controller, { control: control, name: "mode", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                                return (_jsxs(ToggleButtonGroup, __assign({ "aria-label": "Auth mode", disabled: !permissions.hasAuthEditAccess, exclusive: true, onChange: function (event, v) {
                                        if (!['ldap', 'radius', 'tacacs', 'local'].includes(v))
                                            return;
                                        resetHidden();
                                        onChange(v);
                                    } }, field, { sx: { marginBottom: 2 }, orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: "local" }, { children: t('pages.remote_auth.form.scheme.local.title') })), _jsx(ToggleButton, __assign({ value: "radius" }, { children: t('pages.remote_auth.form.scheme.radius.title') })), _jsx(ToggleButton, __assign({ value: "tacacs" }, { children: t('pages.remote_auth.form.scheme.tacacs.title') })), _jsx(ToggleButton, __assign({ value: "ldap" }, { children: t('pages.remote_auth.form.scheme.ldap.title') }))] })));
                            } }) }))] })), selectedAuthMode === 'radius' && (_jsxs(_Fragment, { children: [_jsx(RadiusModeButtons, {}), " ", _jsx(RadiusSettings, {})] })), selectedAuthMode === 'tacacs' && (_jsxs(_Fragment, { children: [_jsx(TacacsModeButtons, {}), " ", _jsx(TacacsSettings, {})] })), selectedAuthMode === 'ldap' && (_jsxs(_Fragment, { children: [_jsx(LdapModeButtons, {}), " ", _jsx(LdapSettings, {})] }))] }));
}
