var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import { OGButton } from '@opengear/buttons';
import { useFormContainerContext } from '@/components/layout/forms';
export var SelectedFirmwareDetails = forwardRef(function (_, ref) {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), setValue = _a.setValue, watch = _a.watch;
    var navigate = useNavigate();
    var location = useLocation();
    var selectedFirmware = watch('firmware');
    var handleDelete = useCallback(function () {
        var _a;
        setValue('firmware', undefined, { shouldValidate: true });
        setValue('nodes', [], { shouldValidate: true });
        // clear copy-repeat data (nodes and firmware when user click)
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.copyRepeatData) {
            navigate(location.pathname, { replace: true });
        }
    }, [location.pathname, location.state, navigate, setValue]);
    if (!selectedFirmware)
        return null;
    return (_jsx(Stack, { children: _jsxs(Stack, __assign({ direction: "row", spacing: 0.5, alignItems: "center" }, { children: [_jsx(Typography, __assign({ component: "span" }, { children: selectedFirmware.filename })), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.firmware_upgrade_new.buttons.clear_firmware.tooltip') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('pages.firmware_upgrade_new.buttons.clear_firmware.label'), startIcon: _jsx(ClearIcon, {}), sx: { marginBottom: 0.2, minHeight: '18px', minWidth: '18px', paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }, variant: "borderless", ref: ref, onClick: handleDelete }) }) }))] })) }));
});
