var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import format from 'string-format';
import { PermissionsTable } from '../permissions/permissions-table';
import { useGroupsPermissions } from '@/api/permissions/groups';
import { DisabledTextField } from '../../inputs/disabled-text-field';
import { LoadingOverlay } from '../../layout/loading-overlay';
import { LoadingErrorOverlay } from '../../layout/loading-error-overlay';
import { useFormatPermission, convertPermissionsData } from '../utils';
import { useDeviceView } from '@/hooks/use-device-view';
export var GROUP_MODAL_TEST_ID = 'group-modal-loader';
var CONTENTHEIGHT = '61vh';
export default function GroupModal(_a) {
    var groupName = _a.groupName, open = _a.open, onClose = _a.onClose;
    var t = useTranslation().t;
    var formatPermission = useFormatPermission();
    var isMobileView = useDeviceView().isMobileView;
    var _b = useGroupsPermissions(groupName), groupPermissions = _b.data, isLoadingPermissions = _b.isLoading;
    var innerNode = null;
    if (isLoadingPermissions && !groupPermissions) {
        innerNode = _jsx(LoadingOverlay, { "data-testid": GROUP_MODAL_TEST_ID });
    }
    if (!isLoadingPermissions && groupPermissions === undefined) {
        innerNode = _jsx(LoadingErrorOverlay, {});
    }
    if (groupPermissions) {
        var permissions = convertPermissionsData(groupPermissions);
        innerNode = (_jsxs(Stack, __assign({ "aria-label": t('components.role.modal.label'), component: "form" }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('components.permissions.form.title') })), _jsxs(Stack, __assign({ gap: isMobileView ? 0 : 4, direction: isMobileView ? 'column' : 'row' }, { children: [_jsx(DisabledTextField, { id: "group-console-shell", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.console_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.console_shell.tooltip') })] }), value: formatPermission(permissions.cli.console_shell) }), permissions.cli.shell_access && (_jsx(DisabledTextField, { id: "group-shell-access", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.shell_access.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.shell_access.tooltip') })] }), value: formatPermission(permissions.cli.shell_access) })), _jsx(DisabledTextField, { id: "group-pm-shell", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.pm_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.pm_shell.tooltip') })] }), value: formatPermission(permissions.cli.pm_shell) })] })), _jsx(Divider, { sx: { marginBottom: 2 } }), _jsx(PermissionsTable, { permissions: permissions })] })));
    }
    return (_jsxs(OGDialog, __assign({ open: open, onClose: onClose, fullWidth: true, sx: {
            '& .MuiDialog-paper': {
                maxWidth: '740px',
            },
        } }, { children: [_jsx(DialogTitle, { children: format(t('components.role.modal.title'), groupName) }), _jsx(DialogContent, __assign({ sx: { paddingY: 0, height: CONTENTHEIGHT } }, { children: innerNode })), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsx(OGButton
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                , __assign({ 
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, onClick: onClose }, { children: t('global.buttons.close') })) })] })));
}
