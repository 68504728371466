var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { OGToast, OGToastPriorities } from '@opengear/toast';
import { useOptionalCloseRef } from './close-ref';
export { OGToastPriorities } from '@opengear/toast';
/**
 * Alert notification accepts an optional 'CloseRef' and returns the same 'CloseRef' if provided, or a new 'CloseRef' if not provided.
 * The returned 'CloseRef' can be passed down the React render tree to other usages of the 'useAlertNotification'.
 * Usages of 'useAlertNotification' that share a 'CloseRef' will only ever have one alert (snackbar/toast) notification rendered at any one time.
 * Newer alerts will clobber old alerts, i.e. the old alert will be close automatically before the new alert is opened.
 */
export function useAlertNotification(optionalCloseRef) {
    var closeRef = useOptionalCloseRef(optionalCloseRef);
    var persistOnUnmountRef = useRef(false);
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    var alertNotification = useCallback(function (message, options, closeCallback) {
        var _a = __assign({ priority: OGToastPriorities.Error, header: undefined }, options), priority = _a.priority, header = _a.header, persist = _a.persist, _b = _a.preventDuplicate, preventDuplicate = _b === void 0 ? false : _b, _c = _a.persistOnUnmount, persistOnUnmount = _c === void 0 ? false : _c;
        closeRef.current();
        var snackBarKey = enqueueSnackbar(message, {
            persist: persist !== null && persist !== void 0 ? persist : priority === OGToastPriorities.Error,
            autoHideDuration: priority === OGToastPriorities.Warning ? 7000 : 5000,
            preventDuplicate: preventDuplicate,
            content: function (snackbarKey) { return (_jsx(OGToast, __assign({ priority: priority, header: header, closeHandler: function () {
                    if (closeCallback)
                        closeCallback();
                    closeSnackbar(snackbarKey);
                } }, { children: message }))); },
        });
        closeRef.current = function () {
            closeSnackbar(snackBarKey);
        };
        // Each instance of alertNotification updates the persistOnUnmountRef
        persistOnUnmountRef.current = persistOnUnmount;
    }, [closeRef, persistOnUnmountRef, enqueueSnackbar, closeSnackbar]);
    /*
      When component unmounts, check if the 'latest' alert instance needs to persist.
      Default behaviour is that when hook unmounts, alerts are closed
     */
    useEffect(function () { return function () {
        if (!persistOnUnmountRef.current) {
            closeRef.current();
        }
    }; }, [closeRef, persistOnUnmountRef]);
    return {
        alertNotification: alertNotification,
        closeRef: closeRef,
    };
}
