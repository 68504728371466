var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OGButtonLink } from '@opengear/buttons';
import { OGLink } from '@opengear/links';
import { OGDialog } from '@opengear/modals';
import { OGTable } from '@opengear/table';
import Box from '@mui/material/Box';
import { useResourceTags } from '@/api/tags/resource-tags';
import { PORTS_PATH } from '@/api/ports/ports';
import { formatSearchParams } from '@/api/utils';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { FreeTextSearch } from '@/components/free-text-search';
import { EditResourceTagModalContent, EDIT_RESOURCE_TAG_MODAL_TITLE_ID } from '../resource-tags-modal';
import { QUERY_PARAM_PORT_FILTER_KEY, QUERY_PARAM_RESOURCE_FILTER_KEY } from '@/constants/query-params-keys';
import { useCreateResourceTagPortFilter } from '@/containers/filters/advanced-filters/port-filter';
import { useCreateResourceTagResourceFilter } from '@/containers/filters/advanced-filters/resource-filter';
import { SMF_RESOURCES_ROUTE } from '@/constants/routes';
function CreateResourceLink(_a) {
    var _b;
    var row = _a.row, tabIndex = _a.tabIndex, itemCount = _a.itemCount, editPermission = _a.editPermission;
    var t = useTranslation().t;
    var createResourceTagResourceFilter = useCreateResourceTagResourceFilter();
    var linkText = itemCount > 0 ? itemCount : t('pages.tags.tabs.resource_tags.table.no_assigned_resources');
    return (_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: "".concat(SMF_RESOURCES_ROUTE).concat(itemCount > 0 ? formatSearchParams((_b = {}, _b[QUERY_PARAM_RESOURCE_FILTER_KEY] = createResourceTagResourceFilter(row.resource_tag_name), _b)) : '') }, { children: editPermission ? linkText.toString() : itemCount.toString() })));
}
function CreatePortLink(_a) {
    var _b;
    var row = _a.row, tabIndex = _a.tabIndex, itemCount = _a.itemCount, editPermission = _a.editPermission;
    var t = useTranslation().t;
    var createResourceTagPortFilter = useCreateResourceTagPortFilter();
    var linkText = itemCount > 0 ? itemCount : t('pages.tags.tabs.resource_tags.table.no_assigned_ports');
    return (_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: "".concat(PORTS_PATH).concat(itemCount > 0 ? formatSearchParams((_b = {}, _b[QUERY_PARAM_PORT_FILTER_KEY] = createResourceTagPortFilter(row.resource_tag_name), _b)) : '') }, { children: editPermission ? linkText.toString() : itemCount.toString() })));
}
export function ResourceTagsTable(_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var hasCRGEditAccess = permissions.hasCRGEditAccess, hasCRGViewAccess = permissions.hasCRGViewAccess, hasTagEditAccess = permissions.hasTagEditAccess, hasNodeEnrollmentAccess = permissions.hasNodeEnrollmentAccess, hasNodeEnrollViewAccess = permissions.hasNodeEnrollViewAccess;
    // Free text search
    var _b = __read(useState({}), 2), searchText = _b[0], setSearchText = _b[1];
    var handleSearch = useCallback(function (value) {
        setSearchText({ name_filter: value });
    }, []);
    var _c = __read(useState(undefined), 2), selectedResourceTag = _c[0], setSelectedResourceTag = _c[1];
    var editResourceTagModalOpen = !!selectedResourceTag;
    var handleEditResourceTagModalOpen = useCallback(function (resourceTag) {
        setSelectedResourceTag(resourceTag);
    }, []);
    var handleEditResourceTagModalClose = useCallback(function () {
        setSelectedResourceTag(undefined);
    }, []);
    var _d = __read(usePaginationModel('resource_tags'), 3), paginationModel = _d[0], handlePaginationModelChange = _d[1], pageSizeOptions = _d[2];
    var _e = __read(useSortModel('name'), 3), defaultSortModel = _e[0], sortQueryParams = _e[1], handleSortModelChange = _e[2];
    var queryParams = useMemo(function () { return (__assign(__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize }, searchText), sortQueryParams)); }, [paginationModel, sortQueryParams, searchText]);
    var _f = useResourceTags(queryParams), data = _f.data, isLoading = _f.isLoading;
    var _g = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.resourceTags) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.resourceTags, isLoading]), loading = _g.loading, rowCount = _g.rowCount, rows = _g.rows;
    var renderNameCell = useCallback(function (params) {
        if (hasTagEditAccess) {
            return (_jsx(OGButtonLink, __assign({ tabIndex: params.tabIndex, variant: "standard", onClick: function () {
                    handleEditResourceTagModalOpen(params.row);
                }, propagateSpacebar: false }, { children: params.value })));
        }
        return params.value;
    }, [handleEditResourceTagModalOpen, hasTagEditAccess]);
    var renderAssignedPortsCell = useCallback(function (params) {
        var value = params.value, tabIndex = params.tabIndex, row = params.row;
        var portCount = value !== null && value !== void 0 ? value : 0;
        if (hasNodeEnrollViewAccess) {
            return _jsx(CreatePortLink, { row: row, tabIndex: tabIndex, itemCount: portCount, editPermission: hasNodeEnrollmentAccess });
        }
        return portCount;
    }, [hasNodeEnrollViewAccess, hasNodeEnrollmentAccess]);
    var renderAssignedResourcesCell = useCallback(function (params) {
        var value = params.value, tabIndex = params.tabIndex, row = params.row;
        var resourceCount = value !== null && value !== void 0 ? value : 0;
        if (hasCRGViewAccess) {
            return _jsx(CreateResourceLink, { row: row, tabIndex: tabIndex, itemCount: resourceCount, editPermission: hasCRGEditAccess });
        }
        return resourceCount;
    }, [hasCRGViewAccess, hasCRGEditAccess]);
    var columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('pages.tags.tabs.resource_tags.table.name'),
            sortable: true,
            minWidth: 85,
            valueGetter: function (_a) {
                var row = _a.row;
                return row.resource_tag_name;
            },
            renderCell: renderNameCell,
        },
        {
            field: 'assigned_resources',
            flex: 1,
            headerName: t('pages.tags.tabs.resource_tags.table.assigned_resources'),
            sortable: false,
            minWidth: 120,
            valueGetter: function (_a) {
                var row = _a.row;
                return row.smf_resource_count;
            },
            renderCell: renderAssignedResourcesCell,
        },
        {
            field: 'assigned_ports',
            flex: 1,
            headerName: t('pages.tags.tabs.resource_tags.table.assigned_ports'),
            sortable: false,
            minWidth: 120,
            valueGetter: function (_a) {
                var row = _a.row;
                return row.port_count;
            },
            renderCell: renderAssignedPortsCell,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(OGDialog, __assign({ "aria-labelledby": EDIT_RESOURCE_TAG_MODAL_TITLE_ID, open: editResourceTagModalOpen, onClose: handleEditResourceTagModalClose }, { children: selectedResourceTag && (_jsx(EditResourceTagModalContent, { resourceTag: selectedResourceTag, onClose: handleEditResourceTagModalClose, permissions: permissions })) })), _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginBottom: 1.5 }, { children: [_jsx("div", {}), _jsx(FreeTextSearch, { onSearch: handleSearch, translations: {
                            placeholder: t('pages.tags.tabs.resource_tags.search.placeholder'),
                            tooltip: t('pages.tags.tabs.resource_tags.search.tooltip'),
                        } })] })), _jsx(Box, { children: _jsx(OGTable, { "aria-label": t('pages.tags.tabs.resource_tags.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('pages.tags.tabs.resource_tags.table.no_row_message'), loading: loading, pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, rowCount: rowCount, onPaginationModelChange: handlePaginationModelChange, onSortModelChange: handleSortModelChange, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    } }) })] }));
}
