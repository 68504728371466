var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import landingBackgroundImg from '../../assets/landing-background.png';
export function LandingBackground(props) {
    return (_jsx(Box, __assign({ sx: {
            backgroundImage: "url(".concat(landingBackgroundImg, ")"),
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '50%',
            backgroundPositionY: 0,
            backgroundSize: '100% 75%',
        } }, props)));
}
