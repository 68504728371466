var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { OGTable } from '@opengear/table';
import { EnrolledStateCell } from '@/components/nodes/enrolled-state-cell';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useFormContainerContext } from '@/components/layout/forms';
import { UpgradeInFailoverCell } from './upgrade-in-failover-cell';
var defaultSortModel = [{ field: 'id', sort: 'asc' }];
export var UpgradeInFailoverNodesTable = memo(function () {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), setValue = _a.setValue, watch = _a.watch;
    var selectedNodes = watch('nodes');
    var _b = __read(usePaginationModel('firmware_upgrade_upgrade_in_failover_nodes'), 3), paginationModel = _b[0], handlePaginationModelChange = _b[1], pageSizeOptions = _b[2];
    var handleUpgradeInFailoverChange = useCallback(function (id, checked) {
        var updatedSelectedNodes = selectedNodes.map(function (selectedNode) {
            if (selectedNode.id === id) {
                // eslint-disable-next-line no-param-reassign
                selectedNode.allowInFailover = checked;
            }
            return selectedNode;
        });
        setValue('nodes', updatedSelectedNodes);
    }, [selectedNodes, setValue]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'connection_status',
            headerName: t('pages.firmware_upgrade_new.tables.selected_nodes.headers.state'),
            maxWidth: 1,
            minWidth: 85,
            valueGetter: function (_a) {
                var row = _a.row;
                return row.runtime_status.connection_status;
            },
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(EnrolledStateCell, { runtime_status: row.runtime_status });
            },
        },
        { field: 'id', headerName: t('pages.firmware_upgrade_new.tables.selected_nodes.headers.id'), minWidth: 140 },
        { field: 'name', headerName: t('pages.firmware_upgrade_new.tables.selected_nodes.headers.name'), flex: 1, minWidth: 160 },
        {
            field: 'model',
            headerName: t('pages.firmware_upgrade_new.tables.selected_nodes.headers.model'),
            valueGetter: function (_a) {
                var value = _a.value;
                return value !== null && value !== void 0 ? value : 'N/A';
            },
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'firmware_version',
            headerName: t('pages.firmware_upgrade_new.tables.selected_nodes.headers.firmware_version'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'upgrade_in_failover',
            headerName: t('pages.firmware_upgrade_new.tables.selected_nodes.headers.upgrade_in_failover'),
            sortable: false,
            minWidth: 180,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(UpgradeInFailoverCell, { nodeId: row.id, tabIndex: tabIndex, checked: row.allowInFailover, onChange: handleUpgradeInFailoverChange }));
            },
        },
    ]; }, [handleUpgradeInFailoverChange, t]);
    return (_jsx(Box, __assign({ marginBottom: 1 }, { children: _jsx(OGTable, { "aria-describedby": "firmware-upgrade-review-nodes-table-description", "aria-label": t('pages.firmware_upgrade_new.tables.selected_nodes.label'), autoHeight: true, columns: columns, rows: selectedNodes, noRowsMessage: t('pages.firmware_upgrade_new.tables.selected_nodes.no_row_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: selectedNodes.length, disableVirtualization: true, initialState: {
                sorting: {
                    sortModel: defaultSortModel,
                },
            } }) })));
});
