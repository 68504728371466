var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { useRolePermissionWarningByKey, useRolePermissionWarnings } from '@/components/accounts/roles/role-form-context';
/** * Constants ** */
export var defaultRolePermissions = {
    actions: {
        message_bus_actions: 'deny',
        subscriptions: 'deny',
    },
    cli: {
        console_shell: 'deny',
        pm_shell: 'off',
        shell_access: 'off',
    },
    logging: {
        logs_portlog: 'deny',
        logs_syslog: 'deny',
    },
    netops: {
        netops_modules: 'deny',
    },
    nodes_and_config: {
        nodes_devices_base: 'deny',
        nodes_devices_advanced: 'deny',
        nodes_firmware: 'deny',
        push_templates: 'deny',
    },
    service_settings: {
        lhvpn: 'deny',
        services_cellhealth: 'deny',
        services_console_gateway: 'deny',
        services_date_and_time: 'deny',
        services_https: 'deny',
        services_netops: 'deny',
        services_node_backup: 'deny',
        services_session_settings: 'deny',
        services_snmp: 'deny',
        services_ssh: 'deny',
        services_syslog: 'deny',
    },
    smartgroups_and_tags: {
        bundles: 'deny',
        smart_groups: 'deny',
        tags: 'deny',
    },
    system: {
        admin_and_licensing: 'deny',
        backup_and_restore: 'deny',
        jobs: 'deny',
        multi_instance: 'deny',
        network_interfaces: 'deny',
        upgrade_and_reset: 'deny',
    },
    users_and_permissions: {
        authentication: 'deny',
        groups_and_roles: 'deny',
        users: 'deny',
    },
    advanced_features: {
        services_smf: 'deny',
        connected_resource_gateway: 'deny',
    },
};
export var entityWeights = new Map([
    ['read_write', 2],
    ['read_only', 1],
    ['deny', 0],
]);
/** * Helpers ** */
export function hardCodePermissions(value, permissions) {
    var hardCodedPermissions = {};
    Object.keys(permissions).forEach(function (key) {
        hardCodedPermissions[key] = value;
    });
    return hardCodedPermissions;
}
// Helper to transform form data to API ready data
export function transformRoleFormData(_a) {
    var rolename = _a.rolename, description = _a.description, permissions = _a.permissions;
    var result = { rolename: rolename, permissions: permissions };
    if (description)
        result.description = description;
    return result;
}
// Helper to transform API data to form ready data
export function transformRoleApiData(_a) {
    var rolename = _a.rolename, description = _a.description, permissions = _a.permissions;
    return {
        rolename: rolename,
        description: description !== null && description !== void 0 ? description : '',
        permissions: permissions,
    };
}
export function createEntitiesDependencyMap(entities) {
    // Store the name keys while looping. Used to determine dependency form names
    var entitiesNameMap = new Map();
    // Store the dependency map
    var entitiesDependencyMap = new Map();
    Object.keys(entities).forEach(function (groupEntityKey) {
        if (Object.prototype.hasOwnProperty.call(entities, groupEntityKey)) {
            var entityItems_1 = entities[groupEntityKey];
            Object.keys(entityItems_1).forEach(function (entityItemKey) {
                if (Object.prototype.hasOwnProperty.call(entityItems_1, entityItemKey)) {
                    // Always add the name to the stored map
                    var entitiesName = "permissions.".concat(groupEntityKey, ".").concat(entityItemKey);
                    entitiesNameMap.set(entityItemKey, entitiesName);
                    // Add to the dependency map with the correct dependency names if necessary
                    var entityItem = entityItems_1[entityItemKey];
                    var entityItemDependencies = entityItem === null || entityItem === void 0 ? void 0 : entityItem.dependencies;
                    if (entityItemDependencies && entityItemDependencies.length > 0) {
                        // Initialise key in map if dependencies field exists
                        entitiesDependencyMap.set(entitiesName, entityItemDependencies);
                    }
                }
            });
        }
    });
    // Convert dependencies to usable form names
    entitiesDependencyMap.forEach(function (entityItemDependencies, entityItemKey, entitiesDependencyMapInstance) {
        var newEntityItemDependencies = [];
        entityItemDependencies.forEach(function (entityItemDependency) {
            var foundDependencyName = entitiesNameMap.get(entityItemDependency);
            if (foundDependencyName) {
                newEntityItemDependencies.push(foundDependencyName);
            }
        });
        entitiesDependencyMapInstance.set(entityItemKey, newEntityItemDependencies);
    });
    return entitiesDependencyMap;
}
export function useTrackDependencies(entityName, dependencyNames) {
    var t = useTranslation().t;
    var watch = useFormContext().watch;
    var _a = __read(useRolePermissionWarnings(), 2), dispatch = _a[1];
    var warning = useRolePermissionWarningByKey(entityName);
    var watchEntityPermission = watch(entityName);
    var watchDependencyPermissions = dependencyNames && dependencyNames.length > 0 ? watch(dependencyNames) : undefined;
    useEffect(function () {
        if (dependencyNames && watchEntityPermission && watchDependencyPermissions) {
            var permissionTranslations_1 = t('components.permissions.table.headers');
            var entitiesTranslations_1 = t('components.permissions.table.rows');
            var newWarning_1 = '';
            watchDependencyPermissions.forEach(function (dependencyPermission, index) {
                var _a, _b;
                if (((_a = entityWeights.get(watchEntityPermission)) !== null && _a !== void 0 ? _a : 0) > ((_b = entityWeights.get(dependencyPermission)) !== null && _b !== void 0 ? _b : 0)) {
                    var dependencyName = dependencyNames[index];
                    var dependencyTitle = dependencyName.substring(dependencyName.lastIndexOf('.') + 1);
                    newWarning_1 += format(t('components.permissions.table.permission_warning'), entitiesTranslations_1[dependencyTitle].title, permissionTranslations_1[watchEntityPermission]);
                }
            });
            if (!newWarning_1 && warning) {
                dispatch({ type: 'clear', payload: { key: entityName } });
            }
            else if (newWarning_1 && newWarning_1 !== warning) {
                dispatch({ type: 'set', payload: { key: entityName, message: newWarning_1 } });
            }
        }
    }, [dependencyNames, dispatch, entityName, t, warning, watchDependencyPermissions, watchEntityPermission]);
}
