var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useId } from 'react';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
export var buttonVariantMapping = new Map([
    ['primary', 'contained'],
    ['secondary', 'outlined'],
    ['borderless', 'text'],
]);
export var OGButton = forwardRef(function (_a, ref) {
    var _b = _a.variant, variant = _b === void 0 ? 'primary' : _b, loading = _a.loading, _c = _a.propagateSpacebar, propagateSpacebar = _c === void 0 ? true : _c, otherProps = __rest(_a, ["variant", "loading", "propagateSpacebar"]);
    var id = useId(); // generate id for loading buttons
    var buttonProps = __assign(__assign({}, otherProps), { color: 'inherit', variant: buttonVariantMapping.get(variant) || 'contained', ref: ref, onKeyDown: function (event) {
            if (!propagateSpacebar && event.code === 'Space') {
                event.stopPropagation();
            }
        } });
    // loading button
    if (loading) {
        var Loader = _jsx(CircularProgress, { color: "inherit", size: 16, "aria-labelledby": id });
        var disabled = buttonProps.disabled, startIcon = buttonProps.startIcon, endIcon = buttonProps.endIcon, otherLoadingProps = __rest(buttonProps, ["disabled", "startIcon", "endIcon"]);
        var loadingButtonProps = __assign(__assign({ id: id }, otherLoadingProps), { startIcon: loading && !endIcon ? Loader : startIcon, endIcon: loading && endIcon ? Loader : endIcon, disabled: disabled || loading });
        return _jsx(MuiButton, __assign({}, loadingButtonProps));
    }
    // icon button
    if (!buttonProps.children && (buttonProps.startIcon || buttonProps.endIcon)) {
        var sx = buttonProps.sx, otherIconButtonProps = __rest(buttonProps, ["sx"]);
        return (_jsx(MuiButton, __assign({}, otherIconButtonProps, { sx: __assign({ 'paddingLeft': '12px', 'paddingRight': '12px', '&.MuiButton-sizeSmall': {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                }, '&.MuiButton-sizeLarge': {
                    paddingLeft: '14px',
                    paddingRight: '14px',
                }, '&.MuiButton-sizeLarge .MuiButton-startIcon > :nth-of-type(1)': {
                    fontSize: '24px',
                }, '.MuiButton-startIcon, .MuiButton-endIcon': {
                    marginRight: 0,
                    marginLeft: 0,
                } }, sx) })));
    }
    // button
    return _jsx(MuiButton, __assign({}, buttonProps));
});
