var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import FormLabel from '@mui/material/FormLabel';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGButton } from '@opengear/buttons';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { requiredFieldError, maxLengthFieldError, minValueFieldError, maxValueFieldError, invalidFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN } from '@/constants/regex';
export function AuthServerFields() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, setFocus = _a.setFocus, setValue = _a.setValue, permissions = _a.permissions;
    var portFieldMinVal = 1;
    var portFieldMaxVal = 65535;
    var _b = useFieldArray({
        name: 'radiusAuthenticationServers',
        rules: { required: true },
        control: control,
    }), radiusAuthenticationServersFields = _b.fields, appendRadiusAuthenticationServer = _b.append, removeRadiusAuthenticationServer = _b.remove;
    var addAuthServerHandler = useCallback(function () {
        appendRadiusAuthenticationServer({ hostname: '', port: 1812 });
        var num = radiusAuthenticationServersFields.length;
        setFocus("radiusAuthenticationServers.".concat(num, ".hostname"));
    }, [appendRadiusAuthenticationServer, radiusAuthenticationServersFields, setFocus]);
    var removeAuthServerHandler = useCallback(function (index) {
        if (radiusAuthenticationServersFields.length > 1) {
            var nextServer = radiusAuthenticationServersFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("radiusAuthenticationServers.".concat(nextServer, ".hostname"));
            removeRadiusAuthenticationServer(index);
        }
        else {
            setFocus("radiusAuthenticationServers.0.hostname");
            setValue("radiusAuthenticationServers.".concat(index, ".hostname"), '');
            setValue("radiusAuthenticationServers.".concat(index, ".port"), 1812);
        }
    }, [removeRadiusAuthenticationServer, radiusAuthenticationServersFields, setFocus, setValue]);
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.remote_auth_server') }), radiusAuthenticationServersFields.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row', spacing: 1, paddingTop: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "radiusAuthenticationServers.".concat(index, ".hostname"), rules: {
                            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            pattern: {
                                value: HOSTNAME_OR_ADDRESS_PATTERN,
                                message: invalidFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            },
                        }, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), error = _a.fieldState.error;
                            return (_jsx(TextField, __assign({ inputRef: ref, fullWidth: true, label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title'), disabled: !permissions.hasAuthEditAccess, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true }, field)));
                        } }), _jsxs(Stack, __assign({ direction: "row", spacing: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "radiusAuthenticationServers.".concat(index, ".port"), rules: {
                                    required: {
                                        value: true,
                                        message: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title')),
                                    },
                                    min: {
                                        value: portFieldMinVal,
                                        message: minValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMinVal.toString()),
                                    },
                                    max: {
                                        value: portFieldMaxVal,
                                        message: maxValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMaxVal.toString()),
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, onChange = _b.onChange, ref = _b.ref, newField = __rest(_b, ["onChange", "ref"]), error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ fullWidth: true, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), _jsx(OGIconTooltip, { title: format(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.default'), '1812') })] }), disabled: !permissions.hasAuthEditAccess, required: true, sx: { maxWidth: isMobileView ? undefined : 200 }, type: "number", inputRef: ref }, newField, { error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, onChange: function (e) {
                                            onChange(e.target.value === '' ? '' : parseInt(e.target.value, 10));
                                        } })));
                                } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.radius.button.delete_auth'), "-").concat(index) }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.radius.button.delete_auth'), "-").concat(index), disabled: !permissions.hasAuthEditAccess, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return removeAuthServerHandler(index); } }) })) }))] }), "".concat(item.id, "-port"))] }), item.id)); }), _jsx(OGButton, __assign({ disabled: !permissions.hasAuthEditAccess, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: addAuthServerHandler }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.button.auth.title') }))] }));
}
export function AccServerFields() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, setFocus = _a.setFocus, permissions = _a.permissions;
    var portFieldMinVal = 1;
    var portFieldMaxVal = 65535;
    var addServerButton = useRef(null);
    var _b = useFieldArray({
        name: 'radiusAccountingServers',
        control: control,
    }), radiusAccountingServersFields = _b.fields, appendRadiusAccountingServer = _b.append, removeRadiusAccountingServer = _b.remove;
    var addAccountServerHandler = useCallback(function () {
        appendRadiusAccountingServer({ hostname: '', port: 1813 });
        var num = radiusAccountingServersFields.length;
        setFocus("radiusAccountingServers.".concat(num, ".hostname"));
    }, [appendRadiusAccountingServer, radiusAccountingServersFields, setFocus]);
    var removeAccountServerHandler = useCallback(function (index) {
        if (radiusAccountingServersFields.length > 1) {
            var nextServer = radiusAccountingServersFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("radiusAccountingServers.".concat(nextServer, ".hostname"));
        }
        else if (addServerButton.current) {
            addServerButton.current.focus();
        }
        removeRadiusAccountingServer(index);
    }, [removeRadiusAccountingServer, radiusAccountingServersFields, setFocus, addServerButton]);
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.radius.remote_acc_server') }), radiusAccountingServersFields.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row', spacing: 1, paddingTop: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "radiusAccountingServers.".concat(index, ".hostname"), rules: {
                            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            pattern: {
                                value: HOSTNAME_OR_ADDRESS_PATTERN,
                                message: invalidFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            },
                        }, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), error = _a.fieldState.error;
                            return (_jsx(TextField, __assign({ inputRef: ref, fullWidth: true, label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title'), disabled: !permissions.hasAuthEditAccess, required: true, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message }, field)));
                        } }), _jsxs(Stack, __assign({ direction: "row", spacing: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "radiusAccountingServers.".concat(index, ".port"), rules: {
                                    required: {
                                        value: true,
                                        message: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title')),
                                    },
                                    min: {
                                        value: portFieldMinVal,
                                        message: minValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMinVal.toString()),
                                    },
                                    max: {
                                        value: portFieldMaxVal,
                                        message: maxValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMaxVal.toString()),
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, onChange = _b.onChange, ref = _b.ref, newField = __rest(_b, ["onChange", "ref"]), error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ fullWidth: true, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), _jsx(OGIconTooltip, { title: format(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.default'), '1813') })] }), disabled: !permissions.hasAuthEditAccess, sx: { maxWidth: isMobileView ? undefined : 200 }, type: "number", required: true, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref }, newField, { onChange: function (e) {
                                            onChange(e.target.value === '' ? '' : parseInt(e.target.value, 10));
                                        } })));
                                } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.radius.button.delete_acc'), "-").concat(index) }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.radius.button.delete_acc'), "-").concat(index), disabled: !permissions.hasAuthEditAccess, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return removeAccountServerHandler(index); } }) })) }))] }), "".concat(item.id, "-port"))] }), item.id)); }), _jsx(OGButton, __assign({ ref: addServerButton, disabled: !permissions.hasAuthEditAccess, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: addAccountServerHandler }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.button.acc.title') }))] }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch, permissions = _a.permissions, trigger = _a.trigger;
    var isRemoteAuth = watch('authStyle');
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "radiusPassword", rules: {
            required: isRemoteAuth ? requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password')) : false,
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, type: "password", label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password'), error: fieldState.invalid, helperText: fieldState.error ? fieldState.error.message : null, required: !!isRemoteAuth }, field, { onBlur: function () {
                    field.onBlur();
                    trigger('confirmRadiusPassword');
                } })));
        } }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirmRadiusPassword", rules: {
            validate: {
                passwordsMatch: function (value, formValues) {
                    return formValues.radiusPassword === formValues.confirmRadiusPassword || t('global.form.password_not_matched');
                },
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, type: "password", label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password_confirm'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
