var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { subscriptionTierMap } from '@/utils/license';
// transform subscriptions
function transformSubscriptions(entitlementsData) {
    var subscriptions = [];
    entitlementsData.forEach(function (entitlement) {
        var _a;
        if (!subscriptions.some(function (subscription) { return subscription.subscriptionType === entitlement.details.tier; }) // ignore if subscription already exists
        ) {
            subscriptions.push({
                name: subscriptionTierMap.get(entitlement.details.tier) || '',
                subscriptionType: entitlement.details.tier,
                availableNodes: entitlement.features.available_nodes || 0,
                isEvaluation: entitlement.details.bucket === 'eval',
                isExpired: ((_a = entitlement.expiration) === null || _a === void 0 ? void 0 : _a.isExpired) || false,
            });
        }
    });
    return subscriptions;
}
function SubscriptionSelectButton(_a) {
    var subscription = _a.subscription, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.showAvailable, showAvailable = _c === void 0 ? true : _c, otherProps = __rest(_a, ["subscription", "disabled", "showAvailable"]);
    var t = useTranslation().t;
    var subscriptionType = subscription.subscriptionType, availableNodes = subscription.availableNodes, isEvaluation = subscription.isEvaluation, isExpired = subscription.isExpired;
    return (_jsx(ToggleButton, __assign({ "aria-label": "\n      ".concat(subscriptionTierMap.get(subscriptionType)).concat(isEvaluation ? t('global.subscriptions.evaluation_mode') : '', "\n      ").concat(isExpired ? " - ".concat(t('global.subscriptions.expired')) : '', "\n      ").concat(!isExpired && showAvailable ? " - ".concat(availableNodes) : '', "\n      "), value: subscriptionType, disabled: disabled || isEvaluation || isExpired }, otherProps, { children: _jsxs(Stack, __assign({ width: "100%", direction: "row", justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(Box, __assign({ sx: { display: 'flex', flex: 1, textAlign: 'left' } }, { children: "".concat(subscriptionTierMap.get(subscriptionType)).concat(isEvaluation ? t('global.subscriptions.evaluation_mode') : '') })), showAvailable && !isExpired && _jsx(Box, __assign({ sx: { display: 'flex', pl: 1 } }, { children: availableNodes })), isExpired && _jsx(Box, __assign({ sx: { display: 'flex', pl: 1 } }, { children: t('global.subscriptions.expired') }))] })) })));
}
export function LicenseSelectField(_a) {
    var autoFocus = _a.autoFocus, field = _a.field, subscriptionsData = _a.subscriptionsData, _b = _a.orientation, orientation = _b === void 0 ? 'vertical' : _b, ariaLabel = _a.ariaLabel, _c = _a.disabled, disabled = _c === void 0 ? false : _c, showAvailable = _a.showAvailable, sx = _a.sx;
    var value = field.value, onChange = field.onChange, fieldProps = __rest(field, ["value", "onChange"]);
    var fieldStyles = __assign({}, sx);
    var subscriptions = useMemo(function () { return transformSubscriptions(subscriptionsData); }, [subscriptionsData]);
    if (subscriptions.length === 0)
        return null;
    return (_jsx(ToggleButtonGroup, __assign({ exclusive: true, "aria-label": ariaLabel, orientation: orientation, disabled: disabled, value: value, onChange: function (event, newSubscription) {
            if (newSubscription !== null) {
                onChange(newSubscription);
            }
        } }, fieldProps, { sx: fieldStyles }, { children: subscriptions.map(function (subscription, idx) { return (_jsx(SubscriptionSelectButton, { 
            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus: autoFocus && !idx, subscription: subscription, showAvailable: showAvailable, disabled: disabled }, subscription.subscriptionType)); }) })));
}
