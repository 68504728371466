var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export function DisabledTextField(_a) {
    var id = _a.id, label = _a.label, value = _a.value, startIcon = _a.startIcon, sx = _a.sx;
    var labelId = "".concat(id, "-label");
    var valueStyles = __assign({ fontWeight: 400, letterSpacing: '0.04em' }, sx);
    return (_jsxs(Box, __assign({ marginBottom: 1.5 }, { children: [_jsx(Typography, __assign({ component: "span", id: labelId }, { children: label })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", marginTop: 0.75 }, { children: [startIcon, _jsx(Typography, __assign({ id: id, "aria-labelledby": labelId, component: "span", sx: valueStyles }, { children: value }))] }))] })));
}
