var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { invalidFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { IPV4_OR_NUM_ONLY_PATTERN } from '@/constants/regex';
// Different Form Controls
export function SmfStatusField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsx(FormControl, { children: _jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasEditPermission, onChange: function (event, newValue) {
                        if (newValue !== null)
                            onChange(newValue);
                    } }, field, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })) }));
        } }));
}
export function AreaField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ospf_internal_area", rules: {
            required: requiredFieldError(t('pages.smf.form.fields.area.label')),
            pattern: { value: IPV4_OR_NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.smf.form.fields.area.label')) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "smf-area", disabled: !permissions.hasEditPermission, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.smf.form.fields.area.label'), _jsx(OGIconTooltip, { title: t('pages.smf.form.fields.area.description') })] }), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
        } }));
}
