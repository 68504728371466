var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import { useAnchorOpen } from '@/hooks/open';
export function InterfacesDetailsCell(_a) {
    var row = _a.row, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    var _b = useAnchorOpen(), anchorEl = _b.anchorEl, open = _b.open, handleClose = _b.handleClose, handleToggle = _b.handleToggle;
    var statusFormatMap = useMemo(function () {
        return new Map([
            ['reloading', t('pages.network_interfaces.table.status.reloading')],
            ['down', t('pages.network_interfaces.table.status.configuration_down')],
            ['unavailable', t('pages.network_interfaces.table.status.unavailable')],
        ]);
    }, [t]);
    var ifaceMode = row.mode;
    var _c = row.runtime_status, ifaceStatus = _c.status, addresses = _c.addresses, gateway = _c.gateway, dns1 = _c.dns1, id = _c.id;
    var renderAddress = useCallback(function (addressItem) { return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', marginBottom: 1 } }, { children: [addressItem.address && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.address'), " : ", addressItem.address] }))), ifaceMode === 'dhcp' && addressItem.netmask && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.netmask'), " : ", addressItem.netmask] }))), ifaceMode === 'ipv6_automatic' && addressItem.prefix_length && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.prefix_length'), " : ", addressItem.prefix_length] }))), gateway && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.gateway'), " : ", gateway] }))), dns1 && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.dns_server'), " : ", dns1] })))] }), addressItem.id)); }, [dns1, gateway, ifaceMode, t]);
    // rendering if mode is 'static' or 'ipv6_static'
    var renderStatic = useCallback(function () {
        var _a, _b, _c, _d;
        var ifaceDetails = ifaceMode === 'static' ? row.ipv4_static_settings : row.ipv6_static_settings;
        return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [(ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.address) && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.address'), " : ", ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.address] }))), ((_a = row.ipv4_static_settings) === null || _a === void 0 ? void 0 : _a.netmask) && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.netmask'), " : ", (_b = row.ipv4_static_settings) === null || _b === void 0 ? void 0 : _b.netmask] }))), ((_c = row.ipv6_static_settings) === null || _c === void 0 ? void 0 : _c.prefix_length) && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.prefix_length'), " : ", (_d = row.ipv6_static_settings) === null || _d === void 0 ? void 0 : _d.prefix_length] }))), (ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.gateway) && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.gateway'), " : ", ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.gateway] }))), (ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.dns1) && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.primary_dns_server'), " : ", ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.dns1] }))), (ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.dns2) && (_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.network_interfaces.table.details.secondary_dns_server'), " : ", ifaceDetails === null || ifaceDetails === void 0 ? void 0 : ifaceDetails.dns2] })))] })));
    }, [ifaceMode, row.ipv4_static_settings, row.ipv6_static_settings, t]);
    if (ifaceStatus === 'down' || ifaceStatus === 'reloading') {
        return _jsx(Typography, __assign({ component: "div" }, { children: statusFormatMap.get(ifaceStatus) }));
    }
    if (ifaceStatus === 'running') {
        if (ifaceMode === 'static' || ifaceMode === 'ipv6_static') {
            return renderStatic();
        }
        if (addresses && (ifaceMode === 'dhcp' || ifaceMode === 'ipv6_automatic')) {
            if (addresses.length > 1) {
                return (_jsxs(_Fragment, { children: [_jsx(Popover, __assign({ open: open, anchorEl: anchorEl, "aria-label": t('pages.network_interfaces.table.details.see_more.label'), sx: { maxHeight: '210px' }, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            }, transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            }, onClose: handleClose }, { children: _jsx(Box, __assign({ sx: { padding: 1, paddingBottom: 0 } }, { children: addresses.map(function (addressItem) { return renderAddress(addressItem); }) })) })), _jsxs(Box, { children: [renderAddress(addresses[0]), _jsx(ToggleButton, __assign({ sx: { width: 'max-content', marginTop: -0.8, paddingX: 0.5, paddingY: 0.3, textTransform: 'initial', lineHeight: 1 }, "aria-expanded": open, "aria-haspopup": true, tabIndex: tabIndex, "aria-label": t('pages.network_interfaces.table.details.see_more.button_label'), value: "checked", onClick: handleToggle }, { children: _jsx(Typography, __assign({ noWrap: true }, { children: t('pages.network_interfaces.table.details.see_more.button_label') })) }))] })] }));
            }
            return renderAddress(addresses[0]);
        }
    }
    return (_jsx(Typography, __assign({ component: "div" }, { children: statusFormatMap.get('unavailable') }), id));
}
