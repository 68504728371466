var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useDeviceView } from '@/hooks/use-device-view';
import { NewFirmwareUpgradeButtons, useNewFirmwareUpgradeSteps, NewFirmwareUpgradeStep } from './steps';
import { UpgradeInFailoverNodesTable } from './upgrade-in-failover-nodes';
import { UpgradeInFailoverActionButton } from './form-controls';
import { StartTimeSummary, TaskDetails } from './details';
export function Review() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var activeStep = useNewFirmwareUpgradeSteps().activeStep;
    if (activeStep < NewFirmwareUpgradeStep.Review)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs(Paper, __assign({ sx: { padding: 2 } }, { children: [_jsxs(Box, __assign({ marginBottom: 4 }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.review_confirm.task_details.heading') })), _jsx(TaskDetails, {})] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.review_confirm.start_time.heading') })), _jsx(StartTimeSummary, {})] })] })), _jsxs(Paper, __assign({ sx: { padding: 2 } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.review_confirm.nodes.heading') })), _jsxs(Box, __assign({ display: "flex", flexDirection: isMobileView ? 'column' : 'row', justifyContent: "space-between", alignItems: isMobileView ? 'start' : 'center', marginBottom: 1, gap: 1 }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center" }, { children: [_jsx(InfoIcon, { fontSize: "medium" }), _jsx(Typography, __assign({ id: "firmware-upgrade-review-nodes-table-description", component: "span", variant: "body1" }, { children: t('pages.firmware_upgrade_new.steps.review_confirm.nodes.message') }))] })), _jsxs(ButtonGroup, __assign({ fullWidth: isMobileView, orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(UpgradeInFailoverActionButton, { action: "allow" }), _jsx(UpgradeInFailoverActionButton, { action: "disallow" })] }))] }))] }), _jsx(UpgradeInFailoverNodesTable, {}), _jsx(NewFirmwareUpgradeButtons, {})] }))] }));
}
