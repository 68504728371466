var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { decodeCreateTemplateResponse, decodeReadTemplatesResponse } from '../../../types/lighthouse/decoders/templates';
import { decodeDeleteTemplateResponse, decodeReadTemplateResponse, decodeUpdateTemplateResponse, } from '../../../types/lighthouse/decoders/templates/template-id';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var TEMPLATES_PATH = '/templates';
export function formatTemplatePath(templateId) {
    if (templateId === void 0) { templateId = ':templateId'; }
    return "".concat(TEMPLATES_PATH, "/").concat(templateId);
}
/**
 * Hook to fetch list of templates.
 */
export function useTemplates(queryParams) {
    var _this = this;
    if (queryParams === void 0) { queryParams = {}; }
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchTemplates = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(TEMPLATES_PATH, __assign({ queryParams: queryParams }, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadTemplatesResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.templates.get'));
                    return [2 /*return*/, status === 200 ? data.templates : null];
            }
        });
    }); }, [executeFetch, queryParams, decodeResponse, handleCommonStatusCodes, t]);
    return useQuery({
        queryKey: [TEMPLATES_PATH, queryParams],
        queryFn: fetchTemplates,
    });
}
export function useTemplate(templateId, _a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a, notFoundCallback = _b.notFoundCallback;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchTemplate = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatTemplatePath(templateId), __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadTemplateResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        error: { message: format(t('api.template.get.error.message'), templateId) },
                        forbidden: { message: format(t('api.template.get.forbidden.message'), templateId) },
                        not_found: { message: format(t('api.template.get.not_found.message'), templateId) },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 404 && notFoundCallback)
                        notFoundCallback(status);
                    return [2 /*return*/, status === 200 ? data.template : null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, notFoundCallback, t, templateId]);
    return useQuery({
        queryKey: [TEMPLATES_PATH, templateId],
        queryFn: fetchTemplate,
    });
}
export function useCreateTemplate() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (templateDetails) { return __awaiter(_this, void 0, void 0, function () {
        var response, formdata, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!('file' in templateDetails)) return [3 /*break*/, 2];
                    formdata = new FormData();
                    formdata.append('file', templateDetails.file);
                    formdata.append('template', JSON.stringify(templateDetails.template));
                    return [4 /*yield*/, executeFetch(TEMPLATES_PATH, { method: 'POST', body: formdata, headers: new Headers({ Accept: 'application/json' }) })];
                case 1:
                    response = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, executeFetch(TEMPLATES_PATH, { method: 'POST', body: JSON.stringify({ template: templateDetails.template }) })];
                case 3:
                    response = _b.sent();
                    _b.label = 4;
                case 4: return [4 /*yield*/, decodeResponse(decodeCreateTemplateResponse, response)];
                case 5:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.templates.post'));
                    if (status === 201) {
                        queryClient.setQueryData([TEMPLATES_PATH, { type: data.template.type }], function (existingTemplates) { return __spreadArray(__spreadArray([], __read((existingTemplates !== null && existingTemplates !== void 0 ? existingTemplates : [])), false), [
                            data.template,
                        ], false); });
                        // Optimistically update template by id
                        queryClient.setQueryData([TEMPLATES_PATH, data.template.id], data.template);
                        // Invalidate templates for specific type
                        queryClient
                            .invalidateQueries({
                            queryKey: [TEMPLATES_PATH, { type: data.template.type }],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data.template];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useUpdateTemplate() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var templatePath, response, formdata, _b, data, status, translations;
        var templateId = _a.id, template = __rest(_a, ["id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    templatePath = formatTemplatePath(templateId);
                    if (!('file' in template)) return [3 /*break*/, 2];
                    formdata = new FormData();
                    // FIXME: This 'as File' is required to stop TS complaining about calling append with null
                    // As far as the types are concerned this isn't allowed but it's what we do in the Ember UI
                    // and is needed to signal an update to a script template without the file changing
                    formdata.append('file', template.file);
                    formdata.append('template', JSON.stringify(template.template));
                    return [4 /*yield*/, executeFetch(templatePath, { method: 'PUT', body: formdata, headers: new Headers({ Accept: 'application/json' }) })];
                case 1:
                    response = _c.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, executeFetch(templatePath, { method: 'PUT', body: JSON.stringify(template) })];
                case 3:
                    response = _c.sent();
                    _c.label = 4;
                case 4: return [4 /*yield*/, decodeResponse(decodeUpdateTemplateResponse, response)];
                case 5:
                    _b = _c.sent(), data = _b.data, status = _b.status;
                    translations = {
                        error: { message: format(t('api.template.put.error.message'), templateId) },
                        forbidden: { message: format(t('api.template.put.forbidden.message'), templateId) },
                        not_found: { message: format(t('api.template.put.not_found.message'), templateId) },
                        ok: { header: t('api.template.put.ok.header'), message: format(t('api.template.put.ok.message'), templateId) },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        // Optimistically update all templates for specific type
                        queryClient.setQueryData([TEMPLATES_PATH, { type: data.template.type }], function (existingTemplates) { return __spreadArray(__spreadArray([], __read((existingTemplates ? existingTemplates.filter(function (existingTemplate) { return existingTemplate.id !== data.template.id; }) : [])), false), [
                            data.template,
                        ], false); });
                        // Optimistically update template by id
                        queryClient.setQueryData([TEMPLATES_PATH, data.template.id], data.template);
                        // Invalidate templates for specific type
                        queryClient
                            .invalidateQueries({
                            queryKey: [TEMPLATES_PATH, { type: data.template.type }],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data.template];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useDeleteTemplate() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (templateId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatTemplatePath(templateId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteTemplateResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        error: { message: format(t('api.template.delete.error.message'), templateId) },
                        forbidden: { message: format(t('api.template.delete.forbidden.message'), templateId) },
                        not_found: { message: format(t('api.template.delete.not_found.message'), templateId) },
                        ok: { header: t('api.template.delete.ok.header'), message: format(t('api.template.delete.ok.message'), templateId) },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 204) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [TEMPLATES_PATH],
                            refetchType: 'none',
                        })
                            .catch(console.error);
                        queryClient.removeQueries({
                            queryKey: [TEMPLATES_PATH, templateId],
                        });
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
