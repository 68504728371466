var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Popover from '@mui/material/Popover';
import ToggleButton from '@mui/material/ToggleButton';
import { OGSettingsIcon } from '@opengear/icons/navigation/settings';
import { useTranslation } from 'react-i18next';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { PopupMenuSection } from '@/components/popup-menu-section';
import { TIME_SETTINGS_ROUTE, CELL_HEALTH_REPORTING_ROUTE, CONSOLE_GATEWAY_ROUTE, MILHVPN_ROUTE, LIGHTHOUSE_VPN_ROUTE, SESSION_SETTINGS_ROUTE, NODE_BACKUP_ROUTE, SUBSCRIPTION_SETTINGS_ROUTE, SECONDARY_LIGHTHOUSES_ROUTE, SYSTEM_CONFIG_ROUTE, TAGS_ROUTE, SMF_SETTINGS_ROUTE, NETWORK_INTERFACES_ROUTE, SYSTEM_UPGRADE_ROUTE, HTTPS_SETTINGS_ROUTE, ALERTING_AND_MONITORING_ROUTE, FILTERS_ROUTE, } from '@/constants/routes';
import { useEntitlementsData } from '@/api/system/entitlements';
var ID = 'settings-menu';
var SETTINGS_MENU = [
    {
        header: 'navigation.filters_and_tags.title',
        items: [
            {
                permissions: ['configure.edit_smart_groups.view', 'configure.edit_managed_device_filters.view'],
                title: 'pages.filters.title',
                to: FILTERS_ROUTE,
            },
            {
                permissions: ['configure.edit_tags.view'],
                title: 'pages.tags.title',
                to: TAGS_ROUTE,
            },
        ],
    },
    {
        header: 'navigation.multi_instance.title',
        items: [
            {
                permissions: ['configure.multiple_instance.lhvpn.view'],
                mi_check: true,
                title: 'pages.multi_instance_vpn.title',
                to: MILHVPN_ROUTE,
            },
            {
                permissions: ['configure.multiple_instance.dependent_lighthouse.view'],
                mi_check: true,
                title: 'pages.secondary_lighthouses.title',
                to: SECONDARY_LIGHTHOUSES_ROUTE,
            },
        ],
    },
    {
        header: 'navigation.services.title',
        items: [
            {
                permissions: ['settings.services.snmp_manager.view', 'settings.services.snmp_service.view', 'settings.services.syslog_servers.view'],
                title: 'pages.alerts_and_monitoring.title',
                to: ALERTING_AND_MONITORING_ROUTE,
            },
            {
                permissions: ['settings.services.cellhealth.view'],
                title: 'pages.cell_health_reporting.title',
                to: CELL_HEALTH_REPORTING_ROUTE,
            },
            {
                permissions: ['settings.services.console_gateway.view'],
                title: 'pages.console_gateway.title',
                to: CONSOLE_GATEWAY_ROUTE,
            },
            {
                permissions: ['settings.services.https_certificate.view'],
                title: 'pages.https_certificate.title',
                to: HTTPS_SETTINGS_ROUTE,
            },
            {
                permissions: ['settings.services.lhvpn.view'],
                title: 'pages.lighthouse_vpn.title',
                to: LIGHTHOUSE_VPN_ROUTE,
            },
            {
                permissions: ['settings.services.node_backup.view'],
                title: 'pages.node_backup.title',
                to: NODE_BACKUP_ROUTE,
            },
            {
                permissions: ['settings.services.smf.view'],
                title: 'pages.smf.title',
                to: SMF_SETTINGS_ROUTE,
            },
        ],
    },
    {
        header: 'navigation.system.title',
        items: [
            {
                permissions: ['settings.system.backup.view', 'settings.system.restore.view', 'settings.system.reset.view'],
                title: 'pages.backup_restore_reset.title',
                to: SYSTEM_CONFIG_ROUTE,
            },
            {
                permissions: ['settings.network_connections.network_interfaces.view'],
                title: 'pages.network_interfaces.title',
                to: NETWORK_INTERFACES_ROUTE,
            },
            {
                permissions: ['settings.system.administration.view'],
                title: 'pages.network_settings.title',
                to: '/settings/system/network-settings',
            },
            {
                permissions: ['settings.services.session_settings.view'],
                title: 'pages.session_settings.title',
                to: SESSION_SETTINGS_ROUTE,
            },
            {
                permissions: ['settings.system.licensing.view'],
                title: 'pages.subscriptions.title',
                to: SUBSCRIPTION_SETTINGS_ROUTE,
            },
            {
                permissions: ['settings.system.upgrade.view'],
                title: 'pages.system_upgrade.title',
                to: SYSTEM_UPGRADE_ROUTE,
            },
            {
                permissions: ['settings.date_time.automatic_settings.view', 'settings.date_time.time_zone.view'],
                title: 'pages.time_settings.title',
                to: TIME_SETTINGS_ROUTE,
            },
        ],
    },
];
export var SETTINGS_MENU_RIGHTS = __spreadArray([], __read(new Set(SETTINGS_MENU.flatMap(function (section) { return section.items.flatMap(function (item) { return item.permissions; }); }))), false);
export function SettingsMenu() {
    var _a = __read(useState(null), 2), anchorEl = _a[0], setAnchorEl = _a[1];
    var t = useTranslation().t;
    var open = !!anchorEl;
    var hasSomePermission = useUIPermissions().hasSomePermission;
    var miSupported = useEntitlementsData().miSupported;
    var handleClick = useCallback(function (_a) {
        var currentTarget = _a.currentTarget;
        setAnchorEl(function (state) { return (state ? null : currentTarget); });
    }, [setAnchorEl]);
    var handleClose = useCallback(function () {
        setAnchorEl(null);
    }, [setAnchorEl]);
    if (!hasSomePermission(SETTINGS_MENU_RIGHTS))
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(ToggleButton, __assign({ id: "".concat(ID, "-button"), "aria-expanded": open, "aria-haspopup": true, "aria-label": t('navigation.settings_menu.label'), selected: open, sx: { marginX: 0.5, marginBottom: 0.75, paddingX: 1 }, value: "checked", onClick: handleClick }, { children: _jsx(OGSettingsIcon, {}) })), _jsx(Popover, __assign({ open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, sx: {
                    'hr:last-of-type': {
                        display: 'none',
                    },
                } }, { children: SETTINGS_MENU.map(function (section) { return (_jsx(PopupMenuSection, { divider: true, 
                    // TODO: IM-16301 Remove type assertions after i18next upgrade
                    header: t(section.header), items: section.items.map(function (item) { return ({
                        visible: item.mi_check ? hasSomePermission(item.permissions) && miSupported : hasSomePermission(item.permissions),
                        onClose: handleClose,
                        title: t(item.title),
                        to: item.to,
                    }); }) }, section.header)); }) }))] }));
}
