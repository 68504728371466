var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { RoleForm } from '@/components/accounts/roles/role-form';
import { useCreateRole } from '@/api/roles';
import { useEntities } from '@/api/entities';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var ADD_ROLE_LOADING_TEST_ID = 'add-role-loading';
function AddRole() {
    var t = useTranslation().t;
    useDocTitle(t('pages.role_new.title'));
    var createRole = useCreateRole();
    var _a = useEntities(), entitiesData = _a.data, isLoadingEntities = _a.isLoading;
    var innerNode = null;
    if (isLoadingEntities) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": ADD_ROLE_LOADING_TEST_ID });
    }
    if (entitiesData) {
        innerNode = _jsx(RoleForm, { entities: entitiesData.entities, submitHandler: createRole });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 1.5 }, { children: t('pages.role_new.title') })), innerNode] })));
}
// settings.user_management.permission.edit covers groups and roles
export default withAuthenticationRequired(AddRole, { rights: ['settings.user_management.permissions.edit'] });
