var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { decodeNodesBulkUnenrollResponse } from '../../../../types/lighthouse/decoders/nodes/bulk/unenroll';
import { CELL_HEALTH_SUMMARY_PATH } from '../../stats/nodes/cell-health-summary';
import { nodeQueryKeys } from '../../nodes';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var NODES_BULK_APPROVE_PATH = '/nodes/bulk/approve';
export function useBulkApproveNodes() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    return useCallback(function (_a) {
        var _b = __read(_a), nodeIds = _b.slice(0);
        return __awaiter(_this, void 0, void 0, function () {
            var response, _c, data, status, translations;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, executeFetch(NODES_BULK_APPROVE_PATH, { method: 'POST', body: JSON.stringify({ node_ids: nodeIds }) })];
                    case 1:
                        response = _d.sent();
                        return [4 /*yield*/, decodeResponse(decodeNodesBulkUnenrollResponse, response)
                            // Consumers currently handle success messages, so we are only handling error messages here
                        ];
                    case 2:
                        _c = _d.sent(), data = _c.data, status = _c.status;
                        translations = {
                            forbidden: t('api.nodes_bulk_approve.post.forbidden'),
                            error: t('api.nodes_bulk_approve.post.error'),
                        };
                        handleCommonStatusCodes(data, status, translations);
                        if (status === 202) {
                            queryClient
                                .invalidateQueries({
                                queryKey: nodeQueryKeys.all,
                            })
                                .catch(console.error);
                            queryClient
                                .invalidateQueries({
                                queryKey: [CELL_HEALTH_SUMMARY_PATH],
                            })
                                .catch(console.error);
                            return [2 /*return*/, data];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
