var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { NameField, SelectFirmwareField, SelectNodesField } from './form-controls';
import { NewFirmwareUpgradeButtons, NewFirmwareUpgradeStep, useNewFirmwareUpgradeSteps } from './steps';
export function SetupUpgradeTask() {
    var t = useTranslation().t;
    var activeStep = useNewFirmwareUpgradeSteps().activeStep;
    if (activeStep !== NewFirmwareUpgradeStep.Setup)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs(Paper, __assign({ sx: { padding: 2 } }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.setup.select_firmware_subheading') })), _jsx(NameField, {}), _jsx(SelectFirmwareField, {})] })), _jsxs(Paper, __assign({ sx: { padding: 2 } }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.setup.select_nodes_subheading') })), _jsx(SelectNodesField, {}), _jsx(NewFirmwareUpgradeButtons, {})] }))] }));
}
