var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { decodeReadCellhealthSettingsResponse, decodeUpdateCellhealthSettingsResponse } from '../../../types/lighthouse/decoders/services/cellhealth';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var CELLHEALTH_PATH = '/services/cellhealth';
/**
 * transform API Cell Health Model to UI Model
 * required for range slider
 */
function api2ui(_a) {
    var _b = _a.cellhealth, quality_range_quarter2 = _b.quality_range_quarter2, quality_range_quarter3 = _b.quality_range_quarter3, other = __rest(_b, ["quality_range_quarter2", "quality_range_quarter3"]);
    return __assign(__assign({}, other), { quality_range_quarter23: [quality_range_quarter2, quality_range_quarter3] });
}
/**
 * transform UI Cell Health Model to API Model
 */
function ui2api(_a) {
    var quality_range_quarter23 = _a.quality_range_quarter23, other = __rest(_a, ["quality_range_quarter23"]);
    return {
        cellhealth: __assign(__assign({}, other), { quality_range_quarter2: quality_range_quarter23 ? quality_range_quarter23[0] : undefined, quality_range_quarter3: quality_range_quarter23 ? quality_range_quarter23[1] : undefined }),
    };
}
export function useCellhealthSettings() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchCellhealthSettings = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(CELLHEALTH_PATH, __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadCellhealthSettingsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.services_cellhealth.get'));
                    return [2 /*return*/, status === 200 ? api2ui(data) : null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, t]);
    return useQuery({
        queryKey: [CELLHEALTH_PATH],
        queryFn: fetchCellhealthSettings,
    });
}
export function useUpdateCellhealthSettings() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (cellhealthSettings) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(CELLHEALTH_PATH, { method: 'PUT', body: JSON.stringify(ui2api(cellhealthSettings)) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateCellhealthSettingsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.services_cellhealth.put'));
                    if (!(status === 200)) return [3 /*break*/, 4];
                    result = api2ui(data);
                    queryClient.setQueryData([CELLHEALTH_PATH], result);
                    return [4 /*yield*/, queryClient
                            .invalidateQueries({
                            queryKey: [CELLHEALTH_PATH],
                        })
                            .catch(console.error)];
                case 3:
                    _b.sent();
                    return [2 /*return*/, result];
                case 4: return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, t, queryClient]);
}
