var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTemplate } from '@/api/template/templates';
import { templateTypeMap } from '@/utils/template';
export function TemplatePushStepper(_a) {
    var step = _a.step;
    var t = useTranslation().t;
    var pushSteps = useMemo(function () { return [t('pages.config_templates.push.select'), t('pages.config_templates.push.pre_flight'), t('pages.config_templates.push.push')]; }, [t]);
    var _b = __read(useState(''), 2), liveMessage = _b[0], setLiveMessage = _b[1];
    useEffect(function () {
        // Wait for a short period before setting the live message
        var timer = setTimeout(function () {
            setLiveMessage("Currently on step ".concat(step + 1, ": ").concat(pushSteps[step]));
        }, 500);
        return function () { return clearTimeout(timer); };
    }, [step, pushSteps]);
    return (_jsx(Container, __assign({ sx: { maxWidth: 600, padding: 2 } }, { children: _jsx(Stepper, __assign({ activeStep: step, "aria-label": "Progress" }, { children: pushSteps.map(function (label, index) { return (_jsx(Step, __assign({ completed: step > index }, { children: _jsx(StepLabel, __assign({ "aria-live": "polite", "aria-label": liveMessage }, { children: label })) }), label)); }) })) })));
}
export function TemplatePreviewSection(_a) {
    var templateId = _a.templateId;
    var theme = useTheme();
    var data = useTemplate(templateId).data;
    if (!data)
        return null;
    return (_jsxs(Stack, __assign({ padding: 0 }, { children: [_jsxs(Box, __assign({ borderBottom: "1px solid ".concat(theme.palette.divider), padding: 2 }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', paddingBottom: 1, alignItems: 'center' } }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", sx: { fontWeight: 700, fontSize: 16, wordBreak: 'keep-all' } }, { children: data.name })), _jsx(ArrowForwardIcon, { sx: { marginLeft: 2 } })] })), _jsx(Typography, { children: templateTypeMap.get(data.type) })] })), _jsx(Typography, __assign({ padding: 2 }, { children: data.description }))] })));
}
