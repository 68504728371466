import { rest } from 'msw';
import { formatApiPath } from '@/api/utils';
import { TEST_LOCATION_PATH } from './test-location';
var TEST_LOCATION_URL = formatApiPath(TEST_LOCATION_PATH);
export var defaultGetTestLocationResponse = {
    location_test: {
        location_writable: true,
        available_space: 7326,
        total_space: 7963,
        is_space_enough: true,
    },
};
export var mockGetTestLocationHandler = rest.get(TEST_LOCATION_URL, function (req, res, ctx) { return res(ctx.status(200), ctx.json(defaultGetTestLocationResponse)); });
