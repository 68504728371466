var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Typography from '@mui/material/Typography';
export function OGNavPopupMenuHeader(_a) {
    var align = _a.align, children = _a.children, sx = _a.sx, tabIndex = _a.tabIndex, props = __rest(_a, ["align", "children", "sx", "tabIndex"]);
    return (_jsx(Typography, __assign({ component: "h3", tabIndex: -1, variant: "h3", sx: __assign({ color: 'text.highlight', marginBottom: 0.75, paddingX: 2, paddingY: 0.75 }, sx) }, props, { children: children })));
}
