var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { OGDialog } from '@opengear/modals';
import { OGLink } from '@opengear/links';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { OGButton } from '@opengear/buttons';
import { useQueryParameterOpen } from '@/hooks/open';
import PermissionsPreview from '../permissions/permissions-preview';
export var QUERY_PARAM_ROLE_NAME_KEY = 'roles';
export function RolesPreviewModal(_a) {
    var _b;
    var roleName = _a.roleName, rolesPermissions = _a.rolesPermissions, tabIndex = _a.tabIndex;
    var _c = useQueryParameterOpen(QUERY_PARAM_ROLE_NAME_KEY, roleName), open = _c.open, handleClose = _c.handleClose;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: "?".concat(new URLSearchParams((_b = {}, _b[QUERY_PARAM_ROLE_NAME_KEY] = roleName, _b))) }, { children: roleName })), _jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: roleName }), _jsx(DialogContent, { children: _jsx(PermissionsPreview, { name: roleName, permissions: rolesPermissions }) }), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: handleClose, autoFocus: true }, { children: t('global.buttons.close') })) })] }))] }));
}
