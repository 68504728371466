/**
 * Constants, hooks and components used across the accounts components
 */
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { OGPseudoTableCell } from '@opengear/table';
import mapValues from 'lodash/mapValues';
var EXPANDER_COLUMN_MIN_WIDTH = '56px';
var NAME_COLUMN_MIN_WIDTH = '280px';
var PERMISSION_COLUMN_MIN_WIDTH = '112px';
var PERMISSION_COLUMN_WIDTH = '15%';
export function createBarStyles(theme) {
    return { marginTop: 0.5, marginX: 'auto', width: "calc(".concat(PERMISSION_COLUMN_MIN_WIDTH, " - ").concat(theme.spacing(4), ")") };
}
export function useFormatPermission() {
    var t = useTranslation().t;
    return useCallback(function (rolePermission) {
        switch (rolePermission) {
            case 'on':
                return t('global.permission.on');
            case 'off':
                return t('global.permission.off');
            case 'read_write':
                return t('global.permission.read_write');
            case 'read_only':
                return t('global.permission.read_only');
            default:
                return t('global.permission.deny');
        }
    }, [t]);
}
/**
 * convert data with type IGroupsAndRolesPermissions that is get from permissions/groups endpoint to
 * data with type IPermissions we can use in PermissionsTable, to reuse the component
 * @param permissions with type IGroupsAndRolesPermissions
 * @returns permissions with type IPermissions
 */
export function convertPermissionsData(permissions) {
    return mapValues(permissions, function (o) { return mapValues(o, function (obj) { return obj.overall; }); });
}
export var ExpandRowTableCell = styled(OGPseudoTableCell)({
    minWidth: EXPANDER_COLUMN_MIN_WIDTH,
});
export var NameCell = styled(OGPseudoTableCell)({
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    minWidth: NAME_COLUMN_MIN_WIDTH,
    width: "calc(100% - 3 * ".concat(PERMISSION_COLUMN_WIDTH, " - ").concat(EXPANDER_COLUMN_MIN_WIDTH, ")"),
});
export var PermissionCell = styled(OGPseudoTableCell)({
    justifyContent: 'center',
    minWidth: PERMISSION_COLUMN_MIN_WIDTH,
    width: PERMISSION_COLUMN_WIDTH,
});
export var PermissionsCell = styled(OGPseudoTableCell)({
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: "calc(3 * ".concat(PERMISSION_COLUMN_MIN_WIDTH, ")"),
    width: "calc(3 * ".concat(PERMISSION_COLUMN_WIDTH, ")"),
});
export var SubExpandRowCell = styled(OGPseudoTableCell)({
    justifyContent: 'right',
    minWidth: "calc(2 * ".concat(EXPANDER_COLUMN_MIN_WIDTH, ")"),
});
export var SubNameCell = styled(OGPseudoTableCell)({
    minWidth: "calc(".concat(NAME_COLUMN_MIN_WIDTH, " - ").concat(EXPANDER_COLUMN_MIN_WIDTH, ")"),
    width: "calc(100% - 3 * ".concat(PERMISSION_COLUMN_WIDTH, " - 2 * ").concat(EXPANDER_COLUMN_MIN_WIDTH, ")"),
});
export function getGroupRoleNames(group) {
    switch (group.mode) {
        case 'global':
            return group.global_roles;
        case 'smart_group':
            return group.smart_group_roles;
        default:
            return undefined;
    }
}
