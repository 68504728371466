var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
export function ConfirmationModal(_a) {
    var title = _a.title, confirmLabel = _a.confirmLabel, open = _a.open, disabled = _a.disabled, onClose = _a.onClose, onConfirm = _a.onConfirm, children = _a.children;
    var t = useTranslation().t;
    return (_jsxs(OGDialog, __assign({ open: open, onClose: onClose }, { children: [_jsx(DialogTitle, { children: title }), _jsx(DialogContent, { children: children }), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: onClose }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ autoFocus: true, disabled: disabled, onClick: onConfirm }, { children: confirmLabel }))] })] })));
}
