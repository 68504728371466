var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { HTTPS_KEY_LENGTH_OPTIONS } from './constants';
export function transformResponseToFormState(res, t) {
    var _a, _b, _c, _d, _e, _f;
    var country = null;
    if (res.csr.country === null) {
        var firstCode = Object.entries(t('global.country_codes'))[0];
        var _g = __read(firstCode, 2), code = _g[0], label = _g[1];
        country = { code: code, label: label };
    }
    else {
        country = { label: t("global.country_codes.".concat(res.csr.country), { defaultValue: res.csr.country }), code: res.csr.country };
    }
    return {
        common_name: (_a = res.csr.common_name) !== null && _a !== void 0 ? _a : '',
        csr: res.csr.csr,
        email: (_b = res.csr.email) !== null && _b !== void 0 ? _b : '',
        locality: (_c = res.csr.locality) !== null && _c !== void 0 ? _c : '',
        org_unit: (_d = res.csr.org_unit) !== null && _d !== void 0 ? _d : '',
        organization: (_e = res.csr.organization) !== null && _e !== void 0 ? _e : '',
        state: (_f = res.csr.state) !== null && _f !== void 0 ? _f : '',
        country: country,
        key_length: res.csr.key_length !== null ? res.csr.key_length.toString() : HTTPS_KEY_LENGTH_OPTIONS[0],
        password: '',
        confirm_password: '',
        private_key: null,
        certfile: null,
    };
}
export function transformFormToNewRequest(formstate) {
    return {
        csr: {
            csr: 'new',
            common_name: formstate.common_name,
            country: formstate.country.code,
            email: formstate.email,
            key_length: Number(formstate.key_length),
            locality: formstate.locality,
            org_unit: formstate.org_unit,
            organization: formstate.organization,
            state: formstate.state,
            challenge_password: formstate.password === '' ? null : formstate.password,
            private_key: formstate.private_key,
        },
    };
}
