var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useDeviceView } from '@/hooks/use-device-view';
import { capacityFromCidr, capacityFromSmfCidr } from '@/utils/calculations';
import { useFormContainerContext } from '@/components/layout/forms';
import { invalidFieldError, maxLengthFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN, IPV4_PATTERN, NUM_ONLY_PATTERN } from '@/constants/regex';
// Field validation limits
export var DescriptionMaxLength = 128;
export var NetworkPortMinVal = 1;
export var NetworkPortMaxVal = 65535;
export var VpnCidrMinVal = 0;
export var VpnCidrMaxVal = 29;
// Different Form Controls
export function DescriptionField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionMaxLength,
                message: maxLengthFieldError(t('pages.secondary_lighthouse.form.fields.description.label'), DescriptionMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "description", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.secondary_lighthouse.form.fields.description.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field, { disabled: !permissions.hasEditPermission })));
        } }));
}
export function NetworkAddressField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "address", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.network_address.label')),
            pattern: {
                value: HOSTNAME_OR_ADDRESS_PATTERN,
                message: invalidFieldError(t('pages.secondary_lighthouse.form.fields.network_address.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "network_address", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.secondary_lighthouse.form.fields.network_address.label'), _jsx(OGIconTooltip, { title: t('pages.secondary_lighthouse.form.fields.network_address.tooltip') })] }), sx: { maxWidth: isMobileView ? undefined : 400 } }, field, { required: true })));
        } }));
}
export function NetworkPortField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "port", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.network_port.label')),
            min: {
                value: NetworkPortMinVal,
                message: minValueFieldError(t('pages.secondary_lighthouse.form.fields.network_port.label'), NetworkPortMinVal.toString()),
            },
            max: {
                value: NetworkPortMaxVal,
                message: maxValueFieldError(t('pages.secondary_lighthouse.form.fields.network_port.label'), NetworkPortMaxVal.toString()),
            },
            // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
            pattern: {
                value: NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.secondary_lighthouse.form.fields.network_port.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "network_port", type: "number", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.secondary_lighthouse.form.fields.network_port.label'), _jsx(OGIconTooltip, { title: t('pages.secondary_lighthouse.form.fields.network_port.tooltip') })] }), sx: { maxWidth: isMobileView ? undefined : 400 } }, field, { required: true })));
        } }));
}
export function UsernameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "username", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.username.label')),
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "username", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.secondary_lighthouse.form.fields.username.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field, { required: true })));
        } }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "password", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.password.label')),
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.secondary_lighthouse.form.fields.password.label'), type: "password", sx: { maxWidth: isMobileView ? undefined : 400 } }, field, { required: true })));
        } }));
}
export function VpnAddressField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "vpn_address", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.address_space.label')),
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.address_space.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "vpn-address", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.address_space.label'), required: true, sx: { width: isMobileView ? undefined : 200, marginRight: isMobileView ? undefined : 2 } }, field, { disabled: !permissions.hasEditPermission })));
        } }));
}
export function VpnCidrField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "cidr", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.cidr_mask.label')),
            max: {
                value: VpnCidrMaxVal,
                message: maxValueFieldError(t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.cidr_mask.label'), VpnCidrMaxVal.toString()),
            },
            min: {
                value: VpnCidrMinVal,
                message: minValueFieldError(t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.cidr_mask.label'), VpnCidrMinVal.toString()),
            },
            // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
            pattern: {
                value: NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.cidr_mask.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "vpn-cidr-subnet-mask", "aria-controls": "vpn-address-capacity", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.cidr_mask.label'), inputProps: { min: 0, max: 29 }, type: "number", required: true, sx: { width: isMobileView ? undefined : 200, marginRight: isMobileView ? undefined : 2 } }, field, { disabled: !permissions.hasEditPermission })));
        } }));
}
export function VpnCapacityField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var watch = useFormContainerContext().watch;
    var cidr = watch('cidr');
    return (_jsx(TextField, { id: "vpn-address-capacity", "aria-live": "polite", disabled: true, label: t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.calculated_capacity.label'), value: capacityFromCidr(cidr), sx: { width: isMobileView ? undefined : 200 } }));
}
export function SmfAddressField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "smf_address", rules: {
            required: requiredFieldError(t('pages.secondary_lighthouse.form.fields.smf_network_range_section.address_space.label')),
            pattern: {
                value: IPV4_PATTERN,
                message: invalidFieldError(t('pages.secondary_lighthouse.form.fields.smf_network_range_section.address_space.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "smf-address", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.secondary_lighthouse.form.fields.smf_network_range_section.address_space.label'), required: true, sx: { width: isMobileView ? undefined : 200, marginRight: isMobileView ? undefined : 2 } }, field, { disabled: !permissions.hasEditPermission })));
        } }));
}
export function SmfCidrField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "smf_mask", render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ id: "smf-cidr-subnet-mask", disabled: true, label: t('pages.secondary_lighthouse.form.fields.smf_network_range_section.cidr_mask.label'), sx: { width: isMobileView ? undefined : 200, marginRight: isMobileView ? undefined : 2 } }, field)));
        } }));
}
export function SmfCapacityField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var watch = useFormContainerContext().watch;
    var smfMask = watch('smf_mask');
    return (_jsx(TextField, { id: "smf-address-capacity", disabled: true, label: _jsxs(_Fragment, { children: [t('pages.lighthouse_vpn.form.fields.capacity.label'), _jsx(OGIconTooltip, { title: t('pages.lighthouse_vpn.form.fields.smf_capacity.description') })] }), value: capacityFromSmfCidr(smfMask), sx: { maxWidth: isMobileView ? undefined : 200 } }));
}
