var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useFormContainerContext } from '@/components/layout/forms';
import { NodeFilterQuery } from '@/containers/filters/advanced-filters/node-filter';
import { decodeFilterQuery } from '../../../../../types/query';
export function NodeFilterNameField() {
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: t('components.nodes.filter.errors.name.required'),
            maxLength: { value: 128, message: t('components.nodes.filter.errors.name.too_long') },
            validate: function (value) { return (value === t('components.nodes.filter.select.new') ? t('components.nodes.filter.errors.name.invalid') : undefined); },
        }, render: function (_a) {
            var _b;
            var _c = _a.field, ref = _c.ref, field = __rest(_c, ["ref"]), fieldState = _a.fieldState;
            return (_jsx(TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            , __assign({ 
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, inputRef: ref, label: t('components.nodes.filter.name_label'), fullWidth: true, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function NodeFilterQueryFieldset() {
    var _a = useFormContainerContext(), watch = _a.watch, register = _a.register, setValue = _a.setValue;
    var queryObject = watch('queryObject');
    register('queryObject', {
        validate: function (value) { return !!decodeFilterQuery(value); },
    });
    var onQueryObjectChange = useCallback(function (newQueryObject) {
        setValue('queryObject', newQueryObject, { shouldValidate: true, shouldDirty: true });
    }, [setValue]);
    return _jsx(NodeFilterQuery, { query: queryObject, onChange: onQueryObjectChange });
}
