var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import format from 'string-format';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useNode } from '@/api/nodes';
import { updateLastEvent } from '@/hooks/activity-timeout';
import { useCustomIFrameCSS } from '@/hooks/use-custom-iframe-css';
import { ttydIFrameCSS } from '@/constants/ttyd';
function PortsTerminal() {
    var t = useTranslation().t;
    var _a = useParams(), nodeId = _a.nodeId, portId = _a.portId;
    var nodeResponse = useNode(nodeId).data;
    var title = useMemo(function () {
        var _a;
        if (!(nodeResponse === null || nodeResponse === void 0 ? void 0 : nodeResponse.node)) {
            return t('pages.web_terminal.title');
        }
        var port = nodeResponse.node.ports.find(function (_a) {
            var port_csid = _a.port_csid;
            return portId === port_csid;
        });
        return format(t('pages.web_terminal.title_template'), nodeResponse.node.name, (_a = port === null || port === void 0 ? void 0 : port.label) !== null && _a !== void 0 ? _a : portId);
    }, [nodeResponse, t, portId]);
    useDocTitle(t('pages.web_terminal.title'));
    var iFrameRef = useRef(null);
    var tabLast = useRef(0); // Last activity timestamp for this tab/window
    // Watches for local iframe state and adds / removes escape listener
    useEffect(function () {
        var _a;
        var iFrame = iFrameRef.current;
        if (!iFrame)
            return undefined;
        function keydownListener(event) {
            // Exit terminal
            if (event.altKey && !event.ctrlKey && !event.shiftKey && (event.key === 'x' || event.code === 'KeyX')) {
                var dashboardNavItem = document.querySelector('#dashboard');
                if (dashboardNavItem)
                    dashboardNavItem.focus();
            }
            // Update last event in local storage
            updateLastEvent(tabLast.current, function (time) {
                tabLast.current = time;
            });
        }
        (_a = iFrame.contentWindow) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', keydownListener);
        return function () {
            var _a;
            (_a = iFrame.contentWindow) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', keydownListener);
        };
    }, []);
    useCustomIFrameCSS(iFrameRef, ttydIFrameCSS);
    return (_jsxs(Box, __assign({ sx: { padding: 2, display: 'flex', flexDirection: 'column', height: function (theme) { return "calc(100vh - ".concat(theme.mixins.toolbar.minHeight, "px)"); } } }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: title })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, alignItems: 'center', marginBottom: 2 } }, { children: [_jsx(InfoIcon, { fontSize: "medium" }), _jsx(Typography, { children: t('pages.web_terminal.sub_heading') })] })), _jsx(Box, __assign({ sx: { minHeight: '300px', flexGrow: 1 } }, { children: _jsx("iframe", { ref: iFrameRef, id: "local-terminal", title: t('pages.web_terminal.title'), width: "100%", height: "100%", src: "/terminal_managed_devices?node_id=".concat(nodeId, "&port_id=").concat(portId) }) }))] })));
}
export default withAuthenticationRequired(PortsTerminal, { rights: ['manage.managed_devices.console_gateway.web_terminal'] });
