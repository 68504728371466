var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, forwardRef, useEffect } from 'react';
import format from 'string-format';
import Box from '@mui/material/Box';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { OGButton } from '@opengear/buttons';
var ReOrderButton = forwardRef(function (props, ref) {
    var index = props.index, rowCount = props.rowCount, direction = props.direction, buttonProps = __rest(props, ["index", "rowCount", "direction"]);
    var isDisabled = (direction === 'up' && index === 0) || (direction === 'down' && index === rowCount - 1);
    var button = (_jsx(OGButton, __assign({ ref: ref, sx: { minHeight: 21, minWidth: 20, padding: 0 }, variant: "borderless", "aria-live": "polite" }, buttonProps, { children: direction === 'up' ? _jsx(ArrowUpwardIcon, { fontSize: "medium" }) : _jsx(ArrowDownwardIcon, { fontSize: "medium" }) })));
    return isDisabled ? null : button;
});
export function ReorderCell(_a) {
    var index = _a.index, tabIndex = _a.tabIndex, labelName = _a.labelName, rowCount = _a.rowCount, onMove = _a.onMove, translation = _a.translation;
    var activeReorderRow = useRef(false);
    var reorderUpButton = useRef(null);
    var reorderDownButton = useRef(null);
    var currentPosition = index + 1;
    var lastPosition = rowCount - 1;
    var label = function (newPosition) { return format(translation, labelName, "".concat(currentPosition), "".concat(newPosition)); };
    var handleMove = useCallback(function (direction) {
        activeReorderRow.current = true;
        var newIndex = direction === 'up' ? index - 1 : index + 1;
        onMove(index, newIndex);
    }, [onMove, index]);
    useEffect(function () {
        if (index === 0 && activeReorderRow.current && reorderDownButton.current) {
            reorderDownButton.current.focus();
        }
        else if (index === lastPosition && activeReorderRow.current && reorderUpButton.current) {
            reorderUpButton.current.focus();
        }
        activeReorderRow.current = false;
    }, [index, lastPosition]);
    return (_jsxs(Box, { children: [_jsx(ReOrderButton, { direction: "up", onClick: function () { return handleMove('up'); }, index: index, rowCount: rowCount, ref: reorderUpButton, tabIndex: tabIndex, "aria-label": label(index) }), _jsx(ReOrderButton, { direction: "down", onClick: function () { return handleMove('down'); }, index: index, rowCount: rowCount, ref: reorderDownButton, tabIndex: tabIndex, "aria-label": label(index + 2) })] }));
}
