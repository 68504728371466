import { screen } from '@testing-library/react';
import { t } from 'i18next';
export var confirmationModalTestUtils = function (title, confirmLabel) { return ({
    dialog: {
        get: function () { return screen.getByRole('dialog'); },
    },
    title: {
        get: function () { return screen.getByText(title); },
        query: function () { return screen.queryByText(title); },
    },
    buttons: {
        cancel: {
            get: function () { return screen.getByRole('button', { name: t('global.buttons.cancel') }); },
        },
        confirm: {
            get: function () { return screen.getByRole('button', { name: confirmLabel }); },
        },
    },
}); };
var translations = {
    cancel: t('global.buttons.cancel'),
    delete: t('global.buttons.delete'),
};
export var deleteConfirmationModalTestUtils = function (deleteButtonLabel) { return ({
    buttons: {
        delete: {
            get: function () { return screen.getByRole('button', { name: deleteButtonLabel }); },
            query: function () { return screen.queryByRole('button', { name: deleteButtonLabel }); },
        },
        confirm: {
            get: function () { return screen.getByRole('button', { name: translations.delete }); },
            query: function () { return screen.queryByRole('button', { name: translations.delete }); },
        },
        cancel: {
            get: function () { return screen.getByRole('button', { name: translations.cancel }); },
            query: function () { return screen.queryByRole('button', { name: translations.cancel }); },
        },
    },
}); };
