var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { SimpleStatus } from './utils';
import { formatErrorMessage } from '@/utils/errors';
import { decodeEnrollNodeResponse, decodeReadNodesResponse } from '../../types/lighthouse/decoders/nodes';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { decodeDeleteNodeResponse, decodeUpdateNodeResponse, decodeReadNodeResponse } from '../../types/lighthouse/decoders/nodes/node-id';
import { CELL_HEALTH_SUMMARY_PATH } from './stats/nodes/cell-health-summary';
import { CONNECTION_SUMMARY_PATH } from './stats/nodes/connection-summary';
import { NODE_BULK_UPDATE_BATCH_SIZE } from '@/constants/limits';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
import { useSearchNodesQueryParams } from '@/api/search/nodes';
export var NODES_PATH = '/nodes';
export function formatNodePath(nodeId) {
    if (nodeId === void 0) { nodeId = ':nodeId'; }
    return "".concat(NODES_PATH, "/").concat(nodeId);
}
export var nodeQueryKeys = {
    all: [NODES_PATH],
    bulk: function () { return __spreadArray(__spreadArray([], __read(nodeQueryKeys.all), false), ['bulk'], false); },
    bulkWithQuery: function (queryParams) { return __spreadArray(__spreadArray([], __read(nodeQueryKeys.bulk()), false), [queryParams], false); },
    single: function (id) { return __spreadArray(__spreadArray([], __read(nodeQueryKeys.all), false), [id], false); },
};
export function useEnrollNode() {
    var _this = this;
    var alertNotification = useAlertNotification().alertNotification;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse;
    var t = useTranslation().t;
    return useCallback(function (enrollment) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(NODES_PATH, { method: 'POST', body: JSON.stringify({ enrollment: enrollment }) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeEnrollNodeResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    switch (status) {
                        case 200:
                            /* istanbul ignore next */
                            if (data.node.status !== 'Pending') {
                                alertNotification(t('api.nodes.post.failed.message'), {
                                    header: t('api.nodes.post.failed.header'),
                                });
                                return [2 /*return*/, SimpleStatus.Failed];
                            }
                            alertNotification(t('api.nodes.post.ok.message'), {
                                header: t('api.nodes.post.ok.header'),
                                priority: OGToastPriorities.Success,
                                persistOnUnmount: true,
                            });
                            return [2 /*return*/, SimpleStatus.Success];
                        case 400:
                            alertNotification('preflight_failed' in data ? data.preflight_failed : t('api.nodes.post.failed.message'), {
                                header: t('api.nodes.post.failed.header'),
                            });
                            return [2 /*return*/, SimpleStatus.Failed];
                        default:
                            alertNotification(formatErrorMessage(data), {
                                header: t('api.nodes.post.error.message'),
                            });
                            return [2 /*return*/, SimpleStatus.Error];
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [executeFetch, decodeResponse, alertNotification, t]);
}
/**
 * Hook to fetch nodes data
 */
export function useNodes(queryParams, _a) {
    var _this = this;
    if (queryParams === void 0) { queryParams = {}; }
    var _b = _a === void 0 ? {} : _a, enabled = _b.enabled;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var searchNodesQueryParams = useSearchNodesQueryParams();
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    var fetchNodes = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var updatedQueryParams, response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, searchNodesQueryParams(queryParams)];
                case 1:
                    updatedQueryParams = _b.sent();
                    return [4 /*yield*/, executeFetch(NODES_PATH, __assign({ queryParams: updatedQueryParams }, context))];
                case 2:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadNodesResponse, response)];
                case 3:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.nodes.get'));
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [CONNECTION_SUMMARY_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, queryParams, searchNodesQueryParams, t]);
    return useQuery({
        queryKey: nodeQueryKeys.bulkWithQuery(queryParams),
        queryFn: fetchNodes,
        enabled: enabled,
    });
}
export function useNode(nodeId, _a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a, notFoundCallback = _b.notFoundCallback;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchNode = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatNodePath(nodeId), __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadNodeResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = __assign(__assign({}, t('api.node.get')), { not_found: { message: format(t('api.node.get.not_found.message'), nodeId) } });
                    handleCommonStatusCodes(data, status, translations);
                    if ((status === 403 || status === 404) && notFoundCallback) {
                        notFoundCallback(status);
                    }
                    return [2 /*return*/, status === 200 ? data : null];
            }
        });
    }); }, [executeFetch, nodeId, decodeResponse, t, handleCommonStatusCodes, notFoundCallback]);
    return useQuery({
        queryKey: nodeQueryKeys.single(nodeId),
        queryFn: fetchNode,
    });
}
export function useBulkUpdateNode() {
    var _this = this;
    var closeRef = useAlertNotification().closeRef;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    var updateNode = useCallback(function (_a) { return __awaiter(_this, void 0, Promise, function () {
        var response, status;
        var id = _a.id, node = __rest(_a, ["id"]);
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatNodePath(id), { method: 'PUT', body: JSON.stringify({ node: node }) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateNodeResponse, response)];
                case 2:
                    status = (_b.sent()).status;
                    switch (status) {
                        case 200:
                            return [2 /*return*/, { node_uuid: id, status: 'success' }];
                        case 403:
                            return [2 /*return*/, { node_uuid: id, status: 'error', error_message: t('api.node.put.forbidden.message') }];
                        case 404:
                            return [2 /*return*/, { node_uuid: id, status: 'error', error_message: format(t('api.node.put.not_found.message'), id) }];
                        default:
                            return [2 /*return*/, { node_uuid: id, status: 'error', error_message: format(t('api.node.put.error.message'), id) }];
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [executeFetch, decodeResponse, t]);
    return useCallback(function (nodes) { return __awaiter(_this, void 0, Promise, function () {
        var subscriptionsSelection, batches, i, batch, results, successCount;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // will close previous alert
                    closeRef.current();
                    subscriptionsSelection = nodes.map(function (node) { return function () { return __awaiter(_this, void 0, void 0, function () {
                        var id, updateData, result;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    id = node.id, updateData = __rest(node, ["id"]);
                                    return [4 /*yield*/, updateNode(__assign({ id: id }, updateData))];
                                case 1:
                                    result = _a.sent();
                                    return [2 /*return*/, result];
                            }
                        });
                    }); }; });
                    batches = [];
                    for (i = 0; i < subscriptionsSelection.length; i += NODE_BULK_UPDATE_BATCH_SIZE) {
                        batch = subscriptionsSelection.slice(i, i + NODE_BULK_UPDATE_BATCH_SIZE);
                        batches.push(batch);
                    }
                    return [4 /*yield*/, Promise.all(batches.map(function (batch) { return __awaiter(_this, void 0, void 0, function () {
                            var result;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, Promise.all(batch.map(function (promise) { return promise(); }))];
                                    case 1:
                                        result = _a.sent();
                                        return [2 /*return*/, result];
                                }
                            });
                        }); })).then(function (res) { return res.flat(); })
                        // Clear the cache
                    ];
                case 1:
                    results = _a.sent();
                    // Clear the cache
                    queryClient
                        .invalidateQueries({
                        queryKey: nodeQueryKeys.all,
                    })
                        .catch(console.error);
                    queryClient
                        .invalidateQueries({
                        queryKey: [CELL_HEALTH_SUMMARY_PATH],
                    })
                        .catch(console.error);
                    successCount = results.filter(function (res) { return res.status === 'success'; }).length;
                    return [2 /*return*/, {
                            total: results.length,
                            job_id: 'bulk_assignment',
                            number_of_successes: successCount,
                            results: results,
                        }];
            }
        });
    }); }, [updateNode, queryClient, closeRef]);
}
export function useUpdateNode() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    return useCallback(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var response, _b, data, status, translations;
        var id = _a.id, node = __rest(_a, ["id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, executeFetch(formatNodePath(id), { method: 'PUT', body: JSON.stringify({ node: node }) })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateNodeResponse, response)];
                case 2:
                    _b = _c.sent(), data = _b.data, status = _b.status;
                    translations = __assign(__assign({}, t('api.node.put')), { error: { message: format(t('api.node.put.error.message'), id) }, not_found: { message: format(t('api.node.put.not_found.message'), id) }, ok: { header: t('api.node.put.ok.header'), message: format(t('api.node.put.ok.message'), id) } });
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: nodeQueryKeys.single(id),
                        })
                            .catch(console.error);
                        queryClient
                            .invalidateQueries({
                            queryKey: nodeQueryKeys.bulk(),
                        })
                            .catch(console.error);
                        queryClient
                            .invalidateQueries({
                            queryKey: [CELL_HEALTH_SUMMARY_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useDeleteNode() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    return useCallback(function (nodeId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatNodePath(nodeId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteNodeResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = __assign(__assign({}, t('api.node.delete')), { error: { message: format(t('api.node.delete.error.message'), nodeId) }, not_found: { message: format(t('api.node.delete.not_found.message'), nodeId) }, ok: { header: t('api.node.delete.ok.header'), message: format(t('api.node.delete.ok.message'), nodeId) } });
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 204) {
                        queryClient
                            .invalidateQueries({
                            queryKey: nodeQueryKeys.single(nodeId),
                        })
                            .catch(console.error);
                        queryClient
                            .invalidateQueries({
                            queryKey: nodeQueryKeys.bulk(),
                        })
                            .catch(console.error);
                        queryClient
                            .invalidateQueries({
                            queryKey: [CELL_HEALTH_SUMMARY_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
