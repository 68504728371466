var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { CopyButton } from '@/components/copy-button';
export function InvalidQueryContent(_a) {
    var query = _a.query;
    var t = useTranslation().t;
    return (_jsxs(Box, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(WarningIcon, { fontSize: "medium", color: "warning", sx: { marginRight: 1 } }), _jsx(Typography, __assign({ component: "span", variant: "body1" }, { children: t('components.query.invalid_query.message') }))] })), _jsx(CopyButton, { content: query, tooltipPlacement: "right", autoFocus: true })] })), _jsx(Box, __assign({ "aria-label": t('components.query.invalid_query.label'), bgcolor: "background.disabled", padding: 2, marginY: 2, maxWidth: "500px" }, { children: _jsx(Typography, { children: query }) }))] }));
}
