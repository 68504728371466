var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { decodeCreateUserResponse, decodeReadUsersResponse } from '../../types/lighthouse/decoders/users';
import { decodeDeleteUserResponse, decodeReadUserResponse, decodeUpdateUserResponse } from '../../types/lighthouse/decoders/users/user-id';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var USERS_PATH = '/users';
export function formatUserPath(userId) {
    if (userId === void 0) { userId = ':userId'; }
    return "".concat(USERS_PATH, "/").concat(userId);
}
export function useUsers(queryParams) {
    var _this = this;
    if (queryParams === void 0) { queryParams = {}; }
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    var fetchUsers = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(USERS_PATH, __assign({ queryParams: queryParams }, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadUsersResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.users.get'));
                    if (status === 200) {
                        data.users.forEach(function (user) {
                            queryClient.setQueryData([USERS_PATH, user.id], user);
                        });
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, queryParams, t]);
    return useQuery({
        queryKey: [USERS_PATH, queryParams],
        queryFn: fetchUsers,
    });
}
export function useCreateUser() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (user) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(USERS_PATH, { method: 'POST', body: JSON.stringify({ user: user }) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeCreateUserResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.users.post'));
                    if (!(status === 201)) return [3 /*break*/, 4];
                    return [4 /*yield*/, queryClient
                            .invalidateQueries({
                            queryKey: [USERS_PATH],
                        })
                            .catch(console.error)];
                case 3:
                    _b.sent();
                    queryClient.setQueryData([USERS_PATH, data.user.id], data.user);
                    return [2 /*return*/, data.user];
                case 4: return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useUser(userId, _a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a, notFoundCallback = _b.notFoundCallback;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchUser = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatUserPath(userId), __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadUserResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        error: { message: format(t('api.user.get.error.message'), userId || '') },
                        forbidden: { message: format(t('api.user.get.forbidden.message'), userId || '') },
                        not_found: { message: format(t('api.user.get.not_found.message'), userId || '') },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if ((status === 403 || status === 404) && notFoundCallback) {
                        notFoundCallback(status);
                    }
                    return [2 /*return*/, status === 200 ? data.user : null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, notFoundCallback, t, userId]);
    return useQuery({
        queryKey: [USERS_PATH, userId],
        queryFn: fetchUser,
        enabled: !!userId,
    });
}
export function useUpdateUser() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var response, _b, data, status, translations;
        var userId = _a.id, user = __rest(_a, ["id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, executeFetch(formatUserPath(userId), { method: 'PUT', body: JSON.stringify({ user: user }) })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateUserResponse, response)];
                case 2:
                    _b = _c.sent(), data = _b.data, status = _b.status;
                    translations = {
                        error: { message: format(t('api.user.put.error.message'), userId) },
                        forbidden: { message: format(t('api.user.put.forbidden.message'), userId) },
                        not_found: { message: format(t('api.user.put.not_found.message'), userId) },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (!(status === 200)) return [3 /*break*/, 4];
                    return [4 /*yield*/, queryClient
                            .invalidateQueries({
                            queryKey: [USERS_PATH],
                        })
                            .catch(console.error)];
                case 3:
                    _c.sent();
                    queryClient.setQueryData([USERS_PATH, data.user.id], data.user);
                    return [2 /*return*/, data.user];
                case 4: return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useDeleteUser() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (userId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatUserPath(userId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteUserResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        error: { message: format(t('api.user.delete.error.message'), userId) },
                        forbidden: { message: format(t('api.user.delete.forbidden.message'), userId) },
                        not_found: { message: format(t('api.user.delete.not_found.message'), userId) },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 204) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [USERS_PATH],
                        })
                            .catch(console.error);
                        queryClient.removeQueries({
                            queryKey: [USERS_PATH, userId],
                        });
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
