import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NEW_FILTER_ID } from '@/utils/filter';
import { decodeFilterQuery, decodeQuery } from '../../../../../types/query';
import { resourceTagNameToQuery } from '@/utils/query';
import { IQueryType } from '../../../../../types/query/types';
/**
 * Type guard to check if the filter is a valid resource filter
 * TODO: CDM-1147 - Replace common decodeFilterQuery with separate validator function based on query schema
 */
export function isResourceQueryValid(filter) {
    return !!decodeFilterQuery(filter.queryObject);
}
/**
 * Check if resource filter is an ad hoc filter
 */
export function isResourceFilterNew(filter) {
    return filter.id === NEW_FILTER_ID;
}
/**
 * Check if resource filters match
 */
export function checkResourceFiltersMatch(filterA, filterB) {
    return filterA.id === filterB.id;
}
/**
 * Evaluate if the selected filter is applied
 */
export function checkSelectedFilterApplied(selectedFilter, filter) {
    return !!selectedFilter && !!filter && checkResourceFiltersMatch(selectedFilter, filter) && !isResourceFilterNew(filter);
}
/**
 * Evaluate if user can apply the selected filter
 */
export function canApplySelectedFilter(selectedFilter, filter) {
    // User cannot apply an empty filter if is no applied filter
    if (!selectedFilter && !filter)
        return false;
    // User cannot apply selected filter if it matches the applied filter
    if (checkSelectedFilterApplied(selectedFilter, filter))
        return false;
    // User cannot apply a new filter if its invalid. All other invalid filters can be applied.
    if (!!selectedFilter && isResourceFilterNew(selectedFilter) && !isResourceQueryValid(selectedFilter))
        return false;
    // All other cases, the user can apply the filter
    return true;
}
/**
 * Parse and validate the json search params from the URL
 */
export function parseResourceFilterSearchParam(jsonString) {
    if (jsonString === null)
        return null;
    try {
        var filter = JSON.parse(jsonString);
        // Light validation to ensure the filter is a valid resource filter
        if (!filter || typeof filter !== 'object' || !('queryObject' in filter) || !decodeQuery(filter.queryObject))
            return null;
        return filter;
    }
    catch (err) {
        /* istanbul ignore next */
        return null;
    }
}
/**
 * Format resource filter for search params
 */
export function formatResourceFilterSearchParam(filter) {
    try {
        return JSON.stringify(filter);
    }
    catch (err) {
        /* istanbul ignore next */
        return '';
    }
}
/**
 * Verify resource filter from smartgroups
 * This is to prevent the filter from being out of "sync" if the filter is deleted or changed.
 */
export function verifyResourceFilter(filter, smartgroups) {
    // Leave filter as is, when filter is new or smartgroups are undefined
    if (!filter || isResourceFilterNew(filter) || !smartgroups)
        return filter;
    var foundFilter = smartgroups.find(function (smartgroup) { return checkResourceFiltersMatch(filter, smartgroup); });
    return !foundFilter ? null : foundFilter;
}
/**
 * Verify selected resource filter from smartgroups
 * This is to prevent the filter from being out of "sync" if the filter is deleted or changed.
 */
export function verifySelectedResourceFilter(filter, smartgroups) {
    var _a;
    // Leave filter as is, when filter is new or smartgroups are undefined
    if (!filter || isResourceFilterNew(filter) || !smartgroups)
        return filter;
    return (_a = smartgroups.find(function (smartgroup) { return checkResourceFiltersMatch(filter, smartgroup); })) !== null && _a !== void 0 ? _a : null;
}
/**
 * Create a resource filter string from a resource tag name
 */
export function useCreateResourceTagResourceFilter() {
    var t = useTranslation().t;
    return useCallback(function (resourceTagName) {
        var query = resourceTagNameToQuery(resourceTagName);
        var queryObject = {
            items: [
                {
                    items: [
                        {
                            items: [query],
                            type: IQueryType.and,
                        },
                    ],
                    type: IQueryType.or,
                },
            ],
            type: IQueryType.and,
        };
        var filter = {
            id: NEW_FILTER_ID,
            name: t('components.resources.filter.select.new'),
            query: JSON.stringify(queryObject),
            queryObject: queryObject,
            rights: { delete: false, modify: true },
        };
        try {
            return JSON.stringify(filter);
        }
        catch (err) {
            /* istanbul ignore next */
            return '';
        }
    }, [t]);
}
