import { jsx as _jsx } from "react/jsx-runtime";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import i18n from 'i18next';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
export var autoRenewIcon = _jsx(OGAutoRenewIcon, { role: "none" });
export var successIcon = _jsx(CheckCircleIcon, { color: "success", role: "none", fontSize: "medium" });
export var errorIcon = _jsx(ErrorIcon, { color: "error", role: "none", fontSize: "medium" });
export var unknownIcon = _jsx(HelpIcon, { color: "inherit", role: "none", fontSize: "medium" });
export var syncErrorIcon = _jsx(SyncProblemIcon, { color: "error", role: "none", fontSize: "medium" });
export var outOfSyncIcon = _jsx(SyncProblemIcon, { color: "warning", role: "none", fontSize: "medium" });
export var versionMismatchIcon = _jsx(SyncProblemIcon, { color: "warning", role: "none", fontSize: "medium" });
var statusIconMap = new Map([
    ['Pending', autoRenewIcon],
    ['Registering', autoRenewIcon],
    ['Registered', autoRenewIcon],
    ['Enrolling', autoRenewIcon],
    ['Enrolled', successIcon],
    ['Failed', errorIcon],
    ['Upgrading', autoRenewIcon],
    ['UpgradeFailed', errorIcon],
]);
var statusFormatMap = new Map([
    ['Pending', i18n.t('pages.secondary_lighthouses.table.status.pending')],
    ['Registering', i18n.t('pages.secondary_lighthouses.table.status.registering')],
    ['Registered', i18n.t('pages.secondary_lighthouses.table.status.registered')],
    ['Enrolling', i18n.t('pages.secondary_lighthouses.table.status.enrolling')],
    ['Enrolled', i18n.t('pages.secondary_lighthouses.table.status.enrolled')],
    ['Failed', i18n.t('pages.secondary_lighthouses.table.status.failed')],
    ['Upgrading', i18n.t('pages.secondary_lighthouses.table.status.upgrading')],
    ['UpgradeFailed', i18n.t('pages.secondary_lighthouses.table.status.upgrade_failed')],
]);
var connectionStatusFormatMap = new Map([
    ['pending', i18n.t('pages.secondary_lighthouses.table.runtime_status.connection_status.pending')],
    ['connected', i18n.t('pages.secondary_lighthouses.table.runtime_status.connection_status.connected')],
    ['disconnected', i18n.t('pages.secondary_lighthouses.table.runtime_status.connection_status.disconnected')],
    ['unknown', i18n.t('pages.secondary_lighthouses.table.runtime_status.connection_status.disconnected')],
]);
var connectionStatusIconMap = new Map([
    ['pending', autoRenewIcon],
    ['connected', successIcon],
    ['disconnected', errorIcon],
    ['unknown', unknownIcon],
]);
var actionTypeFormatMap = new Map([
    ['multi_instance_dependent_upgrader', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.type.upgrade')],
    ['enrollment', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.type.enrollment')],
    ['registration', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.type.registration')],
    ['unenrollment', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.type.unenroll_node')],
]);
var actionStatusIconMap = new Map([
    ['in progress', autoRenewIcon],
    ['finished', successIcon],
    ['error', errorIcon],
    ['unknown', unknownIcon],
]);
var actionStatusFormatMap = new Map([
    ['in progress', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.status.in_progress')],
    ['finished', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.status.finished')],
    ['error', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.status.error')],
    ['unknown', i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.status.unknown')],
]);
var replicationStatusIconMap = new Map([
    ['error', syncErrorIcon],
    ['okay', successIcon],
    ['outOfSync', outOfSyncIcon],
    ['versionMismatch', versionMismatchIcon],
    ['unknown', unknownIcon],
]);
var replicationStatusFormatMap = new Map([
    ['error', i18n.t('pages.secondary_lighthouses.table.replication_status.error')],
    ['okay', i18n.t('pages.secondary_lighthouses.table.replication_status.up_to_date')],
    ['outOfSync', i18n.t('pages.secondary_lighthouses.table.replication_status.out_of_sync')],
    ['versionMismatch', i18n.t('pages.secondary_lighthouses.table.replication_status.version_mismatch')],
    ['unknown', i18n.t('pages.secondary_lighthouses.table.replication_status.unknown')],
]);
var upgradeStatusIconMap = new Map([
    ['in progress', autoRenewIcon],
    ['finished', successIcon],
    ['error', errorIcon],
    ['unknown', unknownIcon],
]);
var upgradeStatusFormatMap = new Map([
    ['in progress', i18n.t('pages.secondary_lighthouses.table.upgrade_status.in_progress')],
    ['finished', i18n.t('pages.secondary_lighthouses.table.upgrade_status.finished')],
    ['error', i18n.t('pages.secondary_lighthouses.table.upgrade_status.failed')],
    ['retrying', i18n.t('pages.secondary_lighthouses.table.upgrade_status.retrying')],
    ['unknown', i18n.t('pages.secondary_lighthouses.table.upgrade_status.unknown')],
]);
export function getSecondaryStatus(dependentLighthouseData) {
    var _a;
    var lhStatusIcon;
    var lhStatusText;
    var isErrorStatus = false;
    if (dependentLighthouseData.status === 'Enrolled') {
        lhStatusIcon = connectionStatusIconMap.get(dependentLighthouseData.runtime_status.connection_status);
        lhStatusText = connectionStatusFormatMap.get(dependentLighthouseData.runtime_status.connection_status);
        if (dependentLighthouseData.runtime_status.connection_status === 'connected') {
            if ((_a = dependentLighthouseData.replication_errors) === null || _a === void 0 ? void 0 : _a.length) {
                isErrorStatus = true;
            }
            lhStatusIcon = replicationStatusIconMap.get(dependentLighthouseData.replication_status);
            lhStatusText = replicationStatusFormatMap.get(dependentLighthouseData.replication_status);
        }
        else if (dependentLighthouseData.runtime_status.action_error_message) {
            isErrorStatus = true;
        }
    }
    else if (dependentLighthouseData.status === 'Failed' || dependentLighthouseData.status === 'UpgradeFailed') {
        if (dependentLighthouseData.runtime_status.action_error_message) {
            isErrorStatus = true;
        }
        lhStatusIcon = statusIconMap.get(dependentLighthouseData.status);
        lhStatusText = statusFormatMap.get(dependentLighthouseData.status);
    }
    else if (dependentLighthouseData.runtime_status.action_type === 'multi_instance_dependent_upgrader') {
        lhStatusText = dependentLighthouseData.runtime_status.action_status
            ? upgradeStatusFormatMap.get(dependentLighthouseData.runtime_status.action_status)
            : upgradeStatusFormatMap.get('unknown');
        lhStatusIcon = upgradeStatusIconMap.get(dependentLighthouseData.runtime_status.action_status) || upgradeStatusIconMap.get('unknown');
        if (dependentLighthouseData.runtime_status.action_error_message) {
            if (dependentLighthouseData.runtime_status.action_status === 'in progress') {
                lhStatusText = i18n.t('pages.secondary_lighthouses.table.upgrade_status.retrying');
            }
            isErrorStatus = true;
        }
    }
    else if (dependentLighthouseData.runtime_status.action_type === 'enrollment' ||
        dependentLighthouseData.runtime_status.action_type === 'registration' ||
        dependentLighthouseData.runtime_status.action_type === 'unenrollment') {
        if (dependentLighthouseData.runtime_status.action_error_message) {
            if (dependentLighthouseData.runtime_status.action_status === 'in progress' || dependentLighthouseData.runtime_status.action_status === 'finished') {
                lhStatusIcon = autoRenewIcon;
                lhStatusText = i18n.t('pages.secondary_lighthouses.table.status.retrying');
                isErrorStatus = true;
            }
            else {
                lhStatusIcon = actionStatusIconMap.get(dependentLighthouseData.runtime_status.action_status);
                lhStatusText = actionStatusFormatMap.get(dependentLighthouseData.runtime_status.action_status);
                isErrorStatus = true;
            }
        }
        else {
            lhStatusIcon = autoRenewIcon;
            lhStatusText = "".concat(actionTypeFormatMap.get(dependentLighthouseData.runtime_status.action_type), "-").concat(actionStatusFormatMap.get(dependentLighthouseData.runtime_status.action_status));
        }
    }
    else {
        lhStatusIcon = autoRenewIcon;
        lhStatusText = i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.status.in_progress');
    }
    return [lhStatusText || i18n.t('pages.secondary_lighthouses.table.runtime_status.actions.status.unknown'), lhStatusIcon || unknownIcon, isErrorStatus];
}
