var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGFileUploader, OGFileTracker } from '@opengear/file-uploader';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { useAlertNotification, OGToastPriorities } from '@/hooks/alert-notification';
import { ONE_MB } from '@/constants/units';
import { NUM_ONLY_PATTERN } from '@/constants/regex';
import { invalidFieldError, maxLengthFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
export var NameFieldMaxLength = 64;
export var DescriptionFieldMaxLength = 128;
export var TimeoutMinVal = 1;
export var TimeoutMaxVal = 1440;
export function NameField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, mode = _a.mode;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.script.form.fields.name.label')),
            maxLength: {
                value: NameFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.script.form.fields.name.label'), NameFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "name", required: true, disabled: !permissions.hasEditPermission || mode === 'update', error: !!fieldState.error, label: t('pages.config_templates.tabs.script.form.fields.name.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.script.form.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "description", disabled: !permissions.hasEditPermission, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.config_templates.tabs.script.form.fields.description.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
        } }));
}
export function ScriptTimeoutField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "scriptTimeout", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.script.form.fields.script_timeout.label')),
            pattern: {
                value: NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.script.form.fields.script_timeout.label')),
            },
            min: {
                value: TimeoutMinVal,
                message: minValueFieldError(t('pages.config_templates.tabs.script.form.fields.script_timeout.label'), TimeoutMinVal.toString()),
            },
            max: {
                value: TimeoutMaxVal,
                message: maxValueFieldError(t('pages.config_templates.tabs.script.form.fields.script_timeout.label'), TimeoutMaxVal.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "scriptTimeout", required: true, type: "number", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.script.form.fields.script_timeout.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.script.form.fields.script_timeout.tooltip') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 200 } }, field, { onChange: function (event) {
                    field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                } })));
        } }));
}
export function ScriptUploadField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, watch = _a.watch;
    var alertNotification = useAlertNotification().alertNotification;
    var script = watch('script');
    var onChangeHandler = function (files, onChange) {
        var file = files.length ? files[0] : null;
        if (!file) {
            onChange(null);
            return;
        }
        if (file.size > ONE_MB) {
            alertNotification(t('pages.config_templates.tabs.script.form.fields.script_file.errors.file_size.message'), {
                header: t('pages.config_templates.tabs.script.form.fields.script_file.errors.file_size.header'),
                priority: OGToastPriorities.Error,
            });
            return;
        }
        onChange(file);
    };
    return (_jsx(Controller, { control: control, name: "script", rules: {
            required: t('pages.config_templates.tabs.script.form.fields.script_file.errors.required'),
        }, render: function (_a) {
            var field = _a.field;
            return (_jsx(OGFileUploader, __assign({ id: "scriptFile", accept: ".sh", ariaLabel: t('pages.config_templates.tabs.script.form.fields.script_file.labels.main'), onChange: function (files) { return onChangeHandler(files, field.onChange); }, disabled: script !== null || !permissions.hasEditPermission, required: true }, { children: script && _jsx(OGFileTracker, { id: script.name, name: script.name, onCancel: function () { return field.onChange(null); } }, script.name) })));
        } }));
}
