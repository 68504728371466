var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useMultipleInstanceLHVPN } from '@/api/multiple-instance/lhvpn';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useAlertNotification } from '@/hooks/alert-notification';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { useEntitlementsData } from '@/api/system/entitlements';
import { MultiInstanceVPNForm, MILHVPN_FORM_LOADER_ID } from '@/components/settings/mi/multi-instance-vpn-form';
import { WarningBanner } from '@/components/warning-banner';
export default withAuthenticationRequired(function () {
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var navigate = useNavigate();
    useDocTitle(t('pages.multi_instance_vpn.title'));
    // Get entitlements to check if license supports MI
    var _a = useEntitlementsData(), miSupported = _a.miSupported, isLoadingEntitlements = _a.isLoading;
    // Check if user has edit permissions
    var hasPermission = useUIPermissions().hasPermission;
    // Get multiple instance lhvpn data
    var _b = useMultipleInstanceLHVPN(), data = _b.data, isLoading = _b.isLoading;
    // Navigate to not found with an alert notifying user their license does not support MI
    useEffect(function () {
        if (!isLoadingEntitlements && !miSupported) {
            alertNotification(t('pages.multi_instance_vpn.mi_not_supported_alert'), { persistOnUnmount: true });
            navigate(NOT_FOUND_ROUTE);
        }
    }, [alertNotification, isLoadingEntitlements, miSupported, navigate, t]);
    var innerNode = null;
    if (isLoading || isLoadingEntitlements) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": MILHVPN_FORM_LOADER_ID });
    }
    if (data) {
        innerNode = (_jsxs(_Fragment, { children: [_jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.multi_instance_vpn.warning_header'), warningMessage: _jsx(Typography, { children: t('pages.multi_instance_vpn.warning_message') }), warningColor: "warning", bannerCode: "node-vpn-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }), _jsx(MultiInstanceVPNForm, { editable: hasPermission('configure.multiple_instance.lhvpn.edit'), data: data })] }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.multi_instance_vpn.title') })), innerNode] })));
}, { rights: ['configure.multiple_instance.lhvpn.view'] });
