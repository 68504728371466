var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { OGFileUploader } from '@opengear/file-uploader';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import FormLabel from '@mui/material/FormLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import { OGButton } from '@opengear/buttons';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGToastPriorities } from '@opengear/toast';
import { useAlertNotification } from '@/hooks/alert-notification';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { CertificateTable } from './ldap-certificate-table';
import { ONE_MB } from '@/constants/units';
import { requiredFieldError, maxLengthFieldError, minValueFieldError, maxValueFieldError, invalidFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN } from '@/constants/regex';
export function RemoteAuthServerFields() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, setFocus = _a.setFocus, setValue = _a.setValue, permissions = _a.permissions;
    var portFieldMinVal = 1;
    var portFieldMaxVal = 65535;
    var _b = useFieldArray({
        name: 'ldapAuthenticationServers',
        rules: { required: true },
        control: control,
    }), ldapAuthenticationServersFields = _b.fields, appendLdapAuthenticationServer = _b.append, removeLdapAuthenticationServer = _b.remove;
    var addServerHandler = useCallback(function () {
        appendLdapAuthenticationServer({ hostname: '', port: null });
        var lastItem = ldapAuthenticationServersFields.length;
        setFocus("ldapAuthenticationServers.".concat(lastItem, ".hostname"));
    }, [appendLdapAuthenticationServer, ldapAuthenticationServersFields, setFocus]);
    var removeServerHandler = useCallback(function (index) {
        if (ldapAuthenticationServersFields.length > 1) {
            var nextServer = ldapAuthenticationServersFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("ldapAuthenticationServers[".concat(nextServer, "].hostname"));
            removeLdapAuthenticationServer(index);
        }
        else {
            setFocus("ldapAuthenticationServers[0].hostname");
            setValue("ldapAuthenticationServers.".concat(index, ".hostname"), '');
            setValue("ldapAuthenticationServers.".concat(index, ".port"), null);
        }
    }, [removeLdapAuthenticationServer, ldapAuthenticationServersFields, setFocus, setValue]);
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.remote_auth_server') }), ldapAuthenticationServersFields.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row', spacing: 1, paddingTop: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "ldapAuthenticationServers.".concat(index, ".hostname"), rules: {
                            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            pattern: {
                                value: HOSTNAME_OR_ADDRESS_PATTERN,
                                message: invalidFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            },
                        }, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), error = _a.fieldState.error;
                            return (_jsx(TextField, __assign({ inputRef: ref, fullWidth: true, label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title'), disabled: !permissions.hasAuthEditAccess, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true }, field)));
                        } }), _jsxs(Stack, __assign({ direction: "row", spacing: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "ldapAuthenticationServers.".concat(index, ".port"), rules: {
                                    min: {
                                        value: portFieldMinVal,
                                        message: minValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMinVal.toString()),
                                    },
                                    max: {
                                        value: portFieldMaxVal,
                                        message: maxValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMaxVal.toString()),
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, onChange = _b.onChange, ref = _b.ref, newField = __rest(_b, ["onChange", "ref"]), error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ fullWidth: true, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.ldap_default') })] }), disabled: !permissions.hasAuthEditAccess, sx: { maxWidth: isMobileView ? undefined : 200 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref, type: "number" }, newField, { value: newField.value != null ? newField.value : '', onChange: function (e) {
                                            onChange(e.target.value ? parseInt(e.target.value, 10) : '');
                                        } })));
                                } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.button.delete'), "-").concat(index) }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.button.delete'), "-").concat(index), disabled: !permissions.hasAuthEditAccess, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return removeServerHandler(index); } }) })) }))] }), "".concat(item.id, "-port"))] }), item.id)); }), _jsx(OGButton, __assign({ disabled: !permissions.hasAuthEditAccess, id: "add-server-button", startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: addServerHandler }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.button.auth.title') }))] }));
}
export function BaseDnField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var fieldMaxLength = 512;
    return (_jsx(Controller, { control: control, name: "ldapBaseDN", rules: {
            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.base_dn.title')),
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.base_dn.title'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.base_dn.title'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.base_dn_tooltip') })] }) }, field)));
        } }));
}
export function BindDnField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var fieldMaxLength = 512;
    return (_jsx(Controller, { control: control, name: "ldapBindDN", rules: {
            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.bind_dn.title')),
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.bind_dn.title'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.bind_dn.title'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.bind_dn_tooltip') })] }) }, field)));
        } }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, trigger = _a.trigger;
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "ldapBindPassword", rules: {
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.bind_dn_password'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, type: "password", label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.bind_dn_password') }, field, { onBlur: function () {
                    field.onBlur();
                    trigger('confirmLdapBindPassword');
                } })));
        } }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirmLdapBindPassword", rules: {
            validate: {
                passwordsMatch: function (value, formValues) {
                    return formValues.ldapBindPassword === formValues.confirmLdapBindPassword || t('global.form.password_not_matched');
                },
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, type: "password", label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password_confirm'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function UsernameAttrField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "ldapUsernameAttribute", rules: {
            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.username_attr.title')),
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.username_attr.title'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.username_attr.title'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.username_attr_tooltip') })] }) }, field)));
        } }));
}
export function GroupMembershipAttrField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "ldapGroupMembershipAttribute", rules: {
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.group_membership_attr'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.group_membership_attr') }, field)));
        } }));
}
export function IgnoreReferralCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "ldapIgnoreReferrals", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasAuthEditAccess }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value || false })));
            } }), label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ignore_referrals'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ignore_referrals_tooltip') })] }) }));
}
export function SSLModeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ssl') })), _jsx(Stack, __assign({ direction: "column", gap: 1 }, { children: _jsx(Controller, { control: control, name: "ldapSslMode", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasAuthEditAccess, exclusive: true, onChange: function (event, v) {
                                if (v !== null) {
                                    onChange(v);
                                }
                            } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "ldaps_preferred" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.button.ldaps_preferred') })), _jsx(ToggleButton, __assign({ value: "ldap_only" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.button.ldap_only') })), _jsx(ToggleButton, __assign({ value: "ldaps_only" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.button.ldaps_only') }))] })));
                    } }) }))] }));
}
export function IgnoreSSLCertErrorsCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "ldapSslIgnoreCertErrors", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasAuthEditAccess }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value || false })));
            } }), label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ignore_ssl_cert_error'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ignore_ssl_cert_error_tooltip') })] }) }));
}
export function SSLCertFileUploader(_a) {
    var onSuccess = _a.onSuccess;
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var _b = useFormContainerContext(), control = _b.control, setValue = _b.setValue, watch = _b.watch, permissions = _b.permissions;
    var _c = __read(useState(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.table.actions.certificate_open')), 2), fileName = _c[0], setFileName = _c[1];
    var certificate = watch('ldapSslCaCert');
    var rows = useMemo(function () {
        if (!certificate)
            return [];
        return [
            {
                id: 'certificate',
                certificate: certificate,
                fileName: fileName,
            },
        ];
    }, [certificate, fileName]);
    var onChangeHandler = function (files) {
        var file = files.length ? files[0] : null;
        if (!file) {
            return;
        }
        if (file.size > ONE_MB) {
            alertNotification(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.errors.file.oversized_contents'), {
                header: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.errors.file.header'),
                priority: OGToastPriorities.Error,
            });
            return;
        }
        var reader = new FileReader();
        reader.onload = function (event) {
            var _a;
            var fileContent = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.result;
            if (fileContent) {
                setValue('ldapSslCaCert', fileContent, { shouldDirty: true });
                setFileName(file.name);
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            }
            else {
                alertNotification(t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.errors.file.empty_contents'), {
                    header: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.errors.file.header'),
                    priority: OGToastPriorities.Error,
                });
            }
        };
        reader.readAsText(file);
    };
    if (certificate)
        return _jsx(CertificateTable, { rows: rows });
    return (_jsx(Controller, { control: control, name: "ldapSslCaCert", render: function () { return (_jsx(OGFileUploader, { accept: ".crt, .cer, .ca-bundle, .p7b, .p7c, .p7s, .pem, .txt", id: "ldapSslCaCert", ariaLabel: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.label'), onChange: function (files) { return onChangeHandler(files); }, disabled: !permissions.hasAuthEditAccess })); } }));
}
