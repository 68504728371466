// LiPy has some scalability metrics that we need to adhere by when it comes to
// making API requests for large data sets. Exceeding these limits can cause the VM
// to be stuck. Setting this limit to 5000 globally for now.
// We can change it/bump it up or down as needed in future
export var QUERY_LIMIT = 5000;
// The refetch frequency for react-query hooks in milliseconds
export var DEFAULT_QUERY_REFETCH_FREQUENCY = 30000; // 30 seconds
export var MIN_QUERY_REFETCH_FREQUENCY = 5000; // 5 seconds
// Batch size of node update requests to the API - same limit as Ember (25)
export var NODE_BULK_UPDATE_BATCH_SIZE = 25;
