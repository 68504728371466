var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OGButtonLink } from '@opengear/buttons';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useOpen } from '@/hooks/open';
import { useNodeCellHealthDetails } from '@/api/nodes/cellhealth-runtime-details';
import { getConfiguredPorts } from '@/utils/calculations';
import { CellStatus } from '@/components/nodes/node/cell-status';
import { subscriptionTierMap } from '@/utils/license';
import { useUIPermissions } from '@/api/sessions/self/ui';
// wrapper for sidebar section
function SideBarSection(_a) {
    var children = _a.children, title = _a.title, sx = _a.sx;
    return (_jsxs(Box, __assign({ sx: __assign({ marginBottom: 3 }, sx), "aria-label": title }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", marginBottom: 2 }, { children: title })), children] })));
}
// single row in a sidebar section. nodes/node-id/cellhealth_runtime_details endpoint returns null for undefined values. null values are
// not displayed. If the value is undefined the row is considered a subtitle and ":" is not applied after the name
function SideBarRow(_a) {
    var name = _a.name, value = _a.value, style = _a.style;
    return value !== null ? (_jsxs("span", __assign({ style: __assign({ width: '100%', display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }, style) }, { children: [_jsxs("span", __assign({ style: { width: '150px' } }, { children: ["".concat(name), " ", value !== undefined && ':'] })), value !== undefined && _jsx("span", { children: value })] }))) : null;
}
// Linear indicator that graphically presents configured and unconfigured ports
function PortConfiguration(_a) {
    var node = _a.node;
    var t = useTranslation().t;
    var _b = getConfiguredPorts(node.ports), configurePortsNumber = _b.configurePortsNumber, total = _b.total;
    var currentValue = Math.round((configurePortsNumber * 100) / total);
    if (total === 0) {
        return _jsx(Typography, { children: t('pages.node.node_details.port_availability.unavailable') });
    }
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' } }, { children: [_jsxs(Typography, __assign({ id: "port-configured", marginBottom: 0.5 }, { children: [total - configurePortsNumber, " of ", total, " ", t('pages.node.node_details.port_availability.ports_unconfigured')] })), _jsx(LinearProgress, { "aria-labelledby": "port-configured", sx: { width: '100%', marginRight: 1 }, value: currentValue, color: "success" })] })));
}
// Network Details section
function NetworkDetails(_a) {
    var node = _a.node;
    var _b = useOpen(), open = _b.open, handleToggle = _b.handleToggle;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [node.lhvpn_address && _jsx(SideBarRow, { name: t('pages.node.node_details.network_details.vpn_ip_address'), value: node.lhvpn_address }), node.non_lhvpn_address && _jsx(SideBarRow, { name: t('pages.node.node_details.network_details.address'), value: node.non_lhvpn_address }), node.interfaces.length || node.mac_address ? (_jsxs(_Fragment, { children: [_jsx(OGButtonLink, __assign({ "aria-label": t('pages.node.node_details.network_details.more_details_label'), variant: "standalone", onClick: handleToggle, sx: { '&:hover': { textDecoration: 'none' } }, endIcon: open ? _jsx(ExpandLessIcon, { fontSize: "small" }) : _jsx(ExpandMoreIcon, { fontSize: "small" }) }, { children: open ? t('global.buttons.less_details') : t('global.buttons.more_details') })), _jsxs(Collapse, __assign({ in: open, unmountOnExit: true }, { children: [_jsx(SideBarRow, { name: t('pages.node.node_details.network_details.net1_mac_address'), value: node.mac_address, style: { marginBottom: '4px', marginTop: '2px' } }), node.interfaces.length
                                ? node.interfaces.map(function (item) { return (_jsxs("div", __assign({ style: { marginBottom: '4px' } }, { children: [_jsx(SideBarRow, { name: item.name }), _jsx(SideBarRow, { name: "IPv4", value: item.ipv4_addr || null }), _jsx(SideBarRow, { name: "IPv6", value: item.ipv6_addr || null })] }), item.name)); })
                                : null] }))] })) : null] }));
}
// Cellular section
function CellularDetails(_a) {
    var node = _a.node, nodeId = _a.nodeId;
    var _b = useOpen(), open = _b.open, handleToggle = _b.handleToggle;
    var t = useTranslation().t;
    var data = useNodeCellHealthDetails(nodeId).data;
    if (!data)
        return null;
    var cellDetails = data.node_cellhealth_runtime_details;
    return (_jsxs(_Fragment, { children: [_jsx(CellStatus // indicator, mobile operator and cell status
            , { connectionStatus: node.runtime_status.connection_status, cellularHealthStatus: node.cellhealth_runtime_status.status, carrier: cellDetails.carrier }), cellDetails.imsi || cellDetails.iccid ? (_jsx(OGButtonLink, __assign({ "aria-label": t('pages.node.node_details.cellular.more_details_label'), variant: "standalone", onClick: handleToggle, sx: { 'marginTop': '6px', '&:hover': { textDecoration: 'none' } }, endIcon: open ? _jsx(ExpandLessIcon, { fontSize: "small" }) : _jsx(ExpandMoreIcon, { fontSize: "small" }) }, { children: open ? t('global.buttons.less_details') : t('global.buttons.more_details') }))) : (_jsx(SideBarRow, { name: t('pages.node.node_details.cellular.sim_status'), value: t('pages.node.node_details.cellular.not_present'), style: { marginTop: '8px' } })), cellDetails.imsi || cellDetails.iccid ? (_jsxs(Collapse, __assign({ in: open, "data-testid": "expanded-cellular-details", unmountOnExit: true }, { children: [(cellDetails.ipv4_address || cellDetails.ipv6_address) && _jsx(SideBarRow, { name: "Cellular IP", style: { marginTop: '6px' } }), _jsx(SideBarRow, { name: "IPv4", value: cellDetails.ipv4_address }), _jsx(SideBarRow, { name: "IPv6", value: cellDetails.ipv6_address }), _jsx(SideBarRow, { name: "Signal Quality", value: cellDetails.signal_strength }), _jsx(SideBarRow, { name: "RSSI", value: cellDetails.rssi }), _jsx(SideBarRow, { name: "ICCID", value: cellDetails.iccid }), _jsx(SideBarRow, { name: "IMEI", value: cellDetails.imei }), _jsx(SideBarRow, { name: "IMSI", value: cellDetails.imsi })] }))) : null] }));
}
// Tag section
function Tags(_a) {
    var node = _a.node;
    var t = useTranslation().t;
    if (!node.tag_list.tags.length) {
        return _jsx("span", { children: t('pages.node.node_details.node_tags.unavailable') });
    }
    return (_jsx(_Fragment, { children: node.tag_list.tags.map(function (tag) { return (_jsx(Chip, { label: "".concat(tag.name, ":").concat(tag.value), sx: { width: 'max-content', marginRight: 1, marginBottom: 1 } }, "".concat(tag.id, "-").concat(tag.name))); }) }));
}
// Device/node general section
function DeviceDetails(_a) {
    var _b;
    var node = _a.node;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(SideBarRow, { name: t('pages.node.node_details.device_details.license_type'), value: ((_b = node.subscription) === null || _b === void 0 ? void 0 : _b.tier) ? subscriptionTierMap.get(node.subscription.tier) : 'n/a' }), _jsx(SideBarRow, { name: t('pages.node.node_details.device_details.serial_number'), value: node.serial_number }), _jsx(SideBarRow, { name: t('pages.node.node_details.device_details.firmware'), value: node.firmware_version }), _jsx(SideBarRow, { name: t('pages.node.node_details.device_details.bundle'), value: node.enrollment_bundle })] }));
}
function _NodeDetails(_a) {
    var _b, _c;
    var node = _a.node, nodeId = _a.nodeId;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    return (_jsxs(Paper, __assign({ sx: { padding: 2, paddingBottom: 1 } }, { children: [_jsx(SideBarSection, __assign({ title: t('pages.node.node_details.port_availability.title') }, { children: _jsx(PortConfiguration, { node: node }) })), _jsx(SideBarSection, __assign({ title: t('pages.node.node_details.network_details.title') }, { children: _jsx(NetworkDetails, { node: node }) })), hasPermission('configure.node_enrollment.enrolled_nodes.view_cell_health') && (_jsx(SideBarSection, __assign({ title: t('pages.node.node_details.cellular.title') }, { children: _jsx(CellularDetails, { node: node, nodeId: nodeId }) }))), _jsx(SideBarSection, __assign({ title: t('pages.node.node_details.node_tags.title') }, { children: _jsx(Tags, { node: node }) })), _jsx(SideBarSection, __assign({ title: (_c = (_b = node.model) !== null && _b !== void 0 ? _b : node.family) !== null && _c !== void 0 ? _c : t('pages.node.node_details.device_details.title'), sx: { marginBottom: 0 } }, { children: _jsx(DeviceDetails, { node: node }) }))] })));
}
// Reduce re-renders
export var NodeDetails = memo(_NodeDetails);
