import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlertNotification } from '@/hooks/alert-notification';
export function useReadOnlyRedirect(props) {
    var navigate = useNavigate();
    var alertNotification = useAlertNotification().alertNotification;
    useEffect(function () {
        var isSecondary = props.isSecondary, permissions = props.permissions, userData = props.userData, currentUser = props.currentUser, errorMessage = props.errorMessage, route = props.route;
        var hasEditPermission = permissions.hasEditPermission, hasViewPermission = permissions.hasViewPermission;
        if (!!userData && !!currentUser) {
            if (!isSecondary && !hasEditPermission && hasViewPermission && userData.username !== currentUser) {
                alertNotification(errorMessage, { persistOnUnmount: true });
                navigate(route);
            }
        }
    }, [navigate, alertNotification, props]);
}
