var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { OGTable } from '@opengear/table';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';
import format from 'string-format';
import { templateTypeMap } from '@/utils/template';
import { useDeleteBundle } from '@/api/bundles/bundles';
import { ENROLLMENT_BUNDLES_ROUTE } from '@/constants/routes';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
export function DeleteBundle(_a) {
    var editable = _a.editable, bundleId = _a.bundleId;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var deleteBundle = useDeleteBundle();
    var deleteHandler = useCallback(function () { return deleteBundle(bundleId); }, [deleteBundle, bundleId]);
    var onSuccess = useCallback(function () { return navigate(ENROLLMENT_BUNDLES_ROUTE); }, [navigate]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.enrollment_bundles.delete.title'), deleteButtonLabel: t('pages.enrollment_bundles.delete.button'), onDelete: deleteHandler, onSuccess: onSuccess, disabled: !editable }, { children: _jsx(DialogContentText, { children: format(t('pages.enrollment_bundles.delete.message'), bundleId !== null && bundleId !== void 0 ? bundleId : '') }) })));
}
export function ModuleSelectionModal(_a) {
    var moduleData = _a.moduleData, open = _a.open, handleClose = _a.handleClose, handleAddModule = _a.handleAddModule, subscription = _a.subscription;
    var _b = __read(useState(''), 2), selectedModule = _b[0], setSelectedModule = _b[1];
    var t = useTranslation().t;
    var id = 'nom-module-select';
    var handleAdd = useCallback(function () {
        if (selectedModule) {
            handleAddModule(selectedModule);
            setSelectedModule('');
        }
        handleClose();
    }, [handleAddModule, selectedModule, handleClose]);
    var validateModule = function (moduleId) {
        var module = moduleData.find(function (moduleInfo) { return moduleInfo.id === moduleId; });
        if (subscription === 'enterprise' && module && module.name !== 'IP Access') {
            return t('pages.enrollment_bundles.add.fields.modules.incompatible_subscription');
        }
        return false;
    };
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: t('pages.enrollment_bundles.add.fields.modules.modal_title') }), _jsx(DialogContent, { children: moduleData.length > 0 ? (_jsx(TextField
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                , __assign({ 
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, id: id, label: t('pages.enrollment_bundles.add.fields.modules.label'), margin: "none", select: true, fullWidth: true, onChange: function (event) { return setSelectedModule(event.target.value); }, value: selectedModule, error: selectedModule ? !!validateModule(selectedModule) : undefined, helperText: selectedModule ? validateModule(selectedModule) : null }, { children: moduleData.map(function (moduleInfo) { return (_jsx(MenuItem, __assign({ value: moduleInfo.id }, { children: moduleInfo.name }), moduleInfo.id)); }) }))) : (_jsx(DialogContentText, { children: t('pages.enrollment_bundles.add.fields.modules.no_modules') })) }), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    , __assign({ 
                        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus: true, variant: "borderless", onClick: function () {
                            setSelectedModule('');
                            handleClose();
                        } }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ disabled: !selectedModule || !!validateModule(selectedModule), onClick: handleAdd }, { children: t('global.buttons.add') }))] })] })));
}
export function TemplateSelectionModal(_a) {
    var open = _a.open, templateData = _a.templateData, excludeTemplates = _a.excludeTemplates, handleClose = _a.handleClose, handleAddTemplates = _a.handleAddTemplates;
    var t = useTranslation().t;
    var _b = __read(useState([]), 2), selectedTemplates = _b[0], setSelectedTemplates = _b[1];
    var rows = useMemo(function () { return templateData.filter(function (template) { return !excludeTemplates.includes(template.id); }); }, [templateData, excludeTemplates]);
    var onSubmit = useCallback(function () {
        handleAddTemplates(selectedTemplates);
        setSelectedTemplates([]);
        handleClose();
    }, [handleAddTemplates, handleClose, selectedTemplates]);
    var handleSelectionModelChange = useCallback(function (newSelection) {
        // template ids are strings so newSelection will be string[]
        setSelectedTemplates(newSelection);
    }, []);
    var columns = [
        {
            field: 'name',
            headerName: t('pages.enrollment_bundles.add.fields.templates.name'),
            flex: 1,
        },
        {
            field: 'type',
            headerName: t('pages.enrollment_bundles.add.fields.templates.type'),
            flex: 1,
            valueFormatter: function (_a) {
                var value = _a.value;
                return templateTypeMap.get(value);
            },
        },
        {
            field: 'description',
            headerName: t('pages.enrollment_bundles.add.fields.templates.description'),
            flex: 1,
        },
    ];
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose, fullWidth: true, sx: {
            '& .MuiDialog-paper': {
                maxWidth: '740px',
            },
        } }, { children: [_jsx(DialogTitle, { children: t('pages.enrollment_bundles.add.fields.templates.modal_title') }), _jsx(Divider, {}), _jsx(DialogContent, __assign({ tabIndex: -1, sx: { height: '500px' } }, { children: _jsx(OGTable, { 
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, "aria-label": t('pages.enrollment_bundles.add.fields.templates.modal_title'), checkboxSelection: true, rows: rows, columns: columns, noRowsMessage: t('pages.enrollment_bundles.add.fields.templates.no_rows_modal'), rowSelectionModel: selectedTemplates, onRowSelectionModelChange: handleSelectionModelChange }) })), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: function () {
                            setSelectedTemplates([]);
                            handleClose();
                        } }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ disabled: selectedTemplates.length < 1, onClick: onSubmit }, { children: t('global.buttons.add') }))] })] })));
}
