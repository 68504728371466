var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useEffect, useMemo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import debounce from 'lodash/debounce';
export var FreeTextSearch = forwardRef(function (_a, ref) {
    var _b;
    var onSearch = _a.onSearch, freeTextDisabled = _a.freeTextDisabled, translations = _a.translations, _c = _a.useDebounce, useDebounce = _c === void 0 ? true : _c;
    var t = useTranslation().t;
    var _d = __read(useState(''), 2), inputValue = _d[0], setInputValue = _d[1];
    var debouncedChangeHandler = useMemo(function () { return debounce(onSearch, 500); }, [onSearch]);
    var handleInputChange = useCallback(function (event) {
        if (useDebounce) {
            debouncedChangeHandler(event.target.value);
        }
        if (!useDebounce && event.target.value.trim() === '') {
            onSearch('');
        }
        setInputValue(event.target.value);
    }, [onSearch, useDebounce, debouncedChangeHandler]);
    var handleBlur = useCallback(function () {
        onSearch(inputValue);
    }, [inputValue, onSearch]);
    var handleKeyDown = useCallback(function (event) {
        if (event.key === 'Enter') {
            onSearch(inputValue);
        }
    }, [inputValue, onSearch]);
    // cleanup function
    useEffect(function () { return function () { return debouncedChangeHandler.cancel(); }; }, [debouncedChangeHandler]);
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 1 }, { children: [_jsx(TextField, { inputRef: ref, fullWidth: true, disabled: freeTextDisabled, type: "text", value: inputValue, onChange: handleInputChange, onBlur: !useDebounce ? handleBlur : undefined, onKeyDown: handleKeyDown, InputProps: {
                    inputProps: { 'aria-label': t('components.nodes.text_search.aria_label') },
                    endAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(SearchIcon, { fontSize: "medium" }) }))),
                }, margin: "none", placeholder: (_b = translations === null || translations === void 0 ? void 0 : translations.placeholder) !== null && _b !== void 0 ? _b : t('components.nodes.text_search.placeholder') }), (translations === null || translations === void 0 ? void 0 : translations.tooltip) && _jsx(OGIconTooltip, { title: translations.tooltip })] })));
});
