var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stack from '@mui/material/Stack';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { NewFilterMenu } from '@/containers/filters/new-filter-menu/new-filter-menu';
import { FILTERS_ROUTE, NODE_FILTERS_ROUTE_KEY, PORT_FILTERS_ROUTE_KEY, RESOURCE_FILTERS_ROUTE_KEY } from '@/constants/routes';
import { pathEndsWith } from '@/utils/navigation';
import { NodeFiltersTable } from '@/containers/filters/node-filters/node-filters-table';
import { NodeFilterSaveModal } from '@/containers/filters/node-filters/node-filter-modals';
import { useQueryParameterOpen } from '@/hooks/open';
import { PortFiltersTable } from '@/containers/filters/port-filters/port-filters-table/port-filters-table';
import { PortFilterSaveModal } from '@/containers/filters/port-filters/port-filter-modals';
import { ResourceFiltersTable } from '@/containers/filters/resource-filters/resource-filters-table/resource-filters-table';
import { useEntitlementsData } from '@/api/system/entitlements';
import { ResourceFilterSaveModal } from '@/containers/filters/resource-filters/resource-filter-modals';
import { QUERY_PARAM_MODAL_NAME_KEY, QUERY_PARAM_CREATE_FILTER_MODAL } from '@/constants/query-params-keys';
function Filters() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useDocTitle(t('pages.filters.title'));
    var lighthouseSubscriptions = useEntitlementsData().lighthouseSubscriptions;
    var hasPremiumSubscription = !!lighthouseSubscriptions.find(function (subscription) { return subscription.details.tier === 'premium'; });
    var _a = __read(useState(false), 2), filterValueError = _a[0], setFilterValueError = _a[1];
    var hasPermission = useUIPermissions().hasPermission;
    var filterPermissions = useMemo(function () {
        var _a;
        return (_a = {},
            _a[NODE_FILTERS_ROUTE_KEY] = {
                hasFilterViewAccess: hasPermission('configure.edit_smart_groups.view'),
                hasFilterEditAccess: hasPermission('configure.edit_smart_groups.edit'),
            },
            _a[PORT_FILTERS_ROUTE_KEY] = {
                hasFilterViewAccess: hasPermission('configure.edit_managed_device_filters.view'),
                hasFilterEditAccess: hasPermission('configure.edit_managed_device_filters.edit'),
            },
            _a[RESOURCE_FILTERS_ROUTE_KEY] = {
                hasFilterViewAccess: hasPermission('configure.edit_smart_groups.view') && hasPremiumSubscription,
                hasFilterEditAccess: hasPermission('configure.edit_smart_groups.edit') && hasPremiumSubscription,
            },
            _a);
    }, [hasPermission, hasPremiumSubscription]);
    var _b = useQueryParameterOpen(QUERY_PARAM_MODAL_NAME_KEY, QUERY_PARAM_CREATE_FILTER_MODAL.nodeFilter, filterPermissions[NODE_FILTERS_ROUTE_KEY].hasFilterEditAccess), newNodeModel = _b.open, handleNodeModalClose = _b.handleClose;
    var _c = useQueryParameterOpen(QUERY_PARAM_MODAL_NAME_KEY, QUERY_PARAM_CREATE_FILTER_MODAL.portFilter, filterPermissions[PORT_FILTERS_ROUTE_KEY].hasFilterEditAccess), newPortModel = _c.open, handlePortModalClose = _c.handleClose;
    var _d = useQueryParameterOpen(QUERY_PARAM_MODAL_NAME_KEY, QUERY_PARAM_CREATE_FILTER_MODAL.resourceFilter, filterPermissions[RESOURCE_FILTERS_ROUTE_KEY].hasFilterEditAccess), newResourceModel = _d.open, handleResourceModalClose = _d.handleClose;
    var defaultTabValue = useMemo(function () {
        if (filterPermissions[NODE_FILTERS_ROUTE_KEY].hasFilterViewAccess)
            return NODE_FILTERS_ROUTE_KEY;
        return PORT_FILTERS_ROUTE_KEY;
    }, [filterPermissions]);
    var tabValue = useMemo(function () {
        if (filterPermissions[RESOURCE_FILTERS_ROUTE_KEY].hasFilterViewAccess && pathEndsWith(pathname, RESOURCE_FILTERS_ROUTE_KEY))
            return RESOURCE_FILTERS_ROUTE_KEY;
        if (filterPermissions[PORT_FILTERS_ROUTE_KEY].hasFilterViewAccess && pathEndsWith(pathname, PORT_FILTERS_ROUTE_KEY))
            return PORT_FILTERS_ROUTE_KEY;
        if (filterPermissions[NODE_FILTERS_ROUTE_KEY].hasFilterViewAccess && pathEndsWith(pathname, NODE_FILTERS_ROUTE_KEY))
            return NODE_FILTERS_ROUTE_KEY;
        return defaultTabValue;
    }, [defaultTabValue, pathname, filterPermissions]);
    useEffect(function () {
        if (pathEndsWith(pathname, FILTERS_ROUTE)) {
            if (filterPermissions[NODE_FILTERS_ROUTE_KEY].hasFilterViewAccess) {
                navigate(NODE_FILTERS_ROUTE_KEY, { replace: true });
            }
            else if (filterPermissions[PORT_FILTERS_ROUTE_KEY].hasFilterViewAccess) {
                navigate(PORT_FILTERS_ROUTE_KEY, { replace: true });
            }
            else {
                navigate(RESOURCE_FILTERS_ROUTE_KEY);
            }
        }
    }, [navigate, pathname, filterPermissions]);
    return (_jsxs(_Fragment, { children: [_jsx(NodeFilterSaveModal, { onClose: handleNodeModalClose, onSubmit: handleNodeModalClose, open: newNodeModel, translations: {
                    dialogTitle: t('components.nodes.filter.save_modal.new_title'),
                    saveBtn: t('components.nodes.filter.save_modal.submit'),
                } }), _jsx(PortFilterSaveModal, { onClose: handlePortModalClose, onSubmit: handlePortModalClose, open: newPortModel, translations: {
                    dialogTitle: t('components.ports.filter.save_modal.new_title'),
                    saveBtn: t('components.ports.filter.save_modal.submit'),
                } }), _jsx(ResourceFilterSaveModal, { onClose: handleResourceModalClose, onSubmit: handleResourceModalClose, open: newResourceModel, translations: {
                    dialogTitle: t('components.resources.filter.save_modal.new_title'),
                    saveBtn: t('components.resources.filter.save_modal.submit'),
                }, error: filterValueError, setError: setFilterValueError }), _jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.filters.title') })), _jsx(NewFilterMenu, { permissions: filterPermissions })] })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [filterPermissions[NODE_FILTERS_ROUTE_KEY].hasFilterViewAccess && (_jsx(Tab, { component: RouterLink, to: NODE_FILTERS_ROUTE_KEY, label: t('pages.filters.tabs.node_filters.title'), value: NODE_FILTERS_ROUTE_KEY })), filterPermissions[PORT_FILTERS_ROUTE_KEY].hasFilterViewAccess && (_jsx(Tab, { component: RouterLink, to: PORT_FILTERS_ROUTE_KEY, label: t('pages.filters.tabs.port_filters.title'), value: PORT_FILTERS_ROUTE_KEY })), filterPermissions[RESOURCE_FILTERS_ROUTE_KEY].hasFilterViewAccess && (_jsx(Tab, { component: RouterLink, to: RESOURCE_FILTERS_ROUTE_KEY, label: t('pages.filters.tabs.resource_filters.title'), value: RESOURCE_FILTERS_ROUTE_KEY }))] })), filterPermissions[NODE_FILTERS_ROUTE_KEY].hasFilterViewAccess && (_jsx(TabPanel, __assign({ value: NODE_FILTERS_ROUTE_KEY }, { children: _jsx(NodeFiltersTable, { permissions: filterPermissions[NODE_FILTERS_ROUTE_KEY] }) }))), filterPermissions[PORT_FILTERS_ROUTE_KEY].hasFilterViewAccess && (_jsx(TabPanel, __assign({ value: PORT_FILTERS_ROUTE_KEY }, { children: _jsx(PortFiltersTable, { permissions: filterPermissions[PORT_FILTERS_ROUTE_KEY] }) }))), filterPermissions[RESOURCE_FILTERS_ROUTE_KEY].hasFilterViewAccess && (_jsx(TabPanel, __assign({ value: RESOURCE_FILTERS_ROUTE_KEY }, { children: _jsx(ResourceFiltersTable, { permissions: filterPermissions[RESOURCE_FILTERS_ROUTE_KEY] }) })))] })) })] }))] }));
}
export default withAuthenticationRequired(Filters, { rights: ['configure.edit_smart_groups.view', 'configure.edit_managed_device_filters.view'] });
