var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { CommonNameField, ConfirmPasswordField, CountrySelect, EmailField, KeyLengthSelect, LocalityField, OrgField, OrgUnitField, PasswordField, SSLCertTable, PrivateKeyUploader, CSRTable, CertFileUploader, CreateCSRButton, StateField, } from './https-form-components';
import { FormActions, FormContainer, ResetButton, SubmitButton } from '@/components/layout/forms';
import { transformFormToNewRequest, transformResponseToFormState } from './transformers';
import { useHTTPSConfig, useUpdateHTTPS } from '@/api/services/https';
import { useFormSync } from '@/hooks/use-form-sync';
import { PAGE_TITLE_ID } from '@/constants/ids';
export function HTTPSForm(_a) {
    var _this = this;
    var data = _a.data, permissions = _a.permissions;
    var t = useTranslation().t;
    var updateHTTPS = useUpdateHTTPS();
    var isFetching = useHTTPSConfig().isFetching;
    var methods = useForm({ mode: 'onTouched', defaultValues: transformResponseToFormState(data, t) });
    var watch = methods.watch, reset = methods.reset;
    useFormSync(methods.formState.defaultValues, transformResponseToFormState(data, t), methods.reset, t('pages.https_certificate.reset_header'), isFetching);
    var csrState = watch('csr');
    var createCSR = useCallback(function (formstate) { return __awaiter(_this, void 0, void 0, function () {
        var reqBody, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    reqBody = transformFormToNewRequest(formstate);
                    return [4 /*yield*/, updateHTTPS({ https: reqBody })];
                case 1:
                    res = _a.sent();
                    if (res) {
                        reset(transformResponseToFormState(res, t));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [reset, t, updateHTTPS]);
    var submitCertificate = useCallback(function (formstate) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(formstate.certfile !== null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateHTTPS({ https: { cert: formstate.certfile } })];
                case 1:
                    res = _a.sent();
                    if (res) {
                        reset(transformResponseToFormState(res, t));
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [reset, t, updateHTTPS]);
    return (_jsx(Paper, { children: _jsxs(Stack, __assign({ padding: 2, spacing: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.https_certificate.current_cert_header') })), _jsx(SSLCertTable, { cert: data }), csrState === null ? (_jsx(FormContainer, __assign({ methods: methods, permissions: permissions, onSubmit: createCSR, mode: "create" }, { children: _jsxs(Stack, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.https_certificate.new_csr_header') })), _jsx(CommonNameField, {}), _jsx(OrgUnitField, {}), _jsx(OrgField, {}), _jsx(LocalityField, {}), _jsx(StateField, {}), _jsx(CountrySelect, {}), _jsx(EmailField, {}), _jsx(KeyLengthSelect, {}), _jsx(PasswordField, {}), _jsx(ConfirmPasswordField, {}), _jsx(PrivateKeyUploader, {}), _jsxs(FormActions, { children: [_jsx(ResetButton, {}), _jsx(CreateCSRButton, {})] })] }) }))) : (_jsx(FormContainer, __assign({ methods: methods, permissions: permissions, onSubmit: submitCertificate, mode: "create" }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.https_certificate.current_csr_header') })), _jsx(CSRTable, { csr: data.csr }), _jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.https_certificate.cert_header') })), _jsx(CertFileUploader, {}), _jsxs(FormActions, { children: [_jsx(ResetButton, {}), _jsx(SubmitButton, {})] })] })) })))] })) }));
}
