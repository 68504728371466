/** autogenerate file, do not edit, regenerate with `make generate_query_types` */
/**
 * This interface was referenced by `CDMQuerySchema`'s JSON-Schema
 * via the `definition` "query_operation".
 */
export var IQueryOperation;
(function (IQueryOperation) {
    IQueryOperation[IQueryOperation["equal"] = 1] = "equal";
    IQueryOperation[IQueryOperation["notEqual"] = 2] = "notEqual";
    IQueryOperation[IQueryOperation["lessThan"] = 3] = "lessThan";
    IQueryOperation[IQueryOperation["lessThanOrEqual"] = 4] = "lessThanOrEqual";
    IQueryOperation[IQueryOperation["greaterThan"] = 5] = "greaterThan";
    IQueryOperation[IQueryOperation["greaterThanOrEqual"] = 6] = "greaterThanOrEqual";
    IQueryOperation[IQueryOperation["startsWith"] = 7] = "startsWith";
    IQueryOperation[IQueryOperation["endsWith"] = 8] = "endsWith";
    IQueryOperation[IQueryOperation["contains"] = 9] = "contains";
    IQueryOperation[IQueryOperation["exists"] = 10] = "exists";
    IQueryOperation[IQueryOperation["nexists"] = 11] = "nexists";
    IQueryOperation[IQueryOperation["in"] = 12] = "in";
    IQueryOperation[IQueryOperation["nin"] = 13] = "nin";
})(IQueryOperation || (IQueryOperation = {}));
/**
 * This interface was referenced by `CDMQuerySchema`'s JSON-Schema
 * via the `definition` "query_data_type".
 */
export var IQueryDataType;
(function (IQueryDataType) {
    IQueryDataType[IQueryDataType["bool"] = 1] = "bool";
    IQueryDataType[IQueryDataType["int"] = 2] = "int";
    IQueryDataType[IQueryDataType["float"] = 3] = "float";
    IQueryDataType[IQueryDataType["string"] = 4] = "string";
    IQueryDataType[IQueryDataType["blob"] = 6] = "blob";
    IQueryDataType[IQueryDataType["list"] = 7] = "list";
    IQueryDataType[IQueryDataType["map"] = 8] = "map";
    IQueryDataType[IQueryDataType["reference"] = 9] = "reference";
})(IQueryDataType || (IQueryDataType = {}));
/**
 * This interface was referenced by `CDMQuerySchema`'s JSON-Schema
 * via the `definition` "query_type".
 */
export var IQueryType;
(function (IQueryType) {
    IQueryType[IQueryType["and"] = 1] = "and";
    IQueryType[IQueryType["or"] = 2] = "or";
    IQueryType[IQueryType["basic"] = 3] = "basic";
})(IQueryType || (IQueryType = {}));
/**
 * Only a subset of node fields are supported on the frontend
 *
 * This interface was referenced by `CDMQuerySchema`'s JSON-Schema
 * via the `definition` "query_node_field".
 */
export var IQueryNodeField;
(function (IQueryNodeField) {
    IQueryNodeField["approved"] = "config:approved";
    IQueryNodeField["firmwareVersion"] = "config:firmware_version";
    IQueryNodeField["id"] = "config:_id";
    IQueryNodeField["lhvpnAddress"] = "config:lhvpn_address";
    IQueryNodeField["macAddress"] = "config:mac_address";
    IQueryNodeField["model"] = "config:model";
    IQueryNodeField["name"] = "config:name";
    IQueryNodeField["product"] = "config:product";
    IQueryNodeField["productFamily"] = "config:product_family";
    IQueryNodeField["serialNumber"] = "config:serial_number";
    IQueryNodeField["sshPort"] = "config:ssh_port";
    IQueryNodeField["status"] = "config:status";
    IQueryNodeField["cellHealthStatus"] = "status:cellular_health";
    IQueryNodeField["connectionStatus"] = "status:resolved_connection_status";
    IQueryNodeField["subscriptionType"] = "subscription:tier";
})(IQueryNodeField || (IQueryNodeField = {}));
/**
 * Only a subset of port fields are supported on the frontend
 *
 * This interface was referenced by `CDMQuerySchema`'s JSON-Schema
 * via the `definition` "query_port_field".
 */
export var IQueryPortField;
(function (IQueryPortField) {
    IQueryPortField["id"] = "config:id";
    IQueryPortField["label"] = "config:label";
    IQueryPortField["mode"] = "config:mode";
    IQueryPortField["tag"] = "config:tag";
})(IQueryPortField || (IQueryPortField = {}));
/**
 * Search fields for a Smart Management Fabric resource
 *
 * This interface was referenced by `CDMQuerySchema`'s JSON-Schema
 * via the `definition` "query_smf_resource_field".
 */
export var IQuerySmfResourceField;
(function (IQuerySmfResourceField) {
    IQuerySmfResourceField["name"] = "config:resource_name";
    IQuerySmfResourceField["tag"] = "config:tag";
    IQuerySmfResourceField["address"] = "config:resource_address";
    IQuerySmfResourceField["networkRange"] = "config:network_range";
    IQuerySmfResourceField["serviceConnection"] = "status:service_connection";
})(IQuerySmfResourceField || (IQuerySmfResourceField = {}));
