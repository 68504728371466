var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useFormContainerContext } from '@/components/layout/forms';
import { invalidFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN, NUM_ONLY_PATTERN } from '@/constants/regex';
export var ApiPortMinVal = 0;
export var ApiPortMaxVal = 49151;
export var VpnPortMinVal = 0;
export var VpnPortMaxVal = 49151;
export var MiVpnPortMinVal = 0;
export var MiVpnPortMaxVal = 49151;
export function ExternalAddressField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "address", rules: {
            required: requiredFieldError(t('pages.network_settings.external_address.form.fields.address.label')),
            pattern: {
                value: HOSTNAME_OR_ADDRESS_PATTERN,
                message: invalidFieldError(t('pages.network_settings.external_address.form.fields.address.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(FormControl, __assign({ margin: "none", fullWidth: true }, { children: _jsx(TextField
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                , __assign({ 
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, id: "address", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.network_settings.external_address.form.fields.address.label'), sx: { width: '100%' } }, field)) })));
        } }));
}
export function APIPortField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "api_port", rules: {
            required: requiredFieldError(t('pages.network_settings.external_address.form.fields.api_port.label')),
            pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.network_settings.external_address.form.fields.api_port.label')) },
            min: {
                value: ApiPortMinVal,
                message: minValueFieldError(t('pages.network_settings.external_address.form.fields.api_port.label'), ApiPortMinVal.toString()),
            },
            max: {
                value: ApiPortMaxVal,
                message: maxValueFieldError(t('pages.network_settings.external_address.form.fields.api_port.label'), ApiPortMaxVal.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(TextField, __assign({ id: "api_port", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.network_settings.external_address.form.fields.api_port.label'), sx: { width: '100%' }, type: "number" }, field, { onChange: function (event) {
                        field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                    } })) })));
        } }));
}
export function VPNPortField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "vpn_port", rules: {
            required: requiredFieldError(t('pages.network_settings.external_address.form.fields.vpn_port.label')),
            pattern: {
                value: NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.network_settings.external_address.form.fields.vpn_port.label')),
            },
            min: {
                value: VpnPortMinVal,
                message: minValueFieldError(t('pages.network_settings.external_address.form.fields.vpn_port.label'), VpnPortMinVal.toString()),
            },
            max: {
                value: VpnPortMaxVal,
                message: maxValueFieldError(t('pages.network_settings.external_address.form.fields.vpn_port.label'), VpnPortMaxVal.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(TextField, __assign({ id: "vpn_port", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.network_settings.external_address.form.fields.vpn_port.label'), sx: { width: '100%' }, type: "number" }, field, { onChange: function (event) {
                        field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                    } })) })));
        } }));
}
export function MultiInstanceVpnPortField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "mi_vpn_port", rules: {
            required: requiredFieldError(t('pages.network_settings.external_address.form.fields.mi_vpn_port.label')),
            pattern: {
                value: NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.network_settings.external_address.form.fields.mi_vpn_port.label')),
            },
            min: {
                value: MiVpnPortMinVal,
                message: minValueFieldError(t('pages.network_settings.external_address.form.fields.mi_vpn_port.label'), MiVpnPortMinVal.toString()),
            },
            max: {
                value: MiVpnPortMaxVal,
                message: maxValueFieldError(t('pages.network_settings.external_address.form.fields.mi_vpn_port.label'), MiVpnPortMaxVal.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(TextField, __assign({ id: "mi_vpn_port", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.network_settings.external_address.form.fields.mi_vpn_port.label'), sx: { width: '100%' }, type: "number" }, field, { onChange: function (event) {
                        field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                    } })) })));
        } }));
}
