var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { OGButton } from '@opengear/buttons';
import { RequiredPill } from '@opengear/inputs/required-pill';
import FormHelperText from '@mui/material/FormHelperText';
import { useDeviceView } from '@/hooks/use-device-view';
import { useThemeMediaQuery } from '@/hooks/use-theme-media-query';
import { useConnectionSummary } from '@/api/stats/nodes/connection-summary';
import { useFormContainerContext } from '@/components/layout/forms';
import { useTestLocation } from '@/api/services/node-backup/test-location';
import { REPEAT, RETENTION_PERIOD, START_TYPE } from './constants';
import { invalidFieldError, maxLengthFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { DATETIME_INPUT_FORMAT, DATETIME_FORMAT } from '@/constants/formats';
import { NUM_ONLY_PATTERN } from '@/constants/regex';
export function EnableBackupsField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
            return (_jsx(FormControl, __assign({ margin: value ? 'dense' : 'none' }, { children: _jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, value: value, onChange: function (event, v) {
                        if (v === null)
                            return;
                        onChange(v);
                    } }, field, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })) })));
        } }));
}
export function NumberOfBackupsField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), permissions = _a.permissions, control = _a.control;
    return (_jsx(Controller, { control: control, name: "number_of_backups", render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(FormControl, __assign({ margin: "normal" }, { children: _jsx(TextField, __assign({ select: true, id: "number_of_backups", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.node_backup.form.fields.number_of_backups.label'), _jsx(OGIconTooltip, { title: t('pages.node_backup.form.fields.number_of_backups.description') })] }), margin: "none", sx: { maxWidth: isMobileView ? undefined : 300 } }, field, { children: Array.from({ length: 10 }, function (_, i) { return i + 1; }).map(function (key) { return (_jsx(MenuItem, __assign({ value: key }, { children: key }), key)); }) })) })));
        } }));
}
export function DaysField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), permissions = _a.permissions, control = _a.control;
    var RetentionDaysMinVal = 1;
    var RetentionDaysMaxVal = 2147483647;
    return (_jsx(Controller, { control: control, name: "retention_period_days", rules: {
            required: requiredFieldError(t('pages.node_backup.form.fields.retention_period_days.label')),
            min: {
                value: RetentionDaysMinVal,
                message: minValueFieldError(t('pages.node_backup.form.fields.retention_period_days.label'), RetentionDaysMinVal.toString()),
            },
            max: {
                value: RetentionDaysMaxVal,
                message: maxValueFieldError(t('pages.node_backup.form.fields.retention_period_days.label'), RetentionDaysMaxVal.toString()),
            },
            pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.node_backup.form.fields.retention_period_days.label')) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ type: "number", id: "retention_period_days", disabled: !permissions.hasEditPermission, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, margin: "none", sx: { marginRight: 1, maxWidth: isMobileView ? undefined : 300 }, inputProps: { 'min': 1, 'aria-label': t('pages.node_backup.form.fields.retention_period_days.label') } }, field, { onChange: function (event) {
                    field.onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                } })));
        } }));
}
export function RetentionPeriodFieldSet() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), permissions = _a.permissions, control = _a.control, watch = _a.watch, trigger = _a.trigger;
    var retentionPeriod = watch('retention_period');
    if (retentionPeriod === RETENTION_PERIOD.days) {
        trigger('retention_period_days');
    }
    return (_jsx(Controller, { control: control, name: "retention_period", render: function (_a) {
            var retentionPeriodField = _a.field;
            return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, __assign({ id: "retention_period" }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "end", gap: 1 }, { children: [t('pages.node_backup.form.fields.retention_period.label'), _jsx(RequiredPill, {})] })) })), _jsxs(RadioGroup, __assign({}, retentionPeriodField, { "aria-labelledby": "retention_period" }, { children: [_jsx(FormControlLabel, { control: _jsx(Radio, {}), label: t('pages.node_backup.form.fields.retention_period.options.none'), value: RETENTION_PERIOD.none, disabled: !permissions.hasEditPermission }), _jsx(FormControlLabel, { control: _jsx(Radio, {}), label: t('pages.node_backup.form.fields.retention_period.options.forever'), value: RETENTION_PERIOD.forever, disabled: !permissions.hasEditPermission }), _jsx(FormControlLabel, { control: _jsx(Radio, {}), label: _jsxs(_Fragment, { children: [retentionPeriodField.value === RETENTION_PERIOD.days && _jsx(DaysField, {}), t('pages.node_backup.form.fields.retention_period.options.days')] }), value: RETENTION_PERIOD.days, disabled: !permissions.hasEditPermission })] }))] })));
        } }));
}
export function LocationField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), permissions = _a.permissions, control = _a.control, watch = _a.watch;
    var _b = __read(watch(['location']), 1), location = _b[0];
    var LocationMaxLength = 128;
    var _c = useTestLocation({ location: location }, { enabled: false, gcTime: 0 }), data = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    var validationErrorMsg = useMemo(function () {
        if (isLoading || !data) {
            return null;
        }
        if (!data.location_test.location_writable) {
            return t('pages.node_backup.form.fields.location.actions.validate.errors.not_writable');
        }
        if (!data.location_test.is_space_enough) {
            return t('pages.node_backup.form.fields.location.actions.validate.errors.not_enough_space');
        }
        return t('pages.node_backup.form.fields.location.actions.validate.errors.success');
    }, [data, isLoading, t]);
    var validateLocation = function () {
        refetch();
    };
    return (_jsx(Controller, { control: control, name: "location", rules: {
            required: requiredFieldError(t('pages.node_backup.form.fields.location.label')),
            maxLength: {
                value: LocationMaxLength,
                message: maxLengthFieldError(t('pages.node_backup.form.fields.location.label'), LocationMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "end", gap: 1 }, { children: [_jsx(TextField, __assign({ id: "location", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.node_backup.form.fields.location.label'), _jsx(OGIconTooltip, { title: t('pages.node_backup.form.fields.location.description') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, margin: "none", required: true, sx: { maxWidth: isMobileView ? undefined : 300 }, inputProps: { 'aria-label': t('pages.node_backup.form.fields.location.label') } }, field)), _jsx(OGButton, __assign({ variant: "secondary", disabled: !permissions.hasEditPermission, loading: isLoading, onClick: validateLocation }, { children: t('pages.node_backup.form.fields.location.actions.validate.label') }))] })), _jsx("span", __assign({ "aria-live": "assertive" }, { children: validationErrorMsg }))] })));
        } }));
}
export function AvailableSpaceField() {
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "available_space", render: function (_a) {
            var field = _a.field;
            return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, { children: t('pages.node_backup.form.fields.available_space.label') }), _jsx(Box, __assign({ sx: { marginY: 1 } }, { children: _jsxs(Typography, { children: [field.value, " MiB"] }) }))] })));
        } }));
}
export function TotalSpaceField() {
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "total_space", render: function (_a) {
            var field = _a.field;
            return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, { children: t('pages.node_backup.form.fields.total_space.label') }), _jsx(Box, __assign({ sx: { marginY: 1 } }, { children: _jsxs(Typography, { children: [field.value, " MiB"] }) }))] })));
        } }));
}
export function EstimatedDiskSpaceUsageField() {
    var SINGLE_NODE_BACKUP_SIZE_KB = 140;
    var t = useTranslation().t;
    var _a = useConnectionSummary(), isLoading = _a.isLoading, data = _a.data;
    var watch = useFormContainerContext().watch;
    var _b = __read(watch(['number_of_backups']), 1), numberOfBackups = _b[0];
    var estimatedDiskUsage = 0;
    if (!isLoading && data) {
        var totalNodes = data.connectionSummary.reduce(function (count, item) { return count + item.count; }, 0);
        // Each node backup is between 60kb and 140kb. Estimate conservatively.
        estimatedDiskUsage = (SINGLE_NODE_BACKUP_SIZE_KB * totalNodes * numberOfBackups) / 1000; // Ensure result is in MB
    }
    return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsxs(FormLabel, { children: [t('pages.node_backup.form.fields.estimated_disk_usage.label'), _jsx(OGIconTooltip, { title: t('pages.node_backup.form.fields.estimated_disk_usage.description') })] }), _jsx(Box, __assign({ sx: { marginY: 1 } }, { children: _jsxs(Typography, { children: [estimatedDiskUsage, " MiB"] }) }))] })));
}
export function StartDateFieldSet() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), permissions = _a.permissions, control = _a.control, trigger = _a.trigger;
    var isMobileView = useDeviceView().isMobileView;
    var tabletMediaQuery = useThemeMediaQuery('tablet');
    var currentTime = {
        time: dayjs().utc().format(DATETIME_FORMAT),
        label: t('pages.node_backup.form.fields.current_time.label'),
    };
    return (_jsx(Controller, { control: control, name: "start_type", render: function (_a) {
            var startTypeField = _a.field;
            return (_jsxs(_Fragment, { children: [_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, __assign({ id: "start_type" }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "end", gap: 1 }, { children: [t('pages.node_backup.form.fields.start_type.label'), _jsx(RequiredPill, {})] })) })), _jsxs(RadioGroup, __assign({}, startTypeField, { "aria-labelledby": "start_type" }, { children: [_jsx(FormControlLabel, { control: _jsx(Radio, {}), label: t('pages.node_backup.form.fields.start_type.options.immediately'), value: START_TYPE.immediately, disabled: !permissions.hasEditPermission }), _jsx(FormControlLabel, { control: _jsx(Radio, {}), label: t('pages.node_backup.form.fields.start_type.options.set_time'), value: START_TYPE.set_time, disabled: !permissions.hasEditPermission })] }))] })), startTypeField.value === START_TYPE.set_time && (_jsxs(_Fragment, { children: [_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, { children: currentTime.label }), _jsx(Box, __assign({ sx: { marginY: 1 } }, { children: _jsx(Typography, { children: currentTime.time }) }))] }), currentTime.label), _jsx(Controller, { control: control, name: "start_date", rules: {
                                    required: requiredFieldError(t('pages.node_backup.form.fields.start_date.label')),
                                    min: {
                                        value: dayjs().valueOf(),
                                        message: invalidFieldError(t('pages.node_backup.form.fields.start_date.label')),
                                    },
                                }, render: function (_a) {
                                    var fieldState = _a.fieldState, startDateField = _a.field;
                                    return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(DateTimePicker, __assign({ ampm: false, format: DATETIME_INPUT_FORMAT, minDateTime: dayjs().utc(), label: t('pages.node_backup.form.fields.start_date.label'), sx: { maxWidth: isMobileView ? undefined : 300, marginBottom: fieldState.error ? 0 : undefined }, closeOnSelect: false, onClose: function () { return trigger('start_date'); }, slots: { openPickerIcon: CalendarMonthIcon }, slotProps: {
                                                    openPickerIcon: {
                                                        style: { fontSize: 16 },
                                                    },
                                                    openPickerButton: {
                                                        style: { padding: 7, marginRight: 0, borderRadius: 0 },
                                                    },
                                                    textField: {
                                                        placeholder: DATETIME_INPUT_FORMAT,
                                                        onBlur: function () {
                                                            trigger('start_date');
                                                        },
                                                        error: !!fieldState.error,
                                                    },
                                                }, disabled: !permissions.hasEditPermission, desktopModeMediaQuery: tabletMediaQuery }, startDateField)), !!fieldState.error && _jsx(FormHelperText, __assign({ error: true }, { children: fieldState.error.message }))] })));
                                } })] }))] }));
        } }));
}
export function FrequencyFieldSet() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), permissions = _a.permissions, control = _a.control;
    var frequencyUnitOptions = [
        { label: t('pages.node_backup.form.fields.frequency_unit.options.days'), value: 'day(s)' },
        { label: t('pages.node_backup.form.fields.frequency_unit.options.weeks'), value: 'week(s)' },
    ];
    var FrequencyMinVal = 1;
    var FrequencyMaxVal = 365;
    return (_jsx(Controller, { control: control, name: "repeat", render: function (_a) {
            var repeatField = _a.field;
            return (_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, __assign({ id: "repeat" }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "end", gap: 1 }, { children: [t('pages.node_backup.form.fields.repeat.label'), _jsx(RequiredPill, {})] })) })), _jsxs(RadioGroup, __assign({}, repeatField, { "aria-labelledby": "repeat" }, { children: [_jsx(FormControlLabel, { control: _jsx(Radio, {}), label: t('pages.node_backup.form.fields.repeat.options.once'), value: REPEAT.once, disabled: !permissions.hasEditPermission }), _jsx(FormControlLabel, { control: _jsx(Radio, {}), label: t('pages.node_backup.form.fields.repeat.options.interval'), value: REPEAT.interval, disabled: !permissions.hasEditPermission }), repeatField.value !== REPEAT.once && (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 1 }, { children: [_jsx("span", { children: t('pages.node_backup.form.fields.frequency.secondaryLabel') }), _jsx(Controller, { control: control, name: "frequency", rules: {
                                            required: requiredFieldError(t('pages.node_backup.form.fields.frequency.label')),
                                            min: {
                                                value: FrequencyMinVal,
                                                message: minValueFieldError(t('pages.node_backup.form.fields.frequency.label'), FrequencyMinVal.toString()),
                                            },
                                            max: {
                                                value: FrequencyMaxVal,
                                                message: maxValueFieldError(t('pages.node_backup.form.fields.frequency.label'), FrequencyMaxVal.toString()),
                                            },
                                            pattern: {
                                                value: NUM_ONLY_PATTERN,
                                                message: invalidFieldError(t('pages.node_backup.form.fields.frequency.label')),
                                            },
                                        }, render: function (_a) {
                                            var _b;
                                            var _c = _a.field, onChange = _c.onChange, frequencyField = __rest(_c, ["onChange"]), frequencyFieldState = _a.fieldState;
                                            return (_jsx(TextField, __assign({ id: "frequency", type: "number", disabled: !permissions.hasEditPermission, margin: "none", error: !!frequencyFieldState.error, helperText: (_b = frequencyFieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { maxWidth: isMobileView ? undefined : 300 }, inputProps: { 'min': 1, 'aria-label': t('pages.node_backup.form.fields.frequency.label') }, onChange: function (event) {
                                                    onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                                                } }, frequencyField)));
                                        } }), _jsx(Controller, { control: control, name: "frequency_unit", render: function (_a) {
                                            var frequencyUnitField = _a.field, frequencyUnitFieldState = _a.fieldState;
                                            return (_jsx(TextField, __assign({ select: true, id: "frequency_unit", disabled: !permissions.hasEditPermission, error: !!frequencyUnitFieldState.error, margin: "none", sx: { maxWidth: isMobileView ? undefined : 300 }, inputProps: { 'aria-label': t('pages.node_backup.form.fields.frequency_unit.label') } }, frequencyUnitField, { children: frequencyUnitOptions.map(function (option) { return (_jsx(MenuItem, __assign({ value: option.value }, { children: option.label }), option.value)); }) })));
                                        } })] })))] }))] })));
        } }));
}
