import { styled } from '@mui/material/styles';
import { OG_FOCUS_BOX_SHADOW } from '@opengear/theme/colors';
export var VisuallyHiddenInput = styled('input')({
    'position': 'absolute',
    'height': '1px',
    'width': '1px',
    'overflow': 'hidden',
    'clip': 'rect(1px, 1px, 1px, 1px)',
    ':is(:focus, :focus-within) + label': {
        boxShadow: OG_FOCUS_BOX_SHADOW,
    },
});
