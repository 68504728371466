var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import MuiLink from '@mui/material/Link';
/**
  Button Link component
  A button that looks like a link

  @param {boolean} propagateSpace - Set to false if the Button Link appears in a table and needs to work with spacebar
 */
export function OGButtonLink(_a) {
    var children = _a.children, disabled = _a.disabled, endIcon = _a.endIcon, startIcon = _a.startIcon, sx = _a.sx, _b = _a.variant, variant = _b === void 0 ? 'standalone' : _b, _c = _a.propagateSpacebar, propagateSpacebar = _c === void 0 ? true : _c, otherProps = __rest(_a, ["children", "disabled", "endIcon", "startIcon", "sx", "variant", "propagateSpacebar"]);
    var sxOverrides = {
        fontStyle: 'italic',
    };
    if (variant === 'standard') {
        sxOverrides.color = 'secondary.main';
    }
    if (startIcon) {
        sxOverrides['> svg:first-of-type'] = {
            marginRight: '4px',
        };
    }
    if (endIcon) {
        sxOverrides['> svg:last-of-type'] = {
            marginLeft: '4px',
        };
    }
    if (disabled) {
        sxOverrides.cursor = 'auto';
        sxOverrides.opacity = 0.5;
        sxOverrides[':hover'] = {
            textDecoration: 'none',
            color: 'inherit',
        };
    }
    sxOverrides.textAlign = 'left';
    return (_jsx(MuiLink, __assign({ component: "button", disabled: disabled, sx: __assign(__assign({}, sxOverrides), sx), type: "button", role: "button", onKeyDown: function (event) {
            if (!propagateSpacebar && event.code === 'Space') {
                event.stopPropagation();
            }
        } }, otherProps, { children: _jsxs(_Fragment, { children: [startIcon && startIcon, children, endIcon && endIcon] }) })));
}
