var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
export function StepIndicator(props) {
    var theme = useTheme();
    var active = props.active, completed = props.completed;
    return (_jsx(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "center", height: theme.spacing(2), width: theme.spacing(2), borderRadius: "50%", bgcolor: theme.palette.stepper.outline }, { children: completed ? (_jsx(Check, { fontSize: "small" })) : (_jsx(Box, { width: theme.spacing(1.25), height: theme.spacing(1.25), borderRadius: "50%", bgcolor: active ? theme.palette.stepper.active : theme.palette.stepper.indicator })) })));
}
