var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import Typography from '@mui/material/Typography';
import { useTestLocation } from '@/api/services/node-backup/test-location';
import { NODE_FIRMWARE_LOCATION } from '@/constants/locations';
export function AvailableSpace() {
    var t = useTranslation().t;
    var _a = useTestLocation({ location: NODE_FIRMWARE_LOCATION }), data = _a.data, isLoading = _a.isLoading;
    if (isLoading || !(data === null || data === void 0 ? void 0 : data.location_test.location_writable))
        return null;
    var availableSpace = data.location_test.available_space.toString();
    var totalSpace = data.location_test.total_space.toString();
    return (_jsx(Typography, __assign({ marginTop: 1, textAlign: "right" }, { children: format(t('pages.firmware_upgrade.tabs.file_manager.space_available'), availableSpace, totalSpace) })));
}
