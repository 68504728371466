var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from '@mui/material/Divider';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import i18n from '@/i18n/config';
import { useFetchSamlLoginData } from '@/api/auth/saml/idp_metadata/login-data';
import { useLighthouseToken } from '@/api/utils';
import { useAlertNotification } from '@/hooks/alert-notification';
export var LOGIN_ERRORS = {
    invalid_creds: i18n.t('pages.login.messages.invalid_creds.message'),
    invalid_response: i18n.t('pages.login.messages.invalid_response'),
    challenge_error: i18n.t('pages.login.messages.challenge_error'),
    user_locked_out: i18n.t('pages.login.messages.user_locked_out'),
    password_expired_secondary_error: i18n.t('pages.login.messages.password_expired_secondary_error'),
    not_authorised: i18n.t('pages.login.messages.not_authorised'),
    invalid_saml_username: i18n.t('pages.login.messages.invalid_saml_username'),
    idp_not_configured: i18n.t('pages.login.messages.idp_not_configured'),
    invalid_idp_configuration: i18n.t('pages.login.messages.invalid_idp_configuration'),
    server_saml_error: i18n.t('pages.login.messages.server_saml_error'),
    session_error: i18n.t('pages.login.messages.session_error'),
};
export function SamlSection() {
    var _a = __read(useState({ is_configured: false }), 2), samlLoginData = _a[0], setSamlLoginData = _a[1];
    var _b = __read(useSearchParams(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var setLighthouseToken = useLighthouseToken().setToken;
    var fetchSamlLoginData = useFetchSamlLoginData();
    var alertNotification = useAlertNotification().alertNotification;
    var t = useTranslation().t;
    // Step 1 - Determine from the backend if to render the SAML section
    useEffect(function () {
        fetchSamlLoginData().then(function (result) {
            if (result) {
                setSamlLoginData(result === null || result === void 0 ? void 0 : result.idp);
            }
        });
    }, [alertNotification, fetchSamlLoginData]);
    // Step 2 - Redirect to Idp (see return block)
    // Step 3 - Handle SAML session creation
    useEffect(function () {
        var _a;
        // Handle IdP -> Lighthouse redirect error
        var errorCode = searchParams.get('error_code');
        if (errorCode) {
            // Remove from url query params
            searchParams.delete('error_code');
            setSearchParams(searchParams);
            alertNotification((_a = LOGIN_ERRORS[errorCode]) !== null && _a !== void 0 ? _a : t('pages.login.saml_auth_failed.message'), {
                header: t('pages.login.saml_auth_failed.header'),
            });
            return;
        }
        // Handle IdP -> Lighthouse redirect success
        var token = searchParams.get('token');
        if (token) {
            searchParams.delete('token');
            setSearchParams(searchParams);
            setLighthouseToken(token);
        }
    }, [alertNotification, searchParams, setLighthouseToken, setSearchParams, t]);
    return samlLoginData.is_configured ? (_jsxs(_Fragment, { children: [_jsx(OGButton, __assign({ fullWidth: true, sx: { marginBottom: 1 }, 
                // Step 2 - Bypass react-router Link (hooked into MUI Link via theme) and use the raw "a" HTML
                // element. This allows the request to be handled by the backend, where the response is a
                // 302 (redirect) which the browser handles by navigating to the accompanying 'Location'
                // header (IdP's website).
                href: "/api/v3.7/sessions/saml/sp_init/".concat(samlLoginData.idp_provider) }, { children: "".concat(t('pages.login.buttons.saml'), " ").concat(samlLoginData.display_name) })), _jsx(Divider, { children: "OR" })] })) : null;
}
