var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useDeviceView } from '@/hooks/use-device-view';
import { useTransformJobsFilterOptions } from './jobs-filter-utils';
import { JobsFilterPill } from './jobs-filter-pill';
var getOptionLabel = function (_a) {
    var label = _a.label;
    return label;
};
function isOptionEqualToValue(option, value) {
    return option.key === value.key;
}
function renderOption(props, _a, _b) {
    var label = _a.label, key = _a.key;
    var selected = _b.selected;
    return (_createElement(ListItem, __assign({}, props, { key: key, dense: true }),
        _jsxs(Box, __assign({ component: "span", sx: { width: '100%', display: 'flex', justifyContent: 'space-between' } }, { children: [label, selected ? _jsx(CheckOutlinedIcon, { sx: { fontSize: '2rem' } }) : ''] }))));
}
export function JobsFilter(_a) {
    var filterOptions = _a.filterOptions, setFilterOptions = _a.setFilterOptions;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = __read(useState(undefined), 2), activeOption = _b[0], setActiveOption = _b[1];
    var selectedOptions = useTransformJobsFilterOptions(filterOptions);
    // Autocomplete options with default values
    var options = useMemo(function () { return [
        { key: 'jobType', label: t('pages.jobs.filter.options.job_type.label'), value: undefined },
        { key: 'jobStatus', label: t('pages.jobs.filter.options.job_status.label'), value: undefined },
        { key: 'jobId', label: t('pages.jobs.filter.options.job_id.label'), value: undefined },
        { key: 'duration', label: t('pages.jobs.filter.options.duration.label'), value: ['lt', undefined] },
        { key: 'dateRange', label: t('pages.jobs.filter.options.date_range.label'), value: [undefined, undefined] },
    ]; }, [t]);
    /**
     * Option handler does not use the multiple values to set the state but instead,
     * takes the individual option and the 'reason' to determine which fields to set.
     */
    var handleOptionChange = useCallback(function (event, values, reason, details) {
        // Handle selecting an option
        if (reason === 'selectOption' && (details === null || details === void 0 ? void 0 : details.option)) {
            var _a = details.option, key_1 = _a.key, value_1 = _a.value;
            // Open the popover settings to complete the filter
            setActiveOption(key_1);
            // Update necessary parent state
            switch (key_1) {
                case 'jobType':
                case 'jobStatus':
                case 'jobId':
                    setFilterOptions(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[key_1] = value_1, _a)));
                    });
                    break;
                case 'duration':
                    setFilterOptions(function (prevState) { return (__assign(__assign({}, prevState), { durationOperator: value_1[0], duration: value_1[1] })); });
                    break;
                case 'dateRange':
                    setFilterOptions(function (prevState) { return (__assign(__assign({}, prevState), { fromDate: value_1[0], toDate: value_1[1] })); });
                    break;
            }
        }
        // Handle removing an option
        // Disable removing option when a form is option
        if (reason === 'removeOption' && (details === null || details === void 0 ? void 0 : details.option) && !activeOption) {
            var key_2 = details.option.key;
            switch (key_2) {
                case 'jobType':
                case 'jobStatus':
                case 'jobId':
                    setFilterOptions(function (prevState) {
                        var _a = prevState, _b = key_2, _ = _a[_b], newState = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                        return newState;
                    });
                    break;
                case 'duration':
                    setFilterOptions(function (prevState) {
                        var duration = prevState.duration, durationOperator = prevState.durationOperator, newState = __rest(prevState, ["duration", "durationOperator"]);
                        return newState;
                    });
                    break;
                case 'dateRange':
                    setFilterOptions(function (prevState) {
                        var fromDate = prevState.fromDate, toDate = prevState.toDate, newState = __rest(prevState, ["fromDate", "toDate"]);
                        return newState;
                    });
                    break;
            }
        }
        // Handle clearing all filter options
        if (reason === 'clear') {
            setFilterOptions({});
        }
    }, [activeOption, setFilterOptions]);
    // Disable mui events when option form popup is open
    // https://mui.com/material-ui/react-autocomplete/#events
    var handleKeyDown = useCallback(function (event) {
        if (activeOption) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            event.defaultMuiPrevented = true;
        }
    }, [activeOption]);
    var renderInput = useCallback(function (params) { return (_jsx(TextField, __assign({}, params, { margin: "none", placeholder: t('pages.jobs.filter.placeholder'), size: "medium", variant: "standard" }))); }, [t]);
    var renderTags = useCallback(function (values, getTagProps) {
        return values.map(function (option, index) {
            var _a = getTagProps({ index: index }), key = _a.key, tagProps = __rest(_a, ["key"]);
            return (_jsx(JobsFilterPill, { option: option, tagProps: tagProps, popoverOpen: activeOption === option.key, setActiveOption: setActiveOption, setFilterOptions: setFilterOptions }, key));
        });
    }, [activeOption, setFilterOptions]);
    return (_jsx(Autocomplete, { blurOnSelect: false, clearOnEscape: true, forcePopupIcon: true, disablePortal: true, getOptionLabel: getOptionLabel, isOptionEqualToValue: isOptionEqualToValue, multiple: true, onChange: handleOptionChange, onKeyDown: handleKeyDown, options: options, renderInput: renderInput, renderOption: renderOption, renderTags: renderTags, value: selectedOptions, sx: { flexShrink: 1, minWidth: isMobileView ? '100%' : '300px' } }));
}
