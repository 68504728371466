var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { IQueryType, decodeQuery, decodeFilterQuery } from '../../types/query';
/** Known case: Legacy smartgroup that is not wrapped in a 'AND' and 'OR'
    Required Criteria:
    - Wrapped in single AND
    - Must not have nested queries, only query items
*/
export var migrateLegacyQueryObject = function (queryObject) {
    // Handle obvious query issues
    if (!('items' in queryObject) || queryObject.type !== IQueryType.and || queryObject.items.length === 0)
        return null;
    var canMigrate = true;
    var filterQueryItems = queryObject.items.reduce(function (queryItems, queryItem) {
        if ('items' in queryItem) {
            canMigrate = false;
            return queryItems;
        }
        var queryItemValue = queryItem.value;
        if (Array.isArray(queryItemValue)) {
            canMigrate = false;
            return queryItems;
        }
        queryItems.push(__assign(__assign({}, queryItem), { value: queryItemValue, type: IQueryType.basic }));
        return queryItems;
    }, []);
    // Handle query items that where not valid or resulted in no filter query items
    if (!canMigrate || filterQueryItems.length === 0)
        return null;
    var migratedQueryObject = {
        items: [
            {
                items: [
                    {
                        items: filterQueryItems,
                        type: IQueryType.and,
                    },
                ],
                type: IQueryType.or,
            },
        ],
        type: IQueryType.and,
    };
    return migratedQueryObject;
};
export function migrateQueryObject(queryObject) {
    if (!queryObject)
        return queryObject;
    // If already a filter query, then leave as is
    var validFilterQueryObject = decodeFilterQuery(queryObject);
    if (validFilterQueryObject)
        return validFilterQueryObject;
    // Try to migrate query to a filter query
    // Add more migration function as needed to increase backwards compatibility
    var migrations = [migrateLegacyQueryObject];
    var migratedFilterQueryObject = migrations.reduce(function (filterQueryObject, migrate) {
        // Do nothing if query has already successfully been migrated
        if (filterQueryObject)
            return filterQueryObject;
        return migrate(queryObject);
    }, null);
    if (migratedFilterQueryObject)
        return migratedFilterQueryObject;
    // Otherwise, leave as is
    return queryObject;
}
export function deserializeSmartGroup(smartgroup) {
    return __assign(__assign({}, smartgroup), { queryObject: migrateQueryObject(decodeQuery(smartgroup.query)) });
}
export function serializeSmartGroup(_a) {
    var queryObject = _a.queryObject, otherFields = __rest(_a, ["queryObject"]);
    return __assign(__assign({}, otherFields), { query: JSON.stringify(queryObject) });
}
