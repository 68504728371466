// Helper to transform form data to API ready data
export function transformUserFormData(formData, groups) {
    var noPassword = formData.remoteOnlyPassword;
    var transformedUser = {
        enabled: formData.enabled,
        username: formData.username,
        no_password: noPassword,
        groups: groups.filter(function (group) { return formData.groupNames.includes(group.groupname); }).map(function (group) { return group.id; }),
        ssh_public_keys: formData.sshAuthKeys,
    };
    // Only add fields to data for string and null values, otherwise omit the key
    if (formData.description !== undefined)
        transformedUser.description = formData.description;
    if (!noPassword)
        transformedUser.password = formData.password;
    return transformedUser;
}
// Helper to transform API data to form ready data
export function transformUserApiData(user, groups) {
    var _a, _b, _c;
    var groupnames = groups.filter(function (group) { return user.groups.includes(group.id); }).map(function (group) { return group.groupname; });
    return {
        enabled: user.enabled,
        groupNames: groupnames,
        username: user.username,
        description: (_a = user.description) !== null && _a !== void 0 ? _a : '',
        remoteOnlyPassword: user.no_password,
        password: '',
        confirmPassword: '',
        sshAuthKeys: (_c = (_b = user.ssh_public_keys) === null || _b === void 0 ? void 0 : _b.map(function (ssh_key) { return ({ description: ssh_key.description, value: ssh_key.value }); })) !== null && _c !== void 0 ? _c : [],
    };
}
