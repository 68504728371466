import { screen } from '@testing-library/react';
import { t } from 'i18next';
export var translations = t('pages.smf_resources.modal');
export var newTagButtonTestUtils = {
    newTagButton: {
        get: function () { return screen.getByRole('button', { name: t('pages.smf_resources.table.buttons.add_tag') }); },
    },
    translations: {
        newTagBtn: t('components.new_tag_button'),
    },
};
