var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons/button';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import { isSubnetOrCidrValid } from '@/utils/calculations';
import { IPV4_OR_NUM_ONLY_PATTERN, IPV4_PATTERN } from '@/constants/regex';
import { ConfigurationMethod } from './types';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { WarningBanner } from '@/components/warning-banner';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Constants
export var NameFieldMaxLength = 64;
export var DescriptionFieldMaxLength = 128;
export function FirewallWarning() {
    var t = useTranslation().t;
    return (_jsx(WarningBanner, { warningIcon: _jsx(WarningIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.config_templates.tabs.smf.firewall_warning.header'), warningMessage: _jsx(Typography, { children: t('pages.config_templates.tabs.smf.firewall_warning.message') }), warningColor: "warning", bannerCode: "smf-firewall-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }));
}
export function NameField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, mode = _a.mode;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.smf.form.fields.name.label')),
            maxLength: {
                value: NameFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.smf.form.fields.name.label'), NameFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "name", required: true, disabled: !permissions.hasEditPermission || mode === 'update', error: !!fieldState.error, label: t('pages.config_templates.tabs.smf.form.fields.name.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? undefined : 400 } }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.smf.form.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "description", disabled: !permissions.hasEditPermission, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.config_templates.tabs.smf.form.fields.description.label'), sx: { width: isMobileView ? undefined : 400 } }, field)));
        } }));
}
export function RedistributeConnectedCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "redistributeConnected", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasEditPermission }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value || false })));
            } }), label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.smf.form.fields.ospf_redistribute_connected.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.smf.form.fields.ospf_redistribute_connected.tooltip') })] }) }));
}
export function RedistributeKernelCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "redistributeKernel", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasEditPermission }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value || false })));
            } }), label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.smf.form.fields.ospf_redistribute_kernel.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.smf.form.fields.ospf_redistribute_kernel.tooltip') })] }) }));
}
export function AllZoneIpMasqueradeCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "allZoneIpMasquerade", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasEditPermission }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value || false })));
            } }), label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.smf.form.fields.all_zone_ip_masquerade.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.smf.form.fields.all_zone_ip_masquerade.tooltip') })] }) }));
}
export function OSPFConfigurationMethod() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "configurationMethod", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
            return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasEditPermission, value: value, onChange: function (event, val) {
                    if (val !== null) {
                        onChange(val);
                    }
                } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: "interface" }, { children: t('pages.config_templates.tabs.smf.form.fields.ospf_configuration_method.interface') })), _jsx(ToggleButton, __assign({ value: "network" }, { children: t('pages.config_templates.tabs.smf.form.fields.ospf_configuration_method.network') }))] })));
        } }));
}
export function MobileStack(_a) {
    var children = _a.children, id = _a.id;
    var isMobileView = useDeviceView().isMobileView;
    if (isMobileView)
        return (_jsx(Stack, __assign({ direction: "row", spacing: 1, role: "group" }, { children: children }), "".concat(id, "-area")));
    return children;
}
export function NetworkAddressFields(_a) {
    var configurationMethod = _a.configurationMethod;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = useFormContainerContext(), control = _b.control, setFocus = _b.setFocus, permissions = _b.permissions;
    var addNetworkButton = useRef(null);
    var _c = useFieldArray({
        name: 'networks',
        rules: { required: configurationMethod === ConfigurationMethod.network },
        control: control,
    }), ospfNetworkFields = _c.fields, appendOSPFNetwork = _c.append, removeOSPFNetwork = _c.remove;
    var addNetworkAddressHandler = useCallback(function () {
        appendOSPFNetwork({ address: '', subnet_mask: '', area: '' });
        var num = ospfNetworkFields.length;
        setFocus("networks.".concat(num, ".address"));
    }, [appendOSPFNetwork, ospfNetworkFields, setFocus]);
    var removeNetworkAddressHandler = useCallback(function (index) {
        if (ospfNetworkFields.length > 1) {
            var nextNetwork = ospfNetworkFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("networks.".concat(nextNetwork, ".address"));
        }
        else if (addNetworkButton.current) {
            addNetworkButton.current.focus();
        }
        removeOSPFNetwork(index);
    }, [removeOSPFNetwork, ospfNetworkFields, setFocus, addNetworkButton]);
    if (configurationMethod === ConfigurationMethod.interface)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", sx: { textTransform: 'uppercase' }, marginY: 2 }, { children: t('pages.config_templates.tabs.smf.form.sections.network') })), ospfNetworkFields.length > 0 && (_jsx(Box, __assign({ sx: { pb: 1 } }, { children: ospfNetworkFields.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row', spacing: 1, paddingBottom: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "networks.".concat(index, ".address"), rules: {
                                required: requiredFieldError(t('pages.config_templates.tabs.smf.form.fields.network_address.address.label')),
                                pattern: {
                                    value: IPV4_PATTERN,
                                    message: invalidFieldError(t('pages.config_templates.tabs.smf.form.fields.network_address.address.label')),
                                },
                            }, render: function (_a) {
                                var _b = _a.field, ref = _b.ref, newField = __rest(_b, ["ref"]), error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, label: t('pages.config_templates.tabs.smf.form.fields.network_address.address.label'), disabled: !permissions.hasEditPermission, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref }, newField)));
                            } }), _jsx(Controller, { control: control, name: "networks.".concat(index, ".subnet_mask"), rules: {
                                required: requiredFieldError(t('pages.config_templates.tabs.smf.form.fields.network_address.subnet_mask.label')),
                                validate: function (value) {
                                    return isSubnetOrCidrValid(value) || invalidFieldError(t('pages.config_templates.tabs.smf.form.fields.network_address.subnet_mask.label'));
                                },
                            }, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, label: t('pages.config_templates.tabs.smf.form.fields.network_address.subnet_mask.label'), disabled: !permissions.hasEditPermission, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message }, field)));
                            } }), _jsxs(MobileStack, __assign({ id: item.id }, { children: [_jsx(Controller, { control: control, name: "networks.".concat(index, ".area"), rules: {
                                        required: requiredFieldError(t('pages.config_templates.tabs.smf.form.fields.network_address.ospf_area.label')),
                                        pattern: {
                                            value: IPV4_OR_NUM_ONLY_PATTERN,
                                            message: invalidFieldError(t('pages.config_templates.tabs.smf.form.fields.network_address.ospf_area.label')),
                                        },
                                    }, render: function (_a) {
                                        var _b = _a.field, onChange = _b.onChange, ref = _b.ref, newField = __rest(_b, ["onChange", "ref"]), error = _a.fieldState.error;
                                        return (_jsx(TextField, __assign({ fullWidth: true, label: t('pages.config_templates.tabs.smf.form.fields.network_address.ospf_area.label'), disabled: !permissions.hasEditPermission, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref }, newField, { onChange: function (e) {
                                                onChange(e.target.value === '' || !Number.isNaN(e.target.value) ? e.target.value : parseInt(e.target.value, 10));
                                            } })));
                                    } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: "".concat(t('pages.config_templates.tabs.smf.form.fields.network_address.buttons.clear'), " - ").concat(index + 1) }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": "".concat(t('pages.config_templates.tabs.smf.form.fields.network_address.buttons.clear'), " - ").concat(index + 1), disabled: !permissions.hasEditPermission, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return removeNetworkAddressHandler(index); } }) })) }))] }))] }), item.id)); }) }))), _jsx(OGButton, __assign({ ref: addNetworkButton, disabled: !permissions.hasEditPermission, startIcon: _jsx(AddCircleOutline, {}), variant: "secondary", sx: { width: 'max-content' }, onClick: addNetworkAddressHandler }, { children: t('pages.config_templates.tabs.smf.form.fields.network_address.buttons.add') }))] }));
}
