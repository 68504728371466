var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { OGDialog } from '@opengear/modals';
import { OGButton, OGButtonLink } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormContainerContext } from '@/components/layout/forms';
import { useOpen } from '@/hooks/open';
export function CertificateTable(_a) {
    var rows = _a.rows;
    var t = useTranslation().t;
    var _b = useOpen(), open = _b.open, handleClose = _b.handleClose, handleOpen = _b.handleOpen;
    var _c = useFormContainerContext(), setValue = _c.setValue, permissions = _c.permissions;
    var onDeleteCallback = useCallback(function () {
        setValue('ldapSslCaCert', '', { shouldDirty: true });
    }, [setValue]);
    var renderCertificateCell = useCallback(function (_a) {
        var tabIndex = _a.tabIndex, row = _a.row;
        return (_jsxs(_Fragment, { children: [_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: handleOpen, propagateSpacebar: false }, { children: row.fileName })), _jsxs(OGDialog, __assign({ open: open, onClose: handleClose, title: "Certificate" }, { children: [_jsx(DialogTitle, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.modal.title') }), _jsx(DialogContent, { children: _jsx(Box, __assign({ flexGrow: 1, marginBottom: 1 }, { children: _jsx(Typography, __assign({ sx: { whiteSpace: 'pre-wrap' } }, { children: row.certificate })) })) }), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: handleClose, autoFocus: true, propagateSpacebar: false }, { children: t('global.buttons.close') })) })] }))] }));
    }, [t, handleClose, handleOpen, open]);
    var renderDeleteActionCell = useCallback(function (_a) {
        var tabIndex = _a.tabIndex;
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.table.actions.certificate_remove') }, { children: _jsx(OGButton, { tabIndex: tabIndex, startIcon: _jsx(DeleteIcon, {}), variant: "borderless", onClick: onDeleteCallback, "aria-label": t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.table.actions.certificate_remove'), propagateSpacebar: false }) })));
    }, [onDeleteCallback, t]);
    var columns = [
        {
            field: 'certificate',
            headerName: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.table.headers.certificate'),
            flex: 3,
            sortable: false,
            minWidth: 100,
            renderCell: renderCertificateCell,
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderDeleteActionCell,
        },
    ];
    return (_jsx(OGTable, { id: "config-templates-auth-certificate-table", "aria-label": t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.table.label'), autoHeight: true, columns: columns, rows: rows, columnVisibilityModel: {
            delete: permissions.hasAuthEditAccess,
        } }));
}
