var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { OG_GREY_M, OG_OFF_WHITE } from '@opengear/theme/colors';
import { useTheme } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { subscriptionTierMap } from '@/utils/license';
import { useFormContainerContext } from '@/components/layout/forms';
import { LicenseSelectField } from '@/components/licenses/license-select';
export function SelectedNodeStack(_a) {
    var selectedNodes = _a.selectedNodes;
    var theme = useTheme();
    var t = useTranslation().t;
    var nodeSelectionFormat = useMemo(function () {
        return selectedNodes.map(function (node) {
            var _a, _b;
            return ({
                id: node.id,
                name: node.name,
                model: node.model,
                subscription: {
                    name: ((_a = node.subscription) === null || _a === void 0 ? void 0 : _a.tier) ? subscriptionTierMap.get(node.subscription.tier) : t('global.subscriptions.unassigned'),
                    tier: ((_b = node.subscription) === null || _b === void 0 ? void 0 : _b.tier) || '',
                },
            });
        });
    }, [selectedNodes, t]);
    return (_jsx(Stack, __assign({ spacing: 1, sx: { width: '100%', maxHeight: '100%' } }, { children: nodeSelectionFormat.map(function (node) { return (_jsxs(Box, __assign({ gap: "1", sx: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_OFF_WHITE,
                p: 1.5,
            } }, { children: [_jsx(Typography, { children: node.name }), _jsx(Typography, { children: node.id }), _jsx(Typography, { children: node.model }), _jsx(Typography, { children: node.subscription.name })] }), node.id)); }) })));
}
export function SubscriptionsField(_a) {
    var nodeIds = _a.nodeIds, subscriptions = _a.subscriptions;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, watch = _b.watch, formState = _b.formState;
    var isDirty = formState.isDirty, isValid = formState.isValid;
    var validateSubscription = function () {
        var _a;
        var subscription = watch('subscription');
        if (!subscription)
            return;
        var selected = subscriptions.filter(function (s) { return s.details.tier === subscription; });
        var nodeCount = nodeIds.length;
        var available = ((_a = selected[0]) === null || _a === void 0 ? void 0 : _a.features.available_nodes) ? selected[0].features.available_nodes : 0;
        var remaining = available - nodeCount;
        // eslint-disable-next-line consistent-return
        return !!selected && remaining < 0
            ? format(t('pages.subscriptions.tabs.subscription_assignment.modal.form.error.subscription.license_quantity'), available.toString(), Math.abs(remaining).toString(), Math.abs(remaining) > 1 ? 's' : '')
            : undefined;
    };
    var licenseSwitchWarning = function () {
        var subscription = watch('subscription');
        return isValid && isDirty && subscription === 'enterprise'
            ? t('pages.subscriptions.tabs.subscription_assignment.modal.form.error.subscription.automation')
            : undefined;
    };
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Controller, { control: control, name: "subscription", rules: {
                    validate: function () { return !validateSubscription(); },
                }, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(LicenseSelectField
                    // Autofocus is required for accessibility, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    , { 
                        // Autofocus is required for accessibility, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus: true, subscriptionsData: subscriptions, field: field, ariaLabel: t('pages.subscriptions.tabs.subscription_assignment.modal.form.fields.subscription.label'), disabled: subscriptions.length < 2 }));
                } }), (!!validateSubscription() || !!licenseSwitchWarning()) && (_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', marginBottom: '-65px', overflow: 'visible' } }, { children: validateSubscription() ? (_jsxs(FormHelperText, __assign({ sx: { marginTop: 1, marginBottom: 2, display: 'flex', alignItems: 'center', maxWidth: '300px' }, error: true }, { children: [_jsx(ErrorIcon, { fontSize: "medium", color: "error", sx: { marginRight: 0.5 } }), validateSubscription()] }))) : (_jsxs(FormHelperText, __assign({ sx: { marginTop: 1, marginBottom: 2, display: 'flex', alignItems: 'center', maxWidth: '300px' } }, { children: [_jsx(WarningIcon, { fontSize: "medium", color: "warning", sx: { marginRight: 0.5 } }), licenseSwitchWarning()] }))) })))] })));
}
