var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useHTTPSConfig } from '@/api/services/https';
import { HTTPSForm } from '@/components/settings/services/https/https-form';
export var HTTPS_LOADER_ID = 'https-form-loading';
export var HTTPSSettings = withAuthenticationRequired(function () {
    var t = useTranslation().t;
    useDocTitle(t('pages.https_certificate.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.services.https_certificate.edit');
    var _a = useHTTPSConfig(), data = _a.data, isLoading = _a.isLoading;
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": HTTPS_LOADER_ID });
    }
    if (data) {
        innerNode = _jsx(HTTPSForm, { data: data, permissions: { hasEditPermission: hasEditPermission } });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.https_certificate.title') })), innerNode] })));
}, { rights: ['settings.services.https_certificate.view'] });
