var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './bootstrap';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import '@opengear/bootstrap';
import { DateTimeProvider } from '@opengear/date';
import { OGThemeProvider } from '@opengear/theme';
import App from './app';
import { createRootElement } from './utils/browser';
import '@/i18n/config';
import { getReactQueryFetchInterval } from '@/api/utils';
var container = createRootElement('lighthouse-root');
var root = createRoot(container);
var domRoot = document.querySelector('body');
root.render(_jsx(BrowserRouter, { children: _jsx(QueryClientProvider, __assign({ client: new QueryClient({
            defaultOptions: { queries: { refetchInterval: getReactQueryFetchInterval() } },
        }) }, { children: _jsx(OGThemeProvider, { children: _jsx(DateTimeProvider, { children: _jsx(SnackbarProvider, __assign({ domRoot: domRoot || undefined, anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    } }, { children: _jsx(App, {}) })) }) }) })) }));
