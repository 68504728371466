var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
import { useNodeTags } from '@/api/tags/node-tags';
export var useNodeTagsFieldName = function (readableNodeTags, statusFieldNames) {
    var _a = useNodeTags(undefined, { enabled: readableNodeTags }), data = _a.data, isLoading = _a.isLoading;
    var freeTextDisabled = readableNodeTags ? isLoading : readableNodeTags;
    var tags = data === null || data === void 0 ? void 0 : data.nodeTags;
    var tagFields = useMemo(function () { return (readableNodeTags && tags ? __spreadArray([], __read(tags.map(function (tag) { return "tag:".concat(tag.name); })), false) : []); }, [tags, readableNodeTags]);
    var newFieldNames = __spreadArray(__spreadArray([], __read(statusFieldNames), false), __read(tagFields), false);
    return { freeTextDisabled: freeTextDisabled, newFieldNames: newFieldNames };
};
