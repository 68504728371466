var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { NODES_ROUTE, NODES_ENROLLED_ROUTE, NODES_PENDING_ROUTE } from '@/constants/routes';
import { NodesPendingTable } from '@/containers/nodes/nodes-pending-table';
import { NodesEnrolledTable } from '@/containers/nodes/nodes-enrolled-table';
import { useConnectionSummary } from '@/api/stats/nodes/connection-summary';
var TabValue;
(function (TabValue) {
    TabValue["ENROLLED"] = "ENROLLED";
    TabValue["PENDING"] = "PENDING";
})(TabValue || (TabValue = {}));
function Nodes() {
    var t = useTranslation().t;
    var _a = useUIPermissions(), hasPermission = _a.hasPermission, hasSomePermission = _a.hasSomePermission, isPrimary = _a.isPrimary;
    useDocTitle(t('pages.nodes.title'));
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var nodeTablePermissions = useMemo(function () { return ({
        canViewCellHealth: hasPermission('configure.node_enrollment.enrolled_nodes.view_cell_health'),
        canViewNode: hasPermission('manage.nodes.node_ui.view'),
        canEditNodeTags: hasPermission('configure.edit_tags.edit') && isPrimary,
        canViewNodeTags: hasPermission('configure.edit_tags.view'),
        editableNodes: hasPermission('configure.node_enrollment.enrolled_nodes.enroll', { ignoreExpiry: true }),
        editableNodeFilters: hasPermission('configure.edit_smart_groups.edit'),
        isPrimary: isPrimary,
        readableNodeTags: hasSomePermission(['configure.edit_tags.view', 'configure.node_enrollment.enrollment_bundles.edit']),
    }); }, [hasPermission, hasSomePermission, isPrimary]);
    var pendingTablePermissions = useMemo(function () { return ({
        canApproveNodes: hasPermission('configure.node_enrollment.enrolled_nodes.enroll'),
        canDeleteNodes: hasPermission('configure.node_enrollment.enrolled_nodes.enroll', { ignoreExpiry: true }),
        editableNodeFilters: hasPermission('configure.edit_smart_groups.edit'),
        isPrimary: isPrimary,
    }); }, [hasPermission, isPrimary]);
    var hasPendingNodesViewAccess = useMemo(function () { return hasPermission('configure.node_enrollment.pending_nodes.view'); }, [hasPermission]);
    var tabValue = useMemo(function () { return (pathname.endsWith(NODES_PENDING_ROUTE) && hasPendingNodesViewAccess ? TabValue.PENDING : TabValue.ENROLLED); }, [pathname, hasPendingNodesViewAccess]);
    var _b = useConnectionSummary(), data = _b.data, isLoading = _b.isLoading;
    var nodeCounts = useMemo(function () {
        if (!data || isLoading)
            return undefined;
        var counts = {
            connected: 0,
            disconnected: 0,
            pending: 0,
            rejected: 0,
        };
        data.connectionSummary.forEach(function (_a) {
            var status = _a.status, count = _a.count;
            counts[status] = count;
        });
        return counts;
    }, [data, isLoading]);
    useEffect(function () {
        if (pathname.endsWith(NODES_ROUTE)) {
            navigate("".concat(NODES_ROUTE).concat(NODES_ENROLLED_ROUTE), { replace: true });
        }
    }, [pathname, navigate]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.nodes.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, { children: [_jsx(Tab, { id: "enrolled-tab", component: RouterLink, to: NODES_ENROLLED_ROUTE.slice(1), label: _jsxs(Typography, __assign({ component: "span" }, { children: [t('pages.nodes.tabs.enrolled.title'), _jsxs(Typography, __assign({ component: "span", sx: { fontWeight: 600, marginLeft: 0.5 } }, { children: ["\u00A0", nodeCounts ? nodeCounts.connected + nodeCounts.disconnected : ''] }))] })), "aria-label": t('pages.nodes.tabs.aria_label', {
                                        status: t('pages.nodes.tabs.enrolled.title'),
                                        nodecount: nodeCounts ? nodeCounts.connected + nodeCounts.disconnected : t('global.unknown'),
                                    }), value: "ENROLLED" }), _jsx(Tab, { id: "pending-tab", component: RouterLink, to: NODES_PENDING_ROUTE.slice(1), disabled: !hasPendingNodesViewAccess, label: _jsxs(Typography, __assign({ component: "span" }, { children: [t('pages.nodes.tabs.pending.title'), _jsxs(Typography, __assign({ component: "span", sx: { fontWeight: 600, marginLeft: 0.5 } }, { children: ["\u00A0", nodeCounts ? nodeCounts.pending : ''] }))] })), "aria-label": t('pages.nodes.tabs.aria_label', {
                                        status: t('pages.nodes.tabs.pending.title'),
                                        nodecount: nodeCounts ? nodeCounts.pending : t('global.unknown'),
                                    }), value: "PENDING" })] }), _jsx(TabPanel, __assign({ value: "ENROLLED" }, { children: _jsx(NodesEnrolledTable, { permissions: nodeTablePermissions }) })), _jsx(TabPanel, __assign({ value: "PENDING" }, { children: _jsx(NodesPendingTable, { permissions: pendingTablePermissions }) }))] })) })] })));
}
export default withAuthenticationRequired(Nodes, { rights: ['monitor.nodes.view'] });
