var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { OGTable } from '@opengear/table';
import Box from '@mui/material/Box';
import format from 'string-format';
import { RolesPreviewModal } from '../roles/roles-preview-modal';
export function GroupRolesTable(_a) {
    var groupName = _a.groupName, roles = _a.roles, rolesPermissions = _a.rolesPermissions;
    var t = useTranslation().t;
    var renderRoleName = useCallback(function (params) {
        return params.value ? _jsx(RolesPreviewModal, { roleName: params.value, rolesPermissions: rolesPermissions, tabIndex: params.tabIndex }) : null;
    }, [rolesPermissions]);
    var columns = useMemo(function () { return [
        {
            field: 'rolename',
            headerName: t('components.role.table.columns.rolename.headerName'),
            renderCell: renderRoleName,
            minWidth: 240,
            sortable: false,
        },
        {
            field: 'description',
            flex: 1,
            headerName: t('components.role.table.columns.description.headerName'),
            minWidth: 240,
            sortable: false,
        },
        {
            field: 'number_of_permissions',
            headerName: t('components.role.table.columns.number_of_permissions.headerName'),
            minWidth: 240,
            sortable: false,
        },
    ]; }, [renderRoleName, t]);
    var groupTable = useMemo(function () { return (_jsx(OGTable, { "aria-label": format(t('components.role.table.label'), groupName), autoHeight: true, columns: columns, noRowsMessage: t('components.role.table.no_rows'), pagination: false, rows: roles })); }, [columns, roles, groupName, t]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('components.role.table.title') })), _jsx(Box, __assign({ maxHeight: 400, minHeight: 40, marginBottom: 2 }, { children: groupTable }))] }));
}
