import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PERMISSIONS_LOADING_TEST_ID, useUIPermissions } from '@/api/sessions/self/ui';
import { useLighthouseToken } from '@/api/utils';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { DASHBOARD_ROUTE, LOGIN_ROUTE, NOT_FOUND_ROUTE } from '@/constants/routes';
var AUTH_HISTORY_KEY = 'auth.history';
// function below is to keep the last page visited by user so user can be redirected to it after logs back in
// we currently disabled this feature as there are some enhancement required - TODO - CDM-460
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function popAuthHistory() {
    var history = sessionStorage.getItem(AUTH_HISTORY_KEY);
    sessionStorage.removeItem(AUTH_HISTORY_KEY);
    return history;
}
export function setAuthHistory(authHistory) {
    sessionStorage.setItem(AUTH_HISTORY_KEY, authHistory);
}
export function withAuthenticationRequired(Component, _a) {
    var _b = _a === void 0 ? {} : _a, clearRouteHistory = _b.clearRouteHistory, rights = _b.rights;
    return function AuthenticatedComponent() {
        var location = useLocation();
        var navigate = useNavigate();
        var _a = useUIPermissions(), permissions = _a.permissions, hasSomePermission = _a.hasSomePermission, isLoadingPermissions = _a.isLoadingPermissions;
        var _b = useLighthouseToken(), token = _b.token, removeTokenAndClearQueryCache = _b.removeTokenAndClearQueryCache;
        var hasAccess = useMemo(function () { return permissions && (rights ? hasSomePermission(rights) : true); }, [hasSomePermission, permissions]);
        // save route and redirect user to login page if logged out from another tab
        useEffect(function () {
            if (!token) {
                if (!clearRouteHistory) {
                    setAuthHistory(location.pathname);
                }
                removeTokenAndClearQueryCache();
                navigate(LOGIN_ROUTE);
            }
        }, [location, navigate, token, removeTokenAndClearQueryCache]);
        // redirect to not found page if user doesn't have permissions to view page
        useEffect(function () {
            if (!isLoadingPermissions && permissions && !hasAccess && !permissions.is_expired) {
                navigate(NOT_FOUND_ROUTE);
            }
        }, [hasAccess, isLoadingPermissions, navigate, permissions]);
        if (isLoadingPermissions) {
            return _jsx(LoadingBackdrop, { "data-testid": PERMISSIONS_LOADING_TEST_ID });
        }
        if (!hasAccess)
            return null;
        return _jsx(Component, {});
    };
}
/**
 * HOC that wraps a page that doesn't require authentication,
 * with logic to redirect to authenticated pages when the user receives an auth token
 */
export function withNoAuthenticationRequired(Component) {
    return function UnAuthenticatedComponent() {
        var token = useLighthouseToken().token;
        var navigate = useNavigate();
        var queryClient = useQueryClient();
        useEffect(function () {
            if (token) {
                // TODO - history below is to be used in CDM-460
                // const history = popAuthHistory()
                navigate(DASHBOARD_ROUTE, { replace: true });
                // Clear all cached queries
                queryClient.clear();
            }
        }, [navigate, queryClient, token]);
        return _jsx(Component, {});
    };
}
