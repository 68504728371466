var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNewFirmwareUpgradeSteps } from './new-firmware-upgrade-steps-context';
export function NewFirmwareUpgradeStepper() {
    var t = useTranslation().t;
    var activeStep = useNewFirmwareUpgradeSteps().activeStep;
    var steps = useMemo(function () { return [
        t('pages.firmware_upgrade_new.steps.setup.label'),
        t('pages.firmware_upgrade_new.steps.schedule.label'),
        t('pages.firmware_upgrade_new.steps.review_confirm.label'),
    ]; }, [t]);
    var _a = __read(useState(''), 2), liveMessage = _a[0], setLiveMessage = _a[1];
    useEffect(function () {
        // Wait for a short period before setting the live message
        var timer = setTimeout(function () {
            setLiveMessage("Currently on step ".concat(activeStep + 1, ": ").concat(steps[activeStep]));
        }, 500);
        return function () { return clearTimeout(timer); };
    }, [activeStep, steps]);
    return (_jsx(Paper, { children: _jsx(Container, __assign({ sx: { maxWidth: 600, padding: 2 } }, { children: _jsx(Stepper, __assign({ activeStep: activeStep, "aria-label": "Progress" }, { children: steps.map(function (label, index) { return (_jsx(Step, __assign({ completed: activeStep > index }, { children: _jsx(StepLabel, __assign({ "aria-live": "polite", "aria-label": liveMessage }, { children: label })) }), label)); }) })) })) }));
}
