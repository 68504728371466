var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { usePasswordPolicy, useUpdatePasswordPolicy } from '@/api/users/password-policy';
import { UpdateForm } from '@/components/layout/update-form';
import { useFormSync } from '@/hooks/use-form-sync';
import { requiredFieldError, minValueFieldError, maxValueFieldError } from '@/utils/field-validation-errors';
export function PasswordPolicyForm(_a) {
    var _this = this;
    var data = _a.data, editable = _a.editable;
    var PwdLengthMinValue = 1;
    var PwdLengthMaxValue = 128;
    var PwdReuseMinValue = 1;
    var PwdReuseMaxValue = 24855;
    var PwdExpiryMinValue = 1;
    var PwdExpiryMaxValue = 24855;
    var t = useTranslation().t;
    var isFetching = usePasswordPolicy().isFetching;
    var sourceValues = useMemo(function () { return ({
        enabled: data.password_policy.enabled,
        min_password_length_enabled: data.password_policy.min_password_length > 0,
        min_password_length: Math.max(data.password_policy.min_password_length, 1),
        no_username: data.password_policy.no_username,
        must_contain_capital_letter: data.password_policy.must_contain_capital_letter,
        must_contain_number: data.password_policy.must_contain_number,
        must_contain_special_character: data.password_policy.must_contain_special_character,
        password_reuse_period_enabled: data.password_policy.password_reuse_period > -2 && data.password_policy.password_reuse_period !== 0,
        password_reuse_period_type: data.password_policy.password_reuse_period > 0 ? 'days' : 'always',
        password_reuse_period: Math.max(data.password_policy.password_reuse_period, 1),
        password_expiry_period_enabled: data.password_policy.password_expiry_period > 0,
        password_expiry_period: Math.max(data.password_policy.password_expiry_period, 1),
    }); }, [data]);
    var _b = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _b.control, handleSubmit = _b.handleSubmit, _c = _b.formState, defaultValues = _c.defaultValues, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting, isValid = _c.isValid, reset = _b.reset, resetField = _b.resetField, watch = _b.watch;
    var updatePasswordPolicy = useUpdatePasswordPolicy();
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var passwordReusePeriod, updatedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    passwordReusePeriod = formData.password_reuse_period;
                    if (!formData.password_reuse_period_enabled) {
                        passwordReusePeriod = 0;
                    }
                    else if (formData.password_reuse_period_type === 'always') {
                        passwordReusePeriod = -1;
                    }
                    return [4 /*yield*/, updatePasswordPolicy({
                            enabled: formData.enabled,
                            min_password_length: formData.min_password_length_enabled ? formData.min_password_length : 0,
                            no_username: formData.no_username,
                            must_contain_capital_letter: formData.must_contain_capital_letter,
                            must_contain_number: formData.must_contain_number,
                            must_contain_special_character: formData.must_contain_special_character,
                            password_reuse_period: passwordReusePeriod,
                            password_expiry_period: formData.password_expiry_period_enabled ? formData.password_expiry_period : 0,
                        })];
                case 1:
                    updatedData = _a.sent();
                    if (updatedData) {
                        reset({
                            enabled: updatedData.password_policy.enabled,
                            min_password_length_enabled: updatedData.password_policy.min_password_length > 0,
                            min_password_length: Math.max(updatedData.password_policy.min_password_length, 1),
                            no_username: updatedData.password_policy.no_username,
                            must_contain_capital_letter: updatedData.password_policy.must_contain_capital_letter,
                            must_contain_number: updatedData.password_policy.must_contain_number,
                            must_contain_special_character: updatedData.password_policy.must_contain_special_character,
                            password_reuse_period_enabled: updatedData.password_policy.password_reuse_period > -2 && updatedData.password_policy.password_reuse_period !== 0,
                            password_reuse_period_type: updatedData.password_policy.password_reuse_period > 0 ? 'days' : 'always',
                            password_reuse_period: Math.max(updatedData.password_policy.password_reuse_period, 1),
                            password_expiry_period_enabled: updatedData.password_policy.password_expiry_period > 0,
                            password_expiry_period: Math.max(updatedData.password_policy.password_expiry_period, 1),
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [updatePasswordPolicy, reset]);
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    // responsible for alert the user of data changes
    useFormSync(defaultValues, sourceValues, reset, t('pages.local_auth_policy.form.reset_warning.password.header'), isFetching);
    return (_jsx(UpdateForm, __assign({ "aria-label": t('pages.local_auth_policy.tabs.password_policy.form'), dirty: isDirty, submitting: isSubmitting, valid: isValid, onReset: handleReset, onSubmit: handleSubmit(onSubmit), editable: !!editable }, { children: _jsxs(FormGroup, { children: [_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !editable, value: value, onChange: function (event, val) {
                                if (val !== null) {
                                    onChange(val);
                                }
                            } }, field, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
                    } }), _jsxs(Stack, __assign({ direction: "column", gap: 1, marginTop: 1, marginBottom: 0.5, role: "group", "aria-label": t('pages.local_auth_policy.tabs.password_policy.form') }, { children: [_jsx(Typography, __assign({ id: "password-policy-tab-title", component: "h3", variant: "h3", paddingTop: 1, paddingBottom: 1, sx: { textTransform: 'uppercase' } }, { children: t('pages.local_auth_policy.form.title') })), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "min_password_length_enabled", render: function (_a) {
                                    var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                                    return (_jsx(Checkbox, __assign({ disabled: !editable }, field, { onChange: function (event, v) {
                                            onChange(v);
                                            if (!v) {
                                                resetField('min_password_length');
                                            }
                                        }, checked: value })));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.min_password_length_checkbox.label') }) }), watch('min_password_length_enabled') && (_jsx(Controller, { control: control, name: "min_password_length", rules: {
                                required: requiredFieldError(t('pages.local_auth_policy.form.fields.min_password_length_checkbox.label')),
                                min: {
                                    value: PwdLengthMinValue,
                                    message: minValueFieldError(t('pages.local_auth_policy.form.fields.min_password_length_checkbox.label'), PwdLengthMinValue.toString()),
                                },
                                max: {
                                    value: PwdLengthMaxValue,
                                    message: maxValueFieldError(t('pages.local_auth_policy.form.fields.min_password_length_checkbox.label'), PwdLengthMaxValue.toString()),
                                },
                            }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
                                return (_jsxs(FormControl, __assign({ error: !!error, margin: "none" }, { children: [_jsxs(Stack, __assign({ alignItems: "center", direction: "row", gap: 2 }, { children: [_jsx(Input, __assign({ inputProps: { min: PwdLengthMinValue, max: PwdLengthMaxValue }, type: "number", disabled: !editable, sx: { maxWidth: 300, minWidth: 175 } }, field, { id: field.name, "aria-describedby": "".concat(field.name, "-error"), onChange: function (event) {
                                                        onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                                                    } })), _jsx(InputLabel, __assign({ htmlFor: field.name }, { children: field.value && field.value.toString() === '1'
                                                        ? t('pages.local_auth_policy.form.fields.min_password_length.label.character')
                                                        : t('pages.local_auth_policy.form.fields.min_password_length.label.characters') }))] })), _jsx(FormHelperText, __assign({ id: "".concat(field.name, "-error") }, { children: error === null || error === void 0 ? void 0 : error.message }))] })));
                            } })), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "must_contain_capital_letter", render: function (_a) {
                                    var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                    return _jsx(Checkbox, __assign({ disabled: !editable }, field, { checked: value }));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.require_capital.label') }) }), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "must_contain_number", render: function (_a) {
                                    var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                    return _jsx(Checkbox, __assign({ disabled: !editable }, field, { checked: value }));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.require_number.label') }) }), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "must_contain_special_character", render: function (_a) {
                                    var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                    return _jsx(Checkbox, __assign({ disabled: !editable }, field, { checked: value }));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.require_symbol.label') }) }), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "no_username", render: function (_a) {
                                    var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                    return _jsx(Checkbox, __assign({ disabled: !editable }, field, { checked: value }));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.no_username.label') }) }), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "password_reuse_period_enabled", render: function (_a) {
                                    var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                                    return (_jsx(Checkbox, __assign({ disabled: !editable }, field, { onChange: function (event, v) {
                                            onChange(v);
                                            if (!v) {
                                                resetField('password_reuse_period_type');
                                                resetField('password_reuse_period');
                                            }
                                        }, checked: value })));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.no_password_reuse_checkbox.label') }) }), watch('password_reuse_period_enabled') && (_jsx(Controller, { control: control, name: "password_reuse_period_type", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                                return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !editable, value: value, onChange: function (event, v) {
                                        onChange(v);
                                        if (v !== null) {
                                            resetField('password_reuse_period');
                                        }
                                    }, sx: { 'label+&': { marginTop: 0 } } }, field, { children: [_jsx(ToggleButton, __assign({ value: "days" }, { children: t('pages.local_auth_policy.form.fields.no_password_reuse_button.days') })), _jsx(ToggleButton, __assign({ value: "always" }, { children: t('pages.local_auth_policy.form.fields.no_password_reuse_button.always') }))] })));
                            } })), watch('password_reuse_period_type') === 'days' && watch('password_reuse_period_enabled') && (_jsx(Controller, { control: control, name: "password_reuse_period", rules: {
                                required: requiredFieldError(t('pages.local_auth_policy.form.fields.no_password_reuse_checkbox.label')),
                                min: {
                                    value: PwdReuseMinValue,
                                    message: minValueFieldError(t('pages.local_auth_policy.form.fields.no_password_reuse_checkbox.label'), PwdReuseMinValue.toString()),
                                },
                                /* In LiPy, we multiply this value with seconds in a day i.e. 86400. Since the column type in database is
                                int(11) which has a maximum value of 2147483647, anything more than 24855 exceeds the range database accepts. */
                                max: {
                                    value: PwdReuseMaxValue,
                                    message: maxValueFieldError(t('pages.local_auth_policy.form.fields.no_password_reuse_checkbox.label'), PwdReuseMaxValue.toString()),
                                },
                            }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
                                return (_jsxs(FormControl, __assign({ error: !!error, margin: "none" }, { children: [_jsxs(Stack, __assign({ alignItems: "center", direction: "row", gap: 2 }, { children: [_jsx(Input, __assign({ inputProps: { min: PwdReuseMinValue, max: PwdReuseMaxValue }, type: "number", disabled: !editable, sx: { maxWidth: 300, minWidth: 175 } }, field, { id: field.name, "aria-describedby": "".concat(field.name, "-error"), onChange: function (event) {
                                                        onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                                                    } })), _jsx(InputLabel, __assign({ htmlFor: field.name, "aria-hidden": "false", "aria-label": t('pages.local_auth_policy.form.fields.no_password_reuse.label.aria_label') }, { children: field.value && field.value.toString() === '1'
                                                        ? t('pages.local_auth_policy.form.fields.no_password_reuse.label.day')
                                                        : t('pages.local_auth_policy.form.fields.no_password_reuse.label.days') }))] })), _jsx(FormHelperText, __assign({ id: "".concat(field.name, "-error") }, { children: error === null || error === void 0 ? void 0 : error.message }))] })));
                            } })), _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "password_expiry_period_enabled", render: function (_a) {
                                    var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                                    return (_jsx(Checkbox, __assign({ disabled: !editable }, field, { onChange: function (event, v) {
                                            onChange(v);
                                            if (!v) {
                                                resetField('password_expiry_period');
                                            }
                                        }, checked: value })));
                                } }), label: _jsx(_Fragment, { children: t('pages.local_auth_policy.form.fields.set_password_expiry_checkbox.label') }) }), watch('password_expiry_period_enabled') && (_jsx(Controller, { control: control, name: "password_expiry_period", rules: {
                                required: requiredFieldError(t('pages.local_auth_policy.form.fields.set_password_expiry_checkbox.label')),
                                min: {
                                    value: PwdExpiryMinValue,
                                    message: minValueFieldError(t('pages.local_auth_policy.form.fields.set_password_expiry_checkbox.label'), PwdExpiryMinValue.toString()),
                                },
                                /* In LiPy, we multiply this value with seconds in a day i.e. 86400. Since the column type in database is
                                int(11) which has a maximum value of 2147483647, anything more than 24855 exceeds the range database accepts. */
                                max: {
                                    value: PwdExpiryMaxValue,
                                    message: maxValueFieldError(t('pages.local_auth_policy.form.fields.set_password_expiry_checkbox.label'), PwdExpiryMaxValue.toString()),
                                },
                            }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
                                return (_jsxs(FormControl, __assign({ error: !!error, margin: "none" }, { children: [_jsxs(Stack, __assign({ alignItems: "center", direction: "row", gap: 2 }, { children: [_jsx(Input, __assign({ inputProps: { min: PwdExpiryMinValue, max: PwdExpiryMaxValue }, type: "number", disabled: !editable, sx: { maxWidth: 300, minWidth: 175 } }, field, { id: field.name, "aria-describedby": "".concat(field.name, "-error"), onChange: function (event) {
                                                        onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                                                    } })), _jsx(InputLabel, __assign({ htmlFor: field.name, "aria-hidden": "false", "aria-label": t('pages.local_auth_policy.form.fields.set_password_expiry.label.aria_label') }, { children: field.value && field.value.toString() === '1'
                                                        ? t('pages.local_auth_policy.form.fields.set_password_expiry.label.day')
                                                        : t('pages.local_auth_policy.form.fields.set_password_expiry.label.days') }))] })), _jsx(FormHelperText, __assign({ id: "".concat(field.name, "-error") }, { children: error === null || error === void 0 ? void 0 : error.message }))] })));
                            } }))] }))] }) })));
}
