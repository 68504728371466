import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
import { ApproximateTimeMessage } from '../form-controls';
import { formatDateTimestamp } from '@/utils/datetime';
import { DATETIME_FORMAT } from '@/constants/formats';
export function StartTimeSummary() {
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var immediate = watch('immediate');
    if (immediate) {
        return _jsx(Typography, { children: t('pages.firmware_upgrade_new.steps.review_confirm.start_time.immediate_message') });
    }
    var scheduledTimeDate = watch('scheduledTime');
    if (!scheduledTimeDate)
        return null;
    var scheduledTime = formatDateTimestamp(scheduledTimeDate.unix(), DATETIME_FORMAT);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: scheduledTime }), _jsx(ApproximateTimeMessage, { futureDate: scheduledTimeDate })] }));
}
