import { jsx as _jsx } from "react/jsx-runtime";
import { useTimezone } from '@/api/system/timezone';
import { TimezoneForm } from './timezone-form';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
var TIMEZONE_TAB_LOADER_ID = 'timezone-tab-form-loader';
export function TimezoneTab(_a) {
    var permissions = _a.permissions;
    var _b = useTimezone(), data = _b.data, isLoading = _b.isLoading;
    if (isLoading) {
        return _jsx(LoadingBackdrop, { "data-testid": TIMEZONE_TAB_LOADER_ID });
    }
    if (!data) {
        return null;
    }
    return _jsx(TimezoneForm, { data: data, permissions: permissions });
}
