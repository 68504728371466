import { useTranslation } from 'react-i18next';
export var NOM_MODULES_PATH = '/netops/modules';
export var NOM_MODULES_STATUS_PATH = '/nom/modules/status';
export var NOM_MODULES_STATE_PATH = '/nom/modules/state';
export function formatRedeployModulePath(moduleName) {
    if (moduleName === void 0) { moduleName = ':moduleName'; }
    return "".concat(NOM_MODULES_PATH, "/").concat(moduleName, "/redeploy");
}
export function formatUpdateModulePath(moduleName) {
    if (moduleName === void 0) { moduleName = ':moduleName'; }
    return "".concat(NOM_MODULES_PATH, "/").concat(moduleName);
}
export function useNetopsModuleNamesMap() {
    var t = useTranslation().t;
    return new Map([
        ['ag', t('pages.subscriptions.tabs.subscription_assignment.table.netops.module_names.nom_ag')],
        ['dop', t('pages.subscriptions.tabs.subscription_assignment.table.netops.module_names.nom_dop')],
        ['sdi', t('pages.subscriptions.tabs.subscription_assignment.table.netops.module_names.nom_sdi')],
    ]);
}
export var NOM_MODULE_NAME_MAP = {
    ag: 'Automation Gateway',
    dop: 'Secure Provisioning',
    sdi: 'IP Access',
};
export var DEFAULT_MOCK_NETOPS_MODULES = [
    {
        id: 'dop',
        module: 'dop',
        name: NOM_MODULE_NAME_MAP.dop,
        description: 'Enable remote nodes to act as bootstrap WAN and LAN for a new remote site.',
        status: 'available',
        version: '2.6.3',
        license_required: 'nom-prov',
        images: ['central-dop', 'remote-dop', 'dop-ui'],
        containers: [
            {
                description: null,
                local_version: null,
                name: 'central-dop',
                tags: ['latest'],
            },
            {
                description: null,
                local_version: null,
                name: 'remote-dop',
                tags: ['latest'],
            },
            {
                description: null,
                local_version: null,
                name: 'dop-ui',
                tags: ['latest'],
            },
        ],
        port: 9003,
        min_lh_version: '5.10.0',
        min_ngcs_version: '20.Q3.0',
        always_activate: true,
        retroactively_activate: false,
        running: true,
        activations: 1,
        to_update: 0,
        available_activations: 5,
        entitled_activations: 6,
    },
    {
        id: 'sdi',
        module: 'sdi',
        name: NOM_MODULE_NAME_MAP.sdi,
        description: 'Access remote management networks and IP devices via nodes.',
        status: 'available',
        version: '2.3.3',
        license_required: 'nom-sdi',
        containers: [],
        images: [],
        port: 9005,
        min_lh_version: '5.10.0',
        min_ngcs_version: '20.Q3.0',
        always_activate: false,
        retroactively_activate: false,
        running: true,
        activations: 0,
        to_update: 0,
        available_activations: 1000010,
        entitled_activations: 1000010,
    },
    {
        id: 'ag',
        module: 'ag',
        name: NOM_MODULE_NAME_MAP.ag,
        description: 'Discover and proxy access to network services in remote or otherwise unreachable locations.',
        status: 'available',
        version: '1.3.5',
        license_required: 'nom-ag',
        containers: [],
        images: [],
        port: 9006,
        min_lh_version: '5.10.0',
        min_ngcs_version: '20.Q3.0',
        always_activate: false,
        retroactively_activate: false,
        running: true,
        activations: 2,
        to_update: 0,
        available_activations: 8,
        entitled_activations: 10,
    },
];
export var DEFAULT_MOCK_LICENSES_AVAILABLE = [
    {
        id: 'licenses-available-0',
        license: 'nom-prov',
        nodes: 50,
    },
    {
        id: 'licenses-available-1',
        license: 'nom-sdi',
        nodes: 1050,
    },
];
export var mockNomModules = {
    status: 'running',
    error_message: '',
    modules: [
        {
            planned_spec: {
                timestamp: '1709686217234310614',
                key: {
                    name: 'dop',
                },
                fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:2',
                plan_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:2',
                netops_module: {
                    product: 'PRODUCT_DOP',
                    version: '2.6.3',
                    running: true,
                    module: 'dop',
                    repository_tags: {
                        'localhost:5005/central-dop:2.6.3': 'sha256:fe4426e624b2787653ec026021b512e86429123034cdd5fae2f6b0134b55eb97',
                        'localhost:5005/remote-dop:2.3.2': 'sha256:271002d2d0df71740f172f2498ef008af58aeb49585adb6ca9cb080b698525a6',
                        'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                    },
                    deployment_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:0',
                },
                requirements: [
                    {
                        name: 'deployment',
                    },
                    {
                        name: 'registry',
                    },
                ],
                labels: {},
            },
            running_spec: {
                timestamp: '1709686216242697109',
                key: {
                    name: 'dop',
                },
                fingerprint: 'dc000ef1-049b-4cbd-a18f-0fcfc6385702:0',
                plan_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:2',
                netops_module: {
                    product: 'PRODUCT_DOP',
                    version: '2.6.3',
                    running: true,
                    module: 'dop',
                    repository_tags: {
                        'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                        'localhost:5005/central-dop:2.6.3': 'sha256:fe4426e624b2787653ec026021b512e86429123034cdd5fae2f6b0134b55eb97',
                        'localhost:5005/remote-dop:2.3.2': 'sha256:271002d2d0df71740f172f2498ef008af58aeb49585adb6ca9cb080b698525a6',
                    },
                    deployment_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:0',
                },
                requirements: [
                    {
                        name: 'deployment',
                    },
                    {
                        name: 'registry',
                    },
                ],
                labels: {},
            },
            running_as_planned: true,
            recent_deployments: [
                {
                    request: {
                        product: 'PRODUCT_DOP',
                        central: true,
                        module: 'dop',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709683902817063843',
                    started: '1709683902818967990',
                    finished: '1709683933831983860',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_DOP',
                        central: true,
                        module: 'dop',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709683862133554673',
                    started: '1709683862135726141',
                    finished: '1709683893147174125',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_DOP',
                        central: true,
                        module: 'dop',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709518381628385950',
                    started: '1709518381630559888',
                    finished: '1709518414642236934',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_DOP',
                        central: true,
                        module: 'dop',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709517549977235204',
                    started: '1709517549979396822',
                    finished: '1709517581992903409',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_DOP',
                        central: true,
                        module: 'dop',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709270488846013731',
                    started: '1709270488848073849',
                    finished: '1709270520860787503',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_DOP',
                        central: true,
                        module: 'dop',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709175690102230192',
                    started: '1709175690104463080',
                    finished: '1709175721119675778',
                    error: '',
                },
            ],
            running_deployments: [],
            pending_deployments: [],
        },
        {
            planned_spec: {
                timestamp: '1709686217234310614',
                key: {
                    name: 'sdi',
                },
                fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:3',
                plan_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:3',
                netops_module: {
                    product: 'PRODUCT_SDI',
                    version: '2.3.3',
                    running: true,
                    module: 'sdi',
                    repository_tags: {
                        'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                        'localhost:5005/ip-access-remote:1.3.2': 'sha256:174ed001e4d5690b93f7e392d969028519774c11a644890ff100cb7c7a095b41',
                        'localhost:5005/central-sdi:2.3.3': 'sha256:ce75af0a1aea6fb1f19d1113ca05bef3472aba3005c17b4299d5f4afff7d8294',
                    },
                    deployment_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:0',
                },
                requirements: [
                    {
                        name: 'deployment',
                    },
                    {
                        name: 'registry',
                    },
                ],
                labels: {},
            },
            running_spec: {
                timestamp: '1709686215240556585',
                key: {
                    name: 'sdi',
                },
                fingerprint: 'abcfdd16-6088-4d8e-84fa-ea61b245f7d2:0',
                plan_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:3',
                netops_module: {
                    product: 'PRODUCT_SDI',
                    version: '2.3.3',
                    running: true,
                    module: 'sdi',
                    repository_tags: {
                        'localhost:5005/central-sdi:2.3.3': 'sha256:ce75af0a1aea6fb1f19d1113ca05bef3472aba3005c17b4299d5f4afff7d8294',
                        'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                        'localhost:5005/ip-access-remote:1.3.2': 'sha256:174ed001e4d5690b93f7e392d969028519774c11a644890ff100cb7c7a095b41',
                    },
                    deployment_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:0',
                },
                requirements: [
                    {
                        name: 'deployment',
                    },
                    {
                        name: 'registry',
                    },
                ],
                labels: {},
            },
            running_as_planned: true,
            recent_deployments: [
                {
                    request: {
                        product: 'PRODUCT_SDI',
                        central: true,
                        module: 'sdi',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709165748457499383',
                    started: '1709165748459640381',
                    finished: '1709165776473724957',
                    error: '',
                },
            ],
            running_deployments: [],
            pending_deployments: [],
        },
        {
            planned_spec: {
                timestamp: '1709686217234310614',
                key: {
                    name: 'ag',
                },
                fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:4',
                plan_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:4',
                netops_module: {
                    product: 'PRODUCT_AG',
                    version: '1.3.5',
                    running: true,
                    module: 'ag',
                    repository_tags: {
                        'localhost:5005/ag-auth:1.1.1': 'sha256:9db1da0e0182774893c93667569aca297cf561ab7189115af999cae40b5c5a91',
                        'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                        'localhost:5005/guacamole:1.2.0': 'sha256:7b58f9298fb19f21b25d826a9537a0dc62f3c7948128287ebc70fc170eed3081',
                        'localhost:5005/ag-devices:1.3.3': 'sha256:e5897e1e2114739061c73cad7e0fda5fa884c4586ffdbd3728ff89f2da2238fb',
                        'localhost:5005/ag-remote:1.1.1': 'sha256:02eab3d9ee768d7570a1659872ecf6d701bf064faeba566012b8354b1561cc7b',
                        'localhost:5005/ag-proxy:1.0.1': 'sha256:f2557223debc6ad87c2208e250f6c2504892e4c1f4108c6f6374a5c92200ac07',
                        'localhost:5005/central-ag:1.3.5': 'sha256:c44f6796cd6f063856b850c011aa36968758cbf06728893f284725a7ecbe15c3',
                    },
                    deployment_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:0',
                },
                requirements: [
                    {
                        name: 'deployment',
                    },
                    {
                        name: 'registry',
                    },
                ],
                labels: {},
            },
            running_spec: {
                timestamp: '1709686216241283883',
                key: {
                    name: 'ag',
                },
                fingerprint: '860ae1b3-5c94-4b59-83a3-92f5a235c4d4:0',
                plan_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:4',
                netops_module: {
                    product: 'PRODUCT_AG',
                    version: '1.3.5',
                    running: true,
                    module: 'ag',
                    repository_tags: {
                        'localhost:5005/ag-auth:1.1.1': 'sha256:9db1da0e0182774893c93667569aca297cf561ab7189115af999cae40b5c5a91',
                        'localhost:5005/ag-proxy:1.0.1': 'sha256:f2557223debc6ad87c2208e250f6c2504892e4c1f4108c6f6374a5c92200ac07',
                        'localhost:5005/ag-remote:1.1.1': 'sha256:02eab3d9ee768d7570a1659872ecf6d701bf064faeba566012b8354b1561cc7b',
                        'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                        'localhost:5005/guacamole:1.2.0': 'sha256:7b58f9298fb19f21b25d826a9537a0dc62f3c7948128287ebc70fc170eed3081',
                        'localhost:5005/ag-devices:1.3.3': 'sha256:e5897e1e2114739061c73cad7e0fda5fa884c4586ffdbd3728ff89f2da2238fb',
                        'localhost:5005/central-ag:1.3.5': 'sha256:c44f6796cd6f063856b850c011aa36968758cbf06728893f284725a7ecbe15c3',
                    },
                    deployment_fingerprint: '35b688f2-b454-4c1b-b6e6-e51166029401:0',
                },
                requirements: [
                    {
                        name: 'deployment',
                    },
                    {
                        name: 'registry',
                    },
                ],
                labels: {},
            },
            running_as_planned: true,
            recent_deployments: [
                {
                    request: {
                        product: 'PRODUCT_AG',
                        central: true,
                        module: 'ag',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709686059761740594',
                    started: '1709686059763851922',
                    finished: '1709686093775382063',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_AG',
                        central: true,
                        module: 'ag',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709515684459086142',
                    started: '1709515684461493721',
                    finished: '1709515719471196102',
                    error: '',
                },
                {
                    request: {
                        product: 'PRODUCT_AG',
                        central: true,
                        module: 'ag',
                        nodes: [],
                    },
                    success: true,
                    queued: '1709515375548083322',
                    started: '1709515375554541796',
                    finished: '1709515411569283191',
                    error: '',
                },
            ],
            running_deployments: [],
            pending_deployments: [],
        },
    ],
};
export var DEFAULT_MOCK_MODULES_STATE = {
    timestamp: '1714046944427870148',
    lighthouse: {
        version: '5.16.0p0',
        entitlements: [
            {
                id: 'OGLH',
                contact: {
                    email: 'xxx@xxx.com',
                    name: 'xxx',
                    phone: '0000 000 000',
                },
                features: {
                    maintenance: '1767139200',
                    nodes: '5000',
                },
            },
            {
                id: 'nom-prov',
                contact: {
                    email: 'xxx@xxx.com',
                    name: 'xxx',
                    phone: '0000 000 000',
                },
                features: {
                    maintenance: '1767139200',
                    nodes: '5000',
                },
            },
            {
                id: 'nom-sdi',
                contact: {
                    email: 'xxx@xxx.com',
                    name: 'xxx',
                    phone: '0000 000 000',
                },
                features: {
                    maintenance: '1767139200',
                    nodes: '5000',
                },
            },
            {
                id: 'nom-ag',
                contact: {
                    email: 'xxx@xxx.com',
                    name: 'xxx',
                    phone: '0000 000 000',
                },
                features: {
                    maintenance: '1767139200',
                    nodes: '5000',
                },
            },
        ],
        multi_instance: {
            ip_address: '172.16.1.0/24',
            primary_ip_address: '172.16.1.1',
        },
    },
    modules: [
        {
            product: 'PRODUCT_DOP',
            short_name: 'dop',
            long_name: 'Secure Provisioning',
            description: 'Enable remote nodes to act as bootstrap WAN and LAN for a new remote site.',
            license_required: 'nom-prov',
            lighthouse_port: 9003,
            available: true,
            current_version: '2.6.1',
            available_version: '2.6.3',
            versions: [
                {
                    module_version: '1.2.0',
                    module_images: [
                        {
                            name: 'central-dop',
                            version: '1.2.0',
                            image_name: 'localhost:5005/central-dop:1.2.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'remote-dop',
                            version: '1.2.0',
                            image_name: 'localhost:5005/remote-dop:1.2.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'dop-ui',
                            version: '1.2.0',
                            image_name: 'localhost:5005/dop-ui:1.2.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                    ],
                    lh_version_min: '5.4.0',
                    ngcs_version_min: '18.Q4.0',
                    available: true,
                    ogcs_version_min: '',
                },
                {
                    module_version: '1.1.1',
                    module_images: [
                        {
                            name: 'central-dop',
                            version: '1.1.1',
                            image_name: 'localhost:5005/central-dop:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'remote-dop',
                            version: '1.1.1',
                            image_name: 'localhost:5005/remote-dop:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'dop-ui',
                            version: '1.1.1',
                            image_name: 'localhost:5005/dop-ui:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                    ],
                    lh_version_min: '5.3.0',
                    ngcs_version_min: '18.Q3.0',
                    available: true,
                    ogcs_version_min: '',
                },
            ],
            running: true,
            error_message: '',
        },
        {
            product: 'PRODUCT_SDI',
            short_name: 'sdi',
            long_name: 'IP Access',
            description: 'Access remote management networks and IP devices via nodes.',
            license_required: 'nom-sdi',
            lighthouse_port: 9005,
            available: true,
            current_version: '2.3.3',
            available_version: '2.3.3',
            versions: [
                {
                    module_version: '1.0.1',
                    module_images: [
                        {
                            name: 'central-sdi',
                            version: '1.0.1',
                            image_name: 'localhost:5005/central-sdi:1.0.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'sdi-ui',
                            version: '1.0.1',
                            image_name: 'localhost:5005/sdi-ui:1.0.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                    ],
                    lh_version_min: '5.4.0',
                    ngcs_version_min: '18.Q3.0',
                    available: true,
                    ogcs_version_min: '',
                },
                {
                    module_version: '1.0.0',
                    module_images: [
                        {
                            name: 'central-sdi',
                            version: '1.0.0',
                            image_name: 'localhost:5005/central-sdi:1.0.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'sdi-ui',
                            version: '1.0.0',
                            image_name: 'localhost:5005/sdi-ui:1.0.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                    ],
                    lh_version_min: '5.4.0',
                    ngcs_version_min: '18.Q3.0',
                    available: true,
                    ogcs_version_min: '',
                },
            ],
            running: true,
            error_message: '',
        },
        {
            product: 'PRODUCT_AG',
            short_name: 'ag',
            long_name: 'Automation Gateway',
            description: 'Discover and proxy access to network services in remote or otherwise unreachable locations.',
            license_required: 'nom-ag',
            lighthouse_port: 9006,
            available: true,
            current_version: '1.3.5',
            available_version: '1.3.5',
            versions: [
                {
                    module_version: '1.3.5',
                    module_images: [
                        {
                            name: 'ag-devices',
                            version: '1.3.3',
                            image_name: 'localhost:5005/ag-devices:1.3.3',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'ag-auth',
                            version: '1.1.1',
                            image_name: 'localhost:5005/ag-auth:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'ag-remote',
                            version: '1.1.1',
                            image_name: 'localhost:5005/ag-remote:1.1.1',
                            platforms: ['amd64', 'arm/v7'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'ag-proxy',
                            version: '1.0.1',
                            image_name: 'localhost:5005/ag-proxy:1.0.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'storage-api',
                            version: '1.1.1',
                            image_name: 'localhost:5005/storage-api:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'central-ag',
                            version: '1.3.5',
                            image_name: 'localhost:5005/central-ag:1.3.5',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'guacamole',
                            version: '1.2.0',
                            image_name: 'localhost:5005/guacamole:1.2.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                    ],
                    lh_version_min: '5.10.0',
                    ngcs_version_min: '20.Q3.0',
                    ogcs_version_min: '4.5.0',
                    available: true,
                },
                {
                    module_version: '1.3.4',
                    module_images: [
                        {
                            name: 'ag-devices',
                            version: '1.3.2',
                            image_name: 'localhost:5005/ag-devices:1.3.2',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'ag-auth',
                            version: '1.1.1',
                            image_name: 'localhost:5005/ag-auth:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'ag-remote',
                            version: '1.1.1',
                            image_name: 'localhost:5005/ag-remote:1.1.1',
                            platforms: ['amd64', 'arm/v7'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'ag-proxy',
                            version: '1.0.1',
                            image_name: 'localhost:5005/ag-proxy:1.0.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'storage-api',
                            version: '1.1.1',
                            image_name: 'localhost:5005/storage-api:1.1.1',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'central-ag',
                            version: '1.3.4',
                            image_name: 'localhost:5005/central-ag:1.3.4',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                        {
                            name: 'guacamole',
                            version: '1.2.0',
                            image_name: 'localhost:5005/guacamole:1.2.0',
                            platforms: ['amd64'],
                            images: [],
                            containers: [],
                        },
                    ],
                    lh_version_min: '5.10.0',
                    ngcs_version_min: '20.Q3.0',
                    ogcs_version_min: '4.5.0',
                    available: true,
                },
            ],
            running: true,
            error_message: '',
        },
    ],
    images: [
        {
            id: 'fe4426e624b2',
            created_at: '1697082570000000000',
            repositories: ['localhost:5005/central-dop', 'public.ecr.aws/opengear/netops/central-dop'],
            repository_tags: ['localhost:5005/central-dop:2.6.3', 'public.ecr.aws/opengear/netops/central-dop:2.6.3'],
        },
        {
            id: 'afacd3d74b42',
            created_at: '1680697173000000000',
            repositories: ['localhost:5005/ip-access-remote', 'public.ecr.aws/opengear/netops/ip-access-remote'],
            repository_tags: ['public.ecr.aws/opengear/netops/ip-access-remote:1.3.2'],
        },
        {
            id: 'c44f6796cd6f',
            created_at: '1680696174000000000',
            repositories: ['localhost:5005/central-ag', 'public.ecr.aws/opengear/netops/central-ag'],
            repository_tags: ['localhost:5005/central-ag:1.3.5', 'public.ecr.aws/opengear/netops/central-ag:1.3.5'],
        },
        {
            id: 'ce75af0a1aea',
            created_at: '1680696145000000000',
            repositories: ['localhost:5005/central-sdi', 'public.ecr.aws/opengear/netops/central-sdi'],
            repository_tags: ['localhost:5005/central-sdi:2.3.3', 'public.ecr.aws/opengear/netops/central-sdi:2.3.3'],
        },
        {
            id: '174ed001e4d5',
            created_at: '1680696041000000000',
            repositories: ['localhost:5005/ip-access-remote', 'public.ecr.aws/opengear/netops/ip-access-remote'],
            repository_tags: ['localhost:5005/ip-access-remote:1.3.2'],
        },
        {
            id: 'e5897e1e2114',
            created_at: '1680696022000000000',
            repositories: ['localhost:5005/ag-devices', 'public.ecr.aws/opengear/netops/ag-devices'],
            repository_tags: ['localhost:5005/ag-devices:1.3.3', 'public.ecr.aws/opengear/netops/ag-devices:1.3.3'],
        },
        {
            id: '1a5ec3ffb199',
            created_at: '1654561240000000000',
            repositories: ['localhost:5005/ag-remote', 'public.ecr.aws/opengear/netops/ag-remote'],
            repository_tags: ['public.ecr.aws/opengear/netops/ag-remote:1.1.1'],
        },
        {
            id: '271002d2d0df',
            created_at: '1654559991000000000',
            repositories: ['localhost:5005/remote-dop', 'public.ecr.aws/opengear/netops/remote-dop'],
            repository_tags: ['localhost:5005/remote-dop:2.3.2', 'public.ecr.aws/opengear/netops/remote-dop:2.3.2'],
        },
        {
            id: 'f2557223debc',
            created_at: '1654559904000000000',
            repositories: ['localhost:5005/ag-proxy', 'public.ecr.aws/opengear/netops/ag-proxy'],
            repository_tags: ['localhost:5005/ag-proxy:1.0.1', 'public.ecr.aws/opengear/netops/ag-proxy:1.0.1'],
        },
        {
            id: '02eab3d9ee76',
            created_at: '1654559881000000000',
            repositories: ['localhost:5005/ag-remote', 'public.ecr.aws/opengear/netops/ag-remote'],
            repository_tags: ['localhost:5005/ag-remote:1.1.1'],
        },
        {
            id: '9db1da0e0182',
            created_at: '1654559880000000000',
            repositories: ['localhost:5005/ag-auth', 'public.ecr.aws/opengear/netops/ag-auth'],
            repository_tags: ['localhost:5005/ag-auth:1.1.1', 'public.ecr.aws/opengear/netops/ag-auth:1.1.1'],
        },
        {
            id: 'c8a45d172ee7',
            created_at: '1654559858000000000',
            repositories: ['localhost:5005/storage-api', 'public.ecr.aws/opengear/netops/storage-api'],
            repository_tags: ['localhost:5005/storage-api:1.1.1', 'public.ecr.aws/opengear/netops/storage-api:1.1.1'],
        },
    ],
    containers: [
        {
            id: '431a4636678d',
            created_at: '1713926421000000000',
            names: ['deployment'],
            image_id: '440b74bd3a2d',
            image_name: '440b74bd3a2d',
            running: true,
            labels: {
                version: '4.4.4',
                description: 'The NetOps Modules deployment manager',
            },
            pid: 10820,
        },
        {
            id: '3585de392486',
            created_at: '1713405781000000000',
            names: ['ag-published'],
            image_id: 'c44f6796cd6f',
            image_name: 'localhost:5005/central-ag:1.3.5',
            running: true,
            labels: {
                'license_required': 'nom-ag',
                'version': '1.3.5',
                'com.opengear.netops.config.hangup': 'false',
                'com.opengear.netops.module.name': 'ag',
                'com.opengear.netops.module.version': '1.3.5',
                'description': 'NetOps Automation Gateway central container.',
            },
            pid: 8996,
        },
        {
            id: '25a0eeab80e2',
            created_at: '1713405776000000000',
            names: ['central-ag'],
            image_id: 'c44f6796cd6f',
            image_name: 'localhost:5005/central-ag:1.3.5',
            running: true,
            labels: {
                'version': '1.3.5',
                'com.opengear.netops.config.hangup': 'false',
                'com.opengear.netops.module.name': 'ag',
                'com.opengear.netops.module.version': '1.3.5',
                'description': 'NetOps Automation Gateway central container.',
                'license_required': 'nom-ag',
            },
            pid: 9084,
        },
        {
            id: 'a639d1057f77',
            created_at: '1713405773000000000',
            names: ['ag-proxy'],
            image_id: 'f2557223debc',
            image_name: 'localhost:5005/ag-proxy:1.0.1',
            running: true,
            labels: {
                'com.opengear.netops.config.hangup': 'false',
                'com.opengear.netops.module.name': 'ag',
                'com.opengear.netops.module.version': '1.3.5',
            },
            pid: 9015,
        },
        {
            id: '9a642e0abcf6',
            created_at: '1713405746000000000',
            names: ['ag-auth'],
            image_id: '9db1da0e0182',
            image_name: 'localhost:5005/ag-auth:1.1.1',
            running: true,
            labels: {
                'com.opengear.netops.config.hangup': 'false',
                'com.opengear.netops.module.name': 'ag',
                'com.opengear.netops.module.version': '1.3.5',
            },
            pid: 24258,
        },
        {
            id: 'd692df3c5df5',
            created_at: '1713405743000000000',
            names: ['ag-storage-api'],
            image_id: 'c8a45d172ee7',
            image_name: 'localhost:5005/storage-api:1.1.1',
            running: true,
            labels: {
                'com.opengear.netops.config.hangup': 'true',
                'com.opengear.netops.module.name': 'ag',
                'com.opengear.netops.module.version': '1.3.5',
            },
            pid: 8922,
        },
        {
            id: '35d0aabc10ae',
            created_at: '1713405737000000000',
            names: ['ag-devices'],
            image_id: 'e5897e1e2114',
            image_name: 'localhost:5005/ag-devices:1.3.3',
            running: true,
            labels: {
                'com.opengear.netops.module.version': '1.3.5',
                'com.opengear.netops.config.hangup': 'true',
                'com.opengear.netops.module.name': 'ag',
            },
            pid: 23780,
        },
        {
            id: '08682c9c8498',
            created_at: '1713405712000000000',
            names: ['central-sdi'],
            image_id: 'ce75af0a1aea',
            image_name: 'localhost:5005/central-sdi:2.3.3',
            running: true,
            labels: {
                'com.opengear.netops.config.hangup': 'false',
                'com.opengear.netops.module.name': 'sdi',
                'com.opengear.netops.module.version': '2.3.3',
                'description': 'The Software Defined Infrastructure central container.',
                'license_required': 'nom-sdi',
                'version': '2.3.3',
            },
            pid: 24122,
        },
        {
            id: 'f343547d40d0',
            created_at: '1713405708000000000',
            names: ['sdi-storage-api'],
            image_id: 'c8a45d172ee7',
            image_name: 'localhost:5005/storage-api:1.1.1',
            running: true,
            labels: {
                'com.opengear.netops.module.version': '2.3.3',
                'com.opengear.netops.config.hangup': 'true',
                'com.opengear.netops.module.name': 'sdi',
            },
            pid: 9085,
        },
        {
            id: '431e112d4eb3',
            created_at: '1713405689000000000',
            names: ['central-dop'],
            image_id: 'fe4426e624b2',
            image_name: 'localhost:5005/central-dop:2.6.3',
            running: true,
            labels: {
                'com.opengear.netops.config.hangup': 'false',
                'com.opengear.netops.module.name': 'dop',
                'com.opengear.netops.module.version': '2.6.3',
                'description': 'The NetOps Device Provisioning central container.',
                'version': '2.6.3',
            },
            pid: 23402,
        },
        {
            id: '5906204e9359',
            created_at: '1713405685000000000',
            names: ['dop-storage-api'],
            image_id: 'c8a45d172ee7',
            image_name: 'localhost:5005/storage-api:1.1.1',
            running: true,
            labels: {
                'com.opengear.netops.config.hangup': 'true',
                'com.opengear.netops.module.name': 'dop',
                'com.opengear.netops.module.version': '2.6.3',
            },
            pid: 8961,
        },
    ],
    planned_specs: [
        {
            timestamp: '1714046944427870148',
            key: {
                name: 'deployment',
            },
            fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            simple_container: {
                name: 'deployment',
                run_args: [
                    '--network=netops',
                    '--mount=type=bind,source=/home/root/.ssh/,destination=/root/.ssh',
                    '--mount=type=bind,source=/etc/config/lhvpn,destination=/root/.netops/lhvpn,readonly',
                    '--mount=type=bind,source=/var/run/nom-state.sock,destination=/var/run/nom-state.sock,readonly',
                    '--publish=9002:80',
                    '--env=LH_SYSTEM_VERSION=5.16.0p0',
                    '--name=deployment',
                    '--detach',
                    '--',
                    'sha256:440b74bd3a2de135b2f80e35eb888aefa79d0c8de1f6901cc88d16ef6298ece6',
                ],
                running: true,
                liveness_command: ['curl', '--silent', '--fail', 'http://localhost/healthz'],
                create_hooks: [
                    {
                        name: '/etc/scripts/init_netops_deployment',
                        args: [],
                    },
                ],
                post_hooks: [
                    {
                        name: 'create_netops_activation_config',
                        args: [],
                    },
                ],
            },
            labels: {},
            requirements: [],
        },
        {
            timestamp: '1714046944427870148',
            key: {
                name: 'dop',
            },
            fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:2',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:2',
            netops_module: {
                product: 'PRODUCT_DOP',
                version: '2.6.3',
                running: true,
                module: 'dop',
                repository_tags: {
                    'localhost:5005/central-dop:2.6.3': 'sha256:fe4426e624b2787653ec026021b512e86429123034cdd5fae2f6b0134b55eb97',
                    'localhost:5005/remote-dop:2.3.2': 'sha256:271002d2d0df71740f172f2498ef008af58aeb49585adb6ca9cb080b698525a6',
                    'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                },
                deployment_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            },
            requirements: [
                {
                    name: 'deployment',
                },
                {
                    name: 'registry',
                },
            ],
            labels: {},
        },
        {
            timestamp: '1714046944427870148',
            key: {
                name: 'sdi',
            },
            fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:3',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:3',
            netops_module: {
                product: 'PRODUCT_SDI',
                version: '2.3.3',
                running: true,
                module: 'sdi',
                repository_tags: {
                    'localhost:5005/central-sdi:2.3.3': 'sha256:ce75af0a1aea6fb1f19d1113ca05bef3472aba3005c17b4299d5f4afff7d8294',
                    'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                    'localhost:5005/ip-access-remote:1.3.2': 'sha256:174ed001e4d5690b93f7e392d969028519774c11a644890ff100cb7c7a095b41',
                },
                deployment_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            },
            requirements: [
                {
                    name: 'deployment',
                },
                {
                    name: 'registry',
                },
            ],
            labels: {},
        },
        {
            timestamp: '1714046944427870148',
            key: {
                name: 'ag',
            },
            fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:4',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:4',
            netops_module: {
                product: 'PRODUCT_AG',
                version: '1.3.5',
                running: true,
                module: 'ag',
                repository_tags: {
                    'localhost:5005/ag-remote:1.1.1': 'sha256:02eab3d9ee768d7570a1659872ecf6d701bf064faeba566012b8354b1561cc7b',
                    'localhost:5005/ag-proxy:1.0.1': 'sha256:f2557223debc6ad87c2208e250f6c2504892e4c1f4108c6f6374a5c92200ac07',
                    'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                    'localhost:5005/central-ag:1.3.5': 'sha256:c44f6796cd6f063856b850c011aa36968758cbf06728893f284725a7ecbe15c3',
                    'localhost:5005/guacamole:1.2.0': 'sha256:7b58f9298fb19f21b25d826a9537a0dc62f3c7948128287ebc70fc170eed3081',
                    'localhost:5005/ag-devices:1.3.3': 'sha256:e5897e1e2114739061c73cad7e0fda5fa884c4586ffdbd3728ff89f2da2238fb',
                    'localhost:5005/ag-auth:1.1.1': 'sha256:9db1da0e0182774893c93667569aca297cf561ab7189115af999cae40b5c5a91',
                },
                deployment_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            },
            requirements: [
                {
                    name: 'deployment',
                },
                {
                    name: 'registry',
                },
            ],
            labels: {},
        },
    ],
    running_specs: [
        {
            timestamp: '1714046943568213165',
            key: {
                name: 'deployment',
            },
            labels: {
                'netops.deployment.Spec_SimpleContainer.container_id': '431a4636678d2483f586088ef0ff531cc378e5983a56ee9e1a6170e0b60f84d6',
            },
            fingerprint: 'daa8b008-bc02-47b4-880e-503bfae05324:0',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            simple_container: {
                name: 'deployment',
                run_args: [
                    '--network=netops',
                    '--mount=type=bind,source=/home/root/.ssh/,destination=/root/.ssh',
                    '--mount=type=bind,source=/etc/config/lhvpn,destination=/root/.netops/lhvpn,readonly',
                    '--mount=type=bind,source=/var/run/nom-state.sock,destination=/var/run/nom-state.sock,readonly',
                    '--publish=9002:80',
                    '--env=LH_SYSTEM_VERSION=5.16.0p0',
                    '--name=deployment',
                    '--detach',
                    '--',
                    'sha256:440b74bd3a2de135b2f80e35eb888aefa79d0c8de1f6901cc88d16ef6298ece6',
                ],
                running: true,
                liveness_command: ['curl', '--silent', '--fail', 'http://localhost/healthz'],
                create_hooks: [
                    {
                        name: '/etc/scripts/init_netops_deployment',
                        args: [],
                    },
                ],
                post_hooks: [
                    {
                        name: 'create_netops_activation_config',
                        args: [],
                    },
                ],
            },
            requirements: [],
        },
        {
            timestamp: '1714046942545819519',
            key: {
                name: 'registry',
            },
            labels: {
                'netops.deployment.Spec_SimpleContainer.container_id': 'a564cc2a111b5f9053cb4ae78d4faeb29495754ff45cc45075b321ced42f14c3',
            },
            fingerprint: '0d18a7a1-c085-453a-b713-1666086a8107:0',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:1',
            simple_container: {
                name: 'registry',
                run_args: [
                    '--detach',
                    '--publish=5005:5000',
                    '--network=netops',
                    '--volume=registry-vol:/var/lib/registry',
                    '--name=registry',
                    '--',
                    'sha256:0ae1560ca86fd99c4932c235774a65173f550179a92e3ba101b2ef783a701d20',
                ],
                running: true,
                liveness_command: ['nc', 'localhost', '5000'],
                create_hooks: [],
                post_hooks: [],
            },
            requirements: [
                {
                    name: 'deployment',
                },
            ],
        },
        {
            timestamp: '1714046942453905023',
            key: {
                name: 'dop',
            },
            fingerprint: 'd5266a52-670b-472e-b210-0fedb07397d8:0',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:2',
            netops_module: {
                product: 'PRODUCT_DOP',
                version: '2.6.3',
                running: true,
                module: 'dop',
                repository_tags: {
                    'localhost:5005/central-dop:2.6.3': 'sha256:fe4426e624b2787653ec026021b512e86429123034cdd5fae2f6b0134b55eb97',
                    'localhost:5005/remote-dop:2.3.2': 'sha256:271002d2d0df71740f172f2498ef008af58aeb49585adb6ca9cb080b698525a6',
                    'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                },
                deployment_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            },
            requirements: [
                {
                    name: 'deployment',
                },
                {
                    name: 'registry',
                },
            ],
            labels: {},
        },
        {
            timestamp: '1714046943470067373',
            key: {
                name: 'sdi',
            },
            fingerprint: '2ef423e8-5fc0-439a-866e-ff32fcc09bb8:0',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:3',
            netops_module: {
                product: 'PRODUCT_SDI',
                version: '2.3.3',
                running: true,
                module: 'sdi',
                repository_tags: {
                    'localhost:5005/central-sdi:2.3.3': 'sha256:ce75af0a1aea6fb1f19d1113ca05bef3472aba3005c17b4299d5f4afff7d8294',
                    'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                    'localhost:5005/ip-access-remote:1.3.2': 'sha256:174ed001e4d5690b93f7e392d969028519774c11a644890ff100cb7c7a095b41',
                },
                deployment_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            },
            requirements: [
                {
                    name: 'deployment',
                },
                {
                    name: 'registry',
                },
            ],
            labels: {},
        },
        {
            timestamp: '1714046942453795067',
            key: {
                name: 'ag',
            },
            fingerprint: 'cebc3f75-0cff-4c0b-ad31-8f63d3af92f3:0',
            plan_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:4',
            netops_module: {
                product: 'PRODUCT_AG',
                version: '1.3.5',
                running: true,
                module: 'ag',
                repository_tags: {
                    'localhost:5005/storage-api:1.1.1': 'sha256:c8a45d172ee79ef7561cc95175dec434fb7a8c8c67ce955ee4125e6db7fbfad7',
                    'localhost:5005/ag-auth:1.1.1': 'sha256:9db1da0e0182774893c93667569aca297cf561ab7189115af999cae40b5c5a91',
                    'localhost:5005/ag-remote:1.1.1': 'sha256:02eab3d9ee768d7570a1659872ecf6d701bf064faeba566012b8354b1561cc7b',
                    'localhost:5005/ag-proxy:1.0.1': 'sha256:f2557223debc6ad87c2208e250f6c2504892e4c1f4108c6f6374a5c92200ac07',
                    'localhost:5005/central-ag:1.3.5': 'sha256:c44f6796cd6f063856b850c011aa36968758cbf06728893f284725a7ecbe15c3',
                    'localhost:5005/guacamole:1.2.0': 'sha256:7b58f9298fb19f21b25d826a9537a0dc62f3c7948128287ebc70fc170eed3081',
                    'localhost:5005/ag-devices:1.3.3': 'sha256:e5897e1e2114739061c73cad7e0fda5fa884c4586ffdbd3728ff89f2da2238fb',
                },
                deployment_fingerprint: 'b412f76d-c6ac-446a-a08b-820700c73b79:0',
            },
            requirements: [
                {
                    name: 'deployment',
                },
                {
                    name: 'registry',
                },
            ],
            labels: {},
        },
    ],
    hangup_pids: [8922, 23780, 9085, 8961],
    process_started: '1713926408688012715',
    process_uptime: '33h28m55.739857418s',
    process_uuid: 'b412f76d-c6ac-446a-a08b-820700c73b79',
    unsupported: false,
    alerts: [],
    recent_deployments: [],
    pending_deployments: [],
    running_deployments: [],
};
