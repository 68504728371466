var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';
export function LoadingOverlay(_a) {
    var ariaLabel = _a.ariaLabel, dataTestId = _a["data-testid"], sx = _a.sx;
    var t = useTranslation().t;
    return (_jsx(Box, __assign({ sx: __assign({ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }, sx) }, { children: _jsx(CircularProgress, { "aria-label": ariaLabel || t('components.loading_overlay.label'), "data-testid": dataTestId || 'modal-inner-content-loader', "aria-busy": true, color: "inherit" }) })));
}
