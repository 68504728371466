var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OGButton } from '@opengear/buttons';
import { NEW_FILTER_ID } from '@/utils/filter';
export var PopoverFilterButton = forwardRef(function (_a, ref) {
    var buttonText = _a.buttonText, filter = _a.filter, props = __rest(_a, ["buttonText", "filter"]);
    var label = buttonText;
    if (filter) {
        label += ": ".concat(filter.name);
    }
    if ((filter === null || filter === void 0 ? void 0 : filter.id) === NEW_FILTER_ID) {
        label += ' *';
    }
    return (_jsx(OGButton, __assign({ ref: ref, endIcon: _jsx(ArrowDropDownIcon, {}), size: "small", variant: "secondary" }, props, { children: label })));
});
