import { useEffect } from 'react';
export function useCustomIFrameCSS(iframeRef, customCSS) {
    useEffect(function () {
        var appendCustomCSS = function () {
            var _a;
            if ((_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentDocument) {
                var iframeDocument = iframeRef.current.contentDocument;
                var styleElement = iframeDocument.createElement('style');
                styleElement.textContent = customCSS;
                iframeDocument.head.appendChild(styleElement);
            }
        };
        var iframeElement = iframeRef.current;
        iframeElement === null || iframeElement === void 0 ? void 0 : iframeElement.addEventListener('load', appendCustomCSS);
        return function () {
            iframeElement === null || iframeElement === void 0 ? void 0 : iframeElement.removeEventListener('load', appendCustomCSS);
        };
    }, [iframeRef, customCSS]);
}
