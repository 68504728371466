var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import { createContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import { OGButton } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
export var UpdateFormContext = createContext({ editable: false });
/**
 * @deprecated Use the UpdateForm component in /components/layout/form instead
 */
export function UpdateForm(_a) {
    var additionalActions = _a.additionalActions, children = _a.children, dirty = _a.dirty, editable = _a.editable, submitting = _a.submitting, valid = _a.valid, onReset = _a.onReset, props = __rest(_a, ["additionalActions", "children", "dirty", "editable", "submitting", "valid", "onReset"]);
    var t = useTranslation().t;
    var contextValue = useMemo(function () { return ({
        editable: editable,
    }); }, [editable]);
    return (_jsx(Stack, __assign({ component: "form" }, props, { children: _jsxs(UpdateFormContext.Provider, __assign({ value: contextValue }, { children: [children, _jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end", marginTop: 2 }, { children: [additionalActions, _jsx(OGButton, __assign({ disabled: !editable || !dirty || submitting, variant: "borderless", onClick: onReset }, { children: t('global.buttons.reset') })), _jsx(OGButton, __assign({ type: "submit", disabled: !editable || !valid || !dirty, loading: submitting }, { children: t('global.buttons.apply') }))] }))] })) })));
}
