import { isScriptTemplate } from '@/api/template/guards';
export var transformFormStateToCreateRequest = function (formState) {
    var name = formState.name, description = formState.description, scriptTimeout = formState.scriptTimeout, script = formState.script;
    if (!script)
        return null;
    return {
        file: script,
        template: {
            name: name,
            description: description,
            type: 'script',
            script_timeout: scriptTimeout,
        },
    };
};
export var transformFormStateToUpdateRequest = function (templateId, formState) {
    var name = formState.name, description = formState.description, scriptTimeout = formState.scriptTimeout, script = formState.script;
    return {
        id: templateId,
        file: script,
        template: {
            name: name,
            description: description,
            type: 'script',
            script_timeout: scriptTimeout,
        },
    };
};
export var transformResponseToFormState = function (response) {
    var _a;
    if (!response || !isScriptTemplate(response))
        return null;
    return {
        name: response.name,
        description: (_a = response.description) !== null && _a !== void 0 ? _a : '',
        scriptTimeout: response.script_timeout,
        scriptSize: response.script_size,
        scriptChecksum: response.script_checksum,
        script: null,
    };
};
