var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
/**
  Link component
  Only use where an HTML anchor element with a href is appropriate, else use a button element
  Renders the react-router-dom Link as the component
 */
export function OGLink(_a) {
    var children = _a.children, endIcon = _a.endIcon, _b = _a.external, external = _b === void 0 ? false : _b, startIcon = _a.startIcon, sx = _a.sx, _c = _a.target, target = _c === void 0 ? '_blank' : _c, to = _a.to, _d = _a.variant, variant = _d === void 0 ? 'standalone' : _d, ariaLabel = _a["aria-label"], otherProps = __rest(_a, ["children", "endIcon", "external", "startIcon", "sx", "target", "to", "variant", 'aria-label']);
    var sxOverrides = {
        fontStyle: 'italic',
    };
    var t = useTranslation().t;
    var variants = Array.isArray(variant) ? variant : [variant];
    if (variants.includes('standard')) {
        sxOverrides.color = 'secondary.main';
    }
    if (variants.includes('noItalic')) {
        sxOverrides.fontStyle = 'normal';
    }
    if (variants.includes('noUnderline')) {
        sxOverrides['&:hover, &:active'] = {
            textDecoration: 'none',
        };
    }
    if (startIcon) {
        sxOverrides['> svg:first-of-type'] = {
            marginRight: '4px',
        };
    }
    if (endIcon) {
        sxOverrides['> svg:last-of-type'] = {
            marginLeft: '4px',
        };
    }
    var content = (_jsxs(_Fragment, { children: [startIcon && startIcon, children, endIcon && endIcon] }));
    if (external) {
        var updatedLabel = target === '_blank' ? "".concat(ariaLabel || children, ", ").concat(t('components.links.new_tab_warning')) : ariaLabel;
        return (_jsx(MuiLink, __assign({ href: to, rel: "noopener", target: target, "aria-label": updatedLabel, sx: __assign(__assign({}, sxOverrides), sx) }, otherProps, { children: content })));
    }
    return (_jsx(MuiLink, __assign({ to: to, component: RouterLink, sx: __assign(__assign({}, sxOverrides), sx), "aria-label": ariaLabel }, otherProps, { children: content })));
}
