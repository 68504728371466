export var RETENTION_PERIOD = {
    none: 'none',
    days: 'days',
    forever: 'forever',
};
export var START_TYPE = {
    immediately: 'immediately',
    set_time: 'set_time',
};
export var REPEAT = {
    once: 'once',
    interval: 'interval',
};
