var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Close';
import { OGButton } from '@opengear/buttons';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useFormContainerContext } from '@/components/layout/forms';
export function NameField(_a) {
    var tooltip = _a.tooltip;
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            maxLength: { value: 128, message: t('components.tags.form.fields.name.errors.max_length') },
            required: t('components.tags.form.fields.name.errors.required'),
        }, render: function (_a) {
            var _b;
            var _c = _a.field, ref = _c.ref, field = __rest(_c, ["ref"]), fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "name", 
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, inputRef: ref, required: true, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('components.tags.form.fields.name.label'), tooltip && _jsx(OGIconTooltip, { title: tooltip })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' } }, field)));
        } }));
}
export function ValuesField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, resetField = _a.resetField, setFocus = _a.setFocus;
    var addButtonEl = useRef(null);
    var _b = useFieldArray({
        control: control,
        name: 'values',
    }), fields = _b.fields, append = _b.append, remove = _b.remove;
    var handleAddValue = useCallback(function () {
        append({ value: '' });
    }, [append]);
    var handleRemoveValue = useCallback(function (index) {
        if (fields.length > 1) {
            var nextTagValue = fields.length - 1 > index ? index + 1 : index - 1;
            setFocus("values.".concat(nextTagValue, ".value"));
            remove(index);
        }
        else {
            resetField('values', { defaultValue: [{ value: '' }] });
            setTimeout(function () { return setFocus("values.0.value"); }, 0);
        }
    }, [fields.length, remove, resetField, setFocus]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ display: "flex", flexDirection: "column" }, { children: fields.map(function (item, index) { return (_jsxs(Box, __assign({ display: "flex", alignItems: "start", marginBottom: 2 }, { children: [_jsx(Controller, { control: control, name: "values.".concat(index, ".value"), rules: { required: t('components.tags.form.fields.values.errors.required') }, render: function (_a) {
                                var _b;
                                var _c = _a.field, ref = _c.ref, field = __rest(_c, ["ref"]), fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ inputRef: ref, label: t('components.tags.form.fields.values.label_single'), required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, margin: "none", sx: { flex: 1, marginRight: 1 } }, field)));
                            } }), _jsx(OGButton, { "aria-label": t('components.tags.form.fields.values.remove_label'), onClick: function () {
                                handleRemoveValue(index);
                            }, sx: { '&.MuiButton-root': { marginTop: '22px' } }, size: "small", startIcon: _jsx(RemoveIcon, {}), variant: "borderless" })] }), item.id)); }) })), _jsx(OGButton, __assign({ ref: addButtonEl, startIcon: _jsx(AddCircleOutline, {}), variant: "secondary", onClick: handleAddValue }, { children: t('components.tags.form.fields.values.add_label') }))] }));
}
