var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import AutorenewIcon from '@mui/icons-material/Autorenew';
export function OGAutoRenewIcon(_a) {
    var label = _a.label, sx = _a.sx, props = __rest(_a, ["label", "sx"]);
    return (_jsx(AutorenewIcon, __assign({ fontSize: "medium" }, (label
        ? {
            'titleAccess': label,
            'aria-label': label,
        }
        : {}), { sx: __assign({ 'animation': 'rotation 2s', 'animationIterationCount': 'infinite', 'animationTimingFunction': 'linear', '@keyframes rotation': {
                from: {
                    transform: 'rotate(0deg)',
                },
                to: {
                    transform: 'rotate(359deg)',
                },
            } }, sx) }, props)));
}
