var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGDialog } from '@opengear/modals';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import format from 'string-format';
import { useInterface } from '@/api/interfaces';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { InterfaceForm, NETWORK_INTERFACE_MODAL_TITLE_ID } from './network-interface-form';
import { transformResponseToFormState } from './transformers';
export var NETWORK_INTERFACE_LOADER_ID = 'interface-modal';
export function InterfaceModal(_a) {
    var open = _a.open, handleClose = _a.handleClose, interfaceId = _a.interfaceId;
    var _b = useInterface(interfaceId), data = _b.data, isLoading = _b.isLoading;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.network_connections.network_interfaces.edit');
    var t = useTranslation().t;
    if (isLoading)
        return _jsx("div", { "data-testid": NETWORK_INTERFACE_LOADER_ID });
    if (!data) {
        return null;
    }
    var sourceValue = transformResponseToFormState(data.interface);
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose, maxWidth: "tablet" }, { children: [_jsx(DialogTitle, __assign({ id: NETWORK_INTERFACE_MODAL_TITLE_ID, display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }, { children: format(t('pages.network_interfaces.modal.title'), sourceValue.name) })), _jsx(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' } }, { children: data && _jsx(InterfaceForm, { handleClose: handleClose, data: sourceValue, permissions: { hasEditPermission: hasEditPermission } }) }))] })));
}
