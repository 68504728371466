var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import format from 'string-format';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useDocTitle } from '@/hooks/doc-title';
import { useUpdateUser, useUser } from '@/api/users';
import { useGroups } from '@/api/groups';
import { DEFAULT_AUTH_MODE, useAuthConfig } from '@/api/auth';
import { usePasswordPolicy } from '@/api/users/password-policy';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { UserForm, useReadOnlyRedirect } from '@/components/accounts/users';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useAlertNotification } from '@/hooks/alert-notification';
import { NOT_FOUND_ROUTE, LOCAL_USERS_ROUTE } from '@/constants/routes';
export var EDIT_USER_LOADING_TEST_ID = 'edit-user-loading';
function EditLocalUser() {
    var _this = this;
    var t = useTranslation().t;
    useDocTitle(t('pages.user_edit.title'));
    var updateUser = useUpdateUser();
    var navigate = useNavigate();
    var alertNotification = useAlertNotification().alertNotification;
    var userId = useParams().userId;
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404) {
            alertNotification(format(t('api.user.get.not_found.message'), userId !== null && userId !== void 0 ? userId : ''), { persistOnUnmount: true });
        }
        if (responseStatus === 403) {
            alertNotification(format(t('api.user.get.forbidden.message'), userId !== null && userId !== void 0 ? userId : ''), { persistOnUnmount: true });
        }
        navigate(NOT_FOUND_ROUTE);
    }, [alertNotification, navigate, t, userId]);
    var _a = useUIPermissions(), username = _a.username, isLoadingPermissions = _a.isLoadingPermissions, hasPermission = _a.hasPermission, permissions = _a.permissions;
    var hasUsersEditPermission = hasPermission('settings.user_management.local_users.edit');
    var hasUsersViewPermission = hasPermission('settings.user_management.local_users.view', { forcePrimary: true });
    var hasAuthViewAccess = hasPermission('settings.user_management.remote_authentication.view');
    var isSecondary = !!(permissions === null || permissions === void 0 ? void 0 : permissions.is_secondary);
    var _b = useUser(userId || '', { notFoundCallback: notFoundCallback }), userData = _b.data, isLoadingUser = _b.isLoading;
    var _c = useGroups(), groupsData = _c.data, isLoadingGroups = _c.isLoading;
    var _d = useAuthConfig({ enabled: hasAuthViewAccess || hasUsersEditPermission }), authData = _d.data, isLoadingAuth = _d.isLoading;
    var _e = usePasswordPolicy(), passwordPolicyData = _e.data, isLoadingPasswordPolicy = _e.isLoading;
    // Redirect read only users navigating to an account other than their own
    useReadOnlyRedirect({
        isSecondary: isSecondary,
        permissions: {
            hasEditPermission: hasUsersEditPermission,
            hasViewPermission: hasUsersViewPermission,
        },
        userData: userData,
        currentUser: username,
        errorMessage: format(t('api.user.get.forbidden.message'), userId !== null && userId !== void 0 ? userId : ''),
        route: LOCAL_USERS_ROUTE,
    });
    var updateUserHandler = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var updateResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(userId !== undefined)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateUser(__assign({ id: userId }, data))];
                case 1:
                    updateResponse = _a.sent();
                    return [2 /*return*/, updateResponse];
                case 2: return [2 /*return*/, undefined];
            }
        });
    }); }, [userId, updateUser]);
    var innerNode = null;
    if (isLoadingUser || isLoadingGroups || isLoadingAuth || isLoadingPasswordPolicy || isLoadingPermissions) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": EDIT_USER_LOADING_TEST_ID });
    }
    if (groupsData && passwordPolicyData && username && userData && isLoadingAuth !== true) {
        innerNode = (_jsx(UserForm, { userToEdit: userData, currentUser: username, groups: groupsData.groups, authMode: authData ? authData.auth.mode : DEFAULT_AUTH_MODE, passwordPolicy: passwordPolicyData.password_policy, submitHandler: updateUserHandler, permissions: {
                hasEditPermission: hasUsersEditPermission,
                hasViewPermission: hasUsersViewPermission,
                isSecondary: isSecondary,
            } }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 2 }, { children: t('pages.user_edit.title') })), innerNode] })));
}
export default withAuthenticationRequired(EditLocalUser);
