var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { OGPortConfiguredIcon } from '@opengear/icons/ports/port-configured';
import { OGPortUnconfiguredIcon } from '@opengear/icons/ports/port-unconfigured';
import NodePortsTable from './node-ports-table';
import { splitArrayByBlocks } from '@/utils/calculations';
export var ICON_BLOCK_TEST_ID = 'port-visualisation';
function _NodePorts(_a) {
    var node = _a.node, resourceTags = _a.resourceTags;
    var theme = useTheme();
    var t = useTranslation().t;
    var ports = node.ports;
    // convert plain array IPort[] to Array Block per 16 ports -> Array Column 2 ports
    var rearrangedPorts = useMemo(function () { return splitArrayByBlocks(ports); }, [ports]);
    var portNumber = 0;
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ "data-testid": ICON_BLOCK_TEST_ID, sx: { padding: 2, marginBottom: 2, backgroundColor: theme.palette.background.box } }, { children: _jsx(Stack, __assign({ flexWrap: "wrap", direction: "row", sx: { gap: 1 } }, { children: rearrangedPorts.map(function (block) { return (_jsx(Stack, __assign({ direction: "row", spacing: 0.5 }, { children: block.map(function (portPair) { return (_jsx(Stack, __assign({ direction: "column", alignItems: "center", spacing: 0 }, { children: portPair.map(function (port, i) {
                                portNumber += 1;
                                return (_jsx(Tooltip, __assign({ arrow: true, placement: i % 2 === 0 ? 'top' : 'bottom', title: port.label, componentsProps: { tooltip: { sx: { marginTop: '8px !important' } } } }, { children: _jsxs(Stack, __assign({ "aria-label": "Port ".concat(portNumber, " - ").concat(port.mode === 'consoleServer' ? 'Configured' : 'Unconfigured'), component: "span", direction: i % 2 === 0 ? 'column' : 'column-reverse', alignItems: "center", spacing: 0 }, { children: [_jsx("span", __assign({ style: { fontSize: '8px' } }, { children: portNumber })), port.mode === 'consoleServer' ? (_jsx(OGPortConfiguredIcon, { "aria-label": t('global.ports.configured'), fontSize: "medium" })) : (_jsx(OGPortUnconfiguredIcon, { "aria-label": t('global.ports.unconfigured'), fontSize: "medium" }))] })) }), "key-port-".concat(portNumber)));
                            }) }), "key-pair-".concat(portNumber))); }) }), "key-block-".concat(Math.ceil(portNumber / 16)))); }) })) })), _jsx(NodePortsTable, { node: node, resourceTags: resourceTags })] }));
}
// Reduce re-renders
export var NodePorts = memo(_NodePorts);
