var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { v4 as uuidV4 } from 'uuid';
import { NameField, MasqueradeCheckbox, PassiveCheckbox, CostField, AuthMethodToggleField, AuthContent, AreaField } from './interface-form-controls';
import { FormContainer } from '@/components/layout/forms';
export var getDefaultInterfaceFormState = function () { return ({
    id: uuidV4(),
    name: '',
    ip_masquerade: false,
    passive: false,
    auth_method: 'no_auth',
    area: '',
    cost: '',
    cleartext: '',
    md5_keys: [],
}); };
export function InterfaceForm(_a) {
    var onSubmit = _a.onSubmit, defaultValues = _a.defaultValues, children = _a.children, mode = _a.mode, permissions = _a.permissions, configurationMethod = _a.configurationMethod;
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    return (_jsxs(FormContainer, __assign({ mode: mode, methods: methods, permissions: permissions, onSubmit: onSubmit }, { children: [_jsxs(Stack, __assign({ sx: { px: 2, pb: 2 } }, { children: [_jsx(NameField, {}), _jsxs(Stack, __assign({ gap: 1, paddingBottom: 2 }, { children: [_jsx(MasqueradeCheckbox, {}), _jsx(PassiveCheckbox, {})] })), _jsx(AreaField, { configurationMethod: configurationMethod }), _jsx(CostField, {}), _jsx(AuthMethodToggleField, {}), _jsx(AuthContent, {})] })), _jsx(Divider, {}), children] })));
}
