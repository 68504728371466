var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useReducer } from 'react';
import { IQueryDataType, IQueryNodeField, IQueryOperation, IQueryPortField, IQuerySmfResourceField, IQueryType } from '../../../types/query/types';
var regex = /"([^"]+)"|\S+/g;
export var useFreeTextSearch = function (fieldNames) {
    var _a = __read(useReducer(function (_, queryString) {
        var queryItems = [];
        var match;
        match = regex.exec(queryString);
        var _loop_1 = function () {
            var word = match[0];
            if (word.startsWith('"')) {
                word = word.slice(1);
            }
            if (word.endsWith('"')) {
                word = word.slice(0, -1);
            }
            var item = {
                type: IQueryType.or,
                items: fieldNames.map(function (fieldName) { return ({
                    fieldname: fieldName,
                    oper: fieldName === IQueryNodeField.id ? IQueryOperation.equal : IQueryOperation.contains,
                    datatype: fieldName === IQueryNodeField.id || fieldName === IQueryPortField.tag || fieldName === IQuerySmfResourceField.tag
                        ? IQueryDataType.reference
                        : IQueryDataType.string,
                    value: word,
                    type: IQueryType.basic,
                }); }),
            };
            queryItems.push(item);
            match = regex.exec(queryString);
        };
        while (match !== null) {
            _loop_1();
        }
        return queryItems.length > 0 ? { type: IQueryType.and, items: queryItems } : undefined;
    }, undefined), 2), textQueryObject = _a[0], dispatchTextQuery = _a[1];
    return {
        textQueryObject: textQueryObject,
        dispatchTextQuery: dispatchTextQuery,
    };
};
