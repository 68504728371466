var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import { OGLink } from '@opengear/links';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAnchorOpen } from '@/hooks/open';
import { SupportReportButton } from '@/containers/menus/help-menu/support-report-button';
import { useSupportReport } from '@/api/support-report';
import { useBeforeUnload } from '@/hooks/before-unload';
import { OG_CONTACT_US_LINK } from '@/constants/links';
import { useUIPermissions } from '@/api/sessions/self/ui';
var USER_MANUAL_LINK = 'https://resources.opengear.com/lighthouse/manuals/24.06/Content/UA/Global/Home.htm';
function HelpMenuItem(_a) {
    var children = _a.children;
    return (_jsx(Box, __assign({ display: "flex", marginX: 2, marginY: 2, textAlign: "left", gap: 1 }, { children: children })));
}
export function HelpMenu() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var helpMenuButtonRef = useRef(null);
    var _a = useAnchorOpen(), anchorEl = _a.anchorEl, open = _a.open, handleOpen = _a.handleOpen, handleClose = _a.handleClose;
    var supportReport = useSupportReport();
    useBeforeUnload(supportReport.status === 'loading', function () {
        if (helpMenuButtonRef.current)
            handleOpen(helpMenuButtonRef.current);
    });
    return (_jsxs(_Fragment, { children: [_jsx(ToggleButton, __assign({ ref: helpMenuButtonRef, "aria-expanded": open, "aria-haspopup": true, "aria-label": t('navigation.help_menu.label'), selected: open, value: "checked", onChange: handleOpen, "aria-controls": "help-popover" }, { children: _jsx(HelpIcon, {}) })), _jsx(Popover, __assign({ open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, onClose: handleClose }, { children: _jsxs(Box, __assign({ id: "help-popover" }, { children: [hasPermission('help.support_report.view') && (_jsx(HelpMenuItem, { children: _jsx(SupportReportButton, __assign({}, supportReport)) })), _jsx(HelpMenuItem, { children: _jsx(OGLink, __assign({ to: USER_MANUAL_LINK, external: true, endIcon: _jsx(OpenInNewIcon, { fontSize: "small" }) }, { children: t('navigation.help_menu.links.user_manual') })) }), _jsx(HelpMenuItem, { children: _jsx(OGLink, __assign({ to: OG_CONTACT_US_LINK, external: true, endIcon: _jsx(OpenInNewIcon, { fontSize: "small" }) }, { children: t('navigation.help_menu.links.support_website') })) })] })) }))] }));
}
