var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import MuiList from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import MuiListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import { useAnchorOpen } from '@/hooks/open';
/**
 * ChipPopover
 * tags: array of string values to use for each <Chip/>;
 * tabIndex: number of the tab index to apply to see more
 */
export function ChipPopover(_a) {
    var tags = _a.tags, children = _a.children, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    var _b = useAnchorOpen(), anchorEl = _b.anchorEl, open = _b.open, handleClose = _b.handleClose, handleOpen = _b.handleOpen;
    var showMoreButtonRef = useRef(null);
    // Tags to be shown in the table
    var visibleTags = tags.slice(0, 4);
    // Tags to be shown in the hidden list
    var extraTags = tags.slice(4);
    return (_jsxs(_Fragment, { children: [_jsx(Popover, __assign({ open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, tabIndex: -1, onClose: handleClose }, { children: _jsx(MuiList, __assign({ sx: { padding: 1.2, maxWidth: '160px', gap: 0.7 }, "aria-label": t('pages.node.ports.table.label') }, { children: extraTags.map(function (tag) { return (_jsx(MuiListItem, __assign({ sx: { padding: 0, wordWrap: 'anywhere' }, tabIndex: -1 }, { children: tag.resource_tag_name ? tag.resource_tag_name : "".concat(tag.name, ":").concat(tag.value) }), tag.id)); }) })) })), _jsxs(Stack, __assign({ direction: "row", flexWrap: "wrap", sx: { gap: 0.7 }, tabIndex: tabIndex }, { children: [visibleTags.map(function (tag) { return (_jsx(Tooltip, __assign({ title: tag.resource_tag_name, placement: "top" }, { children: _jsx(Chip, { label: tag.resource_tag_name || "".concat(tag.name, ":").concat(tag.value), sx: {
                                width: 'max-content',
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                lineHeight: 'normal',
                                textOverflow: 'ellipsis',
                                cursor: 'default',
                            } }) }), tag.id)); }), extraTags.length > 0 && (_jsx(ToggleButton, __assign({ sx: { height: 20, width: 'max-content', marginLeft: 0.5, paddingX: 0.5, paddingY: 'auto', textTransform: 'initial', lineHeight: 1 }, "aria-expanded": open, "aria-haspopup": true, "aria-label": t('components.see_more_button.see_more_label'), value: "checked", onClick: handleOpen, tabIndex: tabIndex, ref: showMoreButtonRef, onKeyDown: function (event) {
                            if (event.code === 'Space') {
                                event.stopPropagation();
                            }
                        } }, { children: t('pages.node.ports.table.buttons.see_more') }))), children] }))] }));
}
