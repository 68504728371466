import { jsx as _jsx } from "react/jsx-runtime";
import format from 'string-format';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
export function ApproximateTimeMessage(_a) {
    var _b = _a.now, now = _b === void 0 ? dayjs() : _b, futureDate = _a.futureDate;
    var t = useTranslation().t;
    if (!now.isValid() || !futureDate.isValid() || futureDate.isBefore(now))
        return null;
    var millisUntil = futureDate.valueOf() - now.valueOf();
    var hoursUntil = millisUntil / (60 * 60 * 1000);
    var hour = Math.floor(hoursUntil % 24);
    var day = Math.floor(hoursUntil / 24);
    var hourString = "".concat(hour, " ").concat(hour === 1 ? t('global.units.hour.long') : t('global.units.hour.plural'));
    var dayString = "".concat(day, " ").concat(day === 1 ? t('global.units.day.long') : t('global.units.day.plural'));
    var approximateTime = '';
    if (day === 0) {
        approximateTime = format(t('pages.firmware_upgrade_new.fields.scheduled_time.approximate_time.approximate'), hourString);
    }
    if (day === 0 && hour === 0) {
        approximateTime = t('pages.firmware_upgrade_new.fields.scheduled_time.approximate_time.less_than_hour');
    }
    if (day !== 0 && hour === 0) {
        approximateTime = format(t('pages.firmware_upgrade_new.fields.scheduled_time.approximate_time.approximate'), dayString);
    }
    if (day !== 0 && hour !== 0) {
        approximateTime = format(t('pages.firmware_upgrade_new.fields.scheduled_time.approximate_time.approximate_days_and_hours'), dayString, hourString);
    }
    if (!approximateTime)
        return null;
    return _jsx(FormHelperText, { children: approximateTime });
}
