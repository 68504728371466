var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useReducer } from 'react';
import { checkSelectedFilterApplied, verifySelectedNodeFilter } from './node-filter-utils';
import { useNodeSmartgroups } from '@/api/nodes/smartgroups';
var NodeFilterContext = createContext(null);
/**
 * Node Filter Context Consumer
 */
export function useNodeFilterContext() {
    var context = useContext(NodeFilterContext);
    if (!context) {
        throw new Error('useNodeFilterContext must be used within a NodeFilterContextProvider');
    }
    return context;
}
/**
 * Selected Node Filter Reducer
 */
var selectedNodeFilterReducer = function (state, action) {
    switch (action.type) {
        case 'clear':
            return null;
        case 'set':
            return action.payload;
        case 'updateQuery':
            return (state && __assign(__assign({}, state), { query: JSON.stringify(action.payload), queryObject: action.payload }));
        /* istanbul ignore next */
        default:
            console.error('Invalid action type for selectedNodeFilter');
            return null;
    }
};
/**
 * Hook to manage the selected node filter
 * - Validates filter from smartgroups.
 */
export function useSelectedNodeFilter() {
    var data = useNodeSmartgroups().data;
    var _a = __read(useReducer(selectedNodeFilterReducer, null), 2), _selectedNodeFilter = _a[0], dispatchSelectedNodeFilter = _a[1];
    /**
     The selected filter must be verified before passing down to context.
     This is to prevent the selected filter from being out of "sync" if the filter is deleted or changed.
     */
    var selectedNodeFilter = useMemo(function () { return verifySelectedNodeFilter(_selectedNodeFilter, data === null || data === void 0 ? void 0 : data.smartgroups); }, [_selectedNodeFilter, data === null || data === void 0 ? void 0 : data.smartgroups]);
    return {
        selectedNodeFilter: selectedNodeFilter,
        dispatchSelectedNodeFilter: dispatchSelectedNodeFilter,
    };
}
/**
 * Node Filter Context Provider
 */
export function NodeFilterContextProvider(_a) {
    var children = _a.children, filter = _a.filter, setFilter = _a.setFilter, permissions = _a.permissions;
    var _b = useSelectedNodeFilter(), selectedNodeFilter = _b.selectedNodeFilter, dispatchSelectedNodeFilter = _b.dispatchSelectedNodeFilter;
    var isSelectedFilterApplied = useMemo(function () { return checkSelectedFilterApplied(selectedNodeFilter, filter); }, [selectedNodeFilter, filter]);
    var contextValue = useMemo(function () { return ({
        filter: filter,
        setFilter: setFilter,
        selectedFilter: selectedNodeFilter,
        dispatchSelectedFilter: dispatchSelectedNodeFilter,
        isSelectedFilterApplied: isSelectedFilterApplied,
        permissions: permissions,
    }); }, [dispatchSelectedNodeFilter, filter, isSelectedFilterApplied, permissions, selectedNodeFilter, setFilter]);
    return _jsx(NodeFilterContext.Provider, __assign({ value: contextValue }, { children: children }));
}
