var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useState } from 'react';
export function useSortModel(defaultOrderBy, defaultDesc) {
    if (defaultDesc === void 0) { defaultDesc = '0'; }
    var defaultSortModel = [{ field: defaultOrderBy, sort: defaultDesc === '0' ? 'asc' : 'desc' }];
    var _a = __read(useState({
        desc: defaultDesc,
        order_by: defaultOrderBy,
    }), 2), sortQueryParams = _a[0], setSortQueryParams = _a[1];
    // Single column sorting handler
    var handleSortModelChange = useCallback(function (_a) {
        var _b = __read(_a, 1), firstSortItem = _b[0];
        if (firstSortItem === null || firstSortItem === void 0 ? void 0 : firstSortItem.sort) {
            var field_1 = firstSortItem.field;
            var desc_1 = firstSortItem.sort === 'asc' ? '0' : '1';
            setSortQueryParams(function (prevSortParams) { return (__assign(__assign({}, prevSortParams), { order_by: field_1, desc: desc_1 })); });
        }
        else {
            // Restore table sort order to defaults
            setSortQueryParams(function (prevSortParams) { return (__assign(__assign({}, prevSortParams), { order_by: defaultOrderBy, desc: defaultDesc })); });
        }
    }, [defaultDesc, defaultOrderBy]);
    return [defaultSortModel, sortQueryParams, handleSortModelChange];
}
