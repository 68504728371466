var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useResourceSmartgroups } from '@/api/smf-resources/smartgroups';
import { QUERY_PARAM_RESOURCE_FILTER_KEY } from '@/constants/query-params-keys';
import { formatResourceFilterSearchParam, parseResourceFilterSearchParam, verifyResourceFilter } from './resource-filter-utils';
/**
 * Hook to manage the applied resource filter state
 * - Initializes filter from search params.
 * - Validates filter from smartgroups.
 */
export function useResourceFilterSearchParams(permissions) {
    var data = useResourceSmartgroups({}, { enabled: permissions.hasSmartgroupsViewAccess }).data;
    // On load, initialize the filter from the search params
    var _a = __read(useSearchParams(), 2), searchParams = _a[0], setSearchParams = _a[1];
    var resourceFilterSearchParam = searchParams.get(QUERY_PARAM_RESOURCE_FILTER_KEY);
    var resourceFilter = useMemo(function () {
        var parsedResourceFilter = parseResourceFilterSearchParam(resourceFilterSearchParam);
        return verifyResourceFilter(parsedResourceFilter, data === null || data === void 0 ? void 0 : data.smartgroups);
    }, [data === null || data === void 0 ? void 0 : data.smartgroups, resourceFilterSearchParam]);
    var setResourceFilter = useCallback(function (filter) {
        setSearchParams(function (prevSearchParams) {
            if (!filter) {
                prevSearchParams.delete(QUERY_PARAM_RESOURCE_FILTER_KEY);
            }
            else {
                prevSearchParams.set(QUERY_PARAM_RESOURCE_FILTER_KEY, formatResourceFilterSearchParam(filter));
            }
            return prevSearchParams;
        });
    }, [setSearchParams]);
    return {
        resourceFilter: resourceFilter,
        setResourceFilter: setResourceFilter,
    };
}
