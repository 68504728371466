import { act } from '@testing-library/react';
import { configureAxe } from 'jest-axe';
import { LighthouseErrorCodes, formatApiPath } from '@/api/utils';
export var axe = configureAxe({ rules: { region: { enabled: false } } });
export function formatLastUpdated(date) {
    return "".concat(date.getUTCFullYear(), "-").concat(date.getUTCMonth() + 1, "-").concat(date.getUTCDate(), " ").concat(date.getUTCHours(), ":").concat(date.getUTCMinutes());
}
/**
 * Helper for testing 'use-local-storage-state' usages.
 */
export function getLocalStorageItem(key) {
    var raw = localStorage.getItem(key);
    return raw ? JSON.parse(raw) : null;
}
export function notFoundError(path) {
    return {
        error: [
            {
                code: 28,
                text: "Entity '".concat(path, "' not found."),
            },
        ],
    };
}
export function permissionDeniedError(path) {
    return {
        error: [
            {
                code: 47,
                text: "Permission denied for '".concat(path, "."),
            },
        ],
    };
}
/**
 * Helper for testing 'use-local-storage-state' usages.
 */
export function setLocalStorageItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
    var raw = localStorage.getItem(key);
    return raw ? JSON.parse(raw) : null;
}
export function setViewportWidth(window, device) {
    var availableDevices = {
        mobile: 375,
        laptop: 1199,
        desktop: 1920,
        default: 782,
    };
    act(function () {
        window.resizeTo(availableDevices[device], window.innerHeight);
    });
}
/**
 * Creates an approximate error response for Lipy from the status code
 */
export function status2error(status) {
    var error = [];
    switch (status) {
        case 400:
            error.push({
                code: LighthouseErrorCodes.InvalidField,
                text: 'Request body is invalid.',
            });
            break;
        case 401:
            error.push({
                code: LighthouseErrorCodes.NotAuthorized,
                text: 'Invalid session ID.',
            });
            break;
        case 403:
            error.push({
                code: LighthouseErrorCodes.PermissionDenied,
                text: "Permission denied for '".concat(formatApiPath('*'), "'."),
            });
            break;
        case 404:
            error.push({
                code: LighthouseErrorCodes.NotFound,
                text: "Entity '".concat(formatApiPath('*'), "' not found."),
            });
            break;
        case 500:
        default:
            error.push({
                code: LighthouseErrorCodes.InternalServerError,
                text: 'Internal server error.',
            });
            break;
    }
    return { error: error };
}
export function spyOnConsoleError() {
    return jest.spyOn(console, 'error').mockImplementation();
}
export var KNOWN_UNIMPLEMENTED_JEST_ERRORS = {
    navigateFetch: 'Not implemented: navigation (except hash changes)',
};
export var waitForMilliseconds = function (milliseconds) {
    return new Promise(function (resolve) {
        setTimeout(resolve, milliseconds);
    });
};
