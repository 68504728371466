var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import FormLabel from '@mui/material/FormLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import { OGButton } from '@opengear/buttons';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { requiredFieldError, maxLengthFieldError, minValueFieldError, maxValueFieldError, invalidFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN } from '@/constants/regex';
export function AuthServerFields() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, setFocus = _a.setFocus, setValue = _a.setValue, permissions = _a.permissions;
    var portFieldMinVal = 1;
    var portFieldMaxVal = 65535;
    var _b = useFieldArray({
        name: 'tacacsAuthenticationServers',
        rules: { required: true },
        control: control,
    }), tacacsAuthenticationServersFields = _b.fields, appendTacacsAuthenticationServer = _b.append, removeTacacsAuthenticationServer = _b.remove;
    var addServerHandler = useCallback(function () {
        appendTacacsAuthenticationServer({ hostname: '', port: 49 });
        var num = tacacsAuthenticationServersFields.length;
        setFocus("tacacsAuthenticationServers.".concat(num, ".hostname"));
    }, [appendTacacsAuthenticationServer, setFocus, tacacsAuthenticationServersFields.length]);
    var removeServerHandler = useCallback(function (index) {
        if (tacacsAuthenticationServersFields.length > 1) {
            var nextServer = tacacsAuthenticationServersFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("tacacsAuthenticationServers.".concat(nextServer, ".hostname"));
            removeTacacsAuthenticationServer(index);
        }
        else {
            setFocus("tacacsAuthenticationServers.0.hostname");
            setValue("tacacsAuthenticationServers.".concat(index, ".hostname"), '');
            setValue("tacacsAuthenticationServers.".concat(index, ".port"), 49);
        }
    }, [removeTacacsAuthenticationServer, setFocus, tacacsAuthenticationServersFields.length, setValue]);
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.remote_auth_server') }), tacacsAuthenticationServersFields.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row', spacing: 1, paddingTop: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "tacacsAuthenticationServers.".concat(index, ".hostname"), rules: {
                            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            pattern: {
                                value: HOSTNAME_OR_ADDRESS_PATTERN,
                                message: invalidFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title')),
                            },
                        }, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), error = _a.fieldState.error;
                            return (_jsx(TextField, __assign({ inputRef: ref, fullWidth: true, label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.address.title'), disabled: !permissions.hasAuthEditAccess, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true }, field)));
                        } }), _jsxs(Stack, __assign({ direction: "row", spacing: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "tacacsAuthenticationServers.".concat(index, ".port"), rules: {
                                    required: {
                                        value: true,
                                        message: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title')),
                                    },
                                    min: {
                                        value: portFieldMinVal,
                                        message: minValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMinVal.toString()),
                                    },
                                    max: {
                                        value: portFieldMaxVal,
                                        message: maxValueFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), portFieldMaxVal.toString()),
                                    },
                                }, render: function (_a) {
                                    var _b;
                                    var _c = _a.field, onChange = _c.onChange, ref = _c.ref, newField = __rest(_c, ["onChange", "ref"]), fieldState = _a.fieldState;
                                    return (_jsx(TextField, __assign({ fullWidth: true, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.title'), _jsx(OGIconTooltip, { title: format(t('pages.config_templates.edit.form.auth.auth_setting.scheme.port.default'), '49') })] }), disabled: !permissions.hasAuthEditAccess, sx: { maxWidth: isMobileView ? undefined : 200 }, inputRef: ref, required: true, type: "number" }, newField, { error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, onChange: function (e) {
                                            onChange(e.target.value ? parseInt(e.target.value, 10) : '');
                                        } })));
                                } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.button.delete'), "-").concat(index) }, { children: _jsx("div", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": "".concat(t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.button.delete'), "-").concat(index), disabled: !permissions.hasAuthEditAccess, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return removeServerHandler(index); } }) })) }))] }), "".concat(item.id, "-port"))] }), item.id)); }), _jsx(OGButton, __assign({ disabled: !permissions.hasAuthEditAccess, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: addServerHandler }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.button.auth.title') }))] }));
}
export function LoginMethodButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ component: "label" }, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.login_method'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.method_tooltip') })] })), _jsx(Stack, __assign({ direction: "column", gap: 1 }, { children: _jsx(Controller, { control: control, name: "tacacsMethod", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasAuthEditAccess, exclusive: true, onChange: function (event, v) {
                                if (v !== null) {
                                    onChange(v);
                                }
                            } }, field, { sx: { marginBottom: 1 }, orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: "pap" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.pap') })), _jsx(ToggleButton, __assign({ value: "chap" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.chap') })), _jsx(ToggleButton, __assign({ value: "login" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.login') }))] })));
                    } }) }))] }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch, permissions = _a.permissions, trigger = _a.trigger;
    var isRemoteAuth = watch('authStyle');
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "tacacsPassword", rules: {
            required: isRemoteAuth ? requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password')) : false,
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, type: "password", label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password'), error: fieldState.invalid, helperText: fieldState.error ? fieldState.error.message : null, required: !!isRemoteAuth }, field, { onBlur: function () {
                    field.onBlur();
                    trigger('confirmTacacsPassword');
                } })));
        } }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirmTacacsPassword", rules: {
            validate: {
                passwordsMatch: function (value, formValues) {
                    return formValues.tacacsPassword === formValues.confirmTacacsPassword || t('global.form.password_not_matched');
                },
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, type: "password", label: t('pages.config_templates.edit.form.auth.auth_setting.scheme.server_password_confirm'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function ServiceField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "tacacsService", rules: {
            required: requiredFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.service.title')),
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.service.title'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.service.title'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.service_tooltip') })] }) }, field)));
        } }));
}
