var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { OGPanel } from '@opengear/panel';
import { FormActions, ResetButton, SubmitButton, FormContainer } from '@/components/layout/forms';
import { nodeBackupKeys, useUpdateNodeBackup } from '@/api/services/node-backup';
import { useFormSyncV2 } from '@/hooks/use-form-sync';
import { EnableBackupsField, NumberOfBackupsField, RetentionPeriodFieldSet, LocationField, AvailableSpaceField, TotalSpaceField, EstimatedDiskSpaceUsageField, StartDateFieldSet, FrequencyFieldSet, } from './node-backup-form-controls';
import { transformRequestToFormState, transformFormStateToRequest } from './transformers';
export var NODE_BACKUP_FORM_LOADER_ID = 'node-backup-form-loader';
export function NodeBackupForm(_a) {
    var data = _a.data, permissions = _a.permissions;
    var mutate = useUpdateNodeBackup().mutate;
    var t = useTranslation().t;
    var sourceValues = useMemo(function () { return transformRequestToFormState(data); }, [data]);
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    var handleFormReset = useCallback(function (resetData) { return methods.reset(transformRequestToFormState(resetData.node_backup)); }, [methods]);
    /**
     * The following fields are omitted from the sync check:
     * - 'start_date' and 'server_time' are unique on each response object
     * - 'available_space' will frequently change on busy LH instances
     */
    useFormSyncV2(nodeBackupKeys.all, handleFormReset, t('pages.node_backup.form.reset_warning.header'), [
        'node_backup.available_space',
        'node_backup.start_date',
        'node_backup.server_time',
    ]);
    var enabled = methods.watch('enabled');
    var onSubmit = useCallback(function (formData) {
        var request = transformFormStateToRequest(formData);
        mutate({ node_backup: request }, {
            onSuccess: function (updatedData) {
                if (updatedData)
                    handleFormReset(updatedData);
            },
        });
    }, [handleFormReset, mutate]);
    return (_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ mode: "update", permissions: permissions, methods: methods, onSubmit: onSubmit }, { children: [_jsx(EnableBackupsField, {}), enabled && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 700, fontSize: 16 } }, { children: t('pages.node_backup.form.sections.storage.label') })), _jsx(NumberOfBackupsField, {}), _jsx(RetentionPeriodFieldSet, {}), _jsx(LocationField, {}), _jsx(AvailableSpaceField, {}), _jsx(TotalSpaceField, {}), _jsx(EstimatedDiskSpaceUsageField, {}), _jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 700, fontSize: 16 } }, { children: t('pages.node_backup.form.sections.scheduling.label') })), _jsx(StartDateFieldSet, {}), _jsx(FrequencyFieldSet, {})] })), _jsxs(FormActions, { children: [_jsx(ResetButton, {}), _jsx(SubmitButton, {})] })] })) }));
}
