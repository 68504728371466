var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useAnchorOpen } from '@/hooks/open';
export function TemplatePopover(_a) {
    var _b, _c, _d, _e;
    var row = _a.row, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    var _f = useAnchorOpen(), anchorEl = _f.anchorEl, open = _f.open, handleClose = _f.handleClose, handleToggle = _f.handleToggle;
    var _g = useAnchorOpen(), anchorElSuccess = _g.anchorEl, openSuccess = _g.open, handleCloseSuccess = _g.handleClose, handleClickSuccess = _g.handleToggle;
    var configPushStatus = row.config_push_status;
    return (_jsxs(Box, { children: [_jsx(Popover, __assign({ open: openSuccess, anchorEl: anchorElSuccess, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, onClose: handleCloseSuccess }, { children: configPushStatus.finished && configPushStatus.finished.length > 0 && (_jsx(MuiList, __assign({ sx: { padding: 1.2 }, id: "".concat(row.id, "-successful-template-list"), "aria-label": t('pages.nodes.table.popover.successful_templates') }, { children: configPushStatus.finished.map(function (templateName) { return (_jsx(MuiListItem, __assign({ sx: { padding: 0 } }, { children: templateName }), "".concat(row.id, "-").concat(templateName))); }) }))) })), _jsx(Popover, __assign({ open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, onClose: handleClose }, { children: configPushStatus.failed && configPushStatus.failed.length > 0 && (_jsx(MuiList, __assign({ sx: { padding: 1.2 }, id: "".concat(row.id, "-failed-template-list"), "aria-label": t('pages.nodes.table.popover.failed_templates') }, { children: configPushStatus.failed.map(function (templateName) { return (_jsx(MuiListItem, __assign({ sx: { padding: 0 } }, { children: templateName }), "".concat(row.id, "-").concat(templateName))); }) }))) })), _jsxs(Stack, { children: [configPushStatus.failed && configPushStatus.failed.length > 0 && (_jsx(ToggleButton, __assign({ sx: { height: 20, width: 'max-content', paddingX: 0.5, paddingY: 'auto', textTransform: 'initial', lineHeight: 1 }, "aria-expanded": open, "aria-haspopup": true, tabIndex: tabIndex, "aria-label": "".concat(t('pages.nodes.table.buttons.view_templates'), " ").concat(format(t('pages.nodes.table.label.template_fail'), configPushStatus.failed.length.toString())), value: "checked", onClick: handleToggle, onKeyDown: function (event) {
                            if (event.code === 'Space') {
                                event.stopPropagation();
                            }
                        } }, { children: _jsx(Typography, __assign({ align: "left" }, { children: format(t('pages.nodes.table.label.template_fail'), configPushStatus.failed.length.toString()) })) }))), configPushStatus.finished && configPushStatus.finished.length > 0 && (_jsx(ToggleButton, __assign({ sx: { height: 20, width: 'max-content', paddingX: 0.5, paddingY: 'auto', textTransform: 'initial', lineHeight: 1 }, "aria-expanded": openSuccess, "aria-haspopup": true, tabIndex: tabIndex, "aria-label": "".concat(t('pages.nodes.table.buttons.view_templates'), " ").concat(format(t('pages.nodes.table.label.template_success'), configPushStatus.finished.length.toString())), value: "checked", onClick: handleClickSuccess, onKeyDown: function (event) {
                            if (event.code === 'Space') {
                                event.stopPropagation();
                            }
                        } }, { children: _jsx(Typography, __assign({ align: "left" }, { children: format(t('pages.nodes.table.label.template_success'), configPushStatus.finished.length.toString()) })) }))), !configPushStatus || // config_push_status object doesn't exist
                        (!configPushStatus.finished && !configPushStatus.failed) || // If neither finished nor failed exist
                        (!configPushStatus.finished && ((_b = configPushStatus.failed) === null || _b === void 0 ? void 0 : _b.length) === 0) || // If finished doesn't exist and no failed items
                        (!configPushStatus.failed && ((_c = configPushStatus.finished) === null || _c === void 0 ? void 0 : _c.length) === 0) || // If failed doesn't exist and no finished items
                        (((_d = configPushStatus.finished) === null || _d === void 0 ? void 0 : _d.length) === 0 &&
                            ((_e = configPushStatus.failed) === null || _e === void 0 ? void 0 : _e.length) === 0 && ( // If both failed and finished are empty
                        _jsx(Typography, __assign({ align: "left" }, { children: t('pages.nodes.table.label.template_empty') }))))] })] }));
}
