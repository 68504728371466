var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import dayjs from 'dayjs';
import i18n from '@/i18n/config';
import { DAY_IN_MS } from '@/constants/units';
import { DATETIME_FORMAT_WITH_SEC } from '@/constants/formats';
/**
 * Given `seconds` since the epoch, formats a datetime string according to `language`.
 * @param time timestamp or time string
 * @param format format string
 * @returns formatted datetime string
 */
export function formatDateTimestamp(time, format) {
    if (format === void 0) { format = DATETIME_FORMAT_WITH_SEC; }
    if (!time)
        return '';
    return dayjs(time * 1000)
        .utc()
        .format(format);
}
export function formatDateString(time, format) {
    if (format === void 0) { format = DATETIME_FORMAT_WITH_SEC; }
    if (!time)
        return '';
    return dayjs(time).format(format);
}
/**
 * Formats `totalSeconds` in the format of
 * '<days>d <hours>h <minutes>m <seconds>s'
 *
 * Uses the current locale for formatting units.
 *
 * @param totalSeconds seconds to be formatted
 * @returns formatted duration
 */
export function secondsToDHMS(totalSeconds) {
    // Negative and 0 seconds default to '0s'
    if (totalSeconds <= 0) {
        return "0".concat(i18n.t('global.units.second.short'));
    }
    var seconds = Math.floor(totalSeconds % 60) || '';
    var minutes = Math.floor((totalSeconds % 3600) / 60) || '';
    var hours = Math.floor((totalSeconds % (3600 * 24)) / 3600) || '';
    var days = Math.floor(totalSeconds / (3600 * 24)) || '';
    var displayDays = days ? "".concat(days).concat(i18n.t('global.units.day.short'), " ") : '';
    var displayHours = hours ? "".concat(hours).concat(i18n.t('global.units.hour.short'), " ") : '';
    var displayMinutes = minutes ? "".concat(minutes).concat(i18n.t('global.units.minute.short'), " ") : '';
    var displaySeconds = seconds ? "".concat(seconds).concat(i18n.t('global.units.second.short')) : '';
    return (displayDays + displayHours + displayMinutes + displaySeconds).trim();
}
/**
 * Convert `totalSeconds` in format max time interval
 * '<year> year[s]' or
 * <month month[s]> or
 * <week>  week[s]or
 * <day> day[s]or
 * <hour> hour[s] or
 * <minute> minute[s] or
 * <second> second[s]
 *
 * Uses to get how long since the last update.
 *
 * @param changeDelta seconds to be formatted
 * @returns Max time interval
 */
export function secondsToYMWDHMS(changeDelta) {
    var e_1, _a;
    var timeCalculation = new Map(); // Map to make sure about the order from year to second
    timeCalculation.set('year', Math.floor(changeDelta / (3600 * 24 * 365)) || null);
    timeCalculation.set('month', Math.floor((changeDelta % (3600 * 24 * 365)) / (3600 * 24 * 7 * 4.345)) || null);
    timeCalculation.set('week', Math.floor((changeDelta % (3600 * 24 * 7 * 4.345)) / (3600 * 24 * 7)) || null);
    timeCalculation.set('day', Math.floor((changeDelta % (3600 * 24 * 7)) / (3600 * 24)) || null);
    timeCalculation.set('hour', Math.floor((changeDelta % (3600 * 24)) / 3600) || null);
    timeCalculation.set('minute', Math.floor((changeDelta % 3600) / 60) || null);
    timeCalculation.set('second', Math.floor(changeDelta % 60) || null);
    var deltaResult = 'n/a';
    try {
        // eslint-disable-next-line no-restricted-syntax
        for (var timeCalculation_1 = __values(timeCalculation), timeCalculation_1_1 = timeCalculation_1.next(); !timeCalculation_1_1.done; timeCalculation_1_1 = timeCalculation_1.next()) {
            var _b = __read(timeCalculation_1_1.value, 2), key = _b[0], value = _b[1];
            if (value) {
                deltaResult = "".concat(value, " ").concat(value > 1 ? i18n.t("global.units.".concat(key, ".plural")) : i18n.t("global.units.".concat(key, ".long")));
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (timeCalculation_1_1 && !timeCalculation_1_1.done && (_a = timeCalculation_1.return)) _a.call(timeCalculation_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return deltaResult;
}
/**
 * Convert `milliSeconds` to days.
 *
 * @param milliSeconds milliseconds to be formatted
 * @returns Days
 */
export function millisecondsToDays(milliSeconds) {
    return Math.ceil(milliSeconds / DAY_IN_MS);
}
