import { useRef, useEffect } from 'react';
export var USER_ACTIVITY_EVENTS = ['touchstart', 'click', 'keydown'];
export var LAST_USER_EVENT = 'last_activity';
var ACTIVITY_DEBOUNCE_TIME = 2000;
var POLLING_INTERVAL = 10000;
export function updateLastEvent(tabLast, setTabLast) {
    var currentTime = Date.now();
    if (currentTime - tabLast > ACTIVITY_DEBOUNCE_TIME) {
        setTabLast(currentTime);
        localStorage.setItem(LAST_USER_EVENT, currentTime.toString());
    }
}
/**
 * Custom hook to handle user activity timeouts.
 *
 * This hook monitors user activity events (see USER_ACTIVITY_EVENTS) and saves the last event timestamp in local storage to sync across tabs.
 * If no activity is detected within the specified timeout period, a provided callback function is executed.
 *
 * @param {number} timeout - The duration (in milliseconds) after which the timeout occurs if no user activity is detected.
 * @param {() => void} onTimeout - The callback function to execute when the timeout period elapses without any user activity.
 */
export function useActivityTimeout(timeout, onTimeout) {
    var intervalId = useRef();
    var tabLast = useRef(0); // Last activity timestamp for this tab/window
    // The first time this hook mounts, update the last event time
    useEffect(function () {
        var currentTime = Date.now();
        localStorage.setItem(LAST_USER_EVENT, currentTime.toString());
        tabLast.current = currentTime;
    }, []);
    useEffect(function () {
        function updateLastEventListener() {
            updateLastEvent(tabLast.current, function (time) {
                tabLast.current = time;
            });
        }
        USER_ACTIVITY_EVENTS.forEach(function (eventtype) {
            window.addEventListener(eventtype, updateLastEventListener);
        });
        return function () {
            USER_ACTIVITY_EVENTS.forEach(function (eventtype) {
                window.removeEventListener(eventtype, updateLastEventListener);
            });
        };
    }, []);
    useEffect(function () {
        intervalId.current = window.setInterval(function () {
            var currentTime = Date.now();
            var eventString = localStorage.getItem(LAST_USER_EVENT);
            if (eventString) {
                var lastEvent = parseInt(eventString, 10);
                if (currentTime - lastEvent > timeout) {
                    onTimeout();
                }
            }
        }, POLLING_INTERVAL);
        return function () {
            if (intervalId.current) {
                window.clearInterval(intervalId.current);
            }
        };
    }, [onTimeout, timeout]);
}
