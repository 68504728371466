var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGConsoleServerIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 24 24' }, props), { children: [_jsx("path", { fill: "none", d: "M23.047 10.0102L19.09 6.06846C18.7132 5.69267 18.1933 5.48022 17.65 5.47998H6.35C5.80681 5.47997 5.28698 5.69206 4.91 6.0675L0.953003 10.0102", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M23.5 13.16C23.5 14.2204 22.6046 15.08 21.5 15.08H2.5C1.39543 15.08 0.5 14.2204 0.5 13.16V11.24C0.5 10.1796 1.39543 9.32001 2.5 9.32001H21.5C22.6046 9.32001 23.5 10.1796 23.5 11.24V13.16Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M4.75 11.96C4.61193 11.96 4.5 12.0675 4.5 12.2C4.5 12.3326 4.61193 12.44 4.75 12.44C4.88807 12.44 5 12.3326 5 12.2C5 12.0675 4.88807 11.96 4.75 11.96V11.96", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M8.25 11.96C8.11193 11.96 8 12.0675 8 12.2C8 12.3326 8.11193 12.44 8.25 12.44C8.38807 12.44 8.5 12.3326 8.5 12.2C8.5 12.0675 8.38807 11.96 8.25 11.96V11.96", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M12 15.84V22.56", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M6.5 22.56H17.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M0.5 22.56H1.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M3.5 22.56H4.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M19.5 22.56H20.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M22.5 22.56H23.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
