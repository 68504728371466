var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { OGDialog } from '@opengear/modals';
import { OGButton } from '@opengear/buttons';
import { useCreateResourceSmartgroup } from '@/api/smf-resources/smartgroups';
import { newDefaultFilter } from '@/utils/filter';
import { ResourceFilterNameField, ResourceFilterQueryFieldset } from './resource-filter-modal-form-controls';
import { FormContainer, CancelButton, FormActions } from '@/components/layout/forms';
import { isResourceQueryValid } from '@/containers/filters/advanced-filters/resource-filter';
export var RESOURCE_FILTER_SAVE_TITLE_ID = 'resource-filter-save-title';
/**
 * ResourceFilterSaveModalContent
 * Separate content logic to help clear the form state when unmounted
 */
export function ResourceFilterSaveModalContent(_a) {
    var onClose = _a.onClose, onSubmit = _a.onSubmit, _b = _a.selectedFilter, selectedFilter = _b === void 0 ? newDefaultFilter : _b, translations = _a.translations, error = _a.error, setError = _a.setError;
    // Always clear the name value for save modal
    var defaultValues = __assign(__assign({}, selectedFilter), { name: '' });
    var mutate = useCreateResourceSmartgroup().mutate;
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    var _c = methods.formState, isValid = _c.isValid, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting;
    var handleSubmit = useCallback(function (newFilter) {
        if (isResourceQueryValid(newFilter)) {
            var request = {
                name: newFilter.name,
                queryObject: newFilter.queryObject,
            };
            mutate(request, {
                onSuccess: function (createdSmartgroup) {
                    if (createdSmartgroup)
                        onSubmit(createdSmartgroup);
                },
            });
        }
    }, [mutate, onSubmit]);
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: RESOURCE_FILTER_SAVE_TITLE_ID, mode: "create", methods: methods, onSubmit: handleSubmit }, { children: [_jsx(DialogTitle, __assign({ id: RESOURCE_FILTER_SAVE_TITLE_ID }, { children: translations.dialogTitle })), _jsxs(DialogContent, { children: [_jsx(ResourceFilterNameField, {}), _jsx(ResourceFilterQueryFieldset, { setError: setError })] }), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { paddingTop: 0 } }, { children: _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: onClose }), _jsx(OGButton, __assign({ type: "submit", disabled: !isValid || !isDirty || error, loading: isSubmitting }, { children: translations.saveBtn }))] }) }))] })));
}
export function ResourceFilterSaveModal(_a) {
    var onClose = _a.onClose, onSubmit = _a.onSubmit, open = _a.open, selectedFilter = _a.selectedFilter, translations = _a.translations, error = _a.error, setError = _a.setError;
    return (_jsx(OGDialog, __assign({ "aria-labelledby": RESOURCE_FILTER_SAVE_TITLE_ID, open: open, onClose: onClose }, { children: _jsx(ResourceFilterSaveModalContent, { onClose: onClose, onSubmit: onSubmit, selectedFilter: selectedFilter, translations: translations, setError: setError, error: error }) })));
}
