var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { RequiredPill } from '@opengear/inputs/required-pill';
import { useDeviceView } from '@/hooks/use-device-view';
import { useThemeMediaQuery } from '@/hooks/use-theme-media-query';
import { useFormContainerContext } from '@/components/layout/forms';
import { ApproximateTimeMessage } from './approximate-time-message';
import { DATETIME_INPUT_FORMAT } from '@/constants/formats';
import { invalidFieldError, requiredFieldError } from '@/utils/field-validation-errors';
export function ScheduledTimeField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var tabletMediaQuery = useThemeMediaQuery('tablet');
    var _a = useFormContainerContext(), control = _a.control, trigger = _a.trigger;
    return (_jsx(Controller, { control: control, name: "scheduledTime", rules: {
            required: requiredFieldError(t('pages.firmware_upgrade_new.fields.scheduled_time.label')),
            min: {
                value: dayjs().valueOf(),
                message: invalidFieldError(t('pages.firmware_upgrade_new.fields.scheduled_time.label')),
            },
        }, render: function (_a) {
            var fieldState = _a.fieldState, field = _a.field;
            return (_jsxs(FormControl, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', alignItems: 'flex-end' } }, { children: [_jsx(DateTimePicker, { ampm: false, format: DATETIME_INPUT_FORMAT, label: _jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 1 }, { children: [t('pages.firmware_upgrade_new.fields.scheduled_time.label'), _jsx(RequiredPill, {})] })), minDateTime: dayjs().utc(), timezone: "UTC", value: field.value, closeOnSelect: false, onChange: field.onChange, onClose: function () {
                                    trigger('scheduledTime');
                                }, desktopModeMediaQuery: tabletMediaQuery, slots: { openPickerIcon: CalendarMonthIcon }, slotProps: {
                                    openPickerIcon: {
                                        style: { fontSize: 16 },
                                    },
                                    openPickerButton: {
                                        'style': { padding: 7, marginRight: 0, borderRadius: 0 },
                                        'aria-label': t('pages.firmware_upgrade_new.fields.scheduled_time.label'),
                                    },
                                    textField: {
                                        inputProps: { 'aria-label': t('pages.firmware_upgrade_new.fields.scheduled_time.label') },
                                        margin: 'none',
                                        onBlur: function () {
                                            trigger('scheduledTime');
                                        },
                                        error: !!fieldState.error,
                                        placeholder: DATETIME_INPUT_FORMAT,
                                        sx: { 'maxWidth': isMobileView ? undefined : 300, '& .MuiInputBase-input': { paddingRight: 0 } },
                                    },
                                } }), _jsx(Typography, __assign({ sx: { marginBottom: '6px', marginLeft: 1 } }, { children: "UTC" }))] })), !!fieldState.error && _jsx(FormHelperText, __assign({ error: true }, { children: fieldState.error.message })), field.value && _jsx(ApproximateTimeMessage, { futureDate: field.value })] }));
        } }));
}
