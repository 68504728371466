var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGExpirePasswordIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 18 18' }, props), { children: [_jsx("path", { fill: "none", d: "M7 10H2C1.44772 10 1 9.55228 1 9V2C1 1.44772 1.44772 1 2 1H17C17.5523 1 18 1.44772 18 2V7.021", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M6 3.5V7.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M7.673 4.40308L4.328 6.59708", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M7.664 6.60989L4.336 4.38989", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M13 3.5V7.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.673 4.40308L11.328 6.59708", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.664 6.60989L11.336 4.38989", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M13.698 13.8298L17 10.4995V9H15.5166L12.1241 12.2396C10.8166 11.6797 9.29998 12.1348 8.50648 13.3252C7.71299 14.5155 7.86517 16.1073 8.86949 17.1221C9.8738 18.1368 11.4486 18.2899 12.6258 17.4872C13.803 16.6846 14.2525 15.1512 13.698 13.8298V13.8298Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
