import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { AutomaticSettingsForm } from './automatic-form';
import { useNTP } from '@/api/system/ntp';
export function AutomaticTimeTab(_a) {
    var editable = _a.editable;
    var data = useNTP().data;
    if (!data) {
        return null;
    }
    return _jsx(AutomaticSettingsForm, { data: data, editable: editable });
}
