import { DNS_PATTERN, IPV4_PATTERN, IPV6_PATTERN, NETWORK_RANGE_PATTERN, SUBNET_MASK_PATTERN } from '@/constants/regex';
/**
 * Constants
 */
export var SMF_INTERNAL_SUBNET_MASK = 26;
export var MAX_WIREGUARD_PACKET_OVERHEAD = 80;
/**
 * Helper function to validate ipv4 string patterns
 * @param address
 */
export function isIpv4Valid(address) {
    return !!address && IPV4_PATTERN.test(address);
}
/**
 * Helper function to validate ipv6 string patterns
 * @param address
 */
export function isIpv6Valid(address) {
    return !!address && IPV6_PATTERN.test(address);
}
/**
 * Helper function to validate dns string patterns
 * @param address
 */
export function isDnsNameValid(name) {
    return !!name && DNS_PATTERN.test(name);
}
/**
 * Internal helper to validate subnet mask strings
 * Must not be falsy and match regular expression
 * @param mask
 */
function isSubnetMaskInvalid(mask) {
    return !mask || !SUBNET_MASK_PATTERN.test(mask);
}
/**
 * Helper function to validate url string patterns using the URL constructor
 * @param {string} url
 */
export function isUrlValid(url) {
    try {
        if (!url)
            return false;
        return !!new URL(url);
    }
    catch (err) {
        return false;
    }
}
/**
 * Helper function to convert a subnet mask to a cidr prefix string
 * @param mask
 */
export function subnetMaskToCIDR(mask) {
    try {
        if (isSubnetMaskInvalid(mask))
            return '';
        return mask
            .split('.')
            .map(function (part) { return parseInt(part, 10); })
            .map(function (part) { return part.toString(2); })
            .join('')
            .replace(/0/g, '')
            .length.toString();
    }
    catch (err) {
        return '';
    }
}
/**
 * Internal helper to validate a cidr prefix string
 * Must be able to parse as an integer and be within range of 0 to 32
 * @param cidr
 */
export function isCidrInvalid(cidr) {
    return !cidr || parseInt(cidr, 10) < 0 || parseInt(cidr, 10) > 32 || Number.isNaN(parseInt(cidr, 10));
}
/**
 * Internal helper to validate an smf cidr prefix string
 * Must be able to parse as an integer and be within range of 0 to 25
 * @param smf_cidr
 */
export function isSmfCidrInvalid(smf_cidr) {
    return !smf_cidr || isCidrInvalid(smf_cidr) || parseInt(smf_cidr, 10) >= SMF_INTERNAL_SUBNET_MASK;
}
/**
 * Helper function to convert a cidr prefix string to subnet mask
 * @param cidr
 */
export function cidrToSubnetMask(cidr) {
    var bits = Array(parseInt(cidr, 10)).fill('1').join('').padEnd(32, '0');
    var bitSegments = Array(4)
        .fill(null)
        .map(function (_, i) { return bits.slice(i * 8, i * 8 + 8); });
    return bitSegments
        .map(function (segment) {
        return segment
            .split('')
            .reverse()
            .reduce(function (acc, curr, i) { return (curr === '1' ? acc + Math.pow(2, i) : acc); }, 0);
    })
        .join('.');
}
/**
 * Helper function to calculate number of usable addresses from a cidr prefix
 * @param cidr
 */
export function capacityFromCidr(cidr) {
    try {
        if (cidr === undefined || isCidrInvalid(cidr))
            return '';
        var zeros = 32 - parseInt(cidr, 10);
        return zeros < 3 ? Math.pow(2, zeros) : Math.pow(2, zeros) - 3;
    }
    catch (err) {
        return '';
    }
}
/**
 * Helper function to calculate number of usable addresses from an smf cidr prefix
 * The smf capacity is the overall network size minus 3 addresses per subnet
 * (lh interface, network space address, broadcast address)
 * @param smf_cidr
 */
export function capacityFromSmfCidr(smf_cidr) {
    try {
        if (smf_cidr === undefined || isSmfCidrInvalid(smf_cidr))
            return '';
        var hosts = Math.pow(2, (32 - parseInt(smf_cidr, 10)));
        var internalSubnetSize = Math.pow(2, (32 - SMF_INTERNAL_SUBNET_MASK));
        return hosts - (hosts / internalSubnetSize) * 3;
    }
    catch (err) {
        return '';
    }
}
/**
 * Helper function to calculate the smf mtu size from the lhvpn mtu
 * The smf mtu is the lhvpn mtu minus the theoretical maximum wireguard packet overhead (80)
 * @param mtu
 */
export function smfMtuFromVpnMtu(mtu) {
    var parsedMtu = parseInt(mtu, 10);
    if (Number.isNaN(parsedMtu)) {
        return '';
    }
    return parsedMtu - MAX_WIREGUARD_PACKET_OVERHEAD;
}
/**
 * Helper function to convert flat array to Array of Block per {blockSize} items -> Array per {columnSize} items
 * @param arr
 * @param [blockSize] Size of blocks, by default 8
 * @param [columnSize] Size of columns, by default 2
 */
export function splitArrayByBlocks(arr, blockSize, columnSize) {
    if (blockSize === void 0) { blockSize = 8; }
    if (columnSize === void 0) { columnSize = 2; }
    var rearrangedArray = [];
    for (var i = 0, k = 0; i < arr.length; i += blockSize * 2, k += 1) {
        var block = arr.slice(i, i + blockSize * 2);
        rearrangedArray[k] = [];
        for (var n = 0; n < block.length; n += columnSize) {
            rearrangedArray[k].push(block.slice(n, n + columnSize));
        }
    }
    return rearrangedArray;
}
/**
 * Helper to calculate the number of configured ports
 * @param ports the array of ports (IPort) from INode
 */
export function getConfiguredPorts(ports) {
    var configurePortsNumber = 0;
    ports.forEach(function (port) {
        if (port.mode === 'consoleServer')
            configurePortsNumber += 1;
    });
    return { configurePortsNumber: configurePortsNumber, total: ports.length };
}
/**
 * Based on data from API endpoint  /nodes/bulk/approve and /nodes/bulk/unenroll
 * return the object with arrays of approved nodes ids and array of details of failed action - node ids and errors,
 * example {successIds: ["nodes-2", "nodes-4", "nodes-8"], errorDetails: [{id: "nodes-3", error: "Node with id 'nodes-3' does not exist"}]}
 */
export function processBulkResult(data) {
    var successIds = [];
    var errorDetails = [];
    data.results.forEach(function (resultItem) {
        if (resultItem.status === 'success') {
            successIds.push(resultItem.node_uuid);
        }
        else {
            errorDetails.push({ id: resultItem.node_uuid, error: resultItem.error_message || '' });
        }
    });
    return { successIds: successIds, errorDetails: errorDetails };
}
/**
 * Format the long message with enumeration
 * @param list with type string[]
 */
export function formatMessageWithList(list) {
    if (list.length > 10) {
        return "'".concat(list.slice(0, 10).join("', '"), "'...\nTotal nodes: ").concat(list.length);
    }
    return "'".concat(list.join("', '"), "'");
}
/**
 * Helper function to convert a backend uuid to an integer id
 * Ideally the backend would be the only place where this should ever be needed.
 * See class UuidMixin in lipy for the backend implementation
 * @param uuid
 * @example uuidToId('node-firmware-1') -> 1
 */
export function uuidToId(uuid) {
    if (!uuid)
        return undefined;
    var result = uuid.match(/\d+$/);
    if (result === null)
        return undefined;
    var match = parseInt(result[0], 10);
    if (Number.isNaN(match))
        return undefined;
    return match;
}
export function isSubnetOrCidrValid(value) {
    return !!value && (isIpv4Valid(value) || !isSubnetMaskInvalid(value) || !isCidrInvalid(value));
}
/**
 * Helper function to validate network range in a query for resource filters
 */
export function isValidNetworkRange(input) {
    return NETWORK_RANGE_PATTERN.test(input);
}
