var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { OGTable } from '@opengear/table';
import { OGButtonLink } from '@opengear/buttons';
import { useResourceSmartgroups } from '@/api/smf-resources/smartgroups';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { ResourceFilterDeleteModal, ResourceFilterEditModal, ResourceFilterInvalidModal } from '../resource-filter-modals';
import { isResourceQueryValid } from '../../advanced-filters/resource-filter';
import { useOpen } from '@/hooks/open';
export function ResourceFiltersTable(_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var _b = __read(usePaginationModel('resource_filters'), 3), paginationModel = _b[0], handlePaginationModelChange = _b[1], pageSizeOptions = _b[2];
    var _c = __read(useSortModel('name'), 3), defaultSortModel = _c[0], sortQueryParams = _c[1], handleSortModelChange = _c[2];
    var _d = __read(useState(undefined), 2), selectedResourceFilter = _d[0], setSelectedResourceFilter = _d[1];
    var openEditFilerModal = !!selectedResourceFilter;
    var _e = __read(useState(undefined), 2), selectedInvalidFilter = _e[0], setSelectedInvalidFilter = _e[1];
    var invalidFilterModalOpen = !!selectedInvalidFilter;
    var deleteFilter = selectedResourceFilter !== null && selectedResourceFilter !== void 0 ? selectedResourceFilter : selectedInvalidFilter;
    var handleEditResourceFilterModalOpen = useCallback(function (resourceFilter) {
        setSelectedResourceFilter(resourceFilter);
    }, []);
    var handleEditFilterModalClose = useCallback(function () {
        setSelectedResourceFilter(undefined);
    }, []);
    var handleInvalidFilterModalOpen = useCallback(function (filter) {
        setSelectedInvalidFilter(filter);
    }, []);
    var handleInvalidFilterModalClose = useCallback(function () {
        setSelectedInvalidFilter(undefined);
    }, []);
    var _f = useOpen(), deleteModalOpen = _f.open, handleDeleteModalOpen = _f.handleOpen, handleDeleteModalClose = _f.handleClose;
    var _g = __read(useState(false), 2), filterValueError = _g[0], setFilterValueError = _g[1];
    var handleDelete = useCallback(function () {
        handleEditFilterModalClose();
        handleInvalidFilterModalClose();
        handleDeleteModalClose();
    }, [handleDeleteModalClose, handleEditFilterModalClose, handleInvalidFilterModalClose]);
    var queryParams = useMemo(function () { return (__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize }, sortQueryParams)); }, [paginationModel, sortQueryParams]);
    var _h = useResourceSmartgroups(queryParams), data = _h.data, isLoading = _h.isLoading;
    var _j = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.smartgroups) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.smartgroups, isLoading]), loading = _j.loading, rowCount = _j.rowCount, rows = _j.rows;
    var renderNameCell = useCallback(function (params) {
        var row = params.row, value = params.value, tabIndex = params.tabIndex;
        if (!permissions.hasFilterEditAccess)
            return value;
        if (!isResourceQueryValid(row))
            return (_jsxs(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                    handleInvalidFilterModalOpen(row);
                }, propagateSpacebar: false }, { children: [value, " ", _jsx(WarningIcon, { fontSize: "medium", color: "warning", sx: { marginLeft: 1 } })] })));
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                handleEditResourceFilterModalOpen(row);
            }, propagateSpacebar: false }, { children: value })));
    }, [handleEditResourceFilterModalOpen, handleInvalidFilterModalOpen, permissions.hasFilterEditAccess]);
    var columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('pages.filters.table.columns.name'),
            sortable: true,
            renderCell: renderNameCell,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ResourceFilterEditModal, { onClose: handleEditFilterModalClose, onDelete: handleDeleteModalOpen, onSubmit: handleEditFilterModalClose, open: openEditFilerModal, selectedFilter: !!selectedResourceFilter && isResourceQueryValid(selectedResourceFilter) ? selectedResourceFilter : undefined, translations: {
                    dialogTitle: t('components.resources.filter.edit_modal.title'),
                    saveBtn: t('components.resources.filter.edit_modal.submit'),
                }, error: filterValueError, setError: setFilterValueError }), _jsx(ResourceFilterInvalidModal, { open: invalidFilterModalOpen, onDelete: handleDeleteModalOpen, onClose: handleInvalidFilterModalClose, selectedFilter: selectedInvalidFilter }), deleteFilter && (_jsx(ResourceFilterDeleteModal, { open: deleteModalOpen, onClose: handleDeleteModalClose, onDelete: handleDelete, selectedFilter: deleteFilter })), _jsx(OGTable, { "aria-label": t('pages.filters.tabs.resource_filters.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('pages.filters.table.no_row_message'), loading: loading, pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, rowCount: rowCount, onPaginationModelChange: handlePaginationModelChange, onSortModelChange: handleSortModelChange, initialState: {
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                } })] }));
}
