var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { OGDependentMode, OGEvaluationMode } from '@opengear/icons/status';
import format from 'string-format';
import Typography from '@mui/material/Typography';
import { useDeviceView } from '@/hooks/use-device-view';
import { AccountsMenu } from '@/containers/menus/accounts-menu';
import { SettingsMenu } from '@/containers/menus/settings-menu';
import { UserMenu } from '@/containers/menus/user-menu';
import { NavigationMenu } from '@/containers/menus/navigation-menu';
import { WarningBanner } from '@/components/warning-banner';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LicenseExpiryBanners } from '@/components/license-expiry-banners';
import { NavigationBar } from '@/containers/menus/navigation-bar';
import { useTimeoutSession } from '@/hooks/timeout-session';
var MAIN_CONTENT_ID = 'main-content';
var EVAL_EXPIRING_BANNER_CODE = 'eval-expiring-banner';
var EVAL_EXPIRED_BANNER_CODE = 'eval-expired-banner';
var DEPENDENT_LH_BANNER_CODE = 'dependent-lighthouse-banner';
var DRAWER_WIDTH = 282;
var SELECT_BAR_WIDTH = 48;
export default function Root() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var pathname = useLocation().pathname;
    var permissions = useUIPermissions().permissions;
    useTimeoutSession();
    var isSecondary = permissions === null || permissions === void 0 ? void 0 : permissions.is_secondary;
    // Evaluation Warning Banner
    var evaluationBanner = useMemo(function () {
        if ((permissions === null || permissions === void 0 ? void 0 : permissions.evaluation_days_remaining) === undefined || permissions.maintenance_days_remaining !== -1) {
            return null;
        }
        return (_jsx(WarningBanner, { warningIcon: _jsx(OGEvaluationMode, { style: { fontSize: '40px' } }), warningHeader: permissions.evaluation_days_remaining === 0 ? t('components.evaluation_expired_warning.title') : t('components.evaluation_warning.title'), warningMessage: _jsx(Typography, { children: permissions.evaluation_days_remaining === 0
                    ? t('components.evaluation_expired_warning.message')
                    : format(t('components.evaluation_warning.message'), permissions.evaluation_days_remaining.toString()) }), warningColor: permissions.evaluation_days_remaining === 0 ? 'error' : 'warning', bannerCode: permissions.evaluation_days_remaining === 0 ? EVAL_EXPIRED_BANNER_CODE : EVAL_EXPIRING_BANNER_CODE, dismissPeriod: -1 }));
    }, [t, permissions]);
    var _a = __read(useState(false), 2), sidebarOpen = _a[0], setSidebarOpen = _a[1];
    // When route changes close the menu
    // Only applicable for small screen sizes
    useEffect(function () {
        if (isMobileView)
            setSidebarOpen(false);
        return function () {
            setSidebarOpen(false);
        };
    }, [isMobileView, pathname]);
    return (_jsxs(Box, __assign({ display: "flex" }, { children: [_jsx(NavigationBar, { onSidebarOpen: function () { return setSidebarOpen(true); } }), _jsx(Drawer, __assign({ anchor: "left", role: "navigation", open: !isMobileView || sidebarOpen, sx: { width: DRAWER_WIDTH }, variant: isMobileView ? 'temporary' : 'permanent', ModalProps: { keepMounted: true }, PaperProps: { sx: { backgroundColor: 'background.menu', width: DRAWER_WIDTH } }, onClose: function () {
                    setSidebarOpen(false);
                } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsxs(Box, __assign({ sx: {
                                backgroundColor: 'background.selectbar',
                                height: '100vh',
                                display: 'flex',
                                flexDirection: 'column',
                                overflowY: 'auto',
                                minWidth: SELECT_BAR_WIDTH,
                            } }, { children: [isMobileView ? (_jsx(OGButton, __assign({ "aria-label": t('navigation.sidebar.close_menu'), variant: "borderless", onClick: function () {
                                        setSidebarOpen(false);
                                    }, sx: { marginX: 0.5, marginY: 1.25, paddingX: 1, paddingY: 0.75 } }, { children: _jsx(CloseIcon, {}) }))) : (_jsx(Toolbar, { sx: { padding: 0 } })), _jsx(Box, { flex: 1 }), _jsx(AccountsMenu, {}), _jsx(SettingsMenu, {}), _jsx(UserMenu, {})] })), _jsxs(Box, __assign({ sx: { height: '100vh', flexGrow: 1, overflowY: 'auto' } }, { children: [!isMobileView && _jsx(Toolbar, { sx: { padding: 0 } }), _jsx(NavigationMenu, {})] }))] })) })), _jsxs(Box, __assign({ id: MAIN_CONTENT_ID, component: "main", 
                // NOTE: we must explicitly set the tabindex else the js in the skip-to-content button
                // can't focus on it. The container itself is not focusable via keyboard navigation.
                tabIndex: -1, width: isMobileView ? '100%' : "calc(100% - ".concat(DRAWER_WIDTH, "px)"), minHeight: "100%", sx: {
                    '&:focus-visible': {
                        outline: 'none',
                    },
                } }, { children: [_jsx(Toolbar, {}), isSecondary ? (_jsx(WarningBanner, { warningIcon: _jsx(OGDependentMode, { style: { fontSize: '40px' } }), warningHeader: t('components.dependent_warning.title'), warningMessage: _jsx(Typography, { children: t('components.dependent_warning.message') }), warningColor: "warning", bannerCode: DEPENDENT_LH_BANNER_CODE, dismissPeriod: -1 })) : null, evaluationBanner || _jsx(LicenseExpiryBanners, {}), _jsx(Outlet, {})] }))] })));
}
