var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGFileExeIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, props, { children: [_jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M18.2486 20.4347C18.6551 20.4347 18.9846 20.0953 18.9846 19.6767V7.53151C18.9852 6.32989 18.5221 5.17727 17.6973 4.32739C16.8725 3.4775 15.7536 3 14.5868 3H5.73603C5.32953 3 5 3.33938 5 3.75803V19.6767C5 20.0953 5.32953 20.4347 5.73603 20.4347H18.2486Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15.3044 3.06372V6.03217C15.3044 6.45082 15.634 6.7902 16.0405 6.7902H18.9257", fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
