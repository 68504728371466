var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import FormLabel from '@mui/material/FormLabel';
import { useFormContainerContext } from '@/components/layout/forms';
import { USER_GROUP_NAME_PATTERN, PORT_RANGE_PATTERN } from '@/constants/regex';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Constants
export var GroupnameFieldMaxLength = 60;
export var DescriptionFieldMaxLength = 128;
export var PortsFieldMaxLength = 128;
export function NameField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "groupname", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.groupname.label')),
            maxLength: {
                value: GroupnameFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.groupname.label'), GroupnameFieldMaxLength.toString()),
            },
            pattern: {
                value: USER_GROUP_NAME_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.groupname.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField
            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            , __assign({ 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, id: "groupname", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.groupname.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' }, required: true }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "groupdescription", disabled: !permissions.hasEditPermission, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.description.label'), sx: { width: '100%' } }, field)));
        } }));
}
export function RolesField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.label') }), _jsx(Controller, { control: control, name: "roles", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, value: value, onChange: function (event, v) {
                            onChange(v);
                        } }, field, { children: [_jsx(ToggleButton, __assign({ value: ['administrator'], selected: value[0] === 'administrator' }, { children: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.options.administrator') })), _jsx(ToggleButton, __assign({ value: ['pmoperator'], selected: value[0] === 'pmoperator' }, { children: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.options.user') }))] })));
                } })] }));
}
export function RestrictPortsField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "restrict_ports", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasEditPermission }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value })));
            } }), label: _jsx(_Fragment, { children: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.restrict_ports.label') }) }));
}
export function PortsField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "ports", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.ports.label')),
            pattern: {
                value: PORT_RANGE_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.ports.label')),
            },
            maxLength: {
                value: PortsFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.group_list.fields.ports.label'), PortsFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "ports", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.users_groups.sections.group_list.fields.ports.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.users_groups.sections.group_list.fields.ports.description') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' }, required: true }, field)));
        } }));
}
