var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { PortsTable } from '@/components/ports';
import { useUIPermissions } from '@/api/sessions/self/ui';
function Ports() {
    var t = useTranslation().t;
    var _a = useUIPermissions(), hasPermission = _a.hasPermission, isPrimary = _a.isPrimary;
    useDocTitle(t('pages.ports.title'));
    // Evaluate permissions
    var permissions = useMemo(function () { return ({
        hasNodeUIViewAccess: hasPermission('manage.nodes.node_ui.view'),
        hasSmartgroupsEditAccess: hasPermission('configure.edit_smart_groups.edit'),
        hasTagsEditAccess: hasPermission('configure.edit_tags.edit'),
        hasWebTerminalAccess: hasPermission('manage.managed_devices.console_gateway.web_terminal'),
        hasSerialPortAccess: hasPermission('manage.managed_devices.console_gateway.serial_port_access'),
        isPrimary: isPrimary,
    }); }, [hasPermission, isPrimary]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.ports.title') })), _jsx(Paper, __assign({ sx: { padding: 2 } }, { children: _jsx(PortsTable, { permissions: permissions }) }))] })));
}
// In the backend, this right is depending on Nodes and Devices (Base)
export default withAuthenticationRequired(Ports, { rights: ['manage.managed_devices.console_gateway.view'] });
