var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { OGLink } from '@opengear/links';
import { OGTable } from '@opengear/table';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { useNodeUpgrades } from '@/api/system/node-upgrade';
import { UpgradeStatusCell } from './firmware-upgrade-table-components';
import { formatNodeUpgradeRoute } from '@/constants/routes';
import { formatDateTimestamp } from '@/utils/datetime';
import { FirmwareStatusPeriodFilter } from './firmware-status-period-filter/firmware-status-period-filter';
import { FirmwareNameFilter } from './firmware-name-filter/firmware-name-filter';
function validateFirmwareUpgradeFilterOptions(_a) {
    var status = _a.status, last_x_days = _a.last_x_days, name_contains = _a.name_contains;
    var filterQueryParams = {};
    if (status)
        filterQueryParams.status = status;
    if (last_x_days)
        filterQueryParams.last_x_days = last_x_days;
    if (name_contains)
        filterQueryParams.name_contains = name_contains;
    return filterQueryParams;
}
function renderUpgradeName(_a) {
    var task = _a.row, tabIndex = _a.tabIndex;
    return (_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: formatNodeUpgradeRoute(task.id.toString()) }, { children: task.name })));
}
function renderTime(value) {
    return value ? formatDateTimestamp(value) : '-';
}
export var FirmwareUpgradeTable = memo(function () {
    var t = useTranslation().t;
    var _a = __read(usePaginationModel('upgrade_tasks'), 3), paginationModel = _a[0], handlePaginationModelChange = _a[1], pageSizeOptions = _a[2];
    var _b = __read(useSortModel('scheduled_time', '1'), 3), defaultSortModel = _b[0], sortQueryParams = _b[1], handleSortModelChange = _b[2];
    // Tasks Filter
    var _c = __read(useState({}), 2), filterOptions = _c[0], setFilterOptions = _c[1];
    var filterQueryParams = useMemo(function () { return validateFirmwareUpgradeFilterOptions(filterOptions); }, [filterOptions]);
    // Query options
    var queryParams = useMemo(function () { return (__assign(__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize }, sortQueryParams), filterQueryParams)); }, [paginationModel, sortQueryParams, filterQueryParams]);
    // Get Node Upgrades Fetch
    var _d = useNodeUpgrades(queryParams), data = _d.data, isUserLoading = _d.isLoading;
    // Derived table values
    var _e = useMemo(function () { return ({
        loading: isUserLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.node_upgrades) || [],
    }); }, [isUserLoading, data]), loading = _e.loading, rowCount = _e.rowCount, rows = _e.rows;
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'name',
            headerName: t('pages.firmware_upgrade.tabs.tasks.table.headers.tasks'),
            flex: 1,
            minWidth: 150,
            renderCell: renderUpgradeName,
        },
        {
            field: 'scheduled_time',
            headerName: t('pages.firmware_upgrade.tabs.tasks.table.headers.upgrade_start'),
            flex: 1,
            minWidth: 230,
            renderCell: function (_a) {
                var row = _a.row;
                return renderTime(row.scheduled_time);
            },
        },
        {
            field: 'finish_time',
            headerName: t('pages.firmware_upgrade.tabs.tasks.table.headers.upgrade_end'),
            flex: 1,
            minWidth: 230,
            renderCell: function (_a) {
                var row = _a.row;
                return renderTime(row.finish_time);
            },
        },
        {
            field: 'status',
            headerName: t('pages.firmware_upgrade.tabs.tasks.table.headers.upgrade_status'),
            minWidth: 150,
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(UpgradeStatusCell, { nodeUpgrade: row });
            },
        },
    ]; }, [t]);
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 1, marginBottom: 1, justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(FirmwareStatusPeriodFilter, { filterOptions: filterOptions, setFilterOptions: setFilterOptions }), _jsx(FirmwareNameFilter, { filterOptions: filterOptions, setFilterOptions: setFilterOptions })] })), _jsx(Box, { children: _jsx(OGTable, { id: "upgrade-tasks-table", "aria-label": t('pages.firmware_upgrade.tabs.tasks.label'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.firmware_upgrade.tabs.tasks.table.no_row_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    } }) })] }));
});
