var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { IQueryNodeField, IQueryOperation, IQueryPortField, IQuerySmfResourceField } from '../../../../types/query/types';
/**
 * A selected list of fields used by the node filter
 */
export var nodeQueryFields = [
    IQueryNodeField.approved,
    IQueryNodeField.firmwareVersion,
    IQueryNodeField.id,
    IQueryNodeField.lhvpnAddress,
    IQueryNodeField.macAddress,
    IQueryNodeField.model,
    IQueryNodeField.name,
    IQueryNodeField.product,
    IQueryNodeField.productFamily,
    IQueryNodeField.serialNumber,
    IQueryNodeField.sshPort,
    IQueryNodeField.status,
    IQueryNodeField.cellHealthStatus,
    IQueryNodeField.connectionStatus,
];
/**
 * A selected list of fields used by the port filter
 */
export var portQueryFields = [IQueryPortField.label, IQueryPortField.tag];
/**
 * A selected list of fields used by the resource filter
 */
export var resourceQueryFields = [
    IQuerySmfResourceField.name,
    IQuerySmfResourceField.address,
    IQuerySmfResourceField.tag,
    IQuerySmfResourceField.networkRange,
];
/**
 * A list of available query operators for string fields
 */
export var stringMatchingOperators = [IQueryOperation.startsWith, IQueryOperation.endsWith, IQueryOperation.contains];
/**
 * A list of available query operators for simple equality checks
 */
export var simpleEqualityOperators = [IQueryOperation.equal, IQueryOperation.notEqual];
/**
 * A list of available query operators for advanced equality checks
 */
export var advancedEqualityOperators = __spreadArray(__spreadArray([], __read(simpleEqualityOperators), false), [
    IQueryOperation.lessThan,
    IQueryOperation.lessThanOrEqual,
    IQueryOperation.greaterThan,
    IQueryOperation.greaterThanOrEqual,
], false);
/**
 * A complete list of available query operations
 */
export var allQueryOperators = __spreadArray(__spreadArray(__spreadArray([], __read(advancedEqualityOperators), false), __read(stringMatchingOperators), false), [IQueryOperation.in, IQueryOperation.nin], false);
