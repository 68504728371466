var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { FormContainer } from '@/components/layout/forms';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NewFirmwareUpgradeStep, NewFirmwareUpgradeStepper, NewFirmwareUpgradeStepsProvider } from './steps';
import { SetupUpgradeTask } from './setup-upgrade-task';
import { ScheduleUpgrade } from './schedule-upgrade';
import { Review } from './review';
import { Confirm } from './confirm';
import { useNodeFirmwares } from '@/api/system/node-firmware';
import { useNodes } from '@/api/nodes';
import { productFamilyToQuery, useJson } from '@/utils/query';
export var FIRMWARE_UPGRADE_FORM_LOADER_ID = 'Firmware_upgrade_form_loader';
function CopyRepeat(_a) {
    var copyRepeatData = _a.copyRepeatData, _b = _a.methods, setValue = _b.setValue, watch = _b.watch, setCopyRepeatData = _a.setCopyRepeatData;
    var firmwareData = useNodeFirmwares({ upgrade_to_nodes: copyRepeatData ? copyRepeatData.nodeIds : undefined }).data;
    var firmwareToSelect = useMemo(function () { return firmwareData === null || firmwareData === void 0 ? void 0 : firmwareData.find(function (firmware) { return firmware.id === copyRepeatData.firmwareId; }); }, [copyRepeatData.firmwareId, firmwareData]);
    var productFamilyQuery = useMemo(function () { return productFamilyToQuery(copyRepeatData === null || copyRepeatData === void 0 ? void 0 : copyRepeatData.family); }, [copyRepeatData === null || copyRepeatData === void 0 ? void 0 : copyRepeatData.family]);
    // Aggregate all query items into one json query string
    var json = useJson(productFamilyQuery);
    // Query options
    var queryOptions = useMemo(function () { return ({
        json: json,
        get_upgrades: true,
    }); }, [json]);
    var nodesData = useNodes(queryOptions).data;
    var copyNodesToSelect = useMemo(function () {
        if (nodesData) {
            var nodesToSelect_1 = [];
            copyRepeatData.nodes.forEach(function (node) {
                var nodeToSelect = nodesData.nodes.find(function (_node) { return _node.id === node.nodeId && !_node.upgrade_scheduled; });
                if (nodeToSelect) {
                    nodesToSelect_1.push(__assign(__assign({}, nodeToSelect), { allowInFailover: node.allowInFailover }));
                }
            });
            return nodesToSelect_1;
        }
        return undefined;
    }, [copyRepeatData.nodes, nodesData]);
    var nodesValue = watch('nodes');
    useEffect(function () {
        if (firmwareToSelect && copyRepeatData.isRepeat) {
            setValue('firmware', firmwareToSelect);
        }
        if ((copyRepeatData === null || copyRepeatData === void 0 ? void 0 : copyRepeatData.name) && copyRepeatData.isRepeat) {
            setValue('name', "".concat(copyRepeatData.name, "-repeat"));
        }
        if (copyNodesToSelect) {
            setValue('nodes', copyNodesToSelect);
        }
        if (copyNodesToSelect && nodesValue.length === copyNodesToSelect.length) {
            setCopyRepeatData(undefined);
        }
    }, [copyNodesToSelect, copyRepeatData, copyRepeatData.isRepeat, firmwareToSelect, nodesValue, setCopyRepeatData, setValue]);
    return null;
}
export function NewFirmwareUpgradeForm(_a) {
    var _b, _c;
    var ariaLabelledBy = _a.ariaLabelledBy, onSubmit = _a.onSubmit, permissions = _a.permissions;
    var location = useLocation();
    var _d = __read(useState(((_b = location.state) === null || _b === void 0 ? void 0 : _b.copyRepeatData) ? __assign({}, (_c = location.state) === null || _c === void 0 ? void 0 : _c.copyRepeatData) : undefined), 2), copyRepeatData = _d[0], setCopyRepeatData = _d[1];
    var defaultValues = {
        name: '',
        firmware: undefined,
        immediate: false,
        scheduledTime: null,
        nodes: [],
    };
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    return (_jsxs(NewFirmwareUpgradeStepsProvider, __assign({ initStep: (copyRepeatData === null || copyRepeatData === void 0 ? void 0 : copyRepeatData.isRepeat) ? NewFirmwareUpgradeStep.Schedule : NewFirmwareUpgradeStep.Setup }, { children: [copyRepeatData && _jsx(CopyRepeat, { copyRepeatData: copyRepeatData, methods: methods, setCopyRepeatData: setCopyRepeatData }), copyRepeatData ? (_jsx(LoadingBackdrop, { "data-testid": FIRMWARE_UPGRADE_FORM_LOADER_ID })) : (_jsx(FormContainer, __assign({ ariaLabelledBy: ariaLabelledBy, mode: "create", methods: methods, onSubmit: onSubmit, permissions: permissions }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 2 }, { children: [_jsx(NewFirmwareUpgradeStepper, {}), _jsx(SetupUpgradeTask, {}), _jsx(ScheduleUpgrade, {}), _jsx(Review, {}), _jsx(Confirm, { onSubmit: onSubmit })] })) })))] })));
}
