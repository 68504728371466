var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGPanel } from '@opengear/panel';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ResetButton, FormContainer, FormActions, SubmitButton } from '@/components/layout/forms';
import { SshPasswordAuthToggleButton, SshRootLoginToggleButton } from './ssh-auth-form-controls';
import { useUpdateSshPasswordAuth } from '@/api/services/ssh-password-auth';
import { useUpdateSshRootLogin } from '@/api/services/ssh-root-login';
import { useAlertNotification } from '@/hooks/alert-notification';
export function SshAuthForm(_a) {
    var _this = this;
    var data = _a.data, permissions = _a.permissions;
    var t = useTranslation().t;
    var closeRef = useAlertNotification().closeRef;
    var updateSshPasswordAuthMutate = useUpdateSshPasswordAuth().mutateAsync;
    var updateSshRootLoginMutate = useUpdateSshRootLogin().mutateAsync;
    var methods = useForm({ mode: 'onTouched', defaultValues: data });
    var dirtyFields = methods.formState.dirtyFields;
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var promises;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    closeRef.current();
                    promises = [];
                    if (dirtyFields.sshPasswordAuth) {
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedData;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, updateSshPasswordAuthMutate({ enabled: formData.sshPasswordAuth })];
                                    case 1:
                                        updatedData = _b.sent();
                                        if (updatedData) {
                                            methods.resetField('sshPasswordAuth', { defaultValue: (_a = updatedData.services_ssh_password_auth.enabled) !== null && _a !== void 0 ? _a : false });
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); })());
                    }
                    if (dirtyFields.sshRootLogin) {
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedData;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, updateSshRootLoginMutate({ enabled: formData.sshRootLogin })];
                                    case 1:
                                        updatedData = _b.sent();
                                        if (updatedData) {
                                            methods.resetField('sshRootLogin', { defaultValue: (_a = updatedData.services_ssh_root_login.enabled) !== null && _a !== void 0 ? _a : false });
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); })());
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [closeRef, dirtyFields.sshPasswordAuth, dirtyFields.sshRootLogin, methods, updateSshPasswordAuthMutate, updateSshRootLoginMutate]);
    return (_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ mode: "update", permissions: permissions, methods: methods, onSubmit: onSubmit }, { children: [_jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 500, fontSize: 16 } }, { children: t('pages.ssh_auth.form.title') })), _jsx(SshPasswordAuthToggleButton, {}), _jsx(Stack, { marginBottom: 2 }), _jsx(SshRootLoginToggleButton, {}), _jsxs(FormActions, { children: [_jsx(ResetButton, {}), _jsx(SubmitButton, {})] })] })) }));
}
