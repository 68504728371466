var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { OGLink } from '@opengear/links';
import { OGTable } from '@opengear/table';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { JobModal } from './job-modal';
import { JobsFilter } from './jobs-filter';
import { validateJobsFilterOptions } from './jobs-filter-utils';
import { useJobs } from '@/api/jobs';
import { formatDateString } from '@/utils/datetime';
import DynamicDurationValue from '@/components/metric/duration';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
export var QUERY_PARAM_JOB_ID_KEY = 'job_id';
var filterDefaults = {
    durationOperator: 'lt',
};
export function JobsTable(_a) {
    var _b;
    var jobState = _a.jobState;
    var t = useTranslation().t;
    // Modal controls
    var _c = __read(useSearchParams(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var modalJobId = useMemo(function () { var _a; return (_a = searchParams.get(QUERY_PARAM_JOB_ID_KEY)) !== null && _a !== void 0 ? _a : undefined; }, [searchParams]);
    var handleModalClose = useCallback(function () {
        searchParams.delete(QUERY_PARAM_JOB_ID_KEY);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    // Table controls
    var _d = __read(usePaginationModel('jobs'), 3), paginationModel = _d[0], handlePaginationModelChange = _d[1], pageSizeOptions = _d[2];
    var _e = __read(useSortModel('id', '1'), 3), defaultSortModel = _e[0], sortQueryParams = _e[1], handleSortModelChange = _e[2];
    var _f = __read(useState(filterDefaults), 2), filterOptions = _f[0], setFilterOptions = _f[1];
    var filterQueryParams = useMemo(function () { return validateJobsFilterOptions(filterOptions); }, [filterOptions]);
    var queryParams = useMemo(function () { return (__assign(__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize, job_state: jobState }, sortQueryParams), filterQueryParams)); }, [paginationModel.page, paginationModel.pageSize, jobState, sortQueryParams, filterQueryParams]);
    var _g = useJobs(queryParams), data = _g.data, isLoading = _g.isLoading;
    var rowCount = (_b = data === null || data === void 0 ? void 0 : data.meta) === null || _b === void 0 ? void 0 : _b.total_count;
    var rows = (data === null || data === void 0 ? void 0 : data.jobs) || [];
    var statusIconMap = useMemo(function () {
        return new Map([
            [
                'permanent_failure',
                _jsx(ErrorIcon, { fontSize: "medium", "aria-hidden": "false", titleAccess: t('pages.jobs.table.status.fail'), color: "error", sx: { marginRight: '4px !important' } }),
            ],
            [
                'success',
                _jsx(SuccessIcon, { fontSize: "medium", "aria-hidden": "false", titleAccess: t('pages.jobs.table.status.succeed'), color: "success", sx: { marginRight: '4px !important' } }),
            ],
        ]);
    }, [t]);
    var renderAttempts = useCallback(function (params) { return params.row.records.length; }, []);
    var renderStarted = useCallback(function (params) { return formatDateString(params.value); }, []);
    var renderState = useCallback(function (params) { return statusIconMap.get(params.value); }, [statusIconMap]);
    var renderDuration = useCallback(function (params) {
        var duration = jobState === 'finished'
            ? params.value
            : (new Date().getTime() - new Date(params.row.creation_time).getTime() + new Date().getTimezoneOffset() * 60 * 1000) / 1000;
        return _jsx(DynamicDurationValue, { dynamic: jobState !== 'finished', initialValue: duration });
    }, [jobState]);
    var renderTubeName = useCallback(function (params) {
        var _a;
        return (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, variant: "standard", to: "?".concat(new URLSearchParams((_a = {}, _a[QUERY_PARAM_JOB_ID_KEY] = params.id.toString(), _a))) }, { children: t("pages.jobs.table.type.".concat(params.value)) })));
    }, [t]);
    var columns = useMemo(function () { return [
        { field: 'last_status', headerName: t('pages.jobs.table.headers.state'), minWidth: 86, maxWidth: 1, sortable: false, renderCell: renderState },
        { field: 'id', headerName: t('pages.jobs.table.headers.id'), minWidth: 60, maxWidth: 1 },
        { field: 'tube_name', headerName: t('pages.jobs.table.headers.type'), minWidth: 300, flex: 1, renderCell: renderTubeName },
        { field: 'attempts', headerName: t('pages.jobs.table.headers.attempts'), renderCell: renderAttempts, sortable: false, minWidth: 88, maxWidth: 1 },
        { field: 'duration', headerName: t('pages.jobs.table.headers.duration'), renderCell: renderDuration, minWidth: 140 },
        { field: 'creation_time', headerName: t('pages.jobs.table.headers.started'), renderCell: renderStarted, minWidth: 230 },
    ]; }, [renderAttempts, renderDuration, renderStarted, renderState, renderTubeName, t]);
    return (_jsxs(_Fragment, { children: [_jsx(JobModal, { open: !!modalJobId, handleClose: handleModalClose, jobId: modalJobId }), _jsx(Stack, __assign({ direction: "row", gap: 1, marginBottom: 1, justifyContent: "space-between", flexWrap: "wrap" }, { children: _jsx(JobsFilter, { filterOptions: filterOptions, setFilterOptions: setFilterOptions }) })), _jsx(Box, { children: _jsx(OGTable, { "aria-label": t('pages.jobs.table.labels')[jobState], columns: columns, rows: rows, autoHeight: true, loading: isLoading, noRowsMessage: t('pages.jobs.table.no_rows_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    }, columnVisibilityModel: {
                        last_status: jobState === 'finished',
                    } }) })] }));
}
