var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import format from 'string-format';
import Box from '@mui/material/Box';
import { OGTable } from '@opengear/table';
import { useNodeFirmwares } from '@/api/system/node-firmware';
import { RadioButtonCell } from './select-firmware-table-components';
export var SelectFirmwareTable = memo(function (_a) {
    var _b, _c;
    var selectedFirmware = _a.selectedFirmware, setSelectedFirmware = _a.setSelectedFirmware;
    var t = useTranslation().t;
    var location = useLocation();
    var _d = useNodeFirmwares({ upgrade_to_nodes: ((_b = location.state) === null || _b === void 0 ? void 0 : _b.copyRepeatData) ? location.state.copyRepeatData.nodeIds : undefined }, { staleTime: ((_c = location.state) === null || _c === void 0 ? void 0 : _c.copyRepeatData) ? 10000 : 0 } // withing 10s since previous request get data from cache
    ), firmwareData = _d.data, isLoading = _d.isLoading;
    var _e = __read(useState([]), 2), rowSelectionModel = _e[0], setRowSelectionModel = _e[1];
    // set upload firmware as a default
    useEffect(function () {
        if (selectedFirmware) {
            setRowSelectionModel([selectedFirmware.id]);
        }
    }, [selectedFirmware]);
    var handleRowSelectionModelChange = useCallback(function (newRowSelectionModel) {
        setRowSelectionModel(newRowSelectionModel);
        if (firmwareData) {
            var _selectedFirmware = firmwareData.find(function (firmware) { return firmware.id === newRowSelectionModel[0]; });
            setSelectedFirmware(_selectedFirmware);
        }
    }, [firmwareData, setSelectedFirmware]);
    // Derived table values
    var _f = useMemo(function () { return ({
        loading: isLoading,
        rowCount: firmwareData ? firmwareData.length : 0,
        rows: firmwareData || [],
    }); }, [isLoading, firmwareData]), loading = _f.loading, rowCount = _f.rowCount, rows = _f.rows;
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'radiobutton',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(RadioButtonCell, { ariaLabel: format(t('pages.firmware_upgrade_new.tables.firmwares.radio_button_label'), row.filename), checked: rowSelectionModel[0] === row.id, tabIndex: tabIndex, onChange: function (value) {
                        handleRowSelectionModelChange([value]);
                    }, value: row.id }));
            },
        },
        {
            field: 'filename',
            headerName: t('pages.firmware_upgrade_new.tables.firmwares.headers.filename'),
            flex: 1,
            minWidth: 300,
        },
        {
            field: 'version',
            headerName: t('pages.firmware_upgrade_new.tables.firmwares.headers.version'),
            minWidth: 150,
        },
        {
            field: 'family',
            headerName: t('pages.firmware_upgrade_new.tables.firmwares.headers.family'),
            minWidth: 150,
        },
    ]; }, [handleRowSelectionModelChange, rowSelectionModel, t]);
    return (_jsx(Box, { children: _jsx(OGTable, { id: "modal-node-firmware-table", "aria-label": t('pages.firmware_upgrade_new.tables.firmwares.label'), autoHeight: true, columns: columns, rows: rows, disableRowSelectionOnClick: false, loading: loading, noRowsMessage: t('pages.firmware_upgrade_new.tables.firmwares.no_row_message'), rowCount: rowCount, disableMultipleRowSelection: true, rowSelectionModel: rowSelectionModel, onRowSelectionModelChange: handleRowSelectionModelChange }) }));
});
