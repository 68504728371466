import { jsx as _jsx } from "react/jsx-runtime";
import { RESOURCE_TAGS_LOADING_TEST_ID, useResourceTagsNames } from '@/api/tags/resource-tags/names';
import { FilterQuerySkeleton, FilterQuery, resourceQueryFields } from '@/containers/filters/filter-query';
export function ResourceFilterQuery(_a) {
    var query = _a.query, onChange = _a.onChange, setError = _a.setError;
    var _b = useResourceTagsNames(), data = _b.data, isLoading = _b.isLoading;
    var resourceTags = data === null || data === void 0 ? void 0 : data.resourceTags;
    if (isLoading || !resourceTags) {
        return _jsx(FilterQuerySkeleton, { "data-testid": RESOURCE_TAGS_LOADING_TEST_ID });
    }
    return _jsx(FilterQuery, { query: query, queryFields: resourceQueryFields, onChange: onChange, tagValues: resourceTags, setError: setError });
}
