var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import dayjs from 'dayjs';
import { DATE_INPUT_FORMAT } from '@/constants/formats';
import { secondsToDHMS } from '@/utils/datetime';
/**
 * validateJobsFilterOptions filters out invalid values before being used in the API call
 */
export function validateJobsFilterOptions(_a) {
    var jobType = _a.jobType, jobStatus = _a.jobStatus, jobId = _a.jobId, duration = _a.duration, durationOperator = _a.durationOperator, fromDate = _a.fromDate, toDate = _a.toDate;
    var filterQueryParams = {};
    if (jobType)
        filterQueryParams.job_type = jobType;
    if (jobStatus)
        filterQueryParams.job_status = jobStatus;
    if (jobId)
        filterQueryParams.job_id = Number(jobId);
    // Duration filter requires both params to work
    if (duration && durationOperator) {
        filterQueryParams.duration = Number(duration);
        filterQueryParams.duration_operator = durationOperator;
    }
    if (fromDate)
        filterQueryParams.from_date = fromDate;
    if (toDate)
        filterQueryParams.to_date = toDate;
    return filterQueryParams;
}
/**
 * useTransformFilterOptions helps transform the applied job filter query params into a selected list
 * to display as pill components in MUI's Autocomplete. There is limited validation done on the values
 * as they are only created here in the jobs filter.
 * Selected filters are derived from whether the key exists instead of undefined.
 */
export function useTransformJobsFilterOptions(filterOptions) {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var autocompleteOptions = new Map();
    if ('jobType' in filterOptions) {
        autocompleteOptions.set('jobType', { key: 'jobType', label: t('pages.jobs.filter.options.job_type.label'), value: filterOptions.jobType });
    }
    if ('jobStatus' in filterOptions) {
        autocompleteOptions.set('jobStatus', {
            key: 'jobStatus',
            label: t('pages.jobs.filter.options.job_status.label'),
            value: filterOptions.jobStatus,
        });
    }
    if ('jobId' in filterOptions) {
        autocompleteOptions.set('jobId', { key: 'jobId', label: t('pages.jobs.filter.options.job_id.label'), value: filterOptions.jobId });
    }
    if ('duration' in filterOptions && 'durationOperator' in filterOptions) {
        autocompleteOptions.set('duration', {
            key: 'duration',
            label: t('pages.jobs.filter.options.duration.label'),
            value: [filterOptions.durationOperator, filterOptions.duration],
        });
    }
    if ('fromDate' in filterOptions) {
        autocompleteOptions.set('dateRange', {
            key: 'dateRange',
            label: t('pages.jobs.filter.options.date_range.label'),
            value: [filterOptions.fromDate, (_b = (_a = autocompleteOptions.get('dateRange')) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b[1]],
        });
    }
    if ('toDate' in filterOptions) {
        autocompleteOptions.set('dateRange', {
            key: 'dateRange',
            label: t('pages.jobs.filter.options.date_range.label'),
            value: [(_d = (_c = autocompleteOptions.get('dateRange')) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d[0], filterOptions.toDate],
        });
    }
    return __spreadArray([], __read(autocompleteOptions.values()), false);
}
export var splitSeconds = function (seconds) {
    if (typeof seconds === 'string' && (seconds === '' || Number.isNaN(Number(seconds)))) {
        return undefined;
    }
    var roundedSeconds = Math.floor(Number(seconds));
    return {
        hours: Math.floor(roundedSeconds / 3600),
        minutes: Math.floor((roundedSeconds % 3600) / 60),
        seconds: Math.floor(roundedSeconds % 60),
    };
};
/**
 * transformJobsFilterAutocompleteOption helper takes in the selected option and constructs the state
 * for the filter form.
 */
export function transformJobsFilterAutocompleteOption(option) {
    var key = option.key, value = option.value;
    var formData = {};
    if (!value)
        return formData;
    if (key === 'jobType') {
        formData.jobType = value;
    }
    else if (key === 'jobStatus') {
        formData.jobStatus = value;
    }
    else if (key === 'jobId') {
        formData.jobId = value;
    }
    else if (key === 'duration') {
        var _a = __read(value, 2), durationOperator = _a[0], duration = _a[1];
        formData.durationOperator = durationOperator;
        if (duration) {
            var splitDuration = splitSeconds(duration);
            if (splitDuration) {
                formData.durationHours = String(splitDuration.hours);
                formData.durationMinutes = String(splitDuration.minutes);
            }
        }
    }
    else if (key === 'dateRange') {
        var _b = __read(value, 2), fromDate = _b[0], toDate = _b[1];
        formData.fromDate = fromDate ? dayjs(fromDate) : undefined;
        formData.toDate = toDate ? dayjs(toDate) : undefined;
    }
    return formData;
}
export var convertSeconds = function (_a) {
    var hours = _a.hours, minutes = _a.minutes, seconds = _a.seconds;
    var totalSeconds = 0;
    if (hours)
        totalSeconds += hours * 3600;
    if (minutes)
        totalSeconds += minutes * 60;
    if (seconds)
        totalSeconds += seconds;
    return totalSeconds;
};
/**
 * transformJobsFormData helper converts the formData into the filter options
 */
export function transformJobsFormData(_a) {
    var jobType = _a.jobType, jobStatus = _a.jobStatus, jobId = _a.jobId, durationOperator = _a.durationOperator, durationHours = _a.durationHours, durationMinutes = _a.durationMinutes, fromDate = _a.fromDate, toDate = _a.toDate;
    var filterOptions = {};
    if (jobType)
        filterOptions.jobType = jobType;
    if (jobStatus)
        filterOptions.jobStatus = jobStatus;
    if (jobId)
        filterOptions.jobId = jobId;
    if (durationOperator && (durationHours || durationMinutes)) {
        if (durationOperator) {
            filterOptions.durationOperator = durationOperator;
            filterOptions.duration = String(convertSeconds({
                hours: !Number.isNaN(Number(durationHours)) ? Number(durationHours) : undefined,
                minutes: !Number.isNaN(Number(durationMinutes)) ? Number(durationMinutes) : undefined,
            }));
        }
    }
    if (fromDate || toDate) {
        filterOptions.fromDate = fromDate ? fromDate.format('YYYY-MM-DDTHH:mm:ss') : undefined;
        filterOptions.toDate = toDate ? toDate.format('YYYY-MM-DDTHH:mm:ss') : undefined;
    }
    return filterOptions;
}
/**
 * useFormatJobsFilterPillLabel helper converts the filter option to a custom label
 */
export function useFormatJobsFilterPillLabel(_a) {
    var key = _a.key, label = _a.label, value = _a.value;
    var t = useTranslation().t;
    var valueLabel = '';
    if (key === 'jobType' && value) {
        valueLabel = t('pages.jobs.filter.options.job_type.select_options')[value];
    }
    if (key === 'jobStatus' && value) {
        valueLabel = t('pages.jobs.filter.options.job_status.select_options')[value];
    }
    if (key === 'jobId' && value) {
        valueLabel = value;
    }
    if (key === 'duration') {
        var _b = __read(value, 2), durationOperator = _b[0], duration = _b[1];
        if (durationOperator && duration) {
            var seconds = Number(duration);
            if (!Number.isNaN(seconds)) {
                valueLabel = format(t('pages.jobs.filter.options.duration.pill_labels')[durationOperator], secondsToDHMS(seconds));
            }
        }
    }
    if (key === 'dateRange') {
        var _c = __read(value, 2), fromDate = _c[0], toDate = _c[1];
        // non-strict date validation
        var parsedFromDate = dayjs(fromDate);
        var parsedToDate = dayjs(toDate);
        var formattedFromDate = fromDate && parsedFromDate.isValid() ? parsedFromDate.format(DATE_INPUT_FORMAT) : '';
        var formattedToDate = toDate && parsedToDate.isValid() ? parsedToDate.format(DATE_INPUT_FORMAT) : '';
        if (formattedFromDate === formattedToDate) {
            valueLabel = formattedFromDate;
        }
        else if (formattedFromDate && formattedToDate) {
            valueLabel = format(t('pages.jobs.filter.options.date_range.pill_labels.both'), formattedFromDate, formattedToDate);
        }
        else if (formattedFromDate && !formattedToDate) {
            valueLabel = format(t('pages.jobs.filter.options.date_range.pill_labels.min_only'), formattedFromDate);
        }
        else if (!formattedFromDate && formattedToDate) {
            valueLabel = format(t('pages.jobs.filter.options.date_range.pill_labels.max_only'), formattedToDate);
        }
    }
    return valueLabel ? "".concat(label, ": ").concat(valueLabel) : label;
}
