var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { OGPanel } from '@opengear/panel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useDeviceView } from '@/hooks/use-device-view';
import { useUpdateConsoleGateway } from '@/api/services/console-gateway';
import { useFormSync } from '@/hooks/use-form-sync';
import { UpdateForm } from '@/components/layout/update-form';
import { requiredFieldError } from '@/utils/field-validation-errors';
export var CONSOLE_GATEWAY_FORM_LOADER_ID = 'console-gateway-form-loading';
export function ConsoleGatewayForm(_a) {
    var _this = this;
    var editable = _a.editable, data = _a.data, isFetchingData = _a.isFetchingData;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    // Update console gateway hook
    var updateConsoleGateway = useUpdateConsoleGateway();
    var sourceValues = useMemo(function () { return ({
        ssh_url_delimiter: data.ssh_url_delimiter,
        ssh_url_address: data.ssh_url_address,
    }); }, [data.ssh_url_address, data.ssh_url_delimiter]);
    var _b = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _b.control, handleSubmit = _b.handleSubmit, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _c = _b.formState, defaultValues = _c.defaultValues, dirtyFields = _c.dirtyFields, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting, isValid = _c.isValid, reset = _b.reset;
    // responsible for alert the user of data changes
    useFormSync(defaultValues, sourceValues, reset, t('pages.console_gateway.form.reset_warning.header'), isFetchingData);
    // Resets form back to current values from api or defaults
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    // Submit handler to update the console gateway details
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var sshUrlAddress, sshUrlDelimiter, updatedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    sshUrlAddress = formData.ssh_url_address;
                    sshUrlDelimiter = formData.ssh_url_delimiter;
                    return [4 /*yield*/, updateConsoleGateway({
                            ssh_url_address: sshUrlAddress,
                            ssh_url_delimiter: sshUrlDelimiter,
                        })
                        // If successful update, reset the form based on the new values
                    ];
                case 1:
                    updatedData = _a.sent();
                    // If successful update, reset the form based on the new values
                    if (updatedData) {
                        reset({
                            ssh_url_address: updatedData.console_gateway.ssh_url_address,
                            ssh_url_delimiter: updatedData.console_gateway.ssh_url_delimiter,
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [reset, updateConsoleGateway]);
    return (_jsx(OGPanel, { children: _jsxs(UpdateForm, __assign({ "aria-label": t('pages.console_gateway.form.label'), dirty: isDirty, editable: !!editable, submitting: isSubmitting, valid: isValid, onReset: handleReset, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Controller, { control: control, name: "ssh_url_delimiter", rules: {
                        required: requiredFieldError(t('pages.console_gateway.form.fields.port_delimiter.label')),
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "ssh_url_delimiter", disabled: !editable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.console_gateway.form.fields.port_delimiter.label'), _jsx(OGIconTooltip, { title: t('pages.console_gateway.form.fields.port_delimiter.description') })] }), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true, inputProps: { maxLength: 1 } }, field)));
                    } }), _jsx(Controller, { control: control, name: "ssh_url_address", rules: {
                        required: requiredFieldError(t('pages.console_gateway.form.fields.ssh_address.label')),
                    }, render: function (_a) {
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ select: true, id: "ssh_url_address", inputProps: { 'aria-labelledby': 'ssh_url_address-label' }, disabled: !editable, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.console_gateway.form.fields.ssh_address.label'), _jsx(OGIconTooltip, { title: t('pages.console_gateway.form.fields.ssh_address.description') })] }), margin: "none", sx: { maxWidth: isMobileView ? undefined : 300 } }, field, { children: data.ssh_addresses.map(function (addr) { return (_jsx(MenuItem, __assign({ value: addr }, { children: addr }), addr)); }) })));
                    } })] })) }));
}
