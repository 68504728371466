var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { OGButton } from '@opengear/buttons';
import { useTheme } from '@mui/material/styles';
import { useAdvanceTemplatePush, useTemplatePushExecContext } from '@/api/template-push/template-push';
import { useDocTitle } from '@/hooks/doc-title';
import { CONFIG_TEMPLATES_ROUTE, PUSH_TEMPLATE_NODE_SELECT_ROUTE, PUSH_TEMPLATE_STATUS_ROUTE } from '@/constants/routes';
import { TemplatePreviewSection, TemplatePushStepper } from '@/containers/config/templates/push/template-push-components';
import TemplatePreflightTable from '@/containers/config/templates/push/template-preflight-table';
import { useNodes } from '@/api/nodes';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { nodeIdsToQuery, useJson } from '@/utils/query';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var PREFLIGHT_LOADER_TEST_ID = 'preflight-loading';
function PreflightPageContent(_a) {
    var _this = this;
    var contextId = _a.contextId;
    var t = useTranslation().t;
    var navigate = useNavigate();
    // Refetch context every second until the preflight is complete
    var _b = __read(useState(1000), 2), refetchInterval = _b[0], setRefetchInterval = _b[1];
    var _c = __read(useState([]), 2), selectedNodes = _c[0], setSelectedNodes = _c[1];
    var _d = __read(useState(false), 2), buttonBusy = _d[0], setButtonBusy = _d[1];
    var advanceTemplate = useAdvanceTemplatePush();
    var contextData = useTemplatePushExecContext(contextId, { refetchInterval: refetchInterval }).data;
    var theme = useTheme();
    var nodeIds = (contextData === null || contextData === void 0 ? void 0 : contextData.node_contexts.map(function (node_ctx) { return node_ctx.node_id; })) || [];
    var nodeJson = useJson(nodeIdsToQuery(nodeIds));
    var nodeData = useNodes({ json: nodeJson }, { enabled: !!nodeIds }).data;
    useEffect(function () {
        if (contextData && contextData.status === 'preflight_complete') {
            setRefetchInterval(false);
        }
    }, [contextData]);
    var onPush = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var advanceResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!contextData) return [3 /*break*/, 2];
                    setButtonBusy(true);
                    return [4 /*yield*/, advanceTemplate(contextId, {
                            template_push: {
                                stage: 'execute',
                                node_ids: selectedNodes,
                                template_ids: contextData.templates,
                            },
                        })];
                case 1:
                    advanceResult = _a.sent();
                    if (advanceResult) {
                        navigate(PUSH_TEMPLATE_STATUS_ROUTE, { state: { contextId: contextId } });
                    }
                    setButtonBusy(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [advanceTemplate, contextData, contextId, navigate, selectedNodes]);
    if (!contextData || !nodeData)
        return _jsx(LoadingBackdrop, { "data-testid": PREFLIGHT_LOADER_TEST_ID });
    return (_jsxs(_Fragment, { children: [_jsx(Paper, { children: _jsx(TemplatePushStepper, { step: 1 }) }), _jsx(Paper, __assign({ sx: { marginTop: 2 } }, { children: _jsxs(Stack, __assign({ display: "flex", direction: "row", flexBasis: 0 }, { children: [_jsx(Box, __assign({ flexGrow: 1 }, { children: _jsx(TemplatePreviewSection, { templateId: contextData.templates[0] }) })), _jsxs(Box, __assign({ flexGrow: 2, borderLeft: "1px solid ".concat(theme.palette.divider), minWidth: 0 }, { children: [_jsx(Box, __assign({ padding: 2, minWidth: 0 }, { children: _jsx(TemplatePreflightTable, { nodeContexts: contextData.node_contexts, nodeData: nodeData.nodes, status: contextData.status, onSelectionChange: setSelectedNodes }) })), _jsxs(Stack, __assign({ spacing: 1, padding: 2, borderTop: "1px solid ".concat(theme.palette.divider), direction: "row", display: "flex", justifyContent: "flex-end" }, { children: [_jsx(OGButton, __assign({ variant: "secondary", onClick: function () { return navigate(PUSH_TEMPLATE_NODE_SELECT_ROUTE, { state: { templateId: contextData.templates[0] } }); } }, { children: t('pages.config_templates.push.back_to_select') })), _jsx(OGButton, __assign({ disabled: contextData.status !== 'preflight_complete' || selectedNodes.length === 0, variant: "primary", onClick: onPush, loading: buttonBusy }, { children: t('pages.config_templates.push.push_config') }))] }))] }))] })) }))] }));
}
function TemplatePushPreflightPage() {
    var t = useTranslation().t;
    useDocTitle(t('pages.config_templates.push.title'));
    var locationState = useLocation().state;
    var navigate = useNavigate();
    useEffect(function () {
        if (locationState === null || !locationState.contextId) {
            navigate(CONFIG_TEMPLATES_ROUTE);
        }
    }, [locationState, navigate]);
    var innerNode = _jsx(LoadingBackdrop, { "data-testid": PREFLIGHT_LOADER_TEST_ID });
    if (locationState === null || locationState === void 0 ? void 0 : locationState.contextId) {
        innerNode = _jsx(PreflightPageContent, { contextId: locationState.contextId });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.config_templates.push.title') })), innerNode] })));
}
export default withAuthenticationRequired(TemplatePushPreflightPage, { rights: ['configure.configuration_templating.apply_templates.push'] });
