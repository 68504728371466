var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuList from '@mui/material/MenuList';
import { useTranslation } from 'react-i18next';
import { OGNavMainMenuLink, OGNavMainMenuSection } from '@opengear/navigation';
import { OGDashboardIcon, OGJobsIcon, OGNodesIcon, OGNodeToolsIcon, OGNetopsIcon, OGPortsIcon, OGResourcesIcon, OGTerminalIcon, } from '@opengear/icons/navigation';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { DASHBOARD_ROUTE, ENROLLMENT_BUNDLES_ROUTE, ENROLLMENT_SETTINGS_ROUTE, JOBS_ROUTE, LOCAL_TERMINAL_ROUTE, PORTS_ROUTE, NODES_ROUTE, FIRMWARE_UPGRADE_ROUTE, CONFIG_TEMPLATES_ROUTE, SMF_RESOURCES_ROUTE, formatNetopsUIRoute, MANAGE_NETOPS_MODULES_ROUTE, SERVICES_NETOPS_ROUTE, } from '@/constants/routes';
import { useNetopsModules } from '@/api/netops';
import { useNetopsModuleRoutes } from '@/api/netops/routes';
import { useNetopsModuleNamesMap } from '@/api/netops/modules.constants';
export var NAVIGATION_MENU_PLACEHOLDER_ID = 'navigation-menu-placeholder';
function NetopsMenuSection(_a) {
    var _b;
    var moduleInfo = _a.moduleInfo;
    var moduleNameMap = useNetopsModuleNamesMap();
    var data = useNetopsModuleRoutes(moduleInfo.id).data;
    if (!data)
        return null;
    return (_jsx(OGNavMainMenuLink, __assign({ level: 1, to: formatNetopsUIRoute(moduleInfo.id, '') }, { children: (_b = moduleNameMap.get(moduleInfo.id)) !== null && _b !== void 0 ? _b : moduleInfo.name })));
}
export function NavigationMenu() {
    var _a = useUIPermissions(), permissions = _a.permissions, hasPermission = _a.hasPermission, hasSomePermission = _a.hasSomePermission;
    var t = useTranslation().t;
    var canRetrieveModules = hasPermission('configure.netops.modules.view');
    var moduleData = useNetopsModules({ enabled: canRetrieveModules }).data;
    return permissions ? (_jsxs(MenuList, __assign({ sx: { paddingX: 0.5 } }, { children: [hasPermission('monitor.dashboard.view') && (_jsx(OGNavMainMenuLink, __assign({ id: "dashboard", to: DASHBOARD_ROUTE, icon: _jsx(OGDashboardIcon, {}) }, { children: t('pages.dashboard.title') }))), hasPermission('monitor.jobs.view') && (_jsx(OGNavMainMenuLink, __assign({ to: JOBS_ROUTE, icon: _jsx(OGJobsIcon, {}) }, { children: t('pages.jobs.title') }))), hasPermission('monitor.nodes.view') && (_jsx(OGNavMainMenuLink, __assign({ to: NODES_ROUTE, icon: _jsx(OGNodesIcon, {}) }, { children: t('pages.nodes.title') }))), hasPermission('manage.managed_devices.console_gateway.view') && (_jsx(OGNavMainMenuLink, __assign({ to: PORTS_ROUTE, icon: _jsx(OGPortsIcon, {}) }, { children: t('pages.ports.title') }))), hasPermission('manage.resources.view') && (_jsx(OGNavMainMenuLink, __assign({ to: SMF_RESOURCES_ROUTE, icon: _jsx(OGResourcesIcon, {}) }, { children: t('pages.smf_resources.title') }))), hasPermission('manage.lighthouse.local_terminal') && (_jsx(OGNavMainMenuLink, __assign({ id: "local-terminal-nav-item", to: LOCAL_TERMINAL_ROUTE, icon: _jsx(OGTerminalIcon, {}) }, { children: t('pages.local_terminal.title') }))), _jsxs(OGNavMainMenuSection, __assign({ id: "node-tools", prefixes: ['/config'], text: t('navigation.node_tools.title'), icon: _jsx(OGNodeToolsIcon, {}) }, { children: [hasSomePermission([
                        'configure.configuration_templating.authentication_templates.view',
                        'configure.configuration_templating.users_groups_templates.view',
                        'configure.configuration_templating.script_templates.view',
                        'configure.configuration_templating.smf_templates.view',
                    ]) && (_jsx(OGNavMainMenuLink, __assign({ level: 1, sx: { marginTop: 0 }, to: CONFIG_TEMPLATES_ROUTE }, { children: t('pages.config_templates.title') }))), hasPermission('configure.node_enrollment.enrollment_bundles.view') && (_jsx(OGNavMainMenuLink, __assign({ level: 1, to: ENROLLMENT_BUNDLES_ROUTE }, { children: t('pages.enrollment_bundles.title') }))), hasPermission('configure.node_enrollment.enrollment_settings.view') && (_jsx(OGNavMainMenuLink, __assign({ level: 1, to: ENROLLMENT_SETTINGS_ROUTE }, { children: t('pages.enrollment_settings.title') }))), hasPermission('settings.services.node_upgrade.view') && (_jsx(OGNavMainMenuLink, __assign({ level: 1, sx: { marginBottom: 0 }, to: FIRMWARE_UPGRADE_ROUTE }, { children: t('pages.firmware_upgrade.title') })))] })), _jsxs(OGNavMainMenuSection, __assign({ prefixes: ['/nom', '/netops', '/settings/services/netops/'], text: t('pages.netops.title'), icon: _jsx(OGNetopsIcon, {}), collapseProps: { unmountOnExit: false } }, { children: [hasPermission('configure.netops.modules.view') && (_jsx(OGNavMainMenuLink, __assign({ sx: { marginTop: 0 }, level: 1, id: "manage-netops-modules-nav-item", to: MANAGE_NETOPS_MODULES_ROUTE }, { children: t('pages.nom.title') }))), hasPermission('settings.services.netops.view') && (_jsx(OGNavMainMenuLink, __assign({ level: 1, sx: { marginBottom: 0 }, to: SERVICES_NETOPS_ROUTE }, { children: t('pages.netops_settings.title') }))), hasPermission('configure.netops.modules.view') &&
                        (moduleData === null || moduleData === void 0 ? void 0 : moduleData['netops-modules'].map(function (moduleInfo) { return _jsx(NetopsMenuSection, { moduleInfo: moduleInfo }, moduleInfo.id); }))] }))] }))) : (_jsx("div", { "data-testid": NAVIGATION_MENU_PLACEHOLDER_ID }));
}
