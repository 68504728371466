import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUsTranslation from './locales/en-us/translation.json';
export var defaultNS = 'translation';
export var resources = {
    'en-US': {
        translation: enUsTranslation,
    },
};
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
    detection: {
        caches: [],
    },
    fallbackLng: 'en-US',
    resources: resources,
    defaultNS: defaultNS,
    ns: ['enUsTranslation'],
    nonExplicitSupportedLngs: true,
    keySeparator: '.',
    debug: process.env.NODE_ENV === 'development',
    returnObjects: true,
})
    .catch(console.error);
export default i18n;
