var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDeleteSession } from '@/api/sessions';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { useActivityTimeout } from './activity-timeout';
import { FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE, NEW_FIRMWARE_UPGRADE_ROUTE, LOCAL_TERMINAL_ROUTE, PUSH_TEMPLATE_STATUS_ROUTE, SERVICES_NETOPS_OFFLINE_ROUTE, SERVICES_NETOPS_ONLINE_ROUTE, SYSTEM_CONFIG_RESTORE_ROUTE, SYSTEM_UPGRADE_ROUTE, formatDevicePortTerminalRoute, } from '@/constants/routes';
export var DEFAULT_WEB_TIMEOUT = 20;
// Routes with long running operations that ignore the idle timeout
export var IGNORED_ROUTES = [
    LOCAL_TERMINAL_ROUTE,
    SYSTEM_UPGRADE_ROUTE,
    SERVICES_NETOPS_ONLINE_ROUTE,
    SERVICES_NETOPS_OFFLINE_ROUTE,
    SYSTEM_CONFIG_RESTORE_ROUTE,
    PUSH_TEMPLATE_STATUS_ROUTE,
    FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE,
    NEW_FIRMWARE_UPGRADE_ROUTE,
    formatDevicePortTerminalRoute(),
];
/**
 * Custom hook to manage user session timeouts and handle automatic sign-out on session expiry.
 */
export function useTimeoutSession() {
    var _this = this;
    var signOut = useDeleteSession();
    var pathname = useLocation().pathname;
    var timeout = useUIPermissions({ refetchIntervalInBackground: true }).timeout;
    var lastKnownTimeout = useRef(timeout);
    var alertNotification = useAlertNotification().alertNotification;
    var t = useTranslation().t;
    var isIgnoredRoute = useMemo(function () { return IGNORED_ROUTES.some(function (route) { return matchPath(route, pathname); }); }, [pathname]);
    var timeoutMilliseconds = useMemo(function () { var _a; return ((_a = timeout !== null && timeout !== void 0 ? timeout : lastKnownTimeout.current) !== null && _a !== void 0 ? _a : DEFAULT_WEB_TIMEOUT) * 60 * 1000; }, [timeout]);
    var onExpiry = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isIgnoredRoute)
                        return [2 /*return*/];
                    alertNotification(t('api.errors.unauthorized.message'), {
                        header: t('api.errors.unauthorized.header'),
                        persistOnUnmount: true,
                        preventDuplicate: true,
                        priority: OGToastPriorities.Warning,
                    });
                    return [4 /*yield*/, signOut()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, isIgnoredRoute, signOut, t]);
    // Keep track of the last known non-null timeout
    // This avoids the user being unexpectedly signed out due to a temporary network/backend error
    useEffect(function () {
        if (timeout)
            lastKnownTimeout.current = timeout;
    }, [timeout]);
    useActivityTimeout(timeoutMilliseconds, onExpiry);
}
