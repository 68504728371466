var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGTerminalIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-4 -5 24 24' }, props), { children: _jsx("svg", __assign({ fill: "none" }, { children: _jsx("path", { d: "M13.3175 1C13.8135 1 14.2143 1.44687 14.2143 2V12C14.2143 12.5531 13.8135 13 13.3175 13H2.55555C2.0595 13 1.65873 12.5531 1.65873 12V2C1.65873 1.44687 2.0595 1 2.55555 1H13.3175ZM2.55555 0C1.56624 0 0.761902 0.896875 0.761902 2V12C0.761902 13.1031 1.56624 14 2.55555 14H13.3175C14.3068 14 15.1111 13.1031 15.1111 12V2C15.1111 0.896875 14.3068 0 13.3175 0H2.55555ZM3.56168 3.675C3.39913 3.88438 3.42155 4.2 3.60932 4.38125L6.35024 7L3.60932 9.62187C3.42155 9.80312 3.39913 10.1156 3.56168 10.3281C3.72423 10.5406 4.00729 10.5625 4.19506 10.3813L7.33395 7.38125C7.43204 7.2875 7.49089 7.14688 7.49089 7.00313C7.49089 6.85938 7.43484 6.71875 7.33395 6.625L4.19506 3.625C4.00729 3.44375 3.72423 3.46875 3.56168 3.67812V3.675ZM7.03968 10C6.79305 10 6.59127 10.225 6.59127 10.5C6.59127 10.775 6.79305 11 7.03968 11H11.9722C12.2188 11 12.4206 10.775 12.4206 10.5C12.4206 10.225 12.2188 10 11.9722 10H7.03968Z", fill: "currentColor" }) })) })));
}
