var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useId, useMemo, forwardRef, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import format from 'string-format';
import debounce from 'lodash/debounce';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useNavigate } from 'react-router-dom';
import { OGButton } from '@opengear/buttons';
import { OG_GREY_ML } from '@opengear/theme/colors';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import i18n from '@/i18n/config';
import { useEnrollNode } from '@/api/nodes';
import { SimpleStatus } from '@/api/utils';
import { NODES_ROUTE, NODES_PENDING_ROUTE } from '@/constants/routes';
import { LicenseSelectField } from '@/components/licenses/license-select';
import { HOSTNAME_OR_ADDRESS_PATTERN, NUM_ONLY_PATTERN } from '@/constants/regex';
var products = [
    { type: 'opengear', name: i18n.t('components.enroll_node_modal.form.fields.product.opengear') },
    { type: 'generic', name: i18n.t('components.enroll_node_modal.form.fields.product.generic') },
    { type: 'acs6000', name: i18n.t('components.enroll_node_modal.form.fields.product.acs6000') },
    { type: 'acs8000', name: i18n.t('components.enroll_node_modal.form.fields.product.acs8000') },
    { type: 'acs_classic', name: i18n.t('components.enroll_node_modal.form.fields.product.acs_classic') },
    { type: 'cisco2900', name: i18n.t('components.enroll_node_modal.form.fields.product.cisco2900') },
    { type: 'digi_passport', name: i18n.t('components.enroll_node_modal.form.fields.product.digi_passport') },
];
var connectionMethods = [
    { method: 'ssh', name: 'SSH' },
    { method: 'telnet', name: 'Telnet' },
];
export var InputField = forwardRef(function (_a, ref) {
    var name = _a.name, label = _a.label, error = _a.error, helperText = _a.helperText, sx = _a.sx, required = _a.required, inputAttributes = __rest(_a, ["name", "label", "error", "helperText", "sx", "required"]);
    var theme = useTheme();
    var _b = __read(useState(false), 2), focus = _b[0], setFocus = _b[1];
    var inputStyles = useMemo(function () { return (__assign(__assign(__assign({ 'display': 'flex', 'backgroundColor': theme.palette.background.input, 'borderRadius': "".concat(theme.shape.borderRadius, "px !important"), 'border': "1px solid ".concat(theme.palette.divider), '&:hover': { borderColor: OG_GREY_ML } }, (focus && {
        borderColor: "".concat(theme.palette.text.primary, " !important"),
        boxShadow: "0px 4px 4px rgba(0, 0, 0, ".concat(theme.palette.mode === 'dark' ? 0.9 : 0.2, ") inset"),
    })), (error && { borderColor: theme.palette.error.main })), { '&+p': { color: theme.palette.error.main, margin: 0, marginTop: '3px', lineHeight: 1.5, fontWeight: 400 }, 'label + &': { marginTop: theme.spacing(0.75) }, '& input': {
            width: '100%',
            height: '1.5em',
            boxSizing: 'content-box',
            padding: "".concat(theme.spacing(0.5), " ").concat(theme.spacing(1)),
            color: 'currentColor',
            fontSize: '1.75rem',
            fontWeight: 400,
            lineHeight: 1.5,
            background: 'none',
            border: 'none',
            outline: 'none',
        } })); }, [error, focus, theme]);
    return (_jsxs(Box, __assign({ sx: __assign({ display: 'flex', flexDirection: 'column', marginBottom: 1.5 }, sx) }, { children: [label && (_jsx(InputLabel, __assign({ required: required, htmlFor: name }, { children: label }))), _jsx(Box, __assign({ sx: inputStyles, onBlur: function () { return setFocus(false); }, onFocus: function () { return setFocus(true); } }, { children: _jsx("input", __assign({ autoComplete: "off", "aria-invalid": error }, (error && helperText ? { 'aria-describedby': "".concat(name, "-helper-text") } : {}), { id: name, name: name, ref: ref }, inputAttributes)) })), error && helperText ? _jsx("p", __assign({ id: "".concat(name, "-helper-text") }, { children: helperText })) : null] })));
});
function PortControllers(_a) {
    var control = _a.control, setError = _a.setError, clearErrors = _a.clearErrors;
    var t = useTranslation().t;
    var _b = useFieldArray({
        control: control,
        name: 'port_labels',
    }), fields = _b.fields, append = _b.append, remove = _b.remove;
    var handlePortCountChange = useCallback(function (event) {
        var portCount = parseFloat(event.target.value);
        var fieldsLength = fields.length;
        if (Number.isNaN(portCount)) {
            for (var i = fieldsLength; i > 0; i--) {
                remove(i - 1);
            }
            setError('port_count', { type: 'required', message: t('components.enroll_node_modal.form.fields.port_count.errors.required') });
            return;
        }
        if (portCount > 400) {
            setError('port_count', { type: 'max', message: t('components.enroll_node_modal.form.fields.port_count.errors.less_than_401') });
            return;
        }
        if (portCount < 1) {
            setError('port_count', { type: 'min', message: t('components.enroll_node_modal.form.fields.port_count.errors.equal_greater_than_1') });
            return;
        }
        if (!NUM_ONLY_PATTERN.test(String(portCount))) {
            setError('port_count', { type: 'pattern', message: t('components.enroll_node_modal.form.fields.port_count.errors.integer') });
            return;
        }
        clearErrors('port_count');
        if (portCount > fieldsLength) {
            for (var i = fieldsLength; i < portCount; i++) {
                append("Port ".concat(i + 1));
            }
        }
        else {
            for (var i = fieldsLength; i > portCount; i--) {
                remove(i - 1);
            }
        }
    }, [append, clearErrors, fields.length, remove, setError, t]);
    var handlePortCountChangeDebounced = debounce(handlePortCountChange, 400);
    return (_jsxs(_Fragment, { children: [_jsx(Controller, { control: control, name: "port_count", render: function (_a) {
                    var _b = _a.field, value = _b.value, onBlur = _b.onBlur, onChange = _b.onChange, field = __rest(_b, ["value", "onBlur", "onChange"]), error = _a.fieldState.error;
                    return (_jsx(TextField, __assign({ required: true, fullWidth: true, id: "port_count", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, InputProps: {
                            inputProps: { min: 1, max: 400, step: 1 },
                        }, label: t('components.enroll_node_modal.form.fields.port_count.label'), type: "number", value: value, onBlur: function () { return false; }, onChange: function (event) {
                            // Change port count
                            onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                            // As a side effect, change port labels
                            handlePortCountChangeDebounced(event);
                        } }, field)));
                } }), fields.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h3", marginY: 2, variant: "h3" }, { children: t('components.enroll_node_modal.form.port_labels') })), fields.map(function (field, index) { return (_jsx(Controller, { control: control, defaultValue: "Port ".concat(index), name: "port_labels.".concat(index), rules: { required: t('components.enroll_node_modal.form.fields.port_labels.errors.required') }, render: function (_a) {
                            var _b = _a.field, value = _b.value, fieldAttributes = __rest(_b, ["value"]), error = _a.fieldState.error;
                            return (_jsx(InputField, __assign({ label: format(t('components.enroll_node_modal.form.fields.port_labels.label'), (index + 1).toString()), error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, value: value !== null && value !== void 0 ? value : "Port ".concat(index), type: "text", required: true }, fieldAttributes)));
                        } }, field.id)); })] }))] }));
}
export function NodeEnrollmentForm(_a) {
    var _this = this;
    var onClose = _a.onClose, defaultSubscriptionData = _a.defaultSubscriptionData, subscriptions = _a.subscriptions, thirdPartySupported = _a.thirdPartySupported;
    var id = useId();
    var navigate = useNavigate();
    var enrollNode = useEnrollNode();
    var t = useTranslation().t;
    var _b = useForm({
        defaultValues: {
            product: 'opengear',
            address: '',
            username: '',
            password: '',
            subscription: defaultSubscriptionData === null || defaultSubscriptionData === void 0 ? void 0 : defaultSubscriptionData.enrollment_default_subscription.subscription_type,
            auto_approve: false,
            preflight: true,
        },
        mode: 'onBlur',
    }), control = _b.control, createSubmitHandler = _b.handleSubmit, _c = _b.formState, isValid = _c.isValid, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting, unregister = _b.unregister, register = _b.register, clearErrors = _b.clearErrors, setError = _b.setError, watch = _b.watch;
    var handleProductChange = useCallback(function (event) {
        var productValue = event.target.value;
        if (productValue === 'opengear') {
            unregister(['name', 'port_count', 'base_port', 'connection_method', 'port_labels']);
        }
        else if (productValue === 'generic') {
            register('name', { value: '' });
            register('port_count', { value: 4 });
            register('base_port', { value: 3000 });
            register('connection_method', { value: 'ssh' });
            register('port_labels', { value: ['Port 1', 'Port 2', 'Port 3', 'Port 4'] });
        }
        else {
            register('name', { value: '' });
            unregister(['port_count', 'base_port', 'connection_method', 'port_labels']);
        }
    }, [register, unregister]);
    var handleSubmit = useMemo(function () {
        return createSubmitHandler(function (enrollment) { return __awaiter(_this, void 0, void 0, function () {
            var status;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, enrollNode(enrollment)];
                    case 1:
                        status = _a.sent();
                        if (status === SimpleStatus.Success) {
                            onClose();
                            navigate("".concat(NODES_ROUTE).concat(NODES_PENDING_ROUTE));
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    }, [createSubmitHandler, navigate, onClose, enrollNode]);
    var selectedProductType = watch('product');
    return (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: _jsxs(Stack, __assign({ "aria-label": t('components.enroll_node_modal.form.label'), component: "form", id: id, onSubmit: handleSubmit }, { children: [_jsx(Controller, { control: control, name: "product", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
                                return (_jsx(TextField
                                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                , __assign({ 
                                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus: true, fullWidth: true, select: true, id: "product-type", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('components.enroll_node_modal.form.fields.product.label'), disabled: !thirdPartySupported, onChange: function (event) {
                                        // Change product field
                                        onChange(event);
                                        // As a side effect, change conditional fields accordingly
                                        handleProductChange(event);
                                    } }, field, { children: products.map(function (product) { return (_jsx(MenuItem, __assign({ value: product.type }, { children: product.name }), "".concat(product.type))); }) })));
                            } }), selectedProductType !== 'opengear' && (_jsx(Controller, { control: control, name: "name", rules: { required: t('components.enroll_node_modal.form.fields.name.errors.required') }, render: function (_a) {
                                var _b;
                                var field = _a.field, error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, required: true, id: "name", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('components.enroll_node_modal.form.fields.name.label'), type: "text" }, field, { value: (_b = field.value) !== null && _b !== void 0 ? _b : '' })));
                            } })), _jsx(Controller, { control: control, name: "address", rules: {
                                required: t('components.enroll_node_modal.form.fields.ip.errors.required'),
                                pattern: {
                                    value: HOSTNAME_OR_ADDRESS_PATTERN,
                                    message: t('components.enroll_node_modal.form.fields.ip.errors.not_valid'),
                                },
                            }, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, required: true, id: "address", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('components.enroll_node_modal.form.fields.ip.label'), type: "text" }, field)));
                            } }), selectedProductType === 'generic' && (_jsx(Controller, { control: control, name: "connection_method", defaultValue: "ssh", render: function (_a) {
                                var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]), error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, select: true, value: value !== null && value !== void 0 ? value : 'ssh', id: "connection_method", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('components.enroll_node_modal.form.fields.connection_method.label') }, field, { children: connectionMethods.map(function (connectionMethod) { return (_jsx(MenuItem, __assign({ value: connectionMethod.method }, { children: connectionMethod.name }), "".concat(connectionMethod.method))); }) })));
                            } })), _jsx(Controller, { control: control, name: "username", rules: { required: t('components.enroll_node_modal.form.fields.username.errors.required') }, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, required: true, id: "username", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('components.enroll_node_modal.form.fields.username.label'), type: "text" }, field)));
                            } }), _jsx(Controller, { control: control, name: "password", rules: { required: t('components.enroll_node_modal.form.fields.password.errors.required') }, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (_jsx(TextField, __assign({ fullWidth: true, required: true, id: "password", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('components.enroll_node_modal.form.fields.password.label'), type: "password" }, field)));
                            } }), subscriptions.length && defaultSubscriptionData ? (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [t('components.enroll_node_modal.form.fields.subscription_type.label'), _jsx(OGIconTooltip, { title: t('components.enroll_node_modal.form.fields.subscription_type.tooltip') })] }), _jsx(Controller, { control: control, name: "subscription", render: function (_a) {
                                        var field = _a.field;
                                        return (_jsx(LicenseSelectField, { subscriptionsData: subscriptions, field: field, ariaLabel: t('components.enroll_node_modal.form.fields.subscription_type.label'), disabled: subscriptions.length < 2 }));
                                    } })] })) : null, _jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "auto_approve", render: function (_a) {
                                        var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                        return _jsx(Checkbox, __assign({ checked: value }, field));
                                    } }), label: t('components.enroll_node_modal.form.fields.auto_approve.label') }) }), _jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "preflight", render: function (_a) {
                                        var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                        return _jsx(Checkbox, __assign({ checked: value }, field));
                                    } }), label: _jsxs(_Fragment, { children: [t('components.enroll_node_modal.form.fields.preflight.label'), _jsx(OGIconTooltip, { title: t('components.enroll_node_modal.form.fields.preflight.tooltip') })] }) }) }), selectedProductType === 'generic' && (_jsxs(_Fragment, { children: [_jsx(Controller, { control: control, name: "base_port", rules: {
                                        validate: function (value) {
                                            if (value === undefined || value === '')
                                                return t('components.enroll_node_modal.form.fields.base_port.errors.required');
                                            if (value < 1)
                                                return t('components.enroll_node_modal.form.fields.base_port.errors.equal_greater_than_1');
                                            if (value > 65535)
                                                return t('components.enroll_node_modal.form.fields.base_port.errors.less_than_65536');
                                            if (!Number.isInteger(value))
                                                return t('components.enroll_node_modal.form.fields.base_port.errors.integer');
                                            return true;
                                        },
                                    }, render: function (_a) {
                                        var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error;
                                        return (_jsx(TextField, __assign({ required: true, fullWidth: true, id: "base_port", error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, InputProps: {
                                                inputProps: { min: 1, max: 65535, step: 1 },
                                            }, label: _jsxs(_Fragment, { children: [t('components.enroll_node_modal.form.fields.base_port.label'), _jsx(OGIconTooltip, { title: t('components.enroll_node_modal.form.fields.base_port.tooltip') })] }), type: "number", value: value, onChange: function (event) {
                                                onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                                            } }, field)));
                                    } }), _jsx(PortControllers, { control: control, setError: setError, clearErrors: clearErrors })] }))] })) }), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: onClose }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ form: id, type: "submit", disabled: !isValid || !isDirty, loading: isSubmitting }, { children: t('global.buttons.enroll_node') }))] })] }));
}
