var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { useNavigate } from 'react-router-dom';
import DialogContentText from '@mui/material/DialogContentText';
import { useDeleteTemplate } from '@/api/template/templates';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
export function DeleteTemplateModal(_a) {
    var editable = _a.editable, templateId = _a.templateId, redirectUrl = _a.redirectUrl;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var deleteTemplate = useDeleteTemplate();
    var deleteHandler = useCallback(function () { return deleteTemplate(templateId); }, [deleteTemplate, templateId]);
    var onSuccess = useCallback(function () { return navigate(redirectUrl); }, [navigate, redirectUrl]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.config_templates.delete.modal.title'), deleteButtonLabel: t('pages.config_templates.delete.button'), onDelete: deleteHandler, onSuccess: onSuccess, disabled: !editable }, { children: _jsx(DialogContentText, { children: format(t('pages.config_templates.delete.modal.message'), templateId) }) })));
}
