var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OGLink, OGLinkButton } from '@opengear/links';
import format from 'string-format';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import WebUIIcon from '@mui/icons-material/Computer';
import Tooltip from '@mui/material/Tooltip';
import { OGTable } from '@opengear/table';
import Stack from '@mui/material/Stack';
import { useNodes } from '@/api/nodes';
import { formatMessageWithList, getConfiguredPorts, processBulkResult } from '@/utils/calculations';
import { CellularDetailsModal } from '@/components/nodes/cellular-details-modal';
import { EnrolledStateCell } from '@/components/nodes/enrolled-state-cell';
import { CellularStatus } from '@/components/nodes/cellular-status';
import { NODES_ROUTE } from '@/constants/routes';
import { NodeStatus, nodeStatusToQuery, useJson } from '@/utils/query';
import { NodeFilter, useNodeFilter } from '@/containers/filters/advanced-filters/node-filter';
import { IQueryNodeField } from '../../../types/query/types';
import { NodeTagsModal } from './node-tags-modal';
import { FreeTextSearch, useFreeTextSearch, useNodeTagsFieldName } from '@/components/free-text-search';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useCellhealthSettings } from '@/api/services/cellhealth';
import { BulkOperations } from '@/components/nodes/bulk-operations';
import { useSortModel } from '@/hooks/sort-model';
import { TemplatePopover } from '@/components/nodes/node/template-status-popover';
import { useBulkUnenrollNodes } from '@/api/nodes/bulk/unenroll';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { BulkErrorMessage } from '@/components/nodes/bulk-error-message';
import { PendingDeleteDialog } from '@/components/nodes/pending-delete-dialog';
import { formatLastOnline, getLastOnlineValue } from '@/containers/nodes/node-table-components';
import { ChipPopover } from '@/components/chip-popover';
import { NewTagButton } from '@/components/new-tag-button';
import { useOpen } from '@/hooks/open';
import { renderAddress } from './node-status-connected-table';
import { RemoveCell } from '@/components/remove-cell';
// Table constants
var statusQuery = nodeStatusToQuery(NodeStatus.Enrolled);
var statusFieldNames = [
    IQueryNodeField.name,
    IQueryNodeField.id,
    IQueryNodeField.lhvpnAddress,
    IQueryNodeField.macAddress,
    IQueryNodeField.model,
    IQueryNodeField.serialNumber,
    IQueryNodeField.cellHealthStatus,
    IQueryNodeField.firmwareVersion,
];
export var NodesEnrolledTable = memo(function (_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var canViewCellHealth = permissions.canViewCellHealth, canViewNode = permissions.canViewNode, canEditNodeTags = permissions.canEditNodeTags, canViewNodeTags = permissions.canViewNodeTags, editableNodes = permissions.editableNodes, editableNodeFilters = permissions.editableNodeFilters, isPrimary = permissions.isPrimary, readableNodeTags = permissions.readableNodeTags;
    var assignTagModalOpen = useOpen();
    var bulkUnenrollNodes = useBulkUnenrollNodes();
    var alertNotification = useAlertNotification().alertNotification;
    var failureAlertNotification = useAlertNotification().alertNotification;
    // Remove row focus management
    var _b = __read(useState(undefined), 2), cellFocus = _b[0], setCellFocus = _b[1];
    // Get cell health settings to render cell column conditionally
    var cellHealthSettings = useCellhealthSettings().data;
    // manage cell details popup
    var _c = __read(useState(), 2), cellNodeDetails = _c[0], setCellNodeDetails = _c[1];
    // manage update node tags popup
    var _d = __read(useState(), 2), nodeToEditTags = _d[0], setNodeToEditTags = _d[1];
    var _e = __read(useState(new Set([])), 2), submittingNodeIds = _e[0], setSubmittingNodeIds = _e[1];
    var _f = __read(useState(), 2), nodeIdsToDelete = _f[0], setNodeIdsToDelete = _f[1];
    var _g = __read(useState([]), 2), rowSelectionModel = _g[0], setRowSelectionModel = _g[1];
    var refocusRef = useRef(null);
    // Table controls - Server side available query params
    var _h = __read(usePaginationModel('nodes_enrolled'), 3), paginationModel = _h[0], handlePaginationModelChange = _h[1], pageSizeOptions = _h[2];
    var _j = __read(useSortModel('last_updated'), 3), defaultSortModel = _j[0], sortQueryParams = _j[1], handleSortModelChange = _j[2];
    var showCellHealth = useMemo(function () { return canViewCellHealth && !!(cellHealthSettings === null || cellHealthSettings === void 0 ? void 0 : cellHealthSettings.cell_health_enabled); }, [canViewCellHealth, cellHealthSettings === null || cellHealthSettings === void 0 ? void 0 : cellHealthSettings.cell_health_enabled]);
    // Node filter
    var _k = useNodeFilter(), nodeFilter = _k.nodeFilter, setNodeFilter = _k.setNodeFilter;
    // adding tag fields to fieldnames if user has access to view tags
    var _l = useNodeTagsFieldName(readableNodeTags, statusFieldNames), freeTextDisabled = _l.freeTextDisabled, newFieldNames = _l.newFieldNames;
    // Free text search
    var _m = useFreeTextSearch(newFieldNames), textQueryObject = _m.textQueryObject, dispatchTextQuery = _m.dispatchTextQuery;
    // TODO - CDM-183 - extend logic
    // Aggregate all query items into one json query string
    var json = useJson(statusQuery, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject, textQueryObject);
    // Query options
    var queryParams = useMemo(function () { return (__assign(__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize, json: json }, sortQueryParams), { get_last_job: true })); }, [paginationModel, json, sortQueryParams]);
    // Get Nodes Fetch
    var _o = useNodes(queryParams), data = _o.data, isLoading = _o.isLoading;
    // Derived table values
    var _p = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.nodes) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.nodes, isLoading]), loading = _p.loading, rowCount = _p.rowCount, rows = _p.rows;
    // cell details modal handler
    var handleCellModalClose = useCallback(function () {
        setCellNodeDetails(undefined);
    }, []);
    // tags update modal handler
    var handleTagsModalClose = useCallback(function () {
        setNodeToEditTags(undefined);
    }, []);
    var handleDeleteDialogClose = useCallback(function () {
        setNodeIdsToDelete(undefined);
    }, []);
    // bulk unenroll nodes handler
    var handleBulkUnenroll = useCallback(function (nodeIds) { return __awaiter(void 0, void 0, void 0, function () {
        var nodeIdsToUnenroll, unenrollResult, _a, successIds, errorDetails;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    nodeIdsToUnenroll = nodeIds.filter(function (id) { return !submittingNodeIds.has(id); });
                    setSubmittingNodeIds(function (ids) {
                        nodeIdsToUnenroll.forEach(function (nodeId) {
                            ids.add(nodeId);
                        });
                        return new Set(__spreadArray([], __read(ids), false));
                    });
                    return [4 /*yield*/, bulkUnenrollNodes(nodeIdsToUnenroll)];
                case 1:
                    unenrollResult = _b.sent();
                    handleDeleteDialogClose();
                    if (!unenrollResult)
                        return [2 /*return*/];
                    _a = processBulkResult(unenrollResult), successIds = _a.successIds, errorDetails = _a.errorDetails;
                    // Show success notification to user
                    if (successIds.length) {
                        setRowSelectionModel(function (ids) { return ids.filter(function (id) { return !successIds.includes(id); }); });
                        alertNotification(format(t('api.nodes_bulk_unenroll.post.ok.message'), formatMessageWithList(successIds)), {
                            header: t('api.nodes_bulk_unenroll.post.ok.header'),
                            priority: OGToastPriorities.Success,
                        });
                    }
                    // Show notification to user that unenroll request for some nodes was failed
                    if (errorDetails.length) {
                        failureAlertNotification(_jsx(BulkErrorMessage, { message: format(t('api.nodes_bulk_unenroll.post.failed.message'), formatMessageWithList(errorDetails.map(function (errorItem) { return errorItem.id; }))), errorDetails: errorDetails }), {
                            header: t('api.nodes_bulk_unenroll.post.failed.header'),
                            persist: true,
                        });
                    }
                    setSubmittingNodeIds(function (ids) {
                        nodeIdsToUnenroll.forEach(function (nodeId) {
                            ids.delete(nodeId);
                        });
                        return new Set(__spreadArray([], __read(ids), false));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, bulkUnenrollNodes, handleDeleteDialogClose, failureAlertNotification, submittingNodeIds, t]);
    // true is single node can be unenrolled
    var canPendingUnenroll = useCallback(function (node) {
        if (node === undefined)
            return false;
        return node.runtime_status.action_type !== 'unenrollment' && !submittingNodeIds.has(node.id);
    }, [submittingNodeIds]);
    var renderConfiguredPorts = useCallback(function (_a) {
        var value = _a.value, tabIndex = _a.tabIndex;
        var currentValue = Math.round((value.configurePortsNumber * 100) / value.total);
        return (_jsxs("span", __assign({ style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' } }, { children: [_jsx(LinearProgress, { "aria-label": format(t('pages.nodes.table.label.configured_ports'), value.configurePortsNumber, value.total), sx: { width: '46px', marginRight: 1 }, tabIndex: tabIndex, value: currentValue, color: "success" }), _jsx("span", { children: "".concat(value.configurePortsNumber, "/").concat(value.total) })] })));
    }, [t]);
    var renderNodeName = useCallback(function (params) {
        return params.row.runtime_status.connection_status === 'connected' && canViewNode ? (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, variant: "standard", to: "".concat(NODES_ROUTE, "/").concat(params.id) }, { children: params.value }))) : (params.value);
    }, [canViewNode]);
    // Node action
    var renderNodeActionCell = useCallback(function (_a) {
        var node = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        var unenrollDisabled = !editableNodes || !canPendingUnenroll(node);
        var webAccessDisabled = !canViewNode || node.product !== 'opengear';
        return node.runtime_status.connection_status !== 'connected' ? (_jsx(RemoveCell, { index: api.getRowIndexRelativeToVisibleRows(node.id), currentCount: rows.length, tabIndex: tabIndex, onRemove: function (nextFocusIndex) {
                // Set focus to next row
                if (typeof nextFocusIndex !== 'undefined') {
                    var nextFocusRowId = rows[nextFocusIndex] ? rows[nextFocusIndex].id : undefined;
                    setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'node_action' } : undefined);
                }
                setNodeIdsToDelete([node.id]);
            }, disabled: unenrollDisabled, translation: {
                label: format(t('pages.nodes.table.buttons.unenroll_label'), node.id),
                tooltip: unenrollDisabled ? t('pages.nodes.table.buttons.unenroll_tooltip_disabled') : t('pages.nodes.table.buttons.unenroll_tooltip'),
            } })) : (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: webAccessDisabled ? t('pages.nodes.table.buttons.web_access_tooltip_disabled') : t('pages.nodes.table.buttons.web_access_tooltip') }, { children: _jsx("div", { children: _jsx(OGLinkButton, { "aria-label": format(t('pages.nodes.table.buttons.web_access'), node.id), external: true, disabled: webAccessDisabled, startIcon: _jsx(WebUIIcon, {}), sx: { minHeight: 20, minWidth: 20, paddingX: 0.5, paddingY: 0 }, tabIndex: tabIndex, target: "_self", to: "/".concat(node.id, "/") }) }) })));
    }, [canPendingUnenroll, canViewNode, editableNodes, t, rows]);
    var assignTagsToRowClickHandler = useCallback(function (row) {
        setNodeToEditTags(row.id);
        assignTagModalOpen.handleOpen();
    }, [assignTagModalOpen]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'connection_status',
            headerName: t('pages.nodes.table.headers.state'),
            maxWidth: 1,
            minWidth: 85,
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(EnrolledStateCell, { runtime_status: row.runtime_status });
            },
        },
        { field: 'id', headerName: t('pages.nodes.table.headers.id'), minWidth: 110 },
        { field: 'name', headerName: t('pages.nodes.table.headers.name'), minWidth: 160, renderCell: renderNodeName },
        {
            field: 'cellular_health',
            headerName: t('pages.nodes.table.headers.cell'),
            minWidth: 160,
            flex: 1,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(CellularStatus, { row: row, tabIndex: tabIndex, setOpen: setCellNodeDetails }));
            },
        },
        {
            field: 'config_push_status',
            headerName: t('pages.nodes.table.headers.template'),
            sortable: false,
            renderCell: TemplatePopover,
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'configured_ports',
            headerName: t('pages.nodes.table.headers.configured_ports'),
            sortable: false,
            minWidth: 140,
            valueGetter: function (_a) {
                var node = _a.row;
                return getConfiguredPorts(node.ports);
            },
            renderCell: renderConfiguredPorts,
        },
        {
            field: 'model',
            headerName: t('pages.nodes.table.headers.model'),
            valueFormatter: function (_a) {
                var value = _a.value;
                return value !== null && value !== void 0 ? value : 'N/A';
            },
            flex: 1,
            minWidth: 120,
        },
        { field: 'firmware_version', headerName: t('pages.nodes.table.headers.firmware_version'), maxWidth: 1, minWidth: 100 },
        { field: 'lhvpn_address', headerName: t('pages.nodes.table.headers.lhvpn_address'), sortable: false, minWidth: 120, flex: 1 },
        {
            field: 'address',
            headerName: t('pages.dashboard.nodes.table.headers.address'),
            sortable: false,
            minWidth: 200,
            resizable: true,
            renderCell: renderAddress,
        },
        {
            field: 'tags',
            headerName: t('pages.nodes.table.headers.tags'),
            minWidth: 220,
            sortable: false,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                var newTagButton = canEditNodeTags && isPrimary ? (_jsx(NewTagButton, { tabIndex: tabIndex, onClick: function () { return assignTagsToRowClickHandler(row); }, toolTipLabel: format(t('pages.nodes.table.buttons.edit_node_tags_label'), row.id) })) : null;
                if (row.tag_list.tags && row.tag_list.tags.length > 0) {
                    return (_jsx(ChipPopover, __assign({ tags: row.tag_list.tags.map(function (fullTag) { return fullTag; }), tabIndex: tabIndex }, { children: newTagButton })));
                }
                return (_jsx(Stack, __assign({ component: "div", tabIndex: tabIndex, "aria-label": t('pages.node.ports.table.no_tag_message') }, { children: newTagButton })));
            },
        },
        {
            field: 'serial_number',
            headerName: t('pages.nodes.table.headers.serial'),
            sortable: false,
            valueFormatter: function (_a) {
                var value = _a.value;
                return value !== null && value !== void 0 ? value : 'N/A';
            },
            flex: 1,
            minWidth: 135,
        },
        {
            field: 'last_updated',
            headerName: t('pages.nodes.table.headers.last_updated'),
            sortable: isPrimary,
            valueGetter: getLastOnlineValue,
            valueFormatter: formatLastOnline,
            flex: 1,
            minWidth: 210,
        },
        {
            field: 'node_action',
            headerName: '',
            headerClassName: 'FocusDisabled',
            maxWidth: 1,
            align: 'center',
            sortable: false,
            renderCell: renderNodeActionCell,
        },
    ]; }, [assignTagsToRowClickHandler, canEditNodeTags, isPrimary, renderConfiguredPorts, renderNodeActionCell, renderNodeName, t]);
    return (_jsxs(_Fragment, { children: [cellNodeDetails && _jsx(CellularDetailsModal, { nodeDetails: cellNodeDetails, open: !!cellNodeDetails, onClose: handleCellModalClose }), nodeToEditTags && _jsx(NodeTagsModal, { open: !!nodeToEditTags, onClose: handleTagsModalClose, nodeId: nodeToEditTags }), nodeIdsToDelete && (_jsx(PendingDeleteDialog, { open: !!nodeIdsToDelete, handleClose: handleDeleteDialogClose, handleUnenroll: handleBulkUnenroll, nodeIds: nodeIdsToDelete })), _jsxs(Box, __assign({ margin: -1 }, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 1, margin: 1, marginBottom: 0.5, justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(NodeFilter, { filter: nodeFilter, setFilter: setNodeFilter, permissions: { hasSmartgroupsEditAccess: editableNodeFilters } }), _jsx(FreeTextSearch, { ref: refocusRef, onSearch: dispatchTextQuery, freeTextDisabled: freeTextDisabled, useDebounce: false, translations: { tooltip: t('components.nodes.text_search.tooltip') } })] })), editableNodes && (_jsx(BulkOperations, { nodeIds: rowSelectionModel, totalNodes: rows.length, setRowSelectionModel: setRowSelectionModel, onClickUnenroll: setNodeIdsToDelete, refocusRef: refocusRef })), _jsx(Box, __assign({ margin: 1 }, { children: _jsx(OGTable, { id: "pending-nodes-table", "aria-label": t('pages.nodes.table.label.enrolled'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.nodes.table.no_row_message.enrolled'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, checkboxSelection: editableNodes, onRowSelectionModelChange: setRowSelectionModel, rowSelectionModel: rowSelectionModel, columnVisibilityModel: {
                                cellular_health: showCellHealth,
                                tags: canViewNodeTags,
                            }, initialState: {
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                            }, setCellFocus: cellFocus }) }))] }))] }));
});
