var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import format from 'string-format';
import difference from 'lodash/difference';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DialogContentText from '@mui/material/DialogContentText';
import { WarningBanner } from '@/components/warning-banner';
import { useUpdateUser, useDeleteUser } from '@/api/users';
import { useAlertNotification, OGToastPriorities } from '@/hooks/alert-notification';
import { PasswordConfirmationModal } from './modals';
import { ConfirmationModal } from '@/components/confirmation-modal';
function deleteUserHelper(userToUpdate, deleteUserCallback) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteUserCallback(userToUpdate.id)];
                case 1:
                    result = _a.sent();
                    if (result) {
                        return [2 /*return*/, userToUpdate];
                    }
                    return [2 /*return*/, null];
            }
        });
    });
}
export function UserActionDialog(_a) {
    var _this = this;
    var currentUser = _a.currentUser, userIds = _a.userIds, users = _a.users, userAction = _a.userAction, authMode = _a.authMode, handleClose = _a.handleClose, setRowSelectionModel = _a.setRowSelectionModel;
    var t = useTranslation().t;
    var updateUser = useUpdateUser();
    var deleteUser = useDeleteUser();
    var alertNotification = useAlertNotification().alertNotification;
    var actionMessage = t('pages.local_users.confirm_modal.title')[userAction];
    var confirmMessage = t("pages.local_users.confirm_modal.message")[userAction];
    var usersToUpdate = users.filter(function (user) { return userIds.includes(user.id); });
    var delDisExpActions = userAction === 'delete' || userAction === 'disable' || userAction === 'expire';
    var handleConfirm = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var updatePromises, results, successResults, header, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatePromises = [];
                    usersToUpdate.forEach(function (user) {
                        if (user && userAction === 'delete') {
                            updatePromises.push(deleteUserHelper(user, deleteUser));
                        }
                        if (user && userAction === 'disable') {
                            updatePromises.push(updateUser(__assign(__assign({}, user), { enabled: false })));
                        }
                        if (user && userAction === 'enable') {
                            updatePromises.push(updateUser(__assign(__assign({}, user), { enabled: true })));
                        }
                        if (user && userAction === 'expire') {
                            updatePromises.push(updateUser(__assign(__assign({}, user), { expired: true })));
                        }
                        if (user && userAction === 'restore') {
                            updatePromises.push(updateUser(__assign(__assign({}, user), { expired: false })));
                        }
                        if (user && userAction === 'unlock') {
                            updatePromises.push(updateUser(__assign(__assign({}, user), { locked_out: false })));
                        }
                    });
                    return [4 /*yield*/, Promise.all(updatePromises)];
                case 1:
                    results = _a.sent();
                    successResults = results.filter(function (result) { return result !== undefined; });
                    if (successResults.length) {
                        header = userAction === 'delete' ? t('api.user.delete.ok.header') : t('api.user.put.ok.header');
                        message = userAction === 'delete' ? t('api.user.delete.ok.message') : t('api.user.put.ok.message');
                        alertNotification(format(message, results.length > 1 ? 's' : '', successResults.map(function (successResult) { return successResult === null || successResult === void 0 ? void 0 : successResult.username; }).join(', ')), {
                            header: format(header, results.length > 1 ? 's' : ''),
                            priority: OGToastPriorities.Success,
                            persistOnUnmount: true,
                        });
                    }
                    if (successResults.length) {
                        setRowSelectionModel(difference(userIds, __spreadArray([], __read(successResults.map(function (successResult) { return successResult === null || successResult === void 0 ? void 0 : successResult.id; })), false)));
                    }
                    handleClose();
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, deleteUser, handleClose, setRowSelectionModel, t, updateUser, userAction, userIds, usersToUpdate]);
    var innerContent = (_jsxs(_Fragment, { children: [userIds.includes('users-1') && delDisExpActions ? (_jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.local_users.confirm_modal.warning_header'), warningMessage: _jsx(Typography, { children: t('pages.local_users.confirm_modal.warning_message') }), warningColor: "warning", bannerCode: "local-users-confirmation-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 3, padding: 1, maxWidth: '640px' } })) : null, _jsxs(DialogContentText, __assign({ sx: { marginBottom: 1 } }, { children: [format(confirmMessage, usersToUpdate.length > 1 ? 's' : '', usersToUpdate.map(function (user) { return user.username; }).join(', ')), ' '] }))] }));
    if (authMode === 'local' && delDisExpActions) {
        return (_jsxs(PasswordConfirmationModal, __assign({ open: !!userIds.length, title: actionMessage, onSuccess: handleConfirm, handleClose: handleClose, username: currentUser }, { children: [innerContent, _jsx(DialogContentText, { children: t('components.user.password_modal.password_prompt') })] })));
    }
    return (_jsx(ConfirmationModal, __assign({ title: actionMessage, confirmLabel: t('global.buttons.confirm'), onClose: handleClose, onConfirm: handleConfirm, open: !!userIds.length }, { children: innerContent })));
}
