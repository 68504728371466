export var transformRequestToFormState = function (selectedRows, resourceTagsNames) {
    // Flatten all tags across all rows, including the row's ID with each tag.
    var allTagsWithNodes = selectedRows.flatMap(function (row) {
        return row.assignedTags.map(function (tag) { return ({
            id: tag.id,
            resource_tag_name: tag.resource_tag_name,
            assignedTo: [row.id], // Initially assign this tag to the current row's ID.
        }); });
    });
    // Use reduce to accumulate a unique list of tags, merging their 'assignedTo' arrays when necessary.
    var uniqueTags = allTagsWithNodes.reduce(function (acc, currentTag) {
        var existingTagIndex = acc.findIndex(function (tag) { return tag.id === currentTag.id; });
        if (existingTagIndex !== -1) {
            // If the tag already exists, append the current node ID to its 'assignedTo' list if not already present.
            if (!acc[existingTagIndex].assignedTo.includes(currentTag.assignedTo[0])) {
                acc[existingTagIndex].assignedTo.push(currentTag.assignedTo[0]);
            }
        }
        else {
            // If the tag does not exist in the accumulator, add it directly.
            acc.push(currentTag);
        }
        return acc;
    }, []);
    // Filter out any resource tags that are not assigned to any node
    var unassignedTags = resourceTagsNames
        .filter(function (tag) { return !uniqueTags.find(function (uniqueTag) { return uniqueTag.resource_tag_name === tag.resource_tag_name; }); })
        .map(function (tag) { return ({
        id: tag.id,
        resource_tag_name: tag.resource_tag_name,
        linkedAlready: false,
    }); });
    // The newSelectedTags field remains an empty array in this transformation.
    var newSelectedOrCreatedTagsFields = [];
    return {
        assignedTags: uniqueTags,
        unassignedTags: unassignedTags,
        newSelectedOrCreatedTagsFields: newSelectedOrCreatedTagsFields,
    };
};
export var getModifiedTags = function (formData) { return ({
    createdTags: formData.newSelectedOrCreatedTagsFields.filter(function (tag) { return tag.type === 'create'; }),
    assignedTags: formData.newSelectedOrCreatedTagsFields.filter(function (tag) { return tag.type === 'existing'; }),
    unassignedTags: formData.unassignedTags.filter(function (tag) { return tag.linkedAlready === true; }),
}); };
export var transformFormStateToRequest = function (formstate, selectedRows) {
    var modifiedTags = getModifiedTags(formstate);
    var newTags = modifiedTags.createdTags.map(function (tag) { return tag.newResourceTagName; });
    var tagsIdsLink = modifiedTags.assignedTags.map(function (tag) { return tag.resourceTagId; });
    var tagsIdsUnlink = modifiedTags.unassignedTags.map(function (tag) { return tag.id; });
    var resourceIds = selectedRows.flatMap(function (row) { return row.id; });
    return {
        resourceTags: {
            actions: {
                create: newTags,
                link: tagsIdsLink,
                unlink: tagsIdsUnlink,
            },
            selected_ports: resourceIds,
        },
    };
};
