import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
export function EnrolledStateCell(_a) {
    var runtime_status = _a.runtime_status;
    var t = useTranslation().t;
    if (runtime_status.action_type === 'unenrollment' && runtime_status.action_status !== 'error') {
        return _jsx(OGAutoRenewIcon, { label: t('components.nodes.state_label.unenrollment'), sx: { marginLeft: 1 } });
    }
    return runtime_status.connection_status === 'connected' ? (_jsx(SuccessIcon, { fontSize: "medium", color: "success", sx: { height: 21, marginLeft: 1 }, "aria-hidden": "false", titleAccess: t('components.nodes.state_label.connected') })) : (_jsx(ErrorIcon, { fontSize: "medium", color: "error", sx: { height: 21, marginLeft: 1 }, "aria-hidden": "false", titleAccess: t('components.nodes.state_label.disconnected') }));
}
