var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGUploadIcon(props) {
    return (_jsx(SvgIcon, __assign({}, props, { children: _jsx("path", { d: "M18.9678 10.5914C19.0847 10.2807 19.15 9.94206 19.15 9.58594C19.15 8.04282 17.9194 6.79297 16.4 6.79297C15.8328 6.79297 15.3103 6.96753 14.8738 7.26428C14.7466 7.35156 14.5919 7.37949 14.4441 7.34458C14.2963 7.30967 14.1691 7.21191 14.0934 7.07925C13.2341 5.57104 11.6322 4.55859 9.8 4.55859C7.06719 4.55859 4.85 6.81043 4.85 9.58594C4.85 9.6732 4.85344 9.75699 4.85688 9.84082C4.87063 10.0887 4.71938 10.3121 4.48906 10.3959C2.77719 11.0069 1.55 12.6652 1.55 14.6133C1.55 17.0816 3.51969 19.0821 5.95 19.0821H18.6C20.7278 19.0821 22.45 17.3329 22.45 15.1719C22.45 13.2796 21.1266 11.7016 19.37 11.3385C19.2119 11.3071 19.0744 11.2024 18.9953 11.0593C18.9163 10.9161 18.9059 10.7415 18.9644 10.5914H18.9678ZM9.8 4C11.6425 4 13.2719 4.91819 14.2688 6.32864C14.3753 6.47876 14.475 6.63587 14.5678 6.79646C14.6881 6.71267 14.8153 6.63935 14.9459 6.57302C15.3859 6.35657 15.8775 6.23438 16.4 6.23438C18.2219 6.23438 19.7 7.7356 19.7 9.58594C19.7 9.81634 19.6759 10.0398 19.6347 10.2562C19.5969 10.4413 19.5453 10.6228 19.48 10.7939C19.6622 10.8323 19.8409 10.8812 20.0128 10.9405C21.7487 11.5375 23 13.2063 23 15.1719C23 17.6402 21.0303 19.6406 18.6 19.6406H5.95C3.21719 19.6406 1 17.3888 1 14.6133C1 12.4208 2.38188 10.5565 4.30688 9.86873C4.30344 9.77445 4.3 9.68022 4.3 9.58594C4.3 6.49971 6.76125 4 9.8 4ZM8.77906 12.4592L11.8041 9.38693C11.9106 9.27872 12.0859 9.27872 12.1925 9.38693L15.2175 12.4592C15.3241 12.5674 15.3241 12.7455 15.2175 12.8537C15.1109 12.9619 14.9356 12.9619 14.8291 12.8537L12.275 10.2597V17.1269C12.275 17.2806 12.1513 17.4063 12 17.4063C11.8488 17.4063 11.725 17.2806 11.725 17.1269V10.2597L9.17094 12.8537C9.06437 12.9619 8.88906 12.9619 8.7825 12.8537C8.67594 12.7455 8.67594 12.5674 8.7825 12.4592H8.77906Z", fill: "currentColor" }) })));
}
