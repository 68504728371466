var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useTranslation } from 'react-i18next';
export function LoadingErrorOverlay(_a) {
    var errorText = _a.errorText, sx = _a.sx;
    var t = useTranslation().t;
    return (_jsxs(Box, __assign({ sx: __assign({ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }, sx) }, { children: [_jsx(WarningAmberOutlinedIcon, { "aria-label": t('components.loading_error.icon_label'), color: "warning", fontSize: "medium", sx: { marginRight: 1 } }), _jsx(Typography, { children: errorText || t('components.loading_error.error') })] })));
}
