var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { OGFileTracker } from '@opengear/file-uploader';
export function FileManagerTracker(_a) {
    var data = _a.data, onCancel = _a.onCancel;
    var t = useTranslation().t;
    var _b = __read(useState(''), 2), statusMessage = _b[0], setStatusMessage = _b[1];
    var handleCancel = data.status === 'pending' ? onCancel : undefined;
    useEffect(function () {
        if (data.status === 'pending') {
            setStatusMessage(t('components.file_uploader.tracker.in_progress'));
        }
        if (data.status === 'success') {
            setStatusMessage(t('components.file_manager.progress.success'));
        }
        if (data.status === 'error' && data.errorMessage) {
            setStatusMessage(data.errorMessage);
        }
    }, [data.errorMessage, data.status, t]);
    var message = useMemo(function () {
        if (data.status === 'pending') {
            return (_jsx(Typography, __assign({ "aria-live": "assertive", "aria-atomic": "true" }, { children: statusMessage })));
        }
        if (data.status === 'success') {
            return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 0.75 }, { children: [_jsx(CheckOutlinedIcon, { color: "success", fontSize: "medium" }), _jsx(Typography, __assign({ "aria-live": "assertive", "aria-atomic": "true" }, { children: statusMessage }))] })));
        }
        if (data.status === 'error' && data.errorMessage) {
            return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 0.75, role: "alert" }, { children: [_jsx(ErrorOutlineIcon, { color: "warning", fontSize: "medium" }), _jsx(Typography, __assign({ "aria-live": "assertive", "aria-atomic": "true" }, { children: statusMessage }))] })));
        }
        return null;
    }, [data.errorMessage, data.status, statusMessage]);
    var progress = data.status !== 'error' ? (data.loaded / data.total) * 100 : undefined;
    return _jsx(OGFileTracker, { id: data.filename, name: data.filename, message: message, progress: progress, onCancel: handleCancel });
}
