var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { useNode } from '@/api/nodes';
import { useResourceTagsNames } from '@/api/tags/resource-tags/names';
import { useAlertNotification } from '@/hooks/alert-notification';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { NODE_PORTS_ROUTE, NODE_LOGS_ROUTE, NOT_FOUND_ROUTE, formatNodeDetailsRoute } from '@/constants/routes';
import { NodePorts } from '@/containers/nodes/node/node-ports';
import { NodeLogs } from '@/containers/nodes/node/node-logs';
import { NodeDetails } from '@/containers/nodes/node/node-details';
import { NodeTopBar } from '@/components/nodes/node/node-top-bar';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var NODE_LOADING_TEST_ID = 'node-loading';
var TabValue;
(function (TabValue) {
    TabValue["PORTS"] = "PORTS";
    TabValue["LOGS"] = "LOGS";
})(TabValue || (TabValue = {}));
function Node() {
    var nodeId = useParams().nodeId;
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    useDocTitle(format(t('pages.node.title'), nodeId !== null && nodeId !== void 0 ? nodeId : ''));
    var _a = useLocation(), pathname = _a.pathname, search = _a.search;
    var navigate = useNavigate();
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404) {
            alertNotification(format(t('api.node.get.not_found.message'), nodeId !== null && nodeId !== void 0 ? nodeId : ''), { persistOnUnmount: true });
        }
        if (responseStatus === 403) {
            alertNotification(format(t('api.node.get.forbidden.message'), nodeId !== null && nodeId !== void 0 ? nodeId : ''), { persistOnUnmount: true });
        }
        navigate(NOT_FOUND_ROUTE);
    }, [alertNotification, navigate, nodeId, t]);
    var _b = useNode(nodeId, {
        notFoundCallback: notFoundCallback,
    }), nodeData = _b.data, isNodeDataLoading = _b.isLoading;
    var _c = useResourceTagsNames({ order_by: 'name' }), tagNamesData = _c.data, isTagNamesLoading = _c.isLoading;
    var innerNode = null;
    if (isNodeDataLoading || isTagNamesLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": NODE_LOADING_TEST_ID });
    }
    var tabValue = useMemo(function () { return (pathname.endsWith(NODE_LOGS_ROUTE) ? TabValue.LOGS : TabValue.PORTS); }, [pathname]);
    useEffect(function () {
        if (pathname.endsWith(formatNodeDetailsRoute(nodeId))) {
            navigate("".concat(formatNodeDetailsRoute(nodeId)).concat(NODE_PORTS_ROUTE), { replace: true });
        }
    }, [pathname, navigate, nodeId]);
    if (nodeData && tagNamesData) {
        var node = nodeData.node;
        var resourceTags = tagNamesData.resourceTags;
        innerNode = (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(NodeTopBar, { node: node }), _jsx(Box, __assign({ sx: { width: '100%', display: 'flex' } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, desktop: 9, laptop: 12, tablet: 12, mobile: 12 }, { children: _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ id: "node-tab-list" }, { children: [_jsx(Tab, { component: RouterLink, to: NODE_PORTS_ROUTE.slice(1), "aria-label": t('pages.node.tabs.ports.label'), label: _jsx(Typography, __assign({ component: "span" }, { children: t('pages.node.tabs.ports.title') })), value: TabValue.PORTS }), _jsx(Tab, { component: RouterLink, disabled: !node.port_logging_enabled, to: search ? "".concat(NODE_LOGS_ROUTE.slice(1)).concat(search) : NODE_LOGS_ROUTE.slice(1), "aria-label": t('pages.node.tabs.logs.label'), label: _jsx(Typography, __assign({ component: "span" }, { children: t('pages.node.tabs.logs.title') })), value: TabValue.LOGS })] })), _jsx(TabPanel, __assign({ value: "PORTS" }, { children: _jsx(NodePorts, { node: node, resourceTags: resourceTags }) })), _jsx(TabPanel, __assign({ value: "LOGS" }, { children: _jsx(NodeLogs, { node: node }) }))] })) }) })), _jsx(Grid, __assign({ item: true, desktop: 3, laptop: 12, tablet: 12, mobile: 12 }, { children: _jsx(NodeDetails, { node: node, nodeId: nodeId }) }))] })) }))] })));
    }
    return _jsx(Box, { children: innerNode });
}
export default withAuthenticationRequired(Node, { rights: ['monitor.nodes.view'] });
