export var QUERY_PARAM_CONFIGURED_PORT_FILTER_KEY = 'port-status-filter';
export var QUERY_PARAM_LOGS_PORT_ID_KEY = 'port-id';
export var QUERY_PARAM_LOGS_FROM_DATE_KEY = 'from-date';
export var QUERY_PARAM_LOGS_TO_DATE_KEY = 'to-date';
export var QUERY_PARAM_LOGS_CONTAINS_KEY = 'contains';
export var QUERY_PARAM_MODAL_NAME_KEY = 'modal';
export var QUERY_PARAM_NODE_FILTER_KEY = 'node-filter';
export var QUERY_PARAM_PORT_FILTER_KEY = 'port-filter';
export var QUERY_PARAM_RESOURCE_FILTER_KEY = 'resource-filter';
export var QUERY_PARAM_CREATE_FILTER_MODAL = {
    nodeFilter: "new-".concat(QUERY_PARAM_NODE_FILTER_KEY),
    portFilter: "new-".concat(QUERY_PARAM_PORT_FILTER_KEY),
    resourceFilter: "new-".concat(QUERY_PARAM_RESOURCE_FILTER_KEY),
};
