import { screen } from '@testing-library/react';
import { t } from 'i18next';
export var formTestUtils = {
    cancelButton: {
        get: function () { return screen.getByRole('button', { name: t('global.buttons.cancel') }); },
    },
    createButton: {
        get: function () { return screen.getByRole('button', { name: formTestUtils.translations.submit.create }); },
    },
    deleteButton: {
        get: function () { return screen.getByRole('button', { name: t('global.buttons.delete') }); },
    },
    form: {
        get: function (label) { return screen.getByRole('form', { name: label }); },
    },
    translations: t('components.tags.form'),
    updateButton: {
        get: function () { return screen.getByRole('button', { name: formTestUtils.translations.submit.update }); },
    },
};
