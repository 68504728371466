var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import TextField from '@mui/material/TextField';
import { OGTable } from '@opengear/table';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormContainerContext } from '@/components/layout/forms';
import { USER_NAME_PATTERN, USER_DESCRIPTION_PATTERN } from '@/constants/regex';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Constants
export var UsernameFieldMaxLength = 32;
export var DescriptionFieldMaxLength = 128;
export var PasswordFieldMaxLength = 128;
export function UsernameField(_a) {
    var usernames = _a.usernames;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions;
    return (_jsx(Controller, { control: control, name: "username", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.username.label')),
            pattern: {
                value: USER_NAME_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.username.label')),
            },
            maxLength: {
                value: UsernameFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.username.label'), UsernameFieldMaxLength.toString()),
            },
            validate: function (value) { return !usernames.includes(value) || t('pages.config_templates.tabs.users_groups.sections.user_list.fields.username.duplicate'); },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField
            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            , __assign({ 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, id: "username", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.users_groups.sections.user_list.fields.username.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' }, required: true }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
            pattern: {
                value: USER_DESCRIPTION_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.description.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "user-description", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.users_groups.sections.user_list.fields.description.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' } }, field)));
        } }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, trigger = _a.trigger;
    return (_jsx(Controller, { control: control, name: "password", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.password.label')),
            maxLength: {
                value: PasswordFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.password.label'), PasswordFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "password", type: "password", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.users_groups.sections.user_list.fields.password.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' }, required: true }, field, { onBlur: function () {
                    field.onBlur();
                    trigger('confirmPassword');
                } })));
        } }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, watch = _a.watch;
    var password = watch('password');
    var validatePasswordsMatch = function (value) {
        return value === password ? true : t('pages.config_templates.tabs.users_groups.sections.user_list.fields.confirm_password.errors.match');
    };
    return (_jsx(Controller, { control: control, name: "confirmPassword", rules: {
            validate: { passwordValid: validatePasswordsMatch },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "confirmPassword", type: "password", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.users_groups.sections.user_list.fields.confirm_password.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: '100%' } }, field)));
        } }));
}
export function GroupSelectionField(_a) {
    var groups = _a.groups;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, trigger = _b.trigger, getValues = _b.getValues;
    var rows = groups;
    var rowCount = rows.length;
    var _c = __read(useState(function () {
        var selectedGroups = new Set(getValues().groups);
        return rows.reduce(function (model, _a) {
            var id = _a.id, groupname = _a.groupname;
            if (selectedGroups.has(groupname)) {
                model.push(id);
            }
            return model;
        }, []);
    }), 2), selectionModel = _c[0], setSelectionModel = _c[1];
    var _d = __read(useState(selectionModel.length), 2), selectedCount = _d[0], setSelectedCount = _d[1];
    var renderRoleCell = function (_a) {
        var value = _a.value;
        return (value === null || value === void 0 ? void 0 : value[0]) === 'administrator'
            ? t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.options.administrator')
            : t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.options.user');
    };
    var columns = [
        { field: 'groupname', headerName: 'Group Name', flex: 1 },
        { field: 'roles', headerName: 'Role', flex: 1, renderCell: renderRoleCell },
    ];
    var handleRowSelection = useCallback(function (newSelectionModel) {
        setSelectionModel(newSelectionModel);
        var selectedGroups = rows.filter(function (_a) {
            var id = _a.id;
            return newSelectionModel.includes(id);
        }).map(function (_a) {
            var groupname = _a.groupname;
            return groupname;
        });
        setSelectedCount(selectedGroups.length);
        return selectedGroups;
    }, [rows]);
    var selectedText = useMemo(function () {
        if (selectedCount === 0) {
            return t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.no_row_selected_text');
        }
        return format(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.selected_count_text'), String(selectedCount), String(rowCount), rowCount === 1
            ? t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.single')
            : t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.plural'));
    }, [rowCount, selectedCount, t]);
    return (_jsx(Controller, { control: control, name: "groups", rules: { required: requiredFieldError(t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.label')) }, render: function (_a) {
            var onChange = _a.field.onChange, error = _a.fieldState.error;
            return (_jsxs(Box, { children: [_jsx(InputLabel, __assign({ sx: { mb: 2 }, htmlFor: "usergroup_select", required: true }, { children: t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.label') })), _jsx(OGTable, { id: "usergroup_select", "aria-label": t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.label'), columns: columns, rows: rows, autoHeight: true, checkboxSelection: true, noRowsMessage: t('pages.config_templates.tabs.users_groups.sections.user_list.fields.groups.no_row_message'), rowSelectionModel: selectionModel, onRowSelectionModelChange: function (newSelectionModel) {
                            onChange(handleRowSelection(newSelectionModel));
                            trigger('groups');
                        } }), error && (_jsx(FormHelperText, __assign({ sx: { marginTop: 0, marginBottom: 2, display: 'flex', alignItems: 'center' }, error: true }, { children: error.message }))), _jsx(Box, __assign({ sx: { pt: 2 } }, { children: selectedText }))] }));
        } }));
}
