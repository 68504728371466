var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
export function ImmediateField() {
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "immediate", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsxs(FormControl, { children: [_jsxs(ToggleButtonGroup, __assign({ exclusive: true, sx: { marginBottom: 1 }, onChange: function (_, value) {
                            if (value === null)
                                return;
                            onChange(value);
                        } }, field, { children: [_jsx(ToggleButton, __assign({ value: true }, { children: t('pages.firmware_upgrade_new.fields.immediate.values.immediately') })), _jsx(ToggleButton, __assign({ value: false }, { children: t('pages.firmware_upgrade_new.fields.immediate.values.set_time') }))] })), _jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center", marginBottom: 1 }, { children: [_jsx(InfoIcon, { titleAccess: t('pages.firmware_upgrade_new.icons.info'), fontSize: "medium" }), _jsx(Typography, __assign({ maxWidth: 600, whiteSpace: "pre-line", component: "span" }, { children: t('pages.firmware_upgrade_new.fields.immediate.time_warning') }))] }))] }));
        } }));
}
