import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { LoginRestrictionsForm } from './login-restrictions-form';
import { useLoginRestrictions } from '@/api/users/login-restrictions';
export function LoginRestrictionsTab(props) {
    var editable = props.editable;
    var data = useLoginRestrictions().data;
    if (!data) {
        return null;
    }
    return _jsx(LoginRestrictionsForm, { data: data, editable: editable });
}
