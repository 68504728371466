var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useContext, useMemo } from 'react';
export var CopyRepeatDataContext = createContext(undefined);
// Consumer
export var useCopyRepeatData = function () {
    var context = useContext(CopyRepeatDataContext);
    if (!context) {
        throw Error('useCopyRepeatData must be wrapped in CopyRepeatDataProvider');
    }
    return context;
};
export function CopyRepeatDataProvider(_a) {
    var children = _a.children;
    var _b = __read(useState(), 2), copyRepeatData = _b[0], setCopyRepeatData = _b[1];
    var value = useMemo(function () { return ({
        copyRepeatData: copyRepeatData,
        setCopyRepeatData: setCopyRepeatData,
    }); }, [copyRepeatData]);
    return _jsx(CopyRepeatDataContext.Provider, __assign({ value: value }, { children: children }));
}
