var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useThemeMediaQuery } from '@/hooks/use-theme-media-query';
import { useDeviceView } from '@/hooks/use-device-view';
import { DATETIME_INPUT_FORMAT } from '@/constants/formats';
import { QUERY_PARAM_LOGS_PORT_ID_KEY, QUERY_PARAM_LOGS_FROM_DATE_KEY, QUERY_PARAM_LOGS_TO_DATE_KEY, QUERY_PARAM_LOGS_CONTAINS_KEY, } from '@/constants/query-params-keys';
export function PortLogsFilter(_a) {
    var portInfo = _a.portInfo;
    var t = useTranslation().t;
    var tabletMediaQuery = useThemeMediaQuery('tablet');
    var isLaptopView = useDeviceView().isLaptopView;
    var _b = __read(useSearchParams(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var queryParamPortId = searchParams.get(QUERY_PARAM_LOGS_PORT_ID_KEY);
    var queryParamFromDate = searchParams.get(QUERY_PARAM_LOGS_FROM_DATE_KEY);
    var queryParamToDate = searchParams.get(QUERY_PARAM_LOGS_TO_DATE_KEY);
    var queryParamContains = searchParams.get(QUERY_PARAM_LOGS_CONTAINS_KEY);
    var portIdValue = useMemo(function () { return (queryParamPortId && queryParamPortId in portInfo ? queryParamPortId : ''); }, [portInfo, queryParamPortId]);
    var _c = __read(useState(queryParamFromDate ? dayjs(queryParamFromDate) : null), 2), fromDateValue = _c[0], setFromDateValue = _c[1];
    var _d = __read(useState(queryParamToDate ? dayjs(queryParamToDate) : null), 2), toDateValue = _d[0], setToDateValue = _d[1];
    var _e = __read(useState(queryParamContains !== null && queryParamContains !== void 0 ? queryParamContains : ''), 2), contentsValue = _e[0], setContentsValue = _e[1];
    var handlePortIdChange = function (event) {
        var _a;
        searchParams.set(QUERY_PARAM_LOGS_PORT_ID_KEY, (_a = event.target.value) !== null && _a !== void 0 ? _a : undefined);
        setSearchParams(searchParams);
    };
    var handleFromDateChange = useCallback(function (value) {
        setFromDateValue(value);
        // if from_date is ahead to_date then 400 error
        // do not set param if:
        // - value is not valid
        // - to_date is already defined but new value is ahead than to_date
        if ((value === null || value === void 0 ? void 0 : value.isValid()) && (!toDateValue || value < toDateValue)) {
            searchParams.set(QUERY_PARAM_LOGS_FROM_DATE_KEY, value.format('YYYY-MM-DDTHH:mm:ss.000'));
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams, toDateValue]);
    var handleToDateChange = useCallback(function (value) {
        setToDateValue(value);
        // do not set param if:
        // - value is not valid
        // - from_date is already defined but new value is behind than from_date
        if ((value === null || value === void 0 ? void 0 : value.isValid()) && (!fromDateValue || value > fromDateValue)) {
            searchParams.set(QUERY_PARAM_LOGS_TO_DATE_KEY, value.format('YYYY-MM-DDTHH:mm:ss.000'));
            setSearchParams(searchParams);
        }
    }, [fromDateValue, searchParams, setSearchParams]);
    var handleFilterContentsChange = useCallback(function (value) {
        searchParams.set(QUERY_PARAM_LOGS_CONTAINS_KEY, value);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    var debouncedChangeHandler = useMemo(function () { return debounce(handleFilterContentsChange, 500); }, [handleFilterContentsChange]);
    var handleContentsChange = useCallback(function (event) {
        setContentsValue(event.target.value);
        debouncedChangeHandler(event.target.value);
    }, [debouncedChangeHandler]);
    // cleanup function
    useEffect(function () { return function () { return debouncedChangeHandler.cancel(); }; }, [debouncedChangeHandler]);
    var handleClear = useCallback(function () {
        setFromDateValue(null);
        setToDateValue(null);
        setContentsValue('');
        searchParams.delete(QUERY_PARAM_LOGS_PORT_ID_KEY);
        searchParams.delete(QUERY_PARAM_LOGS_FROM_DATE_KEY);
        searchParams.delete(QUERY_PARAM_LOGS_TO_DATE_KEY);
        searchParams.delete(QUERY_PARAM_LOGS_CONTAINS_KEY);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    return (_jsxs(Stack, __assign({ direction: "row", gap: 1, justifyContent: "flex-start" }, { children: [_jsxs(Stack, __assign({ direction: "row", flexWrap: "wrap", alignItems: "center", gap: 1 }, { children: [_jsx(FormControl, __assign({ margin: "none", sx: { width: '200px' } }, { children: _jsx(Select, __assign({ id: "port_id", name: "port_id", label: t('pages.node.logs.filter.fields.port_id.label'), placeholder: t('pages.node.logs.filter.fields.port_id.placeholder'), value: portIdValue, margin: "none", MenuProps: {
                                PaperProps: {
                                    sx: { maxHeight: isLaptopView ? '80vh' : '400px' },
                                },
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }, inputProps: { 'aria-label': t('pages.node.logs.filter.fields.port_id.label') }, displayEmpty: true, renderValue: function (value) {
                                return !value ? _jsx("em", { children: t('pages.node.logs.filter.fields.port_id.placeholder') }) : "".concat(portInfo[value].label, " (").concat(portInfo[value].csid, ")");
                            }, onChange: handlePortIdChange }, { children: Object.entries(portInfo).map(function (_a) {
                                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                                return (_jsx(MenuItem, __assign({ value: key }, { children: "".concat(value.label, " (").concat(value.csid, ")") }), key));
                            }) })) })), _jsx(FormControl, __assign({ margin: "none", sx: { width: '200px' } }, { children: _jsx(DateTimePicker, { ampm: false, format: DATETIME_INPUT_FORMAT, value: fromDateValue, maxDate: toDateValue !== null && toDateValue !== void 0 ? toDateValue : undefined, maxTime: (toDateValue === null || toDateValue === void 0 ? void 0 : toDateValue.isSame(fromDateValue, 'day')) ? toDateValue : undefined, onChange: handleFromDateChange, desktopModeMediaQuery: tabletMediaQuery, closeOnSelect: false, slots: { openPickerIcon: CalendarMonthIcon }, slotProps: {
                                openPickerIcon: {
                                    style: { fontSize: 16 },
                                },
                                openPickerButton: {
                                    'style': { padding: 7, marginRight: 0, borderRadius: 0 },
                                    'aria-label': t('pages.node.logs.filter.fields.start_date.label'),
                                },
                                textField: {
                                    inputProps: { 'aria-label': t('pages.node.logs.filter.fields.start_date.label') },
                                    margin: 'none',
                                    placeholder: DATETIME_INPUT_FORMAT,
                                    sx: { '& .MuiInputBase-input': { paddingRight: 0 } },
                                },
                            } }) })), _jsx("span", { children: "-" }), _jsx(FormControl, __assign({ margin: "none", sx: { width: '200px', display: 'flex' } }, { children: _jsx(DateTimePicker, { ampm: false, format: DATETIME_INPUT_FORMAT, value: toDateValue, minDate: fromDateValue !== null && fromDateValue !== void 0 ? fromDateValue : undefined, minTime: (fromDateValue === null || fromDateValue === void 0 ? void 0 : fromDateValue.isSame(toDateValue, 'day')) ? fromDateValue : undefined, onChange: handleToDateChange, desktopModeMediaQuery: tabletMediaQuery, closeOnSelect: false, slots: { openPickerIcon: CalendarMonthIcon }, slotProps: {
                                openPickerIcon: {
                                    style: { fontSize: 16 },
                                },
                                openPickerButton: {
                                    style: { padding: 7, marginRight: 0, borderRadius: 0 },
                                },
                                textField: {
                                    inputProps: { 'aria-label': t('pages.node.logs.filter.fields.end_date.label') },
                                    margin: 'none',
                                    placeholder: DATETIME_INPUT_FORMAT,
                                    sx: { '& .MuiInputBase-input': { paddingRight: 0 } },
                                },
                            } }) })), _jsx(OGIconTooltip, { title: t('pages.node.logs.filter.fields.tooltip_time_format') }), _jsx(FormControl, __assign({ margin: "none", sx: { width: '225px', display: 'flex' } }, { children: _jsxs(Box, __assign({ display: "flex", gap: 1, alignItems: "center" }, { children: [_jsx(TextField, { id: "contents", name: "contents", value: contentsValue, sx: { flexGrow: 1 }, placeholder: t('pages.node.logs.filter.fields.contents.placeholder'), margin: "none", inputProps: { 'aria-label': t('pages.node.logs.filter.fields.contents.label') }, onChange: handleContentsChange }), _jsx(OGIconTooltip, { title: t('pages.node.logs.filter.fields.contents.tooltip') })] })) }))] })), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.node.logs.filter.buttons.clear.label') }, { children: _jsx(OGButton, { "aria-label": t('pages.node.logs.filter.buttons.clear.label'), size: "small", sx: { '&.MuiButton-root': { height: '31px' } }, startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: handleClear }) }))] })));
}
