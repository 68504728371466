var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { maxLengthFieldError, minLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Field validation limits
export var UsernameMaxLength = 128;
export var AuthPasswordMinLength = 8;
export var AuthPasswordMaxLength = 128;
export var PrivacyPasswordMinLength = 8;
export var PrivacyPasswordMaxLength = 128;
export function EngineIDField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var EngineIdMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "engineId", rules: {
            maxLength: {
                value: EngineIdMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.engine_id.label'), EngineIdMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_service.form.engine_id.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_service.form.engine_id.tooltip') })] }) }, field)));
        } }));
}
export function SecurityLevelButtons() {
    var _a, _b;
    var t = useTranslation().t;
    var _c = useFormContainerContext(), control = _c.control, permissions = _c.permissions, clearErrors = _c.clearErrors, setValue = _c.setValue, formState = _c.formState;
    var isMobileView = useDeviceView().isMobileView;
    var securityOptions = t('pages.alerts_and_monitoring.tabs.snmp_service.form.security_level.options');
    var handleChange = useCallback(function (value) {
        var _a, _b, _c, _d, _e, _f;
        if (value === 'noAuthNoPriv') {
            clearErrors(['authPassword', 'confirmAuthPassword', 'privacyPassword', 'confirmPrivacyPassword']);
            setValue('authProtocol', (_b = (_a = formState.defaultValues) === null || _a === void 0 ? void 0 : _a.authProtocol) !== null && _b !== void 0 ? _b : 'MD5');
            setValue('privacyProtocol', (_d = (_c = formState.defaultValues) === null || _c === void 0 ? void 0 : _c.privacyProtocol) !== null && _d !== void 0 ? _d : 'DES');
            setValue('authPassword', '');
            setValue('confirmAuthPassword', '');
            setValue('privacyPassword', '');
            setValue('confirmPrivacyPassword', '');
        }
        if (value === 'authNoPriv') {
            clearErrors(['privacyPassword', 'confirmPrivacyPassword']);
            setValue('privacyProtocol', (_f = (_e = formState.defaultValues) === null || _e === void 0 ? void 0 : _e.privacyProtocol) !== null && _f !== void 0 ? _f : 'DES');
            setValue('privacyPassword', '');
            setValue('confirmPrivacyPassword', '');
        }
    }, [clearErrors, (_a = formState.defaultValues) === null || _a === void 0 ? void 0 : _a.authProtocol, (_b = formState.defaultValues) === null || _b === void 0 ? void 0 : _b.privacyProtocol, setValue]);
    return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [t('pages.alerts_and_monitoring.tabs.snmp_service.form.security_level.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_service.form.security_level.tooltip') })] }), _jsx(Controller, { control: control, name: "securityLevel", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                                handleChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1, overflowX: 'auto', overflowY: 'hidden' } }, { children: [_jsx(ToggleButton, __assign({ value: "noAuthNoPriv" }, { children: securityOptions.no_auth_no_priv })), _jsx(ToggleButton, __assign({ value: "authNoPriv" }, { children: securityOptions.auth_no_priv })), _jsx(ToggleButton, __assign({ value: "authPriv" }, { children: securityOptions.auth_priv }))] })));
                } })] }));
}
export function UsernameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "username", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.username.label')),
            maxLength: {
                value: UsernameMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.username.label'), UsernameMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : null, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.username.label') }, field)));
        } }));
}
export function AuthProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    var authProtocolOptions = t('pages.alerts_and_monitoring.tabs.snmp_service.form.auth_protocol.options');
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('pages.alerts_and_monitoring.tabs.snmp_service.form.auth_protocol.label') }), _jsx(Controller, { control: control, name: "authProtocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "MD5" }, { children: authProtocolOptions.md5 })), _jsx(ToggleButton, __assign({ value: "SHA" }, { children: authProtocolOptions.sha }))] })));
                } })] }));
}
export function AuthPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "authPassword", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.auth_password.label')),
            minLength: {
                value: AuthPasswordMinLength,
                message: minLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.auth_password.label'), AuthPasswordMinLength.toString()),
            },
            maxLength: {
                value: AuthPasswordMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.auth_password.label'), AuthPasswordMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", required: true, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.auth_password.label'), error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : null }, field)));
        } }));
}
export function ConfirmAuthPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirmAuthPassword", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.confirm_auth_password.label')),
            validate: {
                passwordsMatch: function (value, formValues) { return formValues.authPassword === value || t('global.form.password_not_matched'); },
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.confirm_auth_password.label'), error: !!fieldState.error, required: true, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function PrivacyProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    var privacyProtocolOptions = t('pages.alerts_and_monitoring.tabs.snmp_service.form.privacy_protocol.options');
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('pages.alerts_and_monitoring.tabs.snmp_service.form.privacy_protocol.label') }), _jsx(Controller, { control: control, name: "privacyProtocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "DES" }, { children: privacyProtocolOptions.des })), _jsx(ToggleButton, __assign({ value: "AES" }, { children: privacyProtocolOptions.aes }))] })));
                } })] }));
}
export function PrivacyPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "privacyPassword", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.privacy_password.label')),
            minLength: {
                value: PrivacyPasswordMinLength,
                message: minLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.privacy_password.label'), PrivacyPasswordMinLength.toString()),
            },
            maxLength: {
                value: PrivacyPasswordMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.privacy_password.label'), PrivacyPasswordMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", required: true, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.privacy_password.label'), error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : null }, field)));
        } }));
}
export function ConfirmPrivacyPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "confirmPrivacyPassword", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.confirm_privacy_password.label')),
            validate: {
                passwordsMatch: function (value, formValues) { return formValues.privacyPassword === value || t('global.form.password_not_matched'); },
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.confirm_privacy_password.label'), error: !!fieldState.error, required: true, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
