import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { FilterReadonlyQuery } from '../../filter-query';
import { InvalidQueryContent } from '../filter-components';
import { NodeFilterQuery } from './node-filter-query';
import { useNodeFilterContext } from './node-filter-context';
import { isNodeFilterNew, isNodeQueryValid } from './node-filter-utils';
export function NodeFilterQueryContent() {
    var _a = useNodeFilterContext(), selectedFilter = _a.selectedFilter, dispatchSelectedFilter = _a.dispatchSelectedFilter;
    var handleNewQueryChange = useCallback(function (query) {
        dispatchSelectedFilter({ type: 'updateQuery', payload: query });
    }, [dispatchSelectedFilter]);
    if (!selectedFilter)
        return null;
    if (isNodeFilterNew(selectedFilter)) {
        return _jsx(NodeFilterQuery, { query: selectedFilter.queryObject, onChange: handleNewQueryChange });
    }
    if (isNodeQueryValid(selectedFilter)) {
        return _jsx(FilterReadonlyQuery, { query: selectedFilter.queryObject });
    }
    return _jsx(InvalidQueryContent, { query: selectedFilter.query });
}
