var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useVersion } from '@/api/system/version';
function VersionDisplay() {
    var data = useVersion().data;
    var version = data === null || data === void 0 ? void 0 : data.system_version.firmware_version;
    return (_jsx(Typography, __assign({ component: "span", sx: { fontSize: 12, marginLeft: 1 } }, { children: version })));
}
export function AppVersion(_a) {
    var hideVersion = _a.hideVersion;
    var t = useTranslation().t;
    return (_jsxs(Stack, { children: [_jsx(Typography, __assign({ component: "span", sx: { fontSize: 18, textTransform: 'uppercase', marginLeft: 1 } }, { children: t('navigation.app_version.title') })), !hideVersion ? _jsx(VersionDisplay, {}) : null] }));
}
