var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { LIGHTHOUSE_VPN_FORM_LOADER_ID, LighthouseVPNForm } from '@/components/settings/services/lighthouse-vpn/lighthouse-vpn-form';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useLighthouseVPN } from '@/api/services/lhvpn';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { WarningBanner } from '@/components/warning-banner';
export default withAuthenticationRequired(function () {
    var t = useTranslation().t;
    useDocTitle(t('pages.lighthouse_vpn.title'));
    // Check if user has edit permissions based on ui permissions and dependant mode
    var hasPermission = useUIPermissions().hasPermission;
    // Get lighthouse vpn data
    var _a = useLighthouseVPN(), data = _a.data, isLoading = _a.isLoading;
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": LIGHTHOUSE_VPN_FORM_LOADER_ID });
    }
    if (data) {
        innerNode = (_jsxs(_Fragment, { children: [_jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.lighthouse_vpn.warning_header'), warningMessage: _jsx(Typography, { children: t('pages.lighthouse_vpn.warning_message') }), warningColor: "warning", bannerCode: "node-vpn-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }), _jsx(LighthouseVPNForm, { vpnSectionEditable: hasPermission('settings.services.lhvpn.edit'), smfSectionEditable: hasPermission('settings.services.smf.edit'), canViewSmfSection: hasPermission('settings.services.smf.view'), data: data })] }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.lighthouse_vpn.title') })), innerNode] })));
}, { rights: ['settings.services.lhvpn.view'] });
