var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGButtonLink } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import { useFormContainerContext } from '@/components/layout/forms';
export function InterfaceTable(_a) {
    var onInterfaceClick = _a.onInterfaceClick;
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var rows = watch('interfaces');
    var configMethod = watch('configurationMethod');
    var renderNameCell = useCallback(function (_a) {
        var row = _a.row, tabIndex = _a.tabIndex, value = _a.value;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                onInterfaceClick(row);
            }, propagateSpacebar: false }, { children: value })));
    }, [onInterfaceClick]);
    var renderAuthCell = useCallback(function (_a) {
        var value = _a.value;
        return value &&
            new Map([
                ['no_auth', t('pages.config_templates.tabs.smf.form.sections.interface.auth_methods.no_auth')],
                ['cleartext', t('pages.config_templates.tabs.smf.form.sections.interface.auth_methods.cleartext')],
                ['md5', t('pages.config_templates.tabs.smf.form.sections.interface.auth_methods.md5')],
            ]).get(value);
    }, [t]);
    var renderYesNo = useCallback(function (_a) {
        var value = _a.value;
        return (value ? 'Yes' : 'No');
    }, []);
    var columns = [
        {
            field: 'name',
            headerName: t('pages.config_templates.tabs.smf.form.sections.interface.table.headers.interface'),
            flex: 1,
            minWidth: 150,
            renderCell: renderNameCell,
        },
        { field: 'cost', headerName: t('pages.config_templates.tabs.smf.form.sections.interface.table.headers.cost'), flex: 1, minWidth: 120 },
        {
            field: 'auth_method',
            headerName: t('pages.config_templates.tabs.smf.form.sections.interface.table.headers.auth'),
            flex: 1,
            minWidth: 150,
            valueGetter: function (_a) {
                var value = _a.value;
                return renderAuthCell({ value: value });
            },
        },
        {
            field: 'ip_masquerade',
            headerName: t('pages.config_templates.tabs.smf.form.sections.interface.table.headers.masquerade'),
            flex: 1,
            minWidth: 120,
            valueGetter: function (_a) {
                var value = _a.value;
                return renderYesNo({ value: value });
            },
        },
        {
            field: 'passive',
            headerName: t('pages.config_templates.tabs.smf.form.sections.interface.table.headers.passive'),
            flex: 1,
            minWidth: 120,
            valueGetter: function (_a) {
                var value = _a.value;
                return renderYesNo({ value: value });
            },
        },
        {
            field: 'area',
            headerName: t('pages.config_templates.tabs.smf.form.sections.interface.table.headers.area'),
            minWidth: 150,
            flex: 1,
        },
    ];
    return (_jsx(OGTable, { id: "config-templates-smf-interfaces-table", "aria-label": t('pages.config_templates.tabs.smf.form.sections.interface.table.label'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('pages.config_templates.tabs.smf.form.sections.interface.table.no_row_message'), columnVisibilityModel: {
            area: configMethod === 'interface',
        } }));
}
