var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
import { useBulkDeleteResourceTags } from '@/api/tags/resource-tags';
export function DeleteResourceTagModal(_a) {
    var resourceTag = _a.resourceTag, onSuccess = _a.onSuccess;
    var t = useTranslation().t;
    var deleteResourceTag = useBulkDeleteResourceTags();
    var onDelete = useCallback(function () { return deleteResourceTag([resourceTag.id]); }, [deleteResourceTag, resourceTag.id]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('components.tags.resource_tags.modal.title.delete'), deleteButtonLabel: t('global.buttons.delete'), onDelete: onDelete, onSuccess: onSuccess }, { children: _jsxs(DialogContentText, { children: [_jsxs(Box, __assign({ component: "span", display: "flex", alignItems: "flex-end", sx: { marginBottom: 1 } }, { children: [_jsx(InfoIcon, { titleAccess: "Warning", color: "warning", sx: { fontSize: '20px', marginRight: 1 } }), format(t('components.tags.resource_tags.modal.message.resource_warning'), resourceTag.smf_resource_count.toString(), resourceTag.port_count.toString())] })), t('components.tags.resource_tags.modal.message.filter_warning')] }) })));
}
