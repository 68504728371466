var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useCellhealthSettings, useUpdateCellhealthSettings } from '@/api/services/cellhealth';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { CellHealthReportingForm } from '@/components/settings/services/cell-health-reporting/cell-health-reporting-form';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var CELLHEALTH_LOADING_TEST_ID = 'cellhealth-settings-loading';
function CellHealthReporting() {
    var t = useTranslation().t;
    var _a = useCellhealthSettings(), data = _a.data, isLoading = _a.isLoading;
    var updateCellhealthSettings = useUpdateCellhealthSettings();
    var hasPermission = useUIPermissions().hasPermission;
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": CELLHEALTH_LOADING_TEST_ID });
    }
    if (data) {
        innerNode = (_jsx(Card, { children: _jsx(CellHealthReportingForm, { data: data, editable: hasPermission('settings.services.cellhealth.edit'), onSubmit: updateCellhealthSettings }) }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.cell_health_reporting.title') })), innerNode] })));
}
export default withAuthenticationRequired(CellHealthReporting, { rights: ['settings.services.cellhealth.view'] });
