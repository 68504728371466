var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { maxLengthFieldError } from '@/utils/field-validation-errors';
// Field validation limits
export var LocationMaxLength = 255;
export var ContactMaxLength = 255;
export function StatusToggleButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "enabled", rules: {
            validate: function (value, fields) { return !(value && !fields.v1Enabled && !fields.v3Enabled); },
        }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
            return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasEditPermission, value: value, onChange: function (event, v) {
                    if (v !== null) {
                        onChange(v);
                    }
                } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
        } }));
}
export function ProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('pages.alerts_and_monitoring.tabs.snmp_service.form.protocol.label') }), _jsx(Controller, { control: control, name: "protocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "UDP" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_service.form.protocol.udp') })), _jsx(ToggleButton, __assign({ value: "TCP" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_service.form.protocol.tcp') }))] })));
                } })] }));
}
export function LocationField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "location", rules: {
            maxLength: {
                value: LocationMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.location.label'), LocationMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.location.label'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function ContactField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "contact", rules: {
            maxLength: {
                value: ContactMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.contact.label'), ContactMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.contact.label'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function V1V2CheckboxField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, clearErrors = _a.clearErrors, setValue = _a.setValue, formState = _a.formState;
    var handleChange = useCallback(function (e) {
        var _a, _b, _c, _d;
        if (!e.target.checked) {
            clearErrors(['readWriteCommunity', 'readOnlyCommunity']);
            setValue('readOnlyCommunity', (_b = (_a = formState.defaultValues) === null || _a === void 0 ? void 0 : _a.readOnlyCommunity) !== null && _b !== void 0 ? _b : '');
            setValue('readWriteCommunity', (_d = (_c = formState.defaultValues) === null || _c === void 0 ? void 0 : _c.readWriteCommunity) !== null && _d !== void 0 ? _d : '');
        }
    }, [clearErrors, formState, setValue]);
    return (_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "v1Enabled", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                    return (_jsx(Checkbox, __assign({}, field, { checked: value, disabled: !permissions.hasEditPermission, onChange: function (event) {
                            onChange(event);
                            handleChange(event);
                        } })));
                } }), label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.v1/v2.label') }) }));
}
export function V3CheckboxField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, clearErrors = _a.clearErrors, setValue = _a.setValue, formState = _a.formState;
    var handleChange = useCallback(function (e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!e.target.checked) {
            clearErrors(['username', 'authPassword', 'confirmAuthPassword', 'privacyPassword', 'confirmPrivacyPassword']);
            setValue('engineId', (_b = (_a = formState.defaultValues) === null || _a === void 0 ? void 0 : _a.engineId) !== null && _b !== void 0 ? _b : '');
            setValue('authProtocol', (_d = (_c = formState.defaultValues) === null || _c === void 0 ? void 0 : _c.authProtocol) !== null && _d !== void 0 ? _d : 'MD5');
            setValue('securityLevel', (_f = (_e = formState.defaultValues) === null || _e === void 0 ? void 0 : _e.securityLevel) !== null && _f !== void 0 ? _f : 'noAuthNoPriv');
            setValue('username', (_h = (_g = formState.defaultValues) === null || _g === void 0 ? void 0 : _g.username) !== null && _h !== void 0 ? _h : '');
            setValue('privacyPassword', '');
            setValue('confirmPrivacyPassword', '');
            setValue('privacyProtocol', (_k = (_j = formState.defaultValues) === null || _j === void 0 ? void 0 : _j.privacyProtocol) !== null && _k !== void 0 ? _k : 'DES');
            setValue('authPassword', '');
            setValue('confirmAuthPassword', '');
        }
    }, [clearErrors, formState, setValue]);
    return (_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "v3Enabled", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                    return (_jsx(Checkbox, __assign({}, field, { checked: value, disabled: !permissions.hasEditPermission, onChange: function (event) {
                            onChange(event);
                            handleChange(event);
                        } })));
                } }), label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.v3.label') }) }));
}
