var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import { CancelButton, FormActions, FormContainer, SubmitButton } from '@/components/layout/forms';
import { NameField, ValuesField } from '../../tag-forms';
import { transformNodeTagData } from './node-tag-form-transformers';
import { DeleteNodeTagModal } from '@/containers/tags/node-tags/node-tag-modal';
var defaultValues = {
    name: '',
    values: [{ value: '' }],
};
export function NodeTagForm(_a) {
    var ariaLabelledBy = _a.ariaLabelledBy, nodeTag = _a.nodeTag, onCancel = _a.onCancel, onSubmit = _a.onSubmit, permissions = _a.permissions;
    var t = useTranslation().t;
    var mode = !nodeTag ? 'create' : 'update';
    var submitLabel = t('components.tags.form.submit')[mode];
    var sourceValues = useMemo(function () { return (nodeTag ? transformNodeTagData(nodeTag) : defaultValues); }, [nodeTag]);
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: ariaLabelledBy, methods: methods, onSubmit: onSubmit, mode: mode, permissions: permissions }, { children: [_jsxs(DialogContent, __assign({ sx: { paddingTop: 0 } }, { children: [_jsx(NameField, {}), _jsx(ValuesField, {})] })), _jsx(Divider, {}), _jsx(DialogActions, __assign({ sx: { paddingTop: 0 } }, { children: _jsxs(FormActions, { children: [nodeTag && _jsx(DeleteNodeTagModal, { nodeTag: nodeTag, onSuccess: onCancel }), _jsx(Box, { flexGrow: 1 }), _jsx(CancelButton, { onCancel: onCancel }), _jsx(SubmitButton, { children: submitLabel })] }) }))] })));
}
