var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts/hooks';
var centreOffset = 4;
var iconSize = 24;
var fontSize = 18;
/**
 * Donut Centre Label Component
 * Used only for the dashboard panels
 */
export function DonutLabel(_a) {
    var IconComponent = _a.IconComponent, textProps = _a.textProps;
    var theme = useTheme();
    var _b = useDrawingArea(), size = _b.height, left = _b.left, top = _b.top;
    var centreCoordinates = {
        x: left + size / 2,
        y: top + size / 2,
    };
    return (_jsxs("g", { children: [_jsx(IconComponent, { width: iconSize, height: iconSize, x: centreCoordinates.x - iconSize / 2, y: centreCoordinates.y - centreOffset - iconSize }), _jsx(Box, __assign({ component: "text", x: centreCoordinates.x, y: centreCoordinates.y + centreOffset + fontSize / 2, sx: {
                    dominantBaseline: 'central',
                    fill: theme.palette.text.primary,
                    fontSize: fontSize,
                    textAnchor: 'middle',
                } }, textProps))] }));
}
