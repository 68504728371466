var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import format from 'string-format';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { OGButton, OGButtonLink } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { OGLinkButton } from '@opengear/links';
import { OGPanel } from '@opengear/panel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGTable } from '@opengear/table';
import FormLabel from '@mui/material/FormLabel';
import { ROLES_NEW_ROUTE, GROUPS_ROLES_ROUTE, GROUPS_ROUTE } from '@/constants/routes';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useDeviceView } from '@/hooks/use-device-view';
import { usePortSmartgroups } from '@/api/ports/smartgroups';
import { useNodeSmartgroups } from '@/api/nodes/smartgroups';
import { useRoles } from '@/api/roles';
import { useOpen } from '@/hooks/open';
import { useRolesPermissions } from '@/api/permissions/roles';
import { GroupsAndRolesPermissionsTable } from '@/components/accounts/groups/groups-and-roles-permissions-table';
import { GroupsAndRolesPermissionField } from '@/components/accounts/groups/groups-and-roles-permission-field';
import PermissionsPreview from '@/components/accounts/permissions/permissions-preview';
import { useFormSync } from '@/hooks/use-form-sync';
import { QUERY_LIMIT } from '@/constants/limits';
import { useSmfResourcesSmartgroupsNames } from '@/api/smf-resources/smartgroups';
import { USER_GROUP_NAME_PATTERN } from '@/constants/regex';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { RemoveCell } from '@/components/remove-cell';
/** * Constants ** */
// useForm deep partials the generic type that you pass the hook.
// Defining the defaultValues as a variable ensures required fields exists
export var defaultValues = {
    enabled: 'false',
    groupname: '',
    description: '',
    managedDeviceFilter: '',
    resourceFilter: '',
    smartgroup: '',
    roleNames: [],
};
export var LINKED_SMARTGROUPS_LOADER_ID = 'linked-smartgroups-inputs-loading';
export var MANAGED_DEVICE_FILTER_INPUT_TEST_ID = 'managedDeviceFilter-input';
export var RESOURCE_FILTER_INPUT_TEST_ID = 'resourceFilter-input';
export var SMARTGROUP_INPUT_TEST_ID = 'smartgroup-input';
export var ROLES_PERMISSIONS_LOADER_ID = 'roles-permissions-summary-loading';
export var PREVIEW_ROLE_PERMISSIONS_LOADER_ID = 'preview-role-permissions-loading';
export var GroupnameFieldMaxLength = 60;
export var DescriptionFieldMaxLength = 128;
/** * Helpers and Hooks ** */
// Helper to transform form data to API ready data
export function transformGroupFormData(formData) {
    var description = formData.description === '' ? undefined : formData.description;
    var managedDeviceFilter = formData.managedDeviceFilter === '' ? undefined : formData.managedDeviceFilter;
    var resourceFilter = formData.resourceFilter === '' ? undefined : formData.resourceFilter;
    var smartgroup = formData.smartgroup === '' ? undefined : formData.smartgroup;
    // Check for any filters to determine the mode
    var mode = !!managedDeviceFilter || !!resourceFilter || !!smartgroup ? 'smart_group' : 'global';
    var transformedGroup = {
        enabled: formData.enabled === 'true',
        groupname: formData.groupname,
        mode: mode,
    };
    // Only add fields to data for string and null values, otherwise omit the key
    if (description !== undefined)
        transformedGroup.description = description;
    if (managedDeviceFilter !== undefined)
        transformedGroup.managed_device_filter = managedDeviceFilter;
    if (resourceFilter !== undefined)
        transformedGroup.resource_filter = resourceFilter;
    if (smartgroup !== undefined)
        transformedGroup.smart_group = smartgroup;
    if (formData.roleNames.length > 0)
        transformedGroup[mode === 'global' ? 'global_roles' : 'smart_group_roles'] = formData.roleNames;
    if (mode === 'smart_group' && transformedGroup.smart_group === undefined) {
        transformedGroup.smart_group = 'all_nodes';
    }
    return transformedGroup;
}
// Helper to transform API data to form ready data
export function transformGroupApiData(group) {
    var _a, _b, _c, _d;
    var roleNames = group.mode === 'global' ? group.global_roles : group.smart_group_roles;
    return {
        enabled: group.enabled ? 'true' : 'false',
        groupname: group.groupname,
        description: (_a = group.description) !== null && _a !== void 0 ? _a : '',
        managedDeviceFilter: (_b = group.managed_device_filter) !== null && _b !== void 0 ? _b : '',
        resourceFilter: (_c = group.resource_filter) !== null && _c !== void 0 ? _c : '',
        smartgroup: (_d = group.smart_group) !== null && _d !== void 0 ? _d : '',
        roleNames: roleNames !== null && roleNames !== void 0 ? roleNames : [],
    };
}
// Hook to build table data from form data and roles api
export function useRolesTableData(roleNames) {
    // Fetch all roles. Note: Filter param won't work to reduce response
    var _a = useRoles({ per_page: QUERY_LIMIT }), data = _a.data, isLoading = _a.isLoading;
    // Don't show incomplete table data but instead show empty table
    if (isLoading || !data)
        return [];
    // return table rows with role data or unknown message
    return data.roles.filter(function (role) { return roleNames.includes(role.rolename); });
}
// Hook to build add table data from form data and roles api
export function useRolesAddTableData(roleNames) {
    // Fetch all roles. Note: Filter param won't work to reduce response
    var _a = useRoles({ per_page: QUERY_LIMIT }), data = _a.data, isLoading = _a.isLoading;
    // If loading don't show incomplete table data but instead show empty table
    if (isLoading || !data)
        return [];
    // return all roles excluding the ones already selected
    return data.roles.filter(function (role) { return !roleNames.includes(role.rolename); });
}
/** * Child Components ** */
// Optional form inputs that link node and port smartgroups to the permissions of a group
export function LinkedFilters(_a) {
    var control = _a.control;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    // Fetch managed device filters
    var _b = usePortSmartgroups(), portFilters = _b.data, isLoadingPortFilters = _b.isLoading;
    // Fetch node filters
    var _c = useNodeSmartgroups(), nodeFilters = _c.data, isLoadingNodeFilters = _c.isLoading;
    // Fetch resource filters
    var _d = useSmfResourcesSmartgroupsNames(), resourceFilters = _d.data, isLoadingResourceFilters = _d.isLoading;
    // Early return
    if (isLoadingPortFilters || isLoadingNodeFilters || isLoadingResourceFilters)
        return _jsx("div", { "data-testid": LINKED_SMARTGROUPS_LOADER_ID });
    return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.group.form.linked_filters_section.title') })), _jsx(Controller, { control: control, name: "smartgroup", render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (_jsxs(Stack, __assign({ direction: "column", sx: { marginBottom: 3 } }, { children: [_jsxs(TextField, __assign({ id: "smartgroup", label: _jsxs(_Fragment, { children: [t('components.group.form.fields.smartgroup.label'), _jsx(OGIconTooltip, { title: t('components.group.form.fields.smartgroup.tooltip') })] }), select: true, SelectProps: {
                                            displayEmpty: true,
                                            inputProps: {
                                                'data-testid': SMARTGROUP_INPUT_TEST_ID,
                                            },
                                        }, sx: { maxWidth: isMobileView ? undefined : 400, marginBottom: 1 } }, field, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: t('components.group.form.fields.smartgroup.defaultValue') })), (_b = nodeFilters === null || nodeFilters === void 0 ? void 0 : nodeFilters.smartgroups) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                                                var id = _a.id, name = _a.name;
                                                return (_jsx(MenuItem, __assign({ value: id }, { children: name }), id));
                                            })] })), _jsxs(Stack, __assign({ direction: "row", gap: 0.5, alignItems: "center" }, { children: [_jsx(InfoIcon, { fontSize: "small" }), _jsx(Typography, __assign({ component: "span", variant: "body2" }, { children: t('components.group.form.fields.smartgroup.info') }))] }))] })));
                        } }), _jsx(Controller, { control: control, name: "managedDeviceFilter", render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (_jsxs(Stack, __assign({ direction: "column", sx: { marginBottom: 3 } }, { children: [_jsxs(TextField, __assign({ id: "managedDeviceFilter", label: _jsxs(_Fragment, { children: [t('components.group.form.fields.managed_device_filter.label'), _jsx(OGIconTooltip, { title: t('components.group.form.fields.managed_device_filter.tooltip') })] }), select: true, SelectProps: {
                                            displayEmpty: true,
                                            inputProps: {
                                                'data-testid': MANAGED_DEVICE_FILTER_INPUT_TEST_ID,
                                            },
                                        }, sx: { maxWidth: isMobileView ? undefined : 400, marginBottom: 1 } }, field, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: t('components.group.form.fields.managed_device_filter.defaultValue') })), (_b = portFilters === null || portFilters === void 0 ? void 0 : portFilters.smartgroups) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                                                var id = _a.id, name = _a.name;
                                                return (_jsx(MenuItem, __assign({ value: id }, { children: name }), id));
                                            })] })), _jsxs(Stack, __assign({ direction: "row", gap: 0.5, alignItems: "center" }, { children: [_jsx(InfoIcon, { fontSize: "small" }), _jsx(Typography, __assign({ component: "span", variant: "body2" }, { children: t('components.group.form.fields.managed_device_filter.info') }))] }))] })));
                        } }), _jsx(Controller, { control: control, name: "resourceFilter", render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (_jsxs(Stack, __assign({ direction: "column", sx: { marginBottom: 1 } }, { children: [_jsxs(TextField, __assign({ id: "resourceFilter", label: _jsxs(_Fragment, { children: [t('components.group.form.fields.resource_filter.label'), _jsx(OGIconTooltip, { title: t('components.group.form.fields.resource_filter.tooltip') })] }), select: true, SelectProps: {
                                            displayEmpty: true,
                                            inputProps: {
                                                'data-testid': RESOURCE_FILTER_INPUT_TEST_ID,
                                            },
                                        }, sx: { maxWidth: isMobileView ? undefined : 400 } }, field, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: t('components.group.form.fields.resource_filter.defaultValue') })), (_b = resourceFilters === null || resourceFilters === void 0 ? void 0 : resourceFilters.smartgroups) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                                                var id = _a.id, name = _a.name;
                                                return (_jsx(MenuItem, __assign({ value: id }, { children: name }), id));
                                            })] })), _jsxs(Stack, __assign({ direction: "row", gap: 0.5, alignItems: "center" }, { children: [_jsx(InfoIcon, { fontSize: "small" }), _jsx(Typography, __assign({ component: "span", variant: "body2" }, { children: t('components.group.form.fields.resource_filter.info') }))] }))] })));
                        } })] }))] }));
}
function AddRolesPreviewContent(_a) {
    var roleName = _a.roleName;
    var _b = useRolesPermissions([roleName]), data = _b.data, isLoading = _b.isLoading;
    if (isLoading) {
        return (_jsx(Stack, __assign({ height: "100%", alignItems: "center", justifyContent: "center" }, { children: _jsx(CircularProgress, { "data-testid": PREVIEW_ROLE_PERMISSIONS_LOADER_ID }) })));
    }
    return _jsx(PermissionsPreview, { name: roleName, permissions: data });
}
export function AddRolesModal(_a) {
    var control = _a.control, open = _a.open, handleClose = _a.handleClose, handleAddRoles = _a.handleAddRoles;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = __read(useState([]), 2), selectedRoles = _b[0], setSelectedRoles = _b[1];
    var _c = __read(useState(), 2), previewRole = _c[0], setPreviewRole = _c[1];
    var roleNames = useWatch({ control: control, name: 'roleNames' });
    var rows = useRolesAddTableData(roleNames);
    var onSubmit = useCallback(function () {
        handleAddRoles(selectedRoles);
        handleClose();
    }, [handleAddRoles, handleClose, selectedRoles]);
    var onCancel = useCallback(function () {
        handleClose();
    }, [handleClose]);
    var handleSelectionModelChange = useCallback(function (newSelection) {
        // getRowId uses rolename for id so newSelection will be string[]
        setSelectedRoles(newSelection);
    }, []);
    var renderPreviewLink = useCallback(function (_a) {
        var tabIndex = _a.tabIndex, value = _a.value;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                setPreviewRole(value);
            }, propagateSpacebar: false }, { children: value })));
    }, []);
    var columns = useMemo(function () { return [
        {
            field: 'rolename',
            flex: 1,
            headerName: t('components.group.form.roles_section.add_modal.table.headers.rolename'),
            sortable: false,
            renderCell: renderPreviewLink,
        },
        {
            field: 'description',
            flex: 1,
            headerName: t('components.group.form.roles_section.add_modal.table.headers.description'),
            sortable: false,
        },
    ]; }, [renderPreviewLink, t]);
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [previewRole ? (_jsxs(Stack, __assign({ direction: isMobileView ? 'row-reverse' : 'column', alignItems: isMobileView ? 'center' : 'start', justifyContent: "start", marginBottom: 1 }, { children: [_jsx(DialogTitle, __assign({ sx: { paddingBottom: isMobileView ? 2 : 1 } }, { children: previewRole })), _jsx(Box, __assign({ width: "fit-content" }, { children: _jsx(OGButtonLink
                        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        , __assign({ 
                            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, startIcon: _jsx(ChevronLeftIcon, { fontSize: "medium" }), variant: "standard", sx: { marginLeft: 1, paddingRight: 1, paddingY: 1 }, onClick: function () {
                                setPreviewRole(undefined);
                            } }, { children: t('global.buttons.back') })) }))] }))) : (_jsx(DialogTitle, { children: t('components.group.form.roles_section.add_modal.title') })), _jsx(Divider, {}), _jsx(DialogContent, __assign({ tabIndex: -1, sx: { height: '500px' } }, { children: previewRole ? (_jsx(AddRolesPreviewContent, { roleName: previewRole })) : (_jsx(OGTable, { 
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, "aria-label": t('components.group.form.roles_section.add_modal.title'), checkboxSelection: true, rows: rows, columns: columns, noRowsMessage: t('components.group.form.roles_section.add_modal.table.no_rows_message'), getRowId: function (row) { return row.rolename; }, rowSelectionModel: selectedRoles, onRowSelectionModelChange: handleSelectionModelChange })) })), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: onCancel, autoFocus: true }, { children: t('global.buttons.cancel') })), previewRole ? null : (_jsx(OGButton, __assign({ disabled: selectedRoles.length < 1, onClick: onSubmit }, { children: t('global.buttons.add') })))] })] })));
}
function RolePreviewModal(_a) {
    var roleName = _a.roleName, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var _b = useRolesPermissions([roleName]), data = _b.data, isLoading = _b.isLoading;
    return (_jsxs(OGDialog, __assign({ open: !!roleName, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: roleName }), _jsx(DialogContent, { children: isLoading ? (_jsx(Stack, __assign({ height: "100%", alignItems: "center", justifyContent: "center" }, { children: _jsx(CircularProgress, { "data-testid": PREVIEW_ROLE_PERMISSIONS_LOADER_ID }) }))) : (_jsx(PermissionsPreview, { name: roleName, permissions: data })) }), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: handleClose, autoFocus: true }, { children: t('global.buttons.close') })) })] })));
}
export function RolesTable(_a) {
    var control = _a.control, handleRemoveRole = _a.handleRemoveRole, addButtonRef = _a.addButtonRef;
    var t = useTranslation().t;
    var _b = __read(useState(), 2), previewRole = _b[0], setPreviewRole = _b[1];
    // Remove row focus management
    var _c = __read(useState(undefined), 2), cellFocus = _c[0], setCellFocus = _c[1];
    var roleNames = useWatch({ control: control, name: 'roleNames' });
    var rows = useRolesTableData(roleNames);
    var handlePreviewReset = useCallback(function () {
        setPreviewRole(undefined);
    }, []);
    var renderPreviewLink = useCallback(function (_a) {
        var tabIndex = _a.tabIndex, value = _a.value;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                setPreviewRole(value);
            }, propagateSpacebar: false }, { children: value })));
    }, []);
    var renderRemoveCell = useCallback(function (_a) {
        var row = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        return (_jsx(RemoveCell, { index: api.getRowIndexRelativeToVisibleRows(row.rolename), currentCount: rows.length, icon: _jsx(ClearIcon, { fontSize: "medium" }), tabIndex: tabIndex, onRemove: function (nextFocusIndex) {
                var _a;
                // Set focus to next row
                if (typeof nextFocusIndex !== 'undefined') {
                    var nextFocusRowId = rows[nextFocusIndex] ? rows[nextFocusIndex].rolename : undefined;
                    setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'remove' } : undefined);
                }
                else {
                    (_a = addButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                }
                handleRemoveRole(row.rolename);
            }, translation: {
                tooltip: t('components.group.form.roles_section.table.buttons.remove_tooltip'),
                label: format(t('components.group.form.roles_section.table.buttons.remove_label'), row.rolename),
            } }));
    }, [addButtonRef, handleRemoveRole, rows, t]);
    var columns = useMemo(function () { return [
        {
            field: 'rolename',
            flex: 1,
            headerName: t('components.group.form.roles_section.table.headers.rolename'),
            sortable: false,
            renderCell: renderPreviewLink,
        },
        {
            field: 'group_usage',
            flex: 1,
            headerName: t('components.group.form.roles_section.table.headers.group_usage'),
            sortable: false,
        },
        {
            field: 'number_of_permissions',
            flex: 1,
            headerName: t('components.group.form.roles_section.table.headers.number_of_permissions'),
            sortable: false,
        },
        {
            field: 'remove',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderRemoveCell,
        },
    ]; }, [renderPreviewLink, renderRemoveCell, t]);
    return (_jsxs(_Fragment, { children: [previewRole ? _jsx(RolePreviewModal, { roleName: previewRole, handleClose: handlePreviewReset }) : null, _jsx(OGTable, { "aria-label": t('components.group.form.roles_section.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('components.group.form.roles_section.table.no_rows_message'), getRowId: function (row) { return row.rolename; }, setCellFocus: cellFocus })] }));
}
// Roles section to manage selected roles
export function RolesSection(_a) {
    var control = _a.control, handleAddRoles = _a.handleAddRoles, handleRemoveRole = _a.handleRemoveRole;
    var t = useTranslation().t;
    var _b = useOpen(false), openAddModal = _b.open, handleCloseAddModal = _b.handleClose, handleOpenAddModal = _b.handleOpen;
    var addButtonRef = useRef(null);
    return (_jsxs(_Fragment, { children: [openAddModal && _jsx(AddRolesModal, { control: control, open: openAddModal, handleClose: handleCloseAddModal, handleAddRoles: handleAddRoles }), _jsx(Divider, {}), _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.group.form.roles_section.title') })), _jsx(Box, __assign({ marginBottom: 2 }, { children: _jsx(RolesTable, { control: control, handleRemoveRole: handleRemoveRole, addButtonRef: addButtonRef }) })), _jsxs(Box, __assign({ display: "flex", gap: 1 }, { children: [_jsx(OGButton, __assign({ startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", onClick: handleOpenAddModal, ref: addButtonRef }, { children: t('components.group.form.roles_section.buttons.add_role') })), _jsx(OGLinkButton, __assign({ to: ROLES_NEW_ROUTE, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless" }, { children: t('components.group.form.roles_section.buttons.create_role') }))] }))] }))] }));
}
// Permissions Summary
export function AddGroupPermissionsSummary(_a) {
    var control = _a.control;
    var t = useTranslation().t;
    var roleNames = useWatch({ control: control, name: 'roleNames' });
    var _b = useRolesPermissions(roleNames), data = _b.data, isLoading = _b.isLoading;
    var innerNode = null;
    if (isLoading) {
        innerNode = (_jsx(Stack, __assign({ alignItems: "center" }, { children: _jsx(CircularProgress, { "data-testid": ROLES_PERMISSIONS_LOADER_ID }) })));
    }
    if (data) {
        innerNode = (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.permissions.form.title') })), _jsx(GroupsAndRolesPermissionField, { label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.console_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.console_shell.tooltip') })] }), permission: data.cli.console_shell }), data.cli.shell_access && (_jsx(GroupsAndRolesPermissionField, { label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.shell_access.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.shell_access.tooltip') })] }), permission: data.cli.shell_access })), _jsx(GroupsAndRolesPermissionField, { label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.pm_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.pm_shell.tooltip') })] }), permission: data.cli.pm_shell }), _jsx(Divider, { sx: { marginX: -2, marginBottom: 2 } }), _jsx(GroupsAndRolesPermissionsTable, { permissions: data })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.group.form.permissions_section.title') })), _jsx(Typography, __assign({ component: "p", variant: "body1", sx: { marginBottom: 4 } }, { children: t('components.group.form.permissions_section.info') })), innerNode] }))] }));
}
export function GroupForm(_a) {
    var _this = this;
    var group = _a.group, _b = _a.readableFilters, readableFilters = _b === void 0 ? false : _b, _c = _a.readableRoles, readableRoles = _c === void 0 ? false : _c, submitHandler = _a.submitHandler;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var navigate = useNavigate();
    var sourceValues = useMemo(function () { return (group ? transformGroupApiData(group) : defaultValues); }, [group]);
    // Form state
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    var _d = methods.formState, isValid = _d.isValid, isDirty = _d.isDirty, isSubmitting = _d.isSubmitting;
    // Alert the user of data changes
    useFormSync(methods.formState.defaultValues, sourceValues, methods.reset, t('components.group.form.reset_warning.header'));
    // Submit handler to add new group
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var requestData, newOrUpdatedGroup;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestData = transformGroupFormData(formData);
                    return [4 /*yield*/, submitHandler(requestData)
                        // If successful, reset form and navigate to groups page
                    ];
                case 1:
                    newOrUpdatedGroup = _a.sent();
                    // If successful, reset form and navigate to groups page
                    if (newOrUpdatedGroup) {
                        methods.reset();
                        navigate(GROUPS_ROLES_ROUTE + GROUPS_ROUTE);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [submitHandler, methods, navigate]);
    // Reset handler to restore form to default values
    var handleCancel = useCallback(function () {
        navigate(GROUPS_ROLES_ROUTE + GROUPS_ROUTE);
    }, [navigate]);
    // Handler for adding roles
    var handleAddRoles = useCallback(function (names) {
        if (names.length > 0) {
            // Prevent duplicates when adding to roleNames list
            var roleNamesSet = new Set(__spreadArray(__spreadArray([], __read(methods.getValues('roleNames')), false), __read(names), false));
            methods.setValue('roleNames', __spreadArray([], __read(roleNamesSet), false), { shouldDirty: true });
        }
    }, [methods]);
    // Handler for removing roles
    var handleRemoveRole = useCallback(function (name) {
        // Remove all matching role port-tags
        var filteredRoleNames = methods.getValues('roleNames').filter(function (roleName) { return roleName !== name; });
        methods.setValue('roleNames', filteredRoleNames, { shouldDirty: true });
    }, [methods]);
    return (_jsx(OGPanel, __assign({ sx: { padding: 0 } }, { children: _jsxs(Stack, __assign({ component: "form", "aria-labelledby": PAGE_TITLE_ID, onSubmit: methods.handleSubmit(onSubmit) }, { children: [_jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Controller, { control: methods.control, name: "enabled", render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                                return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('components.group.form.fields.status.label') }), _jsxs(ToggleButtonGroup, __assign({ exclusive: true, onChange: function (event, newValue) {
                                                if (newValue !== null)
                                                    onChange(newValue);
                                            } }, field, { children: [_jsx(ToggleButton, __assign({ value: "true" }, { children: t('global.buttons.enabled.enabled') })), _jsx(ToggleButton, __assign({ value: "false" }, { children: t('global.buttons.enabled.disabled') }))] }))] }));
                            } }), _jsx(Controller, { control: methods.control, name: "groupname", rules: {
                                required: requiredFieldError(t('components.group.form.fields.groupname.label')),
                                maxLength: {
                                    value: GroupnameFieldMaxLength,
                                    message: maxLengthFieldError(t('components.group.form.fields.groupname.label'), GroupnameFieldMaxLength.toString()),
                                },
                                pattern: {
                                    value: USER_GROUP_NAME_PATTERN,
                                    message: invalidFieldError(t('components.group.form.fields.groupname.label')),
                                },
                            }, render: function (_a) {
                                var _b;
                                var field = _a.field, fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ id: "groupname", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('components.group.form.fields.groupname.label'), sx: { maxWidth: isMobileView ? undefined : 400 }, required: true }, field)));
                            } }), _jsx(Controller, { control: methods.control, name: "description", rules: {
                                maxLength: {
                                    value: DescriptionFieldMaxLength,
                                    message: maxLengthFieldError(t('components.group.form.fields.description.label'), DescriptionFieldMaxLength.toString()),
                                },
                            }, render: function (_a) {
                                var _b;
                                var field = _a.field, fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ id: "description", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('components.group.form.fields.description.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
                            } })] })), readableFilters && _jsx(LinkedFilters, { control: methods.control }), readableRoles && _jsx(RolesSection, { control: methods.control, handleAddRoles: handleAddRoles, handleRemoveRole: handleRemoveRole }), _jsx(AddGroupPermissionsSummary, { control: methods.control }), _jsx(Stack, __assign({ padding: 2 }, { children: _jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end" }, { children: [_jsx(OGButton, __assign({ disabled: isSubmitting, variant: "borderless", onClick: handleCancel }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ type: "submit", disabled: !isValid || !isDirty, loading: isSubmitting }, { children: t('global.buttons.apply') }))] })) }))] })) })));
}
