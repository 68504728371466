var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import React, { useId, useMemo } from 'react';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToggleButton from '@mui/material/ToggleButton';
import Collapse from '@mui/material/Collapse';
import size from 'lodash/size';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useOpen } from '@/hooks/open';
import { useFormatPermission } from '../utils';
export function GroupsAndRolesPermissionField(_a) {
    var label = _a.label, margin = _a.margin, _b = _a.mode, mode = _b === void 0 ? 'group' : _b, _c = _a.permission, overall = _c.overall, other = __rest(_c, ["overall"]);
    var id = useId();
    var t = useTranslation().t;
    var _d = useOpen(), open = _d.open, handleToggle = _d.handleToggle;
    var formatPermission = useFormatPermission();
    var value = useMemo(function () { return formatPermission(overall); }, [formatPermission, overall]);
    return (_jsxs(FormControl, __assign({ margin: margin, sx: {
            '& .MuiInputBase-root.MuiInput-root': { backgroundColor: 'transparent', border: 'none' },
            '& .MuiInputBase-input.MuiInput-input.Mui-disabled': { padding: 0 },
        } }, { children: [_jsx(InputLabel, __assign({ htmlFor: id }, { children: label })), _jsxs(Box, __assign({ alignItems: "centre", display: "flex", flexDirection: "row", gap: 1, marginTop: 0.75 }, { children: [_jsx(ToggleButton, __assign({ "aria-label": t('components.permissions.breakdown.expand'), disabled: size(other) === 0, onChange: handleToggle, sx: { padding: 0 }, selected: open, value: "check" }, { children: open ? _jsx(ExpandLessIcon, {}) : _jsx(ExpandMoreIcon, {}) })), _jsx(Input, { disabled: true, id: id, value: value })] })), _jsx(Collapse, __assign({ in: open, unmountOnExit: true }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 1.5, marginBottom: 1.5, marginLeft: 4, marginTop: 1.5 }, { children: [_jsxs(InputLabel, __assign({ sx: { textTransform: 'uppercase' } }, { children: [t('components.permissions.breakdown.label'), _jsx(OGIconTooltip, { title: mode === 'group' ? t('components.permissions.breakdown.tooltip_group') : t('components.permissions.breakdown.tooltip_user') })] })), map(other, function (permission, role) { return (_jsxs(Box, __assign({ display: "flex", flexDirection: "row", gap: 1 }, { children: [_jsx(Box, __assign({ minWidth: 160 }, { children: role })), _jsx(Box, { children: formatPermission(permission) })] }), role)); })] })) }))] })));
}
