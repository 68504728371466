import { rest } from 'msw';
import { SUPPORT_REPORT_PATH } from './support-report';
import { formatApiPath } from '@/api/utils';
export var defaultMockSupportReportBlob = new Blob([], { type: 'application/zip' });
/**
 * Mock handler for test server
 */
export var supportReportGetHandler = rest.get(formatApiPath(SUPPORT_REPORT_PATH), function (req, res, ctx) {
    if (req.url.searchParams.get('zip')) {
        return res(ctx.status(200), ctx.set('Content-Type', 'application/zip'), ctx.set('Content-Disposition', 'attachment; filename=support.zip'), ctx.body(defaultMockSupportReportBlob));
    }
    return req.passthrough();
});
