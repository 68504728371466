var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OGTable } from '@opengear/table';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EnrolledStateCell } from '@/components/nodes/enrolled-state-cell';
import { usePaginationModel } from '@/hooks/pagination-model';
import { TemplatePopover } from '@/components/nodes/node/template-status-popover';
export var TemplatePushStatus;
(function (TemplatePushStatus) {
    TemplatePushStatus[TemplatePushStatus["Error"] = 0] = "Error";
    TemplatePushStatus[TemplatePushStatus["Warning"] = 1] = "Warning";
    TemplatePushStatus[TemplatePushStatus["Success"] = 2] = "Success";
    TemplatePushStatus[TemplatePushStatus["Pending"] = 3] = "Pending";
})(TemplatePushStatus || (TemplatePushStatus = {}));
function calculateStatus(ctx) {
    if (ctx.status !== 'execute_complete') {
        return TemplatePushStatus.Pending;
    }
    if (ctx.execution_errors.length > 0) {
        return TemplatePushStatus.Error;
    }
    if (ctx.execution_warnings.length > 0) {
        return TemplatePushStatus.Warning;
    }
    return TemplatePushStatus.Success;
}
function calculateMessage(ctx, t) {
    var messages = [];
    if (ctx.execution_errors.length > 0) {
        messages = ctx.execution_errors;
    }
    else if (ctx.execution_warnings.length > 0) {
        messages = ctx.execution_warnings;
    }
    else if (ctx.execution_messages.length > 0) {
        messages = ctx.execution_messages;
    }
    else if (ctx.execution_changes.length > 0) {
        messages = ctx.execution_changes;
    }
    var formattedMessages = messages.map(function (m) {
        return t("api.error_codes.".concat(m.code.toString()), __assign(__assign({}, m.args), { defaultValue: t('api.error_codes.code_not_found') }));
    });
    // There aren't any templates that return more than one message of the same level - but it is in theory possible
    // So we need to handle it, but in general this will only be a single message
    return formattedMessages.join(', ');
}
function generateTableData(nodeContexts, nodeData, t) {
    return nodeContexts.flatMap(function (context) {
        var node = nodeData.find(function (nodeInfo) { return nodeInfo.id === context.node_id; });
        if (node) {
            return [
                {
                    id: node.id,
                    runtime_status: node.runtime_status,
                    name: node.name,
                    push_status: calculateStatus(context),
                    message: calculateMessage(context, t),
                    config_push_status: node.config_push_status,
                },
            ];
        }
        return [];
    });
}
export function PushStatusCell(_a) {
    var push_status = _a.push_status, message = _a.message;
    var t = useTranslation().t;
    var icon = _jsx(SuccessIcon, { "aria-hidden": "false", fontSize: "medium", color: "success", "aria-label": t('pages.config_templates.push.status_labels.success') });
    if (push_status === TemplatePushStatus.Pending) {
        return _jsx(Typography, { children: t('pages.config_templates.push.push_pending') });
    }
    if (push_status === TemplatePushStatus.Error) {
        icon = _jsx(ErrorIcon, { "aria-hidden": "false", fontSize: "medium", color: "error", "aria-label": t('pages.config_templates.push.status_labels.error') });
    }
    else if (push_status === TemplatePushStatus.Warning) {
        icon = _jsx(WarningIcon, { "aria-hidden": "false", fontSize: "medium", color: "warning", "aria-label": t('pages.config_templates.push.status_labels.warning') });
    }
    var formattedMessage = message.split(', ').map(function (m, i) { return (
    // eslint-disable-next-line react/no-array-index-key
    _jsx(Typography, { children: m }, i)); });
    return (_jsxs(_Fragment, { children: [icon, _jsx(Box, __assign({ sx: { marginLeft: 1 } }, { children: formattedMessage }))] }));
}
function TemplatePushTable(_a) {
    var nodeContexts = _a.nodeContexts, nodeData = _a.nodeData;
    var t = useTranslation().t;
    var rows = useMemo(function () { return generateTableData(nodeContexts, nodeData, t); }, [nodeContexts, nodeData, t]);
    var defaultSortModel = [{ field: 'push_status', sort: 'asc' }];
    var _b = __read(usePaginationModel('template_execution'), 3), paginationModel = _b[0], handlePaginationModelChange = _b[1], pageSizeOptions = _b[2];
    var columns = useMemo(function () { return [
        {
            field: 'connection_status',
            headerName: t('pages.nodes.table.headers.state'),
            maxWidth: 1,
            minWidth: 85,
            valueGetter: function (_a) {
                var row = _a.row;
                return row.runtime_status.connection_status;
            },
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(EnrolledStateCell, { runtime_status: row.runtime_status });
            },
        },
        { field: 'id', headerName: t('pages.nodes.table.headers.id'), minWidth: 110 },
        { field: 'name', headerName: t('pages.nodes.table.headers.name'), minWidth: 160 },
        {
            field: 'config_push_status',
            headerName: t('pages.nodes.table.headers.template'),
            sortable: false,
            renderCell: TemplatePopover,
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'push_status',
            headerName: t('pages.config_templates.push.push_status'),
            flex: 1,
            minWidth: 200,
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(PushStatusCell, { push_status: row.push_status, message: row.message });
            },
        },
    ]; }, [t]);
    return (_jsx(OGTable, { id: "template-execution-table", "aria-label": t('pages.config_templates.push.execution_label'), columns: columns, rows: rows, noRowsMessage: t('pages.config_templates.push.no_exec_nodes'), pageSizeOptions: pageSizeOptions, autoHeight: true, pagination: true, paginationMode: "client", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, initialState: {
            sorting: {
                sortModel: defaultSortModel,
            },
        } }));
}
// Reduce re-renders
export default memo(TemplatePushTable);
