var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { OGLink, OGLinkButton } from '@opengear/links';
import { OGTable } from '@opengear/table';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRoles } from '@/api/roles';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthorizationRequired } from '@/hocs/authorization-required';
import { formatRoleRoute, ROLES_NEW_ROUTE } from '@/constants/routes';
import { usePaginationModel } from '@/hooks/pagination-model';
import { formatDateString } from '@/utils/datetime';
function UnauthorizedMessage() {
    var t = useTranslation().t;
    return _jsx(Typography, { children: t('pages.groups_roles.tabs.roles.unauthorized_message') });
}
function RolesTable() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditAccess = hasPermission('settings.user_management.permissions.edit');
    // Client side table options
    var defaultSortModel = [{ field: 'last_updated', sort: 'asc' }];
    // Client side table controls
    var _a = __read(usePaginationModel('roles'), 3), paginationModel = _a[0], handlePaginationModelChange = _a[1], pageSizeOptions = _a[2];
    var _b = __read(useState({
        page: 1,
        per_page: 100000,
        filter: undefined,
    }), 2), queryParams = _b[0], setQueryParams = _b[1];
    var handleFilterChange = useCallback(function (newFilter) {
        setQueryParams(function (prevQueryParams) { return (__assign(__assign({}, prevQueryParams), { filter: newFilter })); });
    }, []);
    var debouncedChangeHandler = useMemo(function () { return debounce(handleFilterChange, 500); }, [handleFilterChange]);
    // Table data
    var _c = useRoles(queryParams), data = _c.data, isLoading = _c.isLoading;
    var loading = isLoading;
    var rows = useMemo(function () { return ({
        rows: (data === null || data === void 0 ? void 0 : data.roles) || [],
    }); }, [data]).rows;
    // Generate a unique key based on the rows data length and concatenated ids
    var tableKey = useMemo(function () { return rows.map(function (row) { return row.id; }).join(','); }, [rows]);
    var renderRoleNameCell = function (params) { return (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, variant: "standard", to: formatRoleRoute(params.row.id) }, { children: params.value }))); };
    var columns = [
        {
            field: 'rolename',
            headerName: t('pages.groups_roles.tabs.roles.table.headers.rolename'),
            renderCell: renderRoleNameCell,
            minWidth: 190,
            flex: 1,
        },
        { field: 'description', headerName: t('pages.groups_roles.tabs.roles.table.headers.description'), minWidth: 250, flex: 2 },
        { field: 'group_usage', headerName: t('pages.groups_roles.tabs.roles.table.headers.group_usage'), minWidth: 130 },
        { field: 'number_of_permissions', headerName: t('pages.groups_roles.tabs.roles.table.headers.number_of_permissions'), minWidth: 130 },
        {
            field: 'last_updated',
            headerName: t('pages.groups_roles.tabs.roles.table.headers.last_updated'),
            valueGetter: function (_a) {
                var value = _a.value;
                return (value === '--' ? '--' : formatDateString(value));
            },
            minWidth: 200,
        },
    ];
    // cleanup function
    useEffect(function () { return function () { return debouncedChangeHandler.cancel(); }; }, [debouncedChangeHandler]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", marginBottom: 2 }, { children: [_jsx(TextField, { fullWidth: true, placeholder: t('pages.groups_roles.tabs.roles.search_helper_text'), sx: { margin: '0 8px 0 0' }, onChange: function (e) { return debouncedChangeHandler(e.target.value); } }), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: hasEditAccess ? t('pages.groups_roles.tabs.roles.buttons.add_role') : '' }, { children: _jsx("div", { children: _jsx(OGLinkButton, { "aria-label": t('pages.groups_roles.tabs.roles.buttons.add_role'), disabled: !hasEditAccess, size: "small", startIcon: _jsx(AddCircleOutline, {}), to: ROLES_NEW_ROUTE, variant: "borderless" }) }) }))] })), _jsx(Box, { children: _jsx(OGTable, { id: "roles-table", "aria-label": t('pages.groups_roles.tabs.roles.table.label'), columns: columns, rows: rows, autoHeight: true, loading: loading, noRowsMessage: t('pages.groups_roles.tabs.roles.table.no_rows_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "client", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    } }, tableKey) })] }));
}
export default withAuthorizationRequired(RolesTable, { rights: ['settings.user_management.permissions.view'], FallbackComponent: UnauthorizedMessage });
