var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import { OGButton } from '@opengear/buttons';
import { transformFirmwareUpgradeFilterAutocompleteOption, transformFirmwareUpgradeFormData } from './firmware-status-period-filter-utils';
import { StatusField, PeriodField } from './firmware-status-period-filter-components';
export function FirmwareStatusPeriodFilterForm(_a) {
    var option = _a.option, setActiveOption = _a.setActiveOption, setFilterOptions = _a.setFilterOptions;
    var t = useTranslation().t;
    var _b = __read(useState(function () { return transformFirmwareUpgradeFilterAutocompleteOption(option); }), 2), formData = _b[0], setFormData = _b[1];
    // Simple derived value to disable submit button
    var isFormDataEmpty = useMemo(function () { return Object.values(formData).every(function (value) { return !value; }); }, [formData]);
    var nodeUpgradePeriodOptions = useMemo(function () { return ({
        one_week: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.select_options.one_week'),
        two_weeks: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.select_options.two_weeks'),
        one_month: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.select_options.one_month'),
        three_months: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.select_options.three_months'),
        six_months: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.select_options.six_months'),
    }); }, [t]);
    var nodeUpgradeStatusOptions = useMemo(function () { return ({
        completed: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.select_options.completed'),
        in_progress: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.select_options.in_progress'),
        scheduled: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.select_options.scheduled'),
        cancelled: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.select_options.cancelled'),
        completed_with_errors: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.select_options.completed_with_errors'),
    }); }, [t]);
    var handleSelectChange = function (event) {
        var _a = event.target, name = _a.name, value = _a.value;
        setFormData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSubmit = useCallback(function (event) {
        event.preventDefault();
        // Set filter data based on form data
        var newFilterOptions = transformFirmwareUpgradeFormData(formData);
        setFilterOptions(function (prevFilterOptions) { return (__assign(__assign({}, prevFilterOptions), newFilterOptions)); });
        // Close popover
        setActiveOption(undefined);
    }, [formData, setActiveOption, setFilterOptions]);
    return (_jsxs(Box, __assign({ component: "form", "aria-label": t('pages.firmware_upgrade.tabs.tasks.status_period_filter.placeholder'), padding: 1.5, onSubmit: handleSubmit, display: "flex", gap: 1, alignItems: "end" }, { children: [option.key === 'status' && (_jsx(StatusField, { formData: formData, handleSelectChange: handleSelectChange, nodeUpgradeStatusOptions: nodeUpgradeStatusOptions })), option.key === 'last_x_days' && (_jsx(PeriodField, { formData: formData, handleSelectChange: handleSelectChange, nodeUpgradePeriodOptions: nodeUpgradePeriodOptions })), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: t('global.buttons.apply') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('global.buttons.apply'), disabled: isFormDataEmpty, size: "small", startIcon: _jsx(CheckIcon, {}), type: "submit", variant: "borderless" }) }) }))] })));
}
