var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { OGPanel } from '@opengear/panel';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthorizationRequired } from '@/hocs/authorization-required';
import { NodeStatusDisconnectedTable } from './node-status-disconnected-table';
import { NodeStatusPendingTable } from './node-status-pending-table';
import { NodeStatusConnectedTable } from './node-status-connected-table';
import { useConnectionSummary } from '@/api/stats/nodes/connection-summary';
import { NodeConnectionStatus } from '@/utils/query';
export var NodeStatusTablePanel = withAuthorizationRequired(function () {
    var t = useTranslation().t;
    var _a = useUIPermissions(), hasPermission = _a.hasPermission, isPrimary = _a.isPrimary;
    var _b = useConnectionSummary(), data = _b.data, isLoading = _b.isLoading;
    var _c = __read(useState(NodeConnectionStatus.Disconnect), 2), tabSelection = _c[0], setTabSelection = _c[1];
    var disconnectedNodeTablePermissions = useMemo(function () { return ({
        editableNodeFilters: hasPermission('configure.edit_smart_groups.edit'),
    }); }, [hasPermission]);
    var pendingNodeTablePermissions = useMemo(function () { return ({
        editableNodeFilters: hasPermission('configure.edit_smart_groups.edit'),
        editableNodes: hasPermission('configure.node_enrollment.enrolled_nodes.enroll'),
        isPrimary: isPrimary,
    }); }, [hasPermission, isPrimary]);
    var connectedNodeTablePermissions = useMemo(function () { return ({
        canViewCellHealth: hasPermission('configure.node_enrollment.enrolled_nodes.view_cell_health'),
        canViewNode: hasPermission('manage.nodes.node_ui.view'),
        editableNodeFilters: hasPermission('configure.edit_smart_groups.edit'),
    }); }, [hasPermission]);
    var hasPendingNodesViewAccess = useMemo(function () { return hasPermission('configure.node_enrollment.pending_nodes.view'); }, [hasPermission]);
    var handleTabChange = useCallback(function (_, newTab) {
        setTabSelection(newTab);
    }, [setTabSelection]);
    var nodeCounts = useMemo(function () {
        if (!data || isLoading)
            return undefined;
        var counts = {
            connected: 0,
            disconnected: 0,
            pending: 0,
            rejected: 0,
        };
        data.connectionSummary.forEach(function (_a) {
            var status = _a.status, count = _a.count;
            counts[status] = count;
        });
        return counts;
    }, [data, isLoading]);
    return (_jsx(OGPanel, __assign({ sx: { padding: 0 } }, { children: _jsxs(TabContext, __assign({ value: tabSelection }, { children: [_jsxs(TabList, __assign({ "aria-label": t('pages.dashboard.nodes.table.title'), onChange: handleTabChange, variant: "scrollable" }, { children: [_jsx(Tab, { icon: _jsx(ErrorIcon, { fontSize: "medium", color: "error", sx: { marginRight: '4px !important' }, role: "none" }), label: _jsxs(Typography, __assign({ component: "span" }, { children: [t('pages.dashboard.nodes.node_connection.disconnected'), _jsxs(Typography, __assign({ component: "span", sx: { fontWeight: 600, marginLeft: 0.5 } }, { children: ["\u00A0", nodeCounts ? nodeCounts.disconnected : ''] }))] })), "aria-label": t('pages.dashboard.nodes.node_connection.tab_aria_label', {
                                status: t('pages.dashboard.nodes.node_connection.disconnected'),
                                nodecount: nodeCounts ? nodeCounts.disconnected : t('global.unknown'),
                            }), value: NodeConnectionStatus.Disconnect }), _jsx(Tab, { disabled: !hasPendingNodesViewAccess, icon: _jsx(PendingIcon, { fontSize: "medium", color: "info", sx: { marginRight: '4px !important' }, role: "none" }), label: _jsxs(Typography, __assign({ component: "span" }, { children: [t('pages.dashboard.nodes.node_connection.pending'), _jsxs(Typography, __assign({ component: "span", sx: { fontWeight: 600, marginLeft: 0.5 } }, { children: ["\u00A0", nodeCounts ? nodeCounts.pending : ''] }))] })), "aria-label": t('pages.dashboard.nodes.node_connection.tab_aria_label', {
                                status: t('pages.dashboard.nodes.node_connection.pending'),
                                nodecount: nodeCounts ? nodeCounts.pending : t('global.unknown'),
                            }), value: NodeConnectionStatus.Pending }), _jsx(Tab, { icon: _jsx(SuccessIcon, { fontSize: "medium", color: "success", sx: { marginRight: '4px !important' }, role: "none" }), label: _jsxs(Typography, __assign({ component: "span" }, { children: [t('pages.dashboard.nodes.node_connection.connected'), _jsxs(Typography, __assign({ component: "span", sx: { fontWeight: 600, marginLeft: 0.5 } }, { children: ["\u00A0", nodeCounts ? nodeCounts.connected : ''] }))] })), "aria-label": t('pages.dashboard.nodes.node_connection.tab_aria_label', {
                                status: t('pages.dashboard.nodes.node_connection.connected'),
                                nodecount: nodeCounts ? nodeCounts.connected : t('global.unknown'),
                            }), value: NodeConnectionStatus.Connected })] })), _jsx(TabPanel, __assign({ value: NodeConnectionStatus.Disconnect }, { children: _jsx(NodeStatusDisconnectedTable, { permissions: disconnectedNodeTablePermissions }) })), _jsx(TabPanel, __assign({ value: NodeConnectionStatus.Pending }, { children: _jsx(NodeStatusPendingTable, { permissions: pendingNodeTablePermissions }) })), _jsx(TabPanel, __assign({ value: NodeConnectionStatus.Connected }, { children: _jsx(NodeStatusConnectedTable, { permissions: connectedNodeTablePermissions }) }))] })) })));
}, { rights: ['configure.node_enrollment.enrolled_nodes.view'] });
