var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { ScriptForm } from '@/components/node-tools/config-templates/script-form';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { PAGE_TITLE_ID } from '@/constants/ids';
var defaultValues = {
    name: '',
    description: '',
    scriptTimeout: 15,
    script: null,
};
export default withAuthenticationRequired(function () {
    var t = useTranslation().t;
    var pageTitle = t('pages.config_templates.tabs.script.pages.create.title');
    useDocTitle(pageTitle);
    var hasPermission = useUIPermissions().hasPermission;
    var navigate = useNavigate();
    var hasEditPermission = hasPermission('configure.configuration_templating.script_templates.edit');
    useLayoutEffect(function () {
        if (!hasEditPermission) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate, hasEditPermission]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: pageTitle })), _jsx(ScriptForm, { permissions: { hasEditPermission: hasEditPermission }, data: defaultValues })] })));
});
