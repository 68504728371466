export var transformToFormDataObject = function (data) { return ({
    enabled: data.enabled,
    protocol: data.protocol,
    location: data.location || '',
    contact: data.contact || '',
    username: data.username || '',
    v1Enabled: data.v1_enabled,
    v3Enabled: data.v3_enabled,
    readWriteCommunity: data.read_only_community || '',
    readOnlyCommunity: data.read_write_community || '',
    engineId: data.engine_id || '',
    authProtocol: data.auth_protocol || 'MD5',
    authPassword: data.auth_password || '',
    confirmAuthPassword: '',
    privacyProtocol: data.privacy_protocol || 'DES',
    privacyPassword: data.privacy_password || '',
    confirmPrivacyPassword: '',
    securityLevel: data.security_level || 'noAuthNoPriv',
}); };
export var transformToRequestObject = function (data) { return ({
    enabled: data.enabled,
    protocol: data.protocol,
    location: data.location,
    contact: data.contact,
    username: data.username,
    v1_enabled: data.v1Enabled,
    v3_enabled: data.v3Enabled,
    read_write_community: data.readOnlyCommunity,
    read_only_community: data.readWriteCommunity,
    engine_id: data.engineId || null,
    auth_protocol: data.authProtocol,
    auth_password: data.authPassword || null,
    privacy_protocol: data.privacyProtocol,
    privacy_password: data.privacyPassword || null,
    security_level: data.securityLevel,
}); };
