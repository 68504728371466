var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NEW_FILTER_ID } from '@/utils/filter';
import { decodeFilterQuery, decodeQuery } from '../../../../../types/query';
/**
 * Type guard to check if the filter is a valid node filter
 * TODO: CDM-1147 - Replace common decodeFilterQuery with separate validator function based on query schema
 */
export function isNodeQueryValid(filter) {
    return !!decodeFilterQuery(filter.queryObject);
}
/**
 * Check if node filter is an ad hoc filter
 */
export function isNodeFilterNew(filter) {
    return filter.id === NEW_FILTER_ID;
}
/**
 * Check if node filters match
 */
export function checkNodeFiltersMatch(filterA, filterB) {
    return filterA.id === filterB.id;
}
/**
 * Evaluate if the selected filter is applied
 */
export function checkSelectedFilterApplied(selectedFilter, filter) {
    return !!selectedFilter && !!filter && checkNodeFiltersMatch(selectedFilter, filter) && !isNodeFilterNew(filter);
}
/**
 * Evaluate if user can apply the selected filter
 */
export function canApplySelectedFilter(selectedFilter, filter) {
    // User cannot apply an empty filter if is no applied filter
    if (!selectedFilter && !filter)
        return false;
    // User cannot apply selected filter if it matches the applied filter
    if (checkSelectedFilterApplied(selectedFilter, filter))
        return false;
    // User cannot apply a new filter if its invalid. All other invalid filters can be applied.
    if (!!selectedFilter && isNodeFilterNew(selectedFilter) && !isNodeQueryValid(selectedFilter))
        return false;
    // All other cases, the user can apply the filter
    return true;
}
/**
 * Parse and validate the json search params from the URL
 */
export function parseNodeFilterSearchParam(jsonString) {
    if (jsonString === null)
        return null;
    try {
        var filter = JSON.parse(jsonString);
        // Light validation to ensure the filter is a valid node filter
        if (!filter || typeof filter !== 'object' || !('queryObject' in filter) || !decodeQuery(filter.queryObject))
            return null;
        return filter;
    }
    catch (err) {
        /* istanbul ignore next */
        return null;
    }
}
/**
 * Format node filter for search params
 */
export function formatNodeFilterSearchParam(filter) {
    try {
        return JSON.stringify(filter);
    }
    catch (err) {
        /* istanbul ignore next */
        return '';
    }
}
/**
 * Verify node filter from smartgroups
 * This is to prevent the filter from being out of "sync" if the filter is deleted or changed.
 */
export function verifyNodeFilter(filter, smartgroups) {
    // Leave filter as is, when filter is new or smartgroups are undefined
    if (!filter || isNodeFilterNew(filter) || !smartgroups)
        return filter;
    var foundFilter = smartgroups.find(function (smartgroup) { return checkNodeFiltersMatch(filter, smartgroup); });
    return !foundFilter ? null : foundFilter;
}
/**
 * Verify selected node filter from smartgroups
 * This is to prevent the filter from being out of "sync" if the filter is deleted or changed.
 */
export function verifySelectedNodeFilter(filter, smartgroups) {
    var _a;
    // Leave filter as is, when filter is new or smartgroups are undefined
    if (!filter || isNodeFilterNew(filter) || !smartgroups)
        return filter;
    return (_a = smartgroups.find(function (smartgroup) { return checkNodeFiltersMatch(filter, smartgroup); })) !== null && _a !== void 0 ? _a : null;
}
/**
 * Type guard to assert queryObject is a query item
 */
function isQueryItem(queryObject) {
    return 'fieldname' in queryObject;
}
/**
 * Prepends prefix to fieldname value and returns a new object
 */
function prependPrefixToQueryItem(prefix, queryObjectItem) {
    return __assign(__assign({}, queryObjectItem), { fieldname: "".concat(prefix, ":").concat(queryObjectItem.fieldname) });
}
/**
 * Recursively prepends the prefix to all query items in a query object
 */
function recursivelyPrependPrefixToQueryObject(prefix, queryObject) {
    if (isQueryItem(queryObject)) {
        return prependPrefixToQueryItem(prefix, queryObject);
    }
    return queryObject.items.reduce(function (resultQueryObject, currentQueryObject) {
        resultQueryObject.items.push(recursivelyPrependPrefixToQueryObject(prefix, currentQueryObject));
        return resultQueryObject;
    }, { items: [], type: queryObject.type });
}
/**
 * Applies prefix to each item in a filter query
 * Mutates the query object passed into the function
 */
export function applyPrefixToQueryObject(prefix, queryObject) {
    if (!prefix || !queryObject)
        return undefined;
    return recursivelyPrependPrefixToQueryObject(prefix, queryObject);
}
