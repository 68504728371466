var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LIGHTHOUSE_API_VERSION, SessionStatus, useLighthouseToken } from './utils';
import { useAlertNotification } from '@/hooks/alert-notification';
import { formatErrorMessage } from '@/utils/errors';
import { decodeCreateSessionResponse } from '../../types/lighthouse/decoders/sessions';
import { decodeDeleteSessionResponse, decodeUpdateSessionResponse } from '../../types/lighthouse/decoders/sessions/self';
import { LOCAL_TERMINAL_ROUTE } from '@/constants/routes';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var CONFIRM_PASSWORD_PATH = '/sessions/self/confirm_password';
/**
 * List of urls that should retain the users session (keep them logged in) if their session has expired
 */
export var RETAIN_SESSION_URLS = [LOCAL_TERMINAL_ROUTE];
/**
 * Sign in
 */
export function useCreateSession(alertCloseRef) {
    var _this = this;
    var _a = useAlertNotification(alertCloseRef), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var _b = useLighthouseToken(), token = _b.token, setToken = _b.setToken;
    var t = useTranslation().t;
    return useCallback(function (credentials) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    closeRef.current();
                    return [4 /*yield*/, fetch("/api/".concat(LIGHTHOUSE_API_VERSION, "/sessions"), {
                            body: JSON.stringify(credentials),
                            method: 'POST',
                            headers: {
                                'Accept': "application/json",
                                'Authorization': "Token ".concat(token),
                                'Content-Type': "application/json",
                            },
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeCreateSessionResponse(response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    switch (status) {
                        case 200:
                            switch (data.state) {
                                case 'authenticated':
                                    setToken(data.session);
                                    return [2 /*return*/, { sessionStatus: data.password_expired ? SessionStatus.Expired : SessionStatus.Valid }];
                                case 'challenge-in-progress':
                                    return [2 /*return*/, { sessionStatus: SessionStatus.Challenge, data: data }];
                                case 'failed-in-lockout':
                                    alertNotification(formatErrorMessage(t('api.sessions.post.lock_out.message')), { header: t('api.sessions.post.lock_out.header') });
                                    return [2 /*return*/, { sessionStatus: SessionStatus.Valid }];
                                default:
                                    return [2 /*return*/, { sessionStatus: SessionStatus.Failed }];
                            }
                        case 400:
                            return [2 /*return*/, { sessionStatus: SessionStatus.Failed }];
                        default:
                            alertNotification(formatErrorMessage(data), {
                                header: t('api.sessions.post.error.message'),
                            });
                            return [2 /*return*/, { sessionStatus: SessionStatus.Error }];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _b.sent();
                    console.error(err_1);
                    alertNotification(formatErrorMessage(err_1), {
                        header: t('api.sessions.post.error.message'),
                    });
                    return [2 /*return*/, { sessionStatus: SessionStatus.Error }];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [token, setToken, alertNotification, closeRef, t]);
}
/**
 * Challenge request for 2FA
 */
export function useUpdateSession(alertCloseRef) {
    var _this = this;
    var _a = useAlertNotification(alertCloseRef), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var setToken = useLighthouseToken().setToken;
    var t = useTranslation().t;
    return useCallback(function (_a) {
        var request = _a.request, challengeToken = _a.challengeToken;
        return __awaiter(_this, void 0, void 0, function () {
            var response, _b, data, status, err_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        closeRef.current();
                        return [4 /*yield*/, fetch("/api/".concat(LIGHTHOUSE_API_VERSION, "/sessions/self"), {
                                body: JSON.stringify(request),
                                method: 'PUT',
                                headers: {
                                    'Accept': "application/json",
                                    'Authorization': "Token ".concat(challengeToken),
                                    'Content-Type': "application/json",
                                },
                            })];
                    case 1:
                        response = _c.sent();
                        return [4 /*yield*/, decodeUpdateSessionResponse(response)];
                    case 2:
                        _b = _c.sent(), data = _b.data, status = _b.status;
                        switch (status) {
                            case 200:
                                switch (data.state) {
                                    case 'authenticated':
                                        setToken(data.session);
                                        return [2 /*return*/, SessionStatus.Valid];
                                    default:
                                        alertNotification(formatErrorMessage(data), {
                                            header: t('api.sessions.put.error.message'),
                                            persistOnUnmount: true,
                                        });
                                        return [2 /*return*/, SessionStatus.Failed];
                                }
                            case 403:
                                alertNotification(t('pages.login.messages.challenge_error'), { persistOnUnmount: true });
                                return [2 /*return*/, SessionStatus.Unauthorized];
                            default:
                                alertNotification(formatErrorMessage(data), {
                                    header: t('api.sessions.put.error.message'),
                                    persistOnUnmount: true,
                                });
                                return [2 /*return*/, SessionStatus.Error];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _c.sent();
                        console.error(err_2);
                        alertNotification(formatErrorMessage(err_2), {
                            header: t('api.sessions.put.error.message'),
                            persistOnUnmount: true,
                        });
                        return [2 /*return*/, SessionStatus.Error];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [closeRef, setToken, alertNotification, t]);
}
/**
 * Sign out
 */
export function useDeleteSession() {
    var _this = this;
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var _b = useLighthouseToken(), token = _b.token, removeTokenAndClearQueryCache = _b.removeTokenAndClearQueryCache;
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    return useCallback(function (status) { return __awaiter(_this, void 0, void 0, function () {
        var response, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!token || (RETAIN_SESSION_URLS.includes(pathname) && status))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    closeRef.current();
                    return [4 /*yield*/, fetch("/api/".concat(LIGHTHOUSE_API_VERSION, "/sessions/self"), {
                            method: 'DELETE',
                            headers: {
                                'Accept': "application/json",
                                'Authorization': "Token ".concat(token),
                                'Content-Type': "application/json",
                            },
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, decodeDeleteSessionResponse(response)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4:
                    err_3 = _a.sent();
                    console.error(err_3);
                    alertNotification(formatErrorMessage(err_3), {
                        header: t('api.sessions.delete.error.message'),
                    });
                    return [3 /*break*/, 6];
                case 5:
                    removeTokenAndClearQueryCache();
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [token, pathname, closeRef, alertNotification, t, removeTokenAndClearQueryCache]);
}
export function usePasswordValidation() {
    var _this = this;
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var executeFetch = useLighthouseFetch().executeFetch;
    var t = useTranslation().t;
    return useCallback(function (credentials) { return __awaiter(_this, void 0, void 0, function () {
        var response, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    closeRef.current();
                    return [4 /*yield*/, executeFetch(CONFIRM_PASSWORD_PATH, { method: 'PUT', body: JSON.stringify({ password: credentials.password }) })];
                case 1:
                    response = _a.sent();
                    switch (response.status) {
                        case 304:
                        case 422:
                            // 304 is the success case
                            // 422 is the 'can not validate' case.  Returned
                            // in scenarios where it's not possible to
                            // determine if the username or password is valid
                            // (e.g. remote auth)
                            return [2 /*return*/, true];
                        case 403:
                            return [2 /*return*/, false];
                        default:
                            alertNotification(t('api.password_validation.post.error.message'));
                            return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_4 = _a.sent();
                    console.error(err_4);
                    alertNotification(formatErrorMessage(err_4), {
                        header: t('api.password_validation.post.error.message'),
                    });
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [closeRef, executeFetch, alertNotification, t]);
}
