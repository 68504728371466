var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { OGButton } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DialogTitle from '@mui/material/DialogTitle';
import { OGDialog } from '@opengear/modals';
import { useQueryParameterOpen } from '@/hooks/open';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { NodeEnrollmentForm } from './node-enrollment-form';
import { useDeviceView } from '@/hooks/use-device-view';
import { QUERY_PARAM_MODAL_NAME_KEY } from '@/constants/query-params-keys';
import { useEntitlementsData } from '@/api/system/entitlements';
import { useDefaultSubscription } from '@/api/system/default-subscription';
export var ENROLL_NODE_BUTTON_TEST_ID = 'enroll-node-button';
export var ENROLL_NODE_MODAL_VALUE = 'node-enrollment';
export var NODE_ENROLLMENT_LOADER_ID = 'node-enrollment-loading';
export function NodeEnrollmentModal() {
    var _a = useQueryParameterOpen(QUERY_PARAM_MODAL_NAME_KEY, ENROLL_NODE_MODAL_VALUE), open = _a.open, handleClose = _a.handleClose, handleOpen = _a.handleOpen;
    var hasPermission = useUIPermissions().hasPermission;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = useDefaultSubscription(), defaultSubscriptionData = _b.data, isLoadingDefaultSubscription = _b.isLoading;
    var _c = useEntitlementsData(), isEntitlementsLoading = _c.isLoading, lighthouseSubscriptions = _c.lighthouseSubscriptions, thirdPartySupported = _c.thirdPartySupported;
    var labelText = isMobileView ? null : t('components.appbar.buttons.enroll_node');
    return (_jsxs(_Fragment, { children: [_jsx(OGButton, __assign({ variant: "borderless", disabled: !hasPermission('configure.node_enrollment.enrolled_nodes.enroll') || isLoadingDefaultSubscription || isEntitlementsLoading, startIcon: _jsx(AddCircleOutline, {}), onClick: handleOpen, "data-testid": ENROLL_NODE_BUTTON_TEST_ID, "aria-label": t('components.appbar.buttons.enroll_node') }, { children: labelText })), _jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: t('components.enroll_node_modal.title') }), _jsx(NodeEnrollmentForm, { onClose: handleClose, defaultSubscriptionData: defaultSubscriptionData, subscriptions: lighthouseSubscriptions, thirdPartySupported: thirdPartySupported })] }))] }));
}
