var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { OGDialog } from '@opengear/modals';
import Stack from '@mui/material/Stack';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { NODE_TAGS_ROUTE_KEY, PORT_RESOURCE_TAGS_ROUTE_KEY, TAGS_ROUTE } from '@/constants/routes';
import { pathEndsWith } from '@/utils/navigation';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { NewTagMenu } from '@/containers/tags/new-tag-menu/new-tag-menu';
import { NodeTagsTable } from '@/containers/tags/node-tags/node-tags-table';
import { ResourceTagsTable } from '@/containers/tags/resource-tags/resource-tags-table';
import { NewNodeTagModalContent, NEW_NODE_TAG_MODAL_TITLE_ID } from '@/containers/tags/node-tags/node-tag-modal';
import { NewResourceTagModalContent, NEW_RESOURCE_TAG_MODAL_TITLE_ID } from '@/containers/tags/resource-tags/resource-tags-modal';
import { useQueryParameterOpen } from '@/hooks/open';
function Tags() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var hasPermission = useUIPermissions().hasPermission;
    useDocTitle(t('pages.tags.title'));
    var permissions = useMemo(function () { return ({
        hasTagEditAccess: hasPermission('configure.edit_tags.edit'),
        hasCRGEditAccess: hasPermission('manage.resources.edit'),
        hasCRGViewAccess: hasPermission('manage.resources.view'),
        hasNodeEnrollmentAccess: hasPermission('configure.node_enrollment.enrolled_nodes.enroll'),
        hasNodeEnrollViewAccess: hasPermission('configure.node_enrollment.enrolled_nodes.view'),
    }); }, [hasPermission]);
    var _a = useQueryParameterOpen('modal', 'new-node-tag-modal', permissions.hasTagEditAccess), openNodeTagModal = _a.open, handleNodeTagClose = _a.handleClose;
    var _b = useQueryParameterOpen('modal', 'new-resource-tag-modal', permissions.hasTagEditAccess), openResourceTagModal = _b.open, handleResourceTagClose = _b.handleClose;
    var tabValue = useMemo(function () { return (pathEndsWith(pathname, PORT_RESOURCE_TAGS_ROUTE_KEY) ? PORT_RESOURCE_TAGS_ROUTE_KEY : NODE_TAGS_ROUTE_KEY); }, [pathname]);
    useEffect(function () {
        if (pathEndsWith(pathname, TAGS_ROUTE)) {
            navigate(NODE_TAGS_ROUTE_KEY, { replace: true });
        }
    }, [navigate, pathname]);
    return (_jsxs(_Fragment, { children: [_jsx(OGDialog, __assign({ "aria-labelledby": NEW_NODE_TAG_MODAL_TITLE_ID, open: openNodeTagModal && permissions.hasTagEditAccess, onClose: handleNodeTagClose }, { children: _jsx(NewNodeTagModalContent, { onClose: handleNodeTagClose, permissions: permissions }) })), _jsx(OGDialog, __assign({ "aria-labelledby": NEW_RESOURCE_TAG_MODAL_TITLE_ID, open: openResourceTagModal && permissions.hasTagEditAccess, onClose: handleResourceTagClose }, { children: _jsx(NewResourceTagModalContent, { onClose: handleResourceTagClose, permissions: permissions }) })), _jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.tags.title') })), _jsx(NewTagMenu, { disabled: !permissions.hasTagEditAccess })] })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: NODE_TAGS_ROUTE_KEY, label: t('pages.tags.tabs.node_tags.title'), value: NODE_TAGS_ROUTE_KEY }), _jsx(Tab, { component: RouterLink, to: PORT_RESOURCE_TAGS_ROUTE_KEY, label: t('pages.tags.tabs.resource_tags.title'), value: PORT_RESOURCE_TAGS_ROUTE_KEY })] })), _jsx(TabPanel, __assign({ value: NODE_TAGS_ROUTE_KEY }, { children: _jsx(NodeTagsTable, { permissions: permissions }) })), _jsx(TabPanel, __assign({ value: PORT_RESOURCE_TAGS_ROUTE_KEY }, { children: _jsx(ResourceTagsTable, { permissions: permissions }) }))] })) })] }))] }));
}
export default withAuthenticationRequired(Tags, { rights: ['configure.edit_tags.view'] });
