var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Divider from '@mui/material/Divider';
import { DisabledTextField } from '../../inputs/disabled-text-field';
import { useFormatPermission } from '../utils';
import { PermissionsTable } from './permissions-table';
import { useDeviceView } from '@/hooks/use-device-view';
export var PERMISSIONS_PREVIEW_MODAL_TEST_ID = 'permissions-preview-loading';
export default function PermissionsPreview(_a) {
    var name = _a.name, permissions = _a.permissions;
    var t = useTranslation().t;
    var formatPermission = useFormatPermission();
    var isMobileView = useDeviceView().isMobileView;
    var innerNode = null;
    if (permissions) {
        innerNode = (_jsxs(Stack, __assign({ "aria-label": t('components.role.form.label'), component: "form", width: "100%", "data-testid": PERMISSIONS_PREVIEW_MODAL_TEST_ID }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('components.permissions.form.title') })), _jsxs(Stack, __assign({ gap: isMobileView ? 0 : 4, direction: isMobileView ? 'column' : 'row' }, { children: [_jsx(DisabledTextField, { id: "preview-console-shell", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.console_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.console_shell.tooltip') })] }), value: formatPermission(permissions.cli.console_shell[name]) }), permissions.cli.shell_access && (_jsx(DisabledTextField, { id: "preview-shell-access", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.shell_access.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.shell_access.tooltip') })] }), value: formatPermission(permissions.cli.shell_access[name]) })), _jsx(DisabledTextField, { id: "preview-pm-shell", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.pm_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.pm_shell.tooltip') })] }), value: formatPermission(permissions.cli.pm_shell[name]) })] })), _jsx(Divider, { sx: { marginX: -2, marginBottom: 2 } }), _jsx(PermissionsTable, { permissions: permissions, roleName: name })] })));
    }
    return _jsx(Box, __assign({ sx: { paddingRight: 2 } }, { children: innerNode }));
}
