var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WarningIcon from '@mui/icons-material/WarningAmber';
import StopIcon from '@mui/icons-material/DoNotDisturbAlt';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
import DialogContentText from '@mui/material/DialogContentText';
import { useDeleteNodeUpgrade } from '@/api/system/node-upgrade';
import { formatDateTimestamp } from '@/utils/datetime';
import { DATETIME_FORMAT } from '@/constants/formats';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
export function UpgradeNodeStatusIcon(_a) {
    var status = _a.status, finishTime = _a.finishTime;
    var t = useTranslation().t;
    var icon = null;
    var label = '';
    var date = useMemo(function () { return (finishTime ? formatDateTimestamp(finishTime, DATETIME_FORMAT) : null); }, [finishTime]);
    if (status === 'CANCELLED') {
        label = t('global.upgrade_node_status.cancelled');
        icon = _jsx(CancelOutlinedIcon, { fontSize: "medium", titleAccess: label, "aria-label": label });
    }
    if (status === 'IN_PROGRESS') {
        icon = _jsx(OGAutoRenewIcon, { label: t('global.upgrade_node_status.in_progress') });
    }
    if (status === 'SUCCESS') {
        label = t('global.upgrade_node_status.success');
        icon = _jsx(CheckOutlinedIcon, { fontSize: "medium", color: "success", titleAccess: label, "aria-label": label });
    }
    if (status === 'FAILURE') {
        label = t('global.upgrade_node_status.failed');
        icon = _jsx(WarningIcon, { fontSize: "medium", color: "error", titleAccess: label, "aria-label": label });
    }
    if (status === 'NOT_STARTED') {
        label = t('global.upgrade_node_status.not_started');
        icon = _jsx(AccessTimeIcon, { fontSize: "medium", color: "warning", titleAccess: label, "aria-label": label });
    }
    if (status === 'SKIPPED') {
        label = t('global.upgrade_node_status.skipped');
        icon = _jsx(StopIcon, { fontSize: "medium", color: "warning", titleAccess: label, "aria-label": label });
    }
    if (date && icon) {
        return (_jsx(Tooltip, __assign({ "aria-label": format(t('pages.node_upgrade.nodes_table.status_tooltip_label'), t('pages.node_upgrade.nodes_table.headers.status'), label, date), title: date, placement: "top", arrow: true }, { children: _jsx(IconButton, __assign({ sx: { padding: 0, marginLeft: 0.5, height: 'max-content' } }, { children: icon })) })));
    }
    return icon;
}
export function UpgradeNodeInFailoverCell(_a) {
    var checked = _a.checked;
    var t = useTranslation().t;
    var label = checked
        ? t('pages.firmware_upgrade_new.tables.selected_nodes.upgrade_in_failover.options.allow')
        : t('pages.firmware_upgrade_new.tables.selected_nodes.upgrade_in_failover.options.disallow');
    return (_jsx(FormControlLabel, { "aria-label": "".concat(t('pages.node_upgrade.nodes_table.headers.upgrade_in_failover'), ": ").concat(label), control: _jsx(Switch, { disabled: true, checked: checked, tabIndex: -1 }), label: label }));
}
export function CancelTask(_a) {
    var disabled = _a.disabled, taskId = _a.taskId;
    var t = useTranslation().t;
    var deleteUpgradeTask = useDeleteNodeUpgrade();
    var cancelHandler = useCallback(function () { return deleteUpgradeTask(taskId); }, [deleteUpgradeTask, taskId]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.node_upgrade.actions.cancel.title'), deleteButtonLabel: t('pages.node_upgrade.actions.cancel.label'), deleteButtonIcon: _jsx(CancelOutlinedIcon, {}), onDelete: cancelHandler, confirmLabel: t('pages.node_upgrade.actions.cancel.label'), disabled: disabled }, { children: _jsx(DialogContentText, { children: t('pages.node_upgrade.actions.cancel.confirmation') }) })));
}
