var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OGFileUploader, OGFileTracker } from '@opengear/file-uploader';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useCallback } from 'react';
import format from 'string-format';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { OGDialog } from '@opengear/modals';
import Divider from '@mui/material/Divider';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { requiredFieldError } from '@/utils/field-validation-errors';
import { ConfirmationModal } from '@/components/confirmation-modal';
export function BackupUploadField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, watch = _a.watch;
    var backupFile = watch('file');
    return (_jsx(Controller, { control: control, name: "file", rules: {
            required: requiredFieldError(t('pages.backup_restore_reset.tabs.config_restore.form.file.label')),
        }, render: function (_a) {
            var field = _a.field;
            return (_jsx(OGFileUploader, __assign({ id: "backup-file", accept: ".lh_bak", ariaLabel: t('pages.backup_restore_reset.tabs.config_restore.form.file.label'), onChange: function (files) {
                    field.onChange(files[0]);
                }, disabled: backupFile !== null || !permissions.hasEditPermission, required: true }, { children: backupFile && _jsx(OGFileTracker, { id: backupFile.name, name: backupFile.name, onCancel: function () { return field.onChange(null); } }, backupFile.name) })));
        } }));
}
export function ConfigRestorePasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "password", render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ id: "password", type: "password", label: t('pages.backup_restore_reset.tabs.config_restore.form.password.label'), fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 300 } }, field, { disabled: !permissions.hasEditPermission })));
        } }));
}
export function ConfigRestoreConfirmModal(_a) {
    var backupDetails = _a.backupDetails, open = _a.open, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var handleClose = useCallback(function () {
        onClose();
    }, [onClose]);
    var handleSubmit = useCallback(function () {
        onSubmit(backupDetails);
    }, [backupDetails, onSubmit]);
    return (_jsx(ConfirmationModal, __assign({ title: t('pages.backup_restore_reset.tabs.config_restore.modals.confirmation_modal.title'), confirmLabel: t('pages.backup_restore_reset.tabs.config_restore.modals.confirmation_modal.actions.yes'), onClose: handleClose, onConfirm: handleSubmit, open: open }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' } }, { children: [_jsx(Typography, __assign({ marginBottom: 2 }, { children: format(t('pages.backup_restore_reset.tabs.config_restore.modals.confirmation_modal.content.version_label'), backupDetails.system_upload_restore.version) })), _jsx(Typography, __assign({ marginBottom: 2 }, { children: t('pages.backup_restore_reset.tabs.config_restore.modals.confirmation_modal.content.warning_label') })), _jsx(Typography, { children: t('pages.backup_restore_reset.tabs.config_restore.modals.confirmation_modal.content.confirmation') })] })) })));
}
export function RestoringElement(_a) {
    var status = _a.status;
    var t = useTranslation().t;
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }, { children: [status === 'restoring' ? (_jsx(CircularProgress, { "aria-describedby": "restoring-status", "data-testid": "restoring-icon", color: "inherit", size: 24 })) : (_jsx(CheckCircle, { "aria-describedby": "restoring-status", "data-testid": "restored-icon", color: "success" })), _jsx(Typography, __assign({ id: "restoring-status" }, { children: t('pages.backup_restore_reset.tabs.config_restore.modals.status_modal.status.restoring') }))] })));
}
export function RestartingElement(_a) {
    var status = _a.status;
    var t = useTranslation().t;
    if (status === 'restoring')
        return null;
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }, { children: [status === 'restarting' ? (_jsx(CircularProgress, { "aria-describedby": "restarting-status", "data-testid": "restarting-icon", color: "inherit", size: 24 })) : (_jsx(CheckCircle, { "aria-describedby": "restarting-status", "data-testid": "restarted-icon", color: "success" })), _jsx(Typography, __assign({ id: "restarting-status" }, { children: t('pages.backup_restore_reset.tabs.config_restore.modals.status_modal.status.restarting') }))] })));
}
export function CompleteElement(_a) {
    var status = _a.status;
    var t = useTranslation().t;
    if (status !== 'complete')
        return null;
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }, { children: [_jsx(CheckCircle, { "aria-describedby": "complete-status", "data-testid": "complete-icon", color: "success" }), _jsx(Typography, __assign({ id: "complete-status" }, { children: t('pages.backup_restore_reset.tabs.config_restore.modals.status_modal.status.complete') }))] })));
}
export function RestoreStatus(_a) {
    var status = _a.status;
    var t = useTranslation().t;
    return (_jsxs(OGDialog, __assign({ id: "restore-status", open: true, "aria-describedby": "status-message" }, { children: [_jsx(DialogTitle, __assign({ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }, { children: t('pages.backup_restore_reset.tabs.config_restore.modals.status_modal.title') })), _jsx(Divider, {}), _jsx(DialogContent, __assign({ id: "status-message", sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' }, "aria-live": "polite", "aria-atomic": true, tabIndex: 0 }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "left", flexDirection: "column", gap: 2 }, { children: [_jsx(RestoringElement, { status: status }), _jsx(RestartingElement, { status: status }), _jsx(CompleteElement, { status: status })] })) }))] })));
}
