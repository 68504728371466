import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useOpen } from '@/hooks/open';
import { DeleteFilterButton, EditFilterButton, SaveFilterButton } from '../filter-components';
import { PortFilterDeleteModal, PortFilterEditModal, PortFilterSaveModal } from '../../port-filters/port-filter-modals';
import { usePortFilterContext } from './port-filter-context';
import { isPortFilterNew, isPortQueryValid } from './port-filter-utils';
function PortFilterSaveAction(_a) {
    var handlePopoverClose = _a.handlePopoverClose;
    var t = useTranslation().t;
    var _b = useOpen(), open = _b.open, handleClose = _b.handleClose, handleOpen = _b.handleOpen;
    var _c = usePortFilterContext(), selectedFilter = _c.selectedFilter, setFilter = _c.setFilter;
    var handleSubmit = useCallback(function (smartgroup) {
        // Only apply filter if returned smartgroup is valid
        if (isPortQueryValid(smartgroup)) {
            setFilter(smartgroup);
        }
        // Always close modal and popover
        handleClose();
        handlePopoverClose();
    }, [setFilter, handleClose, handlePopoverClose]);
    return (_jsxs(_Fragment, { children: [_jsx(PortFilterSaveModal, { open: open, selectedFilter: !!selectedFilter && isPortFilterNew(selectedFilter) ? selectedFilter : undefined, onClose: handleClose, onSubmit: handleSubmit, translations: {
                    dialogTitle: t('components.ports.filter.save_modal.title'),
                    saveBtn: t('global.buttons.save_and_apply'),
                } }), _jsx(SaveFilterButton, { label: t('components.ports.filter.button.save'), onClick: handleOpen })] }));
}
function PortFilterEditAction(_a) {
    var handlePopoverClose = _a.handlePopoverClose;
    var t = useTranslation().t;
    var _b = useOpen(), open = _b.open, handleClose = _b.handleClose, handleOpen = _b.handleOpen;
    var _c = useOpen(), deleteOpen = _c.open, handleDeleteClose = _c.handleClose, handleDeleteOpen = _c.handleOpen;
    var _d = usePortFilterContext(), isSelectedFilterApplied = _d.isSelectedFilterApplied, selectedFilter = _d.selectedFilter, dispatchSelectedFilter = _d.dispatchSelectedFilter, setFilter = _d.setFilter;
    var handleDelete = useCallback(function () {
        // Only clear applied filter and close popover if applied filter is being deleted
        if (isSelectedFilterApplied) {
            setFilter(null);
            handlePopoverClose();
        }
        // Always clear selected filter
        dispatchSelectedFilter({ type: 'clear' });
        // Always close delete confirm and edit modal
        handleDeleteClose();
        handleClose();
    }, [setFilter, dispatchSelectedFilter, handleClose, handleDeleteClose, handlePopoverClose, isSelectedFilterApplied]);
    var handleSubmit = useCallback(function (smartgroup) {
        // Only apply filter if returned smartgroup is valid
        if (isPortQueryValid(smartgroup)) {
            setFilter(smartgroup);
        }
        // Always close edit modal and popover
        handleClose();
        handlePopoverClose();
    }, [setFilter, handleClose, handlePopoverClose]);
    return (_jsxs(_Fragment, { children: [selectedFilter && _jsx(PortFilterDeleteModal, { open: deleteOpen, onClose: handleDeleteClose, onDelete: handleDelete, selectedFilter: selectedFilter }), _jsx(PortFilterEditModal, { open: open, selectedFilter: !!selectedFilter && isPortQueryValid(selectedFilter) ? selectedFilter : undefined, onClose: handleClose, onDelete: handleDeleteOpen, onSubmit: handleSubmit, translations: {
                    dialogTitle: t('components.ports.filter.edit_modal.title'),
                    saveBtn: t('global.buttons.save_and_apply'),
                } }), _jsx(EditFilterButton, { label: t('components.ports.filter.button.edit'), onClick: handleOpen })] }));
}
function PortFilterDeleteAction() {
    var t = useTranslation().t;
    var _a = useOpen(), open = _a.open, handleClose = _a.handleClose, handleOpen = _a.handleOpen;
    var _b = usePortFilterContext(), selectedFilter = _b.selectedFilter, dispatchSelectedFilter = _b.dispatchSelectedFilter;
    var handleDelete = useCallback(function () {
        // Always clear selected filter
        dispatchSelectedFilter({ type: 'clear' });
        // Always close delete confirm
        handleClose();
    }, [dispatchSelectedFilter, handleClose]);
    return (_jsxs(_Fragment, { children: [selectedFilter && _jsx(PortFilterDeleteModal, { open: open, onClose: handleClose, onDelete: handleDelete, selectedFilter: selectedFilter }), _jsx(DeleteFilterButton, { label: t('components.ports.filter.button.delete'), onClick: handleOpen })] }));
}
export function PortFilterActions(_a) {
    var handlePopoverClose = _a.handlePopoverClose;
    var selectedFilter = usePortFilterContext().selectedFilter;
    if (!selectedFilter)
        return null;
    if (isPortFilterNew(selectedFilter)) {
        return _jsx(PortFilterSaveAction, { handlePopoverClose: handlePopoverClose });
    }
    if (isPortQueryValid(selectedFilter)) {
        return _jsx(PortFilterEditAction, { handlePopoverClose: handlePopoverClose });
    }
    return _jsx(PortFilterDeleteAction, {});
}
