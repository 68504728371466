export var transformToResponseObject = function (data, isCellHealthEnabled) { return ({
    enabled: (data === null || data === void 0 ? void 0 : data.enabled) || false,
    protocol: (data === null || data === void 0 ? void 0 : data.protocol) || 'UDP',
    community: (data === null || data === void 0 ? void 0 : data.community) || '',
    username: (data === null || data === void 0 ? void 0 : data.username) || '',
    version: (data === null || data === void 0 ? void 0 : data.version) || 'v1',
    engine_id: (data === null || data === void 0 ? void 0 : data.engine_id) || '',
    auth_protocol: (data === null || data === void 0 ? void 0 : data.auth_protocol) || 'MD5',
    auth_password: (data === null || data === void 0 ? void 0 : data.auth_password) || '',
    privacy_protocol: (data === null || data === void 0 ? void 0 : data.privacy_protocol) || 'DES',
    privacy_password: (data === null || data === void 0 ? void 0 : data.privacy_password) || '',
    security_level: (data === null || data === void 0 ? void 0 : data.security_level) || 'authPriv',
    port: (data === null || data === void 0 ? void 0 : data.port) || 162,
    address: (data === null || data === void 0 ? void 0 : data.address) || '',
    msg_type: (data === null || data === void 0 ? void 0 : data.msg_type) || 'TRAP',
    traps: (data === null || data === void 0 ? void 0 : data.traps) || [],
    isCellHealthEnabled: isCellHealthEnabled || false,
}); };
export var transformToRequestObject = function (data) {
    var version = data.version;
    switch (version) {
        case 'v2c':
            return {
                enabled: (data === null || data === void 0 ? void 0 : data.enabled) || false,
                address: (data === null || data === void 0 ? void 0 : data.address) || undefined,
                protocol: (data === null || data === void 0 ? void 0 : data.protocol) || 'UDP',
                community: data.community || null,
                version: data.version,
                port: data.port,
                traps: data.traps || [],
                msg_type: (data === null || data === void 0 ? void 0 : data.msg_type) || 'TRAP',
            };
        case 'v3':
            return {
                enabled: (data === null || data === void 0 ? void 0 : data.enabled) || false,
                protocol: (data === null || data === void 0 ? void 0 : data.protocol) || 'UDP',
                community: (data === null || data === void 0 ? void 0 : data.community) || null,
                username: (data === null || data === void 0 ? void 0 : data.username) || null,
                version: (data === null || data === void 0 ? void 0 : data.version) || 'v1',
                engine_id: (data === null || data === void 0 ? void 0 : data.engine_id) || null,
                auth_protocol: (data === null || data === void 0 ? void 0 : data.auth_protocol) || 'MD5',
                auth_password: (data === null || data === void 0 ? void 0 : data.auth_password) || null,
                privacy_protocol: (data === null || data === void 0 ? void 0 : data.privacy_protocol) || 'DES',
                privacy_password: (data === null || data === void 0 ? void 0 : data.privacy_password) || null,
                security_level: (data === null || data === void 0 ? void 0 : data.security_level) || 'authPriv',
                port: data === null || data === void 0 ? void 0 : data.port,
                address: (data === null || data === void 0 ? void 0 : data.address) || undefined,
                msg_type: (data === null || data === void 0 ? void 0 : data.msg_type) || 'TRAP',
                traps: (data === null || data === void 0 ? void 0 : data.traps) || [],
            };
        default:
            return {
                enabled: (data === null || data === void 0 ? void 0 : data.enabled) || false,
                address: (data === null || data === void 0 ? void 0 : data.address) || undefined,
                protocol: (data === null || data === void 0 ? void 0 : data.protocol) || 'UDP',
                community: data.community || null,
                version: data.version,
                port: data === null || data === void 0 ? void 0 : data.port,
                traps: data.traps || [],
            };
    }
};
