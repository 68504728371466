import { screen, within } from '@testing-library/react';
import { t } from 'i18next';
import format from 'string-format';
var testUtils = {
    cancelButton: {
        get: function (name) {
            return screen.getByRole('button', { name: new RegExp(format(this.tooltip, name)) });
        },
        query: function (name) {
            return screen.queryByRole('button', { name: new RegExp(format(this.tooltip, name)) });
        },
        tooltip: t('components.file_uploader.tracker.cancel_label'),
    },
    clearButton: {
        get: function () {
            return screen.getByRole('button', { name: new RegExp(this.tooltip) });
        },
        query: function () {
            return screen.queryByRole('button', { name: new RegExp(this.tooltip) });
        },
        tooltip: t('components.file_uploader.tracker.clear_label'),
    },
    messageLabel: {
        get: function (label) {
            return screen.getByText(new RegExp(label));
        },
        getError: function (label) {
            return within(screen.getByRole('alert')).getByText(new RegExp(label));
        },
    },
    nameLabel: {
        get: function (label) {
            return screen.getByText(new RegExp(label));
        },
        query: function (label) {
            return screen.queryByText(new RegExp(label));
        },
    },
    progressIndicator: {
        get: function () {
            return screen.getByRole('progressbar');
        },
    },
    progressIndicatorLine: {
        get: function (name) {
            return screen.queryByRole('progressbar', { name: new RegExp(format(this.tooltip, name)) });
        },
        tooltip: t('components.file_uploader.tracker.progress_label'),
    },
    progressIndicatorBox: {
        get: function (name) {
            return screen.getByLabelText(new RegExp(format(this.tooltip, name)));
        },
        query: function (name) {
            return screen.queryByLabelText(new RegExp(format(this.tooltip, name)));
        },
        tooltip: t('components.file_uploader.tracker.progress_label'),
    },
};
export default testUtils;
