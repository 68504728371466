var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import sortBy from 'lodash/sortBy';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { LicenseExpiryBar } from '@/containers/menus/system-information-menu/license-expiry-bar';
import { getExpiration } from '@/api/system/entitlements/entitlements.hooks';
export function NetopsDeployment(_a) {
    var entitlements = _a.entitlements, modules = _a.modules;
    var t = useTranslation().t;
    var labels = t("pages.subscriptions.tabs.subscriptions.subscription_item.features");
    var result = [];
    var modulesData = entitlements.reduce(function (nomLicenses, license) {
        var _a, _b;
        var keysArr = (_a = license.id.match(/prov|sdi|ag$/)) !== null && _a !== void 0 ? _a : [];
        if (keysArr.length) {
            var key_1 = keysArr[0];
            var module = modules.find(function (moduleItem) { return (key_1 === 'prov' ? 'dop' : key_1) === moduleItem.id; });
            var data = {
                id: key_1,
                label: labels[key_1],
                totalNodes: license.features.nodes,
                assignedNodes: (_b = module === null || module === void 0 ? void 0 : module.activations) !== null && _b !== void 0 ? _b : 0,
                expiration: getExpiration(license.features.maintenance),
            };
            nomLicenses.push(data);
        }
        return nomLicenses;
    }, result);
    var sortedModulesData = sortBy(modulesData, ['label']);
    return (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: { marginBottom: 2 } }), _jsx(Typography, __assign({ component: "h4", variant: "h4", marginBottom: 3 }, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.features.netops_deployment') })), _jsx(Stack, __assign({ direction: "column" }, { children: sortedModulesData.map(function (moduleData) {
                    if (moduleData) {
                        return (_jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", marginBottom: 2 }, { children: [_jsxs(Box, __assign({ flex: 1 }, { children: [_jsx(Typography, { children: labels[moduleData.id] }), _jsx(Typography, { children: format(t('pages.subscriptions.tabs.subscriptions.subscription_item.features.nodes_available'), (moduleData.totalNodes - moduleData.assignedNodes).toString(), moduleData.totalNodes.toString()) })] })), moduleData.expiration ? (_jsx(Box, __assign({ minWidth: "40%", flex: 1 }, { children: _jsx(LicenseExpiryBar, { expiration: moduleData.expiration }) }))) : (_jsxs(Box, __assign({ minWidth: "40%", display: "flex", flex: 1 }, { children: [_jsx(InfoIcon, { fontSize: "medium", sx: { marginRight: 1 } }), _jsx(Typography, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.no_expiration_message') })] })))] }), moduleData === null || moduleData === void 0 ? void 0 : moduleData.id));
                    }
                    return null;
                }) }))] }));
}
