import { screen } from '@testing-library/react';
import { t } from 'i18next';
export var translations = t('pages.smf_resources.modal');
export var chipPopoverTestUtils = {
    seeMore: {
        button: {
            get: function () { return screen.getByRole('button', { name: chipPopoverTestUtils.translations.seeMore }); },
        },
        label: {
            query: function () { return screen.queryByText(chipPopoverTestUtils.translations.seeMore); },
        },
    },
    translations: {
        seeMore: t('components.chip_popover.see_more'),
    },
};
