var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { OGLink } from '@opengear/links';
import { OGTable } from '@opengear/table';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDependentLighthouses } from '@/api/multiple-instance/dependent-lighthouses';
import { formatDateTimestamp } from '@/utils/datetime';
import { subnetMaskToCIDR } from '@/utils/calculations';
import { getSecondaryStatus } from '@/utils/secondary-lighthouse-status';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { SECONDARY_LIGHTHOUSES_ROUTE, formatEditSecondaryRoute } from '@/constants/routes';
import { SecondaryStatusModal } from './secondary-status-modal';
export var QUERY_PARAM_LH_ID_KEY = 'lighthouse-id';
function StatusElement(_a) {
    var _b;
    var lhId = _a.lhId, statusText = _a.statusText, statusIcon = _a.statusIcon, isError = _a.isError, tabIndex = _a.tabIndex;
    return (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [statusIcon, isError ? (_jsx(OGLink, __assign({ tabIndex: tabIndex, target: statusText, to: "?".concat(new URLSearchParams((_b = {}, _b[QUERY_PARAM_LH_ID_KEY] = lhId, _b))), variant: "standard", sx: { marginLeft: 0.75 } }, { children: statusText }))) : (_jsx(Typography, __assign({ component: "div", sx: { marginLeft: 0.75 } }, { children: statusText })))] })));
}
export function SecondaryLighthousesTable() {
    // Get language
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var isMultiInstancePage = pathname === SECONDARY_LIGHTHOUSES_ROUTE;
    var hasPermission = useUIPermissions().hasPermission;
    var canViewLighthouses = hasPermission('configure.multiple_instance.dependent_lighthouse.view');
    // Data fetches
    var _a = useDependentLighthouses(), data = _a.data, isLoading = _a.isLoading;
    // Derived table values
    var _b = useMemo(function () { return ({
        loading: isLoading,
        rows: (data === null || data === void 0 ? void 0 : data.dependent_lighthouses) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.dependent_lighthouses, isLoading]), loading = _b.loading, rows = _b.rows;
    var _c = __read(useSearchParams(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var modalLighthouseId = useMemo(function () { var _a; return (_a = searchParams.get(QUERY_PARAM_LH_ID_KEY)) !== null && _a !== void 0 ? _a : undefined; }, [searchParams]);
    var handleModalClose = useCallback(function () {
        searchParams.delete(QUERY_PARAM_LH_ID_KEY);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    var getLastUpdatedValue = useCallback(function (params) { return params.row.runtime_status.change_time; }, []);
    var renderDescriptionCell = useCallback(function (params) {
        return isMultiInstancePage && canViewLighthouses ? (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, to: formatEditSecondaryRoute(params.row.id), variant: "standard" }, { children: params.value }))) : (params.value);
    }, [isMultiInstancePage, canViewLighthouses]);
    var getSyncDateFormat = useCallback(function (params) {
        return params.value ? formatDateTimestamp(params.value) : t('global.table.unknown_cell_value');
    }, [t]);
    var renderNameCell = useCallback(function (params) {
        var value = params.value ? params.value : t('global.table.unknown_cell_value');
        // Only show link to lighthouse instance if network address exists
        return params.row.network_address ? (_jsx(OGLink, __assign({ endIcon: _jsx(OpenInNewIcon, { fontSize: "medium" }), external: true, tabIndex: params.tabIndex, to: "https://".concat(params.row.network_address), variant: "standard" }, { children: value }))) : (value);
    }, [t]);
    var renderStatusCell = useCallback(function (params) {
        var _a = __read(getSecondaryStatus(params.row), 3), lhStatusText = _a[0], lhStatusIcon = _a[1], isErrorStatus = _a[2];
        return _jsx(StatusElement, { lhId: params.row.id, statusText: lhStatusText, statusIcon: lhStatusIcon, isError: isErrorStatus, tabIndex: params.tabIndex });
    }, []);
    var renderDetailsCell = useCallback(function (params) {
        var firmwareVersion = params.row.firmware_version ? params.row.firmware_version : t('pages.secondary_lighthouses.table.details.not_available');
        var networkAddress = params.row.network_address ? params.row.network_address : t('pages.secondary_lighthouses.table.details.not_available');
        var vpnAddrRange = t('pages.secondary_lighthouses.table.details.not_available');
        if (params.row.lhvpn_subnet.address && params.row.lhvpn_subnet.netmask) {
            vpnAddrRange = "".concat(params.row.lhvpn_subnet.address, "/").concat(subnetMaskToCIDR(params.row.lhvpn_subnet.netmask));
        }
        var enrolledNodes = t('pages.secondary_lighthouses.table.details.no_nodes_connected');
        if (params.row.nodes_connected && params.row.total_nodes && params.row.nodes_connected > -1) {
            enrolledNodes = "".concat(params.row.nodes_connected, "/").concat(params.row.total_nodes);
        }
        return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.secondary_lighthouses.table.details.software_version'), ": ", firmwareVersion] })), _jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.secondary_lighthouses.table.details.network_address'), ": ", networkAddress] })), _jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.secondary_lighthouses.table.details.vpn_address_range'), ": ", vpnAddrRange] })), _jsxs(Typography, __assign({ component: "div" }, { children: [t('pages.secondary_lighthouses.table.details.num_connected_nodes'), ": ", enrolledNodes] }))] })));
    }, [t]);
    var columns = useMemo(function () { return [
        {
            field: 'description',
            valueGetter: function (params) { return params.row.description || params.row.id; },
            headerName: t('pages.secondary_lighthouses.table.headers.description'),
            renderCell: renderDescriptionCell,
            flex: isMultiInstancePage ? 0.5 : 1,
            minWidth: 200,
        },
        {
            field: 'name',
            headerName: t('pages.secondary_lighthouses.table.headers.name'),
            renderCell: renderNameCell,
            flex: isMultiInstancePage ? 0.5 : 1,
            minWidth: 150,
        },
        {
            field: 'status',
            headerName: t('pages.secondary_lighthouses.table.headers.status'),
            renderCell: renderStatusCell,
            flex: isMultiInstancePage ? 0.5 : 1,
            minWidth: 150,
        },
        {
            field: 'last_updated',
            headerName: t('pages.secondary_lighthouses.table.headers.last_updated'),
            valueGetter: getLastUpdatedValue,
            valueFormatter: getSyncDateFormat,
            flex: isMultiInstancePage ? 0.5 : 0.4,
            minWidth: 128,
        },
        {
            field: 'details',
            headerName: t('pages.secondary_lighthouses.table.headers.details'),
            renderCell: renderDetailsCell,
            flex: 0.4,
            minWidth: 150,
            sortable: false,
        },
    ]; }, [getLastUpdatedValue, getSyncDateFormat, renderDescriptionCell, renderDetailsCell, renderNameCell, renderStatusCell, isMultiInstancePage, t]);
    var defaultSortModel = [{ field: 'last_updated', sort: 'asc' }];
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryStatusModal, { open: !!modalLighthouseId, handleClose: handleModalClose, lighthouseId: modalLighthouseId }), _jsx(Box, { children: _jsx(OGTable, { id: "dependent-lighthouses-table", "aria-label": t('pages.secondary_lighthouses.table.label'), columns: columns, rows: rows, autoHeight: true, loading: loading, noRowsMessage: t('pages.secondary_lighthouses.table.no_rows_message'), pagination: false, hideFooter: true, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    }, columnVisibilityModel: {
                        details: isMultiInstancePage,
                    } }) })] }));
}
