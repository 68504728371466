var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGPanel } from '@opengear/panel';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { isBundleTemplate } from './types';
import { CancelButton, FormActions, FormContainer, SubmitButton } from '@/components/layout/forms';
import { AutoApproveNodeField, BundleNameInput, EnrollmentTokenInput, ModulesSection, SubscriptionSelectField, TagsField, TemplatesSection, } from './bundle-form-controls';
import { transformFormStateToRequest, transformResponseToFormState } from './transformers';
import { ENROLLMENT_BUNDLES_ROUTE } from '@/constants/routes';
import { useAddBundle, useUpdateBundle } from '@/api/bundles/bundles';
import { DeleteBundle } from './modals';
import { useFormSync } from '@/hooks/use-form-sync';
import { isNomTemplate } from '@/api/template/guards';
export var defaultValues = {
    name: '',
    token: '',
    auto_approve: false,
    tier: 'legacy',
    automatic_tags: [],
    templates: [],
    modules: [],
};
export function EnrollmentBundleForm(_a) {
    var _this = this;
    var bundle = _a.bundle, lighthouseSubscriptions = _a.lighthouseSubscriptions, templates = _a.templates, permissions = _a.permissions, nodeTags = _a.nodeTags, defaultSubscriptionData = _a.defaultSubscriptionData;
    var sourceValues;
    if (bundle) {
        sourceValues = transformResponseToFormState(bundle, templates);
    }
    else {
        sourceValues = __assign(__assign({}, defaultValues), { tier: defaultSubscriptionData.enrollment_default_subscription.subscription_type });
    }
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    var navigate = useNavigate();
    var t = useTranslation().t;
    var addBundle = useAddBundle();
    var updateBundle = useUpdateBundle();
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var newOrUpdatedBundle;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!formData.id) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateBundle(__assign(__assign({}, transformFormStateToRequest(formData)), { id: formData.id }))];
                case 1:
                    newOrUpdatedBundle = _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, addBundle(transformFormStateToRequest(formData))];
                case 3:
                    newOrUpdatedBundle = _a.sent();
                    _a.label = 4;
                case 4:
                    if (newOrUpdatedBundle) {
                        navigate(ENROLLMENT_BUNDLES_ROUTE);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [addBundle, updateBundle, navigate]);
    var fieldsToOmit = [];
    if (!bundle) {
        fieldsToOmit.push('tier');
    }
    useFormSync(methods.formState.defaultValues, sourceValues, methods.reset, t('pages.enrollment_bundles.edit.reset_warning'), false, fieldsToOmit);
    var handleCancel = useCallback(function () { return navigate(ENROLLMENT_BUNDLES_ROUTE); }, [navigate]);
    var bundleTemplates = useMemo(function () { return templates.filter(isBundleTemplate); }, [templates]);
    var moduleTemplates = useMemo(function () { return templates.filter(isNomTemplate); }, [templates]);
    return (_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ mode: sourceValues.id ? 'update' : 'create', methods: methods, onSubmit: onSubmit, permissions: permissions }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }, { children: [_jsx(Typography, __assign({ gutterBottom: true, component: "h2", variant: "h2", sx: { fontWeight: 700, fontSize: 16 } }, { children: t('pages.enrollment_bundles.add.headings.details') })), _jsx(Box, { flexGrow: 1 }), sourceValues.id && _jsx(DeleteBundle, { bundleId: sourceValues.id, editable: permissions.hasEditPermission })] })), _jsx(BundleNameInput, {}), _jsx(EnrollmentTokenInput, {}), _jsx(AutoApproveNodeField, {}), _jsx(SubscriptionSelectField, { subscriptions: lighthouseSubscriptions, modules: moduleTemplates }), _jsx(Typography, __assign({ component: "h2", variant: "h2", sx: { fontWeight: 700, fontSize: 16, marginY: 2 } }, { children: t('pages.enrollment_bundles.add.headings.tags') })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, alignItems: 'center', marginBottom: 2 } }, { children: [_jsx(InfoIcon, { fontSize: "medium" }), _jsx(Typography, __assign({ variant: "body2" }, { children: t('pages.enrollment_bundles.add.fields.tags.add_tag_information') }))] })), _jsx(TagsField, { tags: nodeTags }), _jsx(Typography, __assign({ component: "h2", variant: "h2", sx: { fontWeight: 700, fontSize: 16, marginY: 2 } }, { children: t('pages.enrollment_bundles.add.headings.templates') })), _jsx(TemplatesSection, { templates: bundleTemplates }), _jsx(Typography, __assign({ component: "h2", variant: "h2", sx: { fontWeight: 700, fontSize: 16, marginY: 2 } }, { children: t('pages.enrollment_bundles.add.headings.modules') })), _jsx(ModulesSection, { modules: moduleTemplates }), _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: handleCancel }), _jsx(SubmitButton, {})] })] })) }));
}
