var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGUsageIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '0 0 24 24' }, props), { children: _jsxs("svg", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M4 3V21", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4 16H19.1579C19.3812 16 19.5954 16.079 19.7534 16.2197C19.9113 16.3603 20 16.5511 20 16.75V18.25C20 18.4489 19.9113 18.6397 19.7534 18.7803C19.5954 18.921 19.3812 19 19.1579 19H4", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4 10.5H16.9231C17.2087 10.5 17.4826 10.579 17.6846 10.7197C17.8865 10.8603 18 11.0511 18 11.25V12.75C18 12.9489 17.8865 13.1397 17.6846 13.2803C17.4826 13.421 17.2087 13.5 16.9231 13.5H4", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4 5H15.0769C15.3217 5 15.5565 5.07902 15.7296 5.21967C15.9027 5.36032 16 5.55109 16 5.75V7.25C16 7.44891 15.9027 7.63968 15.7296 7.78033C15.5565 7.92098 15.3217 8 15.0769 8H4", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })) })));
}
