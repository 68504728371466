var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import RemoveIcon from '@mui/icons-material/Close';
import { useFormatFirmwareUpgradeFilterPillLabel } from './firmware-status-period-filter-utils';
import { FirmwareStatusPeriodFilterForm } from './firmware-status-period-form';
/**
 * Node Upgrade Filter Pill is a custom component for the MUI's Autocomplete
 */
export function FirmwareStatusPeriodFilterPill(_a) {
    var option = _a.option, tagProps = _a.tagProps, popoverOpen = _a.popoverOpen, setActiveOption = _a.setActiveOption, setFilterOptions = _a.setFilterOptions;
    var _b = __read(useState(null), 2), nodeUpgradePillEl = _b[0], setNodeUpgradePillEl = _b[1];
    // Using useCallback in a ref give the popover an anchor element without relying on a click event but instead the activeOption state.
    var nodeUpgradePillRefCallback = useCallback(function (ref) {
        setNodeUpgradePillEl(ref);
    }, []);
    var label = useFormatFirmwareUpgradeFilterPillLabel(option);
    var handleClose = useCallback(function () {
        var key = option.key;
        setFilterOptions(function (prevState) {
            var prevValue = prevState[key];
            if (!prevValue) {
                var _a = prevState, _b = key, _ = _a[_b], newState = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                return newState;
            }
            return prevState;
        });
        // Close popover
        setActiveOption(undefined);
    }, [option, setActiveOption, setFilterOptions]);
    var handleOpen = useCallback(function () {
        setActiveOption(option.key);
    }, [option.key, setActiveOption]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ ref: nodeUpgradePillRefCallback }, { children: _jsx(Chip, __assign({ "aria-label": label, "aria-haspopup": true, "aria-pressed": popoverOpen ? 'true' : 'false', label: label, variant: "pill", onClick: handleOpen, deleteIcon: _jsx(RemoveIcon, {}) }, tagProps)) })), _jsx(Popover, __assign({ anchorEl: nodeUpgradePillEl, open: popoverOpen && !!nodeUpgradePillEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                } }, { children: _jsx(FirmwareStatusPeriodFilterForm, { option: option, setActiveOption: setActiveOption, setFilterOptions: setFilterOptions }) }))] }));
}
