import useMediaQuery from '@mui/material/useMediaQuery';
import { useThemeMediaQuery } from '@/hooks/use-theme-media-query';
/**
 * Hook to determine the current device view based on the theme breakpoints
 */
export var useDeviceView = function () {
    var isDesktopView = useMediaQuery(useThemeMediaQuery('desktop'));
    var isLaptopView = useMediaQuery(useThemeMediaQuery('desktop', false));
    var isMobileView = useMediaQuery(useThemeMediaQuery('tablet', false));
    return {
        isDesktopView: isDesktopView,
        isLaptopView: isLaptopView,
        isMobileView: isMobileView,
    };
};
