var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { v4 as uuidV4 } from 'uuid';
import { isUserGroupTemplate } from '@/api/template/guards';
export var transformFormStateToRequest = function (formState) {
    var name = formState.name, description = formState.description, users = formState.users, groups = formState.groups;
    var transformedUsers = users.map(function (user) {
        var id = user.id, confirmPassword = user.confirmPassword, remainingFields = __rest(user, ["id", "confirmPassword"]);
        var data = remainingFields;
        if (!user.password.length) {
            delete data.password;
        }
        return remainingFields;
    });
    var transformedGroups = groups.map(function (group) {
        var id = group.id, remainingFields = __rest(group, ["id"]);
        return remainingFields;
    });
    return {
        name: name,
        description: description,
        users: transformedUsers,
        groups: transformedGroups,
        group_operation_type: 'group_set_list',
        user_operation_type: 'user_set_list',
        type: 'users_groups',
    };
};
export var transformResponseToFormState = function (response) {
    if (!response || !isUserGroupTemplate(response))
        return null;
    var name = response.name, description = response.description, users = response.users, groups = response.groups;
    var transformedUsers = users.map(function (user) { return (__assign(__assign({ id: uuidV4() }, user), { password: '', confirmPassword: '' })); });
    var transformedGroups = groups.map(function (group) { var _a; return (__assign({ id: uuidV4(), ports: (_a = group.ports) !== null && _a !== void 0 ? _a : '' }, group)); });
    return {
        name: name,
        description: description !== null && description !== void 0 ? description : '',
        users: transformedUsers,
        groups: transformedGroups,
    };
};
