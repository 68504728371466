var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { OGReception, OGReceptionHigh, OGReceptionMedium, OGReceptionLow } from '@opengear/icons/status';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useTheme } from '@mui/material/styles';
import { OGButtonLink } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
export function CellularStatus(_a) {
    var node = _a.row, tabIndex = _a.tabIndex, setOpen = _a.setOpen;
    var t = useTranslation().t;
    var theme = useTheme();
    var cellStatusTranslation = t('global.query.node_cellhealth_status');
    var icon = _jsx(ErrorIcon, { fontSize: "medium", color: "error" });
    var description = cellStatusTranslation.unknown;
    var id = node.id, name = node.name, cellStatus = node.cellhealth_runtime_status.status;
    var handleClick = useCallback(function () {
        setOpen({ nodeId: id, nodeName: name });
    }, [id, name, setOpen]);
    if (cellStatus === 'n/a' || cellStatus === 'unknown') {
        return (_jsxs("span", __assign({ style: { display: 'flex', alignItems: 'flex-end' } }, { children: [_jsx(DoNotDisturbIcon, { fontSize: "medium", sx: { marginRight: theme.spacing(0.8) } }), cellStatusTranslation[cellStatus]] })));
    }
    if (cellStatus === 'sim_issues' || cellStatus === 'connectivity_test_failed') {
        icon = _jsx(OGReception, { color: "error", fontSize: "medium" });
        description = cellStatusTranslation[cellStatus];
    }
    if (cellStatus === 'pending') {
        icon = _jsx(InfoIcon, { fontSize: "medium" });
        description = cellStatusTranslation.pending;
    }
    if (cellStatus === 'bad') {
        icon = _jsx(OGReceptionLow, { color: "error", fontSize: "medium" });
        description = cellStatusTranslation.bad;
    }
    if (cellStatus === 'moderate') {
        icon = _jsx(OGReceptionMedium, { color: "warning", fontSize: "medium" });
        description = cellStatusTranslation.moderate;
    }
    if (cellStatus === 'good') {
        icon = _jsx(OGReceptionHigh, { color: "success", fontSize: "medium" });
        description = cellStatusTranslation.good;
    }
    if (cellStatus === 'interface_disabled') {
        icon = _jsx(OGReception, { fontSize: "medium" });
        description = cellStatusTranslation.interface_disabled;
    }
    return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: handleClick, sx: { textAlign: 'left', display: 'flex', alignItems: 'flex-start' }, startIcon: _jsx("span", __assign({ style: { color: theme.palette.text.primary, marginRight: theme.spacing(0.8) } }, { children: icon })), propagateSpacebar: false }, { children: description })));
}
