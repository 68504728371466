var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import format from 'string-format';
import { QUERY_LIMIT } from '@/constants/limits';
import { PORTS_PATH } from '@/api/ports/ports';
import { RESOURCE_TAGS_NAMES_PATH } from '@/api/tags/resource-tags/names';
import { SMF_RESOURCES_PATH } from '@/api/smf-resources';
import { decodeReadResourceTagsResponse, decodeCreateResourceTagsResponse } from '../../../types/lighthouse/decoders/tags/resource-tags';
import { decodeUpdateResourceTagResponse } from '../../../types/lighthouse/decoders/tags/resource-tags/resource-tag-id';
import { decodeBulkDeleteResourceTagsResponse } from '../../../types/lighthouse/decoders/tags/resource-tags/bulk/delete';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
import { nodeQueryKeys } from '../nodes';
export var RESOURCE_TAGS_PATH = '/tags/resource_tags';
export var RESOURCE_TAGS_BULK_DELETE_PATH = "".concat(RESOURCE_TAGS_PATH, "/bulk/delete");
export function formatResourceTagPath(resourceTagId) {
    if (resourceTagId === void 0) { resourceTagId = ':resourceTagId'; }
    return "".concat(RESOURCE_TAGS_PATH, "/").concat(resourceTagId);
}
export var defaultQueryParams = { per_page: QUERY_LIMIT, page: 1 };
function createResourceTag(_a) {
    var id = _a.id, resource_tag_name = _a.resource_tag_name;
    return {
        id: id,
        resource_tag_name: resource_tag_name,
        ports: [],
        smf_resources: [],
        port_count: 0,
        smf_resource_count: 0,
    };
}
/**
 * Hook to fetch resource tags.
 * There is a hard limit of the QUERY_LIMIT for the number of resource tags that can be fetched.
 */
export function useResourceTags(queryParams) {
    var _this = this;
    if (queryParams === void 0) { queryParams = defaultQueryParams; }
    var queryClient = useQueryClient();
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchResourceTags = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(RESOURCE_TAGS_PATH, __assign({ queryParams: queryParams }, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadResourceTagsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.tags_resource_tags.get'));
                    if (status === 200) {
                        data.resourceTags.forEach(function (resourceTag) {
                            queryClient.setQueryData([RESOURCE_TAGS_PATH, resourceTag.id], resourceTag);
                        });
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, queryParams, queryClient, t]);
    return useQuery({
        queryKey: [RESOURCE_TAGS_PATH, queryParams],
        queryFn: fetchResourceTags,
    });
}
/**
 * Hook to create a resource tag.
 */
export function useCreateResourceTag() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (resourceTagName) { return __awaiter(_this, void 0, void 0, function () {
        var requestBody, response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestBody = {
                        resourceTags: {
                            actions: {
                                create: __spreadArray([], __read(resourceTagName.split(',').map(function (tag) { return tag.trim(); })), false),
                            },
                        },
                    };
                    return [4 /*yield*/, executeFetch(RESOURCE_TAGS_PATH, { method: 'POST', body: JSON.stringify(requestBody) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeCreateResourceTagsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.tags_resource_tags.create'));
                    if (status === 200) {
                        // Use the response data to update the cache with a default resource tag
                        data.createdTags.forEach(function (_a) {
                            var resource_tag_id = _a.resource_tag_id, resource_tag_name = _a.resource_tag_name;
                            queryClient.setQueryData([RESOURCE_TAGS_PATH, resource_tag_id], createResourceTag({ id: resource_tag_id, resource_tag_name: resource_tag_name }));
                        });
                        // Revalidate resource tags
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_PATH],
                        })
                            .catch(console.error);
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_NAMES_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to create, link and unlink resource tags for a selected port or smf resource.
 */
export function useModifyResourceTags() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (requestData) { return __awaiter(_this, void 0, void 0, function () {
        var requestBody, response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestBody = {
                        resourceTags: requestData,
                    };
                    return [4 /*yield*/, executeFetch(RESOURCE_TAGS_PATH, { method: 'POST', body: JSON.stringify(requestBody) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeCreateResourceTagsResponse, response)
                        // Show the correct toast message whether the requestData uses either selected_ports or selected_smf_resources
                    ];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    // Show the correct toast message whether the requestData uses either selected_ports or selected_smf_resources
                    if (requestData.selected_ports && requestData.selected_ports.length > 0) {
                        handleCommonStatusCodes(data, status, t('api.tags_resource_tags.post_ports'));
                    }
                    if (requestData.selected_smf_resources && requestData.selected_smf_resources.length > 0) {
                        handleCommonStatusCodes(data, status, t('api.tags_resource_tags.post_resources'));
                    }
                    if (status === 200) {
                        // Use the response data to update the cache with a default resource tag
                        data.createdTags.forEach(function (_a) {
                            var resource_tag_id = _a.resource_tag_id, resource_tag_name = _a.resource_tag_name;
                            queryClient.setQueryData([RESOURCE_TAGS_PATH, resource_tag_id], createResourceTag({ id: resource_tag_id, resource_tag_name: resource_tag_name }));
                        });
                        // Node-ports endpoint has resource-tags when add them to node-ports this data needs a refresh hence the invalidateQueries
                        queryClient.invalidateQueries({ queryKey: nodeQueryKeys.all });
                        // Revalidate ports
                        if (requestData.selected_ports && requestData.selected_ports.length > 0) {
                            queryClient
                                .invalidateQueries({
                                queryKey: [PORTS_PATH],
                            })
                                .catch(console.error);
                        }
                        // Revalidate smf resources
                        if (requestData.selected_smf_resources && requestData.selected_smf_resources.length > 0) {
                            queryClient
                                .invalidateQueries({
                                queryKey: [SMF_RESOURCES_PATH],
                            })
                                .catch(console.error);
                        }
                        // Revalidate resource tag names
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_NAMES_PATH],
                        })
                            .catch(console.error);
                        // Revalidate resource tags
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to update a resource tag
 */
export function useUpdateResourceTag() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var requestBody, response, _b, data, status, translations;
        var resourceTagId = _a.id, resourceTag = __rest(_a, ["id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    requestBody = {
                        resource_tag: resourceTag,
                    };
                    return [4 /*yield*/, executeFetch(formatResourceTagPath(resourceTagId), { method: 'PUT', body: JSON.stringify(requestBody) })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateResourceTagResponse, response)];
                case 2:
                    _b = _c.sent(), data = _b.data, status = _b.status;
                    translations = {
                        error: { message: format(t('api.tags_resource_tag.put.error.message'), resourceTagId) },
                        forbidden: { message: format(t('api.tags_resource_tag.put.forbidden.message'), resourceTagId) },
                        not_found: { message: format(t('api.tags_resource_tag.put.not_found.message'), resourceTagId) },
                        ok: { message: format(t('api.tags_resource_tag.put.ok.message'), resourceTagId), header: t('api.tags_resource_tag.put.ok.header') },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        // Update the cache with the updated resource tag
                        queryClient.setQueryData([RESOURCE_TAGS_PATH, resourceTagId], function (cachedResourceTag) {
                            return cachedResourceTag ? __assign(__assign({}, cachedResourceTag), resourceTag) : cachedResourceTag;
                        });
                        // Revalidate resource tags
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, decodeResponse, t, handleCommonStatusCodes, queryClient]);
}
/**
 * Hook to bulk delete a resource tag
 */
export function useBulkDeleteResourceTags() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (resourceTagIds) { return __awaiter(_this, void 0, void 0, function () {
        var requestBody, response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestBody = {
                        deleteTags: {
                            resource_tag_ids: resourceTagIds,
                        },
                    };
                    return [4 /*yield*/, executeFetch(RESOURCE_TAGS_BULK_DELETE_PATH, { method: 'POST', body: JSON.stringify(requestBody) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeBulkDeleteResourceTagsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.tags_resource_tags_bulk_delete.post'));
                    if (status === 200) {
                        // Remove the deleted resource tags from the cache
                        resourceTagIds.forEach(function (resourceTagId) {
                            queryClient.removeQueries({
                                queryKey: [RESOURCE_TAGS_PATH, resourceTagId],
                            });
                        });
                        // Revalidate resource tags
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, true];
                    }
                    if (status === 404) {
                        // Not found can signal that the operation was a partial success.
                        // Since we are unable to determine which resource tags failed, revalidate resource tags.
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_PATH],
                        })
                            .catch(console.error);
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
