import { screen } from '@testing-library/react';
import { t } from 'i18next';
export var formControlsUtils = {
    nameField: {
        get: function () { return screen.getByLabelText(new RegExp(formControlsUtils.translations.name.label), { selector: 'input' }); },
        tooltip: {
            get: function () {
                return screen.getByRole('tooltip', {
                    name: formControlsUtils.tooltipTranslation,
                });
            },
            find: function () { return screen.findByText(formControlsUtils.tooltipTranslation); },
            query: function () { return screen.queryByLabelText(formControlsUtils.tooltipTranslation); },
        },
        errors: {
            maxLength: {
                get: function () { return screen.getByText(formControlsUtils.translations.name.errors.max_length); },
                query: function () { return screen.queryByText(formControlsUtils.translations.name.errors.max_length); },
            },
            required: {
                get: function () { return screen.getByText(formControlsUtils.translations.name.errors.required); },
                query: function () { return screen.queryByText(formControlsUtils.translations.name.errors.required); },
            },
        },
    },
    valuesField: {
        getAll: function () { return screen.getAllByLabelText("".concat(formControlsUtils.translations.values.label_single, " *"), { selector: 'input' }); },
        getNth: function (index) {
            var input = formControlsUtils.valuesField.getAll()[index];
            if (!input)
                throw Error("Unable to get first value field input with index ".concat(index));
            return input;
        },
        error: {
            get: function () { return screen.getByText(formControlsUtils.translations.values.errors.required); },
            query: function () { return screen.queryByText(formControlsUtils.translations.values.errors.required); },
        },
        addButton: {
            get: function () { return screen.getByRole('button', { name: formControlsUtils.translations.values.add_label }); },
        },
        removeButton: {
            getAll: function () { return screen.getAllByRole('button', { name: formControlsUtils.translations.values.remove_label }); },
            getNth: function (index) {
                var input = formControlsUtils.valuesField.removeButton.getAll()[index];
                if (!input)
                    throw Error("Unable to get remove button with index ".concat(index));
                return input;
            },
        },
    },
    translations: t('components.tags.form.fields'),
    tooltipTranslation: t('pages.tags.tabs.resource_tags.form.name_tooltip'),
};
