var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { invalidFieldError, maxLengthFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN } from '@/constants/regex';
// Field validation limits
export var ManagerPortMinVal = 1;
export var ManagerPortMaxVal = 65535;
export var CommunityMaxLength = 255;
export function StatusToggleButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
            return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasEditPermission, value: value, onChange: function (event, v) {
                    if (v !== null) {
                        onChange(v);
                    }
                } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
        } }));
}
export function ManagerProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.protocol.label') })), _jsx(Controller, { control: control, name: "protocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "UDP" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.protocol.udp') })), _jsx(ToggleButton, __assign({ value: "UDP6" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.protocol.udp6') })), _jsx(ToggleButton, __assign({ value: "TCP" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.protocol.tcp') })), _jsx(ToggleButton, __assign({ value: "TCP6" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.protocol.tcp6') }))] })));
                } })] }));
}
export function ManagerAddressField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "address", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.address.label')),
            pattern: {
                value: HOSTNAME_OR_ADDRESS_PATTERN,
                message: invalidFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.address.label')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, required: true, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.address.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.address.tooltip') })] }) }, field)));
        } }));
}
export function ManagerPortField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "port", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.port.label')),
            min: {
                value: ManagerPortMinVal,
                message: minValueFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.port.label'), ManagerPortMinVal.toString()),
            },
            max: {
                value: ManagerPortMaxVal,
                message: maxValueFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.port.label'), ManagerPortMaxVal.toString()),
            },
        }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, ref = _b.ref, field = __rest(_b, ["onChange", "ref"]), error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 200 }, disabled: !permissions.hasEditPermission, required: true, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, type: "number", label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.port.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.port.tooltip') })] }) }, field, { value: field.value != null ? field.value : '', onChange: function (e) {
                    onChange(e.target.value ? parseInt(e.target.value, 10) : '');
                } })));
        } }));
}
export function VersionButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.version.label') })), _jsx(Controller, { control: control, name: "version", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "v1", style: { textTransform: 'none' } }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.version.v1') })), _jsx(ToggleButton, __assign({ value: "v2c", style: { textTransform: 'none' } }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.version.v2c') })), _jsx(ToggleButton, __assign({ value: "v3", style: { textTransform: 'none' } }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.version.v3') }))] })));
                } })] }));
}
export function CommunityField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "community", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.community.label')),
            maxLength: {
                value: CommunityMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.community.label'), CommunityMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, required: true, label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.community.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.community.tooltip') })] }) }, field)));
        } }));
}
export function CheckboxFields() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch, permissions = _a.permissions;
    var isCellHealthEnabled = watch('isCellHealthEnabled');
    return (_jsxs(FormControl, __assign({ component: "fieldset" }, { children: [_jsx(FormLabel, __assign({ component: "legend" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.title') })), _jsx(Typography, __assign({ component: "span", marginTop: 1, marginBottom: 1, style: { fontStyle: 'italic' } }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.caption') })), _jsx(FormGroup, { children: _jsx(Controller, { name: "traps", control: control, render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, _c = _b.value, value = _c === void 0 ? [] : _c;
                        return (_jsxs(_Fragment, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: value.includes('nodes_conn_status'), onChange: function (e) {
                                            var newValue = e.target.checked ? __spreadArray(__spreadArray([], __read(value), false), ['nodes_conn_status'], false) : value.filter(function (v) { return v !== 'nodes_conn_status'; });
                                            onChange(newValue);
                                        }, disabled: !permissions.hasEditPermission }), label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.nodes_conn_status.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.nodes_conn_status.tooltip') })] }) }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: value.includes('replication_status'), onChange: function (e) {
                                            var newValue = e.target.checked ? __spreadArray(__spreadArray([], __read(value), false), ['replication_status'], false) : value.filter(function (v) { return v !== 'replication_status'; });
                                            onChange(newValue);
                                        }, disabled: !permissions.hasEditPermission }), label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.replication_status.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.replication_status.tooltip') })] }) }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: value.includes('nodes_cellular_health_status'), onChange: function (e) {
                                            var newValue = e.target.checked ? __spreadArray(__spreadArray([], __read(value), false), ['nodes_cellular_health_status'], false) : value.filter(function (v) { return v !== 'nodes_cellular_health_status'; });
                                            onChange(newValue);
                                        }, disabled: !permissions.hasEditPermission || !isCellHealthEnabled }), label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.nodes_cellular_health_status.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.trap.nodes_cellular_health_status.tooltip') })] }) })] }));
                    } }) })] })));
}
