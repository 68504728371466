var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { rest } from 'msw';
import reduce from 'lodash/reduce';
import { formatRolePath, ROLES_PATH } from './roles';
import { formatLastUpdated, notFoundError } from '@/test/utils';
import { formatApiPath } from '@/api/utils';
import { mockNoPermissions, mockPermissionsMap } from '@/api/permissions/common.mock';
import { formatRoleId } from '@/constants/models.mock';
export var defaultMockRolesExt = [
    {
        id: formatRoleId(1),
        description: 'Lighthouse Administrator',
        groups: ['admin', 'netgroup'],
        last_updated: '--',
        rolename: 'LighthouseAdmin',
        permissions: mockPermissionsMap.LighthouseAdmin,
    },
    {
        id: formatRoleId(2),
        description: 'Node Administrator',
        groups: [],
        last_updated: '--',
        rolename: 'NodeAdmin',
        permissions: mockPermissionsMap.NodeAdmin,
    },
    {
        id: formatRoleId(3),
        description: 'Node User',
        groups: [],
        last_updated: '--',
        rolename: 'NodeUser',
        permissions: mockPermissionsMap.NodeUser,
    },
    {
        id: formatRoleId(4),
        description: 'Lighthouse Reporter',
        groups: [],
        last_updated: '--',
        rolename: 'Reporter',
        permissions: mockPermissionsMap.Reporter,
    },
    {
        id: formatRoleId(5),
        description: 'A test role',
        groups: [],
        last_updated: '2022-10-18 05:13',
        rolename: 'test_role_a',
        permissions: mockPermissionsMap.Reporter,
    },
];
export function roleExtToRoleListItem(_a) {
    var description = _a.description, groups = _a.groups, id = _a.id, last_updated = _a.last_updated, permissions = _a.permissions, rolename = _a.rolename;
    return {
        description: description,
        group_usage: groups.length,
        id: id,
        last_updated: last_updated,
        number_of_permissions: reduce(permissions, function (count, subPermissions) { return reduce(subPermissions, function (subCount, permission) { return count + (['deny', 'off'].includes(permission) ? 0 : 1); }, count); }, 0),
        rolename: rolename,
    };
}
export var defaultMockRoleListItems = defaultMockRolesExt.map(roleExtToRoleListItem);
export var defaultMockRoles = defaultMockRolesExt.map(function (_a) {
    var last_updated = _a.last_updated, role = __rest(_a, ["last_updated"]);
    return role;
});
var mockRoleDB = new Map(defaultMockRolesExt.map(function (mockRolesExt) { return [mockRolesExt.id, mockRolesExt]; }));
function createMockRole(_a) {
    var description = _a.description, rolename = _a.rolename;
    var id = formatRoleId(mockRoleDB.size + 1);
    var roleExt = {
        description: description !== null && description !== void 0 ? description : null,
        groups: [],
        id: id,
        last_updated: formatLastUpdated(new Date()),
        permissions: mockNoPermissions,
        rolename: rolename,
    };
    mockRoleDB.set(id, roleExt);
    var lastUpdated = roleExt.last_updated, role = __rest(roleExt, ["last_updated"]);
    return role;
}
function updateMockRole(id, _a) {
    var description = _a.description, rolename = _a.rolename;
    var currentRole = mockRoleDB.get(id);
    if (currentRole === undefined) {
        return undefined;
    }
    var roleExt = __assign(__assign({}, currentRole), { description: description !== null && description !== void 0 ? description : currentRole.description, last_updated: formatLastUpdated(new Date()), rolename: rolename });
    mockRoleDB.set(id, roleExt);
    var lastUpdated = roleExt.last_updated, role = __rest(roleExt, ["last_updated"]);
    return role;
}
export function resetRoles() {
    mockRoleDB.clear();
    defaultMockRolesExt.forEach(function (role) {
        mockRoleDB.set(role.id, role);
    });
}
export function getMockRoles() {
    return __spreadArray([], __read(mockRoleDB.values()), false);
}
export function createMockRoles() {
    var roles = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        roles[_i] = arguments[_i];
    }
    return roles.map(createMockRole);
}
var mockRolesFilter = function (mockRoles, filterParam) {
    if (filterParam === null) {
        return mockRoles;
    }
    return mockRoles.filter(function (roleItem) { return roleItem.rolename.toLowerCase().includes(filterParam.toLowerCase()); });
};
export var rolesGetHandler = rest.get(formatApiPath(ROLES_PATH), function (req, res, ctx) {
    var roles = __spreadArray([], __read(mockRoleDB.values()), false).map(roleExtToRoleListItem);
    return res(ctx.status(200), ctx.json({
        roles: mockRolesFilter(roles, req.url.searchParams.get('filter')),
        meta: { total_count: roles.length, total_pages: 1 },
    }));
});
export var rolesPostHandler = rest.post(formatApiPath(ROLES_PATH), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var role, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = createMockRole;
                return [4 /*yield*/, req.json()];
            case 1:
                role = _a.apply(void 0, [(_b.sent()).role]);
                return [2 /*return*/, res(ctx.status(201), ctx.json({ role: role }))];
        }
    });
}); });
export var roleGetHandler = rest.get(formatApiPath(formatRolePath()), function (req, res, ctx) {
    var roleId = req.params.roleId;
    if (typeof roleId !== 'string') {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var roleExt = mockRoleDB.get(roleId);
    if (roleExt === undefined) {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var lastUpdated = roleExt.last_updated, role = __rest(roleExt, ["last_updated"]);
    return res(ctx.status(200), ctx.json({ role: role }));
});
export var rolePutHandler = rest.put(formatApiPath(formatRolePath()), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var roleId, role, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                roleId = req.params.roleId;
                if (typeof roleId !== 'string' || !mockRoleDB.has(roleId)) {
                    return [2 /*return*/, res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)))];
                }
                _a = updateMockRole;
                _b = [roleId];
                return [4 /*yield*/, req.json()];
            case 1:
                role = _a.apply(void 0, _b.concat([(_c.sent()).role]));
                if (role === undefined) {
                    return [2 /*return*/, res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)))];
                }
                return [2 /*return*/, res(ctx.status(200), ctx.json({ role: role }))];
        }
    });
}); });
export var roleDeleteHandler = rest.delete(formatApiPath(formatRolePath()), function (req, res, ctx) {
    var roleId = req.params.roleId;
    if (typeof roleId !== 'string') {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var deleted = mockRoleDB.delete(roleId);
    if (!deleted) {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    return res(ctx.status(204));
});
