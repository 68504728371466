var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormLabel from '@mui/material/FormLabel';
import format from 'string-format';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { OG_OFF_WHITE, OG_BLACK_90 } from '@opengear/theme/colors';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { OGButton, OGButtonLink } from '@opengear/buttons';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { OGTable } from '@opengear/table';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { useOpen } from '@/hooks/open';
import { useGroupsPermissions } from '@/api/permissions/groups';
import { GroupsAndRolesPermissionField } from '@/components/accounts/groups/groups-and-roles-permission-field';
import { GroupsAndRolesPermissionsTable } from '@/components/accounts/groups/groups-and-roles-permissions-table';
import { DisabledTextField } from '@/components/inputs/disabled-text-field';
import { useUpdatePasswordValidation } from '@/api/users/password-validation';
import { PasswordPolicyMessages } from '@/components/password-policy-message';
import { AddGroupsModal, GroupPreviewModal } from './modals';
import { USER_NAME_PATTERN, USER_DESCRIPTION_PATTERN } from '@/constants/regex';
import { requiredFieldError, maxLengthFieldError, invalidFieldError } from '@/utils/field-validation-errors';
import { RemoveCell } from '@/components/remove-cell';
// TestIds to help differentiate user toggle from remote toggle
export var USER_ENABLED_TEST_ID = 'user-enabled-test';
export var USER_DISABLED_TEST_ID = 'user-disabled-test';
export var REMOTE_ENABLED_TEST_ID = 'remote-only-enabled-test';
export var REMOTE_DISABLED_TEST_ID = 'remote-only-disabled-test';
export var GROUPS_PERMISSIONS_LOADER_ID = 'groups-permissions-summary-loading';
// Field validation limits
export var UsernameFieldMaxLength = 32;
export var DescriptionFieldMaxLength = 128;
export var SshKeyDescriptionFieldMaxLength = 128;
// Different Form Controls
export function EnableUserField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    if (permissions.isSecondary || !permissions.hasEditPermission)
        return null;
    return (_jsx(Controller, { control: control, name: "enabled", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('components.user.form.fields.status.label') }), _jsxs(ToggleButtonGroup, __assign({ exclusive: true, onChange: function (event, newValue) {
                            if (newValue !== null)
                                onChange(newValue);
                        } }, field, { children: [_jsx(ToggleButton, __assign({ value: true, "data-testid": USER_ENABLED_TEST_ID }, { children: t('global.buttons.enabled.enabled') })), _jsx(ToggleButton, __assign({ value: false, "data-testid": USER_DISABLED_TEST_ID }, { children: t('global.buttons.enabled.disabled') }))] }))] }));
        } }));
}
export function UsernameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, mode = _a.mode;
    return (_jsx(Controller, { control: control, name: "username", rules: {
            required: requiredFieldError(t('components.user.form.fields.username.label')),
            maxLength: {
                value: UsernameFieldMaxLength,
                message: maxLengthFieldError(t('components.user.form.fields.username.label'), UsernameFieldMaxLength.toString()),
            },
            pattern: {
                value: USER_NAME_PATTERN,
                message: invalidFieldError(t('components.user.form.fields.username.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return mode === 'create' ? (_jsx(TextField, __assign({ id: "username", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('components.user.form.fields.username.label'), _jsx(OGIconTooltip, { title: t('components.user.form.fields.username.tooltip'), "aria-label": t('components.user.form.fields.username.accessible_tooltip') })] }), sx: { maxWidth: isMobileView ? undefined : 400 }, required: true }, field))) : (_jsx(DisabledTextField, { id: "username", label: t('components.user.form.fields.username.label'), value: field.value }));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('components.user.form.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
            pattern: {
                value: USER_DESCRIPTION_PATTERN,
                message: invalidFieldError(t('components.user.form.fields.description.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "description", label: t('components.user.form.fields.description.label'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: permissions.isSecondary }, field)));
        } }));
}
export function EnableRemoteOnlyPasswordField() {
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "remoteOnlyPassword", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsxs(FormControl, { children: [_jsxs(FormLabel, __assign({ id: "remote_only_password" }, { children: [t('components.user.form.fields.remote_only_password.label'), _jsx(OGIconTooltip, { title: t('components.user.form.fields.remote_only_password.tooltip') })] })), _jsxs(ToggleButtonGroup, __assign({ exclusive: true, onChange: function (event, newValue) {
                            if (newValue !== null)
                                onChange(newValue);
                        } }, field, { children: [_jsx(ToggleButton, __assign({ value: true, "data-testid": REMOTE_ENABLED_TEST_ID }, { children: t('global.buttons.enabled.enabled') })), _jsx(ToggleButton, __assign({ value: false, "data-testid": REMOTE_DISABLED_TEST_ID }, { children: t('global.buttons.enabled.disabled') }))] }))] }));
        } }));
}
function usePasswordValidationSection(passwordPolicyList) {
    var _this = this;
    var _a = useFormContainerContext(), getValues = _a.getValues, mode = _a.mode;
    var updatePasswordValidation = useUpdatePasswordValidation();
    var _b = __read(useState(undefined), 2), passwordValidationData = _b[0], setPasswordValidationData = _b[1];
    var isPasswordPolicyEnabled = useMemo(function () {
        return passwordPolicyList &&
            passwordPolicyList.enabled &&
            (passwordPolicyList.min_password_length > 0 ||
                passwordPolicyList.no_username ||
                passwordPolicyList.must_contain_capital_letter ||
                passwordPolicyList.must_contain_number ||
                passwordPolicyList.must_contain_special_character);
    }, [passwordPolicyList]);
    var validatePassword = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, username, password, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!isPasswordPolicyEnabled || (mode === 'update' && !getValues('password'))) {
                        setPasswordValidationData(undefined);
                        return [2 /*return*/, undefined];
                    }
                    _a = __read(getValues(['username', 'password']), 2), username = _a[0], password = _a[1];
                    return [4 /*yield*/, updatePasswordValidation({
                            password_validation: {
                                username: username,
                                password: password,
                            },
                        })];
                case 1:
                    data = _b.sent();
                    if (data) {
                        setPasswordValidationData(data);
                        return [2 /*return*/, data.result];
                    }
                    return [2 /*return*/, undefined];
            }
        });
    }); }, [updatePasswordValidation, mode, isPasswordPolicyEnabled, getValues]);
    var debounceValidatePassword = useMemo(function () { return debounce(validatePassword, 500); }, [validatePassword]);
    var passwordPolicyMessages = _jsx(PasswordPolicyMessages, { passwordPolicyList: passwordPolicyList, passwordValidationData: passwordValidationData });
    return { debounceValidatePassword: debounceValidatePassword, isPasswordPolicyEnabled: isPasswordPolicyEnabled, passwordPolicyMessages: passwordPolicyMessages };
}
export function PasswordField(_a) {
    var passwordPolicy = _a.passwordPolicy;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var theme = useTheme();
    var darkMode = theme.palette.mode === 'dark';
    var _b = useFormContainerContext(), control = _b.control, mode = _b.mode, permissions = _b.permissions, watch = _b.watch, trigger = _b.trigger, getFieldState = _b.getFieldState;
    var _c = usePasswordValidationSection(passwordPolicy), debounceValidatePassword = _c.debounceValidatePassword, isPasswordPolicyEnabled = _c.isPasswordPolicyEnabled, passwordPolicyMessages = _c.passwordPolicyMessages;
    var password = watch('password');
    var noPassword = watch('remoteOnlyPassword');
    var noPassIsDirty = getFieldState('remoteOnlyPassword').isDirty;
    var showRequired = mode === 'create' || (!noPassword && noPassIsDirty);
    var showPasswordPolicy = isPasswordPolicyEnabled && mode === 'update' ? (!noPassword && noPassIsDirty) || !!password : isPasswordPolicyEnabled;
    if (permissions.isSecondary)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Controller, { control: control, name: "password", rules: {
                    required: showRequired && requiredFieldError(t('components.user.form.fields.password.label')),
                    validate: debounceValidatePassword,
                }, render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, field = __rest(_c, ["onChange"]), fieldState = _a.fieldState;
                    return (_jsx(TextField, __assign({ id: "password", type: "password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('components.user.form.fields.password.label'), sx: { maxWidth: isMobileView ? undefined : 400 }, required: showRequired }, field, { onChange: function (event) {
                            onChange(event);
                            trigger('password');
                        } })));
                } }), showPasswordPolicy && (_jsx(Typography, __assign({ variant: "body2", tabIndex: 0, sx: { backgroundColor: darkMode ? OG_BLACK_90 : OG_OFF_WHITE, marginBottom: 2, padding: 1, maxWidth: isMobileView ? undefined : 400 } }, { children: passwordPolicyMessages })))] }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, trigger = _a.trigger;
    if (permissions.isSecondary)
        return null;
    return (_jsx(Controller, { control: control, name: "confirmPassword", rules: {
            validate: function (value, formValues) {
                return value === formValues.password || t('components.user.form.fields.confirm_password.errors.no_match');
            },
        }, render: function (_a) {
            var _b;
            var _c = _a.field, onChange = _c.onChange, field = __rest(_c, ["onChange"]), fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, id: "confirm-new-password", sx: { marginBottom: 1, maxWidth: isMobileView ? undefined : 400 }, label: t('components.user.form.fields.confirm_password.label'), margin: "normal", type: "password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, onChange: function (event) {
                    onChange(event);
                    trigger('confirmPassword');
                } }, field)));
        } }));
}
export function SshAuthKeyDescriptionField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            required: requiredFieldError(t('components.user.form.fields.ssh_auth_key.table.modal.description.label')),
            maxLength: {
                value: SshKeyDescriptionFieldMaxLength,
                message: maxLengthFieldError(t('components.user.form.fields.ssh_auth_key.table.modal.description.label'), SshKeyDescriptionFieldMaxLength.toString()),
            },
            validate: function (value) { return value.trim() !== '' || requiredFieldError(t('components.user.form.fields.ssh_auth_key.table.modal.description.label')); },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "ssh-auth-key-description", required: true, multiline: true, fullWidth: true, margin: "normal", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, disabled: permissions.isSecondary, label: _jsx(_Fragment, { children: t('components.user.form.fields.ssh_auth_key.table.modal.description.label') }), 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true }, field)));
        } }));
}
export function SshAuthKeyField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "value", rules: {
            required: requiredFieldError(t('components.user.form.fields.ssh_auth_key.table.modal.key.label')),
            validate: function (value) { return value.trim() !== '' || requiredFieldError(t('components.user.form.fields.ssh_auth_key.table.modal.key.label')); },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "ssh-auth-key", required: true, multiline: true, fullWidth: true, margin: "normal", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, disabled: permissions.isSecondary, label: _jsxs(_Fragment, { children: [t('components.user.form.fields.ssh_auth_key.table.modal.key.label'), _jsx(OGIconTooltip, { title: t('components.user.form.fields.ssh_auth_key.table.modal.tooltip') })] }) }, field)));
        } }));
}
export function SshAuthKeySection(_a) {
    var onOpenUpdateModal = _a.onOpenUpdateModal, onOpenAddModal = _a.onOpenAddModal;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), watch = _b.watch, permissions = _b.permissions;
    var sshAuthKeys = watch('sshAuthKeys');
    var rows = sshAuthKeys.map(function (key, index) { return (__assign(__assign({}, key), { id: index })); });
    function getSshKeyType(_a) {
        var value = _a.value;
        // Get the ssh key type (first split string) of the SSH key, or the first 30 characters if it can't be split
        // All valid ssh keys should begin with a key type
        var parts = value.split(' ');
        var label = parts.length >= 2 ? parts[0] : value.trimStart().substring(0, 30);
        return label;
    }
    var renderPreviewLink = useCallback(function (_a) {
        var tabIndex = _a.tabIndex, row = _a.row;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", type: "button", onClick: function () { return onOpenUpdateModal(row.id); }, propagateSpacebar: false }, { children: row.description })));
    }, [onOpenUpdateModal]);
    var columns = useMemo(function () { return [
        {
            field: 'description',
            flex: 1,
            headerName: t('components.user.form.fields.ssh_auth_key.table.headers.description'),
            sortable: true,
            maxWidth: 500,
            renderCell: renderPreviewLink,
        },
        {
            field: 'value',
            flex: 1,
            headerName: t('components.user.form.fields.ssh_auth_key.table.headers.key'),
            sortable: true,
            renderCell: getSshKeyType,
        },
    ]; }, [renderPreviewLink, t]);
    return (_jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.user.form.fields.ssh_auth_key.title') })), _jsxs(Box, { children: [_jsx(OGTable, { "aria-label": t('components.user.form.fields.ssh_auth_key.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('components.user.form.fields.ssh_auth_key.table.no_rows_message') }), _jsx(OGButton, __assign({ disabled: permissions.isSecondary, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", onClick: onOpenAddModal, sx: { marginTop: 2 } }, { children: t('components.user.form.fields.ssh_auth_key.add_button') }))] })] })));
}
export function GroupsTable(_a) {
    var groups = _a.groups, editable = _a.editable, handleRemoveGroup = _a.handleRemoveGroup, addButtonRef = _a.addButtonRef;
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var _b = __read(useState(), 2), previewGroup = _b[0], setPreviewGroup = _b[1];
    // Remove row focus management
    var _c = __read(useState(undefined), 2), cellFocus = _c[0], setCellFocus = _c[1];
    var groupNames = watch('groupNames');
    var rows = groups.filter(function (group) { return groupNames.includes(group.groupname); });
    var handlePreviewReset = useCallback(function () {
        setPreviewGroup(undefined);
    }, []);
    var renderPreviewLink = useCallback(function (_a) {
        var tabIndex = _a.tabIndex, value = _a.value;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", type: "button", onClick: function () {
                setPreviewGroup(value);
            }, propagateSpacebar: false }, { children: value })));
    }, []);
    var renderRemoveCell = useCallback(function (_a) {
        var row = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        return (_jsx(RemoveCell, { index: api.getRowIndexRelativeToVisibleRows(row.groupname), currentCount: rows.length, icon: _jsx(ClearIcon, { fontSize: "medium" }), tabIndex: tabIndex, onRemove: function (nextFocusIndex) {
                var _a;
                // Set focus to next row
                if (typeof nextFocusIndex !== 'undefined') {
                    var nextFocusRowId = rows[nextFocusIndex] ? rows[nextFocusIndex].groupname : undefined;
                    setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'remove' } : undefined);
                }
                else {
                    (_a = addButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                }
                handleRemoveGroup(row.groupname);
            }, disabled: !editable, sx: { minHeight: 21, minWidth: 20, padding: 0 }, translation: {
                label: format(t('components.user.form.groups_section.table.buttons.remove_label'), row.groupname),
                tooltip: editable
                    ? t('components.user.form.groups_section.table.buttons.remove_tooltip')
                    : t('components.user.form.groups_section.table.buttons.remove_tooltip_disabled'),
            } }));
    }, [rows, editable, t, handleRemoveGroup, addButtonRef]);
    var columns = useMemo(function () { return [
        {
            field: 'groupname',
            flex: 1,
            headerName: t('components.user.form.groups_section.table.headers.groupname'),
            sortable: false,
            minWidth: 130,
            maxWidth: 500,
            renderCell: renderPreviewLink,
        },
        {
            field: 'description',
            flex: 1,
            headerName: t('components.user.form.groups_section.table.headers.description'),
            sortable: false,
            minWidth: 120,
        },
        {
            field: 'enabled',
            flex: 1,
            headerName: t('components.user.form.groups_section.table.headers.status'),
            sortable: false,
            valueGetter: function (_a) {
                var value = _a.value;
                return (value ? t('pages.groups_roles.status.enabled') : t('pages.groups_roles.status.disabled'));
            },
            minWidth: 80,
        },
        {
            field: 'remove',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderRemoveCell,
        },
    ]; }, [renderPreviewLink, renderRemoveCell, t]);
    return (_jsxs(_Fragment, { children: [previewGroup ? _jsx(GroupPreviewModal, { groupName: previewGroup, handleClose: handlePreviewReset }) : null, _jsx(OGTable, { "aria-describedby": "user-group-table-description", "aria-label": t('components.user.form.groups_section.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('components.user.form.groups_section.table.no_rows_message'), getRowId: function (row) { return row.groupname; }, setCellFocus: cellFocus })] }));
}
// Groups section to manage selected groups
export function GroupsSection(_a) {
    var groups = _a.groups, editable = _a.editable, handleAddGroups = _a.handleAddGroups, handleRemoveGroup = _a.handleRemoveGroup;
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    var _b = useOpen(false), openAddModal = _b.open, handleCloseAddModal = _b.handleClose, handleOpenAddModal = _b.handleOpen;
    var addButtonRef = useRef(null);
    return (_jsxs(_Fragment, { children: [openAddModal ? (_jsx(AddGroupsModal, { control: control, open: openAddModal, handleClose: handleCloseAddModal, handleAddGroups: handleAddGroups, groupData: groups })) : null, _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.user.form.groups_section.title') })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, alignItems: 'center', marginBottom: 2 } }, { children: [_jsx(InfoIcon, { fontSize: "medium" }), _jsx(Typography, __assign({ id: "user-group-table-description" }, { children: t('components.user.form.groups_section.information_heading') }))] })), _jsx(Box, __assign({ marginBottom: 2 }, { children: _jsx(GroupsTable, { editable: editable, groups: groups, handleRemoveGroup: handleRemoveGroup, addButtonRef: addButtonRef }) })), _jsx(Box, __assign({ display: "flex", gap: 1 }, { children: _jsx(OGButton, __assign({ disabled: !editable, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", onClick: handleOpenAddModal, ref: addButtonRef }, { children: t('components.user.form.groups_section.buttons.add_group') })) }))] }))] }));
}
// Permissions Summary
export function OperationsPermissionsSummary() {
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var groupNames = watch('groupNames');
    var _a = useGroupsPermissions.apply(void 0, __spreadArray([], __read(groupNames), false)), data = _a.data, isLoading = _a.isLoading;
    if (isLoading) {
        return (_jsx(Stack, __assign({ alignItems: "center" }, { children: _jsx(CircularProgress, { "data-testid": GROUPS_PERMISSIONS_LOADER_ID, "aria-label": t('components.loading_overlay.label') }) })));
    }
    if (!data)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.permissions.form.title') })), _jsx(GroupsAndRolesPermissionField, { mode: "user", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.console_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.console_shell.tooltip') })] }), permission: data.cli.console_shell }), data.cli.shell_access && (_jsx(GroupsAndRolesPermissionField, { mode: "user", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.shell_access.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.shell_access.tooltip') })] }), permission: data.cli.shell_access })), _jsx(GroupsAndRolesPermissionField, { mode: "user", label: _jsxs(_Fragment, { children: [t('components.permissions.form.fields.pm_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.pm_shell.tooltip') })] }), permission: data.cli.pm_shell }), _jsx(Divider, { sx: { marginX: -2, marginBottom: 2 } }), _jsx(GroupsAndRolesPermissionsTable, { permissions: data, mode: "user" })] }));
}
export function PermissionsSection() {
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: t('components.user.form.permissions_section.title') })), _jsx(Typography, __assign({ component: "p", variant: "body1", sx: { marginBottom: 4 } }, { children: t('components.user.form.permissions_section.info') })), _jsx(OperationsPermissionsSummary, {})] }))] }));
}
