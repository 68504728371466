var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from '@/constants/formats';
export function LicenseExpiryBar(_a) {
    var expiration = _a.expiration;
    var t = useTranslation().t;
    var color = 'success';
    if (expiration.daysRemaining <= 30) {
        color = 'error';
    }
    else if (expiration.daysRemaining <= 90) {
        color = 'warning';
    }
    var subscriptionProgress = expiration.daysRemaining > 100 ? 100 : expiration.daysRemaining;
    var daysRemainingLabel = expiration.daysRemaining === 1
        ? format(t('components.license_expiry_bar.labels.day_remaining'))
        : format(t('components.license_expiry_bar.labels.days_remaining'), expiration.daysRemaining.toString());
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "body2", marginBottom: 1 }, { children: format(t('components.license_expiry_bar.labels.valid_until'), expiration.expiryDate.format(DATE_FORMAT)) })), _jsx(LinearProgress, { color: color, value: subscriptionProgress, "aria-label": daysRemainingLabel }), _jsx(Typography, __assign({ variant: "body2", marginTop: 1 }, { children: daysRemainingLabel }))] }));
}
