var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatErrorMessage, GENERIC_ERROR_MESSAGE } from '@/utils/errors';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var SUPPORT_REPORT_PATH = '/support_report';
export var SUPPORT_REPORT_DOWNLOAD_NAME = 'support.zip';
export var useSupportReport = function (initStatus) {
    if (initStatus === void 0) { initStatus = 'idle'; }
    var t = useTranslation().t;
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var _b = useLighthouseFetch(), executeFetch = _b.executeFetch, decodeResponse = _b.decodeResponse;
    var _c = __read(useState({
        status: initStatus,
        url: '',
    }), 2), data = _c[0], setData = _c[1];
    var generate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var signal, response, _a, _b, _c, _d, err_1;
        var _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    // Early return to prevent multiple requests
                    if (data.status === 'loading')
                        return [2 /*return*/];
                    // Reset
                    if (data.url)
                        URL.revokeObjectURL(data.url);
                    setData({ status: 'loading', url: '' });
                    closeRef.current();
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 9, , 10]);
                    signal = new AbortController().signal;
                    return [4 /*yield*/, executeFetch(SUPPORT_REPORT_PATH, {
                            queryParams: { zip: 1 },
                            headers: new Headers({
                                'Accept': "application/zip",
                                'Content-Type': "application/zip",
                            }),
                            signal: signal,
                        })
                        // Handle 401 status code
                    ];
                case 2:
                    response = _f.sent();
                    // Handle 401 status code
                    decodeResponse(function () { return null; }, response);
                    _a = response.status;
                    switch (_a) {
                        case 200: return [3 /*break*/, 3];
                        case 401: return [3 /*break*/, 5];
                        case 403: return [3 /*break*/, 6];
                    }
                    return [3 /*break*/, 7];
                case 3:
                    alertNotification(t('api.support_report.get.ok.message'), {
                        priority: OGToastPriorities.Success,
                    });
                    _b = setData;
                    _e = { status: 'success' };
                    _d = (_c = URL).createObjectURL;
                    return [4 /*yield*/, response.blob()];
                case 4:
                    _b.apply(void 0, [(_e.url = _d.apply(_c, [_f.sent()]), _e)]);
                    return [2 /*return*/];
                case 5:
                    // `useLighthouseFetch` hook will sign user out
                    setData({ status: 'error', url: '' });
                    return [2 /*return*/];
                case 6: throw new Error(t('api.support_report.get.forbidden.message'));
                case 7: throw new Error(t('api.support_report.get.failure.message'));
                case 8: return [3 /*break*/, 10];
                case 9:
                    err_1 = _f.sent();
                    // Show alert
                    alertNotification(formatErrorMessage(err_1, GENERIC_ERROR_MESSAGE), {
                        header: t('api.support_report.get.error.message'),
                    });
                    // Set status
                    setData({ status: 'error', url: '' });
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }); }, [alertNotification, closeRef, data.status, data.url, decodeResponse, executeFetch, t]);
    useEffect(function () {
        if (data.url) {
            var a_1 = document.createElement('a');
            a_1.href = data.url;
            a_1.download = SUPPORT_REPORT_DOWNLOAD_NAME;
            a_1.rel = 'noopener';
            setTimeout(function () {
                a_1.click();
            }, 0);
        }
        return function () {
            if (data.url)
                URL.revokeObjectURL(data.url);
        };
    }, [data.url]);
    return {
        generate: generate,
        status: data.status,
        url: data.url,
    };
};
