var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useAnchorOpen } from '@/hooks/open';
import { ClearFilterButton, PopoverFilter, PopoverFilterButton } from '../filter-components';
import { ResourceFilterPopoverContent } from './resource-filter-popover-content';
import { ResourceFilterContextProvider, useResourceFilterContext } from './resource-filter-context';
function ResourceFilterContainer() {
    var t = useTranslation().t;
    var popoverAnchorOpen = useAnchorOpen();
    var filterButtonElRef = useRef(null);
    var _a = useResourceFilterContext(), filter = _a.filter, setFilter = _a.setFilter, dispatchSelectedFilter = _a.dispatchSelectedFilter;
    var handleOpen = useCallback(function (event) {
        // Always reset selected filter
        dispatchSelectedFilter({ type: 'set', payload: filter });
        // Always open popover
        popoverAnchorOpen.handleOpen(event);
    }, [dispatchSelectedFilter, filter, popoverAnchorOpen]);
    var handleClear = useCallback(function () {
        // Always clear both filter states
        setFilter(null);
        dispatchSelectedFilter({ type: 'clear' });
        // Focus back on filter button
        if (filterButtonElRef.current) {
            filterButtonElRef.current.focus();
        }
    }, [setFilter, dispatchSelectedFilter]);
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 0.5 }, { children: [_jsx(PopoverFilterButton, { ref: filterButtonElRef, buttonText: t('components.resources.filter.button.label'), filter: filter, onClick: handleOpen }), _jsx(ClearFilterButton, { disabled: !filter, label: t('components.resources.filter.button.clear'), onClick: handleClear }), _jsx(PopoverFilter, __assign({ anchorEl: popoverAnchorOpen.anchorEl, open: popoverAnchorOpen.open, onClose: popoverAnchorOpen.handleClose }, { children: _jsx(ResourceFilterPopoverContent, { handlePopoverClose: popoverAnchorOpen.handleClose }) }))] })));
}
export function ResourceFilter(props) {
    return (_jsx(ResourceFilterContextProvider, __assign({}, props, { children: _jsx(ResourceFilterContainer, {}) })));
}
