var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { portStatusFilterToQuery } from '@/utils/query';
import { QUERY_PARAM_CONFIGURED_PORT_FILTER_KEY } from '@/constants/query-params-keys';
import { PortFilterOptions } from './types';
import { IQueryOperation } from '../../../../types/query/types';
export function validateConfiguredPortFilterQueryParam(queryParam) {
    if (!queryParam)
        return undefined;
    return Object.values(PortFilterOptions).find(function (acceptableParam) { return acceptableParam === queryParam; });
}
export var usePortStatusFilter = function (ports) {
    var _a = __read(useSearchParams(), 2), searchParams = _a[0], setSearchParams = _a[1];
    var statusPortsParamValue = useMemo(function () { return validateConfiguredPortFilterQueryParam(searchParams.get(QUERY_PARAM_CONFIGURED_PORT_FILTER_KEY)); }, [searchParams]);
    var setStatusPortsParamValue = useCallback(function (queryParam) {
        setSearchParams(function (prevSearchParams) {
            if (queryParam === PortFilterOptions.all) {
                prevSearchParams.delete(QUERY_PARAM_CONFIGURED_PORT_FILTER_KEY);
            }
            else {
                prevSearchParams.set(QUERY_PARAM_CONFIGURED_PORT_FILTER_KEY, queryParam);
            }
            return prevSearchParams;
        });
    }, [setSearchParams]);
    var portStatusFilter = useMemo(function () {
        if (ports || !statusPortsParamValue || statusPortsParamValue === PortFilterOptions.all) {
            return undefined;
        }
        var operator = statusPortsParamValue === PortFilterOptions.configured ? IQueryOperation.equal : IQueryOperation.notEqual;
        return portStatusFilterToQuery(operator);
    }, [statusPortsParamValue, ports]);
    var statusFilteredPorts = useMemo(function () {
        if (!ports) {
            return [];
        }
        if (!statusPortsParamValue || statusPortsParamValue === PortFilterOptions.all) {
            return ports;
        }
        return ports.filter(function (port) { return (statusPortsParamValue === PortFilterOptions.configured ? port.mode === 'consoleServer' : port.mode !== 'consoleServer'); });
    }, [statusPortsParamValue, ports]);
    return {
        portStatusFilter: portStatusFilter,
        statusFilteredPorts: statusFilteredPorts,
        statusPortsParamValue: statusPortsParamValue,
        setStatusPortsParamValue: setStatusPortsParamValue,
    };
};
