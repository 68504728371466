var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useLocalStorageState from 'use-local-storage-state';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { deleteCookie, getCookie, setCookie } from '@/utils/browser';
import { DEFAULT_QUERY_REFETCH_FREQUENCY, MIN_QUERY_REFETCH_FREQUENCY } from '@/constants/limits';
export var LIGHTHOUSE_API_VERSION = getCookie('lighthouseApiVersion', 'v3.7');
export var SimpleStatus;
(function (SimpleStatus) {
    SimpleStatus[SimpleStatus["Success"] = 0] = "Success";
    SimpleStatus[SimpleStatus["Failed"] = 1] = "Failed";
    SimpleStatus[SimpleStatus["Error"] = 2] = "Error";
})(SimpleStatus || (SimpleStatus = {}));
export var SessionStatus;
(function (SessionStatus) {
    SessionStatus[SessionStatus["Valid"] = 0] = "Valid";
    SessionStatus[SessionStatus["Failed"] = 1] = "Failed";
    SessionStatus[SessionStatus["Error"] = 2] = "Error";
    SessionStatus[SessionStatus["Expired"] = 3] = "Expired";
    SessionStatus[SessionStatus["Challenge"] = 4] = "Challenge";
    SessionStatus[SessionStatus["Unauthorized"] = 5] = "Unauthorized";
})(SessionStatus || (SessionStatus = {}));
export var LighthouseErrorCodes;
(function (LighthouseErrorCodes) {
    LighthouseErrorCodes[LighthouseErrorCodes["NotFound"] = 28] = "NotFound";
    LighthouseErrorCodes[LighthouseErrorCodes["InvalidField"] = 41] = "InvalidField";
    LighthouseErrorCodes[LighthouseErrorCodes["NotAuthorized"] = 43] = "NotAuthorized";
    LighthouseErrorCodes[LighthouseErrorCodes["PermissionDenied"] = 47] = "PermissionDenied";
    LighthouseErrorCodes[LighthouseErrorCodes["UnknownType"] = 52] = "UnknownType";
    LighthouseErrorCodes[LighthouseErrorCodes["InternalServerError"] = 85] = "InternalServerError";
})(LighthouseErrorCodes || (LighthouseErrorCodes = {}));
/**
 * Storage Keys
 */
export var TOKEN_KEY = 'auth.token';
export var COOKIE_TOKEN_KEY = 'token';
export var REACT_QUERY_REFETCH_INTERVAL_KEY = 'reactQuery.refetchInterval';
/**
 * Define the global react-query fetch interval
 */
export function getReactQueryFetchInterval() {
    var storedQueryRefetchInterval = localStorage.getItem(REACT_QUERY_REFETCH_INTERVAL_KEY);
    if (storedQueryRefetchInterval === null)
        return DEFAULT_QUERY_REFETCH_FREQUENCY;
    var storedQueryRefetchIntervalNumber = Number(storedQueryRefetchInterval);
    if (Number.isNaN(storedQueryRefetchIntervalNumber))
        return DEFAULT_QUERY_REFETCH_FREQUENCY;
    return Math.max(storedQueryRefetchIntervalNumber, MIN_QUERY_REFETCH_FREQUENCY);
}
export function useLighthouseToken() {
    var queryClient = useQueryClient();
    var _a = __read(useLocalStorageState(TOKEN_KEY), 3), localStorageToken = _a[0], setLocalStorageToken = _a[1], removeLocalStorageToken = _a[2].removeItem;
    var setToken = useCallback(function (token) {
        if (token !== undefined) {
            setLocalStorageToken(token);
            // cookie token required for terminal and manifest download
            setCookie(COOKIE_TOKEN_KEY, token);
        }
    }, [setLocalStorageToken]);
    var removeTokenAndClearQueryCache = useCallback(function () {
        removeLocalStorageToken();
        deleteCookie(COOKIE_TOKEN_KEY);
        // Cancel any outgoing requests
        queryClient.cancelQueries();
        queryClient.clear();
    }, [queryClient, removeLocalStorageToken]);
    return { token: localStorageToken, setToken: setToken, removeTokenAndClearQueryCache: removeTokenAndClearQueryCache };
}
export var CHALLENGE_TOKEN_KEY = 'challenge.token';
export var COOKIE_CHALLENGE_TOKEN_KEY = 'challenge';
var useLocalStorageChallengeToken = function () { return useLocalStorageState(TOKEN_KEY, { storageSync: true }); };
export function useLighthouseChallengeToken() {
    var queryClient = useQueryClient();
    var _a = __read(useLocalStorageChallengeToken(), 3), localStorageToken = _a[0], setLocalStorageToken = _a[1], removeLocalStorageToken = _a[2].removeItem;
    var setToken = useCallback(function (token) {
        if (token !== undefined) {
            setLocalStorageToken(token);
            // cookie token required for terminal and manifest download
            setCookie(COOKIE_TOKEN_KEY, token);
        }
    }, [setLocalStorageToken]);
    var removeTokenAndClearQueryCache = useCallback(function () {
        removeLocalStorageToken();
        deleteCookie(COOKIE_TOKEN_KEY);
        // Cancel any outgoing requests
        queryClient.cancelQueries();
        queryClient.clear();
    }, [queryClient, removeLocalStorageToken]);
    return { token: localStorageToken, setToken: setToken, removeTokenAndClearQueryCache: removeTokenAndClearQueryCache };
}
function queryParamReducer(current, _a) {
    var _b = __read(_a, 2), key = _b[0], value = _b[1];
    if (value === undefined || typeof value === 'function') {
        return current;
    }
    if (value instanceof Array) {
        var entries = value.map(function (v) { return [key, v]; });
        return entries.reduce(queryParamReducer, current);
    }
    if (typeof value === 'string') {
        return __spreadArray(__spreadArray([], __read(current), false), [[key, value]], false);
    }
    return __spreadArray(__spreadArray([], __read(current), false), [[key, JSON.stringify(value)]], false);
}
/**
 * Generates formatted url query params from an object to append to path/url
 */
export function formatSearchParams(queryParams) {
    var searchParams = new URLSearchParams(Object.entries(queryParams).reduce(queryParamReducer, [])).toString();
    if (searchParams) {
        searchParams = "?".concat(searchParams);
    }
    return searchParams;
}
// * Format RESTful path with API version
/**
 * Generates a full RESTful path for the Lighthouse API
 * @param path The path to fetch.
 * @param queryParams The query parameters to include in the request.
 * @returns A fully qualified path for the LiPy API
 */
export var formatApiPath = function (path, queryParams) {
    var searchParams = queryParams ? formatSearchParams(queryParams) : '';
    var normalisedPath = path.startsWith('/') ? path : "/".concat(path);
    return "/api/".concat(LIGHTHOUSE_API_VERSION).concat(normalisedPath).concat(searchParams);
};
export var triggerDownload = function (blob, filename) {
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
};
