var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { OGButton, OGButtonLink } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { OGTable } from '@opengear/table';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useGroupsPermissions } from '@/api/permissions/groups';
import PermissionsPreview from '@/components/accounts/permissions/permissions-preview';
import { usePasswordValidation } from '@/api/sessions';
import { useDeviceView } from '@/hooks/use-device-view';
import { useAlertNotification } from '@/hooks/alert-notification';
import { formatErrorMessage } from '@/utils/errors';
var PREVIEW_GROUP_PERMISSIONS_LOADER_ID = 'group-permissions-loader';
function GroupPreviewContent(_a) {
    var groupName = _a.groupName;
    var _b = useGroupsPermissions(groupName), data = _b.data, isLoading = _b.isLoading;
    if (isLoading) {
        return (_jsx(Stack, __assign({ height: "100%", alignItems: "center", justifyContent: "center" }, { children: _jsx(CircularProgress, { "data-testid": PREVIEW_GROUP_PERMISSIONS_LOADER_ID }) })));
    }
    return _jsx(PermissionsPreview, { name: groupName, permissions: data });
}
export function GroupPreviewModal(_a) {
    var groupName = _a.groupName, handleClose = _a.handleClose;
    var t = useTranslation().t;
    return (_jsxs(OGDialog, __assign({ open: !!groupName, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: groupName }), _jsx(DialogContent, { children: _jsx(GroupPreviewContent, { groupName: groupName }) }), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: handleClose, autoFocus: true }, { children: t('global.buttons.close') })) })] })));
}
export function AddGroupsModal(_a) {
    var control = _a.control, open = _a.open, groupData = _a.groupData, handleClose = _a.handleClose, handleAddGroups = _a.handleAddGroups;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = __read(useState([]), 2), selectedGroups = _b[0], setSelectedGroups = _b[1];
    var _c = __read(useState(), 2), previewGroup = _c[0], setPreviewGroup = _c[1];
    var groupNames = useWatch({ control: control, name: 'groupNames' });
    var rows = useMemo(function () { return groupData.filter(function (group) { return !groupNames.includes(group.groupname); }); }, [groupNames, groupData]);
    var onSubmit = useCallback(function () {
        handleAddGroups(selectedGroups);
        handleClose();
    }, [handleAddGroups, handleClose, selectedGroups]);
    var handleSelectionModelChange = useCallback(function (newSelection) {
        // getRowId uses groupname for id so newSelection will be string[]
        setSelectedGroups(newSelection);
    }, []);
    var renderGroupsNameCell = useCallback(function (_a) {
        var tabIndex = _a.tabIndex, value = _a.value;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                setPreviewGroup(value);
            }, propagateSpacebar: false }, { children: value })));
    }, []);
    var columns = [
        {
            field: 'groupname',
            headerName: t('components.user.group_modal.headers.name'),
            renderCell: renderGroupsNameCell,
            minWidth: 200,
        },
        { field: 'description', headerName: t('components.user.group_modal.headers.description'), flex: 1, minWidth: 200 },
    ];
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose, fullWidth: true, sx: {
            '& .MuiDialog-paper': {
                maxWidth: '740px',
            },
        } }, { children: [previewGroup ? (_jsxs(Stack, __assign({ direction: isMobileView ? 'row-reverse' : 'column', alignItems: isMobileView ? 'center' : 'start', justifyContent: "start", marginBottom: 1 }, { children: [_jsx(DialogTitle, __assign({ sx: { paddingBottom: isMobileView ? 2 : 1 } }, { children: previewGroup })), _jsx(Box, __assign({ width: "fit-content" }, { children: _jsx(OGButtonLink
                        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        , __assign({ 
                            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, startIcon: _jsx(ChevronLeftIcon, { fontSize: "medium" }), variant: "standard", sx: { marginLeft: 1, paddingRight: 1, paddingY: 1 }, onClick: function () {
                                setPreviewGroup(undefined);
                            } }, { children: t('global.buttons.back') })) }))] }))) : (_jsx(DialogTitle, { children: t('components.user.group_modal.title') })), _jsx(Divider, {}), _jsx(DialogContent, __assign({ tabIndex: -1, sx: { height: '500px' } }, { children: previewGroup ? (_jsx(GroupPreviewContent, { groupName: previewGroup })) : (_jsx(OGTable, { 
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, "aria-label": t('components.user.group_modal.title'), checkboxSelection: true, rows: rows, columns: columns, noRowsMessage: t('components.user.group_modal.no_rows_message'), getRowId: function (group) { return group.groupname; }, rowSelectionModel: selectedGroups, onRowSelectionModelChange: handleSelectionModelChange, isRowSelectable: function (params) { return params.row.enabled === true; } })) })), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: handleClose }, { children: t('global.buttons.cancel') })), previewGroup ? null : (_jsx(OGButton, __assign({ disabled: selectedGroups.length < 1, onClick: onSubmit }, { children: t('global.buttons.add') })))] })] })));
}
function PasswordConfirmationModalContent(_a) {
    var _this = this;
    var onSuccess = _a.onSuccess, handleClose = _a.handleClose, username = _a.username, children = _a.children, title = _a.title;
    var id = 'password-confirmation';
    var t = useTranslation().t;
    var validatePassword = usePasswordValidation();
    var _b = useForm({ defaultValues: { username: username, password: '' } }), control = _b.control, formSubmitHandler = _b.handleSubmit, _c = _b.formState, isValid = _c.isValid, isSubmitting = _c.isSubmitting;
    var alertNotification = useAlertNotification().alertNotification;
    var handleSubmit = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var validated;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, validatePassword(data)];
                case 1:
                    validated = _a.sent();
                    if (validated) {
                        onSuccess();
                        handleClose();
                    }
                    else {
                        alertNotification(formatErrorMessage(t('components.user.password_modal.error.message')), {
                            header: t('components.user.password_modal.error.header'),
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [validatePassword, onSuccess, handleClose, alertNotification, t]);
    return (_jsxs(Stack, __assign({ "aria-label": id, component: "form", id: id, onSubmit: formSubmitHandler(handleSubmit) }, { children: [_jsx(DialogTitle, { children: title }), _jsxs(DialogContent, __assign({ tabIndex: -1 }, { children: [children, _jsx(Controller, { control: control, name: "password", rules: { required: true }, render: function (_a) {
                            var _b;
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ fullWidth: true, id: "current-password", autoComplete: "current-password", label: t('components.user.password_modal.label'), margin: "normal", type: "password", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field, { sx: { marginTop: 2 }, 
                                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus: true })));
                        } })] })), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: handleClose }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ type: "submit", disabled: !isValid || isSubmitting }, { children: t('global.buttons.confirm') }))] })] })));
}
export function PasswordConfirmationModal(_a) {
    var open = _a.open, onSuccess = _a.onSuccess, handleClose = _a.handleClose, username = _a.username, children = _a.children, title = _a.title;
    return (_jsx(OGDialog, __assign({ open: open, onClose: handleClose }, { children: _jsx(PasswordConfirmationModalContent, __assign({ title: title, onSuccess: onSuccess, handleClose: handleClose, username: username }, { children: children })) })));
}
export function SshAuthKeyModal(_a) {
    var useOpen = _a.useOpen, children = _a.children, title = _a.title, titleId = _a.titleId;
    var open = useOpen.open, handleClose = useOpen.handleClose;
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ id: titleId }, { children: title })), children] })));
}
