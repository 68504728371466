var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { OGTable } from '@opengear/table';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGAutocomplete } from '@opengear/inputs/autocomplete';
import { useCallback, useState } from 'react';
import { OGFileTracker, OGFileUploader } from '@opengear/file-uploader';
import { OGButton } from '@opengear/buttons';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import Tooltip from '@mui/material/Tooltip';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { EMAIL_ADDRESS_PATTERN, HOSTNAME_PATTERN } from '@/constants/regex';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
import { useDownloadCSR, useUpdateHTTPS } from '@/api/services/https';
import { transformResponseToFormState } from './transformers';
import { CommonNameMaxLength, EmailMaxLength, HTTPS_KEY_LENGTH_OPTIONS, LocalityMaxLength, OrgFieldMaxLength, OrgUnitMaxLength, PasswordMaxLength, StateMaxLength, } from './constants';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { formatDateTimestamp } from '@/utils/datetime';
export function SSLCertTable(_a) {
    var cert = _a.cert;
    var t = useTranslation().t;
    var columns = [
        {
            field: 'common_name',
            headerName: t('pages.https_certificate.table.common_name.label'),
            flex: 2,
            minWidth: 120,
        },
        {
            field: 'org_unit',
            headerName: t('pages.https_certificate.table.org_unit.label'),
        },
        {
            field: 'organization',
            headerName: t('pages.https_certificate.table.org.label'),
            minWidth: 120,
        },
        {
            field: 'locality',
            headerName: t('pages.https_certificate.table.city.label'),
        },
        {
            field: 'state',
            headerName: t('pages.https_certificate.table.state.label'),
        },
        {
            field: 'country',
            headerName: t('pages.https_certificate.table.country.label'),
            width: 80,
        },
        {
            field: 'email',
            headerName: t('pages.https_certificate.table.email.label'),
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'valid_from',
            headerName: t('pages.https_certificate.table.issue_date.label'),
            valueGetter: function (_a) {
                var value = _a.value;
                return formatDateTimestamp(dayjs(value).unix());
            },
            minWidth: 120,
        },
        {
            field: 'valid_to',
            headerName: t('pages.https_certificate.table.expiry_date.label'),
            valueGetter: function (_a) {
                var value = _a.value;
                return formatDateTimestamp(dayjs(value).unix());
            },
            minWidth: 120,
        },
    ];
    return (_jsx(OGTable, { id: "https-cert-table", "aria-label": t('pages.https_certificate.current_cert_header'), rows: [cert], columns: columns.map(function (column) { return (__assign(__assign({}, column), { sortable: false })); }), getRowId: function (row) { return row.common_name; } }));
}
export function CSRTable(_a) {
    var _this = this;
    var csr = _a.csr;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), setValue = _b.setValue, reset = _b.reset, permissions = _b.permissions;
    var downloadCSR = useDownloadCSR().downloadCSR;
    var updateHTTPS = useUpdateHTTPS();
    var clearCSR = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setValue('certfile', null);
                    return [4 /*yield*/, updateHTTPS({ https: { csr: { csr: null } } })];
                case 1:
                    newState = _a.sent();
                    if (newState) {
                        reset(transformResponseToFormState(newState, t));
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [reset, setValue, t, updateHTTPS]);
    var columns = [
        {
            field: 'common_name',
            headerName: t('pages.https_certificate.table.common_name.label'),
            flex: 2,
            minWidth: 120,
        },
        {
            field: 'org_unit',
            headerName: t('pages.https_certificate.table.org_unit.label'),
        },
        {
            field: 'organization',
            headerName: t('pages.https_certificate.table.org.label'),
            minWidth: 120,
        },
        {
            field: 'locality',
            headerName: t('pages.https_certificate.table.city.label'),
        },
        {
            field: 'state',
            headerName: t('pages.https_certificate.table.state.label'),
        },
        {
            field: 'country',
            headerName: t('pages.https_certificate.table.country.label'),
            width: 80,
        },
        {
            field: 'email',
            headerName: t('pages.https_certificate.table.email.label'),
            flex: 1,
            minWidth: 80,
        },
        {
            field: 'download',
            headerName: '',
            headerClassName: 'FocusDisabled',
            width: 40,
            renderCell: function (_a) {
                var row = _a.row;
                return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.https_certificate.download_label') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('pages.https_certificate.download_label'), size: "small", sx: { '&.MuiButton-root': { height: '31px', margin: '2px' } }, startIcon: _jsx(FileDownloadIcon, {}), variant: "borderless", onClick: function () { return row.csr && row.common_name && downloadCSR(row.csr, row.common_name); }, propagateSpacebar: false }) }) })));
            },
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'FocusDisabled',
            width: 40,
            renderCell: function () {
                var disabled = !permissions.hasEditPermission;
                return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: disabled ? t('pages.https_certificate.delete_label_disabled') : t('pages.https_certificate.delete_label') }, { children: _jsx("div", { children: _jsx(DeleteConfirmationModal, __assign({ title: t('pages.https_certificate.delete_header'), onDelete: clearCSR, buttonProps: {
                                'size': 'small',
                                'aria-label': t('pages.https_certificate.delete_label'),
                            }, disabled: disabled }, { children: _jsx(DialogContentText, { children: t('pages.https_certificate.delete_confirmation') }) })) }) })));
            },
        },
    ];
    return (_jsx(OGTable, { id: "https-csr-table", "aria-label": t('pages.https_certificate.current_csr_header'), rows: [csr], columns: columns.map(function (column) { return (__assign(__assign({}, column), { sortable: false })); }), getRowId: function () { return 'my-csr-id'; } }));
}
export function CommonNameField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "common_name", rules: {
            required: requiredFieldError(t('pages.https_certificate.form.fields.common_name.label')),
            maxLength: {
                value: CommonNameMaxLength,
                message: maxLengthFieldError(t('pages.https_certificate.form.fields.common_name.label'), CommonNameMaxLength.toString()),
            },
            pattern: { value: HOSTNAME_PATTERN, message: invalidFieldError(t('pages.https_certificate.form.fields.common_name.label')) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "common_name", required: true, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.https_certificate.form.fields.common_name.label'), _jsx(OGIconTooltip, { title: t('pages.https_certificate.form.fields.common_name.tooltip') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function OrgUnitField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "org_unit", rules: {
            required: requiredFieldError(t('pages.https_certificate.form.fields.org_unit.label')),
            maxLength: {
                value: OrgUnitMaxLength,
                message: maxLengthFieldError(t('pages.https_certificate.form.fields.org_unit.label'), OrgUnitMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "org_unit", required: true, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.https_certificate.form.fields.org_unit.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function OrgField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "organization", rules: {
            required: requiredFieldError(t('pages.https_certificate.form.fields.org.label')),
            maxLength: { value: OrgFieldMaxLength, message: maxLengthFieldError(t('pages.https_certificate.form.fields.org.label'), OrgFieldMaxLength.toString()) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "organization", required: true, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.https_certificate.form.fields.org.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function LocalityField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "locality", rules: {
            required: requiredFieldError(t('pages.https_certificate.form.fields.locality.label')),
            maxLength: {
                value: LocalityMaxLength,
                message: maxLengthFieldError(t('pages.https_certificate.form.fields.locality.label'), LocalityMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "locality", required: true, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.https_certificate.form.fields.locality.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function StateField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "state", rules: {
            required: requiredFieldError(t('pages.https_certificate.form.fields.state.label')),
            maxLength: { value: StateMaxLength, message: maxLengthFieldError(t('pages.https_certificate.form.fields.state.label'), StateMaxLength.toString()) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "state", required: true, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.https_certificate.form.fields.state.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function CountrySelect() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    var options = Object.entries(t('global.country_codes')).map(function (_a) {
        var _b = __read(_a, 2), code = _b[0], translation = _b[1];
        return ({ label: translation, code: code });
    });
    return (_jsx(Controller, { control: control, name: "country", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
            return (_jsx(OGAutocomplete, __assign({ id: "country", label: t('pages.https_certificate.form.fields.country.label'), options: options, disabled: !permissions.hasEditPermission, error: !!error, sx: { maxWidth: isMobileView ? undefined : 300 }, onChange: function (_, values) { return onChange(values); }, isOptionEqualToValue: function (option, value) {
                    if (typeof option !== 'string' && typeof value !== 'string') {
                        return option.label === value.label && option.code === value.code;
                    }
                    return option === value;
                } }, field, { disableClearable: true })));
        } }));
}
export function EmailField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "email", rules: {
            required: requiredFieldError(t('pages.https_certificate.form.fields.email.label')),
            maxLength: { value: EmailMaxLength, message: maxLengthFieldError(t('pages.https_certificate.form.fields.email.label'), EmailMaxLength.toString()) },
            pattern: { value: EMAIL_ADDRESS_PATTERN, message: invalidFieldError(t('pages.https_certificate.form.fields.email.label')) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "email", required: true, disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.https_certificate.form.fields.email.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function KeyLengthSelect() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "key_length", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
            return (_jsx(OGAutocomplete, __assign({ id: "key_length", label: t('pages.https_certificate.form.fields.key_length.label'), options: HTTPS_KEY_LENGTH_OPTIONS, disabled: !permissions.hasEditPermission, error: !!error, sx: { maxWidth: isMobileView ? undefined : 300 }, onChange: function (_, values) { return onChange(values); }, defaultValue: HTTPS_KEY_LENGTH_OPTIONS[0] }, field, { disableClearable: true })));
        } }));
}
export function PasswordField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "password", rules: {
            maxLength: {
                value: PasswordMaxLength,
                message: maxLengthFieldError(t('pages.https_certificate.form.fields.password.label'), PasswordMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "password", type: "password", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.https_certificate.form.fields.password.label'), _jsx(OGIconTooltip, { title: t('pages.https_certificate.form.fields.password.tooltip') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function ConfirmPasswordField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, watch = _a.watch;
    var isMobileView = useDeviceView().isMobileView;
    var password = watch('password');
    var validatePasswordsMatch = function (value) { return (value === password ? true : t('global.form.password_not_matched')); };
    return (_jsx(Controller, { control: control, name: "confirm_password", rules: {
            validate: { passwordValid: validatePasswordsMatch },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "confirmPassword", type: "password", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.https_certificate.form.fields.confirm_password.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, sx: { width: isMobileView ? '100%' : 600 } }, field)));
        } }));
}
export function PrivateKeyUploader() {
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var _a = useFormContainerContext(), control = _a.control, setValue = _a.setValue, permissions = _a.permissions;
    var _b = __read(useState(''), 2), fileName = _b[0], setFileName = _b[1];
    var onChangeHandler = function (files) {
        var file = files.length ? files[0] : null;
        if (!file) {
            return;
        }
        var reader = new FileReader();
        reader.onload = function (event) {
            var _a;
            var fileContent = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.result;
            if (fileContent) {
                setValue('private_key', fileContent.toString(), { shouldDirty: true });
                setFileName(file.name);
            }
            else {
                alertNotification(t('pages.https_certificate.form.fields.private_key.errors.empty_contents'), {
                    header: t('pages.https_certificate.form.fields.private_key.errors.header'),
                    priority: OGToastPriorities.Error,
                });
            }
        };
        reader.readAsText(file);
    };
    return (_jsxs(FormControl, { children: [_jsxs(InputLabel, __assign({ htmlFor: "private_key", sx: { marginBottom: 1 } }, { children: [t('pages.https_certificate.form.fields.private_key.label'), _jsx(OGIconTooltip, { title: t('pages.https_certificate.form.fields.private_key.tooltip') })] })), _jsx(Controller, { control: control, name: "private_key", render: function (_a) {
                    var field = _a.field;
                    return (_jsx(OGFileUploader, __assign({ accept: ".pem, .crt, .cer, .key, .txt", id: "private_key", ariaLabel: t('pages.https_certificate.form.fields.private_key.label'), onChange: function (files) { return onChangeHandler(files); }, disabled: !permissions.hasEditPermission }, { children: field.value && _jsx(OGFileTracker, { id: fileName, name: fileName, onCancel: function () { return field.onChange(null); } }, fileName) })));
                } })] }));
}
export function CertFileUploader() {
    var t = useTranslation().t;
    var alertNotification = useAlertNotification().alertNotification;
    var _a = useFormContainerContext(), control = _a.control, setValue = _a.setValue, permissions = _a.permissions;
    var _b = __read(useState(''), 2), fileName = _b[0], setFileName = _b[1];
    var onChangeHandler = function (files) {
        var file = files.length ? files[0] : null;
        if (!file) {
            return;
        }
        var reader = new FileReader();
        reader.onload = function (event) {
            var _a;
            var fileContent = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.result;
            if (fileContent) {
                setValue('certfile', fileContent.toString(), { shouldDirty: true });
                setFileName(file.name);
            }
            else {
                alertNotification(t('pages.https_certificate.form.fields.certfile.errors.empty_contents'), {
                    header: t('pages.https_certificate.form.fields.certfile.errors.header'),
                    priority: OGToastPriorities.Error,
                });
            }
        };
        reader.readAsText(file);
    };
    return (_jsx(Controller, { control: control, name: "certfile", render: function (_a) {
            var field = _a.field;
            return (_jsx(OGFileUploader, __assign({ accept: ".pem, .crt, .cer, .key, .txt", id: "lh_cert", ariaLabel: t('pages.https_certificate.form.fields.certfile.label'), onChange: function (files) { return onChangeHandler(files); }, disabled: !permissions.hasEditPermission }, { children: field.value && _jsx(OGFileTracker, { id: fileName, name: fileName, onCancel: function () { return field.onChange(null); } }, fileName) })));
        } }));
}
export function CreateCSRButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), _b = _a.formState, isValid = _b.isValid, isSubmitting = _b.isSubmitting, permissions = _a.permissions;
    return (_jsx(OGButton, __assign({ type: "submit", disabled: !isValid || !permissions.hasEditPermission || isSubmitting, loading: isSubmitting }, { children: t('global.buttons.apply') })));
}
