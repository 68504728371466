var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { RemoteAuthServerFields, BaseDnField, BindDnField, PasswordField, ConfirmPasswordField, UsernameAttrField, GroupMembershipAttrField, IgnoreReferralCheckbox, SSLModeButtons, IgnoreSSLCertErrorsCheckbox, SSLCertFileUploader, } from './ldap-form-controls';
export function LdapSettings() {
    var t = useTranslation().t;
    return (_jsxs(Stack, __assign({ direction: "column", gap: 1, "aria-label": "Ldap section" }, { children: [_jsx(Divider, {}), _jsx(RemoteAuthServerFields, {}), _jsx(Divider, {}), _jsx(BaseDnField, {}), _jsx(BindDnField, {}), _jsx(PasswordField, {}), _jsx(ConfirmPasswordField, {}), _jsx(UsernameAttrField, {}), _jsx(GroupMembershipAttrField, {}), _jsx(Box, __assign({ sx: { paddingBottom: 1 } }, { children: _jsx(IgnoreReferralCheckbox, {}) })), _jsx(SSLModeButtons, {}), _jsx(Box, __assign({ sx: { paddingTop: 1, paddingBottom: 1 } }, { children: _jsx(IgnoreSSLCertErrorsCheckbox, {}) })), _jsxs(Typography, __assign({ component: "h3", variant: "h3", sx: { textTransform: 'uppercase' }, marginY: 2 }, { children: [t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.heading'), _jsx(OGIconTooltip, { title: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.ca_cert.tooltip') })] })), _jsx(SSLCertFileUploader, {})] })));
}
