var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
import { EngineIDField, SecurityLevelButtons, UsernameField, AuthProtocolButtons, AuthPasswordField, ConfirmAuthPasswordField, PrivacyProtocolButtons, PrivacyPasswordField, ConfirmPrivacyPasswordField, } from './v3-settings-controls';
export function V3Settings() {
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var selectedSecurityLevel = watch('securityLevel');
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "h3", marginY: 2, variant: "h3" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_service.form.v3_form_subheading') })), _jsx(EngineIDField, {}), _jsx(SecurityLevelButtons, {}), _jsx(UsernameField, {}), selectedSecurityLevel !== 'noAuthNoPriv' && (_jsxs(_Fragment, { children: [_jsx(AuthProtocolButtons, {}), _jsx(AuthPasswordField, {}), _jsx(ConfirmAuthPasswordField, {})] })), selectedSecurityLevel === 'authPriv' && (_jsxs(_Fragment, { children: [_jsx(PrivacyProtocolButtons, {}), _jsx(PrivacyPasswordField, {}), _jsx(ConfirmPrivacyPasswordField, {})] }))] }));
}
