var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { rest } from 'msw';
import { formatApiPath } from '@/api/utils';
import { ValidationError } from '../../../types/utils';
export var MOCK_OBJECT_PATH = '/mock-object';
export function formatMockObjectPath(mockObjectId) {
    if (mockObjectId === void 0) { mockObjectId = ':mockObjectId'; }
    return "".concat(MOCK_OBJECT_PATH, "/").concat(mockObjectId);
}
export var defaultMockObjects = [
    { id: '1', label: 'Object 1' },
    { id: '2', label: 'Object 2' },
    { id: '3', label: 'Object 3' },
];
var mockValidationError = {
    text: 'Validation Error',
};
export var mockObjectGetHandler = rest.get(formatApiPath(formatMockObjectPath()), function (req, res, ctx) { return res(ctx.status(200), ctx.json(defaultMockObjects[0])); });
export var mockObjectPostHandler = rest.post(formatApiPath(MOCK_OBJECT_PATH), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () { var _a, _b, _c, _d; return __generator(this, function (_e) {
    switch (_e.label) {
        case 0:
            _a = res;
            _b = [ctx.status(200)];
            _d = (_c = ctx).json;
            return [4 /*yield*/, req.json()];
        case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([_d.apply(_c, [_e.sent()])]))];
    }
}); }); });
export var mockObjectPutHandler = rest.put(formatApiPath(formatMockObjectPath()), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () { var _a, _b, _c, _d; return __generator(this, function (_e) {
    switch (_e.label) {
        case 0:
            _a = res;
            _b = [ctx.status(200)];
            _d = (_c = ctx).json;
            return [4 /*yield*/, req.json()];
        case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([_d.apply(_c, [_e.sent()])]))];
    }
}); }); });
export var mockObjectDeleteHandler = rest.delete(formatApiPath(formatMockObjectPath()), function (req, res, ctx) { return res(ctx.status(204)); });
export function mockDecoder(response) {
    return __awaiter(this, void 0, Promise, function () {
        var status, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    status = response.status;
                    if (process.env.ERROR_ON_VALIDATION) {
                        throw new ValidationError('Validation failed');
                    }
                    _a = status;
                    switch (_a) {
                        case 200: return [3 /*break*/, 1];
                        case 201: return [3 /*break*/, 1];
                        case 202: return [3 /*break*/, 1];
                        case 204: return [3 /*break*/, 3];
                        case 400: return [3 /*break*/, 4];
                        case 401: return [3 /*break*/, 6];
                        case 403: return [3 /*break*/, 6];
                        case 404: return [3 /*break*/, 6];
                        case 500: return [3 /*break*/, 7];
                        case 504: return [3 /*break*/, 8];
                    }
                    return [3 /*break*/, 9];
                case 1:
                    _b = {};
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, (_b.data = _d.sent(), _b.status = status, _b)];
                case 3: return [2 /*return*/, { data: null, status: status }];
                case 4:
                    _c = {};
                    return [4 /*yield*/, response.json()];
                case 5: return [2 /*return*/, (_c.data = (_d.sent()), _c.status = 400, _c)];
                case 6: return [2 /*return*/, { data: null, status: status }];
                case 7: return [2 /*return*/, { data: { error: [mockValidationError] }, status: status }];
                case 8: return [2 /*return*/, { data: null, status: status }];
                case 9: throw new Error("'".concat(status, "' status is unexpected for this endpoint"));
            }
        });
    });
}
