var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OGLikeShine, OGSystemShine } from '@opengear/icons/table';
function NoRowsOverlay(_a) {
    var message = _a.message, secondaryMessage = _a.secondaryMessage, noRowTableIcon = _a.noRowTableIcon;
    var renderEmptyTableIcon = useMemo(function () {
        if (noRowTableIcon === 'systemShine') {
            return _jsx(OGSystemShine, { style: { fontSize: '160px' } });
        }
        return _jsx(OGLikeShine, { style: { fontSize: '160px' } });
    }, [noRowTableIcon]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: renderEmptyTableIcon })), _jsx(Box, __assign({ sx: { width: '100%', height: '72px', display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' } }, { children: _jsxs(Typography, __assign({ component: "span", sx: { fontWeight: 600 } }, { children: [message, secondaryMessage && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(Typography, __assign({ component: "span" }, { children: secondaryMessage }))] }))] })) }))] }));
}
export default NoRowsOverlay;
