var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import { OGButton } from '@opengear/buttons';
import { QueryField } from './query-field';
import { QueryOperator } from './query-operator';
import { QueryValue } from './query-value';
import { useDeviceView } from '@/hooks/use-device-view';
export function QueryItem(_a) {
    var query = _a.query, queryFields = _a.queryFields, queryFieldRef = _a.queryFieldRef, tags = _a.tags, tagValues = _a.tagValues, onChange = _a.onChange, onClear = _a.onClear, sx = _a.sx, setError = _a.setError;
    var t = useTranslation().t;
    var ref = useRef(null);
    var isMobileView = useDeviceView().isMobileView;
    var handleClear = useCallback(function () {
        var _a;
        // if this component has focus, then we want to shift focus onto the next remaining component
        if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement)) {
            var next = ref.current.nextElementSibling;
            // if the next component is the 'Add criteria' button, then focus on it
            if ((next === null || next === void 0 ? void 0 : next.tagName) === 'BUTTON') {
                ;
                next.focus();
            }
            // if the next component is an AND/OR toggle button group,
            // skip it and focus on the QueryField of the next QueryItem component
            if ((next === null || next === void 0 ? void 0 : next.tagName) === 'DIV') {
                next = next.nextElementSibling;
                while ((next === null || next === void 0 ? void 0 : next.tagName) === 'DIV') {
                    next = next.firstElementChild;
                }
                if ((next === null || next === void 0 ? void 0 : next.tagName) === 'INPUT') {
                    ;
                    next.focus();
                }
            }
        }
        if (onClear) {
            onClear();
        }
    }, [onClear]);
    return (_jsxs(Box, __assign({ ref: ref, display: "flex", flexDirection: "row", flexWrap: isMobileView ? 'wrap' : 'nowrap', gap: 1, sx: sx }, { children: [_jsx(QueryField, { ref: queryFieldRef, fieldname: query.fieldname, queryFields: queryFields, tags: tags, onChange: onChange }), _jsx(QueryOperator, { fieldname: query.fieldname, oper: query.oper, onChange: onChange }), _jsx(QueryValue, { fieldname: query.fieldname, datatype: query.datatype, tags: tags, tagValues: tagValues, value: query.value, onChange: onChange, setError: setError }), !!onClear && (_jsx(OGButton, { "aria-label": t('components.query.remove_criteria.label'), size: "small", startIcon: _jsx(ClearIcon, {}), sx: { height: '100%' }, variant: "borderless", onClick: handleClear }))] })));
}
