var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
export function NameField(_a) {
    var inputValue = _a.inputValue, handleInputChange = _a.handleInputChange, handleBlur = _a.handleBlur, handleKeyDown = _a.handleKeyDown;
    var t = useTranslation().t;
    return (_jsx(TextField, { type: "text", value: inputValue, onChange: handleInputChange, onBlur: handleBlur, onKeyDown: handleKeyDown, InputProps: {
            inputProps: { 'aria-label': t('pages.firmware_upgrade.tabs.tasks.name_filter.aria_label') },
            endAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(SearchIcon, { fontSize: "medium" }) }))),
        }, margin: "none", placeholder: t('pages.firmware_upgrade.tabs.tasks.name_filter.placeholder') }));
}
