var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { forwardRef, useCallback, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IQueryDataType, IQueryNodeField, IQueryPortField, IQuerySmfResourceField } from '../../../../types/query';
import { requiredFieldError } from '@/utils/field-validation-errors';
function fieldnameToDatatype(fieldname) {
    switch (fieldname) {
        case IQueryNodeField.approved:
            return IQueryDataType.bool;
        case IQueryNodeField.firmwareVersion:
            return IQueryDataType.string;
        case IQueryNodeField.id:
            return IQueryDataType.string;
        case IQueryNodeField.lhvpnAddress:
            return IQueryDataType.string;
        case IQueryNodeField.macAddress:
            return IQueryDataType.string;
        case IQueryNodeField.model:
            return IQueryDataType.string;
        case IQueryNodeField.name:
            return IQueryDataType.string;
        case IQueryNodeField.product:
            return IQueryDataType.string;
        case IQueryNodeField.productFamily:
            return IQueryDataType.string;
        case IQueryNodeField.serialNumber:
            return IQueryDataType.string;
        case IQueryNodeField.sshPort:
            return IQueryDataType.string;
        case IQueryNodeField.status:
            return IQueryDataType.reference;
        case IQueryNodeField.cellHealthStatus:
            return IQueryDataType.reference;
        case IQueryNodeField.connectionStatus:
            return IQueryDataType.reference;
        case IQueryPortField.label:
            return IQueryDataType.string;
        case IQueryPortField.tag:
            return IQueryDataType.reference;
        default:
            if (fieldname.startsWith('tag:')) {
                return IQueryDataType.reference;
            }
            /* istanbul ignore next */
            return IQueryDataType.string;
    }
}
function groupBy(option) {
    var _a = __read(option.split(':', 1), 1), group = _a[0];
    return group;
}
function renderGroup(_a) {
    var group = _a.group, children = _a.children;
    if (group !== 'config') {
        return (_jsxs(Box, __assign({ role: "group", borderTop: "1px solid", borderColor: "divider", marginTop: 1, paddingTop: 1 }, { children: [_jsx(Typography, __assign({ component: "p", marginX: 1, marginY: 0.75, variant: "h3" }, { children: group })), children] }), group));
    }
    return (_jsxs(Box, __assign({ role: "group" }, { children: [_jsx(Typography, __assign({ component: "p", marginX: 1, marginY: 0.75, variant: "h3" }, { children: group })), children] }), group));
}
export function useQueryFieldFormatter() {
    var t = useTranslation().t;
    return useCallback(function (fieldname) {
        switch (fieldname) {
            case IQueryNodeField.approved:
                return t('components.query.field.approved');
            case IQueryNodeField.firmwareVersion:
                return t('components.query.field.firmware_version');
            case IQueryNodeField.id:
                return t('components.query.field.id');
            case IQueryNodeField.lhvpnAddress:
                return t('components.query.field.lhvpn_address');
            case IQueryNodeField.macAddress:
                return t('components.query.field.mac_address');
            case IQueryNodeField.model:
                return t('components.query.field.model');
            case IQueryNodeField.name:
                return t('components.query.field.name');
            case IQueryNodeField.product:
                return t('components.query.field.product');
            case IQueryNodeField.productFamily:
                return t('components.query.field.product_family');
            case IQueryNodeField.serialNumber:
                return t('components.query.field.serial_number');
            case IQueryNodeField.sshPort:
                return t('components.query.field.ssh_port');
            case IQueryNodeField.status:
                return t('components.query.field.status');
            case IQueryNodeField.cellHealthStatus:
                return t('components.query.field.cellular_health');
            case IQueryNodeField.connectionStatus:
                return t('components.query.field.resolved_connection_status');
            case IQueryPortField.label:
                return t('components.query.field.port_label');
            case IQueryPortField.tag:
                return t('components.query.field.resource_tag');
            case IQuerySmfResourceField.name:
                return t('components.query.field.resource_name');
            case IQuerySmfResourceField.address:
                return t('components.query.field.resource_address');
            case IQuerySmfResourceField.networkRange:
                return t('components.query.field.network_range');
            default:
                return fieldname.replace('tag:', '');
        }
    }, [t]);
}
export var QueryField = forwardRef(function (_a, ref) {
    var fieldname = _a.fieldname, queryFields = _a.queryFields, onChange = _a.onChange, tags = _a.tags;
    var t = useTranslation().t;
    var _b = __read(useState(null), 2), errorMessage = _b[0], setErrorMessage = _b[1];
    var formatQueryField = useQueryFieldFormatter();
    var options = __spreadArray([], __read(queryFields), false);
    if (tags) {
        tags.forEach(function (tag) {
            options.push("tag:".concat(tag.name));
        });
    }
    var handleBlur = useCallback(function () {
        if (!fieldname) {
            setErrorMessage(requiredFieldError(t('components.query.field.label')));
        }
    }, [fieldname, t]);
    var handleChange = useCallback(function (_, _fieldname) {
        if (_fieldname) {
            setErrorMessage(null);
            onChange({ datatype: fieldnameToDatatype(_fieldname), fieldname: _fieldname });
        }
        else {
            onChange({ datatype: IQueryDataType.string, fieldname: undefined });
        }
    }, [onChange]);
    var renderInput = useCallback(function (params) { return (_jsx(TextField, __assign({}, params, { inputRef: ref, error: !!errorMessage, helperText: errorMessage, margin: "none", placeholder: t('components.query.field.label'), size: "medium" }))); }, [errorMessage, ref, t]);
    var renderOption = useCallback(function (props, option, _a) {
        var selected = _a.selected;
        return (_createElement(ListItem, __assign({}, props, { key: option, sx: { width: '100%', display: 'flex', justifyContent: 'space-between' }, dense: true }),
            formatQueryField(option),
            selected ? _jsx(CheckOutlinedIcon, { sx: { fontSize: '2rem' } }) : ''));
    }, [formatQueryField]);
    return (_jsx(Autocomplete, { ListboxProps: { 'aria-label': t('components.query.field.label') }, clearOnEscape: true, closeText: t('global.buttons.clear'), forcePopupIcon: true, getOptionLabel: formatQueryField, groupBy: groupBy, renderGroup: renderGroup, renderInput: renderInput, renderOption: renderOption, options: options, value: fieldname !== null && fieldname !== void 0 ? fieldname : null, onBlur: handleBlur, onChange: handleChange, sx: { flexGrow: 2, minWidth: 180 } }));
});
