var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { invalidFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN } from '@/constants/regex';
// Field validation limits
export var PortMinVal = 1;
export var PortMaxVal = 65535;
export function ServerAddressField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "address", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.address.label')),
            pattern: {
                value: HOSTNAME_OR_ADDRESS_PATTERN,
                message: invalidFieldError(t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.address.label')),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField
            // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            , __assign({ 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, label: _jsx(_Fragment, { children: t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.address.label') }) }, field)));
        } }));
}
export function PortField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "port", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.port.label')),
            min: {
                value: PortMinVal,
                message: minValueFieldError(t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.port.label'), PortMinVal.toString()),
            },
            max: {
                value: PortMaxVal,
                message: maxValueFieldError(t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.port.label'), PortMaxVal.toString()),
            },
        }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, disabled: !permissions.hasEditPermission, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, required: true, type: "number", label: _jsx(_Fragment, { children: t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.port.label') }) }, field, { onChange: function (e) {
                    onChange(e.target.value ? parseInt(e.target.value, 10) : '');
                } })));
        } }));
}
export function ProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, setValue = _a.setValue, trigger = _a.trigger, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ component: "label" }, { children: [t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.protocol.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.protocol.tooltip') })] })), _jsx(Controller, { control: control, name: "protocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                                if (v === 'UDP') {
                                    setValue('port', 514);
                                }
                                if (v === 'TCP') {
                                    setValue('port', 601);
                                }
                                trigger('port');
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "UDP" }, { children: t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.protocol.udp') })), _jsx(ToggleButton, __assign({ value: "TCP" }, { children: t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.protocol.tcp') }))] })));
                } })] }));
}
