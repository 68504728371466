var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useDeviceView } from '@/hooks/use-device-view';
import { PortFilterOptions } from './types';
import { validateConfiguredPortFilterQueryParam } from './port-status-filter.hook';
export var PortStatusFilter = forwardRef(function (_a, ref) {
    var _b = _a.paramFilter, paramFilter = _b === void 0 ? PortFilterOptions.all : _b, onChange = _a.onChange;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var filterOptions = useMemo(function () { return Object.values(PortFilterOptions).map(function (option) { return ({ value: option, name: t('components.port_status_filter.options')[option] }); }); }, [t]);
    var handleFilterChange = function (event) {
        var eventValue = validateConfiguredPortFilterQueryParam(event.target.value);
        if (eventValue)
            onChange(eventValue);
    };
    return (_jsx(Box, __assign({ display: "flex", alignItems: "center", gap: 0.5 }, { children: _jsx(TextField, __assign({ inputRef: ref, select: true, onChange: handleFilterChange, sx: { width: isMobileView ? '100%' : '180px', margin: 0 }, inputProps: { 'aria-label': t('components.port_status_filter.label') }, value: paramFilter }, { children: filterOptions.map(function (option) { return (_jsx(MenuItem, __assign({ value: option.value }, { children: option.name }), option.value)); }) })) })));
});
