var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { triggerDownload } from '@/api/utils';
import { decodeDeleteSystemConfigResponse } from '../../../../types/lighthouse/decoders/system/config';
import { decodeCreateSystemConfigBackupResponse } from '../../../../types/lighthouse/decoders/system/config-backup';
import { decodeUploadAndValidateSystemRestoreFileResponse } from '../../../../types/lighthouse/decoders/system/upload-restore';
import { decodeRestoreSystemConfigResponse } from '../../../../types/lighthouse/decoders/system/config-restore';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
import { useDeleteSession } from '@/api/sessions';
import { ENTITLEMENTS_PATH } from '../entitlements/entitlements.constants';
import { PERMISSIONS_PATH } from '@/api/sessions/self/ui/ui.constants';
import { formatErrorMessage } from '@/utils/errors';
import { isErrorResponse } from '../../../../types/guards';
export var SYSTEM_CONFIG_PATH = '/system/config';
export var SYSTEM_CONFIG_BACKUP_PATH = '/system/config_backup';
export var SYSTEM_UPLOAD_RESTORE_PATH = '/system/upload_restore';
export var SYSTEM_CONFIG_RESTORE_PATH = '/system/config_restore';
export var ASSET_MANIFEST_JSON_PATH = '/asset-manifest.json';
// Nginx error strings
export var NGINX_ERRORS = {
    CHROME: 'Failed to fetch',
    FIREFOX: 'NetworkError when attempting to fetch resource.',
};
export function useDeleteSystemConfig() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    return useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(SYSTEM_CONFIG_PATH, { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteSystemConfigResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.system_config.delete'));
                    return [2 /*return*/, status === 200];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, t]);
}
export function useDownloadBackupFile() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var downloadBackupFile = function (backupId) { return __awaiter(_this, void 0, void 0, function () {
        var signal, response, blob, contentDisposition, filename, matches, _a, extractedFilename;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    signal = new AbortController().signal;
                    return [4 /*yield*/, executeFetch(SYSTEM_CONFIG_BACKUP_PATH, {
                            queryParams: { id: backupId },
                            headers: new Headers({
                                'Accept': 'application/octet-stream',
                                'Content-Type': 'application/octet-stream',
                            }),
                            signal: signal,
                        })];
                case 1:
                    response = _b.sent();
                    handleCommonStatusCodes(null, response.status, t('api.system_config_backup.post'));
                    if (!(response.status === 200)) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.blob()];
                case 2:
                    blob = _b.sent();
                    contentDisposition = response.headers.get('Content-Disposition');
                    filename = 'backup_file.lh_bak';
                    if (contentDisposition) {
                        matches = contentDisposition.match(/filename="?(.+)"?/);
                        if (matches) {
                            _a = __read(matches, 2), extractedFilename = _a[1];
                            filename = extractedFilename;
                        }
                    }
                    triggerDownload(blob, filename);
                    return [2 /*return*/, true];
                case 3: return [2 /*return*/, false];
            }
        });
    }); };
    return {
        downloadBackupFile: downloadBackupFile,
    };
}
export function useSystemConfigBackup() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var downloadBackupFile = useDownloadBackupFile().downloadBackupFile;
    return useCallback(function (configBackup) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(SYSTEM_CONFIG_BACKUP_PATH, { method: 'POST', body: JSON.stringify(configBackup) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeCreateSystemConfigBackupResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    if (status === 200) {
                        /* Alert for success case is handled in downloadBackupFile function
                        to ensure the alert is only shown after the download is complete */
                        downloadBackupFile(data.system_config_backup.id);
                        return [2 /*return*/, data];
                    }
                    handleCommonStatusCodes(data, status, t('api.system_config_backup.post'));
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, downloadBackupFile, executeFetch, handleCommonStatusCodes, t]);
}
export function useUploadSystemConfigRestore() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    return useCallback(function (uploadRestore) { return __awaiter(_this, void 0, void 0, function () {
        var formData, response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    formData = new FormData();
                    formData.append('file', uploadRestore.file);
                    if (uploadRestore.password)
                        formData.append('password', uploadRestore.password);
                    return [4 /*yield*/, executeFetch(SYSTEM_UPLOAD_RESTORE_PATH, { method: 'POST', body: formData, headers: new Headers({ Accept: 'application/json' }) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeUploadAndValidateSystemRestoreFileResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        forbidden: t('api.system_config_restore.upload.post.forbidden'),
                        error: {
                            message: isErrorResponse(data) ? formatErrorMessage(data).replace('ERROR: ', '') : t('api.system_config_restore.upload.post.error.message'),
                            options: { header: t('api.system_config_restore.upload.post.error.header') },
                        },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    return [2 /*return*/, status === 200 ? data : null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, t]);
}
export function useSystemConfigRestore() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    return useCallback(function (configRestore) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(SYSTEM_CONFIG_RESTORE_PATH, { method: 'POST', body: JSON.stringify(configRestore) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeRestoreSystemConfigResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.system_config_restore.restore.post'));
                    return [2 /*return*/, status === 200 ? data : null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, t]);
}
export var fetchAssetManifest = function () { return __awaiter(void 0, void 0, void 0, function () {
    var err_1, isBrowserFailure, isMswError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(ASSET_MANIFEST_JSON_PATH, "?_=").concat(Date.now()))];
            case 1:
                _a.sent();
                return [2 /*return*/, true];
            case 2:
                err_1 = _a.sent();
                isBrowserFailure = err_1 instanceof TypeError && Object.values(NGINX_ERRORS).includes(err_1.message);
                isMswError = err_1 instanceof ProgressEvent && err_1.type === 'error';
                // Catch assumes Nginx has stopped and received ERR_CONNECTION_REFUSED
                if (isBrowserFailure || isMswError) {
                    throw new Error('Nginx has stopped');
                }
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
export function useConfigRestoreStatus() {
    var _a = __read(useState(false), 2), refetchInterval = _a[0], setRefetchInterval = _a[1];
    var _b = __read(useState('restoring'), 2), restoreStatus = _b[0], setRestoreStatus = _b[1];
    var queryClient = useQueryClient();
    var signout = useDeleteSession();
    // Timeout in ms for checking restore status
    var restoreTimeout = 5000;
    var status = useQuery({
        queryKey: [ASSET_MANIFEST_JSON_PATH],
        queryFn: fetchAssetManifest,
        refetchInterval: refetchInterval,
        retry: false,
    }).status;
    useEffect(function () {
        var timeoutId;
        // Initial state for restore status will be loading followed by success
        /* istanbul ignore else */
        if ((status === 'pending' || status === 'success') && restoreStatus === 'restoring') {
            // Remove these two previously mounted queries to prevent any errors
            queryClient.removeQueries({ queryKey: [ENTITLEMENTS_PATH, PERMISSIONS_PATH] });
        }
        else if (status === 'error' && restoreStatus === 'restoring') {
            // Nginx has now stopped which indicates Lighthouse is not restarting
            setRestoreStatus('restarting');
        }
        else if (status === 'success' && restoreStatus === 'restarting') {
            // Success response from Lighthouse Nginx means restore and restart has been successful
            /* istanbul ignore next */
            setRestoreStatus('complete');
        }
        /* istanbul ignore else */
        if (restoreStatus !== 'complete' && refetchInterval === false) {
            setRefetchInterval(restoreTimeout);
        }
        else if (restoreStatus === 'complete' && refetchInterval !== false) {
            // A pause to show users final complete status before deleting the session
            /* istanbul ignore next */
            timeoutId = window.setTimeout(function () {
                setRefetchInterval(false);
                setRestoreStatus(undefined);
                signout();
            }, restoreTimeout);
        }
        return function () {
            window.clearTimeout(timeoutId);
        };
    }, [status, restoreStatus, refetchInterval, queryClient, signout]);
    return { status: restoreStatus };
}
