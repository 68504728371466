import { formatDateTimestamp } from '@/utils/datetime';
// Column value formatter
export var formatLastOnline = function (_a) {
    var value = _a.value;
    return (value ? formatDateTimestamp(value) : 'unknown');
};
// Column value getters
export var getLastOnlineValue = function (_a) {
    var row = _a.row;
    return row.runtime_status.change_time;
};
