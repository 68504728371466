var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
import { IQueryDataType, IQueryNodeField, IQueryOperation, IQueryType, IQuerySmfResourceField, IQueryPortField, } from '../../types/query/types';
export var NodeCellhealthStatus;
(function (NodeCellhealthStatus) {
    NodeCellhealthStatus["Bad"] = "bad";
    NodeCellhealthStatus["ConnectivityTestFailed"] = "connectivity_test_failed";
    NodeCellhealthStatus["Good"] = "good";
    NodeCellhealthStatus["InterfaceDisabled"] = "interface_disabled";
    NodeCellhealthStatus["Moderate"] = "moderate";
    NodeCellhealthStatus["Pending"] = "pending";
    NodeCellhealthStatus["SIMIssues"] = "sim_issues";
    NodeCellhealthStatus["Unknown"] = "unknown";
})(NodeCellhealthStatus || (NodeCellhealthStatus = {}));
export var NodeConnectionStatus;
(function (NodeConnectionStatus) {
    NodeConnectionStatus["Connected"] = "connected";
    NodeConnectionStatus["ConnectViaCellular"] = "failover";
    NodeConnectionStatus["Disconnect"] = "disconnected";
    NodeConnectionStatus["Pending"] = "pending";
})(NodeConnectionStatus || (NodeConnectionStatus = {}));
export function connectionStatusToQuery(value) {
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.reference,
        fieldname: IQueryNodeField.connectionStatus,
        oper: IQueryOperation.equal,
        value: value,
    };
}
export function subscriptionTypeToQuery(value) {
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.string,
        fieldname: IQueryNodeField.subscriptionType,
        oper: IQueryOperation.equal,
        value: value,
    };
}
export function smfResourceConnectionStatusToQuery(value) {
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.string,
        fieldname: IQuerySmfResourceField.serviceConnection,
        oper: IQueryOperation.equal,
        value: value,
    };
}
export var NodeStatus;
(function (NodeStatus) {
    NodeStatus["Enrolling"] = "Enrolling";
    NodeStatus["Enrolled"] = "Enrolled";
    NodeStatus["Registering"] = "Registering";
    NodeStatus["Registered"] = "Registered";
    NodeStatus["Unknown"] = "Unknown";
})(NodeStatus || (NodeStatus = {}));
export function nodeStatusToQuery(value, prefix) {
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.reference,
        fieldname: "".concat(prefix !== null && prefix !== void 0 ? prefix : '').concat(IQueryNodeField.status),
        oper: IQueryOperation.equal,
        value: value,
    };
}
export function productFamilyToQuery(value) {
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.reference,
        fieldname: IQueryNodeField.productFamily,
        oper: IQueryOperation.equal,
        value: value,
    };
}
export function firmwareVersionToQuery(value) {
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.reference,
        fieldname: IQueryNodeField.firmwareVersion,
        oper: IQueryOperation.lessThan,
        value: value,
    };
}
export function nodeIdsToQuery(node_ids) {
    return {
        value: node_ids,
        fieldname: IQueryNodeField.id,
        datatype: IQueryDataType.list,
        oper: IQueryOperation.in,
        type: IQueryType.basic,
    };
}
export function resourceTagNameToQuery(resourceTagName) {
    return {
        value: resourceTagName,
        fieldname: IQueryPortField.tag,
        datatype: IQueryDataType.reference,
        oper: IQueryOperation.equal,
        type: IQueryType.basic,
    };
}
export function portStatusFilterToQuery(oper) {
    if (oper === void 0) { oper = IQueryOperation.equal; }
    return {
        type: IQueryType.basic,
        datatype: IQueryDataType.string,
        fieldname: IQueryPortField.mode,
        oper: oper,
        value: 'consoleServer',
    };
}
export function useJson() {
    var queries = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        queries[_i] = arguments[_i];
    }
    return useMemo(function () {
        var items = [];
        queries.forEach(function (query) {
            if (!query)
                return;
            if ('fieldname' in query) {
                items.push(query);
                return;
            }
            if (query.type === IQueryType.and) {
                // since the root query type is AND we can flatten the query
                items.push.apply(items, __spreadArray([], __read(query.items), false));
                return;
            }
            items.push(query);
        });
        if (items.length === 0) {
            return undefined;
        }
        var query = {
            items: items,
            type: IQueryType.and,
        };
        return JSON.stringify(query);
    }, [queries]);
}
