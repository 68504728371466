import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useFormContainerContext } from '@/components/layout/forms';
import { DisabledTextField } from '@/components/inputs/disabled-text-field';
export function TaskDetails() {
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var taskName = watch('name');
    var upgradeFile = watch('firmware.filename');
    var firmwareVersion = watch('firmware.version');
    return (_jsxs(_Fragment, { children: [_jsx(DisabledTextField, { id: "taskName", label: t('pages.firmware_upgrade_new.steps.review_confirm.task_details.fields.task_name'), value: taskName }), _jsx(DisabledTextField, { id: "upgradeFile", label: t('pages.firmware_upgrade_new.steps.review_confirm.task_details.fields.upgrade_file'), value: upgradeFile }), _jsx(DisabledTextField, { id: "firmwareVersion", label: t('pages.firmware_upgrade_new.steps.review_confirm.task_details.fields.firmware_version'), value: firmwareVersion })] }));
}
