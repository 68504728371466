import { jsx as _jsx } from "react/jsx-runtime";
import { NODE_TAGS_LOADING_TEST_ID, useNodeTags } from '@/api/tags/node-tags';
import { FilterQuerySkeleton, FilterQuery, nodeQueryFields } from '@/containers/filters/filter-query';
export function NodeFilterQuery(_a) {
    var query = _a.query, onChange = _a.onChange;
    var _b = useNodeTags(), data = _b.data, isLoading = _b.isLoading;
    var nodeTags = data === null || data === void 0 ? void 0 : data.nodeTags;
    if (isLoading || !nodeTags) {
        return _jsx(FilterQuerySkeleton, { "data-testid": NODE_TAGS_LOADING_TEST_ID });
    }
    return _jsx(FilterQuery, { query: query, queryFields: nodeQueryFields, onChange: onChange, tags: nodeTags });
}
