import { cidrToSubnetMask, subnetMaskToCIDR } from '@/utils/calculations';
export var transformResponseToFormState = function (response) { return ({
    address: response.multiple_instance_lhvpn.address,
    cidr: subnetMaskToCIDR(response.multiple_instance_lhvpn.mask),
}); };
export var transformFormStateToRequest = function (formState) { return ({
    multiple_instance_lhvpn: {
        address: formState.address,
        mask: cidrToSubnetMask(formState.cidr),
    },
}); };
