var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from 'dayjs';
import format from 'string-format';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useEntitlements } from './entitlements';
/**
 * Returns an object containing the expiration information for a subscription
 * @param expirationTimestamp - the maintenance timestamp in seconds
 */
export function getExpiration(expirationTimestamp) {
    if (!expirationTimestamp)
        return undefined;
    var currentDate = dayjs();
    var expiryDate = dayjs.unix(expirationTimestamp);
    var isExpired = expiryDate < currentDate;
    var daysRemaining = Math.ceil(expiryDate.diff(currentDate, 'days', true));
    var expiration = {
        isExpired: isExpired,
        daysRemaining: isExpired ? 0 : daysRemaining,
        expiryDate: expiryDate,
    };
    return expiration;
}
/**
 * Returns the edition string for a subscription
 * @param tier
 * @param description
 */
export function getEdition(tier, description) {
    var edition = '';
    // For older licenses where the description doesn't match what we display, we do some string manipulation
    if (description === null || description === void 0 ? void 0 : description.startsWith('LH ')) {
        var bucketName = description.split('- ')[1];
        var isPremium = description === null || description === void 0 ? void 0 : description.startsWith('LH AE - ');
        edition = isPremium ? format(t('global.subscriptions.premium_bucket'), bucketName) : format(t('global.subscriptions.enterprise_bucket'), bucketName);
    }
    else {
        switch (tier) {
            case 'premium':
                edition = t('global.subscriptions.premium');
                break;
            case 'enterprise':
                edition = t('global.subscriptions.enterprise');
                break;
            default:
                edition = t('global.subscriptions.legacy');
                break;
        }
    }
    return edition;
}
/**
 * Parse and return a list of lighthouse subscriptions from the entitlements response
 * @param data - the entitlements response
 */
export var getLighthouseSubscriptions = function (data) {
    var oglhEntitlements = data.entitlements.filter(function (entitlement) { return entitlement.id.includes('OGLH'); });
    return oglhEntitlements.map(function (entitlement) {
        var _a, _b;
        var subscriptionTier = ((_a = entitlement.details) === null || _a === void 0 ? void 0 : _a.tier) || 'legacy';
        var subscriptionEdition = getEdition(subscriptionTier, (_b = entitlement.details) === null || _b === void 0 ? void 0 : _b.description);
        var subscriptionExpiration = getExpiration(entitlement.features.maintenance);
        return __assign(__assign({}, entitlement), { details: __assign(__assign({}, entitlement.details), { tier: subscriptionTier, edition: subscriptionEdition }), expiration: subscriptionExpiration });
    });
};
/**
 * Returns a map of available nodes for each subscription tier
 * @param data - the entitlements response
 */
export function getAvailableNodes(data) {
    var countMap = new Map();
    data.entitlements.forEach(function (entitlement) {
        var _a;
        if (entitlement.id.includes('OGLH') && ((_a = entitlement === null || entitlement === void 0 ? void 0 : entitlement.details) === null || _a === void 0 ? void 0 : _a.tier) && entitlement.features.available_nodes !== undefined) {
            countMap.set(entitlement.details.tier, entitlement.features.available_nodes);
        }
    });
    return countMap;
}
function getMiSupported(data) {
    var _a;
    var oglhLicense = data.entitlements.find(function (entitlement) { var _a; return entitlement.id === 'OGLH' && ((_a = entitlement.features.additional) === null || _a === void 0 ? void 0 : _a.multipleinstance) !== undefined; });
    if ((_a = oglhLicense === null || oglhLicense === void 0 ? void 0 : oglhLicense.features.additional) === null || _a === void 0 ? void 0 : _a.multipleinstance)
        return oglhLicense.features.additional.multipleinstance;
    return false;
}
function getThirdPartySupported(data) {
    var _a;
    var oglhLicense = data.entitlements.find(function (entitlement) { var _a; return entitlement.id === 'OGLH' && ((_a = entitlement.features.additional) === null || _a === void 0 ? void 0 : _a.thirdpartynodes) !== undefined; });
    if ((_a = oglhLicense === null || oglhLicense === void 0 ? void 0 : oglhLicense.features.additional) === null || _a === void 0 ? void 0 : _a.thirdpartynodes)
        return oglhLicense.features.additional.thirdpartynodes;
    return false;
}
function hasPremiumSubscription(lighthouseSubscriptions) {
    return lighthouseSubscriptions.some(function (subscription) { return subscription.details && subscription.details.tier === 'premium' && !subscription.features.expired; });
}
export var useEntitlementsData = function () {
    var _a = useEntitlements(), data = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching;
    var lighthouseSubscriptions = useMemo(function () {
        if (isLoading || !data)
            return [];
        return getLighthouseSubscriptions(data);
    }, [data, isLoading]);
    var entitlements = useMemo(function () {
        if (isLoading || !data)
            return [];
        return data.entitlements;
    }, [data, isLoading]);
    var availableNodes = useMemo(function () {
        if (isLoading || !data)
            return new Map();
        return getAvailableNodes(data);
    }, [data, isLoading]);
    var miSupported = useMemo(function () {
        if (!data)
            return false;
        return getMiSupported(data);
    }, [data]);
    var thirdPartySupported = useMemo(function () {
        if (!data)
            return false;
        return getThirdPartySupported(data);
    }, [data]);
    var hasPremium = useMemo(function () {
        if (!lighthouseSubscriptions)
            return false;
        return hasPremiumSubscription(lighthouseSubscriptions);
    }, [lighthouseSubscriptions]);
    return { availableNodes: availableNodes, lighthouseSubscriptions: lighthouseSubscriptions, entitlements: entitlements, isLoading: isLoading, isFetching: isFetching, miSupported: miSupported, thirdPartySupported: thirdPartySupported, hasPremiumSubscription: hasPremium };
};
