var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { AuthTemplateForm } from '@/containers/config/templates/auth-template';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useDocTitle } from '@/hooks/doc-title';
import { transformToResponseObject } from '@/containers/config/templates/auth-template/transformers';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { PAGE_TITLE_ID } from '@/constants/ids';
function AddAuthTemplate() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var navigate = useNavigate();
    var hasAuthEditAccess = hasPermission('configure.configuration_templating.authentication_templates.edit');
    var defaultFormValues = transformToResponseObject();
    useDocTitle(t('pages.add_config_files.title'));
    useLayoutEffect(function () {
        if (!hasAuthEditAccess) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate, hasAuthEditAccess]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.config_templates.add.title') })), _jsx(AuthTemplateForm, { data: defaultFormValues, permissions: { hasAuthEditAccess: hasAuthEditAccess } })] })));
}
export default withAuthenticationRequired(AddAuthTemplate);
