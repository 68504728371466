var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
export function UpgradeStatusIcon(_a) {
    var status = _a.status;
    var t = useTranslation().t;
    if (status === 'IN_PROGRESS') {
        return _jsx(OGAutoRenewIcon, { label: t('global.task_status.in_progress') });
    }
    if (status === 'ABORTED_BY_LH' || status === 'CANCELLING' || status === 'CANCELLED_BY_USER') {
        return _jsx(CancelOutlinedIcon, { role: "none" });
    }
    if (status === 'SUCCESS') {
        return _jsx(CheckOutlinedIcon, { color: "success", role: "none" });
    }
    if (status === 'FAILURE') {
        return _jsx(WarningIcon, { color: "error", role: "none" });
    }
    if (status === 'SCHEDULED') {
        return _jsx(AccessTimeIcon, { color: "warning", role: "none" });
    }
    return null;
}
export function UpgradeStatusText(_a) {
    var nodeUpgrade = _a.nodeUpgrade;
    var t = useTranslation().t;
    var taskStatus = '';
    var runNodes = nodeUpgrade.number_failures + nodeUpgrade.number_successes;
    var taskProgress = nodeUpgrade.total_nodes ? " ".concat(runNodes, "/").concat(nodeUpgrade.total_nodes) : '';
    if (nodeUpgrade.status === 'IN_PROGRESS') {
        taskStatus = "".concat(t('global.task_status.in_progress')).concat(taskProgress);
    }
    if (nodeUpgrade.status === 'ABORTED_BY_LH' || nodeUpgrade.status === 'CANCELLING' || nodeUpgrade.status === 'CANCELLED_BY_USER') {
        taskStatus = t('global.task_status.cancelled');
    }
    if (nodeUpgrade.status === 'SUCCESS') {
        taskStatus = t('global.task_status.completed');
    }
    if (nodeUpgrade.status === 'FAILURE') {
        taskStatus = t('global.task_status.completed');
    }
    if (nodeUpgrade.status === 'SCHEDULED') {
        taskStatus = t('global.task_status.scheduled');
    }
    return _jsx(Typography, { children: taskStatus });
}
export function UpgradeProgress(_a) {
    var nodeUpgrade = _a.nodeUpgrade;
    var t = useTranslation().t;
    var runNodes = nodeUpgrade.number_failures + nodeUpgrade.number_successes;
    var progressValue = 0;
    var progressColor = 'success';
    if (nodeUpgrade.status === 'IN_PROGRESS') {
        progressValue = nodeUpgrade.total_nodes ? Math.round((runNodes * 100) / nodeUpgrade.total_nodes) : 0;
        progressColor = progressValue > 30 ? 'warning' : 'error';
    }
    if (nodeUpgrade.status === 'SUCCESS') {
        progressValue = 100;
    }
    if (nodeUpgrade.status === 'FAILURE') {
        progressValue = 100;
    }
    return (_jsx(LinearProgress, { "aria-label": format(t('pages.firmware_upgrade.tabs.tasks.table.progress_label'), runNodes.toString(), nodeUpgrade.total_nodes.toString()), value: progressValue, color: progressColor }));
}
export function UpgradeDoneStatus(_a) {
    var nodeUpgrade = _a.nodeUpgrade;
    var t = useTranslation().t;
    var runNodes = nodeUpgrade.number_failures + nodeUpgrade.number_successes;
    return _jsx(Typography, { children: format(t('pages.node_upgrade.task_status.fields.status.label'), runNodes.toString(), nodeUpgrade.total_nodes.toString()) });
}
export function UpgradeStatusCell(_a) {
    var nodeUpgrade = _a.nodeUpgrade;
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 1 }, { children: [_jsx(UpgradeStatusIcon, { status: nodeUpgrade.status }), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 0.5, width: "80px" }, { children: [_jsx(UpgradeStatusText, { nodeUpgrade: nodeUpgrade }), _jsx(UpgradeProgress, { nodeUpgrade: nodeUpgrade })] }))] })));
}
