var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OGButtonLink } from '@opengear/buttons';
import { useOpen } from '@/hooks/open';
export function BulkErrorMessage(_a) {
    var message = _a.message, errorDetails = _a.errorDetails;
    var t = useTranslation().t;
    var _b = useOpen(), open = _b.open, handleToggle = _b.handleToggle;
    return (_jsxs(_Fragment, { children: [message, _jsx(OGButtonLink, __assign({ variant: "standalone", onClick: handleToggle, sx: { '&:hover': { textDecoration: 'none' } }, endIcon: open ? _jsx(ExpandLessIcon, { fontSize: "small" }) : _jsx(ExpandMoreIcon, { fontSize: "small" }) }, { children: open ? t('global.buttons.less_details') : t('global.buttons.more_details') })), _jsx(Collapse, __assign({ in: open, unmountOnExit: true }, { children: _jsx("table", __assign({ style: { marginTop: '4px' }, "aria-label": t('components.bulk_error.table_label') }, { children: _jsx("tbody", { children: errorDetails.map(function (errorItem) { return (_jsxs("tr", { children: [_jsx("td", __assign({ style: { width: '75px', verticalAlign: 'top' } }, { children: errorItem.id })), _jsx("td", { children: errorItem.error })] }, errorItem.id)); }) }) })) }))] }));
}
