var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
export function FilterQuerySkeleton(_a) {
    var dataTestId = _a["data-testid"];
    return (_jsxs(Stack, __assign({ "data-testid": dataTestId, direction: "row", gap: 1, marginBottom: 2 }, { children: [_jsx(Skeleton, { sx: { flexGrow: 3, height: 28 }, variant: "rectangular" }), _jsx(Skeleton, { sx: { flexGrow: 1, height: 28 }, variant: "rectangular" }), _jsx(Skeleton, { sx: { flexGrow: 3, height: 28 }, variant: "rectangular" })] })));
}
