var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGLink } from '@opengear/links';
import { QUERY_PARAM_JOB_ID_KEY } from '@/components/jobs/jobs-table';
import { isNodeConnected } from '@/containers/nodes/node-utils';
export function PendingStatusCell(_a) {
    var _b;
    var node = _a.row, tabIndex = _a.tabIndex, isLink = _a.isLink;
    var t = useTranslation().t;
    var _c = node.runtime_status, actionErrorMessage = _c.action_error_message, actionStatus = _c.action_status, actionType = _c.action_type, connectionStatus = _c.connection_status;
    var statusValue = '';
    // Status values always starts with status
    switch (node.status) {
        case 'Pending':
            statusValue = t('global.node_status.pending') + (actionErrorMessage ? " (".concat(t('global.node_status.error'), " - ").concat(actionErrorMessage, ")") : '');
            break;
        case 'Registering':
            statusValue =
                t('global.node_status.registering') +
                    (actionStatus === 'error' && actionErrorMessage ? " (".concat(t('global.node_status.error'), " - ").concat(actionErrorMessage, ")") : '');
            break;
        case 'Registered':
            statusValue =
                t('global.node_status.registered') +
                    (actionErrorMessage === 'Waiting for approval' && !node.approved && isNodeConnected(node)
                        ? " - ".concat(t('global.node_status.waiting_for_approval'))
                        : " (".concat(t('global.node_status.error'), " - ").concat(connectionStatus === 'disconnected' ? t('global.query.node_connection_status.disconnected') : actionErrorMessage, ")"));
            break;
        case 'Enrolling':
            statusValue =
                t('global.node_status.enrolling') +
                    (actionStatus === 'error' ? " (".concat(t('global.node_status.error')).concat(actionErrorMessage ? " - ".concat(actionErrorMessage) : '', ")") : '');
            break;
        case 'Enrolled':
            statusValue =
                t('global.node_status.enrolled') +
                    (actionStatus === 'error' ? " (".concat(t('global.node_status.error')).concat(actionErrorMessage ? " - ".concat(actionErrorMessage) : '', ")") : '');
            break;
        default:
            statusValue = t('global.node_status.unknown');
    }
    if (actionType === 'unenrollment') {
        statusValue = t('global.node_status.unenrolling');
    }
    return isLink ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    _jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function (e) { return e.stopPropagation(); }, to: "?".concat(new URLSearchParams((_b = {}, _b[QUERY_PARAM_JOB_ID_KEY] = node.last_job_id, _b))) }, { children: statusValue }))) : (_jsx("span", { children: statusValue }));
}
