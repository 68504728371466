import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import { commonThemeOptions, OPACITY } from './common';
import { OG_BACKGROUND_DARK, OG_BLACK, OG_BLACK_80, OG_BLACK_90, OG_GREY_H, OG_GREY_M, OG_GREY_L, OG_GREY_ML, OG_GREY_VL, OG_GREY_VVVL, OG_OFF_WHITE, OG_RED, OG_WHITE, OG_DANGER_DARK, OG_WARNING_DARK, OG_SUCCESS_DARK, OG_BUTTON_PRIMARY_ACTIVE, OG_LINK_DARK_STANDARD, OG_PINNED_COLUMNS_SHADOW_DARK, } from './colors';
var darkThemeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: OG_BACKGROUND_DARK,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    ' .MuiDrawer-paper': {
                        boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.25)',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ':hover': {
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                    },
                },
                outlined: {
                    'borderColor': OG_GREY_VVVL,
                    ':hover': {
                        backgroundColor: OG_GREY_M,
                    },
                    ':active': {
                        backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                        borderColor: OG_BUTTON_PRIMARY_ACTIVE,
                        color: OG_WHITE,
                    },
                    '&.Mui-disabled': {
                        borderColor: OG_GREY_VVVL,
                        color: OG_GREY_VVVL,
                    },
                },
                text: {
                    ':hover': {
                        backgroundColor: OG_GREY_M,
                    },
                    ':active': {
                        backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                        color: OG_WHITE,
                    },
                    '&.Mui-disabled': {
                        color: OG_GREY_VVVL,
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: OG_BLACK,
                    color: OG_GREY_VVVL,
                },
                cell: {
                    borderBottomColor: OG_GREY_H,
                },
                columnHeaders: {
                    backgroundColor: OG_BLACK_90,
                    borderBottomColor: OG_GREY_H,
                    color: OG_GREY_VL,
                },
                pinnedColumns: {
                    backgroundColor: OG_BLACK,
                    boxShadow: OG_PINNED_COLUMNS_SHADOW_DARK,
                    row: {
                        '&.Mui-hovered': {
                            backgroundColor: OG_BLACK_90,
                        },
                    },
                },
                pinnedColumnHeaders: {
                    backgroundColor: OG_BLACK_90,
                    boxShadow: OG_PINNED_COLUMNS_SHADOW_DARK,
                },
                footerContainer: {
                    borderTopColor: OG_GREY_H,
                },
                row: {
                    '&.Mui-hovered, &.Mui-selected, &.Mui-selected.Mui-hovered, &.Mui-selected:hover': {
                        backgroundColor: OG_BLACK_90,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: OG_GREY_H,
                },
                head: {
                    backgroundColor: OG_BLACK_90,
                    color: OG_GREY_VL,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover:hover, &.Mui-selected': {
                        backgroundColor: OG_BLACK_90,
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    'color': OG_LINK_DARK_STANDARD,
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    '&.Mui-selected': {
                        'color': "rgba(240, 241, 243, ".concat(OPACITY, ")"),
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        '&:focus-visible': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    },
                    '&.Mui-disabled': {
                        color: OG_GREY_VVVL,
                    },
                    '& > .MuiTouchRipple-root': {
                        color: OG_GREY_VVVL,
                    },
                },
            },
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: OG_GREY_H,
            paper: OG_BLACK,
            // custom backgrounds
            active: OG_GREY_ML,
            chip: OG_GREY_H,
            disabled: OG_GREY_M,
            hover: OG_GREY_L,
            input: OG_BLACK_90,
            box: OG_BLACK_90,
            menu: OG_BLACK_80,
            popup: OG_GREY_H,
            section: OG_BLACK,
            selectbar: OG_BLACK,
            selected: OG_GREY_M,
        },
        divider: OG_GREY_M,
        dividerLight: OG_GREY_L,
        slider: {
            default: OG_GREY_ML,
            hover: OG_OFF_WHITE,
            active: OG_WHITE,
        },
        secondary: {
            main: OG_LINK_DARK_STANDARD,
        },
        stepper: {
            active: OG_RED,
            icon: OG_WHITE,
            indicator: OG_GREY_H,
            outline: OG_GREY_L,
        },
        table: {
            divider: OG_GREY_H,
        },
        text: {
            primary: OG_GREY_VVVL,
            secondary: OG_GREY_VL,
            disabled: OG_GREY_M,
            // custom texts
            contrast: OG_GREY_H,
            highlight: OG_WHITE,
            label: OG_GREY_VL,
        },
        error: {
            main: OG_DANGER_DARK,
        },
        warning: {
            main: OG_WARNING_DARK,
        },
        success: {
            main: OG_SUCCESS_DARK,
        },
        info: {
            main: OG_GREY_VL,
        },
    },
};
export var darkTheme = createTheme(merge({}, commonThemeOptions, darkThemeOptions));
