var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { OGButton } from '@opengear/buttons';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGDialog } from '@opengear/modals';
import { OGPanel } from '@opengear/panel';
import { CancelButton, FormActions, FormContainer, useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
var SMF_RESOURCE_SSH_URL_MODAL_TITLE_ID = 'smf-resource-ssh-url-modal-title-id';
function TargetResourceUsernameField(_a) {
    var disabled = _a.disabled;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = useFormContainerContext(), control = _b.control, setValue = _b.setValue;
    var handleClear = useCallback(function () {
        setValue('targetResourceUsername', '');
    }, [setValue]);
    return ((disabled && (_jsx(TextField, { id: "target_resource_username", inputProps: {
            readOnly: true,
        }, label: t('pages.smf_resources.modal.form.fields.target_resource_username.label'), sx: { maxWidth: isMobileView ? undefined : 230 } }))) || (_jsx(Controller, { control: control, name: "targetResourceUsername", render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ id: "target_resource_username", InputProps: {
                    endAdornment: (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(OGButton, { "aria-label": t('pages.smf_resources.modal.form.fields.target_resource_username.clear'), endIcon: _jsx(ClearIcon, {}), onClick: handleClear, size: "small", variant: "borderless" }) }))),
                }, label: t('pages.smf_resources.modal.form.fields.target_resource_username.label'), sx: { maxWidth: isMobileView ? undefined : 230 } }, field)));
        } })));
}
function SSHCommandField(_a) {
    var sshCommand = _a.sshCommand;
    var t = useTranslation().t;
    return (_jsx(TextField, { id: "ssh_command", name: "sshCommand", multiline: true, fullWidth: true, inputProps: { readOnly: true }, margin: "normal", label: _jsxs(_Fragment, { children: [t('pages.smf_resources.modal.form.fields.ssh_command.label'), _jsx(OGIconTooltip, { title: t('pages.smf_resources.modal.ssh.tooltips.ssh_command') })] }), value: sshCommand }));
}
export function SmfResourceSSHUrlModal(_a) {
    var useOpenModel = _a.useOpenModel, onClose = _a.onClose, currentUser = _a.currentUser, resourceName = _a.resourceName, sshUrl = _a.sshUrl;
    var t = useTranslation().t;
    var open = useOpenModel.open, handleClose = useOpenModel.handleClose;
    var methods = useForm({ mode: 'onTouched', defaultValues: { sshCommandCopied: false, targetResourceUsername: currentUser } });
    var sshCommandCopied = methods.watch('sshCommandCopied');
    var targetResourceUsername = methods.watch('targetResourceUsername');
    var sshConstants = useMemo(function () { return ({
        at: '@',
        crg: 'crg',
        program: 'ssh',
        protocol: 'ssh://',
    }); }, []);
    var urlNoProtocol = useMemo(function () { return (sshUrl.startsWith(sshConstants.protocol) ? sshUrl.substring(sshConstants.protocol.length) : sshUrl); }, [sshUrl, sshConstants]);
    var urlIndexes = useMemo(function () { return ({
        at: urlNoProtocol.indexOf(sshConstants.at),
        crg: urlNoProtocol.indexOf(sshConstants.crg),
    }); }, [sshConstants, urlNoProtocol]);
    var urlValid = useMemo(function () { return urlIndexes.at !== -1 && urlIndexes.crg !== -1; }, [urlIndexes]);
    var urlParts = useMemo(function () { return ({
        address: urlValid ? urlNoProtocol.substring(urlIndexes.at) : '',
        usernamePrefix: urlValid ? urlNoProtocol.substring(0, urlIndexes.crg + 4) : '',
        usernamePostfix: urlValid ? urlNoProtocol.substring(urlIndexes.crg + 4, urlIndexes.at) : '',
    }); }, [urlIndexes, urlNoProtocol, urlValid]);
    var urlCrgSection = useMemo(function () { return (urlValid ? urlParts.usernamePrefix + targetResourceUsername + urlParts.usernamePostfix : ''); }, [targetResourceUsername, urlParts, urlValid]);
    var buttonData = useMemo(function () { return ({
        sshCommand: urlValid ? "".concat(sshConstants.program, " ").concat(urlCrgSection).concat(urlParts.address) : "".concat(sshConstants.program, " ").concat(urlNoProtocol),
        sshUrl: urlValid ? sshConstants.protocol + encodeURIComponent(urlCrgSection) + urlParts.address : sshConstants.protocol + urlNoProtocol,
    }); }, [sshConstants, urlCrgSection, urlNoProtocol, urlParts, urlValid]);
    var handleCancel = useCallback(function () {
        methods.reset();
        onClose();
        handleClose();
    }, [handleClose, methods, onClose]);
    useEffect(function () {
        methods.setValue('sshCommandCopied', false);
    }, [methods, targetResourceUsername]);
    var handleCopyToClipboard = useCallback(function () {
        navigator.clipboard.writeText(buttonData.sshCommand);
        methods.setValue('sshCommandCopied', true);
    }, [methods, buttonData]);
    var handleSSHAccess = useCallback(function () {
        window.open(buttonData.sshUrl, '_self', 'noreferrer');
        methods.reset();
        onClose();
        handleClose();
    }, [buttonData, handleClose, methods, onClose]);
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ id: SMF_RESOURCE_SSH_URL_MODAL_TITLE_ID, sx: { marginBottom: -1 } }, { children: format(t('pages.smf_resources.modal.ssh.title'), resourceName) })), _jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ ariaLabelledBy: SMF_RESOURCE_SSH_URL_MODAL_TITLE_ID, mode: "update", methods: methods, onSubmit: handleSSHAccess }, { children: [_jsx(TargetResourceUsernameField, { disabled: !urlValid }), _jsx(SSHCommandField, { sshCommand: buttonData.sshCommand }), _jsx(Divider, { sx: { marginX: -2 } }), _jsxs(FormActions, { children: [_jsx(OGButton
                                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                , __assign({ 
                                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus: true, onClick: handleCopyToClipboard, startIcon: sshCommandCopied ? _jsx(CheckCircleIcon, { color: "success" }) : _jsx(ContentCopyIcon, {}), variant: "secondary" }, { children: t('pages.smf_resources.modal.ssh.copy_to_clipboard') })), _jsx(Box, { flexGrow: 1 }), _jsx(CancelButton, { onCancel: handleCancel }), _jsx(OGButton, __assign({ "data-url": buttonData.sshUrl, onClick: handleSSHAccess }, { children: t('pages.smf_resources.modal.ssh.ssh_access') }))] })] })) })] })));
}
