var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import StopIcon from '@mui/icons-material/DoNotDisturbAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { OGLink } from '@opengear/links';
import { OGExpirePasswordIcon } from '@opengear/icons/action/expire-password';
import { OGReinstatePasswordIcon } from '@opengear/icons/action/reinstate-password';
import { OGButton } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import { useUsers } from '@/api/users';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useAuthConfig } from '@/api/auth';
import { usePaginationModel } from '@/hooks/pagination-model';
import { LOCAL_USERS_ROUTE } from '@/constants/routes';
import { UserActionDialog } from './user-action-dialog';
import { UsersBulkOperations } from './user-bulk-operations';
function LocalUsersTable() {
    var t = useTranslation().t;
    var _a = useUIPermissions(), hasPermission = _a.hasPermission, currentUser = _a.username;
    var hasEditAccess = hasPermission('settings.user_management.local_users.edit');
    var hasPrimaryUsersEditAccess = hasPermission('settings.user_management.local_users.edit', { forcePrimary: true });
    var hasAuthViewAccess = hasPermission('settings.user_management.remote_authentication.view');
    var _b = __read(useState([]), 2), rowSelectionModel = _b[0], setRowSelectionModel = _b[1];
    var _c = __read(useState(), 2), userAction = _c[0], setUserAction = _c[1];
    var _d = __read(useState([]), 2), userIdsToAction = _d[0], setUserIdToAction = _d[1];
    var _e = __read(usePaginationModel('local_users'), 3), paginationModel = _e[0], handlePaginationModelChange = _e[1], pageSizeOptions = _e[2];
    // Remove row focus management
    var _f = __read(useState(undefined), 2), cellFocus = _f[0], setCellFocus = _f[1];
    var queryParams = useMemo(function () { return ({
        page: paginationModel.page + 1,
        per_page: paginationModel.pageSize,
    }); }, [paginationModel]);
    // Get Users Fetch
    var _g = useUsers(queryParams), data = _g.data, isUserLoading = _g.isLoading;
    var _h = useAuthConfig({ enabled: hasAuthViewAccess || hasPrimaryUsersEditAccess }), authData = _h.data, isAuthLoading = _h.isLoading;
    // Derived table values
    var _j = useMemo(function () { return ({
        loading: isUserLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.users) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.users, isUserLoading]), loading = _j.loading, rowCount = _j.rowCount, rows = _j.rows;
    // Generate a unique key based on the rows data length and concatenated ids
    var tableKey = useMemo(function () {
        // setCellFocus to force focus on next cell button when table key is generated for rerender
        if (cellFocus) {
            setCellFocus(__assign(__assign({}, cellFocus), { focus: true }));
        }
        return rows.map(function (row) { return row.id; }).join(',');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);
    var handleConfirmDialogClose = useCallback(function () {
        setUserIdToAction([]);
        setUserAction(undefined);
    }, []);
    var handleUpdateUserAction = useCallback(function (userId, newUserAction) {
        setUserAction(newUserAction);
        setUserIdToAction([userId]);
    }, []);
    var buttonDisabledSx = useMemo(function () { return ({
        opacity: 0.5,
        cursor: 'not-allowed',
    }); }, []);
    var renderUsername = useCallback(function (_a) {
        var user = _a.row, tabIndex = _a.tabIndex;
        return user.username === currentUser || hasPrimaryUsersEditAccess ? (_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: "".concat(LOCAL_USERS_ROUTE, "/").concat(user.id) }, { children: user.username }))) : (user.username);
    }, [currentUser, hasPrimaryUsersEditAccess]);
    var renderDisableCell = useCallback(function (_a) {
        var user = _a.row, tabIndex = _a.tabIndex;
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: user.enabled ? t('pages.local_users.table.buttons.disable_user_tooltip') : t('pages.local_users.table.buttons.enable_user_tooltip') }, { children: _jsx(OGButton, { "aria-label": user.enabled
                    ? format(t('pages.local_users.table.buttons.disable_user_label'), user.id)
                    : format(t('pages.local_users.table.buttons.enable_user_label'), user.id), "aria-disabled": "false", size: "small", startIcon: user.enabled ? _jsx(StopIcon, {}) : _jsx(PowerIcon, {}), variant: "borderless", tabIndex: tabIndex, onClick: function () {
                    if (user.enabled) {
                        handleUpdateUserAction(user.id, 'disable');
                    }
                    else {
                        handleUpdateUserAction(user.id, 'enable');
                    }
                }, propagateSpacebar: false }) })));
    }, [handleUpdateUserAction, t]);
    var renderDeleteCell = useCallback(function (_a) {
        var user = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        var buttonDisabled = !user.deletable;
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: !buttonDisabled ? t('pages.local_users.table.buttons.delete_user_tooltip') : '' }, { children: _jsx(OGButton, { "aria-label": format(t('pages.local_users.table.buttons.delete_user_label'), user.id), "aria-disabled": buttonDisabled, size: "small", startIcon: _jsx(DeleteIcon, {}), sx: buttonDisabled ? buttonDisabledSx : {}, variant: "borderless", tabIndex: tabIndex, onClick: function () {
                    if (!buttonDisabled) {
                        // Set tabIndex state to next row
                        var index = api.getRowIndexRelativeToVisibleRows(user.id);
                        var nextFocusIndex = index === 0 ? 1 : index - 1;
                        var nextFocusRowId = rows[nextFocusIndex] ? rows[nextFocusIndex].id : undefined;
                        setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'delete', focus: false } : undefined);
                        handleUpdateUserAction(user.id, 'delete');
                    }
                }, propagateSpacebar: false }) })));
    }, [buttonDisabledSx, handleUpdateUserAction, t, rows]);
    var renderExpireCell = useCallback(function (_a) {
        var user = _a.row, tabIndex = _a.tabIndex;
        var actionTooltip = user.expired ? t('pages.local_users.table.buttons.restore_user_tooltip') : t('pages.local_users.table.buttons.expire_user_tooltip');
        var buttonDisabled = user.no_password;
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: !buttonDisabled ? actionTooltip : '' }, { children: _jsx(OGButton, { "aria-label": user.expired
                    ? format(t('pages.local_users.table.buttons.restore_user_label'), user.id)
                    : format(t('pages.local_users.table.buttons.expire_user_label'), user.id), "aria-disabled": buttonDisabled, size: "small", startIcon: user.expired ? _jsx(OGReinstatePasswordIcon, {}) : _jsx(OGExpirePasswordIcon, {}), sx: buttonDisabled ? buttonDisabledSx : {}, variant: "borderless", tabIndex: tabIndex, onClick: function () {
                    if (buttonDisabled) {
                        return;
                    }
                    if (user.expired) {
                        handleUpdateUserAction(user.id, 'restore');
                    }
                    else {
                        handleUpdateUserAction(user.id, 'expire');
                    }
                }, propagateSpacebar: false }) })));
    }, [buttonDisabledSx, handleUpdateUserAction, t]);
    var renderUnlockCell = useCallback(function (_a) {
        var user = _a.row, tabIndex = _a.tabIndex;
        var buttonDisabled = !hasEditAccess || !user.locked_out;
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: !buttonDisabled ? t('pages.local_users.table.buttons.unlock_user_tooltip') : '' }, { children: _jsx(OGButton, { "aria-label": format(t('pages.local_users.table.buttons.unlock_user_label'), user.id), "aria-disabled": buttonDisabled, size: "small", startIcon: _jsx(LockOpenIcon, {}), sx: buttonDisabled ? buttonDisabledSx : {}, variant: "borderless", tabIndex: tabIndex, onClick: function () {
                    if (!buttonDisabled) {
                        handleUpdateUserAction(user.id, 'unlock');
                    }
                }, propagateSpacebar: false }) })));
    }, [buttonDisabledSx, hasEditAccess, handleUpdateUserAction, t]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'username',
            headerName: t('pages.local_users.table.headers.username'),
            sortable: false,
            maxWidth: 1,
            minWidth: 150,
            renderCell: renderUsername,
        },
        {
            field: 'description',
            headerName: t('pages.local_users.table.headers.description'),
            flex: 1,
            sortable: false,
            minWidth: 200,
        },
        {
            field: 'unlock',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderUnlockCell,
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderDeleteCell,
        },
        {
            field: 'disable',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderDisableCell,
        },
        {
            field: 'expire',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderExpireCell,
        },
    ]; }, [renderDeleteCell, renderDisableCell, renderExpireCell, renderUnlockCell, renderUsername, t]);
    return (_jsxs(_Fragment, { children: [currentUser && userIdsToAction.length && userAction && authData ? (_jsx(UserActionDialog, { currentUser: currentUser, userIds: userIdsToAction, users: rows, userAction: userAction, authMode: authData.auth.mode, handleClose: handleConfirmDialogClose, setRowSelectionModel: setRowSelectionModel })) : null, _jsxs(Box, { children: [hasEditAccess && (_jsx(UsersBulkOperations, { userIds: rowSelectionModel, totalUsers: rows.length, setRowSelectionModel: setRowSelectionModel, setUserAction: setUserAction, setUserIdToAction: setUserIdToAction })), _jsx(Box, { children: _jsx(OGTable, { id: "local-users-table", "aria-label": t('pages.local_users.table.label'), autoHeight: true, columns: columns, rows: rows, loading: loading || isAuthLoading, noRowsMessage: t('pages.local_users.table.no_row_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", checkboxSelection: hasEditAccess, onRowSelectionModelChange: setRowSelectionModel, rowSelectionModel: rowSelectionModel, columnVisibilityModel: {
                                unlock: hasEditAccess,
                                delete: hasEditAccess,
                                disable: hasEditAccess,
                                expire: hasEditAccess,
                            }, setCellFocus: cellFocus }, tableKey) })] })] }));
}
// Reduce re-renders
export default memo(LocalUsersTable);
