var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { OGButton } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
/**
 * Adds a button that when focused, moves down and when blurred, moves up. On click, it attempts to
 * find the the element with id `focusContainerId` and shifts focus to it if one exists.
 */
export default function SkipToContentButton(_a) {
    var focusContainerId = _a.focusContainerId;
    var t = useTranslation().t;
    var onClick = useCallback(function (event) {
        // Unfocus the button that triggered this callback so that it disappears
        // regardless of having another target to shift focus to
        var self = event.currentTarget;
        self.blur();
        var mainContent = document.getElementById(focusContainerId);
        if (mainContent) {
            mainContent.focus();
        }
    }, [focusContainerId]);
    return (_jsx(OGButton, __assign({ onClick: onClick, sx: {
            'transform': 'translateY(-200%)',
            'transition': 'ease-in-out',
            'transitionDuration': '0.15s',
            '&:focus': {
                transform: 'translateY(0)',
            },
        } }, { children: t('components.appbar.buttons.skip_to_main_content') })));
}
