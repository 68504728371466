var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
export function OGPseudoTable(_a) {
    var sx = _a.sx, props = __rest(_a, ["sx"]);
    return _jsx(MuiTable, __assign({ component: "div", role: undefined, sx: __assign({ display: 'flex', flexDirection: 'column', width: '100%' }, sx) }, props));
}
export function OGPseudoTableBody(_a) {
    var sx = _a.sx, props = __rest(_a, ["sx"]);
    return _jsx(MuiTableBody, __assign({ component: "div", role: undefined, sx: __assign({ display: 'flex', flexDirection: 'column', width: '100%' }, sx) }, props));
}
export function OGPseudoTableCell(_a) {
    var sx = _a.sx, props = __rest(_a, ["sx"]);
    return _jsx(MuiTableCell, __assign({ component: "div", sx: __assign({ alignItems: 'center', display: 'flex' }, sx) }, props));
}
export function OGPseudoTableHead(_a) {
    var sx = _a.sx, props = __rest(_a, ["sx"]);
    return _jsx(MuiTableHead, __assign({ component: "div", role: undefined, sx: __assign({ display: 'flex', width: '100%' }, sx) }, props));
}
export function OGPseudoTableRow(_a) {
    var sx = _a.sx, props = __rest(_a, ["sx"]);
    return _jsx(MuiTableRow, __assign({ component: "div", role: undefined, sx: __assign({ display: 'flex', flexWrap: 'nowrap', minWidth: 'fit-content', width: '100%' }, sx) }, props));
}
