var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { secondsToDHMS } from '@/utils/datetime';
/**
 * Returns a formatted date string that increments by 1 second for every second that passes starting from the `initialValue`.
 * If `dynamic` is set to false, then a formatted `initialValue` will be returned that does not increment.
 */
export default function DynamicDurationValue(_a) {
    var initialValue = _a.initialValue, dynamic = _a.dynamic;
    var _b = __read(useState(initialValue), 2), duration = _b[0], setDuration = _b[1];
    useEffect(function () {
        if (!dynamic)
            return function () { return undefined; };
        var interval = setInterval(function () {
            setDuration(function (x) { return x + 1; });
        }, 1000);
        return function () {
            clearInterval(interval);
        };
    }, [dynamic, setDuration]);
    return _jsx(_Fragment, { children: secondsToDHMS(duration) });
}
