var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useReducer, useState } from 'react';
import { checkSelectedFilterApplied, verifySelectedResourceFilter } from './resource-filter-utils';
import { useResourceSmartgroups } from '@/api/smf-resources/smartgroups';
var ResourceFilterContext = createContext(null);
/**
 * Resource Filter Context Consumer
 */
export function useResourceFilterContext() {
    var context = useContext(ResourceFilterContext);
    if (!context) {
        throw new Error('useResourceFilterContext must be used within a ResourceFilterContextProvider');
    }
    return context;
}
/**
 * Selected Resource Filter Reducer
 */
var selectedResourceFilterReducer = function (state, action) {
    switch (action.type) {
        case 'clear':
            return null;
        case 'set':
            return action.payload;
        case 'updateQuery':
            return (state && __assign(__assign({}, state), { query: JSON.stringify(action.payload), queryObject: action.payload }));
        /* istanbul ignore next */
        default:
            console.error('Invalid action type for selectedResourceFilter');
            return null;
    }
};
/**
 * Hook to manage the selected resource filter
 * - Validates filter from smartgroups.
 */
export function useSelectedResourceFilter(permissions) {
    var data = useResourceSmartgroups({}, { enabled: permissions.hasSmartgroupsViewAccess }).data;
    var _a = __read(useReducer(selectedResourceFilterReducer, null), 2), _selectedResourceFilter = _a[0], dispatchSelectedResourceFilter = _a[1];
    /**
     The selected filter must be verified before passing down to context.
     This is to prevent the selected filter from being out of "sync" if the filter is deleted or changed.
     */
    var selectedResourceFilter = useMemo(function () { return verifySelectedResourceFilter(_selectedResourceFilter, data === null || data === void 0 ? void 0 : data.smartgroups); }, [_selectedResourceFilter, data === null || data === void 0 ? void 0 : data.smartgroups]);
    return {
        selectedResourceFilter: selectedResourceFilter,
        dispatchSelectedResourceFilter: dispatchSelectedResourceFilter,
    };
}
/**
 * Resource Filter Context Provider
 */
export function ResourceFilterContextProvider(_a) {
    var children = _a.children, filter = _a.filter, setFilter = _a.setFilter, permissions = _a.permissions;
    var _b = useSelectedResourceFilter(permissions), selectedResourceFilter = _b.selectedResourceFilter, dispatchSelectedResourceFilter = _b.dispatchSelectedResourceFilter;
    var isSelectedFilterApplied = useMemo(function () { return checkSelectedFilterApplied(selectedResourceFilter, filter); }, [selectedResourceFilter, filter]);
    var _c = __read(useState(false), 2), error = _c[0], setError = _c[1];
    var contextValue = useMemo(function () { return ({
        filter: filter,
        setFilter: setFilter,
        selectedFilter: selectedResourceFilter,
        dispatchSelectedFilter: dispatchSelectedResourceFilter,
        isSelectedFilterApplied: isSelectedFilterApplied,
        permissions: permissions,
        error: error,
        setError: setError,
    }); }, [dispatchSelectedResourceFilter, error, setError, filter, isSelectedFilterApplied, permissions, selectedResourceFilter, setFilter]);
    return _jsx(ResourceFilterContext.Provider, __assign({ value: contextValue }, { children: children }));
}
