var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import format from 'string-format';
import Box from '@mui/material/Box';
import { OGButton } from '@opengear/buttons';
import { GROUPS_ROLES_ROUTE, ROLES_ROUTE } from '@/constants/routes';
import { useRolePermissionWarningsCount } from '@/components/accounts/roles/role-form-context';
/**
 * Role form actions can be used for creating and editing role form
 */
export function RoleFormActions() {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _a = useFormContext().formState, isValid = _a.isValid, isDirty = _a.isDirty, isSubmitting = _a.isSubmitting;
    var warningsCount = useRolePermissionWarningsCount();
    var submitText = useMemo(function () {
        if (warningsCount > 1) {
            return format(t('global.buttons.apply_with_warnings'), String(warningsCount));
        }
        if (warningsCount > 0) {
            return t('global.buttons.apply_with_a_warning');
        }
        return t('global.buttons.apply');
    }, [t, warningsCount]);
    // Cancel handler that navigates back to table
    var handleCancel = useCallback(function () {
        navigate(GROUPS_ROLES_ROUTE + ROLES_ROUTE);
    }, [navigate]);
    return (_jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end" }, { children: [_jsx(OGButton, __assign({ disabled: isSubmitting, variant: "borderless", onClick: handleCancel }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ type: "submit", disabled: !isValid || !isDirty, loading: isSubmitting }, { children: submitText }))] })));
}
