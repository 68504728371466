var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import MuiLink from '@mui/material/Link';
import { Link as RouterLink, useNavigate, useLocation, useResolvedPath } from 'react-router-dom';
import React, { useCallback } from 'react';
import { OGNavMenuItem } from './nav-menu-item';
export function OGNavPopupMenuLink(_a) {
    var children = _a.children, sx = _a.sx, _b = _a.tabIndex, tabIndex = _b === void 0 ? 0 : _b, to = _a.to, onClose = _a.onClose, props = __rest(_a, ["children", "sx", "tabIndex", "to", "onClose"]);
    var navigate = useNavigate();
    var pathname = useLocation().pathname;
    var activeRoute = pathname === useResolvedPath(to).pathname;
    var handleClose = useCallback(function () {
        if (!activeRoute)
            onClose();
    }, [activeRoute, onClose]);
    var handleKeyDown = useCallback(function (event) {
        if (event.code === 'Enter') {
            navigate(to);
            handleClose();
        }
    }, [handleClose, navigate, to]);
    // move menu padding into link child so that the context menu is a link context menu for the whole menu element
    return (_jsx(OGNavMenuItem, __assign({ selected: pathname.startsWith(to.toString()), onClick: handleClose, sx: __assign({ padding: 0 }, sx), tabIndex: tabIndex }, props, { onKeyDown: handleKeyDown }, { children: _jsx(MuiLink, __assign({ component: RouterLink, sx: {
                'color': 'inherit',
                'fontWeight': 'inherit',
                'lineHeight': 1,
                'paddingBottom': 0.75,
                'paddingLeft': 2,
                'paddingRight': 3,
                'paddingTop': 0.75,
                'textDecoration': 'none',
                'width': '100%',
                ':active, :focus-visible, :hover': {
                    color: 'inherit',
                    border: 'none',
                    outline: 'inherit',
                    textDecoration: 'none',
                },
            }, 
            // let menu parent handle the tab index
            tabIndex: -1, to: to }, { children: children })) })));
}
