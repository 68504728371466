var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { useLighthouseToken, formatApiPath } from '@/api/utils';
import { useDeleteSession } from '@/api/sessions';
import { ValidationError, ENABLE_VALIDATION } from '../../../types/utils';
import { isErrorResponse } from '../../../types/guards';
import { GENERIC_ERROR_MESSAGE, formatErrorMessage } from '@/utils/errors';
/**
 * Custom hook for making authenticated API requests and handling the responses.
 *
 * @returns An object containing the `executeFetch` function for making API requests and the `decodeResponse` function for handling the responses.
 */
export var useLighthouseFetch = function () {
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var signOut = useDeleteSession();
    var token = useLighthouseToken().token;
    var t = useTranslation().t;
    /**
     * Prepares the headers for the fetch request by modifying the headers object.
     */
    var prepareHeaders = useCallback(function (headers) {
        if (headers === void 0) { headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }); }
        if (token) {
            headers.append('Authorization', "Token ".concat(token));
        }
        return headers;
    }, [token]);
    /**
     * Executes a LiPy fetch request with the specified path and fetch options.
     * @param path - The path to fetch.
     * @param options - The fetch options to include in the request.
     * @returns A Promise that resolves to a fetch Response object.
     */
    var executeFetch = useCallback(function (path, _a) {
        var _b = _a.method, method = _b === void 0 ? 'GET' : _b, _c = _a.body, body = _c === void 0 ? null : _c, queryParams = _a.queryParams, headers = _a.headers, signal = _a.signal, fullyQualifiedPath = _a.fullyQualifiedPath;
        var url = fullyQualifiedPath ? path : formatApiPath(path, queryParams);
        return fetch(url, {
            headers: prepareHeaders(headers),
            method: method,
            body: body,
            signal: signal,
        });
    }, [prepareHeaders]);
    /**
     * Handles unauthorized requests by signing the user out and redirecting them to the login screen.
     */
    var handleUnauthorizedRequest = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    alertNotification(t('api.errors.unauthorized.message'), {
                        header: t('api.errors.unauthorized.header'),
                        persistOnUnmount: true,
                        preventDuplicate: true,
                        priority: OGToastPriorities.Warning,
                    });
                    return [4 /*yield*/, signOut()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, signOut, t]);
    /**
     * Handle validation errors raised by the decoder function. If the error is a validation
     * error an alert will be displayed containing the validation error, else, it rethrows
     * the error for react-query to retry the request.
     * @param error - The error object.
     * @returns An object containing the decoded data and status code.
     */
    var handleDecodingError = useCallback(function (error) {
        if (error instanceof ValidationError && ENABLE_VALIDATION) {
            alertNotification(formatErrorMessage(error, GENERIC_ERROR_MESSAGE), {
                header: t('api.errors.validation_error.header'),
                persistOnUnmount: true,
                priority: OGToastPriorities.Error,
            });
            return;
        }
        // else error isn't a validation error, rethrow so react-query retries the request
        throw error;
    }, [alertNotification, t]);
    /**
     * Decodes the response from the server using the provided decoder function and gracefully handles any validation errors.
     * If the response status is 401 the user will be signed out and redirected to the login screen.
     *
     * @template TData - The type of data to be decoded.
     * @param {any} decoder - The decoder function to decode the response.
     * @param {Response} response - The response object from the server.
     * @returns A promise that resolves to an object containing the decoded data and status.
     */
    var decodeResponse = useCallback(function (decoder, response) { return __awaiter(void 0, void 0, Promise, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(response.status === 401)) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleUnauthorizedRequest()];
                case 1:
                    _a.sent();
                    return [2 /*return*/, { data: null, status: 401 }];
                case 2:
                    // Close alerts
                    closeRef.current();
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, decoder(response)];
                case 4:
                    result = _a.sent();
                    return [2 /*return*/, result];
                case 5:
                    error_1 = _a.sent();
                    handleDecodingError(error_1);
                    // Validation error has occurred. We need to return null here to prevent react-query from retrying the request.
                    return [2 /*return*/, { data: null, status: undefined }];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [closeRef, handleDecodingError, handleUnauthorizedRequest]);
    /**
     * Handles common status codes and displays alerts based on the status code and messages provided.
     * The (!) symbol signifies the `messages` param must be supplied for an alert to render, otherwise the function will quietly return.
     *
     * - 200, 201, 202, 204: Display a success alert (!)
     * - 400, 402, 409: Display an alert if an error message is supplied in the response data
     * - 401: No-op. Use `decodeResponse` to handle unauthorized requests
     * - 403: Display a forbidden alert
     * - 404: Display a not found alert (!)
     * - 405: Display a not allowed alert (!)
     * - 406: Display a not accepted alert (!)
     * - 500, 502, 504: Display an error alert (!)
     * - Other: Throw a 'Status not handled' error (dev) or display a generic error message (prod)
     *
     * @template TData - The type of the response data.
     * @param data - The response data.
     * @param status - The HTTP status code of the response.
     * @param messages - The alert messages for different status codes.
     * @returns An object containing the decoded response data and status code.
     */
    var handleCommonStatusCodes = useCallback(function (data, status, messages) {
        switch (status) {
            case 200:
            case 201:
            case 202:
            case 204:
                if (messages.ok) {
                    alertNotification(messages.ok.message, __assign({ header: messages.ok.header, priority: OGToastPriorities.Success, persistOnUnmount: true }, messages.ok.options));
                }
                return;
            case 400:
            case 402:
            case 409:
                if (isErrorResponse(data)) {
                    alertNotification(formatErrorMessage(data), {
                        header: t('api.errors.request_error.header'),
                        priority: OGToastPriorities.Error,
                        persistOnUnmount: true,
                    });
                }
                return;
            case 401:
                // No-op. Use the decodeResponse function to handle unauthorized requests.
                return;
            case 403:
                alertNotification(messages.forbidden.message, messages.forbidden.options);
                return;
            case 404:
                if (messages.not_found) {
                    alertNotification(messages.not_found.message, messages.not_found.options);
                }
                return;
            case 405:
                if (messages.not_allowed) {
                    alertNotification(messages.not_allowed.message, messages.not_allowed.options);
                }
                return;
            case 406:
                if (messages.not_accepted) {
                    alertNotification(messages.not_accepted.message, messages.not_accepted.options);
                }
                return;
            case 500:
            case 502:
            case 504:
                if (messages.error) {
                    alertNotification(messages.error.message, __assign(__assign({}, messages.error.options), { preventDuplicate: true }));
                }
                return;
            default:
                // Throw a runtime error in development environments with validation enabled
                if (ENABLE_VALIDATION) {
                    throw new Error(t('api.errors.unhandled_status_code'));
                    // In all other cases (e.g. production), display a generic error message
                }
                else {
                    alertNotification(GENERIC_ERROR_MESSAGE, {
                        priority: OGToastPriorities.Error,
                        persistOnUnmount: true,
                    });
                }
        }
    }, [alertNotification, t]);
    return { executeFetch: executeFetch, decodeResponse: decodeResponse, handleCommonStatusCodes: handleCommonStatusCodes };
};
