var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { OGTable } from '@opengear/table';
import Stack from '@mui/material/Stack';
import { useNodes } from '@/api/nodes';
import { CellularDetailsModal } from '@/components/nodes/cellular-details-modal';
import { EnrolledStateCell } from '@/components/nodes/enrolled-state-cell';
import { CellularStatus } from '@/components/nodes/cellular-status';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { NodeStatus, nodeStatusToQuery, useJson } from '@/utils/query';
import { NodeFilter, useNodeFilter } from '@/containers/filters/advanced-filters/node-filter';
import { IQueryDataType, IQueryNodeField, IQueryOperation, IQueryType } from '../../../../../types/query/types';
import { NodeTagsModal } from '@/containers/nodes/node-tags-modal';
import { FreeTextSearch, useFreeTextSearch, useNodeTagsFieldName } from '@/components/free-text-search';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useCellhealthSettings } from '@/api/services/cellhealth';
import { useSortModel } from '@/hooks/sort-model';
import { TemplatePopover } from '@/components/nodes/node/template-status-popover';
import { ChipPopover } from '@/components/chip-popover';
// Table constants
var statusQuery = nodeStatusToQuery(NodeStatus.Enrolled);
var statusFieldNames = [
    IQueryNodeField.name,
    IQueryNodeField.id,
    IQueryNodeField.lhvpnAddress,
    IQueryNodeField.macAddress,
    IQueryNodeField.model,
    IQueryNodeField.serialNumber,
    IQueryNodeField.cellHealthStatus,
    IQueryNodeField.firmwareVersion,
];
var opengearOnlyQuery = {
    type: IQueryType.basic,
    datatype: IQueryDataType.string,
    fieldname: IQueryNodeField.product,
    oper: IQueryOperation.equal,
    value: 'opengear',
};
// This is a heavily cut-down version of the NodesEnrolledTable
function TemplateNodeSelectTable(_a) {
    var editableNodeFilters = _a.editableNodeFilters, readableNodeTags = _a.readableNodeTags, selectedNodes = _a.selectedNodes, onSelectChange = _a.onSelectChange;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    // evaluate permissions
    var canViewNodeTags = useMemo(function () { return hasPermission('configure.edit_tags.view'); }, [hasPermission]);
    // Get cell health settings to render cell column conditionally
    var cellHealthSettings = useCellhealthSettings().data;
    // manage cell details popup
    var _b = __read(useState(), 2), cellNodeDetails = _b[0], setCellNodeDetails = _b[1];
    // manage update node tags popup
    var _c = __read(useState(), 2), nodeToEditTags = _c[0], setNodeToEditTags = _c[1];
    // Table controls - Server side available query params
    var _d = __read(usePaginationModel('nodes_enrolled'), 3), paginationModel = _d[0], handlePaginationModelChange = _d[1], pageSizeOptions = _d[2];
    var _e = __read(useSortModel('last_updated'), 3), defaultSortModel = _e[0], sortQueryParams = _e[1], handleSortModelChange = _e[2];
    // Node filter
    var _f = useNodeFilter(), nodeFilter = _f.nodeFilter, setNodeFilter = _f.setNodeFilter;
    // adding tag fields to fieldnames if user has access to view tags
    var _g = useNodeTagsFieldName(readableNodeTags, statusFieldNames), freeTextDisabled = _g.freeTextDisabled, newFieldNames = _g.newFieldNames;
    // Free text search
    var _h = useFreeTextSearch(newFieldNames), textQueryObject = _h.textQueryObject, dispatchTextQuery = _h.dispatchTextQuery;
    // TODO - CDM-183 - extend logic
    // Aggregate all query items into one json query string
    var json = useJson(statusQuery, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject, textQueryObject, opengearOnlyQuery);
    var queryParams = useMemo(function () { return (__assign(__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize, json: json }, sortQueryParams), { get_last_job: true })); }, [paginationModel, json, sortQueryParams]);
    // Get Nodes Fetch
    var _j = useNodes(queryParams), data = _j.data, isLoading = _j.isLoading;
    // Derived table values
    var _k = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.nodes) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.nodes, isLoading]), loading = _k.loading, rowCount = _k.rowCount, rows = _k.rows;
    // cell details modal handler
    var handleCellModalClose = useCallback(function () {
        setCellNodeDetails(undefined);
    }, []);
    // tags update modal handler
    var handleTagsModalClose = useCallback(function () {
        setNodeToEditTags(undefined);
    }, []);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'connection_status',
            headerName: t('pages.nodes.table.headers.state'),
            maxWidth: 1,
            minWidth: 85,
            renderCell: function (_a) {
                var row = _a.row;
                return _jsx(EnrolledStateCell, { runtime_status: row.runtime_status });
            },
        },
        { field: 'id', headerName: t('pages.nodes.table.headers.id'), minWidth: 110 },
        { field: 'name', headerName: t('pages.nodes.table.headers.name'), minWidth: 160 },
        {
            field: 'config_push_status',
            headerName: t('pages.nodes.table.headers.template'),
            sortable: false,
            renderCell: TemplatePopover,
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'cellular_health',
            headerName: t('pages.nodes.table.headers.cell'),
            minWidth: 160,
            flex: 1,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(CellularStatus, { row: row, tabIndex: tabIndex, setOpen: setCellNodeDetails }));
            },
        },
        {
            field: 'model',
            headerName: t('pages.nodes.table.headers.model'),
            valueFormatter: function (_a) {
                var value = _a.value;
                return value !== null && value !== void 0 ? value : 'N/A';
            },
            flex: 1,
            minWidth: 120,
        },
        { field: 'firmware_version', headerName: t('pages.nodes.table.headers.firmware_version'), maxWidth: 1, minWidth: 100 },
        {
            field: 'tags',
            headerName: t('pages.nodes.table.headers.tags'),
            minWidth: 220,
            sortable: false,
            flex: 1,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                if (row.tag_list.tags && row.tag_list.tags.length > 0) {
                    return _jsx(ChipPopover, { tags: row.tag_list.tags.map(function (fullTag) { return fullTag; }), tabIndex: tabIndex });
                }
                return null;
            },
        },
    ]; }, [t]);
    return (_jsxs(_Fragment, { children: [cellNodeDetails && _jsx(CellularDetailsModal, { nodeDetails: cellNodeDetails, open: !!cellNodeDetails, onClose: handleCellModalClose }), nodeToEditTags && _jsx(NodeTagsModal, { open: !!nodeToEditTags, onClose: handleTagsModalClose, nodeId: nodeToEditTags }), _jsxs(Box, __assign({ margin: -1 }, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 1, margin: 1, marginBottom: 0.5, justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(NodeFilter, { filter: nodeFilter, setFilter: setNodeFilter, permissions: { hasSmartgroupsEditAccess: editableNodeFilters } }), _jsx(FreeTextSearch, { onSearch: dispatchTextQuery, freeTextDisabled: freeTextDisabled, useDebounce: false, translations: { tooltip: t('components.nodes.text_search.tooltip') } })] })), _jsx(Box, __assign({ margin: 1 }, { children: _jsx(OGTable, { id: "node-select-table", "aria-label": t('pages.config_templates.push.select_label'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.nodes.table.no_row_message.enrolled'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, selectedRowCount: selectedNodes.length, translations: { entityLabel: t('global.table.node') }, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, keepNonExistentRowsSelected: true, columnVisibilityModel: {
                                cellular_health: !!(cellHealthSettings === null || cellHealthSettings === void 0 ? void 0 : cellHealthSettings.cell_health_enabled),
                                tags: canViewNodeTags,
                            }, checkboxSelection: true, rowSelectionModel: selectedNodes, onRowSelectionModelChange: function (newSelectionModel) { return onSelectChange(newSelectionModel); }, initialState: {
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                            } }) }))] }))] }));
}
// Reduce re-renders
export default memo(TemplateNodeSelectTable);
