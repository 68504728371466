import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
export function UpgradeInFailoverCell(_a) {
    var nodeId = _a.nodeId, checked = _a.checked, tabIndex = _a.tabIndex, onChange = _a.onChange;
    var t = useTranslation().t;
    var label = checked
        ? t('pages.firmware_upgrade_new.tables.selected_nodes.upgrade_in_failover.options.allow')
        : t('pages.firmware_upgrade_new.tables.selected_nodes.upgrade_in_failover.options.disallow');
    var handleChange = useCallback(function (event) {
        onChange(nodeId, event.target.checked);
    }, [onChange, nodeId]);
    var handleKeyDown = useCallback(function (event) {
        if (event.key === 'Enter') {
            // prevent form from submitting
            event.preventDefault();
        }
        if (event.key === ' ') {
            // prevent table scrolling and allows the onChange event to fire
            event.stopPropagation();
        }
    }, []);
    return _jsx(FormControlLabel, { control: _jsx(Switch, { checked: checked, tabIndex: tabIndex, onChange: handleChange, onKeyDown: handleKeyDown }), label: label });
}
