import { uuidToId } from '@/utils/calculations';
export var transformFormStateToRequest = function (formData) {
    // Exit early for mis-use of function
    if (!formData.name || !formData.firmware)
        return undefined;
    var firmwareId = uuidToId(formData.firmware.id);
    // Exit early for failure to parse firmware id
    if (firmwareId === undefined)
        return undefined;
    var nodes = formData.nodes.map(function (_a) {
        var id = _a.id, allowInFailover = _a.allowInFailover;
        var nodeId = uuidToId(id);
        if (nodeId === undefined)
            return undefined;
        return {
            node_id: nodeId,
            allow_in_failover: allowInFailover,
        };
    });
    // Exit early for mis-use of function or failure to parse any of the node ids
    if (nodes.length < 1 || nodes.includes(undefined))
        return undefined;
    return {
        firmware_id: firmwareId,
        immediate: formData.immediate,
        name: formData.name,
        nodes: nodes,
        scheduled_time: formData.scheduledTime && !formData.immediate ? formData.scheduledTime.unix() : 0,
    };
};
