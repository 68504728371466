var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OGLinkButton, OGLink } from '@opengear/links';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { OGReception, OGReceptionHigh, OGReceptionMedium, OGReceptionLow } from '@opengear/icons/status';
import WebUIIcon from '@mui/icons-material/Computer';
import { NodeTopMenu } from './node-top-menu';
import { NODES_ROUTE } from '@/constants/routes';
import { secondsToYMWDHMS } from '@/utils/datetime';
import { useUIPermissions } from '@/api/sessions/self/ui';
// Heading indicator
export function ConnectionIndicator(_a) {
    var connectionStatus = _a.connectionStatus, cellularHealthStatus = _a.cellularHealthStatus, isSubscriptionExpired = _a.isSubscriptionExpired;
    var t = useTranslation().t;
    if (isSubscriptionExpired) {
        return _jsx(WarningIcon, { color: "warning", "aria-label": t('pages.node.topbar.node_status_indicator.subscriptions_expired') });
    }
    if (connectionStatus === 'unknown') {
        return _jsx(WarningIcon, { color: "warning", "aria-label": t('pages.node.topbar.node_status_indicator.connection_unknown') });
    }
    if (connectionStatus === 'connected' && (cellularHealthStatus === 'sim_issues' || cellularHealthStatus === 'connectivity_test_failed')) {
        return _jsx(OGReception, { color: "error", fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_sim_issues') });
    }
    if (connectionStatus === 'connected' && cellularHealthStatus === 'pending') {
        return _jsx(InfoIcon, { fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_pending') });
    }
    if (connectionStatus === 'connected' && cellularHealthStatus === 'bad') {
        return _jsx(OGReceptionLow, { color: "error", fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_bad') });
    }
    if (connectionStatus === 'connected' && cellularHealthStatus === 'moderate') {
        return _jsx(OGReceptionMedium, { color: "warning", fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_moderate') });
    }
    if (connectionStatus === 'connected' && cellularHealthStatus === 'good') {
        return _jsx(OGReceptionHigh, { color: "success", fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_good') });
    }
    if (connectionStatus === 'connected' && cellularHealthStatus === 'interface_disabled') {
        return _jsx(OGReception, { fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_interface_disabled') });
    }
    if (connectionStatus === 'connected' && (cellularHealthStatus === 'unknown' || cellularHealthStatus === 'n/a')) {
        return _jsx(CheckCircleIcon, { color: "success", fontSize: "medium", "aria-label": t('pages.node.topbar.node_status_indicator.connected_cellular_unknown') });
    }
    return _jsx(ErrorIcon, { fontSize: "medium", "aria-hidden": "false", color: "error", "aria-label": t('pages.node.topbar.node_status_indicator.disconnected') });
}
// Web Access button
function WebAccessButton(_a) {
    var name = _a.name, connectionStatus = _a.connectionStatus;
    var t = useTranslation().t;
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.node.topbar.buttons.web_access_tooltip') }, { children: _jsx("div", { children: _jsx(OGLinkButton, { "aria-label": format(t('pages.node.topbar.buttons.web_access'), name), external: true, disabled: connectionStatus !== 'connected', startIcon: _jsx(WebUIIcon, {}), sx: { paddingX: 1, paddingY: 0.5, minWidth: '40px' }, target: "_self", to: "/".concat(name, "/") }) }) })));
}
export function NodeTopBar(_a) {
    var node = _a.node;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var viewWebAccess = hasPermission('manage.nodes.node_ui.view');
    var _b = node.runtime_status, connectionStatus = _b.connection_status, changeDelta = _b.change_delta, cellularHealthStatus = node.cellhealth_runtime_status.status, name = node.name, commonName = node.common_name, nodeId = node.id, subscription = node.subscription;
    return (_jsxs(Box, __assign({ display: "flex", gap: 1, marginBottom: 2, alignItems: "center" }, { children: [_jsx(OGLink, __assign({ "aria-label": t('pages.node.topbar.go_back_link'), to: NODES_ROUTE, sx: { padding: 1 } }, { children: _jsx(ArrowBackIcon, { fontSize: "medium" }) })), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 0.5 }, { children: [_jsxs(Box, __assign({ component: "span", display: "flex", gap: 0.5 }, { children: [_jsx(ConnectionIndicator, { connectionStatus: connectionStatus, cellularHealthStatus: cellularHealthStatus, isSubscriptionExpired: subscription === null || subscription === void 0 ? void 0 : subscription.expired }), _jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: name }))] })), changeDelta && _jsx(Typography, { children: format(t('pages.node.topbar.last_status_change'), secondsToYMWDHMS(changeDelta)) })] })), _jsx(Box, { flexGrow: 1 }), node.product === 'opengear' && viewWebAccess ? _jsx(WebAccessButton, { name: commonName, connectionStatus: connectionStatus }) : null, _jsx(NodeTopMenu, { nodeId: nodeId, rights: node.rights })] })));
}
