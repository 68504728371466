var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Format from 'string-format';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OGButton } from '@opengear/buttons/button';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormContainerContext } from '@/components/layout/forms';
import { IPV4_OR_NUM_ONLY_PATTERN } from '@/constants/regex';
import { useDeviceView } from '@/hooks/use-device-view';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Constants
export var NameFieldMaxLength = 128;
export var DescriptionFieldMaxLength = 128;
export var AuthClearTextKeyMaxLength = 8;
export var AuthMd5KeyMaxLength = 16;
function useIsAuthDirty() {
    var formState = useFormContainerContext().formState;
    var dirtyFields = formState.dirtyFields;
    return useCallback(function () {
        var isClearTextDirty = dirtyFields.cleartext, md5KeysDirty = dirtyFields.md5_keys;
        var isMd5Dirty = md5KeysDirty ? Object.values(md5KeysDirty).some(function (value) { return !!value.id || !!value.key; }) : false;
        return isClearTextDirty || isMd5Dirty;
    }, [dirtyFields]);
}
export function NameField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.name.label')),
            maxLength: {
                value: NameFieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.name.label'), NameFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.name.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, fullWidth: true, required: true, 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true }, field)));
        } }));
}
export function MasqueradeCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "ip_masquerade", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasEditPermission }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value })));
            } }), label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.ip_masquerade.label') }));
}
export function PassiveCheckbox() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "passive", render: function (_a) {
                var _b = _a.field, value = _b.value, onChange = _b.onChange, field = __rest(_b, ["value", "onChange"]);
                return (_jsx(Checkbox, __assign({ disabled: !permissions.hasEditPermission }, field, { onChange: function (event, v) {
                        onChange(v);
                    }, checked: value })));
            } }), label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.passive.label') }));
}
export function AreaField(_a) {
    var configurationMethod = _a.configurationMethod;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions;
    if (configurationMethod === 'network')
        return null;
    return (_jsx(Controller, { control: control, name: "area", rules: {
            pattern: {
                value: IPV4_OR_NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.area.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.area.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.area.tooltip') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, fullWidth: true }, field)));
        } }));
}
export function CostField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "cost", rules: {
            pattern: {
                value: IPV4_OR_NUM_ONLY_PATTERN,
                message: invalidFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.cost.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: _jsxs(_Fragment, { children: [t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.cost.label'), _jsx(OGIconTooltip, { title: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.cost.tooltip') })] }), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, fullWidth: true }, field)));
        } }));
}
export function AuthMethodToggleField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, resetField = _a.resetField;
    var isMobileView = useDeviceView().isMobileView;
    var isAuthDirty = useIsAuthDirty();
    var resetToggle = useCallback(function () {
        if (!isAuthDirty())
            return;
        resetField('cleartext');
        resetField('md5_keys');
    }, [resetField, isAuthDirty]);
    return (_jsx(Controller, { control: control, name: "auth_method", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
            return (_jsxs(_Fragment, { children: [_jsx(Typography, { children: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.auth_method.label') }), _jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, orientation: isMobileView ? 'vertical' : 'horizontal', value: value, onChange: function (event, authMethod) {
                            if (!authMethod)
                                return;
                            onChange(authMethod);
                            resetToggle();
                        }, sx: { marginTop: '6px' } }, field, { children: [_jsx(ToggleButton, __assign({ value: "md5" }, { children: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.auth_method.md5') })), _jsx(ToggleButton, __assign({ value: "cleartext" }, { children: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.auth_method.cleartext') })), _jsx(ToggleButton, __assign({ value: "no_auth" }, { children: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.auth_method.no_auth') }))] }))] }));
        } }));
}
export function AuthMD5(_a) {
    var md5Keys = _a.md5Keys, appendMd5Key = _a.appendMd5Key, removeMd5Key = _a.removeMd5Key;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions, setFocus = _b.setFocus;
    var isMobileView = useDeviceView().isMobileView;
    var addKeyButton = useRef(null);
    var handleAdd = useCallback(function () {
        appendMd5Key({ id: '', key: '' });
        var num = md5Keys.length;
        setFocus("md5_keys.".concat(num, ".id"));
    }, [setFocus, appendMd5Key, md5Keys]);
    var handleRemove = useCallback(function (index) {
        if (md5Keys.length > 1) {
            var nextNetwork = md5Keys.length - 1 > index ? index + 1 : index - 1;
            setFocus("md5_keys.".concat(nextNetwork, ".id"));
        }
        else if (addKeyButton.current) {
            addKeyButton.current.focus();
        }
        removeMd5Key(index);
    }, [setFocus, removeMd5Key, md5Keys]);
    return (_jsxs(_Fragment, { children: [md5Keys.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row', spacing: 1, paddingBottom: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "md5_keys.".concat(index, ".id"), rules: {
                            required: requiredFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.id.label')),
                            pattern: {
                                value: IPV4_OR_NUM_ONLY_PATTERN,
                                message: invalidFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.id.label')),
                            },
                        }, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, newField = __rest(_b, ["ref"]), error = _a.fieldState.error;
                            return (_jsx(TextField, __assign({ disabled: !permissions.hasEditPermission, fullWidth: true, label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.id.label'), sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref }, newField)));
                        } }), isMobileView ? (_jsxs(Stack, __assign({ direction: "row", spacing: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "md5_keys.".concat(index, ".key"), rules: {
                                    required: requiredFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.key.label')),
                                    maxLength: {
                                        value: AuthMd5KeyMaxLength,
                                        message: maxLengthFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.key.label'), AuthMd5KeyMaxLength.toString()),
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, ref = _b.ref, newField = __rest(_b, ["ref"]), error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ disabled: !permissions.hasEditPermission, fullWidth: true, label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.key.label'), error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref }, newField)));
                                } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: Format(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.buttons.clear'), String(index + 1)) }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": Format(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.buttons.clear'), String(index + 1)), size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", disabled: !permissions.hasEditPermission, onClick: function () { return handleRemove(index); } }) })) }))] }), "".concat(item.uuid, "-remove"))) : (_jsxs(_Fragment, { children: [_jsx(Controller, { control: control, name: "md5_keys.".concat(index, ".key"), rules: {
                                    required: requiredFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.key.label')),
                                    maxLength: {
                                        value: AuthMd5KeyMaxLength,
                                        message: maxLengthFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.key.label'), AuthMd5KeyMaxLength.toString()),
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, ref = _b.ref, newField = __rest(_b, ["ref"]), error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ disabled: !permissions.hasEditPermission, fullWidth: true, label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.key.label'), sx: { maxWidth: 400 }, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, inputRef: ref }, newField)));
                                } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: Format(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.buttons.clear'), String(index + 1)) }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '20px' } }, { children: _jsx(OGButton, { "aria-label": Format(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.buttons.clear'), String(index + 1)), size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", disabled: !permissions.hasEditPermission, onClick: function () { return handleRemove(index); } }) })) }))] }))] }), item.uuid)); }), md5Keys.length === 0 && (_jsx(Box, __assign({ paddingBottom: 1 }, { children: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.no_rows_message') }))), _jsx(OGButton, __assign({ ref: addKeyButton, startIcon: _jsx(AddCircleOutline, {}), variant: "secondary", sx: { width: 'max-content', marginTop: 1 }, disabled: !permissions.hasEditPermission, onClick: handleAdd }, { children: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.md5_auth_keys.buttons.add') }))] }));
}
export function AuthClearTextKeyField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "cleartext", rules: {
            required: requiredFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.cleartext.label')),
            maxLength: {
                value: AuthClearTextKeyMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.cleartext.label'), AuthClearTextKeyMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ type: "password", disabled: !permissions.hasEditPermission, error: !!fieldState.error, label: t('pages.config_templates.tabs.smf.form.sections.interface.form.fields.cleartext.label'), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, fullWidth: true, required: true, sx: { marginBottom: 0 } }, field)));
        } }));
}
export function AuthContent() {
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch;
    var authMethod = watch('auth_method');
    var _b = useFieldArray({
        name: 'md5_keys',
        keyName: 'uuid',
        rules: { required: authMethod === 'md5' },
        control: control,
    }), fields = _b.fields, append = _b.append, remove = _b.remove;
    if (authMethod === 'no_auth')
        return null;
    return (_jsx(Box, __assign({ paddingTop: 2 }, { children: authMethod === 'cleartext' ? _jsx(AuthClearTextKeyField, {}) : _jsx(AuthMD5, { md5Keys: fields, appendMd5Key: append, removeMd5Key: remove }) })));
}
