var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DEFAULT_MOCK_HTTPS_PORT = {
    port: 443,
};
var DEFAULT_REQUEST_RESPONSE = {
    system_https_port: __assign({}, DEFAULT_MOCK_HTTPS_PORT),
};
export var DEFAULT_MOCK_HTTPS_PORT_REQUEST = DEFAULT_REQUEST_RESPONSE;
export var DEFAULT_MOCK_HTTPS_PORT_RESPONSE = DEFAULT_REQUEST_RESPONSE;
export var HTTPS_PORT_PATH = '/system/https_port';
