var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { IQueryDataType, IQueryType } from '../../types/query/types';
export var NEW_FILTER_ID = 'new-filter-id';
var newQueryObject = {
    items: [
        {
            items: [
                {
                    items: [
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.string,
                        },
                    ],
                    type: IQueryType.and,
                },
            ],
            type: IQueryType.or,
        },
    ],
    type: IQueryType.and,
};
export var newDefaultFilter = {
    id: NEW_FILTER_ID,
    name: '',
    query: JSON.stringify(newQueryObject),
    queryObject: newQueryObject,
    rights: { delete: false, modify: true },
};
export function createNewFilter(name) {
    return __assign(__assign({}, newDefaultFilter), { name: name });
}
