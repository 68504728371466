var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { v4 as uuidV4 } from 'uuid';
import { FormContainer } from '@/components/layout/forms';
import { ExternalAddressField, APIPortField, VPNPortField, MultiInstanceVpnPortField } from './external-network-form-controls';
export var EXTERNAL_NETWORK_MODAL_TITLE_ID = 'external-network-modal-title-id';
export var getDefaultExternalNetworkFormState = function () { return ({
    id: uuidV4(),
    address: '',
    api_port: 443,
    mi_vpn_port: 1195,
    vpn_port: 1194,
}); };
export function ExternalNetworkForm(_a) {
    var children = _a.children, defaultValues = _a.defaultValues, permissions = _a.permissions, onSubmit = _a.onSubmit, mode = _a.mode;
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    return (_jsxs(FormContainer, __assign({ ariaLabelledBy: EXTERNAL_NETWORK_MODAL_TITLE_ID, mode: mode, methods: methods, onSubmit: onSubmit, permissions: permissions }, { children: [_jsxs(Stack, __assign({ sx: { px: 2, pb: 2 } }, { children: [_jsx(ExternalAddressField, {}), _jsx(APIPortField, {}), _jsx(VPNPortField, {}), _jsx(MultiInstanceVpnPortField, {})] })), _jsx(Divider, {}), children] })));
}
