var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OGDonut } from '@opengear/donut';
export function LicenseNodeAssignments(_a) {
    var availableNodes = _a.availableNodes, assignedNodes = _a.assignedNodes, totalNodes = _a.totalNodes;
    var t = useTranslation().t;
    var data = [
        {
            id: 'assigned_nodes',
            value: assignedNodes,
            label: t('pages.subscriptions.tabs.subscriptions.subscription_item.donut_labels.assigned_nodes'),
        },
        {
            id: 'available_nodes',
            value: availableNodes,
            label: t('pages.subscriptions.tabs.subscriptions.subscription_item.donut_labels.available_nodes'),
        },
    ];
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", minWidth: "216px", marginRight: 1, flex: 1 }, { children: [_jsx(OGDonut, { data: data, size: 80, sx: { marginRight: 2 } }), _jsx(Box, { children: _jsx(Typography, __assign({ sx: { whiteSpace: 'pre-line' } }, { children: format(t('pages.subscriptions.tabs.subscriptions.subscription_item.node_assignments'), availableNodes.toString(), totalNodes.toString()) })) })] })));
}
