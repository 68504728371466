var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Space } from '@/components/entities';
import { useQueryFieldFormatter } from './query-field';
import { IQueryType } from '../../../../types/query/types';
import { getFormattedQueryOperator } from './query-operator-utils';
var QueryField = memo(function (_a) {
    var fieldname = _a.fieldname;
    var formatter = useQueryFieldFormatter();
    return _jsx(_Fragment, { children: formatter(fieldname) });
});
var QueryOperator = memo(function (_a) {
    var oper = _a.oper;
    return _jsx(_Fragment, { children: getFormattedQueryOperator(oper) });
});
var QueryType = memo(function (_a) {
    var type = _a.type;
    var t = useTranslation().t;
    switch (type) {
        case IQueryType.and:
            return _jsx(_Fragment, { children: t('components.query.type.and') });
        case IQueryType.or:
            return _jsx(_Fragment, { children: t('components.query.type.or') });
        default:
            /* istanbul ignore next */
            return null;
    }
});
var QueryValue = memo(function (_a) {
    var value = _a.value;
    var t = useTranslation().t;
    if (typeof value === 'boolean') {
        return value ? _jsx(_Fragment, { children: t('global.buttons.boolean.true') }) : _jsx(_Fragment, { children: t('global.buttons.boolean.false') });
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return _jsx(_Fragment, { children: value });
});
var QueryReadonly = memo(function (_a) {
    var _b = _a.box, box = _b === void 0 ? false : _b, first = _a.first, parentType = _a.parentType, query = _a.query;
    if ('items' in query) {
        if (query.items.length === 0) {
            return null;
        }
        // if there is only one child query then flatten by forwarding the parents props
        if (query.items.length === 1 && !box) {
            var _c = __read(query.items, 1), queryItem = _c[0];
            return _jsx(QueryReadonly, { first: first, parentType: parentType, query: queryItem });
        }
        var forceBox_1 = query.items.some(function (queryItem) { return 'items' in queryItem && queryItem.items.length > 1; });
        // if there are multiple child queries wrap in box
        var nodes = query.items.map(function (queryItem, index) { return (
        // eslint-disable-next-line react/no-array-index-key
        _jsx(QueryReadonly, { box: forceBox_1, first: index === 0, parentType: query.type, query: queryItem }, index)); });
        return parentType ? (_jsxs(_Fragment, { children: [first ? null : (_jsx(Typography, __assign({ component: "span", fontWeight: 500, margin: 1 }, { children: _jsx(QueryType, { type: parentType }) }))), _jsx(Box, __assign({ alignContent: "flex-start", bgcolor: "background.section", display: "flex", flexWrap: "wrap", padding: 2, paddingRight: 0, width: "100%" }, { children: nodes }))] })) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        _jsx(_Fragment, { children: nodes }));
    }
    return (_jsxs(Box, __assign({ component: "span", alignContent: "flex-start" }, { children: [first ? null : (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "span", fontWeight: 500 }, { children: _jsx(QueryType, { type: parentType }) })), _jsx(Space, { repeat: 4 })] })), _jsx(QueryField, { fieldname: query.fieldname }), _jsx(Space, {}), _jsx(QueryOperator, { oper: query.oper }), _jsx(Space, {}), _jsx(QueryValue, { value: query.value }), _jsx(Space, { repeat: 4 })] })));
});
export var FilterReadonlyQuery = memo(function (_a) {
    var query = _a.query;
    if (!query) {
        return null;
    }
    return (_jsx(Box, __assign({ alignContent: "flex-start", display: "flex", flexWrap: "wrap", marginBottom: 2 }, { children: _jsx(QueryReadonly, { first: true, query: query }) })));
});
