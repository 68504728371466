var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { OGUploadIcon } from '@opengear/icons/action/upload';
import { RequiredPill } from '@opengear/inputs/required-pill';
import { OGButtonLink } from '@opengear/buttons';
import { useDeviceView } from '@/hooks/use-device-view';
import { VisuallyHiddenInput } from './visually-hidden-input';
export function OGFileUploader(_a) {
    var accept = _a.accept, ariaLabel = _a.ariaLabel, children = _a.children, _b = _a.labels, labels = _b === void 0 ? {} : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, id = _a.id, _d = _a.multiple, multiple = _d === void 0 ? false : _d, onChange = _a.onChange, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.autoFocus, autoFocus = _f === void 0 ? false : _f;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var theme = useTheme();
    var _g = __read(useState(false), 2), isActiveDrag = _g[0], setIsActiveDrag = _g[1];
    var inputRef = useRef(null);
    var inputId = "".concat(id, "-input");
    var labelId = "".concat(id, "-label");
    var hasChildren = !(!children || (Array.isArray(children) && children.length < 1));
    var activeLabel = labels.active || t('components.file_uploader.labels.active');
    var linkLabel = labels.link || t('components.file_uploader.labels.link');
    var mainLabel = labels.main || t('components.file_uploader.labels.main');
    var supportedFilesLabel = labels.supportedFiles || t('components.file_uploader.labels.supported_files');
    var handleInputChange = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.files) {
            onChange(Object.values(event.target.files));
        }
    }, [onChange]);
    // Workaround for clearing input value if the user uploads the same file twice
    var handleInputClick = useCallback(function () {
        if (inputRef.current && !disabled) {
            inputRef.current.value = '';
        }
    }, [disabled]);
    var handleLabelClick = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (inputRef.current && !disabled) {
            inputRef.current.click();
        }
    }, [disabled]);
    var handleLabelDragEnter = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsActiveDrag(true);
    }, []);
    var handleLabelDragOver = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
    }, []);
    var handleLabelDragExit = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsActiveDrag(false);
    }, []);
    var handleLabelDrop = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        setIsActiveDrag(false);
        if (!disabled) {
            onChange(Object.values(event.dataTransfer.files));
        }
    }, [disabled, onChange]);
    var labelMessage = isActiveDrag && !disabled ? (_jsx(Typography, __assign({ component: "span", marginBottom: 0.5 }, { children: activeLabel }))) : (_jsxs(Typography, __assign({ component: "span", color: disabled ? 'text.disabled' : 'text.primary', marginBottom: 0.5, sx: { display: 'flex', alignItems: 'center' } }, { children: [mainLabel, "\u00A0", _jsx(OGButtonLink, __assign({ autoFocus: autoFocus, variant: "standard", disabled: disabled }, { children: linkLabel }))] })));
    var supportedFilesMessage = accept ? (_jsx(Typography, __assign({ component: "span", color: disabled ? 'text.disabled' : 'text.secondary' }, { children: "".concat(supportedFilesLabel, " ").concat(accept) }))) : null;
    return (_jsxs(_Fragment, { children: [_jsx(VisuallyHiddenInput, { ref: inputRef, id: inputId, "aria-labelledby": labelId, accept: accept, disabled: disabled, type: "file", multiple: multiple, onChange: handleInputChange, onClick: handleInputClick }), _jsxs(InputLabel, __assign({ "aria-label": ariaLabel, id: labelId, htmlFor: inputId, onClick: handleLabelClick, onDragEnter: handleLabelDragEnter, onDragOver: handleLabelDragOver, onDragExit: handleLabelDragExit, onDrop: handleLabelDrop, sx: {
                    backgroundColor: 'background.menu',
                    border: "1px dashed ".concat(theme.palette.divider),
                    borderRadius: 0.5,
                    display: 'flex',
                    flexDirection: isMobileView ? 'column' : 'row',
                    flexWrap: 'wrap',
                    alignItems: 'start',
                    height: 'auto',
                    width: '100%',
                } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 2, padding: 2 }, { children: [_jsx(OGUploadIcon, { sx: { color: disabled ? 'text.disabled' : 'text.secondary', width: '6rem', height: 'auto' } }), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", sx: { whiteSpace: 'normal' } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "start", gap: 1, justifyContent: "left" }, { children: [labelMessage, required && _jsx(RequiredPill, {})] })), supportedFilesMessage] }))] })), hasChildren ? (_jsxs(_Fragment, { children: [_jsx(Divider, { flexItem: true, orientation: isMobileView ? 'horizontal' : 'vertical', sx: { marginY: 2 } }), _jsx(Box, __assign({ display: "flex", flexGrow: 1, gap: 2, flexDirection: "column", padding: 2, overflow: "auto", maxHeight: 250 }, { children: children }))] })) : null] }))] }));
}
