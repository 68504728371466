var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
function DonutListItem(_a) {
    var icon = _a.icon, label = _a.label, value = _a.value;
    return (_jsxs(ListItem, __assign({ sx: { padding: 0.5 } }, { children: [_jsx(ListItemIcon, __assign({ sx: { minWidth: 0, marginRight: 1 } }, { children: icon })), _jsx(ListItemText, __assign({ "aria-live": "polite", primaryTypographyProps: { fontWeight: 400 } }, { children: "".concat(label, ": ").concat(value) }))] })));
}
/**
 * Donut List Component
 * Used only for the dashboard panels
 */
export function DonutList(_a) {
    var items = _a.items;
    return _jsx(List, { children: items.map(function (props) { return props.value > 0 && _jsx(DonutListItem, __assign({}, props), props.id); }) });
}
