var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { ALERTING_AND_MONITORING_ROUTE, SNMP_MANAGER_ROUTE, SNMP_SERVICE_ROUTE, SYSLOG_ROUTE } from '@/constants/routes';
import { pathEndsWith } from '@/utils/navigation';
import { NewSyslogModal } from '@/components/settings/services/alerting-and-monitoring/syslog/syslog-modal';
import SNMPManagerTab from '@/components/settings/services/alerting-and-monitoring/snmp-manager/snmp-manager-tab';
import SNMPServiceTab from '@/components/settings/services/alerting-and-monitoring/snmp-service/snmp-service-tab';
import { SyslogTable } from '@/components/settings/services/alerting-and-monitoring/syslog/syslog-table';
export var SNMP_MANAGER_TAB_ID = 'snmp-manager-tab';
export var SNMP_SERVICE_TAB_ID = 'snmp-service-tab';
export var SYSLOG_TAB_ID = 'syslog-tab';
function AlertingAndMonitoring() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var hasPermission = useUIPermissions().hasPermission;
    var permissions = useMemo(function () { return ({
        hasSNMPManagerViewAccess: hasPermission('settings.services.snmp_manager.view'),
        hasSNMPServiceViewAccess: hasPermission('settings.services.snmp_service.view'),
        hasSyslogViewAccess: hasPermission('settings.services.syslog_servers.view'),
    }); }, [hasPermission]);
    useDocTitle(t('pages.alerts_and_monitoring.title'));
    var initialTab = useMemo(function () {
        if (permissions.hasSNMPManagerViewAccess) {
            return SNMP_MANAGER_ROUTE;
        }
        if (permissions.hasSNMPServiceViewAccess) {
            return SNMP_SERVICE_ROUTE;
        }
        return SYSLOG_ROUTE;
    }, [permissions]);
    var tabValue = useMemo(function () {
        if (pathEndsWith(pathname, SYSLOG_ROUTE)) {
            return SYSLOG_ROUTE;
        }
        if (pathEndsWith(pathname, SNMP_SERVICE_ROUTE)) {
            return SNMP_SERVICE_ROUTE;
        }
        return initialTab;
    }, [pathname, initialTab]);
    useEffect(function () {
        if (pathEndsWith(pathname, ALERTING_AND_MONITORING_ROUTE)) {
            navigate(tabValue, { replace: true });
        }
    }, [pathname, navigate, tabValue]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.alerts_and_monitoring.title') })), pathname === SYSLOG_ROUTE && _jsx(NewSyslogModal, {})] })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(Tabs, __assign({ value: tabValue, orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: SNMP_MANAGER_ROUTE, label: t('pages.alerts_and_monitoring.tabs.snmp_manager.title'), value: SNMP_MANAGER_ROUTE, disabled: !permissions.hasSNMPManagerViewAccess, id: SNMP_MANAGER_TAB_ID }), _jsx(Tab, { component: RouterLink, to: SNMP_SERVICE_ROUTE, label: t('pages.alerts_and_monitoring.tabs.snmp_service.title'), value: SNMP_SERVICE_ROUTE, disabled: !permissions.hasSNMPServiceViewAccess, id: SNMP_SERVICE_TAB_ID }), _jsx(Tab, { component: RouterLink, to: SYSLOG_ROUTE, label: t('pages.alerts_and_monitoring.tabs.syslog.title'), value: SYSLOG_ROUTE, disabled: !permissions.hasSyslogViewAccess, id: SYSLOG_TAB_ID })] })), _jsx(TabPanel, __assign({ value: SNMP_MANAGER_ROUTE }, { children: _jsx(SNMPManagerTab, {}) })), _jsx(TabPanel, __assign({ value: SNMP_SERVICE_ROUTE }, { children: _jsx(SNMPServiceTab, {}) })), _jsx(TabPanel, __assign({ value: SYSLOG_ROUTE }, { children: _jsx(SyslogTable, {}) }))] })) })] })));
}
export default withAuthenticationRequired(AlertingAndMonitoring, {
    rights: ['settings.services.snmp_manager.view', 'settings.services.snmp_service.view', 'settings.services.syslog_servers.view'],
});
