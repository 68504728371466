var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { HOSTNAME_OR_ADDRESS_PATTERN } from '@/constants/regex';
export function ResourceNameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: t('pages.smf_resources.modal.form.fields.name.errors.required'),
            maxLength: { value: 128, message: t('pages.smf_resources.modal.form.fields.name.errors.too_long') },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "resource-name", label: t('pages.smf_resources.modal.form.fields.name.label'), required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, margin: "none", disabled: !permissions.hasEditAccess, 
                // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: true, sx: { maxWidth: isMobileView ? undefined : 500, marginBottom: 2 } }, field)));
        } }));
}
export function ResourceNetworkAddressField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "address", rules: {
            required: t('pages.smf_resources.modal.form.fields.network_address.errors.required'),
            maxLength: { value: 253, message: t('pages.smf_resources.modal.form.fields.network_address.errors.too_long') },
            pattern: {
                value: HOSTNAME_OR_ADDRESS_PATTERN,
                message: t('pages.smf_resources.modal.form.fields.network_address.errors.bad_format'),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "resource-address", label: t('pages.smf_resources.modal.form.fields.network_address.label'), required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, margin: "none", disabled: !permissions.hasEditAccess, sx: { maxWidth: isMobileView ? undefined : 500, marginBottom: 2 } }, field)));
        } }));
}
export function ResourceServiceEnabledField(_a) {
    var service = _a.service, label = _a.label;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions;
    return (_jsx(FormControlLabel, { sx: { marginRight: 12, marginBottom: 2 }, control: _jsx(Box, __assign({ sx: { marginRight: 1 } }, { children: _jsx(Controller, { control: control, name: service, render: function (_a) {
                    var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                    return _jsx(Checkbox, __assign({}, field, { checked: value, disabled: !permissions.hasEditAccess }));
                } }) })), label: label }));
}
export function ResourceServicesPortField(_a) {
    var service = _a.service, label = _a.label;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: _jsx(Controller, { control: control, name: service, rules: {
                required: t('pages.smf_resources.modal.form.fields.services.errors.invalid'),
                min: { value: 1, message: t('pages.smf_resources.modal.form.fields.services.errors.error_min') },
                max: { value: 65535, message: t('pages.smf_resources.modal.form.fields.services.errors.error_max') },
            }, render: function (_a) {
                var _b;
                var _c = _a.field, onChange = _c.onChange, newField = __rest(_c, ["onChange"]), fieldState = _a.fieldState;
                return (_jsx(FormControl, { children: _jsx(Box, __assign({ sx: { marginTop: -1, marginBottom: -1, marginLeft: 4, marginRight: service === 'ssh_port' ? 0 : 1 } }, { children: _jsx(TextField, __assign({}, newField, { id: service, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, inputProps: {
                                'aria-label': label,
                            }, type: "number", disabled: !permissions.hasEditAccess, value: newField.value != null ? newField.value : '', onChange: function (e) {
                                onChange(e.target.value ? parseInt(e.target.value, 10) : '');
                            }, sx: { maxWidth: isMobileView ? undefined : 120 } })) })) }));
            } }) })));
}
export function ResourceServiceFieldset(service, label, enabled) {
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(ResourceServiceEnabledField, { service: "".concat(service, "_enabled"), label: label }), enabled && _jsx(ResourceServicesPortField, { service: "".concat(service, "_port"), label: label })] })));
}
