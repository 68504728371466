var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
import { SelectCompatibleNodesTable } from '../select-compatible-nodes';
export function SelectNodesField() {
    var t = useTranslation().t;
    var location = useLocation();
    var _a = useFormContainerContext(), control = _a.control, watch = _a.watch;
    var selectedFirmware = watch('firmware');
    return (_jsx(Controller, { control: control, name: "nodes", rules: { required: true }, render: function (_a) {
            var _b, _c;
            var field = _a.field;
            return selectedFirmware || ((_c = (_b = location.state) === null || _b === void 0 ? void 0 : _b.copyRepeatData) === null || _c === void 0 ? void 0 : _c.nodes.length) ? (_jsx(SelectCompatibleNodesTable, { onFormChange: field.onChange })) : (_jsx(Typography, __assign({ marginBottom: 2 }, { children: t('pages.firmware_upgrade_new.fields.nodes.label') })));
        } }));
}
