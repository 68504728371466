var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { OGDialog } from '@opengear/modals';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useOpen } from '@/hooks/open';
import { useFormContainerContext } from '@/components/layout/forms';
import { SelectFirmwareModalContent, SelectedFirmwareDetails, SelectFirmwareWarningInfo, SelectFirmwareSelectFile } from '../select-firmware';
export function SelectFirmwareField() {
    var t = useTranslation().t;
    var _a = useOpen(), open = _a.open, handleOpen = _a.handleOpen, handleClose = _a.handleClose;
    var control = useFormContainerContext().control;
    var refocusRef = useRef(null);
    var isFirstRender = useRef(true);
    useEffect(function () {
        if (!isFirstRender.current && !open && refocusRef.current) {
            refocusRef.current.focus();
        }
        isFirstRender.current = false;
    }, [open]);
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: [_jsx(Controller, { control: control, name: "firmware", rules: { required: true }, render: function (_a) {
                    var field = _a.field;
                    return (_jsxs(_Fragment, { children: [_jsx(OGDialog, __assign({ open: open, onClose: handleClose }, { children: _jsx(SelectFirmwareModalContent, { onClose: handleClose, onFormChange: field.onChange }) })), _jsxs(FormLabel, { children: [t('pages.firmware_upgrade_new.fields.firmware.label'), _jsx(OGIconTooltip, { ref: refocusRef, title: t('pages.firmware_upgrade_new.fields.firmware.tooltip') })] })] }));
                } }), _jsx(SelectFirmwareSelectFile, { onClick: handleOpen }), _jsx(SelectedFirmwareDetails, {}), _jsx(SelectFirmwareWarningInfo, {})] })));
}
