export var PERMISSIONS_LOADING_TEST_ID = 'permissions-loading';
export var PERMISSIONS_PATH = '/sessions/self/ui';
export var EXPIRED_LICENSE_PERMISSION_OVERRIDES = {
    monitor: {
        jobs: {
            edit: false,
        },
    },
    configure: {
        node_enrollment: {
            enrolled_nodes: {
                enroll: false,
            },
            enrollment_bundles: {
                edit: false,
            },
            enrollment_settings: {
                edit: false,
            },
        },
        edit_tags: {
            edit: false,
        },
        edit_smart_groups: {
            edit: false,
        },
        edit_managed_device_filters: {
            edit: false,
        },
        configuration_templating: {
            apply_templates: {
                view: false,
                push: false,
            },
            script_templates: {
                view: false,
                edit: false,
            },
            authentication_templates: {
                view: false,
                edit: false,
            },
            users_groups_templates: {
                view: false,
                edit: false,
            },
            smf_templates: {
                view: false,
                edit: false,
            },
        },
        multiple_instance: {
            dependent_lighthouse: {
                edit: false,
            },
            lhvpn: {
                edit: false,
            },
        },
        netops: {
            modules: {
                edit: false,
            },
        },
    },
    settings: {
        user_management: {
            permissions: {
                edit: false,
            },
            local_users: {
                edit: false,
            },
            remote_authentication: {
                edit: false,
            },
            login_restrictions: {
                edit: false,
            },
            password_policy: {
                edit: false,
            },
        },
        notifications: {
            events: {
                edit: false,
            },
        },
        services: {
            session_settings: {
                edit: false,
            },
            console_gateway: {
                edit: false,
            },
            lhvpn: {
                edit: false,
            },
            snmp_manager: {
                edit: false,
            },
            snmp_service: {
                edit: false,
            },
            netops: {
                edit: false,
            },
            cellhealth: {
                edit: false,
            },
            node_backup: {
                edit: false,
            },
            syslog_servers: {
                edit: false,
            },
            node_upgrade: {
                edit: false,
            },
            smf: {
                edit: false,
            },
        },
        system: {
            administration: {
                edit: false,
            },
            upgrade: {
                edit: false,
            },
            reset: {
                edit: false,
            },
            backup: {
                edit: false,
            },
            restore: {
                edit: false,
            },
        },
    },
};
export var DEPENDENT_PERMISSION_OVERRIDES = {
    monitor: {
        jobs: {
            edit: false,
        },
    },
    manage: {
        resources: {
            edit: false,
        },
    },
    configure: {
        node_enrollment: {
            enrolled_nodes: {
                enroll: false,
            },
            enrollment_bundles: {
                edit: false,
            },
            enrollment_settings: {
                edit: false,
            },
        },
        edit_tags: {
            edit: false,
        },
        edit_smart_groups: {
            edit: false,
        },
        edit_managed_device_filters: {
            edit: false,
        },
        configuration_templating: {
            apply_templates: {
                push: false,
                view: false,
            },
            script_templates: {
                edit: false,
                view: false,
            },
            authentication_templates: {
                edit: false,
                view: false,
            },
            users_groups_templates: {
                edit: false,
                view: false,
            },
            smf_templates: {
                edit: false,
                view: false,
            },
        },
        multiple_instance: {
            dependent_lighthouse: {
                edit: false,
                view: false,
            },
            lhvpn: {
                edit: false,
                view: false,
            },
        },
        netops: {
            modules: {
                edit: false,
            },
        },
    },
    settings: {
        network_connections: {
            network_interfaces: {
                edit: false,
            },
        },
        user_management: {
            permissions: {
                edit: false,
            },
            local_users: {
                edit: false,
            },
            remote_authentication: {
                edit: false,
            },
            login_restrictions: {
                edit: false,
            },
            password_policy: {
                edit: false,
            },
        },
        notifications: {
            events: {
                edit: false,
            },
        },
        services: {
            session_settings: {
                edit: false,
            },
            https_certificate: {
                edit: false,
            },
            console_gateway: {
                edit: false,
            },
            lhvpn: {
                edit: false,
            },
            snmp_manager: {
                edit: false,
            },
            snmp_service: {
                edit: false,
            },
            netops: {
                edit: false,
            },
            cellhealth: {
                edit: false,
            },
            node_backup: {
                edit: false,
            },
            smf: {
                edit: false,
            },
            ssh: {
                edit: false,
            },
            syslog_servers: {
                edit: false,
            },
            node_upgrade: {
                edit: false,
            },
        },
        date_time: {
            automatic_settings: {
                edit: false,
            },
            time_zone: {
                edit: false,
            },
        },
        system: {
            administration: {
                edit: false,
            },
            licensing: {
                edit: false,
            },
            upgrade: {
                edit: false,
            },
            reset: {
                edit: false,
            },
            backup: {
                edit: false,
            },
            restore: {
                edit: false,
            },
        },
    },
};
// Mock root ui permissions object
export var MOCK_ROOT_PERMISSIONS = {
    is_secondary: false,
    is_expired: false,
    is_lic_expired: false,
    monitor: {
        dashboard: {
            view: true,
        },
        nodes: {
            view: true,
        },
        jobs: {
            view: true,
            edit: true,
        },
    },
    manage: {
        resources: {
            view: true,
            edit: true,
        },
        managed_devices: {
            console_gateway: {
                view: true,
                serial_port_access: true,
                web_terminal: true,
            },
            quick_search: {
                view: true,
            },
        },
        nodes: {
            node_ui: {
                view: true,
            },
        },
        lighthouse: {
            local_terminal: true,
        },
    },
    configure: {
        node_enrollment: {
            enrolled_nodes: {
                view: true,
                view_cell_health: true,
                enroll: true,
            },
            enrollment_bundles: {
                view: true,
                edit: true,
            },
            enrollment_settings: {
                view: true,
                edit: true,
            },
            pending_nodes: {
                view: true,
            },
        },
        edit_tags: {
            view: true,
            edit: true,
        },
        edit_smart_groups: {
            view: true,
            edit: true,
        },
        edit_managed_device_filters: {
            view: true,
            edit: true,
        },
        configuration_templating: {
            apply_templates: {
                view: true,
                push: true,
            },
            script_templates: {
                view: true,
                edit: true,
            },
            authentication_templates: {
                view: true,
                edit: true,
            },
            users_groups_templates: {
                view: true,
                edit: true,
            },
            smf_templates: {
                view: true,
                edit: true,
            },
        },
        multiple_instance: {
            dependent_lighthouse: {
                view: true,
                edit: true,
            },
            lhvpn: {
                view: true,
                edit: true,
            },
        },
        netops: {
            modules: {
                view: true,
                edit: true,
            },
        },
    },
    settings: {
        network_connections: {
            network_interfaces: {
                view: true,
                edit: true,
            },
        },
        user_management: {
            permissions: {
                view: true,
                edit: true,
            },
            local_users: {
                view: true,
                edit: true,
            },
            remote_authentication: {
                view: true,
                edit: true,
            },
            login_restrictions: {
                view: true,
                edit: true,
            },
            password_policy: {
                view: true,
                edit: true,
            },
        },
        notifications: {
            events: {
                view: true,
                edit: true,
            },
        },
        services: {
            session_settings: {
                view: true,
                edit: true,
            },
            https_certificate: {
                view: true,
                edit: true,
            },
            console_gateway: {
                view: true,
                edit: true,
            },
            lhvpn: {
                view: true,
                edit: true,
            },
            snmp_manager: {
                view: true,
                edit: true,
            },
            snmp_service: {
                view: true,
                edit: true,
            },
            netops: {
                view: true,
                edit: true,
            },
            cellhealth: {
                view: true,
                edit: true,
            },
            node_backup: {
                view: true,
                edit: true,
            },
            node_upgrade: {
                view: true,
                edit: true,
            },
            syslog_servers: {
                view: true,
                edit: true,
            },
            smf: {
                view: true,
                edit: true,
            },
            ssh: {
                view: true,
                edit: true,
            },
        },
        date_time: {
            automatic_settings: {
                view: true,
                edit: true,
            },
            time_zone: {
                view: true,
                edit: true,
            },
        },
        system: {
            administration: {
                view: true,
                edit: true,
            },
            licensing: {
                view: true,
                edit: true,
            },
            upgrade: {
                view: true,
                edit: true,
            },
            reset: {
                view: true,
                edit: true,
            },
            backup: {
                view: true,
                edit: true,
            },
            restore: {
                view: true,
                edit: true,
            },
        },
    },
    help: {
        support_report: {
            view: true,
        },
    },
    evaluation_days_remaining: 0,
    maintenance_days_remaining: 0,
    username: 'root',
    session_timeout: 20,
    is_sso_user: false,
};
