var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { OGButton } from '@opengear/buttons';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { ContactSalesLink } from './contact-sales-link';
export function NoSubscriptionContent(_a) {
    var licenseTier = _a.licenseTier, onClickHandler = _a.onClickHandler, licenseBucket = _a.licenseBucket;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var canUpdateLicense = useMemo(function () { return hasPermission('settings.system.licensing.edit'); }, [hasPermission]);
    var innerNode;
    switch (true) {
        case licenseBucket === 'eval':
            innerNode = (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ marginBottom: 1, fontWeight: 600 }, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.evaluation.evaluation_in_use_desc') })), _jsx(Typography, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.evaluation.evaluation_warning') })] }));
            break;
        case licenseTier === 'legacy':
            innerNode = (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ marginBottom: 1, fontWeight: 600 }, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.legacy.legacy_in_use') })), _jsx(Typography, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.legacy.no_license') })] }));
            break;
        default:
            innerNode = _jsx(Typography, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item')[licenseTier].no_license });
    }
    return (_jsxs(Box, __assign({ display: "flex", padding: 2 }, { children: [licenseBucket === 'eval' || licenseTier === 'legacy' ? (_jsx(InfoIcon, { fontSize: "large", sx: { marginRight: 2 } })) : (_jsx(WarningIcon, { color: "warning", fontSize: "large", sx: { marginRight: 2 } })), _jsxs(Box, { children: [innerNode, licenseTier !== 'legacy' && _jsx(ContactSalesLink, {}), _jsx(OGButton, __assign({ variant: "secondary", onClick: onClickHandler, startIcon: _jsx(AddCircleOutline, {}), disabled: !canUpdateLicense, sx: { marginTop: 2 } }, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.buttons.add_subscription') }))] })] })));
}
