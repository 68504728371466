import { OGToastPriorities } from '@/hooks/alert-notification';
export var displayAPIRequestsResults = function (updateResults) {
    if (updateResults.every(function (result) { return result; })) {
        return { message: undefined, priority: OGToastPriorities.Success };
    }
    if (updateResults.some(function (result) { return result; })) {
        var jointSuccessResult = updateResults
            .filter(function (result) { return result; })
            .reduce(function (_, result, i, results) { return _ + (i !== results.length - 1 ? "".concat(result, ", ") : result); }, '');
        return { message: jointSuccessResult, priority: OGToastPriorities.Warning };
    }
    return undefined;
};
