import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* html entities, https://wikimass.com/html/entities */
import { memo } from 'react';
export var Space = memo(function (_a) {
    var _b = _a.repeat, repeat = _b === void 0 ? 1 : _b;
    if (repeat < 1) {
        return null;
    }
    if (repeat === 1) {
        return _jsx(_Fragment, { children: "\u00A0" });
    }
    return (_jsxs(_Fragment, { children: ["\u00A0", _jsx(Space, { repeat: repeat - 1 })] }));
});
