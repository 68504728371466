import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { OG_DANGER_DARK, OG_DANGER_LIGHT, OG_SUCCESS_DARK, OG_SUCCESS_LIGHT, OG_WARNING_DARK, OG_WARNING_LIGHT } from '@opengear/theme/colors';
import { OGReceptionHigh, OGReceptionLow, OGReceptionMedium } from '@opengear/icons/status';
/**
 * Cell health series order
 */
export var cellHealthSeriesOrder = {
    sim_issues: 0,
    connectivity_test_failed: 1,
    bad: 2,
    moderate: 3,
    good: 4,
};
/**
 * Initial cell health donut data
 */
var initialCellHealthDonutData = {
    seriesData: [],
    seriesTotal: 0,
    summaryTotal: 0,
};
/**
 * Cell health donut colors
 */
var cellHealthDonutColors = {
    light: {
        sim_issues: OG_DANGER_LIGHT,
        connectivity_test_failed: OG_DANGER_LIGHT,
        bad: OG_DANGER_LIGHT,
        moderate: OG_WARNING_LIGHT,
        good: OG_SUCCESS_LIGHT,
    },
    dark: {
        sim_issues: OG_DANGER_DARK,
        connectivity_test_failed: OG_DANGER_DARK,
        bad: OG_DANGER_DARK,
        moderate: OG_WARNING_DARK,
        good: OG_SUCCESS_DARK,
    },
};
/**
 * Build cell health donut data
 */
export function useBuildCellHealthDonutData(cellularHealthSummary) {
    var t = useTranslation().t;
    var themeMode = useTheme().palette.mode;
    return useMemo(function () {
        if (!cellularHealthSummary)
            return initialCellHealthDonutData;
        /* eslint-disable no-param-reassign */
        var cellHealthDonutData = cellularHealthSummary.reduce(function (donutData, _a) {
            var status = _a.status, count = _a.count;
            // Do nothing if count is less than 1
            if (count < 1)
                return donutData;
            // Always add to summary total count. May include 'pending' status which is not shown on pie chart.
            donutData.summaryTotal += count;
            // Update series data for each summary
            if (status === 'sim_issues' || status === 'connectivity_test_failed' || status === 'bad' || status === 'moderate' || status === 'good') {
                donutData.seriesTotal += count;
                donutData.seriesData.push({
                    id: status,
                    color: cellHealthDonutColors[themeMode][status],
                    label: t('pages.dashboard.nodes.cellular_health')[status],
                    value: count,
                });
            }
            return donutData;
        }, {
            seriesData: [],
            seriesTotal: 0,
            summaryTotal: 0,
        });
        /* eslint-enable no-param-reassign */
        // Sort series data by order
        cellHealthDonutData.seriesData.sort(function (dataA, dataB) { return cellHealthSeriesOrder[dataA.id] - cellHealthSeriesOrder[dataB.id]; });
        return cellHealthDonutData;
    }, [cellularHealthSummary, t, themeMode]);
}
/**
 * Cell health list icons
 */
export var cellHealthListIcons = {
    sim_issues: _jsx(OGReceptionHigh, { fontSize: "medium", color: "error" }),
    connectivity_test_failed: _jsx(OGReceptionHigh, { fontSize: "medium", color: "error" }),
    bad: _jsx(OGReceptionLow, { fontSize: "medium", color: "error" }),
    moderate: _jsx(OGReceptionMedium, { fontSize: "medium", color: "warning" }),
    good: _jsx(OGReceptionHigh, { fontSize: "medium", color: "success" }),
};
