var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGCellularIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 24 24' }, props), { children: [_jsx("path", { fill: "none", d: "M14.6682 6.49292C15.4262 7.25122 15.4262 8.48043 14.6682 9.23873", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M17.4139 3.74573C18.5065 4.83825 19.1203 6.32003 19.1203 7.86509C19.1203 9.41015 18.5065 10.8919 17.4139 11.9845", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M20.1559 1C21.977 2.82088 23 5.29061 23 7.86582C23 10.441 21.977 12.9108 20.1559 14.7316", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M9.33192 6.49292C8.57385 7.25122 8.57385 8.48043 9.33192 9.23873", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M6.58613 3.74573C5.4936 4.83825 4.87982 6.32003 4.87982 7.86509C4.87982 9.41015 5.4936 10.8919 6.58613 11.9845", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M3.84407 1C2.02305 2.82088 1 5.29061 1 7.86582C1 10.441 2.02305 12.9108 3.84407 14.7316", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M12 23.6512V12.6512", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
