import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useDocTitle } from '@/hooks/doc-title';
import { SNMPServiceForm } from './snmp-service-form';
import { transformToFormDataObject } from './transformers';
import { useSNMP } from '@/api/services/snmp';
export var SNMP_SERVICE_LOADER_ID = 'snmp-service-form-loading';
export default function SNMPServiceTab() {
    var t = useTranslation().t;
    useDocTitle(t('pages.alerts_and_monitoring.tabs.snmp_service.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var _a = useSNMP(), data = _a.data, isLoading = _a.isLoading;
    var hasEditPermission = hasPermission('settings.services.snmp_service.edit');
    if (isLoading) {
        return _jsx(LoadingBackdrop, { "data-testid": SNMP_SERVICE_LOADER_ID });
    }
    if (!data) {
        return null;
    }
    var sourceValue = transformToFormDataObject(data.snmp);
    return _jsx(SNMPServiceForm, { data: sourceValue, permissions: { hasEditPermission: hasEditPermission } });
}
