var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { OG_OFF_WHITE, OG_BLACK_90 } from '@opengear/theme/colors';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useJob } from '@/api/jobs';
import { useDependentLighthouses } from '@/api/multiple-instance/dependent-lighthouses';
import DynamicDurationValue from '@/components/metric/duration';
import { formatDateString } from '@/utils/datetime';
import { CopyButton } from '@/components/copy-button';
import { SECONDARY_LIGHTHOUSES_ROUTE } from '@/constants/routes';
/**
 * Renders a link to the nodes page if the user has permission, else displays an informational
 * message
 */
export function AssociatedNodeSection(_a) {
    var job = _a.job, sxProps = _a.sxProps;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var canViewNode = hasPermission('configure.node_enrollment.enrolled_nodes.view');
    return (_jsxs(Typography, __assign({ sx: __assign({}, sxProps) }, { children: [t('pages.jobs.modal.labels.associated_node'), canViewNode ? (_jsx(MuiLink, __assign({ component: RouterLink, to: "/nodes/".concat(job.node_id), sx: { display: 'inline', color: 'secondary.main', fontStyle: 'italic' } }, { children: job.name }))) : (_jsx(Tooltip, __assign({ arrow: true, placement: "right-end", title: t('pages.jobs.modal.tooltip.node_access_unauthorized') }, { children: _jsx("span", __assign({ tabIndex: 0 }, { children: job.name })) })))] })));
}
/**
 * Renders a link to the dependent lighthouses if successfully retrieved else nothing is displayed
 */
function DependentNameLink(_a) {
    var associatedLighthouseIds = _a.associatedLighthouseIds;
    // Find the associated name of the secondary
    var _b = useDependentLighthouses(), data = _b.data, isLoading = _b.isLoading;
    if (isLoading || !data)
        return null;
    // Convert the Lighthouse 'ID' to 'UUID' since the dependent_lighthouses "id" field is really a "UUID" and it's
    // our only way to join these up
    var associatedLighthouseUUIDs = associatedLighthouseIds.map(function (id) { return "lighthouse_configurations-".concat(id); });
    var associatedDependentLighthouses = data.dependent_lighthouses.filter(function (x) { return associatedLighthouseUUIDs.includes(x.id); });
    if (associatedDependentLighthouses.length !== 1) {
        console.warn("Expected 1 associated dependent lighthouse but got ".concat(associatedDependentLighthouses.length));
        return null;
    }
    return (_jsx(MuiLink, __assign({ component: RouterLink, to: SECONDARY_LIGHTHOUSES_ROUTE, sx: { display: 'inline', color: 'secondary.main', fontStyle: 'italic' } }, { children: associatedDependentLighthouses[0].name })));
}
/**
 * Finds and renders a link to the dependent lighthouses page if the user has permission to view
 * it. In the event of an error, it renders null with an error logged to console.
 */
function AssociatedLighthouseSection(_a) {
    var associatedLighthouseIds = _a.associatedLighthouseIds, sxProps = _a.sxProps;
    var t = useTranslation().t;
    var _b = useUIPermissions(), permissions = _b.permissions, hasPermission = _b.hasPermission;
    var canViewDependentLhs = hasPermission('configure.multiple_instance.dependent_lighthouse.view', { forcePrimary: true });
    var isSecondary = !!(permissions === null || permissions === void 0 ? void 0 : permissions.is_secondary);
    if (canViewDependentLhs) {
        if (isSecondary) {
            // The secondary shares the same permissions as the primary but the page to navigate to doesn't
            // exist on a secondary so show a message telling the user to view from the primary.
            return (_jsx(Typography, __assign({ fontStyle: "italic", sx: __assign({}, sxProps) }, { children: t('pages.jobs.modal.tooltip.dependent_access_authorized_from_secondary') })));
        }
        return (_jsxs(Typography, __assign({ fontStyle: "italic", sx: __assign({}, sxProps) }, { children: [t('pages.jobs.modal.labels.associated_instance'), " \u00A0", _jsx(DependentNameLink, { associatedLighthouseIds: associatedLighthouseIds })] })));
    }
    return (_jsx(Typography, __assign({ fontStyle: "italic", sx: __assign({}, sxProps) }, { children: t('pages.jobs.modal.tooltip.dependent_access_unauthorized') })));
}
function LogSection(_a) {
    var logs = _a.logs;
    var theme = useTheme();
    var darkMode = theme.palette.mode === 'dark';
    return (_jsx(Box, __assign({ role: "code", padding: 2, sx: { backgroundColor: darkMode ? OG_BLACK_90 : OG_OFF_WHITE } }, { children: _jsx(Box, __assign({ sx: { overflowY: 'auto', maxHeight: '40vh' } }, { children: _jsx(Typography, __assign({ variant: "code", component: "p", sx: { wordWrap: 'break-word', whiteSpace: 'pre-line' } }, { children: logs })) })) })));
}
export var ID = 'jobs-modal';
function JobModalContent(_a) {
    var handleClose = _a.handleClose, jobId = _a.jobId;
    var t = useTranslation().t;
    var _b = useJob(jobId), data = _b.data, isLoading = _b.isLoading;
    useEffect(function () {
        if (!isLoading && data === undefined) {
            // We've finished loading which resulted in a 404. The handler has raised a notification in
            // the background so all we need to do here is close this modal
            handleClose();
        }
    }, [handleClose, data, isLoading]);
    // When loading return an empty div for testing purposes
    if (isLoading)
        return _jsx("div", { "data-testid": ID });
    if (data === undefined) {
        // 404 case continued. The modal is closing so whatever we return here
        // doesn't matter
        return null;
    }
    // Data is now available to us
    var jobData = data.job;
    // Determine icon to render (if any)
    var statusIcon;
    switch (jobData.last_status) {
        case 'success':
            statusIcon = _jsx(SuccessIcon, { fontSize: "medium", color: "success" });
            break;
        case 'temporary_failure':
            statusIcon = null;
            break;
        case 'permanent_failure':
            statusIcon = _jsx(ErrorIcon, { fontSize: "medium", color: "error" });
            break;
    }
    // Calculate duration in seconds
    var duration = jobData.last_status === 'temporary_failure'
        ? (new Date().getTime() - new Date(jobData.creation_time).getTime() + new Date().getTimezoneOffset() * 60 * 1000) / 1000
        : jobData.duration;
    // There's only ever 0 or 1 node in this array as per endpoint implementation - only show if we have one
    var associatedNode = jobData.nodes.length > 0 ? jobData.nodes[0] : null;
    // There's always 1 (the primary) and sometimes a second dependent associated - only show if we know we have a dependent
    var associatedLighthouseIds = jobData.lighthouse_configurations.length > 1 ? jobData.lighthouse_configurations.map(function (x) { return x.lighthouse_configuration_id; }) : null;
    // Prepare the logs for rendering
    var logs = jobData.records.flatMap(function (record) {
        return record.errors.map(function (error) { return "".concat(record.creation_time, " - Attempt #").concat(record.retry_count, " [").concat(record.status, "]\n").concat(error.message); });
    });
    // No logs associated with the job - display a fallback message instead
    if (logs.length === 0) {
        logs = [t('pages.jobs.modal.tooltip.no_logs')];
    }
    var formattedLogs = logs.join('\n\n');
    return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ id: "jobs-modal-title", display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }, { children: [statusIcon, t("pages.jobs.table.type.".concat(jobData.tube_name))] })), _jsxs(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' } }, { children: [_jsxs(Box, __assign({ display: "flex" }, { children: [_jsxs(Box, __assign({ flexGrow: 1 }, { children: [_jsx(Box, { children: _jsxs(Typography, __assign({ lineHeight: 1.5, marginBottom: 1 }, { children: [_jsxs(Typography, __assign({ component: "span", variant: "body1" }, { children: [t('pages.jobs.modal.labels.id'), " ", jobData.id] })), _jsx("br", {}), _jsxs(Typography, __assign({ component: "span", variant: "body1", marginRight: 2 }, { children: [t('pages.jobs.modal.labels.started'), " ", formatDateString(jobData.creation_time)] })), _jsxs(Typography, __assign({ component: "span", variant: "body1" }, { children: [t('pages.jobs.modal.labels.duration'), " ", _jsx(DynamicDurationValue, { dynamic: jobData.last_status === 'temporary_failure', initialValue: duration })] }))] })) }), associatedNode && _jsx(AssociatedNodeSection, { job: associatedNode, sxProps: { marginBottom: 1 } }), associatedLighthouseIds && _jsx(AssociatedLighthouseSection, { associatedLighthouseIds: associatedLighthouseIds, sxProps: { marginBottom: 1 } })] })), _jsx(Box, __assign({ alignSelf: "end", marginBottom: 1 }, { children: _jsx(CopyButton, { content: formattedLogs, sxProps: { marginLeft: 1 }, autoFocus: true }) }))] })), _jsx(LogSection, { logs: formattedLogs })] })), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: handleClose }, { children: t('global.buttons.done') })) })] }));
}
export function JobModal(_a) {
    var open = _a.open, handleClose = _a.handleClose, jobId = _a.jobId;
    return (_jsx(OGDialog, __assign({ "aria-labelledby": "jobs-modal-title", open: open, onClose: handleClose, maxWidth: "tablet" }, { children: jobId && _jsx(JobModalContent, { handleClose: handleClose, jobId: jobId }) })));
}
