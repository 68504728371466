var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useAuthConfig } from '@/api/auth/auth-config';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { RemoteAuthForm } from '@/components/accounts/remote-auth';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { transformResponseToFormState } from '@/components/accounts/remote-auth/transformers';
var REMOTE_AUTH_LOADER_ID = 'remote-auth-form-loading';
function RemoteAuth() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasAuthEditAccess = hasPermission('settings.user_management.remote_authentication.edit');
    var _a = useAuthConfig(), data = _a.data, isLoading = _a.isLoading;
    var authData = transformResponseToFormState(data);
    useDocTitle(t('pages.remote_auth.title'));
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": REMOTE_AUTH_LOADER_ID });
    }
    if (!isLoading && authData) {
        innerNode = _jsx(RemoteAuthForm, { data: authData, permissions: { hasAuthEditAccess: hasAuthEditAccess } });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: _jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2" }, { children: t('pages.remote_auth.title') })) })), innerNode] })));
}
export default withAuthenticationRequired(RemoteAuth, { rights: ['settings.user_management.remote_authentication.view'] });
