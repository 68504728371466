var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { OGButton } from '@opengear/buttons';
import { useNodeSmartgroups } from '@/api/nodes/smartgroups';
import { createNewFilter } from '@/utils/filter';
import { SelectFilterField } from '../filter-components';
import { NodeFilterQueryContent } from './node-filter-query-content';
import { NodeFilterActions } from './node-filter-action-button';
import { canApplySelectedFilter } from './node-filter-utils';
import { useNodeFilterContext } from './node-filter-context';
/**
 * Node filter popover content
 * Assumes that the user has view permissions for smartgroups
 */
export function NodeFilterPopoverContent(_a) {
    var handlePopoverClose = _a.handlePopoverClose;
    var t = useTranslation().t;
    var _b = useNodeFilterContext(), filter = _b.filter, selectedFilter = _b.selectedFilter, setFilter = _b.setFilter, dispatchSelectedFilter = _b.dispatchSelectedFilter, permissions = _b.permissions;
    var _c = useNodeSmartgroups(), data = _c.data, isLoading = _c.isLoading;
    var options = useMemo(function () {
        var _a;
        var initialOptions = (_a = data === null || data === void 0 ? void 0 : data.smartgroups) !== null && _a !== void 0 ? _a : [];
        initialOptions.push(createNewFilter(t('components.nodes.filter.select.new')));
        return initialOptions;
    }, [data === null || data === void 0 ? void 0 : data.smartgroups, t]);
    var applyDisabled = useMemo(function () { return !canApplySelectedFilter(selectedFilter, filter); }, [selectedFilter, filter]);
    var handleFilterChange = useCallback(function (_, value) {
        dispatchSelectedFilter({ type: 'set', payload: value });
    }, [dispatchSelectedFilter]);
    var handleApply = useCallback(function () {
        // Only apply filter if conditions are met
        if (canApplySelectedFilter(selectedFilter, filter)) {
            setFilter(selectedFilter);
        }
        handlePopoverClose();
    }, [setFilter, filter, handlePopoverClose, selectedFilter]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ alignItems: "flex-end", display: "flex", flexDirection: "row", gap: 1, marginBottom: 2, width: "100%" }, { children: [_jsx(SelectFilterField, { disabled: isLoading, options: options, onChange: handleFilterChange, textFieldProps: {
                            autoFocus: true,
                            label: t('components.nodes.filter.select.label'),
                            placeholder: t('components.nodes.filter.button.label'),
                        }, value: selectedFilter }), permissions.hasSmartgroupsEditAccess && _jsx(NodeFilterActions, { handlePopoverClose: handlePopoverClose })] })), _jsx(NodeFilterQueryContent, {}), _jsx(Box, { flexGrow: 1, overflow: "auto" }), _jsx(Divider, { sx: { marginX: -2 } }), _jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end", marginTop: 2 }, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: handlePopoverClose }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ disabled: applyDisabled, onClick: handleApply }, { children: t('global.buttons.apply') }))] }))] }));
}
