var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { OGButton } from '@opengear/buttons';
import { OG_GREY_M, OG_GREY_VL } from '@opengear/theme/colors';
export default function SmfResourceServiceButton(_a) {
    var resourceName = _a.resourceName, serviceName = _a.serviceName, toolTipTitle = _a.toolTipTitle, disabled = _a.disabled, onClick = _a.onClick, url = _a.url, props = __rest(_a, ["resourceName", "serviceName", "toolTipTitle", "disabled", "onClick", "url"]);
    var theme = useTheme();
    var tooltipSx = {
        color: 'inherit',
        fontSize: '1.5rem',
        whiteSpace: 'pre-line',
    };
    var buttonSx = __assign({ 'color': theme.palette.text.primary, 'backgroundColor': theme.palette.mode === 'dark' ? OG_GREY_M : OG_GREY_VL, 'fontSize': '1.3rem', 'fontWeight': 600, 'width': 42, 'minHeight': 20, 'paddingY': 0, 'paddingX': 0.5, 'borderRadius': 1.2, ':hover': {
            color: 'inherit',
            backgroundColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_GREY_VL,
        } }, (disabled && {
        opacity: 0.5,
        cursor: 'not-allowed',
    }));
    var handleClick = useCallback(function () {
        if (!disabled && url) {
            if (onClick) {
                onClick(resourceName, url);
            }
            else {
                window.open(url, '_blank', 'noreferrer');
            }
        }
    }, [disabled, onClick, resourceName, url]);
    return (_jsx(Tooltip, __assign({ title: _jsx(Typography, __assign({ sx: __assign({}, tooltipSx), component: "span" }, { children: toolTipTitle })), placement: "top", arrow: true }, { children: _jsx(OGButton, __assign({ sx: __assign({}, buttonSx), "aria-disabled": disabled, onClick: handleClick, variant: "borderless", "data-url": url, propagateSpacebar: false }, props, { children: serviceName.toUpperCase() }), serviceName) })));
}
