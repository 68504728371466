var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useUIPermissions, PERMISSIONS_LOADING_TEST_ID } from '@/api/sessions/self/ui';
/**
 * withAuthorizationRequired hoc is intended to wrap permission sensitive content
 * If no rights or condition are provided, component is rendered.
 * Loading state returns an empty div for testing purposes.
 * The fallback component or null is rendered in place of an error, or not authorized
 * @param Component - The React component to render
 * @param [rights] - Optional Array of dot strings required to be true in the ui permissions object
 * @param [condition] - Optional function that evaluate a condition on the ui permissions object
 * @param [FallbackComponent] - Optional fallback React component to render instead of null
 */
export function withAuthorizationRequired(Component, _a) {
    var rights = _a.rights, condition = _a.condition, FallbackComponent = _a.FallbackComponent;
    return function AuthorizedComponent(props) {
        var _a = useUIPermissions(), permissions = _a.permissions, hasSomePermission = _a.hasSomePermission, isLoadingPermissions = _a.isLoadingPermissions;
        // Since rights and condition props are optional they are by default true
        var hasAccess = useMemo(function () { return permissions && (rights ? hasSomePermission(rights) : true) && (condition ? condition(permissions) : true); }, [hasSomePermission, permissions]);
        // When loading return an empty div for testing purposes
        // This does not return for cached data
        if (isLoadingPermissions)
            return _jsx("div", { "data-testid": PERMISSIONS_LOADING_TEST_ID });
        if (!hasAccess) {
            return FallbackComponent ? _jsx(FallbackComponent, {}) : null;
        }
        return _jsx(Component, __assign({}, props));
    };
}
