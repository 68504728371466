var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { OGPanel } from '@opengear/panel';
import { Controller } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import { OGFileTracker, OGFileUploader } from '@opengear/file-uploader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { OGButton } from '@opengear/buttons';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Tooltip from '@mui/material/Tooltip';
import SyncAlt from '@mui/icons-material/SyncAlt';
import { useFormContainerContext } from '@/components/layout/forms';
import { NetopsContext } from '@/pages/settings/services/netops/netops-settings';
import { useDeviceView } from '@/hooks/use-device-view';
import { useStartNetopsSync } from '@/api/services/netops/netops-sync';
var NETOPS_SYNC_MESSAGE_ID = 'netops-sync-message';
var NETOPS_INSTALL_MESSAGE_ID = 'netops-install-message';
function NetopsBanner(_a) {
    var status = _a.status, message = _a.message, id = _a.id;
    var messageIcon = null;
    if (status === 'error') {
        messageIcon = _jsx(ErrorIcon, { color: "error" });
    }
    if (status === 'pending' || status === 'running') {
        messageIcon = _jsx(CircularProgress, { "aria-labelledby": id, color: "inherit", size: 24 });
    }
    if (status === 'success') {
        messageIcon = _jsx(ThumbUpIcon, { color: "success" });
    }
    return (_jsx(OGPanel, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }, { children: [messageIcon, _jsx(Typography, __assign({ id: id }, { children: message }))] })) }));
}
export function NetopsSyncStatus(_a) {
    var syncStatus = _a.syncStatus;
    var t = useTranslation().t;
    if (syncStatus.status === '')
        return null;
    var message = '';
    if (syncStatus.status === 'error' && syncStatus.error_message) {
        var errMessage = syncStatus.error_message === 'sync_error' || syncStatus.error_message === 'secondary_update_error'
            ? t("pages.netops_settings.error_messages.".concat(syncStatus.error_message))
            : syncStatus.error_message;
        message = "".concat(t('pages.netops_settings.sync_status.error_message'), " - ").concat(errMessage);
    }
    if (syncStatus.status === 'pending' || syncStatus.status === 'running') {
        message = t('pages.netops_settings.sync_status.running_message');
    }
    if (syncStatus.status === 'success') {
        message = t('pages.netops_settings.sync_status.successful');
    }
    return _jsx(NetopsBanner, { status: syncStatus.status, message: message, id: NETOPS_SYNC_MESSAGE_ID });
}
export function NetopsInstallerStatus(_a) {
    var installStatus = _a.installStatus;
    var t = useTranslation().t;
    if (installStatus.status === '')
        return null;
    var message = '';
    if (installStatus.status === 'error' && installStatus.error_message) {
        message = "".concat(t('pages.netops_settings.install_status.error_message'), " - ").concat(installStatus.error_message);
    }
    if (installStatus.status === 'pending' || installStatus.status === 'running') {
        message = t('pages.netops_settings.install_status.running_message');
    }
    if (installStatus.status === 'success') {
        message = t('pages.netops_settings.install_status.successful');
    }
    return _jsx(NetopsBanner, { status: installStatus.status, message: message, id: NETOPS_INSTALL_MESSAGE_ID });
}
export function NetopsFileTracker(_a) {
    var uploadData = _a.uploadData, fileInfo = _a.fileInfo, cancelUpload = _a.cancelUpload, clearFile = _a.clearFile;
    var t = useTranslation().t;
    var cancelAndClear = function () {
        cancelUpload();
        clearFile();
    };
    var handleCancel = uploadData && uploadData.status === 'pending' ? cancelUpload : cancelAndClear;
    var message = useMemo(function () {
        if (uploadData && uploadData.status === 'pending') {
            return _jsx(Typography, { children: t('components.file_manager.progress.pending') });
        }
        if (uploadData && uploadData.status === 'success') {
            return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 0.75 }, { children: [_jsx(CheckOutlinedIcon, { color: "success", fontSize: "medium" }), _jsx(Typography, { children: t('components.file_manager.progress.success') })] })));
        }
        if (uploadData && uploadData.status === 'error' && uploadData.errorMessage) {
            return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 0.75 }, { children: [_jsx(ErrorOutlineIcon, { color: "warning", fontSize: "medium" }), _jsx(Typography, { children: uploadData.errorMessage })] })));
        }
        return null;
    }, [uploadData, t]);
    var progress = uploadData && uploadData.status !== 'error' ? (uploadData.loaded / uploadData.total) * 100 : undefined;
    return _jsx(OGFileTracker, { id: fileInfo.name, name: fileInfo.name, message: message, progress: progress, onCancel: handleCancel });
}
export function NetopsOfflineFileManager(_a) {
    var data = _a.data, cancelUpload = _a.cancelUpload;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions;
    var netopsBusy = useContext(NetopsContext);
    return (_jsx(Controller, { control: control, name: "file", rules: { required: true }, render: function (_a) {
            var field = _a.field;
            return (_jsxs(Box, __assign({ marginBottom: 1.5 }, { children: [_jsx(InputLabel, __assign({ sx: { marginBottom: 0.75 } }, { children: t('pages.netops_settings.tabs.offline.uploader.label') })), _jsx(Box, { children: _jsx(OGFileUploader, __assign({ id: "netops-installer-upload", accept: ".tar.gz", ariaLabel: t('pages.netops_settings.tabs.offline.uploader.label'), onChange: function (files) {
                                var _a;
                                field.onChange((_a = files[0]) !== null && _a !== void 0 ? _a : null);
                            }, disabled: !permissions.hasEditPermission || !!field.value || netopsBusy, required: true }, { children: field.value && _jsx(NetopsFileTracker, { uploadData: data, fileInfo: field.value, cancelUpload: cancelUpload, clearFile: function () { return field.onChange(null); } }) })) })] })));
        } }));
}
export function NetopsUploadButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext().formState, isValid = _a.isValid, isSubmitting = _a.isSubmitting;
    var netopsBusy = useContext(NetopsContext);
    var submitDisabled = !isValid || isSubmitting || netopsBusy;
    return (_jsx(OGButton, __assign({ type: "submit", disabled: submitDisabled, loading: isSubmitting }, { children: t('pages.netops_settings.tabs.offline.upload') })));
}
export function RepositoryAddressField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "repository", rules: {
            required: t('pages.netops_settings.tabs.online.repository.errors.required'),
            maxLength: { value: 128, message: t('pages.netops_settings.tabs.online.repository.errors.too_long') },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, required: true, label: _jsxs(_Fragment, { children: [t('pages.netops_settings.tabs.online.repository.label'), _jsx(OGIconTooltip, { title: t('pages.netops_settings.tabs.online.repository.tooltip') })] }), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }, field)));
        } }));
}
export function SyncButton() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useFormContainerContext(), _b = _a.formState, isDirty = _b.isDirty, isSubmitting = _b.isSubmitting, permissions = _a.permissions;
    var netopsBusy = useContext(NetopsContext);
    var _c = __read(useState(false), 2), busy = _c[0], setBusy = _c[1];
    var syncDisabled = isDirty || isSubmitting || busy || netopsBusy || !permissions.hasEditPermission;
    var syncNetops = useStartNetopsSync();
    var syncHandler = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setBusy(true);
                    return [4 /*yield*/, syncNetops()];
                case 1:
                    _a.sent();
                    setBusy(false);
                    return [2 /*return*/];
            }
        });
    }); }, [syncNetops]);
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.netops_settings.tabs.online.sync.tooltip') }, { children: _jsx("span", { children: _jsx(OGButton, __assign({ type: "button", variant: "primary", disabled: syncDisabled, loading: isSubmitting, startIcon: _jsx(SyncAlt, {}), onClick: syncHandler }, { children: t('pages.netops_settings.tabs.online.sync.label') })) }) })));
}
