var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { OGPanel } from '@opengear/panel';
var SYSTEM_UPGRADE_MESSAGE_ID = 'system-upgrade-message';
export function SystemUpgradeMessage(_a) {
    var errorMessage = _a.errorMessage, isRefetching = _a.isRefetching, state = _a.state;
    var t = useTranslation().t;
    if (state === '')
        return null;
    var message = '';
    if (state === 'error' && errorMessage)
        message = t('components.system_upgrade_status.message')[errorMessage];
    if (state === 'finished' || state === 'pending' || state === 'running')
        message = t('components.system_upgrade_status.message')[state];
    if (!message)
        return null;
    var messageIcon = null;
    if (isRefetching) {
        messageIcon = _jsx(CircularProgress, { "aria-labelledby": SYSTEM_UPGRADE_MESSAGE_ID, color: "inherit", size: 24 });
    }
    else {
        messageIcon = state === 'error' ? _jsx(ErrorIcon, { color: "error" }) : _jsx(ThumbUpIcon, { color: "success" });
    }
    return (_jsx(OGPanel, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }, { children: [messageIcon, _jsx(Typography, __assign({ id: SYSTEM_UPGRADE_MESSAGE_ID }, { children: message }))] })) }));
}
