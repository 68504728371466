// Page routes
export var DASHBOARD_ROUTE = '/dashboard';
export var RESET_PASSWORD_ROUTE = '/reset-password';
export var ENROLLMENT_SETTINGS_ROUTE = '/config/enrollment-settings';
export var FIRMWARE_UPGRADE_ROUTE = '/config/firmware-upgrade';
export var FIRMWARE_UPGRADE_TASKS_ROUTE_KEY = 'tasks';
export var FIRMWARE_UPGRADE_TASKS_ROUTE = "".concat(FIRMWARE_UPGRADE_ROUTE, "/").concat(FIRMWARE_UPGRADE_TASKS_ROUTE_KEY);
export var FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY = 'file-manager';
export var FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE = "".concat(FIRMWARE_UPGRADE_ROUTE, "/").concat(FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY);
export var NEW_FIRMWARE_UPGRADE_ROUTE = "".concat(FIRMWARE_UPGRADE_ROUTE, "/new");
// Configuration Templates
export var CONFIG_TEMPLATES_ROUTE = '/config/configure-templates';
export var AUTH_TEMPLATES_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/auth-templates");
export var NEW_AUTH_TEMPLATE_ROUTE = "".concat(AUTH_TEMPLATES_ROUTE, "/new");
export function formatAuthTempEditRoute(templateId) {
    if (templateId === void 0) { templateId = ':templateId'; }
    return "/config/configure-templates/auth-templates/".concat(templateId);
}
export var USER_GROUP_TEMPLATES_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/user-group-templates");
export var CREATE_USER_GROUP_TEMPLATE_ROUTE = "".concat(USER_GROUP_TEMPLATES_ROUTE, "/new");
export function formatUserGroupTemplateEditRoute(templateId) {
    if (templateId === void 0) { templateId = ':templateId'; }
    return "".concat(USER_GROUP_TEMPLATES_ROUTE, "/").concat(templateId);
}
export var SCRIPT_TEMPLATES_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/script-templates");
export var CREATE_SCRIPT_TEMPLATE_ROUTE = "".concat(SCRIPT_TEMPLATES_ROUTE, "/new");
export function formatScriptTemplateEditRoute(templateId) {
    if (templateId === void 0) { templateId = ':templateId'; }
    return "".concat(SCRIPT_TEMPLATES_ROUTE, "/").concat(templateId);
}
export var PUSH_TEMPLATE_NODE_SELECT_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/push/select-nodes");
export var PUSH_TEMPLATE_PREFLIGHT_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/push/preflight");
export var PUSH_TEMPLATE_STATUS_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/push/status");
export var NETOPS_TEMPLATES_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/netops-modules-templates");
export var SMF_TEMPLATES_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/smf-templates");
export var CREATE_SMF_TEMPLATE_ROUTE = "".concat(SMF_TEMPLATES_ROUTE, "/new");
export function formatSmfTemplateEditRoute(templateId) {
    if (templateId === void 0) { templateId = ':templateId'; }
    return "".concat(SMF_TEMPLATES_ROUTE, "/").concat(templateId);
}
export var PORT_LOGGING_TEMPLATES_ROUTE = "".concat(CONFIG_TEMPLATES_ROUTE, "/port-logging-templates");
// Accounts
export var GROUPS_ROLES_ROUTE = '/accounts/groups-roles';
export var GROUPS_ROUTE = '/groups';
export var GROUPS_NEW_ROUTE = '/accounts/groups/new';
export var LOCAL_USERS_ROUTE = '/accounts/local-users';
export var LOCAL_AUTH_POLICY_ROUTE = '/accounts/local-auth-policy';
export var PASSWORD_POLICY_ROUTE = '/password';
export var LOGIN_RESTRICTION_ROUTE = '/login-restriction';
export var JOBS_ROUTE = '/jobs';
export var JOBS_ROUTE_CURRENT = '/current';
export var JOBS_ROUTE_ENDED = '/ended';
export var LOGIN_ROUTE = '/login';
export var LOCAL_TERMINAL_ROUTE = '/local-terminal';
export var MANAGE_NETOPS_MODULES_ROUTE = '/netops/manage-netops-modules';
export var MILHVPN_ROUTE = '/settings/multi-instance/multi-instance-vpn';
export var SECONDARY_LIGHTHOUSES_ROUTE = '/settings/multi-instance/secondary-lighthouses';
export var ADD_SECONDARY_ROUTE = "".concat(SECONDARY_LIGHTHOUSES_ROUTE, "/new");
// Settings -> Services
export var LIGHTHOUSE_VPN_ROUTE = '/settings/services/lighthouse-vpn';
export var CELL_HEALTH_REPORTING_ROUTE = '/settings/services/cell-health-reporting';
export var CONSOLE_GATEWAY_ROUTE = '/settings/services/console-gateway';
export var NODE_BACKUP_ROUTE = '/settings/services/node-backup';
export var ALERTING_AND_MONITORING_ROUTE = '/settings/services/alerting-and-monitoring';
export var SNMP_MANAGER_ROUTE = "".concat(ALERTING_AND_MONITORING_ROUTE, "/snmp-manager");
export var SNMP_SERVICE_ROUTE = "".concat(ALERTING_AND_MONITORING_ROUTE, "/snmp-service");
export var SYSLOG_ROUTE = "".concat(ALERTING_AND_MONITORING_ROUTE, "/syslog");
export var SMF_SETTINGS_ROUTE = '/settings/services/smf';
export var HTTPS_SETTINGS_ROUTE = '/settings/services/https';
export var SERVICES_NETOPS_ROUTE = '/settings/services/netops';
export var SERVICES_NETOPS_ONLINE_ROUTE = "".concat(SERVICES_NETOPS_ROUTE, "/online");
export var SERVICES_NETOPS_OFFLINE_ROUTE = "".concat(SERVICES_NETOPS_ROUTE, "/offline");
export var NODES_ROUTE = '/nodes';
export var NODES_ENROLLED_ROUTE = '/enrolled';
export var NODES_PENDING_ROUTE = '/pending';
export var NODE_PORTS_ROUTE = '/ports';
export var NODE_LOGS_ROUTE = '/logs';
export var PORT_LOGS_ROUTE = '/port-logs';
export var PORTS_ROUTE = '/ports';
export var SMF_RESOURCES_ROUTE = '/smf-resources';
export var NOT_FOUND_ROUTE = '/not-found';
export var ROLES_ROUTE = '/roles';
export var ROLES_NEW_ROUTE = '/accounts/roles/new';
export var REMOTE_AUTH_ROUTE = '/accounts/remote-auth';
export var SSH_AUTH_ROUTE = '/accounts/ssh-auth';
export var SESSION_SETTINGS_ROUTE = '/settings/system/session-settings';
export var TIME_SETTINGS_ROUTE = '/settings/system/time-settings';
export var TIME_TIMEZONE_ROUTE = '/timezone';
export var TIME_AUTOMATIC_ROUTE = '/automatic';
export var NETWORK_INTERFACES_ROUTE = '/settings/system/network-interfaces';
export var SUBSCRIPTION_SETTINGS_ROUTE = '/settings/system/subscriptions';
export var WEBPROXY_RETURN_ROUTE = '/webProxyReturn';
export var ENROLLMENT_BUNDLES_ROUTE = '/config/enrollment-bundles';
export var ADD_ENROLLMENT_BUNDLE_ROUTE = "".concat(ENROLLMENT_BUNDLES_ROUTE, "/new");
export var USERS_ROUTE = '/accounts/local-users';
export var ADD_USER_ROUTE = "".concat(USERS_ROUTE, "/new");
export function formatLocalUserEditRoute(userId) {
    if (userId === void 0) { userId = ':userId'; }
    return "".concat(LOCAL_USERS_ROUTE, "/").concat(userId);
}
export var SYSTEM_CONFIG_ROUTE = '/settings/system/config';
export var SYSTEM_CONFIG_BACKUP_ROUTE = '/backup';
export var SYSTEM_CONFIG_RESTORE_ROUTE = '/restore';
export var SYSTEM_CONFIG_RESET_ROUTE = '/reset';
export var SYSTEM_CONFIG_RESTORE_STATUS_ROUTE = '/restore-status';
export var FILTERS_ROUTE = '/settings/filters-and-tags/filters';
export var NODE_FILTERS_ROUTE_KEY = 'node-filters';
export var NEW_NODE_FILTERS_ROUTE = "".concat(FILTERS_ROUTE, "/").concat(NODE_FILTERS_ROUTE_KEY);
export var PORT_FILTERS_ROUTE_KEY = 'port-filters';
export var NEW_PORT_FILTERS_ROUTE = "".concat(FILTERS_ROUTE, "/").concat(PORT_FILTERS_ROUTE_KEY);
export var RESOURCE_FILTERS_ROUTE_KEY = 'resource-filters';
export var NEW_RESOURCE_FILTERS_ROUTE = "".concat(FILTERS_ROUTE, "/").concat(RESOURCE_FILTERS_ROUTE_KEY);
export var TAGS_ROUTE = '/settings/filters-and-tags/tags';
export var NODE_TAGS_ROUTE_KEY = 'node-tags';
export var NEW_NODE_TAGS_ROUTE = "".concat(TAGS_ROUTE, "/").concat(NODE_TAGS_ROUTE_KEY);
// TODO: CDM-479 - Check route for port and resource tags
export var PORT_RESOURCE_TAGS_ROUTE_KEY = 'port-and-resource-tags';
export var SYSTEM_NETWORK_SETTINGS_ROUTE = '/settings/system/network-settings';
export var SYSTEM_UPGRADE_ROUTE = '/settings/system/upgrade';
// Old routes from Ember UI that need to continue to work due to links in NetOps module UIs
export var LEGACY_ROUTES = {
    GROUPS_NEW_ROUTE: '/settings/users/permissions/new/group/new',
    GROUP_PREVIEW_ROUTE: '/settings/users/permissions/preview/group/:groupId',
    NODES_DETAILS_ROUTE: '/monitor/:nodeId/ports',
};
export function formatGroupRoute(groupId) {
    if (groupId === void 0) { groupId = ':groupId'; }
    return "/accounts/groups/".concat(groupId);
}
export function formatCopyGroupRoute(groupId) {
    if (groupId === void 0) { groupId = ':groupId'; }
    return "/accounts/groups/".concat(groupId, "/new");
}
export function formatEditGroupRoute(groupId) {
    if (groupId === void 0) { groupId = ':groupId'; }
    return "/accounts/groups/".concat(groupId, "/edit");
}
export function formatRoleRoute(roleId) {
    if (roleId === void 0) { roleId = ':roleId'; }
    return "/accounts/roles/".concat(roleId);
}
export function formatCopyRoleRoute(roleId) {
    if (roleId === void 0) { roleId = ':roleId'; }
    return "/accounts/roles/".concat(roleId, "/new");
}
export function formatEditRoleRoute(roleId) {
    if (roleId === void 0) { roleId = ':roleId'; }
    return "/accounts/roles/".concat(roleId, "/edit");
}
export function formatNodeDetailsRoute(nodeId) {
    if (nodeId === void 0) { nodeId = ':nodeId'; }
    return "/nodes/".concat(nodeId);
}
export function formatDevicePortTerminalRoute(nodeId, portId) {
    if (nodeId === void 0) { nodeId = ':nodeId'; }
    if (portId === void 0) { portId = ':portId'; }
    return "/nodes/".concat(nodeId, "/port/").concat(portId, "/terminal/");
}
export function formatEditSecondaryRoute(lighthouseId) {
    if (lighthouseId === void 0) { lighthouseId = ':lighthouseId'; }
    return "".concat(SECONDARY_LIGHTHOUSES_ROUTE, "/").concat(lighthouseId, "/edit");
}
export function formatEditEnrollmentBundleRoute(bundleId) {
    if (bundleId === void 0) { bundleId = ':bundleId'; }
    return "".concat(ENROLLMENT_BUNDLES_ROUTE, "/").concat(bundleId, "/edit");
}
export function formatNodeUpgradeRoute(upgradeId) {
    if (upgradeId === void 0) { upgradeId = ':upgradeId'; }
    return "/config/firmware-upgrade/tasks/".concat(upgradeId);
}
export function formatEditNetworkInterfaceRoute(interfaceId) {
    if (interfaceId === void 0) { interfaceId = ':interfaceId'; }
    return "".concat(NETWORK_INTERFACES_ROUTE, "/").concat(interfaceId);
}
export function formatNetopsUIRoute(moduleId, routeName) {
    if (moduleId === void 0) { moduleId = ':moduleId'; }
    if (routeName === void 0) { routeName = ':routeName?'; }
    return "/nom/".concat(moduleId, "/ui/").concat(routeName);
}
export function formatConfigRestoreStatusRoute(restoreId) {
    if (restoreId === void 0) { restoreId = ':restoreId'; }
    return "".concat(SYSTEM_CONFIG_RESTORE_STATUS_ROUTE, "/").concat(restoreId);
}
