var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, createContext } from 'react';
import { useFormContext } from 'react-hook-form';
import format from 'string-format';
var errorTemplate = "useFormContainerContext must be wrapped in {}. Are you correctly using the FormContainer component?')";
export var FormContainerContext = createContext(undefined);
export var useFormContainerContext = function () {
    var formContext = useContext(FormContainerContext);
    if (!formContext) {
        throw Error(format(errorTemplate, 'FormContainerContext'));
    }
    var hookFormContext = useFormContext();
    if (!hookFormContext) {
        throw Error(format(errorTemplate, "react-hook-form's FormContext"));
    }
    return __assign(__assign({}, hookFormContext), formContext);
};
