/**
 * Transforms a SmfResourceModalFormState object into a lipy SMF Resource request object
 */
export var transformFormStateToRequest = function (smfResource, formData) {
    var services = {};
    if (formData.http_enabled) {
        services.http = {
            port: formData.http_port,
        };
    }
    if (formData.https_enabled) {
        services.https = {
            port: formData.https_port,
        };
    }
    if (formData.ssh_enabled) {
        services.ssh = {
            port: formData.ssh_port,
        };
    }
    var resourceTagsActions = {};
    var createdTags = formData.newSelectedOrCreatedTagsFields.filter(function (field) { return field.type === 'create'; }).map(function (field) { return field.newResourceTagName; });
    var linkedTags = formData.newSelectedOrCreatedTagsFields.filter(function (field) { return field.type === 'existing'; }).map(function (field) { return field.resourceTagId; });
    var unlinkedTags = smfResource ? formData.unassignedTags.filter(function (field) { return smfResource.resource_tags.includes(field.id); }).map(function (field) { return field.id; }) : [];
    // Remove tags that are in both unlinkedTags and linkedTags
    var commonTags = linkedTags.filter(function (tag) { return unlinkedTags.includes(tag); });
    linkedTags = linkedTags.filter(function (tag) { return !commonTags.includes(tag); });
    unlinkedTags = unlinkedTags.filter(function (tag) { return !commonTags.includes(tag); });
    if (createdTags.length > 0) {
        resourceTagsActions.create = createdTags;
    }
    if (linkedTags.length > 0) {
        resourceTagsActions.link = linkedTags;
    }
    if (unlinkedTags.length > 0) {
        resourceTagsActions.unlink = unlinkedTags;
    }
    return {
        name: formData.name,
        address: formData.address,
        services: services,
        resource_tags_actions: resourceTagsActions,
    };
};
export var transformRequestToFormState = function (smfResource, resourceTagNames) { return ({
    // Setting default values of 80, 443, 22 are irrelevant if http,https and ssh are not enabled
    name: smfResource.name,
    address: smfResource.address,
    http_enabled: !!smfResource.services.http,
    http_port: smfResource.services.http ? smfResource.services.http.port : 80,
    https_enabled: !!smfResource.services.https,
    https_port: smfResource.services.https ? smfResource.services.https.port : 443,
    ssh_enabled: !!smfResource.services.ssh,
    ssh_port: smfResource.services.ssh ? smfResource.services.ssh.port : 22,
    assignedTags: resourceTagNames.filter(function (tag) { return smfResource.resource_tags.includes(tag.id); }),
    unassignedTags: resourceTagNames.filter(function (tag) { return !smfResource.resource_tags.includes(tag.id); }),
    newSelectedOrCreatedTagsFields: [],
}); };
