var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { SshAuthForm } from '@/components/accounts/ssh-auth/ssh-auth-form';
import { useSshRootLogin } from '@/api/services/ssh-root-login';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { transformResponseToFormState } from '@/components/accounts/ssh-auth/transformers';
import { useSshPasswordAuth } from '@/api/services/ssh-password-auth';
var SSH_AUTH_LOADER_ID = 'ssh-auth-form-loading';
function SshAuth() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasSshEditAccess = hasPermission('settings.services.ssh.edit');
    var _a = useSshPasswordAuth(), sshPasswordAuth = _a.data, isLoadingSshPasswordAuthData = _a.isLoading;
    var _b = useSshRootLogin(), sshRootLogin = _b.data, isLoadingSshRootLogin = _b.isLoading;
    useDocTitle(t('pages.ssh_auth.page_title'));
    var innerNode = null;
    if (isLoadingSshRootLogin || isLoadingSshPasswordAuthData) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": SSH_AUTH_LOADER_ID });
    }
    if (sshPasswordAuth && sshRootLogin) {
        var data = transformResponseToFormState(__assign(__assign({}, sshPasswordAuth), sshRootLogin));
        innerNode = _jsx(SshAuthForm, { data: data, permissions: { hasSshEditAccess: hasSshEditAccess } });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: _jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2" }, { children: t('pages.ssh_auth.page_title') })) })), innerNode] })));
}
export default withAuthenticationRequired(SshAuth, { rights: ['settings.services.ssh.view'] });
