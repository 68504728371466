var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import size from 'lodash/size';
import Collapse from '@mui/material/Collapse';
import map from 'lodash/map';
import { OGPseudoTable, OGPseudoTableBody, OGPseudoTableCell, OGPseudoTableRow } from '@opengear/table';
import { useOpen } from '@/hooks/open';
import { PermissionsCell, SubExpandRowCell, SubNameCell } from '../utils';
import { PermissionsRadioGroup } from '../permissions/permissions-radio-group';
export function GroupsAndRolesPermissionsRow(_a) {
    var label = _a.label, _b = _a.permission, overall = _b.overall, others = __rest(_b, ["overall"]), title = _a.title, tooltip = _a.tooltip, _c = _a.mode, mode = _c === void 0 ? 'group' : _c;
    var _d = useOpen(), open = _d.open, handleToggle = _d.handleToggle;
    var t = useTranslation().t;
    return (_jsxs(OGPseudoTableRow, __assign({ hover: true, sx: { flexDirection: 'column' } }, { children: [_jsxs(OGPseudoTableRow, __assign({ sx: { '& .MuiTableCell-root': { border: 'none' } } }, { children: [_jsx(SubExpandRowCell, { children: _jsx(ToggleButton, __assign({ "aria-label": t('components.permissions.table.actions.expand_permissions_breakdown'), disabled: size(others) === 0, onChange: handleToggle, sx: { padding: 0 }, selected: open, value: "check" }, { children: open ? _jsx(ExpandLessIcon, {}) : _jsx(ExpandMoreIcon, {}) })) }), _jsxs(SubNameCell, { children: [title, tooltip && _jsx(OGIconTooltip, { title: tooltip })] }), _jsx(PermissionsCell, { children: _jsx(PermissionsRadioGroup, { color: "success", label: label !== null && label !== void 0 ? label : title, permission: overall }) })] })), _jsx(OGPseudoTableRow, { children: _jsx(OGPseudoTableCell, __assign({ sx: { padding: 0, width: '100%' } }, { children: _jsx(Collapse, __assign({ in: open, sx: { width: '100%' }, unmountOnExit: true }, { children: _jsx(OGPseudoTable, { children: _jsxs(OGPseudoTableBody, { children: [_jsxs(OGPseudoTableRow, __assign({ sx: { '& .MuiTableCell-root': { border: 'none', paddingTop: 0 } } }, { children: [_jsx(SubExpandRowCell, {}), _jsxs(SubNameCell, __assign({ sx: { color: 'text.label', textTransform: 'uppercase' } }, { children: [t('components.permissions.breakdown.label'), _jsx(OGIconTooltip, { title: mode === 'group' ? t('components.permissions.breakdown.tooltip_group') : t('components.permissions.breakdown.tooltip_user') })] })), _jsx(PermissionsCell, {})] })), map(others, function (value, key) { return (_jsxs(OGPseudoTableRow, __assign({ sx: { '& .MuiTableCell-root': { border: 'none', paddingTop: 0 } } }, { children: [_jsx(SubExpandRowCell, {}), _jsx(SubNameCell, { children: key }), _jsx(PermissionsCell, { children: _jsx(PermissionsRadioGroup, { label: key, permission: value }) })] }), key)); }), _jsx(OGPseudoTableRow, {})] }) }) })) })) })] })));
}
