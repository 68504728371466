var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
import { IQueryNodeField, IQueryOperation, IQueryPortField, IQuerySmfResourceField } from '../../../../types/query/types';
import { nodeQueryFields, portQueryFields, resourceQueryFields, stringMatchingOperators, simpleEqualityOperators, advancedEqualityOperators } from './constants';
import { UNICODE_CHARS } from '@/constants/unicode';
/**
 * Retrieves the filter operators for a given node filter field name.
 * @param fieldName - The name of the field.
 * @returns An array of filter operators.
 */
var getNodeFilterOperators = function (fieldname) {
    switch (fieldname) {
        case IQueryNodeField.approved:
        case IQueryNodeField.connectionStatus:
        case IQueryNodeField.id:
        case IQueryNodeField.productFamily:
        case IQueryNodeField.status:
            return simpleEqualityOperators;
        case IQueryNodeField.lhvpnAddress:
        case IQueryNodeField.macAddress:
        case IQueryNodeField.model:
        case IQueryNodeField.name:
        case IQueryNodeField.product:
            return __spreadArray(__spreadArray([], __read(simpleEqualityOperators), false), __read(stringMatchingOperators), false);
        case IQueryNodeField.cellHealthStatus:
        case IQueryNodeField.firmwareVersion:
        case IQueryNodeField.serialNumber:
        case IQueryNodeField.sshPort:
            return __spreadArray(__spreadArray([], __read(advancedEqualityOperators), false), __read(stringMatchingOperators), false);
        default:
            return [];
    }
};
/**
 * Retrieves the filter operators for a given port filter field name.
 * @param fieldName - The name of the field.
 * @returns An array of filter operators.
 */
var getPortFilterOperators = function (fieldName) {
    switch (fieldName) {
        case IQueryPortField.tag:
            return simpleEqualityOperators;
        case IQueryPortField.label:
            return __spreadArray(__spreadArray([], __read(advancedEqualityOperators), false), __read(stringMatchingOperators), false);
        default:
            return [];
    }
};
/**
 * Retrieves the filter operators for a given resource filter field name.
 * @param fieldName - The name of the field.
 * @returns An array of filter operators for the specified field.
 */
var getResourceFilterOperators = function (fieldName) {
    switch (fieldName) {
        case IQuerySmfResourceField.name:
            return __spreadArray(__spreadArray([], __read(simpleEqualityOperators), false), __read(stringMatchingOperators), false);
        case IQuerySmfResourceField.address:
        case IQuerySmfResourceField.tag:
            return simpleEqualityOperators;
        case IQuerySmfResourceField.networkRange:
            return [IQueryOperation.in];
        default:
            return [];
    }
};
/**
 * Retrieves the operators for a given query field.
 *
 * @param fieldname - The query field for which to retrieve the operators.
 * @returns An array of operators for the specified query field.
 */
export var getOperators = function (fieldname) {
    if (nodeQueryFields.includes(fieldname)) {
        return getNodeFilterOperators(fieldname);
    }
    if (portQueryFields.includes(fieldname)) {
        return getPortFilterOperators(fieldname);
    }
    if (resourceQueryFields.includes(fieldname)) {
        return getResourceFilterOperators(fieldname);
    }
    // Special handling for Node tags. Port/Resource tags use the "config:tag" fieldname.
    if (fieldname.startsWith('tag:')) {
        return simpleEqualityOperators;
    }
    return [];
};
/**
 * Returns the formatted (human-readable) query operator based on the provided operation.
 *
 * @param oper - The query operation.
 * @returns The formatted query operator.
 */
export function getFormattedQueryOperator(oper) {
    switch (oper) {
        case IQueryOperation.equal:
            return UNICODE_CHARS.equal;
        case IQueryOperation.notEqual:
            return UNICODE_CHARS.notEqual;
        case IQueryOperation.lessThan:
            return UNICODE_CHARS.lessThan;
        case IQueryOperation.lessThanOrEqual:
            return UNICODE_CHARS.lessThanOrEqual;
        case IQueryOperation.greaterThan:
            return UNICODE_CHARS.greaterThan;
        case IQueryOperation.greaterThanOrEqual:
            return UNICODE_CHARS.greaterThanOrEqual;
        case IQueryOperation.startsWith:
            return t('components.query.oper.starts_with');
        case IQueryOperation.endsWith:
            return t('components.query.oper.ends_with');
        case IQueryOperation.contains:
            return t('components.query.oper.contains');
        case IQueryOperation.in:
            return t('components.query.oper.in');
        case IQueryOperation.nin:
            return t('components.query.oper.not_in');
        default:
            return '';
    }
}
