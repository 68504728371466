var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Field limits
export var NameFieldMaxLength = 65535;
export function NameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContainerContext().control;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: requiredFieldError(t('pages.firmware_upgrade_new.fields.name.label')),
            maxLength: {
                value: NameFieldMaxLength,
                message: maxLengthFieldError(t('pages.firmware_upgrade_new.fields.name.label'), NameFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var _c = _a.field, ref = _c.ref, field = __rest(_c, ["ref"]), fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ inputRef: ref, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.firmware_upgrade_new.fields.name.label'), sx: { width: isMobileView ? undefined : 300 } }, field, { required: true })));
        } }));
}
