var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { OGTagIcon } from '@opengear/icons/generic/tag';
import { OGTable } from '@opengear/table';
import Stack from '@mui/material/Stack';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { PortStateCell } from '@/components/ports/port/port-state-cell';
import NoRowsOverlay from '@/components/nodes/no-rows-overlay';
import { WebTerminalCell } from '@/components/ports/port/web-terminal-cell';
import { LogCell } from '@/components/ports/port/log-cell';
import { SshAccessCell } from '@/components/ports/port/ssh-access-cell';
import { useDeviceView } from '@/hooks/use-device-view';
import { AssignTagModal } from '@/components/assign-tag-modal';
import { useOpen } from '@/hooks/open';
import { ChipPopover } from '@/components/chip-popover';
import { NewTagButton } from '@/components/new-tag-button';
import { PortStatusFilter, usePortStatusFilter } from '@/components/ports';
function NodePortsTable(_a) {
    var node = _a.node, resourceTagNames = _a.resourceTags;
    var t = useTranslation().t;
    var _b = useUIPermissions(), hasPermission = _b.hasPermission, isPrimary = _b.isPrimary;
    var isLaptopView = useDeviceView().isLaptopView;
    var _c = usePortStatusFilter(node.ports), statusPortsParamValue = _c.statusPortsParamValue, setStatusPortsParamValue = _c.setStatusPortsParamValue, statusFilteredPorts = _c.statusFilteredPorts;
    var transformPortIntoTableRow = useCallback(function (port) { return (__assign(__assign({}, port), { 
        // Map each tag_id to its corresponding resource_tag_name, if found; otherwise, keep the tag_id.
        assignedTags: port.resource_tags.map(function (tag_id) {
            var _a;
            return ({
                id: tag_id,
                resource_tag_name: ((_a = resourceTagNames.find(function (tag) { return tag.id === tag_id; })) === null || _a === void 0 ? void 0 : _a.resource_tag_name) || tag_id,
            });
        }) })); }, [resourceTagNames]);
    // Values passed to table
    var portTableRows = useMemo(function () { return statusFilteredPorts.map(transformPortIntoTableRow); }, [statusFilteredPorts, transformPortIntoTableRow]);
    // Table defaults
    var defaultOrderBy = 'port_csid';
    var defaultSortModel = [{ field: defaultOrderBy, sort: 'asc' }];
    // evaluate permissions
    var hasWebTerminalAccess = hasPermission('manage.managed_devices.console_gateway.web_terminal');
    var hasSerialPortAccess = hasPermission('manage.managed_devices.console_gateway.serial_port_access');
    var canEditTags = hasPermission('configure.edit_tags.edit');
    // Assign Tags
    var _d = __read(useState([]), 2), rowSelectionModel = _d[0], setRowSelectionModel = _d[1];
    var assignTagModalOpen = useOpen();
    var _e = __read(useState([]), 2), modalRowData = _e[0], setModalRowData = _e[1];
    var selectedRows = useMemo(function () { return portTableRows.filter(function (row) { return rowSelectionModel.includes(row.id); }); }, [rowSelectionModel, portTableRows]);
    var assignTagsToRowClickHandler = useCallback(function (row) {
        setModalRowData([{ id: row.id, label: row.label, assignedTags: row.assignedTags }]);
        assignTagModalOpen.handleOpen();
    }, [assignTagModalOpen]);
    var assignTagsToSelectedRowsClickHandler = useCallback(function () {
        setModalRowData(selectedRows.map(function (row) { return ({ id: row.id, label: row.label, assignedTags: row.assignedTags }); }));
        assignTagModalOpen.handleOpen();
    }, [assignTagModalOpen, selectedRows]);
    var uncheckColumnCheckboxes = useCallback(function () {
        setRowSelectionModel([]);
    }, [setRowSelectionModel]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'mode',
            align: 'left',
            headerName: t('pages.node.ports.table.headers.state'),
            sortable: true,
            minWidth: 86,
            renderCell: function (_a) {
                var port = _a.row;
                return _jsx(PortStateCell, { port: port });
            },
        },
        {
            field: 'port_csid',
            headerName: t('pages.node.ports.table.headers.port_number'),
            minWidth: 200,
            sortable: true,
            sortComparator: function (v1, v2) { return Number(v1) - Number(v2); },
            valueGetter: function (_a) {
                var _b, _c;
                var value = _a.value;
                return (_c = (_b = value === null || value === void 0 ? void 0 : value.match(/\d+$/)) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : 'n/a';
            },
        },
        { field: 'label', headerName: t('pages.node.ports.table.headers.label'), sortable: false, flex: 0.5, minWidth: 200 },
        {
            field: 'assignedTags',
            headerName: t('pages.node.ports.table.headers.resource_tags'),
            minWidth: 300,
            flex: 1,
            sortable: false,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                var newTagButton = canEditTags && isPrimary ? (_jsx(NewTagButton, { tabIndex: tabIndex, onClick: function () { return assignTagsToRowClickHandler(row); }, toolTipLabel: t('components.new_tag_button.aria_label') })) : null;
                if (row.assignedTags && row.assignedTags.length > 0) {
                    return (_jsx(ChipPopover, __assign({ tags: row.assignedTags.map(function (fullTag) { return fullTag; }), tabIndex: tabIndex }, { children: newTagButton })));
                }
                return (_jsx(Stack, __assign({ component: "div", tabIndex: tabIndex, "aria-label": t('pages.node.ports.table.no_tag_message') }, { children: newTagButton })));
            },
        },
        {
            field: 'log',
            headerClassName: 'FocusDisabled',
            maxWidth: 1,
            sortable: false,
            renderCell: function (_a) {
                var port = _a.row, tabIndex = _a.tabIndex;
                return _jsx(LogCell, { port: port, tabIndex: tabIndex });
            },
        },
        {
            field: 'web_terminal',
            headerClassName: 'FocusDisabled',
            maxWidth: 1,
            sortable: false,
            renderCell: function (_a) {
                var port = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(WebTerminalCell, { port: port, tabIndex: tabIndex, hasWebTerminalAccess: hasWebTerminalAccess }));
            },
        },
        {
            field: 'ssh_access',
            headerClassName: 'FocusDisabled',
            maxWidth: 1,
            sortable: false,
            renderCell: function (_a) {
                var port = _a.row, tabIndex = _a.tabIndex;
                return _jsx(SshAccessCell, { port: port, tabIndex: tabIndex });
            },
        },
    ]; }, [t, canEditTags, isPrimary, assignTagsToRowClickHandler, hasWebTerminalAccess]);
    if (isEmpty(node.ports)) {
        // node.ports not portsRows because portsRows can become empty as a result of filter application and no chance to go back changing the filter
        return _jsx(NoRowsOverlay, { message: t('pages.node.ports.table.no_row_message'), noRowTableIcon: "systemShine" });
    }
    return (_jsxs(_Fragment, { children: [_jsx(AssignTagModal, { uncheckColumnCheckboxes: uncheckColumnCheckboxes, open: assignTagModalOpen.open, handleClose: assignTagModalOpen.handleClose, selectedRows: modalRowData, resourceTagNames: resourceTagNames }), _jsxs(Box, __assign({ sx: { overflowX: 'auto' } }, { children: [_jsxs(Box, __assign({ display: "flex", marginBottom: 0.5, sx: { justifyContent: 'space-between', paddingLeft: '8px', paddingTop: '4px' } }, { children: [canEditTags && isPrimary && (_jsx(OGButton, __assign({ disabled: !(rowSelectionModel.length > 0), "aria-label": t('components.new_tag_button_multi_row.aria_label'), variant: "borderless", onClick: assignTagsToSelectedRowsClickHandler, size: "small" }, { children: _jsx(OGTagIcon, { fontSize: "small" }) }))), _jsx(PortStatusFilter, { paramFilter: statusPortsParamValue, onChange: setStatusPortsParamValue })] })), _jsx(Box, __assign({ sx: __assign({}, (!isLaptopView && { height: 'calc(100vh - 375px)' })) }, { children: _jsx(OGTable, { id: "node-ports-table", "aria-label": t('pages.node.ports.table.label'), columns: columns, rows: portTableRows, autoHeight: isLaptopView, noRowsMessage: t('pages.node.ports.table.no_row_message'), rowCount: portTableRows.length, checkboxSelection: isPrimary && canEditTags, onRowSelectionModelChange: setRowSelectionModel, rowSelectionModel: rowSelectionModel, initialState: {
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        log: !!node.port_logging_enabled,
                                        ssh_access: hasSerialPortAccess,
                                    },
                                },
                            } }) }))] }))] }));
}
// Reduce re-renders
export default memo(NodePortsTable);
