var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGLink } from '@opengear/links';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OG_CONTACT_US_LINK } from '@/constants/links';
export function ContactSalesLink() {
    var t = useTranslation().t;
    return (_jsx(OGLink, __assign({ variant: "standard", target: "_blank", endIcon: _jsx(OpenInNewIcon, { fontSize: "small" }), to: OG_CONTACT_US_LINK, external: true, sx: { maxWidth: 'max-content' } }, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.renewal_contact_sales') })));
}
