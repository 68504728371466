import i18n from '@/i18n/config';
// eslint-disable-next-line no-restricted-imports
import validateErrorResponse from '../../types/lighthouse/validators/error-response';
export var GENERIC_ERROR_MESSAGE = i18n.t('api.errors.unknown_server_error');
function formatMessageFromErrorResponse(_a, defaultMessage) {
    var error = _a.error;
    var message = '';
    if (error.length === 0)
        return defaultMessage || i18n.t('api.errors.unknown_error');
    var multipleMessages = error.length > 1;
    var prefixMessage = multipleMessages ? '- ' : '';
    if (multipleMessages) {
        message += "".concat(i18n.t('api.errors.multiple_error'), ":\n");
    }
    error.forEach(function (_a) {
        var text = _a.text;
        message += "".concat(prefixMessage + text, "\n");
    });
    return message;
}
export function formatErrorMessage(error, defaultMessage) {
    if (typeof error === 'string')
        return error;
    if (error instanceof Error)
        return error.message;
    try {
        if (validateErrorResponse(error))
            return formatMessageFromErrorResponse(error, defaultMessage);
    }
    catch (err) {
        /* istanbul ignore next */
        // No need to handle validator throwing an error
    }
    if (error !== undefined && error !== null)
        return String(error);
    return defaultMessage || i18n.t('api.errors.unknown_error');
}
// This enum is copied from models/constants.py in lipy with some updates
// It is ONLY useful for providing human-readable error codes for api errors
// It should not be considered a source of truth for error codes
// Editing this enum will have no effect on the error codes received from the backend or how they are translated
export var APIErrorCodes;
(function (APIErrorCodes) {
    APIErrorCodes[APIErrorCodes["STRING_ABOVE_MAX"] = 11] = "STRING_ABOVE_MAX";
    APIErrorCodes[APIErrorCodes["STRING_BELOW_MIN"] = 12] = "STRING_BELOW_MIN";
    APIErrorCodes[APIErrorCodes["STRING_INVALID_VALUE"] = 13] = "STRING_INVALID_VALUE";
    APIErrorCodes[APIErrorCodes["INVALID_FORMAT"] = 14] = "INVALID_FORMAT";
    APIErrorCodes[APIErrorCodes["DUPLICATE"] = 26] = "DUPLICATE";
    APIErrorCodes[APIErrorCodes["NOT_FOUND"] = 28] = "NOT_FOUND";
    APIErrorCodes[APIErrorCodes["UUID_NOT_FOUND"] = 29] = "UUID_NOT_FOUND";
    APIErrorCodes[APIErrorCodes["INVALID_BODY"] = 39] = "INVALID_BODY";
    APIErrorCodes[APIErrorCodes["REQUIRED_PARAM"] = 40] = "REQUIRED_PARAM";
    APIErrorCodes[APIErrorCodes["INVALID_FIELD"] = 41] = "INVALID_FIELD";
    APIErrorCodes[APIErrorCodes["INVALID_LOGIN_CREDENTIALS"] = 42] = "INVALID_LOGIN_CREDENTIALS";
    APIErrorCodes[APIErrorCodes["NOT_AUTHORIZED"] = 43] = "NOT_AUTHORIZED";
    APIErrorCodes[APIErrorCodes["REG_NOT_READY"] = 46] = "REG_NOT_READY";
    APIErrorCodes[APIErrorCodes["PERMISSION_DENIED"] = 47] = "PERMISSION_DENIED";
    APIErrorCodes[APIErrorCodes["INVALID_JSON_PARAM"] = 49] = "INVALID_JSON_PARAM";
    APIErrorCodes[APIErrorCodes["INVALID_JSON_PARAM_VALUE"] = 50] = "INVALID_JSON_PARAM_VALUE";
    APIErrorCodes[APIErrorCodes["INVALID_CERTIFICATE"] = 51] = "INVALID_CERTIFICATE";
    APIErrorCodes[APIErrorCodes["UNKNOWN_TYPE"] = 52] = "UNKNOWN_TYPE";
    APIErrorCodes[APIErrorCodes["DHCP_LIMIT"] = 60] = "DHCP_LIMIT";
    APIErrorCodes[APIErrorCodes["INVALID_ROLE"] = 61] = "INVALID_ROLE";
    APIErrorCodes[APIErrorCodes["NUMBER_OUTSIDE_ALLOWED_RANGE"] = 62] = "NUMBER_OUTSIDE_ALLOWED_RANGE";
    APIErrorCodes[APIErrorCodes["INVALID_LICENSE_SEQUENCE"] = 63] = "INVALID_LICENSE_SEQUENCE";
    APIErrorCodes[APIErrorCodes["LICENSE_ALREADY_EXISTS"] = 64] = "LICENSE_ALREADY_EXISTS";
    APIErrorCodes[APIErrorCodes["UNSUPPORTED_ACTION"] = 66] = "UNSUPPORTED_ACTION";
    APIErrorCodes[APIErrorCodes["NO_CHANGES_REQUIRED"] = 69] = "NO_CHANGES_REQUIRED";
    APIErrorCodes[APIErrorCodes["NODE_OFFLINE"] = 72] = "NODE_OFFLINE";
    APIErrorCodes[APIErrorCodes["INVALID_FILE_FORMAT"] = 74] = "INVALID_FILE_FORMAT";
    APIErrorCodes[APIErrorCodes["SNMP_COMMUNITY"] = 81] = "SNMP_COMMUNITY";
    APIErrorCodes[APIErrorCodes["SNMP_AUTH_PASSWORD"] = 82] = "SNMP_AUTH_PASSWORD";
    APIErrorCodes[APIErrorCodes["SNMP_PRIV_PASSWORD"] = 83] = "SNMP_PRIV_PASSWORD";
    APIErrorCodes[APIErrorCodes["SNMP_V3_REQUIRED_FIELDS"] = 84] = "SNMP_V3_REQUIRED_FIELDS";
    APIErrorCodes[APIErrorCodes["INTERNAL_SERVER_ERROR"] = 85] = "INTERNAL_SERVER_ERROR";
    APIErrorCodes[APIErrorCodes["DUPLICATE_IPADDRESS"] = 102] = "DUPLICATE_IPADDRESS";
    APIErrorCodes[APIErrorCodes["INVALID_SUBNET"] = 103] = "INVALID_SUBNET";
    APIErrorCodes[APIErrorCodes["INVALID_TIMEZONE"] = 107] = "INVALID_TIMEZONE";
    APIErrorCodes[APIErrorCodes["INVALID_START_DATE"] = 111] = "INVALID_START_DATE";
    APIErrorCodes[APIErrorCodes["LOCATION_NOT_WRITABLE"] = 112] = "LOCATION_NOT_WRITABLE";
    APIErrorCodes[APIErrorCodes["LOCATION_NOT_ENOUGH_SPACE"] = 113] = "LOCATION_NOT_ENOUGH_SPACE";
    APIErrorCodes[APIErrorCodes["PASSWORD_LENGTH"] = 118] = "PASSWORD_LENGTH";
    APIErrorCodes[APIErrorCodes["PASSWORD_NUMBER"] = 119] = "PASSWORD_NUMBER";
    APIErrorCodes[APIErrorCodes["PASSWORD_CAPITAL"] = 120] = "PASSWORD_CAPITAL";
    APIErrorCodes[APIErrorCodes["PASSWORD_SPECIAL"] = 121] = "PASSWORD_SPECIAL";
    APIErrorCodes[APIErrorCodes["PASSWORD_USERNAME"] = 122] = "PASSWORD_USERNAME";
    APIErrorCodes[APIErrorCodes["PASSWORD_REUSE"] = 123] = "PASSWORD_REUSE";
    APIErrorCodes[APIErrorCodes["LOW_SUBNET_CAPACITY"] = 200] = "LOW_SUBNET_CAPACITY";
    APIErrorCodes[APIErrorCodes["INVALID_PRIVATE_KEY"] = 201] = "INVALID_PRIVATE_KEY";
    APIErrorCodes[APIErrorCodes["USERNAME_SYSTEM"] = 202] = "USERNAME_SYSTEM";
    APIErrorCodes[APIErrorCodes["INVALID_CELL_HEALTH_CONFIG"] = 203] = "INVALID_CELL_HEALTH_CONFIG";
    APIErrorCodes[APIErrorCodes["UNKNOWN_CLIENT"] = 204] = "UNKNOWN_CLIENT";
    APIErrorCodes[APIErrorCodes["INVALID_TIME_FORMAT"] = 205] = "INVALID_TIME_FORMAT";
    APIErrorCodes[APIErrorCodes["SNMP_ENABLED"] = 206] = "SNMP_ENABLED";
    APIErrorCodes[APIErrorCodes["SNMP_MANAGER_MSG_TYPE"] = 207] = "SNMP_MANAGER_MSG_TYPE";
    APIErrorCodes[APIErrorCodes["SUPPORT_REPORT_FAILED"] = 208] = "SUPPORT_REPORT_FAILED";
    APIErrorCodes[APIErrorCodes["BACKUP_NOT_AVAILABLE"] = 209] = "BACKUP_NOT_AVAILABLE";
    APIErrorCodes[APIErrorCodes["FILE_NOT_FOUND"] = 210] = "FILE_NOT_FOUND";
    APIErrorCodes[APIErrorCodes["FILE_INVALID"] = 211] = "FILE_INVALID";
    APIErrorCodes[APIErrorCodes["BACKUP_ERROR"] = 212] = "BACKUP_ERROR";
    APIErrorCodes[APIErrorCodes["BACKUP_VERSION_ERROR"] = 213] = "BACKUP_VERSION_ERROR";
    APIErrorCodes[APIErrorCodes["VPN_CONFLICT"] = 214] = "VPN_CONFLICT";
    APIErrorCodes[APIErrorCodes["LICENSE_SKU_EXISTS"] = 215] = "LICENSE_SKU_EXISTS";
    APIErrorCodes[APIErrorCodes["SSO_NOT_AUTHORIZED"] = 216] = "SSO_NOT_AUTHORIZED";
    APIErrorCodes[APIErrorCodes["DUPLICATE_SYSTEM_GROUP"] = 217] = "DUPLICATE_SYSTEM_GROUP";
    APIErrorCodes[APIErrorCodes["DUPLICATE_NODE_FIRMWARE"] = 218] = "DUPLICATE_NODE_FIRMWARE";
    APIErrorCodes[APIErrorCodes["DUPLICATE_NODE_FIRMWARE_FILE_NAME"] = 219] = "DUPLICATE_NODE_FIRMWARE_FILE_NAME";
    APIErrorCodes[APIErrorCodes["NODE_FIRMWARE_INVALID"] = 220] = "NODE_FIRMWARE_INVALID";
    APIErrorCodes[APIErrorCodes["EXPECTED_NODE_FIRMWARE_IS_DIR"] = 221] = "EXPECTED_NODE_FIRMWARE_IS_DIR";
    APIErrorCodes[APIErrorCodes["JOB_ALREADY_FINISHED"] = 222] = "JOB_ALREADY_FINISHED";
    APIErrorCodes[APIErrorCodes["JOB_ALREADY_CANCELLED"] = 223] = "JOB_ALREADY_CANCELLED";
    APIErrorCodes[APIErrorCodes["JOB_BEING_CANCELLED"] = 224] = "JOB_BEING_CANCELLED";
    APIErrorCodes[APIErrorCodes["NODE_UPGRADE_ALREADY_SCHEDULED"] = 225] = "NODE_UPGRADE_ALREADY_SCHEDULED";
    APIErrorCodes[APIErrorCodes["FAMILY_NOT_COMPATIBLE"] = 226] = "FAMILY_NOT_COMPATIBLE";
    APIErrorCodes[APIErrorCodes["VERSION_NOT_COMPATIBLE"] = 227] = "VERSION_NOT_COMPATIBLE";
    APIErrorCodes[APIErrorCodes["FIRMWARE_STILL_NEEDED"] = 228] = "FIRMWARE_STILL_NEEDED";
    APIErrorCodes[APIErrorCodes["CANNOT_REMOVE_ADMIN_FROM_ROOT"] = 229] = "CANNOT_REMOVE_ADMIN_FROM_ROOT";
    APIErrorCodes[APIErrorCodes["INVALID_NODE_IDS_PROVIDED"] = 230] = "INVALID_NODE_IDS_PROVIDED";
    APIErrorCodes[APIErrorCodes["INCOMPATIBLE_FIRMWARE_FAMILY_NODE_IDS"] = 231] = "INCOMPATIBLE_FIRMWARE_FAMILY_NODE_IDS";
    APIErrorCodes[APIErrorCodes["MISSING_FIRMWARE_FAMILY_NODE_IDS"] = 232] = "MISSING_FIRMWARE_FAMILY_NODE_IDS";
    APIErrorCodes[APIErrorCodes["LEGACY_LICENSE_CANNOT_BE_USED"] = 233] = "LEGACY_LICENSE_CANNOT_BE_USED";
    APIErrorCodes[APIErrorCodes["EXPIRED_SUBSCRIPTION"] = 234] = "EXPIRED_SUBSCRIPTION";
    APIErrorCodes[APIErrorCodes["EXCEEDED_SUBSCRIPTION_CAPACITY"] = 235] = "EXCEEDED_SUBSCRIPTION_CAPACITY";
    APIErrorCodes[APIErrorCodes["CANNOT_PUSH_NOM_TO_ENTERPRISE"] = 236] = "CANNOT_PUSH_NOM_TO_ENTERPRISE";
    APIErrorCodes[APIErrorCodes["CANNOT_DELETE_SMARTGROUP_ORPHANING_GROUPS"] = 237] = "CANNOT_DELETE_SMARTGROUP_ORPHANING_GROUPS";
    APIErrorCodes[APIErrorCodes["CANNOT_DELETE_MANAGEDDEVICEFILTER_ORPHANING_GROUPS"] = 238] = "CANNOT_DELETE_MANAGEDDEVICEFILTER_ORPHANING_GROUPS";
    APIErrorCodes[APIErrorCodes["CANNOT_UPGRADE_WITH_FAILED_SECONDARY_UPGRADES"] = 239] = "CANNOT_UPGRADE_WITH_FAILED_SECONDARY_UPGRADES";
    APIErrorCodes[APIErrorCodes["DUPLICATE_TAG_ON_RESOURCE"] = 240] = "DUPLICATE_TAG_ON_RESOURCE";
    APIErrorCodes[APIErrorCodes["DUPLICATE_NODE_IDS"] = 241] = "DUPLICATE_NODE_IDS";
    APIErrorCodes[APIErrorCodes["ARRAY_BELOW_MIN_ITEMS"] = 242] = "ARRAY_BELOW_MIN_ITEMS";
    APIErrorCodes[APIErrorCodes["INCORRECT_NUMBER_OF_SMF_AUTH_KEYS"] = 243] = "INCORRECT_NUMBER_OF_SMF_AUTH_KEYS";
    APIErrorCodes[APIErrorCodes["DUPLICATE_AUTH_KEY_IDS"] = 244] = "DUPLICATE_AUTH_KEY_IDS";
    APIErrorCodes[APIErrorCodes["NETWORK_CONTAINS_HOST_BITS"] = 245] = "NETWORK_CONTAINS_HOST_BITS";
    APIErrorCodes[APIErrorCodes["NETWORK_OVERLAPS"] = 246] = "NETWORK_OVERLAPS";
    APIErrorCodes[APIErrorCodes["MISSING_WIREGUARD_CONFIG"] = 247] = "MISSING_WIREGUARD_CONFIG";
    APIErrorCodes[APIErrorCodes["SMF_SUBNET_UNAVAILABLE"] = 248] = "SMF_SUBNET_UNAVAILABLE";
    APIErrorCodes[APIErrorCodes["PREMIUM_SUBSCRIPTION_FEATURE"] = 249] = "PREMIUM_SUBSCRIPTION_FEATURE";
    APIErrorCodes[APIErrorCodes["ALL_SUBSCRIPTIONS_EXPIRED"] = 250] = "ALL_SUBSCRIPTIONS_EXPIRED";
    APIErrorCodes[APIErrorCodes["DUPLICATE_SMF_CONFIG"] = 251] = "DUPLICATE_SMF_CONFIG";
    APIErrorCodes[APIErrorCodes["INVALID_PRIVATE_NETWORK"] = 252] = "INVALID_PRIVATE_NETWORK";
    APIErrorCodes[APIErrorCodes["INVALID_OSPF_AREA_ID"] = 253] = "INVALID_OSPF_AREA_ID";
    APIErrorCodes[APIErrorCodes["DUPLICATE_TEMPLATE_NAME"] = 254] = "DUPLICATE_TEMPLATE_NAME";
    APIErrorCodes[APIErrorCodes["CONFLICTING_OSPF_AREA_DECLARATIONS"] = 255] = "CONFLICTING_OSPF_AREA_DECLARATIONS";
    APIErrorCodes[APIErrorCodes["BUNDLE_HAS_NO_SUB"] = 256] = "BUNDLE_HAS_NO_SUB";
    APIErrorCodes[APIErrorCodes["ADDRESS_NOT_IN_SMF_DISCOVERED_SUBNET"] = 257] = "ADDRESS_NOT_IN_SMF_DISCOVERED_SUBNET";
    APIErrorCodes[APIErrorCodes["DOMAIN_NAME_UNRESOLVED"] = 258] = "DOMAIN_NAME_UNRESOLVED";
})(APIErrorCodes || (APIErrorCodes = {}));
