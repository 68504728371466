var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RedoIcon from '@mui/icons-material/Redo';
import { OGButton } from '@opengear/buttons';
import { CancelTask } from './node-upgrade-components';
import { NEW_FIRMWARE_UPGRADE_ROUTE } from '@/constants/routes';
import { useCopyRepeatData } from './copy-repeat-data-context';
export function NodeUpgradeActionBar(_a) {
    var nodeUpgrade = _a.nodeUpgrade, hasEditPermission = _a.hasEditPermission;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var status = nodeUpgrade.status, id = nodeUpgrade.id;
    var copyRepeatData = useCopyRepeatData().copyRepeatData;
    var isCancelDisabled = useMemo(function () {
        return !hasEditPermission ||
            status === 'ABORTED_BY_LH' ||
            status === 'CANCELLED_BY_USER' ||
            status === 'CANCELLING' ||
            status === 'SUCCESS' ||
            status === 'FAILURE';
    }, [hasEditPermission, status]);
    var isCopyDisabled = useMemo(function () { return !hasEditPermission || !copyRepeatData || status === 'SCHEDULED' || status === 'IN_PROGRESS'; }, [copyRepeatData, hasEditPermission, status]);
    var isRepeatDisabled = useMemo(function () { return !hasEditPermission || !(copyRepeatData === null || copyRepeatData === void 0 ? void 0 : copyRepeatData.firmwareId); }, [copyRepeatData, hasEditPermission]);
    return (_jsxs(Box, { children: [_jsx(CancelTask, { disabled: isCancelDisabled, taskId: id.toString() }), _jsx(Tooltip, __assign({ arrow: true, title: t('pages.node_upgrade.actions.copy.tooltip') }, { children: _jsx("span", __assign({ style: { display: 'inline-block' } }, { children: _jsx(OGButton, __assign({ disabled: isCopyDisabled, startIcon: _jsx(ContentCopyIcon, {}), "aria-label": t('pages.node_upgrade.actions.copy.label'), variant: "borderless", onClick: function () { return navigate(NEW_FIRMWARE_UPGRADE_ROUTE, { state: { copyRepeatData: copyRepeatData } }); } }, { children: t('pages.node_upgrade.actions.copy.label') })) })) })), status === 'FAILURE' && (_jsx(Tooltip, __assign({ arrow: true, title: t('pages.node_upgrade.actions.repeat.tooltip') }, { children: _jsx("span", __assign({ style: { display: 'inline-block' } }, { children: _jsx(OGButton, __assign({ disabled: isRepeatDisabled, startIcon: _jsx(RedoIcon, {}), "aria-label": t('pages.node_upgrade.actions.repeat.label'), variant: "borderless", onClick: function () { return navigate(NEW_FIRMWARE_UPGRADE_ROUTE, { state: { copyRepeatData: __assign(__assign({}, copyRepeatData), { isRepeat: true }) } }); } }, { children: t('pages.node_upgrade.actions.repeat.label') })) })) })))] }));
}
