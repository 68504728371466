var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import SvgIcon from '@mui/material/SvgIcon';
export function OGReceptionHigh(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-1.9 -1.9 24 24' }, props), { children: _jsx("path", { d: "M9.1156 7.09998H12.1156V18.1156H9.1156V7.09998ZM3.1156 12.1156H6.1156V18.1156H3.1156V12.1156ZM15.1156 2.08435H18.1156V18.1156H15.1156V2.08435Z", fill: "currentColor" }) })));
}
