var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormActions, FormContainer, ResetButton, SubmitButton } from '@/components/layout/forms';
import { ImageFileField, ImageUrlField, MethodField, OptionsField } from './system-upgrade-form-controls';
var defaultValues = {
    method: 'url',
    imageFile: null,
    imageUrl: '',
    options: '',
};
export function SystemUpgradeForm(_a) {
    var onSubmit = _a.onSubmit, permissions = _a.permissions;
    var t = useTranslation().t;
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    var watchMethod = methods.watch('method');
    return (_jsxs(FormContainer, __assign({ methods: methods, onSubmit: onSubmit, mode: "update", permissions: permissions }, { children: [_jsxs(Box, __assign({ marginBottom: 2 }, { children: [_jsx(MethodField, {}), watchMethod === 'url' ? _jsx(ImageUrlField, {}) : _jsx(ImageFileField, {})] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 500, fontSize: 16 } }, { children: t('components.system_upgrade.sections.options.title') })), _jsx(OptionsField, {})] }), _jsxs(FormActions, { children: [_jsx(ResetButton, {}), _jsx(SubmitButton, { children: t('components.system_upgrade.form.actions.submit') })] })] })));
}
