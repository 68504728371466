var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { darkTheme } from './dark';
import { lightTheme } from './light';
import { getCookie } from '@/utils/browser';
var cache = createCache({
    key: 'lighthouse',
    nonce: getCookie('nonce'),
});
export var OGThemeMode;
(function (OGThemeMode) {
    OGThemeMode["Dark"] = "dark";
    OGThemeMode["Light"] = "light";
    OGThemeMode["System"] = "system";
})(OGThemeMode || (OGThemeMode = {}));
export var ThemeMode;
(function (ThemeMode) {
    ThemeMode["Dark"] = "dark";
    ThemeMode["Light"] = "light";
})(ThemeMode || (ThemeMode = {}));
export function getTheme(themeMode, prefersLightMode) {
    switch (themeMode) {
        case OGThemeMode.System:
            return prefersLightMode ? lightTheme : darkTheme;
        case OGThemeMode.Dark:
            return darkTheme;
        case OGThemeMode.Light:
        default:
            return lightTheme;
    }
}
export function useThemeMode() {
    var _a = __read(useLocalStorageState('theme.mode', { defaultValue: null }), 3), themeMode = _a[0], dispatch = _a[1], other = _a[2];
    return [themeMode !== null && themeMode !== void 0 ? themeMode : OGThemeMode.System, dispatch, other];
}
export function OGThemeProvider(_a) {
    var children = _a.children;
    var _b = __read(useThemeMode(), 1), themeMode = _b[0];
    var prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');
    return (_jsx(CacheProvider, __assign({ value: cache }, { children: _jsxs(MuiThemeProvider, __assign({ theme: getTheme(themeMode, prefersLightMode) }, { children: [_jsx(CssBaseline, {}), children] })) })));
}
