var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { FormContainer } from '@/components/layout/forms';
import { NameField, DescriptionField, RolesField, RestrictPortsField, PortsField } from './group-form-controls';
export var getDefaultGroupFormState = function () { return ({
    id: uuidV4(),
    groupname: '',
    description: '',
    roles: ['administrator'],
    restrict_ports: false,
    ports: '',
}); };
export function GroupForm(_a) {
    var onSubmit = _a.onSubmit, permissions = _a.permissions, defaultValues = _a.defaultValues, children = _a.children, mode = _a.mode, ariaLabelledBy = _a.ariaLabelledBy;
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    var _b = __read(methods.watch(['roles', 'restrict_ports']), 2), roles = _b[0], restrictPorts = _b[1];
    return (_jsxs(FormContainer, __assign({ mode: mode, methods: methods, onSubmit: onSubmit, permissions: permissions, ariaLabelledBy: ariaLabelledBy }, { children: [_jsxs(Stack, __assign({ sx: { px: 2, pb: 2 } }, { children: [_jsx(NameField, {}), _jsx(DescriptionField, {}), _jsx(RolesField, {}), roles.includes('pmoperator') && (_jsxs(Stack, __assign({ direction: "column", spacing: 2, marginTop: 2 }, { children: [_jsx(RestrictPortsField, {}), restrictPorts && _jsx(PortsField, {})] })))] })), _jsx(Divider, {}), children] })));
}
