var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import { useFormContainerContext } from '@/components/layout/forms';
import { AuthPasswordField, AuthProtocolButtons, EngineIDField, MessageTypeButtons, PrivacyPasswordField, PrivacyProtocolButtons, SecurityLevelButtons, UsernameField, } from './v3-settings-controls';
export function V3Settings() {
    var watch = useFormContainerContext().watch;
    var selectedMessageType = watch('msg_type');
    var selectedSecurityLevel = watch('security_level');
    return (_jsxs(_Fragment, { children: [_jsx(Stack, __assign({ marginBottom: 2 }, { children: _jsx(MessageTypeButtons, {}) })), selectedMessageType === 'TRAP' && _jsx(EngineIDField, {}), _jsx(Stack, __assign({ marginBottom: 2 }, { children: _jsx(SecurityLevelButtons, {}) })), _jsx(UsernameField, {}), selectedSecurityLevel !== 'noAuthNoPriv' && (_jsxs(_Fragment, { children: [_jsx(Stack, __assign({ marginBottom: 2 }, { children: _jsx(AuthProtocolButtons, {}) })), _jsx(AuthPasswordField, {})] })), selectedSecurityLevel === 'authPriv' && (_jsxs(_Fragment, { children: [_jsx(Stack, __assign({ marginBottom: 2 }, { children: _jsx(PrivacyProtocolButtons, {}) })), _jsx(PrivacyPasswordField, {})] }))] }));
}
