var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Panel from '@opengear/panel/panel';
import { DisabledTextField } from '@/components/inputs/disabled-text-field';
import { UpgradeDoneStatus, UpgradeProgress, UpgradeStatusIcon, UpgradeStatusText } from '../upgrade-tasks';
import { formatDateTimestamp } from '@/utils/datetime';
import { DATETIME_FORMAT } from '@/constants/formats';
export function NodeUpgradeTaskStatus(_a) {
    var nodeUpgrade = _a.nodeUpgrade;
    var t = useTranslation().t;
    return (_jsxs(Panel, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.node_upgrade.task_status.title') })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 1, marginBottom: 2 }, { children: [_jsx(UpgradeStatusIcon, { status: nodeUpgrade.status }), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 0.5, width: "300px" }, { children: [_jsx(UpgradeStatusText, { nodeUpgrade: nodeUpgrade }), _jsx(UpgradeProgress, { nodeUpgrade: nodeUpgrade }), _jsx(UpgradeDoneStatus, { nodeUpgrade: nodeUpgrade })] }))] })), _jsx(DisabledTextField, { id: "firmware", label: t('pages.node_upgrade.task_status.fields.firmware.label'), value: nodeUpgrade.firmware }), !nodeUpgrade.start_time && nodeUpgrade.scheduled_time && (_jsx(DisabledTextField, { id: "scheduled_time", label: t('pages.node_upgrade.task_status.fields.scheduled_time.label'), value: formatDateTimestamp(nodeUpgrade.scheduled_time, DATETIME_FORMAT) })), nodeUpgrade.start_time && (_jsx(DisabledTextField, { id: "start_time", label: t('pages.node_upgrade.task_status.fields.start_time.label'), value: formatDateTimestamp(nodeUpgrade.start_time, DATETIME_FORMAT) })), nodeUpgrade.finish_time && (_jsx(DisabledTextField, { id: "finish_time", label: t('pages.node_upgrade.task_status.fields.finish_time.label'), value: formatDateTimestamp(nodeUpgrade.finish_time, DATETIME_FORMAT) }))] }));
}
