var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { NOT_FOUND_ROUTE, SYSTEM_CONFIG_BACKUP_ROUTE, SYSTEM_CONFIG_RESET_ROUTE, SYSTEM_CONFIG_RESTORE_ROUTE, SYSTEM_CONFIG_ROUTE } from '@/constants/routes';
import { pathEndsWith } from '@/utils/navigation';
import { FactoryResetForm, ConfigRestoreForm, ConfigBackupForm } from '@/components/settings/system/system-config';
var TabValue;
(function (TabValue) {
    TabValue["BACKUP"] = "BACKUP";
    TabValue["RESTORE"] = "RESTORE";
    TabValue["RESET"] = "RESET";
})(TabValue || (TabValue = {}));
function SystemConfigSettings() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useDocTitle(t('pages.backup_restore_reset.title'));
    // Check if user has edit permissions based on ui permissions and dependant mode
    var _a = useUIPermissions(), hasPermission = _a.hasPermission, isLoadingPermissions = _a.isLoadingPermissions;
    var pagePermissions = {
        configBackupPermissions: {
            hasEditPermission: hasPermission('settings.system.backup.edit'),
            hasViewPermission: hasPermission('settings.system.backup.view'),
        },
        configRestorePermissions: {
            hasEditPermission: hasPermission('settings.system.restore.edit'),
            hasViewPermission: hasPermission('settings.system.restore.view'),
        },
        factoryResetPermissions: {
            hasEditPermission: hasPermission('settings.system.reset.edit'),
            hasViewPermission: hasPermission('settings.system.reset.view'),
        },
    };
    var tabValue;
    switch (true) {
        case pathEndsWith(pathname, SYSTEM_CONFIG_BACKUP_ROUTE):
            tabValue = TabValue.BACKUP;
            break;
        case pathEndsWith(pathname, SYSTEM_CONFIG_RESTORE_ROUTE):
            tabValue = TabValue.RESTORE;
            break;
        case pathEndsWith(pathname, SYSTEM_CONFIG_RESET_ROUTE):
            tabValue = TabValue.RESET;
            break;
        default:
            tabValue = TabValue.BACKUP;
            break;
    }
    useLayoutEffect(function () {
        if (pathEndsWith(pathname, SYSTEM_CONFIG_ROUTE)) {
            if (pagePermissions.configBackupPermissions.hasViewPermission) {
                navigate(SYSTEM_CONFIG_BACKUP_ROUTE.slice(1), { replace: true });
            }
            else if (pagePermissions.configRestorePermissions.hasViewPermission) {
                navigate(SYSTEM_CONFIG_RESTORE_ROUTE.slice(1), { replace: true });
            }
            else if (pagePermissions.factoryResetPermissions.hasViewPermission) {
                navigate(SYSTEM_CONFIG_RESET_ROUTE.slice(1), { replace: true });
            }
        }
        else if ((pathEndsWith(pathname, SYSTEM_CONFIG_BACKUP_ROUTE) && !pagePermissions.configBackupPermissions.hasViewPermission) ||
            (pathEndsWith(pathname, SYSTEM_CONFIG_RESTORE_ROUTE) && !pagePermissions.configRestorePermissions.hasViewPermission) ||
            (pathEndsWith(pathname, SYSTEM_CONFIG_RESET_ROUTE) && !pagePermissions.factoryResetPermissions.hasViewPermission)) {
            navigate(NOT_FOUND_ROUTE, { replace: true });
        }
    }, [
        pathname,
        navigate,
        pagePermissions.configBackupPermissions.hasViewPermission,
        pagePermissions.configRestorePermissions.hasViewPermission,
        pagePermissions.factoryResetPermissions.hasViewPermission,
    ]);
    var innerNode = null;
    if (tabValue === TabValue.BACKUP && pagePermissions.configBackupPermissions.hasViewPermission) {
        innerNode = _jsx(ConfigBackupForm, { permissions: pagePermissions.configBackupPermissions });
    }
    else if (tabValue === TabValue.RESTORE && pagePermissions.configRestorePermissions.hasViewPermission) {
        innerNode = _jsx(ConfigRestoreForm, { permissions: pagePermissions.configRestorePermissions });
    }
    else if (pagePermissions.factoryResetPermissions.hasViewPermission) {
        innerNode = _jsx(FactoryResetForm, { permissions: pagePermissions.factoryResetPermissions });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.backup_restore_reset.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: SYSTEM_CONFIG_BACKUP_ROUTE.slice(1), label: t('pages.backup_restore_reset.tabs.config_backup.title'), value: TabValue.BACKUP, disabled: isLoadingPermissions || !pagePermissions.configBackupPermissions.hasViewPermission }), _jsx(Tab, { component: RouterLink, to: SYSTEM_CONFIG_RESTORE_ROUTE.slice(1), label: t('pages.backup_restore_reset.tabs.config_restore.title'), value: TabValue.RESTORE, disabled: isLoadingPermissions || !pagePermissions.configRestorePermissions.hasViewPermission }), _jsx(Tab, { component: RouterLink, to: SYSTEM_CONFIG_RESET_ROUTE.slice(1), label: t('pages.backup_restore_reset.tabs.config_reset.title'), value: TabValue.RESET, disabled: isLoadingPermissions || !pagePermissions.factoryResetPermissions.hasViewPermission })] })), _jsx(TabPanel, __assign({ value: tabValue }, { children: innerNode }))] })) })] })));
}
export default withAuthenticationRequired(SystemConfigSettings, {
    rights: ['settings.system.backup.view', 'settings.system.restore.view', 'settings.system.reset.view'],
});
