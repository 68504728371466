var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGLinkButton } from '@opengear/links';
import format from 'string-format';
import Tooltip from '@mui/material/Tooltip';
import { OGWebTerminalIcon } from '@opengear/icons/action/web-terminal';
import { formatDevicePortTerminalRoute } from '@/constants/routes';
export function WebTerminalCell(_a) {
    var port = _a.port, tabIndex = _a.tabIndex, hasWebTerminalAccess = _a.hasWebTerminalAccess;
    var t = useTranslation().t;
    if (port.mode !== 'consoleServer' || !hasWebTerminalAccess) {
        return null;
    }
    var disabled = port.runtime_status.connection_status !== 'connected';
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: disabled ? t('global.ports.web_terminal_access_tooltip_disabled') : t('global.ports.web_terminal_access_tooltip') }, { children: _jsx("div", { children: _jsx(OGLinkButton, { "aria-label": format(t('global.ports.web_terminal_access'), port.port_csid), external: true, disabled: disabled, startIcon: _jsx(OGWebTerminalIcon, {}), sx: { paddingX: 0.5, paddingY: 0.5 }, tabIndex: tabIndex, to: "".concat(formatDevicePortTerminalRoute(port.node_id, port.port_csid)) }) }) })));
}
