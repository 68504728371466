var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useCallback } from 'react';
import { OGTable } from '@opengear/table';
import { OGLink } from '@opengear/links';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { usePaginationModel } from '@/hooks/pagination-model';
import { SyslogEditModal } from './syslog-modal';
import { useSyslogServers } from '@/api/services/syslog';
import { useQueryParameterOpen } from '@/hooks/open';
export var QUERY_PARAM_SYSLOG_SERVER_ID_KEY = 'syslog_id';
export var EDIT_SYSLOG_SERVER_MODAL_VALUE = 'edit_syslog_server';
export function SyslogTable() {
    var _a;
    var t = useTranslation().t;
    var _b = __read(useSearchParams(), 1), searchParams = _b[0];
    var _c = useSyslogServers(), syslogServersResponse = _c.data, isLoading = _c.isLoading;
    var _d = useMemo(function () { return ({
        loading: isLoading,
        rowCount: (syslogServersResponse === null || syslogServersResponse === void 0 ? void 0 : syslogServersResponse.length) || 0,
        rows: syslogServersResponse || [],
    }); }, [isLoading, syslogServersResponse]), loading = _d.loading, rowCount = _d.rowCount, rows = _d.rows;
    var modalSyslogId = (_a = searchParams.get(QUERY_PARAM_SYSLOG_SERVER_ID_KEY)) !== null && _a !== void 0 ? _a : '';
    var _e = useQueryParameterOpen(QUERY_PARAM_SYSLOG_SERVER_ID_KEY, modalSyslogId), open = _e.open, handleClose = _e.handleClose;
    var defaultSortModel = [{ field: 'name', sort: 'asc' }];
    var _f = __read(usePaginationModel('templates'), 3), paginationModel = _f[0], handlePaginationModelChange = _f[1], pageSizeOptions = _f[2];
    var renderNameCell = useCallback(function (params) {
        var _a;
        var value = params.value ? params.value : t('global.table.unknown_cell_value');
        return (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, target: value, to: "?".concat(new URLSearchParams((_a = {}, _a[QUERY_PARAM_SYSLOG_SERVER_ID_KEY] = params.id.toString(), _a))), variant: "standard" }, { children: value })));
    }, [t]);
    var columns = useMemo(function () { return [
        {
            field: 'address',
            flex: 3,
            headerName: t('pages.alerts_and_monitoring.tabs.syslog.table.table_column_header.server_address'),
            sortable: true,
            renderCell: renderNameCell,
            minWidth: 160,
        },
        {
            field: 'port',
            flex: 1,
            headerName: t('pages.alerts_and_monitoring.tabs.syslog.table.table_column_header.port'),
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'protocol',
            flex: 4,
            headerName: t('pages.alerts_and_monitoring.tabs.syslog.table.table_column_header.protocol'),
            sortable: true,
            minWidth: 110,
        },
    ]; }, [t, renderNameCell]);
    var ariaLabel = t('pages.alerts_and_monitoring.tabs.syslog.label');
    return (_jsxs(_Fragment, { children: [open && _jsx(SyslogEditModal, { open: open, handleClose: handleClose, syslogId: modalSyslogId }), _jsx(OGTable, { "aria-label": ariaLabel, autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.alerts_and_monitoring.tabs.syslog.table.no_row_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "client", paginationModel: paginationModel, rowCount: rowCount, onPaginationModelChange: handlePaginationModelChange, initialState: {
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                } })] }));
}
