var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { decodeCreateSmfResourceResponse, decodeReadSmfResourcesResponse } from '../../../types/lighthouse/decoders/smf-resources';
import { decodeDeleteSmfResourceResponse, decodeReadSmfResourceResponse, decodeUpdateSmfResourceResponse, } from '../../../types/lighthouse/decoders/smf-resources/resource-id';
import { RESOURCE_TAGS_NAMES_PATH } from '@/api/tags/resource-tags/names';
import { decodeSmfResourcesBulkRemoveResponse } from '../../../types/lighthouse/decoders/smf-resources/bulk';
import { decodeSmfResourcesWarningsResponse } from '../../../types/lighthouse/decoders/smf-resources/warnings';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var SMF_RESOURCES_PATH = '/smf_resources';
export var SMF_RESOURCES_BULK_PATH = "".concat(SMF_RESOURCES_PATH, "/bulk");
export var SMF_RESOURCES_WARNINGS_PATH = "".concat(SMF_RESOURCES_PATH, "/warnings");
export function formatSmfResourcePath(smfResourceId) {
    if (smfResourceId === void 0) { smfResourceId = ':smfResourceId'; }
    return "".concat(SMF_RESOURCES_PATH, "/").concat(smfResourceId);
}
/**
 * Hook to fetch list of smf resources.
 */
export function useSmfResources(queryParams) {
    var _this = this;
    if (queryParams === void 0) { queryParams = {}; }
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    var fetchSmfResources = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(SMF_RESOURCES_PATH, __assign({ queryParams: queryParams }, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadSmfResourcesResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.smf_resources.get'));
                    if (status === 200) {
                        data.smart_management_fabric_resources.forEach(function (smfResource) {
                            queryClient.setQueryData([SMF_RESOURCES_PATH, smfResource.id], smfResource);
                        });
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, queryParams, queryClient, t]);
    return useQuery({
        queryKey: [SMF_RESOURCES_PATH, queryParams],
        queryFn: fetchSmfResources,
    });
}
/**
 * Hook to create an smf resource.
 */
export function useCreateSmfResource() {
    var _this = this;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    return useCallback(function (smfResource) { return __awaiter(_this, void 0, void 0, function () {
        var request, response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        smart_management_fabric_resource: smfResource,
                    };
                    return [4 /*yield*/, executeFetch(SMF_RESOURCES_PATH, { method: 'POST', body: JSON.stringify(request) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeCreateSmfResourceResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.smf_resources.post'));
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [SMF_RESOURCES_PATH],
                        })
                            .catch(console.error);
                        queryClient.setQueryData([SMF_RESOURCES_PATH, data.smart_management_fabric_resource.id], data.smart_management_fabric_resource);
                        // Revalidate resource tag names
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_NAMES_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to update an smf resource.
 */
export function useUpdateSmfResource() {
    var _this = this;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    return useCallback(function (smfResourceId, smfResource) { return __awaiter(_this, void 0, void 0, function () {
        var request, response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    request = {
                        smart_management_fabric_resource: smfResource,
                    };
                    return [4 /*yield*/, executeFetch(formatSmfResourcePath(smfResourceId), { method: 'PUT', body: JSON.stringify(request) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdateSmfResourceResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.smf_resources.put'));
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [SMF_RESOURCES_PATH],
                        })
                            .catch(console.error);
                        queryClient.setQueryData([SMF_RESOURCES_PATH, data.smart_management_fabric_resource.id], data.smart_management_fabric_resource);
                        // Revalidate resource tag names
                        queryClient
                            .invalidateQueries({
                            queryKey: [RESOURCE_TAGS_NAMES_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to delete a single smf resources by id.
 */
export function useDeleteSmfResource() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (smfResourceId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatSmfResourcePath(smfResourceId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteSmfResourceResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.smf_resources.delete'));
                    if (status === 204) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [SMF_RESOURCES_PATH],
                        })
                            .catch(console.error);
                        queryClient.removeQueries({
                            queryKey: [SMF_RESOURCES_PATH, smfResourceId],
                        });
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to delete multiple smf resources by an array of resource id's.
 */
export function useBulkDeleteSmfResources() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    return useCallback(function (smfResourceIds) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(SMF_RESOURCES_BULK_PATH, {
                        method: 'DELETE',
                        body: JSON.stringify({ smart_management_fabric_resources: smfResourceIds }),
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeSmfResourcesBulkRemoveResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = __assign(__assign({}, t('api.smf_resources_bulk.delete')), { ok: {
                            header: t('api.smf_resources_bulk.delete.ok.header'),
                            message: format(t('api.smf_resources_bulk.delete.ok.message'), smfResourceIds.length.toString(), smfResourceIds.length > 1 ? 's' : ''),
                        } });
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 204) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [SMF_RESOURCES_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, queryClient, t]);
}
/**
 * Hook to fetch a single smf resource by Id.
 */
export function useSmfResource(smfResourceId) {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchSmfResource = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatSmfResourcePath(smfResourceId), __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadSmfResourceResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = __assign(__assign({}, t('api.smf_resources.get')), { not_found: { message: format(t('api.smf_resources.get.not_found.message'), smfResourceId) }, error: { message: format(t('api.smf_resources.get.error.message'), smfResourceId) } });
                    handleCommonStatusCodes(data, status, translations);
                    return [2 /*return*/, status === 200 ? data.smart_management_fabric_resource : null];
            }
        });
    }); }, [executeFetch, decodeResponse, smfResourceId, handleCommonStatusCodes, t]);
    return useQuery({
        queryKey: [SMF_RESOURCES_PATH, smfResourceId],
        queryFn: fetchSmfResource,
    });
}
/**
 * Hook to fetch list of smf resource warnings.
 */
export function useSmfResourceWarnings() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchSmfResourceWarnings = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(SMF_RESOURCES_WARNINGS_PATH, __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeSmfResourcesWarningsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.smf_resources_warnings.get'));
                    return [2 /*return*/, status === 200 ? data : null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, t]);
    return useQuery({
        queryKey: [SMF_RESOURCES_WARNINGS_PATH],
        queryFn: fetchSmfResourceWarnings,
    });
}
