var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OGButtonLink } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { ReorderCell } from '@/components/reorder-cell';
import { useFormContainerContext } from '@/components/layout/forms';
export function ExternalNetworkTable(_a) {
    var onRowClick = _a.onRowClick;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions, watch = _b.watch, clearErrors = _b.clearErrors;
    var move = useFieldArray({
        control: control,
        name: 'endpoints',
        rules: {
            validate: function (endpoints) {
                var uniqueAddresses = new Set(endpoints.map(function (endpoint) { return endpoint.address; }));
                if (uniqueAddresses.size !== endpoints.length) {
                    return t('pages.network_settings.external_address.form.fields.address.errors.unique');
                }
                clearErrors('endpoints');
                return undefined;
            },
        },
    }).move;
    var endpoints = watch('endpoints');
    var renderAddressCell = useCallback(function (_a) {
        var row = _a.row, value = _a.value, tabIndex = _a.tabIndex;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                onRowClick(row);
            }, propagateSpacebar: false }, { children: value })));
    }, [onRowClick]);
    var columns = [
        {
            field: '__reorder__',
            cellClassName: 'nowrap',
            sortable: false,
            renderHeader: function () { return (_jsx("span", __assign({ role: "img", "aria-label": t('pages.network_settings.external_address.table.columns.order.label') }, { children: _jsx(SwapVertIcon, {}) }))); },
            renderCell: function (_a) {
                var row = _a.row, api = _a.api, tabIndex = _a.tabIndex;
                return (_jsx(ReorderCell, { labelName: row.address, index: api.getRowIndexRelativeToVisibleRows(row.id), tabIndex: tabIndex, rowCount: endpoints.length, onMove: move, translation: t('pages.network_settings.external_address.table.columns.order.row_label') }));
            },
        },
        {
            field: 'address',
            headerName: t('pages.network_settings.external_address.table.columns.address.label'),
            flex: 2,
            sortable: false,
            minWidth: 275,
            renderCell: renderAddressCell,
        },
        {
            field: 'api_port',
            headerName: t('pages.network_settings.external_address.table.columns.api_port.label'),
            flex: 1,
            minWidth: 80,
            sortable: false,
        },
        {
            field: 'vpn_port',
            headerName: t('pages.network_settings.external_address.table.columns.vpn_port.label'),
            flex: 1,
            minWidth: 80,
            sortable: false,
        },
        {
            field: 'mi_vpn_port',
            headerName: t('pages.network_settings.external_address.table.columns.mi_vpn_port.label'),
            flex: 1,
            minWidth: 200,
            sortable: false,
        },
    ];
    return (_jsx(OGTable, { "aria-describedby": "NetworkSettings-table-description", "aria-label": t('pages.network_settings.external_address.table.label'), rowReordering: permissions.hasEditPermission, autoHeight: true, rows: endpoints, columns: columns, sx: {
            '& .nowrap': {
                overflowWrap: 'normal',
                whiteSpace: 'nowrap',
            },
        } }));
}
