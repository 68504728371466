import { useLayoutEffect } from 'react';
export function useBeforeUnload(enabled, callback) {
    if (enabled === void 0) { enabled = false; }
    useLayoutEffect(function () {
        function beforeUnloadListener(event) {
            event.preventDefault();
            callback === null || callback === void 0 ? void 0 : callback();
            // Return statement for browser backwards compatibility
            // eslint-disable-next-line no-param-reassign,no-return-assign
            return (event.returnValue = '');
        }
        if (enabled) {
            window.addEventListener('beforeunload', beforeUnloadListener);
        }
        else {
            window.removeEventListener('beforeunload', beforeUnloadListener);
        }
        return function () {
            window.removeEventListener('beforeunload', beforeUnloadListener);
        };
    }, [callback, enabled]);
}
