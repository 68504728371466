var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useDeviceView } from '@/hooks/use-device-view';
import { DATETIME_FORMAT } from '@/constants/formats';
export function CurrentUTCTime() {
    var theme = useTheme();
    var isLaptopView = useDeviceView().isLaptopView;
    var t = useTranslation().t;
    return (_jsxs(Box, __assign({ sx: {
            width: isLaptopView ? '100%' : '250px',
            marginTop: isLaptopView ? 2 : 0,
            padding: 2,
            backgroundColor: theme.palette.background.box,
            alignSelf: 'flex-start',
            lineHeight: 2,
        } }, { children: [_jsx(Typography, __assign({ marginBottom: 1 }, { children: t('pages.firmware_upgrade_new.steps.schedule.current_time.label') })), _jsx(Typography, { children: dayjs().utc().format(DATETIME_FORMAT) })] })));
}
