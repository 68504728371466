var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { OG_BLACK, OG_WHITE, OG_BUTTON_PRIMARY, OG_GREY_VVVL } from '@opengear/theme/colors';
import { OGNavMenuItem } from './nav-menu-item';
export function OGNavMainMenuLink(_a) {
    var children = _a.children, _b = _a.level, level = _b === void 0 ? 0 : _b, sx = _a.sx, _c = _a.tabIndex, tabIndex = _c === void 0 ? 0 : _c, to = _a.to, icon = _a.icon, props = __rest(_a, ["children", "level", "sx", "tabIndex", "to", "icon"]);
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var theme = useTheme();
    var backgroundColor = theme.palette.mode === 'light' ? OG_WHITE : OG_BLACK;
    var linkColor = theme.palette.mode === 'light' && level === 0 ? OG_GREY_VVVL : 'inherit';
    var handleKeyDown = useCallback(function (event) {
        if (event.code === 'Enter') {
            navigate(to);
        }
    }, [navigate, to]);
    // move menu padding into link child so that the context menu is a link context menu for the whole menu element
    return (_jsx(OGNavMenuItem, __assign({ selected: pathname.startsWith(to.toString()), level: level, sx: __assign({ 'minWidth': 'unset', 'padding': 0, 'marginY': theme.spacing(0.25), 'marginLeft': level === 0 ? 0 : theme.spacing(2), 'backgroundColor': backgroundColor, 'textWrap': 'wrap', '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus-visible': {
                backgroundColor: level === 0 ? OG_BUTTON_PRIMARY : backgroundColor,
                color: linkColor,
            } }, sx), tabIndex: tabIndex }, props, { onKeyDown: handleKeyDown }, { children: _jsxs(MuiLink, __assign({ component: RouterLink, sx: {
                'color': 'inherit',
                'fontWeight': 'inherit',
                'lineHeight': '25px',
                'paddingY': 0.75,
                'paddingLeft': (icon ? 1 : 2) + level,
                'paddingRight': 3 - level,
                'textDecoration': 'none',
                'width': '100%',
                ':active, :focus-visible, :hover': {
                    color: 'inherit',
                    border: 'none',
                    outline: 'inherit',
                    textDecoration: 'none',
                },
            }, 
            // let menu parent handle the tab index
            tabIndex: -1, to: to }, { children: [icon && _jsx(Box, __assign({ sx: { marginRight: 1 } }, { children: icon })), children] })) })));
}
