var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { OGButtonLink } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { OGTable } from '@opengear/table';
import format from 'string-format';
import { useNodeTags } from '@/api/tags/node-tags';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { EditNodeTagModalContent, EDIT_NODE_TAG_MODAL_TITLE_ID } from '@/containers/tags/node-tags/node-tag-modal';
export function NodeTagsTable(_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var _b = __read(useState(undefined), 2), selectedNodeTag = _b[0], setSelectedNodeTag = _b[1];
    var editNodeTagModalOpen = !!selectedNodeTag;
    var handleEditNodeTagModalOpen = useCallback(function (nodeTag) {
        setSelectedNodeTag(nodeTag);
    }, []);
    var handleEditNodeTagModalClose = useCallback(function () {
        setSelectedNodeTag(undefined);
    }, []);
    var _c = __read(usePaginationModel('node_tags'), 3), paginationModel = _c[0], handlePaginationModelChange = _c[1], pageSizeOptions = _c[2];
    var _d = __read(useSortModel('name'), 3), defaultSortModel = _d[0], sortQueryParams = _d[1], handleSortModelChange = _d[2];
    var queryParams = useMemo(function () { return (__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize }, sortQueryParams)); }, [paginationModel, sortQueryParams]);
    var _e = useNodeTags(queryParams), data = _e.data, isLoading = _e.isLoading;
    var _f = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.nodeTags) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.nodeTags, isLoading]), loading = _f.loading, rowCount = _f.rowCount, rows = _f.rows;
    var renderNameCell = useCallback(function (params) {
        if (permissions.hasTagEditAccess) {
            return (_jsx(OGButtonLink, __assign({ tabIndex: params.tabIndex, variant: "standard", "aria-haspopup": "dialog", "aria-expanded": selectedNodeTag !== undefined, "aria-label": params.value && format(t('pages.tags.tabs.node_tags.table.edit_label'), params.value), onClick: function () {
                    handleEditNodeTagModalOpen(params.row);
                }, propagateSpacebar: false }, { children: params.value })));
        }
        return params.value;
    }, [handleEditNodeTagModalOpen, permissions.hasTagEditAccess, selectedNodeTag, t]);
    var columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('pages.tags.tabs.node_tags.table.name'),
            sortable: true,
            minWidth: 90,
            renderCell: renderNameCell,
        },
        {
            field: 'values',
            headerName: t('pages.tags.tabs.node_tags.table.value'),
            flex: 2,
            sortable: false,
            minWidth: 130,
            renderCell: function (_a) {
                var row = _a.row;
                return (_jsx(Box, { children: row.values.map(function (tag) { return (_jsx(Chip, { label: tag.value, sx: { width: 'max-content', marginBottom: 1, marginRight: 1 } }, tag.id)); }) }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(OGDialog, __assign({ "aria-labelledby": EDIT_NODE_TAG_MODAL_TITLE_ID, open: editNodeTagModalOpen, onClose: handleEditNodeTagModalClose }, { children: selectedNodeTag ? _jsx(EditNodeTagModalContent, { nodeTag: selectedNodeTag, onClose: handleEditNodeTagModalClose, permissions: permissions }) : null })), _jsx(OGTable, { "aria-label": t('pages.tags.tabs.node_tags.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('pages.tags.tabs.node_tags.table.no_row_message'), loading: loading, pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, rowCount: rowCount, onPaginationModelChange: handlePaginationModelChange, onSortModelChange: handleSortModelChange, initialState: {
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                } })] }));
}
