var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';
export function OGPortConfiguredIcon(props) {
    var t = useTranslation().t;
    return (_jsxs(SvgIcon, __assign({ titleAccess: t('global.ports.configured') }, __assign(__assign({ viewBox: '0 0 20 18' }, props), { color: 'success' }), { children: [_jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M11.8 15.9H8.2C7.72261 15.9 7.26477 15.7104 6.92721 15.3728C6.58964 15.0352 6.4 14.5774 6.4 14.1V12.3H2.8C2.32261 12.3 1.86477 12.1104 1.52721 11.7728C1.18964 11.4352 1 10.9774 1 10.5V3.3C1 2.82261 1.18964 2.36477 1.52721 2.02721C1.86477 1.68964 2.32261 1.5 2.8 1.5H17.2C17.6774 1.5 18.1352 1.68964 18.4728 2.02721C18.8104 2.36477 19 2.82261 19 3.3V10.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M10 1.5V6", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M5.5 1.5V6", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.5 1.5V6", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.1937 14.0704L17.2359 11.0282L16.7606 10.537L14.1937 13.1039L12.9894 11.8996L12.5141 12.375L14.1937 14.0704ZM12.4824 9.99824C13.1479 9.33275 13.9454 9 14.875 9C15.8046 9 16.5968 9.33275 17.2518 9.99824C17.9173 10.6532 18.25 11.4454 18.25 12.375C18.25 13.3046 17.9173 14.1021 17.2518 14.7676C16.5968 15.4225 15.8046 15.75 14.875 15.75C13.9454 15.75 13.1479 15.4225 12.4824 14.7676C11.8275 14.1021 11.5 13.3046 11.5 12.375C11.5 11.4454 11.8275 10.6532 12.4824 9.99824Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
