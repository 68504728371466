var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
import { OGLinkButton } from '@opengear/links';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { OG_OFF_WHITE, OG_BLACK_90, OG_FOCUS_BOX_SHADOW } from '@opengear/theme/colors';
import { usePortLogs } from '@/api/nodes/port-logs';
import { formatDateString } from '@/utils/datetime';
import { QUERY_PARAM_LOGS_PORT_ID_KEY, QUERY_PARAM_LOGS_FROM_DATE_KEY, QUERY_PARAM_LOGS_TO_DATE_KEY, QUERY_PARAM_LOGS_CONTAINS_KEY, } from '@/constants/query-params-keys';
import { formatNodeDetailsRoute, PORT_LOGS_ROUTE } from '@/constants/routes';
import { PortLogsFilter } from './port-logs-filter';
import { DATETIME_FORMAT_SHORT } from '@/constants/formats';
var defaultQueryParams = {
    per_page: 100,
};
export function NodeLogs(_a) {
    var node = _a.node, _b = _a.isFullScreen, isFullScreen = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var theme = useTheme();
    var _c = __read(useInView({ threshold: 1 }), 2), ref = _c[0], inView = _c[1];
    var _d = __read(useSearchParams(), 1), searchParams = _d[0];
    // Get the info for each port on the node
    var portsInfo = useMemo(function () {
        var _portInfo = {};
        node.ports.forEach(function (port) {
            _portInfo[port.id] = { csid: port.port_csid, label: port.label };
        });
        return _portInfo;
    }, [node.ports]);
    var queryParamPortId = searchParams.get(QUERY_PARAM_LOGS_PORT_ID_KEY);
    var queryParamFromDate = searchParams.get(QUERY_PARAM_LOGS_FROM_DATE_KEY);
    var queryParamToDate = searchParams.get(QUERY_PARAM_LOGS_TO_DATE_KEY);
    var queryParamContents = searchParams.get(QUERY_PARAM_LOGS_CONTAINS_KEY);
    var queryParams = useMemo(function () { return ({
        per_page: defaultQueryParams.per_page,
        port_id: queryParamPortId && queryParamPortId in portsInfo ? queryParamPortId : undefined,
        from_date: queryParamFromDate !== null && queryParamFromDate !== void 0 ? queryParamFromDate : undefined,
        to_date: queryParamToDate !== null && queryParamToDate !== void 0 ? queryParamToDate : undefined,
        contains: queryParamContents !== null && queryParamContents !== void 0 ? queryParamContents : undefined,
    }); }, [portsInfo, queryParamContents, queryParamFromDate, queryParamPortId, queryParamToDate]);
    var _e = usePortLogs(node.id, queryParams, {
        getNextPageParam: function (lastPage, allPages) { return ((lastPage === null || lastPage === void 0 ? void 0 : lastPage.meta.total_pages) && lastPage.meta.total_pages > allPages.length ? allPages.length + 1 : undefined); },
    }), data = _e.data, isLoading = _e.isLoading, hasNextPage = _e.hasNextPage, fetchNextPage = _e.fetchNextPage;
    useEffect(function () {
        if (inView) {
            fetchNextPage();
        }
    }, [fetchNextPage, inView]);
    var logs = useMemo(function () {
        var _logs = [];
        data === null || data === void 0 ? void 0 : data.pages.forEach(function (pageItem) {
            return pageItem === null || pageItem === void 0 ? void 0 : pageItem.port_logs.forEach(function (log) {
                var message = log.message
                    .split(/ (?=(?:TXDATA|RXDATA|LOGIN|LOGOUT): )/)
                    .slice(1)
                    .join(' ');
                var nodeTime = formatDateString(log.node_time, DATETIME_FORMAT_SHORT);
                var portCsid = portsInfo[log.port_id].csid;
                var portLabel = portsInfo[log.port_id].label;
                _logs.push({ id: log.id, content: "".concat(nodeTime, " - ").concat(portLabel, " (").concat(portCsid, ") - ").concat(message) });
            });
        });
        return _logs;
    }, [data, portsInfo]);
    var csv = useMemo(function () {
        // Only visible logs as CSV
        var _csv = 'id,port_id,node_time,lighthouse_time,message\n';
        data === null || data === void 0 ? void 0 : data.pages.forEach(function (pageItem) {
            return pageItem === null || pageItem === void 0 ? void 0 : pageItem.port_logs.forEach(function (log) {
                _csv += "".concat(log.id, ",").concat(log.port_id, ",").concat(log.node_time, ",").concat(log.lighthouse_time, ",").concat(log.message, "\n ");
            });
        });
        return "data:text/plain;base64,".concat(window.btoa(_csv));
    }, [data]);
    var newWindowqueryParam = useMemo(function () {
        var _a;
        var _b, _c, _d, _e;
        return new URLSearchParams((_a = {},
            _a[QUERY_PARAM_LOGS_PORT_ID_KEY] = (_b = queryParams.port_id) !== null && _b !== void 0 ? _b : '',
            _a[QUERY_PARAM_LOGS_FROM_DATE_KEY] = (_c = queryParams.from_date) !== null && _c !== void 0 ? _c : '',
            _a[QUERY_PARAM_LOGS_TO_DATE_KEY] = (_d = queryParams.to_date) !== null && _d !== void 0 ? _d : '',
            _a[QUERY_PARAM_LOGS_CONTAINS_KEY] = (_e = queryParams.contains) !== null && _e !== void 0 ? _e : '',
            _a))
            .toString()
            .replace(/(?:&|^)[^&]*?=(?=&|$)/g, '');
    }, [queryParams]);
    var handleOpenNewWindow = function () {
        window.open("".concat(formatNodeDetailsRoute(node.id)).concat(PORT_LOGS_ROUTE).concat(newWindowqueryParam && "?".concat(newWindowqueryParam)), 'window', 'toolbar=no,menubar=no,width=1920,height=1080');
    };
    return (_jsxs(Stack, { children: [_jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", sx: { marginBottom: 1 } }, { children: [_jsx(PortLogsFilter, { portInfo: portsInfo }), _jsxs(Box, __assign({ sx: { display: 'flex', width: '30%', maxWidth: 'max-content', maxHeight: '80px', flexWrap: 'wrap', justifyContent: 'flex-start' } }, { children: [_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.node.logs.buttons.download') }, { children: _jsx("div", { children: _jsx(OGLinkButton, { "aria-label": t('pages.node.logs.buttons.download'), size: "small", disabled: !logs.length, sx: { '&.MuiButton-root': { height: '31px', margin: '2px' } }, startIcon: _jsx(FileDownloadIcon, {}), variant: "borderless", external: true, to: logs.length ? csv : '#', download: logs.length ? "".concat(node.id, "-logs.csv") : '' }) }) })), !isFullScreen && (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.node.logs.buttons.open_in_new_window') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('pages.node.logs.buttons.open_in_new_window'), size: "small", sx: { '&.MuiButton-root': { height: '31px' } }, startIcon: _jsx(OpenInNewIcon, {}), variant: "borderless", onClick: handleOpenNewWindow }) }) })))] }))] })), _jsxs(List, __assign({ "aria-label": t('pages.node.logs.label'), 
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex: 0, sx: {
                    'padding': 2,
                    'height': !isFullScreen ? '72vh' : 'calc(100vh - 56px - 75px)',
                    'fontSize': '1.5rem',
                    'overflow': 'auto',
                    'backgroundColor': theme.palette.mode === 'dark' ? OG_BLACK_90 : OG_OFF_WHITE,
                    '&:focus-visible': { outline: 'none', boxShadow: OG_FOCUS_BOX_SHADOW },
                } }, { children: [!!logs.length &&
                        logs.map(function (log, index) { return (_jsx(ListItem, __assign({ ref: index === logs.length - 1 ? ref : null, sx: { padding: 0, marginBottom: 2 } }, { children: _jsx("span", __assign({ style: { wordBreak: 'break-all' } }, { children: log.content })) }), log.id)); }), !isLoading && !!logs.length && !hasNextPage && _jsx(ListItem, __assign({ sx: { padding: 0 } }, { children: t('pages.node.logs.end_logs') })), !isLoading && !logs.length && _jsx(ListItem, __assign({ sx: { padding: 0 } }, { children: t('pages.node.logs.no_logs') })), isLoading && _jsx(ListItem, __assign({ sx: { padding: 0 } }, { children: t('pages.node.logs.loading') }))] }))] }));
}
