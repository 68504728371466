var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGButton } from '@opengear/buttons';
import Box from '@mui/material/Box';
import { Controller, useFormContext } from 'react-hook-form';
import { forwardRef } from 'react';
import format from 'string-format';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { OGTagIcon } from '@opengear/icons/generic/tag';
import { useTranslation } from 'react-i18next';
import { useFormContainerContext } from '../layout/forms/container-context';
// Create new tags input
export function CreateNewTagInput(_a) {
    var index = _a.index, children = _a.children;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, watch = _b.watch;
    var assignedTags = watch('assignedTags');
    var unassignedTags = watch('unassignedTags');
    var otherTagsSelected = watch('newSelectedOrCreatedTagsFields');
    return (_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, mobile: 11 }, { children: _jsx(Controller, { control: control, name: "newSelectedOrCreatedTagsFields.".concat(index, ".newResourceTagName"), rules: {
                        required: t('components.assign_tag_modal.create_tag.error.required'),
                        maxLength: {
                            value: 128,
                            message: t('components.assign_tag_modal.create_tag.error.max_length'),
                        },
                        validate: {
                            isUnique: function (value) {
                                var nameExistsInAssignedTags = assignedTags.some(function (tag) { return tag.resource_tag_name === value; });
                                var nameExistsInUnassignedTags = unassignedTags.some(function (tag) { return tag.resource_tag_name === value; });
                                var nameAlreadyEntered = otherTagsSelected.some(function (tag, idx) { return tag.newResourceTagName === value && idx !== index; });
                                return ((!nameAlreadyEntered && !nameExistsInAssignedTags && !nameExistsInUnassignedTags) ||
                                    t('components.assign_tag_modal.create_tag.error.tag_exists'));
                            },
                        },
                    }, render: function (_a) {
                        var _b;
                        var fieldState = _a.fieldState, _c = _a.field, ref = _c.ref, field = __rest(_c, ["ref"]);
                        return (_jsx(TextField, __assign({ inputRef: ref }, field, { id: "createdTags-name-".concat(index), placeholder: t('components.assign_tag_modal.create_tag.placeholder'), fullWidth: true, required: true, label: t('components.assign_tag_modal.create_tag.visual_label'), error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, margin: "none", 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, value: field.value, inputProps: {
                                'aria-label': t('components.assign_tag_modal.create_tag.visual_label'),
                            } })));
                    } }) })), _jsx(Grid, __assign({ item: true, mobile: 1, pl: 0, ml: 0 }, { children: children }))] })));
}
// Select existing tags to assign them
export function AssignExistingTagsInput(_a) {
    var index = _a.index, children = _a.children;
    var t = useTranslation().t;
    var _b = useFormContext(), control = _b.control, watch = _b.watch, setValue = _b.setValue;
    // check so user can't select same tag twice
    var unassignedTags = watch('unassignedTags');
    var otherTagsSelected = watch('newSelectedOrCreatedTagsFields');
    var selectedTagIds = otherTagsSelected.map(function (tag) { return tag.resourceTagId; });
    return (_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, mobile: 11 }, { children: _jsx(Controller, { control: control, name: "newSelectedOrCreatedTagsFields.".concat(index, ".newResourceTagName"), rules: { required: t('components.assign_tag_modal.existing_tag.required') }, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]), error = _a.fieldState.error;
                        return (_jsx(TextField, __assign({ inputRef: ref }, field, { onChange: function (event) {
                                var unassignedTag = unassignedTags.find(function (tag) { return tag.resource_tag_name === event.target.value; });
                                if (unassignedTag) {
                                    // useFieldArray only (officially) supports primitive values, however we are using objects in this form.
                                    // The field is subscribed to the resourceTagId, so we additionally need to set the resourceTagName
                                    // value to ensure the field is updated correctly.
                                    setValue("newSelectedOrCreatedTagsFields.".concat(index, ".resourceTagId"), unassignedTag.id);
                                    field.onChange(unassignedTag.resource_tag_name);
                                }
                            }, 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, select: true, fullWidth: true, required: true, label: t('components.assign_tag_modal.existing_tag.visual_label'), "data-testid": "newSelectedTags-field-".concat(index, "-combobox"), id: "newSelectedTags-field-".concat(index), error: !!error, helperText: error ? error.message : null, SelectProps: {
                                displayEmpty: true,
                                renderValue: function (selected) { return (_jsx(Typography, __assign({ style: { maxWidth: '422px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }, { children: selected ? selected.toString() : t('components.assign_tag_modal.existing_tag.placeholder') }))); },
                            }, style: { width: '100%', maxWidth: '100%', marginBottom: '0px' } }, { children: unassignedTags.length > 0 ? (unassignedTags.map(function (tag) { return (_jsx(MenuItem, __assign({ value: tag.resource_tag_name, disabled: selectedTagIds.includes(tag.id) }, { children: tag.resource_tag_name }), tag.id)); })) : (_jsx(MenuItem, __assign({ disabled: true, value: "" }, { children: t('components.assign_tag_modal.existing_tag.no_tags_available') }))) })));
                    } }) })), _jsx(Grid, __assign({ item: true, mobile: 1, pl: 0, ml: 0 }, { children: children }))] })));
}
// Existing tags that are already linked- top of the form with count
export function AssignedTagsInput(_a) {
    var hasCount = _a.hasCount, index = _a.index, children = _a.children;
    var theme = useTheme();
    var t = useTranslation().t;
    var control = useFormContainerContext().control;
    return (_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, mobile: hasCount ? 9 : 11 }, { children: _jsx(Controller, { control: control, name: "assignedTags.".concat(index, ".resource_tag_name"), render: function (_a) {
                        var _b = _a.field, ref = _b.ref, field = __rest(_b, ["ref"]);
                        return (_jsx(TextField, __assign({ inputRef: ref }, field, { id: "assigned-tag-".concat(index), fullWidth: true, margin: "none", label: t('components.assign_tag_modal.assigned_tag.visual_label'), inputProps: {
                                readOnly: true,
                                style: {
                                    backgroundColor: theme.palette.action.disabledBackground,
                                },
                            } })));
                    } }) })), _jsx(Grid, __assign({ item: true, mobile: hasCount ? 3 : 1 }, { children: _jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: children })) }))] })));
}
export function AssignedTagsCount(_a) {
    var nodePorts = _a.nodePorts;
    var t = useTranslation().t;
    var formattedNodePorts = nodePorts.join(', ');
    var message = nodePorts && nodePorts.length > 1
        ? format(t('components.assign_tag_modal.assigned_tag.ports_count'), String(nodePorts.length))
        : format(t('components.assign_tag_modal.assigned_tag.port_count'), String(nodePorts.length));
    return (_jsx(Tooltip, __assign({ describeChild: true, title: formattedNodePorts, placement: "top", arrow: true }, { children: _jsx(Typography, __assign({ tabIndex: 0, component: "span", noWrap: true, sx: {
                fontSize: '12px',
                textDecoration: 'underline dotted',
                textDecorationThickness: '1px',
                display: 'inline-block',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginTop: '22px',
            } }, { children: message })) })));
}
//  FORM BUTTONS
export function AssignTagFormCreateButton(_a) {
    var props = __rest(_a, []);
    var t = useTranslation().t;
    return (_jsx(OGButton, __assign({ variant: "borderless", startIcon: _jsx(AddCircleOutline, { fontSize: "small" }), "aria-label": t('components.assign_tag_modal.buttons.create_new_tag') }, props, { children: t('components.assign_tag_modal.buttons.create_new_tag') })));
}
export var AssignTagFormExistingButton = forwardRef(function (props, ref) {
    var t = useTranslation().t;
    return (_jsx(OGButton
    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
    // eslint-disable-next-line jsx-a11y/no-autofocus
    , __assign({ 
        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus: props.autoFocus, ref: ref, variant: "secondary", startIcon: _jsx(OGTagIcon, { fontSize: "small" }), "aria-label": t('components.assign_tag_modal.buttons.select_existing_tag') }, props, { children: t('components.assign_tag_modal.buttons.select_existing_tag') })));
});
export function RemoveTagButton(_a) {
    var props = __rest(_a, []);
    var t = useTranslation().t;
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('components.assign_tag_modal.buttons.remove_tag') }, { children: _jsx(Box, __assign({ sx: { marginTop: '22px' } }, { children: _jsx(OGButton, __assign({ "aria-label": t('components.assign_tag_modal.buttons.remove_tag'), size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless" }, props)) })) })));
}
