var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useAlternateApiSettings } from '@/api/system/alternate-api';
import { useCliSessionTimeout } from '@/api/system/cli-session-timeout';
import { useWebuiSessionTimeout } from '@/api/system/webui-session-timeout';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { SessionSettingsForm } from '@/components/settings/system/session-settings/session-settings-form';
export var SESSION_SETTINGS_LOADER_ID = "session-settings-loading";
function SessionSettings() {
    var t = useTranslation().t;
    useDocTitle(t('pages.session_settings.title'));
    // Check if user has edit permissions based on ui permissions and dependant mode
    var hasPermission = useUIPermissions().hasPermission;
    // Get console gateway data
    var _a = useAlternateApiSettings(), alternateApiSettings = _a.data, isLoadingAlternateApiSettings = _a.isLoading, isFetchingAlternateApiSettings = _a.isFetching;
    // Get interfaces data
    var _b = useCliSessionTimeout(), cliSessionTimeout = _b.data, isLoadingCliSessionTimeout = _b.isLoading, isFetchingCliSessionTimeout = _b.isFetching;
    // Get external_endpoints data
    var _c = useWebuiSessionTimeout(), webuiSessionTimeout = _c.data, isLoadingWebuiSessionTimeout = _c.isLoading, isFetchingWebuiSessionTimeout = _c.isFetching;
    // Derived loading and ready values
    var isLoadingData = isLoadingAlternateApiSettings || isLoadingCliSessionTimeout || isLoadingWebuiSessionTimeout;
    var isDataReady = !!alternateApiSettings && !!cliSessionTimeout && !!webuiSessionTimeout;
    var isFetchingData = isFetchingAlternateApiSettings || isFetchingCliSessionTimeout || isFetchingWebuiSessionTimeout;
    var sessionSettingsInnerNode = null;
    if (isLoadingData) {
        sessionSettingsInnerNode = _jsx(LoadingBackdrop, { "data-testid": SESSION_SETTINGS_LOADER_ID });
    }
    if (isDataReady) {
        sessionSettingsInnerNode = (_jsx(SessionSettingsForm, { editable: hasPermission('settings.services.session_settings.edit'), webUiTimeout: webuiSessionTimeout.system_webui_session_timeout.timeout, cliTimeout: cliSessionTimeout.system_cli_session_timeout.timeout, altApiEnabled: alternateApiSettings.system_alternate_api.enabled, isFetchingData: isFetchingData }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.session_settings.title') })), sessionSettingsInnerNode] })));
}
export default withAuthenticationRequired(SessionSettings, { rights: ['settings.services.session_settings.view'] });
