var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { OGButton } from '@opengear/buttons';
import { useFormContainerContext } from '@/components/layout/forms';
export function ConfirmModalContent(_a) {
    var title = _a.title, message = _a.message, onCancel = _a.onCancel, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = useFormContainerContext().formState, isSubmitting = _b.isSubmitting, isValid = _b.isValid;
    var acceptanceValue = 'yes';
    var _c = __read(useState(''), 2), input = _c[0], setInput = _c[1];
    var _d = __read(useState(false), 2), isTextMatch = _d[0], setIsTextMatch = _d[1];
    var submitDisabled = !isValid || isSubmitting || !isTextMatch;
    var handleInputChange = useCallback(function (event) {
        var userInput = event.target.value;
        setInput(userInput);
        if (userInput.toLowerCase() === acceptanceValue) {
            setIsTextMatch(true);
        }
        else {
            setIsTextMatch(false);
        }
    }, []);
    var handleCancel = useCallback(function () {
        setInput('');
        onCancel();
    }, [onCancel]);
    var handleSubmit = useCallback(function (event) {
        event.preventDefault();
        setInput('');
        onSubmit();
    }, [onSubmit]);
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, { children: title }), _jsxs(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column', gap: 1.5, whiteSpace: 'normal' } }, { children: [message ? _jsx(Typography, { children: message }) : null, _jsx(TextField
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    , { 
                        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus: true, id: "confirmInput", value: input, label: t('pages.firmware_upgrade_new.steps.review_confirm.modal.check_label'), type: "text", onChange: handleInputChange, sx: {
                            width: 500,
                            maxWidth: '100%',
                        } })] })), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: handleCancel }, { children: t('global.buttons.cancel') })), _jsx(OGButton, __assign({ type: "submit", disabled: submitDisabled, loading: isSubmitting, onClick: handleSubmit }, { children: t('global.buttons.confirm') }))] })] }));
}
