var DEFAULT_MOCK_SERVICES_SMF = {
    lighthouse: 'lighthouse_configurations-1',
    lighthouse_role: 'Primary',
    address: '10.0.0.0',
    mask: '19',
};
export var DEFAULT_MOCK_SERVICES_SMF_RESPONSE = {
    smf: [DEFAULT_MOCK_SERVICES_SMF],
};
export var SERVICES_SMF_PATH = '/services/smf';
