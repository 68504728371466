var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Field validation limits
export var ReadWriteCommMaxLength = 255;
export var ReadOnlyCommMaxLength = 255;
export function ReadWriteCommunityField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, clearErrors = _a.clearErrors;
    var validateWriteCommunity = useCallback(function (value, formValues) {
        if (!value && !formValues.readOnlyCommunity) {
            return requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.read_write_community.label'));
        }
        clearErrors('readOnlyCommunity');
        return true;
    }, [clearErrors, t]);
    return (_jsx(Controller, { control: control, name: "readWriteCommunity", rules: {
            maxLength: {
                value: ReadWriteCommMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.read_write_community.label'), ReadWriteCommMaxLength.toString()),
            },
            validate: validateWriteCommunity,
        }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error, dirtyFields = _a.formState.dirtyFields;
            // Determine if readWriteCommunity is required
            var isRequired = !value && !dirtyFields.readOnlyCommunity;
            return (_jsx(TextField, __assign({ fullWidth: true, value: value, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, required: isRequired, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.read_write_community.label'), onChange: onChange, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message }, field)));
        } }));
}
export function ReadOnlyCommunityField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, clearErrors = _a.clearErrors;
    var validateOnlyCommunity = useCallback(function (value, formValues) {
        if (!value && !formValues.readWriteCommunity) {
            return requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.read_only_community.label'));
        }
        clearErrors('readWriteCommunity');
        return true;
    }, [clearErrors, t]);
    return (_jsx(Controller, { control: control, name: "readOnlyCommunity", rules: {
            maxLength: {
                value: ReadOnlyCommMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_service.form.read_only_community.label'), ReadOnlyCommMaxLength.toString()),
            },
            validate: validateOnlyCommunity,
        }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error, dirtyFields = _a.formState.dirtyFields;
            // Determine if readOnlyCommunity is required
            var isRequired = !value && !dirtyFields.readWriteCommunity;
            return (_jsx(TextField, __assign({ fullWidth: true, required: isRequired, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, value: value, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, label: t('pages.alerts_and_monitoring.tabs.snmp_service.form.read_only_community.label'), onChange: onChange }, field)));
        } }));
}
