var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { OGLink, OGLinkButton } from '@opengear/links';
import { OGTable } from '@opengear/table';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useGroups } from '@/api/groups';
import { formatGroupRoute, GROUPS_NEW_ROUTE } from '@/constants/routes';
import { usePaginationModel } from '@/hooks/pagination-model';
import { formatDateString } from '@/utils/datetime';
export default function GroupsTable() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditAccess = hasPermission('settings.user_management.permissions.edit');
    var hasViewAccess = hasPermission('settings.user_management.permissions.view');
    // Client side table options
    var defaultSortModel = [{ field: 'last_updated_time', sort: 'asc' }];
    // Client side table controls
    var _a = __read(usePaginationModel('groups'), 3), paginationModel = _a[0], handlePaginationModelChange = _a[1], pageSizeOptions = _a[2];
    var _b = __read(useState({
        page: 1,
        per_page: 100000,
        filter: undefined,
    }), 2), queryParams = _b[0], setQueryParams = _b[1];
    var handleFilterChange = useCallback(function (newFilter) {
        setQueryParams(function (prevQueryParams) { return (__assign(__assign({}, prevQueryParams), { filter: newFilter })); });
    }, []);
    var debouncedChangeHandler = useMemo(function () { return debounce(handleFilterChange, 500); }, [handleFilterChange]);
    // Table data
    var _c = useGroups(queryParams), data = _c.data, isLoading = _c.isLoading;
    var loading = isLoading;
    var rows = useMemo(function () { return ({
        rows: (data === null || data === void 0 ? void 0 : data.groups) || [],
    }); }, [data]).rows;
    // Generate a unique key based on the rows data length and concatenated ids
    var tableKey = useMemo(function () { return rows.map(function (row) { return row.id; }).join(','); }, [rows]);
    var renderGroupsNameCell = function (params) {
        return hasViewAccess ? (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, variant: "standard", to: formatGroupRoute(params.row.id) }, { children: params.value }))) : (params.value);
    };
    var getUserCount = function (params) { return params.row.members.length; };
    var userCountComparator = function (v1, v2) { return v1 - v2; };
    var columns = [
        {
            field: 'groupname',
            headerName: t('pages.groups_roles.tabs.groups.table.headers.groupname'),
            renderCell: renderGroupsNameCell,
            minWidth: 190,
            flex: 1,
        },
        { field: 'description', headerName: t('pages.groups_roles.tabs.groups.table.headers.description'), minWidth: 250, flex: 2 },
        {
            field: 'user_count',
            headerName: t('pages.groups_roles.tabs.groups.table.headers.user_count'),
            sortComparator: userCountComparator,
            valueGetter: getUserCount,
            minWidth: 130,
        },
        {
            field: 'enabled',
            headerName: t('pages.groups_roles.tabs.groups.table.headers.status'),
            valueGetter: function (_a) {
                var value = _a.value;
                return (value ? t('pages.groups_roles.status.enabled') : t('pages.groups_roles.status.disabled'));
            },
            minWidth: 130,
        },
        {
            field: 'last_updated_time',
            headerName: t('pages.groups_roles.tabs.groups.table.headers.last_updated'),
            valueGetter: function (_a) {
                var value = _a.value;
                return (value === '--' ? '--' : formatDateString(value));
            },
            minWidth: 200,
        },
    ];
    // cleanup function
    useEffect(function () { return function () { return debouncedChangeHandler.cancel(); }; }, [debouncedChangeHandler]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "start", marginBottom: 2, gap: 1 }, { children: [_jsx(Box, __assign({ flexGrow: "1" }, { children: _jsx(TextField, { fullWidth: true, margin: "none", placeholder: t('pages.groups_roles.tabs.groups.search_helper_text'), onChange: function (e) { return debouncedChangeHandler(e.target.value); } }) })), _jsx(Box, { children: _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: hasEditAccess ? t('pages.groups_roles.tabs.groups.buttons.add_group') : '' }, { children: _jsx(OGLinkButton, { "aria-label": t('pages.groups_roles.tabs.groups.buttons.add_group'), disabled: !hasEditAccess, size: "small", startIcon: _jsx(AddCircleOutline, {}), to: GROUPS_NEW_ROUTE, variant: "borderless" }) })) })] })), _jsx(Box, { children: _jsx(OGTable, { id: "groups-table", "aria-label": t('pages.groups_roles.tabs.groups.table.label'), columns: columns, rows: rows, autoHeight: true, loading: loading, noRowsMessage: t('pages.groups_roles.tabs.groups.table.no_rows_message'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "client", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    } }, tableKey) })] }));
}
