var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { OGButtonLink } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import { useNodeSmartgroups } from '@/api/nodes/smartgroups';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { NodeFilterEditModal, NodeFilterDeleteModal } from '@/containers/filters/node-filters/node-filter-modals';
import { useOpen } from '@/hooks/open';
import { NodeFilterInvalidModal } from '../node-filter-modals';
import { isNodeQueryValid } from '@/containers/filters/advanced-filters/node-filter';
export function NodeFiltersTable(_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var _b = __read(useState(undefined), 2), selectedNodeFilter = _b[0], setSelectedNodeFilter = _b[1];
    var _c = __read(useState(undefined), 2), selectedInvalidFilter = _c[0], setSelectedInvalidFilter = _c[1];
    var editFilterModalOpen = !!selectedNodeFilter;
    var invalidFilterModalOpen = !!selectedInvalidFilter;
    var deleteFilter = selectedNodeFilter !== null && selectedNodeFilter !== void 0 ? selectedNodeFilter : selectedInvalidFilter;
    var _d = useOpen(), deleteModalOpen = _d.open, handleDeleteModalOpen = _d.handleOpen, handleDeleteModalClose = _d.handleClose;
    var handleEditFilterModalOpen = useCallback(function (nodeFilter) {
        setSelectedNodeFilter(nodeFilter);
    }, []);
    var handleEditFilterModalClose = useCallback(function () {
        setSelectedNodeFilter(undefined);
    }, []);
    var handleInvalidFilterModalOpen = useCallback(function (filter) {
        setSelectedInvalidFilter(filter);
    }, []);
    var handleInvalidFilterModalClose = useCallback(function () {
        setSelectedInvalidFilter(undefined);
    }, []);
    var handleDelete = useCallback(function () {
        handleEditFilterModalClose();
        handleInvalidFilterModalClose();
        handleDeleteModalClose();
    }, [handleDeleteModalClose, handleEditFilterModalClose, handleInvalidFilterModalClose]);
    var _e = __read(usePaginationModel('node_filters'), 3), paginationModel = _e[0], handlePaginationModelChange = _e[1], pageSizeOptions = _e[2];
    var _f = __read(useSortModel('name'), 3), defaultSortModel = _f[0], sortQueryParams = _f[1], handleSortModelChange = _f[2];
    var queryParams = useMemo(function () { return (__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize }, sortQueryParams)); }, [paginationModel, sortQueryParams]);
    var _g = useNodeSmartgroups(queryParams), data = _g.data, isLoading = _g.isLoading;
    var _h = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.smartgroups) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.smartgroups, isLoading]), loading = _h.loading, rowCount = _h.rowCount, rows = _h.rows;
    var renderNameCell = useCallback(function (params) {
        var row = params.row, value = params.value, tabIndex = params.tabIndex;
        if (!permissions.hasFilterEditAccess)
            return value;
        if (!isNodeQueryValid(row))
            return (_jsxs(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                    handleInvalidFilterModalOpen(row);
                }, propagateSpacebar: false }, { children: [value, " ", _jsx(WarningIcon, { fontSize: "medium", color: "warning", sx: { marginLeft: 1 } })] })));
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                handleEditFilterModalOpen(row);
            }, propagateSpacebar: false }, { children: value })));
    }, [handleEditFilterModalOpen, handleInvalidFilterModalOpen, permissions.hasFilterEditAccess]);
    var columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('pages.filters.table.columns.name'),
            sortable: true,
            renderCell: renderNameCell,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(NodeFilterEditModal, { onClose: handleEditFilterModalClose, onDelete: handleDeleteModalOpen, onSubmit: handleEditFilterModalClose, open: editFilterModalOpen, selectedFilter: !!selectedNodeFilter && isNodeQueryValid(selectedNodeFilter) ? selectedNodeFilter : undefined, translations: {
                    dialogTitle: t('components.nodes.filter.edit_modal.title'),
                    saveBtn: t('components.nodes.filter.edit_modal.submit'),
                } }), deleteFilter && _jsx(NodeFilterDeleteModal, { open: deleteModalOpen, onClose: handleDeleteModalClose, onDelete: handleDelete, selectedFilter: deleteFilter }), _jsx(NodeFilterInvalidModal, { open: invalidFilterModalOpen, onDelete: handleDeleteModalOpen, onClose: handleInvalidFilterModalClose, selectedFilter: selectedInvalidFilter }), _jsx(OGTable, { "aria-label": t('pages.filters.tabs.node_filters.title'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('pages.filters.table.no_row_message'), loading: loading, pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, rowCount: rowCount, onPaginationModelChange: handlePaginationModelChange, onSortModelChange: handleSortModelChange, initialState: {
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                } })] }));
}
