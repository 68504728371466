var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useFieldArray, useForm } from 'react-hook-form';
import format from 'string-format';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { OGPanel } from '@opengear/panel';
import Divider from '@mui/material/Divider';
import { OGButton } from '@opengear/buttons';
import LoadingIcon from '@mui/icons-material/Autorenew';
import { OGLink } from '@opengear/links';
import { FormContainer } from '../layout/forms/container';
import { FormActions, ResetButton, SubmitButton } from '../layout/forms';
import { AlwaysActivateToggleButton } from './manage-nom-form-controls';
import { useUpdateNetopsModules } from '@/api/netops/modules';
import { useNomRedeploy } from '@/api/netops/nom-redeploy';
import { SUBSCRIPTION_SETTINGS_ROUTE } from '@/constants/routes';
import { NOM_MODULES_PATH } from '@/api/netops/modules.constants';
import { useFormSync } from '@/hooks/use-form-sync';
import { displayAPIRequestsResults } from '@/utils/form-submission-result';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { transformToFormState } from './transformers';
export function ManageNOMForm(_a) {
    var _this = this;
    var modules = _a.modules, permissions = _a.permissions, state = _a.state, status = _a.status;
    var _b = useAlertNotification(), alertNotification = _b.alertNotification, closeRef = _b.closeRef;
    var t = useTranslation().t;
    var updateModules = useUpdateNetopsModules();
    var redeployModule = useNomRedeploy();
    var queryClient = useQueryClient();
    var _c = __read(useState(false), 2), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var isLicensed = false;
    var _d = __read(useMemo(function () { return [modules['netops-modules'], modules['licenses-available'], transformToFormState(modules['netops-modules'])]; }, [modules]), 3), nomModules = _d[0], availableLic = _d[1], defaultNetopsModule = _d[2];
    var methods = useForm({
        mode: 'onTouched',
        defaultValues: defaultNetopsModule,
    });
    var fields = useFieldArray({
        name: 'netopsModules',
        control: methods.control,
    }).fields;
    var handleRedeployClick = useCallback(function (module) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!module)
                        return [2 /*return*/];
                    return [4 /*yield*/, redeployModule(module)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [redeployModule]);
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var promises, resetField, dirtyFields, updateResults, apiRequestResult;
        var _this = this;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmitting(true);
                    closeRef.current();
                    promises = [];
                    resetField = methods.resetField, dirtyFields = methods.formState.dirtyFields;
                    (_a = dirtyFields.netopsModules) === null || _a === void 0 ? void 0 : _a.forEach(function (_, index) {
                        var _a = formData.netopsModules[index], nomId = _a.nomId, alwaysActivate = _a.always_activate;
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedData, updatedModule;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0: return [4 /*yield*/, updateModules(nomId, { always_activate: alwaysActivate })];
                                    case 1:
                                        updatedData = _c.sent();
                                        if (updatedData) {
                                            updatedModule = updatedData['netops-modules'].find(function (nomModule) { return nomModule.id === nomId; });
                                            resetField("netopsModules.".concat(index, ".always_activate"), {
                                                defaultValue: updatedModule === null || updatedModule === void 0 ? void 0 : updatedModule.always_activate,
                                            });
                                            return [2 /*return*/, (_b = (_a = updatedData['netops-modules'].find(function (module) { return module.id === nomId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : undefined];
                                        }
                                        return [2 /*return*/, undefined];
                                }
                            });
                        }); })());
                    });
                    return [4 /*yield*/, Promise.all(promises)];
                case 1:
                    updateResults = _b.sent();
                    return [4 /*yield*/, queryClient.invalidateQueries({
                            queryKey: [NOM_MODULES_PATH],
                        })];
                case 2:
                    _b.sent();
                    setIsSubmitting(false);
                    apiRequestResult = displayAPIRequestsResults(updateResults);
                    if (apiRequestResult) {
                        alertNotification(apiRequestResult.message ? t('api.errors.partial_failure.message').replace('%n', apiRequestResult.message) : t('api.netops.put.ok.message'), {
                            header: apiRequestResult.priority === OGToastPriorities.Warning ? t('api.errors.partial_failure.header') : t('api.netops.put.ok.header'),
                            priority: apiRequestResult.priority,
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [alertNotification, closeRef, methods, queryClient, t, updateModules]);
    useFormSync(methods.formState.defaultValues, defaultNetopsModule, methods.reset, t('pages.nom.form.reset_warning.header'), isSubmitting);
    return (_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ mode: "update", permissions: permissions, methods: methods, onSubmit: onSubmit }, { children: [fields.map(function (field, index) {
                    var _a, _b, _c;
                    var module = nomModules.find(function (nomModule) { return nomModule.id === field.nomId; });
                    var licensed = (module === null || module === void 0 ? void 0 : module.license_required) ? !!availableLic.find(function (lic) { return lic.license === (module === null || module === void 0 ? void 0 : module.license_required); }) : true;
                    var latestVersion = (_a = module === null || module === void 0 ? void 0 : module.version) !== null && _a !== void 0 ? _a : '';
                    var currentVersion = (_c = (_b = state === null || state === void 0 ? void 0 : state.modules.find(function (stateModule) { return stateModule.short_name === (module === null || module === void 0 ? void 0 : module.id); })) === null || _b === void 0 ? void 0 : _b.current_version) !== null && _c !== void 0 ? _c : latestVersion;
                    isLicensed = isLicensed || licensed;
                    if (module) {
                        return (_jsxs(Box, __assign({ padding: 1 }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", gutterBottom: true }, { children: module.name })), _jsx(Typography, __assign({ gutterBottom: true }, { children: module.description })), _jsx(Typography, __assign({ gutterBottom: true }, { children: module.status === 'available' ? t('pages.nom.form.module_status.available') : t('pages.nom.form.module_status.not_available') })), _jsx(Typography, __assign({ gutterBottom: true }, { children: format(t('pages.nom.form.activations.activated'), module.activations.toString()) })), _jsx(Typography, __assign({ gutterBottom: true }, { children: format(t('pages.nom.form.activations.remaining'), module.available_activations.toString()) })), _jsx(Typography, __assign({ gutterBottom: true }, { children: format(t('pages.nom.form.version'), currentVersion) })), currentVersion !== latestVersion && _jsx(Typography, __assign({ gutterBottom: true }, { children: format(t('pages.nom.form.version_available'), latestVersion) })), licensed ? (_jsxs(_Fragment, { children: [_jsx(AlwaysActivateToggleButton, { index: index }), _jsx(OGButton, __assign({ startIcon: _jsx(LoadingIcon, {}), variant: "secondary", sx: { marginTop: 2 }, onClick: function () { return handleRedeployClick(module.module); }, disabled: !permissions.hasEditPermission || status === 'running' || status === 'pending' }, { children: t('pages.nom.form.redeploy.button') }))] })) : (_jsx(OGLink, __assign({ variant: "standard", to: SUBSCRIPTION_SETTINGS_ROUTE, sx: { textTransform: 'uppercase' } }, { children: t('pages.nom.form.no_subscription') }))), _jsx(Divider, { sx: { marginBottom: 2, marginTop: 2 } })] }), module.id));
                    }
                    return null;
                }), isLicensed && (_jsxs(FormActions, { children: [_jsx(ResetButton, {}), _jsx(SubmitButton, {})] }))] })) }));
}
