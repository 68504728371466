var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { SubscriptionsContent } from '@/components/settings/system/subscriptions/subscriptions-content';
import { useUIPermissions } from '@/api/sessions/self/ui';
import SubscriptionsAssignmentTable from '@/components/settings/system/subscriptions/subscriptions-assignment-table';
var TabValue;
(function (TabValue) {
    TabValue["SUBSCRIPTIONS"] = "SUBSCRIPTIONS";
    TabValue["SUBSCRIPTION_ASSIGNMENT"] = "SUBSCRIPTION_ASSIGNMENT";
})(TabValue || (TabValue = {}));
function _Subscriptions() {
    var t = useTranslation().t;
    useDocTitle(t('pages.subscriptions.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var canViewNodes = hasPermission('monitor.nodes.view');
    var _a = __read(React.useState(TabValue.SUBSCRIPTIONS), 2), tabValue = _a[0], setTabValue = _a[1];
    var handleChange = function (event, newValue) {
        setTabValue(newValue);
    };
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.subscriptions.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable", onChange: handleChange }, { children: [_jsx(Tab, { label: t('pages.subscriptions.tabs.subscriptions.title'), value: TabValue.SUBSCRIPTIONS }), _jsx(Tab, { label: t('pages.subscriptions.tabs.subscription_assignment.title'), value: TabValue.SUBSCRIPTION_ASSIGNMENT, disabled: !canViewNodes })] })), _jsx(TabPanel, __assign({ value: TabValue.SUBSCRIPTIONS }, { children: _jsx(SubscriptionsContent, {}) })), _jsx(TabPanel, __assign({ value: TabValue.SUBSCRIPTION_ASSIGNMENT }, { children: _jsx(SubscriptionsAssignmentTable, {}) }))] })) })] })));
}
export var Subscriptions = withAuthenticationRequired(_Subscriptions, { rights: ['settings.system.licensing.view'] });
