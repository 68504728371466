var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { TimezoneTab } from '@/components/settings/system/time/timezone-tab';
import { AutomaticTimeTab } from '@/components/settings/system/time/automatic-tab';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { TIME_AUTOMATIC_ROUTE, TIME_SETTINGS_ROUTE, TIME_TIMEZONE_ROUTE } from '@/constants/routes';
import { pathEndsWith } from '@/utils/navigation';
export var TIMEZONE_TAB_ID = 'time-settings-timezone-tab';
export var AUTOMATIC_TAB_ID = 'time-settings-automatic-tab';
var TabValue;
(function (TabValue) {
    TabValue["TIMEZONE"] = "TIMEZONE";
    TabValue["AUTOMATIC"] = "AUTOMATIC";
})(TabValue || (TabValue = {}));
function TimeSettings() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useDocTitle(t('pages.time_settings.title'));
    // Check if user has edit permissions based on ui permissions and dependant mode
    var hasPermission = useUIPermissions().hasPermission;
    var hasTimezoneViewAccess = hasPermission('settings.date_time.time_zone.view');
    var hasAutomaticViewAccess = hasPermission('settings.date_time.automatic_settings.view');
    var tabValue = pathEndsWith(pathname, TIME_AUTOMATIC_ROUTE) ? TabValue.AUTOMATIC : TabValue.TIMEZONE;
    var innerNode = null;
    if (tabValue === TabValue.TIMEZONE && hasTimezoneViewAccess) {
        innerNode = _jsx(TimezoneTab, { permissions: { hasEditPermission: hasPermission('settings.date_time.time_zone.edit') } });
    }
    if (tabValue === TabValue.AUTOMATIC && hasAutomaticViewAccess) {
        innerNode = _jsx(AutomaticTimeTab, { editable: hasPermission('settings.date_time.automatic_settings.edit') });
    }
    useEffect(function () {
        if (pathEndsWith(pathname, TIME_SETTINGS_ROUTE)) {
            if (hasTimezoneViewAccess) {
                navigate(TIME_TIMEZONE_ROUTE.slice(1), { replace: true });
            }
            else if (hasAutomaticViewAccess) {
                navigate(TIME_AUTOMATIC_ROUTE.slice(1), { replace: true });
            }
        }
    }, [pathname, navigate, hasTimezoneViewAccess, hasAutomaticViewAccess]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.time_settings.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(Tabs, __assign({ value: tabValue, "aria-label": t('pages.time_settings.tabs.label'), orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: TIME_TIMEZONE_ROUTE.slice(1), label: t('pages.time_settings.tabs.timezone.title'), value: TabValue.TIMEZONE, disabled: !hasTimezoneViewAccess, id: TIMEZONE_TAB_ID }), _jsx(Tab, { component: RouterLink, to: TIME_AUTOMATIC_ROUTE.slice(1), label: t('pages.time_settings.tabs.automatic.title'), value: TabValue.AUTOMATIC, disabled: !hasAutomaticViewAccess, id: AUTOMATIC_TAB_ID })] })), _jsx(TabPanel, __assign({ value: tabValue }, { children: innerNode }))] })) })] })));
}
export default withAuthenticationRequired(TimeSettings, { rights: ['settings.date_time.time_zone.view', 'settings.date_time.automatic_settings.view'] });
