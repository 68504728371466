var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { WarningBanner } from '@/components/warning-banner';
import { useAddDependentLighthouse, useDependentLighthouses } from '@/api/multiple-instance/dependent-lighthouses';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NOT_FOUND_ROUTE, SECONDARY_LIGHTHOUSES_ROUTE } from '@/constants/routes';
import { useEntitlementsData } from '@/api/system/entitlements';
import { useAlertNotification } from '@/hooks/alert-notification';
import { useServicesSmf } from '@/api/services/smf';
import { cidrToSubnetMask } from '@/utils/calculations';
import { transformAddFormStateToApi } from '@/components/settings/mi/secondary-lighthouses/transformers';
import { SecondaryLighthouseForm } from '@/components/settings/mi/secondary-lighthouses';
var ADD_SECONDARY_FORM_LOADER_ID = 'add-secondary-form-loading';
var defaultValues = {
    description: '',
    address: '',
    port: '443',
    username: '',
    password: '',
    vpn_address: '172.16.32.0',
    cidr: '19',
    smf_address: '10.0.32.0',
    smf_mask: '19',
};
export default withAuthenticationRequired(function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    useDocTitle(t('pages.secondary_lighthouse.add.title'));
    // Get entitlements to check if license supports MI
    var _b = useEntitlementsData(), miSupported = _b.miSupported, isLoadingEntitlements = _b.isLoading;
    var _c = useDependentLighthouses(), dependentData = _c.data, isLoadingDependent = _c.isLoading;
    var _d = useServicesSmf({ lighthouse_role: 'Primary' }), servicesSmfData = _d.data, isLoadingServicesSmf = _d.isLoading;
    var createSecondaryLighthouse = useAddDependentLighthouse();
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('configure.multiple_instance.dependent_lighthouse.edit');
    var hasViewPermission = hasPermission('configure.multiple_instance.dependent_lighthouse.view');
    var sourceValues = useMemo(function () {
        var _a;
        var updatedDefaultValues = __assign(__assign({}, defaultValues), { smf_mask: ((_a = servicesSmfData === null || servicesSmfData === void 0 ? void 0 : servicesSmfData.smf[0]) === null || _a === void 0 ? void 0 : _a.mask) || '' });
        return updatedDefaultValues;
    }, [servicesSmfData]);
    useEffect(function () {
        if (!isLoadingEntitlements && !miSupported) {
            alertNotification(t('pages.secondary_lighthouses.mi_not_supported_alert'), { persistOnUnmount: true });
            navigate(NOT_FOUND_ROUTE);
        }
        if (dependentData && dependentData.dependent_lighthouses.length >= 10) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [alertNotification, dependentData, isLoadingEntitlements, miSupported, navigate, t]);
    var onSubmit = useCallback(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var mask, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    closeRef.current();
                    mask = cidrToSubnetMask(formData.cidr);
                    if (!(mask === undefined)) return [3 /*break*/, 1];
                    alertNotification(t('pages.secondary_lighthouse.form.parse_error.message'), {
                        header: t('pages.secondary_lighthouse.form.parse_error.message'),
                    });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, createSecondaryLighthouse(transformAddFormStateToApi(formData, mask))];
                case 2:
                    response = _a.sent();
                    if (response) {
                        navigate(SECONDARY_LIGHTHOUSES_ROUTE);
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [alertNotification, closeRef, createSecondaryLighthouse, navigate, t]);
    if (isLoadingDependent || isLoadingServicesSmf || isLoadingEntitlements) {
        return _jsx(LoadingBackdrop, { "data-testid": ADD_SECONDARY_FORM_LOADER_ID });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 1.5 }, { children: t('pages.secondary_lighthouse.add.title') })), _jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.secondary_lighthouse.add.warning.header'), warningMessage: _jsx(Typography, { children: t('pages.secondary_lighthouse.add.warning.message') }), warningColor: "warning", bannerCode: "node-vpn-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }), _jsx(SecondaryLighthouseForm, { defaultValues: sourceValues, permissions: { hasEditPermission: hasEditPermission, hasViewPermission: hasViewPermission }, onSubmit: onSubmit })] })));
}, { rights: ['configure.multiple_instance.dependent_lighthouse.edit'] });
