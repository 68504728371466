var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import startCase from 'lodash/startCase';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { OGReception, OGReceptionHigh, OGReceptionMedium, OGReceptionLow } from '@opengear/icons/status';
export function CellStatus(_a) {
    var connectionStatus = _a.connectionStatus, cellularHealthStatus = _a.cellularHealthStatus, carrier = _a.carrier;
    var t = useTranslation().t;
    var cellStatusTranslation = t('global.query.node_cellhealth_status');
    var icon = _jsx(ErrorIcon, { fontSize: "medium", color: "error" });
    var description = cellStatusTranslation.unknown;
    if (cellularHealthStatus === 'sim_issues' || cellularHealthStatus === 'connectivity_test_failed') {
        icon = _jsx(OGReception, { color: "error", fontSize: "medium" });
        description = cellStatusTranslation[cellularHealthStatus];
    }
    if (cellularHealthStatus === 'pending') {
        icon = _jsx(InfoIcon, { fontSize: "medium" });
        description = cellStatusTranslation.pending;
    }
    if (cellularHealthStatus === 'bad') {
        icon = _jsx(OGReceptionLow, { color: "error", fontSize: "medium" });
        description = "".concat(carrier ? "".concat(startCase(carrier === null || carrier === void 0 ? void 0 : carrier.split(' ')[0]), " - ") : '').concat(cellStatusTranslation.bad, " Signal");
    }
    if (cellularHealthStatus === 'moderate') {
        icon = _jsx(OGReceptionMedium, { color: "warning", fontSize: "medium" });
        description = "".concat(carrier ? "".concat(startCase(carrier === null || carrier === void 0 ? void 0 : carrier.split(' ')[0]), " - ") : '').concat(cellStatusTranslation.moderate, " Signal");
    }
    if (cellularHealthStatus === 'good') {
        icon = _jsx(OGReceptionHigh, { color: "success", fontSize: "medium" });
        description = "".concat(carrier ? "".concat(startCase(carrier === null || carrier === void 0 ? void 0 : carrier.split(' ')[0]), " - ") : '').concat(cellStatusTranslation.good, " Signal");
    }
    if (cellularHealthStatus === 'interface_disabled') {
        icon = _jsx(OGReception, { fontSize: "medium" });
        description = "".concat(carrier ? "".concat(startCase(carrier === null || carrier === void 0 ? void 0 : carrier.split(' ')[0]), " - ") : '').concat(cellStatusTranslation.interface_disabled);
    }
    if (cellularHealthStatus === 'unknown' || cellularHealthStatus === 'n/a') {
        icon = _jsx(DoNotDisturbIcon, { fontSize: "medium" });
        description = "".concat(carrier ? "".concat(startCase(carrier === null || carrier === void 0 ? void 0 : carrier.split(' ')[0]), " - ") : '').concat(cellStatusTranslation[cellularHealthStatus]);
    }
    if (connectionStatus !== 'connected') {
        icon = _jsx(DoNotDisturbIcon, { fontSize: "medium" });
        description = "".concat(carrier ? "".concat(startCase(carrier === null || carrier === void 0 ? void 0 : carrier.split(' ')[0]), " - ") : '').concat(cellStatusTranslation.unknown);
    }
    return (_jsxs("span", __assign({ style: { display: 'flex', alignItems: 'flexEnd' } }, { children: [icon, "\u00A0", description] })));
}
