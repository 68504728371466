var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { rest } from 'msw';
import { notFoundError } from '@/test/utils';
import { formatApiPath } from '@/api/utils';
import { SMF_RESOURCES_BULK_PATH, SMF_RESOURCES_PATH, SMF_RESOURCES_WARNINGS_PATH, formatSmfResourcePath } from './smf-resources';
import { formatResourceTagId, formatSmfResourceId } from '@/constants/models.mock';
export var defaultMockSmfResources = [
    {
        id: formatSmfResourceId(1),
        name: 'SMFResource1',
        address: '192.168.0.1',
        services: {
            https: {
                connection_status: 'connected',
                connection_status_last_updated: 1712251224,
                port: 443,
                url: 'https://some_unique_id.smfresource.example.com',
                user_facing_id: 'some_unique_id',
            },
            http: {
                connection_status: 'connected',
                connection_status_last_updated: 1712251225,
                port: 80,
                url: 'https://some_unique_id_2.smfresource.example.com',
                user_facing_id: 'some_unique_id_2',
            },
            ssh: {
                connection_status: 'disconnected',
                connection_status_last_updated: 0,
                port: 22,
                url: '',
                user_facing_id: 'some_unique_id_3',
            },
        },
        resource_tags: [formatResourceTagId(1), formatResourceTagId(2), formatResourceTagId(3), formatResourceTagId(4), formatResourceTagId(5)],
    },
    {
        id: formatSmfResourceId(2),
        name: 'SMFResource2',
        address: '192.168.0.2',
        services: {
            https: {
                connection_status: 'disconnected',
                connection_status_last_updated: 1712251225,
                port: 444,
                url: 'https://some_unique_id_4.smfresource.example.com',
                user_facing_id: 'some_unique_id_4',
            },
            ssh: {
                connection_status: 'connected',
                connection_status_last_updated: 1712251224,
                port: 23,
                url: 'ssh://smf$bob$acde89a8726bcde@15.16.20.3:2532',
                user_facing_id: 'some_unique_id_5',
            },
        },
        // tag id 555 is for testing
        resource_tags: [formatResourceTagId(1), formatResourceTagId(555)],
    },
    {
        id: formatSmfResourceId(3),
        name: 'SMFResource3',
        address: '192.168.0.3',
        services: {},
        resource_tags: [],
    },
];
export var defaultMockPortsTagsNames = [
    {
        id: formatResourceTagId(1),
        resource_tag_name: 'tag_name1',
    },
    {
        id: formatResourceTagId(2),
        resource_tag_name: 'tag_name2',
    },
    {
        id: formatResourceTagId(3),
        resource_tag_name: 'tag_name3',
    },
    {
        id: formatResourceTagId(4),
        resource_tag_name: 'tag_name4',
    },
    {
        id: formatResourceTagId(5),
        resource_tag_name: 'tag_name5',
    },
    {
        id: formatResourceTagId(555),
        resource_tag_name: 'tag_name555',
    },
];
export var mockSmfResourcesResponse = {
    smart_management_fabric_resources: defaultMockSmfResources,
    meta: { total_count: defaultMockSmfResources.length, total_pages: 1 },
};
var mockSmfResourcesDB = new Map(defaultMockSmfResources.map(function (mockSmfResource) { return [mockSmfResource.id, mockSmfResource]; }));
export function formatNewSmfResourceId() {
    return formatSmfResourceId(mockSmfResourcesDB.size + 1);
}
function createMockSmfResource(request) {
    var newSmfResource = request.smart_management_fabric_resource;
    delete newSmfResource.resource_tags_actions;
    var id = formatNewSmfResourceId();
    var smfResource = __assign(__assign({ id: id }, newSmfResource), { resource_tags: [] });
    mockSmfResourcesDB.set(id, smfResource);
    return smfResource;
}
function updateMockSmfResource(id, request) {
    var updatedSmfResource = request.smart_management_fabric_resource;
    delete updatedSmfResource.resource_tags_actions;
    var smfResource = __assign(__assign({ id: id }, updatedSmfResource), { resource_tags: [] });
    mockSmfResourcesDB.set(id, smfResource);
    return smfResource;
}
export function resetSmfResources() {
    mockSmfResourcesDB.clear();
    defaultMockSmfResources.forEach(function (smfResource) {
        mockSmfResourcesDB.set(smfResource.id, smfResource);
    });
}
export var defaultMockSmfResourcesWarnings = {
    domain: false,
};
export var mockSmfResourcesWarningsResponse = {
    warnings: defaultMockSmfResourcesWarnings,
};
export var smfResourcesGetHandler = rest.get(formatApiPath(SMF_RESOURCES_PATH), function (req, res, ctx) {
    var orderBy = req.url.searchParams.get('order_by');
    var desc = req.url.searchParams.get('desc');
    var resourceResults;
    // Sort order
    if (orderBy === 'name') {
        resourceResults = __spreadArray([], __read(mockSmfResourcesDB.values()), false).sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
    }
    else if (orderBy === 'address') {
        resourceResults = __spreadArray([], __read(mockSmfResourcesDB.values()), false).sort(function (a, b) {
            if (a.address > b.address) {
                return 1;
            }
            if (a.address < b.address) {
                return -1;
            }
            return 0;
        });
    }
    else {
        resourceResults = __spreadArray([], __read(mockSmfResourcesDB.values()), false);
    }
    // Decending
    if (desc === '1') {
        resourceResults.reverse();
    }
    var mockResponseBody = {
        smart_management_fabric_resources: resourceResults,
        meta: { total_count: mockSmfResourcesDB.size, total_pages: 1 },
    };
    return res(ctx.status(200), ctx.json(mockResponseBody));
});
export var smfResourceGetHandler = rest.get(formatApiPath(formatSmfResourcePath()), function (req, res, ctx) {
    var resourceId = req.params.smfResourceId;
    if (typeof resourceId !== 'string') {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var smfResource = mockSmfResourcesDB.get(resourceId);
    if (smfResource === undefined) {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var mockResponseBody = {
        smart_management_fabric_resource: smfResource,
    };
    return res(ctx.status(200), ctx.json(mockResponseBody));
});
export var smfResourcePostHandler = rest.post(formatApiPath(SMF_RESOURCES_PATH), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var smfResource, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = createMockSmfResource;
                return [4 /*yield*/, req.json()];
            case 1:
                smfResource = _a.apply(void 0, [(_b.sent())]);
                return [2 /*return*/, res(ctx.status(200), ctx.json({ smart_management_fabric_resource: smfResource }))];
        }
    });
}); });
export var smfResourcePutHandler = rest.put(formatApiPath(formatSmfResourcePath()), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var resourceId, smfResource, smfResourceResponse, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                resourceId = req.params.smfResourceId;
                if (typeof resourceId !== 'string') {
                    return [2 /*return*/, res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)))];
                }
                smfResource = mockSmfResourcesDB.get(resourceId);
                if (smfResource === undefined) {
                    return [2 /*return*/, res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)))];
                }
                _a = updateMockSmfResource;
                _b = [resourceId];
                return [4 /*yield*/, req.json()];
            case 1:
                smfResourceResponse = _a.apply(void 0, _b.concat([(_c.sent())]));
                return [2 /*return*/, res(ctx.status(200), ctx.json({ smart_management_fabric_resource: smfResourceResponse }))];
        }
    });
}); });
export var smfResourceDeleteHandler = rest.delete(formatApiPath(formatSmfResourcePath()), function (req, res, ctx) {
    var resourceId = req.params.smfResourceId;
    if (typeof resourceId !== 'string') {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var deleted = mockSmfResourcesDB.delete(resourceId);
    if (!deleted) {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    return res(ctx.status(204));
});
export var smfResourceBulkDeleteHandler = rest.delete(formatApiPath(SMF_RESOURCES_BULK_PATH), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var resourceIdsToDelete;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, req.json()];
            case 1:
                resourceIdsToDelete = (_a.sent()).smart_management_fabric_resources;
                resourceIdsToDelete.forEach(function (id) {
                    mockSmfResourcesDB.delete(id);
                });
                return [2 /*return*/, res(ctx.status(204))];
        }
    });
}); });
export var smfResourcesWarningsGetHandler = rest.get(formatApiPath(SMF_RESOURCES_WARNINGS_PATH), function (_, res, ctx) {
    return res(ctx.status(200), ctx.json(mockSmfResourcesWarningsResponse));
});
export function mockGetSmfResourcesWarnings(server, domainWarningEnabled) {
    var mockResponseBody = {
        warnings: {
            domain: domainWarningEnabled,
        },
    };
    server.use(rest.get(formatApiPath(SMF_RESOURCES_WARNINGS_PATH), function (_, res, ctx) { return res(ctx.status(200), ctx.json(mockResponseBody)); }));
}
