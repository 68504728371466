var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { v4 as uuidV4 } from 'uuid';
import { UsernameField, DescriptionField, PasswordField, ConfirmPasswordField, GroupSelectionField } from './user-form-controls';
import { FormContainer } from '@/components/layout/forms';
export var getDefaultUserFormState = function () { return ({
    id: uuidV4(),
    username: '',
    description: '',
    password: '',
    confirmPassword: '',
    groups: [],
}); };
export function UserForm(_a) {
    var onSubmit = _a.onSubmit, permissions = _a.permissions, defaultValues = _a.defaultValues, children = _a.children, mode = _a.mode, groups = _a.groups, usernames = _a.usernames, ariaLabelledBy = _a.ariaLabelledBy;
    var methods = useForm({ mode: 'onTouched', defaultValues: defaultValues });
    if (mode === 'update') {
        // remove the current user from the list of usernames
        var index = usernames.indexOf(defaultValues.username);
        if (index >= 0) {
            usernames.splice(index, 1);
        }
    }
    return (_jsxs(FormContainer, __assign({ mode: mode, methods: methods, onSubmit: onSubmit, permissions: permissions, ariaLabelledBy: ariaLabelledBy }, { children: [_jsxs(Stack, __assign({ sx: { px: 2, pb: 2 } }, { children: [_jsx(UsernameField, { usernames: usernames }), _jsx(DescriptionField, {}), _jsx(PasswordField, {}), _jsx(ConfirmPasswordField, {}), _jsx(GroupSelectionField, { groups: groups })] })), _jsx(Divider, {}), children] })));
}
