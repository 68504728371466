var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import MuiDialog from '@mui/material/Dialog';
import { useDeviceView } from '@/hooks/use-device-view';
export function OGDialog(_a) {
    var children = _a.children, onClose = _a.onClose, PaperProps = _a.PaperProps, open = _a.open, props = __rest(_a, ["children", "onClose", "PaperProps", "open"]);
    var isMobileView = useDeviceView().isMobileView;
    var handleClose = useCallback(function (event, reason) {
        if (reason !== 'backdropClick' && onClose) {
            onClose(event, reason);
        }
    }, [onClose]);
    var sxPaperStyles = useMemo(function () { var _a; return (_a = PaperProps === null || PaperProps === void 0 ? void 0 : PaperProps.sx) !== null && _a !== void 0 ? _a : {}; }, [PaperProps === null || PaperProps === void 0 ? void 0 : PaperProps.sx]);
    var sxPaperOverrideDimensions = isMobileView ? { minHeight: '100vh', minWidth: '100vw' } : {};
    return (_jsx(MuiDialog, __assign({ open: open, onClose: handleClose }, props, { PaperProps: __assign(__assign({}, PaperProps), { sx: __assign(__assign({}, sxPaperStyles), sxPaperOverrideDimensions) }) }, { children: open ? children : null })));
}
