var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { OGDialog } from '@opengear/modals';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { LicenseItem } from './license-item/license-item';
import { useEntitlementsData } from '@/api/system/entitlements';
import { useNetopsModules } from '@/api/netops';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useOpen } from '@/hooks/open';
import { UpdateSubscriptionModalContent } from './update-subscription-modal/update-subscription-modal-content';
export var SUBSCRIPTIONS_LOADING_TEST_ID = 'subscriptions-loading';
export function SubscriptionsContent() {
    var _a;
    var _b = useUIPermissions(), hasPermission = _b.hasPermission, hasSomePermission = _b.hasSomePermission;
    var _c = useOpen(), open = _c.open, handleOpen = _c.handleOpen, handleClose = _c.handleClose;
    var _d = useEntitlementsData(), lighthouseSubscriptions = _d.lighthouseSubscriptions, entitlements = _d.entitlements, isSubscriptionsLoading = _d.isLoading;
    var _e = useNetopsModules(), modules = _e.data, isModuleLoading = _e.isLoading;
    var hasUpdateLicenseAccess = useMemo(function () { return hasPermission('settings.system.licensing.edit'); }, [hasPermission]);
    var hasViewTemplatesAccess = useMemo(function () {
        return hasSomePermission([
            'configure.configuration_templating.authentication_templates.view',
            'configure.configuration_templating.users_groups_templates.view',
            'configure.configuration_templating.script_templates.view',
        ]);
    }, [hasSomePermission]);
    var hasViewEnrollmentBundlesAccess = useMemo(function () { return hasPermission('configure.node_enrollment.enrollment_bundles.view'); }, [hasPermission]);
    if (isSubscriptionsLoading || isModuleLoading) {
        return _jsx(LoadingBackdrop, { "data-testid": SUBSCRIPTIONS_LOADING_TEST_ID });
    }
    var legacySubscription = lighthouseSubscriptions.find(function (subscription) { return subscription.details.tier === 'legacy'; });
    return (_jsxs(_Fragment, { children: [_jsx(OGDialog, __assign({ "aria-labelledby": SUBSCRIPTIONS_LOADING_TEST_ID, open: open, onClose: handleClose }, { children: _jsx(UpdateSubscriptionModalContent, { onClose: handleClose, hasUpdateLicenseAccess: hasUpdateLicenseAccess }) })), _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: legacySubscription ? (_jsx(LicenseItem, { licenseTier: "legacy", openModal: handleOpen, subscription: legacySubscription, entitlements: entitlements, modules: (_a = modules === null || modules === void 0 ? void 0 : modules['netops-modules']) !== null && _a !== void 0 ? _a : undefined, hasUpdateLicenseAccess: hasUpdateLicenseAccess })) : (_jsxs(_Fragment, { children: [_jsx(LicenseItem, { licenseTier: "enterprise", openModal: handleOpen, subscription: lighthouseSubscriptions.find(function (subscription) { return subscription.details.tier === 'enterprise'; }), hasUpdateLicenseAccess: hasUpdateLicenseAccess }), _jsx(LicenseItem, { licenseTier: "premium", openModal: handleOpen, subscription: lighthouseSubscriptions.find(function (subscription) { return subscription.details.tier === 'premium'; }), hasUpdateLicenseAccess: hasUpdateLicenseAccess, hasViewTemplatesAccess: hasViewTemplatesAccess, hasViewEnrollmentBundlesAccess: hasViewEnrollmentBundlesAccess })] })) }))] }));
}
