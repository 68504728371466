var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { GroupForm } from '@/components/accounts/groups/group-form';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useCreateGroup } from '@/api/groups';
function AddGroup() {
    var t = useTranslation().t;
    useDocTitle(t('pages.group_new.title'));
    var createGroup = useCreateGroup();
    var hasSomePermission = useUIPermissions().hasSomePermission;
    // Permissions required for both Port and Node smartgroups are the same, EntityEnum.smart_groups
    // and EntityEnum.nodes_devices_base. 'monitor.nodes.view' and 'configure.edit_smart_groups.view'
    // are the easiest way to derive those rights.
    var readableFilters = hasSomePermission(['monitor.nodes.view', 'configure.edit_smart_groups.view']);
    var readableRoles = hasSomePermission(['settings.user_management.permissions.view']);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 2 }, { children: t('pages.group_new.title') })), _jsx(GroupForm, { readableFilters: readableFilters, readableRoles: readableRoles, submitHandler: createGroup })] })));
}
// settings.user_management.permission.edit covers groups and roles
export default withAuthenticationRequired(AddGroup, { rights: ['settings.user_management.permissions.edit'] });
