var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useDeviceView } from '@/hooks/use-device-view';
import { useFormContainerContext } from '@/components/layout/forms';
import { requiredFieldError, invalidFieldError, minValueFieldError, maxValueFieldError, maxLengthFieldError } from '@/utils/field-validation-errors';
import { HOSTNAME_OR_ADDRESS_PATTERN, NUM_ONLY_PATTERN } from '@/constants/regex';
var HostnameMaxLength = 128;
export var PortMaxVal = 65535;
export var PortMinVal = 1;
export function HostnameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "hostname", rules: {
            required: requiredFieldError(t('pages.network_settings.form.fields.hostname.label')),
            pattern: { value: HOSTNAME_OR_ADDRESS_PATTERN, message: invalidFieldError(t('pages.network_settings.form.fields.hostname.label')) },
            maxLength: {
                value: HostnameMaxLength,
                message: maxLengthFieldError(t('pages.network_settings.form.fields.hostname.label'), HostnameMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "hostname", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.network_settings.form.fields.hostname.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
        } }));
}
export function HttpsPortField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "httpsPort", rules: {
            required: requiredFieldError(t('pages.network_settings.direct_access_port.fields.https_port.label')),
            pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.network_settings.direct_access_port.fields.https_port.label')) },
            min: { value: PortMinVal, message: minValueFieldError(t('pages.network_settings.direct_access_port.fields.https_port.label'), PortMinVal.toString()) },
            max: { value: PortMaxVal, message: maxValueFieldError(t('pages.network_settings.direct_access_port.fields.https_port.label'), PortMaxVal.toString()) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "https_port", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.network_settings.direct_access_port.fields.https_port.label'), _jsx(OGIconTooltip, { title: t('pages.network_settings.direct_access_port.fields.https_port.tooltip') })] }), sx: { maxWidth: isMobileView ? undefined : 150 }, type: "number" }, field, { onChange: function (event) {
                    field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                } })));
        } }));
}
export function SshPortField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "sshPort", rules: {
            required: requiredFieldError(t('pages.network_settings.direct_access_port.fields.ssh_port.label')),
            pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.network_settings.direct_access_port.fields.ssh_port.label')) },
            min: { value: PortMinVal, message: minValueFieldError(t('pages.network_settings.direct_access_port.fields.ssh_port.label'), PortMinVal.toString()) },
            max: { value: PortMaxVal, message: maxValueFieldError(t('pages.network_settings.direct_access_port.fields.ssh_port.label'), PortMaxVal.toString()) },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "ssh_port", disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.network_settings.direct_access_port.fields.ssh_port.label'), _jsx(OGIconTooltip, { title: t('pages.network_settings.direct_access_port.fields.ssh_port.tooltip') })] }), sx: { maxWidth: isMobileView ? undefined : 150 }, type: "number" }, field, { onChange: function (event) {
                    field.onChange(event.target.value ? parseInt(event.target.value, 10) : '');
                } })));
        } }));
}
