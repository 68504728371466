var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, createContext } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { SERVICES_NETOPS_OFFLINE_ROUTE, SERVICES_NETOPS_ONLINE_ROUTE, SERVICES_NETOPS_ROUTE } from '@/constants/routes';
import { pathEndsWith } from '@/utils/navigation';
import { NetopsOnlineTab } from '@/containers/netops-settings/online-tab';
import { NetopsOfflineTab } from '@/containers/netops-settings/offline-tab';
import { NetopsInstallerStatus, NetopsSyncStatus } from '@/containers/netops-settings/netops-settings-components';
import { useNetopsSyncStatus } from '@/api/services/netops/netops-sync';
import { useNetopsInstallerStatus } from '@/api/services/netops/netops-installer';
export var ONLINE_TAB_ID = 'netops-settings-online-tab';
export var OFFLINE_TAB_ID = 'netops-settings-offline-tab';
var TabValue;
(function (TabValue) {
    TabValue["ONLINE"] = "ONLINE";
    TabValue["OFFLINE"] = "OFFLINE";
})(TabValue || (TabValue = {}));
export var NetopsContext = createContext(false);
export function nextStatus(status, data) {
    if (data.status === '') {
        // Show success if status became '' after running i.e. we finished with no errors
        if (status.status === 'running') {
            return {
                inProgress: false,
                status: 'success',
            };
        }
        // Otherwise continue with current status
        return status;
    }
    return {
        status: data.status,
        inProgress: data.status === 'running' || data.status === 'pending',
        error_message: data.error_message ? data.error_message : undefined,
    };
}
function NetopsSettings() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useDocTitle(t('pages.netops_settings.title'));
    var _a = __read(useState({
        inProgress: false,
        status: '',
        error_message: '',
    }), 2), syncStatus = _a[0], setSyncStatus = _a[1];
    var _b = __read(useState({
        inProgress: false,
        status: '',
        error_message: '',
    }), 2), installerStatus = _b[0], setInstallerStatus = _b[1];
    var syncData = useNetopsSyncStatus({ refetchInterval: syncStatus.inProgress ? 5000 : undefined }).data;
    var installerData = useNetopsInstallerStatus({ refetchInterval: installerStatus.inProgress ? 5000 : undefined }).data;
    useEffect(function () {
        if (syncData) {
            setSyncStatus(function (currentSyncStatus) { return nextStatus(currentSyncStatus, syncData); });
        }
    }, [syncData]);
    useEffect(function () {
        if (installerData) {
            setInstallerStatus(function (currentInstallStatus) { return nextStatus(currentInstallStatus, installerData); });
        }
    }, [installerData]);
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.services.netops.edit');
    var tabValue = pathEndsWith(pathname, SERVICES_NETOPS_OFFLINE_ROUTE) ? TabValue.OFFLINE : TabValue.ONLINE;
    var innerNode = null;
    if (tabValue === TabValue.ONLINE) {
        innerNode = _jsx(NetopsOnlineTab, { permissions: { hasEditPermission: hasEditPermission } });
    }
    if (tabValue === TabValue.OFFLINE) {
        innerNode = _jsx(NetopsOfflineTab, { permissions: { hasEditPermission: hasEditPermission } });
    }
    useEffect(function () {
        if (pathEndsWith(pathname, SERVICES_NETOPS_ROUTE)) {
            navigate(SERVICES_NETOPS_ONLINE_ROUTE, { replace: true });
        }
    }, [pathname, navigate]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.netops_settings.title') })), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 2 }, { children: [_jsx(NetopsSyncStatus, { syncStatus: syncStatus }), _jsx(NetopsInstallerStatus, { installStatus: installerStatus }), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(Tabs, __assign({ value: tabValue, "aria-label": t('pages.netops_settings.tabs.label') }, { children: [_jsx(Tab, { component: RouterLink, to: SERVICES_NETOPS_ONLINE_ROUTE, label: t('pages.netops_settings.tabs.online.title'), value: TabValue.ONLINE, id: ONLINE_TAB_ID }), _jsx(Tab, { component: RouterLink, to: SERVICES_NETOPS_OFFLINE_ROUTE, label: t('pages.netops_settings.tabs.offline.title'), value: TabValue.OFFLINE, id: OFFLINE_TAB_ID })] })), _jsx(NetopsContext.Provider, __assign({ value: syncStatus.inProgress || installerStatus.inProgress }, { children: _jsx(TabPanel, __assign({ value: tabValue }, { children: innerNode })) }))] })) })] }))] })));
}
export default withAuthenticationRequired(NetopsSettings, { rights: ['settings.services.netops.view'] });
