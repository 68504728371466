var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { OGTable } from '@opengear/table';
import { OGPanel } from '@opengear/panel';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { useCopyRepeatData } from './copy-repeat-data-context';
import { useNodeUpgradeNodes } from '@/api/system/node-upgrade';
import { UpgradeNodeStatusIcon, UpgradeNodeInFailoverCell } from './node-upgrade-components';
var CANCELLED = 'CANCELLED';
export var NodeUpgradeNodesTable = memo(function (_a) {
    var nodeUpgrade = _a.nodeUpgrade;
    var t = useTranslation().t;
    var _b = __read(usePaginationModel('node_upgrade_nodes'), 3), paginationModel = _b[0], handlePaginationModelChange = _b[1], pageSizeOptions = _b[2];
    var _c = __read(useSortModel('name'), 3), defaultSortModel = _c[0], sortQueryParams = _c[1], handleSortModelChange = _c[2];
    var setCopyRepeatData = useCopyRepeatData().setCopyRepeatData;
    // Query options
    var queryOptions = useMemo(function () { return (__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize }, sortQueryParams)); }, [paginationModel, sortQueryParams]);
    var _d = useNodeUpgradeNodes(nodeUpgrade.id.toString(), queryOptions), data = _d.data, isLoading = _d.isLoading;
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.node_upgrade_nodes.length) {
            var copyRepeatData_1 = { nodes: [], nodeIds: '', family: '', firmwareId: '', isRepeat: false };
            var nodeIds_1 = [];
            data.node_upgrade_nodes.forEach(function (node) {
                var nodeId = "nodes-".concat(node.node_id);
                copyRepeatData_1.nodes.push({ nodeId: nodeId, allowInFailover: node.allow_in_failover });
                nodeIds_1.push(nodeId);
                if (!copyRepeatData_1.family) {
                    copyRepeatData_1.family = node.node_family;
                }
            });
            if (nodeUpgrade.firmware_id) {
                copyRepeatData_1.firmwareId = "node-firmware-".concat(nodeUpgrade.firmware_id);
            }
            copyRepeatData_1.name = nodeUpgrade.name;
            setCopyRepeatData(__assign(__assign({}, copyRepeatData_1), { nodeIds: nodeIds_1.join(',') }));
        }
    }, [data, nodeUpgrade, setCopyRepeatData]);
    // Derived table values
    var _e = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.node_upgrade_nodes) || [],
    }); }, [data, isLoading]), loading = _e.loading, rowCount = _e.rowCount, rows = _e.rows;
    var isCanceled = useMemo(function () { return nodeUpgrade.status === 'ABORTED_BY_LH' || nodeUpgrade.status === 'CANCELLED_BY_USER' || nodeUpgrade.status === 'CANCELLING'; }, [nodeUpgrade.status]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'node_id',
            headerName: t('pages.node_upgrade.nodes_table.headers.id'),
            valueGetter: function (_a) {
                var value = _a.value;
                return "nodes-".concat(value);
            },
            minWidth: 100,
        },
        { field: 'name', headerName: t('pages.node_upgrade.nodes_table.headers.name'), flex: 1, minWidth: 260 },
        {
            field: 'model',
            headerName: t('pages.node_upgrade.nodes_table.headers.model'),
            valueGetter: function (_a) {
                var value = _a.value;
                return value || 'N/A';
            },
            minWidth: 110,
        },
        {
            field: 'original_firmware',
            headerName: t('pages.node_upgrade.nodes_table.headers.firmware'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'upgrade_in_failover',
            headerName: t('pages.node_upgrade.nodes_table.headers.upgrade_in_failover'),
            sortable: false,
            minWidth: 180,
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(UpgradeNodeInFailoverCell, { tabIndex: tabIndex, checked: row.allow_in_failover }));
            },
        },
        {
            field: 'status',
            headerName: t('pages.node_upgrade.nodes_table.headers.status'),
            minWidth: 140,
            renderCell: function (_a) {
                var value = _a.value, row = _a.row;
                return (_jsx(UpgradeNodeStatusIcon, { status: isCanceled ? CANCELLED : value, finishTime: row.finish_time }));
            },
        },
    ]; }, [isCanceled, t]);
    return (_jsxs(OGPanel, __assign({ sx: { marginTop: 2 } }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", marginBottom: 2 }, { children: t('pages.node_upgrade.nodes_table.title') })), _jsx(OGTable, { id: "node-upgrade-nodes-table", "aria-label": t('pages.node_upgrade.nodes_table.label'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.node_upgrade.nodes_table.no_rows_message'), pagination: true, paginationMode: "server", paginationModel: paginationModel, pageSizeOptions: pageSizeOptions, onPaginationModelChange: handlePaginationModelChange, sortingMode: "server", onSortModelChange: handleSortModelChange, rowCount: rowCount, disableVirtualization: true, initialState: {
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                } })] })));
});
