var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WebUIIcon from '@mui/icons-material/Computer';
import { OGLink, OGLinkButton } from '@opengear/links';
import { OGTable } from '@opengear/table';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useNodes } from '@/api/nodes';
import { CellularDetailsModal } from '@/components/nodes/cellular-details-modal';
import { NodeIpDetails } from '@/components/nodes/node-ip-details';
import { CellularStatus } from '@/components/nodes/cellular-status';
import { NodeFilter, useNodeFilter } from '@/containers/filters/advanced-filters/node-filter';
import { NODES_ROUTE } from '@/constants/routes';
import { connectionStatusToQuery, NodeConnectionStatus, useJson } from '@/utils/query';
import { IQueryNodeField, IQueryType } from '../../../types/query/types';
import { FreeTextSearch, useFreeTextSearch } from '@/components/free-text-search';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { useCellhealthSettings } from '@/api/services/cellhealth';
import { formatLastOnline, getLastOnlineValue } from '@/containers/nodes/node-table-components';
// Column address renderers
export function renderAddress(params) {
    return params.row.interfaces.length > 0 ? (_jsxs(_Fragment, { children: [params.row.interfaces[0].ipv4_addr || params.row.interfaces[0].ipv6_addr, params.row.interfaces.length > 1 || (params.row.interfaces[0].ipv4_addr && params.row.interfaces[0].ipv6_addr) ? (_jsx(NodeIpDetails, { interfaces: params.row.interfaces, tabIndex: params.tabIndex })) : null] })) : (
    // If third party devices has an empty interface it only returns a `non_lhvpn_address` which can be ipv4, ipv6 or domain name format
    params.row.non_lhvpn_address);
}
var statusQuery = {
    items: [connectionStatusToQuery(NodeConnectionStatus.Connected), connectionStatusToQuery(NodeConnectionStatus.ConnectViaCellular)],
    type: IQueryType.or,
};
var statusFieldNames = [IQueryNodeField.name, IQueryNodeField.lhvpnAddress, IQueryNodeField.macAddress];
export var NodeStatusConnectedTable = memo(function (_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var canViewCellHealth = permissions.canViewCellHealth, canViewNode = permissions.canViewNode, editableNodeFilters = permissions.editableNodeFilters;
    // Get cell health settings to render cell column conditionally
    var cellHealthSettings = useCellhealthSettings().data;
    // manage cell details popup
    var _b = __read(useState(), 2), cellNodeDetails = _b[0], setCellNodeDetails = _b[1];
    // Table controls - Server side available query params
    var _c = __read(usePaginationModel('dashboard_nodes_connected'), 3), paginationModel = _c[0], handlePaginationModelChange = _c[1], pageSizeOptions = _c[2];
    var _d = __read(useSortModel('last_updated'), 3), defaultSortModel = _d[0], sortQueryParams = _d[1], handleSortModelChange = _d[2];
    var showCellHealth = useMemo(function () { return canViewCellHealth && !!(cellHealthSettings === null || cellHealthSettings === void 0 ? void 0 : cellHealthSettings.cell_health_enabled); }, [canViewCellHealth, cellHealthSettings === null || cellHealthSettings === void 0 ? void 0 : cellHealthSettings.cell_health_enabled]);
    // Node filter
    var _e = useNodeFilter(), nodeFilter = _e.nodeFilter, setNodeFilter = _e.setNodeFilter;
    // Free text search
    var _f = useFreeTextSearch(statusFieldNames), textQueryObject = _f.textQueryObject, dispatchTextQuery = _f.dispatchTextQuery;
    // TODO - CDM-183 - extend logic
    // Aggregate all query items into one json query string
    var json = useJson(statusQuery, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject, textQueryObject);
    var queryParams = useMemo(function () { return (__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize, json: json }, sortQueryParams)); }, [json, paginationModel, sortQueryParams]);
    // Get Nodes Fetch
    var _g = useNodes(queryParams), data = _g.data, isLoading = _g.isLoading;
    var _h = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.nodes) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.nodes, isLoading]), loading = _h.loading, rowCount = _h.rowCount, rows = _h.rows;
    // cell details handler
    var handleCellModalClose = useCallback(function () {
        setCellNodeDetails(undefined);
    }, []);
    // Name cell render
    var renderNameCell = useCallback(function (params) { return (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, variant: "standard", to: "".concat(NODES_ROUTE, "/").concat(params.id) }, { children: params.value }))); }, []);
    // Web Access Button
    var renderWebAccessCell = useCallback(function (params) {
        var disabled = params.row.product !== 'opengear';
        return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: disabled ? t('pages.dashboard.nodes.table.buttons.web_access_tooltip_disabled') : t('pages.dashboard.nodes.table.buttons.web_access_tooltip') }, { children: _jsx("div", { children: _jsx(OGLinkButton, { disabled: disabled, "aria-label": "".concat(params.id, " ").concat(t('pages.dashboard.nodes.table.buttons.web_access')), external: true, startIcon: _jsx(WebUIIcon, {}), sx: { minHeight: 20, minWidth: 20, paddingX: 0.5, paddingY: 0 }, tabIndex: params.tabIndex, target: "_self", to: "/".concat(params.id, "/") }) }) })));
    }, [t]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'connection_status',
            headerName: t('pages.dashboard.nodes.table.headers.state'),
            maxWidth: 1,
            renderCell: function () { return (_jsx(SuccessIcon, { fontSize: "medium", color: "success", sx: { height: 21, marginLeft: 1 }, titleAccess: t('components.nodes.state_label.connected') })); },
            minWidth: 85,
        },
        { field: 'name', headerName: t('pages.dashboard.nodes.table.headers.name'), renderCell: renderNameCell, minWidth: 160, flex: 1 },
        { field: 'lhvpn_address', headerName: t('pages.dashboard.nodes.table.headers.lhvpn_address'), sortable: false, minWidth: 120, flex: 1 },
        {
            field: 'address',
            headerName: t('pages.dashboard.nodes.table.headers.address'),
            sortable: false,
            minWidth: 200,
            resizable: true,
            renderCell: renderAddress,
        },
        { field: 'mac_address', headerName: t('pages.dashboard.nodes.table.headers.mac_address'), sortable: false, minWidth: 150, flex: 1 },
        {
            field: 'cellular_health',
            headerName: t('pages.dashboard.nodes.table.headers.cell_status'),
            renderCell: function (_a) {
                var row = _a.row, tabIndex = _a.tabIndex;
                return (_jsx(CellularStatus, { row: row, tabIndex: tabIndex, setOpen: setCellNodeDetails }));
            },
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'last_updated',
            headerName: t('pages.dashboard.nodes.table.headers.last_updated'),
            valueGetter: getLastOnlineValue,
            valueFormatter: formatLastOnline,
            minWidth: 240,
        },
        { field: 'web_access', headerName: '', headerClassName: 'FocusDisabled', maxWidth: 1, align: 'center', sortable: false, renderCell: renderWebAccessCell },
    ]; }, [renderNameCell, renderWebAccessCell, t]);
    return (_jsxs(_Fragment, { children: [cellNodeDetails && _jsx(CellularDetailsModal, { nodeDetails: cellNodeDetails, open: !!cellNodeDetails, onClose: handleCellModalClose }), _jsxs(Stack, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 1, marginBottom: 1, justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(NodeFilter, { filter: nodeFilter, setFilter: setNodeFilter, permissions: { hasSmartgroupsEditAccess: editableNodeFilters } }), _jsx(FreeTextSearch, { onSearch: dispatchTextQuery, useDebounce: false, translations: { tooltip: t('components.nodes.text_search.tooltip') } })] })), _jsx(Box, { children: _jsx(OGTable, { id: "connected-nodes-table", "aria-label": t('pages.dashboard.nodes.table.label.connected'), autoHeight: true, columns: columns, rows: rows, loading: loading, pageSizeOptions: pageSizeOptions, noRowsMessage: t('pages.dashboard.nodes.table.no_rows_message.connected'), pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, columnVisibilityModel: {
                                web_access: canViewNode,
                                cellular_health: showCellHealth,
                            }, initialState: {
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                            } }) })] })] }));
});
