var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useTranslation } from 'react-i18next';
export function convertFilterPeriodToFormData(value) {
    var formDataLastXDays;
    if (value === 7) {
        formDataLastXDays = 'one_week';
    }
    if (value === 14) {
        formDataLastXDays = 'two_weeks';
    }
    if (value === 31) {
        formDataLastXDays = 'one_month';
    }
    if (value === 92) {
        formDataLastXDays = 'three_months';
    }
    if (value === 183) {
        formDataLastXDays = 'six_months';
    }
    return formDataLastXDays;
}
export function convertFormDataToFilterPeriod(value) {
    var filterDataLastXDaysOption;
    if (value === 'one_week') {
        filterDataLastXDaysOption = 7;
    }
    if (value === 'two_weeks') {
        filterDataLastXDaysOption = 14;
    }
    if (value === 'one_month') {
        filterDataLastXDaysOption = 31;
    }
    if (value === 'three_months') {
        filterDataLastXDaysOption = 92;
    }
    if (value === 'six_months') {
        filterDataLastXDaysOption = 183;
    }
    return filterDataLastXDaysOption;
}
export function useTransformFirmwareUpgradeFilterOptions(filterOptions) {
    var t = useTranslation().t;
    var autocompleteOptions = new Map();
    if ('last_x_days' in filterOptions) {
        autocompleteOptions.set('last_x_days', {
            key: 'last_x_days',
            label: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.label'),
            value: convertFilterPeriodToFormData(filterOptions.last_x_days),
        });
    }
    if ('status' in filterOptions) {
        autocompleteOptions.set('status', {
            key: 'status',
            label: t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.label'),
            value: filterOptions.status,
        });
    }
    return __spreadArray([], __read(autocompleteOptions.values()), false);
}
export function transformFirmwareUpgradeFilterAutocompleteOption(option) {
    var key = option.key, value = option.value;
    var formData = {};
    if (!value)
        return formData;
    if (key === 'status') {
        formData.status = value;
    }
    if (key === 'last_x_days') {
        formData.last_x_days = value;
    }
    return formData;
}
export function transformFirmwareUpgradeFormData(_a) {
    var last_x_days = _a.last_x_days, status = _a.status;
    var filterOptions = {};
    if (status)
        filterOptions.status = status;
    if (last_x_days)
        filterOptions.last_x_days = convertFormDataToFilterPeriod(last_x_days);
    return filterOptions;
}
export function useFormatFirmwareUpgradeFilterPillLabel(_a) {
    var key = _a.key, label = _a.label, value = _a.value;
    var t = useTranslation().t;
    var valueLabel = '';
    if (key === 'status' && value) {
        valueLabel = t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.status.select_options')[value];
    }
    if (key === 'last_x_days' && value) {
        valueLabel = t('pages.firmware_upgrade.tabs.tasks.status_period_filter.options.period.select_options')[value];
    }
    return valueLabel ? "".concat(label, ": ").concat(valueLabel) : label;
}
