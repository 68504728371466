var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import { OG_GREY_M, OG_GREY_ML } from '@opengear/theme/colors';
var defaultColors = ['#F044C3', '#A1C9CB'];
var defaultSize = 100;
var defaultNoData = [{ id: 'no_data', value: 1 }];
var defaultNoDataColors = function (mode) { return (mode === 'dark' ? [OG_GREY_M] : [OG_GREY_ML]); };
export function OGDonut(props) {
    var _a;
    var children = props.children, _b = props.colors, colors = _b === void 0 ? defaultColors : _b, data = props.data, _c = props.isEmpty, isEmpty = _c === void 0 ? false : _c, sx = props.sx, id = props.id, _d = props.size, size = _d === void 0 ? defaultSize : _d;
    return (_jsx(Box, __assign({ "data-testid": id, minWidth: size, width: size, height: size, sx: __assign({}, sx) }, { children: _jsx(PieChart, __assign({ colors: isEmpty ? defaultNoDataColors : colors, tooltip: { trigger: isEmpty ? 'none' : 'item' }, slotProps: { legend: { hidden: true } }, series: [
                {
                    data: isEmpty ? defaultNoData : data,
                    innerRadius: size * 0.36,
                    outerRadius: size * 0.47,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    startAngle: 0,
                    endAngle: 360,
                    cx: size * 0.46,
                    cy: size * 0.46,
                },
            ], sx: (_a = {},
                _a["& .".concat(pieArcLabelClasses.root)] = {
                    fill: 'white',
                    fontWeight: 'bold',
                },
                _a) }, { children: children })) })));
}
