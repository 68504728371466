var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import format from 'string-format';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { WarningBanner } from '@/components/warning-banner';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { useAlertNotification } from '@/hooks/alert-notification';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useNodeUpgrade } from '@/api/system/node-upgrade';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NodeUpgradeTaskStatus } from '@/components/node-tools/firmware-upgrade/node-upgrade/node-upgrade-task-status';
import { NodeUpgradeActionBar } from '@/components/node-tools/firmware-upgrade/node-upgrade/node-upgrade-action-bar';
import { NodeUpgradeNodesTable } from '@/components/node-tools/firmware-upgrade/node-upgrade/node-upgrade-nodes-table';
import { CopyRepeatDataProvider } from '@/components/node-tools/firmware-upgrade/node-upgrade/copy-repeat-data-context';
import { formatDateTimestamp } from '@/utils/datetime';
import { DATETIME_FORMAT } from '@/constants/formats';
export var NODE_UPGRADE_LOADING_TEST_ID = 'node-upgrade-loading';
function NodeUpgrade() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var alertNotification = useAlertNotification().alertNotification;
    var navigate = useNavigate();
    var pageTitle = t('pages.node_upgrade.title');
    useDocTitle(pageTitle);
    var upgradeId = useParams().upgradeId;
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404 && upgradeId) {
            alertNotification(format(t('api.node_upgrade.get.not_found.message'), upgradeId), { persistOnUnmount: true });
        }
        if (responseStatus === 403 && upgradeId) {
            alertNotification(format(t('api.node_upgrade.get.forbidden.message'), upgradeId), { persistOnUnmount: true });
        }
        navigate(NOT_FOUND_ROUTE);
    }, [alertNotification, navigate, t, upgradeId]);
    var _a = useNodeUpgrade(upgradeId, { notFoundCallback: notFoundCallback }), data = _a.data, isLoading = _a.isLoading;
    var hasEditPermission = hasPermission('settings.services.node_upgrade.edit');
    // Early return if loading or retrying
    if (isLoading)
        return _jsx(LoadingBackdrop, { "data-testid": NODE_UPGRADE_LOADING_TEST_ID });
    // Early return if data is not available
    if (!data)
        return null;
    var creator = format(t('pages.node_upgrade.created_by'), data.creator);
    var creationTime = data.creation_time ? format(t('pages.node_upgrade.created_on'), formatDateTimestamp(data.creation_time, DATETIME_FORMAT)) : '';
    return (_jsx(Box, __assign({ margin: 2 }, { children: _jsxs(CopyRepeatDataProvider, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", gap: 1, marginBottom: 2 }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true, marginBottom: 1 }, { children: data.name })), _jsx(Typography, { children: "".concat(creator).concat(creationTime) })] }), _jsx(Box, { flexGrow: 1 }), _jsx(NodeUpgradeActionBar, { nodeUpgrade: data, hasEditPermission: hasEditPermission })] })), data.status === 'FAILURE' && !data.firmware_id && (_jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.node_upgrade.actions.repeat.warning.header'), warningMessage: _jsx(Typography, { children: t('pages.node_upgrade.actions.repeat.warning.message') }), warningColor: "warning", bannerCode: "firmware-upgrade-repeat-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } })), _jsx(NodeUpgradeTaskStatus, { nodeUpgrade: data }), _jsx(NodeUpgradeNodesTable, { nodeUpgrade: data })] }) })));
}
export default withAuthenticationRequired(NodeUpgrade, {
    rights: ['settings.services.node_upgrade.view'],
});
