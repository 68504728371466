var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { InvalidQueryContent } from '@/containers/filters/advanced-filters/filter-components';
export var RESOURCE_FILTER_INVALID_TITLE_ID = 'resource-filter-invalid-title';
function ResourceFilterInvalidModalContent(_a) {
    var onClose = _a.onClose, onDelete = _a.onDelete, selectedFilter = _a.selectedFilter;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, __assign({ id: RESOURCE_FILTER_INVALID_TITLE_ID }, { children: t('components.resources.filter.invalid_modal.title') })), _jsxs(DialogContent, { children: [_jsx(TextField, { id: "filter-name", name: "name", label: t('components.resources.filter.name_label'), fullWidth: true, disabled: true, value: selectedFilter.name }), _jsx(InvalidQueryContent, { query: selectedFilter.query })] }), _jsx(Divider, {}), _jsxs(DialogActions, __assign({ sx: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } }, { children: [_jsx(OGButton, __assign({ startIcon: _jsx(DeleteOutlineIcon, { fontSize: "small" }), variant: "secondary", onClick: onDelete }, { children: t('global.buttons.delete') })), _jsx(OGButton, __assign({ variant: "borderless", onClick: onClose }, { children: t('global.buttons.cancel') }))] }))] }));
}
export function ResourceFilterInvalidModal(_a) {
    var open = _a.open, onClose = _a.onClose, onDelete = _a.onDelete, selectedFilter = _a.selectedFilter;
    return (_jsx(OGDialog, __assign({ "aria-labelledby": RESOURCE_FILTER_INVALID_TITLE_ID, open: open, onClose: onClose }, { children: selectedFilter ? _jsx(ResourceFilterInvalidModalContent, { onClose: onClose, onDelete: onDelete, selectedFilter: selectedFilter }) : null })));
}
