var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGResourcesIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-3 -4.5 24 24' }, props), { children: _jsx("svg", __assign({ fill: "none" }, { children: _jsx("path", { d: "M4.13397 0.903846H12.5641C13.0304 0.903846 13.4071 1.30775 13.4071 1.80769V2.71154H14.2502V1.80769C14.2502 0.810637 13.4941 0 12.5641 0H4.13397C3.20401 0 2.44793 0.810637 2.44793 1.80769V8.13462H1.26771C0.988462 8.13462 0.761902 8.37753 0.761902 8.67692C0.761902 9.87452 1.66814 10.8462 2.78514 10.8462H10.0351V9.94231H2.78514C2.25035 9.94231 1.79987 9.561 1.65497 9.03846H2.44793H3.29095H10.0351V8.13462H3.29095V1.80769C3.29095 1.30775 3.66767 0.903846 4.13397 0.903846ZM11.7211 4.97115C11.7211 4.7226 11.9108 4.51923 12.1426 4.51923H16.3577C16.5895 4.51923 16.7792 4.7226 16.7792 4.97115V13.1058C16.7792 13.3543 16.5895 13.5577 16.3577 13.5577H12.1426C11.9108 13.5577 11.7211 13.3543 11.7211 13.1058V4.97115ZM10.8781 4.97115V13.1058C10.8781 13.8543 11.4445 14.4615 12.1426 14.4615H16.3577C17.0558 14.4615 17.6222 13.8543 17.6222 13.1058V4.97115C17.6222 4.22266 17.0558 3.61539 16.3577 3.61539H12.1426C11.4445 3.61539 10.8781 4.22266 10.8781 4.97115Z", fill: "currentColor" }) })) })));
}
