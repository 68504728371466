var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var DEFAULT_MOCK_SYSTEM_SMF = {
    enabled: true,
    ospf_internal_area: '0.0.0.0',
};
export var DEFAULT_MOCK_SYSTEM_SMF_RESPONSE = {
    system_smf: __assign({}, DEFAULT_MOCK_SYSTEM_SMF),
};
export var SYSTEM_SMF_PATH = '/system/smf';
