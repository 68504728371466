var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export function LicenseFeature(_a) {
    var licenseTier = _a.licenseTier, subscription = _a.subscription;
    var t = useTranslation().t;
    var additional = subscription.features.additional;
    return licenseTier === 'legacy' && additional && Object.keys(additional).length > 0 ? (_jsxs(Box, __assign({ sx: { marginY: 2 } }, { children: [_jsx(Typography, __assign({ marginBottom: 1, fontWeight: 600 }, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.features.enabled_features') })), additional.multipleinstance && _jsx(Typography, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.features.multiple_instance') }), additional.thirdpartynodes && _jsx(Typography, { children: t('pages.subscriptions.tabs.subscriptions.subscription_item.features.third_party_nodes') })] }))) : null;
}
