import MuiMenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { OG_FOCUS_BOX_SHADOW } from '../theme/colors';
export var OGNavMenuItem = styled(MuiMenuItem, {
    shouldForwardProp: function (propName) {
        return propName !== 'level';
    },
})(function (_a) {
    var theme = _a.theme, _b = _a.level, level = _b === void 0 ? 0 : _b;
    return ({
        'borderRadius': theme.shape.borderRadius,
        'color': theme.palette.text.primary,
        'cursor': 'pointer',
        'fontSize': '1.75rem',
        'letter': 0.04,
        'lineHeight': 1,
        'marginBottom': theme.spacing(1),
        'marginTop': theme.spacing(1),
        'minWidth': 220,
        'paddingBottom': theme.spacing(0.75),
        'paddingLeft': theme.spacing(2 + level),
        'paddingRight': theme.spacing(3 - level),
        'paddingTop': theme.spacing(0.75),
        '&.Mui-selected': {
            background: 'none',
            color: theme.palette.text.highlight,
            fontWeight: 600,
        },
        '&.Mui-selected:focus-visible, :focus-visible': {
            boxShadow: OG_FOCUS_BOX_SHADOW,
            background: 'none',
        },
        '&.Mui-selected:hover, :hover': {
            background: theme.palette.background.hover,
        },
        '&.Mui-selected:active, :active': {
            background: theme.palette.background.active,
            color: theme.palette.text.primary,
        },
    });
});
