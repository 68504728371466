import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Radio from '@mui/material/Radio';
export function RadioButtonCell(_a) {
    var ariaLabel = _a.ariaLabel, checked = _a.checked, onChange = _a.onChange, tabIndex = _a.tabIndex, value = _a.value;
    var handleKeyDown = useCallback(function (event) {
        if (event.key === ' ') {
            event.stopPropagation();
            onChange(value);
        }
    }, [onChange, value]);
    return (_jsx(Radio, { tabIndex: tabIndex, inputProps: { 'aria-label': ariaLabel }, onKeyDown: function (event) {
            handleKeyDown(event);
        }, checked: checked, value: value }));
}
