var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useLayoutEffect } from 'react';
/*
  Store default and template titles as local variables.
  For more complex requirements such as nested title or dynamic templates,
  'react-helmet-async' should be used.
 */
var defaultTitle = 'Lighthouse';
var titleTemplate = "%s - ".concat(defaultTitle);
export var useDocTitle = function (title, overrideOptions) {
    if (overrideOptions === void 0) { overrideOptions = {}; }
    var _a = __assign({ disableTemplate: false, resetOnMount: true }, overrideOptions), disableTemplate = _a.disableTemplate, resetOnMount = _a.resetOnMount;
    // updates document title as soon as possible
    useLayoutEffect(function () {
        var newTitle = title;
        if (!disableTemplate && titleTemplate) {
            newTitle = titleTemplate.replace(/%s/g, title);
        }
        if (newTitle !== document.title) {
            document.title = newTitle;
        }
        // Cleanup when components using this hook unmount
        return function () {
            if (resetOnMount && defaultTitle !== document.title) {
                document.title = defaultTitle;
            }
        };
    }, [title, disableTemplate, resetOnMount]);
};
