var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import dayjs from 'dayjs';
import { RETENTION_PERIOD, START_TYPE, REPEAT } from './constants';
/**
 * Transform the rention period value returned by Lipy to a format usable by the Node Backup Form
 */
export var parseRetentionPeriod = function (value) {
    switch (value) {
        case 0:
            return { period: RETENTION_PERIOD.none, days: 0 };
        case -1:
            return { period: RETENTION_PERIOD.forever, days: 0 };
        default:
            return { period: RETENTION_PERIOD.days, days: value };
    }
};
/**
 * Transform the retention period value used by the form state to a format usable by the Lipy API
 */
export var transformRetentionPeriod = function (config) {
    switch (config.period) {
        case RETENTION_PERIOD.none:
            return 0;
        case RETENTION_PERIOD.forever:
            return -1;
        default:
            return config.days;
    }
};
/**
 * Transform the frequency value returned by Lipy to a format usable by the Node Backup Form
 */
export var parseStartTime = function (value) {
    switch (value) {
        case 0:
            return { start_type: START_TYPE.immediately, start_date: dayjs().utc() };
        default:
            return { start_type: START_TYPE.set_time, start_date: dayjs.unix(value).utc() };
    }
};
/**
 * Transform the frequency value used by the form state to a format usable by the Lipy API
 */
export var transformStartTime = function (config) {
    switch (config.start_type) {
        case START_TYPE.set_time:
            return config.start_date.unix();
        case START_TYPE.immediately:
        default:
            return 0;
    }
};
/**
 * Transform the frequency and unit values returned by Lipy to a format usable by the Node Backup Form
 */
export var parseFrequency = function (unit, frequency) {
    var frequencyUnit;
    switch (unit) {
        case 'once':
        case 'days':
            frequencyUnit = 'day(s)';
            break;
        default:
            frequencyUnit = unit;
            break;
    }
    if (unit === 'once') {
        return {
            repeat: REPEAT.once,
            frequency_unit: frequencyUnit,
            frequency: 0,
        };
    }
    return {
        repeat: REPEAT.interval,
        frequency_unit: frequencyUnit,
        frequency: frequency,
    };
};
/**
 * Transform the frequency values used by the form state to a format usable by the Lipy API
 */
export var transformFrequency = function (config) {
    var repeat = config.repeat, frequency = config.frequency, frequencyUnit = config.frequency_unit;
    var unit = repeat === 'once' ? repeat : frequencyUnit;
    if (unit === 'once') {
        return [unit, 0];
    }
    return [unit, frequency];
};
/**
 * Transforms a lipy node backup response object into a NodeBackupFormState object
 */
export var transformRequestToFormState = function (data) {
    var retentionPeriod = parseRetentionPeriod(data.retention_period);
    var startTime = parseStartTime(data.start_date);
    var frequencyConfig = parseFrequency(data.frequency_unit, data.frequency);
    return {
        enabled: data.enabled,
        number_of_backups: data.number_of_backups,
        retention_period: retentionPeriod.period,
        retention_period_days: retentionPeriod.days,
        location: data.location,
        available_space: data.available_space,
        total_space: data.total_space,
        start_type: startTime.start_type,
        start_date: startTime.start_date,
        server_time: data.server_time,
        repeat: frequencyConfig.repeat,
        frequency_unit: frequencyConfig.frequency_unit,
        frequency: frequencyConfig.frequency,
    };
};
/**
 * Transforms a NodeBackupFormState object into a lipy Node Backup request object
 */
export var transformFormStateToRequest = function (formData) {
    var _a = __read(transformFrequency({ repeat: formData.repeat, frequency: formData.frequency, frequency_unit: formData.frequency_unit }), 2), frequencyUnit = _a[0], frequency = _a[1];
    return {
        enabled: formData.enabled,
        number_of_backups: formData.number_of_backups,
        retention_period: transformRetentionPeriod({ period: formData.retention_period, days: formData.retention_period_days }),
        location: formData.location,
        start_date: transformStartTime({ start_type: formData.start_type, start_date: formData.start_date }),
        frequency: frequency,
        frequency_unit: frequencyUnit,
    };
};
