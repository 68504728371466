var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import { OpengearLogo } from '@opengear/logos';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import { OGLink } from '@opengear/links';
import { useDeviceView } from '@/hooks/use-device-view';
import SkipToContentButton from '@/components/skip-to-content-button';
import { NodeEnrollmentModal } from '@/containers/nodes/node-enrollment-modal';
import { SystemInformationMenu } from '@/containers/menus/system-information-menu';
import { HelpMenu } from '@/containers/menus/help-menu';
import { AppVersion } from './app-version';
var MAIN_CONTENT_ID = 'main-content';
export function NavigationBar(_a) {
    var onSidebarOpen = _a.onSidebarOpen;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(AppBar, { children: _jsxs(Toolbar, __assign({ sx: { justifyContent: 'space-between' } }, { children: [_jsx(Box, { children: isMobileView ? (_jsx(OGButton, __assign({ "aria-label": t('navigation.appbar.open_menu'), onClick: onSidebarOpen, variant: "borderless", sx: { paddingX: 1, paddingY: 0.75 } }, { children: _jsx(MenuIcon, {}) }))) : (_jsx(OGLink, __assign({ to: "/dashboard", className: "pseudo-active", variant: ['noItalic', 'noUnderline'] }, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center" }, { children: [_jsx(OpengearLogo, {}), _jsx(AppVersion, {})] })) }))) }), _jsx(SkipToContentButton, { focusContainerId: MAIN_CONTENT_ID }), _jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }, { children: [_jsx(NodeEnrollmentModal, {}), _jsx(HelpMenu, {}), _jsx(SystemInformationMenu, {})] }))] })) }));
}
