var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { OGPanel } from '@opengear/panel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { CancelButton, FormActions, FormContainer, SubmitButton } from '@/components/layout/forms';
import { NameField, DescriptionField, ScriptTimeoutField, ScriptUploadField } from './script-form-controls';
import { SCRIPT_TEMPLATES_ROUTE } from '@/constants/routes';
import { useCreateTemplate, useUpdateTemplate } from '@/api/template/templates';
import { transformFormStateToCreateRequest, transformFormStateToUpdateRequest } from './transformers';
import { ScriptTable } from './script-table';
import { useFormSync } from '@/hooks/use-form-sync';
import { DeleteTemplateModal } from '../delete-template-modal';
export function ScriptForm(_a) {
    var _this = this;
    var templateId = _a.templateId, data = _a.data, permissions = _a.permissions;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var createTemplate = useCreateTemplate();
    var updateTemplate = useUpdateTemplate();
    var methods = useForm({ mode: 'onTouched', defaultValues: data });
    var _b = __read(methods.watch(['scriptChecksum', 'scriptSize', 'script']), 3), scriptChecksum = _b[0], scriptSize = _b[1], script = _b[2];
    useFormSync(methods.formState.defaultValues, data, methods.reset, t('pages.config_templates.tabs.script.form.reset_warning.header'));
    var rows = useMemo(function () {
        if (!scriptChecksum || !scriptSize || script)
            return [];
        return [
            {
                id: 'script',
                checksum: scriptChecksum,
                size: scriptSize,
            },
        ];
    }, [scriptChecksum, scriptSize, script]);
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var response, request, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!templateId) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateTemplate(transformFormStateToUpdateRequest(templateId, formData))];
                case 1:
                    response = _b.sent();
                    return [3 /*break*/, 6];
                case 2:
                    request = transformFormStateToCreateRequest(formData);
                    if (!request) return [3 /*break*/, 4];
                    return [4 /*yield*/, createTemplate(request)];
                case 3:
                    _a = _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    _a = undefined;
                    _b.label = 5;
                case 5:
                    response = _a;
                    _b.label = 6;
                case 6:
                    if (response) {
                        navigate(SCRIPT_TEMPLATES_ROUTE);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [createTemplate, updateTemplate, navigate, templateId]);
    var onCancel = useCallback(function () {
        navigate(SCRIPT_TEMPLATES_ROUTE);
    }, [navigate]);
    return (_jsxs(_Fragment, { children: [_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ methods: methods, onSubmit: onSubmit, permissions: permissions, mode: templateId ? 'update' : 'create' }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1, alignItems: "center" }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", sx: { textTransform: 'uppercase' }, marginBottom: 2 }, { children: t('pages.config_templates.tabs.script.form.sections.template_details') })), _jsx(Box, { flexGrow: 1 }), templateId && _jsx(DeleteTemplateModal, { editable: permissions.hasEditPermission, templateId: templateId, redirectUrl: SCRIPT_TEMPLATES_ROUTE })] })), _jsx(NameField, {}), _jsx(DescriptionField, {}), _jsx(ScriptTimeoutField, {}), _jsx(Typography, __assign({ component: "h3", variant: "h3", sx: { textTransform: 'uppercase' }, marginY: 2 }, { children: t('pages.config_templates.tabs.script.form.sections.upload_script_file') })), rows.length ? _jsx(ScriptTable, { rows: rows }) : _jsx(ScriptUploadField, {}), _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: onCancel }), _jsx(SubmitButton, { children: t('pages.config_templates.tabs.script.form.actions.create') })] })] })) }), _jsx(Typography, __assign({ component: "div", marginTop: 2 }, { children: t('pages.config_templates.tabs.script.warning') }))] }));
}
