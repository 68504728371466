var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGLinkButton } from '@opengear/links';
import format from 'string-format';
import Tooltip from '@mui/material/Tooltip';
import { OGSshIcon } from '@opengear/icons/protocol/ssh';
export function SshAccessCell(_a) {
    var port = _a.port, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    if (port.mode !== 'consoleServer' || !port.proxied_ssh_url) {
        return null;
    }
    var disabled = port.runtime_status.connection_status !== 'connected';
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: disabled ? t('global.ports.ssh_access_tooltip_disabled') : t('global.ports.ssh_access_tooltip') }, { children: _jsx("div", { children: _jsx(OGLinkButton, { "aria-label": format(t('global.ports.ssh_access'), port.port_csid), external: true, disabled: disabled, startIcon: _jsx(OGSshIcon, { sx: { width: '27px', height: '27px' } }), sx: { maxHeight: 28, maxWidth: 28, paddingX: 0.5, paddingY: 0 }, tabIndex: tabIndex, target: "_self", to: "".concat(port.proxied_ssh_url) }) }) })));
}
