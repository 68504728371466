var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useTranslation } from 'react-i18next';
import { useCellhealthSettings } from '@/api/services/cellhealth';
import { useFormSync } from '@/hooks/use-form-sync';
import { UpdateForm } from '@/components/layout/update-form';
import { invalidFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
var SLIDER_MAX_WIDTH = 800;
export var MINUTES = (_a = __read(['min(s)', 'hour(s)', 'day(s)'], 3), _a[0]), HOURS = _a[1], DAYS = _a[2];
export var FreqMinValue = 1;
export var FreqMaxValue = 999999999;
export function CellHealthReportingForm(_a) {
    var _this = this;
    var data = _a.data, editable = _a.editable, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var isFetching = useCellhealthSettings().isFetching;
    var _b = useForm({ defaultValues: data, mode: 'onTouched' }), control = _b.control, 
    // must subscribe to isDirty & dirtyFields of formState proxy for keepDirtyValues to behave correctly in reset function
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _c = _b.formState, defaultValues = _c.defaultValues, isValid = _c.isValid, isSubmitting = _c.isSubmitting, isDirty = _c.isDirty, dirtyFields = _c.dirtyFields, createSubmitHandler = _b.handleSubmit, reset = _b.reset, resetField = _b.resetField, watch = _b.watch;
    // responsible for alert the user of data changes
    useFormSync(defaultValues, data, reset, t('pages.cell_health_reporting.form.updated'), isFetching);
    var _d = __read(watch(['cell_health_enabled', 'interval_frequency', 'quality_range_quarter23']), 3), enabled = _d[0], intervalFrequency = _d[1], qualityRangeQuarter23 = _d[2];
    var disabled = useMemo(function () { return !editable || !enabled; }, [editable, enabled]);
    var _e = __read(qualityRangeQuarter23, 2), qualityRangeQuarter2 = _e[0], qualityRangeQuarter3 = _e[1];
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    var handleSubmit = useMemo(function () {
        return createSubmitHandler(function (updateData) { return __awaiter(_this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, onSubmit(updateData.cell_health_enabled ? updateData : { cell_health_enabled: false })];
                    case 1:
                        result = _a.sent();
                        if (result)
                            reset(result);
                        return [2 /*return*/];
                }
            });
        }); });
    }, [createSubmitHandler, onSubmit, reset]);
    return (_jsxs(UpdateForm, __assign({ "aria-label": t('pages.cell_health_reporting.form.label'), padding: 2, dirty: isDirty, editable: !!editable, submitting: isSubmitting, valid: isValid, onReset: handleReset, onSubmit: handleSubmit }, { children: [_jsx(FormControl, __assign({ margin: enabled ? 'dense' : 'none' }, { children: _jsx(Controller, { control: control, name: "cell_health_enabled", render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                        return (_jsxs(ToggleButtonGroup, __assign({ disabled: !editable, exclusive: true, value: JSON.stringify(value), onChange: function (event, v) {
                                if (!v)
                                    return;
                                var parsedValue = JSON.parse(v);
                                if (!parsedValue) {
                                    resetField('quality_range_quarter23');
                                    resetField('interval_frequency');
                                    resetField('interval_unit');
                                    resetField('connectivity_test_enabled');
                                }
                                onChange(parsedValue);
                            } }, field, { children: [_jsx(ToggleButton, __assign({ value: "false" }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: "true" }, { children: t('global.buttons.enabled.enabled') }))] })));
                    } }) })), enabled && (_jsxs(_Fragment, { children: [_jsxs(FormControl, __assign({ margin: "normal" }, { children: [_jsx(FormLabel, { children: t('pages.cell_health_reporting.form.fields.signal_quality_range.label') }), _jsx(Box, __assign({ paddingX: 3, paddingBottom: 1, paddingTop: 9, maxWidth: SLIDER_MAX_WIDTH }, { children: _jsx(Controller, { control: control, name: "quality_range_quarter23", rules: { validate: function (_a) {
                                            var _b = __read(_a, 2), range2 = _b[0], range3 = _b[1];
                                            return range2 !== range3;
                                        } }, render: function (_a) {
                                        var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                        return (_jsx(Slider, __assign({ getAriaLabel: function (index) { return t('pages.cell_health_reporting.form.fields.signal_quality_range.labels')[index]; }, disabled: disabled, sx: {
                                                '& .MuiSlider-rail': {
                                                    background: function (theme) {
                                                        return [
                                                            'linear-gradient(to right',
                                                            "".concat(theme.palette.error.main, " 0%"),
                                                            "".concat(theme.palette.error.main, " ").concat(value[0] / 0.98, "%"),
                                                            "rgba(0,0,0,0) ".concat(value[0] / 0.98, "%"),
                                                            "rgba(0,0,0,0) ".concat(value[1] / 0.98, "%"),
                                                            "".concat(theme.palette.success.main, " ").concat(value[1] / 0.98, "%"),
                                                            "".concat(theme.palette.success.main, " 100%);"),
                                                        ].join(', ');
                                                    },
                                                },
                                                '& .MuiSlider-track, &.Mui-disabled .MuiSlider-track': { backgroundColor: 'warning.main' },
                                            }, min: data.quality_range_quarter1 + 1, max: data.quality_range_quarter4 - 1, value: value }, field)));
                                    } }) })), _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", maxWidth: SLIDER_MAX_WIDTH }, { children: [_jsx(Typography, __assign({ color: "error.main" }, { children: "".concat(t('pages.cell_health_reporting.form.fields.signal_quality_range.bad'), ": ").concat(data.quality_range_quarter1, " - ").concat(qualityRangeQuarter2 - 1) })), _jsx(Typography, __assign({ color: "warning.main" }, { children: "".concat(t('pages.cell_health_reporting.form.fields.signal_quality_range.moderate'), ": ").concat(qualityRangeQuarter2, " - ").concat(qualityRangeQuarter3) })), _jsx(Typography, __assign({ color: "success.main" }, { children: "".concat(t('pages.cell_health_reporting.form.fields.signal_quality_range.good'), ": ").concat(qualityRangeQuarter3 + 1, " - ").concat(data.quality_range_quarter4) }))] }))] })), _jsxs(Box, __assign({ sx: { '& > :not(:first-of-type)': { paddingLeft: 1 } } }, { children: [_jsx(Controller, { control: control, name: "interval_frequency", rules: {
                                    validate: function (value) {
                                        if (value === undefined || value === '')
                                            return requiredFieldError(t('pages.cell_health_reporting.form.fields.frequency.label'));
                                        if (!Number.isInteger(value))
                                            return invalidFieldError(t('pages.cell_health_reporting.form.fields.frequency.label'));
                                        if (value < FreqMinValue)
                                            return minValueFieldError(t('pages.cell_health_reporting.form.fields.frequency.label'), FreqMinValue.toString());
                                        if (value > FreqMaxValue)
                                            return maxValueFieldError(t('pages.cell_health_reporting.form.fields.frequency.label'), FreqMaxValue.toString());
                                        return true;
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]), error = _a.fieldState.error;
                                    return (_jsx(TextField, __assign({ disabled: disabled, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, InputProps: {
                                            inputProps: { min: 1, step: 1 },
                                            startAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: t('pages.cell_health_reporting.form.fields.frequency.prefix') })),
                                        }, label: t('pages.cell_health_reporting.form.fields.frequency.label'), type: "number", required: true, onChange: function (event) {
                                            onChange(event.target.value === '' ? '' : parseFloat(event.target.value));
                                        } }, field)));
                                } }), _jsx(Controller, { control: control, name: "interval_unit", render: function (_a) {
                                    var field = _a.field;
                                    return (_jsxs(TextField, __assign({ "aria-label": t('pages.cell_health_reporting.form.fields.frequency_units.label'), inputProps: { 'aria-label': t('pages.cell_health_reporting.form.fields.frequency_units.label') }, disabled: disabled, select: true }, field, { sx: { marginTop: 2.75, minWidth: 98 } }, { children: [_jsx(MenuItem, __assign({ value: MINUTES }, { children: intervalFrequency === 1
                                                    ? t('pages.cell_health_reporting.form.fields.frequency_units.minute')
                                                    : t('pages.cell_health_reporting.form.fields.frequency_units.minutes') })), _jsx(MenuItem, __assign({ value: HOURS }, { children: intervalFrequency === 1
                                                    ? t('pages.cell_health_reporting.form.fields.frequency_units.hour')
                                                    : t('pages.cell_health_reporting.form.fields.frequency_units.hours') })), _jsx(MenuItem, __assign({ value: DAYS }, { children: intervalFrequency === 1
                                                    ? t('pages.cell_health_reporting.form.fields.frequency_units.day')
                                                    : t('pages.cell_health_reporting.form.fields.frequency_units.days') }))] })));
                                } })] })), _jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(FormControlLabel, { control: _jsx(Controller, { control: control, name: "connectivity_test_enabled", render: function (_a) {
                                    var _b = _a.field, value = _b.value, field = __rest(_b, ["value"]);
                                    return _jsx(Checkbox, __assign({}, field, { checked: value, disabled: disabled }));
                                } }), label: _jsxs(_Fragment, { children: [t('pages.cell_health_reporting.form.fields.cell_connectivity_test.label'), _jsx(OGIconTooltip, { title: t('pages.cell_health_reporting.form.fields.cell_connectivity_test.tooltip') })] }) }) }))] }))] })));
}
