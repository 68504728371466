var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import format from 'string-format';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { decodeCreatePortSmartgroupResponse, decodeReadPortSmartgroupsResponse } from '../../../types/lighthouse/decoders/ports/smartgroups';
import { deserializeSmartGroup, serializeSmartGroup, } from '../smartgroups';
import { QUERY_LIMIT } from '@/constants/limits';
import { decodeDeletePortSmartgroupResponse, decodeUpdatePortSmartgroupResponse } from '../../../types/lighthouse/decoders/ports/smartgroups/smartgroup-id';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
export var PORT_SMARTGROUPS_PATH = '/ports/smartgroups';
export function formatPortSmartgroupPath(smartgroupId) {
    if (smartgroupId === void 0) { smartgroupId = ':smartgroupId'; }
    return "".concat(PORT_SMARTGROUPS_PATH, "/").concat(smartgroupId);
}
export function usePortSmartgroups(queryParams) {
    var _this = this;
    if (queryParams === void 0) { queryParams = {}; }
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    var fetchPortSmartgroups = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var searchParams, response, _a, data, status, smartgroups;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    searchParams = Object.keys(queryParams).length ? queryParams : { per_page: QUERY_LIMIT };
                    return [4 /*yield*/, executeFetch(PORT_SMARTGROUPS_PATH, __assign({ queryParams: searchParams }, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadPortSmartgroupsResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.ports_smartgroups.get'));
                    if (status === 200) {
                        smartgroups = data.smartgroups.map(deserializeSmartGroup);
                        // prematurely set data for individual smartgroup endpoints
                        smartgroups.forEach(function (smartgroup) {
                            queryClient.setQueryData([PORT_SMARTGROUPS_PATH, smartgroup.id], smartgroup);
                        });
                        return [2 /*return*/, __assign(__assign({}, data), { smartgroups: smartgroups })];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, queryParams, t]);
    return useQuery({
        queryKey: [PORT_SMARTGROUPS_PATH, queryParams],
        queryFn: fetchPortSmartgroups,
    });
}
export function useCreatePortSmartgroup() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (newSmartgroupExt) { return __awaiter(_this, void 0, void 0, function () {
        var smartgroup, response, _a, data, status, createdSmartgroup;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    smartgroup = serializeSmartGroup(newSmartgroupExt);
                    return [4 /*yield*/, executeFetch(PORT_SMARTGROUPS_PATH, { method: 'POST', body: JSON.stringify({ smartgroup: smartgroup }) })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeCreatePortSmartgroupResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.ports_smartgroups.post'));
                    if (status === 200) {
                        createdSmartgroup = deserializeSmartGroup(data.smartgroup);
                        queryClient
                            .invalidateQueries({
                            queryKey: [PORT_SMARTGROUPS_PATH],
                        })
                            .catch(console.error);
                        queryClient.setQueryData([PORT_SMARTGROUPS_PATH, createdSmartgroup.id], createdSmartgroup);
                        return [2 /*return*/, createdSmartgroup];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, t, queryClient]);
}
export function useUpdatePortSmartgroup() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var smartgroup, response, _b, data, status, translations, updatedSmartgroup;
        var id = _a.id, newSmartgroupExt = __rest(_a, ["id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    smartgroup = serializeSmartGroup(newSmartgroupExt);
                    return [4 /*yield*/, executeFetch(formatPortSmartgroupPath(id), { method: 'PUT', body: JSON.stringify({ smartgroup: smartgroup }) })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, decodeResponse(decodeUpdatePortSmartgroupResponse, response)];
                case 2:
                    _b = _c.sent(), data = _b.data, status = _b.status;
                    translations = {
                        error: { message: format(t('api.ports_smartgroup.put.error.message'), id) },
                        forbidden: { message: format(t('api.ports_smartgroup.put.forbidden.message'), id) },
                        not_found: { message: format(t('api.ports_smartgroup.put.not_found.message'), id) },
                        ok: { message: format(t('api.ports_smartgroup.put.ok.message'), id), header: t('api.ports_smartgroup.put.ok.header') },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        updatedSmartgroup = deserializeSmartGroup(data.smartgroup);
                        queryClient
                            .invalidateQueries({
                            queryKey: [PORT_SMARTGROUPS_PATH],
                        })
                            .catch(console.error);
                        queryClient.setQueryData([PORT_SMARTGROUPS_PATH, id], updatedSmartgroup);
                        return [2 /*return*/, updatedSmartgroup];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useDeletePortSmartgroup() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    return useCallback(function (smartgroupId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatPortSmartgroupPath(smartgroupId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeletePortSmartgroupResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        error: { message: format(t('api.ports_smartgroup.delete.error.message'), smartgroupId) },
                        forbidden: { message: format(t('api.ports_smartgroup.delete.forbidden.message'), smartgroupId) },
                        not_found: { message: format(t('api.ports_smartgroup.delete.not_found.message'), smartgroupId) },
                        ok: { message: format(t('api.ports_smartgroup.delete.ok.message'), smartgroupId), header: t('api.ports_smartgroup.delete.ok.header') },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 200) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [PORT_SMARTGROUPS_PATH],
                        })
                            .catch(console.error);
                        queryClient.removeQueries({
                            queryKey: [PORT_SMARTGROUPS_PATH, smartgroupId],
                        });
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
