var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import DialogContentText from '@mui/material/DialogContentText';
import { OGButton } from '@opengear/buttons';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import { useCallback } from 'react';
import { ConfirmationModal } from '@/components/confirmation-modal';
import { useDeleteNodeFirmware } from '@/api/system/node-firmware';
export function FirmwareFilesDeleteCell(_a) {
    var disabled = _a.disabled, firmwareId = _a.firmwareId, onClick = _a.onClick, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: disabled
            ? t('pages.firmware_upgrade.tabs.file_manager.table.delete_file_tooltip_disabled')
            : t('pages.firmware_upgrade.tabs.file_manager.table.delete_file_tooltip') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": format(t('pages.firmware_upgrade.tabs.file_manager.table.delete_file_label'), firmwareId), disabled: disabled, startIcon: _jsx(ClearIcon, {}), tabIndex: tabIndex, size: "small", variant: "borderless", onClick: onClick, propagateSpacebar: false }) }) })));
}
export function FirmwareFilesDeleteDialog(_a) {
    var firmwareId = _a.firmwareId, onClose = _a.onClose, open = _a.open;
    var t = useTranslation().t;
    var deleteFirmware = useDeleteNodeFirmware();
    var onDelete = useCallback(function () {
        if (firmwareId)
            deleteFirmware(firmwareId);
        onClose();
    }, [deleteFirmware, firmwareId, onClose]);
    return (_jsx(ConfirmationModal, __assign({ title: t('pages.firmware_upgrade.tabs.file_manager.delete_modal.title'), confirmLabel: t('global.buttons.remove'), onClose: onClose, onConfirm: onDelete, open: open }, { children: _jsx(DialogContentText, __assign({ sx: { whiteSpace: 'pre-wrap' } }, { children: format(t('pages.firmware_upgrade.tabs.file_manager.delete_modal.message'), firmwareId !== null && firmwareId !== void 0 ? firmwareId : '') })) })));
}
