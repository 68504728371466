var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useCallback, useMemo } from 'react';
export function PermissionsRadioGroup(_a) {
    var _b = _a.color, color = _b === void 0 ? 'default' : _b, label = _a.label, permission = _a.permission, onChange = _a.onChange;
    var t = useTranslation().t;
    var disabled = useMemo(function () { return onChange === undefined; }, [onChange]);
    var handleChange = useCallback(function (event, value) {
        if (onChange !== undefined && value) {
            onChange(value);
        }
    }, [onChange]);
    var _c = useMemo(function () { return (disabled ? { classes: { root: 'Mui-disabled' }, sx: { cursor: 'initial' } } : { classes: undefined, sx: undefined }); }, [disabled]), classes = _c.classes, sx = _c.sx;
    return (_jsxs(RadioGroup, __assign({ "aria-label": label, row: true, sx: { 'justifyContent': 'space-around', 'width': '100%', '& > *:not(:last-child)': { marginRight: 0 } }, value: permission, onChange: handleChange }, { children: [_jsx(Radio, { checked: permission === 'read_write', classes: classes, color: color, disabled: disabled && permission !== 'read_write', inputProps: { 'aria-label': t('components.permissions.table.headers.read_write') }, value: "read_write", sx: sx }), _jsx(Radio, { checked: permission === 'read_only', classes: classes, color: color, disabled: disabled && permission !== 'read_only', inputProps: { 'aria-label': t('components.permissions.table.headers.read_only') }, value: "read_only", sx: sx }), _jsx(Radio, { checked: permission === 'deny', classes: classes, color: color, disabled: disabled && permission !== 'deny', inputProps: { 'aria-label': t('components.permissions.table.headers.deny') }, value: "deny", sx: sx })] })));
}
