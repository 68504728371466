var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Stack from '@mui/material/Stack';
import { OGLinkButton } from '@opengear/links';
import Paper from '@mui/material/Paper';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { ADD_ENROLLMENT_BUNDLE_ROUTE } from '@/constants/routes';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { BundlesTable } from '@/components/config/enrollment-bundles';
export default withAuthenticationRequired(function () {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditAccess = hasPermission('configure.node_enrollment.enrollment_bundles.edit');
    useDocTitle(t('pages.enrollment_bundles.title'));
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.enrollment_bundles.title') })), _jsx(OGLinkButton, __assign({ variant: "primary", startIcon: _jsx(AddCircleOutline, {}), to: ADD_ENROLLMENT_BUNDLE_ROUTE, disabled: !hasEditAccess }, { children: t('pages.enrollment_bundles.buttons.add_bundle') }))] })), _jsx(Paper, __assign({ sx: { padding: 2 } }, { children: _jsx(BundlesTable, {}) }))] })));
}, 
// Verifies bundle permissions and secondary lighthouse check
{
    rights: ['configure.node_enrollment.enrollment_bundles.view'],
});
