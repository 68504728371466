var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import { OGButton } from '@opengear/buttons';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OGLinkButton } from '@opengear/links';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import EnableCRG from '@opengear/icons/misc/enable-crg';
import { OG_DEMO_CONTACT_LINK } from '@/constants/links';
export default function SmfResourceEnableRagModal(_a) {
    var useOpenModel = _a.useOpenModel;
    var open = useOpenModel.open, handleClose = useOpenModel.handleClose;
    var t = useTranslation().t;
    var translatedListItemResourceMerits = t('pages.smf_resources.modal.enable_rag.bottom_text_list_resources_merits');
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: t('pages.smf_resources.modal.enable_rag.title') }), _jsxs(DialogContent, __assign({ sx: { marginBottom: 1, overflowY: 'unset' } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { marginBottom: 3 } }, { children: [_jsx(EnableCRG, { style: { width: '160px', height: '106px' } }), _jsxs(Box, __assign({ style: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Typography, __assign({ component: "div", variant: "body1", sx: { whiteSpace: 'pre-line', fontWeight: '400' } }, { children: t('pages.smf_resources.modal.enable_rag.heading') })), _jsx(Typography, __assign({ component: "div", variant: "body1", sx: { whiteSpace: 'pre-line', marginTop: 1 } }, { children: t('pages.smf_resources.modal.enable_rag.main_text') }))] }))] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ component: "div", variant: "body1" }, { children: t('pages.smf_resources.modal.enable_rag.bottom_text_first_line') })), _jsx(List, __assign({ sx: { listStyleType: 'disc', paddingLeft: 5 } }, { children: translatedListItemResourceMerits.map(function (value) { return (_jsx(ListItem, __assign({ sx: { padding: 0, display: 'list-item' } }, { children: _jsx(ListItemText, { primary: value }) }), value)); }) }))] })] })), _jsx(Divider, {}), _jsxs(DialogActions, { children: [_jsx(OGButton, __assign({ variant: "borderless", onClick: handleClose, autoFocus: true }, { children: t('global.buttons.cancel') })), _jsx(OGLinkButton, __assign({ variant: "primary", to: OG_DEMO_CONTACT_LINK, external: true }, { children: t('pages.smf_resources.modal.enable_rag.confirm') }))] })] })));
}
