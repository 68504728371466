var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { OGButton } from '@opengear/buttons';
import TextField from '@mui/material/TextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Typography from '@mui/material/Typography';
import { useThemeMediaQuery } from '@/hooks/use-theme-media-query';
import { DATE_INPUT_FORMAT } from '@/constants/formats';
import { transformJobsFilterAutocompleteOption, transformJobsFormData, } from './jobs-filter-utils';
/**
 * Jobs Filter Form is a generic form for all filter options.
 * Depending on the option that is passed as props, will determine what form inputs are rendered, and
 * what the filters are actually applied.
 */
export function JobsFilterForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var option = _a.option, setActiveOption = _a.setActiveOption, setFilterOptions = _a.setFilterOptions;
    var t = useTranslation().t;
    var tabletMediaQuery = useThemeMediaQuery('tablet');
    var _m = __read(useState(function () { return transformJobsFilterAutocompleteOption(option); }), 2), formData = _m[0], setFormData = _m[1];
    var _o = __read(useState(null), 2), formError = _o[0], setFormError = _o[1];
    // Simple derived value to disable submit button
    var isFormDataEmpty = useMemo(function () { return Object.values(formData).every(function (value) { return !value; }); }, [formData]);
    var jobTypeOptions = useMemo(function () { return ({
        registration: t('pages.jobs.filter.options.job_type.select_options.registration'),
        enrollment: t('pages.jobs.filter.options.job_type.select_options.enrollment'),
        unenroll_node: t('pages.jobs.filter.options.job_type.select_options.unenroll_node'),
        config_retrieval: t('pages.jobs.filter.options.job_type.select_options.config_retrieval'),
        netops_deactivate: t('pages.jobs.filter.options.job_type.select_options.netops_deactivate'),
        node_certificate_update: t('pages.jobs.filter.options.job_type.select_options.node_certificate_update'),
        thirdparty_config_sync: t('pages.jobs.filter.options.job_type.select_options.thirdparty_config_sync'),
        port_logs_delete: t('pages.jobs.filter.options.job_type.select_options.port_logs_delete'),
        firmware: t('pages.jobs.filter.options.job_type.select_options.firmware'),
    }); }, [t]);
    var jobStatusOptions = useMemo(function () { return ({
        success: t('pages.jobs.filter.options.job_status.select_options.success'),
        permanent_failure: t('pages.jobs.filter.options.job_status.select_options.permanent_failure'),
        temporary_failure: t('pages.jobs.filter.options.job_status.select_options.temporary_failure'),
    }); }, [t]);
    var durationOperatorOptions = useMemo(function () { return ({
        lt: t("pages.jobs.filter.options.duration_operator.select_options.lt"),
        gt: t("pages.jobs.filter.options.duration_operator.select_options.gt"),
    }); }, [t]);
    var handleFromDateChange = function (value) {
        setFormData(function (prevState) { var _a; return (__assign(__assign({}, prevState), { fromDate: (_a = value === null || value === void 0 ? void 0 : value.startOf('d')) !== null && _a !== void 0 ? _a : undefined })); });
    };
    var handleToDateChange = function (value) {
        setFormData(function (prevState) { var _a; return (__assign(__assign({}, prevState), { toDate: (_a = value === null || value === void 0 ? void 0 : value.endOf('d')) !== null && _a !== void 0 ? _a : undefined })); });
    };
    var handleNumericChange = function (event) {
        var _a = event.target, name = _a.name, value = _a.value;
        if (value.match(/^[0-9]+$/) || value === '') {
            setFormData(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
            });
        }
    };
    var handleSelectChange = function (event) {
        var _a = event.target, name = _a.name, value = _a.value;
        setFormData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleDateError = function (dateError) {
        setFormError(dateError);
    };
    var handleSubmit = useCallback(function (event) {
        event.preventDefault();
        // Set filter data based on form data
        var newFilterOptions = transformJobsFormData(formData);
        setFilterOptions(function (prevFilterOptions) { return (__assign(__assign({}, prevFilterOptions), newFilterOptions)); });
        // Close popover
        setActiveOption(undefined);
    }, [formData, setActiveOption, setFilterOptions]);
    return (_jsxs(Box, __assign({ component: "form", "aria-label": t('pages.jobs.filter.placeholder'), padding: 1.5, onSubmit: handleSubmit, display: "flex", gap: 1, alignItems: "end" }, { children: [option.key === 'jobType' && (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(Select
                // eslint-disable-next-line jsx-a11y/no-autofocus
                , __assign({ 
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, id: "jobType", name: "jobType", label: t('pages.jobs.filter.options.job_type.label'), placeholder: t('pages.jobs.filter.options.job_type.label'), value: (_b = formData.jobType) !== null && _b !== void 0 ? _b : '', margin: "none", inputProps: { 'aria-label': t('pages.jobs.filter.options.job_type.label') }, displayEmpty: true, renderValue: function (value) { return (!value ? _jsx("em", { children: t('pages.jobs.filter.options.job_type.placeholder') }) : jobTypeOptions[value]); }, onChange: handleSelectChange }, { children: Object.entries(jobTypeOptions).map(function (_a) {
                        var _b = __read(_a, 2), value = _b[0], label = _b[1];
                        return (_jsx(MenuItem, __assign({ value: value }, { children: label }), value));
                    }) })) }))), option.key === 'jobStatus' && (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(Select
                // eslint-disable-next-line jsx-a11y/no-autofocus
                , __assign({ 
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, id: "jobStatus", name: "jobStatus", label: t('pages.jobs.filter.options.job_status.label'), placeholder: t('pages.jobs.filter.options.job_status.label'), value: (_c = formData.jobStatus) !== null && _c !== void 0 ? _c : '', margin: "none", inputProps: { 'aria-label': t('pages.jobs.filter.options.job_status.label') }, displayEmpty: true, renderValue: function (value) { return (!value ? _jsx("em", { children: t('pages.jobs.filter.options.job_status.placeholder') }) : jobStatusOptions[value]); }, onChange: handleSelectChange }, { children: Object.entries(jobStatusOptions).map(function (_a) {
                        var _b = __read(_a, 2), value = _b[0], label = _b[1];
                        return (_jsx(MenuItem, __assign({ value: value }, { children: label }), value));
                    }) })) }))), option.key === 'jobId' && (_jsx(FormControl, __assign({ margin: "none" }, { children: _jsx(TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                , { 
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus: true, id: "jobId", name: "jobId", value: (_d = formData.jobId) !== null && _d !== void 0 ? _d : '', margin: "none", sx: { maxWidth: '100px' }, inputProps: { 'aria-label': t('pages.jobs.filter.options.job_id.label'), 'inputMode': 'numeric', 'pattern': '[0-9]*' }, onChange: handleNumericChange }) }))), option.key === 'duration' && (_jsxs(_Fragment, { children: [_jsx(FormControl, __assign({ hiddenLabel: true, margin: "none" }, { children: _jsx(Select
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        , __assign({ 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, id: "durationOperator", name: "durationOperator", label: t('pages.jobs.filter.options.duration_operator.label'), placeholder: t('pages.jobs.filter.options.duration_operator.label'), value: (_e = formData.durationOperator) !== null && _e !== void 0 ? _e : '', margin: "none", inputProps: { 'aria-label': t('pages.jobs.filter.options.duration_operator.label') }, onChange: handleSelectChange }, { children: Object.entries(durationOperatorOptions).map(function (_a) {
                                var _b = __read(_a, 2), value = _b[0], label = _b[1];
                                return (_jsx(MenuItem, __assign({ value: value }, { children: label }), value));
                            }) })) })), _jsxs(FormControl, __assign({ margin: "none", sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: function (theme) { return theme.spacing(0.5); } } }, { children: [_jsx(TextField, { id: "durationHours", name: "durationHours", value: (_f = formData.durationHours) !== null && _f !== void 0 ? _f : '', margin: "none", sx: { maxWidth: '70px' }, inputProps: { 'aria-label': t('pages.jobs.filter.options.duration.hour.label'), 'inputMode': 'numeric', 'pattern': '[0-9]*' }, onChange: handleNumericChange }), _jsx(InputLabel, __assign({ htmlFor: "durationHours" }, { children: t('pages.jobs.filter.options.duration.hour.metric') }))] })), _jsxs(FormControl, __assign({ margin: "none", sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: function (theme) { return theme.spacing(0.5); } } }, { children: [_jsx(TextField, { id: "durationMinutes", name: "durationMinutes", value: (_g = formData.durationMinutes) !== null && _g !== void 0 ? _g : '', margin: "none", sx: { maxWidth: '70px' }, inputProps: { 'aria-label': t('pages.jobs.filter.options.duration.minute.label'), 'inputMode': 'numeric', 'pattern': '[0-9]*' }, onChange: handleNumericChange }), _jsx(InputLabel, __assign({ htmlFor: "durationMinutes" }, { children: t('pages.jobs.filter.options.duration.minute.metric') }))] }))] })), option.key === 'dateRange' && (_jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "end", gap: 1 }, { children: [_jsx(DatePicker
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    , { 
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus: true, format: DATE_INPUT_FORMAT, label: t('pages.jobs.filter.options.date_range.from_date.label'), value: (_h = formData.fromDate) !== null && _h !== void 0 ? _h : null, maxDate: (_j = formData.toDate) !== null && _j !== void 0 ? _j : undefined, onChange: handleFromDateChange, onError: handleDateError, sx: { margin: 0, maxWidth: '200px' }, slots: { openPickerIcon: CalendarMonthIcon }, desktopModeMediaQuery: tabletMediaQuery, slotProps: {
                            openPickerIcon: {
                                style: { fontSize: 16 },
                            },
                            openPickerButton: {
                                'aria-label': t('pages.jobs.filter.options.date_range.from_date.label'),
                                'style': { padding: 7, marginRight: 0, borderRadius: 0 },
                            },
                            textField: {
                                margin: 'none',
                                placeholder: DATE_INPUT_FORMAT,
                            },
                        } }), _jsx(Typography, __assign({ component: "span", paddingY: 1 }, { children: "-" })), _jsx(DatePicker, { format: DATE_INPUT_FORMAT, label: t('pages.jobs.filter.options.date_range.to_date.label'), value: (_k = formData.toDate) !== null && _k !== void 0 ? _k : null, minDate: (_l = formData.fromDate) !== null && _l !== void 0 ? _l : undefined, onChange: handleToDateChange, onError: handleDateError, sx: { margin: 0, maxWidth: '200px' }, slots: { openPickerIcon: CalendarMonthIcon }, desktopModeMediaQuery: tabletMediaQuery, slotProps: {
                            openPickerIcon: {
                                style: { fontSize: 16 },
                            },
                            openPickerButton: {
                                'aria-label': t('pages.jobs.filter.options.date_range.to_date.label'),
                                'style': { padding: 7, marginRight: 0, borderRadius: 0 },
                            },
                            textField: {
                                margin: 'none',
                                placeholder: DATE_INPUT_FORMAT,
                            },
                        } })] }))), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: t('global.buttons.apply') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('global.buttons.apply'), disabled: !!formError || isFormDataEmpty, size: "small", startIcon: _jsx(CheckIcon, {}), type: "submit", variant: "borderless" }) }) }))] })));
}
