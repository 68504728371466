import { subnetMaskToCIDR } from '@/utils/calculations';
export function transformResponseToFormState(response) {
    var _a, _b, _c, _d;
    return {
        description: (_a = response.description) !== null && _a !== void 0 ? _a : '',
        address: (_b = response.network_address) !== null && _b !== void 0 ? _b : '',
        port: '443',
        username: '',
        password: '',
        vpn_address: response.lhvpn_subnet.address,
        cidr: subnetMaskToCIDR(response.lhvpn_subnet.netmask),
        smf_address: (_c = response.smf_address) !== null && _c !== void 0 ? _c : '',
        smf_mask: (_d = response.smf_mask) !== null && _d !== void 0 ? _d : '',
    };
}
export function transformAddFormStateToApi(formData, mask) {
    return {
        address: formData.address,
        username: formData.username,
        password: formData.password,
        port: parseInt(formData.port, 10),
        lhvpn_subnet_address: formData.vpn_address,
        lhvpn_subnet_netmask: mask,
        description: formData.description,
        smf_address: formData.smf_address,
        smf_mask: formData.smf_mask,
    };
}
export function transformUpdateFormStateToApi(formData, mask, lighthouseId) {
    return {
        id: lighthouseId,
        lhvpn_subnet_address: formData.vpn_address,
        lhvpn_subnet_netmask: mask,
        description: formData.description,
        smf_address: formData.smf_address,
        smf_mask: formData.smf_mask,
    };
}
