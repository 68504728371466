var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { SmfResourcesTable, CreateSmfResourceModal } from '@/components/smf-resources';
import { WarningBanner } from '@/components/warning-banner';
import { useOpen } from '@/hooks/open';
import SmfResourceEnableRagModal from '@/components/smf-resources/smf-resources-enable-rag-modal';
import { useSmfResourceWarnings } from '@/api/smf-resources';
import { useEntitlementsData } from '@/api/system/entitlements';
import { useResourceTagsNames } from '@/api/tags/resource-tags/names';
function SmfResources() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    useDocTitle(t('pages.smf_resources.title'));
    var _a = useEntitlementsData(), isLoadingEntitlements = _a.isLoading, hasPremiumSubscription = _a.hasPremiumSubscription;
    var permissions = useMemo(function () {
        var hasCrgEditAccess = hasPermission('manage.resources.edit');
        var hasTagEditAccess = hasPermission('configure.edit_tags.edit');
        var hasTagViewAccess = hasPermission('configure.edit_tags.view');
        var hasEditAccess = hasCrgEditAccess && hasPremiumSubscription;
        var hasTagAssignAccess = hasEditAccess && hasTagViewAccess;
        var hasTagCreateAccess = hasTagAssignAccess && hasTagEditAccess;
        return {
            hasEditAccess: hasEditAccess,
            hasDeleteAccess: hasCrgEditAccess,
            hasTagAssignAccess: hasTagAssignAccess,
            hasTagCreateAccess: hasTagCreateAccess,
            hasSmartgroupsViewAccess: hasPermission('configure.edit_smart_groups.view'),
            hasSmartgroupsEditAccess: hasPermission('configure.edit_smart_groups.edit'),
        };
    }, [hasPermission, hasPremiumSubscription]);
    var createSmfResourceOpen = useOpen();
    var smfResourceEnableRagOpen = useOpen(!isLoadingEntitlements && !hasPremiumSubscription);
    var tagNamesData = useResourceTagsNames({ order_by: 'name' }).data;
    var resourceTags = tagNamesData === null || tagNamesData === void 0 ? void 0 : tagNamesData.resourceTags;
    var smfResourcesWarningsBanners = [];
    var _b = useSmfResourceWarnings(), smfResourcesWarnings = _b.data, isLoadingSmfResources = _b.isLoading;
    if (!isLoadingSmfResources && (smfResourcesWarnings === null || smfResourcesWarnings === void 0 ? void 0 : smfResourcesWarnings.warnings.domain)) {
        smfResourcesWarningsBanners.push(_jsx(WarningBanner, { warningIcon: _jsx(ReportProblemIcon, { style: { fontSize: '50px' }, color: "warning" }), warningMessage: _jsx(Typography, { children: t('pages.smf_resources.warning_message_domain') }), warningColor: "warning", bannerCode: "smf-resources-domain-warning", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }, "smf-resources-domain-warning"));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(SmfResourceEnableRagModal, { useOpenModel: smfResourceEnableRagOpen }), _jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.smf_resources.title') })), _jsx(OGButton, __assign({ disabled: !permissions.hasEditAccess, startIcon: _jsx(AddCircleOutline, {}), onClick: createSmfResourceOpen.handleOpen, sx: { marginBottom: 2 } }, { children: t('pages.smf_resources.create') })), _jsx(CreateSmfResourceModal, { useOpenModel: createSmfResourceOpen, permissions: permissions, resourceTagNames: resourceTags })] })), smfResourcesWarningsBanners, _jsx(Paper, __assign({ sx: { padding: 2 } }, { children: _jsx(SmfResourcesTable, { permissions: permissions }) }))] })));
}
export default withAuthenticationRequired(SmfResources, { rights: ['manage.resources.view'] });
