var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { OG_BLACK, OG_DANGER_DARK, OG_DANGER_LIGHT, OG_SUCCESS_DARK, OG_SUCCESS_LIGHT, OG_WHITE } from '@opengear/theme/colors';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
export function PasswordPolicyMessage(_a) {
    var policy = _a.policy, detail = _a.detail, componentKey = _a.componentKey;
    var t = useTranslation().t;
    var theme = useTheme();
    var darkMode = theme.palette.mode === 'dark';
    var icon = (_jsx(WarningIcon, { fontSize: "small", color: "warning", titleAccess: t('pages.password_reset.form.fields.password_policy.icons.warning'), sx: { marginRight: 0.5 } }));
    if (detail !== undefined) {
        icon = detail ? (_jsx(SuccessIcon, { fontSize: "small", color: "success", titleAccess: t('pages.password_reset.form.fields.password_policy.icons.success'), sx: { marginRight: 0.5 } })) : (_jsx(ErrorIcon, { fontSize: "small", color: "error", titleAccess: t('pages.password_reset.form.fields.password_policy.icons.error'), sx: { marginRight: 0.5 } }));
    }
    return (_jsxs("span", __assign({ style: { display: 'flex', alignItems: 'center', paddingBottom: 2, color: darkMode ? OG_WHITE : OG_BLACK } }, { children: [icon, policy, _jsx("br", {})] }), componentKey));
}
export function PasswordPolicyMessages(_a) {
    var passwordPolicyList = _a.passwordPolicyList, passwordValidationData = _a.passwordValidationData;
    var t = useTranslation().t;
    var theme = useTheme();
    var hasError = useMemo(function () {
        return (passwordPolicyList.min_password_length > 0 && !(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.length)) ||
            (passwordPolicyList.no_username && !(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.username)) ||
            (passwordPolicyList.must_contain_capital_letter && !(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.capital)) ||
            (passwordPolicyList.must_contain_number && !(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.number)) ||
            (passwordPolicyList.must_contain_special_character && !(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.special));
    }, [passwordPolicyList, passwordValidationData]);
    var darkMode = theme.palette.mode === 'dark';
    if (!passwordPolicyList.enabled)
        return null;
    var textColor;
    if (hasError) {
        textColor = darkMode ? OG_DANGER_DARK : OG_DANGER_LIGHT;
    }
    else {
        textColor = darkMode ? OG_SUCCESS_DARK : OG_SUCCESS_LIGHT;
    }
    return (_jsxs(_Fragment, { children: [(passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details) && (_jsxs("span", __assign({ style: { display: 'flex', alignItems: 'center', paddingBottom: 4, color: textColor, fontWeight: 500 } }, { children: [_jsx("span", __assign({ style: {
                            position: 'absolute',
                            width: '1px',
                            height: '1px',
                            padding: 0,
                            margin: '-1px',
                            overflow: 'hidden',
                            clip: 'rect(0, 0, 0, 0)',
                            border: 0,
                        } }, { children: hasError
                            ? "".concat(t('pages.password_reset.form.fields.password_policy.invalid_password'), " ").concat(t('pages.password_reset.form.fields.password_policy.use_arrows'))
                            : "".concat(t('pages.password_reset.form.fields.password_policy.valid_password'), " ").concat(t('pages.password_reset.form.fields.password_policy.use_arrows')) })), _jsx("span", __assign({ "aria-hidden": "true" }, { children: hasError
                            ? t('pages.password_reset.form.fields.password_policy.invalid_password')
                            : t('pages.password_reset.form.fields.password_policy.valid_password') }))] }))), passwordPolicyList.min_password_length > 0 && (_jsx(PasswordPolicyMessage, { policy: format(t('pages.password_reset.form.fields.password_policy.min_password_length'), passwordPolicyList.min_password_length.toString()), detail: passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.length, componentKey: "min_length" }, "min_length")), passwordPolicyList.no_username && (_jsx(PasswordPolicyMessage, { policy: t('pages.password_reset.form.fields.password_policy.no_username'), detail: passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.username, componentKey: "no_username" }, "no_username")), passwordPolicyList.must_contain_capital_letter && (_jsx(PasswordPolicyMessage, { policy: t('pages.password_reset.form.fields.password_policy.capital_letter'), detail: passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.capital, componentKey: "capital" }, "capital")), passwordPolicyList.must_contain_number && (_jsx(PasswordPolicyMessage, { policy: t('pages.password_reset.form.fields.password_policy.contain_number'), detail: passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.number, componentKey: "number" }, "number")), passwordPolicyList.must_contain_special_character && (_jsx(PasswordPolicyMessage, { policy: t('pages.password_reset.form.fields.password_policy.contain_special_char'), detail: passwordValidationData === null || passwordValidationData === void 0 ? void 0 : passwordValidationData.details.special, componentKey: "special_char" }, "special_char"))] }));
}
