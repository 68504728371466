var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { maxLengthFieldError, minLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
// Field validation limits
export var UsernameMaxLength = 128;
export var AuthPasswordMinLength = 8;
export var AuthPasswordMaxLength = 128;
export var PrivacyPasswordMinLength = 8;
export var PrivacyPasswordMaxLength = 128;
export function MessageTypeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.msg_type.label') })), _jsx(Controller, { control: control, name: "msg_type", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "TRAP" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.msg_type.trap.label') })), _jsx(ToggleButton, __assign({ value: "INFORM" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.msg_type.inform.label') }))] })));
                } })] }));
}
export function EngineIDField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "engine_id", render: function (_a) {
            var field = _a.field;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, label: _jsxs(_Fragment, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.engine_id.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.engine_id.tooltip') })] }) }, field)));
        } }));
}
export function SecurityLevelButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ component: "label" }, { children: [t('pages.alerts_and_monitoring.tabs.snmp_manager.form.security_level.label'), _jsx(OGIconTooltip, { title: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.security_level.tooltip') })] })), _jsx(Controller, { control: control, name: "security_level", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1, overflowX: 'auto', overflowY: 'hidden' } }, { children: [_jsx(ToggleButton, __assign({ value: "noAuthNoPriv" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.security_level.no_auth_no_priv') })), _jsx(ToggleButton, __assign({ value: "authNoPriv" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.security_level.auth_no_priv') })), _jsx(ToggleButton, __assign({ value: "authPriv" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.security_level.auth_priv') }))] })));
                } })] }));
}
export function UsernameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "username", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.username.label')),
            maxLength: {
                value: UsernameMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.username.label'), UsernameMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, required: true, error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : null, label: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.username.label') }, field)));
        } }));
}
export function AuthProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.auth_protocol.label') })), _jsx(Controller, { control: control, name: "auth_protocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            if (v !== null) {
                                onChange(v);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "MD5" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.auth_protocol.md5') })), _jsx(ToggleButton, __assign({ value: "SHA" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.auth_protocol.sha') }))] })));
                } })] }));
}
export function AuthPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "auth_password", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.auth_password.label')),
            minLength: {
                value: AuthPasswordMinLength,
                message: minLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.auth_password.label'), AuthPasswordMinLength.toString()),
            },
            maxLength: {
                value: AuthPasswordMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.username.label'), AuthPasswordMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", required: true, label: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.auth_password.label'), error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : null }, field)));
        } }));
}
export function PrivacyProtocolButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ component: "label" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_protocol.label') })), _jsx(Controller, { control: control, name: "privacy_protocol", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ disabled: !permissions.hasEditPermission, exclusive: true, onChange: function (event, v) {
                            onChange(v);
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 1 } }, { children: [_jsx(ToggleButton, __assign({ value: "DES" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_protocol.des') })), _jsx(ToggleButton, __assign({ value: "AES" }, { children: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_protocol.aes') }))] })));
                } })] }));
}
export function PrivacyPasswordField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "privacy_password", rules: {
            required: requiredFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_password.label')),
            minLength: {
                value: PrivacyPasswordMinLength,
                message: minLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_password.label'), PrivacyPasswordMinLength.toString()),
            },
            maxLength: {
                value: PrivacyPasswordMaxLength,
                message: maxLengthFieldError(t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_password.label'), PrivacyPasswordMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasEditPermission, type: "password", required: true, label: t('pages.alerts_and_monitoring.tabs.snmp_manager.form.privacy_password.label'), error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : null }, field)));
        } }));
}
