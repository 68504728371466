var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { OGButton } from '@opengear/buttons';
import { OGDialog } from '@opengear/modals';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { OG_OFF_WHITE, OG_BLACK_90 } from '@opengear/theme/colors';
import { OGLink } from '@opengear/links';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import format from 'string-format';
import { useDependentLighthouseById } from '@/api/multiple-instance/dependent-lighthouses';
import { JOBS_ROUTE } from '@/constants/routes';
import { getSecondaryStatus } from '@/utils/secondary-lighthouse-status';
import { CopyButton } from '@/components/copy-button';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useAlertNotification } from '@/hooks/alert-notification';
export var SECONDARY_STATUS_MODAL_LOADING_ID = 'secondary-status-modal';
function LogSection(_a) {
    var logs = _a.logs;
    var theme = useTheme();
    var darkMode = theme.palette.mode === 'dark';
    return (_jsx(Box, __assign({ role: "code", padding: 2, sx: { backgroundColor: darkMode ? OG_BLACK_90 : OG_OFF_WHITE } }, { children: _jsx(Box, __assign({ sx: { overflowY: 'auto', maxHeight: '40vh' } }, { children: _jsx(Typography, __assign({ variant: "code", component: "p", sx: { wordWrap: 'break-word', whiteSpace: 'pre-line' } }, { children: logs })) })) })));
}
function getLighthouseStatusLogs(dependentLighthouseData, noLogsString) {
    if (dependentLighthouseData.runtime_status.action_error_message) {
        return dependentLighthouseData.runtime_status.action_error_message;
    }
    if (dependentLighthouseData.replication_errors) {
        return dependentLighthouseData.replication_errors.join('\n\n');
    }
    return noLogsString;
}
function SecondaryStatusContent(_a) {
    var handleClose = _a.handleClose, lighthouseId = _a.lighthouseId;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var alertNotification = useAlertNotification().alertNotification;
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404) {
            alertNotification(format(t('api.secondary_lighthouses.get.not_found.message'), lighthouseId !== null && lighthouseId !== void 0 ? lighthouseId : ''), { persistOnUnmount: true });
        }
        if (responseStatus === 403) {
            alertNotification(format(t('api.secondary_lighthouses.get.forbidden.message'), lighthouseId !== null && lighthouseId !== void 0 ? lighthouseId : ''), { persistOnUnmount: true });
        }
        handleClose();
    }, [handleClose, alertNotification, t, lighthouseId]);
    var _b = useDependentLighthouseById(lighthouseId, { notFoundCallback: notFoundCallback }), data = _b.data, isLoading = _b.isLoading;
    if (!isLoading && !data) {
        // We've finished loading which resulted in a 404. The handler has raised a notification in
        // the background so all we need to do here is close this modal
        handleClose();
    }
    if (isLoading && !data) {
        return _jsx(LoadingBackdrop, { "data-testid": SECONDARY_STATUS_MODAL_LOADING_ID });
    }
    if (data) {
        // Data is now available to us
        var dependentLighthouseData = data;
        var associatedLighthouse = dependentLighthouseData.network_address ? (_jsx(Box, __assign({ flexGrow: 1, marginBottom: 1 }, { children: _jsxs(Typography, __assign({ fontStyle: "italic" }, { children: [t('pages.secondary_lighthouses.modal.associated_lighthouse'), " \u00A0", _jsx(OGLink, __assign({ sx: { display: 'inline-flex' }, endIcon: _jsx(OpenInNewIcon, { fontSize: "small" }), external: true, to: "https://".concat(dependentLighthouseData.network_address), variant: "standard" }, { children: dependentLighthouseData.network_address }))] })) }))) : null;
        var canViewJobs = hasPermission('monitor.jobs.view');
        var jobDetailsLink = canViewJobs &&
            dependentLighthouseData.runtime_status.action_type === 'multi_instance_dependent_upgrader' &&
            dependentLighthouseData.runtime_status.action_job_id ? (_jsx(Box, __assign({ flexGrow: 1, marginBottom: 1 }, { children: _jsx(Typography, __assign({ fontStyle: "italic" }, { children: _jsx(OGLink, __assign({ sx: { display: 'inline-flex' }, endIcon: _jsx(OpenInNewIcon, { fontSize: "small" }), external: true, target: dependentLighthouseData.runtime_status.action_job_id, to: "".concat(JOBS_ROUTE, "/ended?job_id=").concat(dependentLighthouseData.runtime_status.action_job_id.toString()), variant: "standard" }, { children: t('pages.secondary_lighthouses.modal.view_job_details') })) })) }))) : null;
        var _c = __read(getSecondaryStatus(dependentLighthouseData), 2), status = _c[0], statusIcon = _c[1];
        var statusLogs = getLighthouseStatusLogs(dependentLighthouseData, t('pages.secondary_lighthouses.modal.no_logs'));
        return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }, { children: [statusIcon, status] })), _jsxs(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' } }, { children: [_jsxs(Box, __assign({ display: "flex" }, { children: [_jsxs(Box, __assign({ flexGrow: 1 }, { children: [_jsx(Box, { children: _jsxs(Typography, __assign({ lineHeight: 1.5, marginBottom: 1 }, { children: [_jsxs(Typography, __assign({ component: "span", variant: "body1" }, { children: [t('pages.secondary_lighthouses.modal.id'), " ", dependentLighthouseData.id, _jsx("br", {})] })), _jsxs(Typography, __assign({ component: "span", variant: "body1" }, { children: [t('pages.secondary_lighthouses.modal.instance_name'), " ", dependentLighthouseData.name] }))] })) }), associatedLighthouse, jobDetailsLink] })), _jsx(Box, __assign({ alignSelf: "end", marginBottom: 1 }, { children: _jsx(CopyButton, { content: statusLogs, sxProps: { marginLeft: 1 }, autoFocus: true }) }))] })), _jsx(LogSection, { logs: statusLogs })] })), _jsx(Divider, {}), _jsx(DialogActions, { children: _jsx(OGButton, __assign({ onClick: handleClose }, { children: t('pages.secondary_lighthouses.modal.buttons.done') })) })] }));
    }
    return null;
}
export function SecondaryStatusModal(_a) {
    var open = _a.open, handleClose = _a.handleClose, lighthouseId = _a.lighthouseId;
    return (_jsx(OGDialog, __assign({ open: open, onClose: handleClose, maxWidth: "tablet" }, { children: lighthouseId && _jsx(SecondaryStatusContent, { handleClose: handleClose, lighthouseId: lighthouseId }) })));
}
