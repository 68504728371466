var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { OGPanel } from '@opengear/panel';
import Box from '@mui/material/Box';
import { OGButton } from '@opengear/buttons';
import { useCallback } from 'react';
import { SmfResourceModalForm, SMF_RESOURCE_MODAL_TITLE_ID } from './smf-resource-modal-form';
import { FormActions, SubmitButton, CancelButton } from '@/components/layout/forms';
import { SmfResourceDeleteModal } from './smf-resource-delete-modal';
function SmfResourceModal(_a) {
    var useOpenModel = _a.useOpenModel, children = _a.children, title = _a.title;
    var open = useOpenModel.open, handleClose = useOpenModel.handleClose;
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ id: SMF_RESOURCE_MODAL_TITLE_ID, sx: { marginBottom: -1 } }, { children: title })), _jsx(OGPanel, { children: children })] })));
}
export function CreateSmfResourceModal(_a) {
    var useOpenModel = _a.useOpenModel, permissions = _a.permissions, _b = _a.resourceTagNames, resourceTagNames = _b === void 0 ? [] : _b;
    var t = useTranslation().t;
    var handleClose = useOpenModel.handleClose;
    return (_jsx(SmfResourceModal, __assign({ useOpenModel: useOpenModel, title: t('pages.smf_resources.modal.add.title') }, { children: _jsx(SmfResourceModalForm, __assign({ permissions: permissions, onClose: handleClose, resourceTagNames: resourceTagNames }, { children: _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: handleClose }), _jsx(SubmitButton, { children: t('pages.smf_resources.modal.add.confirm') })] }) })) })));
}
export function UpdateSmfResourceModal(_a) {
    var useOpenModel = _a.useOpenModel, permissions = _a.permissions, smfResource = _a.smfResource, onClose = _a.onClose, _b = _a.resourceTagNames, resourceTagNames = _b === void 0 ? [] : _b;
    var t = useTranslation().t;
    // Cancel Handler
    var handleCancel = useCallback(function () {
        onClose();
    }, [onClose]);
    return (_jsx(SmfResourceModal, __assign({ useOpenModel: useOpenModel, title: t('pages.smf_resources.modal.edit.title') }, { children: _jsx(SmfResourceModalForm, __assign({ permissions: permissions, smfResource: smfResource, onClose: onClose, resourceTagNames: resourceTagNames }, { children: _jsxs(FormActions, { children: [_jsx(SmfResourceDeleteModal, { onClose: onClose, smfResourceIdsToAction: [smfResource.id], disabled: !permissions.hasDeleteAccess, deleteButtonLabel: t('global.buttons.delete') }), _jsx(Box, { flexGrow: 1 }), _jsx(OGButton, __assign({ autoFocus: !permissions.hasEditAccess, variant: "borderless", onClick: handleCancel }, { children: t('global.buttons.cancel') })), _jsxs(SubmitButton, { children: [t('pages.smf_resources.modal.edit.confirm'), " "] })] }) })) })));
}
