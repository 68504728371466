var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { OGPanel } from '@opengear/panel';
import LoadingIcon from '@mui/icons-material/Autorenew';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { OGLinkButton } from '@opengear/links';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useDependentLighthouses } from '@/api/multiple-instance/dependent-lighthouses';
import { WarningBanner } from '@/components/warning-banner';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { ADD_SECONDARY_ROUTE, NOT_FOUND_ROUTE } from '@/constants/routes';
import { useEntitlementsData } from '@/api/system/entitlements/entitlements.hooks';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useAlertNotification } from '@/hooks/alert-notification';
import { SecondaryLighthousesTable } from '@/components/settings/mi/secondary-lighthouses';
export var SECONDARY_PAGE_LOADER_ID = 'secondary-page-loading';
export var SecondaryLighthouses = withAuthenticationRequired(function () {
    var t = useTranslation().t;
    useDocTitle(t('pages.secondary_lighthouses.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var navigate = useNavigate();
    var alertNotification = useAlertNotification().alertNotification;
    // Get entitlements to check if license supports MI
    var _a = useEntitlementsData(), miSupported = _a.miSupported, isLoadingEntitlements = _a.isLoading;
    var _b = useDependentLighthouses(), data = _b.data, isLoading = _b.isLoading;
    // Navigate to not found with an alert notifying user their license does not support MI
    useEffect(function () {
        if (!isLoadingEntitlements && !miSupported) {
            alertNotification(t('pages.secondary_lighthouses.mi_not_supported_alert'), { persistOnUnmount: true });
            navigate(NOT_FOUND_ROUTE);
        }
    }, [alertNotification, isLoadingEntitlements, miSupported, navigate, t]);
    if (isLoading || isLoadingEntitlements) {
        return _jsx(LoadingBackdrop, { "data-testid": SECONDARY_PAGE_LOADER_ID });
    }
    if (data) {
        return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Grid, __assign({ container: true, justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.secondary_lighthouses.title') })) })), _jsx(Grid, __assign({ item: true }, { children: _jsx(OGLinkButton, __assign({ variant: "primary", startIcon: _jsx(AddCircleOutline, {}), to: ADD_SECONDARY_ROUTE, disabled: !hasPermission('configure.multiple_instance.dependent_lighthouse.edit') || (data && data.dependent_lighthouses.length >= 10) }, { children: t('pages.secondary_lighthouse.add.title') })) }))] })), data && data.dependent_lighthouses.some(function (lighthouse) { return lighthouse.status === 'Upgrading'; }) && (_jsx(Box, __assign({ marginTop: 2 }, { children: _jsx(WarningBanner, { warningIcon: _jsx(LoadingIcon, { style: { fontSize: '40px' }, color: "warning" }), warningHeader: t('pages.secondary_lighthouses.upgrading_warning.header'), warningMessage: _jsx(Typography, { children: t('pages.secondary_lighthouses.upgrading_warning.message') }), warningColor: "warning", bannerCode: "secondaries-upgrading", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }) }))), _jsx(Box, __assign({ marginTop: 2 }, { children: _jsx(OGPanel, { children: _jsx(SecondaryLighthousesTable, {}) }) }))] })));
    }
    return null;
}, 
// Verifies user permissions and secondary lighthouse check
{
    rights: ['configure.multiple_instance.dependent_lighthouse.view'],
});
