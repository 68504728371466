var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
import { OGButtonLink } from '@opengear/buttons';
var LOADING_LABEL_ID = 'support-report-loading-label';
export var SupportReportButton = memo(function (_a) {
    var status = _a.status, generate = _a.generate;
    var t = useTranslation().t;
    var handleClick = function () {
        generate();
    };
    if (status === 'loading') {
        return (_jsxs(_Fragment, { children: [_jsx(OGAutoRenewIcon, { "aria-labelledby": LOADING_LABEL_ID, color: "success" }), _jsxs(Typography, __assign({ id: LOADING_LABEL_ID, fontWeight: "500" }, { children: [t('components.support_report.status.loading.header'), _jsx(Typography, __assign({ component: "span", display: "block" }, { children: t('components.support_report.status.loading.subtext') }))] }))] }));
    }
    if (status === 'success') {
        return (_jsx(OGButtonLink, __assign({ startIcon: _jsx(ThumbUpIcon, { fontSize: "medium", color: "success" }), sx: { fontWeight: 500 }, onClick: handleClick }, { children: t('components.support_report.status.success') })));
    }
    if (status === 'error') {
        return (_jsx(OGButtonLink, __assign({ startIcon: _jsx(ErrorIcon, { fontSize: "medium", color: "error" }), sx: { fontWeight: 500 }, onClick: handleClick }, { children: t('components.support_report.status.error') })));
    }
    return _jsx(OGButtonLink, __assign({ onClick: handleClick }, { children: t('components.support_report.status.idle') }));
});
