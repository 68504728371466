var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OGLink } from '@opengear/links';
import { useFormContainerContext } from '@/components/layout/forms';
var SUPPORT_UPDATE_LINK = 'https://opengear.com/support/device-updates/';
export function SelectFirmwareWarningInfo() {
    var theme = useTheme();
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var selectedFirmware = watch('firmware');
    if (!selectedFirmware)
        return null;
    return (_jsxs(Box, __assign({ sx: { marginTop: 1, padding: 2, backgroundColor: theme.palette.background.box } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 1, alignItems: "center", marginBottom: 1 }, { children: [_jsx(InfoIcon, { fontSize: "small" }), _jsx(Typography, __assign({ component: "span" }, { children: format(t('pages.firmware_upgrade_new.steps.setup.warning_info'), selectedFirmware.family, selectedFirmware.version) }))] })), _jsx(OGLink, __assign({ sx: { color: theme.palette.secondary.main, maxWidth: 'max-content' }, to: SUPPORT_UPDATE_LINK, external: true, startIcon: _jsx(OpenInNewIcon, { fontSize: "small", sx: { marginRight: 1 } }) }, { children: t('pages.firmware_upgrade_new.steps.setup.link') }))] })));
}
