var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGTable } from '@opengear/table';
import { OGButtonLink } from '@opengear/buttons';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
export function GroupsTable(_a) {
    var onGroupClick = _a.onGroupClick;
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var groups = watch('groups');
    var renderGroupNameCell = useCallback(function (_a) {
        var row = _a.row, value = _a.value, tabIndex = _a.tabIndex;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                onGroupClick(row);
            }, propagateSpacebar: false }, { children: value })));
    }, [onGroupClick]);
    var renderRoleCell = function (_a) {
        var value = _a.value;
        return (value === null || value === void 0 ? void 0 : value[0]) === 'administrator'
            ? t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.options.administrator')
            : t('pages.config_templates.tabs.users_groups.sections.group_list.fields.role.options.user');
    };
    var columns = [
        {
            field: 'groupname',
            headerName: t('pages.config_templates.tabs.users_groups.sections.group_list.table.headers.groupname'),
            flex: 1,
            renderCell: renderGroupNameCell,
        },
        {
            field: 'description',
            headerName: t('pages.config_templates.tabs.users_groups.sections.group_list.table.headers.description'),
            flex: 2,
        },
        {
            field: 'roles',
            headerName: t('pages.config_templates.tabs.users_groups.sections.group_list.table.headers.role'),
            flex: 1,
            renderCell: renderRoleCell,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ id: "config-templates-user-groups-groups-table-description", component: "span", sx: { marginBottom: 2 } }, { children: t('pages.config_templates.tabs.users_groups.sections.group_list.sub_heading') })), _jsx(OGTable, { id: "config-templates-user-groups-groups-table", "aria-describedby": "config-templates-user-groups-groups-table-description", "aria-label": t('pages.config_templates.tabs.users_groups.sections.group_list.table.label'), autoHeight: true, columns: columns, rows: groups, noRowsMessage: t('pages.config_templates.tabs.users_groups.sections.group_list.table.no_data_message') })] }));
}
