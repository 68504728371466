// https://www.figma.com/file/86vt9ohb1s2QI7k1vmTfIQ/OG-Engineering-Colors?node-id=0%3A1
export var OG_BLACK_90 = '#13181C';
export var OG_BLACK_80 = '#1D2226';
export var OG_BLACK = '#22282C';
export var OG_GREY_H = '#353E44';
export var OG_GREY_M = '#515A61';
export var OG_GREY_L = '#606571';
export var OG_GREY_ML = '#AAB2C6';
export var OG_GREY_VL = '#D6D8E4';
export var OG_GREY_15 = '#E6E8F4';
export var OG_GREY_VVVL = '#F0F1F3';
export var OG_OFF_WHITE = '#F5F5F5';
export var OG_WHITE = '#FFFFFF';
export var OG_MENU_BG_LIGHT = '#F1F2F5';
export var OG_SELECTBAR_LIGHT = '#E6E8EE';
// logo colors
export var OG_RED = '#C8102E';
export var OG_GREY = '#A4A5A5';
// Buttons
export var OG_BUTTON_PRIMARY = '#A02B3E';
export var OG_BUTTON_PRIMARY_HOVER = '#C94A5F';
export var OG_BUTTON_PRIMARY_ACTIVE = '#C70928';
// Input
export var OG_LIGHT_LABEL = '#636A74';
// Link
export var OG_LINK_DARK_STANDARD = '#F498A7';
// Focus
export var OG_FOCUS = '#22B9F5';
export var OG_FOCUS_BOX_SHADOW = "0px 0px 3px 1px ".concat(OG_FOCUS);
export var OG_FOCUS_BOX_INNER_SHADOW = "0px 0px 3px 1px inset ".concat(OG_FOCUS);
export var OG_FOCUS_DROP_SHADOW = "drop-shadow(0px 0px 1px ".concat(OG_FOCUS, ")");
// Pinned columns
export var OG_PINNED_COLUMNS_SHADOW_DARK = "0px 0px 8px 0px rgba(0, 0, 0, 1)";
export var OG_PINNED_COLUMNS_SHADOW_LIGHT = "0px 0px 8px 0px rgba(19, 19, 19, 0.3)";
export var OG_DANGER_LIGHT = '#E00C8B';
export var OG_WARNING_LIGHT = '#9E6D23';
export var OG_SUCCESS_LIGHT = '#5C8012';
export var OG_DANGER_DARK = '#F45384';
export var OG_WARNING_DARK = '#FEC545';
export var OG_SUCCESS_DARK = '#90C81A';
export var OG_BACKGROUND_LIGHT = '#EAEBEF';
export var OG_BACKGROUND_DARK = '#1A2024';
