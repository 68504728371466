var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useCallback } from 'react';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { requiredFieldError, maxLengthFieldError } from '@/utils/field-validation-errors';
export function NameField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, mode = _a.mode;
    var fieldMaxLength = 64;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            required: requiredFieldError(t('pages.config_templates.edit.form.auth.template_details.name.title')),
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.template_details.name.title'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess || mode === 'update', required: true, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.config_templates.edit.form.auth.template_details.name.title') }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var fieldMaxLength = 128;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: fieldMaxLength,
                message: maxLengthFieldError(t('pages.config_templates.edit.form.auth.template_details.description'), fieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(TextField, __assign({ fullWidth: true, sx: { maxWidth: isMobileView ? undefined : 400 }, disabled: !permissions.hasAuthEditAccess, error: !!error, helperText: error === null || error === void 0 ? void 0 : error.message, label: t('pages.config_templates.edit.form.auth.template_details.description') }, field)));
        } }));
}
export function AuthModeButtons() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions, resetField = _a.resetField, formState = _a.formState, mode = _a.mode;
    var isMobileView = useDeviceView().isMobileView;
    // Reset all hidden fields so they can't cause validation errors or cause the form to be dirty unexpectedly
    var resetHidden = useCallback(function () {
        if (formState.defaultValues) {
            Object.keys(formState.defaultValues).forEach(function (key) {
                if (!['authMode', 'description'].includes(key)) {
                    resetField(key);
                }
            });
        }
    }, [formState.defaultValues, resetField]);
    return (_jsx(Controller, { control: control, name: "authMode", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsxs(ToggleButtonGroup, __assign({ "aria-label": t('pages.config_templates.edit.form.auth.auth_setting.scheme.local.label'), disabled: !permissions.hasAuthEditAccess, exclusive: true, onChange: function (event, v) {
                    if (!['ldap', 'radius', 'tacacs', 'local'].includes(v))
                        return;
                    if (mode === 'update')
                        resetHidden();
                    onChange(v);
                } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal', sx: { marginBottom: 2 } }, { children: [_jsx(ToggleButton, __assign({ value: "local" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.local.header') })), _jsx(ToggleButton, __assign({ value: "radius" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.radius.header') })), _jsx(ToggleButton, __assign({ value: "tacacs" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.tacacs.header') })), _jsx(ToggleButton, __assign({ value: "ldap" }, { children: t('pages.config_templates.edit.form.auth.auth_setting.scheme.ldap.header') }))] })));
        } }));
}
