var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { OGPanel } from '@opengear/panel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGButton } from '@opengear/buttons';
import { useDeviceView } from '@/hooks/use-device-view';
import { useUpdateEnrollmentToken } from '@/api/system/enrollment-token';
import { useUpdateDefaultSubscription } from '@/api/system/default-subscription';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { useFormSync } from '@/hooks/use-form-sync';
import { LicenseSelectField } from '@/components/licenses/license-select';
import { displayAPIRequestsResults } from '@/utils/form-submission-result';
import { UpdateForm } from '@/components/layout/update-form';
import { CONTINUOUS_ALPHA_NUM_PATTERN } from '@/constants/regex';
import { useDownloadManifest } from '@/api/system/manifest-link';
import { invalidFieldError, maxLengthFieldError } from '@/utils/field-validation-errors';
// Field limits
export var TokenMaxLength = 128;
export var EnrollmentSettingsForm = memo(function (_a) {
    var editable = _a.editable, enrollmentToken = _a.enrollmentToken, defaultSubscription = _a.defaultSubscription, isFetchingData = _a.isFetchingData, manifestLink = _a.manifestLink, subscriptions = _a.subscriptions;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = useAlertNotification(), alertNotification = _b.alertNotification, closeRef = _b.closeRef;
    // Update enrollment settings hooks
    var updateEnrollmentToken = useUpdateEnrollmentToken();
    var updateDefaultSubscription = useUpdateDefaultSubscription();
    var downloadManifestFile = useDownloadManifest().downloadManifestFile;
    var sourceValues = useMemo(function () { return ({
        enrollmentToken: enrollmentToken !== null && enrollmentToken !== void 0 ? enrollmentToken : '',
        defaultSubscription: defaultSubscription,
    }); }, [enrollmentToken, defaultSubscription]);
    var _c = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _c.control, handleSubmit = _c.handleSubmit, _d = _c.formState, defaultValues = _d.defaultValues, dirtyFields = _d.dirtyFields, isDirty = _d.isDirty, isSubmitting = _d.isSubmitting, isValid = _d.isValid, reset = _c.reset, resetField = _c.resetField;
    // responsible for alert the user of data changes
    useFormSync(defaultValues, sourceValues, reset, t('pages.enrollment_settings.form.reset_warning.header'), isFetchingData);
    // Resets form back to current values from api or defaults
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    // Submit handler to update enrollment settings
    var onSubmit = useCallback(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var promises, updateResults, apiRequestResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    closeRef.current();
                    promises = [];
                    if (dirtyFields.enrollmentToken) {
                        promises.push((function () { return __awaiter(void 0, void 0, void 0, function () {
                            var updatedData;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, updateEnrollmentToken(formData.enrollmentToken)];
                                    case 1:
                                        updatedData = _b.sent();
                                        if (updatedData) {
                                            // reset related fields for success case
                                            resetField('enrollmentToken', { defaultValue: (_a = updatedData.system_global_enrollment_token.token) !== null && _a !== void 0 ? _a : '' });
                                        }
                                        return [2 /*return*/, updatedData ? t('pages.enrollment_settings.form.fields.enrollment_token.label') : undefined];
                                }
                            });
                        }); })());
                    }
                    if (dirtyFields.defaultSubscription) {
                        promises.push((function () { return __awaiter(void 0, void 0, void 0, function () {
                            var updatedData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, updateDefaultSubscription(formData.defaultSubscription)];
                                    case 1:
                                        updatedData = _a.sent();
                                        if (updatedData)
                                            resetField('defaultSubscription', { defaultValue: updatedData.enrollment_default_subscription.subscription_type });
                                        return [2 /*return*/, updatedData ? t('pages.enrollment_settings.form.fields.default_subscription.label') : undefined];
                                }
                            });
                        }); })());
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 1:
                    updateResults = _a.sent();
                    apiRequestResult = displayAPIRequestsResults(updateResults);
                    if (apiRequestResult)
                        alertNotification(apiRequestResult.message
                            ? t('api.errors.partial_failure.message').replace('%n', apiRequestResult.message)
                            : t('pages.enrollment_settings.form.submit.ok.message'), {
                            header: apiRequestResult.priority === OGToastPriorities.Warning ? t('api.errors.partial_failure.header') : undefined,
                            priority: apiRequestResult.priority,
                        });
                    return [2 /*return*/];
            }
        });
    }); }, [
        alertNotification,
        closeRef,
        dirtyFields.enrollmentToken,
        dirtyFields.defaultSubscription,
        resetField,
        t,
        updateDefaultSubscription,
        updateEnrollmentToken,
    ]);
    return (_jsx(OGPanel, { children: _jsxs(UpdateForm, __assign({ "aria-label": t('pages.enrollment_settings.form.label'), additionalActions: _jsxs(_Fragment, { children: [(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.enrollmentToken) && (_jsx(OGButton, __assign({ disabled: !manifestLink, variant: "secondary", onClick: function () { return manifestLink && downloadManifestFile(manifestLink); } }, { children: t('global.buttons.download') }))), _jsx(Box, { flex: 1 })] }), dirty: isDirty, editable: !!editable, submitting: isSubmitting, valid: isValid, onReset: handleReset, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Controller, { control: control, name: "enrollmentToken", rules: {
                        pattern: { value: CONTINUOUS_ALPHA_NUM_PATTERN, message: invalidFieldError(t('pages.enrollment_settings.form.fields.enrollment_token.label')) },
                        maxLength: {
                            value: TokenMaxLength,
                            message: maxLengthFieldError(t('pages.enrollment_settings.form.fields.enrollment_token.label'), TokenMaxLength.toString()),
                        },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "enrollmentToken", disabled: !editable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.enrollment_settings.form.fields.enrollment_token.label'), _jsx(OGIconTooltip, { title: t('pages.enrollment_settings.form.fields.enrollment_token.tooltip') })] }), type: "text", sx: { maxWidth: isMobileView ? '100%' : '250px' } }, field)));
                    } }), _jsxs(FormControl, __assign({ margin: "none" }, { children: [_jsxs(FormLabel, { children: [t('pages.enrollment_settings.form.fields.default_subscription.label'), _jsx(OGIconTooltip, { title: t('pages.enrollment_settings.form.fields.default_subscription.tooltip') })] }), _jsx(Controller, { control: control, name: "defaultSubscription", render: function (_a) {
                                var field = _a.field;
                                return (_jsx(LicenseSelectField, { subscriptionsData: subscriptions, field: field, ariaLabel: t('pages.enrollment_settings.form.fields.default_subscription.label'), orientation: isMobileView ? 'vertical' : 'horizontal', disabled: !editable }));
                            } })] }))] })) }));
});
