var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { OGLinkButton } from '@opengear/links';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import LocalUsersTable from '@/components/accounts/users/local-users-table';
import { ADD_USER_ROUTE } from '@/constants/routes';
function LocalUsers() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditAccess = hasPermission('settings.user_management.local_users.edit');
    useDocTitle(t('pages.local_users.title'));
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2" }, { children: t('pages.local_users.title') })), _jsx(OGLinkButton, __assign({ variant: "primary", startIcon: _jsx(AddCircleOutline, {}), to: ADD_USER_ROUTE, disabled: !hasEditAccess }, { children: t('pages.local_users.buttons.add_user') }))] })), _jsx(Paper, __assign({ sx: { padding: 2 } }, { children: _jsx(LocalUsersTable, {}) }))] })));
}
export default withAuthenticationRequired(LocalUsers);
