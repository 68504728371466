var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGReinstatePasswordIcon(props) {
    return (_jsxs(SvgIcon, __assign({}, __assign({ viewBox: '0 0 18 19' }, props), { children: [_jsx("path", { fill: "none", d: "M7 10H2C1.44772 10 1 9.55228 1 9V2C1 1.44772 1.44772 1 2 1H17C17.5523 1 18 1.44772 18 2V7.021", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M6 3.5V7.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M7.673 4.40308L4.328 6.59708", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M7.664 6.60989L4.336 4.38989", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M13 3.5V7.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.673 4.40308L11.328 6.59708", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.664 6.60989L11.336 4.38989", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.8086 10.1914L18.1758 8.82422V12.9258H14.0742L15.9609 11.0391C15.2682 10.3464 14.4479 10 13.5 10C12.5339 10 11.7044 10.3464 11.0117 11.0391C10.3372 11.7135 10 12.5339 10 13.5C10 14.4661 10.3372 15.2956 11.0117 15.9883C11.7044 16.6628 12.5339 17 13.5 17C14.1745 17 14.8307 16.7721 15.4688 16.3164C16.125 15.8607 16.5716 15.3138 16.8086 14.6758H18.0117C17.7565 15.6966 17.2096 16.5352 16.3711 17.1914C15.5326 17.8477 14.5755 18.1758 13.5 18.1758C12.224 18.1758 11.1302 17.7201 10.2188 16.8086C9.30729 15.8971 8.85156 14.7943 8.85156 13.5C8.85156 12.2057 9.30729 11.1029 10.2188 10.1914C11.1302 9.27995 12.224 8.82422 13.5 8.82422C14.0469 8.82422 14.6393 8.96094 15.2773 9.23438C15.9336 9.48958 16.444 9.80859 16.8086 10.1914Z", fill: "currentColor" })] })));
}
