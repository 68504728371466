import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import { OG_DANGER_DARK, OG_DANGER_LIGHT, OG_GREY_L, OG_GREY_VL, OG_SUCCESS_DARK, OG_SUCCESS_LIGHT } from '@opengear/theme/colors';
/**
 * Node status series order
 */
export var nodeStatusSeriesOrder = {
    disconnected: 0,
    pending: 1,
    connected: 2,
};
/**
 * Initial node status donut data
 */
var initialNodeStatusDonutData = {
    seriesData: [],
    seriesTotal: 0,
    summaryTotal: 0,
};
/**
 * Node status donut colors
 */
var nodeStatusDonutColors = {
    light: {
        disconnected: OG_DANGER_LIGHT,
        pending: OG_GREY_L,
        connected: OG_SUCCESS_LIGHT,
    },
    dark: {
        disconnected: OG_DANGER_DARK,
        pending: OG_GREY_VL,
        connected: OG_SUCCESS_DARK,
    },
};
/**
 * Build node status donut data
 */
export function useBuildNodeStatusDonutData(connectionSummary) {
    var t = useTranslation().t;
    var themeMode = useTheme().palette.mode;
    return useMemo(function () {
        if (!connectionSummary)
            return initialNodeStatusDonutData;
        /* eslint-disable no-param-reassign */
        var nodeStatusDonutData = connectionSummary.reduce(function (donutData, _a) {
            var status = _a.status, count = _a.count;
            // Do nothing if count is less than 1
            if (count < 1)
                return donutData;
            // Always add to summary total count. May include 'rejected' status which is not shown on pie chart.
            donutData.summaryTotal += count;
            // Update series data for each summary
            if (status === 'disconnected' || status === 'pending' || status === 'connected') {
                donutData.seriesTotal += count;
                donutData.seriesData.push({
                    id: status,
                    color: nodeStatusDonutColors[themeMode][status],
                    label: t('pages.dashboard.nodes.node_connection')[status],
                    value: count,
                });
            }
            return donutData;
        }, {
            seriesData: [],
            seriesTotal: 0,
            summaryTotal: 0,
        });
        /* eslint-enable no-param-reassign */
        // Sort series data by order
        nodeStatusDonutData.seriesData.sort(function (a, b) { return nodeStatusSeriesOrder[a.id] - nodeStatusSeriesOrder[b.id]; });
        return nodeStatusDonutData;
    }, [connectionSummary, t, themeMode]);
}
/**
 * Node status list icons
 */
export var nodeStatusListIcons = {
    disconnected: _jsx(ErrorIcon, { fontSize: "medium", color: "error" }),
    pending: _jsx(PendingIcon, { fontSize: "medium", color: "info" }),
    connected: _jsx(SuccessIcon, { fontSize: "medium", color: "success" }),
};
