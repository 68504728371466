var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { rest } from 'msw';
import { RESOURCE_SMARTGROUP_PATH, formatResourceSmartgroupPath } from './smartgroups';
import { notFoundError } from '@/test/utils';
import { formatApiPath } from '@/api/utils';
import { formatSmfResourceSmartgroupId } from '@/constants/models.mock';
import { IQueryDataType, IQueryOperation, IQueryType, IQuerySmfResourceField } from '../../../../types/query/types';
export var mockResourceSmartgroupDB = new Map();
export var queryAndOnlyMock = {
    items: [
        {
            items: [
                {
                    items: [
                        {
                            fieldname: IQuerySmfResourceField.name,
                            oper: IQueryOperation.equal,
                            datatype: IQueryDataType.string,
                            type: IQueryType.basic,
                            value: 'name',
                        },
                    ],
                    type: IQueryType.and,
                },
            ],
            type: IQueryType.or,
        },
    ],
    type: IQueryType.and,
};
export var queryOrOnlyMock = {
    items: [
        {
            items: [
                {
                    items: [
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.string,
                            fieldname: IQuerySmfResourceField.name,
                            oper: IQueryOperation.equal,
                            value: 'test name',
                        },
                    ],
                    type: IQueryType.and,
                },
                {
                    items: [
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.reference,
                            fieldname: IQuerySmfResourceField.tag,
                            oper: IQueryOperation.equal,
                            value: 'tag-name-1',
                        },
                    ],
                    type: IQueryType.and,
                },
            ],
            type: IQueryType.or,
        },
    ],
    type: IQueryType.and,
};
export var queryAndOrMixMock = {
    items: [
        {
            items: [
                {
                    items: [
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.string,
                            fieldname: IQuerySmfResourceField.name,
                            oper: IQueryOperation.notEqual,
                            value: 'Brisbane',
                        },
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.reference,
                            fieldname: IQuerySmfResourceField.tag,
                            oper: IQueryOperation.equal,
                            value: 'zone-a',
                        },
                    ],
                    type: IQueryType.and,
                },
                {
                    items: [
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.string,
                            fieldname: IQuerySmfResourceField.name,
                            oper: IQueryOperation.equal,
                            value: 'Sydney',
                        },
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.reference,
                            fieldname: IQuerySmfResourceField.tag,
                            oper: IQueryOperation.equal,
                            value: 'zone-b',
                        },
                    ],
                    type: IQueryType.and,
                },
            ],
            type: IQueryType.or,
        },
    ],
    type: IQueryType.and,
};
export var queryNetworkRangeMock = {
    items: [
        {
            items: [
                {
                    items: [
                        {
                            type: IQueryType.basic,
                            datatype: IQueryDataType.string,
                            fieldname: IQuerySmfResourceField.networkRange,
                            oper: IQueryOperation.in,
                            value: '0.0.0.0 - 255.255.255.255',
                        },
                    ],
                    type: IQueryType.and,
                },
            ],
            type: IQueryType.or,
        },
    ],
    type: IQueryType.and,
};
export var resourceSmartgroupAndOnlyMock = {
    id: formatSmfResourceSmartgroupId(1),
    name: 'Resource Filter AND Only',
    query: JSON.stringify(queryAndOnlyMock),
    queryObject: queryAndOnlyMock,
    rights: { modify: true, delete: true },
};
export var resourceSmartgroupOrOnlyMock = {
    id: formatSmfResourceSmartgroupId(2),
    name: 'Resource Filter OR Only',
    query: JSON.stringify(queryOrOnlyMock),
    queryObject: queryOrOnlyMock,
    rights: { modify: true, delete: true },
};
export var resourceSmartgroupAndOrMixMock = {
    id: formatSmfResourceSmartgroupId(3),
    name: 'Resource Filter AND OR Mix',
    query: JSON.stringify(queryAndOrMixMock),
    queryObject: queryAndOrMixMock,
    rights: { modify: true, delete: true },
};
export var resourceSmartgroupBadQueryMock = {
    id: formatSmfResourceSmartgroupId(4),
    name: 'Resource Filter Bad Query',
    query: '{}',
    queryObject: null,
    rights: { modify: true, delete: true },
};
export var resourceSmartgroupNetworkRangeQueryMock = {
    id: formatSmfResourceSmartgroupId(5),
    name: 'Resource Filter Network Range Query',
    query: JSON.stringify(queryNetworkRangeMock),
    queryObject: queryNetworkRangeMock,
    rights: { modify: true, delete: true },
};
export function formatNewResourceSmartgroupId() {
    return formatSmfResourceSmartgroupId(mockResourceSmartgroupDB.size + 1);
}
function createMockResourceSmartgroup(_a) {
    var queryObject = _a.queryObject, newSmartgroup = __rest(_a, ["queryObject"]);
    var id = formatNewResourceSmartgroupId();
    var smartgroup = __assign({ id: id, rights: { modify: true, delete: true }, query: JSON.stringify(queryObject) }, newSmartgroup);
    mockResourceSmartgroupDB.set(id, smartgroup);
    return smartgroup;
}
function updateMockResourceSmartgroup(id, updatedSmartgroup) {
    var smartgroup = __assign({ id: id, rights: { modify: true, delete: true } }, updatedSmartgroup);
    mockResourceSmartgroupDB.set(id, smartgroup);
    return smartgroup;
}
export function createMockResourceSmartgroups() {
    var smartgroups = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        smartgroups[_i] = arguments[_i];
    }
    return smartgroups.map(createMockResourceSmartgroup);
}
export function resetResourceSmartgroups() {
    var additionalSmartgroups = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        additionalSmartgroups[_i] = arguments[_i];
    }
    mockResourceSmartgroupDB.clear();
    createMockResourceSmartgroups.apply(void 0, __spreadArray([resourceSmartgroupAndOnlyMock,
        resourceSmartgroupOrOnlyMock,
        resourceSmartgroupAndOrMixMock,
        resourceSmartgroupNetworkRangeQueryMock], __read(additionalSmartgroups), false));
}
export var smfResourcesSmartgroupsGetHandler = rest.get(formatApiPath(RESOURCE_SMARTGROUP_PATH), function (req, res, ctx) {
    return res(ctx.status(200), ctx.json({
        smartgroups: __spreadArray([], __read(mockResourceSmartgroupDB.values()), false),
        meta: {
            total_count: mockResourceSmartgroupDB.size,
            total_pages: 1,
        },
    }));
});
export var smfResourcesSmartgroupGetHandler = rest.get(formatApiPath(formatResourceSmartgroupPath()), function (req, res, ctx) {
    var resourceFilterId = req.params.smartgroupId;
    if (typeof resourceFilterId !== 'string') {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    var smfResourceSmartGroup = mockResourceSmartgroupDB.get(resourceFilterId);
    if (smfResourceSmartGroup === undefined) {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    return res(ctx.status(200), ctx.json({
        smartgroup: smfResourceSmartGroup,
    }));
});
export var smfResourcesSmartgroupsPostHandler = rest.post(formatApiPath(RESOURCE_SMARTGROUP_PATH), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var smartgroup, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = createMockResourceSmartgroup;
                return [4 /*yield*/, req.json()];
            case 1:
                smartgroup = _a.apply(void 0, [(_b.sent()).smartgroup]);
                return [2 /*return*/, res(ctx.status(200), ctx.json({ smartgroup: smartgroup }))];
        }
    });
}); });
export var smfResourcesSmartgroupPutHandler = rest.put(formatApiPath(formatResourceSmartgroupPath()), function (req, res, ctx) { return __awaiter(void 0, void 0, void 0, function () {
    var smartgroupId, smartgroup, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                smartgroupId = req.params.smartgroupId;
                if (!mockResourceSmartgroupDB.has(smartgroupId)) {
                    return [2 /*return*/, res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)))];
                }
                _a = updateMockResourceSmartgroup;
                _b = [smartgroupId];
                return [4 /*yield*/, req.json()];
            case 1:
                smartgroup = _a.apply(void 0, _b.concat([(_c.sent()).smartgroup]));
                return [2 /*return*/, res(ctx.status(200), ctx.json({ smartgroup: smartgroup }))];
        }
    });
}); });
export var smfResourcesSmartgroupDeleteHandler = rest.delete(formatApiPath(formatResourceSmartgroupPath()), function (req, res, ctx) {
    var smartgroupId = req.params.smartgroupId;
    var deleted = mockResourceSmartgroupDB.delete(smartgroupId);
    if (!deleted) {
        return res(ctx.status(404), ctx.json(notFoundError(req.url.pathname)));
    }
    return res(ctx.status(200));
});
