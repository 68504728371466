var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
export function OGDashboardIcon(props) {
    return (_jsx(SvgIcon, __assign({}, __assign({ viewBox: '-4 -4.5 24 24' }, props), { children: _jsx("svg", __assign({ fill: "none" }, { children: _jsx("path", { d: "M7.77.051C7.652.123.876 7.134.819 7.243a.598.598 0 0 0-.021.419c.038.115.18.246.293.271.168.037.245-.02.761-.553l.493-.509.008 3.064L2.361 13l.07.257c.233.847.785 1.446 1.516 1.643.161.043.435.047 4 .047 3.62 0 3.836-.003 3.997-.049.664-.188 1.2-.727 1.44-1.449.136-.406.136-.414.137-3.606V6.867l.513.525c.55.563.59.59.778.528.119-.039.244-.183.283-.326a.517.517 0 0 0-.094-.434C14.857 6.976 8.198.115 8.11.06c-.118-.073-.23-.076-.34-.009m2.801 3.76 2.149 2.224v3.362c-.001 3.726.006 3.568-.175 3.928-.187.37-.528.624-.907.677-.102.014-.527.022-.945.018l-.76-.007-.013-2.48-.013-2.48-.071-.15a.935.935 0 0 0-.419-.432c-.121-.057-.148-.058-1.484-.058h-1.36l-.125.062a.962.962 0 0 0-.447.529c-.053.17-.054.241-.054 2.599v2.424l-.78-.001c-.429 0-.857-.012-.952-.025-.458-.066-.821-.414-1.005-.961-.047-.139-.05-.322-.057-3.573L3.146 6.04l2.393-2.474 2.393-2.474.245.247c.135.136 1.212 1.249 2.394 2.472M9.12 11.68v2.347H6.747V9.333H9.12v2.347", fill: "currentColor" }) })) })));
}
