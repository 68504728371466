var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useDocTitle } from '@/hooks/doc-title';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { EnrollmentBundleForm } from '@/components/config/enrollment-bundles/bundle-form';
import { useEntitlementsData } from '@/api/system/entitlements';
import { useDefaultSubscription } from '@/api/system/default-subscription';
import { useNodeTags } from '@/api/tags/node-tags';
import { useTemplates } from '@/api/template/templates';
export var ADD_BUNDLE_LOADING_TEST_ID = 'add-bundle-loading';
function AddEnrollmentBundle() {
    var t = useTranslation().t;
    useDocTitle(t('pages.enrollment_bundles.add.title'));
    var lighthouseSubscriptions = useEntitlementsData().lighthouseSubscriptions;
    var _a = useDefaultSubscription(), defaultSubscriptionData = _a.data, isLoadingDefaultSubscription = _a.isLoading;
    var _b = useTemplates(), templatesData = _b.data, isLoadingTemplates = _b.isLoading;
    var _c = useNodeTags(), nodeTagsData = _c.data, isLoadingNodeTags = _c.isLoading;
    var innerNode = null;
    if (isLoadingDefaultSubscription || isLoadingNodeTags || isLoadingTemplates) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": ADD_BUNDLE_LOADING_TEST_ID });
    }
    if (defaultSubscriptionData && nodeTagsData && templatesData) {
        innerNode = (_jsx(EnrollmentBundleForm, { permissions: { hasEditPermission: true }, lighthouseSubscriptions: lighthouseSubscriptions, templates: templatesData, nodeTags: nodeTagsData.nodeTags, defaultSubscriptionData: defaultSubscriptionData }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 1.5 }, { children: t('pages.enrollment_bundles.add.title') })), innerNode] })));
}
export default withAuthenticationRequired(AddEnrollmentBundle, { rights: ['configure.node_enrollment.enrollment_bundles.edit'] });
