export var transformResponseToFormState = function (data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    return ({
        id: (data === null || data === void 0 ? void 0 : data.id) || '',
        enabled: (data === null || data === void 0 ? void 0 : data.enabled) || false,
        description: (data === null || data === void 0 ? void 0 : data.description) || '',
        media: (data === null || data === void 0 ? void 0 : data.media) || 'auto',
        physif: (data === null || data === void 0 ? void 0 : data.physif) || 'net1',
        role: (data === null || data === void 0 ? void 0 : data.role) || 'wan',
        name: (data === null || data === void 0 ? void 0 : data.name) || '',
        mode: (data === null || data === void 0 ? void 0 : data.mode) || 'static',
        ipv4_static_settings: {
            id: ((_a = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _a === void 0 ? void 0 : _a.id) || '',
            netmask: ((_b = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _b === void 0 ? void 0 : _b.netmask) || '',
            address: ((_c = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _c === void 0 ? void 0 : _c.address) || '',
            broadcast: ((_d = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _d === void 0 ? void 0 : _d.broadcast) || '',
            gateway: ((_e = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _e === void 0 ? void 0 : _e.gateway) || '',
            dns1: ((_f = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _f === void 0 ? void 0 : _f.dns1) || '',
            dns2: ((_g = data === null || data === void 0 ? void 0 : data.ipv4_static_settings) === null || _g === void 0 ? void 0 : _g.dns2) || '',
        },
        ipv6_static_settings: {
            id: ((_h = data === null || data === void 0 ? void 0 : data.ipv6_static_settings) === null || _h === void 0 ? void 0 : _h.id) || '',
            prefix_length: ((_j = data === null || data === void 0 ? void 0 : data.ipv6_static_settings) === null || _j === void 0 ? void 0 : _j.prefix_length) || 1,
            address: ((_k = data === null || data === void 0 ? void 0 : data.ipv6_static_settings) === null || _k === void 0 ? void 0 : _k.address) || '',
            gateway: ((_l = data === null || data === void 0 ? void 0 : data.ipv6_static_settings) === null || _l === void 0 ? void 0 : _l.gateway) || '',
            dns1: ((_m = data === null || data === void 0 ? void 0 : data.ipv6_static_settings) === null || _m === void 0 ? void 0 : _m.dns1) || '',
            dns2: ((_o = data === null || data === void 0 ? void 0 : data.ipv6_static_settings) === null || _o === void 0 ? void 0 : _o.dns2) || '',
        },
    });
};
