var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import { OGAutocomplete } from '@opengear/inputs/autocomplete';
import format from 'string-format';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useCallback, useMemo, useState, useRef } from 'react';
import { OGTable } from '@opengear/table';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { v4 as uuidV4 } from 'uuid';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
import { useOpen } from '@/hooks/open';
import { TemplateSelectionModal, ModuleSelectionModal } from './modals';
import { templateTypeMap } from '@/utils/template';
import { mergeTags } from './transformers';
import { CONTINUOUS_ALPHA_NUM_PATTERN } from '@/constants/regex';
import { LicenseSelectField } from '@/components/licenses/license-select';
import { RemoveCell } from '@/components/remove-cell';
import { ReorderCell } from '@/components/reorder-cell';
import { requiredFieldError, maxLengthFieldError, invalidFieldError } from '@/utils/field-validation-errors';
// Field limits
export var NameMaxLength = 64;
export var TokenMaxLength = 64;
export var TagNameMaxLength = 128;
export var TagValueMaxLength = 128;
export function BundleNameInput() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "name", rules: {
            pattern: { value: CONTINUOUS_ALPHA_NUM_PATTERN, message: invalidFieldError(t('pages.enrollment_bundles.add.fields.name.label')) },
            maxLength: {
                value: NameMaxLength,
                message: maxLengthFieldError(t('pages.enrollment_bundles.add.fields.name.label'), NameMaxLength.toString()),
            },
            required: requiredFieldError(t('pages.enrollment_bundles.add.fields.name.label')),
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ required: true, id: "name", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, disabled: !permissions.hasEditPermission, label: _jsxs(_Fragment, { children: [t('pages.enrollment_bundles.add.fields.name.label'), _jsx(OGIconTooltip, { title: t('pages.enrollment_bundles.add.fields.name.tooltip'), "aria-label": t('pages.enrollment_bundles.add.fields.name.accessible_tooltip') })] }) }, field, { sx: { width: isMobileView ? undefined : 400 } })));
        } }));
}
export function EnrollmentTokenInput() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsx(Controller, { control: control, name: "token", rules: {
            pattern: { value: CONTINUOUS_ALPHA_NUM_PATTERN, message: invalidFieldError(t('pages.enrollment_bundles.add.fields.token.label')) },
            maxLength: {
                value: TokenMaxLength,
                message: maxLengthFieldError(t('pages.enrollment_bundles.add.fields.token.label'), TokenMaxLength.toString()),
            },
            required: requiredFieldError(t('pages.enrollment_bundles.add.fields.token.label')),
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ required: true, id: "token", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, disabled: !permissions.hasEditPermission, label: _jsxs(_Fragment, { children: [t('pages.enrollment_bundles.add.fields.token.label'), _jsx(OGIconTooltip, { title: t('pages.enrollment_bundles.add.fields.token.tooltip'), "aria-label": t('pages.enrollment_bundles.add.fields.token.accessible_tooltip') })] }) }, field, { sx: { width: isMobileView ? undefined : 400 } })));
        } }));
}
export function AutoApproveNodeField() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    return (_jsx(Controller, { control: control, name: "auto_approve", render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return (_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('pages.enrollment_bundles.add.fields.auto_approve.label') }), _jsxs(ToggleButtonGroup, __assign({ exclusive: true, "aria-label": t('pages.enrollment_bundles.add.fields.auto_approve.label'), disabled: !permissions.hasEditPermission, onChange: function (event, newValue) {
                            if (newValue !== null)
                                onChange(newValue);
                        } }, field, { children: [_jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') })), _jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') }))] }))] }));
        } }));
}
export function SubscriptionSelectField(_a) {
    var subscriptions = _a.subscriptions, modules = _a.modules;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, permissions = _b.permissions;
    var isMobileView = useDeviceView().isMobileView;
    var validateSubscription = useCallback(function (tier, formValues) {
        var moduleNames = formValues.modules.map(function (id) { var _a; return (_a = modules.find(function (moduleInfo) { return moduleInfo.id === id; })) === null || _a === void 0 ? void 0 : _a.name; });
        if (tier === 'enterprise' && (moduleNames.includes('Secure Provisioning') || moduleNames.includes('Automation Gateway'))) {
            return t('pages.enrollment_bundles.add.fields.subscription.incompatible_subscription');
        }
        return true;
    }, [modules, t]);
    return (_jsx(Controller, { control: control, name: "tier", rules: { required: requiredFieldError(t('pages.enrollment_bundles.add.fields.subscription.label')), validate: validateSubscription }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [t('pages.enrollment_bundles.add.fields.subscription.label'), _jsx(OGIconTooltip, { title: t('components.enroll_node_modal.form.fields.subscription_type.tooltip') })] }), _jsx(LicenseSelectField, { subscriptionsData: subscriptions, field: field, ariaLabel: t('pages.enrollment_bundles.add.fields.subscription.label'), orientation: isMobileView ? 'vertical' : 'horizontal', disabled: !permissions.hasEditPermission }), _jsx(FormHelperText, __assign({ error: !!fieldState.error }, { children: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message }))] }));
        } }));
}
export function TagsField(_a) {
    var tags = _a.tags;
    var t = useTranslation().t;
    var _b = __read(useState(new Map()), 2), userTags = _b[0], setUserTags = _b[1];
    var _c = useFormContainerContext(), control = _c.control, watch = _c.watch, setValue = _c.setValue, setFocus = _c.setFocus, permissions = _c.permissions;
    var addTagButton = useRef(null);
    var _d = useFieldArray({
        name: 'automatic_tags',
        control: control,
    }), fields = _d.fields, append = _d.append, remove = _d.remove;
    var tagFields = watch('automatic_tags');
    var allOptions = useMemo(function () { return mergeTags(tags, userTags, tagFields); }, [tagFields, tags, userTags]);
    var tagList = __spreadArray([], __read(allOptions.keys()), false);
    var nameFilter = createFilterOptions();
    var valueFilter = createFilterOptions();
    var handleRemoveTag = useCallback(function (index) {
        if (fields.length > 1) {
            var nextTag = fields.length - 1 > index ? index + 1 : index - 1;
            setFocus("automatic_tags.".concat(nextTag, ".name"));
        }
        else if (addTagButton.current) {
            addTagButton.current.focus();
        }
        remove(index);
    }, [remove, setFocus, fields, addTagButton]);
    return (_jsxs(Stack, __assign({ role: "group", "aria-label": t('pages.enrollment_bundles.add.headings.tags') }, { children: [fields.length > 0 ? (fields.map(function (field, index) {
                var tagName = tagFields[index].name;
                var tagValues = allOptions.get(tagName);
                var valuesList = tagValues === undefined ? [] : __spreadArray([], __read(tagValues), false);
                return (_jsxs(Stack, __assign({ direction: "row", spacing: 2, paddingTop: 1, role: "group" }, { children: [_jsx(Controller, { control: control, name: "automatic_tags.".concat(index, ".name"), rules: {
                                required: requiredFieldError(t('pages.nodes.tags_update_modal.form.fields.tag_name.label_no_id')),
                                maxLength: {
                                    value: TagNameMaxLength,
                                    message: maxLengthFieldError(t('pages.nodes.tags_update_modal.form.fields.tag_name.label_no_id'), TagNameMaxLength.toString()),
                                },
                            }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, value = _b.value, fieldAttributes = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error;
                                return (_jsx(OGAutocomplete, __assign({ disabled: !permissions.hasEditPermission, ariaLabel: format(t('pages.nodes.tags_update_modal.form.fields.tag_name.label'), (index + 1).toString()), value: value === '' ? null : value, error: !!error, options: tagList, placeholder: t('pages.nodes.tags_update_modal.form.fields.tag_name.placeholder'), disableClearable: true, helperText: error === null || error === void 0 ? void 0 : error.message, getOptionDisabled: function (option) { return tagFields.map(function (item) { return item.name; }).includes(option); }, label: t('pages.nodes.tags_update_modal.form.fields.tag_name.label_no_id'), required: true, onChange: function (_, data) {
                                        var nextName = '';
                                        if (typeof data === 'string') {
                                            if (tagList.includes(data)) {
                                                nextName = data;
                                            }
                                        }
                                        else if (data && 'inputValue' in data) {
                                            var newTags = new Map(userTags);
                                            newTags.set(data.inputValue, new Set());
                                            setUserTags(newTags);
                                            nextName = data.inputValue;
                                        }
                                        onChange(nextName);
                                        setValue("automatic_tags.".concat(index, ".value"), ''); // clear the corresponding value when the tag changes
                                    }, getOptionLabel: function (option) {
                                        // e.g. value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return '';
                                    }, defaultValue: value || undefined, filterOptions: function (options, params) {
                                        var filtered = nameFilter(options, params);
                                        if (params.inputValue !== '' && !tagList.includes(params.inputValue)) {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                label: format(t('pages.enrollment_bundles.add.fields.tags.new_tag'), params.inputValue),
                                            });
                                        }
                                        return filtered;
                                    } }, fieldAttributes, { sx: { width: 300 } })));
                            } }), _jsx(Controller, { control: control, name: "automatic_tags.".concat(index, ".value"), rules: {
                                required: requiredFieldError(t('pages.nodes.tags_update_modal.form.fields.tag_value.label_no_id')),
                                maxLength: {
                                    value: TagValueMaxLength,
                                    message: maxLengthFieldError(t('pages.nodes.tags_update_modal.form.fields.tag_value.label_no_id'), TagValueMaxLength.toString()),
                                },
                            }, render: function (_a) {
                                var _b = _a.field, onChange = _b.onChange, fieldValue = _b.value, fieldAttributes = __rest(_b, ["onChange", "value"]), error = _a.fieldState.error;
                                return (_jsx(OGAutocomplete, __assign({ error: !!error, value: fieldValue === '' ? null : fieldValue, placeholder: t('pages.nodes.tags_update_modal.form.fields.tag_value.placeholder'), onChange: function (_, data) {
                                        var nextValue = '';
                                        if (typeof data === 'string') {
                                            if (valuesList.includes(data)) {
                                                nextValue = data;
                                            }
                                        }
                                        else if (data && 'inputValue' in data && typeof data.inputValue === 'string') {
                                            var newTags = new Map(userTags);
                                            var newTagValues = newTags.get(tagName);
                                            if (newTagValues) {
                                                newTagValues.add(data.inputValue);
                                            }
                                            else {
                                                newTags.set(tagName, new Set(data.inputValue));
                                            }
                                            setUserTags(newTags);
                                            nextValue = data.inputValue;
                                        }
                                        onChange(nextValue);
                                    }, options: valuesList, disableClearable: true, helperText: error === null || error === void 0 ? void 0 : error.message, disabled: !tagName || !permissions.hasEditPermission, ariaLabel: format(t('pages.nodes.tags_update_modal.form.fields.tag_value.label'), tagName), label: t('pages.nodes.tags_update_modal.form.fields.tag_value.label_no_id'), required: true, defaultValue: fieldValue || undefined, getOptionLabel: function (option) {
                                        // e.g. value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return '';
                                    }, filterOptions: function (options, params) {
                                        var filtered = valueFilter(options, params);
                                        if (params.inputValue !== '' && !valuesList.includes(params.inputValue)) {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                label: format(t('pages.enrollment_bundles.add.fields.tags.new_value'), params.inputValue, tagName),
                                            });
                                        }
                                        return filtered;
                                    } }, fieldAttributes, { sx: { width: 300 } })));
                            } }), permissions.hasEditPermission && (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.enrollment_bundles.add.fields.tags.remove') }, { children: _jsx(OGButton, { "aria-label": format(t('pages.enrollment_bundles.add.fields.tags.remove_label'), (index + 1).toString()), size: "small", sx: { '&.MuiButton-root': { height: '31px', marginLeft: '8px', marginTop: '22px' } }, startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return handleRemoveTag(index); } }) })))] }), field.id));
            })) : (_jsx(Typography, __assign({ sx: { marginY: 1 } }, { children: t('pages.nodes.tags_update_modal.no_tags_message') }))), _jsx(OGButton, __assign({ ref: addTagButton, id: "add-tag-button", startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return append({ name: '', value: '', id: uuidV4() }); }, disabled: !permissions.hasEditPermission }, { children: t('pages.nodes.tags_update_modal.buttons.add_tag') }))] })));
}
export function TemplatesTable(_a) {
    var templates = _a.templates, onMove = _a.onMove, addButtonRef = _a.addButtonRef;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), getValues = _b.getValues, setValue = _b.setValue, watch = _b.watch, permissions = _b.permissions;
    var selectedTemplates = watch('templates');
    // Remove row focus management
    var _c = __read(useState(undefined), 2), cellFocus = _c[0], setCellFocus = _c[1];
    var rows = useMemo(function () {
        var idToTemplateMap = new Map();
        templates.forEach(function (template) { return idToTemplateMap.set(template.id, template); });
        return selectedTemplates.map(function (id) { return idToTemplateMap.get(id); });
    }, [templates, selectedTemplates]);
    var handleRemoveTemplate = useCallback(function (id, nextFocusIndex) {
        var _a;
        if (typeof nextFocusIndex !== 'undefined') {
            var nextFocusRowId = getValues('templates')[nextFocusIndex];
            setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'remove' } : undefined);
        }
        else {
            (_a = addButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        var filteredTemplates = getValues('templates').filter(function (template) { return template !== id; });
        setValue('templates', filteredTemplates, { shouldDirty: true });
    }, [getValues, setValue, addButtonRef]);
    var renderRemoveCell = useCallback(function (_a) {
        var row = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        return (_jsx(RemoveCell, { index: api.getRowIndexRelativeToVisibleRows(row.id), currentCount: rows.length, tabIndex: tabIndex, onRemove: function (nextFocusIndex) { return handleRemoveTemplate(row.id, nextFocusIndex); }, translation: {
                label: format(t('pages.enrollment_bundles.add.fields.templates.remove_label'), row.name),
                tooltip: t('pages.enrollment_bundles.add.fields.templates.remove_tooltip'),
            } }));
    }, [handleRemoveTemplate, t, rows]);
    var columns = [
        {
            field: '__reorder__',
            cellClassName: 'nowrap',
            sortable: false,
            renderHeader: function () { return (_jsx("span", __assign({ role: "img", "aria-label": t('pages.enrollment_bundles.add.fields.templates.reorder_label') }, { children: _jsx(SwapVertIcon, {}) }))); },
            renderCell: function (_a) {
                var row = _a.row, api = _a.api, tabIndex = _a.tabIndex;
                return (_jsx(ReorderCell, { labelName: row.name, index: api.getRowIndexRelativeToVisibleRows(row.id), tabIndex: tabIndex, rowCount: rows.length, onMove: onMove, translation: t('pages.enrollment_bundles.add.fields.templates.row_reorder_label') }));
            },
        },
        {
            field: 'name',
            headerName: t('pages.enrollment_bundles.add.fields.templates.name'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'type',
            headerName: t('pages.enrollment_bundles.add.fields.templates.type'),
            flex: 1,
            sortable: false,
            maxWidth: 400,
            valueFormatter: function (_a) {
                var value = _a.value;
                return templateTypeMap.get(value);
            },
        },
        {
            field: 'description',
            headerName: t('pages.enrollment_bundles.add.fields.templates.description'),
            flex: 2,
            sortable: false,
        },
        {
            field: 'remove',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderRemoveCell,
        },
    ];
    return (_jsx(OGTable, { rowReordering: permissions.hasEditPermission, "aria-label": t('pages.enrollment_bundles.add.headings.templates'), autoHeight: true, rows: rows, columns: columns, noRowsMessage: t('pages.enrollment_bundles.add.fields.templates.no_rows'), sx: {
            '& .nowrap': {
                overflowWrap: 'normal',
                whiteSpace: 'nowrap',
            },
        }, columnVisibilityModel: {
            remove: permissions.hasEditPermission,
        }, setCellFocus: cellFocus }));
}
export function TemplatesSection(_a) {
    var templates = _a.templates;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), permissions = _b.permissions, watch = _b.watch;
    var _c = useOpen(false), openAddModal = _c.open, handleCloseAddModal = _c.handleClose, handleOpenAddModal = _c.handleOpen;
    var addButtonRef = useRef(null);
    var _d = useFieldArray({ name: 'templates' }), appendTemplate = _d.append, move = _d.move;
    var selectedTemplates = watch('templates');
    return (_jsxs(_Fragment, { children: [_jsx(TemplateSelectionModal, { open: openAddModal, handleClose: handleCloseAddModal, handleAddTemplates: appendTemplate, templateData: templates, excludeTemplates: selectedTemplates }), _jsxs(Stack, { children: [_jsx(Box, __assign({ marginBottom: 2 }, { children: _jsx(TemplatesTable, { templates: templates, onMove: move, addButtonRef: addButtonRef }) })), _jsx(Box, __assign({ display: "flex", gap: 1 }, { children: _jsx(OGButton, __assign({ startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", onClick: handleOpenAddModal, disabled: !permissions.hasEditPermission, ref: addButtonRef }, { children: t('pages.enrollment_bundles.add.fields.templates.add') })) }))] })] }));
}
export function ModulesTable(_a) {
    var templates = _a.templates, onMove = _a.onMove, addButtonRef = _a.addButtonRef;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), getValues = _b.getValues, setValue = _b.setValue, watch = _b.watch, trigger = _b.trigger, permissions = _b.permissions;
    var selectedModules = watch('modules');
    // Remove row focus management
    var _c = __read(useState(undefined), 2), cellFocus = _c[0], setCellFocus = _c[1];
    var rows = useMemo(function () {
        var idToTemplateMap = new Map();
        templates.forEach(function (template) { return idToTemplateMap.set(template.id, template); });
        return selectedModules.map(function (id) { return idToTemplateMap.get(id); });
    }, [templates, selectedModules]);
    var handleRemoveModule = useCallback(function (id, nextFocusIndex) {
        var _a;
        if (typeof nextFocusIndex !== 'undefined') {
            var nextFocusRowId = getValues('modules')[nextFocusIndex];
            setCellFocus(nextFocusRowId ? { id: nextFocusRowId, field: 'remove' } : undefined);
        }
        else {
            (_a = addButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        var filteredModules = getValues('modules').filter(function (template) { return template !== id; });
        setValue('modules', filteredModules, { shouldDirty: true });
        trigger('tier');
    }, [getValues, setValue, trigger, addButtonRef]);
    var renderRemoveCell = useCallback(function (_a) {
        var row = _a.row, tabIndex = _a.tabIndex, api = _a.api;
        return (_jsx(RemoveCell, { index: api.getRowIndexRelativeToVisibleRows(row.id), currentCount: rows.length, tabIndex: tabIndex, onRemove: function (nextFocusIndex) { return handleRemoveModule(row.id, nextFocusIndex); }, disabled: !permissions.hasEditPermission, translation: {
                label: format(t('pages.enrollment_bundles.add.fields.modules.remove_label'), row.name),
                tooltip: t('pages.enrollment_bundles.add.fields.modules.remove_tooltip'),
            } }));
    }, [handleRemoveModule, permissions, t, rows]);
    var columns = [
        {
            field: '__reorder__',
            cellClassName: 'nowrap',
            sortable: false,
            renderHeader: function () { return (_jsx("span", __assign({ role: "img", "aria-label": t('pages.enrollment_bundles.add.fields.modules.reorder_label') }, { children: _jsx(SwapVertIcon, {}) }))); },
            renderCell: function (_a) {
                var row = _a.row, api = _a.api, tabIndex = _a.tabIndex;
                return (_jsx(ReorderCell, { labelName: row.name, index: api.getRowIndexRelativeToVisibleRows(row.id), tabIndex: tabIndex, rowCount: rows.length, onMove: onMove, translation: t('pages.enrollment_bundles.add.fields.modules.row_reorder_label') }));
            },
        },
        {
            field: 'name',
            headerName: t('pages.enrollment_bundles.add.fields.modules.module_name'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'remove',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderRemoveCell,
        },
    ];
    return (_jsx(OGTable, { "aria-label": t('pages.enrollment_bundles.add.headings.modules'), rowReordering: permissions.hasEditPermission, autoHeight: true, rows: rows, columns: columns, noRowsMessage: t('pages.enrollment_bundles.add.fields.modules.no_rows'), sx: {
            '& .nowrap': {
                overflowWrap: 'normal',
                whiteSpace: 'nowrap',
            },
        }, columnVisibilityModel: {
            remove: permissions.hasEditPermission,
        }, setCellFocus: cellFocus }));
}
export function ModulesSection(_a) {
    var modules = _a.modules;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), watch = _b.watch, getValues = _b.getValues, permissions = _b.permissions;
    var _c = useOpen(false), openAddModal = _c.open, handleCloseAddModal = _c.handleClose, handleOpenAddModal = _c.handleOpen;
    var addButtonRef = useRef(null);
    var _d = useFieldArray({ name: 'modules' }), append = _d.append, move = _d.move;
    var handleAddModules = useCallback(function (id) {
        var currentModules = getValues('modules');
        if (!currentModules.includes(id)) {
            append(id);
        }
    }, [append, getValues]);
    var subscription = watch('tier');
    return (_jsxs(_Fragment, { children: [_jsx(ModuleSelectionModal, { open: openAddModal, handleClose: handleCloseAddModal, handleAddModule: handleAddModules, moduleData: modules, subscription: subscription }), _jsxs(Stack, { children: [_jsx(Box, __assign({ marginBottom: 2 }, { children: _jsx(ModulesTable, { templates: modules, onMove: move, addButtonRef: addButtonRef }) })), _jsx(Box, __assign({ display: "flex", gap: 1 }, { children: _jsx(OGButton, __assign({ startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", onClick: handleOpenAddModal, disabled: !permissions.hasEditPermission, ref: addButtonRef }, { children: t('pages.enrollment_bundles.add.fields.modules.add') })) }))] })] }));
}
