import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useNodeFirmwares } from '@/api/system/node-firmware';
import { FirmwareFilesTable } from './firmware-files-table';
import { FileManagerUploader } from './file-manager-uploader';
import { AvailableSpace } from './available-space';
export function FileManagerTab(_a) {
    var hasEditAccess = _a.hasEditAccess;
    var _b = useNodeFirmwares(), firmwareData = _b.data, isLoading = _b.isLoading;
    return (_jsxs(Box, { children: [hasEditAccess ? _jsx(FileManagerUploader, {}) : null, _jsx(FirmwareFilesTable, { firmwareData: firmwareData, isFirmwareLoading: isLoading, hasEditAccess: hasEditAccess }), _jsx(AvailableSpace, {})] }));
}
