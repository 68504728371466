var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useCallback } from 'react';
import format from 'string-format';
import { OGTable } from '@opengear/table';
import { OGButton } from '@opengear/buttons';
import { OGLink } from '@opengear/links';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '@mui/icons-material/Upload';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTemplates } from '@/api/template/templates';
import { usePaginationModel } from '@/hooks/pagination-model';
import { formatAuthTempEditRoute, formatUserGroupTemplateEditRoute, PUSH_TEMPLATE_NODE_SELECT_ROUTE, formatScriptTemplateEditRoute, formatSmfTemplateEditRoute, SERVICES_NETOPS_ROUTE, } from '@/constants/routes';
import { useUIPermissions } from '@/api/sessions/self/ui';
export function ConfigTemplateTab(_a) {
    var type = _a.type, canPushTemplates = _a.canPushTemplates;
    var t = useTranslation().t;
    var _b = useTemplates({ type: type }), data = _b.data, isLoading = _b.isLoading;
    var navigate = useNavigate();
    var defaultSortModel = [{ field: 'name', sort: 'asc' }];
    var pageSizeOptions = [10, 20, 50, 100];
    var _c = __read(usePaginationModel('templates'), 2), paginationModel = _c[0], handlePaginationModelChange = _c[1];
    var _d = useMemo(function () { return ({
        loading: isLoading,
        rowCount: (data === null || data === void 0 ? void 0 : data.length) || 0,
        rows: data || [],
    }); }, [data, isLoading]), loading = _d.loading, rowCount = _d.rowCount, rows = _d.rows;
    // Generate a unique key based on the rows data length and concatenated ids
    var tableKey = useMemo(function () { return rows.map(function (row) { return row.id; }).join(','); }, [rows]);
    var hasPermission = useUIPermissions().hasPermission;
    var canInstallModules = useMemo(function () { return hasPermission('settings.services.netops.edit'); }, [hasPermission]);
    var renderNameCell = useCallback(function (params) {
        var linkRoute = '';
        if (params.row.type === 'auth') {
            linkRoute = formatAuthTempEditRoute(params.id.toString());
        }
        else if (params.row.type === 'script') {
            linkRoute = formatScriptTemplateEditRoute(params.id.toString());
        }
        else if (params.row.type === 'users_groups') {
            linkRoute = formatUserGroupTemplateEditRoute(params.id.toString());
        }
        else if (params.row.type === 'nom') {
            return params.value;
        }
        else if (params.row.type === 'smf') {
            if (!params.row.smf_data.enabled) {
                // SMF templates that can't be deleted have 'enabled' set to false
                // User-created templates are always enabled=true
                return params.value;
            }
            linkRoute = formatSmfTemplateEditRoute(params.id.toString());
        }
        else if (params.row.type === 'port_logging') {
            return params.value;
        }
        return (_jsx(OGLink, __assign({ tabIndex: params.tabIndex, variant: "standard", to: linkRoute }, { children: params.value })));
    }, []);
    var columns = useMemo(function () { return [
        {
            field: 'name',
            flex: 1,
            headerName: t('pages.config_templates.table.headers.name'),
            sortable: true,
            minWidth: 90,
            renderCell: renderNameCell,
        },
        {
            field: 'description',
            flex: 2,
            headerName: t('pages.config_templates.table.headers.description'),
            minWidth: 130,
            sortable: true,
        },
        {
            field: 'push',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: function (_a) {
                var row = _a.row;
                return (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.config_templates.table.push_tooltip') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": format(t('pages.config_templates.table.push_label'), row.id), size: "small", startIcon: _jsx(UploadIcon, { fontSize: "small" }), variant: "borderless", onClick: function () { return navigate(PUSH_TEMPLATE_NODE_SELECT_ROUTE, { state: { templateId: row.id } }); }, disabled: !canPushTemplates, propagateSpacebar: false }) }) })));
            },
        },
    ]; }, [t, renderNameCell, canPushTemplates, navigate]);
    var ariaLabel = '';
    switch (type) {
        case 'auth':
            ariaLabel = t('pages.config_templates.tabs.auth.title');
            break;
        case 'script':
            ariaLabel = t('pages.config_templates.tabs.script.title');
            break;
        case 'users_groups':
            ariaLabel = t('pages.config_templates.tabs.users_groups.title');
            break;
        case 'nom':
            ariaLabel = t('pages.config_templates.tabs.netops.title');
            break;
        case 'smf':
            ariaLabel = t('pages.config_templates.tabs.smf.title');
            break;
        case 'port_logging':
            ariaLabel = t('pages.config_templates.tabs.port_logging.title');
            break;
        default:
            break;
    }
    return (_jsx(OGTable, { "aria-label": ariaLabel, autoHeight: true, columns: columns, rows: rows, noRowsMessage: type === 'nom' && canInstallModules ? (_jsx(Box, __assign({ role: "rowgroup" }, { children: _jsx(Box, __assign({ role: "row" }, { children: _jsx(Box, __assign({ role: "gridcell" }, { children: _jsx(OGLink, __assign({ variant: "standard", to: SERVICES_NETOPS_ROUTE }, { children: t('pages.config_templates.table.no_netops_rows_message') })) })) })) }))) : (t('pages.config_templates.table.no_rows_message')), loading: loading, pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "client", paginationModel: paginationModel, rowCount: rowCount, onPaginationModelChange: handlePaginationModelChange, initialState: {
            sorting: {
                sortModel: defaultSortModel,
            },
        } }, tableKey));
}
