var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { OGLink, OGLinkButton } from '@opengear/links';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DialogContentText from '@mui/material/DialogContentText';
import format from 'string-format';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useRole, useDeleteRole } from '@/api/roles';
import { RoleReadonlyForm } from '@/components/accounts/roles/role-readonly-form';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { GROUPS_ROLES_ROUTE, ROLES_ROUTE, NOT_FOUND_ROUTE, formatEditRoleRoute, formatCopyRoleRoute } from '@/constants/routes';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
export var ROLE_LOADING_TEST_ID = 'role-loading';
// Special roles that should not be deleted
var readOnlyRoles = ['LighthouseAdmin'];
function DeleteRole(_a) {
    var editable = _a.editable, roleId = _a.roleId;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var deleteRole = useDeleteRole();
    var onSuccess = useCallback(function () {
        navigate(GROUPS_ROLES_ROUTE + ROLES_ROUTE);
    }, [navigate]);
    var onDelete = useCallback(function () { return deleteRole(roleId); }, [deleteRole, roleId]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.accounts_role.actions.delete.modal.title'), deleteButtonLabel: t('pages.accounts_role.actions.delete.button'), onDelete: onDelete, onSuccess: onSuccess, disabled: !editable }, { children: _jsx(DialogContentText, __assign({ sx: { whiteSpace: 'pre-wrap' } }, { children: format(t('pages.accounts_role.actions.delete.modal.message'), roleId) })) })));
}
function Role() {
    var roleId = useParams().roleId;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var navigate = useNavigate();
    useDocTitle(t('pages.accounts_role.title'));
    var alertMessages = {
        forbidden: {
            message: format(t('api.role.get.forbidden.message'), roleId !== null && roleId !== void 0 ? roleId : ''),
            options: { persistOnUnmount: true },
        },
        not_found: {
            message: format(t('api.role.get.not_found.message'), roleId !== null && roleId !== void 0 ? roleId : ''),
            options: { persistOnUnmount: true },
        },
    };
    var statusCallback = useCallback(function (responseStatus) {
        if (responseStatus === 403 || responseStatus === 404) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate]);
    var _a = useRole(roleId, { alertMessages: alertMessages, statusCallback: statusCallback }), role = _a.data, isLoading = _a.isLoading;
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": ROLE_LOADING_TEST_ID });
    }
    var hasEditAccess = useMemo(function () { return hasPermission('settings.user_management.permissions.edit') && !!role && !readOnlyRoles.includes(role.rolename); }, [role, hasPermission]);
    var hasCopyAccess = useMemo(function () { return hasPermission('settings.user_management.permissions.edit'); }, [hasPermission]);
    if (role) {
        innerNode = (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1, marginBottom: 2 }, { children: [_jsx(OGLink, __assign({ startIcon: _jsx(ChevronLeftIcon, { fontSize: "medium" }), to: "".concat(GROUPS_ROLES_ROUTE).concat(ROLES_ROUTE) }, { children: t('pages.accounts_role.link') })), _jsx(Box, { flexGrow: 1 }), roleId !== undefined ? _jsx(DeleteRole, { editable: hasEditAccess, roleId: roleId }) : null, _jsx(OGLinkButton, __assign({ disabled: !hasCopyAccess, startIcon: _jsx(ContentCopyIcon, {}), variant: "borderless", to: formatCopyRoleRoute(role.id) }, { children: t('pages.accounts_role.actions.copy') })), _jsx(OGLinkButton, __assign({ disabled: !hasEditAccess, startIcon: _jsx(EditIcon, {}), variant: "borderless", to: formatEditRoleRoute(role.id) }, { children: t('pages.accounts_role.actions.edit') }))] })), _jsx(Paper, { children: _jsx(RoleReadonlyForm, { role: role }) })] }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.accounts_role.title') })), innerNode] })));
}
export default withAuthenticationRequired(Role, { rights: ['settings.user_management.permissions.view'] });
