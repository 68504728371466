var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { OGUsersIcon } from '@opengear/icons/navigation';
import ToggleButton from '@mui/material/ToggleButton';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { PopupMenuSection } from '@/components/popup-menu-section';
import { GROUPS_ROLES_ROUTE, LOCAL_AUTH_POLICY_ROUTE, LOCAL_USERS_ROUTE, REMOTE_AUTH_ROUTE, SSH_AUTH_ROUTE } from '@/constants/routes';
var ID = 'accounts-menu';
export function AccountsMenu() {
    var _a = __read(useState(null), 2), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = !!anchorEl;
    var t = useTranslation().t;
    var _b = useUIPermissions(), permissions = _b.permissions, hasPermission = _b.hasPermission, isLoadingPermissions = _b.isLoadingPermissions;
    var handleClick = useCallback(function (_a) {
        var currentTarget = _a.currentTarget;
        setAnchorEl(function (state) { return (state ? null : currentTarget); });
    }, [setAnchorEl]);
    var handleClose = useCallback(function () {
        setAnchorEl(null);
    }, [setAnchorEl]);
    return (_jsxs(_Fragment, { children: [_jsx(ToggleButton, __assign({ id: "".concat(ID, "-button"), "aria-expanded": open, "aria-haspopup": true, "aria-label": t('navigation.accounts_menu.label'), disabled: isLoadingPermissions || !permissions, selected: open, sx: { marginX: 0.5, marginBottom: 0.75, paddingX: 1 }, value: "checked", onClick: handleClick }, { children: _jsx(OGUsersIcon, {}) })), _jsx(Popover, __assign({ open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                } }, { children: _jsx(PopupMenuSection, { header: t('navigation.accounts_menu.title'), items: [
                        {
                            visible: true,
                            onClose: handleClose,
                            title: t('pages.groups_roles.title'),
                            to: GROUPS_ROLES_ROUTE,
                        },
                        {
                            visible: hasPermission('settings.user_management.password_policy.view'),
                            onClose: handleClose,
                            title: t('pages.local_auth_policy.title'),
                            to: LOCAL_AUTH_POLICY_ROUTE,
                        },
                        {
                            visible: true,
                            onClose: handleClose,
                            title: t('pages.local_users.title'),
                            to: LOCAL_USERS_ROUTE,
                        },
                        {
                            visible: hasPermission('settings.user_management.remote_authentication.view'),
                            onClose: handleClose,
                            title: t('pages.remote_auth.title'),
                            to: REMOTE_AUTH_ROUTE,
                        },
                        {
                            visible: hasPermission('settings.services.ssh.view'),
                            onClose: handleClose,
                            title: t('pages.ssh_auth.title'),
                            to: SSH_AUTH_ROUTE,
                        },
                    ] }) }))] }));
}
