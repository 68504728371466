var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
export function OpengearLogo() {
    var theme = useTheme();
    var t = useTranslation().t;
    return (_jsxs(SvgIcon, __assign({ titleAccess: t('components.appbar.logo_label'), sx: {
            height: 36,
            width: 38,
            display: 'flex',
        }, viewBox: "0 0 38 36" }, { children: [_jsx("path", { d: "M16.6688 0.901645C16.6688 0.901645 8.17083 4.99155 10.4667 8.01952C13.2296 11.4186 23.8702 24.4009 26.1752 26.9053C28.5431 29.2583 32.8467 23.6519 34.8149 21.5094C34.883 21.695 34.9125 21.8469 34.9402 21.9368C34.028 24.2484 33.3106 25.9624 31.7335 28.4699C30.0293 31.0414 27.64 33.8299 26.5076 34.5695C24.8358 35.6408 22.6007 35.5116 21.0907 34.1343C19.1494 32.4 2.80974 12.9144 1.893 11.3586C0.625727 9.16518 1.1734 7.16185 2.54547 5.9608C3.36506 5.30467 6.67055 3.64487 8.84562 2.80399C11.0411 1.96201 14.5959 0.816615 16.2562 0.717811C16.3133 0.71472 16.6688 0.901645 16.6688 0.901645Z", fill: theme.palette.text.highlight }), _jsx("path", { d: "M23.4534 1.06142C23.4534 1.06142 17.715 3.8232 19.2654 5.8679C21.1311 8.16322 28.3164 16.9298 29.8728 18.6209C31.4718 20.2098 34.3779 16.4239 35.707 14.9772C35.753 15.1025 35.7728 15.2051 35.7916 15.2658C35.1756 16.8268 34.6911 17.9842 33.6262 19.6774C32.4754 21.4139 30.862 23.2968 30.0973 23.7963C28.9683 24.5196 27.4591 24.4324 26.4394 23.5024C25.1285 22.3313 14.0949 9.17323 13.4758 8.12266C12.6201 6.64152 12.9899 5.28873 13.9164 4.4777C14.4699 4.03464 16.7019 2.91383 18.1707 2.34601C19.6533 1.77745 22.0537 1.004 23.1749 0.937281C23.2134 0.935194 23.4534 1.06142 23.4534 1.06142Z", fill: theme.palette.common.grey }), _jsx("path", { d: "M28.2986 1.50902C28.2986 1.50902 24.7111 3.2356 25.6804 4.51388C26.8467 5.94884 31.3387 11.4294 32.3118 12.4866C33.3114 13.48 35.1282 11.1132 35.9591 10.2087C35.9879 10.2871 36.0003 10.3512 36.012 10.3892C35.6269 11.365 35.324 12.0886 34.6583 13.1472C33.9388 14.2327 32.9302 15.4099 32.4521 15.7221C31.7463 16.1744 30.8028 16.1199 30.1653 15.5384C29.3458 14.8063 22.4479 6.58027 22.0609 5.92349C21.5259 4.99753 21.7571 4.15181 22.3363 3.64477C22.6823 3.36779 24.0778 2.66709 24.996 2.31211C25.9228 1.95666 27.4235 1.47312 28.1244 1.43141C28.1485 1.43011 28.2986 1.50902 28.2986 1.50902Z", fill: theme.palette.common.red })] })));
}
