var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import format from 'string-format';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { OGButton } from '@opengear/buttons';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getModifiedTags } from './transformers';
import { FormActions, SubmitButton, useFormContainerContext } from '@/components/layout/forms';
export function AssignTagModalConfirmation(_a) {
    var onBack = _a.onBack, selectedRows = _a.selectedRows;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), getValues = _b.getValues, isSubmitting = _b.formState.isSubmitting;
    var modifiedTags = getModifiedTags(getValues());
    var addedTags = modifiedTags.assignedTags.concat(modifiedTags.createdTags).map(function (tag) { return tag.newResourceTagName; });
    var removedTags = modifiedTags.unassignedTags.map(function (tag) { return tag.resource_tag_name; });
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, __assign({ direction: "row", sx: { gap: 0.7 } }, { children: [_jsx(InfoIcon, { fontSize: "medium", color: "warning", "aria-label": t('global.icon.information.aria_label') }), _jsx(Typography, { children: format(t('components.assign_tag_modal.confirm_modal.changes_info'), String(selectedRows.length), selectedRows.length > 1 ? 's' : '') })] })), !!addedTags.length && (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ py: 2 }, { children: t('components.assign_tag_modal.confirm_modal.tags_added') })), _jsx(Stack, __assign({ direction: "row", flexWrap: "wrap", sx: { gap: 0.7 } }, { children: addedTags.map(function (tagName) { return (_jsx(Chip, { label: tagName, sx: {
                                width: 'max-content',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                lineHeight: 'normal',
                                textOverflow: 'ellipsis',
                                cursor: 'default',
                            } }, tagName)); }) }))] })), !!removedTags.length && (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ py: 2 }, { children: t('components.assign_tag_modal.confirm_modal.tags_removed') })), _jsx(Stack, __assign({ direction: "row", flexWrap: "wrap", sx: { gap: 0.7 } }, { children: removedTags.map(function (tagName) { return (_jsx(Chip, { label: tagName, sx: {
                                width: 'max-content',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                lineHeight: 'normal',
                                textOverflow: 'ellipsis',
                                cursor: 'default',
                            } }, tagName)); }) }))] })), _jsx(Box, __assign({ py: 2 }, { children: t('components.assign_tag_modal.confirm_modal.affected_port_filter') })), _jsx(Divider, { sx: { marginX: -2 } }), _jsxs(FormActions, { children: [_jsx(OGButton, __assign({ disabled: isSubmitting, variant: "borderless", onClick: onBack }, { children: t('global.buttons.back') })), _jsx(SubmitButton, __assign({ autoFocus: true }, { children: t('global.buttons.confirm') }))] })] }));
}
