var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { OGButton } from '@opengear/buttons';
import { useTranslation } from 'react-i18next';
export var OGLinkButton = forwardRef(function (
// @ts-ignore - unpack loading so it doesn't accidentally get passed to child component
_a, ref) {
    var external = _a.external, loading = _a.loading, _b = _a.target, target = _b === void 0 ? '_blank' : _b, to = _a.to, _c = _a.variant, variant = _c === void 0 ? 'borderless' : _c, ariaLabel = _a["aria-label"], children = _a.children, props = __rest(_a, 
    // @ts-ignore - unpack loading so it doesn't accidentally get passed to child component
    ["external", "loading", "target", "to", "variant", 'aria-label', "children"]);
    var t = useTranslation().t;
    if (external) {
        // Append the new tab warning if the target indicates the link will open in a new tab, unless it's a download link
        var updatedLabel = target === '_blank' && props.download === undefined ? "".concat(ariaLabel || children, ", ").concat(t('components.links.new_tab_warning')) : ariaLabel;
        return (
        // @ts-ignore - ignore 'rel' and 'target' attributes
        _jsx(OGButton, __assign({ ref: ref, href: to, rel: "noopener", target: target, variant: variant, "aria-label": updatedLabel }, props, { children: children })));
    }
    return (
    // 'href' needs to be supplied along with 'to' so mui knows to use the 'LinkComponent'
    // @ts-ignore - ignore 'to' attributes
    _jsx(OGButton, __assign({ ref: ref, LinkComponent: RouterLink, href: to, to: to, variant: variant, "aria-label": ariaLabel }, props, { children: children })));
});
