var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useDocTitle } from '@/hooks/doc-title';
import { useCreateUser } from '@/api/users';
import { useGroups } from '@/api/groups';
import { useAuthConfig } from '@/api/auth';
import { usePasswordPolicy } from '@/api/users/password-policy';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { UserForm } from '@/components/accounts/users';
import { useUIPermissions } from '@/api/sessions/self/ui';
export var ADD_USER_LOADING_TEST_ID = 'add-user-loading';
function AddLocalUser() {
    var t = useTranslation().t;
    useDocTitle(t('pages.user_new.title'));
    var createUser = useCreateUser();
    var _a = useGroups(), groupsData = _a.data, isLoadingGroups = _a.isLoading;
    var _b = useAuthConfig(), authData = _b.data, isLoadingAuth = _b.isLoading;
    var _c = usePasswordPolicy(), passwordPolicyData = _c.data, isLoadingPasswordPolicy = _c.isLoading;
    var _d = useUIPermissions(), username = _d.username, isLoadingPermissions = _d.isLoadingPermissions;
    var innerNode = null;
    if (isLoadingGroups || isLoadingAuth || isLoadingPasswordPolicy || isLoadingPermissions) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": ADD_USER_LOADING_TEST_ID });
    }
    if (groupsData && authData && passwordPolicyData && username) {
        innerNode = (_jsx(UserForm, { currentUser: username, groups: groupsData.groups, authMode: authData.auth.mode, passwordPolicy: passwordPolicyData.password_policy, submitHandler: createUser, permissions: { hasEditPermission: true, hasViewPermission: true } }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 2 }, { children: t('pages.user_new.title') })), innerNode] })));
}
export default withAuthenticationRequired(AddLocalUser, { rights: ['settings.user_management.local_users.edit'] });
