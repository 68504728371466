var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function transformTags(tags) {
    var availableTags = new Map();
    tags.forEach(function (tag) {
        availableTags.set(tag.name, new Set(tag.values.map(function (value) { return value.value; })));
    });
    return availableTags;
}
export function mergeTags(tags, userTags, tagFields) {
    var mergedTags = transformTags(tags);
    // Merge in options user added this session
    userTags.forEach(function (userTagValues, userTagName) {
        var currentValues = mergedTags.get(userTagName);
        if (currentValues) {
            mergedTags.set(userTagName, new Set(__spreadArray(__spreadArray([], __read(currentValues), false), __read(userTagValues), false)));
        }
        else {
            mergedTags.set(userTagName, userTagValues);
        }
    });
    // Add options from current selections, just in case they aren't in the tags data
    // This should only happen if the user chose a tag that was then deleted
    tagFields.forEach(function (tag) {
        if (tag.name !== '') {
            // Don't add empty strings that are being used as empty
            var currentValues = mergedTags.get(tag.name);
            if (currentValues === undefined) {
                // Need to add name and value
                var valueToSet = tag.value === '' ? new Set() : new Set([tag.value]);
                mergedTags.set(tag.name, valueToSet);
            }
            else if (tag.value !== '') {
                currentValues.add(tag.value);
            }
        }
    });
    return mergedTags;
}
export function transformFormStateToRequest(formdata) {
    var bundle = {
        auto_approve: formdata.auto_approve,
        automatic_tags: formdata.automatic_tags,
        name: formdata.name,
        templates: formdata.templates.concat(formdata.modules),
        tier: formdata.tier,
        token: formdata.token,
    };
    if (formdata.id) {
        bundle.id = formdata.id;
    }
    return bundle;
}
export function transformResponseToFormState(bundle, templateData) {
    var _a;
    var templates = [];
    var modules = [];
    (_a = bundle.templates) === null || _a === void 0 ? void 0 : _a.forEach(function (templateId) {
        var template = templateData.find(function (t) { return t.id === templateId; });
        if (template && template.type === 'nom') {
            modules.push(templateId);
        }
        else {
            templates.push(templateId);
        }
    });
    return __assign(__assign({}, bundle), { templates: templates, modules: modules });
}
