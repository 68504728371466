var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { OGPseudoTableRow } from '@opengear/table';
import Typography from '@mui/material/Typography';
import { ExpandRowTableCell, NameCell, PermissionsCell } from '../utils';
import { PermissionsRadioGroup } from './permissions-radio-group';
export function PermissionsRow(_a) {
    var label = _a.label, permission = _a.permission, title = _a.title, tooltip = _a.tooltip, warning = _a.warning, onChange = _a.onChange;
    return (_jsxs(OGPseudoTableRow, __assign({ hover: true }, { children: [_jsx(ExpandRowTableCell, {}), _jsxs(NameCell, __assign({ "aria-atomic": true, "aria-live": "polite" }, { children: [_jsxs(Box, { children: [title, tooltip && _jsx(OGIconTooltip, { title: tooltip })] }), warning && (_jsxs(Typography, __assign({ display: "flex", marginTop: 1 }, { children: [_jsx(WarningIcon, { fontSize: "medium", sx: { marginRight: 0.5 } }), warning] })))] })), _jsx(PermissionsCell, { children: _jsx(PermissionsRadioGroup, { label: label !== null && label !== void 0 ? label : title, permission: permission, onChange: onChange }) })] })));
}
