var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { OGConfigureIcon } from '@opengear/icons/navigation';
import { OGLinkButton } from '@opengear/links';
import { OGPanel } from '@opengear/panel';
import Tooltip from '@mui/material/Tooltip';
import { withAuthorizationRequired } from '@/hocs/authorization-required';
import { SECONDARY_LIGHTHOUSES_ROUTE } from '@/constants/routes';
import { useEntitlementsData } from '@/api/system/entitlements';
import { LoadingOverlay } from '@/components/layout/loading-overlay';
import { SECONDARY_PAGE_LOADER_ID } from '@/pages/settings/multi-instance/secondary-lighthouses/secondary-lighthouses';
import { SecondaryLighthousesTable } from './settings/mi/secondary-lighthouses';
/**
 * Multi Instance Dependent Lighthouse Dashboard Panel
 */
export var MultiInstancePanel = withAuthorizationRequired(function () {
    // Get language
    var t = useTranslation().t;
    var _a = useEntitlementsData(), miSupported = _a.miSupported, isLoadingEntitlements = _a.isLoading;
    if (isLoadingEntitlements) {
        return _jsx(LoadingOverlay, { "data-testid": SECONDARY_PAGE_LOADER_ID });
    }
    if (!isLoadingEntitlements && miSupported) {
        return (_jsxs(OGPanel, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 } }, { children: [_jsx(Typography, __assign({ component: "h3", variant: "h3", sx: { textTransform: 'uppercase' } }, { children: t('pages.dashboard.multi_instance_title') })), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.dashboard.multi_instance_configuration_link') }, { children: _jsx(OGLinkButton, { "aria-label": t('pages.dashboard.multi_instance_configuration_link'), startIcon: _jsx(OGConfigureIcon, {}), to: SECONDARY_LIGHTHOUSES_ROUTE }) }))] })), _jsx(Box, __assign({ sx: { maxHeight: 600, overflowY: 'auto' } }, { children: _jsx(SecondaryLighthousesTable, {}) }))] }));
    }
    return null;
}, 
// Verifies user permissions and secondary lighthouse check
{
    rights: ['configure.multiple_instance.dependent_lighthouse.view'],
});
