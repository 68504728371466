var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { OGButton } from '@opengear/buttons';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import { OG_BLACK_80, OG_GREY_VVVL } from '@opengear/theme/colors';
export function SmfResourceBulkOperations(_a) {
    var children = _a.children, onClose = _a.onClose, open = _a.open, selectedCount = _a.selectedCount, totalCount = _a.totalCount;
    var theme = useTheme();
    var t = useTranslation().t;
    return (_jsx(Collapse, __assign({ in: open, unmountOnExit: true, timeout: 200, "aria-label": "bulk-operation-section", sx: { marginBottom: 0, padding: 1, paddingLeft: 2, backgroundColor: theme.palette.mode === 'dark' ? OG_BLACK_80 : OG_GREY_VVVL } }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsxs(Stack, __assign({ gap: 2, direction: "row", justifyContent: "flex-start", flexWrap: "wrap" }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: 400, display: 'flex', alignItems: 'center' } }, { children: format(t('components.bulk_operations.number_of_selected'), selectedCount.toString(), totalCount.toString()) })), children] })), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('global.buttons.close') }, { children: _jsx(OGButton, { "aria-label": t('global.buttons.close'), size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: onClose }) }))] })) })));
}
