var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormLabel from '@mui/material/FormLabel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import { useDeviceView } from '@/hooks/use-device-view';
import { PermissionsRow } from '@/components/accounts/permissions/permissions-row';
import { hardCodePermissions, useTrackDependencies } from '@/components/accounts/roles/role-form-utils';
import { useRolePermissionWarningByKey } from '@/components/accounts/roles/role-form-context';
import { PermissionsSectionRow } from '@/components/accounts/permissions/permissions-section-row';
import { DisabledTextField } from '@/components/inputs/disabled-text-field';
import { invalidFieldError, maxLengthFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { ROLENAME_PATTERN } from '@/constants/regex';
export var RolenameFieldMaxLength = 128;
export var DescriptionFieldMaxLength = 128;
export function RoleNameField(_a) {
    var readOnly = _a.readOnly;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContext().control;
    return (_jsx(Controller, { control: control, name: "rolename", rules: {
            required: requiredFieldError(t('components.role.form.fields.name.label')),
            maxLength: {
                value: RolenameFieldMaxLength,
                message: maxLengthFieldError(t('components.role.form.fields.name.label'), RolenameFieldMaxLength.toString()),
            },
            pattern: {
                value: ROLENAME_PATTERN,
                message: invalidFieldError(t('components.role.form.fields.name.label')),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return readOnly ? (_jsx(DisabledTextField, { id: "rolename", label: t('components.role.form.fields.name.label'), value: field.value })) : (_jsx(TextField, __assign({ id: "rolename", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('components.role.form.fields.name.label'), sx: { maxWidth: isMobileView ? undefined : 400 }, required: true }, field)));
        } }));
}
export function DescriptionField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContext().control;
    return (_jsx(Controller, { control: control, name: "description", rules: {
            maxLength: {
                value: DescriptionFieldMaxLength,
                message: maxLengthFieldError(t('components.role.form.fields.description.label'), DescriptionFieldMaxLength.toString()),
            },
        }, render: function (_a) {
            var _b;
            var field = _a.field, fieldState = _a.fieldState;
            return (_jsx(TextField, __assign({ id: "description", error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('components.role.form.fields.description.label'), sx: { maxWidth: isMobileView ? undefined : 400 } }, field)));
        } }));
}
/**
 * Console shell intentionally does not support entity dependencies
 */
export function ConsoleShellField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContext().control;
    return (_jsx(Controller, { control: control, name: "permissions.cli.console_shell", render: function (_a) {
            var field = _a.field;
            return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [t('components.permissions.form.fields.console_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.console_shell.tooltip') })] }), _jsxs(ToggleButtonGroup, __assign({ "aria-label": t('components.permissions.form.fields.console_shell.label'), exclusive: true, orientation: isMobileView ? 'vertical' : 'horizontal' }, field, { children: [_jsx(ToggleButton, __assign({ value: "read_write" }, { children: t('global.permission.read_write') })), _jsx(ToggleButton, __assign({ value: "read_only" }, { children: t('global.permission.read_only') })), _jsx(ToggleButton, __assign({ value: "deny" }, { children: t('global.permission.deny') }))] }))] }));
        } }));
}
/**
 * Shell access field intentionally does not support entity dependencies
 */
export function ShellAccessField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContext().control;
    return (_jsx(Controller, { control: control, name: "permissions.cli.shell_access", render: function (_a) {
            var field = _a.field;
            return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [t('components.permissions.form.fields.shell_access.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.shell_access.tooltip') })] }), _jsxs(ToggleButtonGroup, __assign({ "aria-label": t('components.permissions.form.fields.shell_access.label'), exclusive: true, orientation: isMobileView ? 'vertical' : 'horizontal' }, field, { children: [_jsx(ToggleButton, __assign({ value: "on" }, { children: t('global.permission.on') })), _jsx(ToggleButton, __assign({ value: "off" }, { children: t('global.permission.off') }))] }))] }));
        } }));
}
/**
 * PM shell field intentionally does not support entity dependencies
 */
export function PMShellField() {
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var control = useFormContext().control;
    return (_jsx(Controller, { control: control, name: "permissions.cli.pm_shell", render: function (_a) {
            var field = _a.field;
            return (_jsxs(FormControl, { children: [_jsxs(FormLabel, { children: [t('components.permissions.form.fields.pm_shell.label'), _jsx(OGIconTooltip, { title: t('components.permissions.form.fields.pm_shell.tooltip') })] }), _jsxs(ToggleButtonGroup, __assign({ "aria-label": t('components.permissions.form.fields.pm_shell.label'), exclusive: true, orientation: isMobileView ? 'vertical' : 'horizontal' }, field, { children: [_jsx(ToggleButton, __assign({ value: "on" }, { children: t('global.permission.on') })), _jsx(ToggleButton, __assign({ value: "off" }, { children: t('global.permission.off') }))] }))] }));
        } }));
}
export function PermissionsSection(_a) {
    var name = _a.name, title = _a.title, children = _a.children;
    var _b = useFormContext(), setValue = _b.setValue, watch = _b.watch;
    var watchPermissionSection = watch(name);
    // Access action handler
    var setAccessActionHandler = useCallback(function (permission, permissions) {
        setValue(name, hardCodePermissions(permission, permissions), { shouldDirty: true });
    }, [name, setValue]);
    return (_jsx(PermissionsSectionRow, __assign({ title: title, unmountOnExit: false, permissions: watchPermissionSection, onClick: setAccessActionHandler }, { children: children })));
}
/**
 * Permissions radio group supports dependency warnings
 */
export function PermissionsRadioGroup(_a) {
    var dependencies = _a.dependencies, name = _a.name, title = _a.title, tooltip = _a.tooltip;
    var control = useFormContext().control;
    var warning = useRolePermissionWarningByKey(name);
    useTrackDependencies(name, dependencies);
    return (_jsx(Controller, { control: control, name: name, render: function (_a) {
            var field = _a.field;
            return _jsx(PermissionsRow, { permission: field.value, title: title, tooltip: tooltip, warning: warning, onChange: field.onChange });
        } }));
}
