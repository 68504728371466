var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { updateLastEvent } from '@/hooks/activity-timeout';
import { useCustomIFrameCSS } from '@/hooks/use-custom-iframe-css';
import { ttydIFrameCSS } from '@/constants/ttyd';
/**
 * Lighthouse local terminal page
 */
function LocalTerminal() {
    var t = useTranslation().t;
    useDocTitle(t('pages.local_terminal.title'));
    var iFrameRef = useRef(null);
    var tabLast = useRef(0); // Last activity timestamp for this tab/window
    // Watches for local iframe state and adds / removes escape listener
    useEffect(function () {
        var _a;
        var iFrame = iFrameRef === null || iFrameRef === void 0 ? void 0 : iFrameRef.current;
        if (!iFrame)
            return undefined;
        function keydownListener(event) {
            // Exit terminal
            if (event.altKey && !event.ctrlKey && !event.shiftKey && (event.key === 'x' || event.code === 'KeyX')) {
                var localTerminalNavItem = document.querySelector('#local-terminal-nav-item');
                if (localTerminalNavItem)
                    localTerminalNavItem.focus();
            }
            // Update last event in local storage
            updateLastEvent(tabLast.current, function (time) {
                tabLast.current = time;
            });
        }
        (_a = iFrame.contentWindow) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', keydownListener);
        return function () {
            var _a;
            (_a = iFrame.contentWindow) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', keydownListener);
        };
    }, []);
    useCustomIFrameCSS(iFrameRef, ttydIFrameCSS);
    return (_jsxs(Box, __assign({ sx: {
            paddingLeft: 2,
            paddingRight: 2,
            paddingBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            height: function (theme) { return "calc(100vh - ".concat(theme.mixins.toolbar.minHeight, "px)"); },
        } }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.local_terminal.title') })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, alignItems: 'center', marginBottom: 2 } }, { children: [_jsx(InfoIcon, { fontSize: "medium" }), _jsx(Typography, { children: t('pages.local_terminal.sub_heading') })] })), _jsx(Box, __assign({ sx: { minHeight: '300px', flexGrow: 1 } }, { children: _jsx("iframe", { ref: iFrameRef, id: "local-terminal", title: t('pages.local_terminal.iframe_title'), width: "100%", height: "100%", src: "/terminal" }) }))] })));
}
export default withAuthenticationRequired(LocalTerminal, { rights: ['manage.lighthouse.local_terminal'] });
