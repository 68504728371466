var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
import { useDeviceView } from '@/hooks/use-device-view';
export function SshPasswordAuthToggleButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ component: "label" }, { children: [t('pages.ssh_auth.form.ssh_password_auth'), _jsx(OGIconTooltip, { title: t('pages.ssh_auth.form.ssh_password_auth_details') })] })), _jsx(Controller, { control: control, name: "sshPasswordAuth", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasSshEditAccess, value: value, onChange: function (event, val) {
                            if (val !== null) {
                                onChange(val);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
                } })] }));
}
export function SshRootLoginToggleButton() {
    var t = useTranslation().t;
    var _a = useFormContainerContext(), control = _a.control, permissions = _a.permissions;
    var isMobileView = useDeviceView().isMobileView;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ component: "label" }, { children: [t('pages.ssh_auth.form.ssh_root_login'), _jsx(OGIconTooltip, { title: t('pages.ssh_auth.form.ssh_root_login_details') })] })), _jsx(Controller, { control: control, name: "sshRootLogin", render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                    return (_jsxs(ToggleButtonGroup, __assign({ exclusive: true, disabled: !permissions.hasSshEditAccess, value: value, onChange: function (event, val) {
                            if (val !== null) {
                                onChange(val);
                            }
                        } }, field, { orientation: isMobileView ? 'vertical' : 'horizontal' }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: true }, { children: t('global.buttons.enabled.enabled') }))] })));
                } })] }));
}
