import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
import { isNodeConnected } from '@/containers/nodes/node-utils';
export function PendingStateCell(_a) {
    var node = _a.row;
    var t = useTranslation().t;
    if ((node.status === 'Pending' || node.status === 'Registering' || node.status === 'Enrolling' || node.runtime_status.action_type === 'unenrollment') &&
        node.runtime_status.action_status !== 'error') {
        return _jsx(OGAutoRenewIcon, { label: t('components.nodes.state_label.in_process') });
    }
    if (node.status === 'Registered' &&
        node.approved === false &&
        node.runtime_status.action_type === 'enrollment' &&
        isNodeConnected(node) &&
        node.runtime_status.action_error_message === 'Waiting for approval') {
        return _jsx(PendingActionsIcon, { fontSize: "medium", titleAccess: t('components.nodes.state_label.waiting') });
    }
    return _jsx(ErrorIcon, { fontSize: "medium", "aria-hidden": "false", titleAccess: t('components.nodes.state_label.error'), color: "error" });
}
