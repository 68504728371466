var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import format from 'string-format';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { RoleForm } from '@/components/accounts/roles/role-form';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useRole, useUpdateRole } from '@/api/roles';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { useEntities } from '@/api/entities';
export var EDIT_ROLE_LOADING_TEST_ID = 'edit-role-loading';
function EditRole() {
    var _this = this;
    var t = useTranslation().t;
    useDocTitle(t('pages.role_edit.title'));
    var navigate = useNavigate();
    var updateRole = useUpdateRole();
    var roleId = useParams().roleId;
    var alertMessages = {
        forbidden: {
            message: format(t('api.role.get.forbidden.message'), roleId !== null && roleId !== void 0 ? roleId : ''),
            options: { persistOnUnmount: true },
        },
        not_found: {
            message: format(t('api.role.get.not_found.message'), roleId !== null && roleId !== void 0 ? roleId : ''),
            options: { persistOnUnmount: true },
        },
    };
    var statusCallback = useCallback(function (responseStatus) {
        if (responseStatus === 403 || responseStatus === 404) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate]);
    var _a = useEntities(), entitiesData = _a.data, isLoadingEntities = _a.isLoading;
    var _b = useRole(roleId, { alertMessages: alertMessages, statusCallback: statusCallback }), role = _b.data, isLoadingRole = _b.isLoading;
    var updateRoleHandler = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var updatedRole;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(roleId !== undefined)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateRole(__assign({ id: roleId }, data))];
                case 1:
                    updatedRole = _a.sent();
                    return [2 /*return*/, updatedRole];
                case 2: return [2 /*return*/, undefined];
            }
        });
    }); }, [roleId, updateRole]);
    var innerNode = null;
    if (isLoadingEntities || isLoadingRole) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": EDIT_ROLE_LOADING_TEST_ID });
    }
    if (entitiesData && role) {
        innerNode = _jsx(RoleForm, { entities: entitiesData.entities, role: role, submitHandler: updateRoleHandler });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 1.5 }, { children: format(t('pages.role_edit.page_title'), roleId !== null && roleId !== void 0 ? roleId : '') })), innerNode] })));
}
// settings.user_management.permission.edit covers groups and roles
export default withAuthenticationRequired(EditRole, { rights: ['settings.user_management.permissions.edit'] });
