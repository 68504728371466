var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { OGPanel } from '@opengear/panel';
import { OGIconTooltip } from '@opengear/icon-tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { OGButton } from '@opengear/buttons';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useDeviceView } from '@/hooks/use-device-view';
import { capacityFromCidr, capacityFromSmfCidr, smfMtuFromVpnMtu } from '@/utils/calculations';
import { lhvpnQueryKeys, useUpdateLighthouseVPN } from '@/api/services/lhvpn';
import { useFormSyncV2 } from '@/hooks/use-form-sync';
import { invalidFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { transformResponseToFormState, transformFormStateToRequest } from './transformers';
import { IPV4_PATTERN, NUM_ONLY_PATTERN } from '@/constants/regex';
export var LIGHTHOUSE_VPN_FORM_LOADER_ID = 'lighthouse-vpn-form-loading';
// Field validators
export var CidrMinVal = 0;
export var CidrMaxVal = 29;
export var MtuMaxVal = 1500;
export var MtuMinVal = 1280;
export var SmfCidrMaxVal = 25;
export var SmfCidrMinVal = 0;
export function LighthouseVPNForm(_a) {
    var vpnSectionEditable = _a.vpnSectionEditable, smfSectionEditable = _a.smfSectionEditable, canViewSmfSection = _a.canViewSmfSection, data = _a.data;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = useUpdateLighthouseVPN(), mutate = _b.mutate, isPending = _b.isPending;
    var sourceValues = useMemo(function () { return transformResponseToFormState(data); }, [data]);
    var _c = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _c.control, handleSubmit = _c.handleSubmit, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _d = _c.formState, defaultValues = _d.defaultValues, dirtyFields = _d.dirtyFields, isDirty = _d.isDirty, isValid = _d.isValid, reset = _c.reset, watch = _c.watch;
    var handleFormReset = useCallback(function (resetData) { return reset(transformResponseToFormState(resetData)); }, [reset]);
    // Compute disabled text field values from watched form data
    var lhvpnCapacity = capacityFromCidr(watch('cidr'));
    var smfCapacity = capacityFromSmfCidr(watch('smf_cidr'));
    var smfMtu = smfMtuFromVpnMtu(watch('mtu'));
    // Resets form back to current values from api or defaults
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    useFormSyncV2(lhvpnQueryKeys.all, handleFormReset, t('pages.lighthouse_vpn.form.reset_warning.header'));
    // Submit handler to update the vpn details
    var onSubmit = useCallback(function (formData) {
        var request = transformFormStateToRequest(formData);
        mutate(request.lhvpn, {
            onSuccess: function (updatedData) {
                if (updatedData) {
                    handleFormReset(updatedData);
                }
            },
        });
    }, [handleFormReset, mutate]);
    return (_jsx(OGPanel, { children: _jsxs(Stack, __assign({ "aria-label": t('pages.lighthouse_vpn.form.label'), component: "form", onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Box, __assign({ sx: {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: 1,
                        marginBottom: 2,
                    } }, { children: [_jsx(InfoIcon, { "aria-hidden": "false", "aria-label": t('global.icon.information.aria_label') }), _jsx(Typography, { children: t('pages.lighthouse_vpn.description') })] })), _jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 500, fontSize: 16 } }, { children: t('pages.lighthouse_vpn.form.vpn_section') })), _jsx(Controller, { control: control, name: "address", rules: {
                        required: requiredFieldError(t('pages.lighthouse_vpn.form.fields.address.label')),
                        pattern: { value: IPV4_PATTERN, message: invalidFieldError(t('pages.lighthouse_vpn.form.fields.address.label')) },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "vpn-address", disabled: !vpnSectionEditable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.lighthouse_vpn.form.fields.address.label'), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                    } }), _jsx(Controller, { control: control, name: "cidr", rules: {
                        required: requiredFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label')),
                        max: { value: CidrMaxVal, message: maxValueFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label'), CidrMaxVal.toString()) },
                        min: { value: CidrMinVal, message: minValueFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label'), CidrMinVal.toString()) },
                        // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
                        pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label')) },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "vpn-cidr-subnet-mask", "aria-controls": "vpn-address-capacity", disabled: !vpnSectionEditable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.lighthouse_vpn.form.fields.cidr.label'), inputProps: { min: 0, max: 29 }, type: "number", sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                    } }), _jsx(TextField, { id: "vpn-address-capacity", "aria-live": "polite", disabled: true, label: t('pages.lighthouse_vpn.form.fields.capacity.label'), value: lhvpnCapacity, sx: { maxWidth: isMobileView ? undefined : 300 } }), _jsx(Controller, { control: control, name: "mtu", rules: {
                        required: requiredFieldError(t('pages.lighthouse_vpn.form.fields.mtu.label')),
                        max: {
                            value: MtuMaxVal,
                            message: maxValueFieldError(t('pages.lighthouse_vpn.form.fields.mtu.label'), MtuMaxVal.toString()),
                        },
                        min: {
                            value: MtuMinVal,
                            message: minValueFieldError(t('pages.lighthouse_vpn.form.fields.mtu.label'), MtuMinVal.toString()),
                        },
                        // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
                        pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.lighthouse_vpn.form.fields.mtu.label')) },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "vpn-tunnel-mtu", disabled: !vpnSectionEditable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: _jsxs(_Fragment, { children: [t('pages.lighthouse_vpn.form.fields.mtu.label'), _jsx(OGIconTooltip, { title: t('pages.lighthouse_vpn.form.fields.mtu.description') })] }), inputProps: { min: 1280, max: 1500 }, type: "number", margin: "none", sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                    } }), canViewSmfSection && (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: { marginTop: 2, marginBottom: 2 } }), _jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 500, fontSize: 16 } }, { children: t('pages.lighthouse_vpn.form.smf_section') })), _jsx(Controller, { control: control, name: "smf_address", rules: {
                                required: requiredFieldError(t('pages.lighthouse_vpn.form.fields.address.label')),
                                pattern: { value: IPV4_PATTERN, message: invalidFieldError(t('pages.lighthouse_vpn.form.fields.address.label')) },
                            }, render: function (_a) {
                                var _b;
                                var field = _a.field, fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ id: "smf-address", disabled: !smfSectionEditable || !vpnSectionEditable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.lighthouse_vpn.form.fields.address.label'), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                            } }), _jsx(Controller, { control: control, name: "smf_cidr", rules: {
                                required: requiredFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label')),
                                max: { value: SmfCidrMaxVal, message: maxValueFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label'), SmfCidrMaxVal.toString()) },
                                min: { value: SmfCidrMinVal, message: minValueFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label'), SmfCidrMinVal.toString()) },
                                pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.lighthouse_vpn.form.fields.cidr.label')) },
                            }, render: function (_a) {
                                var _b;
                                var field = _a.field, fieldState = _a.fieldState;
                                return (_jsx(TextField, __assign({ id: "smf-cidr-subnet-mask", disabled: !smfSectionEditable || !vpnSectionEditable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.lighthouse_vpn.form.fields.cidr.label'), inputProps: { min: 0, max: 25 }, type: "number", sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                            } }), _jsx(TextField, { id: "smf-address-capacity", disabled: true, label: _jsxs(_Fragment, { children: [t('pages.lighthouse_vpn.form.fields.capacity.label'), _jsx(OGIconTooltip, { title: t('pages.lighthouse_vpn.form.fields.smf_capacity.description') })] }), value: smfCapacity, sx: { maxWidth: isMobileView ? undefined : 300 } }), _jsx(TextField, { id: "smf-tunnel-mtu", disabled: true, label: _jsxs(_Fragment, { children: [t('pages.lighthouse_vpn.form.fields.smf_mtu.label'), _jsx(OGIconTooltip, { title: t('pages.lighthouse_vpn.form.fields.smf_mtu.description') })] }), margin: "none", value: smfMtu, sx: { maxWidth: isMobileView ? undefined : 300 } })] })), _jsxs(Box, __assign({ display: "flex", gap: 1, justifyContent: "end", marginTop: 2 }, { children: [_jsx(OGButton, __assign({ id: "node-vpn-reset", disabled: !isDirty || isPending || !vpnSectionEditable, variant: "borderless", onClick: handleReset }, { children: t('global.buttons.reset') })), _jsx(OGButton, __assign({ id: "node-vpn-submit", type: "submit", disabled: !isValid || !isDirty || !vpnSectionEditable, loading: isPending }, { children: t('global.buttons.apply') }))] }))] })) }));
}
