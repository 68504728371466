import { jsx as _jsx } from "react/jsx-runtime";
import { useNetopsConfig } from '@/api/services/netops/netops-config';
import { NetopsOnlineForm } from './online-form';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
export var ONLINE_TAB_LOADER_ID = 'online-tab-loader-id';
export function NetopsOnlineTab(_a) {
    var permissions = _a.permissions;
    var _b = useNetopsConfig(), data = _b.data, isLoading = _b.isLoading;
    if (isLoading) {
        return _jsx(LoadingBackdrop, { "data-testid": ONLINE_TAB_LOADER_ID });
    }
    if (!data) {
        return null;
    }
    return _jsx(NetopsOnlineForm, { permissions: permissions, data: data });
}
