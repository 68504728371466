var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';
export function OGPortUnconfiguredIcon(props) {
    var t = useTranslation().t;
    return (_jsxs(SvgIcon, __assign({ titleAccess: t('global.ports.unconfigured') }, __assign(__assign({ viewBox: '0 0 20 18' }, props), { color: 'disabled' }), { children: [_jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M17.2 1.5H2.8C2.32261 1.5 1.86477 1.68964 1.52721 2.02721C1.18964 2.36477 1 2.82261 1 3.3V10.5C1 10.9774 1.18964 11.4352 1.52721 11.7728C1.86477 12.1104 2.32261 12.3 2.8 12.3H6.4V14.1C6.4 14.5774 6.58964 15.0352 6.92721 15.3728C7.26477 15.7104 7.72261 15.9 8.2 15.9H11.8C12.2774 15.9 12.7352 15.7104 13.0728 15.3728C13.4104 15.0352 13.6 14.5774 13.6 14.1V12.3H17.2C17.6774 12.3 18.1352 12.1104 18.4728 11.7728C18.8104 11.4352 19 10.9774 19 10.5V3.3C19 2.82261 18.8104 2.36477 18.4728 2.02721C18.1352 1.68964 17.6774 1.5 17.2 1.5Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M10 1.5V6", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M5.5 1.5V6", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fill: "none", d: "M14.5 1.5V6", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
