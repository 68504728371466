var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import format from 'string-format';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { OGLink } from '@opengear/links';
import { OGPanel } from '@opengear/panel';
import { OGAutoRenewIcon } from '@opengear/icons/action/auto-renew';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useNetopsModules } from '@/api/netops/modules';
import { useNomModuleStatus } from '@/api/netops/nom-status';
import { useNomModuleState } from '@/api/netops/nom-state';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { ManageNOMForm } from '@/components/manage-nom/manage-nom-form';
import { WarningBanner } from '@/components/warning-banner';
import { SERVICES_NETOPS_ROUTE } from '@/constants/routes';
import { PAGE_TITLE_ID } from '@/constants/ids';
export var MANAGE_NOM_FORM_LOADER_ID = 'manage-nom-form-loading';
export function NOMWarningBanner(_a) {
    var nomStatus = _a.status;
    var t = useTranslation().t;
    var _b = useMemo(function () { return ({ status: nomStatus.status, errorMessage: nomStatus.error_message }); }, [nomStatus.error_message, nomStatus.status]), status = _b.status, errorMessage = _b.errorMessage;
    var _c = __read(useState(false), 2), isDeploying = _c[0], setIsDeploying = _c[1];
    if (['running', 'pending', 'error'].includes(status) || (status === 'finished' && isDeploying === true)) {
        var icon = void 0;
        var warningHeader = '';
        var warningMessage = '';
        var warningColor = '';
        switch (status) {
            case 'running':
            case 'pending':
                icon = _jsx(OGAutoRenewIcon, { sx: { fontSize: 40 } });
                warningHeader = t('pages.nom.form.activation_message.running.header');
                warningMessage = t('pages.nom.form.activation_message.running.message');
                warningColor = 'warning';
                if (isDeploying === false) {
                    setIsDeploying(true);
                }
                break;
            case 'error':
                icon = _jsx(ReportProblem, { style: { fontSize: '40px' }, color: "error" });
                warningHeader = t('pages.nom.form.activation_message.error.header');
                warningMessage = format(t('pages.nom.form.activation_message.error.message'), errorMessage !== null && errorMessage !== void 0 ? errorMessage : '');
                warningColor = 'error';
                break;
            case 'finished':
                icon = _jsx(CheckCircleOutlineIcon, { style: { fontSize: '40px' }, color: "success" });
                warningHeader = t('pages.nom.form.activation_message.finished.header');
                warningMessage = t('pages.nom.form.activation_message.finished.message');
                warningColor = 'success';
                setTimeout(function () { return setIsDeploying(false); }, 5000);
                break;
            default:
        }
        return (_jsx(WarningBanner, { warningIcon: icon, warningHeader: warningHeader, warningMessage: _jsx(Typography, { children: warningMessage }), warningColor: warningColor, bannerCode: "activation-status", "aria-live": "polite", dismissPeriod: -1, sxProps: { margin: 0, marginBottom: 2, padding: 1 } }));
    }
    return null;
}
function ManageNOM() {
    var t = useTranslation().t;
    useDocTitle(t('pages.nom.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('configure.netops.modules.edit');
    var hasNomSettingsView = hasPermission('settings.services.netops.view');
    var _a = useNetopsModules(), modules = _a.data, isLoadingNOM = _a.isLoading;
    var status = useNomModuleStatus({ enabled: !!modules && !!modules['netops-modules'].length }).data;
    var _b = useNomModuleState({ enabled: !!modules && !!modules['netops-modules'].length }), state = _b.data, isLoadingNOMState = _b.isLoading;
    var innerNode = null;
    if (isLoadingNOM || isLoadingNOMState) {
        innerNode = (_jsx(Stack, __assign({ alignItems: "center" }, { children: _jsx(CircularProgress, { "data-testid": MANAGE_NOM_FORM_LOADER_ID }) })));
    }
    if ((modules === null || modules === void 0 ? void 0 : modules['netops-modules'].length) && state) {
        innerNode = _jsx(ManageNOMForm, { modules: modules, permissions: { hasEditPermission: hasEditPermission }, status: status === null || status === void 0 ? void 0 : status.status, state: state });
    }
    if (!isLoadingNOM && modules && !modules['netops-modules'].length) {
        innerNode = (_jsx(OGPanel, { children: _jsxs(Box, __assign({ sx: { height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: [_jsx(InfoIcon, { sx: { marginRight: 0.5 } }), _jsx(Typography, __assign({ id: PAGE_TITLE_ID, marginRight: 1 }, { children: t('pages.nom.no_modules') })), hasNomSettingsView && (_jsx(OGLink, __assign({ variant: "standard", to: SERVICES_NETOPS_ROUTE }, { children: t('pages.nom.no_modules_link') })))] })) }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.nom.title') })), status && _jsx(NOMWarningBanner, { status: status }), innerNode] })));
}
export default withAuthenticationRequired(ManageNOM, { rights: ['configure.netops.modules.view'] });
