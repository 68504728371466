var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import includes from 'lodash/includes';
import map from 'lodash/map';
import values from 'lodash/values';
import { isValidNetworkRange } from '@/utils/calculations';
import { IQueryDataType, IQueryNodeField, IQueryPortField, IQuerySmfResourceField } from '../../../../types/query';
import { NodeCellhealthStatus, NodeConnectionStatus, NodeStatus } from '@/utils/query';
import { invalidFieldError, requiredFieldError } from '@/utils/field-validation-errors';
var nodeCellhealthStatuses = values(NodeCellhealthStatus);
var nodeConnectionStatuses = values(NodeConnectionStatus);
var nodeStatuses = values(NodeStatus);
export function QueryValue(_a) {
    var fieldname = _a.fieldname, datatype = _a.datatype, tags = _a.tags, tagValues = _a.tagValues, value = _a.value, onChange = _a.onChange, setGlobalError = _a.setError;
    var t = useTranslation().t;
    var _b = __read(useState(null), 2), errorMessage = _b[0], setErrorMessage = _b[1];
    var previousDatatypeRef = useRef(datatype);
    var previousFieldnameRef = useRef(fieldname);
    var previousValueRef = useRef(value);
    var tag = useMemo(function () { return tags === null || tags === void 0 ? void 0 : tags.find(function (_a) {
        var name = _a.name;
        return fieldname === "tag:".concat(name);
    }); }, [fieldname, tags]);
    var isResourceTagField = fieldname === IQueryPortField.tag;
    var isNetworkRangeField = fieldname === IQuerySmfResourceField.networkRange;
    var selectedTag = useMemo(function () {
        var _a;
        if (!isResourceTagField || value === undefined)
            return null;
        return (_a = tagValues === null || tagValues === void 0 ? void 0 : tagValues.find(function (tagValue) { return tagValue.resource_tag_name === value; })) !== null && _a !== void 0 ? _a : null;
    }, [isResourceTagField, tagValues, value]);
    var handleBlur = useCallback(function () {
        if (value === undefined || value === '') {
            setErrorMessage(requiredFieldError(t('components.query.value.label')));
            setGlobalError === null || setGlobalError === void 0 ? void 0 : setGlobalError(true);
        }
        else if (isNetworkRangeField && !isValidNetworkRange(value.toString())) {
            setErrorMessage(invalidFieldError(t('components.query.value.network_range.label')));
            setGlobalError === null || setGlobalError === void 0 ? void 0 : setGlobalError(true);
        }
    }, [isNetworkRangeField, setGlobalError, t, value]);
    var handleChange = useCallback(function (event) {
        setErrorMessage(null);
        setGlobalError === null || setGlobalError === void 0 ? void 0 : setGlobalError(false);
        onChange({ value: event.target.value });
    }, [onChange, setGlobalError]);
    var handleBooleanChange = useCallback(function (event) {
        setErrorMessage(null);
        setGlobalError === null || setGlobalError === void 0 ? void 0 : setGlobalError(false);
        onChange({ value: JSON.parse(event.target.value) });
    }, [onChange, setGlobalError]);
    var renderValue = useCallback(function (v) {
        if (typeof v !== 'string' || v === '') {
            return _jsx("em", { children: t('components.query.value.label') });
        }
        if (v === 'true') {
            return t('global.buttons.boolean.true');
        }
        if (v === 'false') {
            return t('global.buttons.boolean.false');
        }
        if (fieldname === IQueryNodeField.cellHealthStatus && includes(NodeCellhealthStatus, v)) {
            return t("global.query.node_cellhealth_status.".concat(v));
        }
        if (fieldname === IQueryNodeField.connectionStatus && includes(NodeConnectionStatus, v)) {
            return t("global.query.node_connection_status.".concat(v));
        }
        if (fieldname === IQueryNodeField.status && includes(NodeStatus, v)) {
            return t("global.query.node_status.".concat(v));
        }
        return v;
    }, [fieldname, t]);
    var selectProps = useMemo(function () { return ({
        MenuProps: {
            MenuListProps: { 'aria-label': t('components.query.value.options_label') },
        },
        SelectDisplayProps: {
            'aria-invalid': !!errorMessage,
        },
        displayEmpty: true,
        renderValue: renderValue,
    }); }, [errorMessage, renderValue, t]);
    var textFieldProps = useMemo(function () { return ({
        inputProps: {
            'aria-label': t('components.query.value.label'),
        },
        error: !!errorMessage,
        helperText: errorMessage,
        margin: 'none',
        placeholder: isNetworkRangeField ? t('components.query.value.network_range.placeholder') : t('components.query.value.label'),
        onBlur: handleBlur,
        sx: { flexGrow: 1 },
    }); }, [errorMessage, handleBlur, isNetworkRangeField, t]);
    var renderInput = useCallback(function (params) { return (
    // at this point value is a tag id from a resource tag
    _jsx(TextField, __assign({}, textFieldProps, params, { margin: "none", value: value, placeholder: t('components.query.value.label'), size: "medium", variant: "standard" }))); }, [textFieldProps, value, t]);
    var getOptionLabel = function (option) { return option.resource_tag_name; };
    var handleTagChange = useCallback(function (_event, tagName) {
        if (tagName === null || tagName === void 0 ? void 0 : tagName.resource_tag_name) {
            setErrorMessage(null);
            onChange({ value: tagName.resource_tag_name });
        }
        else {
            onChange({ value: undefined });
        }
    }, [onChange]);
    useEffect(function () {
        if (value !== undefined) {
            // clear the value, if datatype has changed
            if (datatype !== previousDatatypeRef.current) {
                onChange({ value: undefined });
            }
            // clear the value, if datatype is a reference and the fieldname has changed
            if (datatype === IQueryDataType.reference && fieldname !== previousFieldnameRef.current) {
                onChange({ value: undefined });
            }
        }
        previousDatatypeRef.current = datatype;
        previousFieldnameRef.current = fieldname;
        previousValueRef.current = value;
    }, [datatype, fieldname, onChange, tagValues, value]);
    if (datatype === IQueryDataType.bool) {
        return (_jsxs(TextField, __assign({}, textFieldProps, { SelectProps: selectProps, select: true, value: typeof value === 'boolean' ? JSON.stringify(value) : '', onChange: handleBooleanChange }, { children: [_jsx(MenuItem, __assign({ value: "true" }, { children: t('global.buttons.boolean.true') })), _jsx(MenuItem, __assign({ value: "false" }, { children: t('global.buttons.boolean.false') }))] })));
    }
    if (fieldname === IQueryNodeField.cellHealthStatus) {
        return (_jsx(TextField, __assign({}, textFieldProps, { SelectProps: selectProps, select: true, value: includes(nodeCellhealthStatuses, value) ? value : '', onChange: handleChange }, { children: map(NodeCellhealthStatus, function (status) { return (_jsx(MenuItem, __assign({ value: status }, { children: t("global.query.node_cellhealth_status.".concat(status)) }), status)); }) })));
    }
    if (fieldname === IQueryNodeField.connectionStatus) {
        return (_jsx(TextField, __assign({}, textFieldProps, { SelectProps: selectProps, select: true, value: includes(nodeConnectionStatuses, value) ? value : '', onChange: handleChange }, { children: map(NodeConnectionStatus, function (status) { return (_jsx(MenuItem, __assign({ value: status }, { children: t("global.query.node_connection_status.".concat(status)) }), status)); }) })));
    }
    if (fieldname === IQueryNodeField.status) {
        return (_jsx(TextField, __assign({}, textFieldProps, { SelectProps: selectProps, select: true, value: includes(nodeStatuses, value) ? value : '', onChange: handleChange }, { children: map(nodeStatuses, function (status) { return (_jsx(MenuItem, __assign({ value: status }, { children: status }), status)); }) })));
    }
    // NOTE: Autocomplete is for config:tag which is for both port and resource filters
    if (fieldname === IQueryPortField.tag && tagValues) {
        return (_jsx(Autocomplete, { value: selectedTag, onChange: handleTagChange, options: tagValues, getOptionLabel: getOptionLabel, renderInput: renderInput, sx: { flexGrow: 1 } }));
    }
    if (tag) {
        return (_jsx(TextField, __assign({}, textFieldProps, { SelectProps: selectProps, select: true, value: tag.values.some(function (tagValue) { return tagValue.value === value; }) ? value : '', onChange: handleChange }, { children: tag.values.map(function (tagValue) { return (_jsx(MenuItem, __assign({ value: tagValue.value }, { children: tagValue.value }), tagValue.value)); }) })));
    }
    return _jsx(TextField, __assign({}, textFieldProps, { value: value === undefined ? '' : value, onChange: handleChange }));
}
