var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { OGLink, OGLinkButton } from '@opengear/links';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DialogContentText from '@mui/material/DialogContentText';
import format from 'string-format';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { formatCopyGroupRoute, formatEditGroupRoute, GROUPS_ROLES_ROUTE, GROUPS_ROUTE, NOT_FOUND_ROUTE } from '@/constants/routes';
import { useDeleteGroup, useGroup } from '@/api/groups';
import { useRoles } from '@/api/roles';
import { useUsers } from '@/api/users';
import { useRolesPermissions } from '@/api/permissions/roles';
import { GroupReadonlyForm } from '@/components/accounts/groups/group-readonly-form';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { getGroupRoleNames } from '@/components/accounts/utils';
import { QUERY_LIMIT } from '@/constants/limits';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
export var GROUP_LOADING_TEST_ID = 'group-loading';
// These are special groups that should not be deleted
var deleteSafeGroups = ['admin', 'netgrp'];
var editSafeGroups = ['admin'];
function DeleteGroup(_a) {
    var editable = _a.editable, groupId = _a.groupId;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var deleteGroup = useDeleteGroup();
    var onSuccess = useCallback(function () {
        navigate(GROUPS_ROLES_ROUTE + GROUPS_ROUTE);
    }, [navigate]);
    var onDelete = useCallback(function () { return deleteGroup(groupId); }, [deleteGroup, groupId]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.accounts_group.actions.delete.modal.title'), deleteButtonLabel: t('pages.accounts_group.actions.delete.button'), onDelete: onDelete, onSuccess: onSuccess, disabled: !editable }, { children: _jsx(DialogContentText, __assign({ sx: { whiteSpace: 'pre-wrap' } }, { children: format(t('pages.accounts_group.actions.delete.modal.message'), groupId) })) })));
}
function Group() {
    var groupId = useParams().groupId;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var navigate = useNavigate();
    useDocTitle(t('pages.accounts_group.title'));
    var alertMessages = {
        forbidden: {
            message: format(t('api.group.get.forbidden.message'), groupId !== null && groupId !== void 0 ? groupId : ''),
            options: { persistOnUnmount: true },
        },
        not_found: {
            message: format(t('api.group.get.not_found.message'), groupId !== null && groupId !== void 0 ? groupId : ''),
            options: { persistOnUnmount: true },
        },
    };
    var statusCallback = useCallback(function (responseStatus) {
        if (responseStatus === 403 || responseStatus === 404) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate]);
    var _a = useGroup(groupId, { alertMessages: alertMessages, statusCallback: statusCallback }), group = _a.data, isLoadingGroup = _a.isLoading;
    var _b = useRoles({ per_page: QUERY_LIMIT }), rolesResponse = _b.data, isLoadingRoles = _b.isLoading;
    var _c = useUsers({ per_page: QUERY_LIMIT }), usersResponse = _c.data, isLoadingUsers = _c.isLoading;
    var groupRoleNames = useMemo(function () { return group && getGroupRoleNames(group); }, [group]);
    var _d = useRolesPermissions(groupRoleNames !== null && groupRoleNames !== void 0 ? groupRoleNames : []), rolesPermissions = _d.data, isLoadingPermissions = _d.isLoading;
    var innerNode = null;
    if (isLoadingGroup || isLoadingRoles || isLoadingUsers || isLoadingPermissions) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": GROUP_LOADING_TEST_ID });
    }
    var hasDeleteAccess = useMemo(function () { return hasPermission('settings.user_management.permissions.edit') && !!group && !deleteSafeGroups.includes(group.groupname); }, [group, hasPermission]);
    var hasCopyAccess = useMemo(function () { return hasPermission('settings.user_management.permissions.edit'); }, [hasPermission]);
    var hasEditAccess = useMemo(function () { return hasPermission('settings.user_management.permissions.edit') && !!group && !editSafeGroups.includes(group.groupname); }, [group, hasPermission]);
    if (group && rolesResponse && usersResponse && rolesPermissions) {
        var roles = rolesResponse.roles;
        var users = usersResponse.users;
        innerNode = (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1, marginBottom: 2 }, { children: [_jsx(OGLink, __assign({ startIcon: _jsx(ChevronLeftIcon, { fontSize: "medium" }), to: "".concat(GROUPS_ROLES_ROUTE).concat(GROUPS_ROUTE) }, { children: t('pages.accounts_group.link') })), _jsx(Box, { flexGrow: 1 }), _jsx(DeleteGroup, { editable: hasDeleteAccess, groupId: group.id }), _jsx(OGLinkButton, __assign({ disabled: !hasCopyAccess, startIcon: _jsx(ContentCopyIcon, {}), variant: "borderless", to: formatCopyGroupRoute(group.id) }, { children: t('pages.accounts_group.actions.copy') })), _jsx(OGLinkButton, __assign({ disabled: !hasEditAccess, startIcon: _jsx(EditIcon, {}), variant: "borderless", to: formatEditGroupRoute(group.id) }, { children: t('pages.accounts_group.actions.edit') }))] })), _jsx(Paper, { children: _jsx(GroupReadonlyForm, { group: group, roles: roles, rolesPermissions: rolesPermissions, users: users }) })] }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.accounts_group.title') })), innerNode] })));
}
export default withAuthenticationRequired(Group, { rights: ['settings.user_management.permissions.view'] });
