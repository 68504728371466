var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OGButtonLink } from '@opengear/buttons';
import { OGTable } from '@opengear/table';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
export function UserTable(_a) {
    var onUserClick = _a.onUserClick;
    var t = useTranslation().t;
    var watch = useFormContainerContext().watch;
    var rows = watch('users');
    var renderUsernameCell = useCallback(function (_a) {
        var row = _a.row, tabIndex = _a.tabIndex, value = _a.value;
        return (_jsx(OGButtonLink, __assign({ tabIndex: tabIndex, variant: "standard", onClick: function () {
                onUserClick(row);
            }, propagateSpacebar: false }, { children: value })));
    }, [onUserClick]);
    var columns = [
        {
            field: 'username',
            headerName: t('pages.config_templates.tabs.users_groups.sections.user_list.table.headers.username'),
            flex: 1,
            renderCell: renderUsernameCell,
        },
        { field: 'description', headerName: t('pages.config_templates.tabs.users_groups.sections.user_list.table.headers.description'), flex: 2 },
        { field: 'groups', headerName: t('pages.config_templates.tabs.users_groups.sections.user_list.table.headers.groups'), flex: 1 },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ id: "config-templates-user-groups-user-table-description", component: "span", sx: { marginBottom: 2 } }, { children: t('pages.config_templates.tabs.users_groups.sections.user_list.sub_heading') })), _jsx(OGTable, { id: "config-templates-user-groups-user-table", "aria-describedby": "config-templates-user-groups-user-table-description", "aria-label": t('pages.config_templates.tabs.users_groups.sections.user_list.table.label'), autoHeight: true, columns: columns, rows: rows, noRowsMessage: t('pages.config_templates.tabs.users_groups.sections.user_list.table.no_row_message') })] }));
}
