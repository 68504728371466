var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ToggleButton from '@mui/material/ToggleButton';
export function NodeIpDetails(_a) {
    var interfaces = _a.interfaces, tabIndex = _a.tabIndex;
    var t = useTranslation().t;
    var _b = __read(useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = useMemo(function () { return !!anchorEl; }, [anchorEl]);
    var handleClick = useCallback(function (_a) {
        var currentTarget = _a.currentTarget;
        setAnchorEl(function (state) { return (state ? null : currentTarget); });
    }, [setAnchorEl]);
    var handleClose = useCallback(function () {
        setAnchorEl(null);
    }, [setAnchorEl]);
    return (_jsxs(_Fragment, { children: [_jsx(ToggleButton, __assign({ sx: { height: 20, marginLeft: 1, paddingX: 0.5, paddingY: 'auto', textTransform: 'initial', lineHeight: 1 }, "aria-expanded": open, "aria-haspopup": true, tabIndex: tabIndex, "aria-label": t('pages.dashboard.nodes.table.buttons.see_more'), value: "checked", onChange: handleClick, onKeyDown: function (event) {
                    if (event.code === 'Space') {
                        event.stopPropagation();
                    }
                } }, { children: _jsx(Typography, __assign({ noWrap: true }, { children: t('pages.dashboard.nodes.table.buttons.see_more') })) })), _jsx(Popover, __assign({ open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'center',
                    horizontal: 'left',
                }, onClose: handleClose }, { children: _jsxs(MuiList, __assign({ sx: { padding: 1.2 }, "aria-label": t('pages.dashboard.nodes.table.label.ip_details') }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: { marginBottom: 1 } }, { children: t('pages.dashboard.nodes.table.interfaces_title') })), interfaces.map(function (item) { return (_jsxs(MuiListItem, __assign({ sx: { marginBottom: 0.8, padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsx(Typography, { children: "Name: ".concat(item.name) }), _jsx(Typography, __assign({ component: "span" }, { children: item.ipv4_addr ? "IPv4: ".concat(item.ipv4_addr) : null })), _jsx(Typography, __assign({ component: "span" }, { children: item.ipv6_addr ? "IPv6: ".concat(item.ipv6_addr) : null }))] }), item.id)); })] })) }))] }));
}
