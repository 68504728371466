import { rest } from 'msw';
import { formatApiPath } from '@/api/utils';
import { AUTH_PATH } from './auth-config';
var authConfigUrl = formatApiPath(AUTH_PATH);
export var DEFAULT_MOCK_AUTH_RESPONSE = {
    auth: {
        mode: 'local',
        authStyle: null,
        tacacsMethod: null,
        tacacsService: null,
        ldapBaseDN: null,
        ldapBindDN: '',
        ldapIgnoreReferrals: false,
        ldapUsernameAttribute: null,
        ldapGroupMembershipAttribute: null,
        radiusAuthenticationServers: [],
        radiusAccountingServers: [],
        tacacsAuthenticationServers: [],
        ldapAuthenticationServers: [],
        ldapSslIgnoreCertErrors: false,
        ldapSslMode: null,
        ldapSslCaCert: null,
    },
};
export var mockGetAuthConfigHandler = rest.get(authConfigUrl, function (req, res, ctx) { return res(ctx.status(200), ctx.json(DEFAULT_MOCK_AUTH_RESPONSE)); });
export var mockUpdateAuthConfigHandler = rest.put(authConfigUrl, function (req, res, ctx) { return res(ctx.status(200), ctx.json(DEFAULT_MOCK_AUTH_RESPONSE)); });
export function setupMockGetAuthConfig(server, auth) {
    server.use(rest.get(authConfigUrl, function (req, res, ctx) { return res(ctx.status(200), ctx.json(auth)); }));
}
