var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
export var NewFirmwareUpgradeStep;
(function (NewFirmwareUpgradeStep) {
    NewFirmwareUpgradeStep[NewFirmwareUpgradeStep["Setup"] = 0] = "Setup";
    NewFirmwareUpgradeStep[NewFirmwareUpgradeStep["Schedule"] = 1] = "Schedule";
    NewFirmwareUpgradeStep[NewFirmwareUpgradeStep["Review"] = 2] = "Review";
    NewFirmwareUpgradeStep[NewFirmwareUpgradeStep["Confirm"] = 3] = "Confirm";
})(NewFirmwareUpgradeStep || (NewFirmwareUpgradeStep = {}));
export var NewFirmwareUpgradeStepsContext = createContext(undefined);
// Consumer
export var useNewFirmwareUpgradeSteps = function () {
    var context = useContext(NewFirmwareUpgradeStepsContext);
    if (!context) {
        throw Error('useNewFirmwareUpgradeSteps must be wrapped in NewFirmwareUpgradeStepsProvider');
    }
    return context;
};
// Provider
export function NewFirmwareUpgradeStepsProvider(_a) {
    var children = _a.children, _b = _a.initStep, initStep = _b === void 0 ? NewFirmwareUpgradeStep.Setup : _b;
    var _c = __read(useState(initStep), 2), activeStep = _c[0], setActiveStep = _c[1];
    var handleStepBack = useCallback(function () {
        setActiveStep(function (prevStep) { return (prevStep === 0 ? prevStep : prevStep - 1); });
    }, []);
    var handleStepNext = useCallback(function () {
        setActiveStep(function (prevStep) { return (prevStep === NewFirmwareUpgradeStep.Confirm ? prevStep : prevStep + 1); });
    }, []);
    var value = useMemo(function () { return ({
        activeStep: activeStep,
        handleStepBack: handleStepBack,
        handleStepNext: handleStepNext,
    }); }, [activeStep, handleStepBack, handleStepNext]);
    return _jsx(NewFirmwareUpgradeStepsContext.Provider, __assign({ value: value }, { children: children }));
}
