var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import format from 'string-format';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDocTitle } from '@/hooks/doc-title';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { GroupForm } from '@/components/accounts/groups/group-form';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useGroup, useUpdateGroup } from '@/api/groups';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
var EDIT_GROUP_LOADING_TEST_ID = 'edit-group-loading';
function EditGroup() {
    var t = useTranslation().t;
    useDocTitle(t('pages.group_edit.title'));
    var updateGroup = useUpdateGroup();
    var navigate = useNavigate();
    var groupId = useParams().groupId;
    var alertMessages = {
        forbidden: {
            message: format(t('api.group.get.forbidden.message'), groupId !== null && groupId !== void 0 ? groupId : ''),
            options: { persistOnUnmount: true },
        },
        not_found: {
            message: format(t('api.group.get.not_found.message'), groupId !== null && groupId !== void 0 ? groupId : ''),
            options: { persistOnUnmount: true },
        },
    };
    var statusCallback = useCallback(function (responseStatus) {
        if (responseStatus === 403 || responseStatus === 404) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate]);
    var _a = useGroup(groupId, { alertMessages: alertMessages, statusCallback: statusCallback }), group = _a.data, isLoadingGroup = _a.isLoading;
    // Permissions required for both Port and Node smartgroups are the same, EntityEnum.smart_groups
    // and EntityEnum.nodes_devices_base. 'monitor.nodes.view' and 'configure.edit_smart_groups.view'
    // are the easiest way to derive those rights.
    var hasSomePermission = useUIPermissions().hasSomePermission;
    var readableFilters = hasSomePermission(['monitor.nodes.view', 'configure.edit_smart_groups.view']);
    var readableRoles = hasSomePermission(['settings.user_management.permissions.view']);
    var updateGroupHandler = useCallback(function (data) {
        if (groupId !== undefined) {
            return updateGroup(__assign({ id: groupId }, data));
        }
        return null;
    }, [groupId, updateGroup]);
    var innerNode = null;
    if (isLoadingGroup) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": EDIT_GROUP_LOADING_TEST_ID });
    }
    if (group) {
        innerNode = _jsx(GroupForm, { group: group, readableFilters: readableFilters, readableRoles: readableRoles, submitHandler: updateGroupHandler });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", marginBottom: 2 }, { children: format(t('pages.group_edit.page_title'), groupId !== null && groupId !== void 0 ? groupId : '') })), innerNode] })));
}
// settings.user_management.permission.edit covers groups and roles
export default withAuthenticationRequired(EditGroup, { rights: ['settings.user_management.permissions.edit'] });
