var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OGPickersDay } from '@opengear/date';
import { OG_BLACK_90, OG_BLACK_80, OG_GREY_H, OG_GREY_M, OG_GREY_ML, OG_GREY_VL, OG_OFF_WHITE, OG_WHITE, OG_GREY, OG_RED, OG_BUTTON_PRIMARY, OG_BUTTON_PRIMARY_HOVER, OG_BUTTON_PRIMARY_ACTIVE, OG_FOCUS, OG_FOCUS_BOX_SHADOW, OG_FOCUS_BOX_INNER_SHADOW, OG_FOCUS_DROP_SHADOW, OG_SUCCESS_DARK, OG_WARNING_DARK, OG_DANGER_DARK, } from './colors';
import { StepIndicator } from '../stepper/step-indicator';
import BarlowLight300 from './fonts/Barlow-Light-300.woff2';
import BarlowLight300Italic from './fonts/Barlow-Light-300-Italic.woff2';
import BarlowRegular400 from './fonts/Barlow-Regular-400.woff2';
import BarlowRegular400Italic from './fonts/Barlow-Regular-400-Italic.woff2';
import BarlowMedium500 from './fonts/Barlow-Medium-500.woff2';
import BarlowMedium500Italic from './fonts/Barlow-Medium-500-Italic.woff2';
import BarlowSemiBold600 from './fonts/Barlow-SemiBold-600.woff2';
import BarlowSemiBold600Italic from './fonts/Barlow-SemiBold-600-Italic.woff2';
import BarlowBold700 from './fonts/Barlow-Bold-700.woff2';
import BarlowBold700Italic from './fonts/Barlow-Bold-700-Italic.woff2';
export var OPACITY = 0.4;
export var commonThemeOptions = {
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'html': [
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowLight300, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'italic',
                            fontWeight: 300,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowLight300Italic, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowRegular400, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowRegular400Italic, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowMedium500, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'italic',
                            fontWeight: 500,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowMedium500Italic, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowSemiBold600, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'italic',
                            fontWeight: 600,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowSemiBold600Italic, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowBold700, ") format('woff2')"),
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Barlow',
                            fontStyle: 'italic',
                            fontWeight: 700,
                            fontDisplay: 'swap',
                            src: "url(".concat(BarlowBold700Italic, ") format('woff2')"),
                        },
                    },
                    {
                        fontSize: '50%',
                    },
                ],
                '*': {
                    boxSizing: 'border-box',
                },
                'body': {
                    minHeight: '100vh',
                },
                'iframe': {
                    border: 'none',
                },
            },
        },
        MuiAppBar: {
            defaultProps: {
                color: 'inherit',
                elevation: 1,
                position: 'fixed',
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.71)',
                },
                invisible: {
                    background: 'none',
                },
            },
        },
        // TODO - uncomment
        // MuiButtonBase: {
        //   defaultProps: {
        //     disableRipple: true,
        //   },
        // },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                variant: 'contained',
                disableRipple: true, // TODO - remove
            },
            styleOverrides: {
                root: {
                    'paddingTop': '4px',
                    'paddingBottom': '4px',
                    'paddingLeft': '16px',
                    'paddingRight': '16px',
                    'textTransform': 'inherit',
                    'lineHeight': 'normal',
                    'minHeight': '36px',
                    'minWidth': '36px',
                    'borderRadius': '2px',
                    ':focus-visible': {
                        boxShadow: OG_FOCUS_BOX_SHADOW,
                    },
                    ':active': {
                        boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.15)',
                    },
                    '&.Mui-disabled': {
                        opacity: OPACITY,
                    },
                    '&.MuiButton-sizeSmall': {
                        minHeight: '31px',
                        minWidth: '36px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    },
                    '&.MuiButton-sizeLarge': {
                        minHeight: '41px',
                        minWidth: '36px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    },
                },
                contained: {
                    'color': OG_WHITE,
                    'backgroundColor': OG_BUTTON_PRIMARY,
                    ':hover': {
                        backgroundColor: OG_BUTTON_PRIMARY_HOVER,
                    },
                    ':active': {
                        backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                        color: OG_WHITE,
                    },
                    '&.Mui-disabled': {
                        color: OG_WHITE,
                        backgroundColor: OG_BUTTON_PRIMARY,
                    },
                },
                startIcon: {
                    marginRight: '6px',
                },
                endIcon: {
                    marginLeft: '6px',
                },
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: 'default',
                disableRipple: true,
                tabIndex: 0,
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'borderRadius': theme.shape.borderRadius,
                        'padding': 0,
                        '& .MuiSvgIcon-root': {
                            fontSize: 18,
                        },
                        '&.MuiCheckbox-colorDefault': {
                            color: theme.palette.text.primary,
                        },
                        '&.MuiCheckbox-colorError': {
                            color: theme.palette.error.main,
                        },
                        '&:focus-visible, &.Mui-focusVisible': {
                            filter: OG_FOCUS_DROP_SHADOW,
                        },
                        '&.Mui-disabled': {
                            opacity: OPACITY,
                        },
                    });
                },
            },
        },
        MuiDataGrid: {
            defaultProps: {
                density: 'compact',
                disableColumnMenu: true,
                disableMultipleColumnsSorting: true,
                disableRowSelectionOnClick: true,
                getRowHeight: function () { return 'auto'; },
            },
            styleOverrides: {
                'root': {
                    'border': 'none',
                    'borderRadius': 'none',
                    'fontSize': '1.75rem',
                    'fontWeight': 300,
                    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                        lineHeight: 1.5,
                        display: 'block',
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle': {
                        display: 'inline-block',
                        verticalAlign: 'text-bottom',
                    },
                },
                'cell': {
                    'paddingTop': '10px',
                    'paddingBottom': '10px',
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:focus-visible': {
                        boxShadow: OG_FOCUS_BOX_INNER_SHADOW,
                    },
                    '&:focus-within': {
                        outline: 'none',
                    },
                },
                'cellCheckbox': {
                    'padding': 0,
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:focus-within': {
                        outline: 'none',
                        boxShadow: OG_FOCUS_BOX_INNER_SHADOW,
                    },
                },
                'cellContent': {
                    overflow: 'visible',
                },
                'columnHeaders': {
                    fontSize: '1.5rem',
                    fontWeight: 400,
                    minHeight: '30px',
                    textTransform: 'uppercase',
                },
                'columnHeader': {
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:focus-visible': {
                        outline: 'none',
                        boxShadow: OG_FOCUS_BOX_INNER_SHADOW,
                    },
                    '&:focus-within': {
                        outline: 'none',
                        boxShadow: OG_FOCUS_BOX_INNER_SHADOW,
                    },
                    '&.FocusDisabled': {
                        visibility: 'hidden',
                    },
                },
                'columnHeader--sorted': {
                    '&:focus-visible': {
                        '& .MuiDataGrid-sortIcon': {
                            opacity: 1,
                        },
                    },
                },
                'columnHeader--sortable': {
                    '&:focus-visible': {
                        '& .MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                            width: 'auto',
                        },
                        '& .MuiDataGrid-sortIcon': {
                            opacity: 0.5,
                        },
                    },
                },
                'pinnedColumns': {
                    backgroundImage: 'none',
                },
                'pinnedColumnHeaders': {
                    backgroundImage: 'none',
                },
                'columnSeparator': {
                    display: 'none',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minHeight: 160,
                    minWidth: 496,
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.primary,
                        padding: theme.spacing(2),
                    });
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        paddingBottom: theme.spacing(1.5),
                        paddingLeft: theme.spacing(1.75),
                        paddingRight: theme.spacing(1.75),
                    });
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.primary,
                        fontWeight: 400,
                        lineHeight: 1.5,
                    });
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                variant: 'h3',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        padding: "".concat(theme.spacing(2), " ").concat(theme.spacing(1.75)),
                        fontSize: '2rem',
                    });
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                light: function (_a) {
                    var theme = _a.theme;
                    return ({
                        borderColor: theme.palette.dividerLight,
                    });
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    ' .MuiDrawer-paper': {
                        zIndex: 1075,
                    },
                },
                paper: {
                    border: 'none',
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                marginDense: function (_a) {
                    var theme = _a.theme;
                    return ({
                        marginBottom: theme.spacing(1.5),
                        marginTop: 0,
                    });
                },
                marginNormal: function (_a) {
                    var theme = _a.theme;
                    return ({
                        marginBottom: theme.spacing(2),
                        marginTop: 0,
                    });
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'marginLeft': 0,
                        'marginRight': 0,
                        'width': 'fit-content',
                        '& .MuiFormControlLabel-label': {
                            color: theme.palette.text.label,
                            marginLeft: theme.spacing(0.5),
                        },
                    });
                },
                label: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'alignItems': 'center',
                        'display': 'flex',
                        '&.Mui-disabled': {
                            color: theme.palette.text.secondary,
                        },
                    });
                },
                labelPlacementStart: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '& .MuiFormControlLabel-label': {
                            marginLeft: theme.spacing(0),
                            marginRight: theme.spacing(0.5),
                        },
                    });
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '& > *:not(:last-child)': {
                            marginBottom: theme.spacing(0.5),
                            marginRight: 0,
                        },
                        '&[role=radiogroup] > *:not(:last-child)': {
                            marginBottom: theme.spacing(1.5),
                        },
                        'label + &': {
                            marginTop: "".concat(theme.spacing(1.25)),
                        },
                    });
                },
                row: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '& > *:not(:last-child)': {
                            marginBottom: 0,
                            marginRight: theme.spacing(1),
                        },
                        '&[role=radiogroup] > *:not(:last-child)': {
                            marginBottom: 0,
                        },
                    });
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'fontSize': '1.75rem',
                        'fontWeight': 400,
                        'lineHeight': 1.5,
                        'letterSpacing': '0.02em',
                        'marginLeft': 0,
                        'marginRight': 0,
                        '.MuiFormControl-root > &': {
                            '.MuiInputBase-root + &': {
                                marginTop: theme.spacing(0.25),
                            },
                        },
                    });
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'alignItems': 'center',
                        'color': theme.palette.text.label,
                        'display': 'inline-flex',
                        'fontSize': '1.75rem',
                        'fontWeight': 300,
                        'lineHeight': 1.1,
                        'height': '2rem',
                        '&.Mui-focused, &.Mui-error': {
                            color: theme.palette.text.label,
                        },
                        '&.Mui-required::after': {
                            background: theme.palette.text.label,
                            borderRadius: '4px',
                            color: theme.palette.text.contrast,
                            content: '"required"',
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            lineHeight: 1,
                            padding: '2px 3px',
                        },
                    });
                },
                asterisk: {
                    fontSize: '1rem',
                    visibility: 'hidden',
                },
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'backgroundColor': theme.palette.background.input,
                        'borderColor': theme.palette.divider,
                        'borderStyle': 'solid',
                        'borderRadius': theme.shape.borderRadius,
                        'borderWidth': 1,
                        '&:hover': {
                            borderColor: OG_GREY_ML,
                        },
                        '&.Mui-focused, &:focus-visible': {
                            borderColor: theme.palette.text.primary,
                            borderWidth: 1,
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, ".concat(theme.palette.mode === 'dark' ? 0.9 : 0.2, ") inset"),
                        },
                        '&.Mui-disabled': {
                            backgroundColor: theme.palette.background.disabled,
                            borderColor: theme.palette.divider,
                        },
                        '&.Mui-error': {
                            borderColor: theme.palette.error.main,
                        },
                    });
                },
                input: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'height': '1.5em',
                        'padding': "".concat(theme.spacing(0.5), " ").concat(theme.spacing(1)),
                        '&::placeholder': {
                            color: theme.palette.text.label,
                            opacity: 1,
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.text.primary,
                            WebkitTextFillColor: 'initial',
                        },
                    });
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionEnd: function (_a) {
                    var theme = _a.theme;
                    return ({
                        marginLeft: theme.spacing(0.75),
                    });
                },
                positionStart: function (_a) {
                    var theme = _a.theme;
                    return ({
                        marginRight: theme.spacing(0.75),
                    });
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                autoComplete: 'off',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'fontSize': '1.75rem',
                        'fontWeight': 400,
                        'lineHeight': 1.5,
                        'label + &': {
                            marginTop: "".concat(theme.spacing(0.75), " !important"),
                        },
                    });
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                disableAnimation: true,
                shrink: true,
                variant: 'standard',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'alignItems': 'center',
                        'color': theme.palette.text.secondary,
                        'display': 'inline-flex',
                        'fontSize': '1.75rem',
                        'fontWeight': 300,
                        'lineHeight': 1.1,
                        'height': '2rem',
                        'overflow': 'inherit',
                        'position': 'relative',
                        'transform': 'none',
                        '&.Mui-focused, &.Mui-error, &.Mui-disabled': {
                            color: theme.palette.text.secondary,
                        },
                        '&.Mui-required::after': {
                            background: theme.palette.text.secondary,
                            borderRadius: '4px',
                            color: theme.palette.text.contrast,
                            content: '"required"',
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            lineHeight: 1,
                            padding: '2px 3px',
                        },
                    });
                },
                asterisk: {
                    fontSize: '1rem',
                    visibility: 'hidden',
                },
            },
        },
        MuiLinearProgress: {
            defaultProps: {
                color: 'success',
                variant: 'determinate',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'background': theme.palette.divider,
                        '&.MuiLinearProgress-colorSuccess .MuiLinearProgress-barColorSuccess': {
                            backgroundColor: OG_SUCCESS_DARK,
                        },
                        '&.MuiLinearProgress-colorWarning .MuiLinearProgress-barColorWarning': {
                            backgroundColor: OG_WARNING_DARK,
                        },
                        '&.MuiLinearProgress-colorError .MuiLinearProgress-barColorError': {
                            backgroundColor: OG_DANGER_DARK,
                        },
                    });
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'alignItems': 'center',
                        'color': theme.palette.text.primary,
                        'display': 'flex',
                        'fontSize': '1.75rem',
                        'fontWeight': 400,
                        'lineHeight': 1.5,
                        '&:hover': {
                            color: theme.palette.secondary.main,
                        },
                        '&:focus-visible': {
                            outline: "1px solid ".concat(OG_FOCUS),
                            outlineOffset: 0,
                        },
                        '&:active': {
                            color: theme.palette.text.primary,
                            textDecoration: 'underline',
                        },
                    });
                },
                button: {
                    fontFamily: 'Barlow, Helvetica, Arial, sans-serif',
                    fontSize: '1.75rem',
                    fontWeight: 400,
                    letterSpacing: '0.04em',
                    lineHeight: 1.5,
                },
            },
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true,
                dense: true,
            },
            styleOverrides: {
                root: {
                    '&[role=option]': {
                        'fontSize': '1.75rem',
                        'fontWeight': 300,
                        'lineHeight': 1,
                        '&:hover': {
                            backgroundColor: OG_OFF_WHITE,
                            color: OG_GREY_M,
                        },
                        '&.Mui-selected, &.Mui-selected:hover': {
                            backgroundColor: OG_GREY_M,
                            color: OG_WHITE,
                        },
                    },
                },
                dense: function (_a) {
                    var theme = _a.theme;
                    return ({
                        paddingBottom: theme.spacing(0.75),
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        paddingTop: theme.spacing(0.75),
                        minHeight: 'fit-content',
                    });
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    'fontWeight': 400,
                    'fontSize': '1.75rem',
                    'margin': '0 1px',
                    '&:focus-visible': {
                        boxShadow: OG_FOCUS_BOX_SHADOW,
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-disabled': {
                        opacity: OPACITY,
                    },
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 2,
                square: false,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'backgroundColor': theme.palette.background.popup,
                        '&.MuiMenu-paper': {
                            backgroundColor: theme.palette.background.input,
                            borderColor: theme.palette.divider,
                            borderRadius: theme.shape.borderRadius,
                            borderStyle: 'solid',
                            borderWidth: 1,
                        },
                    });
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: 'success',
                disableRipple: true,
                tabIndex: 0,
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'padding': 0,
                        '& .MuiSvgIcon-root': {
                            fontSize: 18,
                        },
                        '&.MuiRadio-colorDefault, &.MuiRadio-colorSuccess': {
                            color: theme.palette.text.primary,
                        },
                        '&:hover': {
                            background: 'none',
                        },
                        '&:focus-visible, &.Mui-focusVisible': {
                            filter: OG_FOCUS_DROP_SHADOW,
                        },
                        '&.Mui-disabled': {
                            opacity: OPACITY,
                        },
                        '&.MuiRadio-colorSuccess > span > svg:not(:first-of-type)': {
                            color: theme.palette.success.main,
                        },
                    });
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                inputProps: { 'data-testid': 'input' },
                variant: 'standard',
            },
            styleOverrides: {
                select: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'background': 'none',
                        'borderRadius': theme.shape.borderRadius,
                        ' em': {
                            color: theme.palette.text.label,
                            fontStyle: 'normal',
                        },
                    });
                },
                icon: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.primary,
                    });
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&.MuiAutocomplete-input': {
                            paddingLeft: theme.spacing(1),
                        },
                    });
                },
                option: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&.MuiAutocomplete-option': {
                            'fontSize': '1.75rem',
                            'fontWeight': 300,
                            'lineHeight': 1,
                            '&:hover': {
                                backgroundColor: OG_OFF_WHITE,
                                color: OG_GREY_M,
                            },
                            '&.MuiAutocomplete-option[aria-selected="true"], &.MuiAutocomplete-option[aria-selected="true"]:hover': {
                                backgroundColor: OG_GREY_M,
                                color: OG_WHITE,
                                lineHeight: 1,
                                paddingTop: theme.spacing(0.63),
                                paddingBottom: theme.spacing(0.63),
                            },
                        },
                        '&.MuiAutocomplete-listbox, &.MuiAutocomplete-option': {
                            minHeight: 'fit-content',
                            minWidth: 'fit-content',
                            paddingLeft: theme.spacing(1),
                            paddingRight: theme.spacing(1),
                        },
                        '&.MuiListItem-dense': {
                            minHeight: 'fit-content',
                            paddingLeft: theme.spacing(1),
                            paddingRight: theme.spacing(1),
                        },
                    });
                },
                paper: function (_a) {
                    var theme = _a.theme;
                    return ({
                        backgroundColor: theme.palette.background.input,
                        borderColor: theme.palette.divider,
                        borderRadius: theme.shape.borderRadius,
                        borderStyle: 'solid',
                        borderWidth: 1,
                    });
                },
            },
        },
        MuiChip: {
            defaultProps: {
                variant: 'tag',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme, ownerState = _a.ownerState;
                    return (__assign({ 'backgroundColor': theme.palette.background.chip, 'color': theme.palette.text.primary, 'fontSize': '1.7rem', 'lineHeight': 'normal', 'height': theme.spacing(2.25), ':hover, :active': {
                            backgroundColor: theme.palette.background.chip,
                        }, '& span': {
                            paddingBottom: '2px',
                        } }, (ownerState.variant === 'pill' && {
                        'borderRadius': theme.spacing(0.5),
                        ':hover': {
                            backgroundColor: OG_BUTTON_PRIMARY_HOVER,
                            color: OG_WHITE,
                        },
                        ':hover > .MuiChip-deleteIcon': {
                            color: OG_WHITE,
                        },
                        ':active': {
                            backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                            color: OG_WHITE,
                        },
                        ':active > .MuiChip-deleteIcon': {
                            color: OG_WHITE,
                        },
                        '&[aria-pressed="true"]': {
                            backgroundColor: OG_BUTTON_PRIMARY_ACTIVE,
                            color: OG_WHITE,
                        },
                        '&[aria-pressed="true"] > .MuiChip-deleteIcon': {
                            color: OG_WHITE,
                        },
                    })));
                },
                deleteIcon: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.primary,
                        fontSize: 'small',
                    });
                },
            },
        },
        MuiSlider: {
            defaultProps: {
                color: 'secondary',
                valueLabelDisplay: 'on',
            },
            styleOverrides: {
                root: {
                    display: 'flex',
                },
                rail: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'background': theme.palette.text.contrast,
                        'border': 'none',
                        'opacity': 1,
                        '.MuiSlider-trackInverted > &': {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        '.Mui-disabled > &': {
                            backgroundColor: theme.palette.text.contrast,
                            opacity: OPACITY,
                        },
                    });
                },
                thumb: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'background': theme.palette.slider.default,
                        'boxShadow': 'none',
                        'height': theme.spacing(4),
                        'width': theme.spacing(4),
                        '&::before': {
                            boxShadow: 'none',
                        },
                        '&:hover': {
                            background: theme.palette.slider.hover,
                            boxShadow: 'none',
                        },
                        '&.Mui-focusVisible': {
                            boxShadow: "0px 0px 1px 3px ".concat(OG_FOCUS),
                        },
                        '&.Mui-active': {
                            background: theme.palette.slider.active,
                            boxShadow: '0px 4px 8px rgba(96, 101, 113, 0.2)',
                        },
                        '.Mui-disabled > &': {
                            background: theme.palette.text.contrast,
                        },
                    });
                },
                track: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'border': 'none',
                        '.MuiSlider-trackInverted > &': {
                            backgroundColor: theme.palette.text.contrast,
                        },
                        '.Mui-disabled > &': {
                            backgroundColor: theme.palette.secondary.main,
                            opacity: OPACITY,
                        },
                    });
                },
                valueLabel: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'color': OG_GREY_VL,
                        'background': OG_BLACK_90,
                        'fontWeight': 300,
                        'letter': '0.04em',
                        'fontSize': '1.75rem',
                        'lineHeight': '2rem',
                        'height': 48,
                        'minWidth': 48,
                        'padding': theme.spacing(2),
                        '&::before': {
                            background: 'none',
                            borderColor: 'transparent',
                            borderStyle: 'solid',
                            borderTopColor: OG_BLACK_90,
                            borderBottomWidth: 0,
                            borderLeftWidth: theme.spacing(0.75),
                            borderRightWidth: theme.spacing(0.75),
                            borderTopWidth: theme.spacing(1.5),
                            transform: 'translate(-50%, 70%)',
                        },
                        '&.MuiSlider-valueLabelOpen': {
                            top: theme.spacing(-1.5),
                            transform: 'translateY(-100%)',
                        },
                        '.MuiSlider-vertical &.MuiSlider-valueLabelOpen': {
                            'left': theme.spacing(-1.5),
                            'transform': 'translate(-100%, -8px)',
                            'top': 0,
                            '&::before': {
                                borderTopColor: 'transparent',
                                borderLeftColor: OG_BLACK_90,
                                borderBottomWidth: theme.spacing(0.75),
                                borderLeftWidth: theme.spacing(1.5),
                                borderRightWidth: 0,
                                borderTopWidth: theme.spacing(0.75),
                                transform: 'translate(0, 50%)',
                            },
                        },
                    });
                },
            },
        },
        MuiStepper: {
            defaultProps: {
                alternativeLabel: true,
                orientation: 'horizontal',
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                horizontal: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&.MuiStepConnector-root': {
                            top: "calc(".concat(theme.spacing(1), " - 2px)"),
                            left: "calc(-50% + ".concat(theme.spacing(1), " - 1px)"),
                            right: "calc(50% + ".concat(theme.spacing(1), " - 1px)"),
                        },
                    });
                },
                lineHorizontal: function (_a) {
                    var theme = _a.theme;
                    return ({
                        borderColor: theme.palette.stepper.outline,
                        borderTopWidth: '3px',
                    });
                },
                vertical: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&.MuiStepConnector-root': {
                            marginLeft: "calc(".concat(theme.spacing(1), " - 1px)"),
                            marginTop: "calc(-".concat(theme.spacing(1), " - 1px)"),
                            marginBottom: "calc(-".concat(theme.spacing(1), " - 1px)"),
                        },
                    });
                },
                lineVertical: function (_a) {
                    var theme = _a.theme;
                    return ({
                        borderColor: theme.palette.stepper.outline,
                        borderLeftWidth: '3px',
                        height: '100%',
                    });
                },
            },
        },
        MuiStepLabel: {
            defaultProps: {
                StepIconComponent: StepIndicator,
            },
            styleOverrides: {
                label: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&.Mui-active, &.Mui-completed': {
                            fontWeight: 300,
                        },
                        '&.MuiStepLabel-alternativeLabel': {
                            marginTop: theme.spacing(1),
                        },
                    });
                },
            },
        },
        MuiSvgIcon: {
            defaultProps: {
                fontSize: 'large',
            },
            styleOverrides: {
                root: {
                    display: 'block',
                    fontSize: '2.4rem',
                    lineHeight: 1,
                },
                colorDisabled: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.disabled,
                    });
                },
                fontSizeSmall: {
                    fontSize: '1.5rem',
                },
                fontSizeLarge: {
                    fontSize: '3rem',
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: 'default',
                disableRipple: true,
                tabIndex: 0,
            },
            styleOverrides: {
                root: {
                    width: 24,
                    height: 18,
                    padding: '4px 2px',
                },
                switchBase: {
                    'padding': '6px 4px',
                    '&:focus-visible, &.Mui-focusVisible': {
                        '& + .MuiSwitch-track': {
                            boxShadow: "0px 0px 1px 0.5px ".concat(OG_FOCUS),
                        },
                    },
                    '&.Mui-checked': {
                        transform: 'translateX(10px)',
                    },
                    '& + .MuiSwitch-track, &.Mui-checked + .MuiSwitch-track': {
                        opacity: 1,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: OPACITY,
                    },
                    '&:hover': {
                        background: 'none',
                    },
                },
                thumb: function (_a) {
                    var theme = _a.theme;
                    return ({
                        background: theme.palette.background.paper,
                        boxShadow: 'none',
                        width: 6,
                        height: 6,
                    });
                },
                track: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'borderRadius': 6,
                        'backgroundColor': theme.palette.text.primary,
                        '.Mui-checked.MuiSwitch-colorDefault + &': {
                            backgroundColor: theme.palette.text.primary,
                        },
                        '.Mui-checked.MuiSwitch-colorSuccess + &': {
                            backgroundColor: theme.palette.success.main,
                        },
                        '.Mui-checked.Mui-disabled + &, .Mui-checked.MuiSwitch-colorSuccess.Mui-disabled + &': {
                            backgroundColor: theme.palette.text.primary,
                        },
                    });
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    'fontSize': '1.75rem',
                    'fontWeight': 300,
                    'paddingTop': 10,
                    'paddingBottom': 10,
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:focus-visible': {
                        boxShadow: OG_FOCUS_BOX_INNER_SHADOW,
                    },
                    '&:focus-within': {
                        outline: 'none',
                    },
                },
                head: {
                    'fontSize': '1.5rem',
                    'fontWeight': 500,
                    'lineHeight': 1.5,
                    'minHeight': 30,
                    'textTransform': 'uppercase',
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:focus-visible': {
                        outline: 'none',
                        boxShadow: OG_FOCUS_BOX_INNER_SHADOW,
                    },
                    '&:focus-within': {
                        outline: 'none',
                    },
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
                tabIndex: 0,
                iconPosition: 'start',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'borderRadius': theme.shape.borderRadius,
                        'color': theme.palette.text.primary,
                        'letterSpacing': '0.04em',
                        'lineHeight': 1,
                        'margin': 2,
                        'minHeight': 'fit-content',
                        'padding': theme.spacing(1.75, 1.75, 1.5, 1.75),
                        '&.MuiTab-wrapped': {
                            fontSize: '1.75rem',
                        },
                        '&.Mui-selected': {
                            color: theme.palette.text.highlight,
                            fontWeight: 600,
                        },
                        '&:hover': {
                            background: theme.palette.background.hover,
                        },
                        '&:active': {
                            background: theme.palette.background.active,
                        },
                        '&.Mui-focusVisible': {
                            background: theme.palette.background.default,
                            boxShadow: OG_FOCUS_BOX_SHADOW,
                        },
                        '&.Mui-disabled': {
                            opacity: OPACITY,
                        },
                    });
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                indicatorColor: 'primary',
            },
            styleOverrides: {
                root: {
                    minHeight: 'fit-content',
                },
                scrollButtons: {
                    'display': 'flex',
                    '@media (min-width: 1300px)': {
                        display: 'none',
                    },
                },
                scroller: function (_a) {
                    var theme = _a.theme;
                    return ({
                        borderBottomColor: theme.palette.divider,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        marginBottom: 1,
                    });
                },
                flexContainerVertical: function (_a) {
                    var theme = _a.theme;
                    return ({
                        borderBottom: 'none',
                        borderRightColor: theme.palette.divider,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        marginRight: 1,
                    });
                },
                indicator: {
                    'boxSizing': 'content-box',
                    'padding': '0 2px',
                    'marginLeft': -2,
                    // this is a hack to remove the residual indicator in the edge case were no tab is selected
                    '&[style="left: 0px; width: 0px;"]': {
                        marginLeft: -4,
                    },
                    '.MuiTabs-flexContainerVertical + &': {
                        'padding': '2px 0',
                        'marginLeft': 0,
                        'marginTop': -2,
                        // this is a hack to remove the residual indicator in the edge case were no tab is selected
                        '&[style="top: 0px; height: 0px;"]': {
                            marginTop: -4,
                        },
                    },
                },
            },
        },
        // @ts-ignore - the themeAugmentation of @mui/x-data-grid-pro is clobbering @mui/lab
        MuiTabPanel: {
            styleOverrides: {
                // @ts-ignore
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        padding: theme.spacing(2),
                    });
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiToggleButton: {
            defaultProps: {
                disableRipple: true,
                value: 'checked',
            },
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'padding': theme.spacing(0.75, 1.25),
                        'border': 'none',
                        ':hover': theme.palette.mode === 'dark'
                            ? {
                                background: OG_GREY_M,
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                            }
                            : {
                                background: OG_WHITE,
                                boxShadow: '0px 4px 8px rgba(96, 101, 113, 0.2)',
                            },
                        ':focus-visible': {
                            boxShadow: OG_FOCUS_BOX_SHADOW,
                        },
                        '&.Mui-selected, &.Mui-selected:hover': {
                            background: OG_BUTTON_PRIMARY_ACTIVE,
                            color: theme.palette.common.white,
                        },
                        '&.Mui-disabled': {
                            border: 'none',
                            color: theme.palette.text.primary,
                            opacity: OPACITY,
                        },
                    });
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'label + &': {
                            marginTop: theme.spacing(1),
                        },
                    });
                },
                grouped: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'backgroundColor': theme.palette.background.paper,
                        'border': "1px solid ".concat(theme.palette.divider),
                        'borderRadius': 0,
                        'color': theme.palette.text.primary,
                        'lineHeight': 1.1,
                        'padding': theme.spacing(0.75, 2),
                        'textTransform': 'capitalize',
                        ':hover': {
                            backgroundColor: theme.palette.background.hover,
                            boxShadow: 'none',
                        },
                        '&.Mui-focused, &:focus-visible': {
                            backgroundColor: theme.palette.background.default,
                            boxShadow: OG_FOCUS_BOX_SHADOW,
                            zIndex: 2,
                        },
                        '&.Mui-selected, &.Mui-selected:hover': {
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.background.selected,
                            zIndex: 1,
                        },
                        '&.MuiToggleButtonGroup-groupedHorizontal.Mui-selected': {
                            '::after': {
                                content: '""',
                                background: theme.palette.primary.main,
                                bottom: -1,
                                left: 0,
                                position: 'absolute',
                                height: 2,
                                width: '100%',
                            },
                        },
                        '&.MuiToggleButtonGroup-groupedVertical': {
                            justifyContent: 'start',
                            minWidth: 108,
                        },
                        '&.MuiToggleButtonGroup-groupedVertical.Mui-selected': {
                            '::after': {
                                content: '""',
                                background: theme.palette.primary.main,
                                left: -1,
                                top: 0,
                                height: '100%',
                                position: 'absolute',
                                width: 2,
                            },
                        },
                        '&.Mui-disabled': {
                            border: "1px solid ".concat(theme.palette.divider),
                            opacity: OPACITY,
                        },
                    });
                },
                groupedHorizontal: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&:not(:first-of-type)': {
                            borderLeftColor: theme.palette.divider,
                        },
                    });
                },
                groupedVertical: function (_a) {
                    var theme = _a.theme;
                    return ({
                        '&:not(:first-of-type)': {
                            borderTopColor: theme.palette.divider,
                        },
                    });
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: function (_a) {
                    var theme = _a.theme;
                    return ({
                        maxWidth: 240,
                        padding: theme.spacing(0.5, 1, 1, 1),
                        borderRadius: theme.shape.borderRadius,
                        fontSize: '1.5rem',
                        fontWeight: 300,
                        lineHeight: 1.15,
                        backgroundColor: OG_GREY_H,
                        color: OG_GREY_VL,
                    });
                },
                arrow: {
                    color: OG_GREY_H,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.secondary,
                    });
                },
                body1: function (_a) {
                    var theme = _a.theme;
                    return ({
                        color: theme.palette.text.primary,
                    });
                },
                gutterBottom: function (_a) {
                    var theme = _a.theme;
                    return ({
                        marginBottom: theme.spacing(2),
                    });
                },
            },
            variants: [
                {
                    props: { variant: 'code' },
                    style: {
                        fontFamily: 'monospace',
                        fontSize: '1.75rem',
                        fontWeight: 400,
                        lineHeight: 1.3,
                    },
                },
            ],
        },
        MuiPickersLayout: {
            styleOverrides: {
                // @ts-ignore
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        backgroundColor: theme.palette.background.input,
                    });
                },
            },
        },
        MuiDateTimePickerToolbar: {
            styleOverrides: {
                root: {
                    '.MuiTypography-h3': {
                        fontSize: '5rem',
                        fontWeight: 500,
                        lineHeight: '1.55',
                    },
                    '.MuiTypography-h4': {
                        fontSize: '3rem',
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                // @ts-ignore
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'paddingLeft': theme.spacing(2),
                        'paddingRight': theme.spacing(0.25),
                        'marginTop': theme.spacing(1),
                        '& .MuiPickersCalendarHeader-labelContainer': {
                            fontSize: '2rem',
                            fontWeight: 600,
                        },
                        '& .MuiIconButton-root': {
                            borderRadius: 0,
                        },
                    });
                },
            },
        },
        MuiDateCalendar: {
            defaultProps: {
                slots: { day: OGPickersDay },
            },
            styleOverrides: {
                // @ts-ignore
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'width': 'auto',
                        'overflow': 'visible',
                        'padding': theme.spacing(1),
                        'paddingTop': 0,
                        '& .MuiPickersSlideTransition-root': {
                            minHeight: '230px',
                        },
                        '& .MuiPickersDay-today:not(.Mui-selected)': {
                            borderColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_GREY_ML,
                        },
                        '& button.MuiPickersDay-root.Mui-selected': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersDay-root.Mui-selected:hover': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersDay-root.Mui-selected:focus': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersDay-root:focus, & button.MuiPickersDay-root:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_OFF_WHITE,
                        },
                        '& button.MuiPickersYear-yearButton.Mui-selected': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersYear-yearButton.Mui-selected:hover': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersYear-yearButton.Mui-selected:focus': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersYear-yearButton:focus, & button.MuiPickersYear-yearButton:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_OFF_WHITE,
                        },
                    });
                },
            },
        },
        MuiDateRangeCalendar: {
            defaultProps: {
                slots: { day: OGPickersDay },
            },
            styleOverrides: {
                // @ts-ignore
                root: function (_a) {
                    var theme = _a.theme;
                    return ({
                        'width': 'auto',
                        'padding': theme.spacing(1),
                        'paddingTop': 0,
                        '& .MuiDateRangeCalendar-monthContainer .MuiTypography-subtitle1': {
                            fontSize: '2rem',
                            fontWeight: 600,
                        },
                        '& .MuiPickersSlideTransition-root': {
                            minHeight: '255px',
                        },
                        '& .MuiPickersDay-today:not(.MuiDateRangePickerDay-day)': {
                            borderColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_GREY_ML,
                        },
                        '& div.MuiDateRangePickerDay-day': {
                            borderColor: 'transparent',
                        },
                        '& button.MuiPickersDay-root.Mui-selected': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersDay-root.Mui-selected:hover': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersDay-root.Mui-selected:focus': {
                            backgroundColor: OG_BUTTON_PRIMARY,
                        },
                        '& button.MuiPickersDay-root:focus, & button.MuiPickersDay-root:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_OFF_WHITE,
                            borderColor: 'transparent !important',
                        },
                        '& .MuiDateRangePickerDay-rangeIntervalDayHighlight:not(.MuiDateRangePickerDay-outsideCurrentMonth)': {
                            backgroundColor: theme.palette.mode === 'dark' ? OG_GREY_M : OG_OFF_WHITE,
                        },
                    });
                },
            },
        },
    },
    palette: {
        common: {
            black: OG_BLACK_80,
            grey: OG_GREY,
            red: OG_RED,
            white: OG_WHITE,
        },
        primary: {
            main: OG_RED,
        },
    },
    shape: {
        borderRadius: 2,
    },
    typography: {
        fontFamily: 'Barlow, Helvetica, Arial, sans-serif',
        htmlFontSize: 8,
        h1: {
            fontSize: '3.75rem',
            fontWeight: 500,
            letterSpacing: '0.04em',
            lineHeight: 1,
            textTransform: 'uppercase',
        },
        h2: {
            fontSize: '2.5rem',
            fontWeight: 400,
            letterSpacing: '0.04em',
            lineHeight: 1,
            textTransform: 'uppercase',
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 600,
            letterSpacing: '0.04em',
            lineHeight: 1,
            textTransform: 'uppercase',
        },
        h4: {
            fontSize: '1.75rem',
            fontWeight: 400,
            letterSpacing: '0.04em',
            lineHeight: 1,
            textTransform: 'uppercase',
        },
        h5: {
            fontSize: '1.75rem',
            fontWeight: 300,
            letterSpacing: '0.04em',
            lineHeight: 1,
            textTransform: 'uppercase',
        },
        body1: {
            fontSize: '1.75rem',
            fontWeight: 300,
            letterSpacing: '0.04em',
            lineHeight: 1.3,
        },
        body2: {
            fontSize: '1.5rem',
            fontWeight: 300,
            letterSpacing: '0.04em',
            lineHeight: 1,
        },
        button: {
            fontSize: '1.75rem',
            fontWeight: 400,
        },
    },
};
