import { rest } from 'msw';
import { formatApiPath } from '@/api/utils';
import { DEFAULT_MOCK_LICENSES, LICENSES_FILE_PATH, LICENSES_PATH } from './licenses.constants';
export function mockGetLicenses(server, mockLicenses) {
    var mockResponseBody = {
        licenses: mockLicenses,
    };
    server.use(rest.get(formatApiPath(LICENSES_PATH), function (_, res, ctx) { return res(ctx.status(200), ctx.json(mockResponseBody)); }));
}
/**
 * Mock GET handler for test server
 */
export var mockLicensesGetHandler = rest.get(formatApiPath(LICENSES_PATH), function (req, res, ctx) {
    var mockResponseBody = {
        licenses: DEFAULT_MOCK_LICENSES,
    };
    return res(ctx.status(200), ctx.json(mockResponseBody));
});
/**
 * Mock POST handler for test server
 */
export var mockLicenseUploadPostHandler = rest.post(formatApiPath(LICENSES_FILE_PATH), function (req, res, ctx) {
    var mockResponseBody = {
        licenses: DEFAULT_MOCK_LICENSES,
    };
    return res(ctx.status(201), ctx.json(mockResponseBody));
});
