var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import DialogContentText from '@mui/material/DialogContentText';
import { formatMessageWithList } from '@/utils/calculations';
import { ConfirmationModal } from '../confirmation-modal';
export function PendingDeleteDialog(_a) {
    var open = _a.open, handleClose = _a.handleClose, handleUnenroll = _a.handleUnenroll, nodeIds = _a.nodeIds;
    var t = useTranslation().t;
    var onConfirm = useCallback(function () { return handleUnenroll(nodeIds); }, [handleUnenroll, nodeIds]);
    return (_jsx(ConfirmationModal, __assign({ title: t('components.nodes.remove_modal.title'), confirmLabel: t('global.buttons.remove'), open: open, onClose: handleClose, onConfirm: onConfirm }, { children: _jsx(DialogContentText, __assign({ sx: { whiteSpace: 'pre-wrap' } }, { children: nodeIds.length > 1
                ? format(t('components.nodes.remove_modal.multiple_nodes_message'), formatMessageWithList(nodeIds))
                : format(t('components.nodes.remove_modal.message'), nodeIds[0]) })) })));
}
