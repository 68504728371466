var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { OGPanel } from '@opengear/panel';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useDeviceView } from '@/hooks/use-device-view';
import { capacityFromCidr } from '@/utils/calculations';
import { useUpdateMultipleInstanceLHVPN, miLhvpnQueryKeys } from '@/api/multiple-instance/lhvpn';
import { useFormSyncV2 } from '@/hooks/use-form-sync';
import { UpdateForm } from '@/components/layout/update-form';
import { transformResponseToFormState, transformFormStateToRequest } from './transformers';
import { invalidFieldError, maxValueFieldError, minValueFieldError, requiredFieldError } from '@/utils/field-validation-errors';
import { IPV4_PATTERN, NUM_ONLY_PATTERN } from '@/constants/regex';
export var MILHVPN_FORM_LOADER_ID = 'multiple-instance-vpn-form-loading';
export var CidrMinVal = 0;
export var CidrMaxVal = 29;
export function MultiInstanceVPNForm(_a) {
    var editable = _a.editable, data = _a.data;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var _b = useUpdateMultipleInstanceLHVPN(), mutate = _b.mutate, isPending = _b.isPending;
    // Derive form default values from data or empty string
    var sourceValues = useMemo(function () { return transformResponseToFormState(data); }, [data]);
    var _c = useForm({
        mode: 'onTouched',
        defaultValues: sourceValues,
    }), control = _c.control, handleSubmit = _c.handleSubmit, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _d = _c.formState, defaultValues = _d.defaultValues, dirtyFields = _d.dirtyFields, isDirty = _d.isDirty, isValid = _d.isValid, reset = _c.reset, watch = _c.watch;
    var handleFormReset = useCallback(function (resetData) {
        reset(transformResponseToFormState(resetData));
    }, [reset]);
    var capacity = capacityFromCidr(watch('cidr'));
    useFormSyncV2(miLhvpnQueryKeys.all, handleFormReset, t('pages.multi_instance_vpn.form.reset_warning.header'));
    // Resets form back to current values from api or defaults
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    // Submit handler to update the vpn details
    var onSubmit = useCallback(function (formData) {
        var request = transformFormStateToRequest(formData);
        mutate(request.multiple_instance_lhvpn, {
            onSuccess: function (updatedData) {
                if (updatedData) {
                    handleFormReset(updatedData);
                }
            },
        });
    }, [handleFormReset, mutate]);
    return (_jsx(OGPanel, { children: _jsxs(UpdateForm, __assign({ "aria-label": t('pages.multi_instance_vpn.form.label'), dirty: isDirty, editable: !!editable, submitting: isPending, valid: isValid, onReset: handleReset, onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Box, __assign({ sx: {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: 1,
                        marginBottom: 2,
                    } }, { children: [_jsx(InfoIcon, { "aria-hidden": "false", "aria-label": t('global.icon.information.aria_label') }), _jsx(Typography, { children: t('pages.multi_instance_vpn.description') })] })), _jsx(Controller, { control: control, name: "address", rules: {
                        required: requiredFieldError(t('pages.multi_instance_vpn.form.fields.address.label')),
                        pattern: { value: IPV4_PATTERN, message: invalidFieldError(t('pages.multi_instance_vpn.form.fields.address.label')) },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "address", disabled: !editable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.multi_instance_vpn.form.fields.address.label'), sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                    } }), _jsx(Controller, { control: control, name: "cidr", rules: {
                        required: requiredFieldError(t('pages.multi_instance_vpn.form.fields.cidr.label')),
                        max: { value: CidrMaxVal, message: maxValueFieldError(t('pages.multi_instance_vpn.form.fields.cidr.label'), CidrMaxVal.toString()) },
                        min: { value: CidrMinVal, message: minValueFieldError(t('pages.multi_instance_vpn.form.fields.cidr.label'), CidrMinVal.toString()) },
                        // Input type="number" catches any non-number characters and shows required message. Pattern here just in case
                        pattern: { value: NUM_ONLY_PATTERN, message: invalidFieldError(t('pages.multi_instance_vpn.form.fields.cidr.label')) },
                    }, render: function (_a) {
                        var _b;
                        var field = _a.field, fieldState = _a.fieldState;
                        return (_jsx(TextField, __assign({ id: "cidr-subnet-mask", "aria-controls": "address-capacity", disabled: !editable, error: !!fieldState.error, helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, label: t('pages.multi_instance_vpn.form.fields.cidr.label'), type: "number", sx: { maxWidth: isMobileView ? undefined : 300 }, required: true }, field)));
                    } }), _jsx(TextField, { id: "address-capacity", "aria-live": "polite", disabled: true, label: t('pages.multi_instance_vpn.form.fields.capacity.label'), value: capacity, sx: { maxWidth: isMobileView ? undefined : 300 } })] })) }));
}
