var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useFieldArray } from 'react-hook-form';
import { FormActions, CancelButton, useFormContainerContext, SubmitButton } from '@/components/layout/forms';
import { CreateNewTagInput, AssignedTagsInput, AssignedTagsCount, RemoveTagButton, AssignExistingTagsInput, AssignTagFormCreateButton, AssignTagFormExistingButton, } from './assign-tag-form-controls';
export function AssignTagModalContent(_a) {
    var onClose = _a.onClose, onSubmit = _a.onSubmit, multipleRows = _a.multipleRows, resourceTagNames = _a.resourceTagNames;
    var t = useTranslation().t;
    var _b = useFormContainerContext(), control = _b.control, setFocus = _b.setFocus;
    var addExistingTagButton = useRef(null);
    var _c = useFieldArray({
        control: control,
        name: 'newSelectedOrCreatedTagsFields',
    }), newSelectedOrCreatedTagsFields = _c.fields, appendNewSelectedOrCreatedTags = _c.append, removeNewSelectedOrCreatedTags = _c.remove;
    var _d = useFieldArray({
        control: control,
        name: 'assignedTags',
    }), assignedTagFields = _d.fields, removeAssignedTag = _d.remove;
    var appendUnassignedTags = useFieldArray({
        control: control,
        name: 'unassignedTags',
    }).append;
    // No tags selected message
    var showNoTagsSelected = useMemo(function () { return !newSelectedOrCreatedTagsFields.length && !assignedTagFields.length; }, [newSelectedOrCreatedTagsFields.length, assignedTagFields.length]);
    // handle removal of newly added rows
    var handleNewRowRemove = useCallback(function (index) {
        if (newSelectedOrCreatedTagsFields.length > 1) {
            var nextTag = newSelectedOrCreatedTagsFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("newSelectedOrCreatedTagsFields.".concat(nextTag, ".newResourceTagName"));
        }
        else if (assignedTagFields.length > 0) {
            setFocus("assignedTags.".concat(assignedTagFields.length - 1, ".resource_tag_name"));
        }
        else if (addExistingTagButton.current) {
            addExistingTagButton.current.focus();
        }
        removeNewSelectedOrCreatedTags(index);
    }, [removeNewSelectedOrCreatedTags, newSelectedOrCreatedTagsFields, setFocus, assignedTagFields]);
    // add row to exiting tag
    var handleExistingTagClick = useCallback(function () {
        appendNewSelectedOrCreatedTags({ resourceTagId: '', newResourceTagName: '', type: 'existing' });
    }, [appendNewSelectedOrCreatedTags]);
    // add row to create new tag
    var handleNewCreateTagClick = useCallback(function () {
        appendNewSelectedOrCreatedTags({ resourceTagId: '', newResourceTagName: '', type: 'create' });
    }, [appendNewSelectedOrCreatedTags]);
    // Remove assigned resource tags, append newly removed tag back into assigned tag list
    var handleAssignedTagRemove = useCallback(function (index, tag) {
        if (index === assignedTagFields.length - 1 && newSelectedOrCreatedTagsFields.length > 0) {
            setFocus("newSelectedOrCreatedTagsFields.0.newResourceTagName");
        }
        else if (assignedTagFields.length > 1) {
            var nextTag = assignedTagFields.length - 1 > index ? index + 1 : index - 1;
            setFocus("assignedTags.".concat(nextTag, ".resource_tag_name"));
        }
        else if (addExistingTagButton.current) {
            addExistingTagButton.current.focus();
        }
        var foundTag = resourceTagNames.find(function (resourceTag) { return resourceTag.resource_tag_name === tag.resource_tag_name; });
        if (foundTag) {
            var unassignedTag = { id: foundTag.id, resource_tag_name: foundTag.resource_tag_name, linkedAlready: true };
            appendUnassignedTags([__assign({}, unassignedTag)]);
        }
        removeAssignedTag(index);
    }, [removeAssignedTag, resourceTagNames, appendUnassignedTags, assignedTagFields, setFocus, newSelectedOrCreatedTagsFields]);
    return (_jsxs(_Fragment, { children: [showNoTagsSelected && (_jsx(Box, __assign({ pt: 1 }, { children: _jsx(Typography, { children: t('components.assign_tag_modal.no_tags_selected') }) }))), assignedTagFields.map(function (tag, index) { return (_jsx(Box, __assign({ py: 0.5 }, { children: _jsxs(AssignedTagsInput, __assign({ index: index, hasCount: !!(tag && tag.assignedTo.length && multipleRows) }, { children: [tag.assignedTo.length && multipleRows && _jsx(AssignedTagsCount, { nodePorts: tag.assignedTo }), _jsx(RemoveTagButton, { onClick: function () { return handleAssignedTagRemove(index, tag); } })] })) }), tag.id)); }), newSelectedOrCreatedTagsFields.map(function (input, index) { return (_jsx(Box, __assign({ py: 0.5 }, { children: input && input.type === 'existing' ? (
                // existing tags input
                _jsx(AssignExistingTagsInput, __assign({ index: index }, { children: _jsx(RemoveTagButton, { onClick: function () { return handleNewRowRemove(index); } }) }))) : (
                // new tags input
                _jsx(CreateNewTagInput, __assign({ index: index }, { children: _jsx(RemoveTagButton, { onClick: function () { return handleNewRowRemove(index); } }) }))) }), input.id)); }), _jsxs(Stack, __assign({ direction: "row", spacing: 2, p: 2, pl: 0 }, { children: [_jsx(AssignTagFormExistingButton
                    // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    , { 
                        // Modals require a default autofocused element, see /docs/development/accessibility.md for more information.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus: true, ref: addExistingTagButton, onClick: handleExistingTagClick }), _jsx(AssignTagFormCreateButton, { onClick: handleNewCreateTagClick })] })), _jsx(Divider, { sx: { marginX: -2 } }), _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: onClose }), _jsx(SubmitButton, __assign({ type: "button", onClick: onSubmit }, { children: t('components.assign_tag_modal.buttons.update_tags') }))] })] }));
}
