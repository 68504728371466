var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import { OGToastPriorities } from '@opengear/toast';
import { useQueryClient, useIsMutating, useIsFetching } from '@tanstack/react-query';
import { useAlertNotification } from '@/hooks/alert-notification';
/**
 * This hook is responsible for alerting the user that the pre-filled form values have recently changed.
 * @deprecated Use useFormSyncV2 instead
 * @param formData default values from React hook form
 * @param sourceData Data fetched from backend
 * @param resetForm React hook form's reset handler.
 * @param header Alert text.
 * @param [isBusy] Optional flag to skip the useEffect triggering an alert on that render. Use when sourceData is fetched from multiple endpoints.
 */
export function useFormSync(formData, sourceData, resetForm, header, isBusy, omittedFields) {
    if (isBusy === void 0) { isBusy = false; }
    if (omittedFields === void 0) { omittedFields = []; }
    var t = useTranslation().t;
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var updatedDataRef = useRef(null);
    var syncFormData = useMemo(function () { return omit(formData, omittedFields); }, [formData, omittedFields]);
    var syncSourceData = useMemo(function () { return omit(sourceData, omittedFields); }, [omittedFields, sourceData]);
    // Close alert. User has acknowledged there are updates so don't show alert again unless fetched data changes.
    var handleCloseCallback = useCallback(function () {
        updatedDataRef.current = syncSourceData;
    }, [syncSourceData]);
    // Sync form, close alert, and reset ref
    var handleSync = useCallback(function () {
        updatedDataRef.current = null;
        closeRef.current();
        resetForm(sourceData);
    }, [closeRef, resetForm, sourceData]);
    useEffect(function () {
        /*
          Behaviour:
          - Trigger new alert when form data is not equal to fetched data
          - Don't re-trigger if fetched data is the same
          - Replace alert if fetched data changes. This will make sure form is synced with 'latest'
         */
        if (!isEqual(syncFormData, syncSourceData) && !isEqual(updatedDataRef.current, syncSourceData) && !isBusy) {
            updatedDataRef.current = syncSourceData;
            alertNotification(_jsx(OGButton, __assign({ size: "small", onClick: handleSync }, { children: t('global.buttons.accept_updates') })), {
                header: header,
                disableTypography: true,
                persist: true,
                priority: OGToastPriorities.Info,
            }, handleCloseCallback);
        }
        // Behaviour: If fetched data returns back to form data, close alert and reset ref
        if (isEqual(syncFormData, syncSourceData)) {
            closeRef.current();
            updatedDataRef.current = null;
        }
    }, [alertNotification, closeRef, handleCloseCallback, handleSync, header, isBusy, syncFormData, syncSourceData, t]);
}
/**
 * This hook is responsible for alerting comsumers that a mismatch between the initial data read from a React Query cache
 * and the newly fetched data has occurred. When a mismatch is detected, an alert is displayed to the user containing an
 * 'Accept Updates' button that executes a reset callback when clicked. It is up to the consumer to decide how to handle
 * the reset, however the most common use case is to reset a form.
 * @param queryKey the query key to watch for changes
 * @param onReset callback to handle the reset
 * @param header alert notification header
 * @param omittedFields fields to omit from the comparison
 */
export function useFormSyncV2(queryKey, onReset, header, omittedFields) {
    if (omittedFields === void 0) { omittedFields = []; }
    var t = useTranslation().t;
    var _a = useAlertNotification(), alertNotification = _a.alertNotification, closeRef = _a.closeRef;
    var queryClient = useQueryClient();
    // By subscribing to the fetching state of our queryKey, we are able to ensure we always have the latest data
    var isFetchingData = useIsFetching({ queryKey: queryKey });
    // We use the mutation count to skip sync checks while a mutation is in progress
    var isMutatingCount = useIsMutating({ mutationKey: queryKey });
    var cachedData = useRef(null);
    var getQueryData = useCallback(function () { var _a; return (_a = queryClient.getQueryData(queryKey)) !== null && _a !== void 0 ? _a : null; }, [queryClient, queryKey]);
    var handleSync = useCallback(function () {
        closeRef.current();
        var queryData = getQueryData();
        if (queryData) {
            onReset(queryData);
        }
    }, [closeRef, onReset, getQueryData]);
    var displayAlert = useCallback(function () {
        alertNotification(_jsx(OGButton, __assign({ size: "small", onClick: handleSync }, { children: t('global.buttons.accept_updates') })), {
            header: header,
            disableTypography: true,
            persist: true,
            priority: OGToastPriorities.Info,
        });
    }, [alertNotification, handleSync, header, t]);
    // This effect is responsible for comparing the cached data with the newly fetched data.
    // It will display an alert if a mismatch is detected.
    useEffect(function () {
        var queryData = getQueryData();
        if (!cachedData.current || !queryData || isMutatingCount > 0)
            return;
        var omittedCache = omit(cachedData.current, omittedFields);
        var omittedResult = omit(queryData, omittedFields);
        if (!isEqual(omittedCache, omittedResult)) {
            displayAlert();
            cachedData.current = queryData;
        }
    }, [displayAlert, isMutatingCount, omittedFields, getQueryData]);
    // This effect is responsible for caching the data each time a mutation occurs or when the data is fetched.
    useEffect(function () {
        // If a mutation happens (e.g. user submits form), we clear the cached data to trick the hook
        // into believing this is the initial render. Else we cache the data for the next render.
        cachedData.current = isMutatingCount ? null : getQueryData();
    }, [isMutatingCount, isFetchingData, getQueryData]);
}
