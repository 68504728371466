var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import format from 'string-format';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { OGButton } from '@opengear/buttons';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useDeviceView } from '@/hooks/use-device-view';
import { UpdateForm } from '@/components/layout/update-form';
import { useNTP, useUpdateNTP } from '@/api/system/ntp';
import { useFormSync } from '@/hooks/use-form-sync';
import { ADDRESS_PATTERN } from '@/constants/regex';
function transformServerResponse(response) {
    var _a;
    return {
        enabled: (_a = response.ntp.enabled) !== null && _a !== void 0 ? _a : false,
        servers: response.ntp.servers.map(function (server) { return ({ value: server.value }); }),
    };
}
export function AutomaticSettingsForm(_a) {
    var _this = this;
    var data = _a.data, editable = _a.editable;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var isFetching = useNTP().isFetching;
    var sourceValues = useMemo(function () { return transformServerResponse(data); }, [data]);
    var addServerButton = useRef(null);
    var _b = useForm({
        mode: 'onBlur',
        defaultValues: sourceValues,
    }), control = _b.control, handleSubmit = _b.handleSubmit, _c = _b.formState, errors = _c.errors, defaultValues = _c.defaultValues, isDirty = _c.isDirty, isSubmitting = _c.isSubmitting, isValid = _c.isValid, reset = _b.reset, setFocus = _b.setFocus;
    var _d = useFieldArray({
        name: 'servers',
        control: control,
    }), fields = _d.fields, append = _d.append, remove = _d.remove;
    var updateNTP = useUpdateNTP();
    useFormSync(defaultValues, sourceValues, reset, t('pages.time_settings.tabs.automatic.reset_warning'), isFetching);
    var onSubmit = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var updatedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateNTP(formData)];
                case 1:
                    updatedData = _a.sent();
                    if (updatedData) {
                        reset(transformServerResponse(updatedData));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [updateNTP, reset]);
    var handleReset = useCallback(function () {
        reset();
    }, [reset]);
    var handleRemoveServer = useCallback(function (index) {
        if (fields.length > 1) {
            var nextServer = fields.length - 1 > index ? index + 1 : index - 1;
            setFocus("servers.".concat(nextServer, ".value"));
        }
        else if (addServerButton.current) {
            addServerButton.current.focus();
        }
        remove(index);
    }, [remove, fields, addServerButton, setFocus]);
    return (_jsxs(UpdateForm, __assign({ "aria-label": t('pages.time_settings.tabs.automatic.form'), dirty: isDirty, submitting: isSubmitting, valid: isValid, onReset: handleReset, onSubmit: handleSubmit(onSubmit), editable: !!editable }, { children: [_jsxs(FormControl, { children: [_jsx(FormLabel, { children: t('pages.time_settings.tabs.automatic.label') }), _jsx(Controller, { control: control, name: "enabled", render: function (_a) {
                            var _b = _a.field, onChange = _b.onChange, value = _b.value, field = __rest(_b, ["onChange", "value"]);
                            return (_jsxs(ToggleButtonGroup, __assign({ disabled: !editable, exclusive: true, value: value.toString(), onChange: function (_, v) {
                                    if (!v)
                                        return;
                                    var parsedValue = JSON.parse(v);
                                    onChange(parsedValue);
                                } }, field, { children: [_jsx(ToggleButton, __assign({ value: "false" }, { children: t('global.buttons.enabled.disabled') })), _jsx(ToggleButton, __assign({ value: "true" }, { children: t('global.buttons.enabled.enabled') }))] })));
                        } })] }), _jsx(FormGroup, { children: fields.map(function (item, index) { return (_jsxs(Stack, __assign({ direction: "row", spacing: 1, role: "group", paddingTop: 1 }, { children: [_jsx(Controller, { control: control, name: "servers.".concat(index, ".value"), rules: {
                                required: t('pages.time_settings.tabs.automatic.server_empty'),
                                pattern: {
                                    value: ADDRESS_PATTERN,
                                    message: t('pages.time_settings.tabs.automatic.address_error'),
                                },
                            }, render: function (_a) {
                                var _b, _c, _d, _e;
                                var _f = _a.field, ref = _f.ref, newField = __rest(_f, ["ref"]);
                                return (_jsx(TextField, __assign({ fullWidth: true, disabled: !editable, inputProps: {
                                        'aria-label': format(t('pages.time_settings.tabs.automatic.server_label'), index.toString()),
                                    }, label: t('pages.time_settings.tabs.automatic.visual_server_label'), required: true, sx: { maxWidth: isMobileView ? undefined : 400 }, error: !!((_b = errors.servers) === null || _b === void 0 ? void 0 : _b[index]), helperText: (_e = (_d = (_c = errors.servers) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.message, inputRef: ref }, newField)));
                            } }), _jsx(Tooltip, __assign({ arrow: true, placement: "right", title: t('pages.time_settings.tabs.automatic.remove') }, { children: _jsx("span", __assign({ style: { height: 'fit-content', marginTop: '22px' } }, { children: _jsx(OGButton, { disabled: !editable, "aria-label": format(t('pages.time_settings.tabs.automatic.remove_server'), index.toString()), size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () { return handleRemoveServer(index); } }) })) }))] }), item.id)); }) }), _jsx(OGButton, __assign({ ref: addServerButton, disabled: !editable, startIcon: _jsx(AddCircleOutline, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return append({ value: '' }); } }, { children: t('pages.time_settings.tabs.automatic.add_server') }))] })));
}
