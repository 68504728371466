var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TextField from '@mui/material/TextField';
import { NEW_FILTER_ID } from '@/utils/filter';
/** Autocomplete prop functions */
var NEW_FILTER_GROUP_KEY = 'new-filter-group';
var FILTERS_GROUP_KEY = 'filters-group';
function getOptionLabel(option) {
    return option.name;
}
function groupBy(option) {
    return option.id === NEW_FILTER_ID ? NEW_FILTER_GROUP_KEY : FILTERS_GROUP_KEY;
}
function renderGroup(_a) {
    var group = _a.group, children = _a.children;
    if (group === NEW_FILTER_GROUP_KEY) {
        return (_jsx(Box, __assign({ role: "group", borderTop: "1px solid", borderColor: "divider", marginTop: 1, paddingTop: 1 }, { children: children }), NEW_FILTER_GROUP_KEY));
    }
    return children;
}
function isOptionEqualToValue(option, value) {
    return option.id === value.id;
}
function renderOption(props, option, _a) {
    var selected = _a.selected;
    return (_createElement(ListItem, __assign({}, props, { key: option.id, dense: true }),
        _jsxs(Box, __assign({ component: "span", sx: { width: '100%', display: 'flex', justifyContent: 'space-between' } }, { children: [getOptionLabel(option), selected ? _jsx(CheckOutlinedIcon, { sx: { fontSize: '2rem' } }) : ''] }))));
}
/**
 * Common filter autocomplete component
 * @param props - subnet of AutocompleteProps
 */
export function SelectFilterField(_a) {
    var textFieldProps = _a.textFieldProps, props = __rest(_a, ["textFieldProps"]);
    var renderInput = useCallback(function (params) { return _jsx(TextField, __assign({}, params, textFieldProps, { margin: "none", size: "medium", variant: "standard" })); }, [textFieldProps]);
    return (_jsx(Autocomplete, __assign({}, props, { clearOnEscape: true, forcePopupIcon: true, getOptionLabel: getOptionLabel, groupBy: groupBy, isOptionEqualToValue: isOptionEqualToValue, renderGroup: renderGroup, renderInput: renderInput, renderOption: renderOption, sx: { flexGrow: 1 } })));
}
