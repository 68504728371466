import { rest } from 'msw';
import merge from 'lodash/merge';
import { formatApiPath } from '@/api/utils';
import { PERMISSIONS_PATH, MOCK_ROOT_PERMISSIONS } from './ui.constants';
export function createUIPermissions(permissions) {
    return merge({}, MOCK_ROOT_PERMISSIONS, permissions);
}
export function mockGetUIPermissions(server, permissions) {
    var ui = createUIPermissions(permissions);
    server.use(rest.get(formatApiPath(PERMISSIONS_PATH), function (req, res, ctx) { return res(ctx.status(200), ctx.json({ ui: ui })); }));
    return ui;
}
// UI permissions handler
export var uiPermissionsGetHandler = rest.get(formatApiPath(PERMISSIONS_PATH), function (req, res, ctx) {
    return res(ctx.status(200), ctx.json({ ui: MOCK_ROOT_PERMISSIONS }));
});
