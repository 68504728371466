var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { LOCAL_AUTH_POLICY_ROUTE, PASSWORD_POLICY_ROUTE, LOGIN_RESTRICTION_ROUTE } from '@/constants/routes';
import { PasswordPolicyTab } from '@/components/accounts/local-auth-policy/password-policy-tab';
import { LoginRestrictionsTab } from '@/components/accounts/local-auth-policy/login-restrictions-tab';
var TabValue;
(function (TabValue) {
    TabValue["PASSWORD"] = "PASSWORD";
    TabValue["LOGIN"] = "LOGIN";
})(TabValue || (TabValue = {}));
function LocalAuthPolicy() {
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var hasPermission = useUIPermissions().hasPermission;
    var hasPasswordPolicyViewAccess = hasPermission('settings.user_management.password_policy.view');
    var hasLoginRestrictionViewAccess = hasPermission('settings.user_management.login_restrictions.view');
    var innerNode = null;
    if (pathname.endsWith(PASSWORD_POLICY_ROUTE) && hasPasswordPolicyViewAccess) {
        innerNode = _jsx(PasswordPolicyTab, { editable: hasPermission('settings.user_management.password_policy.edit') });
    }
    if (pathname.endsWith(LOGIN_RESTRICTION_ROUTE) && hasLoginRestrictionViewAccess) {
        innerNode = _jsx(LoginRestrictionsTab, { editable: hasPermission('settings.user_management.password_policy.edit') });
    }
    useDocTitle(t('pages.local_auth_policy.title'));
    var tabValue = pathname.endsWith(LOGIN_RESTRICTION_ROUTE) ? TabValue.LOGIN : TabValue.PASSWORD;
    useEffect(function () {
        if (pathname.endsWith(LOCAL_AUTH_POLICY_ROUTE)) {
            if (hasPasswordPolicyViewAccess) {
                navigate(PASSWORD_POLICY_ROUTE.slice(1), { replace: true });
            }
            else if (hasLoginRestrictionViewAccess) {
                navigate(LOGIN_RESTRICTION_ROUTE.slice(1), { replace: true });
            }
        }
    }, [pathname, navigate, hasPasswordPolicyViewAccess, hasLoginRestrictionViewAccess]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.local_auth_policy.title') })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: PASSWORD_POLICY_ROUTE.slice(1), label: t('pages.local_auth_policy.tabs.password_policy.title'), value: TabValue.PASSWORD, disabled: !hasPasswordPolicyViewAccess }), _jsx(Tab, { component: RouterLink, to: LOGIN_RESTRICTION_ROUTE.slice(1), label: t('pages.local_auth_policy.tabs.login_restrictions.title'), value: TabValue.LOGIN, disabled: !hasLoginRestrictionViewAccess })] })), _jsx(TabPanel, __assign({ value: TabValue.PASSWORD }, { children: innerNode })), _jsx(TabPanel, __assign({ value: TabValue.LOGIN }, { children: innerNode }))] })) })] })));
}
export default withAuthenticationRequired(LocalAuthPolicy, {
    rights: ['settings.user_management.password_policy.view', 'settings.user_management.login_restrictions.view'],
});
