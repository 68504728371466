var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
/**
 * This component is modelled off the "required" prop for MUI inputs and is used to indicate that a field is required.
 * The styling should match /lib/theme/common.tsx > commonThemeOptions > components > MuiFormLabel > styleOverrides > root > '&.Mui-required::after'
 */
export function RequiredPill() {
    var theme = useTheme();
    var t = useTranslation().t;
    return (_jsx("span", __assign({ style: {
            background: theme.palette.text.label,
            borderRadius: '4px',
            color: theme.palette.text.contrast,
            content: '"required"',
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1,
            padding: '2px 3px',
        } }, { children: t('components.required_pill.label') })));
}
