var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
export function useOpen(initialState) {
    if (initialState === void 0) { initialState = false; }
    var _a = __read(useState(initialState), 2), open = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () {
        setOpen(false);
    }, [setOpen]);
    var handleOpen = useCallback(function () {
        setOpen(true);
    }, [setOpen]);
    var handleToggle = useCallback(function () {
        setOpen(function (state) { return !state; });
    }, [setOpen]);
    return {
        open: open,
        handleClose: handleClose,
        handleOpen: handleOpen,
        handleToggle: handleToggle,
    };
}
export function useQueryParameterOpen(name, valueOnOpen, permissions) {
    if (permissions === void 0) { permissions = true; }
    var search = useLocation().search;
    var navigate = useNavigate();
    var open = useMemo(function () { return new URLSearchParams(search).get(name) === valueOnOpen; }, [name, search, valueOnOpen]);
    useEffect(function () {
        if (open && !permissions) {
            var params = new URLSearchParams(search);
            params.delete(name);
            navigate("?".concat(params.toString()));
        }
    }, [navigate, permissions, search, name, open]);
    var handleClose = useCallback(function () {
        var params = new URLSearchParams(search);
        params.delete(name);
        navigate("?".concat(params.toString()));
    }, [name, navigate, search]);
    var handleOpen = useCallback(function () {
        var params = new URLSearchParams(search);
        params.set(name, valueOnOpen);
        navigate("?".concat(params.toString()));
    }, [name, navigate, search, valueOnOpen]);
    return {
        open: open,
        handleClose: handleClose,
        handleOpen: handleOpen,
    };
}
export function useAnchorOpen(props) {
    var _a = __read(useState(null), 2), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = useMemo(function () { return !!anchorEl; }, [anchorEl]);
    var handleClose = useCallback(function () {
        if (!anchorEl)
            return;
        setAnchorEl(null);
        if (props === null || props === void 0 ? void 0 : props.onClose)
            props.onClose();
    }, [anchorEl, props]);
    var handleOpen = useCallback(function (eventOrTarget) {
        if (anchorEl)
            return;
        var target = eventOrTarget instanceof HTMLElement ? eventOrTarget : eventOrTarget.currentTarget;
        setAnchorEl(target);
        if (props === null || props === void 0 ? void 0 : props.onOpen)
            props.onOpen();
    }, [anchorEl, props]);
    var handleToggle = useCallback(function (eventOrTarget) {
        if (anchorEl) {
            // Toggle close
            setAnchorEl(null);
            if (props === null || props === void 0 ? void 0 : props.onClose)
                props.onClose();
        }
        else {
            // Toggle open
            var target = eventOrTarget instanceof HTMLElement ? eventOrTarget : eventOrTarget.currentTarget;
            setAnchorEl(target);
            if (props === null || props === void 0 ? void 0 : props.onOpen)
                props.onOpen();
        }
    }, [anchorEl, props]);
    return {
        open: open,
        anchorEl: anchorEl,
        handleClose: handleClose,
        handleOpen: handleOpen,
        handleToggle: handleToggle,
    };
}
