var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams, useNavigate } from 'react-router-dom';
import format from 'string-format';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { UserGroupForm } from '@/components/node-tools/config-templates/user-group-form';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useTemplate } from '@/api/template/templates';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { NOT_FOUND_ROUTE } from '@/constants/routes';
import { useAlertNotification } from '@/hooks/alert-notification';
import { transformResponseToFormState } from '@/components/node-tools/config-templates/user-group-form/transformers';
import { PAGE_TITLE_ID } from '@/constants/ids';
var USER_GROUP_TEMPLATE_LOADER_ID = 'user-group-template-form-loading';
export default withAuthenticationRequired(function () {
    var t = useTranslation().t;
    var templateId = useParams().templateId;
    var navigate = useNavigate();
    var alertNotification = useAlertNotification().alertNotification;
    var pageTitle = t('pages.config_templates.tabs.users_groups.pages.update.title');
    useDocTitle(pageTitle);
    var _a = useUIPermissions(), permissions = _a.permissions, hasPermission = _a.hasPermission;
    var hasEditPermission = hasPermission('configure.configuration_templating.users_groups_templates.edit');
    var hasViewPermission = hasPermission('configure.configuration_templating.users_groups_templates.view');
    var notFoundCallback = useCallback(function (responseStatus) {
        if (responseStatus === 404) {
            alertNotification(format(t('api.template.get.not_found.message'), templateId !== null && templateId !== void 0 ? templateId : ''), { persistOnUnmount: true });
        }
        navigate(NOT_FOUND_ROUTE);
    }, [alertNotification, navigate, t, templateId]);
    useLayoutEffect(function () {
        if (!permissions || permissions.is_secondary || permissions.is_lic_expired) {
            navigate(NOT_FOUND_ROUTE);
        }
    }, [navigate, permissions]);
    var _b = useTemplate(templateId || '', { notFoundCallback: notFoundCallback }), data = _b.data, isLoading = _b.isLoading;
    var sourceValues = useMemo(function () { return transformResponseToFormState(data); }, [data]);
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": USER_GROUP_TEMPLATE_LOADER_ID });
    }
    if (!isLoading && sourceValues) {
        innerNode = _jsx(UserGroupForm, { data: sourceValues, permissions: { hasEditPermission: hasEditPermission, hasViewPermission: hasViewPermission }, templateId: templateId });
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2", gutterBottom: true }, { children: pageTitle })), innerNode] })));
});
