var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { OGLink } from '@opengear/links';
import { OGTable } from '@opengear/table';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { OGButton } from '@opengear/buttons';
import { formatEditEnrollmentBundleRoute } from '@/constants/routes';
import { useBundles } from '@/api/bundles/bundles';
import { subscriptionTierMap } from '@/utils/license';
import { useDownloadManifest } from '@/api/system/manifest-link';
export function BundlesTable() {
    var t = useTranslation().t;
    var downloadManifestFile = useDownloadManifest().downloadManifestFile;
    // Get Bundles Fetch
    var _a = useBundles(), data = _a.data, bundlesLoading = _a.isLoading;
    var rows = useMemo(function () { return ({
        rows: (data === null || data === void 0 ? void 0 : data.bundles) || [],
    }); }, [data]).rows;
    // Generate a unique key based on the rows data length and concatenated ids
    var tableKey = useMemo(function () { return rows.map(function (row) { return row.id; }).join(','); }, [rows]);
    var renderName = useCallback(function (_a) {
        var bundle = _a.row, tabIndex = _a.tabIndex;
        return (_jsx(OGLink, __assign({ tabIndex: tabIndex, variant: "standard", to: formatEditEnrollmentBundleRoute(bundle.id) }, { children: bundle.name })));
    }, []);
    var renderSubscriptionTier = useCallback(function (_a) {
        var bundle = _a.row;
        return bundle.tier ? subscriptionTierMap.get(bundle.tier) : t('pages.enrollment_bundles.table.headers.subscription_tier.no_subs');
    }, [t]);
    var renderDownloadCell = useCallback(function (_a) {
        var bundle = _a.row, tabIndex = _a.tabIndex;
        return bundle.manifest_link ? (_jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('pages.enrollment_bundles.table.buttons.download_manifest') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('pages.enrollment_bundles.table.buttons.download_manifest'), size: "small", disabled: !bundle.manifest_link, startIcon: _jsx(FileDownloadIcon, {}), onClick: function () { return bundle.manifest_link && downloadManifestFile(bundle.manifest_link); }, variant: "borderless", tabIndex: tabIndex, propagateSpacebar: false }) }) }))) : null;
    }, [downloadManifestFile, t]);
    // Column grid
    var columns = useMemo(function () { return [
        {
            field: 'name',
            headerName: t('pages.enrollment_bundles.table.headers.name'),
            maxWidth: 1,
            minWidth: 300,
            renderCell: renderName,
        },
        {
            field: 'tier',
            headerName: t('pages.enrollment_bundles.table.headers.subscription_tier.title'),
            flex: 1,
            minWidth: 200,
            renderCell: renderSubscriptionTier,
        },
        {
            field: 'nodes',
            headerName: t('pages.enrollment_bundles.table.headers.node_count'),
            flex: 1,
            minWidth: 200,
            valueGetter: function (_a) {
                var nodes = _a.value;
                return (nodes === null || nodes === void 0 ? void 0 : nodes.length) || 0;
            },
        },
        {
            field: 'manifest_link',
            headerName: '',
            headerClassName: 'FocusDisabled',
            align: 'center',
            maxWidth: 1,
            sortable: false,
            renderCell: renderDownloadCell,
        },
    ]; }, [renderDownloadCell, renderName, renderSubscriptionTier, t]);
    return (_jsx(Box, { children: _jsx(Box, { children: _jsx(OGTable, { id: "enrollment-bundles-table", "aria-label": t('pages.enrollment_bundles.table.label'), autoHeight: true, columns: columns, rows: rows, loading: bundlesLoading, noRowsMessage: t('pages.enrollment_bundles.table.no_rows_message'), sortingMode: "client" }, tableKey) }) }));
}
