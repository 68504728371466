var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { OpengearLogo } from '@opengear/logos';
import { OGThemeProvider } from '@opengear/theme';
import { useNavigate } from 'react-router-dom';
import { OG_WHITE } from '@opengear/theme/colors';
import { LandingBackground } from '@/components/layout/landing-background';
import { ResetPasswordForm } from '@/containers/login/reset-password-form';
import { useDocTitle } from '@/hooks/doc-title';
import { usePasswordPolicy } from '@/api/users/password-policy';
import { useDeleteSession } from '@/api/sessions';
import { DASHBOARD_ROUTE } from '@/constants/routes';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { AppVersion } from '@/containers/menus/navigation-bar/app-version';
function ResetPassword() {
    var navigate = useNavigate();
    var passwordPolicy = usePasswordPolicy().data;
    var permissions = useUIPermissions().permissions;
    var userName = permissions === null || permissions === void 0 ? void 0 : permissions.username;
    var isExpired = permissions === null || permissions === void 0 ? void 0 : permissions.is_expired;
    var signOut = useDeleteSession();
    var t = useTranslation().t;
    useDocTitle(t('pages.password_reset.title'));
    useEffect(function () {
        if (!(permissions === null || permissions === void 0 ? void 0 : permissions.is_expired)) {
            navigate(DASHBOARD_ROUTE);
        }
    }, [permissions, navigate]);
    return (_jsxs(_Fragment, { children: [isExpired && (_jsxs(OGThemeProvider, { children: [_jsx(AppBar, { children: _jsxs(Toolbar, { children: [_jsx(OpengearLogo, {}), _jsx(AppVersion, { hideVersion: true })] }) }), _jsx(Toolbar, {}), _jsx(LandingBackground, { position: "absolute", height: 400, width: "100vw", zIndex: -1 }), _jsxs(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: 18 }, { children: [_jsx(Typography, __assign({ gutterBottom: true, variant: "h1", sx: { color: OG_WHITE } }, { children: t('pages.password_reset.form.title') })), _jsx(Card, __assign({ sx: {
                                    padding: 2,
                                    borderRadius: 0.5,
                                    width: 400,
                                    // Add a little margin on the sides when the screen shrinks to <400 wide
                                    maxWidth: function (theme) { return "calc(100vw - ".concat(theme.spacing(2), ")"); },
                                } }, { children: isExpired && passwordPolicy && _jsx(ResetPasswordForm, { userName: userName, signOut: signOut, passwordPolicy: passwordPolicy }) }))] }))] })), null] }));
}
export default withAuthenticationRequired(ResetPassword, { clearRouteHistory: true });
