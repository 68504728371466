var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { rest } from 'msw';
import dayjs from 'dayjs';
import { formatApiPath } from '@/api/utils';
import { DEFAULT_MOCK_ENTITLEMENTS, ENTITLEMENTS_PATH } from './entitlements.constants';
import { getLighthouseSubscriptions } from './entitlements.hooks';
export function mockGetEntitlements(server, mockEntitlements) {
    var mockResponseBody = {
        entitlements: mockEntitlements,
    };
    server.use(rest.get(formatApiPath(ENTITLEMENTS_PATH), function (_, res, ctx) { return res(ctx.status(200), ctx.json(mockResponseBody)); }));
}
/**
 * Mock handler for test server
 */
export var entitlementsGetHandler = rest.get(formatApiPath(ENTITLEMENTS_PATH), function (req, res, ctx) {
    var mockResponseBody = {
        entitlements: DEFAULT_MOCK_ENTITLEMENTS,
    };
    return res(ctx.status(200), ctx.json(mockResponseBody));
});
export var mockLightHouseSubscriptions = getLighthouseSubscriptions({ entitlements: DEFAULT_MOCK_ENTITLEMENTS });
export var createMockLighthouseSubscription = function (_a) {
    var _b;
    var _c = _a.subscription, subscription = _c === void 0 ? mockLightHouseSubscriptions[0] : _c, id = _a.id, type = _a.type, available = _a.available, expired = _a.expired, evaluation = _a.evaluation;
    var today = new Date().getTime();
    var _id = subscription.id, _features = subscription.features, _details = subscription.details, _expiration = subscription.expiration;
    var subscriptionId = _id;
    var features = _features;
    var details = _details;
    var expiration = _expiration;
    if (id) {
        subscriptionId = id;
    }
    if (type) {
        details = __assign(__assign({}, details), { tier: type });
    }
    if (typeof available === 'number') {
        features = __assign(__assign({}, features), { available_nodes: available, consumed_nodes: features.nodes - available });
    }
    if (evaluation) {
        details = __assign(__assign({}, details), { bucket: evaluation ? 'eval' : (_b = subscription.details) === null || _b === void 0 ? void 0 : _b.bucket });
    }
    if (expired) {
        features = __assign(__assign({}, features), { expired: true });
        expiration = { isExpired: true, daysRemaining: 0, expiryDate: dayjs.unix(today) };
    }
    return __assign(__assign({}, subscription), { id: subscriptionId, features: features, details: details, expiration: expiration });
};
