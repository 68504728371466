var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormContainerContext } from './container-context';
import { PAGE_TITLE_ID } from '@/constants/ids';
export function FormContainer(_a) {
    var children = _a.children, _b = _a.permissions, permissions = _b === void 0 ? {} : _b, methods = _a.methods, onSubmit = _a.onSubmit, mode = _a.mode, _c = _a.ariaLabelledBy, ariaLabelledBy = _c === void 0 ? PAGE_TITLE_ID : _c, ariaLabel = _a.ariaLabel;
    var contextValue = useMemo(function () { return ({
        mode: mode,
        permissions: permissions,
    }); }, [mode, permissions]);
    var ariaProps = ariaLabel ? { 'aria-label': ariaLabel } : { 'aria-labelledby': ariaLabelledBy };
    return (_jsx(Stack, __assign({ component: "form" }, ariaProps, { onSubmit: methods.handleSubmit(onSubmit) }, { children: _jsx(FormProvider, __assign({}, methods, { children: _jsx(FormContainerContext.Provider, __assign({ value: contextValue }, { children: children })) })) })));
}
