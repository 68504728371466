var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import { OGTable } from '@opengear/table';
import Stack from '@mui/material/Stack';
import { useNodes } from '@/api/nodes';
import { NodeFilter, useNodeFilter } from '@/containers/filters/advanced-filters/node-filter';
import { connectionStatusToQuery, NodeConnectionStatus, useJson } from '@/utils/query';
import { IQueryNodeField } from '../../../types/query/types';
import { FreeTextSearch, useFreeTextSearch } from '@/components/free-text-search';
import { usePaginationModel } from '@/hooks/pagination-model';
import { useSortModel } from '@/hooks/sort-model';
import { formatLastOnline, getLastOnlineValue } from '@/containers/nodes/node-table-components';
import { renderAddress } from './node-status-connected-table';
var statusQuery = connectionStatusToQuery(NodeConnectionStatus.Disconnect);
var statusFieldNames = [IQueryNodeField.name, IQueryNodeField.lhvpnAddress, IQueryNodeField.macAddress];
export var NodeStatusDisconnectedTable = memo(function (_a) {
    var permissions = _a.permissions;
    var editableNodeFilters = permissions.editableNodeFilters;
    var t = useTranslation().t;
    // Table controls - Server side available query params
    var _b = __read(usePaginationModel('dashboard_nodes_disconnected'), 3), paginationModel = _b[0], handlePaginationModelChange = _b[1], pageSizeOptions = _b[2];
    var _c = __read(useSortModel('last_updated'), 3), defaultSortModel = _c[0], sortQueryParams = _c[1], handleSortModelChange = _c[2];
    // Node filter
    var _d = useNodeFilter(), nodeFilter = _d.nodeFilter, setNodeFilter = _d.setNodeFilter;
    // Free text search
    var _e = useFreeTextSearch(statusFieldNames), textQueryObject = _e.textQueryObject, dispatchTextQuery = _e.dispatchTextQuery;
    // TODO - CDM-183 - extend logic
    // Aggregate all query items into one json query string
    var json = useJson(statusQuery, nodeFilter === null || nodeFilter === void 0 ? void 0 : nodeFilter.queryObject, textQueryObject);
    var queryParams = useMemo(function () { return (__assign({ page: paginationModel.page + 1, per_page: paginationModel.pageSize, json: json }, sortQueryParams)); }, [json, paginationModel, sortQueryParams]);
    // Get Nodes Fetch
    var _f = useNodes(queryParams), data = _f.data, isLoading = _f.isLoading;
    var _g = useMemo(function () { return ({
        loading: isLoading,
        rowCount: data === null || data === void 0 ? void 0 : data.meta.total_count,
        rows: (data === null || data === void 0 ? void 0 : data.nodes) || [],
    }); }, [data === null || data === void 0 ? void 0 : data.meta.total_count, data === null || data === void 0 ? void 0 : data.nodes, isLoading]), loading = _g.loading, rows = _g.rows, rowCount = _g.rowCount;
    var columns = useMemo(function () { return [
        {
            field: 'state',
            headerName: t('pages.dashboard.nodes.table.headers.state'),
            maxWidth: 1,
            align: 'center',
            sortable: false,
            renderCell: function () { return (_jsx(ErrorIcon, { fontSize: "medium", "aria-hidden": "false", titleAccess: t('components.nodes.state_label.disconnected'), color: "error", sx: { height: 21 } })); },
            minWidth: 55,
        },
        { field: 'name', headerName: t('pages.dashboard.nodes.table.headers.name'), minWidth: 160, flex: 1 },
        { field: 'lhvpn_address', headerName: t('pages.dashboard.nodes.table.headers.lhvpn_address'), sortable: false, minWidth: 120, flex: 1 },
        {
            field: 'address',
            headerName: t('pages.dashboard.nodes.table.headers.address'),
            sortable: false,
            minWidth: 200,
            resizable: true,
            renderCell: renderAddress,
        },
        { field: 'mac_address', headerName: t('pages.dashboard.nodes.table.headers.mac_address'), sortable: false, minWidth: 150, flex: 1 },
        {
            field: 'last_updated',
            headerName: t('pages.dashboard.nodes.table.headers.last_updated'),
            valueGetter: getLastOnlineValue,
            valueFormatter: formatLastOnline,
            flex: 1,
            minWidth: 240,
        },
    ]; }, [t]);
    return (_jsxs(Stack, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 1, marginBottom: 1, justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsx(NodeFilter, { filter: nodeFilter, setFilter: setNodeFilter, permissions: { hasSmartgroupsEditAccess: editableNodeFilters } }), _jsx(FreeTextSearch, { onSearch: dispatchTextQuery, useDebounce: false, translations: { tooltip: t('components.nodes.text_search.tooltip') } })] })), _jsx(Box, { children: _jsx(OGTable, { id: "disconnected-nodes-table", "aria-label": t('pages.dashboard.nodes.table.label.disconnected'), autoHeight: true, columns: columns, rows: rows, loading: loading, noRowsMessage: t('pages.dashboard.nodes.table.no_rows_message.disconnected'), pageSizeOptions: pageSizeOptions, pagination: true, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, rowCount: rowCount, sortingMode: "server", onSortModelChange: handleSortModelChange, initialState: {
                        sorting: {
                            sortModel: defaultSortModel,
                        },
                    } }) })] }));
});
