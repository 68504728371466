var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OGPanel } from '@opengear/panel';
import { SECONDARY_LIGHTHOUSES_ROUTE } from '@/constants/routes';
import { useDeviceView } from '@/hooks/use-device-view';
import { DescriptionField, NetworkAddressField, NetworkPortField, PasswordField, SmfAddressField, SmfCapacityField, SmfCidrField, UsernameField, VpnAddressField, VpnCapacityField, VpnCidrField, } from './secondary-form-controls';
import { CancelButton, FormContainer, FormActions, SubmitButton } from '@/components/layout/forms';
import { useFormSync } from '@/hooks/use-form-sync';
/** * Main Parent Component ** */
export function SecondaryLighthouseForm(_a) {
    var permissions = _a.permissions, defaultValues = _a.defaultValues, lighthouseId = _a.lighthouseId, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var isMobileView = useDeviceView().isMobileView;
    var navigate = useNavigate();
    var formMode = lighthouseId ? 'update' : 'create';
    var methods = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });
    useFormSync(methods.formState.defaultValues, defaultValues, methods.reset, t('pages.secondary_lighthouse.edit.reset_warning.header'));
    // Reset handler to restore form to default values
    var handleCancel = useCallback(function () {
        methods.reset();
        navigate(SECONDARY_LIGHTHOUSES_ROUTE);
    }, [navigate, methods]);
    return (_jsx(OGPanel, { children: _jsxs(FormContainer, __assign({ mode: formMode, methods: methods, permissions: permissions, onSubmit: onSubmit }, { children: [_jsx(Typography, __assign({ component: "h4", gutterBottom: true, variant: "h4", sx: { fontWeight: 700 } }, { children: t('pages.secondary_lighthouse.add.note') })), _jsx(DescriptionField, {}), formMode === 'create' && (_jsxs(Stack, __assign({ direction: "column" }, { children: [_jsx(NetworkAddressField, {}), _jsx(NetworkPortField, {}), _jsx(UsernameField, {}), _jsx(PasswordField, {})] }))), _jsx(Divider, { sx: { marginTop: 2, marginBottom: 2 } }), _jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 500, fontSize: 16 } }, { children: t('pages.secondary_lighthouse.form.fields.vpn_network_range_section.title') })), _jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row' }, { children: [_jsx(VpnAddressField, {}), _jsx(VpnCidrField, {}), _jsx(VpnCapacityField, {})] })), _jsx(Divider, { sx: { marginTop: 2, marginBottom: 2 } }), _jsx(Typography, __assign({ component: "h2", gutterBottom: true, variant: "h2", sx: { fontWeight: 500, fontSize: 16 } }, { children: t('pages.secondary_lighthouse.form.fields.smf_network_range_section.title') })), _jsxs(Stack, __assign({ direction: isMobileView ? 'column' : 'row' }, { children: [_jsx(SmfAddressField, {}), _jsx(SmfCidrField, {}), _jsx(SmfCapacityField, {})] })), _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: handleCancel }), _jsx(SubmitButton, {})] })] })) }));
}
