var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { OGButton } from '@opengear/buttons';
export var OGToastPriorities;
(function (OGToastPriorities) {
    OGToastPriorities["Info"] = "info";
    OGToastPriorities["Error"] = "error";
    OGToastPriorities["Success"] = "success";
    OGToastPriorities["Warning"] = "warning";
})(OGToastPriorities || (OGToastPriorities = {}));
var priorityIcons = new Map([
    [OGToastPriorities.Info, _jsx(InfoIcon, {})],
    [OGToastPriorities.Error, _jsx(ErrorIcon, { color: "error" })],
    [OGToastPriorities.Success, _jsx(ThumbUpIcon, { color: "success" })],
    [OGToastPriorities.Warning, _jsx(WarningIcon, { color: "warning" })],
]);
export var OGToast = forwardRef(function (_a, ref) {
    var children = _a.children, disableTypography = _a.disableTypography, _b = _a.priority, priority = _b === void 0 ? OGToastPriorities.Info : _b, header = _a.header, closeHandler = _a.closeHandler;
    var theme = useTheme();
    var darkMode = theme.palette.mode === 'dark';
    var t = useTranslation().t;
    return (_jsx(SnackbarContent, __assign({ ref: ref, role: "alert" }, { children: _jsxs(Box, __assign({ sx: {
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'background.menu',
                boxShadow: darkMode ? '0px 4px 12px rgba(0, 0, 0, 0.45)' : '0px 8px 24px rgba(53, 62, 68, 0.5)',
                borderRadius: '2px',
                width: {
                    mobile: '100%',
                    tablet: theme.spacing(45),
                },
            } }, { children: [_jsxs(Box, __assign({ sx: {
                        display: 'flex',
                    } }, { children: [_jsx(Box, __assign({ sx: {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingLeft: theme.spacing(2),
                                paddingRight: theme.spacing(1.5),
                                height: theme.spacing(8),
                            } }, { children: priorityIcons.get(priority) })), _jsx(Box, __assign({ sx: {
                                display: 'flex',
                                alignItems: 'center',
                                paddingY: theme.spacing(2),
                            } }, { children: _jsxs(Box, { children: [header && (_jsx(Typography, __assign({ component: "p", variant: "h3", sx: {
                                            color: 'text.primary',
                                            marginBottom: theme.spacing(1),
                                            overflowWrap: 'anywhere',
                                            textTransform: 'none',
                                            whiteSpace: 'pre-line',
                                        } }, { children: header }))), disableTypography ? (children) : (_jsx(Typography, __assign({ component: "p", variant: "body1", sx: { overflowWrap: 'anywhere', whiteSpace: 'pre-line' } }, { children: children })))] }) }))] })), _jsx(Box, { children: _jsx(OGButton, { "aria-label": t('global.buttons.close'), onClick: closeHandler, size: "small", startIcon: _jsx(CloseIcon, {}), sx: { margin: 0.5 }, variant: "borderless" }) })] })) })));
});
