export var LICENSES_PATH = '/system/licenses';
export var LICENSES_FILE_PATH = '/system/licenses/file';
export var DEFAULT_MOCK_LICENSES = [
    {
        id: 1,
        raw: 'randomkey1',
    },
    {
        id: 2,
        raw: 'randomkey2',
    },
    {
        id: 3,
        raw: 'randomkey3',
    },
];
