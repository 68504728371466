/** autogenerate file, do not edit, regenerate with `make generate_query_types` */
import { validateQuery, validateFilterQuery } from './validator';
import { tryParse } from '../utils';
/**
 * Decodes query string or query object
 */
export function decodeQuery(value) {
    try {
        var jsonValue = tryParse(value);
        return validateQuery(jsonValue) ? jsonValue : null;
    }
    catch (err) {
        return null;
    }
}
/**
 * Decodes filter query string or filter query object
 */
export function decodeFilterQuery(value) {
    try {
        var jsonValue = tryParse(value);
        return validateFilterQuery(jsonValue) ? jsonValue : null;
    }
    catch (err) {
        return null;
    }
}
