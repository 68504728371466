import merge from 'lodash/merge';
export var mockNoPermissions = {
    actions: {
        message_bus_actions: 'deny',
        subscriptions: 'deny',
    },
    cli: {
        console_shell: 'deny',
        pm_shell: 'off',
        shell_access: 'off',
    },
    logging: {
        logs_portlog: 'deny',
        logs_syslog: 'deny',
    },
    netops: {
        netops_modules: 'deny',
    },
    nodes_and_config: {
        nodes_devices_base: 'deny',
        nodes_devices_advanced: 'deny',
        nodes_firmware: 'deny',
        push_templates: 'deny',
    },
    service_settings: {
        lhvpn: 'deny',
        services_cellhealth: 'deny',
        services_console_gateway: 'deny',
        services_date_and_time: 'deny',
        services_https: 'deny',
        services_netops: 'deny',
        services_node_backup: 'deny',
        services_session_settings: 'deny',
        services_snmp: 'deny',
        services_ssh: 'deny',
        services_syslog: 'deny',
    },
    smartgroups_and_tags: {
        bundles: 'deny',
        smart_groups: 'deny',
        tags: 'deny',
    },
    system: {
        admin_and_licensing: 'deny',
        backup_and_restore: 'deny',
        jobs: 'deny',
        multi_instance: 'deny',
        network_interfaces: 'deny',
        upgrade_and_reset: 'deny',
    },
    users_and_permissions: {
        authentication: 'deny',
        groups_and_roles: 'deny',
        users: 'deny',
    },
    advanced_features: {
        services_smf: 'deny',
        connected_resource_gateway: 'deny',
    },
};
var mockLighthouseAdminPermissions = {
    actions: {
        message_bus_actions: 'read_write',
        subscriptions: 'read_write',
    },
    cli: {
        console_shell: 'read_write',
        pm_shell: 'on',
        shell_access: 'on',
    },
    logging: {
        logs_portlog: 'read_write',
        logs_syslog: 'read_write',
    },
    netops: {
        netops_modules: 'read_write',
    },
    nodes_and_config: {
        nodes_devices_base: 'read_write',
        nodes_devices_advanced: 'read_write',
        nodes_firmware: 'read_write',
        push_templates: 'read_write',
    },
    service_settings: {
        lhvpn: 'read_write',
        services_cellhealth: 'read_write',
        services_console_gateway: 'read_write',
        services_date_and_time: 'read_write',
        services_https: 'read_write',
        services_netops: 'read_write',
        services_node_backup: 'read_write',
        services_session_settings: 'read_write',
        services_snmp: 'read_write',
        services_ssh: 'read_write',
        services_syslog: 'read_write',
    },
    smartgroups_and_tags: {
        bundles: 'read_write',
        smart_groups: 'read_write',
        tags: 'read_write',
    },
    system: {
        admin_and_licensing: 'read_write',
        backup_and_restore: 'read_write',
        jobs: 'read_write',
        multi_instance: 'read_write',
        network_interfaces: 'read_write',
        upgrade_and_reset: 'read_write',
    },
    users_and_permissions: {
        authentication: 'read_write',
        groups_and_roles: 'read_write',
        users: 'read_write',
    },
    advanced_features: {
        services_smf: 'read_write',
        connected_resource_gateway: 'read_write',
    },
};
var mockNodeAdminPermissions = merge({}, mockNoPermissions, {
    cli: {
        console_shell: 'read_only',
        pm_shell: 'on',
    },
    netops: {
        netops_modules: 'read_only',
    },
    nodes_and_config: {
        nodes_devices_base: 'read_only',
        nodes_devices_advanced: 'read_only',
        nodes_firmware: 'read_only',
        push_templates: 'read_only',
    },
    service_settings: {
        services_cellhealth: 'read_only',
    },
    smartgroups_and_tags: {
        smart_groups: 'read_only',
        tags: 'read_only',
    },
    system: {
        jobs: 'read_only',
    },
});
var mockNodeUserPermissions = merge({}, mockNoPermissions, {
    cli: {
        pm_shell: 'on',
    },
    nodes_and_config: {
        nodes_devices_base: 'read_only',
    },
    service_settings: {
        services_cellhealth: 'read_only',
    },
    smartgroups_and_tags: {
        tags: 'read_only',
    },
});
var mockReporterPermissions = merge({}, mockNoPermissions, {
    actions: {
        message_bus_actions: 'read_only',
        subscriptions: 'read_only',
    },
    logging: {
        logs_portlog: 'read_only',
        logs_syslog: 'read_only',
    },
    netops: {
        netops_modules: 'read_only',
    },
    nodes_and_config: {
        nodes_devices_base: 'read_only',
        nodes_devices_advanced: 'read_only',
        nodes_firmware: 'read_only',
        push_templates: 'read_only',
    },
    service_settings: {
        lhvpn: 'read_only',
        services_cellhealth: 'read_only',
        services_console_gateway: 'read_only',
        services_date_and_time: 'read_only',
        services_https: 'read_only',
        services_netops: 'read_only',
        services_node_backup: 'read_only',
        services_smf: 'read_only',
        services_session_settings: 'read_only',
        services_snmp: 'read_only',
        services_ssh: 'read_only',
        services_syslog: 'read_only',
    },
    smartgroups_and_tags: {
        bundles: 'read_only',
        smart_groups: 'read_only',
        tags: 'read_only',
    },
    system: {
        admin_and_licensing: 'read_only',
        backup_and_restore: 'read_only',
        jobs: 'read_only',
        multi_instance: 'read_only',
        network_interfaces: 'read_only',
        upgrade_and_reset: 'read_only',
    },
    users_and_permissions: {
        authentication: 'read_only',
        groups_and_roles: 'read_only',
        users: 'read_only',
    },
});
export var mockPermissionsMap = {
    LighthouseAdmin: mockLighthouseAdminPermissions,
    NodeAdmin: mockNodeAdminPermissions,
    NodeUser: mockNodeUserPermissions,
    Reporter: mockReporterPermissions,
};
export var mockGroupsPermissionsMap = {
    netgrp: { LighthouseAdmin: mockLighthouseAdminPermissions },
    admin: { LighthouseAdmin: mockLighthouseAdminPermissions },
};
