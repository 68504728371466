export function formatGroupId(number) {
    return "groups-".concat(number);
}
export function formatRoleId(number) {
    return "roles-".concat(number);
}
export function formatNodeSmartgroupId(number) {
    return "smart_groups_nodes_groups-".concat(number);
}
export function formatPortSmartgroupId(number) {
    return "smart_groups_ports_groups-".concat(number);
}
export function formatSmfResourceSmartgroupId(number) {
    return "smart_groups_resources_groups-".concat(number);
}
export function formatNodeTagId(number) {
    return "tags_node_tags-".concat(number);
}
export function formatSmfResourceId(number) {
    return "smf-resources-".concat(number);
}
export function formatResourceTagId(number) {
    return "resource-tag-keys-".concat(number);
}
export function formatNodePortId(number) {
    return "nodes_ports-".concat(number);
}
export function formatNodeTagValueId(number) {
    return "tags_node_tags_values-".concat(number);
}
export function formatUserId(number) {
    return "users-".concat(number);
}
export function formatTemplateId(number) {
    return "templates-".concat(number);
}
export function formatSyslogServerId(number) {
    return "services_syslog_servers-".concat(number);
}
export function formatSecondaryLighthouseId(number) {
    return "lighthouse_configurations-".concat(number);
}
export function formatBundleId(number) {
    return "enrollment_bundles-".concat(number);
}
export function formatBundleAutomaticTagId(number) {
    return "enrollment_bundles_automatic_tags-".concat(number);
}
export function formatNodeFirmwareId(number) {
    return "node-firmware-".concat(number);
}
export function formatInterfaceId(number) {
    return "system_net_conns-".concat(number);
}
