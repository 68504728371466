import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { FilterReadonlyQuery } from '../../filter-query';
import { InvalidQueryContent } from '../filter-components';
import { PortFilterQuery } from './port-filter-query';
import { usePortFilterContext } from './port-filter-context';
import { isPortFilterNew, isPortQueryValid } from './port-filter-utils';
export function PortFilterQueryContent() {
    var _a = usePortFilterContext(), selectedFilter = _a.selectedFilter, dispatchSelectedFilter = _a.dispatchSelectedFilter;
    var handleNewQueryChange = useCallback(function (query) {
        dispatchSelectedFilter({ type: 'updateQuery', payload: query });
    }, [dispatchSelectedFilter]);
    if (!selectedFilter)
        return null;
    if (isPortFilterNew(selectedFilter)) {
        return _jsx(PortFilterQuery, { query: selectedFilter.queryObject, onChange: handleNewQueryChange });
    }
    if (isPortQueryValid(selectedFilter)) {
        return _jsx(FilterReadonlyQuery, { query: selectedFilter.queryObject });
    }
    return _jsx(InvalidQueryContent, { query: selectedFilter.query });
}
