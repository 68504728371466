var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { requiredFieldError } from '@/utils/field-validation-errors';
import { allQueryOperators } from './constants';
import { getFormattedQueryOperator, getOperators } from './query-operator-utils';
export function QueryOperator(_a) {
    var fieldname = _a.fieldname, oper = _a.oper, onChange = _a.onChange;
    var t = useTranslation().t;
    var _b = __read(useState(null), 2), errorMessage = _b[0], setErrorMessage = _b[1];
    var handleBlur = useCallback(function () {
        if (!oper) {
            setErrorMessage(requiredFieldError(t('components.query.oper.label')));
        }
    }, [oper, t]);
    var handleChange = useCallback(function (event) {
        var value = Number.parseInt(event.target.value, 10);
        if (allQueryOperators.includes(value)) {
            setErrorMessage(null);
            onChange({ oper: value });
        }
    }, [onChange]);
    var renderValue = useCallback(function (value) {
        if (typeof value !== 'number') {
            return _jsx("em", { children: t('components.query.oper.label') });
        }
        return getFormattedQueryOperator(value);
    }, [t]);
    var selectProps = useMemo(function () { return ({
        MenuProps: {
            MenuListProps: { 'aria-label': t('components.query.oper.options_label') },
        },
        SelectDisplayProps: {
            'aria-invalid': !!errorMessage,
        },
        displayEmpty: true,
        renderValue: renderValue,
    }); }, [errorMessage, renderValue, t]);
    var operators = useMemo(function () { return (fieldname ? getOperators(fieldname) : []); }, [fieldname]);
    // There is a race condition here where an updated list of operators is sent to the component
    // before the current operator value is cleared. This is a workaround to prevent MUI logging
    // a warning about the value not being found in the current list of operators.
    var value = useMemo(function () { return (!oper || !operators.includes(oper) ? '' : oper); }, [oper, operators]);
    var nodes = useMemo(function () {
        return operators.map(function (operation) { return (_jsx(MenuItem, __assign({ value: operation }, { children: getFormattedQueryOperator(operation) }), operation)); });
    }, [operators]);
    // Clear the current operator if the fieldname changes and it is no longer a valid option
    useEffect(function () {
        if (!oper || !operators.includes(oper)) {
            onChange({ oper: undefined });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldname]);
    return (_jsx(TextField, __assign({ inputProps: {
            'aria-label': t('components.query.oper.label'),
        }, SelectProps: selectProps, disabled: !operators.length, error: !!errorMessage, helperText: errorMessage, margin: "none", placeholder: t('components.query.oper.label'), select: true, value: value, onBlur: handleBlur, onChange: handleChange, sx: { flexGrow: 1 } }, { children: nodes })));
}
