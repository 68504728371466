var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { OGButton } from '@opengear/buttons';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import ClearIcon from '@mui/icons-material/Clear';
import StopIcon from '@mui/icons-material/DoNotDisturbAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { OGExpirePasswordIcon } from '@opengear/icons/action/expire-password';
import { useTheme } from '@mui/material/styles';
import { OG_BLACK_80, OG_GREY_VVVL } from '@opengear/theme/colors';
export var testId = 'bulk-users-operation-section';
export function UsersBulkOperations(_a) {
    var userIds = _a.userIds, totalUsers = _a.totalUsers, setRowSelectionModel = _a.setRowSelectionModel, setUserAction = _a.setUserAction, setUserIdToAction = _a.setUserIdToAction;
    var theme = useTheme();
    var t = useTranslation().t;
    var handleDelete = useCallback(function (ids) {
        setUserAction('delete');
        setUserIdToAction(ids);
    }, [setUserAction, setUserIdToAction]);
    var handleDisable = useCallback(function (ids) {
        setUserAction('disable');
        setUserIdToAction(ids);
    }, [setUserAction, setUserIdToAction]);
    var handleExpire = useCallback(function (ids) {
        setUserAction('expire');
        setUserIdToAction(ids);
    }, [setUserAction, setUserIdToAction]);
    return (_jsx(Collapse, __assign({ in: !!userIds.length, unmountOnExit: true, timeout: 200, "data-testid": testId, sx: { padding: 1, paddingLeft: 2, backgroundColor: theme.palette.mode === 'dark' ? OG_BLACK_80 : OG_GREY_VVVL } }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsxs(Stack, __assign({ gap: 2, direction: "row", justifyContent: "flex-start", flexWrap: "wrap" }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: 400, display: 'flex', alignItems: 'center' } }, { children: format(t('components.bulk_operations.number_of_selected'), userIds.length.toString(), totalUsers.toString()) })), _jsx(OGButton, __assign({ startIcon: _jsx(DeleteIcon, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return handleDelete(userIds); } }, { children: t('pages.local_users.table.buttons.bulk_delete_users') })), _jsx(OGButton, __assign({ startIcon: _jsx(StopIcon, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return handleDisable(userIds); } }, { children: t('pages.local_users.table.buttons.bulk_disable_users') })), _jsx(OGButton, __assign({ startIcon: _jsx(OGExpirePasswordIcon, {}), variant: "borderless", sx: { width: 'max-content' }, onClick: function () { return handleExpire(userIds); } }, { children: t('pages.local_users.table.buttons.bulk_expire_passwords') }))] })), _jsx(Tooltip, __assign({ arrow: true, placement: "top", title: t('global.buttons.close') }, { children: _jsx("div", { children: _jsx(OGButton, { "aria-label": t('global.buttons.close'), disabled: !userIds.length, size: "small", startIcon: _jsx(ClearIcon, {}), variant: "borderless", onClick: function () {
                                setRowSelectionModel([]);
                            } }) }) }))] })) })));
}
