var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { OGLinkButton } from '@opengear/links';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { FIRMWARE_UPGRADE_ROUTE, FIRMWARE_UPGRADE_TASKS_ROUTE_KEY, FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY, NEW_FIRMWARE_UPGRADE_ROUTE, } from '@/constants/routes';
import { FirmwareUpgradeTable } from '@/components/node-tools/firmware-upgrade/upgrade-tasks';
import { FileManagerTab } from '@/components/node-tools/firmware-upgrade/file-manager';
import { PAGE_TITLE_ID } from '@/constants/ids';
function FirmwareUpgrade() {
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useDocTitle(t('pages.firmware_upgrade.title'));
    var hasEditAccess = hasPermission('settings.services.node_upgrade.edit');
    var tabValue = useMemo(function () { return (pathname.endsWith(FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY) ? FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY : FIRMWARE_UPGRADE_TASKS_ROUTE_KEY); }, [pathname]);
    useEffect(function () {
        if (pathname.endsWith(FIRMWARE_UPGRADE_ROUTE)) {
            navigate(FIRMWARE_UPGRADE_TASKS_ROUTE_KEY, { replace: true });
        }
    }, [pathname, navigate]);
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }, { children: [_jsx(Typography, __assign({ id: PAGE_TITLE_ID, component: "h2", variant: "h2" }, { children: t('pages.firmware_upgrade.title') })), _jsx(OGLinkButton, __assign({ variant: "primary", startIcon: _jsx(AddCircleOutline, {}), to: NEW_FIRMWARE_UPGRADE_ROUTE, disabled: !hasEditAccess }, { children: t('pages.firmware_upgrade.buttons.schedule_upgrade') }))] })), _jsx(Paper, { children: _jsxs(TabContext, __assign({ value: tabValue }, { children: [_jsxs(TabList, __assign({ orientation: "horizontal", scrollButtons: true, variant: "scrollable" }, { children: [_jsx(Tab, { component: RouterLink, to: FIRMWARE_UPGRADE_TASKS_ROUTE_KEY, label: t('pages.firmware_upgrade.tabs.tasks.label'), value: FIRMWARE_UPGRADE_TASKS_ROUTE_KEY }), _jsx(Tab, { component: RouterLink, to: FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY, label: t('pages.firmware_upgrade.tabs.file_manager.label'), value: FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY })] })), _jsx(TabPanel, __assign({ value: FIRMWARE_UPGRADE_TASKS_ROUTE_KEY }, { children: _jsx(FirmwareUpgradeTable, {}) })), _jsx(TabPanel, __assign({ value: FIRMWARE_UPGRADE_FILE_MANAGER_ROUTE_KEY }, { children: _jsx(FileManagerTab, { hasEditAccess: hasEditAccess }) }))] })) })] })));
}
export default withAuthenticationRequired(FirmwareUpgrade, {
    rights: ['settings.services.node_upgrade.view'],
});
