var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { OGThemeMode, useThemeMode } from '@opengear/theme';
import { OGProfileIcon } from '@opengear/icons/navigation/profile';
import { OGNavMenuItem, OGNavPopupMenuHeader } from '@opengear/navigation';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { useDeleteSession } from '@/api/sessions';
export function UserMenu() {
    var _this = this;
    var _a;
    var _b = __read(useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = __read(useThemeMode(), 2), themeMode = _c[0], setThemeMode = _c[1];
    var signOut = useDeleteSession();
    var open = useMemo(function () { return !!anchorEl; }, [anchorEl]);
    var permissions = useUIPermissions().permissions;
    var t = useTranslation().t;
    var handleClick = useCallback(function (_a) {
        var currentTarget = _a.currentTarget;
        setAnchorEl(function (state) { return (state ? null : currentTarget); });
    }, [setAnchorEl]);
    var handleClose = useCallback(function () {
        setAnchorEl(null);
    }, [setAnchorEl]);
    var handleSignOut = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signOut()];
                case 1:
                    _a.sent();
                    setAnchorEl(null);
                    return [2 /*return*/];
            }
        });
    }); }, [setAnchorEl, signOut]);
    var handleThemeModeChange = useCallback(function (event, value) {
        if (value !== null)
            setThemeMode(value);
    }, [setThemeMode]);
    return (_jsxs(_Fragment, { children: [_jsx(ToggleButton, __assign({ "aria-expanded": open, "aria-haspopup": true, "aria-label": t('navigation.user_menu.label'), selected: open, sx: { marginX: 0.5, marginBottom: 0.75, paddingX: 1 }, value: "checked", onChange: handleClick }, { children: _jsx(OGProfileIcon, {}) })), _jsx(Popover, __assign({ open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, onClose: handleClose }, { children: _jsxs(Box, __assign({ sx: { paddingY: 1 } }, { children: [_jsx(OGNavPopupMenuHeader, { children: (_a = permissions === null || permissions === void 0 ? void 0 : permissions.username) !== null && _a !== void 0 ? _a : 'USER' }), _jsxs(FormControl, __assign({ margin: "none", sx: { paddingX: 2, paddingY: 1 } }, { children: [_jsx(FormLabel, { children: t('navigation.user_menu.theme.label') }), _jsxs(ToggleButtonGroup, __assign({ "aria-label": t('navigation.user_menu.theme.label'), exclusive: true, sx: { '& .MuiToggleButton-root': { paddingX: 1.75 } }, value: themeMode, onChange: handleThemeModeChange }, { children: [_jsx(ToggleButton, __assign({ value: OGThemeMode.Light }, { children: t('navigation.user_menu.theme.light_label') })), _jsx(ToggleButton, __assign({ value: OGThemeMode.System }, { children: t('navigation.user_menu.theme.system_label') })), _jsx(ToggleButton, __assign({ value: OGThemeMode.Dark }, { children: t('navigation.user_menu.theme.dark_label') }))] }))] })), _jsx(Divider, {}), _jsx(MenuList, __assign({ sx: { padding: 0 } }, { children: _jsx(OGNavMenuItem, __assign({ tabIndex: 0, onClick: handleSignOut }, { children: t('navigation.user_menu.sign_out') })) }))] })) }))] }));
}
