var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { decodeReadSystemManifestLinkResponse } from '../../../types/lighthouse/decoders/system/manifest-link';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
import { triggerDownload } from '../utils';
export var MANIFEST_LINK_PATH = '/system/manifest_link';
export function useManifestLink(_a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a, enabled = _b.enabled;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var t = useTranslation().t;
    var fetchManifestLink = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(MANIFEST_LINK_PATH, __assign({}, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadSystemManifestLinkResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.manifest_link.get'));
                    return [2 /*return*/, status === 200 ? data : null];
            }
        });
    }); }, [executeFetch, decodeResponse, handleCommonStatusCodes, t]);
    return useQuery({
        queryKey: [MANIFEST_LINK_PATH],
        queryFn: fetchManifestLink,
        enabled: enabled,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
    });
}
export function useDownloadManifest() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var t = useTranslation().t;
    var downloadManifestFile = function (manifestUrl) { return __awaiter(_this, void 0, void 0, function () {
        var signal, response, blob, contentDisposition, filename, matches, _a, extractedFilename;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    signal = new AbortController().signal;
                    return [4 /*yield*/, executeFetch(manifestUrl, {
                            headers: new Headers({
                                'Accept': 'text/plain',
                                'Content-Type': 'text/plain',
                            }),
                            signal: signal,
                            fullyQualifiedPath: true,
                        })];
                case 1:
                    response = _b.sent();
                    handleCommonStatusCodes(null, response.status, t('api.manifest_download.get'));
                    if (!(response.status === 200)) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.blob()];
                case 2:
                    blob = _b.sent();
                    contentDisposition = response.headers.get('Content-Disposition');
                    filename = 'manifest.og';
                    if (contentDisposition) {
                        matches = contentDisposition.match(/filename="?(.+)"?/);
                        if (matches) {
                            _a = __read(matches, 2), extractedFilename = _a[1];
                            filename = extractedFilename;
                        }
                    }
                    triggerDownload(blob, filename);
                    return [2 /*return*/, true];
                case 3: return [2 /*return*/, false];
            }
        });
    }); };
    return { downloadManifestFile: downloadManifestFile };
}
