var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { useEnrollmentToken } from '@/api/system/enrollment-token';
import { useDefaultSubscription } from '@/api/system/default-subscription';
import { useEntitlementsData } from '@/api/system/entitlements';
import { EnrollmentSettingsForm } from '@/components/config/enrollment-settings-form';
import { useManifestLink } from '@/api/system/manifest-link';
export var ENROLLMENT_SETTINGS_LOADER_ID = 'enrollment-settings-loader';
function EnrollmentSettings() {
    var t = useTranslation().t;
    useDocTitle(t('pages.enrollment_settings.title'));
    var hasPermission = useUIPermissions().hasPermission;
    var _a = useEnrollmentToken(), enrollmentTokenData = _a.data, isLoadingEnrollmentToken = _a.isLoading, isFetchingEnrollmentToken = _a.isFetching;
    var _b = useDefaultSubscription(), defaultSubscriptionData = _b.data, isLoadingDefaultSubscription = _b.isLoading, isFetchingDefaultSubscription = _b.isFetching;
    var _c = useEntitlementsData(), lighthouseSubscriptions = _c.lighthouseSubscriptions, isEntitlementsLoading = _c.isLoading, isFetchingEntitlements = _c.isFetching;
    var _d = useManifestLink({
        enabled: hasPermission('configure.node_enrollment.enrolled_nodes.view'),
    }), manifestLink = _d.data, isLoadingManifestLink = _d.isLoading;
    var content = null;
    if (isLoadingEnrollmentToken || isLoadingDefaultSubscription || isEntitlementsLoading || isLoadingManifestLink) {
        content = _jsx(LoadingBackdrop, { "data-testid": ENROLLMENT_SETTINGS_LOADER_ID });
    }
    else if (!!enrollmentTokenData && !!defaultSubscriptionData) {
        content = (_jsx(EnrollmentSettingsForm, { editable: hasPermission('configure.node_enrollment.enrollment_settings.edit'), enrollmentToken: enrollmentTokenData.system_global_enrollment_token.token, defaultSubscription: defaultSubscriptionData.enrollment_default_subscription.subscription_type, isFetchingData: isFetchingEnrollmentToken || isFetchingDefaultSubscription || isFetchingEntitlements, manifestLink: manifestLink === null || manifestLink === void 0 ? void 0 : manifestLink.system_global_manifest_link.url, subscriptions: lighthouseSubscriptions }));
    }
    return (_jsxs(Box, __assign({ margin: 2 }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h2", gutterBottom: true }, { children: t('pages.enrollment_settings.title') })), content] })));
}
export default withAuthenticationRequired(EnrollmentSettings, { rights: ['configure.node_enrollment.enrollment_settings.view'] });
