var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { OGPanel } from '@opengear/panel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { PAGE_TITLE_ID } from '@/constants/ids';
import { GROUPS_ROLES_ROUTE, ROLES_ROUTE } from '@/constants/routes';
import { RoleNameField, DescriptionField, ConsoleShellField, ShellAccessField, PMShellField } from '@/components/accounts/roles/role-form-controls';
import { RolePermissionsTable } from '@/components/accounts/roles/role-permissions-table';
import { RoleFormActions } from '@/components/accounts/roles/role-form-actions';
import { defaultRolePermissions, transformRoleApiData, transformRoleFormData } from '@/components/accounts/roles/role-form-utils';
import { RolePermissionWarningsProvider } from '@/components/accounts/roles/role-form-context';
import { useFormSync } from '@/hooks/use-form-sync';
// useForm deep partials the generic type that you pass the hook.
// Defining the defaultValues as a variable ensures required fields exists
var defaultValues = {
    rolename: '',
    description: '',
    permissions: defaultRolePermissions,
};
// Since auto-refresh is enabled for entities, we memoize the RoleForm for performance
export var RoleForm = memo(function (_a) {
    var entities = _a.entities, role = _a.role, submitHandler = _a.submitHandler;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var sourceValues = useMemo(function () { return (role ? transformRoleApiData(role) : defaultValues); }, [role]);
    // Form state
    var methods = useForm({ mode: 'onTouched', defaultValues: sourceValues });
    // Alert the user of data changes
    useFormSync(methods.formState.defaultValues, sourceValues, methods.reset, t('components.role.form.reset_warning.header'));
    // Submit handler to add new role
    var onSubmit = useCallback(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var requestData, newOrUpdatedRole;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestData = transformRoleFormData(formData);
                    return [4 /*yield*/, submitHandler(requestData)
                        // If successful, reset form and navigate to roles page
                    ];
                case 1:
                    newOrUpdatedRole = _a.sent();
                    // If successful, reset form and navigate to roles page
                    if (newOrUpdatedRole) {
                        methods.reset();
                        navigate(GROUPS_ROLES_ROUTE + ROLES_ROUTE);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [submitHandler, methods, navigate]);
    return (_jsx(OGPanel, __assign({ sx: { padding: 0 } }, { children: _jsx(FormProvider, __assign({}, methods, { children: _jsx(RolePermissionWarningsProvider, { children: _jsxs(Stack, __assign({ component: "form", "aria-labelledby": PAGE_TITLE_ID, onSubmit: methods.handleSubmit(onSubmit) }, { children: [_jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(RoleNameField, { readOnly: !!sourceValues.rolename }), _jsx(DescriptionField, {})] })), _jsx(Divider, {}), _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('components.permissions.form.title') })), _jsx(ConsoleShellField, {}), _jsx(ShellAccessField, {}), _jsx(PMShellField, {})] })), _jsx(Divider, {}), _jsxs(Stack, __assign({ padding: 2 }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('components.permissions.table.title') })), _jsx(RolePermissionsTable, { entities: entities })] })), _jsx(Stack, __assign({ padding: 2 }, { children: _jsx(RoleFormActions, {}) }))] })) }) })) })));
});
