var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { OGDialog } from '@opengear/modals';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import format from 'string-format';
import { OGButton } from '@opengear/buttons';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useQueryParameterOpen } from '@/hooks/open';
import { useCreateSyslogServer, useSyslogServer, useUpdateSyslogServer } from '@/api/services/syslog';
import { SyslogForm } from './syslog-form';
import { useUIPermissions } from '@/api/sessions/self/ui';
import { QUERY_PARAM_MODAL_NAME_KEY } from '@/constants/query-params-keys';
import { LoadingOverlay } from '@/components/layout/loading-overlay';
export var SYSLOG_MODAL_LOADER_ID = 'syslog-modal-loader';
export var NEW_SYSLOG_MODAL_TITLE_ID = 'new-syslog-server-modal-title';
export var EDIT_SYSLOG_MODAL_TITLE_ID = 'edit-syslog-server-modal-title';
export var NEW_SYSLOG_SERVER_MODAL_VALUE = 'new_syslog_server';
export function NewSyslogModal() {
    var _this = this;
    var t = useTranslation().t;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.services.syslog_servers.edit');
    var createSyslogServer = useCreateSyslogServer();
    var _a = useQueryParameterOpen(QUERY_PARAM_MODAL_NAME_KEY, NEW_SYSLOG_SERVER_MODAL_VALUE), open = _a.open, handleClose = _a.handleClose, handleOpen = _a.handleOpen;
    var handleCreate = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var newSyslogServer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createSyslogServer(formData)];
                case 1:
                    newSyslogServer = _a.sent();
                    if (newSyslogServer) {
                        handleClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [createSyslogServer, handleClose]);
    return (_jsxs(_Fragment, { children: [_jsx(OGButton, __assign({ disabled: !hasEditPermission, startIcon: _jsx(AddCircleOutline, {}), onClick: handleOpen }, { children: t('pages.alerts_and_monitoring.tabs.syslog.button.new_syslog_server') })), _jsxs(OGDialog, __assign({ open: open, onClose: handleClose, maxWidth: "tablet", "aria-labelledby": NEW_SYSLOG_MODAL_TITLE_ID }, { children: [_jsx(DialogTitle, __assign({ id: NEW_SYSLOG_MODAL_TITLE_ID, display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }, { children: t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.new_syslog_server.title') })), _jsx(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' } }, { children: _jsx(SyslogForm, { ariaLabelledBy: NEW_SYSLOG_MODAL_TITLE_ID, onClose: handleClose, onSubmit: handleCreate, permissions: { hasEditPermission: hasEditPermission } }) }))] }))] }));
}
export function SyslogEditModal(_a) {
    var _this = this;
    var open = _a.open, handleClose = _a.handleClose, syslogId = _a.syslogId;
    var _b = useSyslogServer(syslogId), data = _b.data, isLoading = _b.isLoading;
    var hasPermission = useUIPermissions().hasPermission;
    var hasEditPermission = hasPermission('settings.services.syslog_servers.edit');
    var t = useTranslation().t;
    var updateSyslogServer = useUpdateSyslogServer();
    var handleUpdate = useCallback(function (formData) { return __awaiter(_this, void 0, void 0, function () {
        var updatedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!formData.id) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, updateSyslogServer(__assign(__assign({}, formData), { id: formData.id }))];
                case 1:
                    updatedData = _a.sent();
                    if (updatedData) {
                        handleClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [updateSyslogServer, handleClose]);
    if (isLoading)
        return _jsx(LoadingOverlay, { "data-testid": SYSLOG_MODAL_LOADER_ID });
    if (!data) {
        return null;
    }
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose, "aria-labelledby": EDIT_SYSLOG_MODAL_TITLE_ID }, { children: [_jsx(DialogTitle, __assign({ id: EDIT_SYSLOG_MODAL_TITLE_ID, display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }, { children: format(t('pages.alerts_and_monitoring.tabs.syslog.modal.modal_form.update.title'), data.id) })), _jsx(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column', overflowY: 'unset' } }, { children: data && (_jsx(SyslogForm, { ariaLabelledBy: EDIT_SYSLOG_MODAL_TITLE_ID, onClose: handleClose, data: data, onSubmit: handleUpdate, permissions: { hasEditPermission: hasEditPermission } })) }))] })));
}
