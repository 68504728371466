var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { OGButton } from '@opengear/buttons';
import { OGNavPopupMenuHeader, OGNavPopupMenuLink } from '@opengear/navigation';
import { useAnchorOpen } from '@/hooks/open';
import { formatSearchParams } from '@/api/utils';
var NEW_TAG_MENU_ID = 'new-tag-menu';
var NEW_TAG_MENU_HEADING = 'new-tag-menu-heading';
export var OPEN_TAG_MODAL_NODE_QUERY_PARAM = { modal: 'new-node-tag-modal' };
export var OPEN_TAG_MODAL_RESOURCE_QUERY_PARAM = { modal: 'new-resource-tag-modal' };
export function NewTagMenu(_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var _c = useAnchorOpen(), anchorEl = _c.anchorEl, open = _c.open, handleOpen = _c.handleOpen, handleClose = _c.handleClose;
    var _d = useLocation(), search = _d.search, pathname = _d.pathname;
    var currentQueryParams = Object.fromEntries(new URLSearchParams(search));
    return (_jsxs(_Fragment, { children: [_jsx(OGButton, __assign({ "aria-controls": NEW_TAG_MENU_ID, "aria-expanded": open, "aria-haspopup": true, disabled: disabled, onClick: handleOpen, startIcon: _jsx(AddCircleOutline, {}) }, { children: t('pages.tags.menu.toggle') })), _jsx(Popover, __assign({ id: NEW_TAG_MENU_ID, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'right' } }, { children: _jsxs(Box, __assign({ sx: { paddingX: 0.5, paddingY: 1 } }, { children: [_jsx(OGNavPopupMenuHeader, __assign({ id: NEW_TAG_MENU_HEADING }, { children: t('pages.tags.menu.heading') })), _jsxs(MenuList, __assign({ "aria-labelledby": NEW_TAG_MENU_HEADING, sx: { padding: 0 } }, { children: [_jsx(OGNavPopupMenuLink, __assign({ to: "".concat(pathname).concat(formatSearchParams(__assign(__assign({}, currentQueryParams), OPEN_TAG_MODAL_NODE_QUERY_PARAM))), onClose: handleClose, onClick: handleClose }, { children: t('pages.tags.menu.items.node_tag') })), _jsx(OGNavPopupMenuLink, __assign({ to: "".concat(pathname).concat(formatSearchParams(__assign(__assign({}, currentQueryParams), OPEN_TAG_MODAL_RESOURCE_QUERY_PARAM))), onClose: handleClose, onClick: handleClose }, { children: t('pages.tags.menu.items.resource_tag') }))] }))] })) }))] }));
}
