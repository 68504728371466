var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { TEST_LOCATION_PATH } from '@/api/services/node-backup/test-location/test-location';
import { useDeleteSession } from '@/api/sessions';
import { OGToastPriorities, useAlertNotification } from '@/hooks/alert-notification';
import { decodeDeleteFirmwareFileResponse } from '../../../types/lighthouse/decoders/system/node-firmware/firmware-id';
import { decodeReadNodeFirmwareResponse } from '../../../types/lighthouse/decoders/system/node-firmware/all';
import { decodeUploadNodeFirmwareResponse } from '../../../types/lighthouse/decoders/system/node-firmware';
import { formatErrorMessage } from '@/utils/errors';
import { useLighthouseToken } from '../utils';
import { useLighthouseFetch } from '@/hooks/use-lighthouse-fetch';
import { formatApiPath } from '@/api/utils';
export var NODE_FIRMWARE_ALL_PATH = '/system/node_firmware/all';
export var NODE_FIRMWARE_PATH = '/system/node_firmware';
export function formatNodeFirmwarePath(firmwareId) {
    if (firmwareId === void 0) { firmwareId = ':firmwareId'; }
    return "".concat(NODE_FIRMWARE_PATH, "/").concat(firmwareId);
}
export function useNodeFirmwares(queryParams, _a) {
    var _this = this;
    if (queryParams === void 0) { queryParams = {}; }
    var _b = _a === void 0 ? {} : _a, staleTime = _b.staleTime;
    var _c = useLighthouseFetch(), executeFetch = _c.executeFetch, decodeResponse = _c.decodeResponse, handleCommonStatusCodes = _c.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    var fetchNodeFirmwares = useCallback(function (context) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(NODE_FIRMWARE_ALL_PATH, __assign({ queryParams: queryParams }, context))];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeReadNodeFirmwareResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    handleCommonStatusCodes(data, status, t('api.node_firmware.get'));
                    if (status === 200) {
                        data.node_firmwares.forEach(function (nodeFirmware) {
                            queryClient.setQueryData([NODE_FIRMWARE_PATH, nodeFirmware.id], nodeFirmware);
                        });
                        return [2 /*return*/, data.node_firmwares];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [executeFetch, queryParams, decodeResponse, handleCommonStatusCodes, t, queryClient]);
    return useQuery({
        queryKey: [NODE_FIRMWARE_ALL_PATH, queryParams],
        queryFn: fetchNodeFirmwares,
        staleTime: staleTime,
    });
}
export function useDeleteNodeFirmware() {
    var _this = this;
    var _a = useLighthouseFetch(), executeFetch = _a.executeFetch, decodeResponse = _a.decodeResponse, handleCommonStatusCodes = _a.handleCommonStatusCodes;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    return useCallback(function (firmwareId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, data, status, translations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, executeFetch(formatNodeFirmwarePath(firmwareId), { method: 'DELETE' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, decodeResponse(decodeDeleteFirmwareFileResponse, response)];
                case 2:
                    _a = _b.sent(), data = _a.data, status = _a.status;
                    translations = {
                        error: { message: format(t('api.node_firmware.delete.error.message'), firmwareId) },
                        forbidden: { message: format(t('api.node_firmware.delete.forbidden.message'), firmwareId) },
                        not_found: { message: format(t('api.node_firmware.delete.not_found.message'), firmwareId) },
                        ok: {
                            header: t('api.node_firmware.delete.ok.header'),
                            message: format(t('api.node_firmware.delete.ok.message'), firmwareId),
                        },
                    };
                    handleCommonStatusCodes(data, status, translations);
                    if (status === 204) {
                        queryClient
                            .invalidateQueries({
                            queryKey: [TEST_LOCATION_PATH],
                        })
                            .catch(console.error);
                        queryClient.removeQueries({
                            queryKey: [NODE_FIRMWARE_PATH, firmwareId],
                        });
                        queryClient.setQueryData([NODE_FIRMWARE_PATH], function (nodeFirmwares) {
                            return nodeFirmwares ? nodeFirmwares.filter(function (nodeFirmware) { return nodeFirmware.id !== firmwareId; }) : [];
                        });
                        queryClient
                            .invalidateQueries({
                            queryKey: [NODE_FIRMWARE_PATH],
                        })
                            .catch(console.error);
                        queryClient
                            .invalidateQueries({
                            queryKey: [NODE_FIRMWARE_ALL_PATH],
                        })
                            .catch(console.error);
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); }, [decodeResponse, executeFetch, handleCommonStatusCodes, queryClient, t]);
}
export function useNodeFirmwareUpload() {
    var _this = this;
    var alertNotification = useAlertNotification().alertNotification;
    var t = useTranslation().t;
    var signOut = useDeleteSession();
    var queryClient = useQueryClient();
    var _a = __read(useState(new XMLHttpRequest()), 1), xhr = _a[0];
    var _b = __read(useState(), 2), fileStatus = _b[0], setFileStatus = _b[1];
    var isLoading = !!fileStatus && fileStatus.status === 'pending';
    var url = formatApiPath(NODE_FIRMWARE_PATH);
    var token = useLighthouseToken().token;
    var upload = useCallback(function (file) {
        return new Promise(function (resolve) {
            var filename = file.name;
            // On progress
            xhr.upload.onprogress = function (_a) {
                var loaded = _a.loaded, total = _a.total;
                setFileStatus({ filename: filename, status: 'pending', loaded: loaded, total: total });
            };
            // On request success
            xhr.onload = function (_a) {
                var loaded = _a.loaded, total = _a.total;
                return __awaiter(_this, void 0, void 0, function () {
                    var _b, data, status, _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0: return [4 /*yield*/, decodeUploadNodeFirmwareResponse(xhr)];
                            case 1:
                                _b = _d.sent(), data = _b.data, status = _b.status;
                                _c = status;
                                switch (_c) {
                                    case 200: return [3 /*break*/, 2];
                                    case 400: return [3 /*break*/, 3];
                                    case 401: return [3 /*break*/, 4];
                                    case 403: return [3 /*break*/, 6];
                                }
                                return [3 /*break*/, 7];
                            case 2:
                                setFileStatus({ filename: filename, status: 'success', loaded: loaded, total: total });
                                queryClient.setQueryData([NODE_FIRMWARE_PATH, data.node_firmware.id], data.node_firmware);
                                queryClient.setQueryData([NODE_FIRMWARE_PATH], function (nodeFirmwares) { return __spreadArray(__spreadArray([], __read((nodeFirmwares !== null && nodeFirmwares !== void 0 ? nodeFirmwares : [])), false), [data.node_firmware], false); });
                                queryClient
                                    .invalidateQueries({
                                    queryKey: [NODE_FIRMWARE_PATH],
                                })
                                    .catch(console.error);
                                queryClient
                                    .invalidateQueries({
                                    queryKey: [NODE_FIRMWARE_ALL_PATH],
                                })
                                    .catch(console.error);
                                queryClient
                                    .invalidateQueries({
                                    queryKey: [TEST_LOCATION_PATH],
                                })
                                    .catch(console.error);
                                alertNotification(t('api.node_firmware.post.ok.message'), {
                                    header: t('api.node_firmware.post.ok.header'),
                                    priority: OGToastPriorities.Success,
                                });
                                resolve(data.node_firmware);
                                return [3 /*break*/, 8];
                            case 3:
                                setFileStatus({ filename: filename, status: 'error', errorMessage: formatErrorMessage(data), loaded: loaded, total: total });
                                resolve(undefined);
                                return [3 /*break*/, 8];
                            case 4:
                                setFileStatus(undefined);
                                alertNotification(t('api.errors.unauthorized.message'), {
                                    header: t('api.errors.unauthorized.header'),
                                    persistOnUnmount: true,
                                    preventDuplicate: true,
                                    priority: OGToastPriorities.Warning,
                                });
                                return [4 /*yield*/, signOut()];
                            case 5:
                                _d.sent();
                                resolve(undefined);
                                return [3 /*break*/, 8];
                            case 6:
                                setFileStatus(undefined);
                                alertNotification(t('api.node_firmware.post.forbidden.message'));
                                resolve(undefined);
                                return [3 /*break*/, 8];
                            case 7:
                                setFileStatus({ filename: filename, status: 'error', errorMessage: formatErrorMessage(data), loaded: loaded, total: total });
                                resolve(undefined);
                                return [3 /*break*/, 8];
                            case 8: return [2 /*return*/];
                        }
                    });
                });
            };
            // On settled (abort, error, success)
            xhr.onloadend = function () {
                if (xhr.status === xhr.UNSENT) {
                    // Alert on abort, network interruption or browser failure
                    setFileStatus(undefined);
                    alertNotification(t('api.node_firmware.post.failure.message'), { priority: OGToastPriorities.Warning });
                    resolve(undefined);
                }
            };
            // Initialize request
            xhr.open('POST', url);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', "Token ".concat(token));
            // Create multipart form data
            var formData = new FormData();
            formData.append('firmware_name', filename);
            formData.append('file', file);
            // Upload
            xhr.send(formData);
        });
    }, [alertNotification, queryClient, signOut, t, token, url, xhr]);
    // Reset handler
    var reset = useCallback(function () {
        xhr.abort(); // Aborts request
        setFileStatus(undefined); // Clear file status
        // Remove event listeners
        xhr.upload.onprogress = null;
        xhr.onload = null;
        xhr.onloadend = null;
    }, [xhr]);
    useEffect(function () { return function () {
        // Abort request if active on unmount
        if (xhr.readyState > xhr.UNSENT || xhr.readyState < xhr.DONE) {
            xhr.abort();
        }
    }; }, [xhr]);
    return {
        data: fileStatus,
        isLoading: isLoading,
        upload: upload,
        reset: reset,
    };
}
