var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLighthouseToken } from '@/api/utils';
import { useCustomIFrameCSS } from '@/hooks/use-custom-iframe-css';
export var NETOPS_TITLE = 'NetOps';
function NetopsIframe(_a) {
    var src = _a.src;
    var token = useLighthouseToken().token;
    localStorage.setItem('ember_simple_auth-session', JSON.stringify({ authenticated: { session: token } }));
    var iFrameRef = useRef(null);
    var adjustHeight = useCallback(function (event) {
        var _a;
        if (event.data === 'resize' && ((_a = iFrameRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow)) {
            var nomReactDiv = iFrameRef.current.contentWindow.document.getElementById('root');
            if (nomReactDiv) {
                iFrameRef.current.style.height = nomReactDiv.scrollHeight === 0 ? '100%' : "".concat(nomReactDiv.scrollHeight, "px");
            }
        }
    }, []);
    useEffect(function () {
        var _a;
        if ((_a = iFrameRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow) {
            window.addEventListener('message', adjustHeight);
        }
        return function () {
            window.removeEventListener('message', adjustHeight);
        };
    }, [adjustHeight]);
    // Hide the titles as we already have the tab titles
    useCustomIFrameCSS(iFrameRef, 'h2 { display: none } #page-top-right-content { margin-bottom: 8px }');
    var theme = useTheme();
    var _b = __read(useState(theme.palette.mode === 'dark'), 1), startDark = _b[0];
    useEffect(function () {
        var _a;
        if ((_a = iFrameRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow) {
            iFrameRef.current.contentWindow.postMessage({ isDarkMode: theme.palette.mode === 'dark' }, window.location.origin);
        }
    }, [theme.palette.mode]);
    return (_jsx("iframe", { title: NETOPS_TITLE, id: "netops-iframe", src: src + (startDark ? '?darkmode=1' : ''), "aria-label": NETOPS_TITLE, style: {
            border: 'none',
            width: '100%',
            minHeight: '600px',
            display: 'block',
        }, ref: iFrameRef }));
}
// Setting a key based on the src URL causes a state reset when the src changes
// See: https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
// This is necessary as the ?darkmode query param on the iframe is frozen by storing it in a useState
// which is done do prevent theme changes from changing the iframe's src and causing it to reload
export default function NetopsFrameContainer(_a) {
    var src = _a.src;
    return _jsx(NetopsIframe, { src: src }, src);
}
