var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContainerContext } from '@/components/layout/forms';
import { ImmediateField, ScheduledTimeField, UpgradeInFailoverActionButton, CurrentUTCTime } from './form-controls';
import { NewFirmwareUpgradeButtons, NewFirmwareUpgradeStep, useNewFirmwareUpgradeSteps } from './steps';
import { UpgradeInFailoverNodesTable } from './upgrade-in-failover-nodes';
export function ScheduleUpgrade() {
    var t = useTranslation().t;
    var activeStep = useNewFirmwareUpgradeSteps().activeStep;
    var watch = useFormContainerContext().watch;
    if (activeStep !== NewFirmwareUpgradeStep.Schedule)
        return null;
    var immediate = watch('immediate');
    return (_jsxs(_Fragment, { children: [_jsxs(Paper, __assign({ sx: { padding: 2 } }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.schedule.start_time_subheading') })), _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }, { children: [_jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(ImmediateField, {}), !immediate && _jsx(ScheduledTimeField, {})] })), _jsx(CurrentUTCTime, {})] }))] })), _jsxs(Paper, __assign({ sx: { padding: 2 } }, { children: [_jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }, { children: [_jsx(Typography, __assign({ component: "h3", gutterBottom: true, variant: "h3" }, { children: t('pages.firmware_upgrade_new.steps.schedule.nodes_subheading') })), _jsx(Box, { children: _jsxs(ButtonGroup, { children: [_jsx(UpgradeInFailoverActionButton, { action: "allow" }), _jsx(UpgradeInFailoverActionButton, { action: "disallow" })] }) })] })), _jsx(UpgradeInFailoverNodesTable, {}), _jsx(NewFirmwareUpgradeButtons, {})] }))] }));
}
