var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OGDialog } from '@opengear/modals';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { OGButton } from '@opengear/buttons';
import { FormActions, CancelButton, SubmitButton } from '@/components/layout/forms';
import { getDefaultExternalNetworkFormState, ExternalNetworkForm, EXTERNAL_NETWORK_MODAL_TITLE_ID } from './external-network-form';
function ExternalNetworkModal(_a) {
    var useOpen = _a.useOpen, children = _a.children, title = _a.title;
    var open = useOpen.open, handleClose = useOpen.handleClose;
    return (_jsxs(OGDialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ id: EXTERNAL_NETWORK_MODAL_TITLE_ID }, { children: title })), children] })));
}
export function CreateExternalNetworkModal(_a) {
    var useOpen = _a.useOpen, permissions = _a.permissions, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    return (_jsx(ExternalNetworkModal, __assign({ useOpen: useOpen, title: t('pages.network_settings.external_address.modal.headers.create') }, { children: _jsx(ExternalNetworkForm, __assign({ onSubmit: onSubmit, permissions: permissions, defaultValues: getDefaultExternalNetworkFormState(), mode: "create" }, { children: _jsx(Box, __assign({ sx: { px: 2, pb: 2 } }, { children: _jsxs(FormActions, { children: [_jsx(CancelButton, { onCancel: useOpen.handleClose }), _jsx(SubmitButton, { children: t('pages.network_settings.external_address.form.actions.create') })] }) })) })) })));
}
export function UpdateExternalNetworkModal(_a) {
    var useOpen = _a.useOpen, permissions = _a.permissions, onDelete = _a.onDelete, onSubmit = _a.onSubmit, defaultValues = _a.defaultValues;
    var t = useTranslation().t;
    return (_jsx(ExternalNetworkModal, __assign({ useOpen: useOpen, title: t('pages.network_settings.external_address.modal.headers.update') }, { children: _jsx(ExternalNetworkForm, __assign({ onSubmit: onSubmit, permissions: permissions, defaultValues: defaultValues, mode: "update" }, { children: _jsxs(Box, __assign({ sx: { px: 2, pb: 2, mt: 2, display: 'flex', justifyContent: 'space-between' } }, { children: [_jsx(OGButton, __assign({ onClick: onDelete, startIcon: _jsx(DeleteIcon, {}), variant: "secondary", disabled: !permissions.hasEditPermission }, { children: t('global.buttons.delete') })), _jsxs(Box, __assign({ sx: { display: 'flex', gap: 1, justifyContent: 'end' } }, { children: [_jsx(CancelButton, { onCancel: useOpen.handleClose }), _jsx(SubmitButton, { children: t('pages.network_settings.external_address.form.actions.update') })] }))] })) })) })));
}
