var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'string-format';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { OpengearLogo } from '@opengear/logos';
import Box from '@mui/material/Box';
import { useNode } from '@/api/nodes';
import { NodeLogs } from '@/containers/nodes/node/node-logs';
import { LoadingBackdrop } from '@/components/layout/loading-backdrop';
import { withAuthenticationRequired } from '@/hocs/authentication-required';
import { useDocTitle } from '@/hooks/doc-title';
import { AppVersion } from '@/containers/menus/navigation-bar/app-version';
export var PORT_LOGS_LOADING_TEST_ID = 'port-logs-loading';
function PortLogs() {
    var nodeId = useParams().nodeId;
    var t = useTranslation().t;
    useDocTitle(format(t('pages.port_logs.title'), nodeId !== null && nodeId !== void 0 ? nodeId : ''));
    var _a = useNode(nodeId), data = _a.data, isLoading = _a.isLoading;
    var innerNode = null;
    if (isLoading) {
        innerNode = _jsx(LoadingBackdrop, { "data-testid": PORT_LOGS_LOADING_TEST_ID });
    }
    if (data) {
        var node = data.node;
        innerNode = _jsx(NodeLogs, { node: node, isFullScreen: true });
    }
    return (_jsxs(Box, { children: [_jsx(AppBar, { children: _jsxs(Toolbar, { children: [_jsx(OpengearLogo, {}), _jsx(AppVersion, {})] }) }), _jsx(Toolbar, {}), _jsx(Box, __assign({ padding: 2 }, { children: innerNode }))] }));
}
export default withAuthenticationRequired(PortLogs, { rights: ['monitor.nodes.view'] });
