var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, forwardRef } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RemoveIcon from '@mui/icons-material/Close';
/**
 *Autocomplete component
 *
 * @param options Array of of strings or Array of Objects. One key in the Object has to be 'label', or getOptionLabel should be changed
 * @param [label] Optional. The label of the input.
 * @param [placeholder] Optional. Placeholder inside input element.
 * @param [error] Optional. Define if it is Error state. Getting from React hook form, if the component is used in the form.
 * @param [helperText] Optional. The text that clarifies the error.
 * @param [ariaLabel] Optional. Aria-label attribute.
 * @param [multiple] optional. Define if multiple values are allowed.
 * @param [dense] Optional. Define if options are rendered in the list with compact vertical paddings. Works with defaultRenderOption based on Mui ListItem.
 * @param [defaultValue] Optional If multiple = true, defaultValue has Array<OptionType> type, otherwise - OptionType
 * @param [fixedOptions] Optional Array of non-removable options. Applicable if multiple = true
 * @param [renderInput] Optional. If it is defined, then 'placeholder', 'error', 'helperText', 'required', 'label' params are not allowed
 *
 * Usage in the form
 * <Controller
 *   control={control}
 *   name="autocomplete"
 *   rules={{ required: 'Autocomplete is required' }}
 *   render={({ field: { onChange, ...field }, fieldState: { error } }) => (
 *     <OGAutocomplete
 *       error={!!error}
 *       options={options}
 *       label={
 *         <>
 *           Label
 *           <OGIconTooltip title="Help Text" />
 *         </>
 *       }
 *       onChange={(_, data) => onChange(data)}
 *       defaultValue={options[0]}
 *       {...field}
 *     />
 *   )}
 * />
 */
export var OGAutocomplete = forwardRef(function (_a, ref) {
    var label = _a.label, options = _a.options, id = _a.id, sx = _a.sx, ariaLabel = _a.ariaLabel, renderInput = _a.renderInput, renderOption = _a.renderOption, renderTags = _a.renderTags, onChange = _a.onChange, onInputChange = _a.onInputChange, getOptionLabel = _a.getOptionLabel, fixedOptions = _a.fixedOptions, error = _a.error, helperText = _a.helperText, required = _a.required, placeholder = _a.placeholder, _b = _a.ChipProps, ChipProps = _b === void 0 ? { deleteIcon: _jsx(RemoveIcon, {}) } : _b, _c = _a.forcePopupIcon, forcePopupIcon = _c === void 0 ? true : _c, _d = _a.dense, dense = _d === void 0 ? true : _d, _e = _a.margin, margin = _e === void 0 ? 'dense' : _e, _f = _a.multiple, multiple = _f === void 0 ? false : _f, externalValue = _a.value, defaultValue = _a.defaultValue, otherProps = __rest(_a, ["label", "options", "id", "sx", "ariaLabel", "renderInput", "renderOption", "renderTags", "onChange", "onInputChange", "getOptionLabel", "fixedOptions", "error", "helperText", "required", "placeholder", "ChipProps", "forcePopupIcon", "dense", "margin", "multiple", "value", "defaultValue"]);
    var initialDefaultValue = multiple ? defaultValue !== null && defaultValue !== void 0 ? defaultValue : [] : defaultValue !== null && defaultValue !== void 0 ? defaultValue : null;
    var _g = __read(useState(initialDefaultValue), 2), value = _g[0], setValue = _g[1];
    var _h = __read(useState(''), 2), inputValue = _h[0], setInputValue = _h[1];
    var defaultSx = { width: '100%' };
    var inputLabel = useCallback(function () {
        if (label === 'none') {
            return null;
        }
        return (_jsxs(_Fragment, { children: [label, required ? (_jsx(Box, __assign({ component: "span", bgcolor: "text.label", borderRadius: "4px", color: "text.contrast", fontSize: "1.25rem", fontWeight: 500, lineHeight: 1, marginLeft: "6px", padding: "2px 3px" }, { children: "required" }))) : null] }));
    }, [label, required]);
    var defaultRenderInput = useCallback(function (params) { return (_jsx(TextField, __assign({}, params, { inputRef: ref, label: inputLabel(), margin: margin, placeholder: placeholder, error: error, helperText: helperText }))); }, [error, helperText, margin, placeholder, ref, inputLabel]);
    var defaultRenderOption = useCallback(function (props, option, _a) {
        var selected = _a.selected;
        return (_createElement(ListItem, __assign({}, props, { key: typeof option === 'string' ? option : option.label, dense: dense }),
            _jsxs(Box, __assign({ component: "span", sx: { width: '100%', display: 'flex', justifyContent: 'space-between' } }, { children: [typeof option === 'string' ? option : option.label, selected ? _jsx(CheckOutlinedIcon, { sx: { fontSize: '2rem' } }) : ''] }))));
    }, [dense]);
    var defaultRenderTag = useCallback(function (tagValue, getTagProps) {
        return tagValue.map(function (option, index) { return (_jsx(Chip, __assign({ deleteIcon: _jsx(RemoveIcon, {}), label: typeof option === 'object' ? option.label : option }, getTagProps({ index: index }), { disabled: fixedOptions && fixedOptions.indexOf(option) !== -1 }))); });
    }, [fixedOptions]);
    var defaultGetOptionLabel = useCallback(function (option) {
        if (typeof option === 'string') {
            return option;
        }
        return option.label;
    }, []);
    var changeOptionHandle = useCallback(function (event, newValue, reason, details) {
        if (onChange) {
            onChange(event, newValue, reason, details);
        }
        if (multiple && newValue && Array.isArray(newValue) && fixedOptions) {
            setValue(__spreadArray(__spreadArray([], __read(fixedOptions), false), __read(newValue.filter(function (option) { return fixedOptions.indexOf(option) === -1; })), false));
        }
        else {
            setValue(newValue);
        }
    }, [fixedOptions, multiple, onChange]);
    var changeInputHandler = useCallback(function (event, newInputValue, reason) {
        if (onInputChange) {
            onInputChange(event, newInputValue, reason);
        }
        setInputValue(newInputValue);
    }, [onInputChange]);
    return (_jsx(MuiAutocomplete, __assign({}, otherProps, { id: id, "aria-label": ariaLabel, value: externalValue !== undefined ? externalValue : value, forcePopupIcon: forcePopupIcon, options: options, multiple: multiple, ChipProps: ChipProps, onChange: changeOptionHandle, onInputChange: changeInputHandler, sx: __assign(__assign({}, defaultSx), sx), inputValue: inputValue, renderInput: renderInput !== null && renderInput !== void 0 ? renderInput : defaultRenderInput, renderOption: renderOption !== null && renderOption !== void 0 ? renderOption : defaultRenderOption, renderTags: renderTags !== null && renderTags !== void 0 ? renderTags : defaultRenderTag, getOptionLabel: getOptionLabel !== null && getOptionLabel !== void 0 ? getOptionLabel : defaultGetOptionLabel })));
});
