var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import DialogContentText from '@mui/material/DialogContentText';
import format from 'string-format';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SECONDARY_LIGHTHOUSES_ROUTE } from '@/constants/routes';
import { useDeleteDependentLighthouse } from '@/api/multiple-instance/dependent-lighthouses';
import { DeleteConfirmationModal } from '@/components/confirmation-modal';
export function SecondaryUnenrollModal(_a) {
    var editable = _a.editable, lighthouseId = _a.lighthouseId;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var deleteSecondaryLighthouse = useDeleteDependentLighthouse();
    var onDelete = useCallback(function () { return deleteSecondaryLighthouse(lighthouseId); }, [deleteSecondaryLighthouse, lighthouseId]);
    var onSuccess = useCallback(function () {
        navigate(SECONDARY_LIGHTHOUSES_ROUTE);
    }, [navigate]);
    return (_jsx(DeleteConfirmationModal, __assign({ title: t('pages.secondary_lighthouse.edit.unenroll.modal.title'), deleteButtonLabel: t('pages.secondary_lighthouse.edit.unenroll.button'), confirmLabel: t('pages.secondary_lighthouse.edit.unenroll.modal.confirm'), onDelete: onDelete, onSuccess: onSuccess, disabled: !editable }, { children: _jsx(DialogContentText, { children: format(t('pages.secondary_lighthouse.edit.unenroll.modal.message'), lighthouseId) }) })));
}
