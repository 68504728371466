var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { OGButton } from '@opengear/buttons';
import { OGNavPopupMenuLink, OGNavPopupMenuHeader } from '@opengear/navigation';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { useAnchorOpen } from '@/hooks/open';
import { NEW_AUTH_TEMPLATE_ROUTE, CREATE_USER_GROUP_TEMPLATE_ROUTE, CREATE_SCRIPT_TEMPLATE_ROUTE, CREATE_SMF_TEMPLATE_ROUTE } from '@/constants/routes';
export var NEW_CONFIG_TEMPLATE_MENU_ID = 'new-config-template-menu-id';
export function NewConfigTemplateMenu(_a) {
    var permissions = _a.permissions;
    var t = useTranslation().t;
    var _b = useAnchorOpen(), anchorEl = _b.anchorEl, open = _b.open, handleOpen = _b.handleOpen, handleClose = _b.handleClose;
    return (_jsxs(_Fragment, { children: [_jsx(OGButton, __assign({ "aria-controls": open ? NEW_CONFIG_TEMPLATE_MENU_ID : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, startIcon: _jsx(AddCircleOutline, {}), onClick: handleOpen, disabled: !permissions.hasAuthEditAccess &&
                    !permissions.hasScriptEditAccess &&
                    !permissions.hasSmfTemplateEditAccess &&
                    !permissions.hasUserGroupTemplateEditAccess }, { children: t('pages.config_templates.buttons.new_config_template_label') })), _jsx(Popover, __assign({ id: NEW_CONFIG_TEMPLATE_MENU_ID, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'right' } }, { children: _jsxs(Box, __assign({ sx: { paddingX: 0.5, paddingY: 1 } }, { children: [_jsx(OGNavPopupMenuHeader, { children: t('pages.config_templates.new_config_template_menu.header') }), _jsxs(MenuList, __assign({ sx: { padding: 0 } }, { children: [_jsx(OGNavPopupMenuLink, __assign({ disabled: !permissions.hasAuthEditAccess, to: NEW_AUTH_TEMPLATE_ROUTE, onClose: handleClose }, { children: t('pages.config_templates.new_config_template_menu.links.auth.label') })), _jsx(OGNavPopupMenuLink, __assign({ disabled: !permissions.hasScriptEditAccess, to: CREATE_SCRIPT_TEMPLATE_ROUTE, onClose: handleClose }, { children: t('pages.config_templates.new_config_template_menu.links.scripts.label') })), _jsx(OGNavPopupMenuLink, __assign({ disabled: !permissions.hasSmfTemplateEditAccess, to: CREATE_SMF_TEMPLATE_ROUTE, onClose: handleClose }, { children: t('pages.config_templates.new_config_template_menu.links.ospf.label') })), _jsx(OGNavPopupMenuLink, __assign({ disabled: !permissions.hasUserGroupTemplateEditAccess, to: CREATE_USER_GROUP_TEMPLATE_ROUTE, onClose: handleClose }, { children: t('pages.config_templates.new_config_template_menu.links.usergroups.label') }))] }))] })) }))] }));
}
